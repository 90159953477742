import eventNames from "../../actions/events";

const initialTranslationsState = {
	triggerListUpdate: false,
	filterData: {},
	fetchAllChemotherapyListComplete: false,
	chemotherapyList: {},
	fetchOneChemotherapyComplete: false,
	chemotherapy: {},
	getAllChemotherapyStaffComplete: false,
	chemotherapyStaff: [],
	getAllTextElementsComplete: false,
	textElements: {},
	getAllElementsComplete: false,
	elements: {},
	isGetOneChemoStaffComplete: false,
	isUpdateChemoStaffComplete: false,
	isStaffDeleteComplete: false,
	isChemoAgentsFetchComplete: false,
	chemoAgents: {},
	isCopyChemotherapyComplete: false,
	fetchCyclesForTreatmentsComplete: false,
	isChemoTextElementComplete: false,
	cyclesForTreatment: {},
	isUpdateChemotherapyComplete: false,
	isChemoPrepInfusionCreateComplete: false,
	isGetOneChemoPrepInfusionComplete: false,
	switchStaffTemplateComplete: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case eventNames.TRIGGER_FILTER_LIST_UPDATE:
			return {
				...state,
				triggerListUpdate: true,
				filterData: action.payload
			};
		case eventNames.CLEAR_TRIGGER_FILTER_LIST_UPDATE:
			return {
				...state,
				triggerListUpdate: false,
				filterData: action.payload
			};
		case `${eventNames.FETCH_ALL_CHEMOTHERAPY_LIST}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllChemotherapyListComplete: "FULFILLED",
					chemotherapyList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllChemotherapyListComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CHEMOTHERAPY_LIST}_REJECTED`:
			return {
				...state,
				fetchAllChemotherapyListComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CHEMOTHERAPY_LIST:
			return {
				...state,
				fetchAllChemotherapyListComplete: false
			};
		case `${eventNames.FETCH_ONE_CHEMOTHERAPY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemotherapy: action.payload.data.data,
					fetchOneChemotherapyComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchOneChemotherapyComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CHEMOTHERAPY}_REJECTED`:
			return {
				...state,
				fetchOneChemotherapyComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CHEMOTHERAPY:
			return {
				...state,
				fetchOneChemotherapyComplete: false
			};
		case `${eventNames.GET_ALL_CHEMOTHERAPY_STAFF}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemotherapyStaff: action.payload.data.data,
					getAllChemotherapyStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getAllChemotherapyStaffComplete: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CHEMOTHERAPY_STAFF}_REJECTED`:
			return {
				...state,
				getAllChemotherapyStaffComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ALL_CHEMOTHERAPY_STAFF:
			return {
				...state,
				getAllChemotherapyStaffComplete: false
			};
		case `${eventNames.STAFF_UPDATE_RANK}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					rank: action.payload.data.data,
					ifStaffRankUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				ifStaffRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.STAFF_UPDATE_RANK}_REJECTED`:
			return {
				...state,
				ifStaffRankUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_STAFF_UPDATE_RANK:
			return {
				...state,
				ifStaffRankUpdateComplete: false
			};
		case `${eventNames.GET_ALL_TEXT_ELEMENTS_FOR_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					textElements: action.payload.data.data,
					getAllTextElementsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getAllTextElementsComplete: "FULFILLED"
			};
		case `${eventNames.GET_ALL_TEXT_ELEMENTS_FOR_CHEMO}_REJECTED`:
			return {
				...state,
				getAllTextElementsComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ALL_TEXT_ELEMENTS_FOR_CHEMO:
			return {
				...state,
				getAllTextElementsComplete: false
			};
		case `${eventNames.GET_ALL_ELEMENTS_FOR_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elements: action.payload.data.data,
					getAllElementsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getAllElementsComplete: "FULFILLED"
			};
		case `${eventNames.GET_ALL_ELEMENTS_FOR_CHEMO}_REJECTED`:
			return {
				...state,
				getAllElementsComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ALL_ELEMENTS_FOR_CHEMO:
			return {
				...state,
				getAllElementsComplete: false
			};
		case `${eventNames.GET_ONE_CHEMOTHERAPY_STAFF}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoStaff: action.payload.data.data,
					isGetOneChemoStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isGetOneChemoStaffComplete: "FULFILLED"
			};
		case `${eventNames.GET_ONE_CHEMOTHERAPY_STAFF}_REJECTED`:
			return {
				...state,
				isGetOneChemoStaffComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_CHEMOTHERAPY_STAFF}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoStaffUpdated: action.payload.data.data,
					isUpdateChemoStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isUpdateChemoStaffComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_CHEMOTHERAPY_STAFF}_REJECTED`:
			return {
				...state,
				isUpdateChemoStaffComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_CHEMOTHERAPY_STAFF:
			return {
				...state,
				isGetOneChemoStaffComplete: false,
				isUpdateChemoStaffComplete: false
			};
		case `${eventNames.DELETE_CHEMOTHERAPY_STAFF}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoDeleted: action.payload.data.data,
					isStaffDeleteComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isStaffDeleteComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_CHEMOTHERAPY_STAFF}_REJECTED`:
			return {
				...state,
				isStaffDeleteComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CHEMOTHERAPY_STAFF:
			return {
				...state,
				isStaffDeleteComplete: false
			};
		case `${eventNames.GET_ALL_CHEMO_AGENTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoAgents: action.payload.data.data,
					isChemoAgentsFetchComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isChemoAgentsFetchComplete: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CHEMO_AGENTS}_REJECTED`:
			return {
				...state,
				isChemoAgentsFetchComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ALL_CHEMO_AGENTS:
			return {
				...state,
				isChemoAgentsFetchComplete: false
			};
		case `${eventNames.GET_ONE_CHEMO_AGENTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoAgents: action.payload.data.data,
					isChemoAgentsFetchComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isChemoAgentsFetchComplete: "FULFILLED"
			};
		case `${eventNames.GET_ONE_CHEMO_AGENTS}_REJECTED`:
			return {
				...state,
				isChemoAgentsFetchComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_CHEMO_AGENTS:
			return {
				...state,
				isChemoAgentsFetchComplete: false
			};
		case `${eventNames.COPY_CHEMOTHERAPY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCopyChemotherapyComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isCopyChemotherapyComplete: "FULFILLED"
			};
		case `${eventNames.COPY_CHEMOTHERAPY}_REJECTED`:
			return {
				...state,
				isCopyChemotherapyComplete: "REJECTED"
			};
		case eventNames.CLEAR_COPY_CHEMOTHERAPY:
			return {
				...state,
				isCopyChemotherapyComplete: false
			};
		case `${eventNames.CHEMO_AGENT_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoAgentUpdate: action.payload.data.data,
					isChemoAgentUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isChemoAgentUpdateComplete: "FULFILLED"
			};
		case `${eventNames.CHEMO_AGENT_UPDATE}_REJECTED`:
			return {
				...state,
				isChemoAgentUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_CHEMO_AGENT_UPDATE:
			return {
				...state,
				isChemoAgentUpdateComplete: false
			};
		case `${eventNames.STAFF_CHEMO_CREATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoAgentUpdate: action.payload.data.data,
					isStaffChemoCreateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isStaffChemoCreateComplete: "FULFILLED"
			};
		case `${eventNames.STAFF_CHEMO_CREATE}_REJECTED`:
			return {
				...state,
				isStaffChemoCreateComplete: "REJECTED"
			};
		case eventNames.CLEAR_STAFF_CHEMO_CREATE:
			return {
				...state,
				isStaffChemoCreateComplete: false
			};
		case `${eventNames.FETCH_CYCLES_FOR_TREATMENTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					cyclesForTreatment: action.payload.data.data,
					fetchCyclesForTreatmentsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchCyclesForTreatmentsComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_CYCLES_FOR_TREATMENTS}_REJECTED`:
			return {
				...state,
				fetchCyclesForTreatmentsComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_CYCLES_FOR_TREATMENTS:
			return {
				...state,
				fetchCyclesForTreatmentsComplete: false
			};
		case `${eventNames.CREATE_CHEMO_TEXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoTextElement: action.payload.data.data,
					isChemoTextElementComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isChemoTextElementComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_CHEMO_TEXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isChemoTextElementComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CHEMO_TEXT_ELEMENT:
			return {
				...state,
				isChemoTextElementComplete: false
			};
		case `${eventNames.UPDATE_CHEMOTHERAPY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateChemotherapyComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isUpdateChemotherapyComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_CHEMOTHERAPY}_REJECTED`:
			return {
				...state,
				isUpdateChemotherapyComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CHEMOTHERAPY:
			return {
				...state,
				isUpdateChemotherapyComplete: false,
				isChemoPrepInfusionCreateComplete: false
			};
		case `${eventNames.CREATE_CHEMO_PREP_INFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoPrepInfusion: action.payload.data.data,
					isChemoPrepInfusionCreateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isChemoPrepInfusionCreateComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_CHEMO_PREP_INFUSION}_REJECTED`:
			return {
				...state,
				isChemoPrepInfusionCreateComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CHEMO_PREP_INFUSION:
			return {
				...state,
				isChemoPrepInfusionCreateComplete: false
			};
		case `${eventNames.GET_ONE_CHEMO_PREP_INFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoPrepInfusion: action.payload.data.data,
					isGetOneChemoPrepInfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isGetOneChemoPrepInfusionComplete: "FULFILLED"
			};
		case `${eventNames.GET_ONE_CHEMO_PREP_INFUSION}_REJECTED`:
			return {
				...state,
				isGetOneChemoPrepInfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_CHEMO_PREP_INFUSION:
			return {
				...state,
				isGetOneChemoPrepInfusionComplete: false
			};
		case `${eventNames.UPDATE_CHEMO_PREP_INFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chemoPrepInfusion: action.payload.data.data,
					isUpdateChemoPrepInfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isUpdateChemoPrepInfusionComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_CHEMO_PREP_INFUSION}_REJECTED`:
			return {
				...state,
				isUpdateChemoPrepInfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CHEMO_PREP_INFUSION:
			return {
				...state,
				isUpdateChemoPrepInfusionComplete: false
			};
		case `${eventNames.GET_CHRONIC_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					chronicElements: action.payload.data.data,
					getChronicElementsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getChronicElementsComplete: "FULFILLED"
			};
		case `${eventNames.GET_CHRONIC_CHEMO}_REJECTED`:
			return {
				...state,
				getChronicElementsComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_CHRONIC_CHEMO:
			return {
				...state,
				getChronicElementsComplete: false
			};
		case `${eventNames.UPDATE_RANK_PREP_INFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedRank: action.payload.data.data,
					updatePrepInfusionRankComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updatePrepInfusionRankComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_RANK_PREP_INFUSION}_REJECTED`:
			return {
				...state,
				updatePrepInfusionRankComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_RANK_PREP_INFUSION:
			return {
				...state,
				updatePrepInfusionRankComplete: false
			};
		case `${eventNames.DELETE_PREP_INFUSION_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					delete: action.payload.data.data,
					isDeletePrepInfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isDeletePrepInfusionComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_PREP_INFUSION_CHEMO}_REJECTED`:
			return {
				...state,
				isDeletePrepInfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_PREP_INFUSION_CHEMO:
			return {
				...state,
				isDeletePrepInfusionComplete: false
			};
		case `${eventNames.DELETE_AGENTS_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					delete: action.payload.data.data,
					isDeleteAgentsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isDeleteAgentsComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_AGENTS_CHEMO}_REJECTED`:
			return {
				...state,
				isDeleteAgentsComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_AGENTS_CHEMO:
			return {
				...state,
				isDeleteAgentsComplete: false
			};
		case `${eventNames.COPY_SESSION_TYPE_CHEMO}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					copySessionTypeTeamsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				copySessionTypeTeamsComplete: "FULFILLED"
			};
		case `${eventNames.COPY_SESSION_TYPE_CHEMO}_REJECTED`:
			return {
				...state,
				copySessionTypeTeamsComplete: "REJECTED"
			};
		case eventNames.CLEAR_COPY_SESSION_TYPE_CHEMO:
			return {
				...state,
				copySessionTypeTeamsComplete: false
			};
		case eventNames.CHEMO_MODE_CHANGE:
			return {
				...state,
				chemoModeChange: true
			};
		case eventNames.CLEAR_CHEMO_MODE_CHANGE:
			return {
				...state,
				chemoModeChange: false
			};
		case `${eventNames.CHEMO_MODE_AUTOMATIC}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					changeChemoModeComplete: "FULFILLED"
				};
			}
			return {
				...state,
				changeChemoModeComplete: "FULFILLED"
			};
		case `${eventNames.CHEMO_MODE_AUTOMATIC}_REJECTED`:
			return {
				...state,
				changeChemoModeComplete: "REJECTED"
			};
		case eventNames.CLEAR_CHEMO_MODE_AUTOMATIC:
			return {
				...state,
				changeChemoModeComplete: false
			};
		case `${eventNames.SWITCH_STAFF_TEMPLATE_FOR_CHEMOTHERAPY}_FULFILLED`:
			return {
				...state,
				switchStaffTemplateComplete: "FULFILLED"
			};
		case `${eventNames.SWITCH_STAFF_TEMPLATE_FOR_CHEMOTHERAPY}_REJECTED`:
			return {
				...state,
				switchStaffTemplateComplete: "REJECTED"
			};
		case eventNames.CLEAR_SWITCH_STAFF_TEMPLATE_FOR_CHEMOTHERAPY:
			return {
				...state,
				switchStaffTemplateComplete: false
			};
		default:
			return state;
	}
}
