import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import "./style.css";
import Select, { components } from "react-select";

const Option = props => {
	return (
		<div>
			<components.Option {...props}>
				<input
					type="checkbox"
					checked={props.isSelected}
					onChange={() => null}
				/>{" "}
				<label>{props.label}</label>
			</components.Option>
		</div>
	);
};

// const ValueContainer = ({ children, ...props }) => {
// 	const { getValue, hasValue } = props;
// 	const nbValues = getValue().length;
// 	if (!hasValue) {
// 	  return (
// 		<components.ValueContainer {...props}>
// 		  {children}
// 		</components.ValueContainer>
// 	  );
// 	}
// 	return (
// 	  <components.ValueContainer {...props}>
// 		{`${nbValues} items selected`}
// 	  </components.ValueContainer>
// 	);
//   };
  
const MultiValueList = props => (
	<components.MultiValue {...props}>
		<span>{props.data.label}</span>
	</components.MultiValue>
);
const MultiValue = ({ getValue, index }) =>  !index && `${getValue().filter(e => e.value != "all").length} items selected`;

const animatedComponents = makeAnimated();
export default class ReactMultiSelectCheckboxes extends Component {
	constructor(props) {
		super(props);
		this.state = {
			optionSelected: null
		};
	}

	handleChange = (selected, action) => {
		this.setState({
			optionSelected: selected
		});
		this.props.onChange(selected, action);
	};
	
	render() {

		const components = !this.props.istTag ? { Option, MultiValue, animatedComponents } : { Option, MultiValueList, animatedComponents };
    return (
			<Select
				isOptionSelected={this.props.isOptionSelected}
				options={this.props.options}
				isMulti = {this.props.isMulti ? true : false}
				closeMenuOnSelect={false}
				hideSelectedOptions={false}
				components={components}
				onChange={this.handleChange}
				value={this.props.value && this.props.value.length > 0 && this.props.value[0].label !== undefined ? this.props.value : null}
				allowSelectAll={this.props.allowSelectAll ? true : false}
				placeholder={this.props.placeholderButtonLabel}
				isClearable={true}
				className={`multi-select-checkbox ${this.props.className}`}
		  />
		);
	}
}


