import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import ActionButton from "../../../../../../../plug/action-button";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import utils from "../../../../../../../utils/utils";
import eventNames from "../../../../../../../actions/events";
import { Steps, Button, Checkbox } from "antd";
import Select, { components } from "react-select";
import differenceBy from "lodash.differenceby";
import Loader from "../../../../../../../plug/loader";
import ShowOrHideSteps from "./show-or-hide-steps";
import ActiveComponents from "./active-components";
import clinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";
import StepsComponent from "./stepsComponent";
import { CopyOutlined } from "@ant-design/icons";
// import { times } from 'lodash';
import { Radio } from "antd";

const Step = Steps.Step;

const Option = props => {
	return (
		<div className='react-select-option-label' style={{ color: props.data.color }}>
			<components.Option {...props} />{props.data.color && <label className="possition"><I18 tkey="Already Linked" /></label>}
		</div>
	);
};

const formatOptionLabel = ({ label, extraText }) => (
	<div>
		<div>{label}</div>
		<div style={{ fontSize: 12, color: "gray" }}>{extraText}</div>
	</div>
);
const validTab = ["CLINICAL_PATHWAY", "ALL_ITEMS"];
  
class CopyFromPathWayProblems extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			clinicalPathways: [],
			selectedData: null,
			genericComponents: {
				PATHWAY_DEFINITION: false,
				PATHWAY_ELIGIBILITY: false,
				AUTHORS_AND_ACTIONS: false,
				INTERVENTIONS: false,
				ANAESTHESIA: false
			},
			clinicalPathway: {},
			activeComponents: [],
			selectAll: true,
			checkList: [],
			listOffset: 0,
			listNumResults: 500,
			renderList: [],
			componentsForSelection: {},
			stepLoader: false,
			checkAll: false,
			clpStepFilter: "ALL_ITEMS",
			loading: false,
			code: "",
			copyLoading: false,
			clinicalPathwayCategory: this.props.clinicalPathwayData.isModel ? "MDL" : this.props.clinicalPathwayData.isCheckList ? "CL" : "CLP", //CLP,CL,MDL,
			clinicalPathwayType: this.props.clpStepFilter ? this.props.clpStepFilter : "ALL_ITEMS",
			subStepMapping: [],
			invalidLocation: false,
			currentTitle: "",
			indeterminate: false,
			copyWithoutModel: false,
			checkedItemBackup: [],
			options: [],
			isValid: false,
			optionsValid: [
				{
				  label: "All",
				  value: "All"
				},
				{
				  label: "Valid",
				  value: "Valid"
				}
			  ],
			value: "All"
		};
	}

	UNSAFE_componentWillMount() {
		this.state.clinicalPathway = this.props.clinicalPathwayData;
		this.fetchList();
		this.updateModelTitle();
		this.setGenericComponents();
	}

	setGenericComponents() {
		const prevState = this.state;
		prevState.genericComponents = {
			PATHWAY_DEFINITION: false,
			PATHWAY_ELIGIBILITY: false,
			AUTHORS_AND_ACTIONS: false,
			INTERVENTIONS: false,
			ANAESTHESIA: false
		};
		this.setState(prevState);
	}

	isIncludeModels(id) {
		if (this.props.clinicalPathwayData.modelClpIds) {
			return this.props.clinicalPathwayData.modelClpIds.split(",").includes(id);
		}
	}
	updateModelTitle() {
		const prevState = this.state;
		prevState.currentTitle = this.state.clinicalPathwayCategory == "MDL" ? "Model" : this.state.clinicalPathwayCategory == "CL" ? "Check List" : "Clinical Pathway";
		this.props.getModelTitle(prevState.currentTitle);
		this.setState(prevState);
	}

	fetchList(search) {
		this.updateModelTitle();
		this.setState({ isListLoading: true });
		const sourceType = this.state.clinicalPathwayCategory ? this.state.clinicalPathwayCategory : "";
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&`;
		queryParams += `sort=name&desc=0&${search ? `name=${search}&` : ""}`;
		queryParams += "ignoreExisting=true&";
		queryParams += `treatmentId=${this.props.match.params.id}&`;
		queryParams += "status=APPROVED&";
		queryParams += !this.state.clinicalPathway.isCheckList ? "isCheckList=0&" : "";
		queryParams += this.state.clinicalPathwayCategory ? `sourceType=${sourceType}&` : "";
		queryParams += this.state.clinicalPathwayType ? `stage=${this.state.clinicalPathwayType}&` : "";
		queryParams += validTab.includes(this.state.clinicalPathwayType) ? `valid=${this.state.isValid}` : "";
		//queryParams += this.state.clinicalPathway.clinicalPathway ? `clinicalPathwayType=${this.state.clinicalPathway.clinicalPathway}&` : '';
		this.props.dispatch(clinicalPathwayAction.getAllClpsForCopy(this.props.match.params.id, queryParams));
	}

	fetchListServer(clinicalPathWayId) {
		this.setState({ checkAll: false });
		let queryString = "";
		queryString += "offset=0&";
		queryString += "numResults=1000&";
		queryString += "isCopyList=true&";
		queryString += this.state.clinicalPathway.isCheckList ? "isCheckList=1&" : "";
		queryString += this.state.clinicalPathwayType ? `clpStepFilter=${this.state.clinicalPathwayType}&` : "";
		this.props.dispatch(clinicalPathwayProblemAction.getClpProblemListCopyData(clinicalPathWayId, this.props.match.params.organization, queryString));
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= 767 });
	}

	onListScroll() {
		this.state.stepsOffset += this.state.stepsNumResults;
		this.fetchList();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.timer = setTimeout(() => {
				this.state.stepsOffset = 0;
				this.fetchList(value);
			}, 700);
		}
	}

	onSubStepMapping(data) {
		const prevState = this.state;
		prevState.subStepMapping = data;
		prevState.invalidLocation = false;
		this.setState(prevState);
	}

	selectedSubStep() {
		const clpSubStepIds = [];
		this.state.subStepMapping.map(data => {
			clpSubStepIds.push({ clpSubStepId: data.clpSubStepId, day: data.day ? data.day : null, stepCode: data.stepCode, subStepCode: data.subStepCode });
		});
		return clpSubStepIds;
	}

	readOnlyOptions = ({ noOfDays, procedureDay, clinicalPathwayType }) => {
		//source
		const nfS = noOfDays ? parseInt(noOfDays) : 0;
		const pD = procedureDay ? parseInt(procedureDay) : 0;
		//Destination
		const cnfS = this.state.clinicalPathway.noOfDays ? parseInt(this.state.clinicalPathway.noOfDays) : 0;
		const cpD = this.state.clinicalPathway.procedureDay ? parseInt(this.state.clinicalPathway.procedureDay) : 0;
		let text = "";
		const tab = validTab.includes(this.state.clinicalPathwayType);
		if (tab) {
			if (cnfS < nfS || cpD !== pD) {
				text += cnfS < nfS ? `${i18Get("Hospitalisation Duration is", utils.getLanguage())} ${nfS},` : "";
				text += cpD !== pD ? `${i18Get("Procedure is on day", utils.getLanguage())} ${pD},` : "";
				text += text !== "" ? `${i18Get("hence can't copy", utils.getLanguage())}` : "";
				return { isReadOnly: true, text };
			}
		}
		return { isReadOnly: false, text: "" };
	};

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.clinicalPathways = differenceBy(this.state.clinicalPathways, [this.state.intervention], "id");
		this.state.clinicalPathways.map((data) => {
			const stepSectionType = data.stepSectionType != utils.sameDropper() ? data.stepSectionType : null;
			const prefixName = data.siteId && data.hospitalId ? data.siteShortName : data.hospitalName ? data.hospitalName : "MOM";
			const { isReadOnly, text } = this.readOnlyOptions(data);
		
			prevState.options.push({ value: data.id, label: `${prefixName} ${data.name}`, code: data.code, color: this.isIncludeModels(data.id) ? "red" : "", stepSectionType: stepSectionType, hospitalId: data.hospitalId, extraText: text,  isDisabled: isReadOnly });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onOptionsChange(e) {
		const selectedData = e;
		this.setState({ selectedData: selectedData, invalidClinicalPathway: false, invalidComponents: false, checkAll: false });
		if (selectedData && selectedData.value && this.state.clinicalPathwayType !== "DEFINITION") {
			this.fetchListServer(selectedData.value);
			this.setState({ stepLoader: true });
		}
	}

	getProblemList() {
		this.setState({ stepLoader: true });
		this.fetchListServer(this.state.selectedData.value);

	}
	prevClicked() {
		// this.setState({ activeStep: this.state.activeStep - 1 });
		const prevState = this.state;
		if (this.state.copyWithoutModel) { 
			prevState.checkList = JSON.parse(JSON.stringify(this.state.checkedItemBackup)); 
			prevState.steps = JSON.parse(JSON.stringify(this.state.checkedItemBackup)); 
		}
		prevState.copyWithoutModel = false;
		prevState.activeStep = this.state.activeStep - 1;
		this.setState(prevState);
	}

	nextWithoutModelClicked () {
		const prevState = this.state;
		const checkedItemBackup = this.state.checkList.length > 0 ? JSON.parse(JSON.stringify(this.state.checkList)) : [];
		const problems = prevState.checkList.filter(d => !d.modelTreatmentId && !d.checkListTreatmentId);
		const problemWithoutModel = [];
		problems.map((value) => {
		 	const additionalInfo =  value.additionalInfo.filter(e => !e.modelTreatmentId && !e.checkListTreatmentId);
			 value["additionalInfo"] = additionalInfo;
			 problemWithoutModel.push(value);
		 });

		this.setState({ checkList: problemWithoutModel, copyWithoutModel: true, checkedItemBackup: checkedItemBackup }, () => {
			this.setState({ activeStep: this.state.activeStep + 1 });
		} );
	}

	nextClicked() {
		if (this.checkActiveComponents() && (this.props.clinicalPathwayData.hospitalId === this.state.selectedData.hospitalId)) {
			this.setState({ activeStep: this.state.activeStep + 1 });
		} else if (this.props.clinicalPathwayData.hospitalId !== this.state.selectedData.hospitalId) {
			this.nextWithoutModelClicked();
		}
	}

	checklistModel() {
		const prevState = this.state;
		const problems = prevState.checkList.filter(d => d.modelTreatmentId || d.checkListTreatmentId);
		const additionalInfo = [];
		prevState.checkList.forEach(value => { 
			value.additionalInfo.forEach(e => { if (!e.isHeading && (e.modelTreatmentId || e.checkListTreatmentId)) {
				additionalInfo.push(e);
			}});
		});
		return (
			<div>
				{ this.state.clinicalPathwayCategory === "CLP" && (problems.length || additionalInfo.length) ?
					this.state.selectedData && this.props.clinicalPathwayData.hospitalId !== this.state.selectedData.hospitalId &&
				<div className="information float_left"><I18 tkey="Model and checklist will be ignored while copying"/></div> : ""  
				}
				{this.state.clinicalPathwayCategory !== "CLP" && additionalInfo.length ?
					this.state.selectedData && this.props.clinicalPathwayData.hospitalId !== this.state.selectedData.hospitalId &&
				<div className="information float_left"><I18 tkey="Checklist will be ignored while copying"/></div> : ""}
			</div>
			
		);

	}

	checkActiveComponents() {
		const checkedDataList = this.state.checkList.filter(value => value.checked === true);
		if ((checkedDataList && checkedDataList.length) || (Object.values(this.state.genericComponents).includes(!""))) {
			this.state.invalidComponents = false;
		} else if (this.state.selectedData && this.state.selectedData.value) {
			this.state.invalidComponents = true;
		}
		if (!this.state.selectedData || !this.state.selectedData.value) {
			this.state.invalidClinicalPathway = true;
		}
		if (this.state.subStepMapping.length == 0 && !this.props.clinicalPathwayData.isCheckList && this.state.clinicalPathwayCategory === "CL" && this.state.selectedData && this.state.selectedData.stepSectionType && this.state.clinicalPathwayType !== "DEFINITION") {
			this.state.invalidLocation = true;
		}
		if (this.state.invalidComponents || this.state.invalidClinicalPathway || this.state.invalidLocation) {
			this.setState(this.state);
			return false;
		}

		return true;
	}

	getSelectedComponents(checkList) {
		const checkedDataListCount = checkList.filter(value => value.checked === true);
		if (checkList.length == checkedDataListCount.length) {
			this.setState({ checkList: checkList, invalidComponents: false }, () => { this.oncheckAllChecked(); });
		} else {
			this.setState({ checkList: checkList, invalidComponents: false }, () => { this.oncheckAllChecked(); });
		}
	}

	getSubStepDetails() {
		if (this.state.steps && this.state.steps.length) {
			return (<ShowOrHideSteps getSelectedComponents={this.getSelectedComponents.bind(this)} steps={this.state.steps} checkList={this.state.checkList} isCheckList={this.state.clinicalPathway.isCheckList} />);
		} else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	getActiveComponents() {
		const checkActiveComponents = this.state.checkList.filter(value => value.checked === true);
		if (this.state.checkList && this.state.checkList && checkActiveComponents && checkActiveComponents.length) {
			return (<ActiveComponents checkList={this.state.checkList} />);
		} else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	oncheckAllChecked() {
		const prevState = this.state;
		const genericComponentsObj = Object.keys(this.state.genericComponents);
		const genCount = genericComponentsObj.length;

		let genCheckedCount = 0;
		genericComponentsObj.forEach(element => { if (this.state.genericComponents[element] == true) { genCheckedCount++; } });

		let stepsChecked = 0;
		let stepsCount = 0;
		this.state.checkList.map(function (e) {
			stepsCount++;
			if (e["checked"] === true) {
				stepsChecked++;
			}
			e.additionalInfo.map(function (data) {
				if (data["checked"] === true) {
					stepsChecked++;
				}
				stepsCount++;
			});
		});
		if (genCount == genCheckedCount && stepsCount == stepsChecked && genCheckedCount > 0) {
			prevState.indeterminate = false;
			prevState.checkAll = true;
		} else if (genCheckedCount > 0 || stepsChecked > 0) {
			prevState.indeterminate = true;
			prevState.checkAll = false;
		} else {
			prevState.indeterminate = false;
			prevState.checkAll = false;
		}
		this.setState(prevState);
	}

	onCheckBoxChange(e, value) {
		const prevState = this.state;
		if (e.target.checked) {
			prevState.genericComponents[value] = true;
			prevState.invalidComponents = false;
			prevState.checkAll = false;
		} else {
			prevState.genericComponents[value] = "";
			prevState.checkAll = false;
		}
		this.setState(prevState, () => { this.oncheckAllChecked(); });
	}

	onChangeCheckAll(e) {
		if (e.target.checked) {
			// this.state.checkAll = true;
			this.state.invalidComponents = false;
			this.state.genericComponents.PATHWAY_DEFINITION = true;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = true;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = true;
			if (this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = true;
				this.state.genericComponents.ANAESTHESIA = true;
			}
			this.state.checkList.map(function (e) {
				e["checked"] = true;
				e.additionalInfo.map(function (data) {
					data["checked"] = true;
				});
			});
		}
		else {
			// this.state.checkAll = false;
			this.state.genericComponents.PATHWAY_DEFINITION = false;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = false;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = false;
			if (this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = false;
				this.state.genericComponents.ANAESTHESIA = false;
			}
			this.state.checkList.map(function (e) {
				e["checked"] = false;
				e.additionalInfo.map(function (data) {
					data["checked"] = false;
				});
			});
		}
		//this.setState(this.state);
		this.setState(this.state, () => { this.oncheckAllChecked(); });
	}

	// changeClpStepFilter = filter => { this.setState({ clpStepFilter: filter }, () => { this.getProblemList(filter); }); };

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayProblemAction.copyFromClinicalPathwayProblemsStatus(this.state.code, {}));
			}.bind(this), 3000);
		}
	}

	isProblemCopyCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while copying problems", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	toggleModal = () => {
		this.setState({ copyLoading: false, activeStep: 0 });
		if (this.props.actions.actionInProgress) {
			this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		}
		this.props.closeCopyFromPathWayProblems();
		this.props.reloadDetails();
		this.props.reloadData();
	};

	toggleClinicalPathwayCategory = (category) => {
		this.setState({ clinicalPathwayCategory: category, selectedData: null, invalidLocation: false, subStepMapping: [], checkList: [], indeterminate: false }, () => { this.setGenericComponents(); this.fetchList(); });
	};
	toggleClinicalPathwayType = (type) => { this.setState({ clinicalPathwayType: type, selectedData: null, invalidLocation: false, subStepMapping: [], checkList: [], indeterminate: false }, () => { this.setGenericComponents(); this.fetchList(); }); };

	handleValidButtonChange = (e) => {
		this.setState({
			value: e.target.value,
			isValid: !this.state.isValid
		}, () => { this.fetchList();});
	};

	render() {
		// console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", this.props.clinicalPathwayData);
		// console.log(">>>>>>>>>checkListTreatmentId", this.state.checkList.checkListTreatmentId);
		if (this.props.clinicalPathway.fetchAllClinicalPathwaysForCopy === "FULFILLED") {
			//this.state.clinicalPathways = unionWith(this.state.clinicalPathways, this.props.clinicalPathway.clinicalPathway.data, isEqual);
			this.state.clinicalPathways = this.props.clinicalPathway.clinicalPathway.data;
			this.renderOptions();
			this.props.dispatch(clinicalPathwayAction.clearGetAllClpsForCopy());
		}

		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwayProblemListCopy === "FULFILLED") {
			this.state.steps = [];
			this.state.steps = this.props.clinicalPathwayProblems.problemList.mapping;
			this.state.checkList = this.props.clinicalPathwayProblems.problemList.mapping;
			this.props.dispatch(clinicalPathwayProblemAction.clearGetClpProblemListCopyData());
			this.oncheckAllChecked();
			this.setState({ stepLoader: false });
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblems == "FULFILLED") {
			this.props.dispatch(clinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblems());
			if (this.props.clinicalPathwayProblems.problemCopy && this.props.clinicalPathwayProblems.problemCopy.code) {
				this.setState({ code: this.props.clinicalPathwayProblems.problemCopy.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblems == "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblems());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblemsStatus == "FULFILLED") {
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayProblems.problemCopyStatus && this.props.clinicalPathwayProblems.problemCopyStatus.status) {
				if (this.props.clinicalPathwayProblems.problemCopyStatus.status == "PROCESSED") {
					utils.showSuccess(i18Get("Problems copy completed successfully", utils.getLanguage()));
					this.toggleModal();
				} else if (!this.isProblemCopyCompleted(this.props.clinicalPathwayProblems.problemCopyStatus.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}
			this.props.dispatch(clinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblemsStatus());
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblemsStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblemsStatus());
			this.setState({ loading: false });
		}

		return (<div>
			<Steps className="steps_wrapper" progressDot current={this.state.activeStep}>
				<Step className='custom_steps_label' key='source' title={i18Get("Select source & steps", utils.getLanguage())} />
				<Step key='summary' title={i18Get("Summary", utils.getLanguage())} />
			</Steps>
			{this.state.activeStep === 0 && <div className="steps-content padding-15 margin-top-0 copy-content">
				<div className="col-md-12 padding-bottom-10 padding-right-0 padding-left-0">
					<div className="col-md-2 padding-0"><span className="steps_label_special font_size_13px_label padding-top-0 margin-top-5"><I18 tkey='Source' /></span></div>
					<div className="col-md-10 padding-0">
						<div className="btn-group spacer-top-0">
							<button type="button" onClick={() => this.toggleClinicalPathwayCategory("CLP")} className={`width_50 uppercase left-button ${this.state.clinicalPathwayCategory === "CLP" ? "with_border active-btton" : "with_border"}`}>
								<I18 tkey='CLINICAL PATHWAY' />
							</button>
							<span>
								<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("CL")} className={`width_100 uppercase center_btn ${this.state.clinicalPathwayCategory === "CL" ? "with_border active-btton" : "with_border"}`}>
									<I18 tkey='CHECK LIST' />
								</button>
								<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("MDL")} className={`width_100 uppercase right-button  ${this.state.clinicalPathwayCategory === "MDL" ? "with_border active-btton" : "with_border"}`}>
									<I18 tkey='MODEL' />
								</button></span>

							{/* {this.props.clinicalPathwayData.isModel ? <button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory('ALL')} className={`width_150 uppercase right-button ${this.state.clinicalPathwayCategory === 'ALL' ? "with_border active-btton tick_button" : "with_border"}`}>
								<I18 tkey='ALL' />
							</button> : ''} */}

						</div>
						<div className="copy_clp_duration_info">
							<div className="margin-right-10"><strong><I18 tkey="Destination" /></strong></div>
							<div className="margin-right-10"><I18 tkey="Duration Of Hospitalisation" />: <strong>{this.state.clinicalPathway.noOfDays}</strong></div>
							<div> <I18 tkey="Day of Procedure" />: <strong>{this.state.clinicalPathway.procedureDay}</strong></div>
						</div>
					</div>
				</div>

				<div className="col-md-12 padding-bottom-10 padding-right-0 padding-left-0">
					<div className="col-md-2 padding-0"><span className="steps_label_special font_size_13px_label padding-top-0 margin-top-5"><I18 tkey='Stages' /></span></div>
					<div className="col-md-10 padding-0">
						<div className="btn-group button-group">
							{/* {(this.state.clinicalPathwayCategory !== 'CL' || this.props.clinicalPathwayData.isCheckList) ? */}
							<button type="button" onClick={() => this.toggleClinicalPathwayType("DEFINITION")} className={`width_50 uppercase with_border ${this.state.clinicalPathwayType === "DEFINITION" ? "active-btton" : ""}`} >
								<I18 tkey='DEFINITION' />
							</button>
							{/* : ''} */}
							<button type="button" onClick={() => this.toggleClinicalPathwayType("BEFORE_ADMISSION")} className={`width_50 uppercase  with_border ${this.state.clinicalPathwayType === "BEFORE_ADMISSION" ? "active-btton" : ""}`} >
								<I18 tkey='BEFORE ADMISSION' />
							</button>
							<button type="button" onClick={() => this.toggleClinicalPathwayType("CLINICAL_PATHWAY")} className={`width_100 uppercase  with_border ${this.state.clinicalPathwayType === "CLINICAL_PATHWAY" ? " active-btton" : ""}`}  >
								<I18 tkey='HOSPITALISATION' />
							</button>
							<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayType("AFTER_CHECKOUT")} className={`width_100 uppercase  with_border ${this.state.clinicalPathwayType === "AFTER_CHECKOUT" ? " active-btton" : ""}`}>
								<I18 tkey='AFTER CHECKOUT' />
							</button>
							{/* {(this.state.clinicalPathwayCategory !== 'CL' || this.props.clinicalPathwayData.isCheckList) ? */}
							<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayType("ALL_ITEMS")} className={`width_50 uppercase with_border ${this.state.clinicalPathwayType === "ALL_ITEMS" ? "  active-btton " : ""}`}>
								<I18 tkey='ALL' />
							</button>
							{/*: '' } */}
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-2 padding-0"><span className="steps_label_special font_size_13px_label padding-top-0 margin-top-10"><I18 tkey='Select source' /></span></div>
					<div className="col-md-10 padding-0">
						<div className="w_100 di position_relative react_dropdown_scroll">
							<Select
								isSearchable
								className="w100"
								onChange={this.onOptionsChange.bind(this)}
								value={this.state.selectedData}
								options={this.state.options}
								onMenuScrollToBottom={this.onListScroll.bind(this)}
								onInputChange={this.onSearch.bind(this)}
								isLoading={this.state.isListLoading}
								loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
								placeholder={i18Get("Select", utils.getLanguage())}
								// styles={colorStyles}
								components={{ Option }}
								formatOptionLabel={formatOptionLabel}
							/>
							{this.state.clinicalPathwayType !== "BEFORE_ADMISSION" && this.state.clinicalPathwayType !== "AFTER_CHECKOUT" && this.state.clinicalPathwayType !== "DEFINITION" && this.state.clinicalPathwayCategory !== "CL" &&
								< span className='warning_msg position_unset'>
									*<b><I18 tkey={`This List shows the ${this.state.currentTitle} with tasks present in steps having the same`} /></b>:
									<ul className='list_square margin-left-10 margin-top--15'>
										<li className='w_auto padding-right-20 float_left'><I18 tkey="Length of Stay" /> ({this.state.clinicalPathway.noOfDays == 1 ? this.state.clinicalPathway.noOfDays : ` ${i18Get("less then or equal to", utils.getLanguage())} ${this.state.clinicalPathway.noOfDays}`})</li>
										<li className='w_auto padding-right-20 float_left'><I18 tkey="Day of Procedure" /> ({this.state.clinicalPathway.procedureDay ? parseInt(this.state.clinicalPathway.procedureDay) : 1})</li>
									</ul>
								</span>
							}
							{validTab.includes(this.state.clinicalPathwayType) && <div className="float_right_li">
								<Radio.Group
									options={this.state.optionsValid}
									onChange={(e) => {this.handleValidButtonChange(e);}}
									value={this.state.value}
									optionType="button"
									buttonStyle="solid"
								/>
							</div> }
							{this.state.invalidClinicalPathway && <span className='invalid fixed_label margin-top--20'><I18 tkey='Select Clinical Pathway' /></span>}
						</div>
					</div>
				</div>
				{!this.props.clinicalPathwayData.isCheckList && this.state.clinicalPathwayCategory === "CL" && this.state.selectedData && this.state.selectedData.stepSectionType && this.state.clinicalPathwayType !== "DEFINITION" &&
					<StepsComponent
						rangeOption={true}
						label={true}
						stepSectionType={this.state.selectedData.stepSectionType}
						onSubStepMapping={this.onSubStepMapping.bind(this)}
						invalidLocation={this.state.invalidLocation}
						data={this.state.subStepMapping}
						clinicalPathwayType={this.state.clinicalPathwayType}
						className={this.state.clinicalPathwayType === "BEFORE_ADMISSION" || this.state.clinicalPathwayType === "AFTER_CHECKOUT" || this.state.clinicalPathwayCategory === "CL" ? "padding-top-17" : ""} />
				}				
				<div className="component_heading  di w_10 vertical_align_middile"><I18 tkey='Components' /></div>
				<div className={`margin-top-5 position_relative ${this.state.clinicalPathway.isCheckList && this.state.invalidComponents ? "height_15" : ""}`}>
					{this.state.invalidComponents && <span className='invalid fixed_label margin-top--5'><I18 tkey='Select Components' /></span>}
					{!this.state.clinicalPathway.isCheckList && this.state.selectedData && this.state.selectedData.value ?
						<div className="">
							<div>
								<div className="check-box-block margin-left-10">
									<Checkbox
										checked={this.state.checkAll}
										indeterminate={this.state.indeterminate}
										onChange={(e) => this.onChangeCheckAll(e, "ALL")} />
									<span className="margin-left-10"><I18 tkey='ALL' /></span>
								</div>
							</div>
							<div className="check-box-block margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.AUTHORS_AND_ACTIONS}
									onChange={(e) => this.onCheckBoxChange(e, "AUTHORS_AND_ACTIONS")} />
								<span className="margin-left-10"><I18 tkey='AUTHORS AND ACTIONS' /></span>
							</div>
							<div className="check-box-block margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.PATHWAY_DEFINITION}
									onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_DEFINITION")} />
								<span className="margin-left-10"><I18 tkey='PATHWAY DEFINITION' /></span>
							</div>
							<div className="check-box-block margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.PATHWAY_ELIGIBILITY}
									onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_ELIGIBILITY")} />
								<span className="margin-left-10"><I18 tkey='PATHWAY ELIGIBILITY' /></span>
							</div>
							{this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY" ?
								<div className="check-box-block margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.INTERVENTIONS}
										onChange={(e) => this.onCheckBoxChange(e, "INTERVENTIONS")} />
									<span className="margin-left-10"><I18 tkey='INTERVENTIONS' /></span>
								</div> : ""
							}
							{this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY" ?
								<div className="check-box-block margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.ANAESTHESIA}
										onChange={(e) => this.onCheckBoxChange(e, "ANAESTHESIA")} />
									<span className="margin-left-10"><I18 tkey='ANAESTHESIA' /></span>
								</div> : ""
							}
						</div> : !this.state.clinicalPathway.isCheckList ? <div className='bg_settings'><CopyOutlined /></div> : ""
					}
				</div>
				{this.state.clinicalPathwayType !== "DEFINITION" ?
					<div className={`copy_scroll position_relative ${this.state.selectedData && this.state.selectedData.value ? "margin-bottom-20" : ""}`}>
						{this.state.selectedData && this.state.selectedData.value ?
							<div className="margin-top-15 position_relative height_max_315 scroll">
								{this.state.selectedData && this.state.selectedData.value ? this.getSubStepDetails() : ""}
							</div> : this.state.clinicalPathway.isCheckList ? <div className='bg_settings'><CopyOutlined /></div> : ""}
					</div> : ""}
			</div>}
			{this.state.activeStep === 1 && <div> {this.getActiveData()} </div>}
			<div className="steps-action di w_100 text_align_right margin-top-24">
				{this.state.copyLoading &&
					<div className="padding-bottom-10 copy_label">
						<I18 tkey="Copy pathway problem  will take more time. You will not be able to close this popup" />
					</div>}
				{this.state.activeStep === 0 && <Button type="primary" className="" onClick={this.nextClicked.bind(this)}><I18 tkey='Next' /></Button>}
				{this.checklistModel()}
				{/* {this.state.checkList.filter(d => d.modelTreatmentId || d.checkListTreatmentId).length ?
					this.state.clinicalPathwayCategory === "CLP" && this.state.selectedData &&  this.props.clinicalPathwayData.hospitalId !== this.state.selectedData.hospitalId &&
					<div className="information float_left"><I18 tkey="Model and checklist will be ignored while copying"/></div> : ""
				} */}
				{/* {this.state.checkList.filter(d => d.checkListTreatmentId).length ?
					this.state.clinicalPathwayCategory !== "CLP" && this.state.selectedData &&  this.props.clinicalPathwayData.hospitalId !== this.state.selectedData.hospitalId &&
					<div className="information float_left"><I18 tkey="Checklist will be ignored while copying"/></div> : ""
				} */}
				<div className="copy_btn_modal">
					{this.state.activeStep === 1 && <Button type="primary" className="margin-right-10 " onClick={this.prevClicked.bind(this)}><I18 tkey='Prev' /></Button>}
					{this.state.activeStep === 1 && <ActionButton type="primary " className="font-size-14 padding-bottom-5 padding-top-5" onClick={this.doneClicked.bind(this)} value={i18Get("Copy", utils.getLanguage())} />}
				</div>
			</div>
		</div >);
	}

	getActiveData() {
		return (
			<div className='copy-content'>
				<div className="row">
					<div className="col-md-6 padding-left-0 padding-right-5">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-0 padding-bottom-10 margin-top-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey='Source' /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Code' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.selectedData ? this.state.selectedData.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Name' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9"> {this.state.selectedData.label}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6 padding-right-0 padding-left-5">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-0 padding-bottom-10 margin-top-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey='Destination' /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Code' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.clinicalPathway ? this.state.clinicalPathway.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Name' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9"> {this.state.clinicalPathway ? this.state.clinicalPathway.name : ""}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.state.subStepMapping.length > 0 && <div className="col-md-12 padding-0">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-10 padding-bottom-10 margin-top-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey='Location of the intervention' /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-12 col-sm-10 col-xs-12">
											<ul className='list_square'>
												{this.state.subStepMapping.map((data, index) => {
													return <li key={index} className='w_auto padding-right-20 float_left'>{data.label}</li>;
												})
												}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>}
				</div>

				<div className="steps-content padding-15 padding-top-0 margin-top-10">
					<div className="row">
						<div className="col-md-3"><div className="component_heading  di w_100 margin-bottom-0"><I18 tkey='Components' /></div></div>
						<div className="col-md-12 margin-top-0 position_relative height_max_355 scroll">
							{this.state.genericComponents.AUTHORS_AND_ACTIONS ? <div className="col-md-12 margin-bottom-10"><I18 tkey='AUTHORS AND ACTIONS' /></div> : ""}
							{this.state.genericComponents.PATHWAY_DEFINITION ? <div className="col-md-12 margin-bottom-10"><I18 tkey='PATHWAY DEFINITION' /></div> : ""}
							{this.state.genericComponents.PATHWAY_ELIGIBILITY ? <div className="col-md-12 margin-bottom-10"><I18 tkey='PATHWAY ELIGIBILITY' /></div> : ""}
							{this.state.genericComponents.INTERVENTIONS ? <div className="col-md-12 margin-bottom-10"><I18 tkey='INTERVENTIONS' /></div> : ""}
							{this.state.genericComponents.ANAESTHESIA ? <div className="col-md-12 margin-bottom-10"><I18 tkey='ANAESTHESIA' /></div> : ""}
							<div className="col-md-12">
								<div className="">{this.getActiveComponents()}</div>
							</div>
						</div>
					</div>
				</div>
			</div>);
	}

	doneClicked() {
		const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
		const list = checkedDataList.filter(value => value.checked === true);
		const components = [];
		list.map(function (e) {
			if (e.checked) {
				delete e["checked"];
			}
			if (e.additionalInfo) {
				delete e["problem"];
			}
			const additionalInfo = [];
			e.additionalInfo = e.additionalInfo.filter(value => value.checked === true);
			e.additionalInfo.map(function (data) {
				if (data.checked) {
					delete data["checked"];
				}
				if (data.interventionSummaryName) {
					delete data["interventionSummaryName"];
				}
				additionalInfo.push({ additionalInfoId: data.id });
			});
			components.push({ problemId: e.problemId, additionalInfo: additionalInfo });
		});
		this.setState({ copyLoading: true, loading: true });
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			components: components,
			genericComponents: this.state.genericComponents,
			subStepMapping: this.selectedSubStep()
		};
		this.props.dispatch(clinicalPathwayProblemAction.copyFromClinicalPathwayProblems(this.state.clinicalPathway.id, this.state.selectedData.value, payload));
		
	}
}

CopyFromPathWayProblems.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations,
	clinicalPathwayProblems: store.clinicalPathwayProblems
}))(CopyFromPathWayProblems));