import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";

const { Option } = Select;

class QuantityList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			quantityList: [],
			staffQuantityList: []
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.staff) {
			for (let i = 0.1; i < 5; i += 0.1) {
				this.state.staffQuantityList.push(parseFloat(i.toFixed(1)));
			}
		} else {
			for (let i = 0.1; i < 20.1; i += 0.1) {
				this.state.quantityList.push(parseFloat(i.toFixed(1)));
			}
		}
	}

	renderQuantityOptions() {
		if (this.props.staff) {
			return this.state.staffQuantityList.map((quantity, index) => <Option key={index} value={quantity}>{utils.setLocaleForCurrency(quantity)}</Option>);
		}
		return this.state.quantityList.map((quantity, index) => <Option key={index} value={quantity}>{utils.setLocaleForCurrency(quantity)}</Option>);
	}

	render() {
		return (
			<div className="w_100 di position_relative">
				<label><I18 tkey="Quantity" /></label>
				<Select
					allowClear
					showSearch
					value={this.props.quantity}
					className="w_100 di"
					defaultActiveFirstOption={false}
					onChange={this.props.quantityChanged}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					optionFilterProp="children"
					filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				>
					{this.renderQuantityOptions()}
				</Select>
				{this.props.invalidQuantity && <span className="invalid_right fixed_label"><I18 tkey="Select a quantity" /></span>}
			</div>
		);
	}
}

QuantityList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(QuantityList));
