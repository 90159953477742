import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get }  from "../../../../../../../../i18";
import anesthesiaAction from "../../../../../../../../actions/anaesthesia";
import interventionAction from "../../../../../../../../actions/intervention";
import utils from "../../../../../../../../utils/utils";
import AssignAndApprovePopup from "./assign-approve-popup";
import { statusList } from "../../../../../../../../constants";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import "../../../../../../../../plug/reactTable/react-table.css";
import typeActivity from "../../../../../../../../constants/typeActivity.json";

class Anesthesia  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modelVisible: false,
			totalPages: 1,
			list: [],
			sorted: [],
			costDetails: {}
		};
	} 

	UNSAFE_componentWillMount() {
		this.state.data = this.props.data;
		this.onFetchData();
	}
    
	getCols() {
		const tableArray = ([
			// {
			//     Header: '',
			//     id: '',
			//     accessor: d => d,
			//     resizable: true,
			//     sortable: false,
			//     Cell: row => (
			//         <div className="margin_left_auto"> 
			//     {
			//         row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
			//         <a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a> 
			//         : ''
			//      }
			//      </div>
			//     ),
			// },
			{
				Header: "",
				id: "id",
				accessor: d => d,
				width: 40,
				resizable: false,
				sortable: false,
				Cell: row => (
					<div className="margin_left_auto"> 
						{
							row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
								<a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a> 
								: ""
						}
					</div>
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "subTreatmentName",
				accessor: d => d.subTreatmentName,
				resizable: true,
				sortable: false,
				Cell: row => (
					row.value
				)
			},
			{
				Header: i18Get("HOSPITAL", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				sortable: false,
				Cell: row => (
					row.value ? row.value : "Master"
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				sortable: false,
				Cell: row => (
					row.value && row.value === "APPROVED" ? "Approved" : "Waiting For Approval"
				)
			}
		]);
		return tableArray;
	}

	onFetchData() {
		this.props.dispatch(anesthesiaAction.get(this.props.type, ((this.props.typeActivity === typeActivity.RADIOTHERAPY.toUpperCase()) || (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase())) ? this.props.match.params.treatmentId : this.props.match.params.id, this.state.sort, this.state.desc, this.props.status ? this.props.status : "", this.props.hospitalId));
	}

	render() {
		if (this.props.anaesthesia.isElementGetCompleted === "FULFILLED") {
			this.state.list = this.props.anaesthesia.elementsData.subTreatments;
			this.state.loading = false;
			this.props.dispatch(anesthesiaAction.clearGet());
		}
		if (this.props.data !== this.state.data) {
			this.state.data = this.props.data;
			this.onFetchData();
		}

		if (this.props.intervention.intervention && Object.keys(this.props.intervention.intervention).length) {
			this.state.intervention = this.props.intervention.intervention ? this.props.intervention.intervention : {};
			this.state.costDetails = this.state.intervention.costDetails ? this.state.intervention.costDetails : {};
		}
		return (            
			<div>
				<div className="table_heading"><I18 tkey={this.props.type.toUpperCase()}/></div>
				<ReactTable
					data={this.state.list}
					loading={this.state.loading}
					multiSort={false}
					pageSize={this.state.list.length ? this.state.list.length : 5}
					showPagination={false}
					noDataText={i18Get("NO ANAESTHESIA FOUND", utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange = { (newSorted) => {
						this.setState({ sorted: newSorted });
					}}                                       
					sorted={this.state.sorted}
				/>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApprovePopup
							refreshCompleted={this.refreshCompleted}
							elementId={this.state.elementId}
							exType={this.state.type}
							type = {this.state.elementType}
							showAssignAndApprove={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}

	onAssignAndApproveClick (data) {
		return () => {
			this.setState({ modelVisible: true, elementId: data.subTreatmentId, elementType: data.type });
		};
	}

	refreshCompleted = () => {
		const prevState = this.state;
		prevState.modelVisible = false;
		this.onFetchData();
		this.props.dispatch(interventionAction.getOneIntervention(this.props.match.params.id, new Date().getTimezoneOffset()));
	};

}

Anesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	anaesthesia: store.anaesthesia,
	intervention: store.intervention,
	translations: store.translations
}))(Anesthesia));