import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";
import { apiHeadersMultipart } from "../../utils";

const getProfile = () => ({
	type: events.FETCH_USER_PROFILE,
	payload: axios.get(`${Config.apiRootSecure}/user`)
});

const clearProfile = () => ({
	type: events.CLEAR_PROFILE,
	payload: {}
});
const updateProfile = (payload) => ({
	type: events.UPDATE_PROFILE,
	payload: axios.put(`${Config.apiRootSecure}/user`, { ...payload })
});

const clearUpdateProfile = () => ({
	type: events.CLEAR_UPDATE_PROFILE,
	payload: {}
});

const changeProfilePic = (payload, id) => ({
	type: events.UPDATE_PROFILE_PIC,
	payload: axios.post(`${Config.apiRootSecure}/users/image/${id}`, payload, { ...apiHeadersMultipart() })
});

const changePasswordUser = (payload) => ({
	type: events.CHANGE_PASSWORD_PROFILE,
	payload: axios.post(`${Config.apiRootSecure}/user/changePassword`, { ...payload })
});

const clearChangePassword = () => ({
	type: events.CLEAR_CHANGE_PASSWORD_PROFILE,
	payload: {}
});

const getSupportPortalLink = () => ({
	type: events.GET_SUPPORT_PORTAL_LINK,
	payload: axios.get(`${Config.apiRootSecure}/getSupportPortalLink`)
});

const clearGetSupportPortalLink = () => ({
	type: events.CLEAR_GET_SUPPORT_PORTAL_LINK,
	payload: {}
});

export default {
	getProfile,
	clearProfile,
	updateProfile,
	clearUpdateProfile,
	changeProfilePic,
	changePasswordUser,
	clearChangePassword,
	getSupportPortalLink,
	clearGetSupportPortalLink
};
