import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import eventNames from "../../../../../../../actions/events";
import pick from "lodash.pick";
import interventionAction from "../../../../../../../actions/intervention";
import NumericInput from "../../../../../../../plug/numeric-input";
import locations from "../../../../../../../router/locations";
import "./top-section.css";
import StatusDropdown from "./status-dropdown";
import Config from "../../../../../../../MOM-config";
import HospitalSelect from "../../../../main-overview/administration/activities/specialities/selectHospital";

class TopSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervention: {},
			isValueChanged: false,
			isClicked: false,
			selectedDoctor: false,
			invalidOperaiveTime: false,
			invalidPreOperaiveTime: false,
			invalidStatus: false,
			isLoaded: false,
			selectedHospital: "",
			selectedStatus: "All"
		};
	}

	UNSAFE_componentWillMount() {
		this.state.selectedDoctor = utils.checkForParameter("doctorId", this.props.location);
		this.props.onRef(this);
	}

	toggleIsLoaded() {
		const prevState = this.state;
		prevState.isLoaded = false;
		this.setState(prevState);
	}

	onOptionsChange(e) {
		let doctorName = utils.sameDropper();
		if (e) {
			this.props.doctors.doctorList.doctors.map(data => {
				if (data.id === e) {
					doctorName = data.fullName;
				}
			});
		}
		const params = { doctorId: e };
		utils.setQueryParams(this.props.history, this.props.location, params);
		this.props.changeDoctor(e, doctorName);
		this.setState({ selectedDoctor: e });
	}

	onStatusChange(e) {
		this.state.selectedStatus = e;
		this.state.invalidStatus = false;
		this.props.statusChanged(e);
		this.setState(this.state);
	}

	validate() {
		const prevState = this.state;
		try {
			if (!(parseInt(this.state.intervention.preOperativeTime) >= 0)) {
				prevState.invalidPreOperaiveTime = true;
			}
		} catch (e) {
			prevState.invalidPreOperaiveTime = true;
		}
		try {
			if (!(parseInt(this.state.intervention.operativeTime) >= 0)) {
				prevState.invalidOperaiveTime = true;
			}
		} catch (e) {
			prevState.invalidOperaiveTime = true;
		}
		// if(!this.state.intervention.status) {
		//     prevState.invalidStatus = true;
		// }
		this.setState(prevState);
		if (this.state.invalidOperaiveTime || this.state.invalidPreOperaiveTime || this.state.invalidStatus) {
			return false;
		}
		return true;
	}

	onSave() {
		if (this.state.intervention && this.validate()) {
			this.state.isClicked = true;
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const props = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization
			};
			const payload = pick(this.state.intervention, ["status", "description"]);
			payload.preOperativeTime = utils.calculateFloatValue(this.state.intervention.preOperativeTime);
			payload.operativeTime = utils.calculateFloatValue(this.state.intervention.operativeTime);
			this.props.dispatch(interventionAction.updateIntervention(payload, props));
		}
	}

	getNumericValue(label, value) {
		const prevState = this.state;
		prevState.intervention[label] = value;
		switch (label) {
			case "operativeTime": prevState.invalidOperaiveTime = false;
				break;
			case "preOperativeTime": prevState.invalidPreOperaiveTime = false;
				break;
			default: break;
		}
		this.setState(prevState);
	}

	moveToDuree = () => {
		window.open(`${Config.siteUrl}/intervention/local/${this.props.match.params.id}/detailDoctor?duree=intervention`, "_blank");
	};

	render() {
		if (Object.keys(this.props.interventionData) && !this.state.isLoaded) {
			this.state.intervention = this.props.interventionData;
			this.state.status = utils.toPascalCase(this.props.interventionData.status);
			this.state.isLoaded = true;
		}

		if (this.props.intervention.updateIntervention === "FULFILLED" && this.state.isClicked) {
			this.state.isClicked = false;
			this.props.dispatch(interventionAction.clearUpdateIntervention());
			this.props.reloadDetails();
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="treatment_details di w_100">
				<div className="row">
					<div className="col-md-12 col-sm-12">
						<div className="col-md-6 row d_flex align_items_base margin-top-18">
							<div className='col-md-6' >
								{/* <DoctorDropdown
                                    onRef={ref => (this.doctor = ref)}
                                    intervention={this.state.intervention}
                                    selectedDoctor={this.state.selectedDoctor}
                                    setDoctor={this.props.setDoctor}
                                    onOptionsChange={this.onOptionsChange.bind(this)}/>   */}
								<label>
									<I18 tkey="Hospital" />
								</label>
								< HospitalSelect
									data={this.props.selectedHospital}
									hospitalChanged={this.props.hospitalChanged.bind(this)}
								/>
							</div>
							<div className='col-md-6' >
								<StatusDropdown
									status={this.state.selectedStatus}
									invalidStatus={this.state.invalidStatus}
									onStatusChange={this.onStatusChange.bind(this)}
								/>
							</div>
						</div>
						{(locations.scannedTreatments !== `/${this.props.match.params.sheets}`) ?
							<div className="col-md-6 col-sm-6">
								<div className='row margin-top-18' >
									<div className='col-md-4 col-sm-6' >
										<div className="w_100 di margin-bottom-18 position_relative">
											<label className="common_label"><I18 tkey='Operative time' /></label>
											<NumericInput value={this.state.intervention.operativeTime} maxLength={5} label="operativeTime" getNumericValue={this.getNumericValue.bind(this)} integerOnly={true} />
											{this.state.invalidOperaiveTime && <span className='invalid fixed_label'><I18 tkey="Enter a valid time" /></span>}
										</div>
									</div>
									<div className='col-md-4 col-sm-6' >
										<div className="w_100 di margin-bottom-18 position_relative">
											<label className="common_label"><I18 tkey='PreOperative time' /></label>
											<NumericInput value={this.state.intervention.preOperativeTime} maxLength={5} label="preOperativeTime" getNumericValue={this.getNumericValue.bind(this)} integerOnly={true} />
											{this.state.invalidPreOperaiveTime && <span className='invalid fixed_label'><I18 tkey="Enter a valid time" /></span>}
										</div>
									</div>
									<div className='col-md-4 col-sm-6'>
										<div className="text_align_right di w_100 ">
											<ActionButton className="common_button mt-25" onClick={this.onSave.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
										</div>
									</div>
									<div className="col-sm-12" >
										{/* {(`${this.props.match.params.organization}`=== 'local') ?  
                                    <React.Fragment>
                                        <div className="col-sm-4">
                                            <a className="common_label" onClick={this.moveToDuree} ><I18 tkey='Quantity' />:&nbsp; {this.state.intervention && this.state.intervention.dureeValues ? this.state.intervention.dureeValues.quantity : ''} </a>
                                        </div>
                                        <div className="col-sm-4">
                                            <a className="common_label" onClick={this.moveToDuree}><I18 tkey='Average R' />:&nbsp; {this.state.intervention && this.state.intervention.dureeValues ? this.state.intervention.dureeValues.Average: ''} </a>
                                        </div>
                                        <div className="col-sm-4">
                                            <a className="common_label" onClick={this.moveToDuree}><I18 tkey='Mediane R' />:&nbsp; {this.state.intervention && this.state.intervention.dureeValues ? this.state.intervention.dureeValues.median : ''} </a>
                                        </div>
                                    </React.Fragment>
                                    
                                    
                                    : ''
                                } */}
									</div>
								</div>
							</div> : ""}
					</div>
					{/*(locations.scannedTreatments !== `/${this.props.match.params.sheets}`) ? 
                    <div className="col-md-12">
                        <div className="text_align_right di w_100 margin-bottom-40">
                            <ActionButton className="common_button" onClick={this.onSave.bind(this)} value={i18Get('SAVE', utils.getLanguage())} />
                        </div>
                            </div> : ''*/}
				</div>
			</div>
		);
	}
}

TopSection.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	doctors: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	intervention: store.intervention,
	translations: store.translations,
	doctors: store.doctors
}))(TopSection));