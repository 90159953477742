import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
// import AnaesthesiaCreateLocal from './anaesthesia-local-create';
import AnaesthesiaCreateMom from "./anaesthesia-mom-create";

class CreateAnaesthesia extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={locations.anaesthesia_mom_create} component={AnaesthesiaCreateMom} />
				{/* <Route exact path={locations.anaesthesia_local_create} component={AnaesthesiaCreateLocal} /> */}
			</Switch>

		);
	}
}

CreateAnaesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateAnaesthesia));
