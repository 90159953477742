import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const validateResetToken = (payload) => ({
	type: events.VALIDATE_RESET_TOKEN,
	payload: axios.post(`${Config.apiRoot}/validateResetToken/`, { ...payload })
});

const clearValidateResetToken = () => ({
	type: events.CLEAR_VALIDATE_RESET_TOKEN,
	payload: {}
});

const resetPassword = (payload) => ({
	type: events.RESET_PASSWORD,
	payload: axios.post(`${Config.apiRoot}/resetPassword/`, { ...payload })
});

const clearResetPassword = () => ({
	type: events.CLEAR_RESET_PASSWORD,
	payload: {}
});

export default {
	validateResetToken,
	clearValidateResetToken,
	resetPassword,
	clearResetPassword
};
