import store from "../store";

export default function isPermissionGranted(isReadOnly, method) {
	const state = store.getState();
	if (state.permissions.userPermission && state.permissions.userPermission.CLINICAL_PATHWAY[method]) {
		if (!isReadOnly) {
			return true;
		}
	}
	return false;
}
