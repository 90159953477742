import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../i18";
import Utils from "../../../../../utils/utils";
import locations from "../../../../../router/locations";
import events from "../../../../../actions/events";

class CardLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			triggerOnce: true
		};
	}

	moveToPage(link) {
		if (!link) {
			Utils.showInfo(i18Get("Coming Soon...", Utils.getLanguage()));
			return;
		}
		// if(this.props.position) {
		//     this.props.dispatch({ type: events.SIDE_BAR_POSITION, payload: { position: this.props.position } });
		// }
		this.props.history.push(link);
	}

	getName(data) {
		if (data.link && data.suffix) {
			return <span><I18 tkey="DAY" /> <I18 tkey={data.name}/></span>;
		} else {
			return <I18 tkey={data.name}/>;
		}
	}
	setScrollPosition = () => {
		this.props.dispatch({ type: events.SIDE_BAR_POSITION, payload: { position: this.props.position } });
	};

	render() {
		const data = this.props.data;
		const pathName = this.props.location.pathname;
		if (!Array.isArray(data) && data.link.startsWith(this.props.location.pathname) && this.props.position) {
			this.props.dispatch({ type: events.SIDE_BAR_POSITION, payload: { position: this.props.position } });
		}
		if (Array.isArray(data) && this.props.position) {
			data.map((link) => { 
				if (link.link.startsWith(pathName)) {
					this.setScrollPosition();
				}
			});
		}
		this.state.triggerOnce = true;
		let classVal = "first_a ";
		if (!Array.isArray(data)) {
			const realUrl = data.link ? data.link.split("?")[0] : "";
			if (data.link && this.props.location.pathname != locations.administration 
                    && (this.props.location.pathname.startsWith(realUrl) || data.link.startsWith(this.props.location.pathname))) {
				classVal += "active";
			}
            
			return (
				<div className="di w_100 inner_a">
					{data.static && <a className="administration_headings"><I18 tkey={data.static} /></a>}
					<a className={classVal} onClick={this.moveToPage.bind(this, ((data.tag && localStorage.getItem(data.tag)) ? (localStorage.getItem(data.tag)) : data.link))}><i className="fa fa-arrow-right margin-right-10" aria-hidden="true"></i><I18 tkey={data.name} /></a>
				</div>);
		}
		return (
			<div className="di w_100 inner_a">
				{data.static && <a className="administration_headings"><I18 tkey={data.static} /></a>}
				{
					data.map(function(link, index) {

						if (link.permission && this.props.permissions.userPermission && !this.props.permissions.userPermission[link.permission]["READ"]) {

							return false;
						}

						classVal = "second_a ";
						if (link.link && !this.props.match.params.extType && (this.props.location.pathname === link.link || this.props.location.pathname.startsWith(link.link + "/"))) {
							classVal += "active";
						}
						if (link.link.indexOf(this.props.match.params.extType) > -1 && (this.props.location.pathname === link.link || this.props.location.pathname.startsWith(link.link + "/"))) {
							classVal += "active"; 
						}
						if (classVal !== "second_a active" && (this.props.location.search && this.props.location.pathname + this.props.location.search === link.link || this.props.location.search && link.link.lastIndexOf(this.props.location.search) > -1)) {
							classVal += "active";
						}
						
						return <a key={index} className={classVal} onClick={this.moveToPage.bind(this, (link.tag && localStorage.getItem(link.tag)) ? localStorage.getItem(link.tag) : link.link)}><i className="fa fa-arrow-right margin-right-10" aria-hidden="true"></i>{this.getName(link)}</a>;
					}.bind(this))
				}
			</div>);
	}
}

CardLink.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	permissions: store.permissions
}))(CardLink));        