import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../utils/utils";
import { i18Get } from "../../i18";

class NameSearchInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		};
	}

	componentWillUnmount() {
		this.state.value = "";
		if (this.props.isDebounce) {
			this.props.inputChanged(this.state.value, true);
		}
		clearTimeout(this.searchTimer);
	}

	handleChange(e) {
		if (this.props.extended ? !utils.regexInvalidStringContent.test(e.target.value) : this.props.isDragSearch ? utils.regexSearch.test(e.target.value) : utils.regexName.test(e.target.value)) {
			const prevState = this.state;
			prevState.value = e.target.value ? e.target.value : "";
			this.setState(prevState);
			if (this.props.isDebounce) {
				clearTimeout(this.searchTimer);
				this.searchTimer = setTimeout(() => {
					this.props.inputChanged(encodeURIComponent(this.state.value ? this.state.value.trim() : ""));
				}, 750);
			} else {
				this.props.inputChanged((this.state.value ? this.state.value : ""), this.props.meta);
			}
		}
	}

	render() {
		if (!this.props.isDebounce) {
			this.state.value = this.props.value ? this.props.value : "";
		}

		return (
			<div className={this.props.divClassName}>
				<input
					className={this.props.className ? this.props.className : (this.props.isDebounce ? "form-control searchbox float_right" : "form-control w_100")}
					value={this.state.value}
					maxLength={this.props.maxLength ? this.props.maxLength : 50}
					placeholder={this.props.isDebounce ? i18Get("Search ...", utils.getLanguage()) : ""}
					onChange={this.handleChange.bind(this)}
					disabled={!!this.props.disabled}
				/>
			</div>
		);
	}

	componentDidMount() {
		this.setState({ value: this.props.value });
	}

	componentDidUpdate() {

	}
}

NameSearchInput.propTypes = {
	dispatch: PropTypes.func.isRequired,
	translations: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(NameSearchInput));
