import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	elementClassCreate: false,
	deleteElementClassComplete: false,
	getAllInterventionForElementComplete: false,
	interventionList: [],
	filterChangedElementClass: null,
	ignorePageChangeElementClass: false,
	fetchAllElementClassMeta: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.CREATE_ELEMENT_CLASS}_FULFILLED`:
			return {
				...state,
				elementClass: action.payload.data.data,
				elementClassCreate: "FULFILLED"
			};
		case `${eventNames.CREATE_ELEMENT_CLASS}_REJECTED`:
			return {
				...state,
				elementClassCreate: "REJECTED"
			};
		case `${eventNames.CLEAR_CREATE_ELEMENT_CLASS}`:
			return {
				...state,
				elementClassCreate: false
			};

		case `${eventNames.ELEMENT_CLASS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllElementClassCompleted: "REJECTED",
				fetchAllElementClassMeta: null
			};

		case `${eventNames.ELEMENT_CLASS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					count: action.payload.data.data.count,
					elementClass: action.payload.data.data.data,
					isFetchAllElementClassCompleted: "FULFILLED",
					fetchAllElementClassMeta: action.meta
				};
			}
			return {
				...state,
				elementClass: [],
				isFetchAllElementClassCompleted: "FULFILLED",
				fetchAllElementClassMeta: null
			};
		case eventNames.CLEAR_ELEMENT_CLASS_FETCH:
			return {
				...state,
				isFetchAllElementClassCompleted: false,
				fetchAllElementClassMeta: null
			};
		case `${eventNames.DELETE_ELEMENT_CLASS}_REJECTED`:
			return {
				...state,
				deleteElementClassComplete: "REJECTED"
			};

		case `${eventNames.DELETE_ELEMENT_CLASS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteElementClassComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteElementClassComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_ELEMENT_CLASS:
			return {
				...state,
				deleteElementClassComplete: false
			};
		case `${eventNames.GET_INTERVENTION_LIST_FOR_ELEMENT}_REJECTED`:
			return {
				...state,
				getAllInterventionForElementComplete: "REJECTED"
			};

		case `${eventNames.GET_INTERVENTION_LIST_FOR_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					interventionList: action.payload.data.data,
					getAllInterventionForElementComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getAllInterventionForElementComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_INTERVENTION_LIST_FOR_ELEMENT:
			return {
				...state,
				getAllInterventionForElementComplete: false
			};
		case eventNames.FILTER_CHANGE_ELEMENT_CLASS:
			return {
				...state,
				filterChangedElementClass: action.payload.filter,
				ignorePageChangeElementClass: action.payload.ignorePageChange
			};

		case `${eventNames.ASSIGN_ELEMENT_CLASS_LIST}_FULFILLED`:
			return {
				...state,
				getAssignElementClassStatus: "FULFILLED",
				assignElementClass: action.payload.data.data
			};
		case `${eventNames.ASSIGN_ELEMENT_CLASS_LIST}_REJECTED`:
			return { ...state, getAssignElementClassStatus: "REJECTED" };
		case eventNames.ASSIGN_ELEMENT_CLASS_LIST_COMPLETED:
			return { ...state, getAssignElementClassStatus: false };

		case `${eventNames.ASSIGN_ELEMENT_CLASS}_FULFILLED`:
			return { ...state, assignElementClassStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_ELEMENT_CLASS}_REJECTED`:
			return { ...state, assignElementClassStatus: "REJECTED" };
		case eventNames.ASSIGN_ELEMENT_CLASS_COMPLETED:
			return { ...state, assignElementClassStatus: false };

		case `${eventNames.CONSUMABLE_CLASS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllConsumableClassCompleted: "REJECTED",
				fetchAllElementClassMeta: null
			};

		case `${eventNames.CONSUMABLE_CLASS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					count: action.payload.data.data.count,
					consumableClass: action.payload.data.data.data,
					isFetchAllConsumableClassCompleted: "FULFILLED",
					fetchAllElementClassMeta: action.meta
				};
			}
			return {
				...state,
				elementClass: [],
				isFetchAllConsumableClassCompleted: "FULFILLED",
				fetchAllElementClassMeta: null
			};
		case eventNames.CLEAR_CONSUMABLE_CLASS_FETCH:
			return {
				...state,
				isFetchAllConsumableClassCompleted: false,
				fetchAllElementClassMeta: null
			};
		default:
			return state;
	}
}
