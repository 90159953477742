import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
//import ClinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";
import categoryPersonnelActions from "../../../../../../../actions/category-personnel";


class ProblemIntervenantsDropDown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: [],
			offset: 0,
			numResults: 1000,
			isLoading: true,
			search: null,
			//problemTypeList: [],
			problemIntervenantsList: [],
			stepSectionType: "ALL_ITEMS"
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchProblemIntervenantsList();
	}

	onListScroll() {
		const prevState = this.state;
		prevState.offset += prevState.numResults;
		this.setState({ prevState }, () => {
			this.fetchProblemIntervenantsList();
		});
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchProblemIntervenantsList(search);
			}, 800);
		}
	}

	fetchProblemIntervenantsList() {
		this.setState({ isLoading: true });
		//this.state.meta = Date.now();
		let queryString = `offset=${this.state.offset}&numResults=${this.state.numResults}&sort=name&desc=0`;
		queryString += "&status=APPROVED";
		queryString += this.state.search ? `&name=${this.state.search}` : "";
		this.props.dispatch(categoryPersonnelActions.fetchAllApprovedCategoryPersonnel(queryString));
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (!this.props.isCreate) {
			prevState.options.push({ value: null, label: i18Get("All Intervenants", utils.getLanguage()) });
		}
		this.state.problemIntervenantsList.map((data) => {
			prevState.options.push({ value: data.id, label: data.name, code: data.code });
		});
		prevState.isLoading = false;
		this.setState(prevState);
	}

	checkProblemIntervenants() {
		if (localStorage.getItem("CLP_PROBLEM_INTERVENANTS")) {
			const problemIntervenants = JSON.parse(localStorage.getItem("CLP_PROBLEM_INTERVENANTS"));
			if (!problemIntervenants) {
				return "";
			}
			if (this.state.problemIntervenantsList && problemIntervenants) {
				const index = this.state.problemIntervenantsList.findIndex((e) => e.id == problemIntervenants.value);
				if (index !== -1 || problemIntervenants.label === "All Types") {
					return this.props.onChange(problemIntervenants);
				}
				this.props.onChange(false);
			}
		}
	}

	render() {
		if (this.props.categoryPersonnel.isFetchAllApprovedCategoryPersonnelCompleted === "FULFILLED") {
			this.state.problemIntervenantsList = this.props.categoryPersonnel.categoryPersonnelList.data;
			this.renderOptions();
			this.checkProblemIntervenants();
			this.setState({ isLoading: false });
			this.props.dispatch(categoryPersonnelActions.clearFetchAllApprovedCategoryPersonnel());
		}
		if (this.props.categoryPersonnel.isFetchAllApprovedCategoryPersonnelCompleted === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(categoryPersonnelActions.clearFetchAllApprovedCategoryPersonnel());
		}
		// if (this.props.stepSectionType !== this.state.stepSectionType) {
		// 	this.fetchProblemTypeList();
		// 	this.setState({ stepSectionType: this.props.stepSectionType });
		// }
		
		return (
			<Select
				className={`w_100 di select_prbl_section ${this.props.className ? this.props.className : ""}`}
				value={this.props.data ? this.props.data : null}
				onChange={this.props.onChange}
				options={this.state.options}
				maxMenuHeight={220}
				isClearable
				onInputChange={this.onSearch.bind(this)}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Intervenants", utils.getLanguage())}
				isMulti={!!this.props.isMulti}
			/>
		);
	}
}

ProblemIntervenantsDropDown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	//clinicalPathwayProblems: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	//clinicalPathwayProblems: store.clinicalPathwayProblems,
	categoryPersonnel: store.categoryPersonnel,
	actions: store.actions
}))(ProblemIntervenantsDropDown));
