import React, { Component } from "react";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import RecordPopover from "./record-popover";
import { Resizable } from "react-resizable";
import Parser from "html-react-parser";
import { Table, Tooltip, Checkbox } from "antd";
import { DownCircleOutlined, RightCircleOutlined, EditOutlined, DeleteOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import isPermissionGranted from "../../../../../../../../utils/permission";

const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;
	if (!width) {
		return <th {...restProps} />;
	}
	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class ItemsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemWidth: 200,
			responsibleWidth: 200,
			problemWidth: 100,
			descriptionWidth: 100,
			referenceWidth: 100,
			riskWidth: 100,
			assessmentWidth: 100,
			healthProblemWidth: 100,
			codeWidth: 100
		};
	}

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.isReadOnly, method);
	};

	convertString(input) {
		return input.split("<a").join("<a target=\"_blank\"");
	}

	getText = (value, data, level, isItemOnly, itemOrder) => {
		return (
			<div>
				{value && level && isItemOnly ?
					<div>
						<span className={`${data && data.isCheckList ? "check_list" : ""} item_level${level}`}>{value} {itemOrder && itemOrder !== "0" ? " (" + itemOrder + ")" : ""}{itemOrder && itemOrder !== "0" ? " (" + itemOrder + ")" : ""}</span>
					</div>
					:
					data && data.additionalInfoId !== "undefined" && data.additionalInfoId && value == data.id ?
						<span className={data && data.isCheckList ? "check_list" : ""}>
							{data.clpItemId}
						</span>
						:
						<span className={data && data.isCheckList ? data.isHeading ? "check_list check_list_bold font_size_14px" : "check_list" : ""}> {value && data.isHeading !== true ? Parser(this.convertString(value)) : value}{itemOrder && itemOrder !== "0" ? " (" + itemOrder + ")" : ""}</span>
				}
			</div>
		);
	};

	renderStyledCell = (text, bgName, bgColor, fileCount, fileType, data, level, isItemOnly, itemOrder) => ({
		props: {
			style: { background: bgColor ? bgColor : "" }
		},
		children: <Tooltip placement="top" title={i18Get(bgName ? bgName : "", utils.getLanguage())}>
			<div className="d-flex">
				<div className="">
					{text ? this.getText(text, data, level, isItemOnly, itemOrder) : ""}
				</div>
				<div>
					{fileCount ? <a onClick={() => { this.props.onAttachmentClick(data, fileType); }} ><img className="margin-left-5" src="/images/attach.png" /></a> : ""}
				</div>
			</div>
		</Tooltip>
	});

	getColumns = (subStep, step) => [
		{
			title: "",
			dataIndex: "sort",
			className: this.props.bulkDeleteStepId === subStep.id ? "drag-visible padding-left-5 icon_td_width_60" : "drag-visible padding-left-5 icon_td_width",
			render: (text, record, index) => (
				<div className="d-flex icon_div_width">
					{this.isPermissionGranted("DELETE") && this.props.bulkDeleteStepId && this.props.bulkDeleteStepId === subStep.id && this.props.day === subStep.day && !record.isCheckList && !record.modelTreatmentId ?
						<div className="margin-left-7 margin-top-2">
							<Checkbox
								checked={this.props.checkList.indexOf(record.id) !== -1}
								onChange={(e) => this.props.onCellCheckBoxChange(e, record)} />
						</div> : ""}
					{!record.modelTreatmentId && this.isPermissionGranted("EDIT") && this.props.bulkDeleteStepId != subStep.id && this.props.editId !== record.id && !record.isCheckList ?
						<div className="margin-right-7 margin-top-2">
							<EditOutlined className="edit_color " type="edit" onClick={this.props.editClicked(record)} />
						</div> : ""}
					{this.isPermissionGranted("DELETE") && this.props.bulkDeleteStepId != subStep.id && this.props.editId !== record.id && !record.isCheckList && !record.modelTreatmentId ?
						<div className="margin-right-7 margin-top-2">
							<DeleteOutlined className="edit_color " type="delete" onClick={this.props.deleteClicked(record, step.id)} />
						</div> : ""}

					{!record.modelTreatmentId && this.isPermissionGranted("EDIT") && this.props.bulkDeleteStepId != subStep.id && !record.isCheckList ?
						<div>
							{this.props.editId !== record.id && this.isPermissionGranted("EDIT") ? (
								<div className="margin-right-7 margin-top-2">
									<a className="edit_color " onClick={this.props.quickEditClicked(record)}><img src="/images/Quick_edit.png" className="quick_edit_icon" /> </a>
								</div>
							) : (
								<div className="d-flex ">
									<div className="margin-right-7 margin-top-2">
										<a onClick={this.props.saveChanges(record)} disabled={this.props.disableSave}>
											<SaveOutlined className="edit_color " type="save" />
										</a>
									</div>
									<div className="margin-right-7 margin-top-2">
										<CloseOutlined  className=" delete_color" type="close" onClick={this.props.revertEdit} />
									</div>
								</div>
							)}
						</div> : ""}
					{/* {this.props.bulkDeleteStepId != subStep.id && this.props.editId !== record.id && !record.isCheckList ?
                        <div className="margin-right-7">
                            <a className="" onClick={this.duplicateClicked(record, step.id).bind(this)}><img src="/images/duplicated.png" /> </a>
                        </div> : ''} */}
					<div className=" margin-top-2">
						<RecordPopover key={index} record={record} />
					</div>
					{record.isCheckList && record.isExpand !== true ? record.isHeading == true && !this.props.expandList.includes(record.id) ? <div className="margin-right-7 svg_action_icon"><RightCircleOutlined style={{ color: "#2016b8" }} onClick={() => this.props.expandClicked(subStep, record)} /></div> : <div className="margin-right-7 svg_action_icon"> <DownCircleOutlined style={{ color: "#2016b8" }} onClick={() => this.props.collapseClicked(subStep, record)} /></div> : ""}
				</div>
			)
		},
		{
			title: i18Get("CODE", utils.getLanguage()),
			dataIndex: "clpItemId",
			className: this.props.selectedColumn && this.props.selectedColumn.CODE ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.CODE ? false : true,
			width: this.state.codeWidth,
			render: (text, record) => this.renderStyledCell((record.isHeading == true ? record.checkListTreatmentId : record.clpItemId), null, null, null, "", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("codeWidth")
			})
		},
		{
			title: i18Get("ITEM", utils.getLanguage()),
			dataIndex: "item",
			className: "drag-visible",
			width: this.state.itemWidth,
			render: (text, record) =>
				this.props.editId === record.id
					? this.props.renderItemInput(this.props.selectedFields && this.props.selectedFields.item ? this.props.selectedFields.item : "")
					: this.renderStyledCell(record.item, record.itemBgName, record.itemBgColor, null, "", record, record.level, record.isItemOnly, record.itemOrder),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("itemWidth")
			})
		},
		{
			title: i18Get("INTERVENANT", utils.getLanguage()),
			dataIndex: "categoryPersonnelName",
			className: this.props.selectedColumn && this.props.selectedColumn.INTERVENANT ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.INTERVENANT ? false : true,
			width: this.state.responsibleWidth,
			render: (text, record) => this.renderStyledCell(record.categoryPersonnelName, record.responsibleBgName, record.responsibleBgColor, null, "", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("responsibleWidth")
			})
		},
		{
			title: i18Get("PROBLEM", utils.getLanguage()),
			dataIndex: "problem",
			className: this.props.selectedColumn && this.props.selectedColumn.PROBLEM ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.PROBLEM ? false : true,
			width: this.state.problemWidth,
			render: (text, record) => this.renderStyledCell(record.problem, null, null, null, "", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("problemWidth")
			})
		},
		{
			title: i18Get("DELIVERABLE", utils.getLanguage()),
			dataIndex: "description",
			className: this.props.selectedColumn && this.props.selectedColumn.DELIVERABLE ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.DELIVERABLE ? false : true,
			width: this.state.descriptionWidth,
			render: (text, record) => this.renderStyledCell(record.description, record.descriptionBgName, record.descriptionBgColor, record.descriptionFileCount, "DELIVERABLE", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("descriptionWidth")
			})
		},
		{
			title: i18Get("REFERENCE", utils.getLanguage()),
			dataIndex: "reference",
			className: this.props.selectedColumn && this.props.selectedColumn.REFERENCE ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.REFERENCE ? false : true,
			width: this.state.referenceWidth,
			render: (text, record) => this.renderStyledCell(record.reference, record.referenceBgName, record.referenceBgColor, record.referenceFileCount, "REFERENCE", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("referenceWidth")
			})
		},
		{
			title: i18Get("RISK", utils.getLanguage()),
			dataIndex: "risk",
			className: this.props.selectedColumn && this.props.selectedColumn.RISK ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.RISK ? false : true,
			width: this.state.riskWidth,
			render: (text, record) => this.renderStyledCell(record.risk, record.riskBgName, record.riskBgColor, record.riskFileCount, "RISK", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("riskWidth")
			})
		},
		{
			title: i18Get("ASSESSMENT", utils.getLanguage()),
			dataIndex: "assessment",
			className: this.props.selectedColumn && this.props.selectedColumn.ASSESSMENT ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.ASSESSMENT ? false : true,
			width: this.state.assessmentWidth,
			render: (text, record) => this.renderStyledCell(record.assessment, record.assessmentBgName, record.assessmentBgColor, record.assessmentFileCount, "ASSESSMENT", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("assessmentWidth")
			})
		},
		{
			title: i18Get("DATA", utils.getLanguage()),
			dataIndex: "clpHealthProblemData",
			className: this.props.selectedColumn && this.props.selectedColumn.DATA ? "drag-visible" : "hide",
			hidden: this.props.selectedColumn && this.props.selectedColumn.DATA ? false : true,
			width: this.state.healthProblemWidth,
			render: (text, record) => this.renderStyledCell(record.clpHealthProblemData, "", "", "", "", record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("healthProblemWidth")
			})
		}
	].filter(item => !item.hidden);


	handleResize = label => (e, { size }) => {
		switch (label) {
			case "codeWidth":
				this.setState({ codeWidth: size.width });
				break;
			case "itemWidth":
				this.setState({ itemWidth: size.width });
				break;
			case "responsibleWidth":
				this.setState({ responsibleWidth: size.width });
				break;
			case "problemWidth":
				this.setState({ problemWidth: size.width });
				break;
			case "descriptionWidth":
				this.setState({ descriptionWidth: size.width });
				break;
			case "referenceWidth":
				this.setState({ referenceWidth: size.width });
				break;
			case "riskWidth":
				this.setState({ riskWidth: size.width });
				break;
			case "assessmentWidth":
				this.setState({ assessmentWidth: size.width });
				break;
			case "healthProblemWidth":
				this.setState({ healthProblemWidth: size.width });
				break;
			default:
				break;
		}
	};

	render() {
		return (
			<Table
				pagination={false}
				dataSource={this.props.subStep.data ? this.props.subStep.data : ""}
				columns={this.getColumns(this.props.subStep, this.props.step)}
				bordered
				rowKey="id"
				rowClassName={(record) => { return (record.modelTreatmentId ? "model_attached_tr" : ""); }}
				key={this.props.index}
				components={{
					header: {
						cell: ResizableTitle
					}
				}}
			/>
		);
	}
}

ItemsList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	permissions: store.permissions
}))(ItemsList));
