import React, { Component } from "react";
import { Radio } from "antd";
import I18 from "../../../../../../../../i18";

export default class Search extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="pathway_task">
				<Radio.Group value={this.props.searchType} onChange={this.props.searchTypeChanged}>
					<Radio className="parcourse_checkbox" value="EQUALS">
						<I18 tkey="EQUALS" />
					</Radio>
					<Radio className="parcourse_checkbox" value="CONTAINS">
						<I18 tkey="CONTAINS" />
					</Radio>
				</Radio.Group>
			</div>
		);
	}
}
