import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";

class NumericInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			invalid: false,
			isLoaded: false
		};
		this.inputRef = React.createRef();
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.state.value = this.convertValue();
		this.state.lang = utils.getLanguage();
	}

	convertValue() {
		if (utils.getLanguage() === utils.engCode) {
			return this.props.value ? utils.calculateFloatValue(this.props.value, utils.frCode) : this.props.value;
		}
		if (utils.getLanguage() === utils.frCode) {
			return this.props.value ? utils.setLocaleForCurrency(parseFloat(this.props.value), utils.sameDropper(), this.props.integerOnly) : this.props.value;
		}
	}

	onChange(e) {
		if (utils.numericRegex().test(e.target.value) || !e.target.value) {
			this.setState({ value: e.target.value, invalid: false });
		}
	}

	getValue() {
		return this.state.value;
	}

	validate() {
		if (!this.state.value && (this.state.value !== 0)) {
			this.setState({ invalid: true });
			return false;
		}
		return true;
	}
	numberBlur = () => {
		if (this.props.onBlur) {
			this.props.onBlur(this.state.value);
		}
	};

	setFocus = () => {
        
		this.inputRef.current.focus();
	};


	render() {
		if (this.state.lang !== utils.getLanguage()) {
			this.state.lang = utils.getLanguage();
			if (utils.getLanguage() === utils.engCode) {
				this.state.value = this.state.value ? utils.calculateFloatValue(this.state.value, utils.frCode) : this.state.value;
			}
			if (utils.getLanguage() === utils.frCode) {
				this.state.value = this.state.value ? utils.setLocaleForCurrency(parseFloat(this.state.value), utils.sameDropper(), this.props.integerOnly) : this.state.value;
			}
		}

      
		return (
			<div>
				<input 
					{...this.props}
					className={`${this.props.className} form-control w_100`}
					value={this.state.value} 
					onBlur = {this.numberBlur}
					ref={this.inputRef}
					onChange={this.onChange.bind(this)} />
			</div>
		);
	}

	componentDidMount() {
		this.setFocus();
	}
 
}

NumericInput.propTypes = {
	dispatch: PropTypes.func.isRequired,
	translations: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations
}))(NumericInput));