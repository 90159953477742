import React, { Component } from "react";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import { ClpStepFilters } from "../../clinical-pathway/details";
import { Select } from "antd";
const { Option } = Select;

export class SectionTypeDropDown extends Component {
	renderOptions = () => [
		<Option key={ClpStepFilters.BEFORE_ADMISSION} value={ClpStepFilters.BEFORE_ADMISSION}><I18 tkey="BEFORE ADMISSION" /></Option>,
		<Option key={ClpStepFilters.CLINICAL_PATHWAY} value={ClpStepFilters.CLINICAL_PATHWAY}><I18 tkey="HOSPITALISATION" /></Option>,
		<Option key={ClpStepFilters.AFTER_CHECKOUT} value={ClpStepFilters.AFTER_CHECKOUT}><I18 tkey="AFTER CHECKOUT" /></Option>
	];

	render() {
		return (
			<div className={this.props.className ? this.props.className : "w_200p di mb_15_mob"}>
				<Select
					value={this.props.sectionType}
					disabled={this.props.disabled}
					className="w_100 di"
					allowClear={this.props.allowClear}
					defaultActiveFirstOption={true}
					onChange={this.props.selectedSectionType}
					placeholder={i18Get("Select Type", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())} >
					{this.renderOptions()}
				</Select>
			</div>
		);
	}
}

export default SectionTypeDropDown;
