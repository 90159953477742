import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllExtClinicalPathwayDataCompleted: false,
	extClinicalPathwayList: [],
	isExtClinicalPathwayDeleteComplete: false,
	isDeleteSuccess: {},
	isExtClinicalPathwayUpdateComplete: false,
	updatedExtClinicalPathway: {},
	isFetchAllExtClinicalPathwayForTypeCompleted: false,
	extClinicalPathwayTypeList: [],
	isExtClinicalPathwayCreateComplete: false,
	createdExtClinicalPathway: {},
	isFetchOneExtClinicalPathwayComplete: false,
	selectedExtClinicalPathway: {},
	listUpdate: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllExtClinicalPathwayDataCompleted: "FULFILLED",
					extClinicalPathwayList: action.payload.data.data,
					fetchAllExtClinicalPathwayMeta: action.meta
				};
			}
			return {
				...state,
				isFetchAllExtClinicalPathwayDataCompleted: "FULFILLED",
				fetchAllExtClinicalPathwayMeta: null
			};
		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA}_REJECTED`:
			return {
				...state,
				isFetchAllExtClinicalPathwayDataCompleted: "REJECTED",
				fetchAllExtClinicalPathwayMeta: null
			};
		case eventNames.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA:
			return {
				...state,
				isFetchAllExtClinicalPathwayDataCompleted: false,
				fetchAllExtClinicalPathwayMeta: false
			};

		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllExtClinicalPathwayResponsibleCompleted: "FULFILLED",
					responsibleList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllExtClinicalPathwayResponsibleCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE}_REJECTED`:
			return {
				...state,
				isFetchAllExtClinicalPathwayResponsibleCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE:
			return {
				...state,
				isFetchAllExtClinicalPathwayResponsibleCompleted: false
			};

		case `${eventNames.DELETE_EXT_CLINICAL_PATHWAY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtClinicalPathwayDeleteComplete: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isExtClinicalPathwayDeleteComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_EXT_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				isExtClinicalPathwayDeleteComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_EXT_CLINICAL_PATHWAY:
			return {
				...state,
				isExtClinicalPathwayDeleteComplete: false
			};

		case `${eventNames.UPDATE_EXT_CLINICAL_PATHWAY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtClinicalPathwayUpdateComplete: "FULFILLED",
					updatedExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				isExtClinicalPathwayUpdateComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_EXT_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				isExtClinicalPathwayUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_EXT_CLINICAL_PATHWAY:
			return {
				...state,
				isExtClinicalPathwayUpdateComplete: false
			};

		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllExtClinicalPathwayForTypeCompleted: "FULFILLED",
					extClinicalPathwayTypeList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllExtClinicalPathwayForTypeCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE}_REJECTED`:
			return {
				...state,
				isFetchAllExtClinicalPathwayForTypeCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE:
			return {
				...state,
				isFetchAllExtClinicalPathwayForTypeCompleted: false
			};

		case `${eventNames.CREATE_EXT_CLINICAL_PATHWAY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtClinicalPathwayCreateComplete: "FULFILLED",
					createdExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				isExtClinicalPathwayCreateComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_EXT_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				isExtClinicalPathwayCreateComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_EXT_CLINICAL_PATHWAY:
			return {
				...state,
				isExtClinicalPathwayCreateComplete: false
			};

		case `${eventNames.FETCH_ONE_EXT_CLINICAL_PATHWAY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneExtClinicalPathwayComplete: "FULFILLED",
					selectedExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneExtClinicalPathwayComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_EXT_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				isFetchOneExtClinicalPathwayComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_EXT_CLINICAL_PATHWAY:
			return {
				...state,
				isFetchOneExtClinicalPathwayComplete: false
			};
		case eventNames.TRIGGER_LIST_UPDATE:
			return {
				...state,
				listUpdate: true
			};
		case eventNames.CLEAR_TRIGGER_LIST_UPDATE:
			return {
				...state,
				listUpdate: false
			};

		case eventNames.TRIGGER_RESPONSIBLE_LIST_UPDATE:
			return {
				...state,
				responsibleListUpdate: true
			};
		case eventNames.CLEAR_TRIGGER_RESPONSIBLE_LIST_UPDATE:
			return {
				...state,
				responsibleListUpdate: false
			};

		case `${eventNames.GET_ONE_EXT_CLP}_FULFILLED`:
			return {
				...state,
				extClinicalPathwayDetails: action.payload.data.data,
				fetchExtClinicalPathwayDetails: "FULFILLED"
			};
		case `${eventNames.GET_ONE_EXT_CLP}_REJECTED`:
			return {
				...state,
				fetchExtClinicalPathwayDetails: "REJECTED"
			};

		case eventNames.CLEAR_GET_ONE_EXT_CLP:
			return {
				...state,
				extClinicalPathwayDetails: [],
				fetchExtClinicalPathwayDetails: false
			};

		case `${eventNames.UPDATE_SUB_STEP_NAME}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isSubStepNameUpdateComplete: "FULFILLED",
					updatedSubStepName: action.payload.data.data
				};
			}
			return {
				...state,
				isSubStepNameUpdateComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_SUB_STEP_NAME}_REJECTED`:
			return {
				...state,
				isSubStepNameUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_SUB_STEP_NAME:
			return {
				...state,
				isSubStepNameUpdateComplete: false
			};

		case `${eventNames.GET_ONE_SUB_STEP_DETAILS}_FULFILLED`:
			return {
				...state,
				subStepDetails: action.payload.data.data,
				fetchSubStepDetails: "FULFILLED"
			};
		case `${eventNames.GET_ONE_SUB_STEP_DETAILS}_REJECTED`:
			return {
				...state,
				fetchSubStepDetails: "REJECTED"
			};

		case eventNames.CLEAR_GET_ONE_SUB_STEP_DETAILS:
			return {
				...state,
				subStepDetails: [],
				fetchSubStepDetails: false
			};

		case `${eventNames.EXT_CLINICAL_PATHWAY_RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtClinicalPathwayRankUpdateComplete: "FULFILLED",
					rank: action.payload.data.data
				};
			}
			return {
				...state,
				isExtClinicalPathwayRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.EXT_CLINICAL_PATHWAY_RANK_UPDATE}_REJECTED`:
			return {
				...state,
				isExtClinicalPathwayRankUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_EXT_CLINICAL_PATHWAY_RANK_UPDATE:
			return {
				...state,
				isExtClinicalPathwayRankUpdateComplete: false
			};

		case `${eventNames.UPLOAD_REFERENCE_FILE}_PENDING`:
			return {
				...state,
				isReferenceFileUploadComplete: "PENDING"
			};
		case `${eventNames.UPLOAD_REFERENCE_FILE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					data: action.payload.data.data,
					isReferenceFileUploadComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isReferenceFileUploadComplete: "FULFILLED"
			};
		case `${eventNames.UPLOAD_REFERENCE_FILE}_REJECTED`:
			return {
				...state,
				isReferenceFileUploadComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPLOAD_REFERENCE_FILE:
			return {
				...state,
				isReferenceFileUploadComplete: false
			};

		case `${eventNames.DELETE_REFERENCE_FILE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isReferenceFileDeleteComplete: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isReferenceFileDeleteComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_REFERENCE_FILE}_REJECTED`:
			return {
				...state,
				isReferenceFileDeleteComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_REFERENCE_FILE:
			return {
				...state,
				isReferenceFileDeleteComplete: false
			};

		case `${eventNames.FETCH_REFERENCE_ATTACHMENT}_REJECTED`:
			return {
				...state,
				isFetchReferenceFileComplete: "REJECTED"
			};

		case `${eventNames.FETCH_REFERENCE_ATTACHMENT}_FULFILLED`:
			return {
				...state,
				attachments: action.payload.data.data,
				isFetchReferenceFileComplete: "FULFILLED"
			};

		case eventNames.FETCH_REFERENCE_ATTACHMENT_COMPLETED:
			return {
				...state,
				attachments: [],
				isFetchReferenceFileComplete: false
			};

		case `${eventNames.FETCH_BACK_GROUND_COLOR}_REJECTED`:
			return {
				...state,
				isFetchBgColorComplete: "REJECTED"
			};

		case `${eventNames.FETCH_BACK_GROUND_COLOR}_FULFILLED`:
			return {
				...state,
				bgColor: action.payload.data.data,
				isFetchBgColorComplete: "FULFILLED"
			};

		case eventNames.FETCH_BACK_GROUND_COLOR_COMPLETED:
			return {
				...state,
				bgColor: [],
				isFetchBgColorComplete: false
			};

		case `${eventNames.CREATE_ITEM}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isItemCreateComplete: "FULFILLED",
					createdItem: action.payload.data.data
				};
			}
			return {
				...state,
				isItemCreateComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_ITEM}_REJECTED`:
			return {
				...state,
				isItemCreateComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_ITEM:
			return {
				...state,
				isItemCreateComplete: false
			};

		case `${eventNames.UPDATE_ITEM}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isItemUpdateComplete: "FULFILLED",
					updatedItem: action.payload.data.data
				};
			}
			return {
				...state,
				isItemUpdateComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_ITEM}_REJECTED`:
			return {
				...state,
				isItemUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_ITEM:
			return {
				...state,
				isItemUpdateComplete: false
			};

		case `${eventNames.DUPLICATE_ITEM_MAPPING_ONLY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDuplicateItemMappingStatus: "FULFILLED",
					duplicateItem: action.payload.data.data
				};
			}
			return {
				...state,
				isDuplicateItemMappingStatus: "FULFILLED"
			};
		case `${eventNames.DUPLICATE_ITEM_MAPPING_ONLY}_REJECTED`:
			return {
				...state,
				isDuplicateItemMappingStatus: "REJECTED"
			};
		case eventNames.CLEAR_DUPLICATE_ITEM_MAPPING_ONLY:
			return {
				...state,
				isDuplicateItemMappingStatus: false
			};

		case `${eventNames.DUPLICATE_MAPPING}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDuplicateMappingStatus: "FULFILLED",
					duplicateItem: action.payload.data.data
				};
			}
			return {
				...state,
				isDuplicateMappingStatus: "FULFILLED"
			};
		case `${eventNames.DUPLICATE_MAPPING}_REJECTED`:
			return {
				...state,
				isDuplicateMappingStatus: "REJECTED"
			};
		case eventNames.CLEAR_DUPLICATE_MAPPING:
			return {
				...state,
				isDuplicateMappingStatus: false
			};

		case eventNames.SIDE_BAR_POSITION:
			return {
				...state,
				position: action.payload.position
			};
		case eventNames.CLEAR_SIDE_BAR_POSITION:
			return {
				...state,
				position: null
			};

		case `${eventNames.EXT_CLINICAL_PATHWAY_QUICK_EDIT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					extClinicalPathwayQuickEditComplete: "FULFILLED",
					updatedExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				extClinicalPathwayQuickEditComplete: "FULFILLED"
			};
		case `${eventNames.EXT_CLINICAL_PATHWAY_QUICK_EDIT}_REJECTED`:
			return {
				...state,
				extClinicalPathwayQuickEditComplete: "REJECTED"
			};
		case eventNames.CLEAR_EXT_CLINICAL_PATHWAY_QUICK_EDIT:
			return {
				...state,
				extClinicalPathwayQuickEditComplete: false
			};

		case `${eventNames.EXT_CLP_ITEM_ONLY_QUICK_EDIT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					extClpItemOnlyQuickEditComplete: "FULFILLED",
					updatedExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				extClpItemOnlyQuickEditComplete: "FULFILLED"
			};
		case `${eventNames.EXT_CLP_ITEM_ONLY_QUICK_EDIT}_REJECTED`:
			return {
				...state,
				extClpItemOnlyQuickEditComplete: "REJECTED"
			};
		case eventNames.CLEAR_EXT_CLP_ITEM_ONLY_QUICK_EDIT:
			return {
				...state,
				extClpItemOnlyQuickEditComplete: false
			};

		case `${eventNames.EXT_CLP_RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					extClpRankUpdateComplete: "FULFILLED",
					updatedExtClinicalPathway: action.payload.data.data
				};
			}
			return {
				...state,
				extClpRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.EXT_CLP_RANK_UPDATE}_REJECTED`:
			return {
				...state,
				extClpRankUpdateComplete: "REJECTED"
			};
		case eventNames.EXT_CLP_RANK_UPDATE_COMPLETED:
			return {
				...state,
				extClpRankUpdateComplete: false
			};

		case `${eventNames.GET_STEP_ITEM_COUNT}_FULFILLED`:
			return {
				...state,
				stepDetails: action.payload.data.data,
				getStepItemCount: "FULFILLED"
			};
		case `${eventNames.GET_STEP_ITEM_COUNT}_REJECTED`:
			return {
				...state,
				getStepItemCount: "REJECTED"
			};
		case eventNames.GET_STEP_ITEM_COUNT_COMPLETED:
			return {
				...state,
				getStepItemCount: false
			};

		case `${eventNames.BULK_DELETE_OF_CLP_ITEMS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					bulkDeleteOfClpItems: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				bulkDeleteOfClpItems: "FULFILLED"
			};
		case `${eventNames.BULK_DELETE_OF_CLP_ITEMS}_REJECTED`:
			return {
				...state,
				bulkDeleteOfClpItems: "REJECTED"
			};
		case eventNames.BULK_DELETE_OF_CLP_ITEMS_COMPLETED:
			return {
				...state,
				bulkDeleteOfClpItems: false
			};

		default:
			return state;
	}
}
