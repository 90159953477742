import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import CategoryPersonnelDropdown from "../../../personnels/category-personnels/categoryPersonnelDropdown";
import NameInput from "../../../../../../../../plug/regex-name-search-input";

class CreateItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidItemName: null,
			invalidItem: false,
			itemName: "",
			categoryPersonnelName: []
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.state.template = this.props.template ? this.props.template : utils.checkForParameter("templateId", this.props.location);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelCreate });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	itemChanged(e) {
		const prevState = this.state;
		prevState.item = e;
		prevState.invalidItem = false;
		this.setState(prevState);
	}

	categoryPersonnelNameChanged(e) {
		const prevState = this.state;
		if (e && e.length < 4) {
			prevState.categoryPersonnelName = e;
			prevState.invalidCategoryPersonnel = false;
			this.setState(prevState);
		}
	}

	selectedCategoryPersonnelIds() {
		const categoryPersonnelIds = [];
		this.state.categoryPersonnelName.map((data) => {
			categoryPersonnelIds.push(data.value);
		});
		return categoryPersonnelIds;
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.item || !this.state.item.trim() || (this.state.item.match(/\\$/)) || (this.state.item.indexOf("\"") >= 0)) {
			prevState.invalidItem = true;
		}
		if (this.state.categoryPersonnelName.length < 1) {
			prevState.invalidCategoryPersonnel = true;
		}
		if (!this.state.invalidItem && !this.state.invalidCategoryPersonnel) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				item: this.state.item ? this.state.item.trim() : "",
				categoryPersonnelIds: this.selectedCategoryPersonnelIds()
			};
			this.props.dispatch(adminClinicalPathwayActions.createClinicalPathwayItems(payload));
		}
	}

	subStepChanged(e) {
		const prevState = this.state;
		prevState.invalidSubStep = false;
		prevState.subStep = e;
		this.setState(prevState);
	}

	render() {
		if (this.props.adminClinicalPathway.createClinicalPathwayItemsCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayItems());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}${this.props.location.search}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.createClinicalPathwayItemsCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayItems());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="bloc_create_section">
				<div className="bloc_create_box">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Item" /></label>
								<NameInput
									value={this.state.item}
									inputChanged={this.itemChanged.bind(this)}
									maxLength={255}
								/>
								{this.state.invalidItem ? <span className="invalid_right fixed_label"><I18 tkey='Enter valid item (Special characters " \ not allowed)' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Intervenant" /></label>
								<CategoryPersonnelDropdown
									data={this.state.categoryPersonnelName}
									categoryPersonnelChanged={this.categoryPersonnelNameChanged.bind(this)}
									clinicalPathway
									isAdminPage
								/>
								{this.state.invalidCategoryPersonnel ? <span className="invalid_right fixed_label"><I18 tkey="Select Intervenant" /></span> : ""}
							</div>
						</div>
					</div>

					<div className="">
						<div className="di w_100 text_align_right">
							<ActionButton
								showAdd
								onClick={this.createClicked.bind(this)}
								value={i18Get("CREATE", utils.getLanguage())}
							/>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

CreateItem.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(CreateItem));
