import React, { Component } from "react";
import { LoadingOutlined } from "@ant-design/icons";

export default class Loader extends Component {
	render() {
		if (!this.props.loading) {
			return null;
		}
		return (
			<div className={this.props.isTable ? "position_absolute_loader" : "display_grid H_300px"}>
				<span className="margin_auto">
					<LoadingOutlined type="loading" style={{ fontSize: 24 }} />
				</span>
			</div>
		);
	}
}
