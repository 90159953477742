import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import HealthPathWayList from "./health-pathway-list";
import HealthPathWayCreate from "./createHealthPathWay";
import HealthPathwayDetails from "./details/index";

class HealthPathWay extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<Switch>
				<Route exact path={locations.health_pathway_create} component={HealthPathWayCreate} />
				<Route exact path={locations.healthPathwayDetails} component={HealthPathwayDetails} />
				<Route path={locations.health_pathway} component={HealthPathWayList} />
			</Switch>
		);
	}
}

HealthPathWay.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(HealthPathWay));
