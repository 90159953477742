import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import SpecialtyDropdown from "../../administration/activities/specialities/selectSpecialities";
import FamilyDropDown from "../../administration/activities/activity-families/selectFamilyForSpeciality";
import ActionButton from "../../../../../../plug/action-button";
import utils from "../../../../../../utils/utils";
import eventNames from "../../../../../../actions/events";
import interventionAction from "../../../../../../actions/intervention";
import clinicalPathwayAction from "../../../../../../actions/clinical-pathway";
import anaesthesiaAction from "../../../../../../actions/anaesthesia";
import healthPathwayAction from "../../../../../../actions/health-pathway";
import typeActivity from "../../../../../../constants/typeActivity.json";
import { Tooltip, Radio, Checkbox, Button } from "antd";
import NameInput from "../../../../../../plug/regex-name-search-input";
import NumericInput from "../../../../../../plug/numeric-input";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import SiteDropDown from "../../clinical-pathway/createClinicalPathWay/site_dropdown";
import { InfoCircleOutlined } from "@ant-design/icons";
import locations from "../../../../../../router/locations";
class CreateInterventionMom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			clinicalPathwayType: "SURGERY",
			type: "DAY",
			localStorageKey: this.props.typeActivity === "CLINICAL_PATHWAY" ? "CLP_FILTER" : "HEALTH_FILTER",
			isCheckList: false,
			clpMinuteChart: false,
			clinicalPathwayCategory: this.props.category,
			hospital: !this.props.user.loginData.hospitalId ? { value: "MASTER", label: "MOM" } : ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		const storedValues = utils.getFilterInLocalStorage(this.state.localStorageKey);
		if (storedValues && (typeof storedValues === "object") && Object.keys(storedValues).length) {
			if (storedValues.clinicalPathwayType && storedValues.clinicalPathwayType !== "ALL") {
				this.state.clinicalPathwayType = storedValues.clinicalPathwayType;
			}
			if (storedValues.category === "checkList") {
				this.state.clinicalPathwayCategory = storedValues.category;
				this.state.isCheckList = true;
				this.onNameChange("CheckList");
				this.setIfCheckList();
			} else {
				this.state.clinicalPathwayCategory = storedValues.category;
			}
		}
	}

	onSpecialtyChange(e) {
		const selectedSpecialty = e;
		this.state.data.selectedSpecialty = selectedSpecialty;
		this.state.invalidSpecialty = false;
		this.state.data.selectedFamily = false;
		this.state.invalidFamily = false;
		this.setState(this.state);
	}

	onFamilyChanged(e) {
		const selectedFamily = e;
		this.state.data.selectedFamily = selectedFamily;
		this.state.invalidFamily = false;
		this.setState(this.state);

	}

	onNameChange(value) {
		if (this.state.isCheckList) {
			if (value.includes("CheckList")) {
				this.state.data.name = value;
				this.state.invalidName = false;
				this.setState(this.state);
			}
		}
		else {
			this.state.data.name = value;
			this.state.invalidName = false;
			this.setState(this.state);
		}
	}

	getNumericValue(label, value) {
		this.state.data.day = value;
		this.state.invalidDay = false;
		this.state.data.hours = false;
		this.setState(this.state);
	}

	lengthOfStayChanged(label, value) {
		const prevState = this.state;
		if (prevState.type === "DAY") {
			prevState.invalidDay = false;
			prevState.data.day = value;
			prevState.data.hours = false;
		}
		if (prevState.type === "HOURS") {
			const limit = (prevState.clpMinuteChart) ? 9 : 24;
			if (value < limit) {
				prevState.data.hours = value;
				prevState.data.day = 1;
				prevState.invalidDay = false;
				prevState.invalidHours = false;
			}
		}
		this.setState(prevState);
	}

	typeChanged(e) {
		const prevState = this.state;
		prevState.type = e.target.value;
		if (prevState.type != "HOURS") {
			prevState.clpMinuteChart = false;
			prevState.invalidMinute = false;
		}
		prevState.invalidDay = false;
		prevState.invalidHours = false;
		this.setState(prevState);
	}

	// isCheckList(e) {
	// 	let prevState = this.state;
	// 	prevState.isCheckList = e.target.checked;
	// 	if (e.target.checked) {
	// 		this.onNameChange('CheckList');
	// 		this.onSpecialtyChange(null);
	// 		this.onFamilyChanged(null);
	// 	} else {
	// 		this.onNameChange('');
	// 		this.onSpecialtyChange(null);
	// 		this.onFamilyChanged(null);
	// 	}
	// 	this.setState(prevState);
	// }

	toggleClinicalPathwayCategory = (category) => {
		const prevState = this.state;
		if (prevState.clinicalPathwayCategory != category) {
			if (category === "checkList") {
				prevState.isCheckList = true;
				this.onNameChange("CheckList");
				this.onSpecialtyChange(null);
				this.onFamilyChanged(null);
				this.setIfCheckList();
			} else {
				prevState.isCheckList = false;
				this.onNameChange("");
				this.onSpecialtyChange(null);
				this.onFamilyChanged(null);
			}
			this.setState({ clinicalPathwayCategory: category });
		}
	};

	setIfCheckList() {
		const prevState = this.state;
		prevState.type = "DAY";
		prevState.data.day = 1;
		this.setState({ prevState });
	}

	handleCheckboxChange() {
		return (e) => {
			const prevState = this.state;
			prevState.clpMinuteChart = e.target.checked;
			if (prevState.clpMinuteChart) {
				prevState.data.hours = (prevState.type != "HOURS") ? 8 : (prevState.data.hours > 8) ? 8 : prevState.data.hours;
				prevState.type = "HOURS";
			}
			prevState.invalidDay = false;
			prevState.invalidHours = false;
			prevState.invalidMinute = false;
			prevState.data.day = 1;
			this.setState(prevState);
		};
	}
	doneClicked() {
		if (this.validate(this.state.data)) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				name: this.state.data.name.trim(),
				specialityId: this.state.data.selectedSpecialty.value,
				familyId: this.state.data.selectedFamily.value,
				typeActivity: this.props.typeActivity ? this.props.typeActivity : typeActivity.INTERVENTION.toUpperCase(),
				clinicalPathwayType: this.state.clinicalPathwayType,
				noOfDays: this.state.data.day ? this.state.data.day : "",
				noOfHours: this.state.data.hours ? (this.state.clpMinuteChart && this.state.data.hours > 8 ? 8 : this.state.data.hours) : "",
				beforeAdmissionNoOfDays: 2,
				beforeAdmissionNoOfHours: 23,
				afterCheckOutNoOfDays: 2,
				afterCheckOutNoOfHours: 23,
				isCheckList: this.state.isCheckList, 
				isModel: this.state.clinicalPathwayCategory && this.state.clinicalPathwayCategory === "model" ? true : false,
				clpMinuteChart: this.state.clpMinuteChart,
				siteId: this.state.site && this.state.site.value !== "MASTER" ? this.state.site.value : "",
				hospitalId: this.state.hospital && this.state.hospital.value && this.state.hospital.value !== "MASTER" ? this.state.hospital.value : ""
			};
			if (this.state.typeActivity === typeActivity.ANAESTHESIA.toUpperCase()) {
				this.props.dispatch(anaesthesiaAction.createAnaesthesia(payload));
			}
			else if (this.state.typeActivity === "CLINICAL_PATHWAY") {
				this.props.dispatch(clinicalPathwayAction.createClinicalPathway(payload, "MOM"));
			}
			else if (this.state.typeActivity === typeActivity.INTERVENTION.toUpperCase()) {
				this.props.dispatch(interventionAction.createIntervention(payload, "MOM"));
			}
			else if (this.state.typeActivity === "HEALTH_PATHWAY") {
				this.props.dispatch(healthPathwayAction.createHealthPathway(payload, "MOM"));
			}
		}
	}

	validate(data) {
		let valid = true;
		const prevState = this.state;
		if (!utils.checkNameRegex(data.name) || !data.name || !data.name.trim()) {
			prevState.invalidName = true;
			valid = false;
		}
		if (!data.selectedSpecialty) {
			prevState.invalidSpecialty = true;
			valid = false;
		}
		if (!data.selectedFamily) {
			prevState.invalidFamily = true;
			valid = false;
		}

		if (this.props.typeActivity === "CLINICAL_PATHWAY" && !this.state.site) {
			prevState.invalidSite = true;
			valid = false;
		}
		if (this.state.clpMinuteChart && (data.hours > 8 || data.hours < 1)) {
			prevState.invalidMinute = true;
			valid = false;
		}
		if (this.props.typeActivity === "CLINICAL_PATHWAY") {
			if (!utils.NumericValidation.test(data.day) || !data.day || parseInt(data.day) === 0) {
				prevState.invalidDay = true;
				valid = false;
			}
		}
		if (this.props.typeActivity === "HEALTH_PATHWAY" && !this.state.site) {
			prevState.invalidSite = true;
			valid = false;
		}
		if (valid) {
			return true;
		} else {
			this.setState(prevState);
			this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			return false;
		}
	}

	toggleClinicalPathwayType = (status) => {
		if (this.state.clinicalPathwayType != status) {
			this.setState({ clinicalPathwayType: status });
		}
	};

	hospitalChanged(e) {
		const prevState = this.state;
		prevState.hospital = e;
		prevState.site = null;
		this.setState(prevState);
	}

	siteChanged(e) {
		const prevState = this.state;
		prevState.site = e;
		prevState.invalidSite = false;
		this.setState(prevState);
	}

	render() {
		this.state.typeActivity = this.props.typeActivity;
		if (this.props.intervention.createIntervention === "FULFILLED") {
			const treatmentId = this.props.intervention.createdInterventionData.id;
			let urlToPush = "";
			if (treatmentId) {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
				urlToPush += `/timeSheets/${treatmentId}`;
			} else {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
			}
			this.props.history.push(urlToPush);
			this.props.dispatch(interventionAction.clearCreate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.anaesthesia.isCreateAnaesthesiaCompleted === "FULFILLED") {
			const treatmentId = this.props.anaesthesia.anaesthesia.id;
			let urlToPush = "";
			if (treatmentId) {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
				urlToPush += `/${treatmentId}`;
			} else {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
			}
			this.props.history.push(urlToPush);
			this.props.dispatch(anaesthesiaAction.clearCreateAnaesthesia());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.clinicalPathway.createClinicalPathway === "FULFILLED") {
			const clinicalPathwayId = this.props.clinicalPathway.createdClinicalPathwayData.id;
			let urlToPush = "";
			if (clinicalPathwayId) {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
				urlToPush += `/${clinicalPathwayId}`;
			} else {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
			}
			this.props.history.push(urlToPush);
			this.props.dispatch(clinicalPathwayAction.clearCreate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.clinicalPathway.createClinicalPathway === "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.clearCreate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.healthPathway.createHealthPathway === "FULFILLED") {
			const healthPathwayId = this.props.healthPathway.createdHealthPathwayData.id;
			let urlToPush = "";
			if (healthPathwayId) {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
				urlToPush += `/${healthPathwayId}`;
			} else {
				urlToPush = this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/"));
			}
			this.props.history.push(urlToPush);
			this.props.dispatch(healthPathwayAction.clearCreate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.healthPathway.createHealthPathway === "REJECTED") {
			this.props.dispatch(healthPathwayAction.clearCreate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div className="main_overview_section">
				<div className='padding-top-15 padding-bottom-15 di w_100'>
					<div className="di w_100 steps_outer_container_mom">
						<div className="step_header"><I18 tkey={`Enter the data for New ${utils.toPascalCase(this.state.typeActivity).replace(/_/g, " ")}`} /> <I18 tkey={`${this.props.typeActivity === "CLINICAL_PATHWAY" && this.state.clinicalPathwayType ? `(${this.state.clinicalPathwayType === "MEDICINE" ? "MEDICINE WITH ACTE" : this.state.clinicalPathwayType.replace(/_/g, " ")})` : ""}`} /></div>
						<div className="steps_contents">
							{this.props.typeActivity === "CLINICAL_PATHWAY" || this.props.typeActivity === "HEALTH_PATHWAY" ?
								<div>
									<div className="col-md-3"></div>
									<div className="col-md-9 padding-left-7">
										<div className="btn-group">
											<button type="button" onClick={() => this.toggleClinicalPathwayType("SURGERY")} className={`width_200 ${this.state.clinicalPathwayType === "SURGERY" ? "with_border uppercase left_button active-btton" : "with_border uppercase  left-button"}`}>
												<I18 tkey='SURGERY' />
											</button>
											<button type="button" onClick={() => this.toggleClinicalPathwayType("MEDICINE")} className={`width_200 ${this.state.clinicalPathwayType === "MEDICINE" ? "with_border uppercase  active-btton center_btn" : "with_border uppercase center_btn"}`}  >
												<I18 tkey='MEDICINE WITH ACTE' />
											</button>
											<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayType("MEDICINE_WITHOUT_ACTE")} className={`width_200 ${this.state.clinicalPathwayType === "MEDICINE_WITHOUT_ACTE" ? "with_border uppercase right-button active-btton" : "with_border uppercase right-button"}`}>
												<I18 tkey='MEDICINE WITHOUT ACTE' />
											</button>
										</div>
									</div>
								</div> : ""}
							{this.props.typeActivity === "CLINICAL_PATHWAY" &&
								<div>
									<div className="col-md-3 height_30"></div>
									<div className="col-md-9 padding-left-7">
										<div className="btn-group spacer-top-10">
											<button type="button" onClick={() => this.toggleClinicalPathwayCategory("clinicalPathway")} className={`width_200 ${this.state.clinicalPathwayCategory === "clinicalPathway" ? "with_border uppercase left_button active-btton" : "with_border uppercase  left-button"}`}>
												<I18 tkey='CLINICAL PATHWAY' />
											</button>
											<button type="button" onClick={() => this.toggleClinicalPathwayCategory("checkList")} className={`width_200 ${this.state.clinicalPathwayCategory === "checkList" ? "with_border uppercase  active-btton center_btn" : "with_border uppercase center_btn"}`}  >
												<I18 tkey='CHECK LIST' />
											</button>
											<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("model")} className={`width_200 ${this.state.clinicalPathwayCategory === "model" ? "with_border uppercase right-button active-btton" : "with_border uppercase right-button"}`}>
												<I18 tkey='MODEL' />
											</button>
										</div>
									</div>
								</div>
								// <div className="row margin-bottom-8-steps">
								// 	<div className="col-md-3"><span className="steps_label_special"><I18 tkey='Check List' /></span></div>
								// 	<div className="col-md-9">
								// 		<div className="w_100 di position_relative padding-top-25-steps">
								// 			<Checkbox onChange={this.isCheckList.bind(this)} value={this.state.isCheckList} checked={this.state.isCheckList}></Checkbox>
								// 		</div>
								// 	</div>
								// </div>
							}
							{this.props.typeActivity === "CLINICAL_PATHWAY" || this.props.typeActivity === "HEALTH_PATHWAY" ?
								<div>
									<div className="row margin-bottom-10-steps">
										<div className="col-md-3 height_30"></div>
										<div className="col-md-9">
											<div className="col-md-6 padding-left-0">
												<div className="w_100 height_30"><span className="steps_label_special padding-top-10 margin_top_0"><I18 tkey='Hospital' /></span></div>
												<div className="w_100">
													<div className="w_100 di position_relative padding-top-10-steps">
														<HospitalSelect
															data={this.state.hospital}
															hospitalChanged={this.hospitalChanged.bind(this)}
															isCreateClp={true}
															hospitalId={this.props.user.loginData.hospitalId}
														/>
													</div>
												</div>
											</div>
											<div className="col-md-6 padding-right-0">
												<div className="w_100 height_30"><span className="steps_label_special padding-top-10 margin_top_0"><I18 tkey='Site' /></span></div>
												<div className="w_100">
													<div className="w_100 di position_relative padding-top-10-steps">
														<SiteDropDown
															autoSetSingleOne={true}
															data={this.state.site}
															siteChanged={this.siteChanged.bind(this)}
															hospital={this.props.user.loginData.hospitalId ? this.props.user.loginData.hospitalId : this.state.hospital && this.state.hospital.value}
														/>
													</div>
													{this.state.invalidSite && <span className='invalid_right fixed_label'><I18 tkey='Invalid site' /></span>}
												</div>
											</div>
										</div>
									</div>
								</div>
								: ""
							}
							<div className="row margin-bottom-10-steps">
								<div className="col-md-3 "><span className="steps_label_special"><I18 tkey='Name' /></span></div>
								<div className="col-md-9">
									<div className="w_100 di position_relative padding-top-25-steps name_field">
										<NameInput
											className="form-control w_100 padding-right-36"
											value={this.state.data.name}
											inputChanged={this.onNameChange.bind(this)}
											maxLength={120}
										/>
										{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey='Invalid name' /></span>}
										<Tooltip placement="topRight" overlayClassName="special_character_tooltip" title={i18Get("Special characters not allowed except ( ) + _ - ' ,.", utils.getLanguage())}><InfoCircleOutlined /></Tooltip>
									</div>
								</div>
							</div>
							<div className="row margin-bottom-10-steps">
								<div className="col-md-3"><span className="steps_label_special"><I18 tkey='Speciality' /></span></div>
								<div className="col-md-9">
									<div className="w_100 di position_relative padding-top-25-steps">
										<SpecialtyDropdown setSingleDataDefault= {false} autoSetSingleOne={true} isCheckList={this.state.isCheckList} data={(this.state.data && this.state.data.selectedSpecialty) ? this.state.data.selectedSpecialty : ""} specialityChanged={this.onSpecialtyChange.bind(this)} organization='mom' typeActivity={this.state.typeActivity ? this.state.typeActivity : typeActivity.INTERVENTION.toUpperCase()} />
										{this.state.invalidSpecialty && <span className='invalid_right fixed_label'><I18 tkey='Select valid speciality' /></span>}
									</div>
								</div>
							</div>
							<div className="row margin-bottom-10-steps">
								<div className="col-md-3"><span className="steps_label_special"><I18 tkey='Family' /></span></div>
								<div className="col-md-9">
									<div className="w_100 di position_relative padding-top-25-steps">
										<FamilyDropDown setSingleDataDefault= {false} autoSetSingleOne={true} isCheckList={this.state.isCheckList} data={(this.state.data && this.state.data.selectedFamily) ? this.state.data.selectedFamily : ""} onFamilyChanged={this.onFamilyChanged.bind(this)} organization='mom' specialityId={this.state.data.selectedSpecialty ? this.state.data.selectedSpecialty.value : ""} typeActivity={this.state.typeActivity ? this.state.typeActivity : typeActivity.INTERVENTION.toUpperCase()} />
										{this.state.invalidFamily && <span className='invalid_right fixed_label'><I18 tkey='Select valid family' /></span>}
									</div>
								</div>
							</div>
							{this.props.typeActivity === "CLINICAL_PATHWAY" &&
								<div>
									<div className="row margin-bottom-10-steps">
										<div className="col-md-3 col-sm-3"><span className="steps_label_special"><I18 tkey='Length of Stay' /></span></div>
										<div className="col-md-8 col-sm-8">
											<div className="row di position_relative padding-top-25-steps">
												<div className="col-md-3">
													<Radio.Group value={this.state.type} onChange={this.typeChanged.bind(this)}>
														<Radio className="" value="DAY">
															<I18 tkey="Jours" />
														</Radio>
														<Radio className="" value="HOURS" disabled={this.state.isCheckList}>
															<I18 tkey="Hours" />
														</Radio>
													</Radio.Group>
												</div>
												<div className="col-md-3 margin-top-5 padding-left-0">
													<NumericInput
														value={this.state.type === "DAY" ? this.state.data.day : this.state.data.hours}
														getNumericValue={this.lengthOfStayChanged.bind(this)}
														maxLength={2}
														integerOnly={true}
														disabled={this.state.isCheckList}

													/>
												</div>
												<div className="col-md-12">
													<Checkbox
														checked={this.state.clpMinuteChart}
														onChange={this.handleCheckboxChange().bind(this)}
														disabled={this.state.isCheckList}>
														<I18 tkey="Minutes" />
													</Checkbox>
												</div>
												{this.state.invalidDay && <span className='invalid fixed_label invalid_day_steps_three'><I18 tkey='Days should have a value' /></span>}
												{this.state.invalidMinute && <span className='invalid fixed_label invalid_day_steps_three'><I18 tkey='Hours should have a value' /></span>}
											</div>
										</div>
									</div>
								</div>}
							<div className="row margin-bottom-10-steps">
								<div className="col-md-3"><span className="steps_label_special"><I18 tkey='Code' /></span></div>
								<div className="col-md-9">
									<div className="row padding-top-25-steps">
										<div className="col-md-12 margin-bottom-5">
											<div className="row">
												<div className="col-md-4 col-sm-4 col-xs-6">
													<div className="code_header w_100 di"><I18 tkey='Code Speciality' /></div>
												</div>
												<div className="col-md-4 col-sm-4 col-xs-6">
													<div className="code_header w_100 di"><I18 tkey='Code Family' /></div>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="row ">
												<div className="col-md-4 col-sm-4 col-xs-6">
													<div className="code_value w_100 di">{this.state.data && this.state.data.selectedSpecialty ? this.state.data.selectedSpecialty.code : ""}</div>
												</div>
												<div className="col-md-4 col-sm-4 col-xs-6">
													<div className="code_value w_100 di">{this.state.data && this.state.data.selectedFamily ? this.state.data.selectedFamily.code : ""}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="text_align_right margin-top-25">
										<Button onClick={() => { this.props.history.push(this.props.match.path.replace("/create", ""));}} className="margin-right-10" ><I18 tkey="Cancel" /></Button>
										<ActionButton showAdd={true} type="primary" className="" onClick={this.doneClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateInterventionMom.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	imagery: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	intervention: store.intervention,
	anaesthesia: store.anaesthesia,
	imagery: store.imagery,
	actions: store.actions,
	translations: store.translations,
	clinicalPathway: store.clinicalPathway,
	healthPathway: store.healthPathway,
	user: store.user
}))(CreateInterventionMom));