import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import config from "../../../../../../../../MOM-config";
import clinicalPathwayDetailsAction from "../../../../../../../../actions/clinicalPathway-details";

class DownloadFile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			visible: false
		};
	}

	getContent() {
		return (
			<div className="padding-10 success_error_popover font_size_12px">
				{this.state.referenceAttachments && this.state.referenceAttachments.length && this.state.referenceAttachments.map((data) => (
					<div key="success_error_popover" className="padding-10 success_error_popover font_size_12px">
						<div className="display_inline_flex w_100">
							<a key="success_error_key" className="success_error_key bold" onClick={this.fileClicked.bind(this, data)}>
								{" "}
								<I18 tkey={data.fileName} />
							</a>
						</div>
					</div>
				))}
			</div>

		);
	}

	fileClicked(data) {
		const url = config.apiRoot.replace("/api/v1", "");
		const filePath = `${url}/referenceFiles${data.filePath}`;
		const save = document.createElement("a");
		save.href = filePath;
		save.download = data.fileName;
		save.target = "_blank";
		document.body.appendChild(save);
		save.click();
		document.body.removeChild(save);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(clinicalPathwayDetailsAction.fetchReferenceAttachment(this.props.match.params.id, this.props.data.id, this.props.fileType ? this.props.fileType : ""));
	}

	render() {
		if (this.props.clinicalPathwayDetails.fetchReferenceAttachmentStatus === "FULFILLED") {
			this.state.referenceAttachments = this.props.clinicalPathwayDetails.attachments ? this.props.clinicalPathwayDetails.attachments : [];
			this.props.dispatch(clinicalPathwayDetailsAction.fetchReferenceAttachmentCompleted());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Files", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.props.cancelClicked}
					footer={[
						<Button key="back" onClick={this.props.cancelClicked}>
							<I18 tkey="Cancel" />
						</Button>
					]}
				>
					<div>
						{this.state.referenceAttachments && this.state.referenceAttachments.length > 0 ? this.getContent() : ""}
					</div>
				</Modal>
			</div>
		);
	}
}

DownloadFile.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(DownloadFile));
