import eventNames from "../../actions/events";

const initialState = {
	reloadStaff: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.PREPARATION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					preparations: action.payload.data.data,
					fetchAllpreparations: "FULFILLED"
				};
			}
			return {
				...state,
				preparations: [],
				fetchAllpreparations: "FULFILLED"
			};
		case `${eventNames.PREPARATION_GET}_REJECTED`:
			return {
				...state,
				fetchAllpreparations: "REJECTED"
			};
		case `${eventNames.POSITION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					positions: action.payload.data.data,
					fetchAllPositions: "FULFILLED"
				};
			}
			return {
				...state,
				positions: [],
				fetchAllPositions: "FULFILLED"
			};
		case `${eventNames.POSITION_GET}_REJECTED`:
			return {
				...state,
				fetchAllPositions: "REJECTED"
			};
		case `${eventNames.INSTALLATION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					installation: action.payload.data.data,
					fetchAllInstallation: "FULFILLED"
				};
			}
			return {
				...state,
				installation: [],
				fetchAllInstallation: "FULFILLED"
			};
		case `${eventNames.INSTALLATION_GET}_REJECTED`:
			return {
				...state,
				fetchAllInstallation: "REJECTED"
			};
		case `${eventNames.STAFF_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					staff: action.payload.data.data,
					fetchAllStaff: "FULFILLED"
				};
			}
			return {
				...state,
				staff: [],
				fetchAllStaff: "FULFILLED"
			};
		case `${eventNames.STAFF_GET}_REJECTED`:
			return {
				...state,
				fetchAllStaff: "REJECTED"
			};
		case eventNames.CLEAR_STAFF_GET:
			return {
				...state,
				fetchAllStaff: false
			};
		case `${eventNames.MEDICAL_DEVICES_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					medicalDevices: action.payload.data.data,
					fetchAllMedicalDevices: "FULFILLED"
				};
			}
			return {
				...state,
				medicalDevices: [],
				fetchAllMedicalDevices: "FULFILLED"
			};
		case `${eventNames.MEDICAL_DEVICES_GET}_REJECTED`:
			return {
				...state,
				fetchAllMedicalDevices: "REJECTED"
			};
		case `${eventNames.PHARMACY_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					pharmacy: action.payload.data.data,
					fetchAllPharmacy: "FULFILLED"
				};
			}
			return {
				...state,
				pharmacy: [],
				fetchAllPharmacy: "FULFILLED"
			};
		case `${eventNames.PHARMACY_GET}_REJECTED`:
			return {
				...state,
				fetchAllPharmacy: "REJECTED"
			};
		case `${eventNames.INSTALLATION_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagesInstallation: action.payload.data.data,
					isInstallationImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imagesInstallation: [],
				isInstallationImageGetCompleted: "FULFILLED"
			};
		case `${eventNames.INSTALLATION_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isInstallationImageGetCompleted: "REJECTED"
			};
		case `${eventNames.POSITION_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagesPosition: action.payload.data.data,
					isPositionImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imagesPosition: [],
				isPositionImageGetCompleted: "FULFILLED"
			};
		case `${eventNames.POSITION_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isPositionImageGetCompleted: "REJECTED"
			};
		case `${eventNames.DEFINITION_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagesDefinition: action.payload.data.data,
					isDefinitionImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imagesDefinition: [],
				isDefinitionImageGetCompleted: "FULFILLED"
			};
		case `${eventNames.DEFINITION_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isDefinitionImageGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DEFINITION_GET_ALL_IMAGES:
			return {
				...state,
				isDefinitionImageGetCompleted: false
			};
		case `${eventNames.DEFINITION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					Definition: action.payload.data.data,
					isDefinitionGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				Definition: [],
				isDefinitionGetCompleted: "FULFILLED"
			};
		case `${eventNames.DEFINITION_GET}_REJECTED`:
			return {
				...state,
				isDefinitionGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DEFINITION_GET:
			return {
				...state,
				isDefinitionGetCompleted: false
			};
		case `${eventNames.DESCRIPTION_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagesDescription: action.payload.data.data,
					isDescriptionImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imagesDescription: [],
				isDescriptionImageGetCompleted: "FULFILLED"
			};
		case `${eventNames.DESCRIPTION_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isDescriptionImageGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DESCRIPTION_GET_ALL_IMAGES:
			return {
				...state,
				isDescriptionImageGetCompleted: false
			};
		case `${eventNames.DESCRIPTION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					description: action.payload.data.data,
					isDescriptionGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				Description: [],
				isDescriptionGetCompleted: "FULFILLED"
			};
		case `${eventNames.DESCRIPTION_GET}_REJECTED`:
			return {
				...state,
				isDescriptionGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DESCRIPTION_GET:
			return {
				...state,
				isDescriptionGetCompleted: false
			};
		case `${eventNames.REMARKS_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagesRemarks: action.payload.data.data,
					isRemarksImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imagesRemarks: [],
				isDefinitionImageGetCompleted: "FULFILLED"
			};
		case `${eventNames.REMARKS_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isRemarksImageGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_REMARKS_GET_ALL_IMAGES:
			return {
				...state,
				isRemarksImageGetCompleted: false
			};
		case `${eventNames.REMARKS_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					remarks: action.payload.data.data,
					isRemarksGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				remarks: [],
				isRemarksGetCompleted: "FULFILLED"
			};
		case `${eventNames.REMARKS_GET}_REJECTED`:
			return {
				...state,
				isRemarksGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_REMARKS_GET:
			return {
				...state,
				isRemarksGetCompleted: false
			};
		case eventNames.CLEAR_INTERVENTIONDETAILS_GET:
			return {
				...state,
				fetchAllpreparations: false
			};

		case eventNames.RELOAD_STAFF:
			return {
				...state,
				reloadStaff: true
			};
		case eventNames.CLEAR_RELOAD_STAFF:
			return {
				...state,
				reloadStaff: false
			};

		case `${eventNames.FETCH_ALL_EXTENDED_ELEMENTS}_FULFILLED`:
			return {
				...state,
				interventionData: action.payload.data.data,
				fetchAllExtendedElements: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXTENDED_ELEMENTS}_REJECTED`:
			return {
				...state,
				fetchAllExtendedElements: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_ELEMENTS}_FULFILLED`:
			return {
				...state,
				interventionElementsData: action.payload.data.data,
				fetchAllElements: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_ELEMENTS}_REJECTED`:
			return {
				...state,
				fetchAllElements: "REJECTED"
			};
		default:
			return state;
	}
}
