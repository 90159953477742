import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox } from "antd";
import eventNames from "../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../i18";
import extElementActions from "../../../../../../actions/ext-elements";
import consumableActions from "../../../../../../actions/consumable";
import ActionButton from "../../../../../../plug/action-button";
import utils from "../../../../../../utils/utils";
import QuantityList from "../top-section/quantity-list";
import Loader from "../../../../../../plug/loader";
import extElements from "../../../../../../constants/extElements.json";
import typeActivity from "../../../../../../constants/typeActivity.json";
import locations from "../../../../../../router/locations";

class EditExtElements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			extElement: {},
			invalidQuantity: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		if (this.props.screenId) {
			this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		}
		const queryString = (this.props.doctorId ? `doctorId=${this.props.doctorId}&` : "") + (this.props.cycle ? `cycleId=${this.props.cycle.id}&` : "") + (this.props.type ? `exType=${this.props.type.toUpperCase()}&` : "");
		if (this.props.isDci) {
			this.props.dispatch(consumableActions.getOnePreparationPerfusion(this.props.match.params.consumableId, this.props.match.params.id, queryString));
		} else {
			this.props.dispatch(extElementActions.fetchOneExtElement(this.props.match.params.id, this.props.type, this.props.match.params.elementId, queryString));
		}
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	optionChanged(field) {
		return () => {
			const prevState = this.state;
			prevState.extElement[field] = !this.state.extElement[field];
			this.setState(prevState);
		};
	}

	quantityChanged(e) {
		const prevState = this.state;
		prevState.extElement.quantity = e;
		prevState.invalidQuantity = false;
		this.setState(prevState);
	}

	cancelClicked() {
		if (this.props.isDci) {
			this.props.history.push(`${locations.consumable_dci}/dci/${this.props.match.params.consumableId}/${this.props.type.toLowerCase()}`);
		} else if (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) {
			this.props.history.push(`/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}${this.props.cycle ? `?cycleId=${this.props.cycle.id}` : ""}`);
		} else if (this.props.typeActivity === typeActivity.RADIOTHERAPY.toUpperCase()) {
			this.props.history.push(`/radiotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}${utils.checkForParameter("sessionTypeId", this.props.location) ? `?sessionTypeId=${utils.checkForParameter("sessionTypeId", this.props.location)}` : ""}`);
		} else if (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase()) {
			this.props.history.push(`/imagery/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}`);
		} else {
			this.props.history.push(`/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}/extElements/${this.props.type.toLowerCase()}${this.props.location.search}`);
		}
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.extElement.quantity) {
			prevState.invalidQuantity = true;
		}
		this.setState(prevState);
		if (!this.state.invalidQuantity) {
			return true;
		}
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				elementId: this.state.extElement.elementId,
				type: this.props.name,
				quantity: this.state.extElement.quantity,
				isOptional: !!this.state.extElement.isOptional,
				costOne: !!this.state.extElement.costOne
			};
			if (this.props.type !== extElements.extElements.INSTRUMENTATION) {
				payload.isSubsidised = !!this.state.extElement.isSubsidised;
			}
			if (this.props.isDci) {
				payload.type = this.props.type;
				payload.isDci = this.state.extElement.isDci ? this.state.extElement.isDci : false;
				payload.isAnnex = this.state.extElement.isAnnex ? this.state.extElement.isAnnex : false;
				payload.isBottle = this.state.extElement.isBottle ? this.state.extElement.isBottle : false;
				this.props.dispatch(consumableActions.updatePreparationPerfusion(this.props.match.params.consumableId, this.state.extElement.id, payload));
			} else {
				const queryString = this.props.doctorId ? `doctorId=${this.props.doctorId}` : "";
				this.props.dispatch(extElementActions.updateExtElement(this.props.match.params.id, this.props.type, this.state.extElement.elementId, payload, queryString));
			}
		}
	}

	renderChemoContents() {
		return (
			<div className="di w_100">
				<div className="col-md-3  col-sm-3">
					<div className="w_100 di margin-bottom-18">
						<Checkbox checked={this.state.extElement.isDci} disabled><I18 tkey="DCI" /></Checkbox>
					</div>
				</div>
				<div className="col-md-3  col-sm-3">
					<div className="w_100 di margin-bottom-18">
						<Checkbox checked={this.state.extElement.isAnnex} disabled><I18 tkey="Annex" /></Checkbox>
					</div>
				</div>
				<div className="col-md-3  col-sm-3">
					<div className="w_100 di margin-bottom-18">
						<Checkbox checked={this.state.extElement.isBottle} disabled><I18 tkey="Bottle" /></Checkbox>
					</div>
				</div>
			</div>
		);
	}

	resetChemoContents() {
		return (
			this.state.extElement.isDci = false,
			this.state.extElement.isAnnex = false,
			this.state.extElement.isBottle = false
		);
	}

	chemoContentsChanged(field) {
		return (e) => {
			this.resetChemoContents();
			const prevState = this.state;
			prevState.extElement[field] = e.target.checked ? !this.state.extElement[field] : false;
			this.setState(prevState);
		};
	}

	renderEdit() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100 col-md-7">
					<div className="row">
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-18">
								<input className="w_100 di form-control" value={this.state.extElement.elementName} readOnly />
							</div>
						</div>
						<div className="di col-md-5">
							<div className="col-md-3  col-sm-3">
								<div className="w_100 di margin-bottom-18">
									<Checkbox
										checked={this.state.extElement.isOptional}
										onChange={this.optionChanged("isOptional").bind(this)}
									>
										<I18 tkey="Is Optional" />
									</Checkbox>
								</div>
							</div>
							<div className="col-md-3  col-sm-3">
								<div className="w_100 di margin-bottom-18">
									<Checkbox
										checked={this.state.extElement.costOne}
										onChange={this.optionChanged("costOne").bind(this)}
									>
										<I18 tkey="Cost One" />
									</Checkbox>
								</div>
							</div>
							{(this.props.type !== extElements.extElements.INSTRUMENTATION)
								&& (
									<div className="col-md-3  col-sm-3">
										<div className="w_100 di margin-bottom-18">
											<Checkbox
												disabled
												checked={this.state.extElement.isSubsidised}
												onChange={this.optionChanged("isSubsidised").bind(this)}
											>
												<I18 tkey="Is Subsidised" />
											</Checkbox>
										</div>
									</div>
								)}
						</div>
						{((this.props.typeActivity === "CHEMOTHERAPY") || this.props.isDci) && this.renderChemoContents()}
						<div className="col-md-12">
							<QuantityList
								quantity={this.state.extElement.quantity}
								quantityChanged={this.quantityChanged.bind(this)}
								invalidQuantity={this.state.invalidQuantity}
							/>
						</div>
						<div className="col-md-12 margin-top-20">
							<div className="di w_100 text_align_right">
								<button className="edit_delete_button cancel_button margin-right-15" onClick={this.cancelClicked.bind(this)}><I18 tkey="Cancel" /></button>
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.extElements.isFetchOneExtElementComplete === "FULFILLED") {
			this.state.extElement = this.props.extElements.selectedExtElement;
			if (Object.keys(this.state.extElement).length) {
				this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { elementId: this.state.extElement.elementId, elementName: this.state.extElement.elementName } });
			}
			this.props.dispatch(extElementActions.clearFetchOneExtElement());
		}
		if (this.props.extElements.isFetchOneExtElementComplete === "REJECTED") {
			this.props.dispatch(extElementActions.clearFetchOneExtElement());
			this.cancelClicked();
		}

		if (this.props.consumable.getOnePreparationPerfusionComplete === "FULFILLED") {
			this.state.extElement = this.props.consumable.preparationPerfusionDetails[0];
			if (Object.keys(this.state.extElement).length) {
				this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.extElement.id, name: this.state.extElement.elementName } });
			}
			this.props.dispatch(consumableActions.clearGetOnePreparationPerfusion());
		}
		if (this.props.consumable.getOnePreparationPerfusionComplete === "REJECTED") {
			this.props.dispatch(consumableActions.clearGetOnePreparationPerfusion());
			this.cancelClicked();
		}
		if (this.props.extElements.isExtElementUpdateComplete === "FULFILLED") {
			this.props.dispatch(extElementActions.clearUpdateExtElement());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.cancelClicked();
		}

		if (this.props.consumable.updatePreparationPerfusionComplete === "FULFILLED") {
			this.props.dispatch(consumableActions.clearUpdatePreparationPerfusion());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.cancelClicked();
		}
		if (this.props.consumable.updatePreparationPerfusionComplete === "REJECTED") {
			this.props.dispatch(consumableActions.clearUpdatePreparationPerfusion());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.state.extElement.id) {
			return this.renderEdit();
		}
		return (
			<div>
				<Loader loading isTable={false} />
			</div>
		);
	}
}

EditExtElements.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extElements: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	extElements: store.extElements,
	consumable: store.consumable,
	translations: store.translations
}))(EditExtElements));
