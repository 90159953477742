import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import locations from "../../../../../../../../router/locations";
import SitesList from "./sitesList";

export default class List extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					path={locations.structure_sites}
					render={() => <SitesList {...this.props} />}
				/>
				<Redirect to={locations.structure_sites} />
			</Switch>
		);
	}
}
