import React, { Component } from "react";
import { Select } from "antd";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils.js";

const { Option } = Select;

export default class ProcedureDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			procedureDay: this.props.days ? this.renderProcedureDayOptions(this.props.days) : []
		};
	}

	renderProcedureDayOptions(days, type) {
		const procedureDay = [];
		let day = 0;
		if (type === "HOURS") {
			day = -1;
		}
		for (day; day < parseInt(days); day++) {
			procedureDay.push(<Option key={"Jour" + ` ${day + 1}`} value={parseInt(day) + 1}>{"Jour" + ` ${day + 1}`}</Option>);
		}
		return procedureDay;
	}

	render() {
		if (this.state.days !== this.props.days) {
			const prevState = this.state;
			prevState.days = this.props.days;
			prevState.procedureDay = this.renderProcedureDayOptions(this.state.days, this.props.type);
			this.state = prevState;
		}
		return (
			<Select
				allowClear
				value={this.props.procedureDay}
				className="w_100 di"
				defaultActiveFirstOption={false}
				disabled={this.props.disabled}
				onChange={this.props.procedureDayChanged}
				placeholder={i18Get("Select Procedure", utils.getLanguage())}
				notFoundContent={i18Get("Not Found", utils.getLanguage())}
				optionFilterProp="children"
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			>
				{this.state.procedureDay}
			</Select>
		);
	}
}
