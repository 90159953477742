import React, { useEffect, useState } from "react";
import I18, { i18Get } from "i18";
import { useDispatch, useSelector } from "react-redux";
import healthPathwayAction from "actions/health-pathway";
import { Modal } from "antd";
import Config from "MOM-config";
import utils from "utils/utils";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function DiagramPreview(props) {
	const dispatch = useDispatch();
	const healthPathway = useSelector((state) => { return state.healthPathway;});
	const [diagramData, setDiagramData] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect( () => { 
		const metaData = props.code;
		dispatch(healthPathwayAction.getOneByCode(props.code, new Date().getTimezoneOffset(), metaData));
		setLoading(true);
	}, [props.code]);

	useEffect( () => { 
		if (healthPathway.fetchOneHealthPathwayByCode === "FULFILLED" && healthPathway.meta ===  props.code) {
			setDiagramData(healthPathway.healthPathwayByCode);
			dispatch(healthPathwayAction.cleargetOneByCode());
			setLoading(false);
		}
	}, [healthPathway]);

	return (
		props.popupDisabled !== true ? 
			<Modal 
				className="preview_diagram" 
				confirmLoading={true} 
				title={i18Get(`${diagramData.hospitalId ? diagramData.hospitalCode : diagramData.hospitalName ? diagramData.hospitalName : "MOM"} ${diagramData.name ? diagramData.name : ""}`, utils.getLanguage())} 
				open={props.open && diagramData.imagePath} 
				onOk={""} 
				onCancel={() => props.onCancel(false)} width={"80vw"} 
				footer="">
				<p>
					<div className="w_100 text_align_center">
						{ !loading ? diagramData.imagePath && <img src={`${Config.siteUrl.replace("/#", "")}${diagramData.imagePath}`}/> : <Spin indicator={antIcon} /> }
					</div>
				</p>
			</Modal> : <>{ !loading ? diagramData.imagePath && <img src={`${Config.siteUrl.replace("/#", "")}${diagramData.imagePath}`} width={"100%"} height={"auto"}/>  : <Spin indicator={antIcon} /> } </>
	);
}

export default DiagramPreview;