import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../../router/locations";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import Loader from "../../../../../../../../plug/loader";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import { Modal } from "antd";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import column from "./column";
import AssignAndApproveModel from "./assign-and-approve-model";

class Items extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemList: [],
			totalPages: 1,
			loading: false,
			search: "",
			deleteItemsVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedItem: {},
			sort: "rank",
			desc: 0,
			rank: {
				min: 1,
				max: 1
			},
			disableRankUpdate: false,
			status: "ALL",
			hospitalId: "",
			modelVisible: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelList });
	}

	editClicked(item) {
		return () => {
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}/${item.id}${this.props.location.search}`);
		};
	}

	deleteClicked(item) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedItem: item, deleteItemsVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deleteItemsVisible: false });
	}

	deleteClinicalPathwayItem() {
		this.props.dispatch(adminClinicalPathwayActions.deleteClinicalPathwayItems(this.state.deletedItem.id));
		this.setState({ deleteItemsVisible: false, deletedItem: {} });
	}

	getTableOptions() {
		return { page: this.state.page, pageSize: this.state.pageSize, sort: this.state.sort, desc: this.state.sort };
	}

	approveClicked(item) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, itemId: item.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	rankIncreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) - 1,
				clinicalPathwayType: this.state.clinicalPathwayType
			};
			this.props.dispatch(adminClinicalPathwayActions.updateSubStepRank(value.id, payload));
		};
	}

	rankDecreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) + 1,
				clinicalPathwayType: this.state.clinicalPathwayType
			};
			this.props.dispatch(adminClinicalPathwayActions.updateSubStepRank(value.id, payload));
		};
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort ? this.state.sort : ""}&desc=${this.state.desc}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.search ? `&search=${this.state.search}` : "";
		this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayItems(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.itemList, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this),
					column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.approveClicked.bind(this), this.props.isPermissionGranted), true, false,
					this.onPageChange.bind(this), "ITEMS", "", this.onPageSizeChange.bind(this))}
				minRows={17} />
		);
	}

	render() {
		if ((this.state.search !== this.props.search) || (this.state.hospitalId !== this.props.hospitalId) || (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.itemList = [];
			this.state.loading = true;
			this.state.page = 1;
			this.fetchDataFromServer();
		}
		if (this.props.adminClinicalPathway.fetchAllClinicalPathwayItemsCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminClinicalPathway.itemList.count / this.state.pageSize);
			this.state.itemList = this.props.adminClinicalPathway.itemList.data;
			this.props.getCount(this.props.adminClinicalPathway.itemList.count);
			this.props.dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwayItems());
		}
		if (this.props.adminClinicalPathway.deleteClinicalPathwayItemsCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(adminClinicalPathwayActions.clearDeleteClinicalPathwayItems());
		}
		return (
			<div>
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Item", utils.getLanguage())}
					visible={this.state.deleteItemsVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteClinicalPathwayItem.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedItem.name}</span> ?
					</div>
				</Modal>
				{this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							clinicalPathwayType={this.props.clinicalPathwayType && (this.props.clinicalPathwayType !== "ALL") ? this.props.clinicalPathwayType : ""}
							refreshCompleted={this.refreshCompleted}
							itemId={this.state.itemId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

Items.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminClinicalPathway: store.adminClinicalPathway
}))(Items));    