import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import communitySectionAction from "../../../../../../../../actions/community-section";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button } from "antd";
import { Specialities } from "../../../../uiComponents";

class EditCommunitySection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySection: {},
			loading: false,
			specialities: []
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(communitySectionAction.getOne(this.props.communitySectionData.id));
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.communitySection.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.communitySection.code = text;
		} else {
			prevState.communitySection.code = this.state.communitySection.code ? this.state.communitySection.code : "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	validate() {
		const prevState = this.state;
		if (!prevState.communitySection.name || !prevState.communitySection.name.trim() || !utils.checkNameRegex(prevState.communitySection.name)) {
			prevState.invalidName = true;
		}
		if (!prevState.communitySection.code || !prevState.communitySection.code.trim()) {
			prevState.invalidCode = true;
		}

		if (!prevState.specialities || this.createPayloadData(prevState.specialities) == 0) {
			prevState.invalidSpecialities = true;
		}

		if (prevState.invalidName || prevState.invalidCode || prevState.invalidSpecialities) {
			this.setState(prevState);
			return false;
		}
        
		return true;
	}
    
	saveClicked() {
		if (this.validate()) {
			this.setState({ loading: true });
			const payload = {
				name: this.state.communitySection.name,
				code: this.state.communitySection.code,
				specialityIds: this.createPayloadData(this.state.specialities)

			};
			this.props.dispatch(communitySectionAction.updateCommunitySection(this.state.communitySection.id, payload));  
		}     
	}


	handleCancel() {
		this.props.visibleEditModal(false);
	}

	//This is for converting server data to option list based on the id and name
	selectOptions(arrayData) {
		const options = [];
		if (arrayData && arrayData.length > 0) {
			arrayData.map((data) => {
				if (data.id)
				{options.push({ value: data.id, label: data.name });}
			});
		}
		return options;
	} 
	//Fetch IDs from multi select checkbox
	createPayloadData(data) {
		const dataList = [];
		data.map(data => {dataList.push(data.value);});
		return dataList;
	}
	onChangeSpecialities = e => {
		const prevState = this.state;
		prevState.specialities = e;
		prevState.invalidSpecialities = false;
		this.setState(prevState);
	};
    
	render() {
		if (this.props.communitySection.fetchOneCommunitySectionComplete === "FULFILLED") {
			this.state.communitySection = this.props.communitySection.communitySection;
			this.state.specialities = this.selectOptions(this.state.communitySection.specialities);
			this.props.dispatch(communitySectionAction.clearGetOne());
		}
		if (this.props.communitySection.fetchOneCommunitySectionComplete === "REJECTED") {
			this.props.dispatch(communitySectionAction.clearGetOne());
		}
		if (this.props.communitySection.updateCommunitySectionComplete === "FULFILLED") {
			this.props.dispatch(communitySectionAction.updateCommunitySectionCompleted());
			this.handleCancel();
			this.props.createClassCompleted(true);
			this.setState({ loading: false });
		}
		if (this.props.communitySection.updateCommunitySectionComplete === "REJECTED") {
			this.props.dispatch(communitySectionAction.updateCommunitySectionCompleted());
			this.setState({ loading: false });
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Community Section", utils.getLanguage())}
					visible={this.props.editModal}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}
					footer={[
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.saveClicked.bind(this)}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}>
					<div className="pole_create_box create_category_personal_width_100 padding-0">
						<div className="row">
							<div className="col-md-12 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Code" /></label>
									<NameInput
										value={this.state.communitySection.code}
										inputChanged={this.codeChanged.bind(this)}
										maxLength={2}
										disabled={true}
									/>
									{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Enter valid code" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Community Name" /></label>
									<NameInput
										value={this.state.communitySection.name}
										inputChanged={this.nameChanged.bind(this)}
										maxLength={60}
									/>
									{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Specialities" /></label>
									<Specialities
										value={this.state.specialities}
										onChange={this.onChangeSpecialities}
										filter={{ typeActivity: "CLINICAL_PATHWAY" }}
									/>
									{this.state.invalidSpecialities && <span className='invalid_right fixed_label'><I18 tkey="Field should not be empty " /></span>}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

EditCommunitySection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	actions: store.actions,
	communitySection: store.communitySection
}))(EditCommunitySection)); 