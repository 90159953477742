import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import { Collapse, Checkbox } from "antd";
import utils from "../../../../../../../utils/utils";
const { Panel } = Collapse;

class ShowOrHideSteps extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkList: [],
			list: [],
			isCheckList: 0
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.isCheckList != utils.sameDropper()) {
			this.setState({ isCheckList: this.props.isCheckList });
		}
	}

	checkAllCheckListItem(type, checked, subStep, problem) {
		if (subStep && subStep.isCheckList && subStep.isHeading) {
			problem.additionalInfo.map(function (data) {
				if (subStep.id == data.checkListTreatmentId) {
					data["checked"] = checked;
				}

			});

		}
	}

	onCheckBoxChange = (e, value, type, subStep) => {
		let index;
		if (type === "step") {
			index = this.state.checkList.indexOf(value.problemId);
		}
		if (type === "subStep") {
			index = this.state.checkList.indexOf(value.id);
		}
		const prevState = this.state;
		if (e.target.checked) {
			if (index === -1) {
				if (type === "step") {
					prevState.checkList.map(function (e) {
						if (e.problem === value.problem) {
							e["checked"] = true;
							e.additionalInfo.map(function (data) {
								data["checked"] = true;
							});
						} else if (prevState.isCheckList) {
							e["checked"] = false;
							e.additionalInfo.map(function (data) {
								data["checked"] = false;
							});
						}
					});
				}
				if (type === "subStep") {
					prevState.checkList.map(function (e) {
						if (e.problem === value.problem && e.problemId === value.problemId) {
							e["checked"] = true;
							e.additionalInfo.map(function (data) {
								if (data.name === subStep.name && data.id === subStep.id) {
									data["checked"] = true;
								}
							});
						}
					});
				}

			}
		}
		else {
			if (type === "step") {
				prevState.checkList.map(function (e) {
					if (e.problem === value.problem && e.problemId === value.problemId) {
						e["checked"] = false;
						e.additionalInfo.map(function (data) {
							delete data["checked"];
						});
					}
				});

			}
			if (type === "subStep") {
				prevState.checkList.map(function (e) {
					if (e.problem === value.problem && e.problemId === value.problemId) {
						e["checked"] = true;
						e.additionalInfo.map(function (data) {
							if (data.name === subStep.name && data.id === subStep.id) {
								delete data["checked"];
							}
						});
					}
				});
				// prevState.checkList.push({ problemId: value[1].problemId, checked: true, day: value.day, subStep: this.state.list });
			}
		}
		this.setState(prevState);
		this.props.getSelectedComponents(this.state.checkList);
		this.checkAllCheckListItem(type, e.target.checked, subStep, value);
	};


	getSubproblem(subStep) {
		if (subStep) {
			return <span> <I18 tkey={subStep.interventionSummaryName ? subStep.interventionSummaryName : ""} /></span>;
		}
	}

	subStepDetails(additionalInfo, parentProblemId, step) {
		return (
			<div className="pl-25">
				{additionalInfo && additionalInfo.length ?
					additionalInfo.map((subStep, index) => {
						//if (subStep.modelTreatmentId) { return ''; }
						return (<div className={`${subStep.modelTreatmentId ? "model_attached_td" : ""}`} key={index}>
							<div className=""><div></div>

								{subStep.isCheckList && !subStep.isHeading ?
									<div className={subStep.isCheckList ? "check_list check_list_bold check_list padding-left-20" : ""}>
										<Checkbox disabled checked={this.checkSubStep(this.state.checkList, subStep, parentProblemId)} />&nbsp;&nbsp;{this.getSubproblem(subStep)}</div>
									:
									<div className={subStep.isCheckList ? "check_list check_list_bold " : ""}>
										<Checkbox
											checked={this.checkSubStep(this.state.checkList, subStep, parentProblemId)}
											onChange={(e) => this.onCheckBoxChange(e, step, "subStep", subStep)} />&nbsp;&nbsp;{this.getSubproblem(subStep)}
									</div>
								}
							</div>
						</div>);
					})
					: ""}

			</div>
		);
	}

	checkStepData(problemId) {
		if (this.state.checkList && this.state.checkList.length) {
			const index = this.state.checkList.findIndex(value => value.problemId === problemId && (value.checked ? value.checked === true : null));
			if (index !== -1) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	checkSubStep(checkList, subStep, parentProblemId) {
		if (checkList && checkList.length) {
			const checkListIndex = checkList.findIndex(row => row.problemId === parentProblemId);
			if (checkListIndex < 0) {return false;}
			const index = checkList[checkListIndex].additionalInfo.findIndex(value => value.id === subStep.id && (value.checked ? value.checked === true : null) && value.name === subStep.name);
			if (index !== -1) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	getPannelHeader = (step) => {
		return (
			<React.Fragment>
				<Checkbox
					checked={this.checkStepData(step.problemId)}
					onClick={event => event.stopPropagation()}
					onChange={(e) => this.onCheckBoxChange(e, step, "step")} />&nbsp;&nbsp; <span className={`${step.modelTreatmentId ? "model_attached_td" : ""}`}><I18 tkey={step.problem} />
				</span>
			</React.Fragment>
		);
	};

	render() {

		this.state.checkList = this.props.checkList ? this.props.checkList : [];
		return (
			<Collapse ghost={true} >
				{
					this.props.steps.map((step, index) => {
						return <Panel header={this.getPannelHeader(step)} key={index} >
							<div className={step.modelTreatmentId ? "model_attached_td" : ""}>
								{this.subStepDetails(step.additionalInfo, step.problemId, step)}
							</div>
						</Panel>;
					}

					)
				}
			</Collapse>
		);
	}

}

ShowOrHideSteps.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(ShowOrHideSteps));