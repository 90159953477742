import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import Interventions from "./intervention";
import InterventionDetail from "./details";
import typeActivity from "../../../../../constants/typeActivity.json";
// import RecommendStandard from './recommend-standard';

class Intervention extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route exact path={locations.interventionDetails} render={() => <InterventionDetail typeActivity={typeActivity.INTERVENTION.toUpperCase()} />} />
				<Route path={locations.interventionsSheets} component={Interventions} />
				<Redirect to={`${locations.interventions_plain}/${this.props.match.params.organization}${locations.timeSheets}`} />
			</Switch>
		);
	}
}

Intervention.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Intervention));
