import React, { useEffect, useState } from "react";
import { LoadingOutlined, CopyOutlined } from "@ant-design/icons";
import { Collapse, Spin, Tooltip } from "antd";
import I18, { i18Get } from "i18";
import { User, LocationDot, Clock } from "utils/icons";
const { Panel } = Collapse;
import YoutubeEmbed from "./YoutubeEmbed";
import utils from "utils/utils";
import Config from "MOM-config";
import DiagramPreview from "./diagramPreview";
import ViewLinkedItem from "mom-ui-commons/build/components/reGroupment/viewLinkedHP";
import utilsActivities from "../../../plugin/utils";
const CollapsePannel = (props) => {
	const [viewImage, setViewImage] = useState(false);
	useEffect(() => {
		let timer;
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			const currentPos = document.getElementById(`panel-${props.currentNodeId}`)?.offsetTop;
			if (currentPos) {
				document.getElementsByClassName("sideBar-body")[0]?.scrollTo({
					top: currentPos - (props.staticDiagram ? 70 : 170),
					behavior: "smooth"
				});
			}
		}, 300);
			
	}, [props.currentNodeId, props.data, props.getDiagramData]);

	const openInIfram = (URL) => {

		const embedId = findYoutubeLink(URL);
		if (embedId) {
			return <YoutubeEmbed embedId={embedId} />;
		} else {
			// return <iframe className="ifram-preivew" src={URL}  ></iframe>;
		}
	};

	const convertValue = (value, selectedType) => {
		const isGrouped = props.getDiagramData?.groupId || null;
		if (isGrouped) {
			return convertMinutes(value);
		} else {
			return convertNonGroupMinit(value, selectedType);
		}
	};

	const convertNonGroupMinit = (value, selectedType) => {

		let newValue = 0 ;
		if (selectedType && selectedType === "MINUTES") {
			newValue = value;
		} else if (selectedType && selectedType === "HOURS") {
		   newValue = (value / 60).toFixed(2).replace(/\.?0+$/, "");
		} else if (selectedType && selectedType === "DAYS") {
			newValue = (value / (60 * 24)).toFixed(2).replace(/\.?0+$/, "");
		}
		return parseFloat(newValue);
	};
	const convertMinutes = (minutes) => {
		const day = minutes / 1440;
		const hour =  minutes / 60;
		if (day >= 1) {
			return day.toFixed(2);
		} else if (hour >= 1) {
			return hour.toFixed(2);
		} else {
			return parseFloat(minutes).toFixed(2);
		}
	  };

	  const findTypeShortName = (minutes) => {
		const day = minutes / 1440;
		const hour =  minutes / 60;
		if (day >= 1) {
			return "DAYS";
		} else if (hour >= 1) {
			return "HOURS";
		} else if (minutes > 0) {
			return "MINUTES";
		}
	  };

	const validationDuration = () => {
		const defaultType = "MINUTES";
		const isGrouped = props.getDiagramData?.groupId || null;
		const max = convertValue(props.getDiagramData?.durationMaximumMinutes ?? 0, props.getDiagramData?.durationMaximumType ?? defaultType);
		const min = convertValue(props.getDiagramData?.durationMinimumMinutes ?? 0, props.getDiagramData?.durationMinimumType ?? defaultType);
		const duration = convertValue(props.getDiagramData?.durationInMinutes ?? 0, props.getDiagramData?.durationType ?? defaultType);
		const minType = isGrouped ?  findTypeShortName(props.getDiagramData?.durationMinimumMinutes ?? 0) : props.getDiagramData?.durationMinimumType;
		const maxType = isGrouped ?  findTypeShortName(props.getDiagramData?.durationMaximumMinutes ?? 0) : props.getDiagramData?.durationMaximumType;
		const freType = isGrouped ?  findTypeShortName(props.getDiagramData?.durationInMinutes ?? 0) : props.getDiagramData?.durationType;

		if (max) {
			return <div className="w_100 margin-bottom-3">&nbsp;{min ? <><I18 tkey={min} />&nbsp;<I18 tkey={minType || defaultType} /> &lt;=</> : ""} <strong><I18 tkey="Duration" /></strong> &lt;= &nbsp;<I18 tkey={max} /> <I18 tkey={ maxType  || defaultType} />&nbsp;{duration ? <>(<I18 tkey="Frequent" /> = <I18 tkey={duration} /> <I18 tkey={ freType  || defaultType} />)</> : ""}</div>;
		} else if (min) {
			return <div className="w_100 margin-bottom-3">&nbsp; <strong><I18 tkey="Duration" /></strong> &gt;= &nbsp;<I18 tkey={min} /> <I18 tkey={minType  || defaultType} />&nbsp;{duration ? <>(<I18 tkey="Frequent" /> = <I18 tkey={duration} /> <I18 tkey={ freType || defaultType} />)</> : ""}</div>;
		} else if (duration) {
			return <div className="w_100 margin-bottom-3">&nbsp; <strong><I18 tkey="Duration" /></strong> =&nbsp;<I18 tkey={duration} /> <I18 tkey={ freType || defaultType} /></div>;
		} else {
			return "";
		}
	};

	const findYoutubeLink = (url) => {
		if (url !== "") {
			const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
			const match = url.match(regExp);
			if (match && match[2].length === 11) {
				console.log("Welcome to Programiz!", match[2]);
				return match[2];
			} else {
				return false;
			}
		} 
	};

	return <> 
		<div style={{ backgroundColor: props.data.bgColor }} >
			<p style={{ backgroundColor: props.data.bgColor }} >
				{/* {props.data.intervenants && props.data.intervenants.length > 0 ? <ul className="bullet-list margin-bottom-10">
				{
					props.data.intervenants.map(e => <li key={e.label}>{e.label}</li>)}
			</ul> : ""} */}
				{props.loading ? <div className="text_align_center"><Spin indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />} /></div> : ""}
				{props.data.interventionDetails ? <h5 className="padding-bottom-3">{props.data.interventionDetails}</h5> : "" }
				{props.getDiagramData && props.getDiagramData.intervenants && props.getDiagramData.intervenants.length > 0 && props.data.intervenants ? 
					<div className="w_100 margin-bottom-3"><User width={20} height={15} /><span>: &nbsp;
						{props.getDiagramData.intervenants.length > 0 && props.getDiagramData.intervenants.map((e, i) => `${e.name}${props.getDiagramData.intervenants.length !== (i + 1) ? ", " : "" }` )}
					</span></div> : "" }
				{props.data.location ? <div className="w_100 margin-bottom-3"><LocationDot width={20} height={15} /><span>: &nbsp;
					{props.data.location}
				</span></div> : "" }
				{props.data.durationOfTheTask ? <div className="w_100 margin-bottom-3"><Clock width={22} height={15} /><span>: &nbsp;
					{props.data.durationOfTheTask}
				</span></div> : "" }
				{props.getDiagramData && props.getDiagramData.unitCost ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Unit Cost" />: &nbsp;</strong>{utilsActivities.replaceComa(props.getDiagramData.unitCost)}</div> : ""}
				<ViewLinkedItem data={ props.getDiagramData }  >{validationDuration()}</ViewLinkedItem>
				{props.data.expectedResults ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Exp  Result" />: &nbsp;</strong>{props.data.expectedResults}</div> : ""}
				{props.data.argumentation ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Arg. & Found" />: &nbsp;</strong>{props.data.argumentation}</div> : ""}
				{props.data.risks ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Risks(s)" />: &nbsp;</strong>{props.data.risks}</div> : "" }
				{props.data.reference ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Reference" />: &nbsp;</strong>{props.data.reference}</div> : ""}
				{props.getDiagramData && <>
					{props.getDiagramData.linkType && props.getDiagramData.linkType !== "EXTERNAL_URL" ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Link Type" />: &nbsp;</strong>
						<I18 tkey={props.getDiagramData.linkType ? props.getDiagramData.linkType.replace("_", " ") : ""} />
						</div> : ""}
					{props.getDiagramData.linkType && props.getDiagramData.linkTo  ? 
						<div className="w_100">
							{props.getDiagramData.linkType === "EXTERNAL_URL" && props.getDiagramData.linkTo ? openInIfram(props.getDiagramData.linkTo) : "" }

							<a href={props.getDiagramData.linkTo} target="_blank" rel="noreferrer" >
									{props.getDiagramData.linkType === "EXTERNAL_URL" ? "Open link in new tab": `${props.getDiagramData?.linkedItem?.hospitalCode ?? "MOM"} ${props.getDiagramData?.linkedItem?.name}` }
									</a>

							{props.getDiagramData.linkType === "EXTERNAL_URL" && props.getDiagramData.linkTo ? 
								<Tooltip placement="top" title={i18Get("Copy to clipboard", utils.getLanguage())} >
									<CopyOutlined className="margin-left-10" onClick={ 
										() => utils.copyToclipboard(
											props.getDiagramData.linkType === "EXTERNAL_URL" ? props.getDiagramData.linkTo : `${Config.siteUrl}${props.getDiagramData.linkType ? props.getDiagramData.linkType.replace("_", " ") : ""} `
										)
									} />
								</Tooltip>
								: ""
							}
						</div> : ""
					}
					{props.getDiagramData.linkType === "HEALTH_PATHWAY" ? 
						<div className="w_100" onClick={ () => setViewImage(true)}>
							<DiagramPreview popupDisabled={true} code={props.getDiagramData?.linkedItem?.code} />
						</div>
						: "" }
					{!props.getDiagramData?.linkTo && props.getDiagramData?.linkedItem ? <div className="w_100">{ props.getDiagramData?.linkedItem ? props.getDiagramData?.linkedItem?.name : "" }</div> : ""}
				</>}
				{props.getDiagramData &&  props.getDiagramData.types && props.getDiagramData.types.length > 0 && props.data.type ? 
					<h5 className="padding-bottom-3 padding-top-3"><strong><I18 tkey="Problem Type" />: &nbsp;</strong></h5> 
					: "" }
				
				{props.data.type && props.data.type.length > 0 ? <ul className="bullet-list margin-bottom-3">
					{
						props.getDiagramData && props.getDiagramData.types.length > 0 && props.getDiagramData.types.map((e, i) => `${e.problemType}${props.getDiagramData.types.length !== (i + 1) ? ", " : "" }` )
					}
				</ul> : ""}
				{props.data.problems ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Problem" />: &nbsp;</strong>{props.data.problems}</div> : ""}
				{props.data.data ? <div className="w_100 margin-bottom-3"><strong><I18 tkey="Data" />: &nbsp;</strong>{props.data.data}</div> : ""}
				{props.getDiagramData && props.getDiagramData.step && props.data.steps ? <div className="w_100 margin-top-5 margin-bottom-3"><strong><I18 tkey="Step" />: &nbsp;</strong> 
					{`${props.data.steps ? 
						props.getDiagramData.step && props.getDiagramData.step.name
						: ""} 
				${props.data && props.getDiagramData && props.data.subSteps ? `(${ props.getDiagramData.substep && props.getDiagramData.substep.name}) ` : ""}` }</div> 
					: ""
				}
			</p>
		</div>
		{viewImage ? <DiagramPreview onCancel={setViewImage} open={viewImage} code={props.data.linkedItemCode} /> : "" }
	</>;

};

export default CollapsePannel;