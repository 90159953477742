import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import locations from "../../../../../router/locations";
import { DebounceInput } from "react-debounce-input";
import Family from "../administration/activities/activity-families/selectFamilyForSpeciality";
import Speciality from "../administration/activities/specialities/selectSpecialities";
import StatusDropDown from "../intervention/statusDropDown";
import InterventionStatus from "../intervention/intervention/intervention-status-filter";
import HospitalSelect from "../administration/activities/specialities/selectHospital";
import { statusList } from "../../../../../constants";
import { Modal, Popover } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, FilterOutlined } from "@ant-design/icons";
import anaesthesiaAction from "../../../../../actions/anaesthesia";
import Loader from "../../../../../plug/loader";
import ReactTable from "../../../../../plug/reactTable";
import events from "../../../../../actions/events";
import screenId from "../../../../../router/screenIdList";
import column from "./column";
import AnaesthesiaEditModel from "./anaesthesia-edit-model";
import AssignAndApproveModel from "./assign-and-approve-model";
import AnaesthesiaDeleteModel from "./aneasthesia-delete-model";

class AnaesthesiaList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilter: false,
			filter: "",
			organization: "mom",
			count: 0,
			desc: 0,
			status: "ALL",
			waitingForApprovalStatus: "ALL",
			hospital: "",
			editModel: false,
			modelVisible: false,
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			anaesthesia: [],
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			ignore: false,
			deleteModalVisible: false,
			isAneasthesiaLinked: false,
			aneasthesiaLinks: []
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillUpdate() {
		if (this.state.organization !== this.props.match.params.organization) {
			document.getElementsByClassName("main_container_parent")[0].scrollTo(0, 0);
			this.state.organization = this.props.match.params.organization;
			this.state.speciality = false;
			this.state.family = false;
			this.state.anaesthesiaCount = false;
			this.state.anaesthesia = [];
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.anaesthesiaList });
		this.state.organization = this.props.match.params.organization;
	}

	filterChanged(e) {
		this.state.filterValue = e.target.value;
		this.state.page = 1;
		this.fetchDataFromServer();
	}

	createClicked() {
		this.state.ignore = true;
		this.props.history.push(`${locations.anaesthesia_plain}/${this.state.organization}${locations.create}`);
	}

	filterClicked() {
		this.setState({
			showFilter: !this.state.showFilter,
			speciality: "",
			family: ""
		});
	}

	clearFilter() {
		const prevState = this.state;
		prevState.family = false;
		prevState.speciality = false;
		prevState.status = "ALL";
		prevState.waitingForApprovalStatus = "ALL";
		prevState.hospital = false;
		prevState.filterValue = "";
		this.setState(prevState);
		this.fetchDataFromServer();
	}

	getFilter() {
		return (
			<div>
				<div className="row  margin-top-25">
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_spl margin-top-7 label_name"><I18 tkey="Speciality" /></span>
							<Speciality data={this.state.speciality ? this.state.speciality : ""} specialityChanged={this.specialityChanged.bind(this)} organization={this.state.organization} typeActivity='ANAESTHESIA' hideLabel={true} />
						</div>
					</div>
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name"><I18 tkey="Family" /></span>
							<Family data={this.state.family ? this.state.family : ""} onFamilyChanged={this.familyChanged.bind(this)} organization={this.state.organization} typeActivity='ANAESTHESIA' specialityId={this.state.speciality ? this.state.speciality.value : ""} hideLabel={true} />
						</div>
					</div>
					<div className="col-md-4 col-sm-4 ">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name">
								<I18 tkey="Status" />
							</span>
							<StatusDropDown 
								data={this.state.status} 
								onStatusChange={this.statusChanged.bind(this)}/> 
						</div> 
					</div>
				</div>
				<div className="row margin-top-10">
					<div className="col-md-4 col-sm-4 ">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_status  margin-top-7 label_name">
								<I18 tkey="Hospital" />
							</span>
							<HospitalSelect
								data={this.state.hospital}
								hospitalChanged={this.hospitalChanged.bind(this)} />
						</div>
					</div>
					<div className="col-md-4 col-sm-4 ">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name">
								<I18 tkey="Anaesthesia Status" />
							</span>
							<InterventionStatus 
								data={this.state.waitingForApprovalStatus}
								isAnaesthesia={true} 
								dropdownChanged={this.waitingForApprovalStatusChanged.bind(this)}/> 
						</div> 
					</div>
					<div className="col-md-4 col-sm-4">
						<div className="clear_fliter text_align_right">
							<a className="clear_filter_a" onClick={this.clearFilter.bind(this)}><I18 tkey="Clear Filter" /></a>
						</div>
					</div>
				</div>
			</div>
            
		);
	}

	specialityChanged(e) {
		this.state.speciality = e;
		this.state.family = "";
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	familyChanged(e) {
		this.state.family = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	statusChanged(e) {
		this.state.status = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	waitingForApprovalStatusChanged(e) {
		this.state.waitingForApprovalStatus = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	hospitalChanged(e) {
		this.state.hospital = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	getContent(data) {
		return (
			<div className="padding-10 success_error_popover font_size_12px">
				<div className="display_inline_flex w_100">
					<span className="success_error_key bold"><I18 tkey="Consumable Count"/></span>
					<span className="">{data.wfaConsumableCount ? data.wfaConsumableCount : 0}</span>
				</div>
			</div>
		);
	}

	render() {
		if (this.state.organization !== this.props.match.params.organization) {
			this.state.organization = this.props.match.params.organization;
			this.state.speciality = "";
			this.state.family = "";
			this.state.showFilter = false;
			this.state.loading = true;
			this.state.sort = "";
			this.fetchDataFromServer();
		}
		if (this.props.anaesthesia.isFetchAllAnaesthesiaCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.anaesthesia = this.props.anaesthesia.anaesthesiaList;
			this.state.anaesthesiaCount = this.props.anaesthesia.anaesthesiaCount;
			this.state.totalPages = parseInt(Math.ceil(this.props.anaesthesia.anaesthesiaCount / this.state.pageSize));
			this.props.dispatch(anaesthesiaAction.clearFetchAllAnaesthesia());
		}
		// if (this.props.anaesthesia.deleteAnaesthesiaStatus === "FULFILLED") {
		// 	this.props.dispatch(anaesthesiaAction.removeAnaesthesiaCompleted());
		// 	this.fetchDataFromServer();
		// }
		// if (this.props.anaesthesia.deleteAnaesthesiaStatus === "REJECTED") {
		// 	this.props.dispatch(anaesthesiaAction.removeAnaesthesiaCompleted());
		// }

		if (this.props.anaesthesia.getAneasthesiaLinkStatus === "FULFILLED") {
			this.setState({ aneasthesiaLinks: this.props.anaesthesia.aneasthesiaLinkList });
			if (this.props.anaesthesia.aneasthesiaLinkList.length > 0) {
				this.setState({  isAneasthesiaLinked: true });
			}
			this.props.dispatch(anaesthesiaAction.getAnaesthesiaLinkCompleted());
		}
		if (this.props.anaesthesia.getAneasthesiaLinkStatus === "REJECTED") {
			this.setState({  isAneasthesiaLinked: false });
			this.props.dispatch(anaesthesiaAction.getAnaesthesiaLinkCompleted());
		}

        
		return (
			<div className="main_overview_section">
				<div className="main_overview_top_section di w_100 float_left padding-top-15">
					<div className="row">
						<div className="col-md-4 col-sm-4">
							<div className="record_container w_100 margin-top-6">
								<div className="di number_of_records">
									<span><I18 tkey="Number of Records" />:<b>{this.state.anaesthesiaCount}</b></span>
								</div>
							</div>
						</div>
						<div className="col-md-8 d-flex align-item-center justify-content-end  ">

							<div className='text_align_right margin-right-10'>
								<button className="common_button" type="button" onClick={this.createClicked.bind(this)}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE ANAESTHESIA" /></button>
							</div>
							<div className="create_interventions text_align_right mt10_mobile">
								<div className="" onClick={this.filterClicked.bind(this)}>
									<FilterOutlined  className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
								</div>
							</div>
						</div>
					</div>
					{this.state.showFilter && this.getFilter()}
				</div>
				<div className="tab_section padding-top-15 di w_100">
					<ul className="nav nav-tabs anesthesia_search">
						<li className="float_right_li">
							<a className="padding-0 tab_search">
								<DebounceInput  
									className="filter_input_design form-control"                        
									debounceTimeout={750}
									placeholder={i18Get("Search...", utils.getLanguage())}
									value={this.state.filterValue}
									onChange={this.filterChanged.bind(this)}
								/>
							</a>
						</li>
					</ul>
					<div className="tab-content table_scroll_container">
						<div id="home" className="tab-pane fade in active">
							{this.getList()}
						</div>
						{this.state.modelVisible ?
							<div>
								<AssignAndApproveModel
									refreshCompleted={this.refreshCompleted}
									treatmentId={this.state.treatmentId}
									visible={this.state.modelVisible} />
							</div>
							: ""
						}
						{this.state.editModel ?
							<div>
								<AnaesthesiaEditModel
									refreshCompleted={this.refreshCompleted}
									treatmentId={this.state.treatmentId}
									visible={this.state.editModel} />
							</div>
							: ""
						}
						{  this.state.deleteModalVisible ?
							<div>
								<AnaesthesiaDeleteModel
									deleteCompleted={this.deleteCompleted}
									treatmentId={this.state.treatmentId}
									visible={this.state.deleteModalVisible} 
									isAneasthesiaLinked={this.state.isAneasthesiaLinked}/>
							</div>
							: ""
						}
					</div>
				</div>
			</div>
		);
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	moveToDetails = (value) => {
		if (value.status === statusList.STATUS.values.APPROVED)
		{this.props.history.push(this.props.location.pathname + "/" + value.id + this.props.location.search);}
	};

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.anaesthesia, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this), column(this.getActionButton, this.moveToDetails), true, false,
					this.onPageChange.bind(this), "ANAESTHESIA",
					`-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll ${(this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "scanned_treatments_table" : this.props.organization === locations.tabMom ? "intervention_table" : "table_head_element_hide"}`,
					this.onPageSizeChange.bind(this))}
				getTrProps={function (state, rowInfo) {
					return {
						style: {
							background: rowInfo && rowInfo.index === this.state.activeCompany ? "#e8eaea7a" : ""
						}
					};
				}.bind(this)}
				defaultSorted={[
					{
						id: this.state.sort ? this.state.sort : "name",
						desc: this.state.desc ? true : false
					}
				]}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex} />
		);
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = false;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		if (this.state.ignore) {
			return;
		}
		let queryString = "";
		const organization = `${(this.state.organization ? this.state.organization.toUpperCase() : "MOM")}`;
		queryString += `offset=${this.state.page ? (this.state.page - 1) * this.state.pageSize : 0}&`;
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${this.state.desc ? `desc=${this.state.desc}&` : ""}`;
		queryString += this.state.filterValue ? `code=${encodeURIComponent(this.state.filterValue)}&name=${encodeURIComponent(this.state.filterValue)}&momCode=${encodeURIComponent(this.state.filterValue)}&momName=${encodeURIComponent(this.state.filterValue)}&` : "";
		queryString += this.state.speciality ? `specialityId=${this.state.speciality.value}&` : "";
		queryString += this.state.family ? `familyId=${this.state.family.value}&` : "";
		queryString += this.state.status ? `status=${this.state.status}&` : "";
		queryString += this.state.waitingForApprovalStatus ? `wfaStatus=${this.state.waitingForApprovalStatus}&` : "";
		queryString += this.state.hospital ? `hospitalId=${this.state.hospital.value}` : "";
		this.props.dispatch(anaesthesiaAction.fetchAllAnaesthesia(organization, queryString));
	}

	aneasthesiaLinkList = (row) => {
		this.setState({ treatmentId: row.id, deleteModalVisible: true });
		if (row.id) {
			this.props.dispatch(anaesthesiaAction.getAnaesthesiaLink(row.id));
		}
	};

	deleteClicked = (row) => {
		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to delete ?"),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Delete"),
			cancelText: i18Get("Cancel"),
			onOk: () => {
				if (row && row.id) {
					this.props.dispatch(anaesthesiaAction.removeAnaesthesia(row.id));
				}

			},
			onCancel: () => {
			}
		});
	};


	approveClicked(treatment) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, treatmentId: treatment.id });
		};
	}

	editClicked(treatment) {
		return (e) => {
			e.preventDefault();
			this.setState({ editModel: true, treatmentId: treatment.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false, editModel: false });
		this.fetchDataFromServer();
	};

	deleteCompleted = () => {
		this.setState({ deleteModalVisible: false });
		this.fetchDataFromServer();
	};

	getActionButton = (row) => {
		return (
			<div className="">
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
							: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
					}
				</div>
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<DeleteOutlined className="delete_color" type="delete" onClick={() => this.aneasthesiaLinkList(row.value)} />
							: ""
					}
				</div>
				<div className="min_width_12_px">
					{
						row.value.wfaConsumableCount ? 
							<Popover 
								content={this.getContent(row.value ? row.value : {})} 
								title={i18Get("Waiting For Approval Count", utils.getLanguage())}
								trigger="click" 
								placement="topRight">
								<a><img className="margin-left-10" src="/images/info.png" /></a>
							</Popover>
							: "" }
				</div>
			</div>
		);
	};

}

AnaesthesiaList.propTypes = {
	anaesthesia: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	anaesthesia: store.anaesthesia,
	translations: store.translations
}))(AnaesthesiaList));