import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import specialityModelActions from "../../../../../../../actions/speciality-model";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SpecialitySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			specialityData: {},
			speciality: [],
			offset: 0,
			numResults: 100,
			specialityId: "",
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.speciality = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.specialityId = this.props.specialityId;
		this.fetchSpecialityList();
	}

	renderspecialityOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.specialty) {
			this.state.specialty.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}

		if (!this.props.data) {
			const multiselect = this.props.assignTeam ? true : false;
			utils.setSingleDataDefault(prevState.options, this.props.specialityChanged, multiselect, this.props.setSingleDataDefault, this.props.autoSetSingleOne);
		}

		prevState.isListLoading = false;
		this.setState(prevState);
	}


	fetchSpecialityList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += "&status=APPROVED";
		queryString += this.props.typeActivity ? `&typeActivity=${this.props.typeActivity.toUpperCase()}` : "";
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += `${this.props.isCheckList ? "&extendedType=CHECKLIST" : ""}`;
		this.props.dispatch(specialityModelActions.getAll(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchSpecialityList(search);
			}, 800);
		}
	}

	onListScroll() {
		const offset = this.state.offset + this.state.numResults;
		this.setState({ ...this.state, offset }, () => { this.fetchSpecialityList(); });
	}

	render() {
		if (this.props.specialtyModel.isFetchAllSpecialtyCompleted === "FULFILLED") {
			this.props.dispatch(specialityModelActions.clearSpecialtyFetch());
			if (this.props.isCheckList) {
				this.state.specialty = this.props.specialtyModel.specialty;
			}
			else {
				this.state.specialty = unionWith(this.state.specialty, this.props.specialtyModel.specialty, isEqual);
			}

			this.setState({ ...this.state }, () => {this.renderspecialityOptions();});
		}
		if (this.props.specialityId !== this.state.specialityId || this.props.isCheckList !== this.state.isCheckList) {
			this.fetchSpecialityList();
			this.setState({ ...this.state, specialityId: this.props.specialityId, isCheckList: this.props.isCheckList });
		}
		return (
			<Select
				isDisabled={this.props.disabled}
				styles={customStyles}
				className="w_100 di"
				isMulti={this.props.assignTeam}
				defaultActiveFirstOption={false}
				value={this.props.data}
				onChange={this.props.specialityChanged}
				isSearchable
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Speciality", utils.getLanguage())} />
		);
	}
}

SpecialitySelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialtyModel: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	specialtyModel: store.specialtyModel,
	translations: store.translations
}))(SpecialitySelect));
