import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Intervention from "../v4/intervention";
import ExtClinicalPathway from "./extClinicalPathway/extClinicalPathway";
import Definition from "./definition";
import Eligibility from "./eligibility";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import AuthorsAndActivities from "./authors-activities";
import "./v5-details.css";
import Staff from "./staff";
import Loader from "../../../../../../../plug/loader";
import Anaesthesia from "./anaesthesia";
class clinicalPathwayDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervention: {},
			steps: [],
			checkList: [],
			treatmentId: null,
			search: "",
			checkedAll: false,
			searchField: "",
			searchType: "EQUALS",
			showAssignAndApprove: false,
			showIntervention: false,
			selElementId: null,
			type: null,
			name: null,
			code: null,
			scrollPosition: false,
			clpStepFilter: "ALL_ITEMS",
			loading: true,
			showAnaesthesia: false
		};
	}

	editClicked(link) {
		this.props.history.push(`${this.props.location.pathname}/sub_clinical_pathway/${link}`);

	}

	getExtClinicalPathway() {
		return (<ExtClinicalPathway
			search={encodeURIComponent(this.props.search.trim())}
			searchField={this.props.searchField ? this.props.searchField : ""}
			searchType={this.props.searchType}
			refreshClinicalPathwayDetail={this.refreshClinicalPathwayDetail.bind(this)}
			steps={this.state.steps ? this.state.steps : ""}
			treatmentId={this.props.treatmentId}
			onSubStepCheckBoxChange={this.onSubStepCheckBoxChange}
			clearCheckList={this.clearCheckList}
			onCellCheckBoxChange={this.onCellCheckBoxChange}
			checkList={this.state.checkList}
			checkedAll={this.state.checkedAll}
			hideSteps={this.props.hideSteps}
			tabChanged={this.props.tabChanged}
			clinicalPathwayType={this.props.clinicalPathwayType}
			clinicalPathway={this.props.clinicalPathway}
		/>);
	}

	refreshClinicalPathwayDetail() {
		this.props.dispatch(clinicalPathwayDetailsAction.fetchAllExtendedClinicalPathway((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), encodeURIComponent(this.props.search.trim()), this.props.searchField && this.props.searchField !== "SEARCH_ALL_FIELDS" ? this.props.searchField : "", this.props.searchType, this.props.clpStepFilter));
		this.state.steps = [];
	}

	onCellCheckBoxChange = (e, item) => {
		if (!e.target.checked) {
			this.setState({ ...this.state, checkedAll: e.target.checked });
		}
		this.checkListChanged(item.id);
	};


	onSubStepCheckBoxChange = (e, subStep) => {
		if (e.target.checked) {
			const itemNotInCheckList = subStep && subStep.data && subStep.data.filter(row => !this.state.checkList.includes(row.id));
			itemNotInCheckList.forEach(row => this.checkListChanged(row.id, row.isCheckList, row.modelTreatmentId));
		} else {
			this.state.checkList = [];
		}
		this.setState({ ...this.state, checkedAll: e.target.checked });
	};

	clearCheckList = () => {
		this.setState({ checkList: [], checkedAll: false });
	};

	checkListChanged(id, isCheckList, modelTreatmentId) {
		if (!isCheckList && !modelTreatmentId) {
			const index = this.state.checkList.indexOf(id);
			const prevState = this.state;
			if (index === -1) {
				prevState.checkList.push(id);
			} else {
				prevState.checkList.splice(index, 1);
			}
			this.setState(prevState);
		}
	}

	render() {
		if (this.props.clinicalPathwayDetails.fetchAllExtendedClinicalPathway === "FULFILLED") {
			const data = this.props.clinicalPathwayDetails.clinicalPathwayData ? this.props.clinicalPathwayDetails.clinicalPathwayData : {};
			this.state.steps = data;
			this.setState({ loading: false });
			this.props.dispatch(clinicalPathwayDetailsAction.fetchAllExtendedClinicalPathwayCompleted());
		}

		if (this.props.clinicalPathwayDetails.fetchAllExtendedClinicalPathway === "REJECTED") {
			this.setState({ loading: false });
			this.props.dispatch(clinicalPathwayDetailsAction.fetchAllExtendedClinicalPathwayCompleted());
		}

		if ((this.state.treatmentId != this.props.treatmentId || this.state.search != this.props.search
			|| this.state.searchField != this.props.searchField || this.state.searchType != this.props.searchType || this.state.clpStepFilter != this.props.clpStepFilter)) {
			this.setState({ treatmentId: this.props.treatmentId, search: this.props.search, searchField: this.props.searchField, searchType: this.props.searchType, steps: [], clpStepFilter: this.props.clpStepFilter }, () => {
				this.props.dispatch(clinicalPathwayDetailsAction.fetchAllExtendedClinicalPathway((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), encodeURIComponent(this.props.search.trim()), this.props.searchField && this.props.searchField !== "SEARCH_ALL_FIELDS" ? this.props.searchField : "", this.props.searchType, this.props.clpStepFilter));
			});
		}
		this.state.showIntervention = this.props.showIntervention;
		return (
			<div className="pathway_scrollable_content_1 margin-bottom-35">
				{
					this.props.showHeaders &&
					<React.Fragment>
						<div id="details_1" className="table_section di w_100">
							<AuthorsAndActivities getSelectedComponents={this.props.getSelectedComponents} type='AUTHORS_AND_ACTIVITIES' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
						</div>
						<div className="">
							<div className="text_align_right di w_100   margin-bottom-10">
							</div>
						</div>
						<div id="details_2" className="table_section di w_100">
							<Definition type='PATHWAY_DEFINITION' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
						</div>
						<div id="details_3" className="table_section di w_100">
							<Eligibility type='PATHWAY_ELIGIBILITY' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
						</div>
						<div id="details_5" className="table_section di w_100">
							<Staff type='PATHWAY_STAFF' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
						</div>
						{this.state.showIntervention &&
							<div>
								<div id="details_4" className="table_section di w_100">
									<Intervention getSelectedComponents={this.props.getSelectedComponents} type='Intervention' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
								</div>
								<div id="details_4" className="table_section di w_100">
									<Anaesthesia getSelectedComponents={this.props.getSelectedComponents} type='Anaesthesia' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData = { this.props.clinicalPathway } />
								</div>
							</div>
						}
						<div className="text_align_right di w_100   margin-bottom-40">
						</div>
					</React.Fragment>
				}
				{this.state.steps && this.state.steps.length > 0 ? this.getExtClinicalPathway() : <Loader loading={this.state.loading} isTable={true} />}
				{this.state.steps && this.state.steps.length == 0 && !this.state.loading && <div className="text_align_center w_100 margin-top-30vh">NO DATA FOUND</div>}
			</div>
		);
	}

}

clinicalPathwayDetail.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(clinicalPathwayDetail));