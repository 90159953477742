import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import findIndex from "lodash.findindex";
import translationActions from "../../../../actions/translation";
import utils from "../../../../utils/utils";
import { i18Get } from "../../../../i18";

class Language extends Component {
	constructor(props) {
		super(props);
		this.state = {
			languages: [],
			languageSet: false,
			selectedLanguage: {}
		};
	}

	getLanguage() {
		const langKey = "SELECTED_LANGUAGE";
		if (!localStorage.getItem(langKey) || localStorage.getItem(langKey) === "null" || localStorage.getItem(langKey) === "undefined") {
			return utils.getLanguage();
		}
		return localStorage.getItem(langKey);
	}

	resetLanguage(lang) {
		return () => {
			this.state.languages.map((language) => {
				if (language.language_code === lang) {
					this.setState({ selectedLanguage: language });
				}
			});
			this.props.dispatch(translationActions.resetLanguage(lang));
		};
	}

	renderLanguages() {
		return this.state.languages.map((language, index) => (
			<li key={index} className="dropdown-item">
				<a onClick={this.resetLanguage(language.language_code).bind(this)}>
					<img src={`/images/${language.icon}`} />
					{i18Get(language.language, utils.getLanguage())}
				</a>
			</li>
		));
	}

	render() {
		if (this.props.translations.isResetLanguageComplete) {
			this.props.dispatch(translationActions.clearResetFlag());
		}
		if (this.props.translations.languages.translations && !this.state.languageSet) {
			this.state.languages = this.props.translations.languages.lang;
			this.state.languageSet = true;
			const lan = this.getLanguage();
			this.resetLanguage(lan)();
			this.state.selectedLanguage = this.props.translations.languages.lang[findIndex(this.state.languages, (l) => l.language_code === lan)];
		}

		if (this.state.selectedLanguage && this.state.selectedLanguage.icon) {
			return (
				<div className="dropdown display_table_cell w_language">
					<a className="dropdown-toggle lang" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<img src={`/images/${this.state.selectedLanguage.icon}`} />
						<span>{i18Get(this.state.selectedLanguage.language, utils.getLanguage())}</span>
					</a>
					<ul className={`dropdown-menu profile_menu no-padding  ${this.props.classWithoutTop ? "" : "top_66"}`} data-wow-delay="0.4s">
						{this.renderLanguages()}
					</ul>
				</div>
			);
		}
		return (<div />);
	}
}
Language.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Language));
