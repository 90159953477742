import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import NumericInput from "../../InterventionTabs/defaultValue-list/numeric-input";
import interventionAction from "../../../../../../../actions/intervention";
import { localeData } from "moment";

class TotalSection  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			charLengthExceeded: false,
			defaultCostClicked: false
		};
	}

	UNSAFE_componentWillMount() {
		this.setLength(this.props.costDetails);
	}

	formatCount(value) {
		return utils.setLocaleForCurrency(value ? value : 0.00);
	}
  
	setLength(costDetails) {
		const values = ["interventionTotalCost", "interventionWithoutSubsidisedCost", "anaesthesiaTotalCost", "anaesthesiaWithoutSubsidisedCost", "totalCost", "totalCostWithoutSubsidy"];
		if (costDetails) {
			values.map(value => {
				if (costDetails[value] && (costDetails[value].length > 13)) {
					this.setState({ charLengthExceeded: true });
				}
			});
		}
	}

	defaultCostClick = () => {
		if (!this.state.defaultCostClicked) {
			this.setState({ defaultCostClicked: true });
		}
	};

	validateDefaultValue = () => {
		return !(!this.defaultValue.getValue());
	};

	saveDefaultValue = () => {
		if (this.validateDefaultValue()) {
			const payload = {
				defaultValue: this.defaultValue.getValue() ? String(this.defaultValue.getValue()).replace(",", ".") : 0
			};
			this.props.dispatch(interventionAction.updateTotalDefaultCost(this.props.match.params.id, payload));
		} else {
			this.setState({ defaultCostClicked: false });
		}
	};

	showDefaultValue = () => {
		const defaultValue = this.props.defaultValue ? this.props.defaultValue : "";
		if (this.state.defaultCostClicked) {
			return (
				<NumericInput 
					key="defaultValue"
					maxLength={9} 
					label="defaultValue" 
					className="defatul-cost-tbox"
					onBlur={this.saveDefaultValue}
					value={defaultValue ? defaultValue : "" } 
					onRef={ref => this.defaultValue = ref}
				/> 
			);
		}
		return (
			<span className="margin_top_24px_special"  >{this.formatCount(defaultValue)}</span>
		);
      
	};

	moveToDefaultCost = () => {
		const LOCALKEY = this.props.match.params.organization === "local" ? "INTERVENTION_LOCAL" : "INTERVENTION_MOM";

		const localData = localStorage.getItem(LOCALKEY);
		let jsonData = {};
		if (localeData) {
			jsonData = JSON.parse(localData);
		}
		localStorage.setItem(LOCALKEY, JSON.stringify({ ...jsonData, search: this.props.interventionName }));
		this.props.history.push(`/intervention/${this.props.match.params.organization}/defaultValues`);

	};

	render() {
		// let defaultValue = this.props.defaultValue ? this.props.defaultValue : {};
		const className = this.state.charLengthExceeded ? "col-xs-4 col-sm-3 col-md-3" : "col-xs-4 col-sm-2 col-md-2";
		const baseClassName = this.state.charLengthExceeded ? "col-xs-8 col-sm-9 col-md-9" : "col-xs-8 col-sm-10 col-md-10";
		return (
			<div className=" text_align_right id_total_section margin-bottom-60">
				<div className="table_section di w_100">
					{/* <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Total response with paid product'} />{` (${utils.currency})`}</div><div className = {className}>  <div className="w_100 ellipsis"><span  className="margin_top_24px_special">{this.formatCount(costDetails.interventionTotalCost)}</span> </div></div></div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Total procedure without repaid product'} />{` (${utils.currency})`}</div><div className = {className}><div className="w_100 ellipsis">  <span  className="margin_top_24px_special">{this.formatCount(costDetails.interventionWithoutSubsidisedCost)}</span> </div></div> </div>
               {this.props.showAnaesthesia && <div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Total Anaesthesia with paid product'} />{` (${utils.currency})`}</div> <div className = {className}> <div className="w_100 ellipsis"><span className="margin_top_24px_special">{this.formatCount(costDetails.anaesthesiaTotalCost)}</span></div> </div></div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Total Anaesthesia without repaid product'} />{` (${utils.currency})`}</div> <div className = {className}> <div className="w_100 ellipsis"><span  className="margin_top_24px_special">{this.formatCount(costDetails.anaesthesiaWithoutSubsidisedCost)}</span> </div></div></div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'General Expenses Package'} />{` (${utils.currency})`}</div> <div className = {className}> <div className="w_100 ellipsis"><span  className="margin_top_24px_special">{this.formatCount(costDetails.generalCost)}</span> </div></div></div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Grand total paid with DM'} />{` (${utils.currency})`}</div> <div className = {className}> <div className="w_100 ellipsis"><span>{this.formatCount(costDetails.totalCost)}</span> </div></div></div>
                <div className="row margin-bottom-10"><div className = {baseClassName}><I18 tkey={'Grand total repaid without DM'} />{` (${utils.currency})`}</div> <div className = {className}><div className="w_100 ellipsis"> <span className="margin_top_24px_special">{this.formatCount(costDetails.totalCostWithoutSubsidy)}</span> </div></div></div>
                </div>} */}
					{this.props.isDefault &&
                <div className="row margin-bottom-10 "><div className ={`${baseClassName}`}><span onClick={this.moveToDefaultCost} className=""><I18 tkey={"Total Default Cost"} />{` (${utils.currency})`}</span></div> <div className = {className}><div className="w_100 ellipsis bg-intervention-details" onClick={this.defaultCostClick}>{this.showDefaultValue()}</div></div></div>
					}
				</div> 
			</div>
		);
	}

	componentDidUpdate() {
		if (this.props.defaultValueUpdated) {
			this.setState({ defaultCostClicked: false }, () => {
				this.props.changeupdateState();
			});
		}
	}
}

TotalSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	translations: store.translations
}))(TotalSection));