import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import { FilterOutlined } from "@ant-design/icons";
import locations from "../../../../../../router/locations";
import InterventionTabs from "../InterventionTabs";
import interventionAction from "../../../../../../actions/intervention";
import typeActivity from "../../../../../../constants/typeActivity.json";
import utils from "../../../../../../utils/utils";
import TopSection from "./top-section";
import Filter from "./filter";

class Interventions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			organization: "",
			count: 0,
			course: "",
			typeActivity: typeActivity.INTERVENTION.toUpperCase(),
			durationSelected: "intervention",
			showFilter: false,
			sort: "",
			desc: 0,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			initialStartDateRange: {
				start: "",
				end: ""
			},
			filter: {
				selectedStatus: "ALL",
				selectedDoctor: null,
				selectedIntervention: null,
				selectedSpecialty: null,
				selectedFamily: null,
				template: null,
				selectedDate: "",
				selectedLinkStatus: "ALL",
				selectedInterventionStatus: "ALL",
				startDateRange: { start: "", end: "" },
				field: "admissionId",
				search: ""
			},
			localStorageKey: "",
			userDetails: localStorage.getItem("USER_DETAILS") ? JSON.parse(localStorage.getItem("USER_DETAILS")) : {},
			recommendationCount: 0,
			isRefreshing: false,
			costRefreshTime: null,
			showCopyModal: false,
			copyKey: 1
		};
	}


	UNSAFE_componentWillUpdate(nextProps) {
		if (this.state.pathname !== nextProps.location.pathname) {
			this.state.count = 0;
			this.state.pathname = nextProps.location.pathname;
			this.state.localStorageKey = `INTERVENTION_${nextProps.match.params.organization.toUpperCase()}`;
			const storedValues = utils.getFilterInLocalStorage(this.state.localStorageKey);
			if (storedValues && (typeof storedValues === "object") && Object.keys(storedValues).length) {
				this.state.showFilter = storedValues.showFilter;
				delete storedValues.showFilter;
				this.state.filter = storedValues;

			}
			if (utils.checkForParameter("interventionStatus", this.props.location)) {
				this.state.filter.selectedInterventionStatus = utils.checkForParameter("interventionStatus", this.props.location);
				this.filterAction();
			}
			if (utils.checkForParameter("showFilter", this.props.location)) {
				this.state.showFilter = utils.checkForParameter("showFilter", this.props.location);
			}
			// this.filterAction(true);
		}
	}

	componentWillUnmount() {
		this.props.dispatch(interventionAction.clearFilter());
	}

	onSpecialtyChange(e) {
		this.state.filter.selectedSpecialty = e;
		this.state.filter.selectedFamily = null;
		this.setState(this.state);
		this.filterAction();
	}

	onFamilyChanged(e) {
		this.state.filter.selectedFamily = e;
		this.filterAction();
	}

	onStatusChange(e) {
		this.state.filter.selectedStatus = e;
		this.filterAction();
	}

	hospitalChanged(e) {
		this.state.filter.selectedHospital = e;
		this.filterAction();
	}

	onInterventionStatusChange(e) {
		this.state.filter.selectedInterventionStatus = e;
		this.filterAction();
	}

	fieldChanged(e) {
		this.state.filter.field = e ? e : utils.sameDropper();
		this.filterAction();
	}

	searchChanged(e) {
		this.state.filter.search = e.target.value;
		this.filterAction();
	}

	setLocalStorageForFilter() {
		const filters = {
			...this.state.filter,
			showFilter: this.state.showFilter

		};
		utils.setFilterInLocalStorage(this.state.localStorageKey, filters);
	}

	filterAction(ignorePageChange) {
		const payload = {
			field: this.state.filter.field,
			search: this.state.filter.search,
			selectedSpecialty: this.state.filter.selectedSpecialty ? this.state.filter.selectedSpecialty.value : "",
			selectedFamily: this.state.filter.selectedFamily ? this.state.filter.selectedFamily.value : "",
			selectedStatus: this.state.filter.selectedStatus ? this.state.filter.selectedStatus.toUpperCase() : "",
			selectedHospital: this.state.filter.selectedHospital ? this.state.filter.selectedHospital.value : "",
			showFilter: this.state.showFilter,
			selectedInterventionStatus: this.state.filter.selectedInterventionStatus
		};
		this.props.dispatch(interventionAction.filterAction(payload, ignorePageChange));
		this.setLocalStorageForFilter(ignorePageChange);
	}

	clearFilter() {
		this.state.filter.selectedFamily = null;
		this.state.filter.selectedSpecialty = null;
		this.state.filter.selectedStatus = "ALL";
		this.state.filter.field = "name";
		this.state.filter.search = "";
		this.state.filter.selectedHospital = null;
		this.state.filter.selectedInterventionStatus = "ALL";
		this.setState(this.state);
		this.filterAction();
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter }, () => { this.setLocalStorageForFilter(); });
	}



	typeActivityChanged(e) {
		const prevState = this.state;
		prevState.typeActivity = e;
		this.setState(prevState);
	}

	totalCount(value) {
		this.setState({ count: value });
	}

	createClicked = () => {
		this.props.history.push(`${locations.interventions_plain}/${"mom"}${locations.create}`);
	};

	generateItems() {
		const items = [];
		if (this.props.location.pathname.startsWith(`${locations.interventions_mom}${locations.timeSheets}`)) {
			items.push({ name: i18Get("Intervention", utils.getLanguage()), link: locations.interventions_mom + locations.timeSheets });
		}
		if (this.props.location.pathname === locations.interventions_mom + "/create") {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.interventions_mom + "/create" });
		}
		// if(this.state.sterilization.id) {
		//     items.push({ name: this.state.sterilization.name, link: locations.consumable_sterilization_root + `/${this.props.match.params.type}/${this.state.sterilization.id}${this.props.location.search}` });
		// }
		return items;
	}
	render() {
		this.state.hasAccess = this.props.scope.userScope && ((this.props.scope.userScope["PRINT"] & this.props.user.loginData.scope) === this.props.user.loginData.scope);
		if ((this.props.match.params.course) && (this.props.match.params.course != this.state.course)) {
			this.state.course = this.props.match.params.course;
			this.clearFilter();
		}
		return (
			<div className="main_overview_section">
				<div className="main_overview_top_section di w_100 float_left padding-top-15">
					<div className="row">
						<div className="col-md-4 col-sm-4">
							<div className="record_container w_100 margin-top-6">
								<div className="di number_of_records">
									<span><I18 tkey="Number of Records" />:<b>{this.state.count}</b></span>
								</div>
							</div>
						</div>
						<div className="col-md-8 col-sm-8 ">

							<div className="create_interventions text_align_right mt10_mobile min-height-33">
								{!this.state.course && (this.props.location.pathname.indexOf(locations.defaultValues) === -1) &&

									<button className="common_button margin_top_15_mob margin-right-15" type="button" onClick={this.createClicked} >
										<i className="fa fa-plus margin-right-8" aria-hidden="true"></i> <I18 tkey="CREATE INTERVENTION" />
									</button>
								}

								<div className="filter" onClick={this.filterClicked.bind(this)}>
									<FilterOutlined className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
									<div>

									</div>
								</div>

							</div>
						</div>
					</div>
					{this.state.showFilter ?
						<Filter
							filter={this.state.filter}
							onSpecialtyChange={this.onSpecialtyChange.bind(this)}
							onFamilyChanged={this.onFamilyChanged.bind(this)}
							onStatusChange={this.onStatusChange.bind(this)}
							hospitalChanged={this.hospitalChanged.bind(this)}
							onInterventionStatusChange={this.onInterventionStatusChange.bind(this)}
							clearFilter={this.clearFilter.bind(this)} />

						: ""}
				</div>
				<div className="tab_section padding-top-15 di w_100">
					<TopSection
						field={this.state.filter.field}
						search={this.state.filter.search}
						course={this.state.course}
						typeActivity={this.state.typeActivity}
						fieldChanged={this.fieldChanged.bind(this)}
						searchChanged={this.searchChanged.bind(this)}
						recommendationCount={this.state.recommendationCount}
						typeActivitySelected={this.typeActivityChanged.bind(this)} />
					{/* {!this.state.course && (this.props.location.pathname.indexOf(locations.durations) !== -1) ? 
                        <DurationTopSection
                            durationSelected={this.state.durationSelected}
                            durationChanged={this.durationChanged.bind(this)}
                            durationCourseChanged={this.durationCourseChanged.bind(this)}/> : ''} */}
					<InterventionTabs
						organization={this.props.match.params.organization}
						courseReference
						isRefreshing={this.state.isRefreshing}
						costRefreshTime={this.state.costRefreshTime}
						typeActivity={this.state.typeActivity ? this.state.typeActivity : "INTERVENTION"}
						durationSelected={(this.props.location.pathname.indexOf(locations.durations) !== -1) ? this.state.durationSelected : ""}
						durationCourse={this.state.durationCourse ? this.state.durationCourse : ""}
						totalCount={this.totalCount.bind(this)} />
				</div>
			</div>

		);
	}

	componentDidMount() {

		this.state.organizationMode = this.props.organizationMode;
		const filter = utils.getFilterInLocalStorage(`INTERVENTION_${this.props.match.params.organization.toUpperCase()}`);
		if (filter && (typeof filter === "object") && Object.keys(filter).length) {
			this.state.filter = filter;
			this.state.filter.selectedSpecialty = filter.selectedSpecialty ? filter.selectedSpecialty : "";
			this.state.filter.selectedFamily = filter.selectedFamily ? filter.selectedFamily : "";
			this.state.filter.selectedHospital = filter.selectedHospital ? filter.selectedHospital : "";
			this.state.filter.selectedStatus = filter.selectedStatus ? filter.selectedStatus : "ALL";
			this.state.filter.selectedIntervention = filter.selectedIntervention ? filter.selectedIntervention.value : "ALL";
		}
		this.state.typeActivity = this.props.typeActivity;

		this.setState({ ...this.state });
		if (utils.checkForParameter("showFilter", this.props.location)) {
			this.state.showFilter = utils.checkForParameter("showFilter", this.props.location);
			this.setState({ ...this.state });
		}
		if (utils.checkForParameter("interventionStatus", this.props.location)) {
			this.state.filter.selectedInterventionStatus = utils.checkForParameter("interventionStatus", this.props.location);
			this.setState({ filter: { ...this.state.filter } }, () => {
				this.filterAction();
			});

		}
	}

	componentDidUpdate() {
		if (utils.checkForParameter("showFilter", this.props.location) &&
			this.state.showFilter != utils.checkForParameter("showFilter", this.props.location)) {
			this.state.showFilter = utils.checkForParameter("showFilter", this.props.location);
			this.setState({ ...this.state });
		}
		if (utils.checkForParameter("interventionStatus", this.props.location)
			&& this.state.filter.selectedInterventionStatus != utils.checkForParameter("interventionStatus", this.props.location)) {
			this.state.filter.selectedInterventionStatus = utils.checkForParameter("interventionStatus", this.props.location);
			this.setState({ filter: { ...this.state.filter } }, () => {
				this.filterAction();
				this.props.history.push(`/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}`);
			});

		}

		if (this.props.intervention.recommendationCountStatus == "FULFILLED") {
			this.state.recommendationCount = this.props.intervention.recommendationCount;
			this.setState({ ...this.state }, () => {

			});
			this.props.dispatch(interventionAction.clearRecommandationCount());
		}

	}

}



Interventions.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	scope: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	user: store.user,
	scope: store.scope,
	translations: store.translations,
	intervention: store.intervention
}))(Interventions));