import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import categoryPersonnelActions from "../../../../../../../../actions/category-personnel";
import events from "../../../../../../../../actions/events";
import CategoryPersonnelSelect from "../categoryPersonnelDropdown";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 1,
			categoryPersonnelName: "",
			categoryPersonnelCode: "",
			invalidCategoryPersonnelName: false,
			invalidCategoryPersonnelCode: false,
			invalidCategoryPersonnel: false,
			selectedCategoryPersonnel: "",
			categoryPersonnel: {},
			categoryPersonnelId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(categoryPersonnelActions.fetchOneCategoryPersonnel(this.props.categoryPersonnelId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidCategoryPersonnelName: false, invalidCategoryPersonnelCode: false });
		this.props.refreshCompleted();
	}

	onCategoryPersonnelChange(e) {
		this.setState({ selectedCategoryPersonnel: e ? e : "", invalidCategoryPersonnel: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.categoryPersonnelName.trim())) {
			prevState.invalidCategoryPersonnelName = true;
		}
		if (!(this.state.categoryPersonnelCode)) {
			prevState.invalidCategoryPersonnelCode = true;
		}
		if (prevState.invalidCategoryPersonnelName || prevState.invalidCategoryPersonnelCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateCategoryPersonnel = () => {
		if (this.state.selectedCategoryPersonnel) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.categoryPersonnelName,
					code: this.state.categoryPersonnelCode
				};
				this.props.dispatch(categoryPersonnelActions.saveAndApproveCategoryPersonnel(this.state.categoryPersonnel.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateCategoryPersonnel()) {
				payload = {
					categoryPersonnelId: this.state.selectedCategoryPersonnel && this.state.selectedCategoryPersonnel.value ? this.state.selectedCategoryPersonnel.value : ""
				};
				this.props.dispatch(categoryPersonnelActions.assignAndApproveCategoryPersonnel(this.state.categoryPersonnel.id, payload));
			}
			else {
				this.setState({ invalidCategoryPersonnel: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidCategoryPersonnelName = false;
		prevState.categoryPersonnelName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidCategoryPersonnelCode = false;
		prevState.categoryPersonnelCode = value;
		this.setState(prevState);
	}

	render() {
		const { value } = this.state;
		if (this.props.categoryPersonnel.asssignAndApproveCategoryPersonnelStatus === "FULFILLED") {
			this.setState({ modelVisible: false, invalidCategoryPersonnelName: false, invalidCategoryPersonnelCode: false });
			this.props.dispatch(categoryPersonnelActions.assignAndApproveCategoryPersonnelCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.categoryPersonnel.saveAndApproveCategoryPersonnelStatus === "FULFILLED") {
			this.setState({ modelVisible: false, invalidCategoryPersonnelName: false, invalidCategoryPersonnelCode: false });
			this.props.dispatch(categoryPersonnelActions.saveAndApproveCategoryPersonnelCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.categoryPersonnel.isFetchOneCategoryPersonnelCompleted === "FULFILLED") {
			this.state.categoryPersonnel = this.props.categoryPersonnel.selectedCategoryPersonnel;
			this.state.categoryPersonnelName = this.state.categoryPersonnel.name ? this.state.categoryPersonnel.name : "";
			this.state.categoryPersonnelCode = this.state.categoryPersonnel.code ? this.state.categoryPersonnel.code : "";
			this.state.isloading = true;
			this.props.dispatch(categoryPersonnelActions.clearFetchOneCategoryPersonnel());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.categoryPersonnel.name, id: this.state.categoryPersonnel.id } });
		}

		if (this.props.categoryPersonnel.isFetchOneCategoryPersonnelCompleted === "REJECTED") {
			this.props.dispatch(categoryPersonnelActions.clearFetchOneCategoryPersonnel());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Category Personnel", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
                   
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
							<I18 tkey='Save & Approve'/>
						</Button> 
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.categoryPersonnel.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.categoryPersonnel.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.categoryPersonnel.hospitalName ? this.state.categoryPersonnel.hospitalName : ""}</p>
							</div>
							{/* {this.state.categoryPersonnel.consumableSpecialityName&&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="STERILIZATION SPECIALITY NAME"/>:</span> {this.state.categoryPersonnel.consumableSpecialityName ? this.state.categoryPersonnel.consumableSpecialityName : ''}</p>
                           }
                            {this.state.categoryPersonnel.consumableFamilyName&&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="STERILIZATION FAMILY NAME"/>:</span> {this.state.categoryPersonnel.consumableFamilyName ? this.state.categoryPersonnel.consumableFamilyName : ''}</p>
                            } */}
						</div>
						{this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											<NameInput
												value={this.state.categoryPersonnelCode}
												inputChanged={this.codeChanged.bind(this)}
												disabled={this.state.value != 1}
												maxLength={10}
											/>
											{this.state.invalidCategoryPersonnelCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group ">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.categoryPersonnelName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidCategoryPersonnelName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Category Personnel" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<CategoryPersonnelSelect
												defaultValue="Select Category Personnel" disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedCategoryPersonnel}
												categoryPersonnelChanged={this.onCategoryPersonnelChange.bind(this)} />
											{this.state.invalidCategoryPersonnel && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select category personnel" /></span>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
                               
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	categoryPersonnel: store.categoryPersonnel
}))(AssignAndApproveModel));    