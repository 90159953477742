import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import { Checkbox } from "antd";
import extElementActions from "../../../../../../actions/ext-elements";
import consumableActions from "../../../../../../actions/consumable";
import SelectTypeList from "./type-list";
import QuantityList from "./quantity-list";
import ActionButton from "../../../../../../plug/action-button";
import events from "../../../../../../actions/events";
import extElements from "../../../../../../constants/extElements.json";
import elements from "../../../../../../constants/elements.json";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import StatusDropdown from "../top-section/status-list/index";

class TopSectionExtElement extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.getInitialState());
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.addClicked();
		}
	}

	getInitialState = () => {
		return {
			tab: extElements.tabs.GENERIC,
			tab2: this.props.tabs[this.props.tabs.length - 1],
			data: false,
			invalidData: false,
			category: extElements.category.STANDARD,
			quantity: 1,
			invalidQuantity: false,
			isSubsidised: false,
			isOptional: false,
			costOne: false,
			type: "",
			selectedStatus: "All",
			selectedHospital: ""
		};
	};

	tabChanged(label, category) {
		return () => {
			if (!this.typeList.getLoading()) {
				const prevState = Object.assign({}, this.getInitialState());
				prevState.tab = label;
				prevState.tab2 = this.state.tab2;
				prevState.category = category;
				this.state.category = category;
				prevState.type = this.props.type;
				prevState.data = false;
				this.setState(prevState);
			}
		};
	}

	tab2Changed(label) {
		return () => {
			if (!this.typeList.getLoading()) {
				const prevState = Object.assign({}, this.getInitialState());
				if (label !== elements.elements.STERILIZATION) {
					prevState.tab = this.state.tab;
					prevState.category = this.state.category;
				}
				prevState.tab2 = label;
				this.state.tab2 = label;
				prevState.type = this.props.type;
				prevState.data = false;
				this.setState(prevState);
			}
		};
	}

	dataChanged(e) {
		const prevState = this.state;
		prevState.data = e;
		if (e) {
			prevState.costOne = false;
			prevState.isOptional = false;
		}
		prevState.isSubsidised = e && e.isSubsidised ? true : false;
		prevState.invalidData = false;
		this.setState(prevState);
	}

	quantityChanged(e) {
		const prevState = this.state;
		prevState.quantity = e;
		prevState.invalidQuantity = false;
		this.setState(prevState);
	}

	subsidyChanged() {
		const prevState = this.state;
		prevState.subsidised = !prevState.subsidised;
		this.setState(prevState);
	}

	optionChanged(field) {
		return () => {
			const prevState = this.state;
			prevState[field] = !this.state[field];
			this.setState(prevState);
		};
	}

	onStatusChange(e) {
		this.state.selectedStatus = e;
		this.setState(this.state);
		this.props.dispatch(extElementActions.fetchAllExtElements(this.props.match.params.id, this.props.type, `offset=0&numResults=1000${this.state.selectedHospital && this.state.selectedHospital.value ? `&hospitalId=${this.state.selectedHospital.value}` : ""}${this.state.selectedStatus ? `&status=${this.state.selectedStatus}` : ""}`));
	}

	hospitalChanged(e) {
		const prevState = this.state;
		prevState.selectedHospital = e;
		this.setState(prevState);
		this.props.dispatch(extElementActions.fetchAllExtElements(this.props.match.params.id, this.props.type, `offset=0&numResults=1000${this.state.selectedHospital && this.state.selectedHospital.value ? `&hospitalId=${this.state.selectedHospital.value}` : ""}${this.state.selectedStatus ? `&status=${this.state.selectedStatus}` : ""}`));
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.data || !Object.keys(this.state.data).length) {
			prevState.invalidData = true;
		}
		if (!this.state.quantity) {
			prevState.invalidQuantity = true;
		}
		this.setState(prevState);
		if (!this.state.invalidData && !this.state.invalidQuantity) {
			return true;
		}
		return false;
	}

	addClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				elementId: this.state.data.id,
				quantity: this.state.quantity,
				isOptional: this.state.isOptional,
				costOne: this.state.costOne
			};
			if (this.props.doctorId) {
				payload.doctorId = this.props.doctorId;
			}
			if (this.props.type !== extElements.extElements.INSTRUMENTATION) {
				payload.isSubsidised = this.state.isSubsidised;
			}
			if (this.props.isDci) {
				payload.isBottle = false;
				payload.isAnnex = false;
				payload.isDci = false;
				payload.extType = this.props.type;
				this.props.dispatch(consumableActions.createDciPerfusionPreparation(this.props.match.params.consumableId, payload));
			} else {
				this.props.dispatch(extElementActions.createExtElement(this.props.match.params.id, this.props.type, payload));
			}

		}
	}

	getLabel(txt) {
		return txt.replace(/_/g, " ").replace(/\w+/g, function (txt) { return txt.charAt(0) + txt.substr(1).toLowerCase(); });
	}

	loadContent() {
		return (
			<div>
				<div className="row d_flex align_items_base margin-bottom-15">
					<div className="col-md-3">
						<SelectTypeList
							data={this.state.data}
							doctorId={this.props.doctorId}
							dataChanged={this.dataChanged.bind(this)}
							type={this.state.type}
							invalidData={this.state.invalidData}
							category={this.state.category}
							elementType={this.state.tab2}
							isDci={this.props.isDci}
							typeActivity={this.props.typeActivity}
							cycle={this.props.cycle}
							isScannedTreatments={this.props.isScannedTreatments}
							onRef={ref => this.typeList = ref} />
					</div>
					<div className="col-md-3">
						<QuantityList
							quantity={this.state.quantity}
							quantityChanged={this.quantityChanged.bind(this)}
							invalidQuantity={this.state.invalidQuantity}
							typeActivity={this.props.typeActivity} />
					</div>
					<div className="col-md-6 ">
						<div className="di">
							<div className="margin-top-5"><Checkbox checked={this.state.isOptional} onChange={this.optionChanged("isOptional").bind(this)}><I18 tkey="Is Optional" /></Checkbox></div>
						</div>
						<div className="di">
							<div className="margin-top-5 margin-left-5"><Checkbox checked={this.state.costOne} onChange={this.optionChanged("costOne").bind(this)}><I18 tkey="Cost One" /></Checkbox></div>
						</div>
						{(this.props.type !== extElements.extElements.INSTRUMENTATION) ?
							<div className="di">
								<div className="margin-top-5 margin-left-5">
									<Checkbox
										disabled
										checked={this.state.isSubsidised}
										onChange={this.optionChanged("isSubsidised").bind(this)}>
										<I18 tkey="Is Subsidised" />
									</Checkbox>
								</div>
							</div> : ""}
						<div className="di  text_align_right float_right">
							<ActionButton showAdd={true} onClick={this.addClicked.bind(this)} value={i18Get("ADD", utils.getLanguage())} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderTabs() {
		return (
			<div className="d-flex align-items-end justify-content-between">
				<div className="w_50 d-flex align-items-end padding-bottom-5">
					<div className="col-md-6 padding-left-0">
						<StatusDropdown
							status={this.state.selectedStatus}
							onStatusChange={this.onStatusChange.bind(this)} />
					</div>
					<div className="col-md-6">
						<label>
							<I18 tkey="Hospital" />
						</label>
						< HospitalSelect
							data={this.state.selectedHospital}
							hospitalChanged={this.hospitalChanged.bind(this)} />
					</div>
				</div>
				<ul className="nav nav-tabs">
					{/* <li className={this.state.tab === extElements.tabs.SPECIFIC ?'active':''}><a  onClick={this.tabChanged(extElements.tabs.SPECIFIC, extElements.category.SPECIAL).bind(this)}><I18 tkey={extElements.tabs.SPECIFIC} /></a></li> */}
					{/* <li className={this.state.tab === extElements.tabs.GENERIC ?'active':''}><a  onClick={this.tabChanged(extElements.tabs.GENERIC, extElements.category.STANDARD).bind(this)}><I18 tkey={extElements.tabs.GENERIC} /></a></li> */}
					{
						this.props.tabs.map((tab, index) => {
							return <li key={index} className={this.state.tab2 === tab ? "active float_right_imp" : "float_right_imp"}><a onClick={this.tab2Changed(tab).bind(this)}><I18 tkey={this.getLabel(tab)} /></a></li>;
						})
					}
				</ul>
			</div>

		);
	}

	render() {
		if (this.props.extElements.isExtElementCreateComplete === "FULFILLED") {
			this.state.data = null;
			this.state.quantity = 1;
			this.state.isSubsidised = false;
			this.state.isOptional = false;
			this.state.costOne = false;
			this.props.dispatch(extElementActions.clearCreateExtElement());
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch(extElementActions.fetchAllExtElements(this.props.match.params.id, this.props.type, `offset=0&numResults=1000${this.props.doctorId ? `&doctorId=${this.props.doctorId}` : ""}`));
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.consumable.createDciPerfusionPreparationComplete === "FULFILLED") {
			this.state.data = {};
			this.state.quantity = 1;
			this.state.isSubsidised = false;
			this.state.isOptional = false;
			this.state.costOne = false;
			this.props.dispatch(consumableActions.clearCreateDciPerfusionPreparation());
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch(consumableActions.fetchAllPreparationPerfusion(this.props.match.params.consumableId, this.props.type, "offset=0&numResults=1000"));
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.type !== this.state.type) {
			const prevState = Object.assign({}, this.getInitialState());
			prevState.type = this.props.type;
			this.state = prevState;
		}

		return (
			<div className="w_100 di">
				{this.props.cycle && this.props.cycle.cycle ? <div className="col-md-12 margin-bottom-10 margin-top-10"><label className="margin-top-5 margin-right-15">{this.props.cycle.cycle}</label></div> : ""}
				<div className="tab_section instrumentation_tab border_bottom_imp padding-top-15 padding-bottom-15">
					{this.renderTabs()}
					<div className="medical_device_box">
						<div className="tab-pane fade in active">
							{this.loadContent()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

TopSectionExtElement.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extElements: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	chemotherapy: PropTypes.object.isRequired,
	radiotherapy: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired,
	imagery: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	extElements: store.extElements,
	actions: store.actions,
	chemotherapy: store.chemotherapy,
	radiotherapy: store.radiotherapy,
	consumable: store.consumable,
	imagery: store.imagery,
	translations: store.translations
}))(TopSectionExtElement));