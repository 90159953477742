import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../../router/locations";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import { Modal, Table, Tooltip } from "antd";
import { MenuOutlined, DeleteOutlined, EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import events from "../../../../../../../../actions/events";
import AssignAndApproveModel from "./assign-and-approve-model";
import { statusList } from "../../../../../../../../constants";
import clpDailySubSteps from "../../../../../../../../constants/clpDailySubSteps.json";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Resizable } from "react-resizable";
import Pagination from "../../../../../../../../plug/pagination";

const DragHandle = sortableHandle(() => (<MenuOutlined style={{ cursor: "grab", color: "#999" }} />));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

//max-height: calc(100vh - 250px);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class Steps extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stepList: [],
			totalPages: 1,
			loading: false,
			search: "",
			deleteStepVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedStep: {},
			sort: "rank",
			desc: 0,
			modelVisible: false,
			stepId: "",
			hospitalId: "",
			status: "ALL",
			enableReorder: this.props.enableReorder,
			name: 250,
			sectionType: 200,
			clpType: 200,
			type: 200,
			code: 120,
			tableBlankSpace: 0,
			noOfSubSteps: 80
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.state.clinicalPathwayType = this.props.clinicalPathwayType ? this.props.clinicalPathwayType : utils.checkForParameter("clinicalPathwayType", this.props.location);
	}

	editClicked(step) {
		return () => {
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}/${step.id}`);
		};
	}

	approveClicked(step) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, stepId: step.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	deleteClicked(step) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedStep: step, deleteStepVisible: true });
		};
	}


	hideModalDelete() {
		this.setState({ deleteStepVisible: false });
	}

	deleteStep() {
		this.props.dispatch(adminClinicalPathwayActions.deleteClinicalPathwayStep(this.state.deletedStep.id));
		this.setState({ deleteStepVisible: false, deletedStep: {} });
	}

	fetchDataFromServer() {
		let queryString = !this.props.enableReorder && this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "offset=0&";
		queryString += !this.props.enableReorder && this.state.pageSize ? `numResults=${this.state.pageSize}` : "numResults=100";
		queryString += this.state.sort ? `&sort=${this.state.sort ? this.state.sort : "rank"}&desc=${this.state.desc}` : "";
		// queryString += this.state.clinicalPathwayType && (this.state.clinicalPathwayType !== 'ALL') ? `&clinicalPathwayType=${this.state.clinicalPathwayType}` : '';
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.search ? `&search=${this.state.search}` : "";
		if (this.props.enableReorder) {
			this.setState({ page: 1, pageSize: 100 }, () => {
				this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayStep(queryString));
			});
		} else {
			this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayStep(queryString));
		}
	}

	onFetchData(pagination, filters, sorter) {
		const prevState = this.state;
		prevState.loading = true;
		if (sorter.field && sorter.field.length > 0) {
			prevState.sort = sorter.field;
			prevState.desc = sorter.order == "ascend" ? 0 : 1;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = pagination.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex;
		this.setState({ ...this.state }, () => {
			this.onFetchData({ pageSize: this.state.pageSize }, null, { order: (this.state.desc == "ascend" ? 0 : 1), field: this.state.sort });
		});
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange(value) {
		this.setState({ ...this.state, pageSize: value, page: 1 }, () => { this.onPageChange(this.state.page); });
	}
	getText(value) {
		return <div>{value}</div>;
	}

	getActions(row, statusList, isPermissionGranted) {
		return (
			<div className="margin_left_auto min_w_34 text_align_center">
				{!this.props.enableReorder ?
					<span>
						{row.status === statusList.STATUS.values.APPROVED && isPermissionGranted("EDIT") ?
							<EditOutlined
								className="edit_color margin-right-10"
								type="edit"
								onClick={this.editClicked(row)} />
							: isPermissionGranted("EDIT") ? <a className="waiting-for-approve" onClick={this.approveClicked(row)}><img src="/images/waitingforapprovel.png" /> </a> : ""
						}
						{row.status === statusList.STATUS.values.APPROVED && !row.isMandatory && isPermissionGranted("DELETE") ?
							<DeleteOutlined
								className="delete_color"
								type="delete"
								onClick={this.deleteClicked(row)} />
							: ""}
					</span>
					: <DragHandle />
				}
			</div>
		);
	}

	clpTypeStringSplitAndJoin = (data) => {
		let returnData = "";
		if (data && data.length > 0) {
			data.forEach((element, index) => {
				let name = "";
				if (element == "MEDICINE") {
					name = "MEDICINE WITH ACTE";
				} else {
					name = element;
				}
				returnData += ((index !== 0 ? ", " : "") + i18Get(name.replace(/_/g, " "), utils.getLanguage()));
			});
		}
		return returnData;
	};

	getColumns = () => [
		{
			title: i18Get("ACTIONS", utils.getLanguage()),
			key: "action",
			render: row => { return row.id ? (this.getActions(row, statusList, this.props.isPermissionGranted)) : <span>&#10240;</span>; },
			width: "90px"
		},
		{
			title: i18Get("CODE", utils.getLanguage()),
			dataIndex: "code",
			key: "code",
			render: text => (
				this.getText(text ? text : "")
			),
			width: this.state.code,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("code")
			})
		},
		{
			title: i18Get("NAME", utils.getLanguage()),
			dataIndex: "name",
			key: "name",
			render: text => (
				this.getText(text ? text : "")
			),
			width: this.state.name,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("name")
			})
		},
		{
			title: i18Get("STEP TYPE", utils.getLanguage()),
			key: "type",
			render: row => (
				<Tooltip placement="top" title={i18Get(row && row.description ? row.description : "", utils.getLanguage())}>
					<div className="d-flex align-items-center">
						{this.getText(row && clpDailySubSteps.key[row.type] ? i18Get(clpDailySubSteps.key[row.type].replace(/_/g, " "), utils.getLanguage()) : "")}
					</div>
				</Tooltip>
			),
			width: this.state.type,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("type")
			})
		},
		{
			title: i18Get("AVAILABILITY", utils.getLanguage()),
			key: "sectionType",
			render: row => (
				<div className="d-flex align-items-center">
					{row.isClp ?  i18Get("Clinical Pathway", utils.getLanguage()) : "" } 
					{row.isClp ? <br /> : "" } 
					{row.isHcp ? i18Get("Healthcare Pathway", utils.getLanguage()) : ""}
				</div>
			),
			width: this.state.sectionType,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("sectionType")
			})
		},
		{
			title: i18Get("CLINICAL PATHWAY TYPE", utils.getLanguage()),
			dataIndex: "clinicalPathwayType",
			key: "clinicalPathwayType",
			render: text => (
				this.getText(this.clpTypeStringSplitAndJoin(text))
			),
			width: this.state.clpType,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("clpType")
			})
		},
		{
			title: i18Get("NUMBER OF SUB STEPS", utils.getLanguage()),
			dataIndex: "noOfSubSteps",
			key: "noOfSubSteps",
			render: text => (
				this.getText(text === 0 ? <Tooltip placement="topRight" overlayClassName="special_character_tooltip" title={i18Get("Number of Sub Step is zero.", utils.getLanguage())}><InfoCircleOutlined style={{ color: "red" }}/></Tooltip> : text
				)
			),
			width: this.state.noOfSubSteps,
			onHeaderCell: column => ({
				width: column,
				onResize: this.handleResize("noOfSubSteps")
			})
		},
		{
			title: i18Get("STATUS", utils.getLanguage()),
			//dataIndex: "status",
			key: "status",
			render: text => {
				return text.status ?
					<Tooltip placement="top" title={i18Get((text.status ? text.status === "APPROVED" ? "Approved" : "Waiting For Approval" : "") + " - From " + (text.hospitalName ? text.hospitalName : "Master"), utils.getLanguage())}>
						<div className="d-flex align-items-center">
							{text.hospitalName ? text.hospitalName : "Master"}
							<div className={`hospitalname_tag margin-left-10 ${text.hospitalName ? "hospitalnameactive" : ""}`}></div>
						</div>
					</Tooltip> : "";
			}
		}
	];



	getList(list) {
		const { BEFORE_ADMISSION, CLINICAL_PATHWAY, AFTER_CHECKOUT } = this.dataManimulation(list);

		return (<table className="reorder-table clinical_pathway_list_table">
			{BEFORE_ADMISSION.length > 0 && <>
				<tr className="sub-header"><td colSpan={6}><strong><I18 tkey="BEFORE ADMISSION" /></strong></td></tr>
				<tr><td colSpan={6}>{this.getTableContent(BEFORE_ADMISSION)}</td></tr></>}
			{CLINICAL_PATHWAY.length > 0 && <>
				<tr className="sub-header"><td colSpan={6}><strong><I18 tkey="HOSPITALISATION"/></strong></td></tr>
				<tr><td colSpan={6}>{this.getTableContent(CLINICAL_PATHWAY)}</td></tr></>}
			{AFTER_CHECKOUT.length > 0 && <>
				<tr className="sub-header"><td colSpan={6}><strong><I18 tkey="AFTER CHECKOUT"/></strong></td></tr>
				<tr><td colSpan={6}>{this.getTableContent(AFTER_CHECKOUT)}</td></tr></>}
		</table>);
	}

	 dataManimulation = (list) => {
		const BEFORE_ADMISSION = list.filter(row => row.sectionType === "BEFORE_ADMISSION");
		const CLINICAL_PATHWAY = list.filter(row => row.sectionType === "CLINICAL_PATHWAY");
		const AFTER_CHECKOUT = list.filter(row => row.sectionType === "AFTER_CHECKOUT");
		return { BEFORE_ADMISSION, CLINICAL_PATHWAY, AFTER_CHECKOUT };

	};

	getTableContent = (list) => {

		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns()}
				bordered
				rowKey="id"
				key={0}
				components={{
					header: {
						cell: ResizableTitle
					},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	};


	DraggableBodyRow = ({ ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex(
			(x) => x.id === restProps["data-row-key"]
		);
		return <SortableItem index={index} {...restProps} />;
	};

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	onSortEnd = ({ oldIndex, newIndex }, list) => {
		if (oldIndex !== newIndex) {
			const prevState = this.state;
			prevState.stepList = arrayMove(
				[].concat(list),
				oldIndex,
				newIndex
			).filter((el) => !!el);

			Array.prototype.splice.apply(
				list,
				[0, prevState.stepList.length].concat(prevState.stepList)
			);
			this.setState({ list: prevState.stepList, orderChanged: true });
			this.props.reorderList(prevState.stepList, "stepList");
		}
	};

	handleResize = label => (e, { size }) => {
		switch (label) {
			case "name":
				this.setState({ name: size.width });
				break;
			case "code":
				this.setState({ code: size.width });
				break;
			case "type":
				this.setState({ type: size.width });
				break;
			case "sectionType":
				this.setState({ sectionType: size.width });
				break;
			case "clpType":
				this.setState({ clpType: size.width });
				break;
			case "noOfSubSteps":
				this.setState({ noOfSubSteps: size.width });
				break;
			default:
				break;
		}
	};

	calcTableBlankSpace = () => {
		const prevState = this.state;
		const itemCount = prevState.stepList.length;
		const difference = 15 - itemCount;
		if (difference > 0) {
			prevState.tableBlankSpace = 31 * difference;
		} else {
			prevState.tableBlankSpace = 0;
		}
		this.setState(prevState);
	};

	render() {

		if ((this.state.search !== this.props.search) || (this.state.clinicalPathwayType !== this.props.clinicalPathwayType)
			|| (this.state.hospitalId !== this.props.hospitalId) || (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.clinicalPathwayType = this.props.clinicalPathwayType;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.stepList = [];
			this.state.page = 1;
			this.state.loading = true;
			this.fetchDataFromServer();
		}
		if (this.props.adminClinicalPathway.fetchAllClinicalPathwayStepCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminClinicalPathway.stepList.count / this.state.pageSize);
			this.state.stepList = this.props.adminClinicalPathway.stepList.data;
			this.props.reorderList(this.state.stepList, "stepList");
			this.props.getCount(this.props.adminClinicalPathway.stepList.count);
			this.props.dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwayStep());
			this.calcTableBlankSpace();
		}

		if (this.props.adminClinicalPathway.deleteClinicalPathwayStepCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch({ type: events.REFRESH_FILTERS, payload: {} });
			this.props.dispatch(adminClinicalPathwayActions.clearDeleteClinicalPathwayStep());
		}
		return (
			<div>
				{this.getList(this.state.stepList)}
				<Pagination
					page={this.state.page}
					totalPage={this.state.totalPages}
					pageSize={this.state.pageSize}
					onPageSizeChange={this.onPageSizeChange.bind(this)}
					onPageChange={this.onPageChange.bind(this)}
					// style={{ marginTop: `${this.state.tableBlankSpace}px` }}
					 />
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Step", utils.getLanguage())}
					visible={this.state.deleteStepVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteStep.bind(this)}>
					<div>
						<I18 tkey="Deleting this will remove all the corresponding sub steps and items belonging to the step. It will also remove the items added for clinical pathways and health pathways under this step. Are you sure you want to delete" />&nbsp;
						<span className="bold">{this.state.deletedStep.name}</span> ?
					</div>
				</Modal>
				{this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							clinicalPathwayType={this.state.clinicalPathwayType && (this.state.clinicalPathwayType !== "ALL") ? this.state.clinicalPathwayType : ""}
							refreshCompleted={this.refreshCompleted}
							stepId={this.state.stepId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}

	componentDidUpdate() {
		if (this.state.enableReorder != this.props.enableReorder) {
			this.setState({ enableReorder: this.props.enableReorder });
			this.fetchDataFromServer();
		}
	}
}

Steps.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminClinicalPathway: store.adminClinicalPathway
}))(Steps));    