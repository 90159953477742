import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import MainOverview from "./main-overview";
import SideBar from "./side-bar";
import "./overview.css";
import Profile from "./profile";
import locations from "../../../router/locations";

class Overview extends Component {
	constructor(props) {
		super(props);
	}

	UNSAFE_componentWillMount() {
		this.unlisten = this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
                            || location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	componentWillUnmount() {
		if (this.unlisten) {
			this.unlisten();
		}
	}

	render() {
		if (locations.profile === this.props.location.pathname) {
			return (
				<Switch>
					<Route exact path={locations.profile} component={Profile} />
				</Switch>
			);
		}
		return (
			<div className="overview_container">
				<SideBar />
				<MainOverview />
			</div>
		);
	}
}

Overview.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Overview));
