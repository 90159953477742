import eventNames from "./event";

const initialState = {
	trajectoryGroupsList: [],
	trajectoryGroupsListStatus: false,
	nodeList: [],
	getNodelistStatus: false,
	updateCreaetGroupStatus: false,
	groupDeletedStatus: false,
	getOneGroupItem: null,
	getOneGroupStatus: false,
	groupNameLis: []
};

const nodeListTransmission = (list) => {
	if(list && list.length > 0) {
		return list.filter(e => e.name.trim() !== "").map(e => {  e.tId= e.id; e.id = e.code;  return e;});
	}
};

const trajectoryTransmission = (list) => {
	if(list && list.length > 0) {
		return list.map(e => { 
			e.nodes&&e.nodes.forEach((element, i) => {
					e["CRITERIA "+(i+1)] = element;
				});
			return e;});
	}
};

const filterGroupNames = (list) => {
	return list.map(e => {e.value = e.groupName; return e;});
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_NODE_GROUP_LIST}_FULFILLED`:
			return {
				...state,
				trajectoryGroupsListStatus: "FULFILLED",
				trajectoryGroupsList: trajectoryTransmission(action.payload.data.data)
			};
		case `${eventNames.GET_NODE_GROUP_LIST}_REJECTED`:
			return {
				...state,
				trajectoryGroupsListStatus: "REJECTED",
				trajectoryGroupsList:[]
			};
		case eventNames.CLEAR_GET_NODE_GROUP_LIST:
			return {
				...state,
				trajectoryGroupsListStatus: false
			};


		case `${eventNames.GET_NODE_LIST}_FULFILLED`:
			return {
				...state,
				getNodelistStatus: "FULFILLED",
				nodeList: nodeListTransmission(action.payload.data.data)
			};
		case `${eventNames.GET_NODE_LIST}_REJECTED`:
			return {
				...state,
				getNodelistStatus: "REJECTED",
				nodeList:[]
			};
		case eventNames.CLEAR_GET_NODE_LIST:
			return {
				...state,
				getNodelistStatus: false
			};

		case `${eventNames.CREATE_UPDATE_NODE_GROUP}_FULFILLED`:
			return {
				...state,
				updateCreaetGroupStatus: "FULFILLED"
			};
		case `${eventNames.CREATE_UPDATE_NODE_GROUP}_REJECTED`:
			return {
				...state,
				updateCreaetGroupStatus: "REJECTED",
			};
		case eventNames.CLEAR_CREATE_UPDATE_NODE_GROUP:
			return {
				...state,
				updateCreaetGroupStatus: false
			};
	
		case `${eventNames.DELETE_NODE_GROUP}_FULFILLED`:
			return {
				...state,
				groupDeletedStatus: "FULFILLED"
			};
		case `${eventNames.DELETE_NODE_GROUP}_REJECTED`:
			return {
				...state,
				groupDeletedStatus: "REJECTED",
			};
		case eventNames.CLEAR_DELETE_NODE_GROUP:
			return {
				...state,
				groupDeletedStatus: false
			};
			
		case `${eventNames.GET_NODE_GROUP}`:
			return {
				...state,
				getOneGroupItem: action.payload.data
			};
		case `${eventNames.CLEAR_GET_NODE_GROUP}`:
			return {
				...state,
				getOneGroupItem:null
			};

		case `${eventNames.GET_ALL_GROUP_NAME}_FULFILLED`:
			return {
				...state,
				groupNameLis: filterGroupNames(action.payload.data.data)
			};
		case `${eventNames.GET_ALL_GROUP_NAME}_REJECTED`:
			return {
				...state,
				groupNameLis:[]
			};
		case eventNames.CLEAR_GET_ALL_GROUP_NAME:
			return {
				...state,
				groupNameLis: []
			};
		default:
			return state;
	}
}
