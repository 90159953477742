import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";

const { Option } = Select;

class StatusDropDown extends Component {
	constructor(props) {
		super(props);
	}

	renderStatus() {
		const options = ["All", "Approved", "Waiting For Approval"];
		return options.map((option) => <Option key={option} value={option.toUpperCase().replace(/ /g, "_")}>{i18Get(option, utils.getLanguage())}</Option>);
	}

	render() {
		return (
			<Select
				className="w100"
				defaultActiveFirstOption={false}
				placeholder={i18Get("Select status", utils.getLanguage())}
				notFoundContent={i18Get("Not Found", utils.getLanguage())}
				optionFilterProp="children"
				onChange={this.props.onStatusChange}
				value={this.props.data}
			>
				{this.renderStatus()}
			</Select>
		);
	}
}

StatusDropDown.propTypes = {
	history: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(StatusDropDown));
