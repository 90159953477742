import React, { Component, Fragment } from "react";
import I18, { i18Get } from "../../../../../../../i18";
import { Modal } from "antd";
import utils from "../../../../../../../utils/utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import Config from "../../../../../../../MOM-config";
import DragWindow from "../drag-window";
import { LoadingOutlined } from "@ant-design/icons";

class ExportClinicalPathway extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModel: false,
			loading: false,
			code: null,
			fileLocation: null,
			showColumns: []
		};
		this.timer = null;
	}

	okText = () => {
		return !this.state.loading ? <I18 tkey="Export now" /> : <I18 tkey="Exporting" />;
	};

	toggleModal = () => {
		this.setState({ showModel: false, loading: false });
		this.props.closeExport();
	};

	UNSAFE_componentWillMount() {
		this.state.showModel = this.props.exportClinicalPathWay;
		if (localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST")) {
			const selectedColumns = JSON.parse(localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST"));
			if (selectedColumns && selectedColumns.check) {
				const columns = [];
				Object.keys(selectedColumns.check).forEach(function(key) {
					if (selectedColumns.check[key] === true) {
						columns.push(key);
					} 
				});
				this.state.showColumns = columns;
			}
		}
	}

	getFilters = () => {
		const filters = {};
		if (this.props.status && this.props.status.value) {
			filters.status = this.props.status.value;
		}
		if (this.props.hospitalId && this.props.hospitalId.value) {
			filters.hospitalId = this.props.hospitalId.value;
		}

		if (this.props.search) {
			filters.search = this.props.search;
		}
		if (this.props.searchField) {
			filters.searchField = this.props.searchField;
		}
		if (this.props.searchType) {
			filters.searchType = this.props.searchType;
		}
		if (this.props.clpStepFilter) {
			filters.clpStepFilter = this.props.clpStepFilter;
		}
		filters.locale = `${utils.getLanguage()}`;
		filters.exportType = "EXCEL";
		filters.showColumns = this.state.showColumns;
		filters.timezone = new Date().getTimezoneOffset();
		return filters;
	};

	onOkClick = () => {
		this.setState({ loading: true });
		const filter = this.getFilters();
		this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReport(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, filter));
	};

	isReportCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while exporting clinical pathway", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};
	title = () => {return (<div className="position_relative"><DragWindow title={i18Get("Export Data", utils.getLanguage())} /></div>);};
	render() {
		return (
			<Fragment>
				<Modal
					visible={this.state.showModel}
					maskClosable={false}
					className="re_assign_modal modal_mini_height_auto"
					title={this.title()}
					onCancel={this.toggleModal}
					okText={this.okText()}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					wrapClassName="drag_modal"
					onOk={this.onOkClick}
					okButtonProps={{ loading: this.state.loading }}>

					<div className="alert margin-top-15 alert-info">
						<p> <I18 tkey="Exporting the data of clinical pathway will take some time. Do not close this tab until the process is completed." /></p>
					</div>
				</Modal>
			</Fragment>
		);
	}

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatus(this.state.code));
			}.bind(this), 3000);
		}
	}

	componentDidUpdate() {
		if (this.props.clinicalPathwayDetails.clinicalPathwayReportExportStatus == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReportCompleted());
			if (this.props.clinicalPathwayDetails.clinicalPathwayReport && this.props.clinicalPathwayDetails.clinicalPathwayReport.code) {
				this.setState({ code: this.props.clinicalPathwayDetails.clinicalPathwayReport.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportExportStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReportCompleted());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportStatus == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatusCompleted());
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayDetails.clinicalPathwayReportStage && this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status) {
				if (this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status == "PROCESSED") {
					const url = Config.apiRoot.replace("/api/v1", "");
					// window.open(`${url}${this.props.intervention.clinicalPathwayReportStage.destination}`);
					this.props.clinicalPathwayDetails.clinicalPathwayReportStage.path ? document.location.href = `${url}${this.props.clinicalPathwayDetails.clinicalPathwayReportStage.path}` : "";
					this.toggleModal();
				} else if (!this.isReportCompleted(this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}

		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatusCompleted());
			this.setState({ loading: false });
		}

	}
}

ExportClinicalPathway.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	parcours: store.parcours,
	clinicalPathwayDetails: store.clinicalPathwayDetails
}))(ExportClinicalPathway));