import React, { Component } from "react";
import { Select } from "antd";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";

const { Option } = Select;

class ActivityDropdown extends Component {
	constructor(props) {
		super(props);
	}

	optionsStatus() {
		const newOptions = ["Creation", "Contribution", "Validation"];
		// let statusIndex = this.props.intervention.status ? newOptions.indexOf(this.props.intervention.status) : -1;
		return newOptions.map((option, index) => <Option key={index} value={option.replace(/ /g, "_")}><I18 tkey={option} /></Option>);
	}

	render() {
		return (
			<div className="">
				<div className="w_100 di position_relative">
					<Select
						allowClear
						className="w100"
						placeholder={i18Get("Select Action", utils.getLanguage())}
						notFoundContent={i18Get("Not Found", utils.getLanguage())}
						optionFilterProp="children"
						onChange={this.props.onActivityChange}
						value={this.props.activity ? i18Get(this.props.activity, utils.getLanguage()) : utils.sameDropper()}
					>
						{this.optionsStatus()}
					</Select>
				</div>
			</div>
		);
	}
}

export default withRouter(ActivityDropdown);
