import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import I18 from "../../../../../../../../i18";
import { Select } from "antd";
import Search from "./search";
import ConsumableTypeDropdown from "./consumable-type-dropdown";
import utils from "../../../../../../../../utils/utils";
import HospitalSelect from "../../../activities/specialities/selectHospital";
import { FilterOutlined } from "@ant-design/icons";
import { i18Get } from "../../../../../../../../i18";
const { Option } = Select;

class Filter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filter: this.getFilterFromSearch(),
			key: 1
		};
	}

   
	UNSAFE_componentWillUpdate() {
       
		if (this.state.pathname !== this.props.location.pathname) {
			this.state.pathname = this.props.location.pathname;
			this.state.filter = this.getFilterFromSearch();
			this.state.key += 1; 
			this.props.filterChanged(this.state.filter, true);
		}
	}

	getFilterFromSearch() {
		return {
			search: utils.checkForParameter("search", this.props.location) ? utils.checkForParameter("search", this.props.location) : "",
			showFilter: utils.checkForParameter("showFilter", this.props.location) === "true" ? true : false,
			// selectedHospital: utils.checkForParameter("selectedHospital", this.props.location) ? JSON.parse(utils.checkForParameter("selectedHospital", this.props.location)) : null,
			selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			consumableType: utils.checkForParameter("consumableType", this.props.location) ? utils.checkForParameter("consumableType", this.props.location) : "ALL",
			selectedStatus: utils.checkForParameter("selectedStatus", this.props.location) ? utils.checkForParameter("selectedStatus", this.props.location) : "ALL"
		};
	}

	searchChanged(e) {
		const prevState = this.state;
		prevState.filter.search = e.target.value;
		this.setState(prevState, () => { this.props.filterChanged(this.state.filter); });
	}

	filterClicked() {
		const prevState = this.state;
		prevState.filter.showFilter = !this.state.filter.showFilter;
		this.setState(prevState);
	}

	typeChanged(e) {
		const prevState = this.state;
		prevState.filter.consumableType = e;
		this.setState(prevState, () => { this.props.filterChanged(this.state.filter); });
	}

	onStatusChange = (e) => {
		const prevState = this.state;
		prevState.filter.selectedStatus = e;
		this.setState(prevState, () => { this.props.filterChanged(this.state.filter); });
	};

	onHospitalChanged(e) {
		const prevState = this.state;
		prevState.filter.selectedHospital = e;
		this.setState(prevState, () => { this.props.filterChanged(this.state.filter); });
	}

	clearFilter() {
		const prevState = this.state;
		prevState.filter.selectedHospital = { value: null, label: i18Get("All", utils.getLanguage()) },
		prevState.filter.selectedStatus = "ALL";
		prevState.filter.consumableType = "ALL";
		prevState.filter.search = "";
		this.setState(prevState, () => { this.props.filterChanged(this.state.filter); });
	}

	getFilter() {
		return (
			<div className="row">
				<div className="col-md-4 performance_filter">
					<div>
						<div className="form-group">
							<label><I18 tkey="Status" />:</label>
							<Select 
								className="w_100"
								value={this.state.filter.selectedStatus ? this.state.filter.selectedStatus : <I18 tkey="All"/>}
								onChange={this.onStatusChange}
							>
								<Option key='All' value="ALL"><I18 tkey="All"/></Option>
								<Option key='Approved' value="APPROVED"><I18 tkey="Approved"/></Option>
								<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval "/></Option>
							</Select>
						</div>
					</div>
				</div>
				<div className="col-md-4">
					<div className="form-group">
						<label><I18 tkey="Hospital" />:</label>
						<HospitalSelect 
							data={this.state.filter.selectedHospital} 
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>       
				<div className="col-md-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>          
		);
	}

	render() {
		return ( 
			<div className="pole_create_section di w_100  float_left">  
				<div className="row">
					<div className="col-md-4 ">
						<ConsumableTypeDropdown
							type={this.state.filter.consumableType}
							typeChanged={this.typeChanged.bind(this)}/>
                              
					</div>
					<div className="col-md-offset-3 col-md-4">
						<Search
							key={this.state.key }
							isConsumableClass={this.props.isConsumableClass}
							filter={this.state.filter}
							searchChanged={this.searchChanged.bind(this)}/>
					</div>
					<div className="col-md-1 text_align_right">
						<div className="filter margin-right-0" onClick={this.filterClicked.bind(this)}>
							<FilterOutlined className={this.state.filter.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
						</div>
					</div>
				</div>

				{ this.state.filter.showFilter ? 
					<div className=" min-height-60 margin-bottom-5">
						<div className="min-height-60 margin-top-15">
							{this.state.filter.showFilter ? this.getFilter() : ""}
						</div>  
					</div> : ""}
               
			</div>
		);
	}
}        

export default withRouter(Filter);