import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { MenuOutlined } from "@ant-design/icons";
import { Table, Checkbox } from "antd";
import { Resizable } from "react-resizable";
import "antd/dist/antd.min.css";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";

const DragHandle = sortableHandle(({ active }) => (
	<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class DataList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			quickReorderChanges: 0,
			orderChanged: false,
			problemWidth: 500,
			typeWidth: 200,
			modelWidth: 500,
			selectedItems: [],
			unSelect: false
		};
	}

	UNSAFE_componentWillMount() {
		const prevState = this.state;
		prevState.list = this.props.list;
		prevState.quickReorderChanges = this.props.quickReorderChanges;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.state.quickReorderChanges !== this.props.quickReorderChanges) {
			const list = this.props.list;
			this.setState({ quickReorderChanges: this.props.quickReorderChanges, selectedItems: [], list: list });
		} 
		
		if (this.props.unSelect === true) {
			this.onClickUnselect();
		}
	}

	onClickUnselect = () => {
		this.setState({ unSelect: false, selectedItems: [] });
		this.props.deSelectHandler(this.props.type);
	};

	renderStyledCell = (text, problem) => ({
		props: {
			style: { background: "" }
		},
		children: (
			<div className="d-flex">
				<div className="">{text ? this.getText(text, problem) : ""}</div>
			</div>
		)
	});

	getText = (value, problem) => {
		return (
			<div>
				<div>
					{value && problem ? <a className="text_underline" onClick={() => { this.props.onClickProblem(value); }}>{value.problem}</a> : <span className={value.modelTreatmentId ? "model_attached_td" : ""}>{value.type}</span>}
				</div>
			</div>
		);
	};
  
	handleResize = label => (e, { size }) => {
		switch (label) {
			case "typeWidth":
				this.setState({ typeWidth: size.width });
				break;
			case "modelWidth":
				this.setState({ modelWidth: size.width });
				break;
			case "problemWidth":
				this.setState({ problemWidth: size.width });
				break;
			default:
				break;
		}
	};
	
	onCheckBoxChange = (e, i, problemId) => {
		const prevState = this.state;
		const index = this.state.selectedItems.findIndex(value => value.problemId === problemId);
		if (index === -1) {
			prevState.selectedItems.push({ problemId: problemId, index: i });
		} else {
			prevState.selectedItems.splice(index, 1);
		}
		this.setState(prevState);
		this.props.selectedItemsHandler(prevState.selectedItems, this.props.type);
	};

	fetchModelData(data, modelDetails)
	{
	    const fetchData = [];

		if (data.modelTreatmentId && !modelDetails.includes(data.modelTreatmentName)) {
			fetchData.push(data.modelTreatmentName);
		}

		if (data.additionalInfo && data.additionalInfo !== undefined)
		{
			data.additionalInfo.forEach((eachProblem) => {
				if (eachProblem.modelTreatmentId && !modelDetails.includes(eachProblem.modelTreatmentName)) {
					fetchData.push(eachProblem.modelTreatmentName);
				}
			});
		}

		return [...new Set(fetchData)];
	}

	getColumns = (selectedItems) => [
		{
			title: "",
			dataIndex: "problemId",
			className: "",
			width: 100,
			render: (text, record, index) => (
				<div className="">
					<DragHandle active={selectedItems.length > 0 && selectedItems.findIndex((x) => x.index === index) !== -1} />
					<span className="margin-left-10" >
						<Checkbox key={ index }
							checked={selectedItems.length > 0 && selectedItems.findIndex(value => value.problemId === text) !== -1}
							onChange={!this.state.unSelect ? (e) => this.onCheckBoxChange(e, index, text) : ""}
						/>
					</span>
				</div>
			)
		},
		{
			title: i18Get("TYPE", utils.getLanguage()),
			dataIndex: "type",
			className: "drag-visible",
			width: this.state.typeWidth,
			render: (text, record) => this.renderStyledCell(record),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("typeWidth")
			})
		},
		{
			title: i18Get("PROBLEM", utils.getLanguage()),
			dataIndex: "problem",
			className: "drag-visible",
			width: this.state.problemWidth,
			render: (text, record) => this.renderStyledCell(record, "problem"),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("problemWidth")
			})
		},
		{
			title: i18Get("PROBLEM SOURCE", utils.getLanguage()),
			dataIndex: "modelTreatmentName",
			className: "drag-visible",
			width: this.state.modelWidth,
			render: (text, record) => {
				const models = this.fetchModelData(record, []);
				const currentSource = `Specific to the  ${this.props.clinicalPathwayData.isCheckList ? "Check List" : this.props.clinicalPathwayData.isModel ?  "Model" : "Clinical Pathway" }`;
				return <div>{models.length > 0 && models[0] ? <span>{models.toString().replace(",", ", ")}{(!record.modelTreatmentId) ? <i>, {i18Get(currentSource, utils.getLanguage())}</i> : ""}</span> : <i>{i18Get(currentSource, utils.getLanguage())}</i>}</div>;
			},
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("modelWidth")
			})
		}
	];

	merge(a, b, i = 0) {
		const aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	}

	updateIndex(tempDataSource) {
		const prevState = this.state;
		prevState.selectedItems.map(function (data, i) {
			const indexValue = tempDataSource.findIndex(value => value.problemId === data.problemId);
			if (indexValue !== -1) {
				prevState.selectedItems[i].index = indexValue;
			}
		});
		this.setState({ selectedItems: prevState.selectedItems });
	}

	getTaskList(list) {
		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns(this.state.selectedItems)}
				bordered
				rowKey="problemId"
				key={0}
				rowClassName = { (record) => {
					return record.modelTreatmentId ? "model_attached_tr" : "";
				}}
				components={{
					header: {
						cell: ResizableTitle
					},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	}

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	DraggableBodyRow = ({ className, style, ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex(
			(x) => x.problemId === restProps["data-row-key"]
		);
		return <SortableItem className={className} style={style} index={index} {...restProps} />;
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { selectedItems, list } = this.state;
		let tempDataSource = JSON.parse(JSON.stringify(list));

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				tempDataSource = arrayMove([].concat(tempDataSource), oldIndex, newIndex).filter((el) => !!el);
			} 
			else {
				const filteredItems = [];
				selectedItems.forEach((d) => {
					filteredItems.push(tempDataSource[d.index]);
				});
				const newData = [];
				tempDataSource.forEach((d, i) => {
					if (selectedItems.findIndex((x) => x.index === i) === -1) {
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = this.merge(tempDataSource, filteredItems, newIndex);
				this.updateIndex(tempDataSource, selectedItems);
			}
			this.setState({ list: tempDataSource, orderChanged: true });
			this.props.setListItemsHandler(tempDataSource, this.props.type);
		}
	};

	render() {
		return (<div className="">{this.getTaskList(this.state.list)}</div>);
	}
}

DataList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		actions: store.actions
	}))(DataList)
);
