import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllCategoryPersonnel = (queryString) => ({
	type: events.FETCH_ALL_CATEGORY_PERSONNEL,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnel?${queryString}`)
});

const clearFetchAllCategoryPersonnel = () => ({
	type: events.CLEAR_FETCH_ALL_CATEGORY_PERSONNEL,
	payload: {}
});

const fetchOneCategoryPersonnel = (id) => ({
	type: events.FETCH_ONE_CATEGORY_PERSONNEL,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnel/${id}`)
});

const clearFetchOneCategoryPersonnel = () => ({
	type: events.CLEAR_FETCH_ONE_CATEGORY_PERSONNEL,
	payload: {}
});

const createCategoryPersonnel = (payload) => ({
	type: events.CREATE_CATEGORY_PERSONNEL,
	payload: axios.post(`${Config.apiRootSecure}/categoryPersonnel`, { ...payload })
});

const clearCreateCategoryPersonnel = () => ({
	type: events.CLEAR_CREATE_CATEGORY_PERSONNEL,
	payload: {}
});

const updateCategoryPersonnel = (id, payload) => ({
	type: events.UPDATE_CATEGORY_PERSONNEL,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnel/${id}`, { ...payload })
});

const clearUpdateCategoryPersonnel = () => ({
	type: events.CLEAR_UPDATE_CATEGORY_PERSONNEL,
	payload: {}
});

const deleteCategoryPersonnel = (id) => ({
	type: events.DELETE_CATEGORY_PERSONNEL,
	payload: axios.delete(`${Config.apiRootSecure}/categoryPersonnel/${id}`)
});

const clearDeleteCategoryPersonnel = () => ({
	type: events.CLEAR_DELETE_CATEGORY_PERSONNEL,
	payload: {}
});

const fetchAllApprovedCategoryPersonnel = (queryString) => ({
	type: events.FETCH_ALL_APPROVED_CATEGORY_PERSONNEL,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnel?${queryString}`)
});

const clearFetchAllApprovedCategoryPersonnel = () => ({
	type: events.CLEAR_FETCH_ALL_APPROVED_CATEGORY_PERSONNEL,
	payload: {}
});

const saveAndApproveCategoryPersonnel = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_CATEGORY_PERSONNEL,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnel/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveCategoryPersonnelCompleted = () => ({
	type: events.SAVE_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED,
	payload: {}
});

const assignAndApproveCategoryPersonnel = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnel/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveCategoryPersonnelCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED,
	payload: {}
});

export default {
	fetchAllCategoryPersonnel,
	clearFetchAllCategoryPersonnel,
	fetchOneCategoryPersonnel,
	clearFetchOneCategoryPersonnel,
	createCategoryPersonnel,
	clearCreateCategoryPersonnel,
	updateCategoryPersonnel,
	clearUpdateCategoryPersonnel,
	deleteCategoryPersonnel,
	clearDeleteCategoryPersonnel,
	fetchAllApprovedCategoryPersonnel,
	clearFetchAllApprovedCategoryPersonnel,
	saveAndApproveCategoryPersonnel,
	assignAndApproveCategoryPersonnel,
	assignAndApproveCategoryPersonnelCompleted,
	saveAndApproveCategoryPersonnelCompleted
};
