import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import { Select } from "antd";
import CreateActivityFamilies from "./create";
import EditActivityFamilies from "./edit";
import ListActivityFamilies from "./list";
import BreadCrumb from "../../../breadcrumb";
import "./activity_families.css";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { DebounceInput } from "react-debounce-input";
import HospitalSelect from "../../activities/specialities/selectHospital";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;


class Families  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activityFamily: {},
			typeActivity: "INTERVENTION",
			showFilter: false,
			copyKey: 1,
			selectedStatus: "ALL",
			selectedHospital: "",
			totalRecords: 0
		};
	}

	UNSAFE_componentWillMount() {
		if (utils.checkForParameter("typeActivity", this.props.location)) {
			this.state.typeActivity = utils.checkForParameter("typeActivity", this.props.location);
		}
	}

	onStatusChange = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Sterilization Families", utils.getLanguage()), link: locations.sterilization_families });
		if (this.props.location.pathname === locations.sterilization_families + "/create") {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.sterilization_families + "/create" });
		}
		if (this.state.activityFamily.id) {
			items.push({ name: this.state.activityFamily.name, link: locations.sterilization_families + `/${this.state.activityFamily.id}` });
		}
		return items;
	}

	addClicked() {
		this.props.history.push(locations.sterilization_families + "/create");
	}

	getSortAndDesc(sort, desc) {
		this.state.sort = sort;
		this.state.desc = desc;
	}

	clearFilter() {
		this.setState({ selectedHospital: "", selectedStatus: "ALL", filter: "" });
	}

	getFilter() {
		return (
			<div className="row ">
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Status" />:</label>
						<Select 
							className="w_100"
							value={this.state.selectedStatus ? this.state.selectedStatus : <I18 tkey="All"/>}
							onChange={this.onStatusChange}
						>
							<Option key='All' value="ALL"><I18 tkey="All"/></Option>
							<Option key='Approved' value="APPROVED"><I18 tkey="Approved"/></Option>
							<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval "/></Option>
						</Select>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Hospital" />:</label>
						<HospitalSelect 
							data={this.state.selectedHospital} 
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter });
	}

	setCount = (count) => {
		this.setState({ totalRecords: count });
	};

	onHospitalChanged(e) {
		this.setState({ selectedHospital: e ? e : "" });
	}

	showContent() {
		if (this.props.location.pathname === locations.sterilization_families) {
			return true;    
		}
		return false;
	}
    
	render() {
		return (    
			<div className="main_overview_section">
				<div className="bloc_container">
					<BreadCrumb items={this.generateItems()} showRecord={this.showContent()} totalRecords={this.state.totalRecords}/>
					{this.showContent() && <div className="main_overview_top_section di w_100 padding-top-15">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								<div className="create_interventions margin-top-2">
									<button className="common_button" type="button" onClick={this.addClicked.bind(this)}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE" /></button>
								</div> 
							</div>
						</div>
					</div>}
					<div className="di w_100">
                   
						<div className="tab_section tab_section_speciality padding-top-15">
							{
								this.props.location.pathname === locations.sterilization_families ?
									<div>
										<div className="row margin-bottom-15">
											<div className="col-md-4">
												{/* <TypeActivityDropdown typeActivitySelected={this.typeActivityChanged.bind(this)} typeActivity={this.state.typeActivity}/> */}
											</div>
											<div className="col-md-3"></div>
											<div className="col-md-4">
												<DebounceInput
													className="filter_input_design form-control"
													debounceTimeout={750}
													placeholder={i18Get("Search...", utils.getLanguage())}
													value={this.state.filter}
													onChange={this.filterChanged.bind(this)}/>
											</div>
											<div className="col-md-1">
												<div className="filter" onClick={this.filterClicked.bind(this)}>
													<FilterOutlined className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
												</div>
											</div>
										</div>
										{this.state.showFilter && this.getFilter()} 
									</div>
									: ""
							}
							<div className="tab-content">
								<div id="home" className="tab-pane fade in active">
									{this.loadContent()}
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <CopyModal key={this.state.copyKey} copyClicked={this.copyClicked.bind(this)} showCopyModal={this.state.showCopyModal} type="SPECIALITY"/> */}
			</div>   
		);
	}


	loadContent() {
		return (
			<Switch>
				<Route exact path={locations.sterilization_families}
					render={() =>
						<ListActivityFamilies typeActivity={this.state.typeActivity} setCount={this.setCount} filter={this.state.filter} status = {this.state.selectedStatus}  hospitalId = {this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "" } getSortAndDesc={this.getSortAndDesc.bind(this)}/> } />
       
				<Route exact path={locations.sterilization_families + "/create"} 
					render={() =>
						<CreateActivityFamilies tab={this.state.tab} typeActivity={this.state.typeActivity}/> } />
				<Route path={locations.sterilization_families_edit}
					render={() =>                    
						<EditActivityFamilies typeActivity={this.state.typeActivity}/> } />
				<Redirect to={locations.sterilization_families } /> 
			</Switch>
		);
	}

	filterChanged = ({ target: { value } }) => {
		this.setState({ filter: value });
	};

}

Families.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	breadcrumb: store.breadcrumb,
	consomableSterilization: store.consomableSterilization,
	translations: store.translations
}))(Families));  