import { connect } from "react-redux";
import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import userActions from "../../../../../../../actions/user";
import eventNames from "../../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import NumericInput from "../../../../../../../plug/numeric-input";
import { Radio, Input, Tooltip } from "antd";
import SVG from "../../../../../../../plug/svg";
import HospitalSelect from "../../activities/specialities/selectHospital";
import SiteDropDown from "../../../clinical-pathway/createClinicalPathWay/site_dropdown";
import { Communities } from "../../../uiComponents";
import Roles from "../rolePermissions/roles/components/roles";
import { InfoCircleOutlined } from "@ant-design/icons";
const PasswordValidation = React.lazy(() => import("mom-ui-commons/build/components/user/passwordValidation"));

const RadioGroup = Radio.Group;

class userCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			contactBy: "EMAIL",
			showPassword: false,
			invalidcontactBy: false,
			invalidemail: false,
			invalidname: false,
			invalidpassword: false,
			invalidphone: false,
			invalidstatus: false,
			selectedCommunity: [],
			invalidCommunity: false,
			userRole: {}

		};
	}

	onChanged(stateValue, value ) {
		const prevState = this.state;
		prevState["invalid" + stateValue] = false;
		if (stateValue === "contactBy" || stateValue === "password" || stateValue === "email") {
			prevState[stateValue] = value.target.value;     
		} else if (stateValue == "status") {
			prevState[stateValue] = value.label;
			prevState.userRole = value;
			prevState.selectedHospital = null;
			prevState.site = null;
		} else {
			prevState[stateValue] = value;
		}
		this.setState(prevState, this.cleanValidations);
		
	}

	onChangePhoneNumber(stateValue, value) {
		const prevState = this.state;
		prevState.invalidphone = false;
		prevState["phone"] = value;
		this.setState(prevState);
		
	}

	toggleShowPassword() {
		const prevState = this.state;
		prevState.showPassword = !this.state.showPassword;
		this.setState(prevState);
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				name: this.state.name.trim(),
				contactBy: this.state.contactBy,
				email: this.state.email,
				userRole: this.state.userRole ? this.state.userRole.label : "",
				userRoleId: this.state.userRole ? this.state.userRole.value : "",
				roleType: this.state.userRole ? this.state.userRole.roleType : "",
				hospitalId: this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "",
				siteId: this.state.site && this.state.site.value !== "ALL" ? this.state.site.value : "",
				password: this.state.password,
				phone: this.state.phone,
				communityIds: this.createPayloadData(this.state.selectedCommunity)
			};
			this.props.dispatch(userActions.createUser(payload));
		}
	}

	cleanValidations() {
		const prevState = this.state;
		prevState.invalidname = false;
		prevState.invalidemail = false;
		prevState.invalidcontactBy = false;
		prevState.invalidphone = false;
		prevState.invalidpassword = false;
		prevState.invalidstatus = false;
		prevState.invalidHospital = false;
		prevState.invalidCommunity = false;
		prevState.invalidSite = false;
		this.setState(this.state);
	}

	validateFields() {
		const prevState = { ...this.state };
		let valid = true;
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim()) {
			prevState.invalidname = true;
			valid = false;
		}
		if (!this.state.contactBy) {
			prevState.invalidcontactBy = true;
			valid = false;
		}
		if ((!utils.regexEmail.test(this.state.email))) { 
			prevState.invalidemail = true;
			valid = false;
		}
		if (!utils.regexPhone.test(this.state.phone)) {
			prevState.invalidphone = true;
			valid = false;
		}
		if (!utils.regexPassVal.test(this.state.password)) {
			prevState.invalidpassword = true;
			valid = false;
		}
		if (!this.state.userRole.value) {
			prevState.invalidstatus = true;
			valid = false;
		}     
		if (this.state.userRole.roleType === "PATHWAY_USER") {
			if (!this.state.selectedHospital && this.state.userRole) {
				prevState.invalidHospital = true;
				valid = false;
			}  
			if (!this.state.site && this.state.userRole) {
				prevState.invalidSite = true;
				valid = false;
			}  
	
			if ((!this.state.selectedCommunity || this.state.selectedCommunity.length == 0) && this.state.userRole) {
				prevState.invalidCommunity = true;
				valid = false;
			}
		}
		
		if (!valid) {
			this.setState(prevState);
		}
		return valid;
	}


	onHospitalChanged(e) {
		const prevState = this.state;
		prevState.selectedHospital = e;
		prevState.site = null;
		prevState.selectedCommunity = "";
		prevState.invalidHospital = false;
		this.setState(prevState);
	}

	siteChanged(e) {
		const prevState = this.state;
		prevState.selectedCommunity = "";
		prevState.invalidCommunity = false;
		prevState.site = e;
		this.setState(prevState);
		
	}

	//------------MULTI SELECT CHECK BOX---------------------------------
	onCommunityChanged = e => {
		const prevState = this.state;
		prevState.selectedCommunity = e;
		prevState.invalidCommunity = false;//If needed
		this.setState(prevState);
	};
	//Use this function for fetch Ids
	createPayloadData(data) {
		const dataList = [];
		data.map(data => {dataList.push(data.value);});
		return dataList;
	}

	render() {
		if (this.props.user.isCreateUsersCompleted === "FULFILLED") {
			this.props.dispatch(userActions.clearCreateuser());
			this.props.history.push(locations.userManagement_users);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.user.isCreateUsersCompleted === "REJECTED") {
			this.props.dispatch(userActions.clearCreateuser());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100 padding-top-15">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="col-md-offset-12 col-md-12 col-sm-offset-12 col-sm-12">
								<div className="w_100 di position_relative text_align_right">
									<label className="margin-right-15"><I18 tkey="Contact by" /></label>
									<RadioGroup onChange={this.onChanged.bind(this, "contactBy")} value={this.state.contactBy}>
										<Radio value={"SMS"}><I18 tkey='SMS' /></Radio>
										<Radio value={"EMAIL"}><I18 tkey='EMAIL' /></Radio>
									</RadioGroup>
									{this.state.invalidcontactBy && <span className='invalid_right fixed_label'><I18 tkey="Select contact by" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="w_100 di margin-bottom-9 position_relative">
									<label><span className='color_red font_size_14px'>*</span><I18 tkey="Name" /></label>
									<NameInput
										value={this.state.name}
										inputChanged={this.onChanged.bind(this, "name")}
										maxLength={60}
									/>
									{this.state.invalidname && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="w_100 di margin-bottom-9 position_relative">
									<label><span className='color_red font_size_14px'>*</span><I18 tkey="Phone" /></label>
									<NumericInput
										value={this.state.phone}
										getNumericValue={this.onChangePhoneNumber.bind(this)}
										maxLength={30}
										integerOnly={true}
									/>
									{this.state.invalidphone && <span className='invalid_right fixed_label'><I18 tkey="Enter valid phone" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="w_100 di  position_relative">
									<label><span className='color_red font_size_14px'>*</span><I18 tkey="Role" /></label>
									<Roles 
										isMulti = {false}
										value={this.state.userRole}
										onChange={this.onChanged.bind(this, "status")}
									/>
									{this.state.invalidstatus && <span className='invalid_right fixed_label'><I18 tkey="Select valid role" /></span>}
								</div>
							</div>

							{this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER" &&
								<div className="col-md-12 col-sm-12 margin-top-8">
									<div className="w_100 di margin-bottom-9 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Hospital" /></label>
										<HospitalSelect
											data={this.state.selectedHospital}
											hospitalChanged={this.onHospitalChanged.bind(this)}
											isClpList = {true} />
										{this.state.invalidHospital && <span className='invalid_right fixed_label'><I18 tkey="Select hospital" /></span>}
									</div>
								</div>}
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="privacy_section">
								<div className="col-md-12 col-sm-12">
									<div className="w_100 di margin-bottom-3 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Email" /></label>
										<Input
											value={this.state.email}
											onChange={this.onChanged.bind(this, "email")}
											maxLength={60}
											type='email'
										/>
										{this.state.invalidemail && <span className='invalid_right fixed_label'><I18 tkey="Enter valid email" /></span>}
									</div>
								</div>
								<div className="col-md-12 col-sm-12">
									<div className="w_100 di margin-bottom-5 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Password" /></label>
										<div className="eye_icon_container">
											<Input
												value={this.state.password}
												onChange={this.onChanged.bind(this, "password")}
												maxLength={60}
												type={this.state.showPassword ? "text" : "password"}
											/>
											<a onClick={this.toggleShowPassword.bind(this)}>
												{this.state.showPassword ?
													<SVG src='/images/eye.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
													: <SVG src='/images/eye-slash.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
											</a>
										</div>
										{this.state.invalidpassword && <span className='invalid_right fixed_label'><I18 tkey="Enter valid password" /></span>}
										<Suspense fallback={<div></div>}><PasswordValidation value={this.state.password} className="" style={{ top: "-30px", left: "-24px" }} /></Suspense>
									</div>
								</div>
							</div>
							{this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER" &&
								<div className="col-md-12 col-sm-12 margin-top-8">
									{this.state.selectedHospital &&
										<div className="w_100 di margin-bottom-12 position_relative">
											<label><span className='color_red font_size_14px'>*</span><I18 tkey="Site" /></label>
											<SiteDropDown
												autoSetSingleOne = {true}
												data={this.state.site}
												siteChanged={this.siteChanged.bind(this)}
												isUser={true}
												hospital={this.state.selectedHospital.value} />
											{this.state.invalidSite && <span className='invalid_right fixed_label'><I18 tkey="Select site" /></span>}
										</div>}
								</div>}
							{this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER" && this.state.site &&
								<div className="col-md-12 col-sm-12">
									<div className="w_100 di margin-bottom-15 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Communities" /></label>
										<Communities
											autoSetSingleOne = {true}
											value={this.state.selectedCommunity}
											onChange={this.onCommunityChanged.bind(this)}
											filter={{
												hospitalId: this.state.selectedHospital.value,
												siteId: this.state.site.value,
												filterBySite: 1
											}}
										/>
										{this.state.invalidCommunity && <span className='invalid_right fixed_label'><I18 tkey="Select Community" /></span>}
									</div>
								</div>
							}
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton showAdd={true} onClick={this.createClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

userCreate.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	blocType: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	user: store.administartionUser,
	blocType: store.blocType,
	actions: store.actions,
	settings: store.settings,
	translations: store.translations
}))(userCreate));