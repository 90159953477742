import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "plug/react-multiselect-checkboxes";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "i18";
import { Tooltip } from "antd";
import utils from "utils/utils";
import categoryPersonnelActions from "actions/category-personnel";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class CategoryPersonnelDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			offset: 0,
			numResults: 100,
			search: "",
			options: []
		};
	}

	UNSAFE_componentWillMount() {
		this.state.list = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.list) {
			this.state.list.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		
		if (this.props.data && this.props.data.length === 0 && this.props.autoSetSingleOne === true) {
			const multiSelect = !this.props.clinicalPathway ? false : true;
			utils.setSingleOneAsDefaultOption(prevState.options, this.props.categoryPersonnelChanged, multiSelect);
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchList() {
		this.setState({ isListLoading: true });
		let queryString = `offset=${this.state.offset}&numResults=${this.state.numResults}&sort=name&desc=0`;
		queryString += "&status=APPROVED";
		queryString += this.state.search ? `&name=${this.state.search}` : "";
		this.props.dispatch(categoryPersonnelActions.fetchAllApprovedCategoryPersonnel(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchList(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchList();
	}

	render() {
		if (this.props.categoryPersonnel.isFetchAllApprovedCategoryPersonnelCompleted === "FULFILLED") {
			this.state.list = unionWith(this.state.list, this.props.categoryPersonnel.categoryPersonnelList.data, isEqual);
			this.renderOptions(); 
			this.props.dispatch(categoryPersonnelActions.clearFetchAllApprovedCategoryPersonnel());
		}
		if ((this.props.clinicalPathway && this.props.data && this.props.data.length > 0 && this.props.filterData ) ||
        (this.props.listActiveFirst && this.props.data && this.props.data.length > 0)) {
			const filteredData = this.state.options.filter(e => !this.props.data.find(rm => (rm.value === e.value)));
			this.state.options = this.props.data.concat(filteredData);
		}
		return (
			<div className={this.props.className}>
				<Tooltip placement="top" title={i18Get("Select Intervenant", utils.getLanguage())} >
					<div className={!this.props.disabled ? `react_multi_select_checkbox${!this.props.isAdminPage ? " drop_max_height_150" : ""}` : ""}>
						{!this.props.disabled ?
							<ReactMultiSelectCheckboxes
								className="w_100 di"
								value={this.state.options ? 
									this.state.options.filter(e => this.props.customData.intervenantsCode ? 
										this.props.customData.intervenantsCode.includes(e.code) 
										: "") 
									: null}
								onChange={this.props.categoryPersonnelChanged}
								options={this.state.options}
								isMulti={true}
								isClearable={true}
								isDisabled={this.props.disabled}
								onMenuScrollToBottom={this.onListScroll.bind(this)}
								onInputChange={this.onSearch.bind(this)}
								placeholderButtonLabel={i18Get("Select Intervenant", utils.getLanguage())}
								isLoading={this.state.isListLoading}
								noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
								loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
							/>
							:
							<Select
								className="w_100 di "
								isDisabled={true}
								placeholder={i18Get("Select Intervenant", utils.getLanguage())}
							/>
						}
					</div>
				</Tooltip>
			</div>
		);
	}
}

CategoryPersonnelDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	categoryPersonnel: store.categoryPersonnel,
	translations: store.translations
}))(CategoryPersonnelDropdown));
