import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";
import FieldDropdown from "./field-dropdown";
import SearchType from "./search-type";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

export default class Search extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidStatus: false,
			selectedStatus: "All"
		};
	}

	render() {
		return (
			<React.Fragment>
				<FieldDropdown
					searchField={this.props.searchField}
					searchFieldChanged={this.props.searchFieldChanged}
				/>
				<div className="">
					<SearchType
						searchType={this.props.searchType}
						searchTypeChanged={this.props.searchTypeChanged}
					/>
				</div>
				<div className="pathway_search">
					<div className="cosumable_search">
						<DebounceInput
							className="filter_input_design form-control"
							debounceTimeout={750}
							maxLength={750}
							placeholder={i18Get("Search...", utils.getLanguage())}
							value={this.props.search ? this.props.search : ""}
							onChange={this.props.searchChanged}
						/>
					</div>
				</div>
			</React.Fragment>
		);
	}
}
