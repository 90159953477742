import React from "react";
import { Tooltip } from "antd";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";

export default function getCols(getActionButton, moveToDetails, viewLinkedList, viewCommunityList, category) {
	const tableArray = ([
		{
			Header: "",
			id: "actions",
			accessor: (d) => d,
			resizable: false,
			width: category === "checkList" ? 60 : 80,
			sortable: false,
			Cell: (row) => (
				<div>
					{getActionButton(row)}
				</div>

			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d,
			width: 130,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.code
					}
				</span>
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			width: 350,
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						`${row.value.siteId && row.value.hospitalId ? row.value.siteShortName : row.value.hospitalName ? row.value.hospitalName : "MOM"} ${row.value.name}`
						//`${row.value.hospitalName ? row.value.hospitalName : ""} ${row.value.name}`
					}
				</span>
			)
		},
		 {
			Header: i18Get("MODIFIED ON", utils.getLanguage()),
			id: "updatedAt",
			accessor: (d) => d,
			resizable: true,
			width: 120,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.updatedAt ? row.value.updatedAt : "-"
					}
				</span>
			)
		}, {
			Header: i18Get("CLINICAL PATHWAYS NAME", utils.getLanguage()),
			id: "linkedClps",
			accessor: (d) => d,
			resizable: true,
			sortable: false,
			show: category === "checkList",
			Cell: (row) => (
				<div className="linked_data_count"><a onClick={viewLinkedList(row.value && row.value.linkedClps ? row.value.linkedClps : [], true)}>{row.value && row.value.linkedClps ? row.value.linkedClps.length : 0}</a></div>
			)
		},
		{
			Header: i18Get("CHECK LIST NAME", utils.getLanguage()),
			id: "linkedCheckList",
			accessor: (d) => d,
			resizable: true,
			sortable: false,
			show: category !== "checkList",
			Cell: (row) => (
				<div className="linked_data_count"><a onClick={viewLinkedList(row.value && row.value.linkedCheckList ? row.value.linkedCheckList : [], true)}>{row.value && row.value.linkedCheckList ? row.value.linkedCheckList.length : 0}</a></div>
			)
		},
		{
			Header: i18Get("MODEL LIST NAME", utils.getLanguage()),
			id: "modelLinkedList",
			accessor: (d) => d,
			resizable: true,
			sortable: false,
			show: category !== "checkList" && category !== "model",
			Cell: (row) => (
				<div className="linked_data_count"><a onClick={viewLinkedList(row.value && row.value.modelClpList ? row.value.modelClpList : [], true)}>{row.value && row.value.modelClpList ? row.value.modelClpList.length : 0}</a></div>
			)
		},
		{
			Header: i18Get("CLINICAL PATHWAYS NAME", utils.getLanguage()),
			id: "clpLinkedList",
			accessor: (d) => d,
			resizable: true,
			sortable: false,
			show: category !== "checkList" && category === "model",
			Cell: (row) => (
				<div className="linked_data_count"><a onClick={viewLinkedList(row.value && row.value.modelAttachedClpList ? row.value.modelAttachedClpList : [], true)}>{row.value && row.value.modelAttachedClpList ? row.value.modelAttachedClpList.length : 0}</a></div>
			)
		},
		// {
		// 	Header: i18Get("COMMUNITY LIST NAME", utils.getLanguage()),
		// 	id: "communityList",
		// 	accessor: (d) => d,
		// 	resizable: true,
		// 	sortable: false,
		// 	Cell: (row) => (
		// 		<div className="linked_data_count"><a onClick={viewCommunityList(row.value && row.value.communityList ? row.value.communityList : [], true)}>{row.value && row.value.communityList ? row.value.communityList.length : 0}</a></div>
		// 	)
		// },
		{
			Header: i18Get("SPECIALITY", utils.getLanguage()),
			id: "specialityName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.specialityName
					}
				</span>
			)
		},
		{
			Header: i18Get("FAMILY", utils.getLanguage()),
			id: "familyName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.familyName
					}
				</span>
			)
		},
		{
			Header: i18Get("DUREE", utils.getLanguage()),
			id: "duree",
			accessor: (d) => d,
			sortable: false,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					<Tooltip
						placement="top"
						title={() => (
							<div>
								<I18 tkey="Before Admission" />
								:{`${row.value.beforeAdmissionNoOfDays} ${i18Get("Jours")}`}
								<br />
								<I18 tkey="Hospitalisation" />
								:{(row.value.noOfDays == 1 && row.value.noOfHours > 0 ? `${row.value.noOfHours} ${i18Get("Hours")}` : `${row.value.noOfDays} ${i18Get("Jours")}`)}
								<br />
								<I18 tkey="After Checkout" />
								:{`${row.value.afterCheckOutNoOfDays} ${i18Get("Jours")}`}
							</div>
						)}
					>
						<a className={row.value ? "color_black" : ""}>
							{`${row.value.beforeAdmissionNoOfDays} ${i18Get("Jours")}`}
							{" / "}
							{(row.value.noOfDays == 1 && row.value.noOfHours > 0 ? `${row.value.noOfHours} ${i18Get("Hours")}` : `${row.value.noOfDays} ${i18Get("Jours")}`)}
							{" / "}
							{`${row.value.afterCheckOutNoOfDays} ${i18Get("Jours")}`}
						</a>
					</Tooltip>
				</span>
			)
		},
		{
			Header: i18Get("TYPE", utils.getLanguage()),
			id: "clinicalPathway",
			accessor: (d) => d,
			sortable: false,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.clinicalPathway === "MEDICINE" ? <I18 tkey="MEDICINE WITH ACTE" /> : row.value.clinicalPathway.replace(/_/g, " ")
					}
				</span>
			)
		},
		{
			Header: i18Get("HOSPITAL", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.hospitalName ? row.value.hospitalName : <I18 tkey="MOM" />
					}
				</span>
			)
		},
		{
			Header: i18Get("SITE", utils.getLanguage()),
			id: "siteName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.siteName ? row.value.siteName : <I18 tkey="MOM" />
					}
				</span>
			)
		 }
		// {
		// 	Header: i18Get("STATUS", utils.getLanguage()),
		// 	id: "status",
		// 	accessor: (d) => d,
		// 	width: 150,
		// 	resizable: true,
		// 	Cell: (row) => (
		// 		<span onClick={() => { moveToDetails(row.value); }}>
		// 			<Tooltip placement="top" title={i18Get(`${row.value.status ? row.value.status === "APPROVED" ? "Approved" : "Waiting For Approval" : ""} - From ${row.value.momHospitalName ? row.value.momHospitalName : "Master"}`, utils.getLanguage())}>
		// 				<div className="d-flex align-items-center">
		// 					{row.value.momHospitalName ? row.value.momHospitalName : "MOM"}
		// 					<div className={`hospitalname_tag margin-left-10 ${row.value.momHospitalName ? "hospitalnameactive" : ""}`} />
		// 				</div>
		// 			</Tooltip>
		// 		</span>
		// 	)
		// }
	]);
	return tableArray;
}
