import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";

const { Option } = Select;

class clinicalPathwayTypeDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	renderClinicalPathwayTypeOptions() {
		const options = [];
		options.push(<Option key="SURGERY" value="SURGERY"><I18 tkey="SURGERY" /></Option>);
		options.push(<Option key="MEDICINE" value="MEDICINE"><I18 tkey="MEDICINE WITH ACTE" /></Option>);
		options.push(<Option key="MEDICINE_WITHOUT_ACTE" value="MEDICINE_WITHOUT_ACTE"><I18 tkey="MEDICINE WITHOUT ACTE" /></Option>);
		return options;
	}

	render() {
		return (
			<div className={this.props.className ? this.props.className : "w_200p di mb_15_mob"}>
				<Select
					value={this.props.clinicalPathwayType}
					disabled={this.props.disabled}
					className="w_100 di"
					mode="multiple"
					defaultActiveFirstOption
					onChange={this.props.selectedClinicalPathwayType}
					placeholder={i18Get("Select Clinical Pathway Type", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
				>
					{this.renderClinicalPathwayTypeOptions()}
				</Select>
			</div>
		);
	}
}

clinicalPathwayTypeDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(clinicalPathwayTypeDropdown));
