import eventNames from "../../actions/events";

const initialState = {
	userDetails: {},
	loginInfo: {}
};

let alteredLoginData;

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.USER_LOGIN}_REJECTED`:
			return {
				...state,
				isLoginComplete: "REJECTED"
			};

		case `${eventNames.USER_LOGIN}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const key = "USER_DETAILS";
				const locationKey = "LOGOUT_LOCATION";
				const userDetails = localStorage.setItem(key, JSON.stringify(action.payload.data));
				const location = localStorage.getItem(locationKey);
				if (location) {
					localStorage.setItem(locationKey, "");
					window.location = location;
				}
			}   
			return {
				...state,
				loginData: action.payload.data.data,
				isLoginComplete: "FULFILLED",
				isLoggedIn: true
			};

		case `${eventNames.USER_LOGOUT}`:
			localStorage.setItem("LOGOUT_LOCATION", window.location);
			return {
				...state,
				isLogoutComplete: "FULFILLED"
			};

		case eventNames.SET_LOGIN_DATA:
			return {
				...state,
				loginData: action.payload.data,
				isLoginComplete: "FULFILLED",
				isLoggedIn: true
			};
		case eventNames.CLEAR_USER_LOGIN_MINIMAL:
			return {
				...state,
				isLoginComplete: false
			};
		case eventNames.CLEAR_USER_LOGIN:
			return {
				...state,
				isLoginComplete: false,
				isLogoutComplete: false,
				loginData: false,
				isLoggedIn: false
			};
		case `${eventNames.FORGOT_PASSWORD}_REJECTED`:
			return {
				...state,
				isForgotPasswordComplete: "REJECTED"
			};

		case `${eventNames.FORGOT_PASSWORD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isForgotPasswordComplete: "FULFILLED"
				};
			}
			break;
		case eventNames.CLEAR_FORGOT_PASSWORD:
			return {
				...state,
				isForgotPasswordComplete: false
			};
		case `${eventNames.RESET_PASSWORD}_REJECTED`:
			return {
				...state,
				isResetPasswordComplete: "REJECTED"
			};

		case `${eventNames.RESET_PASSWORD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isResetPasswordComplete: "FULFILLED"
				};
			}
			break;
		case eventNames.CLEAR_RESET_PASSWORD:
			return {
				...state,
				isValidateResetTokenComplete: false
			};
		case `${eventNames.VALIDATE_RESET_TOKEN}_REJECTED`:
			return {
				...state,
				isValidateResetTokenComplete: "REJECTED"
			};

		case `${eventNames.VALIDATE_RESET_TOKEN}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isValidateResetTokenComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isValidateResetTokenComplete: "REJECTED"
			};
		case eventNames.CLEAR_VALIDATE_RESET_TOKEN:
			return {
				...state,
				isValidateResetTokenComplete: false
			};
		case `${eventNames.FETCH_USER_PROFILE}_REJECTED`:
			return {
				...state,
				isProfileFetchComplete: "REJECTED"
			};

		case `${eventNames.FETCH_USER_PROFILE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				alteredLoginData = JSON.parse(localStorage.getItem("USER_DETAILS"));
				alteredLoginData.data.profileUrl = action.payload.data.data.profileUrl;
				state.loginData.profileUrl = action.payload.data.data.profileUrl;
				alteredLoginData.data.name = action.payload.data.data.name;
				state.loginData.name = action.payload.data.data.name;
				localStorage.setItem("USER_DETAILS", JSON.stringify(alteredLoginData));
				return {
					...state,
					userDetails: action.payload.data.data,
					isProfileFetchComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isProfileFetchComplete: "REJECTED"
			};
		case eventNames.CLEAR_PROFILE:
			return {
				...state,
				isProfileFetchComplete: false
			};
		case `${eventNames.UPDATE_PROFILE}_REJECTED`:
			return {
				...state,
				isProfileUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_PROFILE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				alteredLoginData = JSON.parse(localStorage.getItem("USER_DETAILS"));
				alteredLoginData.data.name = action.payload.data.data.name;
				state.loginData.name = action.payload.data.data.name;
				localStorage.setItem("USER_DETAILS", JSON.stringify(alteredLoginData));
				return {
					...state,
					userUpdatedDetails: action.payload.data.data,
					isProfileUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isProfileUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_PROFILE:
			return {
				...state,
				isProfilePicUpdateComplete: false,
				isProfileUpdateComplete: false
			};
		case `${eventNames.CHANGE_PASSWORD_PROFILE}_REJECTED`:
			return {
				...state,
				isChangePasswordUserCompleted: "REJECTED"
			};
		case `${eventNames.CHANGE_PASSWORD_PROFILE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isChangePasswordUserCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isChangePasswordUserCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CHANGE_PASSWORD_PROFILE:
			return {
				...state,
				isChangePasswordUserCompleted: false
			};
		case `${eventNames.UPDATE_PROFILE_PIC}_REJECTED`:
			return {
				...state,
				isProfilePicUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_PROFILE_PIC}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				alteredLoginData = JSON.parse(localStorage.getItem("USER_DETAILS"));
				alteredLoginData.data.profileUrl = action.payload.data.data.fileName;
				state.loginData.profileUrl = action.payload.data.data.fileName;
				localStorage.setItem("USER_DETAILS", JSON.stringify(alteredLoginData));
				return {
					...state,
					userProfilePic: action.payload.data.data,
					isProfilePicUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isProfilePicUpdateComplete: "REJECTED"
			};
		case `${eventNames.GET_SUPPORT_PORTAL_LINK}_FULFILLED`:
			return {
				...state,
				supportLink: action.payload.data.data,
				supportPortalLinkFetchComplete: "FULFILLED"
			};
		case `${eventNames.GET_SUPPORT_PORTAL_LINK}_REJECTED`:
			return {
				...state,
				supportLink: [],
				supportPortalLinkFetchComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_SUPPORT_PORTAL_LINK:
			return {
				...state,
				supportLink: [],
				supportPortalLinkFetchComplete: false
			};
		case `${eventNames.LOGIN_LIMIT_INFO}_FULFILLED`:
			return {
				...state,
				loginInfo: action.payload.data.data,
				fetchLoginInfo: "FULFILLED"
			};
		case `${eventNames.LOGIN_LIMIT_INFO}_REJECTED`:
			return {
				...state,
				loginInfo: null,
				fetchLoginInfo: "REJECTED"
			};
		case `${eventNames.LOGIN_LIMIT_INFO_CLEAR}`:
			return {
				...state,
				loginInfo: null,
				fetchLoginInfo: false
			};
		default:
			return state;
	}
}
