import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (queryString, type) => ({
	type: events.SPECIALTY_GET_ALL,
	meta: type,
	payload: axios.get(`${Config.apiRootSecure}/speciality${queryString}`)
});

const clearSpecialtyFetch = () => ({
	type: events.CLEAR_SPECIALTY_FETCH,
	payload: {}
});

const getAllHospital = (queryString) => ({
	type: events.HOSPITAL_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/hospitals${queryString}`)
});

const clearHospitalFetch = () => ({
	type: events.CLEAR_HOSPITAL_FETCH,
	payload: {}
});

const getAllMom = (queryString) => ({
	type: events.SPECIALTY_GET_ALL_MOM,
	payload: axios.get(`${Config.apiRootSecure}/speciality${queryString}`)
});

const clearSpecialtyMomFetch = () => ({
	type: events.CLEAR_SPECIALTY_MOM_FETCH,
	payload: {}
});

const fetchOneSpecialty = (id) => ({
	type: events.FETCH_ONE_SPECIALTY,
	payload: axios.get(`${Config.apiRootSecure}/speciality/${id}`)
});

const clearFetchOneSpecialty = () => ({
	type: events.CLEAR_FETCH_ONE_SPECIALTY,
	payload: {}
});

const updateSpecialty = (id, payload) => ({
	type: events.UPDATE_SPECIALTY,
	payload: axios.put(`${Config.apiRootSecure}/speciality/${id}`, { ...payload })
});

const clearUpdateSpecialty = () => ({
	type: events.CLEAR_UPDATE_SPECIALTY,
	payload: {}
});

const createSpecialty = (payload) => ({
	type: events.CREATE_SPECIALTY,
	payload: axios.post(`${Config.apiRootSecure}/speciality`, { ...payload })
});

const clearcreateSpecialty = () => ({
	type: events.CLEAR_CREATE_SPECIALTY,
	payload: {}
});

const deleteSpecialty = (id) => ({
	type: events.DELETE_SPECIALTY,
	payload: axios.delete(`${Config.apiRootSecure}/speciality/${id}`)
});

const clearDeleteSpecialty = () => ({
	type: events.CLEAR_DELETE_SPECIALTY,
	payload: {}
});

const copyMOMSpecialityToLocal = (payload) => ({
	type: events.COPY_MOM_SPECIALITY_TO_LOCAL,
	payload: axios.post(`${Config.apiRootSecure}/speciality/copy`, { ...payload })
});

const clearCopyMOMSpecialityLocal = () => ({
	type: events.CLEAR_COPY_MOM_SPECIALITY_TO_LOCAL,
	payload: {}
});

const fetchAllUnassignedLocalMOM = (queryString) => ({
	type: events.FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/specialitiesNotInLocal?${queryString}`)
});

const clearFetchAllUnassignedLocalMOM = () => ({
	type: events.CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY,
	payload: {}
});

const reAssignSpeciality = (id, data) => ({
	type: events.RE_ASSIGN_SPECIALITY,
	payload: axios.put(`${Config.apiRootSecure}/speciality/reassign/${id}`, data)
});

const reAssignSpecialityComplete = () => ({
	type: events.RE_ASSIGN_SPECIALITY_COMPLETED,
	payload: ""
});

export default {
	getAll,
	clearSpecialtyFetch,
	getAllMom,
	clearSpecialtyMomFetch,
	fetchOneSpecialty,
	clearFetchOneSpecialty,
	updateSpecialty,
	clearUpdateSpecialty,
	createSpecialty,
	clearcreateSpecialty,
	deleteSpecialty,
	clearDeleteSpecialty,
	copyMOMSpecialityToLocal,
	clearCopyMOMSpecialityLocal,
	fetchAllUnassignedLocalMOM,
	clearFetchAllUnassignedLocalMOM,
	reAssignSpeciality,
	reAssignSpecialityComplete,
	getAllHospital,
	clearHospitalFetch

};
