import eventNames from "../../actions/events";

const initialState = {
	dropDownData: "",
	switchTemplateCompleted: false,
	templateInfo: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.STAFF_ELEMENTS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllDataCompleted: "REJECTED"
			};
		case `${eventNames.STAFF_ELEMENTS_GET_ALL}_PENDING`:
			return {
				...state,
				isFetchAllDataCompleted: "PENDING"
			};
		case `${eventNames.STAFF_ELEMENTS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					data: action.payload.data.data,
					isFetchAllDataCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				data: [],
				isFetchAllDataCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_STAFF_ELEMENTS_GET_ALL:
			return {
				...state,
				data: [],
				isFetchAllDataCompleted: false
			};

		case `${eventNames.STAFF_ELEMENTS_UPDATE}_REJECTED`:
			return {
				...state,
				iselementUpdateCompleted: "REJECTED"
			};
		case `${eventNames.STAFF_ELEMENTS_UPDATE}_PENDING`:
			return {
				...state,
				iselementUpdateCompleted: "PENDING"
			};
		case `${eventNames.STAFF_ELEMENTS_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementUpdateData: action.payload.data.data,
					iselementUpdateCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				elementUpdateData: [],
				iselementUpdateCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_STAFF_ELEMENTS_UPDATE:
			return {
				...state,
				elementUpdateData: [],
				iselementUpdateCompleted: false
			};
		case `${eventNames.STAFF_ELEMENTS_GET_ONE}_REJECTED`:
			return {
				...state,
				iselementGetCompleted: "REJECTED"
			};
		case `${eventNames.STAFF_ELEMENTS_GET_ONE}_PENDING`:
			return {
				...state,
				iselementGetCompleted: "PENDING"
			};
		case `${eventNames.STAFF_ELEMENTS_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementgetData: action.payload.data.data,
					iselementGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				elementgetData: [],
				iselementGetCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_STAFF_ELEMENTS_GETONE:
			return {
				...state,
				elementgetData: [],
				iselementGetCompleted: false,
				iselementUpdateCompleted: false
			};
		case `${eventNames.SWITCH_TEMPLATE}_REJECTED`:
			return {
				...state,
				switchTemplateCompleted: "REJECTED"
			};
		case `${eventNames.SWITCH_TEMPLATE}_FULFILLED`:
			return {
				...state,
				switchTemplateCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SWITCH_TEMPLATE:
			return {
				...state,
				switchTemplateCompleted: false
			};
		case eventNames.TRANSFER_TEMPLATE_INFO:
			return {
				...state,
				templateInfo: action.payload
			};
		default:
			return state;
	}
}
