import React, { useEffect, useState } from "react";
import { i18Get } from "i18";
import ActionButton from "plug/action-button";
import { Slider, Tooltip } from "antd";
import utils from "utils/utils";
import { MissLinkIcon } from "../../../plugin/icons";
import { useDispatch, useSelector } from "react-redux";
import action from "../../redux/action";
import "./styles.css";

function MissLink () {
    const dispatch = useDispatch();
    const missConnectores = useSelector((store) => store.DiagramReducer.missConnectores);
    const [totalCount, setTotalCount] = useState(0);
    const [itemIndex, setItemIndex] = useState(1);
    
    useEffect(() => {

        if(missConnectores?.length > 0) {
            setTotalCount(missConnectores.length);
            if(totalCount != missConnectores.length) {
                setItemIndex(1);
            }
        }

    }, [missConnectores]);

    const onchange = () => {
        if(totalCount > 0 ) {
            const index = totalCount >= itemIndex ? itemIndex : 1;
            const item = missConnectores[(index-1)];
            dispatch(action.selectMissConnector(item.id))
            setItemIndex(index+1)
        }
       
    };

    return missConnectores?.length > 0 ? <Tooltip title={`${itemIndex-1}/${totalCount}`}><div className={`MissLink-Icons-Holder ${ missConnectores?.length > 0 ? "red_icon": ""}`} onClick={onchange}><MissLinkIcon /></div></Tooltip> : "";

}
export default MissLink;