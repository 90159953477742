import eventNames from "../../actions/events";

const initialState = {
	selectedSupplierName: ""
};
const supplierMasterKey = "MASTER_SUPPLIER";

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_SUPPLIER}_REJECTED`:
			return {
				...state,
				isFetchAllSupplierCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_SUPPLIER}_PENDING`:
			return {
				...state,
				isFetchAllSupplierCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.count,
					supplier: action.payload.data.data.supplier,
					isFetchAllSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				supplier: [],
				isFetchAllSupplierCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SUPPLIER_FETCH:
			return {
				...state,
				supplier: [],
				isFetchAllSupplierCompleted: false
			};
		case `${eventNames.FETCH_MASTER_SUPPLIER}_REJECTED`:
			return {
				...state,
				isFetchMasterSupplierCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_MASTER_SUPPLIER}_PENDING`:
			return {
				...state,
				isFetchMasterSupplierCompleted: "PENDING"
			};
		case `${eventNames.FETCH_MASTER_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				localStorage.setItem(supplierMasterKey, JSON.stringify(action.payload.data.data));
				return {
					...state,
					isFetchMasterSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchMasterSupplierCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_MASTER_SUPPLIER_FETCH:
			return {
				...state,
				isFetchMasterSupplierCompleted: false
			};
		case `${eventNames.FETCH_ONE_SUPPLIER}_REJECTED`:
			return {
				...state,
				isFetchOneSupplierCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_SUPPLIER}_PENDING`:
			return {
				...state,
				isFetchOneSupplierCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedSupplier: action.payload.data.data,
					isFetchOneSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				selectedSupplier: [],
				isFetchOneSupplierCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ONE_SUPPLIER:
			return {
				...state,
				selectedSupplier: [],
				isFetchOneSupplierCompleted: false
			};
		case eventNames.SET_SUPPLIER_NAME:
			return {
				...state,
				selectedSupplierName: action.payload.name
			};
		case `${eventNames.UPDATE_SUPPLIER}_REJECTED`:
			return {
				...state,
				isUpdateSupplierCompleted: "REJECTED"
			};
		case `${eventNames.UPDATE_SUPPLIER}_PENDING`:
			return {
				...state,
				isUpdateSupplierCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedSupplier: action.payload.data.data,
					isUpdateSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				updatedSupplier: [],
				isUpdateSupplierCompleted: "FULFILLED"
			};
		case eventNames.UPDATE_CLEAR_SUPPLIER:
			return {
				...state,
				updatedSupplier: [],
				isUpdateSupplierCompleted: false
			};
		case `${eventNames.CREATE_SUPPLIER}_REJECTED`:
			return {
				...state,
				isCreateSupplierCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_SUPPLIER}_PENDING`:
			return {
				...state,
				isCreateSupplierCompleted: "PENDING"
			};
		case `${eventNames.CREATE_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					supplier: action.payload.data.data,
					isCreateSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				supplier: [],
				isCreateSupplierCompleted: "FULFILLED"
			};
		case eventNames.CREATE_CLEAR_SUPPLIER:
			return {
				...state,
				supplier: [],
				isCreateSupplierCompleted: false
			};
		case `${eventNames.DELETE_SUPPLIER}_REJECTED`:
			return {
				...state,
				isDeleteSupplierCompleted: "REJECTED"
			};
		case `${eventNames.DELETE_SUPPLIER}_PENDING`:
			return {
				...state,
				isDeleteSupplierCompleted: "PENDING"
			};
		case `${eventNames.DELETE_SUPPLIER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deletedId: action.payload.data.data,
					isDeleteSupplierCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				deletedId: [],
				isDeleteSupplierCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SUPPLIER_DELETE:
			return {
				...state,
				deletedId: [],
				isDeleteSupplierCompleted: false
			};
		default:
			return state;
	}
}
