import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { Button, Modal, Dropdown, Menu } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, ExpandOutlined, FullscreenExitOutlined, CaretDownOutlined } from "@ant-design/icons";
import ProblemList from "./problem-list";
import ProblemEdit from "./edit";
import ProblemCreate from "./create";
import HeaderSection from "./header-section";
import ClpStepFilter from "../clp-step-filter";
import QuickOrder from "./quick-reorder";
import CopyFromPathWayProblems from "../copyfromPathwayProblems";
import ClpSynthesis from "../clp-synthesis";
import TopSection from "../top-section";
import DragWindow from "../drag-window";
import CopyClinicalPathwayHeader from "../../copyClinicalPathwayHeader";
import isPermissionGranted from "../../../../../../../utils/permission";
import AttachModels from "../../components/attachModels";
import ClpComparison from "../clp-comparison";
import { statusList } from "../../../../../../../constants";

class ProblemSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			loading: false,
			problemId: null,
			type: "",
			canEnableProblemListReorder: false,
			enableProblemListReorder: false,
			isProblemOrderUpdating: false,
			//isProblemListFullScreen: false,
			quickReorderProblemListChanges: 0,
			saveAndNextQuickOrder: false,
			problemData: null,
			viewList: false,
			totalProblemsUnderClp: 0,
			copyFrom: ""
		};
	}

	editClicked = (value) => {
		this.setState({ editModal: true, problemId: value.isParent ? value.problemId : value.parentId, type: value.type, problemData: value });
	};

	createClicked() {

		if (this.state.clinicalPathway.isCheckList && this.state.totalProblemsUnderClp > 0) {
			utils.showInfo("Checklist can have only one problem!");
			return false;
		}

		this.setState({ createModal: true });
	}

	onClickCancel() {
		this.setState({ editModal: false, problemId: null, createModal: false, type: "" });
	}

	reloadData() {
		this.props.reloadDetails();
		this.props.handleCancel();
	}

	updateData() {
		if (this.child) {
			this.child.updateProblemsRank();
		}
	}

	onProblemListReorderCompleted = () => {
		this.setState({ enableProblemListReorder: false, isProblemOrderUpdating: false });
		this.disableProblemListReorder();
	};

	updateSettings = (settings) => {
		localStorage.setItem("PBLM_COLOR", JSON.stringify(settings));
		this.setState({ pblmColor: settings });
		this.props.toggleColorSettings();
	};

	//toggleProblemListFullScreen = () => this.setState({ isProblemListFullScreen: !this.state.isProblemListFullScreen });

	onEnableReorder = () => {
		this.setState({ enableProblemListReorder: true });
	};
	onSaveAndNextQuickOrder(flag) {
		this.setState({ saveAndNextQuickOrder: flag, isProblemOrderUpdating: true });
	}
	disableProblemListReorder = () => {
		if (!this.state.saveAndNextQuickOrder) {
			this.setState({ enableQuickProblemListReorder: false });
		}
		this.setState({ enableProblemListReorder: false });
		this.reloadData();
	};

	onQuickReorderData = () => {
		this.setState({ enableQuickProblemListReorder: true });
	};
	typeChanged = e => {
		this.props.typeChanged(e);
		this.state.type = e;
		this.setState(this.state);
	};
	fetchProblemListQuickOrder = () => {
		this.setState({ quickReorderProblemListChanges: this.state.quickReorderProblemListChanges + 1 });
	};

	copyFromPathwayProblemsTitle = () => { return (<div className="position_relative w_100"><DragWindow title={<CopyClinicalPathwayHeader title={`Copy From ${this.state.copyFrom}`} clinicalPathwayData={this.state.clinicalPathway} />} clinicalPathwayData={this.state.clinicalPathwayData} /> </div>); };

	pathwayProblemsSynthesisTitle = () => { return (<div className="position_relative"><DragWindow title={i18Get("Pathway Problems - Synthesis", utils.getLanguage())} /> </div>); };

	checkListTitle = () => { return (<div className="position_relative"><DragWindow title={i18Get("Select the Check Lists", utils.getLanguage())} /> </div>); };

	toggleViewListModal = (value) => {
		this.setState({ viewList: value });
	};

	totalProblemsUnderClp = (count) => {

		const prevState = this.state;
		prevState.totalProblemsUnderClp = count;
		this.setState(prevState);
	};
	createMenu = (isModel) => (
		<Menu>
			<Menu.Item onClick={this.createClicked.bind(this)} key="1">
				<span className="margin-right-10">
					<I18 tkey='Cible' />
				</span>
			</Menu.Item>
			{isModel &&
				<Menu.Item onClick={() => this.toggleViewListModal(true)} key="2">
					<span className="margin-right-10">
						<I18 tkey='Model' />
					</span>
				</Menu.Item>
			}
		</Menu>
	);

	getCopyFrom(name) {
		const prevState = this.state;
		prevState.copyFrom = name;
		this.setState(prevState);
	}
	render() {
		
		if (Object.keys(this.props.clinicalPathwayData) && !this.state.isLoaded) {
			this.state.clinicalPathway = this.props.clinicalPathwayData;
			this.state.isLoaded = true;
		}
		return (
			<div className={"problem_pathway_1 padding-top-10 my2"}>
				<div className={`problem_content_container ${this.state.enableProblemListReorder ? "problem_content_container_reorder" : ""}`}>
					{this.props.showHeaders &&
						<div>
							<HeaderSection
								showIntervention={this.props.showIntervention}
								clinicalPathwayData={this.props.clinicalPathwayData}
								treatmentId = { this.props.treatmentId }
							/>
						</div>
					}
					<>
						<ProblemList
							clinicalPathwayData={this.props.clinicalPathwayData}
							editClicked={this.editClicked}
							pblmColor={this.state.pblmColor}
							search={this.props.search}
							treatmentId={this.props.treatmentId}
							key={this.props.key}
							field={this.props.field}
							type={this.props.type}
							onRef={this.props.onRef}
							enableReorder={this.props.enableProblemListReorder}
							onReorderCompleted={this.onProblemListReorderCompleted}
							canEnableReorderFn={this.canEnableProblemReorder}
							isFullScreen={this.props.isProblemListFullScreen}
							toggleFullScreen={this.props.toggleProblemListFullScreen}
							clpStepFilter={this.props.clpStepFilter}
							selectedPblmColumns={this.props.selectedPblmColumns}
							fetchProblemListQuickOrder={this.fetchProblemListQuickOrder}
							onFetchData={this.props.onFetchData}
							setProblemCount={this.totalProblemsUnderClp}
							setTaskCount={this.props.setTaskCount}
							intervenants = {this.props.intervenants}
						/>
					</>
				</div>
				{this.state.editModal ?
					<ProblemEdit
						clinicalPathwayData={this.props.clinicalPathwayData}
						problemId={this.state.problemId}
						editModal={this.state.editModal}
						type={this.state.type}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						filter={this.props.clpStepFilter}
						data={this.state.problemData}

					/>
					: ""}
				{this.state.createModal ?
					<ProblemCreate
						clinicalPathwayData={this.props.clinicalPathwayData}
						createModal={this.state.createModal}
						type={this.state.type}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						filter={this.props.clpStepFilter}
						changeFilter={this.props.changeClpStepFilter}
					/>
					: ""}
				{this.props.copyFromPathWayProblems ?
					<Modal
						visible={this.props.copyFromPathWayProblems}
						className="cost_modal re_assign_modal overflow-x-hidden-model m_w_1000_px copy_clp_modal"
						title={this.copyFromPathwayProblemsTitle()}
						onCancel={this.props.closeCopyFromPathWayProblems}
						wrapClassName="drag_modal"
						footer={null}
					>
						<CopyFromPathWayProblems
							clinicalPathwayData={this.props.clinicalPathwayData}
							closeCopyFromPathWayProblems={this.props.closeCopyFromPathWayProblems}
							reloadData={this.reloadData.bind(this)}
							reloadDetails={this.props.reloadDetails}
							clpStepFilter={this.props.clpStepFilter}
							getModelTitle={this.getCopyFrom.bind(this)}
						/>
					</Modal> : ""}

				{this.props.clpComparison ?
					<Modal
						visible={this.props.clpComparison}
						className="fullview_modal re_assign_modal"
						wrapClassName="drag_modal"
						title={<DragWindow title={<span>{i18Get("Comparison", utils.getLanguage())}
							<b>{` ( ${i18Get(this.props.clinicalPathwayData.isModel ? "Model" : this.props.clinicalPathwayData.isCheckList ? "Check List" : "Clinical Pathway", utils.getLanguage())} A:  ${(this.props.clinicalPathwayData.hospitalName ? this.props.clinicalPathwayData.hospitalName + " " : "")}${this.props.clinicalPathwayData.name} )`}</b>
						</span>} ></DragWindow>}
						closable={false}
						footer={null}>
						<ClpComparison 
							clinicalPathwayData={this.props.clinicalPathwayData} 
							// reloadData={this.reloadData.bind(this)}
							reloadDetails={this.props.reloadDetails}
							cancelClicked={this.props.closeComparisonOfClps}/>

					</Modal> : ""
				}
				{this.props.isClpSynthesis ?
					<Modal
						visible={this.props.isClpSynthesis}
						className="cost_modal re_assign_modal clp_synthesis_modal"
						title={this.pathwayProblemsSynthesisTitle()}
						onCancel={this.props.handleCancel}
						wrapClassName="drag_modal"
						footer={null}>
						<ClpSynthesis
							clinicalPathwayData={this.props.clinicalPathwayData}
							treatmentId={this.props.treatmentId}
							reloadData={this.reloadData.bind(this)}
							clpStepFilter={this.props.clpStepFilter}
						/>
					</Modal> : ""}
				{
					<AttachModels
						clinicalPathwayData={this.state.clinicalPathway}
						toggleViewListModal={this.toggleViewListModal}
						viewList={this.state.viewList}
						reloadDetails={this.props.reloadDetails}
					/>
				}
			</div>
		);
	}
}

ProblemSection.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequire
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations,
	permissions: store.permissions
}))(ProblemSection));