import eventNames from "../../actions/events";

const initialState = {
	dropDownData: "",
	fetchOneImageryComplete: false,
	selectedImagery: {},
	fetchAllStaffForImagery: false,
	fetchAllMedicalDeviceForImagery: false,
	fetchAllPharmacyForImagery: false,
	staff: {},
	medicalDevice: {},
	pharmacy: {},
	isUpdateImageryComplete: false,
	updatedImagery: {},
	copySessionTypeTeamsComplete: false,
	switchStaffTemplateComplete: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_IMAGERY}_REJECTED`:
			return {
				...state,
				isFetchAllImageryCompleted: "REJECTED"
			};
		case `${eventNames.GET_ALL_IMAGERY}_PENDING`:
			return {
				...state,
				isFetchAllImageryCompleted: "PENDING"
			};
		case `${eventNames.GET_ALL_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageryList: action.payload.data.data.data,
					imageryCount: action.payload.data.data.count,
					isFetchAllImageryCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchAllImageryCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ALL_IMAGERY:
			return {
				...state,
				imageryList: [],
				isFetchAllImageryCompleted: false
			};
		case `${eventNames.CREATE_TREATMENT_CHEMO_RADIO_IMAGERY}_REJECTED`:
			return {
				...state,
				isCreateTreatmentCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_TREATMENT_CHEMO_RADIO_IMAGERY}_PENDING`:
			return {
				...state,
				isCreateTreatmentCompleted: "PENDING"
			};
		case `${eventNames.CREATE_TREATMENT_CHEMO_RADIO_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					treatment: action.payload.data.data,
					isCreateTreatmentCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				treatment: [],
				isCreateTreatmentCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_TREATMENT_CHEMO_RADIO_IMAGERY:
			return {
				...state,
				isCreateTreatmentCompleted: false
			};
		case `${eventNames.GET_ONE_IMAGERY}_REJECTED`:
			return {
				...state,
				fetchOneImageryComplete: "REJECTED"
			};
		case `${eventNames.GET_ONE_IMAGERY}_PENDING`:
			return {
				...state,
				fetchOneImageryComplete: "PENDING"
			};
		case `${eventNames.GET_ONE_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagery: action.payload.data.data,
					fetchOneImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				imagery: [],
				fetchOneImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ONE_IMAGERY:
			return {
				...state,
				fetchOneImageryComplete: false
			};
		case `${eventNames.FETCH_ALL_IMAGERY_ELEMENTS}_REJECTED`:
			return {
				...state,
				fetchAllElementImageryComplete: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_IMAGERY_ELEMENTS}_PENDING`:
			return {
				...state,
				fetchAllElementImageryComplete: "PENDING"
			};
		case `${eventNames.FETCH_ALL_IMAGERY_ELEMENTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const elements = {};
				elements[action.meta] = action.payload.data.data;
				return {
					...state,
					imageryElement: elements,
					type: action.meta,
					fetchAllElementImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				imageryElement: [],
				type: "",
				fetchAllElementImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_IMAGERY_ELEMENTS:
			return {
				...state,
				fetchAllElementImageryComplete: false
			};
		case `${eventNames.FETCH_ALL_IMAGERY_EQUIPMENT}_REJECTED`:
			return {
				...state,
				fetchAllEquipmentImageryComplete: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_IMAGERY_EQUIPMENT}_PENDING`:
			return {
				...state,
				fetchAllEquipmentImageryComplete: "PENDING"
			};
		case `${eventNames.FETCH_ALL_IMAGERY_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageryEquipment: action.payload.data.data,
					fetchAllEquipmentImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				imageryEquipment: [],
				fetchAllEquipmentImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_IMAGERY_EQUIPMENT:
			return {
				...state,
				fetchAllEquipmentImageryComplete: false
			};
		case `${eventNames.UPDATE_IMAGERY}_REJECTED`:
			return {
				...state,
				updateImageryComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_IMAGERY}_PENDING`:
			return {
				...state,
				updateImageryComplete: "PENDING"
			};
		case `${eventNames.UPDATE_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imagery: action.payload.data.data,
					updateImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				imagery: [],
				updateImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_IMAGERY:
			return {
				...state,
				updateImageryComplete: false
			};
		case `${eventNames.FETCH_ALL_STAFF_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				fetchAllStaffImagery: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_STAFF_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				fetchAllStaffImagery: "PENDING"
			};
		case `${eventNames.FETCH_ALL_STAFF_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageryStaff: action.payload.data.data,
					fetchAllStaffImagery: "FULFILLED"
				};
			}
			return {
				...state,
				imageryStaff: [],
				fetchAllStaffImagery: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_STAFF_FOR_IMAGERY:
			return {
				...state,
				fetchAllStaffImagery: false
			};
		case `${eventNames.CREATE_IMAGERY_PREPARATION_PERFUSION}_REJECTED`:
			return {
				...state,
				createImageryPrepPerfusionComplete: "REJECTED"
			};
		case `${eventNames.CREATE_IMAGERY_PREPARATION_PERFUSION}_PENDING`:
			return {
				...state,
				createImageryPrepPerfusionComplete: "PENDING"
			};
		case `${eventNames.CREATE_IMAGERY_PREPARATION_PERFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageryPrepPerfusion: action.payload.data.data,
					createImageryPrepPerfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				imageryPrepPerfusion: [],
				createImageryPrepPerfusionComplete: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_IMAGERY_PREPARATION_PERFUSION:
			return {
				...state,
				createImageryPrepPerfusionComplete: false
			};
		case `${eventNames.GET_ONE_STAFF_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				fetchGetOneStaffCompleted: "REJECTED"
			};
		case `${eventNames.GET_ONE_STAFF_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				fetchGetOneStaffCompleted: "PENDING"
			};
		case `${eventNames.GET_ONE_STAFF_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageryStaff: action.payload.data.data,
					fetchGetOneStaffCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				imageryStaff: [],
				fetchGetOneStaffCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ONE_STAFF_FOR_IMAGERY:
			return {
				...state,
				fetchGetOneStaffCompleted: false
			};
		case `${eventNames.CREATE_STAFF_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				createStaffComplete: "REJECTED"
			};
		case `${eventNames.CREATE_STAFF_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				createStaffComplete: "PENDING"
			};
		case `${eventNames.CREATE_STAFF_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					staffCreated: action.payload.data.data,
					createStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				staffCreated: [],
				createStaffComplete: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_STAFF_FOR_IMAGERY:
			return {
				...state,
				createStaffComplete: false
			};
		case `${eventNames.UPDATE_STAFF_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				updateStaffComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_STAFF_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				updateStaffComplete: "PENDING"
			};
		case `${eventNames.UPDATE_STAFF_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					staffUpdate: action.payload.data.data,
					updateStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				staffUpdate: [],
				updateStaffComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_STAFF_FOR_IMAGERY:
			return {
				...state,
				updateStaffComplete: false
			};
		case `${eventNames.UPDATE_STAFF_RANK_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				updateRankImageryComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_STAFF_RANK_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				updateRankImageryComplete: "PENDING"
			};
		case `${eventNames.UPDATE_STAFF_RANK_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					rankUpdate: action.payload.data.data,
					updateRankImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				rankUpdate: [],
				updateRankImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_STAFF_RANK_FOR_IMAGERY:
			return {
				...state,
				updateRankImageryComplete: false
			};
		case `${eventNames.CREATE_EQUIPMENT_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				createEquipmentImageryComplete: "REJECTED"
			};
		case `${eventNames.CREATE_EQUIPMENT_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				createEquipmentImageryComplete: "PENDING"
			};
		case `${eventNames.CREATE_EQUIPMENT_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					createEquipment: action.payload.data.data,
					createEquipmentImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				createEquipment: [],
				createEquipmentImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_EQUIPMENT_FOR_IMAGERY:
			return {
				...state,
				createEquipmentImageryComplete: false
			};
		case `${eventNames.GET_ONE_EQUIPMENT_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				getOneEquipmentImageryComplete: "REJECTED"
			};
		case `${eventNames.GET_ONE_EQUIPMENT_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				getOneEquipmentImageryComplete: "PENDING"
			};
		case `${eventNames.GET_ONE_EQUIPMENT_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					equipment: action.payload.data.data,
					getOneEquipmentImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				equipment: [],
				getOneEquipmentImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ONE_EQUIPMENT_FOR_IMAGERY:
			return {
				...state,
				getOneEquipmentImageryComplete: false
			};
		case `${eventNames.UPDATE_EQUIPMENT_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				updateEquipmentImageryComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_EQUIPMENT_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				updateEquipmentImageryComplete: "PENDING"
			};
		case `${eventNames.UPDATE_EQUIPMENT_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateEquipment: action.payload.data.data,
					updateEquipmentImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updateEquipment: [],
				updateEquipmentImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_EQUIPMENT_FOR_IMAGERY:
			return {
				...state,
				updateEquipmentImageryComplete: false
			};
		case `${eventNames.FETCH_ONE_PREPARATION_PERFUSION_IMAGERY}_REJECTED`:
			return {
				...state,
				fetchOnePrepPerfusionImageryComplete: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_PREPARATION_PERFUSION_IMAGERY}_PENDING`:
			return {
				...state,
				fetchOnePrepPerfusionImageryComplete: "PENDING"
			};
		case `${eventNames.FETCH_ONE_PREPARATION_PERFUSION_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchPrepPerfusion: action.payload.data.data,
					fetchOnePrepPerfusionImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchPrepPerfusion: [],
				fetchOnePrepPerfusionImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ONE_PREPARATION_PERFUSION_IMAGERY:
			return {
				...state,
				fetchOnePrepPerfusionImageryComplete: false
			};
		case `${eventNames.UPDATE_PREPARATION_PERFUSION_IMAGERY}_REJECTED`:
			return {
				...state,
				updatePrepPerfusionImageryComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_PREPARATION_PERFUSION_IMAGERY}_PENDING`:
			return {
				...state,
				updatePrepPerfusionImageryComplete: "PENDING"
			};
		case `${eventNames.UPDATE_PREPARATION_PERFUSION_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatePrepPerfusion: action.payload.data.data,
					updatePrepPerfusionImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updatePrepPerfusion: [],
				updatePrepPerfusionImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_PREPARATION_PERFUSION_IMAGERY:
			return {
				...state,
				updatePrepPerfusionImageryComplete: false
			};
		case `${eventNames.CREATE_PREPARATION_PERFUSION_IMAGERY}_REJECTED`:
			return {
				...state,
				createPrepPerfusionImageryComplete: "REJECTED"
			};
		case `${eventNames.CREATE_PREPARATION_PERFUSION_IMAGERY}_PENDING`:
			return {
				...state,
				createPrepPerfusionImageryComplete: "PENDING"
			};
		case `${eventNames.CREATE_PREPARATION_PERFUSION_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					prepPerfusion: action.payload.data.data,
					createPrepPerfusionImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				prepPerfusion: [],
				createPrepPerfusionImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_PREPARATION_PERFUSION_IMAGERY:
			return {
				...state,
				createPrepPerfusionImageryComplete: false
			};
		case `${eventNames.DELETE_PREP_PERFUSION_IMAGERY}_REJECTED`:
			return {
				...state,
				deletePrepPerfusionImageryComplete: "REJECTED"
			};
		case `${eventNames.DELETE_PREP_PERFUSION_IMAGERY}_PENDING`:
			return {
				...state,
				deletePrepPerfusionImageryComplete: "PENDING"
			};
		case `${eventNames.DELETE_PREP_PERFUSION_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deletePrepPerfusion: action.payload.data.data,
					deletePrepPerfusionImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deletePrepPerfusion: [],
				deletePrepPerfusionImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_PREP_PERFUSION_IMAGERY:
			return {
				...state,
				deletePrepPerfusionImageryComplete: false
			};
		case `${eventNames.DELETE_STAFF_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				deleteStaffImageryComplete: "REJECTED"
			};
		case `${eventNames.DELETE_STAFF_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				deleteStaffImageryComplete: "PENDING"
			};
		case `${eventNames.DELETE_STAFF_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteStaff: action.payload.data.data,
					deleteStaffImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteStaff: [],
				deleteStaffImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_STAFF_FOR_IMAGERY:
			return {
				...state,
				deleteStaffImageryComplete: false
			};
		case `${eventNames.DELETE_EQUIPMENT_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				deleteEquipmentImageryComplete: "REJECTED"
			};
		case `${eventNames.DELETE_EQUIPMENT_FOR_IMAGERY}_PENDING`:
			return {
				...state,
				deleteEquipmentImageryComplete: "PENDING"
			};
		case `${eventNames.DELETE_EQUIPMENT_FOR_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteEquipment: action.payload.data.data,
					deleteEquipmentImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteEquipment: [],
				deleteEquipmentImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_EQUIPMENT_FOR_IMAGERY:
			return {
				...state,
				deleteEquipmentImageryComplete: false
			};
		case `${eventNames.GET_ALL_COMPLEXITY_FACTOR}_REJECTED`:
			return {
				...state,
				getAllComplexityFactorComplete: "REJECTED"
			};
		case `${eventNames.GET_ALL_COMPLEXITY_FACTOR}_PENDING`:
			return {
				...state,
				getAllComplexityFactorComplete: "PENDING"
			};
		case `${eventNames.GET_ALL_COMPLEXITY_FACTOR}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					complexityFactor: action.payload.data.data,
					getAllComplexityFactorComplete: "FULFILLED"
				};
			}
			return {
				...state,
				complexityFactor: [],
				getAllComplexityFactorComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ALL_COMPLEXITY_FACTOR:
			return {
				...state,
				getAllComplexityFactorComplete: false
			};
		case `${eventNames.GET_COMPLEXITY_FACTOR_TYPE}_REJECTED`:
			return {
				...state,
				getComplexityFactorTypeComplete: "REJECTED"
			};
		case `${eventNames.GET_COMPLEXITY_FACTOR_TYPE}_PENDING`:
			return {
				...state,
				getComplexityFactorTypeComplete: "PENDING"
			};
		case `${eventNames.GET_COMPLEXITY_FACTOR_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					complexityFactorType: action.payload.data.data,
					getComplexityFactorTypeComplete: "FULFILLED"
				};
			}
			return {
				...state,
				complexityFactorType: [],
				getComplexityFactorTypeComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_COMPLEXITY_FACTOR_TYPE:
			return {
				...state,
				getComplexityFactorTypeComplete: false
			};
		case `${eventNames.CREATE_COMPLEXITY_FACTOR}_REJECTED`:
			return {
				...state,
				createComplexityFactorComplete: "REJECTED"
			};
		case `${eventNames.CREATE_COMPLEXITY_FACTOR}_PENDING`:
			return {
				...state,
				createComplexityFactorComplete: "PENDING"
			};
		case `${eventNames.CREATE_COMPLEXITY_FACTOR}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					complexityFactorCreate: action.payload.data.data,
					createComplexityFactorComplete: "FULFILLED"
				};
			}
			return {
				...state,
				complexityFactorCreate: [],
				createComplexityFactorComplete: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_COMPLEXITY_FACTOR:
			return {
				...state,
				createComplexityFactorComplete: false
			};
		case `${eventNames.GET_ONE_COMPLEXITY_FACTOR}_REJECTED`:
			return {
				...state,
				getOneComplexityFactorComplete: "REJECTED"
			};
		case `${eventNames.GET_ONE_COMPLEXITY_FACTOR}_PENDING`:
			return {
				...state,
				getOneComplexityFactorComplete: "PENDING"
			};
		case `${eventNames.GET_ONE_COMPLEXITY_FACTOR}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					complexityFactorData: action.payload.data.data,
					getOneComplexityFactorComplete: "FULFILLED"
				};
			}
			return {
				...state,
				complexityFactorData: [],
				getOneComplexityFactorComplete: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ONE_COMPLEXITY_FACTOR:
			return {
				...state,
				getOneComplexityFactorComplete: false
			};
		case `${eventNames.UPDATE_COMPLEXITY_FACTOR}_REJECTED`:
			return {
				...state,
				updateComplexityFactorComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_COMPLEXITY_FACTOR}_PENDING`:
			return {
				...state,
				updateComplexityFactorComplete: "PENDING"
			};
		case `${eventNames.UPDATE_COMPLEXITY_FACTOR}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					complexityFactorUpdated: action.payload.data.data,
					updateComplexityFactorComplete: "FULFILLED"
				};
			}
			return {
				...state,
				complexityFactorUpdated: [],
				updateComplexityFactorComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_COMPLEXITY_FACTOR:
			return {
				...state,
				updateComplexityFactorComplete: false
			};
		case `${eventNames.PREPARATION_PERFUSION_RANK_UPDATE_IMAGERY}_REJECTED`:
			return {
				...state,
				prepPerfusionRankUpdateComplete: "REJECTED"
			};
		case `${eventNames.PREPARATION_PERFUSION_RANK_UPDATE_IMAGERY}_PENDING`:
			return {
				...state,
				prepPerfusionRankUpdateComplete: "PENDING"
			};
		case `${eventNames.PREPARATION_PERFUSION_RANK_UPDATE_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					prepPerfusionRankUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				prepPerfusionRankUpdateComplete: "FULFILLED"
			};
		case eventNames.CLEAR_PREPARATION_PERFUSION_RANK_UPDATE_IMAGERY:
			return {
				...state,
				prepPerfusionRankUpdateComplete: false
			};
		case `${eventNames.COPY_IMAGERY}_REJECTED`:
			return {
				...state,
				copyImageryComplete: "REJECTED"
			};
		case `${eventNames.COPY_IMAGERY}_PENDING`:
			return {
				...state,
				copyImageryComplete: "PENDING"
			};
		case `${eventNames.COPY_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					copyImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				copyImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_COPY_IMAGERY:
			return {
				...state,
				copyImageryComplete: false
			};
		case `${eventNames.DELETE_COMPLEXITY_FACTOR}_REJECTED`:
			return {
				...state,
				deleteComplexityFactorImageryComplete: "REJECTED"
			};
		case `${eventNames.DELETE_COMPLEXITY_FACTOR}_PENDING`:
			return {
				...state,
				deleteComplexityFactorImageryComplete: "PENDING"
			};
		case `${eventNames.DELETE_COMPLEXITY_FACTOR}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteComplexityFactorImageryComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteComplexityFactorImageryComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_COMPLEXITY_FACTOR:
			return {
				...state,
				deleteComplexityFactorImageryComplete: false
			};
		case `${eventNames.COPY_SESSION_TYPE_TEAMS_IMAGERY}_REJECTED`:
			return {
				...state,
				copySessionTypeTeamsComplete: "REJECTED"
			};
		case `${eventNames.COPY_SESSION_TYPE_TEAMS_IMAGERY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					copySessionTypeTeamsComplete: "FULFILLED"
				};
			}
			return {
				...state,
				copySessionTypeTeamsComplete: "FULFILLED"
			};
		case eventNames.CLEAR_COPY_SESSION_TYPE_TEAMS_IMAGERY:
			return {
				...state,
				copySessionTypeTeamsComplete: false
			};
		case `${eventNames.SWITCH_STAFF_TEMPLATE_FOR_IMAGERY}_REJECTED`:
			return {
				...state,
				switchStaffTemplateComplete: "REJECTED"
			};
		case `${eventNames.SWITCH_STAFF_TEMPLATE_FOR_IMAGERY}_FULFILLED`:
			return {
				...state,
				switchStaffTemplateComplete: "FULFILLED"
			};
		case eventNames.CLEAR_SWITCH_STAFF_TEMPLATE_FOR_IMAGERY:
			return {
				...state,
				switchStaffTemplateComplete: false
			};
		default:
			return state;
	}
}
