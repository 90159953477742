import eventNames from "../../actions/events";

const initialState = {
	specialtyData: {},
	isCopySpecialityComplete: false,
	isFetchAllCopyListComplete: false,
	isFetchAllHospitalCompleted: false,
	copyList: [],
	getSpecialityCommunityDataStatus: false,
	specialityCommunityList: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.SPECIALTY_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllSpecialtyCompleted: "REJECTED"
			};

		case `${eventNames.SPECIALTY_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.count,
					specialty: action.payload.data.data.data,
					isFetchAllSpecialtyCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				specialty: [],
				isFetchAllSpecialtyCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SPECIALTY_FETCH:
			return {
				...state,
				isFetchAllSpecialtyCompleted: false
			};
		case `${eventNames.HOSPITAL_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllHospitalCompleted: "REJECTED"
			};

		case `${eventNames.HOSPITAL_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					hospitalData: action.payload.data.data.data,
					isFetchAllHospitalCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				hospitalData: [],
				isFetchAllHospitalCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_HOSPITAL_FETCH:
			return {
				...state,
				isFetchAllHospitalCompleted: false
			};
		case `${eventNames.FETCH_ONE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneSpecialtyCompleted: "FULFILLED",
					selectedSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isFetchOneSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_SPECIALTY:
			return {
				...state,
				isFetchOneSpecialtyCompleted: false
			};
		case `${eventNames.UPDATE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateSpecialtyCompleted: "FULFILLED",
					updatedSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isUpdateSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_SPECIALTY:
			return {
				...state,
				isUpdateSpecialtyCompleted: false
			};

		case `${eventNames.CREATE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateSpecialtyCompleted: "FULFILLED",
					createdSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isCreateSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_SPECIALTY:
			return {
				...state,
				isCreateSpecialtyCompleted: false
			};

		case `${eventNames.DELETE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteSpecialtyCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isDeleteSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_SPECIALTY:
			return {
				...state,
				isDeleteSpecialtyCompleted: false
			};
		case `${eventNames.COPY_MOM_SPECIALITY_TO_LOCAL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCopySpecialityComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isCopySpecialityComplete: "FULFILLED"
			};
		case `${eventNames.COPY_MOM_SPECIALITY_TO_LOCAL}_REJECTED`:
			return {
				...state,
				isCopySpecialityComplete: "REJECTED"
			};
		case eventNames.CLEAR_COPY_MOM_SPECIALITY_TO_LOCAL:
			return {
				...state,
				isCopySpecialityComplete: false
			};
		case `${eventNames.FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					copyList: action.payload.data.data,
					isFetchAllCopyListComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchAllCopyListComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY}_REJECTED`:
			return {
				...state,
				isFetchAllCopyListComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY:
			return {
				...state,
				isFetchAllCopyListComplete: false
			};

		case `${eventNames.RE_ASSIGN_SPECIALITY}_FULFILLED`:
			return { ...state, reAssignStatus: "FULFILLED" };
		case `${eventNames.RE_ASSIGN_SPECIALITY}_REJECTED`:
			return { ...state, reAssignStatus: "REJECTED" };
		case eventNames.RE_ASSIGN_SPECIALITY_COMPLETED:
			return { ...state, reAssignStatus: false };
		
		case `${eventNames.GET_SPECIALITY_COMMUNITY}_FULFILLED`:

			return {
				...state,
				getSpecialityCommunityDataStatus: "FULFILLED",
				specialityCommunityList: action.payload.data.data
			};

		case `${eventNames.GET_SPECIALITY_COMMUNITY}_REJECTED`:
			return {
				...state,
				getSpecialityCommunityDataStatus: "REJECTED"
			};
		case eventNames.CLEAR_SPECIALITY_COMMUNITY:
			return {
				...state,
				getSpecialityCommunityDataStatus: false
			};
		default:
			return state;
	}
}
