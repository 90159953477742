import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import "../../../../../../../plug/reactTable/react-table.css";
import Loader from "../../../../../../../plug/loader";

class DayInformation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			dayInformation: {},
			loading: false,
			page: 1,
			totalPages: 1,
			pageSize: utils.getDefaultPageSize()
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}

	fetchData() {
		let query = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		query += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
		this.props.dispatch(clinicalPathwayAction.getDayInformation(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, query));
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchData();
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("DAYS", utils.getLanguage()),
				id: "prefix",
				accessor: (d) => d.prefix,
				width: 250,
				resizable: true,
				sortable: false,
				Cell: (row) => (
					<d className="w_100 word_break">
						<I18 tkey="DAY" />
						{" "}
						{row.value}
					</d>
				)
			},
			{
				Header: i18Get("STEPS", utils.getLanguage()),
				id: "stepName",
				accessor: (d) => d.stepName,
				resizable: true,
				sortable: false,
				Cell: (row) => (
					<d className="w_100 word_break">
						{i18Get(row.value, utils.getLanguage())}
					</d>
				)
			}
		]);
		return tableArray;
	}

	render() {
		if (this.props.clinicalPathwayDetails.fetchDayInformation === "FULFILLED") {
			this.state.dayInformation = this.props.clinicalPathwayDetails.dayInformation;
			this.state.totalPages = Math.ceil(this.state.dayInformation.count / this.state.pageSize);
			this.state.loading = false;
			this.props.dispatch(clinicalPathwayAction.getDayInformationCompleted());
		}

		return (
			<div>
				<p>
					<I18 tkey="Length of Stay" />
          :
					{" "}
					{this.state.dayInformation.noOfDays}
				</p>
				<p>
					<I18 tkey="Day of Procedure" />
          :
					{" "}
					{this.state.dayInformation.procedureDay}
				</p>
				<div className="day_info">
					<ReactTable
						{
							...utils.getPropsForReactTable(
								this.state.dayInformation.data, (
									this.state.page - 1),
								this.state.totalPages,
								this.state.loading,
								() => (<Loader loading={this.state.loading} isTable />),
								false,
								10,
								true,
								this.onFetchData.bind(this),
								this.getCols(),
								true,
								false,
								this.onPageChange.bind(this),
								"DAYINFO",
								"",
								this.onPageSizeChange.bind(this)
							)
						}
					/>
				</div>
			</div>
		);
	}
}

DayInformation.propTypes = {
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(DayInformation));
