import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal } from "antd";
import {
	EditorState, ContentState, convertToRaw, getDefaultKeyBinding
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import eventNames from "../../../../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../../../../i18";
import extClinicalPathwayActions from "../../../../../../../../../actions/ext-clinical-pathway";
import ActionButton from "../../../../../../../../../plug/action-button";
import utils from "../../../../../../../../../utils/utils";
import events from "../../../../../../../../../actions/events";
import SelectTypeList from "../create-ext-clinical-pathway/type-list";
import SelectResponisbleList from "../create-ext-clinical-pathway/responsible-list";
import SelectItemList from "../create-ext-clinical-pathway/item-list";
import UploadFiles from "./upload-files";
import BgColor from "./item-bg-color";
import Level from "../create-ext-clinical-pathway/level-dropdown";
import ProblemList from "../create-ext-clinical-pathway/problem-list";
import TextEditor from "../../../problem-section/text-editor";
import textElementTypes from "../../../../../../../../../constants/text-element-types.json";

class ClinicalPathwayEditModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			extClinicalPathway: {},
			selectedClp: {},
			invalidResponsible: false,
			invalidItem: false,
			checkList: "",
			invalidExplanation: false,
			invalidProblem: false,
			problemDetails: {}
		};
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	UNSAFE_componentWillMount() {
		this.state.selectedClp = this.props.selectedClpForEdit;
		this.onFetchData();
		this.onFetchBgColor();
	}

	onFetchBgColor() {
		this.props.dispatch(extClinicalPathwayActions.fetchBgColor(this.props.match.params.id, this.state.selectedClp.id));
	}

	onFetchData() {
		let queryString = "offset=0&numResults=1000&";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		// queryString += this.state.selectedClp.id ? `subtype=${this.state.selectedClp.id}` : '';
		this.props.dispatch(extClinicalPathwayActions.fetchOneExtClinicalPathway(this.props.match.params.id, this.state.selectedClp.id, queryString));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	onEditorStateChange(value, editorState) {
		const prevState = this.state;
		if (value === "reference") {
			this.state.invalidReference = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.extClinicalPathway.reference = editorState;
			}
		}
		if (value === "description") {
			this.state.invalidExplanation = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.extClinicalPathway.description = editorState;
			}
		}
		if (value === "risk") {
			this.state.invalidRisk = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.extClinicalPathway.risk = editorState;
			}
		}
		if (value === "assessment") {
			this.state.invalidAssessment = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.extClinicalPathway.assessment = editorState;
			}
		}
		this.setState(prevState);
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	handleBeforeInput(input) {
		if (input === "description") {
			if (this.getLength(this.state.extClinicalPathway.description) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "reference") {
			if (this.getLength(this.state.extClinicalPathway.reference) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "risk") {
			if (this.getLength(this.state.extClinicalPathway.risk) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "assessment") {
			if (this.getLength(this.state.extClinicalPathway.assessment) >= utils.editorMaxLength) {
				return "handled";
			}
		}
	}

	handlePastedText(text, type) {
		if (type === "description") {
			if (text && ((this.getLength(this.state.extClinicalPathway.description) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "reference") {
			if (text && ((this.getLength(this.state.extClinicalPathway.reference) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "risk") {
			if (text && ((this.getLength(this.state.extClinicalPathway.risk) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "assessment") {
			if (text && ((this.getLength(this.state.extClinicalPathway.assessment) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command, type) {
		if (type === "description") {
			if (this.getLength(this.state.extClinicalPathway.description) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "reference") {
			if (this.getLength(this.state.extClinicalPathway.reference) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "risk") {
			if (this.getLength(this.state.extClinicalPathway.risk) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "assessment") {
			if (this.getLength(this.state.extClinicalPathway.assessment) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		return "not-handled";
	}

	dataChanged(e) {
		const prevState = this.state;
		if (e.length <= 255) {
			prevState.extClinicalPathway.item = e;
			if (!e) {
				prevState.extClinicalPathway.responsible = null;
				this.props.dispatch({ type: events.TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
			}
			prevState.invalidItem = false;
			prevState.invalidDataRegEx = false;
			this.setState(prevState);
		}
	}

	itemChanged(e) {
		const prevState = this.state;
		prevState.extClinicalPathway.responsible = e.responsible;
		prevState.invalidResponsible = false;
		this.setState(prevState);
	}

	responsibleChanged(e) {
		if (e.length <= 5) {
			const prevState = this.state;
			prevState.extClinicalPathway.responsible = e;
			prevState.invalidResponsible = false;
			this.setState(prevState);
		}
	}

	onItemChanged(e) {
		const prevState = this.state;
		if (e.length <= 255) {
			prevState.extClinicalPathway.item = e;
			prevState.invalidItem = false;
			prevState.invalidDataRegEx = false;
			this.setState(prevState);
		}
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.extClinicalPathway.responsible || this.state.extClinicalPathway.responsible.length < 1) {
			prevState.invalidResponsible = true;
		}
		if (!this.state.extClinicalPathway.item || !this.state.extClinicalPathway.item.trim() || (this.state.extClinicalPathway.item.match(/\\$/)) || (this.state.extClinicalPathway.item.indexOf("\"") >= 0)) {
			prevState.invalidItem = true;
		} else if (!utils.checkNameRegex(this.state.extClinicalPathway.item)) {
			prevState.invalidItem = true;
			prevState.invalidDataRegEx = true;
		}

		this.setState(prevState);
		if (!this.state.invalidResponsible && !this.state.invalidItem && !this.state.invalidReference && !this.state.invalidDescription && !this.state.invalidRisk && !this.state.invalidAssessment) {
			return true;
		}
		return false;
	}

	validateItemOnlyFields() {
		const prevState = this.state;
		if (!this.state.extClinicalPathway.item || !this.state.extClinicalPathway.item.trim() || (this.state.extClinicalPathway.item.match(/\\$/)) || (this.state.extClinicalPathway.item.indexOf("\"") >= 0)) {
			prevState.invalidItem = true;
		} else if (!utils.checkNameRegex(this.state.extClinicalPathway.item)) {
			prevState.invalidItem = true;
			prevState.invalidDataRegEx = true;
		}

		if (!this.state.extClinicalPathway.level) {
			prevState.invalidLevel = true;
		}
		this.setState(prevState);
		if (!this.state.invalidItem && !this.state.invalidLevel) {
			return true;
		}
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				item: this.state.extClinicalPathway.item ? this.state.extClinicalPathway.item.trim().charAt(0).toUpperCase() + this.state.extClinicalPathway.item.trim().slice(1) : "",
				categoryPersonnelIds: this.state.extClinicalPathway.responsible.map((data) => data.value),
				description: this.state.extClinicalPathway.description && this.state.extClinicalPathway.description.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.extClinicalPathway.description.getCurrentContent())) : "",
				reference: this.state.extClinicalPathway.reference && this.state.extClinicalPathway.reference.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.extClinicalPathway.reference.getCurrentContent())) : "",
				risk: this.state.extClinicalPathway.risk && this.state.extClinicalPathway.risk.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.extClinicalPathway.risk.getCurrentContent())) : "",
				assessment: this.state.extClinicalPathway.assessment && this.state.extClinicalPathway.assessment.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.extClinicalPathway.assessment.getCurrentContent())) : "",
				itemBg: this.state.extClinicalPathway.itemBg ? this.state.extClinicalPathway.itemBg : "",
				responsibleBg: this.state.extClinicalPathway.responsibleBg ? this.state.extClinicalPathway.responsibleBg : "",
				descriptionBg: this.state.extClinicalPathway.descriptionBg ? this.state.extClinicalPathway.descriptionBg : "",
				referenceBg: this.state.extClinicalPathway.referenceBg ? this.state.extClinicalPathway.referenceBg : "",
				riskBg: this.state.extClinicalPathway.riskBg ? this.state.extClinicalPathway.riskBg : "",
				assessmentBg: this.state.extClinicalPathway.assessmentBg ? this.state.extClinicalPathway.assessmentBg : "",
				clpHealthProblemMappigId: this.state.problemDetails && this.state.problemDetails.value ? this.state.problemDetails.value : "",
				clpHealthProblemData: this.state.problemDetails.explanation && this.state.problemDetails.explanation.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.problemDetails.explanation.getCurrentContent())) : ""
			};
			this.props.dispatch(extClinicalPathwayActions.updateExtClinicalPathway(this.props.match.params.id, this.state.extClinicalPathway.id, payload));
		}
	}

	saveItemClicked() {
		if (this.validateItemOnlyFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				item: this.state.extClinicalPathway.item ? this.state.extClinicalPathway.item.trim().charAt(0).toUpperCase() + this.state.extClinicalPathway.item.trim().slice(1) : "",
				itemBg: this.state.extClinicalPathway.itemBg ? this.state.extClinicalPathway.itemBg : "",
				level: this.state.extClinicalPathway.level
			};
			this.props.dispatch(extClinicalPathwayActions.updateItem(this.props.match.params.id, this.state.extClinicalPathway.id, payload));
		}
	}

	onColorChange(value, e, data) {
		if (e.target.checked) {
			if (this.state.extClinicalPathway[value] !== data.id) {
				this.state.extClinicalPathway[value] = data.id;
			}
		} else {
			this.state.extClinicalPathway[value] = "";
		}
		this.setState(this.state);
	}

	onLevelChanged(e) {
		this.state.extClinicalPathway.level = e;
		this.state.invalidLevel = false;
		this.setState(this.state);
	}

	onProblemChanged(e) {
		const prevState = this.state;
		prevState.problemDetails = e || "";
		if (prevState.problemDetails.explanation && typeof prevState.problemDetails.explanation === "string") {
			this.initProblem(prevState.problemDetails.explanation, "explanation");
		}
		if (!(prevState.extClinicalPathway.description && prevState.extClinicalPathway.description.getCurrentContent().hasText())) {
			e && e.results ? this.init(e.results, "description") : "";
		}
		prevState.invalidProblem = false;
		prevState.invalidExplanation = false;
		this.setState(prevState);
	}

	renderEdit() {
		return (
			<div className="pole_create_section">
				{ this.state.extClinicalPathway && this.state.extClinicalPathway.isItemOnly
					? (
						<div className="pole_create_box_clp create_category_personal_width_100">
							<div className="row">
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between">
											<div className="">
												<label><I18 tkey="Select Items" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.itemBg ? this.state.extClinicalPathway.itemBg : ""}
												onColorChange={this.onColorChange.bind(this, "itemBg")}
											/>
										</div>
										<SelectItemList
											data={this.state.extClinicalPathway.item}
											isEdit
											onItemChanged={this.onItemChanged.bind(this)}
											type={this.props.type}
											extType={this.state.selectedClp.clpSubStepId}
											days={this.state.selectedClp.days}
											invalidItem={this.state.invalidItem}
											invalidDataRgEx={this.state.invalidDataRegEx}
											onRef={(ref) => this.itemList = ref}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between" />
										<div />
										<Level
											data={this.state.extClinicalPathway.level}
											onLevelChanged={this.onLevelChanged.bind(this)}
											invalidLevel={this.state.invalidLevel}
										/>
									</div>
								</div>
							</div>
						</div>
					)
					: (
						<div className="pole_create_box_clp create_category_personal_width_100">
							<div className="row">
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between">
											<div className="">
												<label><I18 tkey="Select Items" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.itemBg ? this.state.extClinicalPathway.itemBg : ""}
												onColorChange={this.onColorChange.bind(this, "itemBg")}
											/>
										</div>
										<SelectTypeList
											data={this.state.extClinicalPathway.item}
											isEdit
											dataChanged={this.dataChanged.bind(this)}
											itemChanged={this.itemChanged.bind(this)}
											type={this.props.type}
											extType={this.state.selectedClp.clpSubStepId}
											days={this.state.selectedClp.days}
											invalidData={this.state.invalidItem}
											invalidDataRgEx={this.state.invalidDataRegEx}
											onRef={(ref) => this.typeList = ref}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Select Intervenant" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.responsibleBg ? this.state.extClinicalPathway.responsibleBg : ""}
												onColorChange={this.onColorChange.bind(this, "responsibleBg")}
											/>
										</div>
										<SelectResponisbleList
											data={this.state.extClinicalPathway.responsible}
											responsibleChanged={this.responsibleChanged.bind(this)}
											type={this.props.type}
											isEdit
											extType={this.state.selectedClp.clpSubStepId}
											days={this.state.selectedClp.days}
											invalidData={this.state.invalidResponsible}
											typeActivity={this.props.typeActivity}
											onRef={(ref) => this.typeList = ref}
										/>
									</div>
								</div>
							</div>
							<div className="row ">
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Enter Reference" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.referenceBg ? this.state.extClinicalPathway.referenceBg : ""}
												onColorChange={this.onColorChange.bind(this, "referenceBg")}
											/>
										</div>
										<TextEditor
											className="text_area_treatment form-control"
											editorState={this.state.extClinicalPathway.reference}
											onEditorStateChange={this.onEditorStateChange.bind(this, "reference")}
											handleBeforeInput={this.handleBeforeInput.bind(this, "reference")}
											handlePastedText={this.handlePastedText.bind(this, "reference")}
											keyBindingFn={this.keyBindingFn.bind(this)}
											handleKeyCommand={this.handleKeyCommand.bind(this, "reference")}
											invalidMsg={this.state.invalidReference}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Enter Deliverable" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.descriptionBg ? this.state.extClinicalPathway.descriptionBg : ""}
												onColorChange={this.onColorChange.bind(this, "descriptionBg")}
											/>
										</div>
										<TextEditor
											className="text_area_treatment form-control"
											editorState={this.state.extClinicalPathway.description}
											onEditorStateChange={this.onEditorStateChange.bind(this, "description")}
											handleBeforeInput={this.handleBeforeInput.bind(this, "description")}
											handlePastedText={this.handlePastedText.bind(this, "description")}
											keyBindingFn={this.keyBindingFn.bind(this)}
											handleKeyCommand={this.handleKeyCommand.bind(this, "description")}
											invalidMsg={this.state.invalidDescription}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Enter Risk" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.riskBg ? this.state.extClinicalPathway.riskBg : ""}
												onColorChange={this.onColorChange.bind(this, "riskBg")}
											/>
										</div>
										<TextEditor
											className="text_area_treatment form-control"
											editorState={this.state.extClinicalPathway.risk}
											onEditorStateChange={this.onEditorStateChange.bind(this, "risk")}
											handleBeforeInput={this.handleBeforeInput.bind(this, "risk")}
											handlePastedText={this.handlePastedText.bind(this, "risk")}
											keyBindingFn={this.keyBindingFn.bind(this)}
											handleKeyCommand={this.handleKeyCommand.bind(this, "risk")}
											invalidMsg={this.state.invalidRisk}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Enter Assessment" /></label>
											</div>
											<BgColor
												bgColor={this.state.bgColor}
												value={this.state.extClinicalPathway.assessmentBg ? this.state.extClinicalPathway.assessmentBg : ""}
												onColorChange={this.onColorChange.bind(this, "assessmentBg")}
											/>
										</div>
										<TextEditor
											className="text_area_treatment form-control"
											editorState={this.state.extClinicalPathway.assessment}
											onEditorStateChange={this.onEditorStateChange.bind(this, "assessment")}
											handleBeforeInput={this.handleBeforeInput.bind(this, "assessment")}
											handlePastedText={this.handlePastedText.bind(this, "assessment")}
											keyBindingFn={this.keyBindingFn.bind(this)}
											handleKeyCommand={this.handleKeyCommand.bind(this, "assessment")}
											invalidMsg={this.state.invalidAssessment}
										/>
									</div>
								</div>
								<div className="col-md-6 select_problem_edit">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label><I18 tkey="Select Problem" /></label>
											</div>
										</div>
										<div className="w_100 text_editor_z_index_select">
											<ProblemList
												data={this.state.problemDetails && this.state.problemDetails.value ? this.state.problemDetails : null}
												onProblemChanged={this.onProblemChanged.bind(this)}
												type={this.props.type}
												extType={this.state.selectedClp.clpSubStepId}
												days={this.state.selectedClp.days}
												sectionType={this.state.selectedClp.sectionType}
												withoutModel={true}
											/>
										</div>
										{this.state.invalidProblem && <span className="invalid invalid_color item_invalid"><I18 tkey="Select Problem" /></span>}
									</div>
								</div>
								<div className="col-md-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												<label>
													<I18 tkey="Explanations Specific to" />
													{" "}
													{this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.problemType ? `${utils.toPascalCase(this.state.problemDetails.problemType).replace(/_/g, " ")}` : ""} />}
												</label>
											</div>
										</div>
										<Editor
											readonly
											toolbarHidden
											wrapperClassName="wrapper-class"
											editorClassName="editor-class"
											toolbarClassName="toolbar-class"
											editorState={this.state.problemDetails.explanation ? this.state.problemDetails.explanation : ""}
										/>
										{this.state.invalidExplanation && <span className="invalid_right fixed_label top_section_invalid"><I18 tkey='Enter Valid Explanation (Special characters " \ not allowed)' /></span>}
									</div>
								</div>
							</div>
							<div className="row ">
								<div className="col-md-12">
									<div className="w_100 di ">
										<UploadFiles
											mappingId={this.state.selectedClp.id}
										/>
									</div>
								</div>
							</div>
						</div>
					)}
			</div>
		);
	}

	init(data, type) {
		const contentBlock = htmlToDraft(data || "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.extClinicalPathway[type] = EditorState.createWithContent(contentState);
		}
	}

	initProblem(data, type) {
		const contentBlock = htmlToDraft(data || "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.problemDetails[type] = EditorState.createWithContent(contentState);
		}
	}

	render() {
		if (this.props.extClinicalPathway.isFetchOneExtClinicalPathwayComplete === "FULFILLED") {
			this.state.extClinicalPathway = this.props.extClinicalPathway.selectedExtClinicalPathway.data;
			this.state.extClinicalPathway.responsible = [];
			this.state.extClinicalPathway.categoryPersonnels && this.state.extClinicalPathway.categoryPersonnels.map((value) => {
				const	categoryPersonnel = {
					value: value.categoryPersonnelId,
					label: value.categoryPersonnelName
				};
				this.state.extClinicalPathway.responsible.push(categoryPersonnel);
			});
			const { data } = this.props.extClinicalPathway.selectedExtClinicalPathway;
			this.state.problemDetails.value = this.state.extClinicalPathway.clpHealthProblemMappigId;
			this.state.problemDetails.label = this.state.extClinicalPathway.clpHealthProblemType ? `${i18Get(this.state.extClinicalPathway.clpHealthProblemType, utils.getLanguage())} - ${this.state.extClinicalPathway.problem}` : "";
			this.state.problemDetails.type = this.state.extClinicalPathway.clpHealthProblemType;
			this.state.problemDetails.problem = this.state.extClinicalPathway.problem ? this.state.extClinicalPathway.problem : "";
			Object.keys(this.state.extClinicalPathway).map((type) => {
				if (textElementTypes.key[type]) {
					this.init(data[type], type);
				}
			});
			if (this.state.extClinicalPathway.clpHealthProblemData) {
				this.initProblem(this.state.extClinicalPathway.clpHealthProblemData, "explanation");
			}
			this.props.dispatch(extClinicalPathwayActions.clearFetchOneExtClinicalPathway());
		}
		if (this.props.extClinicalPathway.isFetchOneExtClinicalPathwayComplete === "REJECTED") {
			this.props.dispatch(extClinicalPathwayActions.clearFetchOneExtClinicalPathway());
		}

		if (this.props.extClinicalPathway.isFetchBgColorComplete === "FULFILLED") {
			this.state.bgColor = this.props.extClinicalPathway.bgColor;
			this.props.dispatch(extClinicalPathwayActions.clearFetchBgColor());
		}
		if (this.props.extClinicalPathway.isFetchBgColorComplete === "REJECTED") {
			this.props.dispatch(extClinicalPathwayActions.clearFetchBgColor());
		}

		if (this.props.extClinicalPathway.isExtClinicalPathwayUpdateComplete === "FULFILLED") {
			this.props.dispatch(extClinicalPathwayActions.clearUpdateExtClinicalPathway());
			this.props.cancelClicked();
			this.props.refreshExtClinicalPathwayList(this.state.selectedClp.day, this.state.selectedClp.clpSubStepId);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.extClinicalPathway.isItemUpdateComplete === "FULFILLED") {
			this.props.dispatch(extClinicalPathwayActions.clearItemUpdate());
			this.props.cancelClicked();
			this.props.refreshExtClinicalPathwayList(this.state.selectedClp.day, this.state.selectedClp.clpSubStepId);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div>
				<Modal
					className="re_assign_modal edit_clinical_pathway "
					title={i18Get("Edit Clinical Pathway", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.props.cancelClicked}
					footer={[
						<div key="edit_clinical_pathway" className="d-flex align-items-center justify-content-end common_cancel_btn_container">
							<button key="Cancel" type="primary" className="ant-btn float_none_imp height_common_btn margin-right-10" onClick={this.props.cancelClicked}>{i18Get("Cancel", utils.getLanguage())}</button>
							<ActionButton key="Save" type="primary" className="" onClick={this.state.extClinicalPathway.isItemOnly ? this.saveItemClicked.bind(this) : this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
						</div>
					]}
				>
					{this.state.extClinicalPathway.id ? this.renderEdit() : ""}
				</Modal>
			</div>
		);
	}
}

ClinicalPathwayEditModel.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	extClinicalPathway: store.extClinicalPathway,
	imagery: store.imagery,
	translations: store.translations
}))(ClinicalPathwayEditModel));
