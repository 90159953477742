import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	interventions: [],
	fetchAllInterventions: false,
	updateIntervention: false,
	interventionLinkList: [],
	getInterventionLinkStatus: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.INTERVENTION_FILTER}`:
			return {
				...state,
				filterData: action.payload.filter,
				ignorePageChange: action.payload.ignorePageChange
			};
		case `${eventNames.CLEAR_FILTER}`:
			return {
				...state,
				filterData: {}
			};

		case `${eventNames.INTERVENTION_GET_ALL}_FULFILLED`:
			return {
				...state,
				interventions: action.payload.data.data,
				interventionsListMeta: action.meta,
				fetchAllInterventions: "FULFILLED"
			};
		case `${eventNames.INTERVENTION_GET_ALL}_REJECTED`:
			return {
				...state,
				fetchAllInterventions: "REJECTED"
			};

		case eventNames.CLEAR_INTERVENTION_GET_ALL:
			return {
				...state,
				interventions: [],
				fetchAllInterventions: false
			};

		case `${eventNames.INTERVENTION_GET_ONE}_FULFILLED`:
			return {
				...state,
				intervention: action.payload.data.data,
				fetchOneIntervention: "FULFILLED"
			};
		case `${eventNames.INTERVENTION_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneIntervention: "REJECTED"
			};
		case eventNames.CLEAR_INTERVENTION_GETONE:
			return {
				...state,
				fetchOneIntervention: false
			};
		case `${eventNames.INTERVENTION_UPDATE}_FULFILLED`:
			return {
				...state,
				fetchOneUpdateIntervention: "FULFILLED",
				updateIntervention: "FULFILLED"

			};
		case `${eventNames.INTERVENTION_UPDATE}_REJECTED`:
			return {
				...state,
				fetchOneUpdateIntervention: "REJECTED",
				updateIntervention: "REJECTED"
			};

		case `${eventNames.CLEAR_INTERVENTION_UPDATE}`:
			return {
				...state,
				fetchOneUpdateIntervention: false,
				updateIntervention: false

			};
		case `${eventNames.COST_GET}_FULFILLED`:
			return {
				...state,
				interventionCost: action.payload.data.data,
				fetchOneInterventionCost: "FULFILLED"
			};
		case `${eventNames.COST_GET}_REJECTED`:
			return {
				...state,
				fetchOneInterventionCost: "REJECTED"
			};

		case `${eventNames.CONSUMMABLES_GET}_FULFILLED`:
			return {
				...state,
				interventionConsummablesCost: action.payload.data.data,
				fetchOneInterventionConsummablesCost: "FULFILLED"
			};
		case `${eventNames.CONSUMMABLES_GET}_REJECTED`:
			return {
				...state,
				fetchOneInterventionConsummablesCost: "REJECTED"
			};

		case `${eventNames.INTERVENTION_CREATE}_FULFILLED`:
			return {
				...state,
				createdInterventionData: action.payload.data.data,
				createIntervention: "FULFILLED"
			};
		case `${eventNames.INTERVENTION_CREATE}_REJECTED`:
			return {
				...state,
				createIntervention: "REJECTED"
			};
		case eventNames.CLEAR_INTERVENTION_CREATE:
			return {
				...state,
				createdInterventionData: [],
				createIntervention: false
			};
		case `${eventNames.COPY_INTERVENTION}_FULFILLED`:
			return {
				...state,
				copyInterventionData: action.payload.data,
				copyIntervention: "FULFILLED"
			};
		case `${eventNames.COPY_INTERVENTION}_REJECTED`:
			return {
				...state,
				copyIntervention: "REJECTED"
			};

		case eventNames.CLEAR_COPY_INTERVENTION:
			return {
				...state,
				copyIntervention: false
			};
		case `${eventNames.ASSIGN_NEW_MOM}_FULFILLED`:
			return {
				...state,
				newMOMAssigned: action.payload.data,
				assignNewMOMCompleted: "FULFILLED"
			};
		case `${eventNames.ASSIGN_NEW_MOM}_REJECTED`:
			return {
				...state,
				assignNewMOMCompleted: "REJECTED"
			};
		case eventNames.CLEAR_ASSIGN_NEW_MOM:
			return {
				...state,
				assignNewMOMCompleted: false
			};
		case `${eventNames.INTERVENTION_REPORT_EXPRORT}_FULFILLED`:
			return { ...state, interventionReportExportStatus: "FULFILLED", interventionReport: action.payload.data.data };
		case `${eventNames.INTERVENTION_REPORT_EXPRORT}_REJECTED`:
			return { ...state, interventionReportExportStatus: "REJECTED" };
		case eventNames.INTERVENTION_REPORT_EXPRORT_COMPLETED:
			return { ...state, interventionReportExportStatus: false };

		case `${eventNames.CHECK_INTERVENTION_REPORT_STATUS}_FULFILLED`:
			return { ...state, intervetionReportStatus: "FULFILLED", intervetionReportStage: action.payload.data.data };
		case `${eventNames.CHECK_INTERVENTION_REPORT_STATUS}_REJECTED`:
			return { ...state, intervetionReportStatus: "REJECTED", intervetionReportStage: action.payload.data.data };
		case eventNames.CHECK_INTERVENTION_REPORT_STATUS_COMPLETED:
			return { ...state, intervetionReportStatus: false };

		case `${eventNames.GET_RECOMMENDATION_COUNT}_FULFILLED`:
			return { ...state, recommendationCountStatus: "FULFILLED", recommendationCount: action.payload.data.data };
		case `${eventNames.GET_RECOMMENDATION_COUNT}_REJECTED`:
			return { ...state, recommendationCountStatus: "REJECTED" };
		case eventNames.CLEAR_RECOMMENDATION_COUNT:
			return { ...state, recommendationCountStatus: false };

		case `${eventNames.GET_ONE_INTERVENTION}_FULFILLED`:
			return {
				...state,
				treatment: action.payload.data.data,
				getOneIntervention: "FULFILLED"
			};
		case `${eventNames.GET_ONE_INTERVENTION}_REJECTED`:
			return {
				...state,
				getOneIntervention: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_INTERVENTION:
			return {
				...state,
				getOneIntervention: false
			};

		case `${eventNames.GET_APPROVED_INTERVENTIONS}_FULFILLED`:
			return {
				...state,
				interventionList: action.payload.data.data,
				getInterventionStatus: "FULFILLED"
			};
		case `${eventNames.GET_APPROVED_INTERVENTIONS}_REJECTED`:
			return {
				...state,
				getInterventionStatus: "REJECTED"
			};
		case eventNames.GET_APPROVED_INTERVENTIONS_COMPLETED:
			return {
				...state,
				getInterventionStatus: false
			};
		case `${eventNames.GET_LINKED_ITEMS_FOR_INTERVENTION}_FULFILLED`:
			return {
				...state,
				interventionLinkList: action.payload.data.data.linkedTreatments,
				getInterventionLinkStatus: "FULFILLED"
			};
		case `${eventNames.GET_LINKED_ITEMS_FOR_INTERVENTION}_REJECTED`:
			return {
				...state,
				getInterventionLinkStatus: "REJECTED"
			};
		case eventNames.GET_LINKED_ITEMS_FOR_INTERVENTION_COMPLETED:
			return {
				...state,
				getInterventionLinkStatus: false
			};

		case `${eventNames.SAVE_AND_APPROVE_INTERVENTION}_FULFILLED`:
			return { ...state, saveAndApproveInterventionStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_INTERVENTION}_REJECTED`:
			return { ...state, saveAndApproveInterventionStatus: "REJECTED" };
		case eventNames.CLEAR_SAVE_AND_APPROVE_INTERVENTION:
			return { ...state, saveAndApproveInterventionStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_INTERVENTION}_FULFILLED`:
			return { ...state, asssignAndApproveInterventionStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_INTERVENTION}_REJECTED`:
			return { ...state, asssignAndApproveInterventionStatus: "REJECTED" };
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_INTERVENTION:
			return { ...state, asssignAndApproveInterventionStatus: false };

		case `${eventNames.EDIT_INTERVENTION}_FULFILLED`:
			return { ...state, editInterventionStatus: "FULFILLED" };
		case `${eventNames.EDIT_INTERVENTION}_REJECTED`:
			return { ...state, editInterventionStatus: "REJECTED" };
		case eventNames.CLEAR_EDIT_INTERVENTION:
			return { ...state, editInterventionStatus: false };

		case `${eventNames.DELETE_INTERVENTION}_FULFILLED`:
			return { ...state, deleteInterventionStatus: "FULFILLED" };
		case `${eventNames.DELETE_INTERVENTION}_REJECTED`:
			return { ...state, deleteInterventionStatus: "REJECTED" };
		case eventNames.DELETE_INTERVENTION_COMPLETED:
			return { ...state, deleteInterventionStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_CONSUMABLE}_FULFILLED`:
			return { ...state, saveAndApproveConsumableStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_CONSUMABLE}_REJECTED`:
			return { ...state, saveAndApproveConsumableStatus: "REJECTED" };
		case eventNames.CLEAR_SAVE_AND_APPROVE_CONSUMABLE:
			return { ...state, saveAndApproveConsumableStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_CONSUMABLE}_FULFILLED`:
			return { ...state, asssignAndApproveConsumableStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_CONSUMABLE}_REJECTED`:
			return { ...state, asssignAndApproveConsumableStatus: "REJECTED" };
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_CONSUMABLE:
			return { ...state, asssignAndApproveConsumableStatus: false };

		case `${eventNames.GET_TREATMENT_COST_LIST}_FULFILLED`:
			return { ...state, getTreatmentCostListStatus: "FULFILLED", treatmentCostList: action.payload.data.data };
		case `${eventNames.GET_TREATMENT_COST_LIST}_REJECTED`:
			return { ...state, getTreatmentCostListStatus: "REJECTED" };
		case eventNames.GET_TREATMENT_COST_LIST_COMPLETED:
			return { ...state, getTreatmentCostListStatus: false };

		case `${eventNames.UPDATE_TOTAL_DEFAULT_COST}_FULFILLED`:
			return {
				...state,
				updateTotalDefaultCostComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_TOTAL_DEFAULT_COST}_REJECTED`:
			return {
				...state,
				updateTotalDefaultCostComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_TOTAL_DEFAULT_COST:
			return {
				...state,
				updateTotalDefaultCostComplete: false
			};

		case `${eventNames.GET_ONE_DEFAULT_VALUE}_FULFILLED`:
			return {
				...state,
				defaultValue: action.payload.data.data,
				getOneDefaultValue: "FULFILLED"
			};
		case `${eventNames.GET_ONE_DEFAULT_VALUE}_REJECTED`:
			return {
				...state,
				getOneDefaultValue: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_DEFAULT_VALUE:
			return {
				...state,
				getOneDefaultValue: false
			};

		case `${eventNames.APPROVE_DEFAULT_VALUE}_FULFILLED`:
			return { ...state, approveDefaultValueStatus: "FULFILLED" };
		case `${eventNames.APPROVE_DEFAULT_VALUE}_REJECTED`:
			return { ...state, approveDefaultValueStatus: "REJECTED" };
		case eventNames.CLEAR_APPROVE_DEFAULT_VALUE:
			return { ...state, approveDefaultValueStatus: false };

		case `${eventNames.INTERVENTION_SUB_TREATMENT_ADD}_FULFILLED`:
			return {
				...state,
				createdSubTreatment: action.payload.data.data,
				createSubTreatmentStatus: "FULFILLED"
			};
		case `${eventNames.INTERVENTION_SUB_TREATMENT_ADD}_REJECTED`:
			return {
				...state,
				createSubTreatmentStatus: "REJECTED"
			};
		case eventNames.INTERVENTION_SUB_TREATMENT_ADD_COMPLETED:
			return {
				...state,
				createdSubTreatment: [],
				createSubTreatmentStatus: false
			};

		case `${eventNames.INTERVENTION_SUB_TREATMENT_UPDATE}_FULFILLED`:
			return { ...state, editSubTreatmentStatus: "FULFILLED" };
		case `${eventNames.INTERVENTION_SUB_TREATMENT_UPDATE}_REJECTED`:
			return { ...state, editSubTreatmentStatus: "REJECTED" };
		case eventNames.INTERVENTION_SUB_TREATMENT_UPDATE_COMPLETED:
			return { ...state, editSubTreatmentStatus: false };

		case `${eventNames.INTERVENTION_SUB_TREATMENT_DELETE}_FULFILLED`:
			return { ...state, deleteSubTreatmentStatus: "FULFILLED" };
		case `${eventNames.INTERVENTION_SUB_TREATMENT_DELETE}_REJECTED`:
			return { ...state, deleteSubTreatmentStatus: "REJECTED" };
		case eventNames.INTERVENTION_SUB_TREATMENT_DELETE_COMPLETED:
			return { ...state, deleteSubTreatmentStatus: false };

		case `${eventNames.INTERVENTION_SUB_TREATMENT_GET_ONE}_FULFILLED`:
			return {
				...state,
				subTreatment: action.payload.data.data,
				getOneSubTreatmentStatus: "FULFILLED"
			};
		case `${eventNames.INTERVENTION_SUB_TREATMENT_GET_ONE}_REJECTED`:
			return {
				...state,
				getOneSubTreatmentStatus: "REJECTED"
			};
		case eventNames.INTERVENTION_SUB_TREATMENT_GET_ONE_COMPLETED:
			return {
				...state,
				getOneSubTreatmentStatus: false
			};

		case `${eventNames.UPDATE_INTERVENTION_DESCRIPTION}_FULFILLED`:
			return { ...state, editInterventionDescriptionStatus: "FULFILLED" };
		case `${eventNames.UPDATE_INTERVENTION_DESCRIPTION}_REJECTED`:
			return { ...state, editInterventionDescriptionStatus: "REJECTED" };
		case eventNames.CLEAR_UPDATE_INTERVENTION_DESCRIPTION:
			return { ...state, editInterventionDescriptionStatus: false };

		case eventNames.SELECTED_COLUMN_INTERVENTION_TIMESHEETS:
			return {
				...state,
				selectedColumnTimeSheet: action.payload.value
			};

		case eventNames.SELECTED_COLUMN_INTERVENTION_DEFAULT_VALUES:
			return {
				...state,
				selectedColumnDefaultValues: action.payload.value
			};
		default:
			return state;
	}
}
