import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../actions/admin-clinical-pathway";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import SectionTypeDropDown from "../section-type-dropdown";
import { Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class EditProblem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			problem: {},
			visible: false,
			clpDetails: []
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwayProblem(this.props.match.params.id));
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.saveClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.problem.problemType = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		if (utils.checkCodeRegex(value)) {
			prevState.invalidCode = false;
			prevState.problem.code = value;
		} else {
			prevState.problem.code = "";
		}
		this.setState(prevState);
	}

	selectedSectionType(e) {
		const prevState = this.state;
		prevState.invalidSectionType = false;
		prevState.problem.stepSectionType = e;
		this.setState(prevState);
	}


	onRiskChange = (e) => {
		const prevState = this.state;
		prevState.problem.isWithRisk = e.target.checked;
		this.setState(prevState);
	};

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.problem.problemType) || !this.state.problem.problemType || !this.state.problem.problemType.trim() || (this.state.problem.problemType.match(/\\$/)) || (this.state.problem.problemType.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (!this.state.problem.stepSectionType) {
			prevState.invalidSectionType = true;
		}
		if (!this.state.problem.code) {
			prevState.invalidCode = true;
		}
		if (!prevState.invalidName && !prevState.invalidCode && !prevState.invalidClinicalPathwayType && !prevState.invalidSectionType) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				problemType: this.state.problem.problemType.trim(),
				stepSectionType: this.state.problem.stepSectionType ? this.state.problem.stepSectionType : "",
				code: this.state.problem.code.trim(),
				isWithRisk: this.state.problem.isWithRisk
			};
			this.props.dispatch(adminClinicalPathwayActions.updateClinicalPathwayProblem(this.state.problem.id, payload));
		}
	}

	onClickCancel = () => {
		const prevState = this.state;
		prevState.visible = false;
		this.setState(prevState);
	};

	render() {
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayProblemCompleted === "FULFILLED") {
			this.state.problem = this.props.adminClinicalPathway.problem;
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.problem.problemType, id: this.state.problem.id } });
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayProblem());
		}
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayProblem());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`);
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayProblemCompleted === "FULFILLED") {

			if (this.props.adminClinicalPathway && this.props.adminClinicalPathway.problemDetails.isErrorResponse) {
				this.state.clpDetails = this.props.adminClinicalPathway.problemDetails.errorData;
				this.state.visible = true;
				this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayProblem());
				return false;

			}
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayProblem());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayProblemCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayProblem());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								{/* <label className="code_label"> * <I18 tkey="Code must be 5-digit" /></label> */}
								<NameInput
									value={this.state.problem.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={5}
									disabled={true} />
								{this.state.invalidCode ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid code' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Problem Type" /></label>
								<NameInput
									value={this.state.problem.problemType}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={56} />
								{this.state.invalidName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid problem type (Special characters not allowed)' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Type" /></label>
								<SectionTypeDropDown
									className=" "
									sectionType={this.state.problem.stepSectionType ? this.state.problem.stepSectionType : utils.sameDropper()}
									selectedSectionType={this.selectedSectionType.bind(this)}
									disabled={true}
								/>
								{this.state.invalidSectionType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid type" /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-top-25 position_relative">
								<Checkbox checked={this.state.problem.isWithRisk} onChange={this.onRiskChange}>
									<strong className='bold' ><I18 tkey="Enable Risk Option" /><br />
										<label className='margin-left-23'>
											<em className='color-6a6666'><I18 tkey="This enables 'With Risk' or 'Without Risk' while creating a cible" /></em>
										</label>
									</strong>
								</Checkbox>
							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									onClick={this.saveClicked.bind(this)}
									value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>

				{this.state.clpDetails.length > 0 &&
					<Modal className='model_information' title={<span className='information_header'><ExclamationCircleOutlined /><span className='text'><I18 tkey="Risk can't be disabled for this problem type, because the following problem are created with risk." /></span></span>}
						visible={this.state.visible} footer={null}
						width={600}
						onCancel={this.onClickCancel}
						wrapClassName="drag_modal"
					>
						<div className="scroll_window">
							{
								this.state.clpDetails.map((data, index) => {
									return (
										<div className='margin-bottom-5' key={index}>
											<h4 className='clp_name'>{data.clinicalPathway}</h4>
											<li className='clp_details'>{data.problem}</li>
										</div>
									);

								})

							}
						</div>
					</Modal>
				}
			</div>
		);
	}
}

EditProblem.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(EditProblem));
