import React, { Component } from "react";
import Card from "./cards";
import I18 from "../../../i18";
import config from "./config.json";
import "./dashboard.css";
import screenId from "../../../router/screenIdList";
import events from "../../../actions/events";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			buildVersion: "",
			buildDate: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.dashboard });
	}

	render() {
		if (this.props.settings.isThemeFetchComplete === "FULFILLED" && this.props.settings.theme) {
			this.state.buildVersion = this.props.settings.theme.buildVersion.value;
			this.state.buildDate = this.props.settings.theme.buildDate.value;
		}
		return (
			<div className="container position_relative height_66calc">
				<div className="bottom_section position_relative h_100">
					<div className="main-container-contents">
						<div className="display_table w_100">
							<div className="display_table_cell">
								<div className="mom_benchmark_text text_align_center margin-bottom-40">
									<I18 tkey="Welcome to the MOM-Benchmark platform" />
								</div>
								<div className="four_boxes">
									<div className="w_100 di">
										<Card config={config} />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="main_container_footer">
						<span>
              MOM-Benchmark
							{this.state.buildVersion}
              &copy;
							{this.state.buildDate}
						</span>
					</div>
				</div>
			</div>
		);
	}
}

Dashboard.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	settings: store.settings
}))(Dashboard));
