import eventNames from "../../actions/events";

const initialState = {
	selectedSiteName: ""
};
const siteMasterKey = "MASTER_SITE";

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_SITES}_REJECTED`:
			return {
				...state,
				isFetchAllSitesCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_SITES}_PENDING`:
			return {
				...state,
				isFetchAllSitesCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.count,
					sites: action.payload.data.data.sites,
					isFetchAllSitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isFetchAllSitesCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SITE_FETCH:
			return {
				...state,
				sites: [],
				isFetchAllSitesCompleted: false
			};
		case `${eventNames.FETCH_MASTER_SITE}_REJECTED`:
			return {
				...state,
				isFetchMasterSiteCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_MASTER_SITE}_PENDING`:
			return {
				...state,
				isFetchMasterSiteCompleted: "PENDING"
			};
		case `${eventNames.FETCH_MASTER_SITE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				localStorage.setItem(siteMasterKey, JSON.stringify(action.payload.data.data));
				return {
					...state,
					isFetchMasterSiteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchMasterSiteCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_MASTER_SITE_FETCH:
			return {
				...state,
				isFetchMasterSiteCompleted: false
			};
		case `${eventNames.FETCH_ONE_SITES}_REJECTED`:
			return {
				...state,
				isFetchOneSitesCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_SITES}_PENDING`:
			return {
				...state,
				isFetchOneSitesCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedSite: action.payload.data.data,
					isFetchOneSitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				selectedSite: [],
				isFetchOneSitesCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ONE_SITES:
			return {
				...state,
				selectedSite: [],
				isFetchOneSitesCompleted: false
			};
		case eventNames.SET_SITE_NAME:
			return {
				...state,
				selectedSiteName: action.payload.name
			};
		case `${eventNames.UPDATE_SITES}_REJECTED`:
			return {
				...state,
				isUpdateSitesCompleted: "REJECTED"
			};
		case `${eventNames.UPDATE_SITES}_PENDING`:
			return {
				...state,
				isUpdateSitesCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedSite: action.payload.data.data,
					isUpdateSitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				updatedSite: [],
				isUpdateSitesCompleted: "FULFILLED"
			};
		case eventNames.UPDATE_CLEAR_SITES:
			return {
				...state,
				updatedSite: [],
				isUpdateSitesCompleted: false
			};
		case `${eventNames.CREATE_SITES}_REJECTED`:
			return {
				...state,
				isCreateSitesCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_SITES}_PENDING`:
			return {
				...state,
				isCreateSitesCompleted: "PENDING"
			};
		case `${eventNames.CREATE_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					site: action.payload.data.data,
					isCreateSitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				site: [],
				isCreateSitesCompleted: "FULFILLED"
			};
		case eventNames.CREATE_CLEAR_SITES:
			return {
				...state,
				site: [],
				isCreateSitesCompleted: false
			};
		case `${eventNames.DELETE_SITES}_REJECTED`:
			return {
				...state,
				isDeleteSitesCompleted: "REJECTED"
			};
		case `${eventNames.DELETE_SITES}_PENDING`:
			return {
				...state,
				isDeleteSitesCompleted: "PENDING"
			};
		case `${eventNames.DELETE_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deletedId: action.payload.data.data,
					isDeleteSitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				deletedId: [],
				isDeleteSitesCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SITES_DELETE:
			return {
				...state,
				deletedId: [],
				isDeleteSitesCompleted: false
			};
		default:
			return state;
	}
}
