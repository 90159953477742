import React, { Component } from "react";
import { withRouter } from "react-router";
import I18 from "../../../../../../i18";
import FamilyDropDown from "../../administration/activities/activity-families/selectFamilyForSpeciality";
import SpecialtyDropdown from "../../administration/activities/specialities/selectSpecialities";
import StatusDropDown from "../statusDropDown";
import typeActivity from "../../../../../../constants/typeActivity.json";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import InterventionStatusFilter from "./intervention-status-filter";

class Filter extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="row   performance_filter position-relative">
				<a className="clear_filter_a intervention-clear-filter" onClick={this.props.clearFilter}>
					<I18 tkey="Clear Filter" />
				</a>
				<div className="col-md-4 col-sm-4">
					<div className=" w_100 margin-bottom-5">
						<label className="min_width_spl margin-top-7 label_name">
							<I18 tkey="Speciality" />
						</label>
						<SpecialtyDropdown
							data={this.props.filter.selectedSpecialty}
							organization={this.props.match.params.organization}
							typeActivity={typeActivity.INTERVENTION.toUpperCase()}
							specialityChanged={this.props.onSpecialtyChange}
						/>
					</div>
				</div>
				<div className="col-md-4 col-sm-4">
					<div className=" w_100 margin-bottom-5">
						<label className="min_width_spl  margin-top-7 label_name">
							<I18 tkey="Family" />
						</label>
						<FamilyDropDown
							data={this.props.filter.selectedFamily}
							organization={this.props.match.params.organization}
							specialityId={this.props.filter.selectedSpecialty ? this.props.filter.selectedSpecialty.value : null}
							typeActivity={typeActivity.INTERVENTION.toUpperCase()}
							onFamilyChanged={this.props.onFamilyChanged}
						/>
					</div>
				</div>
				<div className="col-md-4 col-sm-4 ">
					<div className=" w_100 margin-bottom-15">
						<label className="min_width_status  margin-top-7 label_name">
							<I18 tkey="Status" />
						</label>
						<StatusDropDown
							data={this.props.filter.selectedStatus}
							onStatusChange={this.props.onStatusChange}
						/>
					</div>
				</div>
				<div className="col-md-4 col-sm-4 ">
					<div className=" w_100 margin-bottom-5">
						<label className="min_width_status  margin-top-7 label_name">
							<I18 tkey="Hospital" />
						</label>
						<HospitalSelect
							data={this.props.filter.selectedHospital}
							hospitalChanged={this.props.hospitalChanged.bind(this)}
						/>
					</div>
				</div>

				<div className="col-md-4 col-sm-4 ">
					<div className=" w_100 margin-bottom-5">
						<label className="min_width_status  margin-top-7 label_name">
							<I18 tkey="Intervention Status" />
						</label>
						<InterventionStatusFilter
							data={this.props.filter.selectedInterventionStatus}
							dropdownChanged={this.props.onInterventionStatusChange.bind(this)}
						/>
					</div>
				</div>

			</div>
		);
	}
}

export default withRouter(Filter);
