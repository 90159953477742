import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { DatePicker } from "antd";
import moment from "moment";
import eventNames from "../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../actions/clinicalPathway-details";
import ActionButton from "../../../../../../plug/action-button";
import NameInput from "../../../../../../plug/regex-name-search-input";
import utils from "../../../../../../utils/utils";
import Loader from "../../../../../../plug/loader";
import BreadCrumb from "../../breadcrumb";
import ActivityDropdown from "../authors-activities/activity-type";

class EditSubClinincPathway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			authorAndActivity: {},
			invalidQuantity: false
		};
		// this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	getLink(type) {
		return `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}` + `${type ? `/sub_clinical_pathway/${type}` : ""}`;
	}

	getName(name) {
		return name || "";
	}

	getQueryParams() {
		return `${this.state.position ? `?position=${this.state.position}` : ""}`;
	}

	generateItems() {
		const items = [];
		items.push({
			name: i18Get("Clinical Pathway", utils.getLanguage()),
			link: `/clinical_pathway/${this.props.match.params.organization}`
		});
		items.push({ name: this.props.clinicalPathway.name ? `${this.props.clinicalPathway.hospitalName ? this.props.clinicalPathway.hospitalName : ""} ${this.props.clinicalPathway.name}` : "", link: this.getLink() + this.getQueryParams() });
		items.push({ name: i18Get(this.getName("AUTHORS AND ACTIONS"), utils.getLanguage()), link: this.getLink("Authors_And_Actions") });
		if (this.state.authorAndActivity.id) {
			items.push({ name: this.state.authorAndActivity.authors, link: `${this.getLink("Authors_And_Actions")}/${this.state.authorAndActivity.id}` });
		}
		return items;
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(clinicalPathwayDetailsAction.getOneAuthorAndActivity(this.props.match.params.id, this.props.match.params.serviceId, new Date().getTimezoneOffset()));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	cancelClicked() {
		this.props.history.push(`/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}/sub_clinical_pathway/Authors_And_Actions${this.props.location.search}`);
	}

	onChange(value, e) {
		if (e.target.value.length <= 255) {
			this.state.authorAndActivity[value] = e.target.value;
			this.state.invalidDescription = false;
			this.setState(this.state);
		}
	}

	authorChanged(value) {
		const prevState = this.state;
		prevState.authorAndActivity.authors = value;
		prevState.invalidAuthor = false;
		this.setState(prevState);
	}

	onActivityChange(e) {
		this.state.authorAndActivity.activity = e;
		this.state.invalidActivity = false;
		this.setState(this.state);
	}

	dateChanged(value, dateString) {
		this.state.authorAndActivity.date = dateString;
		this.state.invalidDate = false;
		this.setState(this.state);
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.authorAndActivity.authors || !this.state.authorAndActivity.authors.trim() || (this.state.authorAndActivity.authors.match(/\\$/)) || (this.state.authorAndActivity.authors.indexOf("\"") >= 0)) {
			prevState.invalidAuthor = true;
		}
		if (!this.state.authorAndActivity.activity) {
			prevState.invalidActivity = true;
		}
		if (!this.state.authorAndActivity.date) {
			prevState.invalidDate = true;
		}
		if ((this.state.authorAndActivity.description ? !this.state.authorAndActivity.description.trim() || this.state.authorAndActivity.description.match(/\\$/) : false) || (this.state.authorAndActivity.description ? this.state.authorAndActivity.description.indexOf("\"") >= 0 : false)) {
			prevState.invalidDescription = true;
		}
		this.setState(prevState);
		if (!this.state.invalidAuthor && !this.state.invalidActivity && !this.state.invalidDate && !this.state.invalidDescription) {
			return true;
		}
		return false;
	}

	onOk() {
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				description: this.state.authorAndActivity.description ? this.state.authorAndActivity.description.trim() : "",
				authors: this.state.authorAndActivity.authors ? this.state.authorAndActivity.authors.trim() : "",
				activity: this.state.authorAndActivity.activity,
				date: this.state.authorAndActivity.date ? this.state.authorAndActivity.date : ""
			};
			this.props.dispatch(clinicalPathwayDetailsAction.updateAuthorAndActivity(this.props.match.params.id, this.state.authorAndActivity.id, payload));
		}
	}

	renderEdit() {
		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				<div className="pole_create_section">
					<div className="pole_create_box create_category_personal_width_100">
						<div className="row">
							<div className="col-md-6 margin-bottom-10">
								<label className="common_label"><I18 tkey="Enter Author" /></label>
								<NameInput
									value={this.state.authorAndActivity.authors}
									inputChanged={this.authorChanged.bind(this)}
									maxLength={255}
								/>
								{this.state.invalidAuthor ? <span className="invalid invalid_color"><I18 tkey='Enter Valid Author (Special characters " \ not allowed)' /></span> : ""}
							</div>
							<div className="col-md-6 margin-bottom-10">
								<label className="common_label"><I18 tkey="Action" /></label>
								<ActivityDropdown
									activity={this.state.authorAndActivity.activity}
									onActivityChange={this.onActivityChange.bind(this)}
								/>
								{this.state.invalidActivity ? <span className="invalid invalid_color"><I18 tkey="Select Action" /></span> : ""}
							</div>
							<div className="col-md-6 margin-bottom-10">
								<label className="common_label"><I18 tkey="Date" /></label>
								<DatePicker
									className="w_100"
									showTime
									format="DD/MM/YYYY HH:mm"
									value={this.state.authorAndActivity.date ? moment(this.state.authorAndActivity.date, "DD/MM/YYYY HH:mm") : ""}
									onChange={this.dateChanged.bind(this)}
									onOk={this.onOk}
								/>
								{this.state.invalidDate && <span className="invalid invalid_color"><I18 tkey="Select Date" /></span>}
							</div>
							<div className="col-md-6 margin-bottom-10">
								<div className="w_100 ">
									<label className="common_label"><I18 tkey="Description" /></label>
									<textarea className="text_area_treatment form-control" maxLength={255} value={this.state.authorAndActivity.description} onChange={this.onChange.bind(this, "description")} />
									{this.state.invalidDescription && <span className="invalid invalid_color"><I18 tkey='Enter Valid Description (Special characters " \ not allowed)' /></span>}
								</div>
							</div>
							<div className="col-md-12 ">
								<div className="di w_100 text_align_right">
									<button className="edit_delete_button cancel_button margin-right-15" onClick={this.cancelClicked.bind(this)}><I18 tkey="Cancel" /></button>
									<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.clinicalPathwayDetails.getOneAuthorAndActivity === "FULFILLED") {
			this.state.authorAndActivity = this.props.clinicalPathwayDetails.authorAndActivity;
			this.props.dispatch(clinicalPathwayDetailsAction.getOneAuthorAndActivityCompleted());
		}
		if (this.props.clinicalPathwayDetails.getOneAuthorAndActivity === "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.getOneAuthorAndActivityCompleted());
			this.cancelClicked();
		}

		if (this.props.clinicalPathwayDetails.editAuthorAndActivityStatus === "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.updateAuthorAndActivityCompleted());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.cancelClicked();
		}

		if (this.state.authorAndActivity.id) {
			return this.renderEdit();
		}

		if (this.props.extClinicalPathway.position) {
			this.state.position = this.props.extClinicalPathway.position;
			this.props.dispatch({ type: eventNames.CLEAR_SIDE_BAR_POSITION, payload: {} });
		}
		return (
			<div>
				<Loader loading isTable={false} />
			</div>
		);
	}
}

EditSubClinincPathway.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	extClinicalPathway: store.extClinicalPathway
}))(EditSubClinincPathway));
