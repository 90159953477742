import eventNames from "../../actions/events";

const initialTranslationsState = {
	componentData: {},
	items: []
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case eventNames.BREADCRUMB_DATA:
			if (!Object.keys(action.payload).length) {
				return {
					componentData: {},
					items: []
				};
			}
			return {
				...state,
				componentData: action.payload
			};

		case eventNames.BREADCRUMB_ITEMS:
			return {
				...state,
				items: action.payload
			};
		default:
			return state;
	}
}
