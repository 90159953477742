import axios from "axios";
import Config from "MOM-config";
import events from "./event";

const getAllHealthPathwayLinkedItemsList = (treatmentId) => ({
  type: events.GET_LINKED_ITEMS_LIST,
  payload: axios.get(
    `${Config.apiRootSecure}/healthPathway/references/${treatmentId}`
  ),
});

const clearAllHealthPathwayLinkedItemsList = () => ({
    type: events.CLEAR_GET_LINKED_ITEMS_LIST,
    payload: {},
  });

  const setCommonSearch = (data) => ({
    type: events.SET_COMMON_SEARCH,
    payload: data,
  });

  const clearCommonSearch = () => ({
    type: events.CLEAR_COMMON_SEARCH,
    payload: {},
  });

  const HPMainTab = (tab) => ({
    type: events.HEALTHPATHWAYS_TAB,
    payload: {tab}
  });
  
  const takeDuplicate = (treatmentId, name, specialityId, familyId, data) => ({
    type: events.TAKE_HEALTH_PATHWAYS_DUPLICATE,
    payload: axios.post(
      `${Config.apiRootSecure}/healthPathway/clone/${treatmentId}/${name}/${specialityId}/${familyId}`, {...data}
    ),
  });
  
  const clearDuplicate = () => ({
    type: events.CLEAR_TAKE_HEALTH_PATHWAYS_DUPLICATE,
    payload: {}
  });

export default {
    getAllHealthPathwayLinkedItemsList,
    clearAllHealthPathwayLinkedItemsList,
    setCommonSearch,
    clearCommonSearch,
    HPMainTab,
    takeDuplicate,
    clearDuplicate
  };