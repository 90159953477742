import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../router/locations";
import Problem from "./problem";
import Steps from "./step";
import SubSteps from "./sub-step";
import Items from "./items";

export default class AdminPersonnelListRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`}
					render={() => <Problem {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}`}
					render={() => <Steps {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`}
					render={() => <SubSteps {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}`}
					render={() => <Items {...this.props} />}
				/>
				<Redirect to={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`} />
			</Switch>
		);
	}
}
