import eventNames from "../../actions/events";

const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isFetchAllSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isFetchAllSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.count,
					sessionType: action.payload.data.data.sessionType,
					isFetchAllSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sessionType: [],
				isFetchAllSessionTypeCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SESSION_TYPE_FETCH:
			return {
				...state,
				sessionType: [],
				isFetchAllSessionTypeCompleted: false
			};
		case `${eventNames.FETCH_ONE_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isFetchOneSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isFetchOneSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedSessionType: action.payload.data.data,
					isFetchOneSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				selectedSessionType: [],
				isFetchOneSessionTypeCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ONE_SESSION_TYPE:
			return {
				...state,
				selectedSessionType: [],
				isFetchOneSessionTypeCompleted: false
			};
		case `${eventNames.UPDATE_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isUpdateSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.UPDATE_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isUpdateSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedSessionType: action.payload.data.data,
					isUpdateSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				updatedSessionType: [],
				isUpdateSessionTypeCompleted: "FULFILLED"
			};
		case eventNames.UPDATE_CLEAR_SESSION_TYPE:
			return {
				...state,
				updatedSessionType: [],
				isUpdateSessionTypeCompleted: false
			};
		case `${eventNames.CREATE_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isCreateSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isCreateSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.CREATE_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					sessionType: action.payload.data.data,
					isCreateSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sessionType: [],
				isCreateSessionTypeCompleted: "FULFILLED"
			};
		case eventNames.CREATE_CLEAR_SESSION_TYPE:
			return {
				...state,
				sessionType: [],
				isCreateSessionTypeCompleted: false
			};
		case `${eventNames.DELETE_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isDeleteSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.DELETE_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isDeleteSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.DELETE_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deletedId: action.payload.data.data,
					isDeleteSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				deletedId: [],
				isDeleteSessionTypeCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SESSION_TYPE_DELETE:
			return {
				...state,
				deletedId: [],
				isDeleteSessionTypeCompleted: false
			};
		case eventNames.CLEAR_FETCH_UNASSIGNED_SESSION_TYPE:
			return {
				...state,
				isFetchUnassignedSessionTypeCompleted: false
			};
		case `${eventNames.FETCH_UNASSIGNED_SESSION_TYPE}_REJECTED`:
			return {
				...state,
				isFetchUnassignedSessionTypeCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_UNASSIGNED_SESSION_TYPE}_PENDING`:
			return {
				...state,
				isFetchUnassignedSessionTypeCompleted: "PENDING"
			};
		case `${eventNames.FETCH_UNASSIGNED_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					unAssignedSession: action.payload.data.data,
					isFetchUnassignedSessionTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				unAssignedSession: [],
				isFetchUnassignedSessionTypeCompleted: "FULFILLED"
			};
		case `${eventNames.ASSING_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return { ...state, assinSessionTypeStatus: "FULFILLED" };
			}
			return { ...state, assinSessionTypeStatus: "REJECTED" };
		case `${eventNames.ASSING_SESSION_TYPE}_REJECTED`:
			return { ...state, assinSessionTypeStatus: "REJECTED" };

		case eventNames.ASSING_SESSION_TYPE_COMPLETED:
			return { ...state, assinSessionTypeStatus: false };

		case `${eventNames.UNASSING_SESSION_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return { ...state, unAssinSessionTypeStatus: "FULFILLED" };
			}
			return { ...state, unAassinSessionTypeStatus: "REJECTED" };
		case `${eventNames.UNASSING_SESSION_TYPE}_REJECTED`:
			return { ...state, unAassinSessionTypeStatus: "REJECTED" };

		case eventNames.UNASSING_SESSION_TYPE_COMPLETED:
			return { ...state, unAassinSessionTypeStatus: false };

		default:
			return state;
	}
}
