import React, { Component } from "react";
import {
	DiagramComponent,
	SymbolPaletteComponent,
	Node,
	Rect,
	UndoRedo, 
	Inject,
	DiagramContextMenu,
	Snapping,
	ConnectorEditing,
	ConnectorConstraints,
	PrintAndExport,
	randomId, cloneObject 
} from "@syncfusion/ej2-react-diagrams";
import Palettes from "./palettes";
import RightSideBarComponents from "./components";
import FloatingAction from "./floatingAction";
import "./styles.css";
import contextMenus from "./contextMenus";
import "../SidebarSettings/settings/languageSettings";
import HorizontalVirtualScroll from "../../plugin/HorizontalVirtualScroll";
let diagramInstance;

const interval = [
	1,
	9,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75,
	0.25,
	9.75
];
const gridlines = {
	lineColor: "#e0e0e0",
	lineIntervals: interval
};

function getPorts() {
	const ports = [
		{ id: "port1", shape: "Circle", offset: { x: 0, y: 0.5 } },
		{ id: "port2", shape: "Circle", offset: { x: 0.5, y: 1 } },
		{ id: "port3", shape: "Circle", offset: { x: 1, y: 0.5 } },
		{ id: "port4", shape: "Circle", offset: { x: 0.5, y: 0 } }
	];
	return ports;
}

// function onContextMenuOpen (args) {

// 	for (const item of args.items) {
// 		switch (item.text) {
// 			case "Delete":
// 				if (!diagramInstance.selectedItems.nodes.length &&
// 					!diagramInstance.selectedItems.connectors.length) {
// 					args.hiddenItems.push(item.text);
// 				}
// 				break;
// 			default:
// 				console.log("Unknown event");
// 				break;
// 		}
// 	}
// }

function oncontextMenuClick(args) {
	switch (args.item.text) {
		case "Delete":
			if (diagramInstance.selectedItems.nodes.length +
				diagramInstance.selectedItems.connectors.length >
				0) {
				diagramInstance.cut();
			}
			break;
		case "Left":
			alignmentSection(args.item.text);
			break;
		case "Right":
			alignmentSection(args.item.text);
			break;
		case "RightToLeft":
			alignmentSection(args.item.text);
			break;
		case "Center":
			alignmentSection(args.item.text);
			break;
		case "BottomToTop":
			alignmentSection(args.item.text);
			break;
		case "Bottom":
			alignmentSection(args.item.text);
			break;
		case "Middle":
			alignmentSection(args.item.text);
			break;
		case "Top":
			alignmentSection(args.item.text);
			break;
		case "Width":
			alignmentSection(args.item.text);
			break;
		case "Height":
			alignmentSection(args.item.text);
			break;
		case "Duplicate":
		//alignmentSection(args.item.text);
		CloneTool(args);
		break;
		default:
			console.log("Unknown event");
			break;
	}
	
}

function CloneTool (args) {
	let newObject;
	if (diagramInstance.selectedItems.nodes.length > 0) {
		newObject = cloneObject(diagramInstance.selectedItems.nodes[0]);
	}
	else {
		newObject = cloneObject(diagramInstance.selectedItems.connectors[0]);
	}
	newObject.id += randomId();
	diagramInstance.paste([newObject]);
	args.source = diagramInstance.nodes[diagramInstance.nodes.length - 1];
	args.sourceWrapper = args.source.wrapper;

}
function alignmentSection(align) {

	// eslint-disable-next-line no-case-declarations
	const selArray = [];
	if (diagramInstance.selectedItems.nodes.length  === 0) {
		diagramInstance.nodes.forEach(element => {
			selArray.push(element);
		});
		//Selects the nodes
		diagramInstance.select(selArray);
	}
	switch (align) {
		case "Left":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes, "Selector");
			break;
		case "Right":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes, "Selector");
			break;
		// case "RightToLeft":
		// 	diagramInstance.align(align, diagramInstance.selectedItems.nodes, "Selector");
		// 	break;
		case "Center":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes);
			break;
		// case "BottomToTop":
		// 	diagramInstance.align(align, diagramInstance.selectedItems.nodes, "Selector");
		// 	break;
		case "Bottom":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes);
			break;
		case "Middle":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes);
			break;
		case "Top":
			diagramInstance.align(align, diagramInstance.selectedItems.nodes);
			break;
		case "Width":
			diagramInstance.sameSize(align, diagramInstance.selectedItems.nodes);
			break;
		case "Height":
			diagramInstance.sameSize(align, diagramInstance.selectedItems.nodes);
			break;
		default:
			console.log("Unknown event");
			break;
	}

	diagramInstance.dataBind();
}

class Diagram extends Component {
	render() {
		return (
			<div className="control-pane">
				<div className="control-section">
					<div style={{ width: "100%" }}>
						<div className="sb-mobile-palette-bar">
							<div id="palette-icon" style={{ float: "right", role: "button" }} className="e-ddb-icons1 e-toggle-palette" />
						</div>

						<FloatingAction 
							backToDetails={this.props.backToDetails}  
							onSave={this.props.onSave} 
							onZoomSliderChange={this.props.onZoomSliderChange}
							actionDisabled={this.props.actionDisabled}
							hideShowNodeSideBar={this.props.hideShowNodeSideBar}
							sideBarSettings={this.props.sideBarSettings} 
							sideBarStatus={this.props.sideBarStatus}/>
						<RightSideBarComponents >
							<div mid="palette-space" className="sb-mobile-palette">	
								<SymbolPaletteComponent
									id="symbolpalette"
									expandMode="Multiple"
									palettes={Palettes}
									width="100%"
									height={this.props.height.symbolPaletteHeight}
									symbolHeight={60}
									symbolWidth={60}
									getNodeDefaults={(symbol) => {
										if (
											symbol.id === "Terminator"
											|| symbol.id === "Process"
											|| symbol.id === "Delay"
										) {
											symbol.width = 80;
											symbol.height = 40;
										} else if (
											symbol.id === "Decision"
											|| symbol.id === "Document"
											|| symbol.id === "PreDefinedProcess"
											|| symbol.id === "PaperTap"
											|| symbol.id === "DirectData"
											|| symbol.id === "MultiDocument"
											|| symbol.id === "Data"
										) {
											symbol.width = 50;
											symbol.height = 40;
										} else {
											symbol.width = 50;
											symbol.height = 50;
										}
										// symbol.style.strokeColor = '#757575'
									}}
									symbolMargin={{
										left: 15, right: 15, top: 15, bottom: 15
									}}
									getSymbolInfo={() => ({ fit: true })}
								/>
							</div>
						</RightSideBarComponents>
						
						<div
							id="diagram-space"
							className="sb-mobile-diagram"
						>
							<div ref={this.props.containerRef}>
								<DiagramComponent
									id="diagram"
									ref={(diagram) => {this.props.setDiagramInstance(diagram); diagramInstance = diagram;}}
									width={this.props.width}
									height={this.props.height.diagramHeight}
									pageSettings={{ multiplePage: true, showPageBreaks: true }}
									scrollSettings={{
										canAutoScroll: true,
										scrollLimit: "Infinity",
										scrollableArea: new Rect(0, 0, 300, 300)
									}}
									snapSettings={{
										horizontalGridlines: gridlines,
										verticalGridlines: gridlines,
										//constraints: SnapConstraints.SnapToObject,
										// snapObjectDistance: 10,
										// // Snap Angle for object
										// snapAngle: 10,
										// // Set the Snapline color
										snapLineColor: "red"
									}}
									nodes={this.props.nodes}
									connectors={this.props.connectors} // Sets the default values of a node
									getNodeDefaults={(node) => {
										const obj = {};
										if (obj.width === undefined) {
											obj.width = 145;
										} else {
											const ratio = 100 / obj.width;
											obj.width = 100;
											obj.height *= ratio;
										}
										obj.style = { fill: "#357BD2", strokeColor: "white" };
										obj.annotations = [
											{ style: { color: "black", fill: "transparent" }, hyperlink: { link: "", color: "black" } }
										];
										// Set ports
										obj.ports = getPorts(node);
										return obj;
									}} // Sets the default values of a connector
									getConnectorDefaults={(obj) => {
										obj.constraints = ConnectorConstraints.Default | ConnectorConstraints.DragSegmentThumb;
										if (obj.id.indexOf("connector") !== -1) {
											obj.type = "Orthogonal";
											obj.targetDecorator = {
												shape: "Arrow",
												width: 10,
												height: 10
											};
										}
									}}
									// Sets the Node style for DragEnter element.
									dragEnter={(args) => {
										const obj = args.element;
										if (obj instanceof Node) {
											const oWidth = obj.width;
											const oHeight = obj.height;
											const ratio = 100 / obj.width;
											obj.width = 100;
											obj.height *= ratio;
											obj.offsetX += (obj.width - oWidth) / 2;
											obj.offsetY += (obj.height - oHeight) / 2;
										}
									}}

									rulerSettings={
										{
											showRulers: true
										}
									}
									contextMenuSettings={{ show: true, items: contextMenus, showCustomMenuOnly: false }}
									// contextMenuOpen={(args) => { onContextMenuOpen(args); }} // removed due to some context menu issue
									contextMenuClick={(args) => { oncontextMenuClick(args); }}
								>
									<Inject services={[UndoRedo, DiagramContextMenu, Snapping, ConnectorEditing, PrintAndExport]} />
								</DiagramComponent>

							</div>
						</div>
					</div>
				</div>
				<HorizontalVirtualScroll refItem={this.props.containerRef} onZoomSliderChange={this.props.onZoomSliderChange}/>
			</div>
		);
	}
}

export default Diagram;
