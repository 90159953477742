import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import adminPersonnelActions from "../../../../../../../actions/admin-personnel";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class PersonnelTemplatesDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			offset: 0,
			numResults: 100,
			search: "",
			typeActivity: "",
			isListLoading: true
		};
	}

	UNSAFE_componentWillMount() {
		this.state.list = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.typeActivity = this.props.typeActivity;
		this.fetchList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.list) {
			this.state.list.map((data) => {
				prevState.options.push({ value: data.id, label: data.name });
			});
		}
		if (this.props.staff) {
			if (prevState.options.indexOf(this.props.staff.value) === -1) {
				prevState.options.push(this.props.staff);
			}
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchList() {
		let queryString = `sort=name&desc=0&offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += this.state.typeActivity ? `&typeActivity=${this.state.typeActivity}` : "";
		queryString += "&status=APPROVED";
		queryString += this.state.search ? `&name=${this.state.search}` : "";
		if (this.props.minimal) {
			this.props.dispatch(adminPersonnelActions.fetchAllMinimalPersonnelTemplates(this.state.typeActivity, queryString));
		} else {
			this.props.dispatch(adminPersonnelActions.fetchAllApprovedPersonnelTemplates(queryString));
		}
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchList();
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchList();
	}

	render() {
		if (this.state.typeActivity !== this.props.typeActivity) {
			this.state.typeActivity = this.props.typeActivity;
			this.state.offset = 0;
			this.state.list = [];
			this.state.isListLoading = true;
			this.fetchList();
		}
		if (this.props.adminPersonnel.fetchAllApprovedPersonnelTemplateCompleted === "FULFILLED") {
			this.state.isListLoading = false;
			this.props.dispatch(adminPersonnelActions.clearFetchAllApprovedPersonnelTemplates());
			this.state.list = unionWith(this.state.list, this.props.adminPersonnel.personnelTemplateList.data, isEqual);
			this.renderOptions();
		}
		if (this.props.adminPersonnel.fetchAllMinimalTemplateCompleted === "FULFILLED") {
			this.state.isListLoading = false;
			this.props.dispatch(adminPersonnelActions.clearFetchAllMinimalPersonnelTemplates());
			this.state.list = unionWith(this.state.list, this.props.adminPersonnel.minimalTemplateList, isEqual);
			this.renderOptions();
		}

		return (
			<Select
				styles={customStyles}
				className={this.props.className ? this.props.className : "w_100 di"}
				onChange={this.props.templateChanged}
				value={this.props.data}
				isSearchable
				isClearable
				isDisabled={this.props.disabled}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Personnel Template", utils.getLanguage())}
			/>
		);
	}
}

PersonnelTemplatesDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminPersonnel: store.adminPersonnel
}))(PersonnelTemplatesDropdown));
