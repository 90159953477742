import React, { useState, useEffect } from "react";
import I18 from "i18";

const Summary = (props) => {
	const [customData, setCustomData] = useState({});

	useEffect( () => {
		setCustomData(props.diagramData);
	}, [props.diagramData]);

	return <div>
		<div className="popup_modal_content popup_node">
			<div className="card-container">
				<div className="d-flex margin-top-10 popup_modal_content_input">
					<div className="w_100 edit-task-input-field">
						<strong className="margin-top-5" ><I18 tkey='Title' />: </strong>
						<span >{customData.title}</span>
					</div>
				</div>
				<div className="d-flex margin-top-10 popup_modal_content_input">
					<div className="w_100 edit-task-input-field">
						<strong className="margin-top-5" ><I18 tkey='Duration' />: </strong>
						<span >{customData.duration}</span>
					</div>
				</div>
			</div>
		</div>
	</div>;
};
export default Summary;