import { connect } from "react-redux";
import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import userActions from "../../../../../../../actions/user";
import eventNames from "../../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import Loader from "../../../../../../../plug/loader";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import NumericInput from "../../../../../../../plug/numeric-input";
import { Radio, Select, Input, Checkbox } from "antd";
import UserRoles from "../../../../../../../utils/utils";
import screenId from "../../../../../../../router/screenIdList";
import SVG from "../../../../../../../plug/svg";
import { Modal, Tooltip } from "antd";
import HospitalSelect from "../../activities/specialities/selectHospital";
import SiteDropDown from "../../../clinical-pathway/createClinicalPathWay/site_dropdown";
import { Communities } from "../../../uiComponents";
import Roles from "../rolePermissions/roles/components/roles";
const PasswordValidation = React.lazy(() => import("mom-ui-commons/build/components/user/passwordValidation"));


const Option = Select.Option;
const RadioGroup = Radio.Group;

class user extends Component {
	constructor(props) {
		super(props);
		this.state = {
			user: [],
			bloctype: [],
			changePasswordVisible: false,
			showPassword: {
				NEW: false,
				R_NEW: false
			},
			isEmail: false,
			selectedCommunity: [],
			invalidCommunity: false,
			userRole: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: screenId.userEdit });
		this.props.dispatch(userActions.fetchOneuser(this.props.match.params.id));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
	}

	onChanged(stateValue, value) {
		const prevState = this.state;
		prevState["invalid" + stateValue] = false;
		
		if (stateValue === "contactBy" || stateValue === "email" || stateValue === "password") {
			prevState.user[stateValue] = value.target.value;
		} else if (stateValue == "role") {
			prevState.user[stateValue] = value.label;
			prevState.userRole = value;
		} else {
			prevState.user[stateValue] = value;
		}

		if (stateValue == "role")
		{
			prevState.user.selectedHospital = null;
			prevState.user.site = null;
			prevState.selectedCommunity = null;
		}

		this.setState(prevState);
		this.cleanValidations();
	}

	onChangePhoneNumber(stateValue, value) {
		const prevState = this.state;
		prevState.invalidphone = false;
		prevState.user["phone"] = value;
		this.setState(prevState);
		this.cleanValidations();
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				name: this.state.user.name.trim(),
				contactBy: this.state.user.contactBy,
				email: this.state.user.email,
				userRole: this.state.userRole ? this.state.userRole.label : "",
				userRoleId: this.state.userRole ? this.state.userRole.value : "",
				roleType: this.state.userRole ? this.state.userRole.roleType : "",
				hospitalId: this.state.user.selectedHospital && this.state.user.selectedHospital.value ? this.state.user.selectedHospital.value : "",
				siteId: this.state.user.site && this.state.user.site.value !== "ALL" ? this.state.user.site.value : "",
				phone: this.state.user.phone,
				communityIds: this.createPayloadData(this.state.selectedCommunity),
				active: this.state.user.active
			};
			this.props.dispatch(userActions.updateUser(this.state.user.id, payload));
		}
	}

	onCommunityChanged = e => {
		const prevState = this.state;
		prevState.selectedCommunity = e;
		prevState.invalidCommunity = false;//If needed
		this.setState(prevState);
		this.cleanValidations();
	};
	//Use this function for fetch Ids
	createPayloadData(data) {
		this.cleanValidations();
		const dataList = [];
		if (data) {
			data.map(data => { dataList.push(data.value); });
		}
		return dataList;
	}
	//This is for converting server data to option list based on the id and name
	selectOptions(arrayData) {

		this.cleanValidations();
		const options = [];
		if (arrayData && arrayData.length > 0) {
			arrayData.map((data) => {
				if (data.id)
				{options.push({ value: data.id, label: data.name });}
			});
		}
		return options;
	}

	validateFields() {
		const prevState = this.state;
		let valid = true;
		if (!utils.checkNameRegex(this.state.user.name) || !this.state.user.name || !this.state.user.name.trim()) {
			prevState.invalidname = true;
			valid = false;
		}
		if (!this.state.user.contactBy) {
			prevState.invalidcontactBy = true;
			valid = false;
		}
		if ((!utils.regexEmail.test(this.state.user.email))) {
			prevState.invalidemail = true;
			valid = false;
		}
		if (!utils.regexPhone.test(this.state.user.phone)) {
			prevState.invalidphone = true;
			valid = false;
		}
		if (!this.state.userRole.roleType) {
			prevState.invalidrole = true;
			valid = false;
		}
		if (!this.state.user.selectedHospital && this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER") {
			prevState.invalidHospital = true;
			valid = false;
		}
		if (!this.state.user.site && this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER") {
			prevState.invalidSite = true;
			valid = false;
		}

		if ((!this.state.selectedCommunity || this.state.selectedCommunity.length == 0) && this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER") {
			this.state.invalidCommunity = true,
			valid = false;
		}

		if (!valid) {
			this.setState(prevState);
		}
		return valid;
	}

	renderStatus() {
		return UserRoles.userDefaultRoles.map(data => {
			return <Option key={data.value} value={data.value}>{i18Get(data.name, utils.getLanguage())}</Option>;
		});
	}

	onChangePasswordClick() {
		this.resetInvalidField();
		const state = this.state;
		state.changePasswordVisible = true;
		state.newPassword = "";
		state.confirmNewPassword = "";
		state.isEmail = false;
		this.setState(state);
	}

	passwordChange(field) {
		return (e) => {
			const prevState = this.state;
			const inputString = e.target.value;
			prevState[field] = inputString.replace(utils.regexPassValEcp, "");
			prevState.invalidPassNotEqual = false;
			switch (field) {
				case "newPassword": prevState.invalidNewPass = false;
					break;
				case "confirmNewPassword": prevState.invalidConfirmPass = false;
					break;
			}
			this.setState(prevState);
		};
	}

	hideModalChangePassword() {
		const state = this.state;
		state.changePasswordVisible = false;
		this.setState(state);
	}

	resetInvalidField() {
		const prevState = this.state;
		prevState.invalidNewPass = false;
		prevState.invalidNewPassValid = false;
		prevState.invalidConfirmPass = false;
		prevState.invalidPassNotEqual = false;
		this.setState(prevState);
	}

	changePassword() {
		this.resetInvalidField();
		if (this.validatePassword()) {
			const payload = {
				id: this.state.user.id,
				newPassword: this.state.newPassword,
				isEmail: this.state.isEmail
			};
			this.props.dispatch(userActions.userPasswordUpdate(payload));
		}
		this.cleanValidations();
	}

	validatePassword() {
		let valid = true;
		const prevState = this.state;
		if (!prevState.newPassword || !utils.regexPassVal.test(prevState.newPassword)) {
			prevState.invalidNewPass = true;
			valid = false;
		}
		if (!prevState.confirmNewPassword || !utils.regexPassVal.test(prevState.confirmNewPassword)) {
			prevState.invalidConfirmPass = true;
			valid = false;
		} else if (prevState.newPassword !== prevState.confirmNewPassword) {
			prevState.invalidPassNotEqual = true;
			valid = false;
		}
		this.setState(prevState);
		return valid;
	}

	cleanValidations() {
		const prevState = this.state;
		prevState.invalidname = false;
		prevState.invalidemail = false;
		prevState.invalidcontactBy = false;
		prevState.invalidphone = false;
		prevState.invalidpassword = false;
		prevState.invalidstatus = false;
		prevState.invalidHospital = false;
		prevState.invalidCommunity = false;
		prevState.invalidSite = false;
		this.setState(this.state);
	}

	toggleShowPassword(label) {
		return () => {
			const prevState = this.state;
			prevState.showPassword[label] = !this.state.showPassword[label];
			this.setState(prevState);
		};
	}

	onHospitalChanged(e) {
		const prevState = this.state;
		prevState.user["selectedHospital"] = e;
		prevState.user["site"] = null;
		prevState.selectedCommunity = null;
		prevState.invalidHospital = false;
		this.setState(prevState);
		this.cleanValidations();
	}

	siteChanged(e) {
		const prevState = this.state;
		if (this.state.user.selectedHospital.value !== "MASTER") {
			prevState.selectedCommunity = null;
		}
		prevState.user["site"] = e;
		this.setState(prevState);
		this.cleanValidations();
	}

	render() {
		if (this.props.user.isFetchOneusersCompleted === "FULFILLED") {
			this.state.user = this.props.user.selecteduser;

			if (this.state.user.roleType ===	"PATHWAY_USER" && !this.state.user.hospitalId && !this.state.user.siteId) {

				this.state.user.selectedHospital = { value: "MASTER", label: "MOM" };
				this.state.user.site = { value: "MASTER", label: "MOM" };
			}
			else {
				this.state.user.selectedHospital = this.state.user.hospitalId ? { value: this.state.user.hospitalId, label: this.state.user.hospitalName } : null;
			
				this.state.user.site = this.state.user.siteId ? { value: this.state.user.siteId ? this.state.user.siteId : "ALL", label: this.state.user.siteName ? this.state.user.siteName : "All" } : null;
			}

			this.state.selectedCommunity = this.selectOptions(this.state.user.communities);
			this.state.userRole = ({ label: this.state.user.userRole, value: (this.state.user.userRoleId) ? this.state.user.userRoleId : "", roleType: (this.state.user.roleType) ? this.state.user.roleType : "" });
			this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.user.id, name: this.state.user.name } });
			this.props.dispatch(userActions.clearFetchOneuser());
		}
		if (this.props.user.isFetchOneusersCompleted === "REJECTED") {
			this.props.dispatch(userActions.clearFetchOneuser());
			this.props.history.push(locations.userManagement_users);
		}
		if (this.props.user.isUpdateusersCompleted === "FULFILLED") {
			this.state.user = this.props.user.updatedUser;
			this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.user.id, name: this.state.user.name } });
			this.props.dispatch(userActions.clearUpdateuser());
			this.props.history.push(locations.userManagement_users);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.user.isAdminChangePasswordUserCompleted === "FULFILLED") {
			this.state.changePasswordVisible = false;
			this.props.dispatch(userActions.clearUserPasswordUpdate());
		}
		if (this.state.user.id) {
			return (
				<div>
					{this.edituser()}
				</div>
			);
		}
		return (<Loader loading={true} isTable={false} />);
	}

	setEmailTrueOrFalse = ({ target: { checked } }) => {
		this.setState({ isEmail: checked });
	};

	activationChanged(e) {
		const prevState = this.state;
		prevState.user["active"] = e.target.checked;
		this.setState(prevState);
	}

	edituser() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-offset-6 col-md-6 col-sm-offset-6 col-sm-6">
							<div className="w_100 di  position_relative text_align_right">
								<label className="margin-right-15"><I18 tkey="Contact by" /></label>
								<RadioGroup onChange={this.onChanged.bind(this, "contactBy")} value={this.state.user.contactBy}>
									<Radio value={"SMS"}><I18 tkey='SMS' /></Radio>
									<Radio value={"EMAIL"}><I18 tkey='EMAIL' /></Radio>
								</RadioGroup>
								{this.state.invalidcontactBy && <span className='invalid_right fixed_label'><I18 tkey="Select contact by" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18">
								<label><I18 tkey="Code" /></label>
								<input className="w_100 di form-control" value={this.state.user.id} readOnly />
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.user.name}
									inputChanged={this.onChanged.bind(this, "name")}
									maxLength={60}
								/>
								{this.state.invalidname && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-6  col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Contact" /></label>
								<Input
									value={this.state.user.email}
									onChange={this.onChanged.bind(this, "email")}
									maxLength={60}
									type='email'
								/>
								{this.state.invalidemail && <span className='invalid_right fixed_label'><I18 tkey="Enter valid emai" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Phone" /></label>
								<NumericInput
									value={this.state.user.phone}
									getNumericValue={this.onChangePhoneNumber.bind(this)}
									maxLength={30}
									integerOnly={true}
								/>
								{this.state.invalidphone && <span className='invalid_right fixed_label'><I18 tkey="Enter valid phone" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Status" /></label>
								{/* <Select
									allowClear={true}
									showSearch
									value={this.state.user.role}
									className="w_100 di"
									defaultActiveFirstOption={false}
									onChange={this.onChanged.bind(this, 'role')}
									placeholder={i18Get('Select status', utils.getLanguage())}
									notFoundContent={i18Get('Not Found', utils.getLanguage())}
									optionFilterProp="children"
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
									{this.renderStatus()}
								</Select> */}
								<Roles 
									mode = 'single'
									value={this.state.userRole}
									onChange={this.onChanged.bind(this, "role")}
								/>
								{this.state.invalidrole && <span className='invalid_right fixed_label'><I18 tkey="Select valid role" /></span>}
							</div>
							<div className="w_100 di margin-bottom-18 position_relative">
								<Checkbox checked={ this.state.user.active } onChange={this.activationChanged.bind(this)}><I18 tkey="Active" /></Checkbox>
							</div>
						</div>

						{this.state.userRole && this.state.userRole.roleType === "PATHWAY_USER" &&
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><span className='color_red font_size_14px'>*</span><I18 tkey="Hospital" /></label>
									<HospitalSelect
										data={this.state.user.selectedHospital}
										hospitalChanged={this.onHospitalChanged.bind(this)}
										isClpList = {true} />
									{this.state.invalidHospital && <span className='invalid_right fixed_label'><I18 tkey="Select hospital" /></span>}
								</div>
								{this.state.user.selectedHospital &&
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Site" /></label>
										<SiteDropDown
											autoSetSingleOne = {true}
											data={this.state.user.site}
											siteChanged={this.siteChanged.bind(this)}
											isUser={true}
											hospital={this.state.user.selectedHospital.value} />
										{this.state.invalidSite && <span className='invalid_right fixed_label'><I18 tkey="Select site" /></span>}
									</div>
								}
								{this.state.user.site &&
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><span className='color_red font_size_14px'>*</span><I18 tkey="Communities" /></label>
										<Communities
											autoSetSingleOne = {true}
											value={this.state.selectedCommunity}
											onChange={this.onCommunityChanged.bind(this)}
											filter={{
												hospitalId: (this.state.user.selectedHospital) ? this.state.user.selectedHospital.value : "",
												siteId: this.state.user.site.value,
												filterBySite: 1
											}}
										/>
										{this.state.invalidCommunity && <span className='invalid_right fixed_label'><I18 tkey="Select Community" /></span>}
									</div>
								}
							</div>}
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<button onClick={this.onChangePasswordClick.bind(this)} type="button" className="change_password_button float_left border_radius20 margin-right-10" ><I18 tkey="Change Password" /></button>
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
				<Modal
					title={i18Get("Change Password", utils.getLanguage())}
					className="change_pass_popup re_assign_modal"
					visible={this.state.changePasswordVisible}
					onCancel={this.hideModalChangePassword.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.changePassword.bind(this)}>
					<div className="mom_form_popup padding-0">
						<div className="row">
							<div className="col-xs-12 col-sm-offset-1 col-sm-10">
								<div className="group">
									<label className="top_10px" htmlFor='newPassword'><I18 tkey="New Password" /></label>
									<div className="profile_input_eye">
										<input type={this.state.showPassword["NEW"] ? "text" : "password"} id="newPassword" placeholder="" name="newPassword" value={this.state.newPassword} onChange={this.passwordChange("newPassword").bind(this)} maxLength="30" />
										<a onClick={this.toggleShowPassword("NEW").bind(this)}>
											{!this.state.showPassword["NEW"] ?
												<SVG src='/images/eye-slash.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
												: <SVG src='/images/eye.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
										</a>
									</div>
									{this.state.invalidNewPass && <span className="invalid"><I18 tkey="Enter valid password" /></span>}

									<Suspense fallback={<div></div>}><PasswordValidation value={this.state.newPassword} /></Suspense>
									{/* <Tooltip placement="topRight" 
										title={ () => <span>
											<div>{i18Get("Password should contain atleast 8 characters, 1 numerc, 1 Alphabetic, 1 Upper Case, 1 lower Case, 1 special character (!@#$%^&*)", utils.getLanguage())}</div>
										</span>}><InfoCircleOutlined  className="change-password-info" type="info-circle" theme="outlined" /></Tooltip> */}
								</div>
							</div>
							<div className="col-xs-12 col-sm-offset-1 col-sm-10">
								<div className="group">
									<label className="top_10px" htmlFor='confirmNewPassword'><I18 tkey="Confirm New Password" /></label>
									<div className="profile_input_eye">
										<input type={this.state.showPassword["R_NEW"] ? "text" : "password"} id="confirmNewPassword" placeholder="" name="confirmNewPassword" value={this.state.confirmNewPassword} onChange={this.passwordChange("confirmNewPassword").bind(this)} maxLength="30" />
										<a onClick={this.toggleShowPassword("R_NEW").bind(this)}>
											{!this.state.showPassword["R_NEW"] ?
												<SVG src='/images/eye-slash.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
												: <SVG src='/images/eye.svg' fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
										</a>
									</div>
									{this.state.invalidConfirmPass && <span className="invalid"><I18 tkey="Enter valid password" /></span>}
									{this.state.invalidPassNotEqual && <span className="invalid"><I18 tkey="New passwords do not match" /></span>}
								</div>
							</div>
							<div className="col-xs-12 col-sm-offset-1 col-sm-10">
								{this.state.isEmail}
								<p><Checkbox onChange={this.setEmailTrueOrFalse} checked={this.state.isEmail} /><label className="margin-left-10" style={{ color: "#3740f2" }}><I18 tkey="Email the password to" /> {this.state.user.email}</label> </p>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

user.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	blocType: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	user: store.administartionUser,
	blocType: store.blocType,
	actions: store.actions,
	settings: store.settings,
	translations: store.translations
}))(user));