import React from "react";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";

export default function getCols(onClickProblem) {
	const tableArray = ([
		{
			Header: i18Get("CLINICAL PATHWAY STAGES", utils.getLanguage()),
			id: "stepSectionType",
			accessor: (d) => d.stepSectionType,
			resizable: true,
			sortable: false,
			Cell: (row) => (
				row.value ? (row.value == "CLINICAL_PATHWAY" ? i18Get("HOSPITALIZATION", utils.getLanguage()) : i18Get(row.value.replace(/_/g, " "), utils.getLanguage())) : ""
			)
		},
		{
			Header: i18Get("TYPE", utils.getLanguage()),
			id: "type",
			accessor: (d) => d.type,
			resizable: true,
			sortable: false,
			Cell: (row) => (
				row.value ? i18Get(row.value, utils.getLanguage()) : ""
			)
		},
		{
			Header: i18Get("PROBLEM", utils.getLanguage()),
			id: "problem",
			accessor: (d) => d,
			resizable: true,
			sortable: false,
			Cell: (row) => (
				row.value && row.value.problem ? <a className="text_underline" onClick={() => { onClickProblem(row.value); }}>{row.value.problem}</a> : ""
			)
		}
	]);
	return tableArray;
}
