import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import clinicalPathwayAction from "../../../../../../../../actions/clinical-pathway";
import utils from "../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../constants";
import Config from "../../../../../../../../MOM-config";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import "../../../../../../../../plug/reactTable/react-table.css";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import { Checkbox } from "antd";
import isPermissionGranted from "../../../../../../../../utils/permission";

class Anaesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modelVisible: false,
			totalPages: 1,
			list: [],
			sorted: [],
			costDetails: {},
			checkList: [],
			treatmentId: this.props.treatmentId
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.copySelectedToMaster) {
			this.fetchDataForSelectedCopy();
		}
		else {
			this.onFetchData();
		}
	}
	
	UNSAFE_componentWillUpdate () {
		if (this.state.treatmentId !== this.props.treatmentId)
		{ 
			this.setState({ treatmentId: this.props.treatmentId }, this.onFetchData());
		}
	}

	getCols() {
		const tableArray = ([
			{
				Header: "",
				id: "linkStatus",
				width: 50,
				accessor: d => d,
				resizable: false,
				sortable: false,
				show: this.props.copySelectedToMaster,
				Cell: row => (
					<div>
						{this.renderCheckbox(row.value)}
					</div>
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "subTreatmentName",
				accessor: d => d,
				resizable: true,
				sortable: false,
				Cell: row => (
					this.props.user.loginData && this.props.user.loginData.scope == 64 &&  this.props.user.loginData.roleType === "PATHWAY_USER" ?
						row.value && row.value ? row.value.subTreatmentName : ""
						:                     
						<a onClick={() => { this.moveToDetails(row.value); }}>
							{
								row.value && row.value ? row.value.subTreatmentName : ""
							}
						</a> 
				)
			},
			{
				Header: i18Get("DESCRIPTION", utils.getLanguage()),
				id: "description",
				accessor: d => d.description,
				resizable: true,
				sortable: false,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			}
		]);
		return tableArray;
	}

	onCellCheckBoxChange = (e, data) => {
		this.checkListChanged(data.id);
	};

	renderCheckbox(value) {
		return (
			<div className="check_box_container_table_sub_head">
				<Checkbox
					checked={this.state.checkList.indexOf(value.id) !== -1}
					onChange={(e) => this.onCellCheckBoxChange(e, value) }/>
			</div>);
	}

	onFetchData() {
		this.props.dispatch(clinicalPathwayAction.getClpSubTreatment(this.props.type, (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), this.state.sort, this.state.desc, this.props.treatmentId));
	}

	// fetchDataForSelectedCopy() {
	// 	this.props.dispatch(clinicalPathwayAction.getClpSubTreatmentForSelectedCopy(this.props.type, (this.props.treatmentId ? this.props.treatmentId :this.props.match.params.id), this.state.sort, this.state.desc, this.props.treatmentId));
	// }

	moveToDetails = (value) => {
		if (value.status === statusList.STATUS.values.APPROVED)
		{window.open(`${Config.siteUrl}/anaesthesia/mom/${value.subTreatmentId}`, "_blank");}
	};

	init() {
		const contentBlock = htmlToDraft(this.state.description ? this.state.description : `<p></p><p style="text-align:center;">${i18Get("NO GENERIC DESCRIPTION FOUND", utils.getLanguage())}</p>`);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.description = EditorState.createWithContent(contentState);
		}
	}

	onHeaderCheckBoxChange = ({ target: { checked } }) => {
		if (checked) {
			const itemNotInCheckList = this.state.list.filter(row => !this.state.checkList.includes(row.id));
			itemNotInCheckList.forEach(row => this.checkListChanged(row.id));
		} else {
			this.clearCheckList();
		}
		this.setState({ ...this.state, checkedAll: checked });
	};


	checkListChanged(id) {
		const index = this.state.checkList.indexOf(id);
		const prevState = this.state;
		prevState.checkedAll = true;
		if (index === -1) {
			prevState.checkList.push(id);
		} else {
			prevState.checkList.splice(index, 1);
		}
		this.setState(prevState);
		this.props.getSelectedComponents(prevState.checkList, "Anaesthesia");
	}

	clearCheckList = () => {
		const prevState = this.state;
		prevState.checkList = [];
		this.setState(prevState);
	};

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.clinicalPathway.isReadOnly, method);
	};
	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathwaySubTreatment === "FULFILLED") {
			if (this.props.clinicalPathway.meta == this.props.type)
			{
				this.state.list = this.props.clinicalPathway.clinicalPathwaySubTreatment.subTreatments;
				this.state.description = this.props.clinicalPathway.clinicalPathwaySubTreatment && this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription ? this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription : "";
				this.state.loading = false;
				this.init();
				this.props.dispatch(clinicalPathwayAction.clearGetClpSubTreatment());
			}
		}

		if (this.props.clinicalPathway.fetchAllClpSubTreatmentForSelectedCopy === "FULFILLED") {
			if (this.props.clinicalPathway.meta == this.props.type)
			{
				this.state.list = this.props.clinicalPathway.clinicalPathwaySubTreatment.subTreatments;
				this.state.description = this.props.clinicalPathway.clinicalPathwaySubTreatment && this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription ? this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription : "";
				this.state.loading = false;
				this.init();
				this.props.dispatch(clinicalPathwayAction.clearGetClpSubTreatmentForSelectedCopy());
			}
		}

		return (
			<div className="table_no_data_edit">
				<div className="table_heading d-flex align-items-center justify-content-between">
					<div className="check_box_container_table"></div>
					<div className='margin_auto'><I18 tkey='ANAESTHESIA' /></div>
					{this.isPermissionGranted("EDIT") ?
						<button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "Anaesthesia")}><I18 tkey="EDIT" /></button>
						: <span className="text_align_right edit_button_clp"></span> }   
				</div>
				<div className="w_100 di margin-bottom-18 editor_no_edit">
					<div className="text_align_center margin-bottom-5 margin-top-5 bold_dec"><I18 tkey='GENERIC DESCRIPTION' /></div>
					<Editor
						readonly={true}
						toolbarHidden={true}
						wrapperClassName="wrapper-class"
						editorClassName="editor-class no_scroll_pathway"
						toolbarClassName="toolbar-class"
						editorState={this.state.description}
					/>
				</div>
				<ReactTable
					data={this.state.list}
					loading={this.state.loading}
					multiSort={false}
					pageSize={this.state.list.length ? this.state.list.length : 2}
					showPagination={false}
					noDataText={i18Get("NO ANAESTHESIA FOUND", utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange={(newSorted) => {
						this.setState({ sorted: newSorted });
					}}
					sorted={this.state.sorted}
				/>
			</div>
		);
	}

}

Anaesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathway: store.clinicalPathway,
	intervention: store.intervention,
	translations: store.translations,
	user: store.user,
	permissions: store.permissions
}))(Anaesthesia));