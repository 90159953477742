import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const forgotPassword = (payload) => ({
	type: events.FORGOT_PASSWORD,
	payload: axios.post(`${Config.apiRoot}/forgotPassword/`, { ...payload })
});

const clearForgotPassword = () => ({
	type: events.CLEAR_FORGOT_PASSWORD,
	payload: {}
});

export default {
	forgotPassword,
	clearForgotPassword
};
