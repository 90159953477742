import React from "react";
import { i18Get } from "../../i18";
import utils from "../../utils/utils";
const reactStringReplace = require("./reactStringReplace");

class I18Plus extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const key = this.props.tkey;
		const linkWord = this.props.lword;
		const onClick = this.props.onClick;
		const className = this.props.className;
		const position = this.props.rindex ? this.props.rindex : 1;
		const tString = i18Get(key, utils.getLanguage());
		const tword = i18Get(linkWord, utils.getLanguage());
		let count = 0;
		return (
			reactStringReplace(tString, tword, (match) => {
				count++;
				if (position == count) {
					return <a onClick={() => { onClick(); }} className={className}>{match}</a>;
				} else {
					return match;
				}
			}
			));
	}
}
export default I18Plus;
