import eventNames from "../../actions/events";

const initialState = {
	dropDownData: ""
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.ELEMENTS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dropDownData: action.payload.data.data.element,
					isFetchAllDropDownDataCompleted: "FULFILLED"

				};
			}
			return {
				...state,
				sites: [],
				isFetchAllDropDownDataCompleted: "FULFILLED"

			};

		case eventNames.CLEAR_ELEMENTS_GET_ALL:
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: false
			};

		case `${eventNames.ELEMENTS_ADD}_REJECTED`:
			return {
				...state,
				iselementAddCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_ADD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					addElementsData: action.payload.data.data,
					iselementAddCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				iselementAddCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ELEMENTS_ADD:
			return {
				...state,
				elementAddData: [],
				iselementAddCompleted: false
			};

		case `${eventNames.ELEMENTS_GET}_REJECTED`:
			return {
				...state,
				isElementGetCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isElementGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementGetCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_ELEMENTS_GET:
			return {
				...state,
				isElementGetCompleted: false,
				isElementGetOneCompleted: false
			};
		case `${eventNames.ELEMENTS_GET_ONE}_REJECTED`:
			return {
				...state,
				isElementGetOneCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsDataOne: action.payload.data.data,
					isElementGetOneCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementGetOneCompleted: "FULFILLED"
			};
		case `${eventNames.ELEMENTS_DELETE}_REJECTED`:
			return {
				...state,
				isElementDeleteCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_DELETE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsDeletedData: action.payload.data.data,
					isElementDeleteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementDeleteCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ELEMENTS_DELETE:
			return {
				...state,
				elementsDeletedData: [],
				isElementDeleteCompleted: false
			};
		case `${eventNames.ELEMENTS_UPDATE}_REJECTED`:
			return {
				...state,
				isElementUpdateCompleted: "REJECTED"
			};

		case `${eventNames.ELEMENTS_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsUpdateData: action.payload.data.data,
					isElementUpdateCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementUpdateCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ELEMENTS_UPDATE:
			return {
				...state,
				elementsUpdateData: [],
				isElementUpdateCompleted: false
			};

		case `${eventNames.GET_ALL_EXISTING_CONSUMABLE}_FULFILLED`:
			return { ...state, getExistingConsumbaleStatus: "FULFILLED", existingConsumbales: action.payload.data.data.element };
		case `${eventNames.GET_ALL_EXISTING_CONSUMABLE}_REJECTED`:
			return { ...state, getExistingConsumbaleStatus: "REJECTED", existingConsumbales: action.payload.data.data.element };
		case eventNames.CLEAR_GET_ALL_EXISTING_CONSUMABLE:
			return { ...state, getExistingConsumbaleStatus: false };

		case `${eventNames.GET_ALL_NEW_CONSUMABLE}_FULFILLED`:
			return { ...state, getNewConsumableStatus: "FULFILLED", getNewConsumables: action.payload.data.data.element };
		case `${eventNames.GET_ALL_NEW_CONSUMABLE}_REJECTED`:
			return { ...state, getNewConsumableStatus: "REJECTED", getNewConsumables: action.payload.data.data.element };
		case eventNames.CLEAR_GET_ALL_NEW_CONSUMABLE:
			return { ...state, getNewConsumableStatus: false };

		case `${eventNames.GET_ALL_EXISTING_STERLIZATION_CONSUMABLE}_FULFILLED`:
			return { ...state, getExistingSterlizationConsumableStatus: "FULFILLED", getExistingSterlizationConsumables: action.payload.data.data.element };
		case `${eventNames.GET_ALL_EXISTING_STERLIZATION_CONSUMABLE}_REJECTED`:
			return { ...state, getExistingSterlizationConsumableStatus: "REJECTED", getExistingSterlizationConsumables: action.payload.data.data.element };
		case eventNames.CLEAR_GET_ALL_EXISTING_STERLIZATION_CONSUMABLE:
			return { ...state, getExistingSterlizationConsumableStatus: false };

		case `${eventNames.GET_ALL_NEW_STERLIZATION_CONSUMABLE}_FULFILLED`:
			return { ...state, getNewSterlizationConsumableStatus: "FULFILLED", getNewSterlizationConsumables: action.payload.data.data.element };
		case `${eventNames.GET_ALL_NEW_STERLIZATION_CONSUMABLE}_REJECTED`:
			return { ...state, getNewSterlizationConsumableStatus: "REJECTED", getNewSterlizationConsumables: action.payload.data.data.element };
		case eventNames.CLEAR_GET_ALL_NEW_STERLIZATION_CONSUMABLE:
			return { ...state, getNewSterlizationConsumableStatus: false };
		default:
			return state;
	}
}
