import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ClinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button, Tooltip, Checkbox } from "antd";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import striptags from "striptags";
import InterventionLocationDropDown from "./interventionLocationDropDown";
import CategoryPersonnelDropdown from "../../../../administration/personnels/category-personnels/categoryPersonnelDropdown";
import ChartControlTypeDropdown from "./chart-control-types";
import DragWindow from "../../drag-window";
import CheckList from "./check-list/index";
import StartDayDropdown from "./startDay-dropdown";
import EndDayDropdown from "./endDay-dropdown";
import SelectTypeList from "./type-list";
import TextEditorSection from "./text-editor-section";

class AdditionalInfoCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			problemDetails: {
				interventionDetails: EditorState.createEmpty(),
				expectedResults: EditorState.createEmpty(),
				argumentationAndFoundations: EditorState.createEmpty(),
				interventionLocation: [],
				categoryPersonnelName: [],
				checkList: [],
				chartControlType: "NONE"
			},
			locationSelectLoading: true,
			isDoctorSelected: null,
			isNurseSelected: null,
			saveAs: false,
			category: "additionalInfo",
			startDayOptions: [],
			endDayOptions: [],
			reloadSummaryName: false,
			filterData: false,
			totalTasks: this.props.problemDetails.additionalInfoCount,
			saveAndNextCount: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.prevStepsWillBeDefault();
		
		setTimeout(() => {
			this.setState((prevState) => ({ ...prevState, eidtorVisible: true }));
		}, 700);
	}

	getLength(editorState) {
		if (editorState)
		{return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);}
		else {return 0;}
	}

	onEditorStateChange(value, editorState) {
		const prevState = this.state;
		let interventionSummaryName;
		if (value === "interventionDetails") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.interventionDetails = editorState;
				if (!this.state.isInterventionSummaryName) {
					interventionSummaryName = striptags(draftToHtml(convertToRaw(prevState.problemDetails.interventionDetails.getCurrentContent())));
					prevState.problemDetails.interventionSummaryName = interventionSummaryName.replace(/\&nbsp;/g, " ").replace(/\n/g, "").slice(0, 128).trim();
					prevState.invalidSummaryName = false;
				}
			}
		}
		if (value === "expectedResults") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.expectedResults = editorState;
			}
		}
		if (value === "argumentationAndFoundations") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.argumentationAndFoundations = editorState;
			}
		}
		this.setState(prevState);
		this.medicalPrescriptionActive();
		this.checkAutoCheckboxSelect();
	}

	init(data, type) {
		const contentBlock = htmlToDraft(data ? data : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.problemDetails[type] = EditorState.createWithContent(contentState);
		}
	}

	validate() {
		const prevState = this.state;
		if (!(this.state.problemDetails.interventionSummaryName && this.state.problemDetails.interventionSummaryName.trim())) {
			prevState.invalidSummaryName = true;
		} else if (!utils.checkNameRegex(this.state.problemDetails.interventionSummaryName)) {
			prevState.invalidSummaryName = true;
		}
		if (!this.props.clinicalPathwayData.isCheckList && this.state.problemDetails.interventionLocation.length < 1) {
			prevState.invalidLocation = true;
		}
		if (this.state.problemDetails.categoryPersonnelName.length < 1) {
			prevState.invalidCategoryPersonnelName = true;
		}

		// if (this.state.problemDetails.interventionDetails && this.state.problemDetails.interventionDetails.getCurrentContent().hasText() && !this.state.isNurseSelected && !this.state.isDoctorSelected) {
		// 	prevState.invalidMedicalPrescription = true;
		// }
		if (!this.state.isNurseSelected && !this.state.isDoctorSelected) {
			prevState.invalidMedicalPrescription = true;
		}

		this.setState(prevState);
		if (this.state.invalidSummaryName || this.state.invalidLocation || this.state.invalidCategoryPersonnelName || this.state.invalidMedicalPrescription) {
			return false;
		}
		return true;
	}

	selectedSubStep() {
		const clpSubStepIds = [];
		this.state.problemDetails.interventionLocation.map(data => {
			clpSubStepIds.push({ clpSubStepId: data.clpSubStepId, day: data.day ? data.day : null });
		});
		return clpSubStepIds;
	}

	selectedCategoryPersonnelIds() {
		const categoryPersonnelIds = [];
		this.state.problemDetails.categoryPersonnelName.map(data => {
			categoryPersonnelIds.push(data.value);
		});
		return categoryPersonnelIds;
	}

	selectedCheckList() {
		const checkListIds = [];
		this.state.problemDetails.checkList.map(data => {
			checkListIds.push({ checkListTreatmentId: data.value, checkListProblemId: data.checkListProblemId });
		});
		return checkListIds;
	}

	stripData(dataObj) {
		let flag = false;
		dataObj.blocks.map((obj, index) => {
			if (flag == false) {
				if (obj.text.replace(/\n/g, "").replace("<br>", "").trim() != "") {
					dataObj.blocks[index].text = obj.text.replace(/\n/g, "").replace("<br>", "");
					flag = true;
					return dataObj;
				} else {
					delete dataObj.blocks[index];
				}
			}
		});
		return dataObj;
	}
	onClickSave(saveAs) {
		this.setState({ saveAs: saveAs }, () => {
			if (this.state.problemDetails.checkList.length > 0) {
				const payload = {
					checkListLinkData: this.selectedCheckList(),
					subStepMapping: this.selectedSubStep()
				};
				this.setState({ loading: true });
				this.props.dispatch(ClinicalPathwayProblemAction.linkCheckList(payload, this.props.match.params.id, this.props.problemId));
			}
			else if (this.validate()) {
				const props = {
					id: this.props.match.params.id,
					organization: this.props.match.params.organization
				};
				this.state.problemDetails.interventionSummaryName = this.state.problemDetails.interventionSummaryName.trim();
				const payload = {
					interventionSummaryName: this.state.problemDetails.interventionSummaryName ? this.state.problemDetails.interventionSummaryName.trim().charAt(0).toUpperCase() + this.state.problemDetails.interventionSummaryName.trim().slice(1) : "",
					interventionDetails: this.state.problemDetails.interventionDetails && this.state.problemDetails.interventionDetails.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.interventionDetails.getCurrentContent()))) : "",
					expectedResults: this.state.problemDetails.expectedResults && this.state.problemDetails.expectedResults.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.expectedResults.getCurrentContent()))) : "",
					argumentationAndFoundations: this.state.problemDetails.argumentationAndFoundations && this.state.problemDetails.argumentationAndFoundations.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.argumentationAndFoundations.getCurrentContent()))) : "",
					subStepMapping: this.selectedSubStep(),
					categoryPersonnelIds: this.selectedCategoryPersonnelIds(),
					chartControlType: this.state.problemDetails.chartControlType,
					nurseAndDoctor: this.state.isNurseSelected && this.state.isDoctorSelected ? "ALL" : this.state.isNurseSelected ? "NURSE" : this.state.isDoctorSelected ? "DOCTOR" : ""
				};
				this.setState({ loading: true });
				this.props.dispatch(ClinicalPathwayProblemAction.createAdditionalInfo(payload, props, this.props.problemId));
			}
		});
	}

	saveAsHolder() {
		const prevState = this.state;
		prevState.problemDetails.interventionDetails = EditorState.createEmpty();
		prevState.problemDetails.expectedResults = EditorState.createEmpty();
		prevState.problemDetails.argumentationAndFoundations = EditorState.createEmpty();
		prevState.problemDetails.childResults = EditorState.createEmpty();
		prevState.problemDetails.interventionSummaryName = "";
		//prevState.problemDetails.categoryPersonnelName = [];
		prevState.problemDetails.checkList = [];
		prevState.filterData = true;
		this.checkAutoCheckboxSelect();
	}

	clearAllFields() {
		this.state.problemDetails.interventionDetails = EditorState.createEmpty();
		this.state.problemDetails.expectedResults = EditorState.createEmpty();
		this.state.problemDetails.argumentationAndFoundations = EditorState.createEmpty();
		this.state.problemDetails.childResults = EditorState.createEmpty();
		this.state.problemDetails.interventionSummaryName = "";
		//this.state.problemDetails.interventionLocation = [];
		this.state.problemDetails.categoryPersonnelName = [];
		this.state.problemDetails.checkList = [];
		this.state.problemDetails.chartControlType = "NONE";
	}

	onSummaryChange(value) {
		const prevState = this.state;
		prevState.problemDetails.interventionSummaryName = value;
		if (value) {
			prevState.invalidSummaryName = false;
			prevState.isInterventionSummaryName = true;
		}
		else {
			prevState.isInterventionSummaryName = false;
		}
		this.setState(prevState);
	}

	onLocationChange = e => {
		const prevState = this.state;
		prevState.problemDetails.interventionLocation = e;
		if (!e.length) {
			prevState.startDay = null;
			prevState.endDayOptions = [];
			prevState.endDay = null;
		}
		prevState.invalidLocation = false;
		this.setState(prevState);
	};

	renderDayOptions = e => {
		this.state.startDayOptions = e;
		this.setState(this.state);
	};

	startDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		if (e) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			prevState.endDayOptions = list.splice(list.findIndex(x => x.value === e.value));
		}
		else {
			prevState.endDayOptions = [];
			filteredData = prevState.problemDetails.interventionLocation.filter(item1 => item1.clpSubStepId !== prevState.startDay.clpSubStepId);
			prevState.problemDetails.interventionLocation = [...filteredData];
		}
		prevState.startDay = e;
		prevState.endDay = null;
		this.setState(prevState);
	}

	endDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		prevState.endDay = e;
		if (prevState.startDay && prevState.endDay) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			const startDayIndex = list.findIndex(x => x.value === prevState.startDay.value);
			const endDayIndex = list.findIndex(x => x.value === prevState.endDay.value);
			const data = list.slice(startDayIndex, endDayIndex + 1);
			const interventionLocationList = JSON.parse(JSON.stringify(prevState.problemDetails.interventionLocation));
			filteredData = interventionLocationList.filter(item1 => item1.clpSubStepId !== prevState.endDay.clpSubStepId);
			prevState.problemDetails.interventionLocation = [...filteredData, ...data];

		}
		this.setState(prevState);
	}

	onCheckListChange = e => {
		const prevState = this.state;
		prevState.problemDetails.checkList = e;
		prevState.invalidCheckList = false;
		this.setState(prevState);
	};

	categoryPersonnelNameChanged(e, filterData) {
		const prevState = this.state;
		prevState.filterData = filterData === true ? filterData : false;
		if (e && e.length < 6) {
			prevState.problemDetails.categoryPersonnelName = e;
			prevState.invalidCategoryPersonnelName = false;
			this.setState(prevState);
			this.checkAutoCheckboxSelect();
		}
	}

	checkAutoCheckboxSelect() {
		const prevState = this.state;
		if (prevState.problemDetails.categoryPersonnelName && prevState.problemDetails.categoryPersonnelName.length > 0)
		{prevState.problemDetails.categoryPersonnelName.forEach((e) => {
			const data = e.label.trim().split(" ");
			const upperCase = data[0].toUpperCase();
			if ((upperCase == "MEDECIN" || upperCase == "MÉDECIN") &&
					(((prevState.problemDetails.interventionDetails &&
						prevState.problemDetails.interventionDetails.getCurrentContent().hasText() == false)) ||
						prevState.isDoctorSelected == null)) {
				prevState.isDoctorSelected = true;
				prevState.invalidMedicalPrescription = false;
			}
		});}

		this.setState(prevState);
	}
	chartControlTypeChanged(e) {
		const prevState = this.state;
		prevState.problemDetails.chartControlType = e;
		this.setState(prevState);
	}

	medicalPrescriptionActive() {
		const prevState = this.state;
		prevState.invalidMedicalPrescription = false;
		this.setState(prevState);
	}

	onCheckboxChanged(value) {
		const prevState = this.state;
		if (value === "interventionsMedicalPrescription") {
			prevState.isDoctorSelected = !prevState.isDoctorSelected;
		}
		if (value === "interventionsByNursesOrProfessionals") {
			prevState.isNurseSelected = !prevState.isNurseSelected;
		}
		//prevState.problemDetails.interventionDetails = prevState.problemDetails.interventionDetails;
		if (!this.state.isInterventionSummaryName) {
			prevState.problemDetails.interventionSummaryName = "";
			if (value === "interventionsMedicalPrescription" || value === "interventionsByNursesOrProfessionals") {
				const interventionSummaryName = prevState.problemDetails.interventionDetails ? striptags(draftToHtml(convertToRaw(prevState.problemDetails.interventionDetails.getCurrentContent()))) : "";
				if (interventionSummaryName) {
					prevState.problemDetails.interventionSummaryName = interventionSummaryName.replace(/\&nbsp;/g, " ").replace(/\n/g, "").slice(0, 128).trim();
				}
			}
		}
		this.setState(prevState);
		this.medicalPrescriptionActive();
	}

	reloadCheckList() {
		if (this.child) {
			this.child.reloadCheckList();
		}
	}

	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Add Additional Info of", utils.getLanguage()) + " " + i18Get(this.props.problemDetails.type ? `${this.props.problemDetails.type} ` : "", utils.getLanguage()) + `( ${this.props.problemDetails.problem} )`} /> </div>); };

	tabChanged(category) {
		if (this.state.category !== category) {
			const prevState = this.state;
			prevState.category = category;
			this.clearAllFields();
			this.setState(prevState);
		}
	}

	dataChanged(e) {
		if (e.length <= 255) {
			const data = utils.clpTasksReplaceCharsBasedOnAscii(e);
			this.onSummaryChange(data);
			this.setState({ filterData: true });
		}
	}

	itemChanged(e) {
		const prevState = this.state;
		this.categoryPersonnelNameChanged(e.responsible, true);
		this.setState(prevState);
	}

	setReloadSummaryName() {
		this.setState({ reloadSummaryName: false });
	}

	disableButtons() {
		return this.state.saveAndNextCount == 0 && this.state.category === "checkList" && this.state.totalTasks == 0 && !this.props.problemDetails.isCheckList ? true : false;
	}

	formatSteps = data => {
		return `${i18Get(data.stepName, utils.getLanguage())} -> ${data.day ? i18Get("DAY", utils.getLanguage()) : ""} ${data.subStepName}`;
	};


	prevStepsWillBeDefault() {

		if (this.state.totalTasks > 0 && this.props.problemDetails.additionalInfo) {

			const additionalInfo = this.props.problemDetails.additionalInfo[this.props.problemDetails.additionalInfo.length - 1];
			this.state.problemDetails["interventionLocation"] = [];
			let data = {};
			additionalInfo.allSubStepMapping && additionalInfo.allSubStepMapping.map((value) => {
				if (value.clpSubStepId) {
					data = {
						day: value.day,
						clpSubStepId: value.clpSubStepId,
						value: value.clpSubStepId + value.day,
						label: this.formatSteps(value)
					};
					this.state.problemDetails["interventionLocation"].push(data);
				}
			});
		}
	}

	render() {
		if (this.props.clinicalPathwayProblems.createAdditionalProblemStatus === "FULFILLED") {
			this.setState({ loading: false });
			if (this.state.saveAs) {
				this.saveAsHolder();
				this.setState({ loading: false, saveAs: false, reloadSummaryName: true, saveAndNextCount: 1 });
			} else
			{this.props.onClickCancel();}
			this.props.reloadData();
			this.props.dispatch(ClinicalPathwayProblemAction.clearCreateAdditionalInfo());
		}

		if (this.props.clinicalPathwayProblems.createAdditionalProblemStatus === "REJECTED") {
			this.setState({ loading: false, saveAs: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearCreateAdditionalInfo());
		}

		if (this.props.clinicalPathwayProblems.linkCheckListProblems === "FULFILLED") {
			this.setState({ loading: false });
			if (this.state.saveAs) {
				this.setState({ loading: false, saveAs: false });
				this.reloadCheckList();
			} else {
				this.props.onClickCancel();
			}
			this.props.reloadData();
			this.props.dispatch(ClinicalPathwayProblemAction.clearLinkCheckList());
		}

		if (this.props.clinicalPathwayProblems.linkCheckListProblems === "REJECTED") {
			this.setState({ loading: false, saveAs: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearLinkCheckList());
		}

		return (
			<div>
				<Modal
					className="re_assign_modal create_problem create_additional"
					title={this.title()}
					visible={this.props.createAdditionalInfo}
					onCancel={this.props.onClickCancel}
					wrapClassName="drag_modal"
					footer={[
						<Button key="submit" type="primary" loading={(this.state.saveAs && this.state.loading ? true : false)} onClick={this.onClickSave.bind(this, true)} disabled={this.disableButtons()}>
							<span className="margin-left-10" >
								<I18 tkey="Add and Next" />
							</span>
						</Button>,
						<Button key="submit" type="primary" loading={(!this.state.saveAs && this.state.loading ? true : false)} onClick={this.onClickSave.bind(this, false)} disabled={this.disableButtons()}>
							<span className="margin-left-10" >
								<I18 tkey="Add" />
							</span>
						</Button>,
						<Button key="back" onClick={this.props.onClickCancel}>
							<I18 tkey="Cancel" />
						</Button>
					]}
				>
					<div className="additionalInfo_wraper">

						{!this.props.clinicalPathwayData.isCheckList &&
							<ul className="nav nav-tabs clinical_pathway_tabs">
								<li className={this.state.category === "additionalInfo" ? "active" : ""}>
									<a className="tab_section di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "additionalInfo")}>
										<I18 tkey="Additional Info" />
									</a>
								</li>
								<li className={this.state.category === "checkList" ? "active" : ""}>
									<a className="tab_section  di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "checkList")}>
										<I18 tkey="Check List" />
									</a>
								</li>
							</ul>
						}
						{this.state.category === "additionalInfo" &&
							<div className="row">
								<div className="di padding-left-5 padding-bottom-5 col-md-8">
									<div className="">
										<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
											<label className='margin-bottom-0'><I18 tkey='Intervention Summary Name' />:</label>
										</Tooltip>
									</div>
									<div className="">
										<div className="w_100 di position_relative">
											<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
												<div className="">
													<SelectTypeList
														data={this.state.problemDetails.interventionSummaryName}
														dataChanged={this.dataChanged.bind(this)}
														itemChanged={this.itemChanged.bind(this)}
														reloadSummaryName={this.state.reloadSummaryName}
														setReloadSummaryName={this.setReloadSummaryName.bind(this)}
														autoSetSingleOne={true}
													/>
												</div>
											</Tooltip>
											{this.state.invalidSummaryName && <span className='invalid_clinical_pathway invalid_color'>
												<I18 tkey={"Enter valid intervention summary name (not allowed except ( ) + _ - ' ,. : ;) "} />
											</span>}
										</div>
									</div>
								</div>
								<div className="di padding-bottom-5 col-md-4">
									<div className="">
										<label className='margin-bottom-0'><I18 tkey='Intervenant' />:</label>
									</div>
									<div className="">
										<div className="w_100 di position_relative text_editor_z_index_select">
											<div className="margin-bottom-10">
												<CategoryPersonnelDropdown
													data={this.state.problemDetails.categoryPersonnelName}
													categoryPersonnelChanged={this.categoryPersonnelNameChanged.bind(this)}
													clinicalPathway={true}
													filterData={this.state.filterData}
													autoSetSingleOne={true}

												/>

												{this.state.invalidCategoryPersonnelName ?
													<span className='invalid_clinical_pathway invalid_color'>
														<I18 tkey={"Select Intervenant"} />
													</span>
													:
													<span className='info_text'>
														<I18 tkey={"Please check the type of intervenant"} />
													</span>
												}
											</div>
										</div>
									</div>
								</div>
								<div className={`di padding-bottom-5 ${this.props.clinicalPathwayData.isCheckList ? "col-md-12 padding-left-5 margin-top--20" : " col-md-4 margin-top--5"} float_right ${!this.props.problemDetails.stepSectionType == "CLINICAL_PATHWAY" ? "margin-top-12" : ""}`}>
									<div className="">
										<label className='margin-bottom-0'><I18 tkey='Chart Control Type' />:</label>
									</div>
									<div className="">
										<div className="w_100 di position_relative">
											<ChartControlTypeDropdown
												createClass="no_class"
												data={this.state.problemDetails.chartControlType}
												chartControlTypeChanged={this.chartControlTypeChanged.bind(this)}
											/>
										</div>
									</div>
								</div>
								{!this.props.clinicalPathwayData.isCheckList &&
									<div className="di padding-left-5 padding-bottom-5 col-md-8 z_index_100 margin-top--7">
										<div className={this.state.invalidLocation ? "padding_bottom_20" : ""}>
											<label className={`margin-bottom-0 ${!this.props.problemDetails.stepSectionType ? "margin-top-5" : ""}`}><I18 tkey='Location of the intervention' /></label>
											{!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" && <table className='float_right_i margin-top--5'>
												<tr>
													<td className='padding-right-5 w_45_px'><label>&nbsp;<I18 tkey='From' />:</label></td>
													<td className='padding-right-5'>
														<div className="w_145_px di position_relative">
															<StartDayDropdown
																startDayOptions={this.state.startDayOptions}
																startDay={this.state.startDay}
																startDayChanged={this.startDayChanged.bind(this)}
															/>
														</div>
													</td>
													<td className='padding-left-5 padding-right-5 w_30_px'><label><I18 tkey='To' />:</label></td>
													<td className="w_145_px">
														<div className="w_145_px di position_relative float_right">
															<EndDayDropdown
																endDayOptions={this.state.endDayOptions}
																endDay={this.state.endDay}
																endDayChanged={this.endDayChanged.bind(this)}
															/>
														</div>
													</td>
												</tr>
											</table>}
										</div>
										<div className="">
											<div className="w_100 di position_relative">
												<div className={!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" ? "margin-top-5" : ""}>
													{!this.props.clinicalPathwayData.isCheckList &&
														<InterventionLocationDropDown
															data={this.state.problemDetails.interventionLocation ? this.state.problemDetails.interventionLocation : null}
															onChange={this.onLocationChange}
															stepSectionType={this.props.problemDetails.stepSectionType}
															problemId={this.props.problemId}
															renderDayOptions={this.renderDayOptions}
															isCreate={true}
														/>
													}
												</div>
												{this.state.invalidLocation && <span className='invalid_clinical_pathway invalid_color' >
													<I18 tkey={"Select a valid location of the intervention"} />
												</span>}
											</div>
										</div>
									</div>
								}
								<div className="padding-left-5 col-md-6">
									<div className={"w_100 di  d-flex align-items-center"}>
										<Checkbox checked={this.state.isDoctorSelected} onChange={this.onCheckboxChanged.bind(this, "interventionsMedicalPrescription")}>
											<I18 tkey={"Medical interventions or on medical prescription"} />
											<img className="interventions_icon_doctor" src="/images/doctor.png" />
										</Checkbox>
									</div>
								</div>
								<div className="padding-left-5 col-md-6">
									<div className="w_100 di  d-flex align-items-center">
										<Checkbox checked={this.state.isNurseSelected} onChange={this.onCheckboxChanged.bind(this, "interventionsByNursesOrProfessionals")}>
											<I18 tkey={"Interventions By Nurses Or Professionals"} />
											<img className="interventions_icon_nurses" src="/images/nurses.png" />
										</Checkbox>
									</div>
								</div>
								{this.state.eidtorVisible ? 
								<TextEditorSection
									onEditorStateChange={this.onEditorStateChange.bind(this)}
									problemDetails={this.state.problemDetails}
									isDoctorSelected={this.state.isDoctorSelected}
									invalidMedicalPrescription={this.state.invalidMedicalPrescription}
								/> : "" }
							</div>}

						{this.state.category === "checkList" ?

							this.state.saveAndNextCount == 0 && this.state.totalTasks == 0 && !this.props.problemDetails.isCheckList ?
								<span className='information w_80_max notice_message'><I18 tkey="Sorry, you can't add a checklist as the first task in a problem" /></span>
								:
								<div>
									<div className="di padding-left-5 padding-bottom-20 col-md-8 z-index-2">
										<div className="">
											<label className='margin-bottom-0'><I18 tkey='Check List' />:</label>
										</div>
										<div className="">
											<div className="w_100 di position_relative text_editor_z_index_select">
												<CheckList
													data={this.state.problemDetails.checkList ? this.state.problemDetails.checkList : null}
													onChange={this.onCheckListChange}
													problemId={this.props.problemId}
													onRef={ref => (this.child = ref)}
													clinicalPathwayData={this.props.clinicalPathwayData}
												/>
											</div>
										</div>
									</div>
									<div className="di padding-left-5 padding-bottom-5 col-md-8 z_index_100 margin-top--7 z-index-1 min-height-260">
										<div className={this.state.invalidLocation ? "padding_bottom_20" : ""}>
											<label className={`margin-bottom-0 ${!this.props.problemDetails.stepSectionType ? "margin-top-5" : ""}`}>{!this.props.clinicalPathwayData.isCheckList ? <I18 tkey='Location of the intervention' /> : ""}</label>
											{!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" && <table className='float_right_i margin-top--5'>
												<tr>
													<td className='padding-right-5 w_45_px'><label>&nbsp;<I18 tkey='From' />:</label></td>
													<td className='padding-right-5'>
														<div className="w_145_px di position_relative">
															<StartDayDropdown
																startDayOptions={this.state.startDayOptions}
																startDay={this.state.startDay}
																startDayChanged={this.startDayChanged.bind(this)}
															/>
														</div>
													</td>
													<td className='padding-left-5 padding-right-5 w_30_px'><label><I18 tkey='To' />:</label></td>
													<td className="w_145_px">
														<div className="w_145_px di position_relative float_right">
															<EndDayDropdown
																endDayOptions={this.state.endDayOptions}
																endDay={this.state.endDay}
																endDayChanged={this.endDayChanged.bind(this)}
															/>
														</div>
													</td>
												</tr>
											</table>}
										</div>
										<div className="">
											<div className="w_100 di position_relative">
												<div className={!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" ? "margin-top-5" : ""}>
													{!this.props.clinicalPathwayData.isCheckList &&
														<InterventionLocationDropDown
															data={this.state.problemDetails.interventionLocation ? this.state.problemDetails.interventionLocation : null}
															onChange={this.onLocationChange}
															stepSectionType={this.props.problemDetails.stepSectionType}
															problemId={this.props.problemId}
															renderDayOptions={this.renderDayOptions}
															isCreate={true}
														/>
													}
												</div>
												{this.state.invalidLocation && <span className='invalid_clinical_pathway invalid_color' >
													<I18 tkey={"Select a valid location of the intervention"} />
												</span>}
											</div>
										</div>
									</div>
								</div>
							: ""}
					</div>
				</Modal>
			</div>
		);
	}
}

AdditionalInfoCreate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(AdditionalInfoCreate));