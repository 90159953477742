import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import BreadCrumb from "../../../breadcrumb";
import  Permissions from "./permissions";
import  Roles from "./roles";
import { Redirect } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";

class RolePermissions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			roles: {},
			filterValue: "",
			totalPages: 0,
			tab: "",
			showRecord: true,
			showCreate: false,
			search: ""
		};
	}

	showHideCreate(value) {
		this.setState({ showCreate: value });
	}

	filterChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState);
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Roles and Permissions", utils.getLanguage()), link: locations.roles });
		if (this.props.location.pathname === locations.create_role) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.create_role });
		}
		if (this.state.role.id) {
			items.push({ name: this.state.role.name, link: locations.roles + `/${this.state.role.id}` });
		}
		return items;
	}

	onCreateClicked() {
		this.props.history.push(locations.create_role);
	}  

	totalCount(count) {
		this.setState({ totalPages: (count ? count : 0) });
	}

	showRecord = (show) => { this.setState({ showRecord: show });};

	tabChanged(link) {
		if (link) {
			const prevState = this.state;
			prevState.tab = link;
			this.setState(prevState, () => {
				this.props.history.push(link);
			});
            
		}
	}
	render() {

		this.state.role = this.props.breadcrumb.componentData;
		return (   
			<div className="main_overview_section">
				<div className="pole_container">
					<BreadCrumb items={this.generateItems()} showRecord={this.state.showRecord} totalRecords={this.state.totalPages}/> 
					<div className="padding-top-15">
						{this.props.location.pathname === `${locations.userManagement_roles}` && <li className='float_left list_style'>
							<button
								className="common_button"
								type="button"
								onClick={this.showHideCreate.bind(this, true)}>
								<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
								<I18 tkey="CREATE" />
							</button>
						</li>}
						{this.props.location.pathname === `${locations.userManagement_roles}` && <li className='float_right list_style'>
							<DebounceInput
								className="form-control w_100"
								debounceTimeout={750}
								value={this.state.search}
								onChange={this.filterChanged.bind(this)}
								maxLength={200}
								placeholder={i18Get("Search ...", utils.getLanguage())}
							/>
						</li>}
					</div>
					<div className="pole_top_section">
						<div className="di w_100">
							<div className="tab_section padding-top-15">
								<ul className="nav nav-tabs">
									<li className={(this.props.location.pathname === `${locations.userManagement_roles}`) ? "active" : ""}>
										<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.userManagement_roles)}>
											<I18 tkey="Roles" />
										</a>
									</li>
									<li className={(this.props.location.pathname === `${locations.userManagement_permissions}`) ? "active" : ""}>
										<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.userManagement_permissions)}>
											<I18 tkey="Permissions" />
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
        
					<Switch>
						<Route path={locations.userManagement_permissions} render={() => <Permissions totalCount={this.totalCount.bind(this)} showRecord ={this.showRecord.bind(this)} />}/>
						<Route path={locations.userManagement_roles} render={() => <Roles totalCount={this.totalCount.bind(this)} showRecord ={this.showRecord.bind(this)} showCreate = {this.state.showCreate} showHideCreate = {this.showHideCreate.bind(this)} search ={this.state.search} />}/>
						<Redirect to={locations.userManagement_roles}  render={() => <Roles  totalCount={this.totalCount.bind(this)} showRecord ={this.showRecord.bind(this)} showCreate = {this.state.showCreate} showHideCreate = {this.showHideCreate.bind(this)} search ={this.state.search} />}/>
					</Switch>
				</div>
			</div>
		);
	}
}

RolePermissions.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(RolePermissions));                    