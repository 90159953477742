import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ClinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import utils from "../../../../../../../../utils/utils";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import TextEditor from "../text-editor";
import { EditorState, ContentState, convertToRaw, getDefaultKeyBinding } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import ProblemTypeDropDown from "../problem-type-dropdown";
import DragWindow from "../../drag-window";
import SectionTypeDropDown from "../../../../administration/clinical-pathway/section-type-dropdown";


class ProblemCreate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			problemDetails: {
				invalidExplanation: false,
				invalidRisk: false,
				invalidRiskExplanation: false,
				invalidProblem: false,
				withRisk: null,
				type: "",
				data: EditorState.createEmpty(),
				results: EditorState.createEmpty(),
				riskExplanation: EditorState.createEmpty(),
				childResults: EditorState.createEmpty()
			},
			saveAs: false,
			riskLabel: "Risque de", //riskLabel: 'Risque de',
			filter: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.setState({
			filter: (this.props.filter && this.props.filter != "ALL_ITEMS") ? this.props.filter : "BEFORE_ADMISSION"
		});

		setTimeout(() => {
			this.setState((prevState) => ({ ...prevState, eidtorVisible: true }));
		}, 700);
	}
	onStepSectionTypeChange(value) {
		const prevState = this.state;
		prevState.filter = value;
		prevState.problemDetails.type = null;
		this.setState(prevState);
	}

	onEditorStateChange(value, editorState) {
		const prevState = this.state;
		if (value === "data") {
			prevState.invalidExplanation = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.data = editorState;
			}
		}
		if (value === "results") {
			prevState.invalidResults = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.results = editorState;
			}
		}
		if (value === "riskExplanation") {
			prevState.invalidRiskExplanation = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.riskExplanation = editorState;
			}
		}
		if (value === "childResults") {
			prevState.invalidChildResults = false;
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.childResults = editorState;
			}
		}
		this.setState(prevState);
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	handleBeforeInput(input) {
		if (input === "data") {
			if (this.getLength(this.state.problemDetails.data) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "results") {
			if (this.getLength(this.state.problemDetails.results) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "riskExplanation") {
			if (this.getLength(this.state.problemDetails.riskExplanation) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "childResults") {
			if (this.getLength(this.state.problemDetails.childResults) >= utils.editorMaxLength) {
				return "handled";
			}
		}
	}

	handlePastedText(text, type) {
		if (type === "data") {
			if (text && ((this.getLength(this.state.problemDetails.data) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "results") {
			if (text && ((this.getLength(this.state.problemDetails.results) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "riskExplanation") {
			if (text && ((this.getLength(this.state.problemDetails.riskExplanation) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "childResults") {
			if (text && ((this.getLength(this.state.problemDetails.childResults) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command, type) {
		if (type === "data") {
			if (this.getLength(this.state.problemDetails.data) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "results") {
			if (this.getLength(this.state.problemDetails.data) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "riskExplanation") {
			if (this.getLength(this.state.problemDetails.riskExplanation) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "childResults") {
			if (this.getLength(this.state.problemDetails.childResults) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		return "not-handled";
	}

	init(data, type) {
		const contentBlock = htmlToDraft(data ? data : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.problemDetails[type] = EditorState.createWithContent(contentState);
		}
	}

	validate() {
		const prevState = this.state;
		if ((this.state.problemDetails.problem && this.state.problemDetails.problem.trim() ? (!utils.checkNameRegex(this.state.problemDetails.problem)) || (this.state.problemDetails.problem.match(/\\$/)) || (this.state.problemDetails.problem.indexOf("\"") >= 0) : true)) {
			prevState.invalidProblem = true;
		}

		if (!this.state.problemDetails.type || !this.state.problemDetails.type.value) {
			prevState.invalidProblemType = true;
		} else if (!this.props.clinicalPathwayData.isCheckList && !this.state.problemDetails.withRisk && this.state.problemDetails.type.isWithRisk) {
			prevState.invalidRisk = true;
		}

		this.setState(prevState);
		if (this.state.invalidProblemType || this.state.invalidExplanation || this.state.invalidProblem || this.state.invalidRisk || this.state.invalidRiskExplanation || this.state.invalidResults || this.state.invalidChildResults) {
			return false;
		}
		return true;
	}

	stripData(dataObj) {
		let flag = false;
		dataObj.blocks.map((obj, index) => {
			if (flag == false) {
				if (obj.text.replace(/\n/g, "").replace("<br>", "").trim() != "") {
					dataObj.blocks[index].text = obj.text.replace(/\n/g, "").replace("<br>", "");
					flag = true;
					return dataObj;
				} else {
					delete dataObj.blocks[index];
				}
			}
		});
		return dataObj;
	}

	onClickSaveAs() {
		this.state.saveAs = true;
		this.onClickSave();
	}
	//this.props.clpStepFilter === 'ALL_ITEMS'
	onClickSave() {
		if (this.validate()) {
			this.setState({ loading: true });
			const props = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization
			};
			let payload = {};
			if (this.state.problemDetails.withRisk == "WITH_RISK") {
				payload = {
					clpHealthProblemId: this.state.problemDetails.type && this.state.problemDetails.type.value ? this.state.problemDetails.type.value : "",
					problem: this.state.problemDetails.problem ? this.state.riskLabel === "Risque de" ? this.state.riskLabel + " " + this.state.problemDetails.problem.trim() : this.state.riskLabel + this.state.problemDetails.problem.trim() : "",
					data: this.state.problemDetails.riskExplanation.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.riskExplanation.getCurrentContent()))) : "",
					results: this.state.problemDetails.childResults.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.childResults.getCurrentContent()))) : "",
					stepSectionType: this.state.filter ? this.state.filter : "",
					withoutRisk: false
				};
				payload["child"] = [{
					id: this.state.problemDetails.id && this.state.problemDetails.parentId ? this.state.problemDetails.id : "",
					problem: this.state.problemDetails.problem ? this.state.problemDetails.problem.trim().charAt(0).toUpperCase() + this.state.problemDetails.problem.trim().slice(1) : "",
					data: this.state.problemDetails.data.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.data.getCurrentContent()))) : "",
					results: this.state.problemDetails.results.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.results.getCurrentContent()))) : ""
				}
				];
				this.props.dispatch(ClinicalPathwayProblemAction.createClpProblem(payload, props, this.state.problemId));
			}
			else if (this.state.problemDetails.withRisk == "WITHOUT_RISK" || !this.state.problemDetails.type.isWithRisk || this.props.clinicalPathwayData.isCheckList) {
				payload = {
					clpHealthProblemId: this.state.problemDetails.type && this.state.problemDetails.type.value ? this.state.problemDetails.type.value : "",
					problem: this.state.problemDetails.problem ? this.state.problemDetails.problem.trim().charAt(0).toUpperCase() + this.state.problemDetails.problem.trim().slice(1) : "",
					data: this.state.problemDetails.data.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.data.getCurrentContent()))) : "",
					results: this.state.problemDetails.results.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.results.getCurrentContent()))) : "",
					stepSectionType: this.state.filter ? this.state.filter : "",
					withoutRisk: true
				};
				this.props.dispatch(ClinicalPathwayProblemAction.createClpProblem(payload, props, this.state.problemId));
			}
		}
	}

	problemRiskShowToggle(e) {
		const prevState = this.state;
		//prevState.problemDetails.withRisk = e.target.value === 'WITH_RISK' ? true : false;
		prevState.problemDetails.withRisk = e.target.value;
		prevState.invalidRisk = false;
		this.setState(prevState);
	}

	formatRiskLabel(value) {
		const list = ["a", "e", "i", "o", "u", "y"];
		if (list.includes(value.trim().charAt(0))) {
			return true;
		}
		return false;
	}

	onProblemChange(value) {
		this.state.problemDetails.problem = value;
		this.state.invalidProblem = false;
		this.state.riskLabel = "Risque de";
		if (value && value.trim() && this.formatRiskLabel(value)) {
			this.state.riskLabel = "Risque d'";
		}
		this.setState(this.state);
	}

	onProblemTypeChange = e => {
		const prevState = this.state;
		prevState.problemDetails.type = e;
		prevState.invalidProblemType = false;
		if (prevState.problemDetails.type && !prevState.problemDetails.type.isWithRisk) {
			prevState.problemDetails.withRisk = "WITHOUT_RISK";
		}
		this.setState(prevState);
	};
	saveAsHolder() {
		this.state.problemDetails.problem = "";
		this.state.problemDetails.data = EditorState.createEmpty();
		this.state.problemDetails.results = EditorState.createEmpty();
		this.state.problemDetails.riskExplanation = EditorState.createEmpty();
		this.state.problemDetails.childResults = EditorState.createEmpty();
	}

	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Add", utils.getLanguage()) + " " + i18Get(this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : "", utils.getLanguage())} /> </div>); };
	render() {
		if (this.props.clinicalPathwayProblems.createClpProblemStatus === "FULFILLED") {
			this.setState({ loading: false });
			if (this.state.saveAs) {
				this.saveAsHolder();
				this.state.saveAs = false;
			} else
			{this.props.onClickCancel();}
			this.props.reloadData();
			this.props.dispatch(ClinicalPathwayProblemAction.clearCreateClpProblem());
		}

		if (this.props.clinicalPathwayProblems.createClpProblemStatus === "REJECTED") {
			this.setState({ loading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearCreateClpProblem());
		}

		return (
			<div className="">
				<Modal
					className="re_assign_modal create_problem"
					title={this.title()}
					visible={this.props.createModal}
					onCancel={this.props.onClickCancel}
					wrapClassName="drag_modal"
					footer={[
						<Button disabled={this.props.clinicalPathwayData.isCheckList} key="1" type="primary" loading={(this.state.saveAs && this.state.loading ? true : false)} onClick={this.onClickSaveAs.bind(this)}>
							<span className="margin-left-10" >
								<I18 tkey='Add & Next' />
							</span>
						</Button>,
						<Button key="2" type="primary" loading={(!this.state.saveAs && this.state.loading ? true : false)} onClick={this.onClickSave.bind(this)}>
							<span className="margin-left-10" >
								<I18 tkey="Add" />
							</span>
						</Button>,
						<Button key="3" onClick={this.props.onClickCancel}>
							<I18 tkey="Cancel" />
						</Button>
					]}
				>
					<div className="d-flex pathway_form_btn txtarea_btn_container">
						<div className="pathway_problem_btn_input">
							<div className="row d-flex align-items-center margin-bottom-10">
								<div className="col-md-8 padding-left-0 position_relative">
									<div className="w_100 di position_relative text_editor_z_index_select font_size_12 section_type_dropdown">
										<SectionTypeDropDown
											sectionType={this.state.filter}
											selectedSectionType={this.onStepSectionTypeChange.bind(this)}
											allowClear={false}
										/>
									</div>
								</div>
								<div className="col-md-8 padding-left-0 position_relative">
									<div className="w_100 di position_relative text_editor_z_index_select">
										<ProblemTypeDropDown
											data={this.state.problemDetails.type ? this.state.problemDetails.type : null}
											onChange={this.onProblemTypeChange}
											stepSectionType={this.state.filter}
											isCreate={true}
										/>
										{this.state.invalidProblemType && <span className='invalid_pathway_create' ><I18 tkey={"Select a valid problem type"} /></span>}
									</div>
								</div>
								<div className="col-md-4 min-height-42">
									{(!this.props.clinicalPathwayData.isCheckList && this.state.problemDetails.type && this.state.problemDetails.type.isWithRisk) ?
										<Radio.Group onChange={this.problemRiskShowToggle.bind(this)} value={this.state.problemDetails.withRisk} >
											<span>
												<Radio className="" value="WITHOUT_RISK">

													{this.state.problemDetails.type && !this.state.problemDetails.type.isWithRisk ?
														<span className='active_blue'><I18 tkey="Without the risk" /></span> : <I18 tkey="Without the risk" />
													}
												</Radio>
												<Radio className="" value="WITH_RISK">
													<I18 tkey="With the risk" />
												</Radio>
											</span>
											{this.state.invalidRisk && <span className='invalid_pathway_create'><I18 tkey="Please select any risk option" /></span>}
										</Radio.Group>
										:
										<br />
									}
								</div>
							</div>
							<div className="w_100 di margin-bottom-10 position_relative margin-top-10">
								<NameInput
									className="form-control w_100 padding-right-36"
									value={this.state.problemDetails.problem}
									inputChanged={this.onProblemChange.bind(this)}
									maxLength={128}
								/>
								{this.state.invalidProblem && <span className='invalid_pathway_create'><I18 tkey={`Enter Valid ${this.state.problemDetails.type && this.state.problemDetails.type.value ? this.state.problemDetails.type.label : "data"}`} /> <I18 tkey="(Special characters not allowed except ( ) + _ - ' ,.)" /></span>}
							</div>
						</div>
					</div>
					<div className="">
						<div className="row">
							<div className="col-md-12 padding-right-5">
								<div className="w_100 di margin-bottom-10 ">
									<div className="d-flex align-items-center justify-content-between ">
										<div className="">
											<label><I18 tkey={"Clinical Data and Contributing Factors of"} /> {this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : ""} />}</label>
										</div>
									</div>
									{this.state.eidtorVisible ? 
									<TextEditor
										className="text_area_treatment form-control"
										editorState={this.state.problemDetails.data}
										onEditorStateChange={this.onEditorStateChange.bind(this, "data")}
										handleBeforeInput={this.handleBeforeInput.bind(this, "data")}
										handlePastedText={this.handlePastedText.bind(this, "data")}
										keyBindingFn={this.keyBindingFn.bind(this)}
										handleKeyCommand={this.handleKeyCommand.bind(this, "data")}
										invalidMsg={this.state.invalidExplanation}
									/> : "" }
								</div>
							</div>
						</div>
						<div className="d-flex pathway_form_btn txtarea_btn_container">
							<div className="pathway_problem_btn_input">
								{this.state.problemDetails.withRisk === "WITH_RISK" ?
									<div>
										<div className="problem_seperate"></div>
										<div className="w_100 di margin-bottom-10 margin-top-20">
											<div className="form-control w_100 padding-right-36 form-control-label">
												{this.state.riskLabel === "Risque de" ?
													<React.Fragment>
														<label><I18 tkey={this.state.riskLabel} /></label> {this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : ""} />}
													</React.Fragment>
													:
													<React.Fragment>
														<label><I18 tkey={this.state.riskLabel} /></label>{this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : ""} />}
													</React.Fragment>}
											</div>
										</div>
									</div>
									: ""}
							</div>
						</div>
						<div className="row">
							<div className="col-md-12 padding-right-5">
								{this.state.problemDetails.withRisk === "WITH_RISK" ?
									<div className="w_100 di margin-bottom-10">
										<div className="d-flex align-items-center justify-content-between ">
											<div className="">
												{this.state.riskLabel === "Risque de" ?
													<React.Fragment>
														<label><I18 tkey={"Explanations Specific to"} /> {this.state.riskLabel} {this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : ""} />}</label>
													</React.Fragment>
													:
													<React.Fragment>
														<label><I18 tkey={"Explanations Specific to"} /> {this.state.riskLabel}{this.state.problemDetails.problem ? this.state.problemDetails.problem : <I18 tkey={this.state.problemDetails.type && this.state.problemDetails.type.value ? `${this.state.problemDetails.type.label}` : ""} />}</label>
													</React.Fragment>}
											</div>
										</div>
										<TextEditor
											className="text_area_treatment form-control "
											editorState={this.state.problemDetails.riskExplanation}
											onEditorStateChange={this.onEditorStateChange.bind(this, "riskExplanation")}
											handleBeforeInput={this.handleBeforeInput.bind(this, "riskExplanation")}
											handlePastedText={this.handlePastedText.bind(this, "riskExplanation")}
											keyBindingFn={this.keyBindingFn.bind(this)}
											handleKeyCommand={this.handleKeyCommand.bind(this, "riskExplanation")}
											invalidMsg={this.state.invalidRiskExplanation}
										/>
									</div>
									: ""}
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

ProblemCreate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(ProblemCreate));