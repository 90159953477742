import { combineReducers } from "redux";
import user from "./user";
import actions from "./actions";
import translations from "./translations";
import intervention from "./intervention";
import site from "./site";
import supplier from "./supplier";
import specialty from "./specialty";
import family from "./family";
import service from "./service";
import breadcrumb from "./breadcrumb";
import textElements from "./text-element";
import elements from "./elements";
import adminPersonnel from "./admin-personnel";
import categoryPersonnel from "./category-personnel";
import anaesthesia from "./anaesthesia";
import sessionType from "./sessionType";
import staffElements from "./staff-elements";
import interventionDetails from "./intervention-details";
import extElements from "./ext-elements";
import assignSteps from "./assign-steps";
import anaesthesiaDetails from "./anaesthesia-details";
import course from "./course";
import administartionUser from "./administrationUsers";
import settings from "./settings";
import equipments from "./equipment";
import scope from "./scope";
import activitySteps from "./activity-steps";
import consumable from "./consumable";
import chemotherapy from "./chemotherapy";
import imagery from "./imagery";
import elementClass from "./element-class";
import screen from "./screen";
import sterilization from "./sterilization";
import constants from "./constants";
import assignToTreatments from "./assign-to-treatments";
import consomableSterilization from "./consomable-sterilization";
import sync from "./sync";
import specialtyModel from "./speciality-model";
import familyModel from "./family-model";
import clinicalPathway from "./clinical-pathway";
import clinicalPathwayDetails from "./clinicalPathway-details";
import extClinicalPathway from "./ext-clinical-pathway";
import adminClinicalPathway from "./admin-clinical-pathway";
import clinicalPathwayProblems from "./clinical-pathway-problems";
import healthPathway from "./health-pathway";
import healthPathwayDetails from "./healthPathway-details";
import syncfusion from "./sync-fusion";
import standardCareChart from "./standard-care-chart";
import communitySection from "./community-section";
import communityMembers from "./community-members";
import communitySites from "./community-sites";
import uiComponents from "../components/main-container/overview/main-overview/uiComponents/reducer";
import userManagement from "../components/main-container/overview/main-overview/administration/userManagement/reducer";
import clpBackup from "./clp-backup";
import DiagramReducer from "mom-ui-commons/build/components/reducers";

const rootReducer = combineReducers({
	user, 
	translations, 
	anaesthesia, 
	staffElements, 
	interventionDetails, 
	elements, 
	textElements, 
	intervention, 
	actions, 
	site, 
	specialty, 
	family, 
	supplier,
	service, 
	breadcrumb,
	adminPersonnel, 
	categoryPersonnel, 
	sessionType, 
	extElements,
	assignSteps, 
	anaesthesiaDetails, 
	course, 
	administartionUser,
	settings,
	equipments,
	scope,
	activitySteps,
	consumable,
	chemotherapy,
	imagery,
	elementClass,
	screen,
	sterilization,
	constants,
	assignToTreatments,
	consomableSterilization,
	sync,
	specialtyModel,
	familyModel,
	clinicalPathway,
	clinicalPathwayDetails,
	extClinicalPathway,
	adminClinicalPathway,
	clinicalPathwayProblems,
	healthPathway,
	healthPathwayDetails,
	syncfusion,
	standardCareChart,
	communitySection,
	communityMembers,
	communitySites,
	clpBackup,
	...uiComponents,
	...userManagement,
	...DiagramReducer
});

export default rootReducer;
