import "./styles.css";
import I18 from "i18";
import React, { useState, useEffect } from "react";
import NodeSettings from "./nodeSettings";
// import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import CreateStep from "./fieldComponets/create-step";
import CreateSubsteps from "./fieldComponets/create-substeps";
import { useSelector } from "react-redux";
import ConnectorSettings from "./connectorSettings";
function SidebarSettings(props) {
	const adminClinicalPathway = useSelector((state) => { return state.adminClinicalPathway;});

	// const [hideSideBar, setHideSideBar] = useState(false);
	const [inputStepName, setInputStepNAme] = useState("");
	const [createdStepName, setCreatedStepName] = useState("");
	const [isCreateStepIconVisible, setIsCreateStepIconVisible] = useState(false);
	const [isCreateStepModalVisible, setIsCreateStepModalVisible] = useState(false);
	const [inputSubStepName, setInputSubStepNAme] = useState("");
	const [isCreateSubStepIconVisible, setIsCreateSubStepIconVisible] = useState(false);
	const [isCreateSubStepModalVisible, setIsCreateSubStepModalVisible] = useState(false);
	const [selectedStep, setSelectedStep] = useState([]);
	const [createdSubStepName, setCreatedSubStepName] = useState("");
	const [isNameInStepList, setIsNameInStepList] = useState(false);
	const [isNameInSubStepList, setIsNameInSubStepList] = useState(false);

	useEffect(() => { 
		setCreatedStepName("");
		setCreatedSubStepName("");
	}, [props.selectedShape]);
	
	//STEP 
	const onStepSearchData = (value) => {
		const findOption = adminClinicalPathway.stepList.data.filter( e => e.name === value);
		if (value && findOption.length === 0) {
			setIsNameInStepList(false);
			setIsCreateStepIconVisible(true);
			setInputStepNAme(value);
		} else {
			setIsNameInStepList(true);
			setIsCreateStepIconVisible(false);
			setInputStepNAme("");
		}
	};

	const clearCreatedStepName = () => {
		setInputStepNAme("");
		setCreatedStepName("");
	};

	const setNewName = (name) => {
		setCreatedStepName(name);
	};

	const createStepClicked = () => {
		setIsNameInStepList(false);
		setIsCreateStepModalVisible(true);
	};

	const createStepClosed = () => {
		setIsCreateStepIconVisible(false);
		setIsCreateStepModalVisible(false);
	};

	//SUBSTEP
	const setNewSubStepName = (name) => {
		setCreatedSubStepName(name);
	};

	const clearCreatedSubStepName = () => {
		setInputSubStepNAme("");
		setCreatedSubStepName("");
	};

	const onSubStepSearchData = (value) => {
		const findOption = adminClinicalPathway.subStepList.data.filter( e => e.name === value);
		if (value && findOption.length === 0) {
			setIsNameInSubStepList(false);
			setIsCreateSubStepIconVisible(true);
			setInputSubStepNAme(value);
		} else {
			setIsNameInSubStepList(true);
			setIsCreateSubStepIconVisible(true);
			setInputSubStepNAme("");
		}
	};

	const createSubStepClicked = (data) => {
		setIsNameInSubStepList(false);
		setIsCreateSubStepModalVisible(true);
		setSelectedStep(data);
	};

	const createSubStepClosed = () => {
		setIsCreateSubStepIconVisible(false);
		setIsCreateSubStepModalVisible(false);
	};

	useEffect(() => {
		props.updateDiagramWidth();
	}, []);

	return <div>
		{props.openTab ?
			<div className={`sideBar-wrapper ${props.hideSideBar ? "slide-side-bar" : "" }`} id="sideBar-wrapper">
				{props.openTab === "OPEN_NODE_SETTINGS_TAB" ? 
					<NodeSettings 
						nodeName={props.nodeName}
						selectedShape={props.selectedShape}
						inputChangeHandler={props.inputChangeHandler}
						styleHandler={props.styleHandler}
						onChangeFiledData={props.onChangeFiledData}
						colorPicker={props.colorPicker}
						colorPickerHandler={props.colorPickerHandler}
						activityHandler={props.activityHandler}
						treatmentId= {props.treatmentId}
						customNodeData={props.customNodeData}
						onChangecustomNodeData={props.onChangecustomNodeData}
						onStepSearchData={onStepSearchData} 
						createStepClicked={createStepClicked}
						createSubStepClicked={createSubStepClicked}
						onSubStepSearchData={onSubStepSearchData}
						inputStepName={createdStepName} 
						createdSubStepName={createdSubStepName}
						clearCreatedSubStepName={clearCreatedSubStepName}
						clearCreatedStepName={clearCreatedStepName}
						healthPathwayData={props.healthPathwayData}
						diagramNodesData={props.diagramNodesData}
						autoSelectNode = {props.autoSelectNode}
						currentNode={props.currentNode}
						currentNodeId={props.currentNodeId}
						
					/> 
					: ""}

				{props.openTab === "OPEN_CONNECTOR_SETTINGS_TAB" ? <ConnectorSettings 
					selectedShape={props.selectedShape}
					diagramData={props.selectedAnnotation} 
					onChange={props.onChangeConnectorData}  /> : ""}
				{props.openTab === "OPEN_TEMPLATE_TAB" ? <div>COMING SOON!</div> : ""}
				{/* <div className="sidebar_close_diagram hidden-xs hidden-sm  justify-content-center  " onClick={() => setHideSideBar( !props.hideSideBar )}>{ !props.hideSideBar ? <ArrowRightOutlined /> : <ArrowLeftOutlined />}</div> */}
			</div> : ""}
		{isCreateStepModalVisible && 
			<CreateStep 
				inputStepName={inputStepName} 
				createStepClosed={createStepClosed}
				healthPathwayData={props.healthPathwayData}
				customNodeData={props.customNodeData}
				setInputStepNAme={setNewName}
				onChangecustomNodeData={props.onChangecustomNodeData}
			/>	}	
		{isCreateSubStepModalVisible && 
			<CreateSubsteps 
				inputSubStepName={inputSubStepName} 
				stepId={selectedStep.value} 
				stepCode={selectedStep.code}
				stepName={selectedStep.label}
				createSubStepClosed={createSubStepClosed}
				setNewSubStepName={setNewSubStepName}
				clearCreatedSubStepName={clearCreatedSubStepName}/>}
	</div>;
}
export default SidebarSettings;