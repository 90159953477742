import eventNames from "../../actions/events";

const initialState = {
	reloadStaff: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {     
		case `${eventNames.DESCRIPTION_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					definition: action.payload.data.data,
					isDefinitionGetCompleted: "FULFILLED"
				};
			}  
			return {
				...state,
				definition: [],
				isDefinitionGetCompleted: "FULFILLED"
			};
		case `${eventNames.DESCRIPTION_GET}_REJECTED`:
			return {
				...state,
				isDefinitionGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DESCRIPTION_GET:
			return {
				...state,
				isDefinitionGetCompleted: false
			};

		case `${eventNames.ELIGIBILITY_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					eligibility: action.payload.data.data,
					isEligibilityGetCompleted: "FULFILLED"
				};
			}  
			return {
				...state,
				eligibility: [],
				isEligibilityGetCompleted: "FULFILLED"
			};
		case `${eventNames.ELIGIBILITY_GET}_REJECTED`:
			return {
				...state,
				isEligibilityGetCompleted: "REJECTED"
			};
		case eventNames.CLEAR_ELIGIBILITY_GET:
			return {
				...state,
				isEligibilityGetCompleted: false
			};

		case `${eventNames.FETCH_ALL_EXTENDED_CLINICAL_PATHWAY}_FULFILLED`:
			return {
				...state,
				clinicalPathwayData: action.payload.data.data,
				fetchAllExtendedClinicalPathway: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXTENDED_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				fetchAllExtendedClinicalPathway: "REJECTED"
			};
		case eventNames.FETCH_ALL_EXTENDED_CLINICAL_PATHWAY_COMPLETED:
			return {
				...state,
				clinicalPathwayData: [],
				fetchAllExtendedClinicalPathway: false
			};
		case `${eventNames.FETCH_SIDE_BAR}_REJECTED`:
			return {
				...state,
				fetchClinicalPathwaySidebar: "REJECTED"
			};

		case `${eventNames.FETCH_SIDE_BAR}_FULFILLED`:
			return {
				...state,
				clinicalPathwaySidebar: action.payload.data.data,
				fetchClinicalPathwaySidebar: "FULFILLED"
			};

		case eventNames.CLEAR_FETCH_SIDE_BAR:
			return {
				...state,
				clinicalPathwaySidebar: [],
				fetchClinicalPathwaySidebar: false
			};

		case `${eventNames.CLINICAL_PATHWAY_DETAILS_UPDATE}_FULFILLED`:
			return { ...state, 
				validationMsg: action.payload.data.data,
				editClinicalPathwayDetailsStatus: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_DETAILS_UPDATE}_REJECTED`:
			return { ...state, editClinicalPathwayDetailsStatus: "REJECTED" };
		case eventNames.CLEAR_CLINICAL_PATHWAY_DETAILS_UPDATE:
			return { ...state, validationMsg: null, editClinicalPathwayDetailsStatus: false };

		case `${eventNames.FETCH_DEFAULT_STEP}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayDefaultSteps: "REJECTED"
			};

		case `${eventNames.FETCH_DEFAULT_STEP}_FULFILLED`:
			return {
				...state,
				clinicalPathwayDefaultStep: action.payload.data.data,
				fetchAllClinicalPathwayDefaultSteps: "FULFILLED"
			};

		case eventNames.CLEAR_FETCH_DEFAULT_STEP:
			return {
				...state,
				clinicalPathwayDefaultStep: [],
				fetchAllClinicalPathwayDefaultSteps: false
			};
        
		case `${eventNames.FETCH_REFERENCE_ATTACHMENT}_REJECTED`:
			return {
				...state,
				fetchReferenceAttachmentStatus: "REJECTED"
			};

		case `${eventNames.FETCH_REFERENCE_ATTACHMENT}_FULFILLED`:
			return {
				...state,
				attachments: action.payload.data.data,
				fetchReferenceAttachmentStatus: "FULFILLED"
			};

		case eventNames.FETCH_REFERENCE_ATTACHMENT_COMPLETED:
			return {
				...state,
				attachments: [],
				fetchReferenceAttachmentStatus: false
			};

		case eventNames.SELECTED_COLUMN:
			return {
				...state,
				selectedColumn: action.payload.value
			};
		case eventNames.CLEAR_SELECTED_COLUMN:
			return {
				...state,
				selectedColumn: false
			};

		case eventNames.MAPPING_DATA:
			return {
				...state,
				mappingData: action.payload.value
			};
		case eventNames.CLEAR_MAPPING_DATA:
			return {
				...state,
				mappingData: false
			};

		case eventNames.SELECTED_LIST:
			return {
				...state,
				selectedList: action.payload.value
			};
		case eventNames.CLEAR_SELECTED_LIST:
			return {
				...state,
				selectedList: false
			};
        
		case `${eventNames.FETCH_ALL_MINIMAL_SUB_STEP}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllMinimalSubStep: "FULFILLED",
					minimalSubStepList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllMinimalSubStep: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_MINIMAL_SUB_STEP}_REJECTED`:
			return {
				...state,
				fetchAllMinimalSubStep: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_MINIMAL_SUB_STEP:
			return {
				...state,
				fetchAllMinimalSubStep: false
			};

		case `${eventNames.SAVE_MINIMAL_SUB_STEP}_FULFILLED`:
			return { ...state, saveMinimalSubStep: "FULFILLED" };
		case `${eventNames.SAVE_MINIMAL_SUB_STEP}_REJECTED`:
			return { ...state, saveMinimalSubStep: "REJECTED" };
		case eventNames.CLEAR_SAVE_MINIMAL_SUB_STEP:
			return { ...state, saveMinimalSubStep: false };

		case `${eventNames.FETCH_COPY_FROM_CLP_LIST}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchCopyFromClinicalPathwayList: "FULFILLED",
					copyFromClinicalPathwayList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchCopyFromClinicalPathwayList: "FULFILLED"
			};
		case `${eventNames.FETCH_COPY_FROM_CLP_LIST}_REJECTED`:
			return {
				...state,
				fetchCopyFromClinicalPathwayList: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_COPY_FROM_CLP_LIST:
			return {
				...state,
				fetchCopyFromClinicalPathwayList: false
			};

		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY}_FULFILLED`:
			return { ...state, copyFromClinicalPathWay: "FULFILLED", clpCopy: action.payload.data.data };
		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY}_REJECTED`:
			return { ...state, copyFromClinicalPathWay: "REJECTED" };
		case eventNames.CLEAR_COPY_FROM_CLINICAL_PATHWAY:
			return { ...state, copyFromClinicalPathWay: false };

		case `${eventNames.CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS}_FULFILLED`:
			return { ...state, copyFromClinicalPathwayStatus: "FULFILLED",  clpCopyStatus: action.payload.data.data };
		case `${eventNames.CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS}_REJECTED`:
			return { ...state, copyFromClinicalPathwayStatus: "REJECTED", clpCopyStatus: action.payload.data.data };
		case eventNames.CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS_COMPLETED:
			return { ...state, copyFromClinicalPathwayStatus: false };


		case `${eventNames.CLINICAL_PATHWAY_EXPORT}_FULFILLED`:
			return { ...state, clinicalPathwayReportExportStatus: "FULFILLED", clinicalPathwayReport: action.payload.data.data };
		case `${eventNames.CLINICAL_PATHWAY_EXPORT}_REJECTED`:
			return { ...state, clinicalPathwayReportExportStatus: "REJECTED" };
		case eventNames.CLINICAL_PATHWAY_EXPORT_COMPLETED:
			return { ...state, clinicalPathwayReportExportStatus: false };

		case `${eventNames.CHECK_CLINICAL_PATHWAY_REPORT_STATUS}_FULFILLED`:
			return { ...state, clinicalPathwayReportStatus: "FULFILLED",  clinicalPathwayReportStage: action.payload.data.data };
		case `${eventNames.CHECK_CLINICAL_PATHWAY_REPORT_STATUS}_REJECTED`:
			return { ...state, clinicalPathwayReportStatus: "REJECTED", clinicalPathwayReportStage: action.payload.data.data };
		case eventNames.CHECK_CLINICAL_PATHWAY_REPORT_STATUS_COMPLETED:
			return { ...state, clinicalPathwayReportStatus: false };

		case `${eventNames.CREATE_CLINICAL_PATHWAY_SERVICE}_FULFILLED`:
			return { ...state, createClinicalPathWayService: "FULFILLED" };
		case `${eventNames.CREATE_CLINICAL_PATHWAY_SERVICE}_REJECTED`:
			return { ...state, createClinicalPathWayService: "REJECTED" };
		case eventNames.CREATE_CLINICAL_PATHWAY_SERVICE_COMPLETED:
			return { ...state, createClinicalPathWayService: false };

		case `${eventNames.GET_AUTHORS_AND_ACTIVITIES}_FULFILLED`:
			return { ...state, fetchAllAuthorsAndActivities: "FULFILLED",  authorsAndActivities: action.payload.data.data };
		case `${eventNames.GET_AUTHORS_AND_ACTIVITIES}_REJECTED`:
			return { ...state, fetchAllAuthorsAndActivities: "REJECTED" };
		case eventNames.CLEAR_GET_AUTHORS_AND_ACTIVITIES:
			return { ...state, fetchAllAuthorsAndActivities: false };

		case `${eventNames.DELETE_AUTHOR_AND_ACTIVITY}_FULFILLED`:
			return { ...state, deleteAuthorAndActivityStatus: "FULFILLED" };
		case `${eventNames.DELETE_AUTHOR_AND_ACTIVITY}_REJECTED`:
			return { ...state, deleteAuthorAndActivityStatus: "REJECTED" };
		case eventNames.DELETE_AUTHOR_AND_ACTIVITY_COMPLETED:
			return { ...state, deleteAuthorAndActivityStatus: false };

		case `${eventNames.GET_ONE_AUTHORS_AND_ACTIVITIES}_FULFILLED`:
			return { ...state, getOneAuthorAndActivity: "FULFILLED",  authorAndActivity: action.payload.data.data };
		case `${eventNames.GET_ONE_AUTHORS_AND_ACTIVITIES}_REJECTED`:
			return { ...state, getOneAuthorAndActivity: "REJECTED" };
		case eventNames.CLEAR_GET_ONE_AUTHORS_AND_ACTIVITIES:
			return { ...state, getOneAuthorAndActivity: false };


		case `${eventNames.UPDATE_AUTHOR_AND_ACTIVITY}_FULFILLED`:
			return { ...state, editAuthorAndActivityStatus: "FULFILLED" };
		case `${eventNames.UPDATE_AUTHOR_AND_ACTIVITY}_REJECTED`:
			return { ...state, editAuthorAndActivityStatus: "REJECTED" };
		case eventNames.UPDATE_AUTHOR_AND_ACTIVITY_COMPLETED:
			return { ...state, editAuthorAndActivityStatus: false };

		case `${eventNames.GET_HOSPITAL_FOR_CLP}_FULFILLED`:
			return {
				...state,
				hospitalData: action.payload.data,
				getHospitalForClpCompleted: "FULFILLED"
			};
		case `${eventNames.GET_HOSPITAL_FOR_CLP}_REJECTED`:
			return {
				...state,
				getHospitalForClpCompleted: "REJECTED"
			};
        
		case eventNames.GET_HOSPITAL_FOR_CLP_COMPLETED:
			return {
				...state,
				hospitalData: [],
				getHospitalForClpCompleted: false
			};

		case `${eventNames.COPY_ALL_TO_CLINICAL_PATHWAY_MASTER}_FULFILLED`:
			return { ...state, copyAllToClinicalPathWayMaster: "FULFILLED" };
		case `${eventNames.COPY_ALL_TO_CLINICAL_PATHWAY_MASTER}_REJECTED`:
			return { ...state, copyAllToClinicalPathWayMaster: "REJECTED" };
		case eventNames.CLEAR_COPY_ALL_TO_CLINICAL_PATHWAY_MASTER:
			return { ...state, copyAllToClinicalPathWayMaster: false };

		case `${eventNames.FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY}_FULFILLED`:
			return {
				...state,
				clinicalPathwayData: action.payload.data.data,
				fetchAllExtendedClpForSelectedCopy: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY}_REJECTED`:
			return {
				...state,
				fetchAllExtendedClpForSelectedCopy: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY:
			return {
				...state,
				clinicalPathwayData: [],
				fetchAllExtendedClpForSelectedCopy: false
			};

		case `${eventNames.GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY}_FULFILLED`:
			return { ...state, fetchAllAuthorsAndActivitiesForSelectedCopy: "FULFILLED",  authorsAndActivities: action.payload.data.data };
		case `${eventNames.GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY}_REJECTED`:
			return { ...state, fetchAllAuthorsAndActivitiesForSelectedCopy: "REJECTED" };
		case eventNames.CLEAR_GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY:
			return { ...state, fetchAllAuthorsAndActivitiesForSelectedCopy: false };

		case `${eventNames.COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP}_FULFILLED`:
			return { ...state, copySelectedDataToMasterClp: "FULFILLED" };
		case `${eventNames.COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP}_REJECTED`:
			return { ...state, copySelectedDataToMasterClp: "REJECTED" };
		case eventNames.CLEAR_COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP:
			return { ...state, copySelectedDataToMasterClp: false };

		case `${eventNames.FETCH_COPY_TO_SUB_STEP_LIST}_FULFILLED`:
			return {
				...state,
				copyToSubStepList: action.payload.data.data,
				fetchCopyToSubStepList: "FULFILLED"
			};
		case `${eventNames.FETCH_COPY_TO_SUB_STEP_LIST}_REJECTED`:
			return {
				...state,
				fetchCopyToSubStepList: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_COPY_TO_SUB_STEP_LIST:
			return {
				...state,
				clinicalPathwayData: [],
				fetchCopyToSubStepList: false
			};

		case `${eventNames.SUB_STEP_COPY}_FULFILLED`:
			return { ...state, copySubStep: "FULFILLED", subStepCopy: action.payload.data.data };
		case `${eventNames.SUB_STEP_COPY}_REJECTED`:
			return { ...state, copySubStep: "REJECTED" };
		case eventNames.SUB_STEP_COPY_COMPLETED:
			return { ...state, copySubStep: false };

		case `${eventNames.CHECK_SUB_STEP_COPY_STATUS}_FULFILLED`:
			return { ...state, subStepCopyStatus: "FULFILLED",  subStepCopyStage: action.payload.data.data };
		case `${eventNames.CHECK_SUB_STEP_COPY_STATUS}_REJECTED`:
			return { ...state, subStepCopyStatus: "REJECTED" };
		case eventNames.CHECK_SUB_STEP_COPY_STATUS_COMPLETED:
			return { ...state, subStepCopyStatus: false };

		case `${eventNames.GET_DAY_INFORMATION}_FULFILLED`:
			return {
				...state,
				dayInformation: action.payload.data.data,
				fetchDayInformation: "FULFILLED"
			};
		case `${eventNames.GET_DAY_INFORMATION}_REJECTED`:
			return {
				...state,
				fetchDayInformation: "REJECTED"
			};

		case eventNames.GET_DAY_INFORMATION_COMPLETED:
			return {
				...state,
				dayInformation: [],
				fetchDayInformation: false
			};

		case `${eventNames.FETCH_TOTAL_ITEM_COUNT}_FULFILLED`:
			return { ...state, fetchTotalNumberOfItems: "FULFILLED",  totalItemCount: action.payload.data.data };
		case `${eventNames.FETCH_TOTAL_ITEM_COUNT}_REJECTED`:
			return { ...state, fetchTotalNumberOfItems: "REJECTED" };
		case eventNames.CLEAR_FETCH_TOTAL_ITEM_COUNT:
			return { ...state, fetchTotalNumberOfItems: false };

		case `${eventNames.COMMUNITY_SHARE}_FULFILLED`:
			return { ...state, communityShareCompleted: "FULFILLED" };
		case `${eventNames.COMMUNITY_SHARE}_REJECTED`:
			return { ...state, communityShareCompleted: "REJECTED" };
		case eventNames.COMMUNITY_SHARE_COMPLETED:
			return { ...state, communityShareCompleted: false };

		default:
			return state;
	}
}
