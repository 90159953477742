import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import EditCommunitySites from "./edit";
import List from "./list";
import BreadCrumb from "../../../breadcrumb";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import CreateSites from "./create-sites";
import Filter from "./filter";
import communitySitesAction from "../../../../../../../actions/community-sites";

class Sites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumableDefinition: {},
			showConsumableClasses: false,
			key: 1,
			list: {},
			refresh: false,
			newClassName: "",
			totalPages: 0,
			showAlert: false,
			editModal: false,
			type: locations.tabSitesClass,
			sort: utils.checkForParameter("sort", this.props.location) ? parseInt(utils.checkForParameter("sort", this.props.location)) : "",
			desc: utils.checkForParameter("desc", this.props.location) ? parseInt(utils.checkForParameter("desc", this.props.location)) : 0

		};
	}

	generateItems() {
		const items = [];
		if (this.props.location.pathname.startsWith(locations.structure_sites)) {
			items.push({ name: i18Get("Sites", utils.getLanguage()), link: locations.structure_sites });
		}
		return items;
	}

	showTop() {
		if (this.props.location.pathname === (locations.structure_sites)) {
			return true;
		}
		return false;
	}

	filterChanged(filter, ignorePageChange) {
		if (this.props.location.pathname === locations.structure_sites) {
			this.props.dispatch(communitySitesAction.filterChangeCommunitySites(filter, ignorePageChange));
		}
	}

	createClassCompleted(refresh) {
		this.setState({ refresh: refresh });
	}

	visibleEditModal(data) {
		this.setState({ editModal: data ? true : false, communitySitesData: data ? data : "" });
	}

	totalCount(count) {
		this.setState({ totalPages: (count ? count : 0) });
	}

	setSort = (sort, desc) => {
		this.setState({ sort, desc });
	};

	render() {
		this.state.consumableDefinition = this.props.breadcrumb.componentData;
		return (
			<div className="consumable_definition_table_scroll">
				<div className="main_overview_section">
					<div className="pole_container">
						<BreadCrumb useSearch items={this.generateItems()} showRecord={this.showTop()} totalRecords={this.state.totalPages} />
						{
							this.showTop() && this.props.location.pathname === (locations.structure_sites) &&
							<CreateSites createClassCompleted={this.createClassCompleted.bind(this)} />
						}
						{this.showTop() ?
							<Filter
								isConsumableClass={this.props.location.pathname === (locations.structure_sites)}
								filterChanged={this.filterChanged.bind(this)} /> : ""}
						{this.state.editModal &&
							<EditCommunitySites
								editModal={this.state.editModal}
								communitySitesData={this.state.communitySitesData}
								createClassCompleted={this.createClassCompleted.bind(this)}
								visibleEditModal={this.visibleEditModal.bind(this)}
							/>}
						<Switch>
							<Route path={locations.structure_sites} render={() =>
								<List totalCount={this.totalCount.bind(this)}
									refresh={this.state.refresh}
									createClassCompleted={this.createClassCompleted.bind(this)}
									visibleEditModal={this.visibleEditModal.bind(this)}
									setSort={this.setSort}
									sort={this.state.sort} desc={this.state.desc} />} />
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

Sites.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	elementClass: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	breadcrumb: store.breadcrumb,
	elementClass: store.elementClass,
	translations: store.translations
}))(Sites));                    