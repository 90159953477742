import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import elementActions from "../../../../../../actions/elements";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SelectExistingConsumbales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumables: [],
			offset: 0,
			numResults: 100,
			search: null,
			checked: 1
		};
	}

	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchConsumableList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchConsumableList();
	}

	fetchConsumableList() {
		if (this.props.checked === 2) {
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			if (this.props.checked === 2 && this.props.type !== "STERILIZATION") {
				this.props.dispatch(elementActions.getAllExistingConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), this.state.search, "", this.props.match.params.id, "", "", this.props.exType, ("mode=EXISTING"), this.props.elementId));
			}
			if (this.props.type === "STERILIZATION" && this.props.checked === 2) {
				this.props.dispatch(elementActions.getAllExistingSterlizationConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), this.state.search, "", this.props.match.params.id, "", "", this.props.exType, ("mode=EXISTING"), this.props.elementId));
			}
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchConsumableList(search);
				}, 800);
			}
		}
	}

	onStatusChange() {
		this.props.statusCheck("EXISTING_CONSUMABLE");
		return (this.props.data);
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.consumables) {
			this.state.consumables.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	render() {
		if (this.props.elements.getExistingConsumbaleStatus === "FULFILLED") {
			if (this.props.elements.existingConsumbales.length === 0 || this.props.type === this.props.elements.existingConsumbales[0].type) {
				this.state.fetchingData = false;
				this.state.consumables = unionWith(this.state.consumables, this.props.elements.existingConsumbales, isEqual);
				this.renderOptions();
			}
			this.props.dispatch(elementActions.clearGetAllExistingConsumable());
		}
		if (this.props.elements.getExistingSterlizationConsumableStatus === "FULFILLED") {
			if (this.props.elements.getExistingSterlizationConsumables.length === 0 || this.props.type === this.props.elements.getExistingSterlizationConsumables[0].type) {
				this.state.fetchingData = false;
				this.state.consumables = unionWith(this.state.consumables, this.props.elements.getExistingSterlizationConsumables, isEqual);
				this.renderOptions();
			}
			this.props.dispatch(elementActions.clearGetAllExistingSterlizationConsumable());
		}
		if ((this.props.checked !== this.state.checked)) {
			this.state.checked = this.props.checked;
			this.state.consumables = [];
			this.state.offset = 0;
			this.state.options = [];
			this.fetchConsumableList();
		}
		if (!this.props.elements.getExistingConsumbaleStatus && this.state.fetchDataFromserver) {
			this.fetchConsumableList();
		}
		return (
			<Select
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.dataChanged}
				isClearable
				value={this.state.options ? this.state.options.filter((options) => (options.value === this.props.data.value ? this.onStatusChange() : "")) : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Consumbale", utils.getLanguage())}
			/>
		);
	}
}

SelectExistingConsumbales.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	elements: store.elements,
	translations: store.translations
}))(SelectExistingConsumbales));
