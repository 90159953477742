import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import BreadCrumb from "../../../breadcrumb";
import { DebounceInput } from "react-debounce-input";
import { Select } from "antd";
import HospitalSelect from "../../activities/specialities/selectHospital";
import List from "./list";
import Create from "./create";
import Edit from "./edit";
import { FilterOutlined } from "@ant-design/icons";
const { Option } = Select;
class Specialities extends Component {

	constructor(props) {
		super(props);
    
		this.state = {
			totalRecords: 0,
			selectedStatus: "ALL"
		};
	}
    
	showContent = () => {
		if (this.props.location.pathname === locations.sterilization_speciality) {
			return true;    
		}
		return false;
	};

	generateItems = () => {
		const items = [];
		items.push({ name: i18Get("Sterilization Speciality", utils.getLanguage()), link: locations.sterilization_speciality  });
		if (this.props.location.pathname === locations.sterilization_speciality + "/create") {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.sterilization_speciality + "/create" });
		}
		if (this.state.specialtyData && this.state.specialtyData.id) {
			items.push({ name: this.state.specialtyData.name, link: locations.sterilization_speciality + `/${this.state.specialtyData.id}` });
		}
		return items;
	};

	addClicked = () => {
		this.props.history.push(`${locations.sterilization_speciality_create}`);
	};

	filterChanged = ({ target: { value } }) => {
		this.setState({ filter: value });
	};

	onStatusChange = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};

	onHospitalChanged = (e) => {
		this.setState({ selectedHospital: e ? e : "" });
	};


	filterClicked = () => {
		this.setState({ showFilter: !this.state.showFilter });
	};

	clearFilter = () => {
		this.setState({ selectedHospital: "", selectedStatus: "ALL", filter: "" });
	};

	getFilter = () => (
		<div className="row ">
			<div className="col-sm-4">
				<div className="form-group">
					<label><I18 tkey="Status" />:</label>
					<Select 
						className="w_100"
						value={this.state.selectedStatus ? this.state.selectedStatus : <I18 tkey="All"/>}
						onChange={this.onStatusChange}
					>
						<Option key='All' value="ALL"><I18 tkey="All"/></Option>
						<Option key='Approved' value="APPROVED"><I18 tkey="Approved"/></Option>
						<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval "/></Option>
					</Select>
				</div>
			</div>
			<div className="col-sm-4">
				<div className="form-group">
					<label><I18 tkey="Hospital" />:</label>
					<HospitalSelect 
						data={this.state.selectedHospital} 
						hospitalChanged={this.onHospitalChanged} />
				</div>
			</div>
			<div className="col-sm-4">
				<div className="clear_fliter text_align_right">
					<a className="clear_filter_a filter_top_align" onClick={this.clearFilter}>
						<I18 tkey='Clear Filter' />
					</a>
				</div>
			</div>
		</div>
	);
    

	getSortAndDesc = (sort, desc) => {
		this.state.sort = sort;
		this.state.desc = desc;
	};

	setCount = (count) => {
		this.setState({ totalRecords: count });
	};

	render() {
		return (
			<div className="main_overview_section">
				<div className="bloc_container">
					<BreadCrumb items={this.generateItems()} showRecord={this.showContent()} totalRecords={this.state.totalRecords}/>
					{this.showContent() && <div className="main_overview_top_section di w_100 padding-top-15">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								<div className="create_interventions margin-top-2">
									<button className="common_button" type="button" onClick={this.addClicked}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE" /></button>
								</div> 
							</div>
						</div>
					</div>}
				</div>
				<div className="di w_100">
                   
					<div className="tab_section tab_section_speciality padding-top-15">
						{
							this.props.location.pathname === locations.sterilization_speciality ?
								<div>
									<div className="row margin-bottom-15">
										<div className="col-md-7">
                                
										</div>
										<div className="col-md-4">
											<DebounceInput
												className="filter_input_design form-control"
												debounceTimeout={750}
												placeholder={i18Get("Search...", utils.getLanguage())}
												value={this.state.filter}
												onChange={this.filterChanged}/> 
										</div>
										<div className="col-md-1">
											<div className="filter" onClick={this.filterClicked}>
												<FilterOutlined  className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
											</div>
										</div>
									</div>
									{this.state.showFilter && this.getFilter()} 
								</div>
								: ""
						}
						<div className="tab-content">
							<div id="home" className="tab-pane fade in active">
								<Switch>
									<Route exact path={locations.sterilization_speciality}>
										<List setCount={this.setCount}  filter={this.state.filter} status = {this.state.selectedStatus}  hospitalId = {this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "" } getSortAndDesc={this.getSortAndDesc}/>
									</Route>
									<Route exact path={locations.sterilization_speciality + "/create"}>
										<Create />
									</Route>
									<Route exact path={locations.sterilization_speciality_edit}>
										<Edit />
									</Route>
									<Redirect to={locations.specialities} /> 
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


Specialities.propTypes = {
	specialty: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	specialty: store.specialty,
	translations: store.translations
}))(Specialities));                    