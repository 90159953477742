import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "i18";
import utils from "utils/utils";
import clinicalPathwayAction from "actions/clinical-pathway";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import Config from "MOM-config";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class ClinicalPathwaySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathway: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true,
			fetchAllMeta: null,
			options: []
		};
	}
   
	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchClinicalPathwayList(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchClinicalPathwayList(this.state.search);
	}

     
	fetchClinicalPathwayList(search) {
		const hospitalId = this.props.healthPathwayData.hospitalId;
		if (!this.state.fetchingData) {   
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			let queryString = "";
			const organization = `${Config.isMasterServer ? (this.props.organization ? this.props.organization.toUpperCase() : "MOM") : ""}`;
			queryString = "sort=name&desc=0&offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + "&status=APPROVED" + (search ? this.state.search ? `&name=${this.state.search}` : "" : "") + "&isCheckList=0";
			queryString += this.props.clinicalPathwayType ? `&clinicalPathwayType=${this.props.clinicalPathwayType}` : "";
			//queryString += hospitalId ? " " :`&organization=${organization}`;
			queryString += `&hospitalId=${hospitalId ? hospitalId : "MASTER"}`;
			this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
				this.props.dispatch(clinicalPathwayAction.getAll(organization, queryString, this.state.fetchAllMeta));
			});
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchClinicalPathwayList(search);
				}, 800);
			}
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.clinicalPathway) {    
			this.state.clinicalPathway.map((data) => {   
				const prefix = `${this.props.match.params.organization !== "mom" ? data.hospitalCode? data.hospitalCode : "" : data.siteId && data.hospitalId ? data.hospitalCode : "MOM"}`;
				prevState.options.push({ value: data.id, label: (prefix ? prefix + " " : "") + data.name, hospitalCode: data.hospitalCode, code: data.code });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}
	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathway === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.clinicalPathway = unionWith(this.state.clinicalPathway, this.props.clinicalPathway.clinicalPathway.data, isEqual);
			this.renderOptions();
			// if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
			// 	this.fetchClinicalPathwayList(false);
			// 	this.state.isDataFound = false;
			// } //This is commented because of unlimited API trigger when without data
			this.props.dispatch(clinicalPathwayAction.clearGetAll());    
		}

		return (   
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100 select_intervention"
				onChange={this.props.onClinicalPathwayChange}
				isClearable={true}
				value={this.state.options.find(options => options.code === this.props.value)}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Clinical Pathway", utils.getLanguage())}
			/>
		);
	}
}

ClinicalPathwaySelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(ClinicalPathwaySelect));                    