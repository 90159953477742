import PropTypes from "prop-types";
import { withRouter } from "react-router";
import React, { Component } from "react";
import { connect } from "react-redux";
import I18, { i18Get } from "../../../../../../../i18";
import "./style.css";
import SourceComponents from "./sourceComponents";
import utils from "../../../../../../../utils/utils";
import TableData from "./tableData";
import ClinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";
import { GitCompare } from "../../../../../../../utils/icons";
import ActionButton from "../../../../../../../plug/action-button";
import eventNames from "../../../../../../../actions/events";
import { Button } from "antd";


class ClpComparison extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			clinicalPathwayCategory: this.props.clinicalPathwayData.isModel ? "MDL" : this.props.clinicalPathwayData.isCheckList ? "CL" : "CLP", //CLP,CL,MDL,
			comparisonMethod: "1",
			selectedCompanion: null,
			checkList: [],
			stepsAndSubSteps: []

		};
	}
	UNSAFE_componentWillMount() {
		this.fetchStepsAndSubStepsList();
	}

	fetchProblemList() {
		if (!this.state.selectedCompanion) { return false; }
		this.setState({ checkAll: false });
		let queryString = "";
		queryString += "?offset=0&";
		queryString += "numResults=1000&";
		queryString += `filter=${this.state.comparisonMethod}`;
		this.props.dispatch(ClinicalPathwayProblemAction.getClpProblemListForComparison(this.props.match.params.id, this.state.selectedCompanion.value, queryString));
	}

	fetchStepsAndSubStepsList() {
		this.setState({ isLoading: true });
		const queryString = "?offset=0&numResults=1000";
		this.props.dispatch(ClinicalPathwayProblemAction.getAllStepsAndSubSteps(this.props.match.params.id, queryString));
	}
	toggleClinicalPathwayCategory(data) {
		const prevState = this.state;
		prevState.clinicalPathwayCategory = data;
		prevState.selectedCompanion = null;
		prevState.problemList = null;
		this.setState(prevState);
	}
	toggleConditions(data) {
		const prevState = this.state;
		prevState.comparisonMethod = data;
		prevState.problemList = null;
		this.fetchProblemList();
		this.setState(prevState);
	}

	onChangeClpData(e) {
		const prevState = this.state;
		prevState.selectedCompanion = e;
		this.fetchProblemList();
		prevState.srcAndDestClpSame = this.props.clinicalPathwayData.noOfDays === e?.noOfDays && this.props.clinicalPathwayData.procedureDay === e?.procedureDay && !e?.isCheckList;
		this.setState(prevState);
	}

	getSelectedComponents(checkList) {
		this.setState({ checkList: checkList, invalidComponents: false });
	}

	selectedSubStep = subStepMapping => {
		const clpSubSteps = [];
		subStepMapping.map(data => {
			clpSubSteps.push({ clpSubStepId: data.clpSubStepId, day: data.day ? data.day : null, stepCode: data.stepCode, subStepCode: data.subStepCode });
		});
		return clpSubSteps;
	};

	saveClicked(saveAs) {
		const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
		const list = checkedDataList.filter(value => value.checked === true);
		const components = [];
		list.map((e) => {
			if (e.checked) {
				delete e["checked"];
			}
			if (e.additionalInfo) {
				delete e["problem"];
			}
			const additionalInfo = [];
			e.additionalInfo = e.additionalInfo.filter(value => value.checked === true);
			e.additionalInfo.map((data) => {
				if (data.checked) {
					delete data["checked"];
				}
				if (data.interventionSummaryName) {
					delete data["interventionSummaryName"];
				}
				additionalInfo.push({ additionalInfoId: data.id, subStepMapping: this.selectedSubStep(data.subStepMapping), isHeading: data.isHeading });
			});
			if (additionalInfo && additionalInfo.length) {
				components.push({ problemId: e.problemId, additionalInfo: additionalInfo });
			}
		});
		if (components.length > 0) {
			let isSubStepEmpty = false;
			components.map((e) => {
				e.additionalInfo.map((data) => {
					if (data.subStepMapping && data.subStepMapping.length === 0 && !data.isHeading) {
						isSubStepEmpty = true;
					}
				});
			});
			if (isSubStepEmpty) {
				utils.showInfo("Select step(s) for all the selected task");
			} else {
				this.setState({ copyLoading: true, loading: true, saveAs: saveAs });
				this.props.dispatch({ type: eventNames.ACTION_ONE });
				const payload = {
					components: components
				};
				this.props.dispatch(ClinicalPathwayProblemAction.copyFromClinicalPathwayProblems(this.props.match.params.id, this.state.selectedCompanion.value, payload));
			}
		} else {
			utils.showInfo("Please select atleast one task!");
		}
	}

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(ClinicalPathwayProblemAction.copyFromClinicalPathwayProblemsStatus(this.state.code, {}));
			}.bind(this), 3000);
		}
	}

	isProblemCopyCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while copying problems", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	toggleModal = () => {
		this.setState({ copyLoading: false, activeStep: 0 });
		if (this.props.actions.actionInProgress) {
			this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		}
		if (!this.state.saveAs) {
			this.props.reloadDetails();
			this.props.cancelClicked();
		} else {
			this.fetchProblemList();
		}
	};

	cancelClicked = () => {
		if (this.state.saveAs) {
			this.setState({ saveAs: false }, () => {
				this.props.reloadDetails();
				this.props.cancelClicked();
			});
		} else {
			this.props.cancelClicked();
		}
	};

	render() {
		if (this.props.clinicalPathwayProblems.getProblemListForComparisonStatus === "FULFILLED") {
			this.state.problemList = this.props.clinicalPathwayProblems.problemList.mapping;
			this.state.checkList = this.props.clinicalPathwayProblems.problemList.mapping;
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetClpProblemListForComparison());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblems == "FULFILLED") {
			this.props.dispatch(ClinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblems());
			if (this.props.clinicalPathwayProblems.problemCopy && this.props.clinicalPathwayProblems.problemCopy.code) {
				this.setState({ code: this.props.clinicalPathwayProblems.problemCopy.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblems == "REJECTED") {
			this.props.dispatch(ClinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblems());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblemsStatus == "FULFILLED") {
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayProblems.problemCopyStatus && this.props.clinicalPathwayProblems.problemCopyStatus.status) {
				if (this.props.clinicalPathwayProblems.problemCopyStatus.status == "PROCESSED") {
					utils.showSuccess(i18Get("Problems copy completed successfully", utils.getLanguage()));
					this.toggleModal();
				} else if (!this.isProblemCopyCompleted(this.props.clinicalPathwayProblems.problemCopyStatus.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}
			this.props.dispatch(ClinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblemsStatus());
		}

		if (this.props.clinicalPathwayProblems.copyFromClinicalPathwayProblemsStatus == "REJECTED") {
			this.props.dispatch(ClinicalPathwayProblemAction.clearCopyFromClinicalPathwayProblemsStatus());
			this.setState({ loading: false });
		}

		//========SUB STEP======================================
		if (this.props.clinicalPathwayProblems.getAllStepsAndSubStepsStatus === "FULFILLED") {
			this.state.stepsAndSubSteps = [];
			this.state.stepsAndSubSteps = this.props.clinicalPathwayProblems.stepsAndSubSteps.mapping;
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllStepsAndSubSteps());
		}

		if (this.props.clinicalPathwayProblems.getAllStepsAndSubStepsStatus === "REJECTED") {
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllStepsAndSubSteps());
		}

		return (
			<div className='display-grid'>
				<div className="col-md-12 padding-bottom-10 padding-right-0 padding-left-0">
					<div className="type_block padding-left-0">
						<div className="w_100 padding-0 "><span className="steps_label_special padding-top-0 margin-top-0"><I18 tkey='Type' /></span></div>
						<div className="w_100 padding-0">
							<div className="btn-group button-group">
								<button type="button" onClick={() => this.toggleClinicalPathwayCategory("CLP")} className={`width_50 uppercase with_border ${this.state.clinicalPathwayCategory === "CLP" ? " active-btton" : ""}`}>
									<I18 tkey='CLINICAL PATHWAY' />
								</button>
								<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("CL")} className={`width_100 uppercase  with_border ${this.state.clinicalPathwayCategory === "CL" ? " active-btton" : ""}`}>
									<I18 tkey='CHECK LIST' />
								</button>
								<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("MDL")} className={`width_100 uppercase with_border  ${this.state.clinicalPathwayCategory === "MDL" ? " active-btton" : ""}`}>
									<I18 tkey='MODEL' />
								</button>
								<button type="button" data-toggle="dropdown" onClick={() => this.toggleClinicalPathwayCategory("ALL_ITEMS")} className={`width_150 uppercase with_border ${this.state.clinicalPathwayCategory === "ALL_ITEMS" ? " active-btton" : ""}`}>
									<I18 tkey='ALL' />
								</button>
							</div>
						</div>
					</div>

					<div className="clp_block padding-0">
						<div className="col-md-12 padding-0"><span className="steps_label_special padding-top-0 margin-top-0"><I18 tkey={`${this.state.clinicalPathwayCategory === "MDL" ? "Model" : this.state.clinicalPathwayCategory === "CL" ? "Check List" : "Clinical Pathway" + (this.state.clinicalPathwayCategory === "ALL_ITEMS" ? `(${i18Get("All", utils.getLanguage())})` : "")}`} /> <b >B</b></span></div>
						<div className="col-md-12 padding-0">
							<div className="w_100 di position_relative react_dropdown_scroll z-index-99">
								<SourceComponents
									selectedData={this.state.selectedCompanion}
									isCheckList={this.state.clinicalPathwayCategory === "CL"}
									sourceType={this.state.clinicalPathwayCategory}
									onChange={this.onChangeClpData.bind(this)}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 padding-0 margin-bottom-10 padding-top-5">
					<div className="col-md-12 padding-0"><span className="steps_label_special padding-top-0 margin-top-0"><I18 tkey='Compare By ' /></span></div>
					<div className="col-md-12 padding-0">
						<div className="btn-group button-group float_left">
							<button type="button" onClick={() => this.toggleConditions("1")} className={`width_50 uppercase with_border ${this.state.comparisonMethod === "1" ? " active-btton" : ""}`}>
								<I18 tkey='IN A AND IN B' />
							</button>
							<button type="button" data-toggle="dropdown" onClick={() => this.toggleConditions("2")} className={`width_100 uppercase  with_border ${this.state.comparisonMethod === "2" ? " active-btton" : ""}`}>
								<I18 tkey='IN A AND NOT IN B' />
							</button>
							<button type="button" data-toggle="dropdown" onClick={() => this.toggleConditions("3")} className={`width_100 uppercase with_border  ${this.state.comparisonMethod === "3" ? " active-btton" : ""}`}>
								<I18 tkey='IN B AND NOT IN A' />
							</button>
						</div>
					</div>
				</div>
				{
					this.state.problemList ?
						<TableData
							list={this.state.problemList}
							checkList={this.state.checkList}
							clinicalPathwayData={this.props.clinicalPathwayData}
							comparisonMethod={this.state.comparisonMethod}
							getSelectedComponents={this.getSelectedComponents.bind(this)}
							stepsAndSubSteps={this.state.stepsAndSubSteps}
							srcAndDestClpSame={this.state.srcAndDestClpSame}
						/> : 
						<div className='empty_data'>
							<GitCompare />
						</div>
				}
				<div className="copy_btn_modal pop_button_wrapper">
					<Button type="primary" className="margin-right-10 " onClick={this.cancelClicked}><I18 tkey='Cancel' /></Button>
					<ActionButton disabled={this.state.comparisonMethod === "3" && this.state.checkList.length > 0 ? false : true} type="primary " className="font-size-14 padding-bottom-5 padding-top-5 margin-right-10" onClick={this.saveClicked.bind(this, false)} value={i18Get("Copy", utils.getLanguage())} />
					<ActionButton disabled={this.state.comparisonMethod === "3" && this.state.checkList.length > 0 ? false : true} type="primary " className="font-size-14 padding-bottom-5 padding-top-5" onClick={this.saveClicked.bind(this, true)} value={i18Get("Copy Next", utils.getLanguage())} />
				</div>
			</div>);
	}
}

ClpComparison.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(ClpComparison));