import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { Modal } from "antd";
import communitySectionAction from "../../../../../../../../../actions/community-section";
import ReactTable from "../../../../../../../../../plug/reactTable";
import "../../../../../../../../../plug/reactTable/react-table.css";
import Loader from "../../../../../../../../../plug/loader";
import column from "./column";
import SimplePopupTable from "../../../../../../../../../plug/simple-mode-table";

class SectionClassesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySectionList: [],
			page: utils.checkForParameter("page", this.props.location) ? parseInt(utils.checkForParameter("page", this.props.location)) : 1,
			pageSize: utils.checkForParameter("pageSize", this.props.location) ? parseInt(utils.checkForParameter("pageSize", this.props.location)) : localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : 10,
			sort: "",
			desc: 1,
			refresh: false,
			search: "",
			totalPages: 1,
			loading: true,
			deleteVisible: false,
			modelVisible: false,
			consumable: true,
			modalData: {},
			deletedConsumableClass: {},
			type: "",
			fetchAllMeta: null,
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			filter: {
				search: "",
				consumableType: "ALL"
			},
			showAssingPopup: false,
			assingRow: {},
			viewSpeciality: false,
			specialityList: []

		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillMount() {
		this.props.totalCount(0);
	}

	editClicked(sectionClass) {
		return () => {
			this.props.visibleEditModal(sectionClass);
		};
	}

	deleteClicked(tabSectionClass) {
		return () => {
			this.setState({ deletedConsumableClass: tabSectionClass, deleteVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	deleteConsumableClass() {
		this.props.dispatch(communitySectionAction.deleteCommunitySection(this.state.deletedConsumableClass.id));
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	filterChanged(filter) {
		const prevState = this.state;
		prevState.search = filter;
		prevState.page = 1;
		this.setState(prevState, this.fetchDataFromServer());
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = this.props.sort ? this.props.sort : "";
			prevState.desc = this.props.desc ? this.props.desc : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.props.setSort(prevState.sort, prevState.desc);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.setState({ loading: true });
		let queryString = "?";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.filter.selectedStatus ? `&status=${this.state.filter.selectedStatus}` : "";
		queryString += this.state.filter.selectedHospital && this.state.filter.selectedHospital.value ? `&hospitalId=${this.state.filter.selectedHospital.value}` : "";
		queryString += this.state.filter.search ? `&code=${encodeURIComponent(this.state.filter.search)}&name=${encodeURIComponent(this.state.filter.search)}&` : "";
		this.state.fetchAllMeta = Date.now();
		this.props.dispatch(communitySectionAction.getAll(queryString, this.state.fetchAllMeta));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.rowScrollIndex = 0;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	fetchOnFilterChange(filter, ignorePageChange) {
		if (!ignorePageChange) {
			this.state.page = 1;
		}
		this.state.loading = true;
		this.state.filter = filter;
		this.fetchDataFromServer();
	}

	onPageSizeChange(pageSize) {
		this.state.page = 1;
		this.state.pageSize = pageSize;
		this.state.rowScrollIndex = 0;
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	viewSpecialityList(list) {
		return () => {
			list.sort((a, b) => a.name.localeCompare(b.name));
			this.setState({ viewSpeciality: true, specialityList: list });
		};
	}
	closeView() {
		this.setState({ viewSpeciality: false, specialityList: [] });
	}

	renderList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.communitySectionList, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this),
					column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.viewSpecialityList.bind(this)), true, false,
					this.onPageChange.bind(this), "COMMUNITY SECTION", "Virtualized_react_table_scroll", this.onPageSizeChange.bind(this))}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex}
			/>);
	}

	render() {
		if (this.props.communitySection.isFetchAllCommunitySectionCompleted === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.communitySection.fetchAllElementClassMeta) {
				this.state.loading = false;
				this.state.refresh = false;
				this.props.createClassCompleted(false);
				this.state.communitySectionList = this.props.communitySection.communitySection ? this.props.communitySection.communitySection : [];
				this.state.totalPages = parseInt(Math.ceil(this.props.communitySection.count / this.state.pageSize));
				this.props.totalCount(this.props.communitySection.count);
			}
			this.props.dispatch(communitySectionAction.clearGetAll());
		}
		if (this.props.communitySection.deleteCommunitySectionComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(communitySectionAction.clearDeleteCommunitySection());
		}

		if (this.props.communitySection.filterChangedCommunitySection) {
			this.fetchOnFilterChange(this.props.communitySection.filterChangedCommunitySection, this.props.communitySection.ignorePageChangeCommunitySection);
			this.props.dispatch(communitySectionAction.filterChangeCommunitySection(null, false));
		}

		if (this.props.refresh !== this.state.refresh) {
			this.state.refresh = this.props.refresh;
			this.fetchDataFromServer();
		}
		return (
			<Fragment>
				<div className=" di w_100">
					{this.renderList()}
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Community Section", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteConsumableClass.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedConsumableClass.class}</span> ?
					</div>
				</Modal>
				{this.state.viewSpeciality &&
					<SimplePopupTable
						title={"SPECIALITY LIST"}
						visible={this.state.viewSpeciality}
						onCancel={this.closeView.bind(this)}
						footer={false}
						data={this.state.specialityList}
					/>
				}

			</Fragment>
		);
	}
}

SectionClassesList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	communitySection: store.communitySection,
	translations: store.translations
}))(SectionClassesList));                    