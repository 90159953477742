import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import ActionButton from "../../../../../plug/action-button";
import events from "../../../../../actions/events";
import utils from "../../../../../utils/utils";
import loginActions from "../../../../../actions/user/login";
import I18, { i18Get } from "../../../../../i18";
import SVG from "../../../../../plug/svg";
import Suspended from "../message";

const userKey = "USER_DETAILS";

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			password: "",
			invalidMail: false,
			invalidPassword: false,
			loginClicked: false,
			showPassword: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
				|| location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.loginClicked();
		}
	}

	forgotPasswordClicked() {
		this.props.history.push(locations.forgotPassword);
	}

	emailChanged(e) {
		const prevState = this.state;
		prevState.email = e.target.value;
		prevState.invalidMail = false;
		this.setState(prevState);
	}

	passwordChanged(e) {
		const prevState = this.state;
		prevState.password = e.target.value;
		prevState.invalidPassword = false;
		this.setState(prevState);
	}

	toggleShowPassword() {
		this.setState({ showPassword: !this.state.showPassword });
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.password) {
			prevState.invalidPassword = true;
		} else {
			prevState.invalidPassword = false;
		}
		if (!this.state.email) {
			prevState.invalidMail = true;
		} else {
			prevState.invalidMail = false;
		}
		if (!utils.regexEmail.test(this.state.email)) {
			prevState.invalidMail = true;
		} else {
			prevState.invalidMail = false;
		}
		this.setState(prevState);
		if (!this.state.invalidMail && !this.state.invalidPassword) {
			return true;
		}
		return false;
	}

	loginClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				email: this.state.email,
				password: this.state.password
			};
			this.props.dispatch(loginActions.userLogin(payload));
		}
	}

	render() {
		if (this.props.user.isLoginComplete === "FULFILLED") {
			if (this.props.user.loginData.message_code === 1) {
				this.props.history.push("/");
			} else {
				utils.showError(this.props.user.loginData.data.message);
				localStorage.setItem(userKey, "");
				this.props.dispatch(loginActions.clearLogin());
			}
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.user.isLoginComplete === "REJECTED") {
			this.props.dispatch(loginActions.clearLogin());
			this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
		}
		return (
			<div className="login_input_container margin-top-35">
				<div className="input_group di w_100">
					<img src="images/email_icon.png" />
					<div className="group w_100">
						<input maxLength="120" className="input_icon login_input" type="text" value={this.state.email} onChange={this.emailChanged.bind(this)} required />
						<span className="highlight" />
						<span className="bar1" />
						<label><I18 tkey="Email Address" /></label>
						{this.state.invalidMail && <span className="invalid fixed_label"><I18 tkey="Enter a valid email" /></span>}
					</div>
				</div>
				<div className="input_group di margin-top-20 w_100">
					<img className="width_15 input_icon" src="images/password_icon.png" />
					<div className="group w_100 reset_password_eye">

						<input maxLength="30" className="input_icon login_input" type={this.state.showPassword ? "text" : "password"} value={this.state.password} onChange={this.passwordChanged.bind(this)} required />
						<a onClick={this.toggleShowPassword.bind(this)}>
							{this.state.showPassword
								? <SVG src="/images/eye-slash.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
								: <SVG src="/images/eye.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
						</a>

						<span className="highlight" />
						<span className="bar1" />
						<label><I18 tkey="Password" /></label>
						{this.state.invalidPassword && <span className="invalid fixed_label"><I18 tkey="Enter password" /></span>}
					</div>
				</div>
				<div className="forgot_password float_right" onClick={this.forgotPasswordClicked.bind(this)}>
					<a>
						<I18 tkey="Forgot Password" />
						{" "}
            ?
					</a>
				</div>
				<div className="login_button di w_100 text_align_center margin-top-35">
					<ActionButton type="button" onClick={this.loginClicked.bind(this)} value={i18Get("SIGN IN", utils.getLanguage())} />
				</div>
				<Suspended email={this.state.email} />	
			</div>
		);
	}
}

Login.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	actions: store.actions,
	settings: store.settings,
	translations: store.translations
}))(Login));
