import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
	Modal, Button
} from "antd";
import { i18Get } from "../../../../../i18";
import "../../../../../plug/reactTable/react-table.css";
import aneasthesiaAction from "../../../../../actions/anaesthesia";
import "./aneasthesia-list.css";
import { typeActivity } from "../../../../../constants";

class InterventionDeleteModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			treatmentId: "",
			isloading: false,
			modalVisible: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modalVisible = this.props.visible;
	}

	cancelClicked = () => {
		this.setState({
			modalVisible: false
		});
		this.props.deleteCompleted();
	};

	handleOk = () => {
		if (this.props.treatmentId) {
			this.props.dispatch(aneasthesiaAction.removeAnaesthesia(this.props.treatmentId));
		}
	};

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th>
							{i18Get("NAME")}
						</th>
						<th>
							{i18Get("TYPE ACTIVITY")}
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map(data => {
				return (
					<>
						<tr key={data.name}>
							<td key={data.name}><div className='linked_data_count'>{data.name}</div></td>
							<td key={data.typeActivity}><div className='linked_data_count'>{typeActivity[data.typeActivity]}</div></td>
						</tr>
					</>
				);
			});
		}
	}


	render() {
		const LinkedList = this.props.anaesthesia.aneasthesiaLinkList;
		if (this.props.anaesthesia.deleteAnaesthesiaStatus === "FULFILLED") {
			this.setState({ modalVisible: false });
			this.props.dispatch(aneasthesiaAction.removeAnaesthesiaCompleted());
			this.props.deleteCompleted();
		}
		if (this.props.anaesthesia.deleteAnaesthesiaStatus === "REJECTED") {
			this.setState({ modalVisible: false });
			this.props.dispatch(aneasthesiaAction.removeAnaesthesiaCompleted());
		}

		return (
			<div>
				<Modal
					open={this.state.modalVisible}
					title="Deletion of Anaesthesia"
					onOk={this.handleOk}
					onCancel={this.cancelClicked}
					className="re_assign_modal anaesthesia_deletion_modal "
					footer={[
						
						<><div key="message" className="aneasthesia_delete_modal_left-align">Are you sure you want to delete ?</div>
							<div key="buttons" className="aneasthesia_delete_modal_right-align">
								<Button type="primary" loading={this.state.isloading} onClick={this.handleOk}>
								Delete
								</Button>
								<Button onClick={this.cancelClicked}>
								Cancel
								</Button>
							</div></>
						
					]}
				>
					<div className="w_100 margin-bottom-10 scroll_window">
						{this.props.isAneasthesiaLinked ? "Following are the list of Pathways this anaesthesia linked to" : ""}
						{this.props.isAneasthesiaLinked ? this.getTable(LinkedList) : ""}
					</div>
			
				</Modal>

			</div>
		);
	}
}

InterventionDeleteModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	anaesthesia: store.anaesthesia
}))(InterventionDeleteModel));
