import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import locations from "../../../../router/locations";
import Logo from "./logo";
import Login from "./login";
import "./right-container.css";
import ForgotPassword from "./forgotPassword";
import ResetPassword from "./resetPassword";
import Language from "../../../main-container/header/language-dropdown";

class Right extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<div className="col-xs-12 col-sm-5 col-md-5 col-lg-4 no-padding h_100 di">
				<div className="right_login_conatiner position_relative">
					<Language classWithoutTop />
					<div className="padding-left-50 padding-right-50 h_100">
						<div className="display_table w_100">
							<div className="display_table_cell">
								<Logo />
								<Switch>
									<Route exact path={locations.forgotPassword} component={ForgotPassword} />
									<Route exact path={locations.resetPassword} component={ResetPassword} />
									<Route path={locations.base} component={Login} />
								</Switch>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Right.propTypes = {
	dispatch: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Right));
