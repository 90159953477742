import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const createClass = (payload) => ({
	type: events.CREATE_ELEMENT_CLASS,
	payload: axios.post(`${Config.apiRootSecure}/consumableClass`, { ...payload })
});

const clearCreateClass = () => ({
	type: events.CLEAR_CREATE_ELEMENT_CLASS,
	payload: {}
});

const getAll = (queryString, meta) => ({
	type: events.ELEMENT_CLASS_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/consumableClass${queryString}`)
});

const clearElementClassFetch = () => ({
	type: events.CLEAR_ELEMENT_CLASS_FETCH,
	payload: {}
});

const getCosumableClass = (queryString, meta) => ({
	type: events.CONSUMABLE_CLASS_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/consumableClass${queryString}`)
});

const clearCosumableClassFetch = () => ({
	type: events.CLEAR_CONSUMABLE_CLASS_FETCH,
	payload: {}
});

const deleteConsumableClass = (id) => ({
	type: events.DELETE_ELEMENT_CLASS,
	payload: axios.delete(`${Config.apiRootSecure}/consumableClass/${id}`)
});

const clearDeleteConsumableClass = () => ({
	type: events.CLEAR_DELETE_ELEMENT_CLASS,
	payload: {}
});

const interventionListForElement = (elementId, organization, queryString) => ({
	type: events.GET_INTERVENTION_LIST_FOR_ELEMENT,
	payload: axios.get(`${Config.apiRootSecure}/elements/${elementId}/treatments/${organization}?${queryString}`)
});

const clearInterventionListForElement = () => ({
	type: events.CLEAR_GET_INTERVENTION_LIST_FOR_ELEMENT,
	payload: {}
});

const filterChangeElementClass = (filter, ignorePageChange) => ({
	type: events.FILTER_CHANGE_ELEMENT_CLASS,
	payload: { filter, ignorePageChange }
});

const getElementListForAssign = (query) => ({
	type: events.ASSIGN_ELEMENT_CLASS_LIST,
	payload: axios.get(`${Config.apiRootSecure}/elementClassDetails/element/list${query}`)
});

const getElementListForAssignCompleted = () => ({
	type: events.ASSIGN_ELEMENT_CLASS_LIST_COMPLETED,
	payload: ""
});

const assignElementListForClass = (id, data) => ({
	type: events.ASSIGN_ELEMENT_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/elementClassDetails/${id}/element/copy`, data)
});

const assignElementListForClassCompleted = () => ({
	type: events.ASSIGN_ELEMENT_CLASS_COMPLETED,
	payload: ""
});

export default {
	createClass,
	clearCreateClass,
	getAll,
	clearElementClassFetch,
	deleteConsumableClass,
	clearDeleteConsumableClass,
	interventionListForElement,
	clearInterventionListForElement,
	filterChangeElementClass,
	getElementListForAssign,
	getElementListForAssignCompleted,
	assignElementListForClass,
	assignElementListForClassCompleted,
	getCosumableClass,
	clearCosumableClassFetch
};
