import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllFamilyList = (queryString) => ({
	type: events.FETCH_ALL_FAMILY_MODEL,
	payload: axios.get(`${Config.apiRootSecure}/family?${queryString}`)
});

const clearFetchAllFamilyList = () => ({
	type: events.CLEAR_FETCH_ALL_FAMILY_MODEL,
	payload: {}
});

const assignAndApproveFamily = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/family/${id}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveFamily = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_FAMILY,
	payload: {}
});

const saveAndApproveFamily = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/family/${id}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveFamily = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_FAMILY,
	payload: {}
});

export default {
	fetchAllFamilyList,
	clearFetchAllFamilyList,
	assignAndApproveFamily,
	clearAssignAndApproveFamily,
	saveAndApproveFamily,
	clearSaveAndApproveFamily
};
