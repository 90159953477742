import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Header from "./header";
import Dashboard from "./dashboard";
import Overview from "./overview";
import locations from "../../router/locations";
import "./main-container.css";
import eventNames from "../../actions/events";
import scopeActions from "../../actions/scope";
import loginActions from "../../actions/user/login";
import events from "../../actions/events";
import translationActions from "../../actions/translation";
import permissionActions from "./overview/main-overview/administration/userManagement/rolePermissions/permissions/redux/action";
import utils from "../../utils/utils";
import ChangePassword from "./overview/profile/changePassword";

class MainContainer extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: "",
			pathname: ""
		};
	}

	UNSAFE_componentWillMount() {
	}

	componentDidUpdate() {
		if (this.state.pathname != this.props.location.pathname) {
			this.setState({ pathname: this.props.location.pathname });
			const checkCreateAndEditPathParam = this.props.location.pathname.toLowerCase().includes("create") || this.props.location.pathname.replace(/[^0-9]/g, "");
			if (checkCreateAndEditPathParam) {
				this.props.dispatch({ type: eventNames.HIDE_SHOW_SIDE_BAR, payload: { sideBar: true } });
			} else {
				this.props.dispatch({ type: eventNames.HIDE_SHOW_SIDE_BAR, payload: { sideBar: false } });
			}
		}
	}

	toggleSideBarClicked() {
		if (this.props.actions.sideBarHideShow) {
			this.props.actions.sideBarHideShow = false;
		} else {
			this.props.actions.sideBarHideShow = true;
		}
		this.props.dispatch({ type: eventNames.HIDE_SHOW_SIDE_BAR, payload: { sideBar: this.props.actions.sideBarHideShow } });
	}

	onCancelSupport() {
		this.props.history.push(locations.base);
	}

	render() {
		if (this.state.email !== this.props.user.loginData.email) {
			let queryString = "";
			queryString += "offset=0&numResults=1000";
			this.props.dispatch(scopeActions.userScope(queryString));
			this.state.email = this.props.user.loginData.email;
		}
		if (this.props.user.isLoginComplete === "FULFILLED") {
			this.props.dispatch(loginActions.clearLoginMinimal());
			const payload = {
				lang: utils.getLanguage(),
				initialLoading: true
			};
			this.props.dispatch(translationActions.syncTranslation(payload));
			this.props.dispatch(permissionActions.getUserPermissions());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.translations.isTranslationSyncCompleted === "FULFILLED") {
			this.props.dispatch(translationActions.clearSyncTranslation());
			this.props.dispatch(translationActions.getAllTranslations());
		}
		if (this.props.permissions.getUserPermissions === "FULFILLED") {
			this.props.dispatch(permissionActions.getUserPermissionCompleted());
		}
		return (
			<div className=" h_100">
				<Header />
				<Switch>
					<Route exact path={locations.base} component={Dashboard} />
					<Route path={locations.profile} component={Overview} />
					<Route path={locations.interventions} component={Overview} />
					<Route path={locations.anaesthesia} component={Overview} />
					<Route path={locations.clinical_pathway} component={Overview} />
					<Route path={locations.health_pathway} component={Overview} />
					<Route path={locations.loadLink} component={Overview} /> 
					{(!this.props.user.loginData.hospitalId) && <Route path={locations.administration} component={Overview} />}
					<Redirect to={locations.base} />
				</Switch>
				{((this.props.location.pathname !== locations.base) && (this.props.location.pathname !== locations.not_found) && (this.props.location.pathname !== locations.profile) && (this.props.location.pathname !== locations.issue)) && (
					<div onClick={this.toggleSideBarClicked.bind(this)} className={this.props.actions.sideBarHideShow ? "di sidebar_close hidden-xs hidden-sm left_zero justify-content-center " : "di sidebar_close hidden-xs hidden-sm justify-content-center "}>
						{this.props.actions.sideBarHideShow
							? <ArrowRightOutlined />
							: <ArrowLeftOutlined />}
					</div>
				) }
				
				{this.props.user && this.props.user.loginData && this.props.user.loginData.resetPassword ? <ChangePassword /> : ""}
			</div>

		);
	}
}
MainContainer.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	translations: store.translations,
	actions: store.actions,
	settings: store.settings,
	permissions: store.permissions
}))(MainContainer));
