import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const createCommunityMembers = (payload) => ({
	type: events.CREATE_COMMUNITY_MEMBERS,
	payload: axios.post(`${Config.apiRootSecure}/hospitals`, { ...payload })
});

const clearCreateCommunityMembers = () => ({
	type: events.CLEAR_CREATE_COMMUNITY_MEMBERS,
	payload: {}
});

const getAll = (queryString, meta) => ({
	type: events.COMMUNITY_MEMBERS_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/hospitals${queryString}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_COMMUNITY_MEMBERS_FETCH,
	payload: {}
});

const deleteCommunityMembers = (id) => ({
	type: events.DELETE_COMMUNITY_MEMBERS,
	payload: axios.delete(`${Config.apiRootSecure}/hospitals/${id}`)
});

const clearDeleteCommunityMembers = () => ({
	type: events.CLEAR_DELETE_COMMUNITY_MEMBERS,
	payload: {}
});

const getOne = (id) => ({
	type: events.COMMUNITY_MEMBERS_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/hospitals/${id}`)
});

const clearGetOne = () => ({
	type: events.CLEAR_COMMUNITY_MEMBERS_GET_ONE,
	payload: ""
});

const updateCommunityMembers = (id, payload) => ({
	type: events.UPDATE_COMMUNITY_MEMBERS,
	payload: axios.put(`${Config.apiRootSecure}/hospitals/${id}`, payload)
});

const updateCommunityMembersCompleted = () => ({
	type: events.UPDATE_COMMUNITY_MEMBERS_COMPLETED,
	payload: ""
});

const filterChangeCommunityMembers = (filter, ignorePageChange) => ({
	type: events.FILTER_CHANGE_COMMUNITY_MEMBERS,
	payload: { filter, ignorePageChange }
});

export default {
	createCommunityMembers,
	clearCreateCommunityMembers,
	getAll,
	clearGetAll,
	deleteCommunityMembers,
	clearDeleteCommunityMembers,
	getOne,
	clearGetOne,
	updateCommunityMembers,
	updateCommunityMembersCompleted,
	filterChangeCommunityMembers
};
