import eventNames from "../../actions/events";

const initialState = {
	specialtyData: {},
	isAssignAndApproveSpecialtyCompleted: false,
	isSaveAndApproveSpecialtyCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.SPECIALTY_MODEL_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllSpecialtyCompleted: "REJECTED"
			};

		case `${eventNames.SPECIALTY_MODEL_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.count,
					specialty: action.payload.data.data.data,
					isFetchAllSpecialtyCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				specialty: [],
				isFetchAllSpecialtyCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SPECIALTY_MODEL_GET_ALL:
			return {
				...state,
				isFetchAllSpecialtyCompleted: false
			};
		case `${eventNames.FETCH_ONE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneSpecialtyCompleted: "FULFILLED",
					selectedSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isFetchOneSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_SPECIALTY:
			return {
				...state,
				isFetchOneSpecialtyCompleted: false
			};
		case `${eventNames.ASSIGN_AND_APPROVE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isAssignAndApproveSpecialtyCompleted: "FULFILLED",
					assignAndApproveSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isAssignAndApproveSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.ASSIGN_AND_APPROVE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isAssignAndApproveSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_SPECIALTY:
			return {
				...state,
				isAssignAndApproveSpecialtyCompleted: false
			};
		case `${eventNames.SAVE_AND_APPROVE_SPECIALTY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isSaveAndApproveSpecialtyCompleted: "FULFILLED",
					assignAndApproveSpecialty: action.payload.data.data
				};
			}
			return {
				...state,
				isSaveAndApproveSpecialtyCompleted: "FULFILLED"
			};
		case `${eventNames.SAVE_AND_APPROVE_SPECIALTY}_REJECTED`:
			return {
				...state,
				isSaveAndApproveSpecialtyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_SAVE_AND_APPROVE_SPECIALTY:
			return {
				...state,
				isSaveAndApproveSpecialtyCompleted: false
			};
		default:
			return state;
	}
}
