import React, { Component } from "react";
import {
	Switch, Route, Redirect, withRouter
} from "react-router-dom";
import locations from "../../../../../../../../router/locations";
import ConsumableClassEdit from "./consumable-class";

class Edit extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					path={`${locations.consumable_definition_plain}/${locations.tabConsumableClass}`}
					render={() => <ConsumableClassEdit type={this.props.match.params.type} consumableClassId={this.props.match.params.id} />}
				/>
				<Redirect to={`${locations.consumable_definition_plain}/${locations.tabConsumable}`} />
			</Switch>
		);
	}
}

export default withRouter(Edit);
