import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import healthPathwayAction from "../../../../../../../actions/health-pathway";
import healthPathwayDetailsAction from "../../../../../../../actions/healthPathway-details";
import utils from "../../../../../../../utils/utils";
import { Steps, Button, Checkbox } from "antd";
import Loader from "../../../../../../../plug/loader";
import Select from "react-select";
import isEqual from "lodash.isequal";
import unionWith from "lodash.unionwith";
import ActiveComponents from "./active-components";

const Step = Steps.Step;
class CopyToHealthPathWays extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			healthPathway: [],
			selectedData: [],
			genericComponents: {},
			healthPathwayDetails: {},
			activeComponents: [],
			selectAll: false,
			copyLoading: false,
			listOffset: 0,
			listNumResults: 100,
			renderList: [],
			componentsForSelection: {},
			stepLoader: false,
			steps: [],
			checkAll: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.healthPathwayDetails = this.props.healthPathwayData;
		this.fetchList();
	}

	fetchList(search) {
		this.setState({ isListLoading: true });
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&`;
		queryParams += `sort=name&desc=0&${search ? `name=${search}&` : ""}`;
		queryParams += "ignoreExisting=true&hospitalId=null&";
		queryParams += `treatmentId=${this.props.match.params.id}&`;
		queryParams += this.state.healthPathwayDetails.clinicalPathway ? `clinicalPathwayType=${this.state.healthPathwayDetails.clinicalPathway}` : "";
		this.props.dispatch(healthPathwayAction.getAll(this.props.match.params.organization, queryParams));
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= 767 });
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumResults;
		this.fetchList();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchList(value);
			}, 700);
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.healthPathway.map((data) => {
			prevState.options.push({ value: data.id, label: `${data.siteId && data.value.hospitalId ? data.siteShortName : data.hospitalName ? data.hospitalName : "MOM"} ${data.name}` });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onChange(component, e) {
		this.setState({ [component]: e.target.checked, invalidComponents: false });
	}

	onOptionsChange(e) {
		const selectedData = e;
		this.setState({ selectedData: selectedData, invalidHealthPathway: false });
	}

	prevClicked() {
		this.setState({ activeStep: this.state.activeStep - 1 });
	}

	nextClicked() {
		const prevState = this.state;
		if (this.state.activeStep === 0) {
			if (this.state.selectedData.length) {
				prevState.activeStep = this.state.activeStep + 1;
			}
			else {
				prevState.invalidHealthPathway = true;
			}
		}
		else if (this.state.activeStep === 1) {
			if (Object.values(this.state.genericComponents).includes(!"")) {
				prevState.activeStep = this.state.activeStep + 1;
				prevState.invalidComponents = false;
			} else {
				prevState.invalidComponents = true;
			}
		}
		this.setState(prevState);
	}

	getActiveComponents() {
		if (Object.values(this.state.genericComponents).includes(!"")) {
			return (<ActiveComponents genericComponents={this.state.genericComponents} activeHealthPathways={this.state.selectedData} closeCopyToHealthPathways={this.props.closeCopyToHealthPathways} refreshCopyLoading={this.refreshCopyLoading} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	onCheckBoxChange(e, value) {
		if (e.target.checked) {
			this.state.genericComponents[value] = true;
			this.state.invalidComponents = false;
			this.state.checkAll = false;
		}
		else {
			this.state.genericComponents[value] = "";
			this.state.checkAll = false;
		}
		this.setState(this.state);
	}

	refreshCopyLoading = (status) => {
		this.setState({ copyLoading: status });
	};

	onChangeCheckAll(e) {
		if (e.target.checked) {
			this.state.checkAll = true;
			this.state.invalidComponents = false;
			this.state.genericComponents.HEALTH_DEFINITION = true;
			this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM = true;
			this.state.genericComponents.HEALTH_REMARKS = true;
		}
		else {
			this.state.checkAll = false;
			this.state.genericComponents.HEALTH_DEFINITION = false;
			this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM = false;
			this.state.genericComponents.HEALTH_REMARKS = false;
		}
		this.setState(this.state);
	}

	invertSelection(value) {
		let selectedData = [];
		if (value) {
			this.state.healthPathway.map((data) => {
				selectedData.push({ value: data.id, label: data.name });
				this.state.invalidHealthPathway = false;
			});
		}
		else {
			selectedData = [];
		}
		this.setState({ selectAll: value, selectedData: selectedData });
	}

	render() {

		if (this.props.healthPathway.fetchAllHealthPathway === "FULFILLED") {
			this.state.healthPathway = unionWith(this.state.healthPathway, this.props.healthPathway.healthPathway.data, isEqual);
			this.renderOptions();
			this.props.dispatch(healthPathwayAction.clearGetAll());
		}

		const directionProp = this.state.isMobile ? { direction: "vertical" } : "";

		return (<div>
			<Steps className="margin-top-20" {...directionProp} progressDot current={this.state.activeStep}>
				<Step key='source' title={i18Get("Select destination(s)", utils.getLanguage())} />
				<Step key='source' title={i18Get("Select components", utils.getLanguage())} />
				<Step key='assign to MOM' title={i18Get("Summary", utils.getLanguage())} />
			</Steps>
			{this.state.activeStep === 0 && <div className="steps-content padding-15">
				<div className="row margin-bottom-20">
					<div className="col-md-12 text_align_right"><a className="select_all" onClick={this.invertSelection.bind(this, !this.state.selectAll)}>{this.state.selectAll ? <I18 tkey='Unselect All' /> : <I18 tkey='Select All' />}</a></div>
					<div className="col-md-4"><span className="steps_label_special font_size_13px_label"><I18 tkey='Select destination(s)' /></span></div>
					<div className="col-md-8">
						<div className="w_100 di position_relative padding-top-25-steps">
							<Select
								isSearchable
								className="w100"
								isMulti={true}
								isClearable={false}
								options={this.state.options}
								onMenuScrollToBottom={this.onListScroll.bind(this)}
								onInputChange={this.onSearch.bind(this)}
								isLoading={this.state.isListLoading}
								onChange={this.onOptionsChange.bind(this)}
								value={this.state.selectedData}
								loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
								placeholder={i18Get("Select", utils.getLanguage())}
							/>
							{this.state.invalidHealthPathway && <span className='invalid fixed_label'><I18 tkey='Select destination(s)' /></span>}
						</div>
					</div>
				</div>
			</div>
			}

			{this.state.activeStep === 1 && <div >
				{this.getActiveData()}
			</div>}
			{this.state.activeStep === 2 &&
				<div>
					<div className="steps-content padding-15">
						<div className="row">
							<div className="col-md-12 copy_scroll">
								{this.getActiveComponents()}
							</div>
						</div>
					</div>
				</div>
			}
			<div className="steps-action di w_100 text_align_right">
				{this.state.copyLoading ? <div className="padding-bottom-10 copy_label"><I18 tkey="Copy to health pathways will take more time. You will not be able to close this popup" /></div> : ""}
				{this.state.activeStep > 0 && <Button type="primary" className="margin-right-10" onClick={this.prevClicked.bind(this)}><I18 tkey='Prev' /></Button>}
				{this.state.activeStep < 2 && <Button type="primary" className="margin-right-10" onClick={this.nextClicked.bind(this)}><I18 tkey='Next' /></Button>}
				{this.state.activeStep === 2 && <Button loading={this.state.copyLoading} type="primary" className="margin-right-10" onClick={this.doneClicked.bind(this)}><I18 tkey='Copy' /></Button>}
			</div>
		</div>);
	}

	getActiveData() {
		return (
			<div className="copy_scroll position_relative">
				<div className="margin-top-15 position_relative">
					{this.state.invalidComponents && <span className='invalid fixed_label'><I18 tkey='Select Components' /></span>}
					{this.state.selectedData && this.state.selectedData.length > 0 ?
						<div className="">
							<div>
								<span className="margin-left-10">
									<Checkbox
										checked={this.state.checkAll}
										onChange={(e) => this.onChangeCheckAll(e, "ALL")} />
									<span className="margin-left-10"><I18 tkey='ALL' /></span>
								</span>
							</div>
							<span className="margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.HEALTH_DEFINITION}
									onChange={(e) => this.onCheckBoxChange(e, "HEALTH_DEFINITION")} />
								<span className="margin-left-10"><I18 tkey='DEFINITION' /></span>
							</span>
							<span className="margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM}
									onChange={(e) => this.onCheckBoxChange(e, "HEALTH_PATHWAY_DIAGRAM")} />
								<span className="margin-left-10"><I18 tkey='PATHWAY' /></span>
							</span>
							<span className="margin-left-10">
								<Checkbox
									checked={this.state.genericComponents.HEALTH_REMARKS}
									onChange={(e) => this.onCheckBoxChange(e, "HEALTH_REMARKS")} />
								<span className="margin-left-10"><I18 tkey='REMARKS' /></span>
							</span>
						</div> : ""
					}
				</div>
			</div>);
	}

	getActiveHealthPathways() {
		const hcp = [];
		this.state.selectedData.map(data => {
			hcp.push(data.value);
		});
		return hcp;

	}

	doneClicked() {
		this.setState({ copyLoading: true });
		Object.keys(this.state.genericComponents).forEach(key => this.state.genericComponents[key] === "" ? delete this.state.genericComponents[key] : {});
		const payload = {
			genericComponents: this.state.genericComponents,
			destIds: this.getActiveHealthPathways()
		};
		this.props.dispatch(healthPathwayDetailsAction.copyToHealthPathways(this.props.match.params.id, payload));
	}
}

CopyToHealthPathWays.propTypes = {
	actions: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	healthPathwayDetails: store.healthPathwayDetails,
	healthPathway: store.healthPathway,
	translations: store.translations
}))(CopyToHealthPathWays));