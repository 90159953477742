import eventNames from "./event";

const initialState = {
	missConnectores: [],
	missedConnector: null,
	isDiagramUpdated: 0
};

const missedDiagramConnectores = (data) => {
	let missConnectores = [];
	if(data?.length > 0) {
		data.forEach(element => {
			if (!element.sourceID || !element.targetID) {
				missConnectores.push({ id: element.id });
			}
		});
	}
	return missConnectores;
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case eventNames.MISSED_CONNECTORES:
			return {
				...state,
				missConnectores: missedDiagramConnectores(action.payload.data)
			};
		case eventNames.SELECTED_MISSED_CONNECTORE:
		return {
			...state,
			missedConnector: action.payload.data
		};
		case eventNames.DIAGRAM_IS_UPDATED:
			return {
				...state,
				isDiagramUpdated: state.isDiagramUpdated + 1
			};
		default:
			return state;
	}
}
