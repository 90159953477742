import React, { Component } from "react";
import Select from "react-select";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils.js";

export default class StartDayDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			startDayOptions: []
		};
	}

	render() {
		return (
			<Select
				className=""
				value={this.props.startDay ? this.props.startDay : null}
				onChange={this.props.startDayChanged}
				options={this.props.startDayOptions ? this.props.startDayOptions : []}
				maxMenuHeight={220}
				isClearable
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				multi={false}

			/>
		);
	}
}
