import eventNames from "../../actions/events";

const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_SCOPE}_PENDING`:
			return {
				...state,
				isFetchAllScopeComplete: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SCOPE}_FULFILLED`:
			if (action.payload.data.message_code) {
				return {
					...state,
					isFetchAllScopeComplete: "FULFILLED",
					scope: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllScopeComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_SCOPE}_REJECTED`:
			return {
				...state,
				isFetchAllScopeComplete: "REJECTED"
			};
		case `${eventNames.CLEAR_FETCH_ALL_SCOPE}`:
			return {
				...state,
				isFetchAllScopeComplete: false
			};
		case `${eventNames.UPDATE_SCOPE}_PENDING`:
			return {
				...state,
				isUpdateScopeComplete: "PENDING"
			};
		case `${eventNames.UPDATE_SCOPE}_FULFILLED`:
			if (action.payload.data.message_code) {
				return {
					...state,
					isUpdateScopeComplete: "FULFILLED",
					updatedScope: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateScopeComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_SCOPE}_REJECTED`:
			return {
				...state,
				isUpdateScopeComplete: "REJECTED"
			};
		case `${eventNames.CLEAR_UPDATE_SCOPE}`:
			return {
				...state,
				isUpdateScopeComplete: false
			};
		case `${eventNames.DELETE_SCOPE}_PENDING`:
			return {
				...state,
				isDeleteScopeComplete: "PENDING"
			};
		case `${eventNames.DELETE_SCOPE}_FULFILLED`:
			if (action.payload.data.message_code) {
				return {
					...state,
					isDeleteScopeComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isDeleteScopeComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_SCOPE}_REJECTED`:
			return {
				...state,
				isDeleteScopeComplete: "REJECTED"
			};
		case `${eventNames.CLEAR_DELETE_SCOPE}`:
			return {
				...state,
				isDeleteScopeComplete: false
			};

		case `${eventNames.USER_SCOPE_FETCH}_PENDING`:
			return {
				...state,
				isUserScopeFetchComplete: "PENDING"
			};
		case `${eventNames.USER_SCOPE_FETCH}_FULFILLED`:
			if (action.payload.data.message_code) {
				return {
					...state,
					isUserScopeFetchComplete: "FULFILLED",
					userScope: action.payload.data.data.scopes
				};
			}
			return {
				...state,
				isUserScopeFetchComplete: "FULFILLED"
			};
		case `${eventNames.USER_SCOPE_FETCH}_REJECTED`:
			return {
				...state,
				isUserScopeFetchComplete: "REJECTED"
			};

		default:
			return state;
	}
}
