import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import I18, { i18Get } from "../../../../../../../../i18";
import healthPathwayDetailsAction from "../../../../../../../../actions/healthPathway-details";
import utils from "../../../../../../../../utils/utils";

class Definition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			images: [],
			definition: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(healthPathwayDetailsAction.getDefinition((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), this.props.type));
	}

	init() {
		const contentBlock = htmlToDraft(this.state.definition.description ? this.state.definition.description : `<p></p><p style="text-align:center;">${i18Get("NO DEFINITION FOUND", utils.getLanguage())}</p>`);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);
		}
	}

	render() {
		if (this.props.healthPathwayDetails.getHealthPathwayDefinitionCompleted === "FULFILLED") {
			this.state.definition = this.props.healthPathwayDetails.definition[0] ? this.props.healthPathwayDetails.definition[0] : {};
			this.init();
			this.props.dispatch(healthPathwayDetailsAction.clearGetDefinition());
		}
		return (
			<div className="editor_no_edit">
				<div className="table_heading">
					{/* <I18 tkey="DEFINITION" /> */}
					{!this.props.treatmentId && !this.props.exportPdf
						? <button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "definition")}><I18 tkey="EDIT" /></button>
						: <span className="text_align_right edit_button_clp" />}
				</div>
				<div className="di w_100 float_left">
					<Editor
						readonly
						toolbarHidden
						wrapperClassName="wrapper-class"
						editorClassName="editor-class no_scroll_pathway"
						toolbarClassName="toolbar-class"
						editorState={this.state.editorState}
					/>
				</div>
				<div className="di w_100">
					<div className="text_align_right di w_100 margin-bottom-20" />
				</div>
			</div>
		);
	}
}

Definition.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired
};
export default withRouter(connect((store) => ({
	healthPathwayDetails: store.healthPathwayDetails,
	translations: store.translations
}))(Definition));
