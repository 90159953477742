import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import locations from "../../../../../../router/locations";
import HealthPathwayDetailV5 from "./health-pathway-detailV5";
import healthPathwayAction from "../../../../../../actions/health-pathway";
import { Modal } from "antd";
import TopDetailsSection from "./top-details-section";
import Loader from "../../../../../../plug/loader";
import "./details.css";
import BreadCrumb from "../../breadcrumb";
import CopyToHealthPathWays from "./copy-to-healthpathways";
import CopyFromHealthPathway from "./copy-from-healthpathway";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DragWindow from "../../../../../../plug/drag-window";
import diagramActions from "../../../../../../actions/sync-fusion";
import { v4 as uuid } from "uuid";
const meta = "diagram";
class HealthPathwayDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			healthPathway: {},
			key: 1,
			isLoadingFirstTime: true,
			isLoaded: true,
			searchType: "EQUALS",
			search: "",
			selectedHospital: "",
			exportPdf: false,
			excelExport: false,
			isReadOnly: false,
			isCopyBlocked: false
		};
	}

	componentDidMount() {
		const sessionUuid = sessionStorage.getItem("MOM-HealthPathway-Edit-uuid");
		const newUuid = sessionUuid ? sessionUuid : uuid() ;
		sessionStorage.setItem("MOM-HealthPathway-Edit-uuid", newUuid);
	}
	
	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	onFetchData() {
		// if (this.state.selectedHospital && this.state.selectedHospital.value) {
		// 	this.fetchDataWithHospitalId();
		// }
		// else {
		const payload = {
			id: this.props.match.params.id,
			organization: this.props.match.params.organization
		};
		this.props.dispatch(healthPathwayAction.getOne(payload.id, new Date().getTimezoneOffset()));
		// }
	}

	fetchDataWithHospitalId() {
		let queryString = "";
		queryString += `?timeZone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(healthPathwayAction.getOneHealthPathwayUsingHospitalId(this.props.match.params.id, this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "", queryString));
	}

	generateItems() {
		const items = [];
		items.push({
			name: i18Get("Health Pathway", utils.getLanguage()),
			link: `${locations.health_pathway_plain}/${this.props.match.params.organization}`
		});
		if (this.state.healthPathway && this.state.healthPathway.name) {
			items.push({ name: this.state.healthPathway.name, link: this.props.location.pathname });
		}
		return items;
	}

	reloadDetails() {
		const prevState = this.state;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.onFetchData();
	}

	hospitalChanged(e) {
		const prevState = this.state;
		prevState.selectedHospital = e;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		if (prevState.selectedHospital) {
			this.fetchDataWithHospitalId(e);
		} else {
			this.onFetchData();
		}
	}

	handleCancel() {
		const prevState = this.state;
		prevState.copyToHealthPathways = false;
		prevState.copyFromHealthPathway = false;
		this.setState(prevState);
	}

	copyToHealthPathways = () => {
		this.setState({ copyToHealthPathways: true });
	};

	copyFromHealthPathway = () => {
		const type = "HEALTH_PATHWAY";
		this.props.dispatch(diagramActions.checkDiagramLockValidation(type, (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": sessionStorage.getItem("MOM-HealthPathway-Edit-uuid") }, meta));
		this.setState({ copyFromHealthPathway: true });
	};

	closeCopy() {
		this.onFetchData();
		this.state.increasekeyOnFetchSuccess = true;
		this.handleCancel();
	}

	printDocument = () => {
		this.setState({ exportPdf: true }, () => {
			if (document.getElementById("diagram_diagramLayer_div")) {
				document.getElementById("diagram_diagramLayer_div").style.backgroundColor = "white";
			}
			if (document.getElementById("preview-wrapper")) {
				document.getElementById("preview-wrapper").style.display = "none";
			}
			let win = window;
			win = window.open("/loader.html");
			window.oldOpen = window.open;
			const input = document.getElementById("divToPrint");
			html2canvas(input,  {
				scale: 7
			}).then((canvas) => {
				const imgData = canvas.toDataURL("image/jpeg");
				const imgWidth = 190;
				const pageHeight = 300;
				const imgHeight = (canvas.height * imgWidth) / canvas.width;
				let heightLeft = imgHeight;
				const doc = new jsPDF("pt", "mm");
				let position = 0;
				doc.addImage(imgData, "JPEG", 10, 0, imgWidth, imgHeight + 25);
				heightLeft -= pageHeight;
				while (heightLeft >= 0) {
					position = heightLeft - imgHeight;
					doc.addPage();
					doc.addImage(imgData, "JPEG", 10, position, imgWidth, imgHeight + 25);
					heightLeft -= pageHeight;
				}
				win.location = doc.output("bloburl");
				win.focus();
				this.setState({ exportPdf: false }, () => {
					if (document.getElementById("diagram_diagramLayer_div"))
					{document.getElementById("diagram_diagramLayer_div").style.backgroundColor = "";}
					if (document.getElementById("preview-wrapper")) {
						document.getElementById("preview-wrapper").style.display = "";
					}
				});
			});
		});
	};

	printExcel = () => {

		this.setState({ excelExport: !this.state.excelExport });
	};

	//=======SET READONLY VARIABLE=======================
	setReadOnly = (flag) => {
		const prevState = this.state;
		prevState.isReadOnly = flag;
		this.setState(prevState);
	};

	render() {
		if (this.props.healthPathway.fetchOneHealthPathway === "FULFILLED") {
			this.state.isLoadingFirstTime = false;
			this.state.healthPathway = this.props.healthPathway.healthPathway;
			if (this.child) {
				this.child.toggleIsLoaded();
			}
			if (this.state.increasekeyOnFetchSuccess) {
				this.state.key = this.state.key + 1;
				this.state.increasekeyOnFetchSuccess = false;
			}
			if (this.state.healthPathway && this.state.healthPathway.healthPathway !== "SURGERY") {
				this.state.showIntervention = false;
			}
			if (this.state.healthPathway.hospitalId) {
				this.state.selectedHospital = { label: this.state.healthPathway.hospitalName,  value: this.state.healthPathway.hospitalId };
			}
			this.state.isLoaded = false;
			this.props.dispatch(healthPathwayAction.cleargetOne());
		}

		if (this.props.healthPathway.fetchOneHealthPathwayUsingHospitalId === "FULFILLED") {
			this.state.isLoadingFirstTime = false;
			this.state.healthPathway = this.props.healthPathway.healthPathway;
			if (this.child) {
				this.child.toggleIsLoaded();
			}
			if (this.state.increasekeyOnFetchSuccess) {
				this.state.key = this.state.key + 1;
				this.state.increasekeyOnFetchSuccess = false;
			}
			if (this.state.healthPathway && this.state.healthPathway.healthPathway !== "SURGERY") {
				this.state.showIntervention = false;
			}
			this.state.isLoaded = false;
			this.props.dispatch(healthPathwayAction.clearGetOneHealthPathwayUsingHospitalId());
		}

		if (this.props.healthPathway.fetchOneHealthPathway === "REJECTED") {
			this.props.dispatch(healthPathwayAction.cleargetOne());
			this.props.history.push(`${locations.health_pathway_plain}/${this.props.match.params.organization}`);
		}

		if (!this.props.healthPathway.fetchOneHealthPathway && this.state.isLoadingFirstTime) {
			return (<Loader loading />);
		}

		if (!this.props.healthPathway.fetchOneClpUsingHospitalId && this.state.isLoadingFirstTime) {
			return (<Loader loading />);
		}

		// && this.props.syncfusion.lockMeta === meta
		if (this.props.syncfusion.diagramLockValidation === "REJECTED" && this.props.syncfusion.lockMeta === meta) {
			this.setState({ isCopyBlocked: true });
			this.props.dispatch(diagramActions.clearCheckDiagramLockValidation());
		}

		if (this.props.syncfusion.diagramLockValidation === "FULFILLED" && this.props.syncfusion.lockMeta === meta) {
			this.setState({ isCopyBlocked: false });
			this.props.dispatch(diagramActions.clearCheckDiagramLockValidation());
		}

		return (
			<div className="main_overview_section healthpathway_container">
				{/* <BreadCrumb items={this.generateItems()} /> */}
				<div className="clinical_pathway_breadcrumb_container">
					<div className="clinical_pathway_breadcrumb">
						<BreadCrumb items={this.generateItems()} />
					</div>
					<div className="d-flex align-items-center ">
						<div className="">
							<label className="common_label margin-right-10 margin-bottom-0"><I18 tkey='Modified on' /></label>
							<div className="clinical_pathway_modified">
								{this.state.healthPathway.updatedAt}
							</div>
						</div>
					</div>
				</div>
				<div className="position_relative w_100 di">
					<div id="divToPrint" className="mt4">
						<div className="eye_button_section w_100 padding-top-15">
							<div className="row">
								<TopDetailsSection
									healthPathway={this.state.healthPathway}
									hospitalChanged={this.hospitalChanged.bind(this)}
									selectedHospital={this.state.selectedHospital}
									copyToHealthPathways={this.copyToHealthPathways.bind(this)}
									copyFromHealthPathway={this.copyFromHealthPathway}
									printDocument={this.printDocument}
									exportPdf={this.state.exportPdf}
									reloadDetails={this.reloadDetails.bind(this)}
									toggleExportExcel={this.printExcel.bind(this)} 
									isReadOnly={this.state.isReadOnly}
								/>
							</div>
						</div>
						<div className={`health_pathway_content  ${this.state.exportPdf ? "scroll_disabled" : ""}`}>
							<Switch>
								<Route exact path={locations.healthPathwayDetails} render={() => <HealthPathwayDetailV5 
									healthPathway={this.state.healthPathway} 
									key={this.state.key} exportPdf={this.state.exportPdf} 
									treatmentId={this.state.selectedHospital ? this.state.selectedHospital.treatmentId : ""}
									excelExport={this.state.excelExport}
									toggleExportExcel={this.printExcel.bind(this)}
									setReadOnly={this.setReadOnly}  />} />
							</Switch>
						</div>
					</div>
					{this.state.copyToHealthPathways ?
						<Modal
							open={this.state.copyToHealthPathways}
							className="cost_modal re_assign_modal"
							title={<DragWindow title={i18Get("Copy To Health Pathways", utils.getLanguage())} /> }
							onCancel={this.handleCancel.bind(this)}
							footer={null}
							wrapClassName="drag_modal"
						>
							<CopyToHealthPathWays key={this.state.key} healthPathwayData={this.state.healthPathway} closeCopyToHealthPathways={this.closeCopy.bind(this)} />
						</Modal> : ""}
					{this.state.copyFromHealthPathway ?
						<Modal
							open={this.state.copyFromHealthPathway}
							className="cost_modal re_assign_modal"
							title={<DragWindow title={i18Get("Copy From Health Pathway", utils.getLanguage())} /> }
							onCancel={this.handleCancel.bind(this)}
							footer={null}
							wrapClassName="drag_modal"
						>
							<CopyFromHealthPathway key={this.state.key} healthPathwayData={this.state.healthPathway} closeCopy={this.closeCopy.bind(this)} isCopyBlocked={this.state.isCopyBlocked}/>
						</Modal> : ""}
				</div>
			</div>
		);
	}
}

HealthPathwayDetails.propTypes = {
	syncfusion: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	syncfusion: store.syncfusion,
	healthPathway: store.healthPathway,
	healthPathwayDetails: store.healthPathwayDetails,
	translations: store.translations
}))(HealthPathwayDetails));