export default {
	base: "/",
	not_found: "/404",
	profile: "/profile",
	forgotPassword: "/forgotPassword",
	resetPassword: "/resetPassword/:token",
	intervention: "/intervention",
	interventions: "/intervention/:organization",
	clinical_pathway: "/clinical_pathway/:organization",
	health_pathway: "/health_pathway/:organization",
	clinicalPathwayDetails: "/clinical_pathway/:organization/:id",
	healthPathwayDetails: "/health_pathway/:organization/:id",
	interventionDetails: "/intervention/:organization/:sheets/:id",
	interventionsSheets: "/intervention/:organization/:sheets",
	interventionDetailsv5: "/intervention/:organization/timeSheets/:id",
	interventions_mom: "/intervention/mom",
	interventions_plain: "/intervention",
	clinical_pathway_plain: "/clinical_pathway",
	health_pathway_plain: "/health_pathway",
	defaultValues: "/defaultValues",
	scannedTreatmentsDetails: "/intervention/:organization/scannedTreatments/:id",
	analyticalSheets: "/analyticalSheets",
	administration: "/administration",
	structure: "/administration/structure",
	activities: "/administration/activities",
	timeSheets: "/timeSheets",
	durations: "/durations",
	scannedTreatments: "/scannedTreatments",
	create: "/create",
	element: "/elements",
	extElement: "/extElements",
	elements: "/intervention/:organization/:sheets/:id/elements",
	elementdetails: "/intervention/:organization/:sheets/:id/elements/:type/:mappingId",
	extElements: "/intervention/:organization/:sheets/:id/extElements",
	sub_clinical_pathways: "/clinical_pathway/:organization/:id/sub_clinical_pathway",
	sub_health_pathways: "/health_pathway/:organization/:id/sub_health_pathway",
	ediExtElements: "/intervention/:organization/:sheets/:id/extElements/:type/:elementId",
	editSubClinicPathWay: "/clinical_pathway/:organization/:id/sub_clinical_pathway/:tsterilizationype/:subTreatmentId",
	definition: "/definition",
	remarks: "/remarks",
	diagram: "/diagram",
	loadLink: "/loadLink",
	pathway_definition: "/Pathway_Definition",
	eligibility: "/Pathway_Eligibility",
	authors_and_actions: "/Authors_And_Actions",
	roominstallation: "/roominstallation",
	patientinstallation: "/patientinstallation",
	staff: "/staff",
	staffEdit: "/staff/:specialTeamId",
	medicaldevice: "/medical_device",
	pharmacy: "/pharmacy",
	prosthesis: "/prosthesis",
	sterilization: "/sterilization",
	patient_preparation: "/patient_preparation",
	surgical_dressing: "/surgical_dressing",
	draping_fieldage: "/draping_fieldage",
	instrumentation: "/instrumentation",
	small_equipment: "/small_equipment",
	prostheses_dmi: "/prostheses_dmi",
	stitches: "/stitches",
	closing_drainages: "/closing_drainages",
	samples: "/samples",
	dressings: "/dressings",
	preparation: "/preparation",
	personnels: "/administration/personnels",
	administration_clinical_pathway: "/administration/activities/clinical_pathway",
	categoryPersonnel: "/administration/personnels/categoryPersonnel",
	editCategoryPersonnel: "/administration/personnels/categoryPersonnel/:id",
	specialities: "/administration/activities/specialties",
	editSpecialty: "/administration/activities/specialties/:id",
	activity_families: "/administration/activities/activity_families",
	edit_activity_families: "/administration/activities/activity_families/:id",
	tabMom: "mom",
	tabLocal: "local",
	clpBackup: "/administration/clpBackup",
	translations: "/administration/translations",
	createTranslations: "/administration/translations/create",
	adminPersonnel: "/administration/personnels/adminPersonnel",
	editAdminPersonnel: "/administration/personnels/adminPersonnel/:tab/:id",
	editAdminPersonnelTemplate: "/administration/personnels/adminPersonnel/templates/:id",
	editAdminPersonnelTemplateDetails: "/administration/personnels/adminPersonnel/details/:id",
	createAdminPersonnel: "/administration/personnels/adminPersonnel/:tab/create",
	tabPersonnelTemplates: "templates",
	tabTemplateDetails: "details",
	tabClinicalPathwayProblem: "problem",
	tabClinicalPathwayStep: "step",
	tabClinicalPathwaySubStep: "subStep",
	tabClinicalPathwayItems: "items",
	createAdminClinicalPathway: "/administration/activities/clinical_pathway/:tab/create",
	editAdminClinicalPathway: "/administration/activities/clinical_pathway/:tab/:id",
	editAdminClinicalPathwayProblem: "/administration/activities/clinical_pathway/problem/:id",
	editAdminClinicalPathwayStep: "/administration/activities/clinical_pathway/step/:id",
	editAdminClinicalPathwaySubStep: "/administration/activities/clinical_pathway/subStep/:id",
	editAdminClinicalPathwayItems: "/administration/activities/clinical_pathway/items/:id",
	anaesthesia_plain: "/anaesthesia",
	anaesthesia: "/anaesthesia/:organization",
	anaesthesia_mom_create: "/anaesthesia/mom/create",
	clinical_pathway_create: "/clinical_pathway/mom/create",
	health_pathway_create: "/health_pathway/mom/create",
	anaesthesia_local_create: "/anaesthesia/local/create",
	anaesthesia_detail: "/anaesthesia/:organization/:id",
	anaesthesia_edit: "/anaesthesia/:organization/:id",
	userManagement: "/administration/userManagement",
	userManagement_users: "/administration/userManagement/users",
	userManagement_create_user: "/administration/userManagement/users/create",
	userManagement_edit_user: "/administration/userManagement/users/:id",

	userManagement_role_permissions: "/administration/userManagement/rolePermissions",
	userManagement_roles: "/administration/userManagement/rolePermissions/roles",
	userManagement_create_role: "/administration/userManagement/rolePermissions/roles/create",
	userManagement_edit_role: "/administration/userManagement/rolePermissions/roles/:id",
	userManagement_permissions: "/administration/userManagement/rolePermissions/permissions",

	consumable: "/administration/consumable",
	consumable_definition_plain: "/administration/consumable/consumable-definition",
	consumable_definition: "/administration/consumable/consumable-definition/:type",
	consumable_dci: "/administration/consumable/consumable-dci",
	editConsumable_definition: "/administration/consumable/consumable-definition/:type/:id",
	tabConsumable: "consumable",
	tabConsumableClass: "consumableClass",
	benchmark: "/benchmark",
	consumable_sterilization_root: "/administration/consumable/sterilization",
	consumable_sterilization: "/administration/consumable/sterilization/:type",
	consumable_sterilization_edit: "/administration/consumable/sterilization/:type/:id",
	create_consumable_sterilization: "/administration/consumable/sterilization/:type/create",
	reusableMedicalDevices_class_tab: "reusableMedicalDevices-class",
	issue: "/issue",
	administration_sterilization: "/administration/sterilization/",
	sterilization_speciality: "/administration/sterilization/speciality",
	sterilization_speciality_create: "/administration/sterilization/speciality/create",
	sterilization_speciality_edit: "/administration/sterilization/speciality/:id",
	sterilization_families: "/administration/sterilization/families",
	sterilization_families_edit: "/administration/sterilization/families/:id",
	applications: "/administration/applications",
	community: "/administration/community",
	community_section: "/administration/community/section",
	tabSectionClass: "sectionClass",
	structure_hospital: "/administration/structure/hospital",
	structure_sites: "/administration/structure/sites"
};
