import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import config from "./config.json";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import CardLink from "../../../dashboard/cards/card-link";

class Benchmark extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1
		};
	}

	render() {
		return (
			<div className="mom-sidebar">
				{
					config.map((cardLink) => this.renderContents(cardLink))
				}
			</div>
		);
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {
		if (cardLink.expandable) {
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}
		return <CardLink data={cardLink} />;
	}
}

Benchmark.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Benchmark));
