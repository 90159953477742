import eventNames from "../../actions/events";

const initialTranslationsState = {
	clpBackupCompleted: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.GENERATE_CLP_BACKUP}_FULFILLED`:
			return {
				...state,
				backupData: action.payload.data.data,
				clpBackupCompleted: "FULFILLED"
			};
		case `${eventNames.GENERATE_CLP_BACKUP}_REJECTED`:
			return {
				...state,
				clpBackupCompleted: "REJECTED"
			};
		case eventNames.GENERATE_CLP_BACKUP_COMPLETED:
			return {
				...state,
				clpBackupCompleted: false
			};
		default:
			return state;
	}
}
