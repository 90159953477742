import eventNames from "../../actions/events";

const initialState = {
	isFetchAllServiceCompleted: false,
	totalPages: 1,
	isFetchOneServiceCompleted: false,
	selectedService: {},
	isFetchAllServiceForPoleCompleted: false,
	serviceForPole: {},
	selectedSiteName: "",
	isUpdateServiceCompleted: false,
	updatedService: {},
	isCreateServiceCompleted: false,
	isDeleteServiceCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_SERVICE}_REJECTED`:
			return {
				...state,
				isFetchAllServiceCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_SERVICE}_PENDING`:
			return {
				...state,
				isFetchAllServiceCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SERVICE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPages: action.payload.data.data.totalCount,
					service: action.payload.data.data.serviceList,
					isFetchAllServiceCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				service: [],
				isFetchAllServiceCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SERVICE_FETCH:
			return {
				...state,
				isFetchAllServiceCompleted: false
			};
		case `${eventNames.FETCH_ONE_SERVICE}_REJECTED`:
			return {
				...state,
				isFetchOneServiceCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_SERVICE}_PENDING`:
			return {
				...state,
				isFetchOneServiceCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_SERVICE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedService: action.payload.data.data,
					isFetchOneServiceCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				selectedService: [],
				isFetchOneServiceCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ONE_SERVICE:
			return {
				...state,
				selectedService: [],
				isFetchOneServiceCompleted: false
			};
		case `${eventNames.FETCH_ALL_SERVICE_FOR_POLE}_REJECTED`:
			return {
				...state,
				isFetchAllServiceForPoleCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_SERVICE_FOR_POLE}_PENDING`:
			return {
				...state,
				isFetchAllServiceForPoleCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_SERVICE_FOR_POLE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					serviceForPole: action.payload.data.data,
					isFetchAllServiceForPoleCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchAllServiceForPoleCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_SERVICE_FOR_POLE:
			return {
				...state,
				isFetchAllServiceForPoleCompleted: false
			};
		case eventNames.SET_SITE_NAME:
			return {
				...state,
				selectedSiteName: action.payload.name
			};
		case `${eventNames.UPDATE_SERVICE}_REJECTED`:
			return {
				...state,
				isUpdateServiceCompleted: "REJECTED"
			};
		case `${eventNames.UPDATE_SERVICE}_PENDING`:
			return {
				...state,
				isUpdateServiceCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_SERVICE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedService: action.payload.data.data,
					isUpdateServiceCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				updatedSite: [],
				isUpdateServiceCompleted: "FULFILLED"
			};
		case eventNames.UPDATE_CLEAR_SERVICE:
			return {
				...state,
				updatedService: [],
				isUpdateServiceCompleted: false
			};
		case `${eventNames.CREATE_SERVICE}_REJECTED`:
			return {
				...state,
				isCreateServiceCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_SERVICE}_PENDING`:
			return {
				...state,
				isCreateServiceCompleted: "PENDING"
			};
		case `${eventNames.CREATE_SERVICE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					service: action.payload.data.data,
					isCreateServiceCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				service: [],
				isCreateServiceCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_SERVICE:
			return {
				...state,
				service: [],
				isCreateServiceCompleted: false
			};
		case `${eventNames.DELETE_SERVICE}_REJECTED`:
			return {
				...state,
				isDeleteServiceCompleted: "REJECTED"
			};
		case `${eventNames.DELETE_SERVICE}_PENDING`:
			return {
				...state,
				isDeleteServiceCompleted: "PENDING"
			};
		case `${eventNames.DELETE_SERVICE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deletedId: action.payload.data.data,
					isDeleteServiceCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				deletedId: [],
				isDeleteServiceCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SERVICE_DELETE:
			return {
				...state,
				deletedId: [],
				isDeleteServiceCompleted: false
			};
		default:
			return state;
	}
}
