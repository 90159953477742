import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import { Modal } from "antd";
import elementsAction from "../../../../../../actions/elements";
import Loader from "../../../../../../plug/loader"; 
import { statusList } from "../../../../../../constants";
import AssignAndApprovePopup from "../../anaesthesia/details/assign-approve-popup";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import "../../../../../../plug/reactTable/react-table.css";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

class ElementsTable  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			medicalDevices: {},
			sorted: [],
			deleteVisible: false,
			elementToDelete: false,
			subTotal: false,
			sort: false,
			modelVisible: false,
			list: []
		};
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTION", utils.getLanguage()),
				id: "id",
				accessor: d => d,
				width: 130,
				resizable: false,
				sortable: false,
				Cell: row => (
					<div className="margin_left_auto">  
						{row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
							<a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							: <EditOutlined  className={(this.state.doctorId && row.value.isDefault) ? "edit_color margin-right-10 disabled_a_tag" : "edit_color margin-right-10"} type="edit" onClick={this.editElementClicked.bind(this, row.value)}/>
						}
						{row.value.status === statusList.STATUS.values.APPROVED ?
							<DeleteOutlined className={(this.state.doctorId && row.value.isDefault) ? "delete_color disabled_a_tag" : "delete_color"} type="delete" onClick={this.deleteElementClicked.bind(this, row.value)} />   
							: ""
						}   
					</div>
				)

			},
			{
				Header: i18Get(this.props.type.replace("_", " ").toUpperCase(), utils.getLanguage()),
				id: "name",
				accessor: d => d,
				resizable: true,
				minWidth: 200,
				Cell: row => (
					row.value.elementName ? row.value.elementName : " -- "
				)
			},
			{
				Header: i18Get("IS OPTIONAL", utils.getLanguage()),
				id: "isOptional",
				accessor: d => d.isOptional,
				resizable: true,
				show: this.props.hideColumn ? false : true,
				maxWidth: 80,
				Cell: row => (
					this.showYesOrNo(row.value)
				)
			},
			{
				Header: i18Get("COST ONE", utils.getLanguage()),
				id: "costOne",
				accessor: d => d.costOne,
				resizable: true,
				show: this.props.hideColumn ? false : true,
				maxWidth: 80,
				Cell: row => (
					this.showYesOrNo(row.value)
				)
			},
			{
				Header: i18Get("QUANTITY", utils.getLanguage()),
				id: "quantity",
				accessor: d => d.quantity,
				resizable: true,
				maxWidth: 80,
				Cell: row => (
					utils.setLocaleForCurrency(row.value)
				)
			},
			{
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				Cell: row => (
					row.value ? row.value : "Master"
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				Cell: row => (
					row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : ""
				)
			}
		]);
            
		return  tableArray; 
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, elementToDelete: false });
	}

	editElementClicked(value) {
		if (!(this.state.doctorId && value.isDefault)) {
			this.props.history.push(this.props.location.pathname + "/" + value.id + (this.state.doctorId ? "?doctorId=" + this.state.doctorId : ""));
		}    
	}

	onAssignAndApproveClick (data) {
		return () => {
			this.setState({ modelVisible: true, elementId: data.id, elementType: data.type });
		};
	}

	refreshCompleted = () => {
		const prevState = this.state;
		prevState.modelVisible = false;
		this.setState(prevState, () => {
			this.props.refreshCompleted();
		});
	};
    
	showYesOrNo(value) {
		return <div>{value ? <div className = "subsidy_bold"><I18 tkey="Yes"/></div> : <I18 tkey="No"/>}</div>;
	}

	deleteElementClicked(value) {
      
		if (!(this.state.doctorId && value.isDefault )) {
			const prevState = this.state;
			prevState.elementToDelete = value ;
			prevState.deleteVisible = true;
			this.setState(prevState);
		}
	}

	deleteElement() {
        
		this.props.dispatch(elementsAction.deleteElement(this.state.elementToDelete.id, this.props.type, this.props.match.params.id, this.state.doctorId));      
	}

	getList(list) {
		return (<ReactTable
			data={list}
			pages={this.props.totalPages}
			loading={this.props.loading}
			LoadingComponent={() => {return (<Loader loading={this.props.loading} isTable={true}/>);}}
			multiSort={false}
			pageSize={list.length ? list.length : 3}
			noDataText={this.props.loading ? "" : i18Get(`NO ${this.props.type.replace(/_/g, " ").toUpperCase()} FOUND`, utils.getLanguage())}
			columns={this.getCols()}
			className="-highlight report_react_table w_100 margin-bottom-40 medical_device_table rt-body-x_scroll-hidden"
			showPagination={false}
			pageText={i18Get("Page", utils.getLanguage())}
			ofText={i18Get("of", utils.getLanguage())}
			rowsText={i18Get("rows", utils.getLanguage())}
			getTrProps={(state, rowInfo) => {
				return {
					className: (rowInfo && state.data && state.data.length ? (this.state.doctorId && state.data[rowInfo.index].isDefault ? "disabled_row" : "") : "" )
				};
			}}
			onSortedChange = { (newSorted) => {
				this.setState({ sorted: newSorted });
			}}                                       
			sorted={this.state.sorted}
		/>);
	}

	render() {
		this.state.doctorId = this.props.doctorId;

		if (this.state.type !== this.props.type) {
			this.state.list = [];
			this.state.subTotal = false;
			this.state.type = this.props.type;
		}
        
		if (this.props.elements.isElementGetCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.list = this.props.elements.elementsData.data;
			this.state.subTotal = this.props.elements.elementsData.subTotal;
			this.props.dispatch(elementsAction.clearGet());
		}
		if (this.props.elements.isElementDeleteCompleted === "FULFILLED") {
			this.state.deleteVisible = false;
			this.props.dispatch(elementsAction.clearDelete());
		}
		return (            
			<div >
				{this.getList(this.state.list)}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteElement.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> {this.state.elementToDelete.name} ?
					</div>
				</Modal>

				{  this.state.modelVisible ?
					<div>
						<AssignAndApprovePopup
							refreshCompleted={this.refreshCompleted}
							elementId={this.state.elementId}
							exType={this.state.type}
							type = {this.state.elementType}
							showAssignAndApprove={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

ElementsTable.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	elements: store.elements,
	translations: store.translations
}))(ElementsTable));