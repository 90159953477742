import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../i18";
import locations from "../../../../../router/locations";
import Anaesthesia from "../elements/anaesthesia";
import Risks from "../elements/text-element/risk";
import Staff from "../elements/staff";
import TextElement from "./text-element";
import MainSection from "./main-section";
import interventionActions from "../../../../../actions/intervention";
import extElements from "../../../../../constants/extElements.json";
import "./ext-elements.css";
import utils from "../../../../../utils/utils";
import typeActivity from "../../../../../constants/typeActivity.json";
import screenId from "../../../../../router/screenIdList";

class ExtElements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervention: i18Get("Intervention Details", utils.getLanguage())
		};
	}

	UNSAFE_componentWillMount() {
		const payload = {
			organization: this.props.match.params.organization,
			id: this.props.match.params.id,
			organizationMode: "EXTENDED",
			showAnaesthesia: false,
			showStaff: false,
			cost: false

		};
		this.props.dispatch(interventionActions.getOneIntervention(payload.id, new Date().getTimezoneOffset()));
	}

	renderRoutes() {
		return extElements.type.map((type, index) => <Route key={index + "_"} path={locations.extElements + locations[type.toLowerCase()]} render={() => <MainSection key={index} type={type} tabs={extElements.elementMatch[type]} intervention={this.state.intervention} doctorId={utils.checkForParameter("doctorId", this.props.location)} screenId={screenId[extElements.screenId[type]]} typeActivity={typeActivity.INTERVENTION.toUpperCase()} />} />);
	}

	renderEditRoutes() {
		return extElements.type.map((type, index) => <Route key={index + "_"}  path={`${locations.extElements + locations[type.toLowerCase()]}/:elementId`} render={() => <MainSection key={index} type={type} intervention={this.state.intervention} doctorId={utils.checkForParameter("doctorId", this.props.location)} screenId={screenId[extElements.screenId[`${type}_EDIT`]]} typeActivity={typeActivity.INTERVENTION.toUpperCase()} />} />);
	}

	renderTextElements() {
		return extElements.textType.map((type, index) => <Route key={index + "_"}  path={locations.extElements + locations[type.toLowerCase()]} render={() => <TextElement type={type} intervention={this.state.intervention} typeActivity={typeActivity.INTERVENTION.toUpperCase()} screenId={screenId[extElements.screenId[type]]} />} />);
	}

	render() {
		if (this.props.intervention.getOneIntervention === "FULFILLED") {
			this.state.intervention = this.props.intervention.treatment.name;
			this.props.dispatch(interventionActions.clearGetOneIntervention());
		}
		return (
			<div className="main_overview_section">
				<div className="">
					<Switch>
						<Route path={locations.extElements + locations.anaesthesia_plain} render={() => <Anaesthesia type="anaesthesia" intervention={this.state.intervention} typeActivity={typeActivity.INTERVENTION.toUpperCase()} screenId={screenId.timeEditAnaesthesia} />} />
						<Route path={locations.extElements + locations.risks} render={() => <Risks type="risk" intervention={this.state.intervention} screenId={screenId.timeEditRisk} />} />
						{/* <Route path={locations.extElements + locations.patientinstallation} render={() => <PatientInstallation type='position' v5type={utils.toPascalCase(extElements.otherElements.PATIENT_INSTALLATION.replace(/_/g, ' '))} intervention={this.state.intervention} screenId={screenId.timeEditPatientInstallation}/>}/>
                    <Route path={locations.extElements + locations.roominstallation} render={() => <RoomInstallation type='INSTALLATION' v5type={utils.toPascalCase(extElements.otherElements.ROOM_INSTALLATION.replace(/_/g, ' '))} intervention={this.state.intervention} screenId={screenId.timeEditRoomInstalltion}/>}/> */}
						<Route path={locations.extElements + locations.staff} render={() => <Staff typeActivity={typeActivity.INTERVENTION.toUpperCase()} details={this.state.intervention} screenId={screenId.timeEditStaffList} />} />
						{this.renderEditRoutes()}
						{this.renderTextElements()}
						{this.renderRoutes()}
					</Switch>
				</div>
			</div>
		);
	}
}

ExtElements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	intervention: store.intervention,
	translations: store.translations
}))(ExtElements));
