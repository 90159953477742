import eventNames from "./event";

const initialState = {
	fetchAllHealthPathWayDetailsRegroupment: false,
	healthPathWayDetailsRegroupment: [],
	fetchAllHealthPathWayLinkedTaskDropDownList: false,
	HealthPathWayLinkedTaskDropDownList: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_LINKED_GROUP_TASK_LIST_DROP_DOWN}_FULFILLED`:
			return {
				...state,
				HealthPathWayLinkedTaskDropDownList: action.payload.data.data,
				fetchAllHealthPathWayLinkedTaskDropDownList: "FULFILLED"
			};
		case `${eventNames.GET_LINKED_GROUP_TASK_LIST_DROP_DOWN}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayLinkedTaskDropDownList: "REJECTED"
			};
	
		case eventNames.CLEAR_GET_LINKED_GROUP_TASK_LIST_DROP_DOWN:
			return {
				...state,
				HealthPathWayLinkedTaskDropDownList: [],
				fetchAllHealthPathWayLinkedTaskDropDownList: false
			};
		default:
			return state;
	}
}
