import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../router/locations";
import List from "./list";
import Edit from "./edit";
import Create from "./create";

export default class AdminPersonnelRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="tab-content">
				<div id="home" className="tab-pane fade in active">
					<Switch>
						<Route
							exact
							path={locations.createAdminClinicalPathway}
							render={() => <Create {...this.props} />}
						/>
						<Route
							exact
							path={locations.editAdminClinicalPathway}
							render={() => <Edit {...this.props} />}
						/>
						<Route
							path={locations.administration_clinical_pathway}
							render={() => <List {...this.props} />}
						/>
						<Redirect to={`${locations.administration_clinical_pathway}/${(this.props.tab ? this.props.tab : locations.tabClinicalPathwayProblem)}`} />
					</Switch>
				</div>
			</div>
		);
	}
}
