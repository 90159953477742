import { configureStore } from "@reduxjs/toolkit";
import Promise from "redux-promise-middleware";
import loggerMiddleware from "./logger";
import rootReducer from "../reducers";
import monitorReducersEnhancer from "./monitor";
import { routerMiddleware } from "react-router-redux";
import { createHashHistory } from "history";
export const history = createHashHistory();
export function configureAppStore(preloadedState) {
	const store = configureStore({
		reducer: rootReducer,
		middleware: [routerMiddleware(history), loggerMiddleware, Promise],
		preloadedState,
		enhancers: [monitorReducersEnhancer]
	});    return store;
}
const store = configureAppStore();
export default store;