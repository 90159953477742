import eventNames from "../../actions/events";
import utils from "../../utils/utils";

const initialTranslationsState = {
	isTranslationFetchCompleted: false,
	language: "en",
	isResetLanguageComplete: false,
	data: {},
	languages: {},
	translations: {},
	isTranslationFetchForLanguageCompleted: false,
	isTranslationDeleteCompleted: false,
	isTranslationCreateCompleted: false,
	isTranslationUpdateCompleted: false
};

const key = "SELECTED_LANGUAGE";
const jsonKey = "TRANSLATIONS";

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.TRANSLATION_FETCH}_FULFILLED`:
			if (action.payload.data.data && Object.keys(action.payload.data.data).length) {
				localStorage.setItem(jsonKey, JSON.stringify(action.payload.data.data));
			}
			return {
				...state,
				languages: action.payload.data.data,
				isTranslationFetchCompleted: "FULFILLED"
			};
		case `${eventNames.TRANSLATION_FETCH}_REJECTED`:
			return {
				...state,
				isTranslationFetchCompleted: "REJECTED"
			};
		case eventNames.RESET_TRANSLATION_LANGUAGE:
			utils.setLanguage(action.language);
			localStorage.setItem(key, action.language);
			return {
				...state,
				language: action.language,
				isResetLanguageComplete: true
			};
		case eventNames.CLEAR_RESET_FLAG:
			return {
				...state,
				isResetLanguageComplete: false,
				isTranslationFetchCompleted: false
			};
		case eventNames.TRIGGER_TRANSLATION_JSON_GET:
			var languages = JSON.parse(localStorage.getItem(jsonKey) ? localStorage.getItem(jsonKey) : "{}");
			return {
				...state,
				languages
			};
		case `${eventNames.GET_ALL_TRANSLATION_FOR_LANGUAGE}_REJECTED`:
			return {
				...state,
				isTranslationFetchForLanguageCompleted: "REJECTED"
			};

		case `${eventNames.GET_ALL_TRANSLATION_FOR_LANGUAGE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					translations: action.payload.data.data,
					isTranslationFetchForLanguageCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isTranslationFetchForLanguageCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ALL_TRANSLATION_FOR_LANGUAGE:
			return {
				...state,
				isTranslationFetchForLanguageCompleted: false
			};
		case `${eventNames.DELETE_TRANSLATION}_REJECTED`:
			return {
				...state,
				isTranslationDeleteCompleted: "REJECTED"
			};

		case `${eventNames.DELETE_TRANSLATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isTranslationDeleteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isTranslationDeleteCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_TRANSLATION:
			return {
				...state,
				isTranslationDeleteCompleted: false
			};
		case `${eventNames.CREATE_TRANSLATION}_REJECTED`:
			return {
				...state,
				isTranslationCreateCompleted: "REJECTED"
			};

		case `${eventNames.CREATE_TRANSLATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isTranslationCreateCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isTranslationCreateCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_TRANSLATION:
			return {
				...state,
				isTranslationCreateCompleted: false
			};
		case `${eventNames.UPDATE_TRANSLATION}_REJECTED`:
			return {
				...state,
				isTranslationUpdateCompleted: "REJECTED"
			};

		case `${eventNames.UPDATE_TRANSLATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isTranslationUpdateCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isTranslationUpdateCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_TRANSLATION:
			return {
				...state,
				isTranslationUpdateCompleted: false
			};
		case `${eventNames.SYNC_TRANSLATION}_REJECTED`:
			return {
				...state,
				isTranslationSyncCompleted: "REJECTED"
			};

		case `${eventNames.SYNC_TRANSLATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isTranslationSyncCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isTranslationSyncCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_SYNC_TRANSLATION:
			return {
				...state,
				isTranslationSyncCompleted: false
			};
		default:
			return state;
	}
}
