import React, { Component } from "react";
import Select from "react-select";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils.js";

export default class EndDayDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			endDayOptions: []
		};
	}

	render() {
		return (
			<Select
				className=""
				value={this.props.endDay ? this.props.endDay : null}
				onChange={this.props.endDayChanged}
				options={this.props.endDayOptions ? this.props.endDayOptions : []}
				maxMenuHeight={220}
				isClearable
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				multi={false}
			/>
		);
	}
}
