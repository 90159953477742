import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import ActionButton from "../../../../../plug/action-button";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import forgotPasswordActions from "../../../../../actions/user/forgotPassword";
import Suspended from "../message";

class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidEmail: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
            || location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.onSubmitClicked();
		}
	}

	handleInputChange(field) {
		return (e) => {
			const prevState = this.state;
			prevState[field] = e.target.value;
			prevState.invalidEmail = false;
			this.setState(prevState);
		};
	}

	onLoginClicked() {
		this.props.history.push(locations.base);
	}

	validateFields() {
		if (!utils.regexEmail.test(this.state.email)) {
			const prevState = this.state;
			prevState.invalidEmail = true;
			this.setState(prevState);
			return false;
		}
		return true;
	}

	onSubmitClicked() {
		if (this.validateFields()) {
			const payload = {
				email: this.state.email
			};
			this.props.dispatch(forgotPasswordActions.forgotPassword(payload));
		}
	}

	render() {
		if (this.props.user.isForgotPasswordComplete === "FULFILLED") {
			this.props.dispatch(forgotPasswordActions.clearForgotPassword());
			this.props.history.push(locations.base);
		}
		return (
			<div className="login_input_container margin-top-35">
				<div className="input_group di w_100 ">
					<img src="images/email_icon.png" />
					<div className="group w_100 margin-bottom-0">
						<input
							maxLength="120"
							value={this.state.email}
							onChange={this.handleInputChange("email").bind(this)}
							className="input_icon login_input"
							type="text"
							required
						/>
						<span className="highlight" />
						<span className="bar1" />
						<label><I18 tkey="Email Address/Telephone" /></label>
						{this.state.invalidEmail && <span className="invalid"><I18 tkey="Enter a valid email" /></span>}
					</div>
				</div>

				<div className="login_button di w_100 text_align_center margin-top-35">
					<ActionButton type="button" onClick={this.onSubmitClicked.bind(this)} actionName={this.props.actions.actionOneInProgress} value={i18Get("SUBMIT", utils.getLanguage())} />
				</div>
				<div className="forgot_password float_right margin-top-15" onClick={this.onLoginClicked.bind(this)}><a><I18 tkey="Login" /></a></div>
				<Suspended email={this.state.email} />
			</div>
		);
	}
}

ForgotPassword.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	actions: store.actions,
	translations: store.translations
}))(ForgotPassword));
