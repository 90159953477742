import React, { Component } from "react";
import { Popover, Icon } from "antd";
import Parser from "html-react-parser";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import "./record-popover.css";
import { EllipsisOutlined } from "@ant-design/icons";

class RecordPopover extends Component {
	constructor(props) {
		super(props);

		this.state = {
			title: "Title",
			content: "",
			popoverHeight: 220
		};
	}

	convertString(input) {
		return input.split("<a").join("<a target=\"_blank\"");
	}

	parse(value) {
		return Parser(this.convertString(value));
	}

	renderContentItem(name, value, parse) {
		return (
			<div className="padding-left-10 record_key_value_div">
				<p className="f-bold f-12 record_key_name">
					{i18Get(name, utils.getLanguage())}
          :
				</p>
				<div className="f-12 padding-left-16 bottom-margin-8">{parse ? this.parse(value) : value}</div>
			</div>
		);
	}

	render() {
		const { record } = this.props;
		if (record) {
			this.state.title = (
				<div>
					<div>
						<span className="f-bold f-14">
							{record.item}
							{record.itemOrder && record.itemOrder !== "0" ? ` (${record.itemOrder})` : ""}
						</span>
					</div>
					<div>
						<span className="f-bold f-11">{record.categoryPersonnelName}</span>
					</div>
				</div>
			);
			this.state.content = (
				<div>
					<div>
						<div>
							<span className="f-bold f-14 padding-left-10">
								{record.item}
								{record.itemOrder && record.itemOrder !== "0" ? ` (${record.itemOrder})` : ""}
							</span>
						</div>
						<div>
							<span className="f-bold f-11 padding-left-10">{record.categoryPersonnelName}</span>
						</div>
					</div>
					<div>
						{
							record.problem
							&& this.renderContentItem("PROBLEM", record.problem)
						}
						{record.description
							&& this.renderContentItem("DELIVERABLE", record.description, true)}
						{record.reference
							&& this.renderContentItem("REFERENCE", record.reference, true)}
						{
							record.risk
							&& this.renderContentItem("RISK", record.risk, true)
						}
						{
							record.assessment
							&& this.renderContentItem("ASSESSMENT", record.assessment, true)
						}
						{
							record.clpHealthProblemData
							&& this.renderContentItem("DATA", record.clpHealthProblemData, true)
						}
					</div>
				</div>
			);
		}
		return (

			<div className={`position-relative popover_component_div_${record.day ? record.day : "MISC"}`}>
				<Popover placement="right" title={this.state.title} content={this.state.content}>
					<EllipsisOutlined  className="edit_color" type="ellipsis" />
				</Popover>
				<style>
					{`
          .ant-popover-inner {
            max-height:220px !important;
            overflow: auto !important;
          }
        `}
				</style>
			</div>
		);
	}
}

export default RecordPopover;
