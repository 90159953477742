import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../utils/utils";

class NumericInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.lang = utils.getLanguage();
	}

	onChange(e) {
		if ((utils.numericRegex().test(e.target.value) || !e.target.value) && !this.props.integerOnly && !this.props.percentageOnly) {
			this.props.getNumericValue(this.props.label, e.target.value);
		}
		if ((/^[0-9]*$/.test(e.target.value)) && this.props.integerOnly) {
			this.props.getNumericValue(this.props.label, e.target.value);
		}
		if (((parseInt(e.target.value) >= 0) && (parseInt(e.target.value) <= 100) || !e.target.value) && this.props.percentageOnly) {
			this.props.getNumericValue(this.props.label, e.target.value ? parseInt(e.target.value) : e.target.value);
		}
		if (this.props.parcours) {
			if (e.target.value === "-") {
				this.props.getNumericValue(this.props.label, e.target.value);
			} else if (utils.negativePossitiveRegex().test(e.target.value)) {
				this.props.getNumericValue(this.props.label, e.target.value);
			}
		}

		// if(this.props.maxValue){
		//     this.props.getNumericValue(this.props.label, (e.target.value && e.target.value<=this.props.maxValue) ?parseFloat(e.target.value)? e.target.value:0 :0 );
		// }
	}

	render() {
		if (this.state.lang !== utils.getLanguage()) {
			this.state.lang = utils.getLanguage();
			if (utils.getLanguage() === utils.engCode) {
				this.state.value = this.props.value ? utils.calculateFloatValue(this.props.value, utils.frCode) : this.props.value;
			}
			if (utils.getLanguage() === utils.frCode) {
				this.state.value = this.props.value ? utils.setLocaleForCurrency(parseFloat(this.props.value), utils.sameDropper(), this.props.integerOnly) : this.props.value;
			}
			// this.props.getNumericValue(this.props.label?this.props.label:'', this.state.value);
		}
		return (
			<div className={this.props.parcours ? "parcours_filter_numeric_input" : ""}>
				<input className={this.props.parcours ? this.props.className : "form-control w_100"} value={(this.props.value || this.props.value === 0) ? this.props.value : ""} onChange={this.onChange.bind(this)} maxLength={this.props.maxLength} disabled={!!this.props.disabled} placeholder={this.props.placeholder ? this.props.placeholder : ""} />
			</div>
		);
	}
}

NumericInput.propTypes = {
	dispatch: PropTypes.func.isRequired,
	translations: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(NumericInput));
