import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../i18";
import locations from "../../../../../../router/locations";
import Team from "./list";
import "./staff.css";
import BreadCrumb from "../../breadcrumb";
import utils from "../../../../../../utils/utils";
import screenId from "../../../../../../router/screenIdList";
import SwitchTemplate from "./switch-template";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			staff: {}
		};
	}

	getLink() {
		if (this.props.location.pathname.indexOf(/sub_clinical_pathway/) !== -1) {return `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}/sub_clinical_pathway/staff`;}

		return (this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? `/anaesthesia/${this.props.match.params.organization}/${this.props.match.params.id}/staff`
			: ((this.props.location.pathname.indexOf(/elements/) !== -1) ? `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}/elements/staff`
				: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}/extElements/staff`);
	}

	generateItems() {
		const items = [];
		if (this.props.location.pathname.indexOf(/sub_clinical_pathway/) !== -1) {
			items.push({
				name: i18Get("Clinical Pathway", utils.getLanguage()),
				link: `/clinical_pathway/${this.props.match.params.organization}`
			});
			items.push({ name: this.props.details.name ? `${this.props.details.hospitalName ? this.props.details.hospitalName : ""} ${this.props.details.name}` : "", link: `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}` });
		} else {
			items.push({
				name: (this.props.location.pathname.indexOf(/anaesthesia/) === -1) ? i18Get("Intervention", utils.getLanguage()) : i18Get("Anaesthesia", utils.getLanguage()),
				link: (this.props.location.pathname.indexOf(/anaesthesia/) === -1) ? `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}` : `/anaesthesia/${this.props.match.params.organization}`
			});
			items.push({ name: this.props.details, link: (this.props.location.pathname.indexOf(/anaesthesia/) === -1) ? `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}` : `/anaesthesia/${this.props.match.params.organization}/${this.props.match.params.id}` });
		}
		items.push({ name: i18Get("Staff", utils.getLanguage()), link: this.getLink() });
		if (this.state.staff.id) {
			items.push({ name: this.state.staff.name, link: `${this.getLink()}/${this.state.staff.id}` });
		}
		return items;
	}

	render() {
		this.state.staff = this.props.breadcrumb.componentData;
		return (
			<div className="staff_table_scroll">
				<div className="di w_100">
					<BreadCrumb useSearch items={this.generateItems()} />
					<SwitchTemplate
						isScannedTreatment={this.props.isScannedTreatment}
						typeActivity={this.props.typeActivity}
						onFetchData={() => this.list && this.list.onFetchData()}
					/>
					<Switch>
						<Route exact path={locations.elements + locations.staff} render={() => <Team screenId={screenId.analyticalEditStaffList} onRef={(ref) => this.list = ref} typeActivity={this.props.typeActivity} />} />
						<Route path={locations.extElements + locations.staff} render={() => <Team screenId={screenId.timeEditStaffList} onRef={(ref) => this.list = ref} />} />
						<Route path={locations.anaesthesia_edit + locations.staff} render={() => <Team screenId={screenId.anaesthesiaEditStaffList} onRef={(ref) => this.list = ref} typeActivity={this.props.typeActivity} />} />
						<Route path={locations.scannedTreatmentsDetails + locations.staff} render={() => <Team isScannedTreatment={this.props.isScannedTreatment} screenId={screenId.anaesthesiaEditStaffList} onRef={(ref) => this.list = ref} typeActivity={this.props.typeActivity} />} />
						<Route path={locations.sub_clinical_pathways + locations.staff} render={() => <Team isScannedTreatment={this.props.isScannedTreatment} onRef={(ref) => this.list = ref} typeActivity={this.props.typeActivity} />} />
					</Switch>
				</div>
			</div>
		);
	}
}

Staff.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	breadcrumb: store.breadcrumb
}))(Staff));
