import axios from "axios";
import Config from "../../../../../../../../../MOM-config";
import events from "./event";

const getAll = (query) => ({
	type: events.PERMISSIONS_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/permissions?${query}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_PERMISSIONS_GET_ALL,
	payload: {}

});

const getUserPermissions = () => ({
	type: events.GET_USER_PERMISSIONS,
	payload: axios.get(`${Config.apiRootSecure}/permissions/user`)
});

const getUserPermissionCompleted = () => ({
	type: events.GET_USER_PERMISSIONS_COMPLETED,
	payload: {}
});

const create = (payload) => ({
	type: events.CREATE_PERMISION,
	payload: axios.put(`${Config.apiRootSecure}/permissions`, { ...payload })
});

const clearCreate = () => ({
	type: events.CLEAN_CREATE_PERMISION,
	payload: {}

});

export default {
	getAll,
	clearGetAll,
	getUserPermissions,
	getUserPermissionCompleted,
	create,
	clearCreate
};