
import utils from "utils/utils";
  const replaceComa = (value) => {

	if(utils.getLanguage() == "fr" && value) {
		value = value.toString().replace(".", ",")
	}

	return value
  };

  const validCost = (value, preValue) => {
    const priceRegex = /^(\d{0,9}[.,]?\d{0,2})$/; 
    return priceRegex.test(value) ? value : preValue;
   }

   const validFloat = (value, preValue) => {
    const priceRegex = /^(\d{0,3}[.,]?\d{0,2})$/; 
    return priceRegex.test(value) ? value : preValue;
   }

   const floatingPointData = (value, preValue) => {
    return validFloat(value, replaceComa( preValue || "")).toString().replace(",", ".");
   };

   const convertMaxAndMinDurationToMinutes = (value, valueType) => {
    if (!value || !valueType) { 
      return parseInt(value) || 0;
    }
  
    switch (valueType) {
      case "Minutes":
        return parseFloat(value);
      case "Hours":
        return parseFloat(value) * 60;
      case "Days":
        return parseFloat(value) * 24 * 60;
      default: 0;
        break;
    }
  }

  const removeEmptyDecimalPoints = (value) => {
    const point = (value || 0) % 1;
    return (point > 0) ?  value : parseInt(value);
    
  };
  
  const replaceString = (str, search, replacement) => {
    if (!str) {
      return str;
    }
    return str.split(search).join(replacement);
  };

  const removeSQLChars = (inputString) => {
    // Define the regular expression to match SQL harmful characters
    var regex = /[\0\n\r\b\t\\\'\"\x1a]/g;
    
    // Replace SQL harmful characters with an empty string
    var sanitizedString = inputString.replace(regex, '');
    
    return sanitizedString;
}
const currentYear = () => {
	const currentDate = new Date();
	return currentDate.getFullYear();
};
export default {
	replaceComa,
	validCost,
  floatingPointData,
  convertMaxAndMinDurationToMinutes,
  removeEmptyDecimalPoints,
  replaceString,
  removeSQLChars,
  currentYear
};
