import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import actions from "../../../../../../../actions/sterilization";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

export class selectSpecialities extends Component {

	constructor(props) {
		super(props);
    
		this.state = {
			specialityData: {},
			speciality: [],
			offset: 0,
			numResults: 100,
			specialityId: "",
			search: null,
			isDataFound: true
		};
	}

	UNSAFE_componentWillMount() {
		this.state.speciality = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.specialityId = this.props.specialityId;
		this.fetchSpecialityList(true);
	}

	onSearch = (search) => {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchSpecialityList(search);
			}, 800);
		}
	};

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	onListScroll = () => {
		this.state.offset += this.state.numResults;
		this.fetchSpecialityList(this.state.search);
	};
    

	render() {
		if (this.props.sterilization.getAllSterilizationSpecialityStatus === "FULFILLED") {
			this.setState({ specialty: unionWith(this.state.specialty, this.props.sterilization.getAllSterilizationSpeciality && this.props.sterilization.getAllSterilizationSpeciality.data ? this.props.sterilization.getAllSterilizationSpeciality.data : "", isEqual) }, () => {
				this.renderspecialityOptions();
			});
			this.props.dispatch(actions.getAllSterilizationSpecialityCompleted());
			if (this.props.assignAndApprove) {
				if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
					this.fetchSpecialityList(false);
					this.state.isDataFound = false;
				}
			} 
		}

		return (
			<Select
				isDisabled={this.props.disabled}
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				value={this.props.assignAndApprove ? this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : "" : this.props.data }
				onChange={this.props.specialityChanged}
				isSearchable
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Sterilization Speciality", utils.getLanguage())} />
		);
	}

	renderspecialityOptions = () => {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.specialty) {
			this.state.specialty.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
                        
		if (!this.props.data && this.props.autoSetSingleOne === true) {
			utils.setSingleOneAsDefaultOption(prevState.options, this.props.specialityChanged);
		}

		prevState.isListLoading = false;
		this.setState(prevState);
	};


    
	fetchSpecialityList = (search) => {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += "&status=APPROVED";
		queryString += this.props.typeActivity ? `&typeActivity=${this.props.typeActivity.toUpperCase()}` : "";
		if (this.props.assignAndApprove) {
			queryString += search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "";
		}
		else {
			queryString += this.state.search ? "&name=" + this.state.search : "";
		}
		this.props.dispatch(actions.getAllSterilizationSpeciality(queryString));
	};

}


selectSpecialities.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	specialtyModel: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	specialty: store.specialty,
	translations: store.translations,
	specialtyModel: store.specialtyModel,
	sterilization: store.sterilization
}))(selectSpecialities));  
