import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "actions/admin-clinical-pathway";
import ActionButton from "plug/action-button";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import events from "actions/events";
import screenId from "router/screenIdList";
import NameInput from "plug/regex-name-search-input";
import { Modal } from "antd";

class CreateSubStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidSubStepName: null,
			invalidStep: false,
			subStepName: ""
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (this.props.inputSubStepName) {
			const prevState = this.state;
			prevState.subStepName = this.props.inputSubStepName;
			this.setState(prevState);
		}
		this.state.template = this.props.template ? this.props.template : utils.checkForParameter("templateId", this.props.location);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelCreate });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.createClicked();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	subStepNameChanged(e) {
		const prevState = this.state;
		prevState.subStepName = e;
		prevState.invalidSubStepName = false;
		this.setState(prevState);
	}

	codeChanged(e) {
		const prevState = this.state;
		if (utils.checkCodeRegex(e)) {
			prevState.code = e;
			prevState.invalidCode = false;
		} else {
			prevState.code = "";
		}
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		const findOption = this.props.adminClinicalPathway.subStepList.data.filter( e => e.name === this.state.subStepName);

		if (!prevState.subStepName || !prevState.subStepName.trim() || (prevState.subStepName.match(/\\$/)) || (prevState.subStepName.indexOf("\"") >= 0)) {
			prevState.invalidSubStepName = true;
		}
		if (!prevState.invalidSubStepName && !prevState.invalidCode) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.subStepName,
				stepCode: this.props.stepCode
			};
			this.props.setNewSubStepName(payload.name);
			this.props.dispatch(adminClinicalPathwayActions.createClinicalPathwaySubStep(this.props.stepId, payload));
		}
	}

	stepChanged(e) { 
		const prevState = this.state;
		prevState.invalidStep = false;
		prevState.step = e;
		this.setState(prevState);
	}

	render() {
		if (this.props.adminClinicalPathway.createClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwaySubStep());
		    this.props.createSubStepClosed();
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			let queryString = `offset=0&numResults=${1000}`;
			queryString += this.props.stepId ? `&clpStepId=${this.props.stepId}` : "";
			queryString += this.props.stepCode ? `&clpStepCode=${this.props.stepCode}` : "";
			this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwaySubStep(queryString));
		}
		if (this.props.adminClinicalPathway.createClinicalPathwaySubStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwaySubStep());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="bloc_create_section">
				<Modal
					className="create-sub-step-modal"
					title={"Create Sub Step"}
					onCancel={this.props.createSubStepClosed}
					open={ true }
					footer={[
						<div className="di w_100 text_align_right">
							<ActionButton
								showAdd
								onClick={this.createClicked.bind(this)}
								value={i18Get("CREATE", utils.getLanguage())}
							/>
						</div>
					]}
				>
					<div className="">
						<div className="row">
							<label className="step-name"><I18 tkey="Step: "></I18>{this.props.stepName}</label>
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name"/></label>
								<NameInput
									value={this.state.subStepName}
									inputChanged={this.subStepNameChanged.bind(this)}
									maxLength={128} />
								{this.state.invalidSubStepName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid name (Special characters " \ not allowed)' /></span> : ""}
							</div>
						</div>
					</div>
				</Modal>
			</div>

		);
	}
}

CreateSubStep.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(CreateSubStep));
