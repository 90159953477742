
import React, { Component, useEffect, useState } from "react";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
let timer;
const AutoSave = (props) => {
    
	const [autosaveInterval, setAutosaveInterval] = useState(localStorage.getItem("diagramAutosaveInterval") || 120);
	const [autosaveTimer, setAutosaveTimer ] = useState(autosaveInterval);

	useEffect(() => {
		if ( props.isSaving ) {
			clearTimeout(timer);
		} else {
			setAutosaveTimer(autosaveInterval);
		}
	}, [props.isSaving]);
	useEffect(() => {
		timer = setTimeout(() => {
			let time = autosaveTimer - 1;
			if (time === 0) {
				autosave();
				time = autosaveInterval;
			}
			setAutosaveTimer(time);
		}, 1000);
	}, [autosaveTimer]);

	const onAutoSaveIntervalChange = e => {
		if (!isNaN(e.target.value)) {
			if (e.target.value > 60) {
				e.target.value = 60;
			}
			clearTimeout(timer);
			setAutosaveInterval((e.target.value * 60));
			setAutosaveTimer(e.target.value * 60);
			localStorage.setItem("diagramAutosaveInterval", (e.target.value * 60));
		}
	};

    
	const getTimer = time => {
		const minutes = Math.floor(time / 60);
		const seconds = (time - minutes * 60).toString().padStart(2, "0");
		return `${minutes}:${seconds}`;
	};

	const autosave = () => {
		props.onSave();
	};

	return <>
		<div className="d-flex align-items-center invalid_color margin-right-40" style={{ fontSize: "15px" }}>

			{/* {!isAutoSaveIntervalSet && <span><I18 tkey="Set autosave interval" />...</span> } */}

			{props.isSaving ?  <span style={{ fontStyle: "italic" }}><I18 tkey="Saving the content" />...</span>  : 
				<span style={{ background: "#ff4a85", color: "white", minWidth: "150px", padding: "0 5px", textAlign: "center" }}>
					{autosaveTimer !== 0 ? <span><I18 tkey="Autosaving in" /> <span style={{ display: "inline-block", minWidth: "40px", textAlign: "center" }}>{getTimer(autosaveTimer)}</span> <I18 tkey="minutes" /></span> : ""}
				</span>}
		</div>
		{ !props.networkIssue ?
			<div className={`d-flex align-items-center ${props.isSaving ? "disabled_color" : "invalid_color"} autosave-interval-div`}>
				<I18 tkey="Autosave the content in every" />
				<input type="text" pattern="^[1-9]{1}[0-9]{0,1}$"
					style={{ width: "30px", border: "1px solid #df9595ad", textAlign: "center", outline: "none", margin: "0 4px" }}
					// onBlur={onBlur}
					onInput={onAutoSaveIntervalChange} value={autosaveInterval ? autosaveInterval / 60 : ""}
				/>
				<I18 tkey="minutes" />
			</div> :
			<div className="d-flex align-items-center invalid_color autosave-interval-div">
				<I18 tkey="Network outage happened, Please save the pathway content manually using 'Enregister'" />
			</div>
		}
	</>;

};

export default AutoSave;