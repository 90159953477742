import React, { Component } from "react";
import { Modal } from "antd";
import I18, { i18Get } from "../../i18";

class simplePopupTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th><I18 tkey="NAME" /></th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	getTableContent(list) {

		if (list.length > 0) {
			return list.map((data) => (<tr key={data.name}><td>{data.name}</td></tr>));
		}

		return (
			<tr>
				<td><div className="text_align_center w_100"><I18 tkey="NO DATA FOUND" /></div></td>
				{" "}
			</tr>
		);
	}

	render() {
		return (

			<Modal
				title={i18Get((this.props.title ? this.props.title : "LIST"))}
				visible={this.props.visible}
				onCancel={this.props.onCancel}
				footer={this.props.footer ? this.props.footer : null}
			>
				<div>
					<div className="margin-top-o-15p margin-bottom-10 scroll_window">
						{this.props.isLoading && <div><I18 tkey="Loading" />...</div>}
						{!this.props.isLoading && this.getTable(this.props.data)}
					</div>
				</div>
			</Modal>

		);
	}
}
export default simplePopupTable;
