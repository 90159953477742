import store from "../store";

export default function apiHeaders() {
	const state = store.getState();
	let headers = { headers: {} };
	if (state.user.loginData) {
		headers = { headers: { Authorization: `bearer ${state.user.loginData.jwtToken}`, "content-type": "multipart/form-data" } };
	}
	return headers;
}
