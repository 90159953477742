import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import communityMembersAction from "../../../../../../../../actions/community-members";
import Members from "./createMembers";

class CreateMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showMembersCreate: false,
			name: "",
			code: "",
			invalidCode: false,
			invalidName: false,
			loading: false,
			saveAndNext: false,
			loadingNext: false,
			invalidShortName: false,
			shortName: ""
		};
	}

	createNewClass() {
		this.setState({ showMembersCreate: true });
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.checkCodeRegex(text)) {
			prevState.code = text;
		} else {
			prevState.code = "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	shortNameChanged(value) {
		const prevState = this.state;
		if (value.length <= 60) {
			prevState.shortName = value;
			this.setState(prevState);
		}
		prevState.invalidShortName = false;
	}

	validate() {
		const prevState = this.state;
		if (!prevState.name || !prevState.name.trim() || !utils.checkNameRegex(prevState.name)) {
			prevState.invalidName = true;
		}
		if (!prevState.code || !prevState.code.trim()) {
			prevState.invalidCode = true;
		}
		if (!prevState.shortName || !prevState.shortName.trim()) {
			prevState.invalidShortName = true;
		}

		if (prevState.invalidName || prevState.invalidCode || prevState.invalidShortName) {
			this.setState(prevState);
			return false;
		}

		return true;
	}

	handleOk(saveAndNext) {
		if (this.validate()) {
			if (saveAndNext === true) {this.setState({ saveAndNext: true, loadingNext: true });}
			else {this.setState({ loading: true, saveAndNext: false });}
			const payload = {
				name: this.state.name,
				code: this.state.code,
				shortName: this.state.shortName
			};
			this.props.dispatch(communityMembersAction.createCommunityMembers(payload));
		}
	}

	handleCancel() {
		this.setState({
			showMembersCreate: false,
			name: "",
			invalidName: false,
			code: "",
			invalidCode: false,
			shortName: "",
			invalidShortName: false
		});
	}

	handleNext() {
		this.setState({
			showMembersCreate: true, name: "", invalidName: false, code: "", invalidCode: false, shortName: "", invalidShortName: false
		});
	}

	render() {
		if (this.props.communityMembers.communityMembersCreate === "FULFILLED") {
			if (this.state.saveAndNext === true) {
				this.handleNext();
			} else { this.handleCancel(); }
			this.props.createClassCompleted(true);
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communityMembersAction.clearCreateCommunityMembers());
		}
		if (this.props.communityMembers.communityMembersCreate === "REJECTED") {
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communityMembersAction.clearCreateCommunityMembers());
		}
		return (
			<div>
				<div className="pole_create_section di w_100 padding-top-15 padding-bottom-0 ">
					<button
						className="common_button margin_top_15_mob  margin-right-0 "
						type="button"
						onClick={this.createNewClass.bind(this)}
					>
						<i className="fa fa-plus margin-right-8" aria-hidden="true" />
						<I18 tkey="CREATE" />
					</button>
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Create Hospital", utils.getLanguage())}
					visible={this.state.showMembersCreate}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose
					footer={[
						<Button key="submitNext" type="primary" loading={this.state.loadingNext} onClick={this.handleOk.bind(this, true)}>
							<span className="margin-left-10">
								<I18 tkey="Save and Next" />
							</span>
						</Button>,
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk.bind(this, false)}>
							<span className="margin-left-10">
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}
				>
					<Members
						name={this.state.name}
						nameChanged={this.nameChanged.bind(this)}
						invalidName={this.state.invalidName}
						code={this.state.code}
						codeChanged={this.codeChanged.bind(this)}
						invalidCode={this.state.invalidCode}
						shortNameChanged={this.shortNameChanged.bind(this)}
						invalidShortName={this.state.invalidShortName}
						shortName={this.state.shortName}
					/>
				</Modal>
			</div>
		);
	}
}

CreateMembers.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communityMembers: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	communityMembers: store.communityMembers,
	translations: store.translations
}))(CreateMembers));
