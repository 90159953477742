import eventNames from "../../actions/events";

const initialState = {
	dropDownData: "",
	isTextElementUpdateComplete: false,
	updateData: {},
	isElementGetCompleted: false,
	elementsData: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.TEXTELEMENTS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dropDownData: action.payload.data.data,
					isFetchAllDropDownDataCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isFetchAllDropDownDataCompleted: "FULFILLED"
			};

		case `${eventNames.TEXTELEMENTS_ADD}_REJECTED`:
			return {
				...state,
				iselementAddCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_ADD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					addElementsData: action.payload.data.data,
					iselementAddCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				iselementAddCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_TEXTELEMENTS_ADD:
			return {
				...state,
				elementAddData: [],
				iselementAddCompleted: false
			};

		case `${eventNames.TEXTELEMENTS_GET}_REJECTED`:
			return {
				...state,
				isElementGetCompleted: "REJECTED"
			};
		case `${eventNames.TEXTELEMENTS_GET}_PENDING`:
			return {
				...state,
				isElementGetCompleted: "PENDING"
			};
		case `${eventNames.TEXTELEMENTS_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isElementGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementGetCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_TEXTELEMENTS_GET:
			return {
				...state,
				isElementGetCompleted: false
			};

		case `${eventNames.TEXTELEMENTS_DELETE}_REJECTED`:
			return {
				...state,
				isElementDeleteCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_DELETE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsDeletedData: action.payload.data.data,
					isElementDeleteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isElementDeleteCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_TEXTELEMENTS_DELETE:
			return {
				...state,
				elementsDeletedData: [],
				isElementDeleteCompleted: false
			};
		case `${eventNames.UPDATE_TEXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isTextElementUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_TEXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateData: action.payload.data.data,
					isTextElementUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isTextElementUpdateComplete: "FULFILLED"
			};

		case eventNames.CLEAR_UPDATE_TEXT_ELEMENT:
			return {
				...state,
				isTextElementUpdateComplete: false
			};
		case `${eventNames.UPLOAD_PIC}_REJECTED`:
			return {
				...state,
				isElementImageUploadCompleted: "REJECTED"
			};

		case `${eventNames.UPLOAD_PIC}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageData: action.payload.data.data,
					imageTitle: action.meta,
					isElementImageUploadCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isElementImageUploadCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_UPLOAD_PIC:
			return {
				...state,
				imageData: [],
				imageTitle: "",
				isElementImageUploadCompleted: false
			};

		case `${eventNames.TEXTELEMENTS_IMAGE_ADD}_REJECTED`:
			return {
				...state,
				isElementImageUpdateCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_IMAGE_ADD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageUpdateData: action.payload.data.data,
					isElementImageUpdateCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isElementImageUpdateCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_TEXTELEMENTS_IMAGE_ADD:
			return {
				...state,
				imageUpdateData: [],
				isElementImageUpdateCompleted: false
			};

		case `${eventNames.TEXTELEMENTS_GET_ALL_IMAGES}_REJECTED`:
			return {
				...state,
				isElementImageGetCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_GET_ALL_IMAGES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageGetAllData: action.payload.data.data,
					isElementImageGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isElementImageGetCompleted: "FULFILLED"
			};

		case `${eventNames.TEXTELEMENTS_IMAGE_DELETE}_REJECTED`:
			return {
				...state,
				isElementImageDeleteCompleted: "REJECTED"
			};

		case `${eventNames.TEXTELEMENTS_IMAGE_DELETE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					imageDeleteData: action.payload.data.data,
					isElementImageDeleteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isElementImageDeleteCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_TEXTELEMENTS_IMAGE_DELETE:
			return {
				...state,
				imageDeleteData: [],
				isElementImageDeleteCompleted: false
			};
		case eventNames.CLEAR_TEXTELEMENTS_GET_ALL:
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: false,
				isElementImageGetCompleted: false
			};

		case `${eventNames.ELIGIBILITY_GET_ONE}_REJECTED`:
			return {
				...state,
				isClinicalPathwayEligibilityCompleted: "REJECTED"
			};
		case `${eventNames.ELIGIBILITY_GET_ONE}_PENDING`:
			return {
				...state,
				isClinicalPathwayEligibilityCompleted: "PENDING"
			};
		case `${eventNames.ELIGIBILITY_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isClinicalPathwayEligibilityCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isClinicalPathwayEligibilityCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_ELIGIBILITY_GET_ONE:
			return {
				...state,
				isClinicalPathwayEligibilityCompleted: false
			};

		case `${eventNames.UPDATE_ELIGIBILITY}_REJECTED`:
			return {
				...state,
				isClinicalPathwayEligibilityUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_ELIGIBILITY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateData: action.payload.data.data,
					isClinicalPathwayEligibilityUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isClinicalPathwayEligibilityUpdateComplete: "FULFILLED"
			};

		case eventNames.CLEAR_UPDATE_ELIGIBILITY:
			return {
				...state,
				isClinicalPathwayEligibilityUpdateComplete: false
			};

		case `${eventNames.HEALTH_PATHWAY_DEFINITION_GET_ONE}_REJECTED`:
			return {
				...state,
				isHealthPathwayDefinitionCompleted: "REJECTED"
			};
		case `${eventNames.HEALTH_PATHWAY_DEFINITION_GET_ONE}_PENDING`:
			return {
				...state,
				isHealthPathwayDefinitionCompleted: "PENDING"
			};
		case `${eventNames.HEALTH_PATHWAY_DEFINITION_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isHealthPathwayDefinitionCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isHealthPathwayDefinitionCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_HEALTH_PATHWAY_DEFINITION_GET_ONE:
			return {
				...state,
				isHealthPathwayDefinitionCompleted: false
			};

		case `${eventNames.UPDATE_HEALTH_PATHWAY_DEFINITION}_REJECTED`:
			return {
				...state,
				isHealthPathwayDefinitionUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_HEALTH_PATHWAY_DEFINITION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateData: action.payload.data.data,
					isHealthPathwayDefinitionUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isHealthPathwayDefinitionUpdateComplete: "FULFILLED"
			};

		case eventNames.CLEAR_UPDATE_HEALTH_PATHWAY_DEFINITION:
			return {
				...state,
				isHealthPathwayDefinitionUpdateComplete: false
			};

		case `${eventNames.HEALTH_PATHWAY_REMARKS_GET_ONE}_REJECTED`:
			return {
				...state,
				isHealthPathwayRemarksCompleted: "REJECTED"
			};
		case `${eventNames.HEALTH_PATHWAY_REMARKS_GET_ONE}_PENDING`:
			return {
				...state,
				isHealthPathwayRemarksCompleted: "PENDING"
			};
		case `${eventNames.HEALTH_PATHWAY_REMARKS_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isHealthPathwayRemarksCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isHealthPathwayRemarksCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_HEALTH_PATHWAY_REMARKS_GET_ONE:
			return {
				...state,
				isHealthPathwayRemarksCompleted: false
			};

		case `${eventNames.UPDATE_HEALTH_PATHWAY_REMARKS}_REJECTED`:
			return {
				...state,
				isHealthPathwayRemarksUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_HEALTH_PATHWAY_REMARKS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateData: action.payload.data.data,
					isHealthPathwayRemarksUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isHealthPathwayRemarksUpdateComplete: "FULFILLED"
			};

		case eventNames.CLEAR_UPDATE_HEALTH_PATHWAY_REMARKS:
			return {
				...state,
				isHealthPathwayRemarksUpdateComplete: false
			};

		default:
			return state;
	}
}
