import React from "react";
import { Tooltip } from "antd";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { typeActivity, statusList } from "../../../../../../../../../constants";
import { DeleteOutlined, EditOutlined, InteractionOutlined } from "@ant-design/icons";

function getText(value) {
	return <div>{value}</div>;
}

export default function getCols(editClicked, deleteClicked, toggleVisibleAssignTreatments, approveClicked) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				(row.value.name.toUpperCase() === "STANDARD") ? ""
					: (
						<div className="margin_left_auto">
							{row.value.status === statusList.STATUS.values.APPROVED
								? (
									<EditOutlined
										className="edit_color"
										type="edit"
										onClick={editClicked(row.value)}
									/>
								)
								: (
									<a className="waiting-for-approve" onClick={approveClicked(row.value)}>
										<img src="/images/waitingforapprovel.png" />
										{" "}
									</a>
								)}
							{row.value.status === statusList.STATUS.values.APPROVED
								? (
									<DeleteOutlined
										className="delete_color margin-left-10"
										type="delete"
										onClick={deleteClicked(row.value)}
									/>
								)
								: ""}

							{(row.value.status === statusList.STATUS.values.APPROVED)
                    && (row.value.typeActivity !== typeActivity.CHEMOTHERAPY.toUpperCase())
                    && (row.value.typeActivity !== typeActivity.IMAGERY.toUpperCase())
                    // && (row.value.typeActivity !== typeActivity.CLINICAL_PATHWAY.toUpperCase().replace(/ /g, '_'))
                    && (row.value.typeActivity !== typeActivity.RADIOTHERAPY.toUpperCase())
								? (
									<Tooltip title={i18Get("Assign to treatments", utils.getLanguage())}>
										<a onClick={toggleVisibleAssignTreatments(row.value)}>
											<InteractionOutlined className="margin-left-10" />
										</a>
									</Tooltip>
								)
								: ""}
						</div>
					)
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("TYPE ACTIVITY", utils.getLanguage()),
			id: "typeActivity",
			accessor: (d) => d.typeActivity,
			resizable: true,
			width: 150,
			Cell: (row) => (
				getText(row.value.replace(/_/g, " "))
			)
		},
		{
			Header: i18Get("NUMBER OF CATEGORIES", utils.getLanguage()),
			id: "categoryPersonnelCount",
			accessor: (d) => d.categoryPersonnelCount,
			resizable: true,
			width: 100,
			Cell: (row) => (
				getText(utils.setLocaleForCurrency(row.value, "", true))
			)
		},
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d.hospitalName,
			resizable: true,
			width: 200,
			Cell: (row) => (
				getText(row.value ? row.value : "Master")
			)
		}, {
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			width: 150,
			resizable: true,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		}
	]);
	return tableArray;
}
