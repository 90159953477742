import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import categoryPersonnel from "./category-personnels";
import AdminPersonnnel from "./admin-personnels";

class Personnels extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<Switch>
				<Route path={locations.adminPersonnel} component={AdminPersonnnel} />
				<Route path={locations.categoryPersonnel} component={categoryPersonnel} />
				<Route path={locations.editCategoryPersonnel} component={categoryPersonnel} />
				<Redirect to={locations.adminPersonnel} />
			</Switch>
		);
	}
}

Personnels.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Personnels));
