import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";
import { FilterOutlined } from "@ant-design/icons";
import SpecialityDropdown from "../../../../../../activities/specialities/selectSpecialities";
import FamilyDropdown from "../../../../../../activities/activity-families/selectFamilyForSpeciality";
import StatusDropdown from "./status-dropdown";
import locations from "../../../../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../../../../i18";
import utils from "../../../../../../../../../../../utils/utils";

export default class AssignTreatmentsFilter extends Component {
	constructor(props) {
		super(props);
		this.state = {
			speciality: null,
			family: null,
			unassigned: 0,
			search: "",
			organization: locations.tabMom,
			showFilter: true,
			disableFamily: false
		};
	}

	specialityChanged(e) {
		this.setState({ speciality: e, family: null }, this.renderList);
	}

	familyChanged(e) {
		this.setState({ family: e }, this.renderList);
	}

	statusChanged(e) {
		this.setState({ unassigned: e }, this.renderList);
	}

	searchChanged(e) {
		this.setState({ search: e.target.value }, this.renderList);
	}

	clearFilter() {
		const prevState = this.state;
		prevState.speciality = null;
		prevState.family = null;
		prevState.unassigned = 0;
		prevState.search = "";
		this.setState(prevState, this.renderList);
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter });
	}

	renderList() {
		this.props.renderOnFilterChange({
			specialityId: this.state.speciality && this.state.speciality.length ? this.state.speciality.filter((speciality) => speciality.value) : null,
			familyId: this.state.family && this.state.family.value ? this.state.family.value : null,
			unassigned: this.state.unassigned,
			search: this.state.search
		});
	}

	componentDidUpdate() {
		if (this.props.organization !== this.state.organization) {
			this.state.organization = this.props.organization;
			this.state.speciality = null;
			this.state.family = null;
			this.state.unassigned = 0;
			this.state.search = "";
			this.renderList();
		}
	}

	render() {
		return (
			<div className="margin-bottom-10 position_relative space_filter">
				<div className="text_align_right">
					<div className=" filter_icon active" onClick={this.filterClicked.bind(this)}>
						<FilterOutlined  className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
					</div>
				</div>
				<div className="text_align_right">
					{this.state.showFilter
						? (
							<a className=" model_clear_filter" onClick={this.clearFilter.bind(this)}>
								<I18 tkey="Clear Filter" />
							</a>
						)
						: 						""}
				</div>
				{this.state.showFilter
					? (
						<div className="row">
							<div className="col-md-6">
								<div>
									<label className="filter_label"><I18 tkey="Speciality" /></label>
								</div>
								<div>
									<SpecialityDropdown
										organization={this.props.organization ? this.props.organization.toUpperCase() : ""}
										typeActivity={this.props.typeActivity}
										assignTeam
										data={this.state.speciality}
										specialityChanged={this.specialityChanged.bind(this)}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div>
									<label className="filter_label"><I18 tkey="Family" /></label>
								</div>
								<div>
									<FamilyDropdown
										organization={this.props.organization ? this.props.organization.toUpperCase() : ""}
										typeActivity={this.props.typeActivity}
										data={this.state.family}
										assignTeam
										disableFamily={!!(this.state.speciality && this.state.speciality.length > 1)}
										specialityId={this.state.speciality && this.state.speciality.length && this.state.speciality[0].value ? this.state.speciality[0].value : null}
										onFamilyChanged={this.familyChanged.bind(this)}
									/>
								</div>
							</div>
						</div>
					) : ""}
				{this.state.showFilter
					? (
						<div className="row">
							<div className="col-md-6">
								<div>
									<label className="filter_label"><I18 tkey="Status" /></label>
								</div>
								<div>
									<StatusDropdown
										data={this.state.unassigned}
										dropdownChanged={this.statusChanged.bind(this)}
									/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="height_26">
									<span>&nbsp;</span>
								</div>
								<div>
									<DebounceInput
										className="filter_input_design form-control"
										debounceTimeout={750}
										placeholder={i18Get("Search...", utils.getLanguage())}
										value={this.state.search}
										onChange={this.searchChanged.bind(this)}
									/>
								</div>
							</div>
						</div>
					) : ""}
			</div>
		);
	}
}
