// import i18 from "i18";
import { AnnotationConstraints } from "@syncfusion/ej2-react-diagrams";
export default [
	{ id: "Terminator", shape: { type: "Flow", shape: "Terminator" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Process", shape: { type: "Flow", shape: "Process" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Decision", shape: { type: "Flow", shape: "Decision" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Document", shape: { type: "Flow", shape: "Document" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{
		id: "PreDefinedProcess",
		shape: { type: "Flow", shape: "PreDefinedProcess" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }] 
	},
	{ id: "PaperTap", shape: { type: "Flow", shape: "PaperTap" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "DirectData", shape: { type: "Flow", shape: "DirectData" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "SequentialData", shape: { type: "Flow", shape: "SequentialData" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Sort", shape: { type: "Flow", shape: "Sort" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "MultiDocument", shape: { type: "Flow", shape: "MultiDocument" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Collate", shape: { type: "Flow", shape: "Collate" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "SummingJunction", shape: { type: "Flow", shape: "SummingJunction" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Or", shape: { type: "Flow", shape: "Or" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "InternalStorage", shape: { type: "Flow", shape: "InternalStorage" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Extract", shape: { type: "Flow", shape: "Extract" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "ManualOperation", shape: { type: "Flow", shape: "ManualOperation" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Merge", shape: { type: "Flow", shape: "Merge" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{
		id: "OffPageReference",
		shape: { type: "Flow", shape: "OffPageReference" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }] 
	},
	{
		id: "SequentialAccessStorage",
		shape: { type: "Flow", shape: "SequentialAccessStorage" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }] 
	},
	{ id: "Annotation", shape: { type: "Flow", shape: "Annotation" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Annotation2", shape: { type: "Flow", shape: "Annotation2" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Data", shape: { type: "Flow", shape: "Data" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Card", shape: { type: "Flow", shape: "Card" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Delay", shape: { type: "Flow", shape: "Delay" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  }
];