import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import roleActions from "./redux/action";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import { Modal, Button, Checkbox, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./style.css";
class Create extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			invalidName: false,
			code: "",
			invalidCode: false,
			loading: false,
			saveAs: false
		};
	}

	onChanged(stateValue, value ) {
		const prevState = this.state;

		if (stateValue === "code") {
			prevState.code = value;
		}
		if (stateValue === "name") {
			prevState.name = value;
		}

		prevState.invalidName = "";
		prevState.invalidCode = "";
		this.setState(prevState);
	}

	cleanWindow() {
		const prevState = this.state;
		prevState.code = "";
		prevState.name = "";
		prevState.invalidName = "";
		prevState.invalidCode = "";
		if (!prevState.saveAs) {
			this.handleCancel();
		}
		this.setState(prevState);

	}
	createClicked(saveAs) {
		const prevState = this.state;
		prevState.saveAs = saveAs;

		if (this.validateFields()) {
			prevState.loading = true;
			const payload = {
				name: this.state.name.trim(),
				code: this.state.code.trim(),
				isPathwayUser: this.state.isPathwayUser
			};
			this.props.dispatch(roleActions.create(payload));
		}
		this.setState(prevState);
	}

	validateFields() {
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim()) {
			this.state.invalidName = true;
		}
		if (!utils.checkNameRegex(this.state.code) || !this.state.code || !this.state.code.trim()) {
			this.state.invalidCode = true;
		}
		if (this.state.invalidName || this.state.invalidCode) {
			return false;
		}
		return true;
	}

	handleCancel() {
		this.props.onCancel();
	}

	onCheckboxChanged(e) {
		this.setState({ isPathwayUser: e.target.checked });
	}

	render() {

		if (this.props.roles.isCreatedStatus === "FULFILLED") {

			this.cleanWindow();
			this.state.loading = false;
			this.props.dispatch(roleActions.clearCreate());
			this.props.refreshList();
		}

		if (this.props.roles.isCreatedStatus === "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(roleActions.clearCreate());
			this.props.refreshList();
		}

		return (
			<Modal
				title={i18Get("Create Role", utils.getLanguage())}
				visible={this.props.visible}
				width="600px"
				onCancel={this.handleCancel.bind(this)}
				footer={[
					<Button key="Save and Next" type="primary" loading={this.state.loading && this.state.saveAs} onClick={this.createClicked.bind(this, true)}>
						<I18 tkey="Save and Next" />
					</Button>,
					<Button key="submit" type="primary" loading={this.state.loading && !this.state.saveAs} onClick={this.createClicked.bind(this, false)}>
						<I18 tkey="Save" />
					</Button>,
					<Button key="back" onClick={this.handleCancel.bind(this)}>
						<I18 tkey="Cancel" />
					</Button>

				]}
			>
				<div className="role_permissions">
					<div className="row">
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-9 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Code" /></label>
								{!this.state.invalidCode && <span className="label_info"> <I18 tkey="Code must be 5-digit" /></span>}
								<NameInput
									value={this.state.code}
									inputChanged={this.onChanged.bind(this, "code")}
									maxLength={5}
								/>
								{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
							<div className="w_100 di margin-bottom-9 position_relative">
								<label><span className='color_red font_size_14px'>*</span><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.onChanged.bind(this, "name")}
									maxLength={60}
								/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
							<div className="w_100 di margin-bottom-4 position_relative info_tooltip" >
								<Checkbox
									checked={this.state.isPathwayUser}
									onChange={this.onCheckboxChanged.bind(this)}
								>
									<I18 tkey="Assign to Hospital/Site/Community" />
								</Checkbox>

								<Tooltip placement="topRight" overlayClassName="special_character_tooltip" title={<span><h5 className='warning_span'><I18 tkey="Wanrning!" /></h5>{i18Get("This option allows the Clinical pathway user to assign/filter the Hospital and Site and the Community Share.", utils.getLanguage())}</span>}><InfoCircleOutlined  type="info-circle" theme="outlined" /></Tooltip>

							</div>
						</div>
					</div>
				</div>
			</Modal>
		);
	}
}

Create.propTypes = {
	roles: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired

};

export default withRouter(connect(store => { return ({
	roles: store.roles,
	actions: store.actions
});})(Create));