import React from "react";
import { Carousel } from "antd";
import "./styles.css";
import colorPattern from "./colorPattern.json";

const contentStyle = React.CSSProperties = {
	margin: 0,
	height: "100px",
	color: "black",
	lineHeight: "100px",
	textAlign: "center",
	background: "#ccc"
};

const ColorSlider = (props) => {
	const onChange = (currentSlide) => {
		console.log(currentSlide);
	};
	const onSelected = (type, color) => {
		props.onClicked(type, color);
	};
	return (
		<Carousel afterChange={onChange} style={{ backgroundColor: "#fff" }} >
			{
				Object.keys(colorPattern).map( data => <div className="slide-card" key={data}> 
					{
						colorPattern[data].map( e => <div key={e.backgroundColor}><button className="geStyleButton" style={{ height: "24px", backgroundColor: `${e.backgroundColor}`, border: `1px solid ${e.borderColor}`, borderRadius: "0px" }} onClick={ () => onSelected("fill", e.backgroundColor) }></button></div>)
					}
				</div>)
			}
		</Carousel>
	);
};

export default ColorSlider;
