import eventNames from "../../actions/events";

const initialState = {
	actionInProgress: false,
	sideBarHideShow: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case eventNames.ACTION_ONE:
			return {
				...state,
				actionInProgress: true
			};
		case eventNames.ACTION_ONE_CLEAR:
			return {
				...state,
				actionInProgress: false
			};
		case eventNames.HIDE_SHOW_SIDE_BAR:
			return {
				...state,
				sideBarHideShow: action.payload.sideBar
			};
		case eventNames.HTTP_REQUEST_LOST: 
			return {
				...state,
				lostRequest: true
			};
		case eventNames.HTTP_REQUEST_LOST_CLEAR: 
			return {
				...state,
				lostRequest: false
			};
		case eventNames.LOGIN_BLOCKED:
			return {
				...state,
				loginBlocked: true
			};
		case eventNames.LOGIN_BLOCKE_CLEAR:
			return {
				...state,
				loginBlocked: false
			};
		default:
			return state;
	}
}
