import I18 from "i18";
import React, { useEffect, useState } from "react";
import ColorSlider from "./settings/colorSlider";
import SideBarEditTask from "./edit-task/edit-task";
import BgColorPicker from "./settings/ColorPickerFiled";
import NodeStyles from "./settings/nodeStyles";
import Summary from "./summary";

function NodeSettings(props) {
	const [tabSwitching, setTabSwitching] = useState("Data");
	const tabSwitcher = (tab) => { setTabSwitching(tab);};

	useEffect( () => { 
		if (props.currentNode.addInfo) {
			const customNodeData = props.currentNode.addInfo.customNodeData;
			if ((customNodeData.intervenantsIds && customNodeData.intervenantsIds.length === 0) && !customNodeData.interventionDetails 
				&& !customNodeData.location && !customNodeData.durationOfTheTask && !customNodeData.expectedResults
				&& !customNodeData.argumentation && !customNodeData.risks && !customNodeData.reference && !customNodeData.linkType 
				&& !customNodeData.phase && !customNodeData.problems && !customNodeData.data && tabSwitching === "Summary") {
				setTabSwitching("Data");
			} 
		}
		else {
			setTabSwitching("Data");
		}
	}, [props.currentNode] );
	
	return <div>
		<ul className="sideBar-tab">
			<li data-active={tabSwitching === "Summary"} onClick={ () => tabSwitcher("Summary")}><I18 tkey="Summary" /> </li>
			<li data-active={tabSwitching === "Data"} onClick={ () => tabSwitcher("Data")}><I18 tkey="Edit" /> </li>
			<li data-active={tabSwitching === "Attributes"} onClick={() => tabSwitcher("Attributes")}><I18 tkey="Attributes" /> </li>
			
		</ul>
		<div className="sideBar-body">
			{tabSwitching === "Summary" ? 
				<Summary 
					tabSwitching ={tabSwitching} 
					tabSwitcher={tabSwitcher}
					diagramNodesData={props.diagramNodesData} 
					autoSelectNode = {props.autoSelectNode} 
					currentNode={props.currentNode} 
					currentNodeId={props.currentNodeId}
				/> : ""}
			{tabSwitching === "Attributes" ? <div>
				<ColorSlider onClicked={props.activityHandler} />
				<BgColorPicker onClicked={props.activityHandler} 
				activity={"strokeColor"}
				color={props.selectedShape.strokeColor ? props.selectedShape.strokeColor : "#000"}
				 />
				<NodeStyles onChange={props.activityHandler} strokeWidth = {props.selectedShape.strokeWidth} />
			</div> : ""}
			{tabSwitching === "Data" ? <div>
				<SideBarEditTask
					nodeName={props.nodeName}
					selectedShape={props.selectedShape}
					inputChangeHandler={props.inputChangeHandler}
					styleHandler={props.styleHandler}
					onChangeFiledData={props.onChangeFiledData}
					colorPicker={props.colorPicker}
					colorPickerHandler={props.colorPickerHandler}
					treatmentId= {props.treatmentId}
					customNodeData={props.customNodeData}
					onChangecustomNodeData={props.onChangecustomNodeData}
					onStepSearchData={props.onStepSearchData} 
					createStepClicked={props.createStepClicked}
					createSubStepClicked={props.createSubStepClicked}
					onSubStepSearchData={props.onSubStepSearchData}
					inputStepName={props.inputStepName} 
					createdSubStepName={props.createdSubStepName}
					clearCreatedSubStepName={props.clearCreatedSubStepName}
					clearCreatedStepName={props.clearCreatedStepName}
					healthPathwayData={props.healthPathwayData}
				/>
			</div> : ""}
		</div>
	</div>;
}
export default NodeSettings;