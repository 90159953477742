import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (id, sort, desc) => ({
	type: events.STAFF_ELEMENTS_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/personnel${sort ? (`?sort=${sort}&desc=${desc || 0}`) : ""}`)
});

const updateElement = (id, elementId, payload) => ({
	type: events.STAFF_ELEMENTS_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/personnel/${elementId}`, { ...payload })

});

const getOne = (id, elementId) => ({
	type: events.STAFF_ELEMENTS_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/category_personnel/${elementId}`)
});

const clearGetOne = () => ({
	type: events.CLEAR_STAFF_ELEMENTS_GETONE,
	payload: {}
});

const cleargetAll = () => ({
	type: events.CLEAR_STAFF_ELEMENTS_GET_ALL,
	payload: {}
});

const clearUpdate = () => ({
	type: events.CLEAR_STAFF_ELEMENTS_UPDATE,
	payload: {}
});

const switchTemplate = (treatmentId, payload) => ({
	type: events.SWITCH_TEMPLATE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${treatmentId}/personnel`, payload)
});

const clearSwitchTemplate = () => ({
	type: events.CLEAR_SWITCH_TEMPLATE,
	payload: {}
});

const transferTemplateInfo = (payload) => ({
	type: events.TRANSFER_TEMPLATE_INFO,
	payload
});

export default {
	getAll,
	cleargetAll,
	updateElement,
	clearUpdate,
	getOne,
	clearGetOne,
	switchTemplate,
	clearSwitchTemplate,
	transferTemplateInfo
};
