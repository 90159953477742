import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import locations from "../../../../../../router/locations";
import BreadCrumb from "../../breadcrumb";
import clpBackupActions from "../../../../../../actions/clp-backup";

class CLPBackup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ""
		};
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Clinical Pathway Backup", utils.getLanguage()), link: locations.clpBackup });
		return items;
	}

	onClickDownload() {
		this.props.dispatch(clpBackupActions.generateClpBackup());
	}

	render() {
		if (this.props.clpBackup.clpBackupCompleted === "FULFILLED") {
			this.props.dispatch(clpBackupActions.generateClpBackupCompleted());
		}
		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				<div className="di w_100 float_left">
					<div className=" w_100 float_left padding-top-15 padding-bottom-15">
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<button className="common_button margin-right-15" onClick={this.onClickDownload.bind(this)}>
									<i className="fa fa-download" aria-hidden="true"></i>&nbsp;
									<I18 tkey="DOWNLOAD BACKUP" />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CLPBackup.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clpBackup: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clpBackup: store.clpBackup
}))(CLPBackup));
