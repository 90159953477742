import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { Modal } from "antd";
import communitySitesAction from "../../../../../../../../../actions/community-sites";
import ReactTable from "../../../../../../../../../plug/reactTable";
import "../../../../../../../../../plug/reactTable/react-table.css";
import Loader from "../../../../../../../../../plug/loader";
import column from "./column";
import CommunityView from "./communityView";


class SiteClassesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySiteList: [],
			page: utils.checkForParameter("page", this.props.location) ? parseInt(utils.checkForParameter("page", this.props.location)) : 1,
			pageSize: utils.checkForParameter("pageSize", this.props.location) ? parseInt(utils.checkForParameter("pageSize", this.props.location)) : localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : 10,
			sort: "",
			desc: 1,
			refresh: false,
			search: "",
			totalPages: 1,
			loading: true,
			deleteVisible: false,
			modelVisible: false,
			consumable: true,
			modalData: {},
			deletedConsumableClass: {},
			type: "",
			fetchAllMeta: null,
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			filter: {
				search: "",
				consumableType: "ALL"
			},
			showAssingPopup: false,
			assingRow: {},
			communityListView: false,
			communityList: []
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillMount() {
		this.props.totalCount(0);
	}

	editClicked(siteData) {
		return () => {
			this.props.visibleEditModal(siteData);
		};
	}

	deleteClicked(tabMembersData) {
		return () => {
			this.setState({ deletedConsumableClass: tabMembersData, deleteVisible: true });
		};
	}

	viewCommunityList(list) {
		return () => {
			this.setState({ communityListView: true, communityList: list });

		};
	}

	CloseviewCommunity() {
		this.setState({ communityListView: false, communityList: [] });
	}

	hideModalDelete() {
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	deleteConsumableClass() {
		this.props.dispatch(communitySitesAction.deleteCommunitySites(this.state.deletedConsumableClass.id, this.state.deletedConsumableClass.hospitalId));
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	filterChanged(filter) {
		const prevState = this.state;
		prevState.search = filter;
		prevState.page = 1;
		this.setState(prevState, this.fetchDataFromServer());
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = this.props.sort ? this.props.sort : "";
			prevState.desc = this.props.desc ? this.props.desc : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.props.setSort(prevState.sort, prevState.desc);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.setState({ loading: true });
		let queryString = "?";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.filter.selectedStatus ? `&status=${this.state.filter.selectedStatus}` : "";
		queryString += this.state.filter.selectedHospital && this.state.filter.selectedHospital.value ? `&hospitalId=${this.state.filter.selectedHospital.value}` : "";
		queryString += this.state.filter.selectedCommunity && this.state.filter.selectedCommunity.value ? `&communityId=${this.state.filter.selectedCommunity.value}` : "";
		queryString += this.state.filter.search ? `&code=${encodeURIComponent(this.state.filter.search)}&name=${encodeURIComponent(this.state.filter.search)}&` : "";
		this.state.fetchAllMeta = Date.now();
		this.props.dispatch(communitySitesAction.getAll(queryString, this.state.fetchAllMeta));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.rowScrollIndex = 0;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	fetchOnFilterChange(filter, ignorePageChange) {
		if (!ignorePageChange) {
			this.state.page = 1;
		}
		this.state.loading = true;
		this.state.filter = filter;
		this.fetchDataFromServer();
	}

	onPageSizeChange(pageSize) {
		this.state.page = 1;
		this.state.pageSize = pageSize;
		this.state.rowScrollIndex = 0;
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	renderList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.communitySiteList, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this),
					column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.viewCommunityList.bind(this)), true, false,
					this.onPageChange.bind(this), "COMMUNITY SECTION", "Virtualized_react_table_scroll", this.onPageSizeChange.bind(this))}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex}
			/>);
	}

	render() {
		if (this.props.communitySites.isFetchAllcommunitySitesCompleted === "FULFILLED") {
			this.state.loading = false;
			this.state.refresh = false;
			this.props.createClassCompleted(false);
			this.state.communitySiteList = this.props.communitySites.communitySites ? this.props.communitySites.communitySites : [];
			this.state.totalPages = parseInt(Math.ceil(this.props.communitySites.count / this.state.pageSize));
			this.props.totalCount(this.props.communitySites.count);

			this.props.dispatch(communitySitesAction.clearGetAll());
		}
		if (this.props.communitySites.deleteCommunitySitesComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(communitySitesAction.clearDeleteCommunitySites());
		}

		if (this.props.communitySites.filterChangedcommunitySites) {
			this.fetchOnFilterChange(this.props.communitySites.filterChangedcommunitySites, this.props.communitySites.ignorePageChangecommunitySites);
			this.props.dispatch(communitySitesAction.filterChangeCommunitySites(null, false));
		}

		if (this.props.refresh !== this.state.refresh) {
			this.state.refresh = this.props.refresh;
			this.fetchDataFromServer();
		}
		return (
			<Fragment>
				<div className=" di w_100">
					{this.renderList()}
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Community Sites", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteConsumableClass.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedConsumableClass.class}</span> ?
					</div>
				</Modal>

				{this.state.communityListView &&
					<CommunityView
						view={this.state.communityListView}
						data={this.state.communityList}
						onClose={this.CloseviewCommunity.bind(this)}
					/>
				}
			</Fragment>
		);
	}
}

SiteClassesList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	communitySites: store.communitySites,
	translations: store.translations
}))(SiteClassesList));                    