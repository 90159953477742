import React from "react";
import { i18Get } from "../../../../../../../../../../../i18";
import utils from "../../../../../../../../../../../utils/utils";

export default function getCols(renderCheckbox, renderHeaderCheckBox) {
	const tableArray = ([
		{
			Header: () => (<div>{renderHeaderCheckBox()}</div>),
			id: "linkStatus",
			width: 50,
			accessor: (d) => d,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div style={{ paddingLeft: "35%" }}>
					{renderCheckbox(row.value)}
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			resizable: true,
			width: 100,
			Cell: (row) => (
				row.value
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			Cell: (row) => (
				row.value
			)
		},
		{
			Header: i18Get("PERSONNEL TEAM", utils.getLanguage()),
			id: "categoryPersonnelTemplateName",
			accessor: (d) => d.categoryPersonnelTemplateName,
			resizable: true,
			Cell: (row) => (
				row.value ? row.value : "Unassigned"
			)
		}]);
	return tableArray;
}
