import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import EditConsumableDefinition from "./edit";
import List from "./list";
import BreadCrumb from "../../../breadcrumb";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import CreateClass from "./create-class"; 
import Filter from "./filter";
import elementClassActions from "../../../../../../../actions/element-class";

class ConsumableDefinition  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumableDefinition: {},
			showConsumableClasses: false,
			key: 1,
			list: {},
			refresh: false,
			newClassName: "",
			totalPages: 0,
			showAlert: false,
			type: locations.tabConsumableClass,
			sort: utils.checkForParameter("sort", this.props.location) ? parseInt(utils.checkForParameter("sort", this.props.location)) : "",
			desc: utils.checkForParameter("desc", this.props.location) ? parseInt(utils.checkForParameter("desc", this.props.location)) : 0

		};
	}

	generateItems() {
		const items = [];
		if (this.props.location.pathname.startsWith(locations.consumable_definition_plain + `/${locations.tabConsumableClass}`)) {
			items.push({ name: i18Get("Definition Of Consumable Groups", utils.getLanguage()), link: locations.consumable_definition_plain + `/${locations.tabConsumableClass}` });
		}
		if (this.state.consumableDefinition.id) {
			items.push({ name: this.state.consumableDefinition.name, link: this.props.location.pathname });
		}
		return items;
	}

	showTop() {
		if (this.props.location.pathname === (locations.consumable_definition_plain + `/${locations.tabConsumableClass}`)) {
			return true;
		}
		return false;
	}

	filterChanged(filter, ignorePageChange) {

		if (this.props.location.pathname === locations.consumable_definition_plain + "/" + locations.tabConsumableClass) {
			this.props.dispatch(elementClassActions.filterChangeElementClass(filter, ignorePageChange));
		}
	}

	createClassCompleted(refresh) {
		this.state.refresh = refresh;
	}

	totalCount(count) {
		this.setState({ totalPages: (count ? count : 0) });
	}

	toggleShowAlert(showAlert) {
		this.setState({ showAlert: showAlert });
	}

	setSort = (sort, desc) => {
		this.setState({ sort, desc });
	};

	render() {
		this.state.consumableDefinition = this.props.breadcrumb.componentData;
		return ( 
			<div className="consumable_definition_table_scroll"> 
				<div className="main_overview_section">
					<div className="pole_container">
						<BreadCrumb useSearch items={this.generateItems()} showRecord={this.showTop()} totalRecords={this.state.totalPages}/>
                    
						{
							this.showTop() && this.props.location.pathname === (locations.consumable_definition_plain + `/${locations.tabConsumableClass}`) &&
                            <CreateClass createClassCompleted = {this.createClassCompleted.bind(this)}/>
						}
						{this.showTop() ? 
							<Filter 
								isConsumableClass={this.props.location.pathname === (locations.consumable_definition_plain + `/${locations.tabConsumableClass}`)}
								filterChanged={this.filterChanged.bind(this)}/> : ""} 
						<Switch>
							<Route exact path={locations.editConsumable_definition} render={() => 
								<EditConsumableDefinition 
									toggleShowAlert={this.toggleShowAlert.bind(this)}
									showClassCreate={this.state.showClassCreate}/>} />
							<Route path={locations.consumable_definition} render={() => 
								<List totalCount={this.totalCount.bind(this)}
									refresh = {this.state.refresh}
									createClassCompleted = {this.createClassCompleted.bind(this)}
									setSort={this.setSort} 
									sort={this.state.sort} desc={this.state.desc} />} />
						</Switch>
					</div>
				</div>
			</div> 
		);
	}
}

ConsumableDefinition.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	elementClass: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	breadcrumb: store.breadcrumb,
	elementClass: store.elementClass,
	translations: store.translations
}))(ConsumableDefinition));                    