import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import sterilizationActions from "../../../../../../../actions/sterilization";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		height: "32px",
		minHeight: "32px"
	})
};
class SterilizatonSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sterilizationList: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	renderOptions() { 
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.sterilizationList) {    
			this.state.sterilizationList.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState);
	}
 
	fetchData(search) {
		this.setState({ isListLoading: true });
		const queryString = `offset=${this.state.offset}&numResults=${this.state.numResults}&status=APPROVED${search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : ""}`;
		this.props.dispatch(sterilizationActions.fetchAllSterilization(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchData(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchData(this.state.search);
	}

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	render() {
		if (this.props.sterilization.isFetchAllSterilizationCompleted === "FULFILLED") {
			this.props.dispatch(sterilizationActions.clearFetchAllSterilization());
			this.state.sterilizationList = unionWith(this.state.sterilizationList,  this.props.sterilization.sterilizationList.data, isEqual);
			this.renderOptions();
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
				this.fetchData(false);
				this.state.isDataFound = false;
			}
		}
		return (
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.onSterilizationChange}
				value={this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : ""}
				isSearchable
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select", utils.getLanguage())}
			/>
		);
	}
}

SterilizatonSelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	sterilization: store.sterilization,
	translations: store.translations
}))(SterilizatonSelect));                    