import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import utils from "../../../../../../../utils/utils";
import Config from "../../../../../../../MOM-config";
import { apiHeaders } from "../../../../../../../utils";

const moment = require("moment");
const PDF = "PDF";
const EXCEL = "EXCEL";

class ExportPathwayProblem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			synthesisList: [],
			loading: false,
			showColumns: []
		};
	}

	UNSAFE_componentWillMount() {
		const { selectedPblmColumns } = this.props;
		if (selectedPblmColumns && selectedPblmColumns.check) {
			const columns = [];
			Object.keys(selectedPblmColumns.check).forEach((key) => {
				if (selectedPblmColumns.check[key] === true) {
					columns.push(key);
				}
			});
			this.state.showColumns = columns.filter(column => column !== "TYPE");
		}
		this.exportPathwayProblem();
	}

	pathwayProblemTemplate(type, win) {
		const filters = {};
		filters.locale = `${utils.getLanguage()}`;
		filters.organization = this.props.match.params.organization;
		filters.search = this.props.search ? this.props.search : "";
		filters.field = this.props.field && this.props.field !== "SEARCH_ALL_FIELDS" ? this.props.field : "";
		filters.clpHealthProblemId = this.props.type && this.props.type.value ? this.props.type.value : "";
		filters.clinicalPathwayType = this.props.clinicalPathwayData.clinicalPathwayType ? this.props.clinicalPathwayData.clinicalPathwayType : "";
		filters.clpStepFilter = this.props.clpStepFilter ? this.props.clpStepFilter : "";
		filters.showColumns = this.state.showColumns ? this.state.showColumns : "";
		filters.timezone = new Date().getTimezoneOffset();
		filters.exportType = "EXCEL";
		axios({
			url: `${Config.apiRootSecure}/reports/clinicalPathway/${this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id}/healthMapping`,
			...apiHeaders(),
			method: "POST",
			responseType: "blob",
			data: filters
		})
			.then((response) => {
				this.state.isExportLoading = false;
				const blob = new Blob([response.data], (type !== "csv" ? { type: `application/${type}` } : { type: `text/${type}` }));
				const windowUrl = (window.URL || window.webkitURL);
				const downloadUrl = windowUrl.createObjectURL(blob);
				const dateTime = moment().format("DDMMYYYY_HHmmss");
				if (type !== PDF) {
					const link = document.createElement("a");
					link.href = downloadUrl;
					link.download = `${this.state.clinicalPathway.name}_master_${dateTime}` + `${type === EXCEL ? ".xlsx" : ""}`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					win.location = downloadUrl;
					win.focus();
				}
				this.setState(this.state);
			}).catch(() => {
				this.state.isExportLoading = false;
				//win.close();
				this.setState(this.state);
			});
		this.props.closeExport();
	}

	exportPathwayProblem() {
		const win = window;
		this.pathwayProblemTemplate(EXCEL, win);
	}

	render() {
		if (Object.keys(this.props.clinicalPathwayData) && !this.state.isLoaded) {
			this.state.clinicalPathway = this.props.clinicalPathwayData;
			this.state.isLoaded = true;
		}

		return (
			<div />
		);
	}
}

ExportPathwayProblem.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations
}))(ExportPathwayProblem));
