import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import eventNames from "../../../../../../actions/events";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import "../../../../../../plug/reactTable/react-table.css";
import { Modal, DatePicker } from "antd";
import ActionButton from "../../../../../../plug/action-button";
import NameInput from "../../../../../../plug/regex-name-search-input";
import clinicalPathwayDetailsAction from "../../../../../../actions/clinicalPathway-details";
import Loader from "../../../../../../plug/loader";
import BreadCrumb from "../../breadcrumb";
import ActivityDropdown from "./activity-type";
import moment from "moment";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

class AuthorsAndActivities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			maxlen: [],
			type: "",
			data: [],
			deleteVisible: false,
			elementToDelete: false,
			modelVisible: false,
			name: "",
			sorted: [],
			sort: "authors",
			desc: 0,
			listOffset: 0,
			listNumResults: 100,
			author: "",
			service: "",
			date: ""
		};
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}


	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.state.listOffset = 0;
		this.state.listNumResults = 100;
		this.fetchDataFromServer();
	}

	componentWillUnmount() {
		this.state.list = [];
		this.state.data = [];
		this.state.selectedData = "";
		this.state.type = "";
		clearTimeout(this.timer);
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	getCols() {
		const tableArray = ([
			{
				Header: "",
				id: "action",
				accessor: d => d,
				sortable: false,
				resizable: true,
				maxWidth: 60,
				Cell: row => (

					<div className="margin_left_auto">
						{
							<span className="risk_action_button text_align_center"><EditOutlined type="edit" className="edit_color" onClick={this.editClicked.bind(this, row.value)} /></span>

						}
						{
							<span className="risk_action_button text_align_center margin-left-10"><DeleteOutlined type="delete" className="delete_color" onClick={this.deleteElementClicked.bind(this, row.value)} /></span>

						}

						{/* {
                    row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
                    <a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
                    : ''
                } */}
					</div>

				)
			},
			{
				Header: i18Get("AUTHOR", utils.getLanguage()),
				id: "authors",
				accessor: d => d,
				resizable: true,
				width: 180,
				Cell: row => (
					row.value && row.value ? row.value.authors : ""
				)
			},
			{
				Header: i18Get("ACTION", utils.getLanguage()),
				id: "activity",
				accessor: d => d.activity,
				resizable: true,
				width: 110,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			},
			{
				Header: i18Get("DATE", utils.getLanguage()),
				id: "date",
				accessor: d => d.date,
				resizable: true,
				sortable: false,
				width: 140,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			},
			{
				Header: i18Get("DESCRIPTION", utils.getLanguage()),
				id: "description",
				accessor: d => d.description,
				resizable: true,
				sortable: false,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			}
		]);
		return tableArray;
	}

	deleteElementClicked(value) {
		const prevState = this.state;
		prevState.elementToDelete = value;
		prevState.deleteVisible = true;
		this.setState(prevState);
	}

	editClicked(value) {
		this.props.history.push(`/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}/sub_clinical_pathway/Authors_And_Actions/${value.id}${this.props.location.search}`);
	}

	onAssignAndApproveClick(data) {
		return () => {
			this.setState({ modelVisible: true, subTreatmentId: data.id, elementType: data.type });
		};
	}

	refreshCompleted = () => {
		const prevState = this.state;
		prevState.modelVisible = false;
		this.fetchDataFromServer();
	};

	deleteElement() {
		this.props.dispatch(clinicalPathwayDetailsAction.deleteAuthorAndActivity(this.props.match.params.id, this.state.elementToDelete.id));
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, elementToDelete: false });
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString +=  `&timezone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(clinicalPathwayDetailsAction.getAuthorsAndActivities(this.props.match.params.id, queryString));
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "authors";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.fetchDataFromServer();
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Clinical Pathway", utils.getLanguage()), link: `/clinical_pathway/${this.props.match.params.organization}` });
		items.push({ name: this.props.clinicalPathway.name ? `${this.props.clinicalPathway.hospitalName ? this.props.clinicalPathway.hospitalName : ""} ${this.props.clinicalPathway.name}` : i18Get("Clinical Pathway Details", utils.getLanguage()), link: `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}${this.state.position ? `?position=${this.state.position}` : ""}` });
		items.push({ name: i18Get("AUTHORS AND ACTIONS", utils.getLanguage()), link: this.props.location.pathname });
		return items;
	}

	authorChanged(value) {
		this.state.author = value;
		this.state.invalidAuthor = false;
		this.setState(this.state);
	}

	onActivityChange(e) {
		this.state.selectedActivity = e;
		this.state.invalidActivity = false;
		this.setState(this.state);
	}

	dateChanged(value, dateString) {
		this.state.date = dateString;
		this.state.invalidDate = false;
		this.setState(this.state);
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.author || !this.state.author.trim() || (this.state.author.match(/\\$/)) || (this.state.author.indexOf("\"") >= 0)) {
			prevState.invalidAuthor = true;
		}
		if (!this.state.selectedActivity) {
			prevState.invalidActivity = true;
		}
		if (!this.state.date) {
			prevState.invalidDate = true;
		}
		this.setState(prevState);
		if (!this.state.invalidAuthor && !this.state.invalidActivity && !this.state.invalidDate) {
			return true;
		}
		return false;
	}

	onCreateClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				authors: this.state.author.trim(),
				activity: this.state.selectedActivity ? this.state.selectedActivity : "",
				date: this.state.date ? this.state.date : ""
			};
			this.props.dispatch(clinicalPathwayDetailsAction.createService(this.props.match.params.id, payload));
		}
		else {
			this.setState({ invalidService: true });
		}
	}

 
	onOk() {
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	render() {
		if (this.props.clinicalPathwayDetails.fetchAllAuthorsAndActivities === "FULFILLED") {
			this.state.list = this.props.clinicalPathwayDetails.authorsAndActivities.data;
			this.state.loading = false;
			this.props.dispatch(clinicalPathwayDetailsAction.clearGetAuthorsAndActivities());
		}

		if (this.props.clinicalPathwayDetails.createClinicalPathWayService === "FULFILLED") {
			this.state.data = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.state.author = "";
			this.state.selectedActivity = "";
			this.state.date = "";
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			} 
			this.fetchDataFromServer();
			this.props.dispatch(clinicalPathwayDetailsAction.createServiceCompleted());
		}

		if (this.props.clinicalPathwayDetails.deleteAuthorAndActivityStatus === "FULFILLED") {
			this.state.deleteVisible = false;
			this.state.data = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.fetchDataFromServer();
			this.props.dispatch(clinicalPathwayDetailsAction.deleteAuthorAndActivityCompleted());
		}

		if (this.props.extClinicalPathway.position) {
			this.state.position = this.props.extClinicalPathway.position;
			// this.props.dispatch({ type: eventNames.CLEAR_SIDE_BAR_POSITION, payload: {} });
		}

		return (
			<div>
				<BreadCrumb useSearch items={this.generateItems()} />
				<div className="row margin-rop-10">
					<div className="col-md-4">
						<label className="common_label"><I18 tkey='Enter Author' /></label>
						<NameInput
							value={this.state.author}
							inputChanged={this.authorChanged.bind(this)}
							maxLength={255} />
						{this.state.invalidAuthor && <span className='invalid_right fixed_label'><I18 tkey='Enter Valid Author (Special characters " \ not allowed)' /></span>}
					</div>
					<div className="col-md-4">
						<label className="common_label"><I18 tkey='Action' /></label>
						<ActivityDropdown
							activity={this.state.selectedActivity}
							onActivityChange={this.onActivityChange.bind(this)} />
						{this.state.invalidActivity && <span className='invalid_right fixed_label'><I18 tkey='Select Action' /></span>}
					</div>
					<div className="col-md-4">
						<label className="common_label"><I18 tkey='Date' /></label>
						<div className="w_100">
							<DatePicker
								className="w_100" 
								showTime 
								format='DD/MM/YYYY HH:mm'
								value={this.state.date ? moment(this.state.date, "DD/MM/YYYY HH:mm") : ""}
								onChange={this.dateChanged.bind(this)}
								onOk={this.onOk} 
							/>
							{this.state.invalidDate && <span className='invalid_right fixed_label'><I18 tkey='Select Date' /></span>}
						</div>          
					</div>
				</div>
				<div className="text_align_right margin-top-10 margin-bottom-10 ">
					<ActionButton className="common_button margin_top_15px_mob" onClick={this.onCreateClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
				</div>
				<div className="w_100">
					<div className="w_100 di margin-bottom-18">
						<ReactTable
							data={this.state.list}
							loading={this.state.loading}
							LoadingComponent={() => { return (<Loader loading={this.state.loading} isTable={true} />); }}
							multiSort={false}
							pageSize={this.state.list.length ? this.state.list.length : 3}
							onFetchData={this.onFetchData.bind(this)}
							showPagination={false}
							noDataText={this.state.loading ? "" : i18Get("NO AUTHORS AND ACTIVITIES FOUND", utils.getLanguage())}
							columns={this.getCols()}
							onPageChange={this.onPageChange.bind(this)}
						/>
					</div>
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteElement.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.elementToDelete.name}</span> ?
					</div>
				</Modal>

				{/* {  this.state.modelVisible ?
                    <div>
                        <AssignAndApprovePopup
                            refreshCompleted={this.refreshCompleted}
                            elementId={this.state.subTreatmentId}
                            exType={this.state.type}
                            type = {this.state.elementType}
                            showAssignAndApprove={this.state.modelVisible} />
                    </div>
                    : ''
                } */}
			</div>
		);
	}
}

AuthorsAndActivities.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	extClinicalPathway: store.extClinicalPathway,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(AuthorsAndActivities));