import events from "../../actions/events";

const initialState = {
	description: {},
	getDescriptionCompleted: false,
	saveDescription: {},
	saveDescriptionCompleted: false,
	fileUploadResponse: {},
	fileUploadCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${events.HEALTH_PATHWAY_REMARKS_GET}_FULFILLED`:
			return {
				...state,
				getHealthPathwayRemarksCompleted: "FULFILLED",
				remarks: action.payload.data.data
			};
		case `${events.HEALTH_PATHWAY_REMARKS_GET}_REJECTED`:
			return {
				...state,
				getHealthPathwayRemarksCompleted: "REJECTED"
			};
		case events.CLEAR_HEALTH_PATHWAY_REMARKS_GET:
			return {
				...state,
				getHealthPathwayRemarksCompleted: false
			};

		case `${events.HEALTH_PATHWAY_DEFINITION_GET}_FULFILLED`:
			return {
				...state,
				getHealthPathwayDefinitionCompleted: "FULFILLED",
				definition: action.payload.data.data
			};
		case `${events.HEALTH_PATHWAY_DEFINITION_GET}_REJECTED`:
			return {
				...state,
				getHealthPathwayDefinitionCompleted: "REJECTED"
			};
		case events.CLEAR_HEALTH_PATHWAY_DEFINITION_GET:
			return {
				...state,
				getHealthPathwayDefinitionCompleted: false
			};
		case `${events.SAVE_DESCRIPTION}_FULFILLED`:
			return {
				...state,
				saveDescriptionCompleted: "FULFILLED",
				saveDescription: action.payload.data.data
			};
		case `${events.SAVE_DESCRIPTION}_REJECTED`:
			return {
				...state,
				saveDescriptionCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_SAVE_DESCRIPTION:
			return {
				...state,
				saveDescriptionCompleted: false
			};
		case `${events.COPY_TO_HEALTH_PATHWAYS}_FULFILLED`:
			return { ...state, copyToHealthPathways: "FULFILLED", statusCode: action.payload.data.data };
		case `${events.COPY_TO_HEALTH_PATHWAYS}_REJECTED`:
			return { ...state, copyToHealthPathways: "REJECTED" };
		case events.CLEAR_COPY_TO_HEALTH_PATHWAYS:
			return { ...state, copyToHealthPathways: false };

		case `${events.CHECK_COPY_TO_HEALTH_PATHWAY_STATUS}_FULFILLED`:
			return { ...state, checkCopyToHealthPathwayStatus: "FULFILLED", copyToHealthPathwayStatus: action.payload.data.data };
		case `${events.CHECK_COPY_TO_HEALTH_PATHWAY_STATUS}_REJECTED`:
			return { ...state, checkCopyToHealthPathwayStatus: "REJECTED" };
		case events.CHECK_COPY_TO_HEALTH_PATHWAY_STATUS_COMPLETED:
			return { ...state, checkCopyToHealthPathwayStatus: false };

		case `${events.COPY_FROM_HEALTH_PATHWAY}_FULFILLED`:
			return { ...state, copyFromHealthPathway: "FULFILLED", statusCode: action.payload.data.data };
		case `${events.COPY_FROM_HEALTH_PATHWAY}_REJECTED`:
			return { ...state, copyFromHealthPathway: "REJECTED" };
		case events.CLEAR_COPY_FROM_HEALTH_PATHWAY:
			return { ...state, copyFromHealthPathway: false };
	
		case `${events.COMMUNITY_SHARE_HEALTH_PATHWAY}_FULFILLED`:
			return { ...state, communityShareCompleted: "FULFILLED" };
		case `${events.COMMUNITY_SHARE_HEALTH_PATHWAY}_REJECTED`:
			return { ...state, communityShareCompleted: "REJECTED" };
		case events.COMMUNITY_SHARE_HEALTH_PATHWAY_COMPLETED:
			return { ...state, communityShareCompleted: false };

		default: return state;
	}
}
