import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
	Modal, Button
} from "antd";
import utils from "../../../../../utils/utils";
import I18, { i18Get } from "../../../../../i18";
import "../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../plug/regex-name-search-input";
import healthPathwayAction from "../../../../../actions/health-pathway";

class HealthPathwayEditModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			invalidName: false,
			healthPathwayDetails: {},
			healthPathwayId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(healthPathwayAction.getOne(this.props.healthPathwayId, new Date().getTimezoneOffset()));
	}

	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false });
		this.props.refreshCompleted();
	}

	validateFields() {
		const prevState = this.state;
		if (!(this.state.name.trim()) || (this.state.name.match(/\\$/)) || (this.state.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		let payload = {};
		if (this.validateFields()) {
			payload = {
				name: this.state.name.trim()
			};
			this.props.dispatch(healthPathwayAction.updateHealthPathway(payload, this.props.healthPathwayId));
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.healthPathwayId !== this.state.healthPathwayId) {
			this.state.healthPathwayId = this.props.healthPathwayId;
			this.props.dispatch(healthPathwayAction.getOne(this.props.healthPathwayId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		if (this.props.healthPathway.editHealthPathwayStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "" });
			this.props.dispatch(healthPathwayAction.clearUpdateHealthPathway());
			this.props.refreshCompleted();
		}
		if (this.props.healthPathway.fetchOneHealthPathway === "FULFILLED") {
			this.state.healthPathwayDetails = this.props.healthPathway.healthPathway ? this.props.healthPathway.healthPathway : "";
			this.state.name = this.state.healthPathwayDetails.name ? this.state.healthPathwayDetails.name : "";
			this.props.dispatch(healthPathwayAction.cleargetOne());
		}
		if (this.props.healthPathway.fetchOneHealthPathway === "REJECTED") {
			this.props.dispatch(healthPathwayAction.cleargetOne());
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Health Pathway", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
              Cancel
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
              Save
						</Button>
					]}
				>
					<div className="position_relative">
						<div className="form-group ">
							<label className="bold">
								<I18 tkey="Rename Health Pathway" />
                :
							</label>
							<NameInput
								value={this.state.name}
								inputChanged={this.nameChanged.bind(this)}
								maxLength={120}
							/>
							{this.state.invalidName && <span className="invalid text-danger invalid_rename_healthpathway"><I18 tkey='Enter valid health pathway name (Special characters " \ not allowed)' /></span>}
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

HealthPathwayEditModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	healthPathway: store.healthPathway
}))(HealthPathwayEditModel));
