import React, { useEffect, useState } from "react";
import I18 from "../../i18";
import selectColumn from "./columns.json";
import { Popover, Checkbox, Button } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const CHECK_ALL = "CHECK_ALL";

function ShowHide(props) {
	const [check, setCheck] = useState({});
	const [visible, setVisible] = useState(false);
	const [isItMadeChange, setIsItMadeChange] = useState(false);
	const [intermediate, setIntermediate] = useState(false);
	const [checkAll, setCheckAll] = useState(false);

	useEffect(() => { setAsDefault();}, [props]);

	const handleChange = (e) => {
		setIsItMadeChange(true);

		if (e !== CHECK_ALL) {
			if (!check[e]) {
				check[e] = true;
			} else {
				delete check[e];
			}
			setCheck(JSON.parse(JSON.stringify(check)));
			let activeCount = selectColumn[props.columnKey].nonActive.length;
			Object.keys(check).forEach((name) => { if (check[name] === true) { activeCount++; } });
			setCheckAll(activeCount === selectColumn[props.columnKey].labels.length);
			setIntermediate(!!activeCount && activeCount < selectColumn[props.columnKey].labels.length );
		} else {
			toggleCheckAllOptions();
		}

	};

	const getContent = () => {
		return (
			<div className="shohideContent">
				{renderOptions()}
				<div className="shohidebuttoncontainer">
					<Button onClick={setAsDefault}><I18 tkey={"Cancel"} /></Button>
					<Button onClick={savehandle} type="primary">
						<I18 tkey={"Save"} />
					</Button>
				</div>
			</div>
		);
	};

	const toggleCheckAllOptions = () => {
		if (!checkAll) {
			selectColumn[props.columnKey].labels.map( (item) => {
				if (!selectColumn[props.columnKey].nonActive.includes(item)) {
					check[item] = true;
				}
			});
			setCheck(JSON.parse(JSON.stringify(check)));
		} else {
			Object.keys(check).forEach((name) => (check[name] = false));
		}
		setCheck(JSON.parse(JSON.stringify(check)));
		let activeCount = selectColumn[props.columnKey].nonActive.length;
		Object.keys(check).forEach((name) => { if (check[name] === true) { activeCount++; } });
		setCheckAll((activeCount) === selectColumn[props.columnKey].labels.length);
		setIntermediate(!!activeCount && activeCount < selectColumn[props.columnKey].labels.length );
		return true;
	};

	const renderOptions = () => {
		// const options = [];
		return selectColumn[props.columnKey].labels.map((item) => {
			return (<div className="show_hide_col_li">
				<span className="show_hide_col_li_check">
					<Checkbox
						disabled={
							selectColumn[props.columnKey].nonActive.includes(item) ? true : false
						}
						checked={
							check[item] ? true : selectColumn[props.columnKey].nonActive.includes(item) ? true : false
						}
						onChange={() => { handleChange(item);}}
					/>
				</span>
				<span className="show_hide_col_li_label">
					<I18 tkey={item.replace(/_/g, " ")} />
				</span>
			</div>);
		});
	};

	const handleVisibleChange = (visible) => {

		if (isItMadeChange) { setAsDefault();}
		setVisible(visible);
	};
	const savehandle = () => {
		props.onChangeColSelect({
			check: check,
			checkAll: checkAll,
			intermediate: intermediate
		});
		setVisible(false);
		setIsItMadeChange(false);
	};

	const title = () => {
		return (
			<div>
				<div className="w-50 float_right">
					<Checkbox
						indeterminate={intermediate}
						checked={checkAll}
						onChange={() => { handleChange(CHECK_ALL); }}
					>
						<I18 tkey={"Select All"} />
					</Checkbox>
				</div>
				<div className="w-50 float_left">
					<h4><I18 tkey={"Include"} /></h4>
				</div>
			</div>
		);
	};

	const setAsDefault = () => {
		setVisible(false);
		setCheck(props.selectedColumns && props.selectedColumns.check
        	? JSON.parse(JSON.stringify(props.selectedColumns.check))
        	: {});
		setCheckAll(props.selectedColumns && props.selectedColumns.checkAll
        	? JSON.parse(JSON.stringify(props.selectedColumns.checkAll))
        	: "");
		setIntermediate(props.selectedColumns && props.selectedColumns.intermediate
        	? JSON.parse(JSON.stringify(props.selectedColumns.intermediate))
        	: "");
	};
	
	return (
		<li className="showHideBox">
			<Popover
				placement="leftTop"
				style={{ width: "100px", float: "right" }}
				title={title()}
				trigger="click"
				content={getContent()}
				open={visible}
				onOpenChange={() => {handleVisibleChange(); }}
			>
				<Button className="shohidebutton">
					<EyeOutlined />
					{/* <I18 className="showlabel" tkey=' Show/Hide Columns'/> */}
				</Button>
			</Popover>
		</li>
	);
}
export default  ShowHide;