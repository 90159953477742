import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactMultiSelectCheckboxes from "../../../../../../../plug/react-multiselect-checkboxes";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import communitySectionAction from "../../../../../../../actions/community-section";

class selectCommunitySection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communityData: [],
			offset: 0,
			numResults: 100,
			search: "",
			options: [],
			selected: null,
			currentSelected: null,
			fetchAllMeta: null
		};
	}

	UNSAFE_componentWillMount() {
		this.state.communityData = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchCommunityList();
	}

	componentDidUpdate() {
		if (this.props.data != this.state.currentSelected && this.state.options.length > 0) {
			this.state.currentSelected = this.props.data;
			this.setSelectedItems();
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	setSelectedItems() {
		const prevState = this.state;
		if (this.state.options) {
			prevState.selected = this.state.options.filter((el) => this.props.data.some((f) => f === el.value));
		}
		this.setState(prevState);
	}

	renderHospitalOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.communityData) {
			this.state.communityData.map((data) => {
				prevState.options.push({ value: data.id, label: data.name });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchCommunityList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += "&status=APPROVED";
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(communitySectionAction.getAll(queryString, this.state.fetchAllMeta));
		});
	}

	onSearch(search) {
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.setState({
				...this.state, isListLoading: true, search, offset: 0
			});
			this.timer = setTimeout(() => {
				this.fetchCommunityList(search);
			}, 800);
		}
	}

	onListScroll() {
		const offset = this.state.offset + this.state.numResults;
		this.setState({ ...this.state, offset }, () => { this.fetchCommunityList(); });
	}

	selectAllOption = {
		value: "all",
		label: "All Community"
	  };

	onChange = (e, a) => {

		if (a.action === "select-option" && a.option.value === "all") {
			this.props.communityChanged(this.state.options);
		} else if (a.action === "deselect-option" && a.option.value === "all") {
			this.props.communityChanged([]);
		} else { this.props.communityChanged(e); }
	};


	render() {
		if (this.props.communitySection.isFetchAllCommunitySectionCompleted === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.communitySection.fetchAllElementClassMeta) {
				this.state.communityData = this.props.communitySection.communitySection ? this.props.communitySection.communitySection : [];
				this.renderHospitalOptions();
				this.props.dispatch(communitySectionAction.clearGetAll());
			}
		}
		const currentOption = this.state.options && this.state.selected && this.state.selected.length !== this.state.options.length ? this.state.selected : this.state.selected ? [this.selectAllOption, ...this.state.selected] : null;

		return (
			<div className="community_multi_select">
				<ReactMultiSelectCheckboxes
					className="w_100 di"
					value={currentOption}
					onChange={this.onChange}
					options={[this.selectAllOption, ...this.state.options]}
					isMulti={true}
					isClearable
					allowSelectAll={true}
					isDisabled={this.props.disabled}
					placeholderButtonLabel={i18Get("Select Community", utils.getLanguage())}
					isLoading={this.state.isListLoading}
					noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
					loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				/>
			</div>
		);
	}
}

selectCommunitySection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	specialty: store.specialty,
	translations: store.translations,
	communitySection: store.communitySection
}))(selectCommunitySection));
