import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import Specialities from "./specialities";
import ActivityFamilies from "./activity-families";
import ClinicalPathway from "../clinical-pathway";
import isPermissionGranted from "../../../../../../utils/permission";

class Activities extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="activities_table_scroll">
				<Switch>
					<Route path={locations.specialities} component={Specialities} />
					<Route path={locations.activity_families} component={ActivityFamilies} />
					{isPermissionGranted(false, "READ") && <Route path={locations.administration_clinical_pathway} component={ClinicalPathway} />}
					<Redirect to={locations.specialities} />
				</Switch>
			</div>
		);
	}
}

Activities.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Activities));
