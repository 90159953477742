import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import InterventionList from "./intervention-list";
// import DurationList from './duration-list';
import locations from "../../../../../../router/locations";
import events from "../../../../../../actions/events";
import DefaultValueList from "./defaultValue-list";

class AnalyticalSheetsTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: 1
		};
	}

	UNSAFE_componentWillUpdate() {
		if (this.state.screenId !== this.props.screenId && (this.props.location.pathname.indexOf(locations.durations) === -1)) {
			this.state.screenId = this.props.screenId;
			this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: this.props.screenId });
		}
	}

	render() {
		if (this.props.location.pathname.indexOf(locations.defaultValues) !== -1) {
			return <DefaultValueList costRefreshTime={this.props.costRefreshTime} isRefreshing={this.props.isRefreshing} organization={this.props.organization} courseReference={this.props.courseReference} typeActivity={this.props.typeActivity} totalCount={this.props.totalCount} />;
		}
		return <InterventionList organization={this.props.organization} organizationMode={this.props.organizationMode} courseReference={this.props.courseReference} typeActivity={this.props.typeActivity} totalCount={this.props.totalCount} dateFilter={this.props.dateFilter} />;
	}
}

AnalyticalSheetsTab.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(AnalyticalSheetsTab));
