import eventNames from "../../actions/events";

const initialState = {
	dropDownData: "",
	selectDropDownData: "",
	fetchOneAnaesthesiaComplete: false,
	selectedAnaesthesia: {},
	fetchAllStaffForAnaesthesia: false,
	fetchAllMedicalDeviceForAnaesthesia: false,
	fetchAllPharmacyForAnaesthesia: false,
	staff: {},
	medicalDevice: {},
	pharmacy: {},
	isUpdateAnaesthesiaComplete: false,
	updatedAnaesthesia: {},
	aneasthesiaLinkList: [],
	getAneasthesiaLinkStatus: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.ANESTHESIA_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "REJECTED"
			};
		case `${eventNames.ANESTHESIA_GET_ALL}_PENDING`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "PENDING"
			};
		case `${eventNames.ANESTHESIA_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dropDownData: action.payload.data.data.data,
					isFetchAllDropDownDataCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_ANESTHESIA_GET_ALL:
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: false
			};
		case `${eventNames.ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "REJECTED"
			};
		case `${eventNames.ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL}_PENDING`:
			return {
				...state,
				isFetchAllDropDownDataCompleted: "PENDING"
			};
		case `${eventNames.ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dropDownData: action.payload.data.data.data,
					isFetchAllDropDownDataCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: "FULFILLED"
			};
	
		case eventNames.CLEAR_ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL:
			return {
				...state,
				dropDownData: [],
				isFetchAllDropDownDataCompleted: false
			};	

		case `${eventNames.ANESTHESIA_ADD}_REJECTED`:
			return {
				...state,
				iselementAddCompleted: "REJECTED"
			};
		case `${eventNames.ANESTHESIA_ADD}_PENDING`:
			return {
				...state,
				iselementAddCompleted: "PENDING"
			};
		case `${eventNames.ANESTHESIA_ADD}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					addElementsData: action.payload.data.data,
					iselementAddCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				iselementAddCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ANESTHESIA_ADD:
			return {
				...state,
				elementAddData: [],
				iselementAddCompleted: false
			};

		case `${eventNames.ANESTHESIA_GET}_REJECTED`:
			return {
				...state,
				isElementGetCompleted: "REJECTED"
			};
		case `${eventNames.ANESTHESIA_GET}_PENDING`:
			return {
				...state,
				isElementGetCompleted: "PENDING"
			};
		case `${eventNames.ANESTHESIA_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsData: action.payload.data.data,
					isElementGetCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementGetCompleted: "FULFILLED"
			};

		case eventNames.CLEAR_ANESTHESIA_GET:
			return {
				...state,
				isElementGetCompleted: false
			};

		case `${eventNames.ANESTHESIA_DELETE}_REJECTED`:
			return {
				...state,
				isElementDeleteCompleted: "REJECTED"
			};
		case `${eventNames.ANESTHESIA_DELETE}_PENDING`:
			return {
				...state,
				isElementDeleteCompleted: "PENDING"
			};
		case `${eventNames.ANESTHESIA_DELETE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementsDeletedData: action.payload.data.data,
					isElementDeleteCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				sites: [],
				isElementDeleteCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ANESTHESIA_DELETE:
			return {
				...state,
				elementsDeletedData: [],
				isElementDeleteCompleted: false
			};
		case `${eventNames.GET_ALL_ANAESTHESIA}_REJECTED`:
			return {
				...state,
				isFetchAllAnaesthesiaCompleted: "REJECTED"
			};
		case `${eventNames.GET_ALL_ANAESTHESIA}_PENDING`:
			return {
				...state,
				isFetchAllAnaesthesiaCompleted: "PENDING"
			};
		case `${eventNames.GET_ALL_ANAESTHESIA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					anaesthesiaList: action.payload.data.data.data,
					anaesthesiaCount: action.payload.data.data.count,
					isFetchAllAnaesthesiaCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchAllAnaesthesiaCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_GET_ALL_ANAESTHESIA:
			return {
				...state,
				anaesthesiaList: [],
				isFetchAllAnaesthesiaCompleted: false
			};		

		case `${eventNames.CREATE_ANAESTHESIA}_REJECTED`:
			return {
				...state,
				isCreateAnaesthesiaCompleted: "REJECTED"
			};		
		case `${eventNames.CREATE_ANAESTHESIA}_PENDING`:
			return {
				...state,
				isCreateAnaesthesiaCompleted: "PENDING"
			};
		case `${eventNames.CREATE_ANAESTHESIA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					anaesthesia: action.payload.data.data,
					isCreateAnaesthesiaCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				anaesthesia: [],
				isCreateAnaesthesiaCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_ANAESTHESIA:
			return {
				...state,
				isCreateAnaesthesiaCompleted: false
			};
		case `${eventNames.FETCH_ONE_ANAESTHESIA}_REJECTED`:
			return {
				...state,
				fetchOneAnaesthesiaComplete: "REJECTED"
			};
		case `${eventNames.FETCH_ONE_ANAESTHESIA}_PENDING`:
			return {
				...state,
				fetchOneAnaesthesiaComplete: "PENDING"
			};
		case `${eventNames.FETCH_ONE_ANAESTHESIA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedAnaesthesia: action.payload.data.data,
					fetchOneAnaesthesiaComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchOneAnaesthesiaComplete: "FULFILLED"
			};

		case eventNames.CLEAR_FETCH_ONE_ANAESTHESIA:
			return {
				...state,
				fetchOneAnaesthesiaComplete: false
			};
		case `${eventNames.COPY_ANAESTHESIA}_FULFILLED`:
			return {
				...state,
				copyAnaesthesiaData: action.payload.data,
				copyanaesthesia: "FULFILLED"
			};
		case `${eventNames.COPY_ANAESTHESIA}_REJECTED`:
			return {
				...state,
				copyanaesthesia: "REJECTED"
			};
		case `${eventNames.COPY_ANAESTHESIA}_PENDING`:
			return {
				...state,
				copyanaesthesia: "PENDING"
			};
		case eventNames.CLEAR_COPY_ANAESTHESIA:
			return {
				...state,
				copyanaesthesia: false
			};
		case `${eventNames.UPDATE_ANAESTHESIA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedAnaesthesia: action.payload.data,
					isUpdateAnaesthesiaComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isUpdateAnaesthesiaComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_ANAESTHESIA}_REJECTED`:
			return {
				...state,
				isUpdateAnaesthesiaComplete: "REJECTED"
			};
		case `${eventNames.UPDATE_ANAESTHESIA}_PENDING`:
			return {
				...state,
				isUpdateAnaesthesiaComplete: "PENDING"
			};
		case eventNames.CLEAR_UPDATE_ANAESTHESIA:
			return {
				...state,
				isUpdateAnaesthesiaComplete: false
			};

		case `${eventNames.ANESTHESIA_REMOVE}_FULFILLED`:
			return { ...state, deleteAnaesthesiaStatus: "FULFILLED" };
		case `${eventNames.ANESTHESIA_REMOVE}_REJECTED`:
			return { ...state, deleteAnaesthesiaStatus: "REJECTED" };
		case eventNames.ANESTHESIA_REMOVE_COMPLETED:
			return { ...state, deleteAnaesthesiaStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_ANESTHESIA}_FULFILLED`:
			return { ...state, saveAndApproveAnaesthesiaStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_ANESTHESIA}_REJECTED`:
			return { ...state, saveAndApproveAnaesthesiaStatus: "REJECTED" };
		case eventNames.CLEAR_SAVE_AND_APPROVE_ANESTHESIA:
			return { ...state, saveAndApproveAnaesthesiaStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_ANESTHESIA}_FULFILLED`:
			return { ...state, asssignAndApproveAnaesthesiaStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_ANESTHESIA}_REJECTED`:
			return { ...state, asssignAndApproveAnaesthesiaStatus: "REJECTED" };
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_ANESTHESIA:
			return { ...state, asssignAndApproveAnaesthesiaStatus: false };

		case `${eventNames.GET_APPROVED_ANESTHESIA}_REJECTED`:
			return {
				...state,
				getAnaesthesiaStatus: "REJECTED"
			};

		case `${eventNames.GET_APPROVED_ANESTHESIA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					anaesthesiaList: action.payload.data.data.data,
					anaesthesiaCount: action.payload.data.data.count,
					getAnaesthesiaStatus: "FULFILLED"
				};
			}
			return {
				...state,
				getAnaesthesiaStatus: "FULFILLED"
			};
		case eventNames.GET_APPROVED_ANESTHESIA_COMPLETED:
			return {
				...state,
				anaesthesiaList: [],
				getAnaesthesiaStatus: false
			};
		case `${eventNames.GET_LINKED_ITEMS_FOR_ANESTHESIA}_FULFILLED`:
			return {
				...state,
				aneasthesiaLinkList: action.payload.data.data.linkedTreatments,
				getAneasthesiaLinkStatus: "FULFILLED"
			};
		case `${eventNames.GET_LINKED_ITEMS_FOR_ANESTHESIA}_REJECTED`:
			return {
				...state,
				getAneasthesiaLinkStatus: "REJECTED"
			};
		case eventNames.GET_LINKED_ITEMS_FOR_ANESTHESIA_COMPLETED:
			return {
				...state,
				getAneasthesiaLinkStatus: false
			};
		case `${eventNames.SAVE_AND_APPROVE_ELEMENT}_FULFILLED`:
			return { ...state, saveAndApproveElementStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_ELEMENT}_REJECTED`:
			return { ...state, saveAndApproveElementStatus: "REJECTED" };
		case eventNames.CLEAR_SAVE_AND_APPROVE_ELEMENT:
			return { ...state, saveAndApproveElementStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_ELEMENT}_FULFILLED`:
			return { ...state, asssignAndApproveElementStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_ELEMENT}_REJECTED`:
			return { ...state, asssignAndApproveElementStatus: "REJECTED" };
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_ELEMENT:
			return { ...state, asssignAndApproveElementStatus: false };

		case `${eventNames.SUBTREATMENT_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneSubTreatmentComplete: "REJECTED"
			};
		case `${eventNames.SUBTREATMENT_GET_ONE}_PENDING`:
			return {
				...state,
				fetchOneSubTreatmentComplete: "PENDING"
			};
		case `${eventNames.SUBTREATMENT_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selectedSubTreatment: action.payload.data.data,
					fetchOneSubTreatmentComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchOneSubTreatmentComplete: "FULFILLED"
			};

		case eventNames.CLEAR_SUBTREATMENT_GET_ONE:
			return {
				...state,
				fetchOneSubTreatmentComplete: false
			};
		case `${eventNames.GET_ALL_EXISTING_ANESTHESIA}_FULFILLED`:
			return { ...state, getExistingAnesthesiaStatus: "FULFILLED", existingAnesthesia: action.payload.data.data.data };
		case `${eventNames.GET_ALL_EXISTING_ANESTHESIA}_REJECTED`:
			return { ...state, getExistingAnesthesiaStatus: "REJECTED", existingAnesthesia: action.payload.data.data.data };
		case eventNames.CLEAR_GET_ALL_EXISTING_ANESTHESIA:
			return { ...state, getExistingAnesthesiaStatus: false };

		case `${eventNames.GET_ALL_NEW_ANESTHESIA}_FULFILLED`:
			return { ...state, getNewAnesthesiaStatus: "FULFILLED", getNewAnesthesia: action.payload.data.data.data };
		case `${eventNames.GET_ALL_NEW_ANESTHESIA}_REJECTED`:
			return { ...state, getNewAnesthesiaStatus: "REJECTED", getNewAnesthesia: action.payload.data.data.data };
		case eventNames.CLEAR_GET_ALL_NEW_ANESTHESIA:
			return { ...state, getNewAnesthesiaStatus: false };

		case `${eventNames.SAVE_AND_APPROVE}_FULFILLED`:
			return { ...state, saveAndApproveStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE}_REJECTED`:
			return { ...state, saveAndApproveStatus: "REJECTED" };
		case eventNames.CLEAR_SAVE_AND_APPROVE:
			return { ...state, saveAndApproveStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE}_FULFILLED`:
			return { ...state, asssignAndApproveStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE}_REJECTED`:
			return { ...state, asssignAndApproveStatus: "REJECTED" };
		case eventNames.CLEAR_ASSIGN_AND_APPROVE:
			return { ...state, asssignAndApproveStatus: false };

		default:
			return state;
	}
}
