import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import { Collapse } from "antd";

const { Panel } = Collapse;

class ActiveComponents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkList: [],
			list: []
		};
	}

	UNSAFE_componentWillMount() {}

	getSubStepName(subStep) {
		if (subStep && subStep.checked) {
			return <I18 tkey={subStep.interventionSummaryName ? subStep.interventionSummaryName : ""} />;
		}
	}

	subStepDetails(subSteps) {
		return (
			<div className="pl-25">
				{subSteps && subSteps.length ?
					subSteps.map((subStep, index) => {
						if (subStep.checked) { 
							return (<div className="" key={index}>
								{subStep ?
									<div className={`${ subStep.modelTreatmentId ? "model_attached_td" : subStep.isCheckList ? "check_list" : "" }`}><div></div>
										{subStep.isCheckList && !subStep.isHeading ?
											<div className={subStep.isCheckList ? "check_list check_list_bold check_list padding-left-20 right-arrow-bullat" : ""}>{this.getSubStepName(subStep)}</div>
											:
											<div className={subStep.isCheckList ? "check_list check_list_bold square-bullat" : "square-bullat"}>
												{this.getSubStepName(subStep)}
											</div>

										}
									</div>
									: ""}
							</div>);
						}
					})
					: ""}

			</div>
		);
	}

	callback() { }

	getPannelHeader = (step) => {
		return (
			<React.Fragment>
				<span className={`${step.modelTreatmentId ? "model_attached_td" : ""}`}><I18 tkey={step.checked ? step.problem : ""} /></span>
			</React.Fragment>
		);
	};

	render() {

		return (
			<Collapse ghost={true} >
				{
					this.props.checkList.map((step, index) =>
						//step.checked && !step.modelTreatmentId ?
						step.checked ?
							<Panel header={this.getPannelHeader(step)} key={index}>
								<div>
									{this.subStepDetails(step.additionalInfo, step)}
								</div>
							</Panel>
							: ""
					)
				}
			</Collapse>
		);
	}

}

ActiveComponents.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(ActiveComponents));