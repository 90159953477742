import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../../../plug/reactTable";   
import "../../../../../../../../plug/reactTable/react-table.css";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal } from "antd";
import locations from "../../../../../../../../router/locations";
import sterilizationActions from "../../../../../../../../actions/sterilization";
import Loader from "../../../../../../../../plug/loader";
import events from "../../../../../../../../actions/events";
import AssignAndApproveModel from "./assign-and-approve-model";
import { statusList } from "../../../../../../../../constants";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export class ClassList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sterilizationList: [],
			totalPages: 1,
			loading: false,
			deleteCPVisible: false,
			page: utils.checkForParameter("page", this.props.location) ? parseInt(utils.checkForParameter("page", this.props.location)) : 1,
			pageSize: utils.checkForParameter("pageSize", this.props.location) ? parseInt(utils.checkForParameter("pageSize", this.props.location)) : localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : 10,
			deletedSterilization: {},
			sort: "",
			desc: 0,
			filterName: "all",
			status: "",
			hospitalId: "",
			search: this.props.search,
			modelVisible: false,
			assingRow: {},
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			costCenterId: utils.checkForParameter("costCenterId", this.props.location) ? utils.checkForParameter("costCenterId", this.props.location) : "",
			consumableSpecialityId: utils.checkForParameter("consumableSpecialityId", this.props.location) ? utils.checkForParameter("consumableSpecialityId", this.props.location) : "",
			consumableFamilyId: utils.checkForParameter("consumableFamilyId", this.props.location) ? utils.checkForParameter("consumableFamilyId", this.props.location) : ""

		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.props.onRef(utils.sameDropper());
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: d => d,
				width: 80,
				resizable: false,
				sortable: false,
				Cell: row => (
					<div className="margin_left_auto">
						<div className="min_width_12_px">
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
								: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							}
						</div>
						{row.value.status === statusList.STATUS.values.APPROVED ?
							<DeleteOutlined className=" delete_color margin-right-10" type="delete" onClick={this.deleteClicked(row.value).bind(this)}/>
							: <div className="min_width_12_px"> </div>
						}
					</div>
				)
			},
			{
				Header: i18Get("CODE", utils.getLanguage()),
				id: "code",
				accessor: d => d.code,
				width: 120,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "name",
				accessor: d => d.name,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("STERILIZATION SPECIALITY NAME", utils.getLanguage()),
				id: "consumableSpecialityName",
				accessor: d => d.consumableSpecialityName,
           
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("STERILIZATION FAMILY NAME", utils.getLanguage()),
				id: "consumableFamilyName",
				accessor: d => d.consumableFamilyName,
				width: 130,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				Cell: row => (
					row.value ? this.getText(row.value) : "Master"
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				width: 150,
				Cell: row => (
					this.getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	editClicked (sterilization) {
		return () => {
			this.generateQueryParams();
			this.props.history.push(locations.consumable_sterilization_root +  `/${locations.reusableMedicalDevices_class_tab}/${sterilization.id}${this.props.location.search}`);
		};
	}

	approveClicked(sterilizationClass) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, sterilizationClassId: sterilizationClass.id });
		};
	}

	deleteClicked(sterilization) {
		return () => {
			this.setState({ deletedSterilization: sterilization, deleteCPVisible: true });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	hideModalDelete() {
		this.setState({ deleteCPVisible: false });
	}

	deleteSterilization() {
		this.props.dispatch(sterilizationActions.deleteClassSterilization(this.state.deletedSterilization.id));
		this.setState({ deleteCPVisible: false, deletedSterilization: {} });
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = this.props.sort ? this.props.sort : "";
			prevState.desc = this.props.desc ? this.props.desc : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.props.getSortDetails({ sort: this.state.sort, desc: this.state.desc, numResults: this.state.pageSize, offset: ((this.state.page - 1) * this.state.pageSize) });
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.setState({ loading: true });
		this.generateQueryParams();
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.consumableSpecialityId ? `&consumableSpecialityId=${this.state.consumableSpecialityId}` : "";
		queryString += this.state.consumableFamilyId ? `&consumableFamilyId=${this.state.consumableFamilyId}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.search ? `&code=${encodeURIComponent(this.state.search)}&name=${encodeURIComponent(this.state.search)}&` : "";
		this.props.dispatch(sterilizationActions.fetchAllClassSterilization(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	generateQueryParams() {
		let queryParams = `page=${this.state.page}&pageSize=${this.state.pageSize}&rowScrollIndex=${this.state.rowScrollIndex}&`;
		queryParams += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		queryParams += this.state.consumableSpecialityId ? `consumableSpecialityId=${this.state.consumableSpecialityId}&` : "";
		queryParams += this.state.consumableFamilyId ? `consumableFamilyId=${this.state.consumableFamilyId}&` : "";
		queryParams += this.state.status ? `&status=${this.state.status}` : "";
		queryParams += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryParams += this.state.search ? `&code=${encodeURIComponent(this.state.search)}&name=${encodeURIComponent(this.state.search)}&` : "";
		this.props.history.push(this.props.location.pathname  + "?" + queryParams);
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	getList() {
		return (<div className="Reusable_medical_devices_table" ><ReactTable

			{...utils.getPropsForReactTable(this.state.sterilizationList, (this.state.page - 1), this.state.totalPages, 
				this.state.loading, () => {return (<Loader loading={this.state.loading} isTable={true}/>);}, false, 
				this.state.pageSize, true, this.onFetchData.bind(this), this.getCols(), true, false, 
				this.onPageChange.bind(this), "STERILIZATION", "rt-body-x_scroll-hidden", this.onPageSizeChange.bind(this))}
			rowScroll={this.rowScroll.bind(this)}
			rowScrollIndex={this.state.rowScrollIndex}
		/></div>);
	}

	setPageNumber = (PageNumber) => this.setState({ page: PageNumber });

	render() {
		if ((this.state.search !== this.props.search) || 
        (this.state.consumableSpecialityId !== this.props.consumableSpecialityId) || 
        (this.state.consumableFamilyId !== this.props.consumableFamilyId)) {
			this.state.search = this.props.search;
			this.state.page = 1;
            
			this.state.consumableSpecialityId = this.props.consumableSpecialityId;
			this.state.consumableFamilyId = this.props.consumableFamilyId;
			// this.setState({...this.state}, ()=>{
			this.fetchDataFromServer();
			// });
		}
		if (this.state.status !== this.props.status) {
			this.setState({ status: this.props.status }, () => {
				this.fetchDataFromServer();
			});
		}
		if (this.state.hospitalId !== this.props.hospitalId) {
			this.setState({ hospitalId: this.props.hospitalId }, () => {
				this.fetchDataFromServer();
			});
		}
		return (
			<div className="pole_top_section">
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete sterilization", utils.getLanguage())}
					visible={this.state.deleteCPVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteSterilization.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedSterilization.name}</span> ?
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							refreshCompleted={this.refreshCompleted}
							sterilizationClassId={this.state.sterilizationClassId}
							typeActivity={this.state.typeActivity}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}

	componentDidMount() {
		this.props.onRef(this);

	}


	componentDidUpdate() {
		if (this.props.sterilization.fetchAllClassSterilizationStatus === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = parseInt(Math.ceil( this.props.sterilization.fetchAllClassSterilization.count / this.state.pageSize));            
			this.state.sterilizationList = this.props.sterilization.fetchAllClassSterilization.data;
			this.props.totalCount(this.props.sterilization.fetchAllClassSterilization.count);
			this.props.dispatch(sterilizationActions.clearFetchAllClassSterilization());
			this.setState({ ...this.state });
		}
		if (this.props.sterilization.fetchAllClassSterilizationStatus === "REJECTED") {
			this.state.loading = false;
			this.state.totalPages = 1;
			this.state.sterilizationList = [];
			this.props.dispatch(sterilizationActions.clearFetchAllClassSterilization());
			this.setState({ ...this.state });
		}
		if (this.props.sterilization.deleteSterilizationClassStatus === "FULFILLED") {
			this.state.loading = true;
			this.props.dispatch(sterilizationActions.deleteClassSterilizationCompleted());
			this.setState({ ...this.state }, () => {
				this.fetchDataFromServer();
			});
		}
		// if((this.state.search !== this.props.search) || 
		// (this.state.consumableSpecialityId !== this.props.consumableSpecialityId) || 
		// (this.state.consumableFamilyId !== this.props.consumableFamilyId)) {
		//     this.state.search = this.props.search;
		//    // this.state.page = 1;
            
		//     this.state.consumableSpecialityId = this.props.consumableSpecialityId;
		//     this.state.consumableFamilyId = this.props.consumableFamilyId;
		//     this.setState({...this.state}, ()=>{
		//         this.fetchDataFromServer();
		//     });
		// }

	}


}

ClassList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	sterilization: store.sterilization,
	translations: store.translations
}))(ClassList));
