import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../i18";
import loginActions from "../../../../actions/user/login";
import UiUtils from "../../../../utils/ui-utils";
import utils from "../../../../utils/utils";
import locations from "../../../../router/locations";
import Config from "../../../../MOM-config";

const userKey = "USER_DETAILS";
class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	logoutClicked() {
		UiUtils.showLogout(`${i18Get("Are you sure you want to logout", utils.getLanguage())} ?`, this.onLogout.bind(this));
	}

	onLogout() {
		this.props.dispatch(loginActions.userLogout());
	}

	dropDownClicked(path) {
		this.props.history.push(path);
	}

	render() {
		if (this.props.user.isLogoutComplete === "FULFILLED") {
			localStorage.setItem(userKey, "");
			window.location = "/";
		}
		return (
			<div className="dropdown user_profile display_table_cell">
				<a className="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<img src={(this.props.user.loginData && this.props.user.loginData.profileUrl) ? (Config.profileUrl + this.props.user.loginData.profileUrl) : "/images/default_id.png"} />
					<span className="hidden-xs">{this.props.user.loginData ? this.props.user.loginData.name : ""}</span>
				</a>
				<ul className="dropdown-menu margin-0 profile_menu no-padding dropdown-menu2 top_66" data-wow-delay="0.4s">
					<li className="dropdown-item hidden-sm hidden-md hidden-lg text_align_center">
						<a>
              Logged in as
							<br />
							{this.props.user.loginData.name ? this.props.user.loginData.name : ""}
						</a>
					</li>
					<li className="dropdown-item">
						<a onClick={this.dropDownClicked.bind(this, locations.profile)}>
							<i className="fa fa-user" aria-hidden="true" />
							<I18 tkey="Profile" />
						</a>
					</li>
					<li className="dropdown-item hidden-sm hidden-md hidden-lg">
						<a onClick={this.dropDownClicked.bind(this, locations.site)}>
							<i className="fa fa-cog" aria-hidden="true" />
							<I18 tkey="Administration" />
						</a>
					</li>
					<li className="dropdown-item">
						<a onClick={this.logoutClicked.bind(this)}>
							<i className="glyphicon glyphicon-log-out" aria-hidden="true" />
							<I18 tkey="Logout" />
						</a>
					</li>
				</ul>
			</div>
		);
	}
}

Profile.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	translations: store.translations
}))(Profile));
