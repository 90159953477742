import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../../i18";
import "antd/dist/antd.min.css";
import DataList from "./dataList";
import "./style.css";

function ReorderProblem(props) {

	//const [listProblem, setListProblem]  = useState([]);
	const [beforeAdmission, setBeforeAdmission]  = useState([]);
	const [clinicalPathways, setClinicalPathways]  = useState([]);
	const [afterCheckout, setAfterCheckout]  = useState([]);
	const [selectedBeforeAdmission, setSelectedBeforeAdmission]  = useState([]);
	const [selectedClinicalPathways, setSelectedClinicalPathways]  = useState([]);
	const [selectedAfterCheckout, setSelectedAfterCheckout]  = useState([]);
	const [quickReorderChanges, setQuickReorderChanges]  = useState([]);
	const [orderChanged, setOrderChanged] = useState(false);

	useEffect(() => {
		if (props.listProblem) {
			dataManimulation(props.listProblem);
			//setListProblem(props.listProblem);
		}

	}, []);



	useEffect(() => {
		if (quickReorderChanges !== props.quickReorderChanges) {
			const list = JSON.parse(JSON.stringify(props.clinicalPathwayProblems.problemList.mapping));
			dataManimulation(list);
			setQuickReorderChanges(props.quickReorderChanges);
			setSelectedBeforeAdmission([]);
			setSelectedClinicalPathways([]);
			setSelectedAfterCheckout([]);
			setOrderChanged(false);

		} 
		
		if (props.unSelect === true) {
			deSelectHandler();
		}
	});
	useEffect(() => {

		const data = [...selectedBeforeAdmission, ...selectedClinicalPathways, ...selectedAfterCheckout];
		props.selectedItems(data);

		if (data.length === 0) { props.deSelect();}
	}, [selectedBeforeAdmission, selectedClinicalPathways, selectedAfterCheckout]);

	useEffect(() => {
		const data = [...beforeAdmission, ...clinicalPathways, ...afterCheckout];
		props.setListItems(data, orderChanged);
	}, [beforeAdmission, clinicalPathways, afterCheckout]);

	const dataManimulation = () => {
		const BEFORE_ADMISSION = props.listProblem.filter(row => row.stepSectionType === "BEFORE_ADMISSION");
		const CLINICAL_PATHWAY = props.listProblem.filter(row => row.stepSectionType === "CLINICAL_PATHWAY");
		const AFTER_CHECKOUT = props.listProblem.filter(row => row.stepSectionType === "AFTER_CHECKOUT");
		setBeforeAdmission(BEFORE_ADMISSION);
		setClinicalPathways(CLINICAL_PATHWAY);
		setAfterCheckout(AFTER_CHECKOUT);
		props.setItemCount(props.listProblem ? props.listProblem.length : 0);

	};

	const setListItemsHandler = (data, type) => {
		if (type === "BEFORE_ADMISSION")
		{
			setBeforeAdmission([...data]);
		}
		if (type === "CLINICAL_PATHWAY")
		{
			setClinicalPathways([...data]);
		}
		if (type === "AFTER_CHECKOUT")
		{
			setAfterCheckout([...data]);
		}

		setOrderChanged(true);
	};
	const  selectedItemsHandler = (data, type) => {

		if (type === "BEFORE_ADMISSION")
		{
			setSelectedBeforeAdmission([...data]);
		}
		if (type === "CLINICAL_PATHWAY")
		{
			setSelectedClinicalPathways([...data]);
		}
		if (type === "AFTER_CHECKOUT")
		{
			setSelectedAfterCheckout([...data]);
		}
	};

	const deSelectHandler = (type) => {		
		if (type === "BEFORE_ADMISSION")
		{
			setSelectedBeforeAdmission([]);
		}
		if (type === "CLINICAL_PATHWAY")
		{
			setSelectedClinicalPathways([]);
		}
		if (type === "AFTER_CHECKOUT")
		{
			setSelectedAfterCheckout([]);
		}
	};

	return (<>
		<table className="reorder-table">
			
			{beforeAdmission.length > 0 && <>
				<tr className="sub-header"><td colSpan={4}><I18 tkey="BEFORE ADMISSION" /></td></tr>
				<tr><td colSpan={4}><DataList key="BEFORE ADMISSION" {...props} list={beforeAdmission} type="BEFORE_ADMISSION" selectedItemsHandler={selectedItemsHandler} deSelectHandler={deSelectHandler} setListItemsHandler={setListItemsHandler} quickReorderChanges = {quickReorderChanges} /></td></tr></>}
			{clinicalPathways.length > 0 && <>
				<tr className="sub-header"><td colSpan={4}><I18 tkey="HOSPITALISATION"  /></td></tr>
				<tr><td colSpan={4}><DataList key="HOSPITALISATION" {...props} list={clinicalPathways} type="CLINICAL_PATHWAY"  selectedItemsHandler={selectedItemsHandler} deSelectHandler={deSelectHandler} setListItemsHandler={setListItemsHandler} quickReorderChanges = {quickReorderChanges} /></td></tr></>}
			{afterCheckout.length > 0 && <>
				<tr className="sub-header"><td colSpan={4}><I18 tkey="AFTER CHECKOUT"  /></td></tr>
				<tr><td colSpan={4}><DataList key="HOSPITALISATION" {...props} list={afterCheckout} type="AFTER_CHECKOUT"  selectedItemsHandler={selectedItemsHandler} deSelectHandler={deSelectHandler} setListItemsHandler={setListItemsHandler} quickReorderChanges = {quickReorderChanges} /></td></tr></>}
		</table>
	</>);
	
}

ReorderProblem.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		actions: store.actions
	}))(ReorderProblem)
);
