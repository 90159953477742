import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import permissionAction from "./redux/action";
import { Collapse, Checkbox } from "antd";
import { Button } from "antd";
import Roles from "../roles/components/roles";
import "./dome";
const { Panel } = Collapse;

class List extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			users: [],
			dataLoadng: false,
			memberListView: false,
			memberList: [],
			createNew: false,
			editWind: false,
			userRoles: [],
			userRolesBackup: [],
			selectedRoles: [],
			search: "",
			newChanges: false,
			paramsRolesId: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.props.totalCount(0);
		//this.fetchDataFromServer();
	}

	UNSAFE_componentWillUpdate() {
		const id = 	window.location.href?.split("?")[1]?.split("=")[1];
		if (id && id !== this.state.paramsRolesId) {
			const prevState = this.state;
			prevState.paramsRolesId = id;
			this.setState({ prevState });
			 console.log(">paramsRolesId>>>>>>>>>>>>>>>>>>", this.state.paramsRolesId);
		}
	}

	editClicked() {
		return () => {
			const prevState = this.state;
			prevState.editWind = true;
			this.setState({ prevState });
		};

	}

	//Use this function for fetch Ids
	createPayloadData(data) {

		const dataList = [];
		if (data) {
			data.map(e => { dataList.push(e.value); });
		}
		return dataList;
	}

	onSave = () => {

		this.setState({ loading: true });

		const payload = {
			permissions: { ...this.state.userRoles }
		};
		this.props.dispatch(permissionAction.create(payload));
	};

	onChange = () => {
		//console.log(key);
	};

	fetchDataFromServer() {
		this.setState({ dataLoadng: true });
		let queryString = "";
		queryString += this.state.search ? `search=${this.state.search}&` : "";
		queryString += this.state.selectedRoles ? `roleIds=${this.createPayloadData(this.state.selectedRoles)}` : "";
		this.props.dispatch(permissionAction.getAll(queryString));
	}


	filterChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState, this.fetchDataFromServer());
	}

	handleChange = (value) => {
		const prevState = this.state;
		prevState.selectedRoles = value;
		if (this.state.selectedRoles.length > 0)
		{
			this.setState(prevState, this.fetchDataFromServer());
		} else {
			this.setState({ ...prevState,  userRoles: [], userRolesBackup: [] });
		}
	};

	returnHeaderCheckBoxList(data, menu) {
		return (
			data.map((role, index) => {

				return (
					<th key={index} className='header_check_box'><Checkbox checked={this.isCheckAll(role, menu)} onClick={this.onCheckAll(role, menu)} indeterminate={this.isIntermediate(role, menu)} /></th>
				);
			}));
	}

	isCheckAll(role, mainMenu) {
		const prevState = this.state;
		for (const submenu in prevState.userRoles[role][mainMenu]) {
			if (!prevState.userRoles[role][mainMenu][submenu]) {
				return false;
			}
		}
		return true;
	}

	isIntermediate(role, mainMenu) {
		const prevState = this.state;
		let checkedCount = 0;
		let uncheckedCount = 0;
		for (const submenu in prevState.userRoles[role][mainMenu]) {
			if (!prevState.userRoles[role][mainMenu][submenu]) {
				uncheckedCount++;

			} else {
				checkedCount ++;
			}
		}

		if (uncheckedCount > 0 && checkedCount > 0)
		{
			return true;
		}
		return false;
	}

	onClearAll() {
		const prevState = this.state;
		prevState.userRoles = JSON.parse(JSON.stringify(this.state.userRolesBackup));
		prevState.newChanges = false;
		this.setState(prevState);
	}

	onCheckAll(role, mainMenu) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.checkAllAction(role, mainMenu, e.target.checked);
			this.setState({ newChanges: true });
		};
	}

	checkAllAction(role, mainMenu, action) {
		const prevState = this.state;
		for (const submenu in prevState.userRoles[role][mainMenu]) {
			prevState.userRoles[role][mainMenu][submenu] = action;
		}
		this.setState(prevState);
	}

	onCheck(mainMenu, role, subMenu) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			const prevState = this.state;
			prevState.userRoles[role][mainMenu][subMenu] = e.target.checked;
			prevState.newChanges = true;
			this.setState(prevState);

			if (this.isViewPermissionRow(subMenu) && e.target.checked == false)
			{
				this.checkAllAction(role, mainMenu, false);
			}
		};

	}

	mainMenuList(menus, keyMenu) {
		const option = [];

		for (const keyData in this.state.userRoles) {

			option.push(<td className='item_check_box'><Checkbox disabled={!this.isViewActivated(menus, keyData, keyMenu)} checked={this.state.userRoles[keyData][menus][keyMenu]} onChange={this.onCheck(menus, keyData, keyMenu)} /></td>);

		}
		return option;
	}

	isViewActivated(mainMenu, role, subMenu)
	{
		const menuKeys = Object.keys(this.state.userRoles[role][mainMenu]);
		const primeryKey = menuKeys.filter((data) => { return data.includes("READ");});

		if (this.isViewPermissionRow(subMenu)) { return true;}
		else 
		{return this.state.userRoles[role][mainMenu][primeryKey[0]] ? true : false;}
	}

	isViewPermissionRow(keyMenu)
	{
		return  keyMenu.includes("READ");
	}

	nameSwitcher(e) {
		const splitData = e.split("_");
		const expression = splitData[splitData.length - 1];
		switch (expression) {
			case "READ":
				return "VIEW";
			case "CREATE":
				return "CREATE";
			case "EDIT":
				return "UPDATE";
			case "DELETE":
				return "DELETE";
			default:
		}
	}

	crudMenuList(menus, firstRoleMenusKeys, userRolesKey) {
		const option = [];

		for (const keyMenu in this.state.userRoles[userRolesKey[0]][menus]) {
			option.push(<tr className= {this.isViewPermissionRow(keyMenu) ? "permission_master_checkBox" : ""}><td>{this.nameSwitcher(keyMenu)}</td>{this.mainMenuList(menus, keyMenu)}</tr>);
		}
		return option;
	}

	getList(userRoles, returnHeaderCheckBoxList, crudMenuList) {
		if (userRoles.length == 0) { return (<div className='w100 no_data_found'><I18 tkey={this.state.dataLoadng ? "Loading..." : "PLEASE SELECT ROLE...!"}/> </div>);}

		const userRolesKey = Object.keys(userRoles);
		const selectedRoles = userRolesKey;
		const firstRoleMenus = this.state.userRoles[userRolesKey[0]];
		const firstRoleMenusKeys = Object.keys(firstRoleMenus);
		return (
			<div>
				<div className="col-sm-12 border_common collaps_header container padding-left-40">
					<table className='collapse_table_header top_header' >
						<tr>
							<th></th>
							{
								selectedRoles.map((data, index) => {
									return (<th className='font-size-12 header_section' key={"role_" + index} >{data.replace("_", " ")}</th>);
								})
							}
						</tr>
					</table>
				</div>

				<div className="col-sm-12 padding-left-0 padding-right-0 margin-top-1px-d">
					<Collapse defaultActiveKey={this.state.selectedRoles.length === 0 && this.state.defaultRoles === this.state.selectedRoles.id ? [] : [0]} onChange={this.onChange}>
						{
							firstRoleMenusKeys.map((menus, index) => {
								return (<Panel header={
									<table className='collapse_table_header' >
										<tr>
											<th className='font-size-12'><I18 tkey={menus.replace("_", " ")} /></th>
											{returnHeaderCheckBoxList(selectedRoles, menus)}
										</tr>
									</table>
								} key={index}>

									<div>
										<table className='collapse_table_body' >
											<tbody>
												{crudMenuList(menus, firstRoleMenusKeys, userRolesKey)}
											</tbody>
										</table>
									</div>
								</Panel>);
							})
						}
					</Collapse>
				</div>
			</div>
		);
	}

	render() {

		if (this.props.permissions.getAllStatus == "FULFILLED") {
			this.state.userRoles = this.props.permissions.rolePermisions.data;
			this.state.userRolesBackup = JSON.parse(JSON.stringify(this.state.userRoles));
			this.state.dataLoadng = false;
			this.props.dispatch(permissionAction.clearGetAll());
		}

		if (this.props.permissions.getAllStatus == "REJECTED") {
			this.state.dataLoadng = false;
			this.props.dispatch(permissionAction.clearGetAll());
		}

		if (this.props.permissions.permisionSaveStatus == "FULFILLED") {
			this.state.loading = false;
			this.props.dispatch(permissionAction.clearCreate());
			this.fetchDataFromServer();
			this.state.newChanges = false;
		}

		if (this.props.permissions.permisionSaveStatus == "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(permissionAction.clearCreate());
		}

		return (
			<div className="row">
				<div className="col-sm-12 padding-top-10 padding-bottom-10 padding-left-10 border_common margin-bottom-1px-d ">
					<div className='col-sm-5'>
						<Roles
							value={this.state.selectedRoles}
							onChange={this.handleChange}
							isMulti = {true}
							defaultRoles= { this.state.paramsRolesId}
						/>
					</div>
					<div className="col-md-3 col-sm-3">
					</div>

					<div className='top_elements float_right'>
						<Button disabled={!this.state.newChanges} key="submit" type="primary" loading={this.state.loading} className='font-size-12' onClick={() => { this.onSave(); }} >
							<I18 tkey="Apply Changes" />
						</Button>
					</div>
					{this.state.newChanges && <div className='top_elements clean_button' >
						<a className='font-size-12' onClick={() => { this.onClearAll(); }} ><I18 tkey="Clear Changes" /></a>
					</div>}
				</div>
				{this.getList(this.state.userRoles, this.returnHeaderCheckBoxList.bind(this), this.crudMenuList.bind(this))}
			</div>
		);
	}
}

List.propTypes = {
	permissions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	permissions: store.permissions,
	translations: store.translations
}))(List));