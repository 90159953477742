import React, { Component } from "react";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import locations from "../../../../../../../router/locations";
import NumericInput from "../../../../../../../plug/numeric-input";

class TopDetailsSection extends Component {
	constructor(props) {
		super(props);
	}

	formatText(value, text, isFirst) {
		return (
			<span>
				{value && !isFirst ? ",  " : ""}
				{value ? <I18 tkey={text} /> : ""}
				{ value ? `: ${value}  ` : ""}
			</span>
		);
	}

	renderTextDescription() {
		return (
			<div className="">
				<div className="word_break">
					{this.formatText(this.props.intervention.ipp, "Patient", true)}
					{this.formatText(this.props.intervention.admissionId, "Admission Id")}
					{this.formatText(this.props.intervention.doctorName, "Doctor")}
					{this.formatText(this.props.intervention.room, "Room")}
				</div>
				<div>
					<I18 tkey="Captured Data" />
          :&nbsp;
					{this.props.intervention.date ? this.formatText(utils.formatDate(this.props.intervention.date), "Date", true) : ""}
					{this.props.intervention.startDate ? this.formatText(utils.formatDate(this.props.intervention.startDate), "Start Date") : ""}
					{this.props.intervention.endDate ? this.formatText(utils.formatDate(this.props.intervention.endDate), "End Date") : ""}
					{this.props.intervention.operativeTime ? this.formatText(this.props.intervention.operativeTime, "Operative Time") : ""}
					{this.props.intervention.preOperativeTime ? this.formatText(this.props.intervention.preOperativeTime, "PeriOperative Time") : ""}
				</div>
				{(this.props.intervention.treatmentDate || this.props.intervention.treatmentStartDate || this.props.intervention.treatmentEndDate
					|| this.props.intervention.treatmentOperativeTime || this.props.intervention.treatmentPreOperativeTime)
					? (
						<div>
							<I18 tkey="Intervention Data" />
              :&nbsp;
							{this.props.intervention.treatmentDate ? this.formatText(utils.formatDate(this.props.intervention.treatmentDate), "Date", true) : ""}
							{this.props.intervention.treatmentStartDate ? this.formatText(utils.formatDate(this.props.intervention.incisionTime), "Surgery Start") : ""}
							{this.props.intervention.treatmentEndDate ? this.formatText(utils.formatDate(this.props.intervention.fermetureTime), "Surgery End") : ""}
							{this.props.intervention.treatmentOperativeTime ? this.formatText(this.props.intervention.treatmentOperativeTime, "Operative Time") : ""}
							{this.props.intervention.treatmentPreOperativeTime ? this.formatText(this.props.intervention.treatmentPreOperativeTime, "PeriOperative Time") : ""}
						</div>
					) : ""}
			</div>
		);
	}

	render() {
		return (
			<div className="col-md-12">
				<div className="w_100 ellipsis">
					<span className='intervention_name'>
						{this.props.intervention && this.props.intervention.name ? this.props.intervention.name : ""}
					</span>
					<span className='intervention_speciality_name'>
						{this.props.intervention && this.props.intervention.specialityName && this.props.intervention.familyName ?
							`(${this.props.intervention.specialityName}, ${this.props.intervention.familyName})${this.props.doctorName ? `(${this.props.doctorName})` : ""}` : ""}
					</span>
					{(this.props.match.params.organization === locations.tabLocal) ?
						<span className='intervention_name'>
							<br></br>
							<I18 tkey='MOM' />:&nbsp;
							{this.props.intervention && this.props.intervention.momName ?
								this.props.intervention.momName : i18Get("Not Assigned", utils.getLanguage())}
						</span> : ""}
					{(this.props.match.params.organization === locations.tabLocal) ?
						<span className='intervention_speciality_name'>
							{this.props.intervention && this.props.intervention.momSpeciality && this.props.intervention.momFamily ?
								`(${this.props.intervention.momSpeciality}, ${this.props.intervention.momFamily})` : ""}
						</span> : ""}
					<div className="row  align-items-center d-flex margin-bottom-5">
						<div className="col-md-6 padding-left-0">
							<I18 tkey='Intervention Code' />:&nbsp;
							{this.props.intervention && this.props.intervention.code ? this.props.intervention.code : ""}&nbsp;
						</div>
						<div className="col-md-6  justify-content-end align-items-center d-flex common_input form_control_update">
							<label className="common_label width_85 margin-bottom-0"><I18 tkey='Updated At:' /></label>
							<NumericInput className="" value={this.props.intervention.updatedAt} disabled={true} />
						</div>
					</div>
					{(locations.scannedTreatments !== `/${this.props.match.params.sheets}`) && (this.props.intervention) && (this.props.intervention.userName || this.props.intervention.updatedUser) ?
						<div>
							<I18 tkey='Modified By' />:&nbsp;
							{this.props.intervention.updatedUser ? this.props.intervention.updatedUser : (this.props.intervention.userName ? this.props.intervention.userName : "")}
						</div> : ""}
					{(locations.scannedTreatments === `/${this.props.match.params.sheets}`) && this.props.intervention && Object.keys(this.props.intervention).length ?
						<div className="">
							{this.renderTextDescription()}
						</div> : ""}
				</div>
			</div>
		);
	}
}

export default withRouter(TopDetailsSection);
