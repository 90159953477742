
import React, { useEffect, useState } from "react";
import { Modal, Button, Radio, Tooltip, Input } from "antd";
import healthPathwayAction from "actions/health-pathway";
import I18, { i18Get } from "i18";
import SpecialtyDropdown from "components/main-container/overview/main-overview/administration/activities/specialities/selectSpecialities";
import FamilyDropDown from "components/main-container/overview/main-overview/administration/activities/activity-families/selectFamilyForSpeciality";
import action from "../redux/action";
import { useDispatch, useSelector } from "react-redux";
import utils from "../../plugin/utils";
import getUtils from "utils/utils";
import "../../plugin/common.css"
import "./style.css";
import { InfoCircleOutlined } from "@ant-design/icons";
import Config from "MOM-config";

const HealthPathwayDuplicateModel = (props) => {

	const dispatch = useDispatch();
	const { fetchOneHealthPathway } = useSelector((store) => store.healthPathway);
	const healthPathwayData = useSelector((store) => store.healthPathway.getOneDetails);
	const takeDuplicateStatus = useSelector((store) => store.healthPathwayLinkedItems.takeDuplicateStatus);
	const [healthPathwayDetails, setHealthPathwayDetails] = useState({});
	const [isLoading, setIsLoading] = useState(false);
	const [clinicalPathwayType, setClinicalPathwayType] = useState("SURGERY");
	const [isCheckList, setIsCheckList] = useState(false);
	const [typeActivity, setTypeActivity] = useState("HEALTH_PATHWAY");
	const [dupName, setDupeName] = useState("");	
	const [selectedSpecialty, setSelectedSpecialty] = useState(false);	
	const [selectedFamily, setSelectedFamily] = useState(false);
	const [invalidSpecialty, setinvalidSpecialty] = useState(false);
	const [invalidFamily, setInvalidFamily] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);

	useEffect(() => {
		setIsModalOpen(props.modalOpen);
		dispatch(healthPathwayAction.getOne(props.healthPathwayId, new Date().getTimezoneOffset()));
	}, []);

	useEffect(() => {
		if (fetchOneHealthPathway === "FULFILLED") {
			setHealthPathwayDetails(healthPathwayData ? healthPathwayData : "");
			dispatch(healthPathwayAction.cleargetOne());
		}
		if (fetchOneHealthPathway === "REJECTED") {
			dispatch(healthPathwayAction.cleargetOne());
		}
		if (takeDuplicateStatus === "FULFILLED" ) {
			//Refrsh List List
			setIsModalOpen(false);
			props.onVisible(false, true);
			dispatch(action.clearDuplicate());
			setIsLoading(false);
		}

		if (takeDuplicateStatus === "REJECTED") {
			dispatch(action.clearDuplicate());
			setIsLoading(false);
		}
	}, [fetchOneHealthPathway, takeDuplicateStatus]);

	useEffect( () => {
		setDupeName(healthPathwayDetails.name ? `${healthPathwayDetails.name} (DUP 01)` : "");
	}, [healthPathwayDetails]);

	const handleOk = () => {

		if(isValid()) {
			setIsLoading(true);
			dispatch(action.takeDuplicate(props.healthPathwayId,dupName, selectedSpecialty.value, selectedFamily.value, {clinicalPathwayType, name: dupName}  ));
		}
	};

	const isValid = () => {
		let isValidation = true;
		if(!selectedSpecialty) {

			isValidation = false;
			setinvalidSpecialty(true);
		}
		 if (!selectedFamily) {
			isValidation = false;
			setInvalidFamily(true);
		}
		
		if(isValidation){
			setinvalidSpecialty(false);
			setInvalidFamily(false);
		}
		
		return isValidation;
	};
	const handleCancel = () => {
		setIsModalOpen(false);
		props.onVisible(false);
	};

	const toggleClinicalPathwayType = (status) => {
		if (clinicalPathwayType != status) {
			setClinicalPathwayType(status);
		}
	};

	const onSpecialtyChange = (e) => {
		
		const selectedSpecialty = e;
		setSelectedSpecialty(selectedSpecialty);
		setInvalidFamily(false);
		setinvalidSpecialty(false);
		setSelectedFamily(false);

	};

	const onFamilyChanged = (e) => {
		const selectedFamily = e;
		setSelectedFamily(selectedFamily);
		setInvalidFamily(false);
	};
	const onChangeName = e => {
		setDupeName(utils.removeSQLChars(e.target.value));
	};
	const organization = Config.isMasterServer ? "MOM" : "LOCAL"
	return (
		<div className="modal-dialog modal-xl">
			<Modal
				title={`${props.title} (Type: ${clinicalPathwayType ? clinicalPathwayType.replace("_", " "): ""})`}
				open={true}
				onCancel={handleCancel}
				width={700} 
				footer={[
					<Button key="cancel" onClick={handleCancel} disabled={isLoading}>
						<I18 tkey='CANCEL' />
					</Button>,
					<Button loading={isLoading} key="ok" type="primary" onClick={handleOk}>
						<I18 tkey='CREATE' />
					</Button>
				]}
			>
				<div className="custom-modal-content">
					<div className="group">
						<div><b><I18 tkey='Source' /></b>: {`${healthPathwayDetails?.hospitalId ? healthPathwayDetails.hospitalCode : "MOM"} ${healthPathwayDetails?.name || ""}`}</div>
						<div><b><I18 tkey='Hospital' /></b>: {`${healthPathwayDetails?.hospitalName ? healthPathwayDetails.hospitalName : "MOM"}`}</div>
						<div><b><I18 tkey='Speciality' /> / <I18 tkey='Family' /></b>: {healthPathwayDetails?.specialityName || ""} / {healthPathwayDetails?.familyName || ""} </div>	
					</div>
					<label className="line-after"><span><I18 tkey='New HealthPathway' /></span></label>
					<div><b><I18 tkey='Hospital' /> / <I18 tkey='Site' /></b>: {Config.isMasterServer ? <><I18 tkey='MOM' /> / <I18 tkey='MOM' /></> :
						<><I18 tkey={` ${healthPathwayDetails.hospitalName ? healthPathwayDetails.hospitalName : "MOM"}`} /> / <I18 tkey={`${healthPathwayDetails.siteName ? healthPathwayDetails.siteName : "MOM"}`} /></>}
					 </div>
					<div className="spacer"></div>
					<div className="group">

						<div className="btn-group-duplicate btn-group spacer-top-10 float_right">
							<button type="button" onClick={() => toggleClinicalPathwayType("SURGERY")} className={`width_183 ${clinicalPathwayType === "SURGERY" ? "with_border uppercase left_button active-btton" : "with_border uppercase  left-button"}`}>
								<I18 tkey='SURGERY' />
							</button>
							<button type="button" onClick={() => toggleClinicalPathwayType("MEDICINE")} className={`width_200 ${clinicalPathwayType === "MEDICINE" ? "with_border uppercase  active-btton center_btn" : "with_border uppercase center_btn"}`}  >
								<I18 tkey='MEDICINE WITH ACTE' />
							</button>
							<button type="button" data-toggle="dropdown" onClick={() => toggleClinicalPathwayType("MEDICINE_WITHOUT_ACTE")} className={`width_200 ${clinicalPathwayType === "MEDICINE_WITHOUT_ACTE" ? "with_border uppercase right-button active-btton" : "with_border uppercase right-button"}`}>
								<I18 tkey='MEDICINE WITHOUT ACTE' />
							</button>
						</div>
					</div>
					<div className="duplicate-name-main">
						<div className="duplicate-name"><span><b><I18 tkey='Name' /></b></span></div>
						
						<div className="position_relative name_field">
							<Input
							className={"duplicate-name-input"}
							value={dupName}
							onChange={onChangeName}
							maxLength={120}
						/>
						<Tooltip placement="topRight" overlayClassName="special_character_tooltip" title={i18Get("Special characters not allowed except ( ) + _ - ' ,.", getUtils.getLanguage())}><InfoCircleOutlined type="info-circle" theme="outlined" /></Tooltip>
						</div>
					</div>
					<div className="duplicate-speciality-family-main">
						<span className="duplicate-speciality-family"><b><I18 tkey='Speciality' /></b></span>
						<div className="duplicate-speciality-family-dropdown">
							<SpecialtyDropdown setSingleDataDefault= {false} autoSetSingleOne={true} isCheckList={isCheckList} data={(selectedSpecialty ) ? selectedSpecialty  : ""} specialityChanged={onSpecialtyChange} organization={organization} typeActivity={typeActivity ? typeActivity : ""} />
							{invalidSpecialty && <span className='validation_text'><I18 tkey='Select valid speciality' /></span>}
						</div>
						<div className="CodeHolder">{selectedSpecialty? selectedSpecialty.code : ""}</div>
					</div>
					<div className="duplicate-speciality-family-main">
						<span className="duplicate-speciality-family"><b><I18 tkey='Family' /></b></span>
						<div className="duplicate-speciality-family-dropdown">
							<FamilyDropDown setSingleDataDefault= {false} autoSetSingleOne={true} isCheckList={isCheckList} data={( selectedFamily) ? selectedFamily : ""} onFamilyChanged={onFamilyChanged} onFamilyChange={onFamilyChanged} organization={organization} specialityId={selectedSpecialty ? selectedSpecialty.value : ""} typeActivity={typeActivity ? typeActivity : ""} />
							{invalidFamily && <span className='validation_text'><I18 tkey='Select valid family' /></span>}
						</div> 
						<div className="CodeHolder">{selectedFamily? selectedFamily.code : ""}</div>
					</div>
				</div>
			</Modal>
		</div>
	); 

};

export default HealthPathwayDuplicateModel;