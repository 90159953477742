import React, { Component } from "react";
import { Select } from "antd";
import categoryPersonnelNumber from "../../../../../../../../../constants/categoryPersonnelNumber.json";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils.js";

const { Option } = Select;

export default class PersonnelNumberDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryPersonnelNumberList: this.renderCategoryPersonnelNumberOptions()
		};
	}

	renderCategoryPersonnelNumberOptions() {
		const categoryPersonnelNumberList = [];
		categoryPersonnelNumber.forEach((value) => {
			categoryPersonnelNumberList.push(<Option key={value} value={value}>{utils.setLocaleForCurrency(value)}</Option>);
		});
		return categoryPersonnelNumberList;
	}

	render() {
		return (
			<Select
				allowClear
				showSearch
				value={this.props.categoryPersonnelNumber}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.categoryPersonnelNumberChanged}
				placeholder={i18Get("Select quantity", utils.getLanguage())}
				notFoundContent={i18Get("Not Found", utils.getLanguage())}
				optionFilterProp="children"
				filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
			>
				{this.state.categoryPersonnelNumberList}
			</Select>
		);
	}
}
