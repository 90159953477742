import eventNames from "../../actions/events";

const initialState = {
	fetchAllConstantsCompleted: false,
	constants: {},
	updateConstantCompleted: false,
	createStayConstantCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_CONSTANTS}_REJECTED`:
			return {
				...state,
				fetchAllConstantsCompleted: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_CONSTANTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const constants = {};
				constants[action.meta] = action.payload.data.data;
				return {
					...state,
					constants,
					type: action.meta,
					fetchAllConstantsCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllConstantsCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_CONSTANTS:
			return {
				...state,
				fetchAllConstantsCompleted: false
			};
		case `${eventNames.UPDATE_CONSTANT}_REJECTED`:
			return {
				...state,
				updateConstantCompleted: "REJECTED"
			};
		case `${eventNames.UPDATE_CONSTANT}_FULFILLED`:
			return {
				...state,
				updateConstantCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_CONSTANT:
			return {
				...state,
				updateConstantCompleted: false
			};
		case `${eventNames.CREATE_STAY_CONSTANT}_REJECTED`:
			return {
				...state,
				createStayConstantCompleted: "REJECTED"
			};
		case `${eventNames.CREATE_STAY_CONSTANT}_FULFILLED`:
			return {
				...state,
				createStayConstantCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_CREATE_STAY_CONSTANT:
			return {
				...state,
				createStayConstantCompleted: false
			};
		default:
			return state;
	}
}
