import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import ReactMultiSelectCheckboxes from "../../../../../../../../../../plug/react-multiselect-checkboxes";
import I18, { i18Get } from "../../../../../../../../../../i18";
import categoryPersonnelActions from "../../../../../../../../../../actions/category-personnel";
import events from "../../../../../../../../../../actions/events";
import utils from "../../../../../../../../../../utils/utils";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class ResponisbleList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			totalCount: 0,
			extType: "",
			type: "",
			listNumber: 100,
			listOffset: 0,
			isLoading: false,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	fetchData() {
		this.setState({ isLoading: true });
		let queryString = `offset=${this.state.listOffset}&numResults=${this.state.listNumber}&sort=name&desc=0`;
		queryString += "&status=APPROVED";
		queryString += this.state.search ? `&name=${this.state.search}` : "";
		this.props.dispatch(categoryPersonnelActions.fetchAllApprovedCategoryPersonnel(queryString));
	}

	scroll() {
		this.state.listOffset = this.state.listOffset + this.state.listNumber;
		this.fetchData();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			this.state.search = value;
			this.state.listOffset = 0;
			this.fetchData(value);
		}
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumber;
		this.fetchData();
	}

	generateResponsibleList() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.responsibleList) {
			const list = this.state.responsibleList.filter((el) => el.name != null);
			list.map((data) => {
				prevState.options.push({ value: data.id ? data.id : "", label: data.name ? data.name : "" });
			});
		}
		
		if (!this.props.data) {
			utils.setSingleDataDefault(prevState.options, this.props.responsibleChanged, this.props.multiselect, this.props.setSingleDataDefault, this.props.autoSetSingleOne);
		}

		this.state.isLoading = false;
		this.setState(prevState);
	}

	getLoading() {
		return this.state.isLoading;
	}

	render() {
		if ((this.state.extType !== this.props.extType) || (this.props.type !== this.state.type) || (this.props.days !== this.state.days)) {
			this.state.responsibleList = [];
			this.state.options = [];
			this.state.extType = this.props.extType;
			this.state.type = this.props.type;
			this.state.days = this.props.days;
			this.fetchData();
		}
		if (this.props.extClinicalPathway.responsibleListUpdate) {
			this.state.listOffset = 0;
			this.state.responsibleList = [];
			this.state.options = [];
			this.state.search = "";
			this.fetchData();
			this.props.dispatch({ type: events.CLEAR_TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
		}

		if (this.props.categoryPersonnel.isFetchAllApprovedCategoryPersonnelCompleted === "FULFILLED") {
			this.props.dispatch(categoryPersonnelActions.clearFetchAllApprovedCategoryPersonnel());
			this.state.responsibleList = unionWith(this.state.responsibleList, this.props.categoryPersonnel.categoryPersonnelList.data, isEqual);
			this.generateResponsibleList();
			this.state.isLoading = false;
		}

		return (
			<div className="w_100 di  position_relative text_editor_z_index_select react_multi_select_checkbox">
				{this.props.isEdit ? "" : <label className=""><I18 tkey="Select Intervenant" /></label>}
				<ReactMultiSelectCheckboxes
					isMulti = {true}
					styles={customStyles}
					className="w_100 di"
					defaultActiveFirstOption={false}
					onChange={this.props.responsibleChanged}
					value={this.props.data}
					isSearchable
					isClearable
					options={this.state.options}
					onMenuScrollToBottom={this.onListScroll.bind(this)}
					onInputChange={this.onSearch.bind(this)}
					isLoading={this.state.isListLoading}
					noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
					loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				/>
				{this.props.invalidData && <span className={`${this.props.isEdit ? "invalid_right_edit fixed_label" : "invalid_right fixed_label top_section_invalid"}`}><I18 tkey="Please select intervenant" /></span>}
			</div>
		);
	}
}

ResponisbleList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	extClinicalPathway: store.extClinicalPathway,
	categoryPersonnel: store.categoryPersonnel,
	elements: store.elements,
	translations: store.translations,
	scannedTreatments: store.scannedTreatments
}))(ResponisbleList));
