import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "antd";
import locations from "../../../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../../../i18";
import utils from "../../../../../../../../../../utils/utils";
import Filter from "./filter";
import List from "./list";
import assignToTreatmentActions from "../../../../../../../../../../actions/assign-to-treatments";

class AssignToTreatments extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkList: [],
			loading: false,
			organization: locations.tabMom 
		};
	}

	checkListChanged(id) {
		const index = this.state.checkList.indexOf(id);
		const prevState = this.state;
		if (index === -1) {
			prevState.checkList.push(id);
		} else {
			prevState.checkList.splice(index, 1);
		}
		this.setState(prevState);
	}

	clearCheckList = () => {
		this.setState({ ...this.state, checkList: [] });
	};

	afterClose() {
		this.setState({ checkList: [] });
	}

	assignToTreatments() {
		if (this.state.checkList.length && this.props.template.id) {
			this.setState({ loading: true });
			const payload = {
				treatmentIds: this.state.checkList
			};
			this.props.dispatch(assignToTreatmentActions.assignToTreatments(this.props.template.id, payload));
		} else {
			utils.showInfo(i18Get("Select atleast one treatment", utils.getLanguage()));
		}
	}

	renderTitle() {
		return (
			<div className="row">
				<div className="col-md-6">
					<I18 tkey="Assign team to treatment"/>&nbsp;&nbsp;
                    (<I18 tkey="Team"/>:&nbsp;<b>{this.props.template.name}</b>)
				</div>
				<div className="col-md-6 ">
					<div className="model_num_align">
						<I18 tkey="Number of records"/>:&nbsp;
						<b>
							{this.props.assignToTreatments.treatmentList && this.props.assignToTreatments.treatmentList.count ? 
								utils.setLocaleForCurrency(this.props.assignToTreatments.treatmentList.count, null, true) : 0}
						</b>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.assignToTreatments.assignToTreatmentsCompleted === "FULFILLED") {
			this.state.loading = false;
			this.state.checkList = [];
			this.list.fetchDataFromServer();
			this.list.clearCheckAll();
			this.props.dispatch(assignToTreatmentActions.clearAssignToTreatments());
		}
		if (this.props.assignToTreatments.assignToTreatmentsCompleted === "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(assignToTreatmentActions.clearAssignToTreatments());
		}

		return (
			<Modal
				className="cost_modal_cost_screen ant_body_padding"
				title={this.renderTitle()}
				visible={this.props.assignTreatmentVisible}
				confirmLoading={this.state.loading}
				onCancel={this.props.toggleVisibleAssignTreatments(null)}
				okText={i18Get("Assign", utils.getLanguage())}
				cancelText={i18Get("Cancel", utils.getLanguage())}
				afterClose={this.afterClose.bind(this)}
				onOk={this.assignToTreatments.bind(this)}>
				<Filter 
					typeActivity={this.props.template && this.props.template.typeActivity ? this.props.template.typeActivity : null}
					organization={this.state.organization}
					renderOnFilterChange={(filter) => this.list.renderOnFilterChange(filter)}/>
				<List
					categoryPersonnelTemplateId={this.props.template.id}
					organization={this.state.organization}
					typeActivity={this.props.template && this.props.template.typeActivity ? this.props.template.typeActivity : null}
					checkList={this.state.checkList}
					onRef={ref => this.list = ref}
					checkListChanged={this.checkListChanged.bind(this)}
					clearCheckList ={this.clearCheckList.bind(this)}
				/>
			</Modal>
		);
	}
}

AssignToTreatments.propTypes = {
	dispatch: PropTypes.func.isRequired,
	assignToTreatments: PropTypes.object.isRequired
};

export default connect(store => ({
	assignToTreatments: store.assignToTreatments
}))(AssignToTreatments);