import eventNames from "./event";

const initialState = {
	AllTask: "",
	TarifGhm: "",
	TarifSupplements: ""
};


export default function reducer(state = initialState, action) {
	switch (action.type) {
		case eventNames.ALL_TASK_COLUMNS:
			return {
				...state,
				AllTask: action.payload.value
			};
		case eventNames.TARIF_GHM_COLUMNS:
			return {
				...state,
				TarifGhm: action.payload.value
			};
		case eventNames.TARIF_SUPPLEMENTS_COLUMNS:
			return {
				...state,
				TarifSupplements: action.payload.value
			};
		default:
			return state;
	}
}
