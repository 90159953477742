import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import config from "./config.json";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import CardLink from "../../../dashboard/cards/card-link";

class SheetsSideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1
		};
	}

	render() {
		return (
			<div className="mom-sidebar">
				{
					config.map((cardLink) => this.renderContents(cardLink))
				}
			</div>
		);
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {
		if (this.props.user.loginData && this.props.user.loginData.scope == 64 && this.props.user.loginData.roleType === "PATHWAY_USER") {
			if (cardLink.index < 2) {
				return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
			}
			return false;
		}
		if (cardLink.expandable) {
			
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}

		return <CardLink data={cardLink} />;
	}
}

SheetsSideBar.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	translations: store.translations
}))(SheetsSideBar));
