import React from "react";
import { Collapse } from "antd";
import { UpOutlined } from "@ant-design/icons";
const { Panel } = Collapse;

function RightSideBarComponents (props) {

	return <div className="shap-bar-section"><Collapse size="large" expandIcon={({ isActive }) => <UpOutlined rotate={isActive ? 180 : 0} />}>
		<Panel header="Component" key="1">
			<p>{props.children}</p>
		</Panel>
	</Collapse></div>;

}
export default RightSideBarComponents;