import axios from "axios";
import events from "../events";
import Config from "../../MOM-config";

const fetchAllTreatmentsForTypeActivity = (typeActivity, categoryPersonnelTemplateId, query) => ({
	type: events.FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplate/${categoryPersonnelTemplateId}/typeActivity/${typeActivity}/treatments${query ? `?${query}` : ""}`)
});

const clearFetchAllTreatmentsForTypeActivity = () => ({
	type: events.CLEAR_FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY,
	payload: {}
});

const assignToTreatments = (templateId, payload) => ({
	type: events.ASSIGN_TO_TREATMENTS,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplate/${templateId}/treatments`, payload)
});

const clearAssignToTreatments = () => ({
	type: events.CLEAR_ASSIGN_TO_TREATMENTS,
	payload: {}
});

export default {
	fetchAllTreatmentsForTypeActivity,
	clearFetchAllTreatmentsForTypeActivity,
	assignToTreatments,
	clearAssignToTreatments
};
