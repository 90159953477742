import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";

import utils from "../../../../../utils/utils";
import I18, { i18Get } from "../../../../../i18";
import "../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../plug/regex-name-search-input";
import clinicalPathwayAction from "../../../../../actions/clinical-pathway";

class ClinicalPathwayEditModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			invalidName: false,
			clinicalPathwayDetails: {},
			clinicalPathwayId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(clinicalPathwayAction.getOne(this.props.clinicalPathwayId, new Date().getTimezoneOffset()));
	}

	validateFields() {
		const prevState = this.state;
		if (!(this.state.name.trim()) || (this.state.name.match(/\\$/)) || (this.state.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		let payload = {};
		if (this.validateFields()) {
			payload = {
				name: this.state.name.trim()
			};
			this.props.dispatch(clinicalPathwayAction.updateClinicalPathway(payload, this.props.clinicalPathwayId));
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		if (this.props.isCheckList) {
			if (value.includes("CheckList")) {
				prevState.invalidName = false;
				prevState.name = value;
				this.setState(prevState);
			}
		} else {
			prevState.invalidName = false;
			prevState.name = value;
			this.setState(prevState);
		}
	}

	componentDidUpdate() {
		if (this.props.clinicalPathwayId !== this.state.clinicalPathwayId) {
			this.state.clinicalPathwayId = this.props.clinicalPathwayId;
			this.props.dispatch(clinicalPathwayAction.getOne(this.props.clinicalPathwayId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		if (this.props.clinicalPathway.editClinicalPathwayStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "" });
			this.props.dispatch(clinicalPathwayAction.clearUpdateClinicalPathway());
			this.props.refreshCompleted();
		}
		if (this.props.clinicalPathway.fetchOneClinicalPathway === "FULFILLED") {
			this.state.clinicalPathwayDetails = this.props.clinicalPathway.clinicalPathway ? this.props.clinicalPathway.clinicalPathway : "";
			this.state.name = this.state.clinicalPathwayDetails.name ? this.state.clinicalPathwayDetails.name : "";
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
		}
		if (this.props.clinicalPathway.fetchOneClinicalPathway === "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Clinical Pathway", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.props.cancelClicked}
					footer={[
						<Button key="back" onClick={this.props.cancelClicked} className = "margin-right-10">
							<I18 tkey="Cancel" />
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
							<I18 tkey="Save" />
						</Button>
					]}
				>
					<div className="position_relative">
						<div className="form-group ">
							<label className="bold">
								<I18 tkey="Rename Clinical Pathway" />
								:
							</label>
							<NameInput
								value={this.state.name}
								inputChanged={this.nameChanged.bind(this)}
								maxLength={120}
							/>
							{this.state.invalidName && <span className="invalid text-danger invalid_rename_healthpathway"><I18 tkey='Enter valid clinical pathway name (Special characters " \ not allowed)' /></span>}
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

ClinicalPathwayEditModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	clinicalPathway: store.clinicalPathway
}))(ClinicalPathwayEditModel));
