import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

const { Option } = Select;

class SearchFieldDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	options() {
		const newOptions = ["SEARCH ALL FIELDS", "PROBLEM", "DATA", "CODE", "INTERVENTIONS SUMMARY NAME", "INTERVENANT", "STEPS", "DOCTOR NURSE PRESCRIPTION", "EXPECTED RESULTS", "ARGUMENTATION AND FOUNDATIONS"];
		// let statusIndex = this.props.intervention.status ? newOptions.indexOf(this.props.intervention.status) : -1;
		return newOptions.map((option, index) => <Option key={index} value={option.toUpperCase().replace(/ /g, "_").replace(/\//g, "_")}><I18 tkey={option} /></Option>);
	}

	render() {
		return (
			<Select
				value={this.props.field}
				allowClear
				className="w_100 di select_prbl_section"
				onChange={this.props.searchFieldChanged}
				placeholder={i18Get("Select Search Field", utils.getLanguage())}
				notFoundContent={i18Get("Not Found", utils.getLanguage())}
			>
				{this.options()}
			</Select>
		);
	}
}

SearchFieldDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(SearchFieldDropdown));
