import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionBy from "lodash.unionby";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import elementActions from "../../../../../../../actions/elements";
import consumableActions from "../../../../../../../actions/consumable";
import events from "../../../../../../../actions/events";
import extElements from "../../../../../../../constants/extElements.json";
import typeActivity from "../../../../../../../constants/typeActivity.json";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class TypeList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			totalCount: 0,
			category: "",
			elementType: "",
			type: "",
			listNumber: 100,
			listOffset: 0,
			isLoading: false,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	fetchData() {
		this.setState({ isLoading: true });
		const chemo = (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) ? "1" : "";
		if (this.props.isDci) {
			const query = `offset=${this.state.listOffset}&numResults=${this.state.listNumber}&type=${this.state.elementType}&sort=name&desc=0&category=${this.state.category}&search=${this.state.search}&dciId=${this.props.match.params.consumableId}${this.props.type ? `&extentedType=${this.props.type}` : ""}`;
			this.props.dispatch(consumableActions.fetchAllElementsForDci(query));
		} else if (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) {
			this.props.dispatch(elementActions.getAll(this.state.listOffset, this.state.elementType, this.state.listNumber, "name", 0, (this.state.category ? `category=${this.state.category}` : ""), this.state.search, this.props.doctorId, this.props.match.params.treatmentId, chemo, this.props.cycle.id, this.props.type));
		} else {
			this.props.dispatch(elementActions.getAll(this.state.listOffset, this.state.elementType, this.state.listNumber, "name", 0, (this.state.category ? `category=${this.state.category}` : ""), this.state.search, this.props.doctorId, ((this.props.typeActivity === typeActivity.RADIOTHERAPY.toUpperCase()) || (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase())) ? this.props.match.params.treatmentId : this.props.match.params.id, "", "", this.props.type));
		}
	}

	getLabel() {
		return (this.props.type === extElements.extElements.PROSTHESES_DMI) ? "Prostheses DMI" : this.props.type.replace(/_/g, " ").replace(/\w+/g, (txt) => txt.charAt(0) + txt.substr(1).toLowerCase());
	}

	getPrefixLabel() {
		if (this.props.type === extElements.extElements.INSTRUMENTATION
            || this.props.type === extElements.extElements.SMALL_EQUIPMENT) {
			return `${i18Get(this.props.elementType.replace(/_/g, " ").replace(/\w+/g, (txt) => txt.charAt(0) + txt.substr(1).toLowerCase()))} for `;
		}
		return "";
	}

	scroll() {
		this.state.listOffset = this.state.listOffset + this.state.listNumber;
		this.fetchData();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.setState({ isLoading: true });
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchData(value);
			}, 700);
		}
	}

	generateTypeList() {
		this.state.typeList.map((type, index) => {
			this.state.typeList[index].value = this.state.typeList[index].id;
			this.state.typeList[index].label = this.state.typeList[index].name;
		});
		this.state.isLoading = false;
	}

	getLoading() {
		return this.state.isLoading;
	}

	render() {
		if ((this.state.category !== this.props.category) || (this.state.elementType !== this.props.elementType) || (this.props.type !== this.state.type)) {
			this.state.typeList = [];
			this.state.category = this.props.category;
			this.state.elementType = this.props.elementType;
			this.state.type = this.props.type;
			this.fetchData();
		}
		if (this.props.extElements.listUpdate) {
			this.state.listOffset = 0;
			this.state.typeList = [];
			this.state.search = "";
			this.fetchData();
			this.props.dispatch({ type: events.CLEAR_TRIGGER_LIST_UPDATE, payload: {} });
		}
		if (this.props.elements.isFetchAllDropDownDataCompleted === "FULFILLED") {
			this.props.dispatch(elementActions.cleargetAll());
			if (this.props.elements.dropDownData.length === 0 || this.state.elementType === this.props.elements.dropDownData[0].type) {
				this.state.typeList = unionBy(this.state.typeList, this.props.elements.dropDownData, "id");
				this.generateTypeList();
			} else {
				this.state.isLoading = false;
				this.state.typeList = [];
			}
		}
		if (this.props.consumable.fetchAllElementsForDciComplete === "FULFILLED") {
			this.props.dispatch(consumableActions.clearFetchAllElementsForDci());
			this.state.typeList = unionBy(this.state.typeList, this.props.consumable.dciElementList.element, "id");
			this.generateTypeList();
		}

		return (
			<div className="w_100 di  position_relative">
				<label>
					{this.getPrefixLabel()}
					<I18 tkey={this.getLabel()} />
				</label>
				<Select
					styles={customStyles}
					isSearchable
					isLoading={this.state.isLoading}
					options={this.state.typeList}
					value={this.props.data ? this.props.data : null}
					isClearable
					className="w_100 di"
					onMenuScrollToBottom={this.scroll.bind(this)}
					onInputChange={this.onSearch.bind(this)}
					noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
					onChange={this.props.dataChanged}
					loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
					placeholder={i18Get("Select", utils.getLanguage())}
				/>
				{this.props.invalidData && <span className="invalid_right fixed_label "><I18 tkey={`Select a ${this.getLabel().toLowerCase()}`} /></span>}
			</div>
		);
	}
}

TypeList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extElements: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired,
	scannedTreatments: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	extElements: store.extElements,
	elements: store.elements,
	consumable: store.consumable,
	translations: store.translations,
	scannedTreatments: store.scannedTreatments
}))(TypeList));
