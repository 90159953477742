import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../i18";
import locations from "../../../../../router/locations";
import Definition from "./definition";
import "./ext-elements.css";
import utils from "../../../../../utils/utils";
import typeActivity from "../../../../../constants/typeActivity.json";
import healthPathwayActions from "../../../../../actions/health-pathway";
import Remarks from "./remarks";
import Diagram from "mom-ui-commons/build/components/diagram";

class SubHealthPathWay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			healthPathway: i18Get("Health Pathway Details", utils.getLanguage()),
			healthPathwayData: []
		};
	}

	UNSAFE_componentWillMount() {
		const payload = {
			organization: this.props.match.params.organization,
			id: this.props.match.params.id

		};
		this.props.dispatch(healthPathwayActions.getOne(payload.id, new Date().getTimezoneOffset()));
	}

	renderDefinition() {
		return <Route path={locations.sub_health_pathways + locations.definition} render={() => <Definition type="DEFINITION" healthPathwayData={this.state.healthPathwayData} healthPathway={this.state.healthPathway} typeActivity={typeActivity.HEALTH_PATHWAY.toUpperCase()} />} />;
	}

	renderRemarks() {
		return <Route path={locations.sub_health_pathways + locations.remarks} render={() => <Remarks type="REMARKS" healthPathwayData={this.state.healthPathwayData} healthPathway={this.state.healthPathway} typeActivity={typeActivity.HEALTH_PATHWAY.toUpperCase()} />} />;
	}

	renderDiagram() {
		return <Route path={locations.sub_health_pathways + locations.diagram} render={() => <Diagram type="PATHWAY" healthPathwayData={this.state.healthPathwayData} healthPathway={this.state.healthPathway} typeActivity={typeActivity.HEALTH_PATHWAY.toUpperCase()} />} />;
	}

	render() {
		if (this.props.healthPathway.fetchOneHealthPathway === "FULFILLED") {
			this.state.healthPathwayData = this.props.healthPathway.healthPathway;
			this.state.healthPathway = this.props.healthPathway.healthPathway.name;
			this.props.dispatch(healthPathwayActions.cleargetOne());
		}
		return (
			<div className="main_overview_section">
				<div className="">
					<Switch>
						{this.renderRemarks()}
						{this.renderDiagram()}
						{this.renderDefinition()}
					</Switch>
				</div>
			</div>
		);
	}
}

SubHealthPathWay.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	healthPathway: store.healthPathway
}))(SubHealthPathWay));
