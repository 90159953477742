import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../../constants";

function getText(value) {
	return <div>{value}</div>;
}

export default function column(editClicked, deleteClicked, approveClicked) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto">
					<div className="min_width_12_px">
						{row.value.status === statusList.STATUS.values.APPROVED
							? <EditOutlined className=" edit_color margin-right-10" onClick={editClicked(row.value)} />
							: (
								<a className="waiting-for-approve" onClick={approveClicked(row.value)}>
									<img src="/images/waitingforapprovel.png" />
									{" "}
								</a>
							)}
					</div>
					{row.value.status === statusList.STATUS.values.APPROVED
						? <DeleteOutlined className=" delete_color margin-right-10" onClick={deleteClicked(row.value)} />
						: <div className="min_width_12_px"> </div>}
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			maxWidth: 130,
			resizable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("GENERIC NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				row.value ? getText(row.value.name) : ""
			)
		},
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d.hospitalName,
			resizable: true,
			Cell: (row) => (
				row.value ? getText(row.value) : "Master"
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		}
	]);
	return tableArray;
}
