import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import I18 from "../../../../../../../../i18";
import interventionDetailsAction from "../../../../../../../../actions/intervention-details";
import isPermissionGranted from "../../../../../../../../utils/permission";
import { statusList } from "../../../../../../../../constants";

class staff extends Component {

	constructor(props) {
		super(props);

		this.state = {
			list: [],
			treatmentId: this.props.treatmentId
		};
	}

	componentDidMount() {
		this.onFetchData();
	}

	UNSAFE_componentWillUpdate () {
		if (this.state.treatmentId !== this.props.treatmentId)
		{ 
			this.setState({ treatmentId: this.props.treatmentId }, this.onFetchData());
		}
	}

	onFetchData() {

		this.props.dispatch(interventionDetailsAction.getStaff(null, this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id));
	}

	getTable(list) {
		return (
			<table className="w_100">
				<thead>
					<tr>
						<th>
							<I18 tkey='NAME' />
						</th>
						<th>
							<I18 tkey='NUMBER' />
						</th>
						<th>
							<I18 tkey='DURATION' />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map((data, index) => {
				return (
					<tr key={index} >
						<td>{data.personnelName}</td>
						<td>{data.numberOfPersonnels}</td>
						<td>{data.duration ? data.duration : 0}</td>
					</tr>
				);
			});
		}
		else {
			return <tr><td colSpan="3" ><div className="text_align_center w_100"><I18 tkey={`NO ${this.props.type.replace(/_/g, " ").toUpperCase()} FOUND`} /></div></td> </tr>;
		}
	}

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.clinicalPathway.isReadOnly, method);
		
	};
	render() {
		if (this.props.interventionDetails.fetchAllStaff === "FULFILLED") {
			this.state.list = this.props.interventionDetails.staff.personnels;
			this.state.template = this.props.interventionDetails.staff.template ? this.props.interventionDetails.staff.template .templateName : "";
			this.props.dispatch(interventionDetailsAction.clearGetStaff());
		}
		return (
			<div className="editor_no_edit">
				<div className="table_heading"><I18 tkey='STAFF' /> {this.state.template ? ("(" + this.state.template + ")") : ""}
					{this.isPermissionGranted("EDIT") && <button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "staff")}><I18 tkey="EDIT" /></button>}
				</div>
				<div className="di w_100 float_left">
					<div className="table_div risk_table">{this.getTable(this.state.list)}</div>
				</div>
				<div className="di w_100">
					<div className="text_align_right di w_100 margin-bottom-10">
					</div>
				</div>
			</div>
		);
	}
}

staff.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	interventionDetails: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};


export default withRouter(connect(store => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	interventionDetails: store.interventionDetails,
	translations: store.translations,
	permissions: store.permissions,
	clinicalPathway: store.clinicalPathway
}))(staff));
