import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllExtElements = (id, type, queryString) => ({
	type: events.FETCH_ALL_EXT_ELEMENT_DATA,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}?${queryString}`)
});

const clearFetchAllExtElements = () => ({
	type: events.CLEAR_FETCH_ALL_EXT_ELEMENT_DATA,
	payload: {}
});

const deleteExtElement = (id, type, elementId, queryString) => ({
	type: events.DELETE_EXT_ELEMENT,
	payload: axios.delete(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}${queryString ? `?${queryString}` : ""}`)
});

const clearDeleteExtElement = () => ({
	type: events.CLEAR_DELETE_EXT_ELEMENT,
	payload: {}
});

const updateExtElement = (id, type, elementId, payload, queryString) => ({
	type: events.UPDATE_EXT_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}${queryString ? `?${queryString}` : ""}`, { ...payload })
});

const clearUpdateExtElement = () => ({
	type: events.CLEAR_UPDATE_EXT_ELEMENT,
	payload: {}
});

const fetchAllExtElementsForType = (type, queryString) => ({
	type: events.FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE,
	payload: axios.get(`${Config.apiRootSecure}/extelements/${type}?${queryString}`)
});

const clearFetchAllExtElementsForType = () => ({
	type: events.CLEAR_FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE,
	payload: {}
});

const createExtElement = (id, type, payload) => ({
	type: events.CREATE_EXT_ELEMENT,
	payload: axios.post(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}`, { ...payload })
});

const clearCreateExtElement = () => ({
	type: events.CLEAR_CREATE_EXT_ELEMENT,
	payload: {}
});

const rankUpdate = (id, type, elementId, payload) => ({
	type: events.RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}/rank`, { ...payload })
});

const clearRankUpdate = () => ({
	type: events.CLEAR_RANK_UPDATE,
	payload: {}
});

const fetchOneExtElement = (id, type, elementId, queryString) => ({
	type: events.FETCH_ONE_EXT_ELEMENT,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}${queryString ? `?${queryString}` : ""}`)
});

const clearFetchOneExtElement = () => ({
	type: events.CLEAR_FETCH_ONE_EXT_ELEMENT,
	payload: {}
});

const fetchAllDoctorsOfExtElement = (id, elementId, queryString) => ({
	type: events.FETCH_ALL_DOCTORS_OF_EXT_ELEMENT,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/element/${elementId}${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllDoctorsOfExtElement = () => ({
	type: events.CLEAR_FETCH_ALL_DOCTORS_OF_EXT_ELEMENT,
	payload: {}
});

const updateDoctorsOfExtelement = (id, elementId, doctorId, payload) => ({
	type: events.UPDATE_DOCTORS_OF_EXT_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/treatments/${id}/element/${elementId}/doctor/${doctorId}`, { ...payload })
});

const clearUpdateDoctorsOfExtelement = () => ({
	type: events.CLEAR_UPDATE_DOCTORS_OF_EXT_ELEMENT,
	payload: {}
});

export default {
	fetchAllExtElements,
	clearFetchAllExtElements,
	deleteExtElement,
	clearDeleteExtElement,
	updateExtElement,
	clearUpdateExtElement,
	fetchAllExtElementsForType,
	clearFetchAllExtElementsForType,
	createExtElement,
	clearCreateExtElement,
	rankUpdate,
	clearRankUpdate,
	fetchOneExtElement,
	clearFetchOneExtElement,
	fetchAllDoctorsOfExtElement,
	clearFetchAllDoctorsOfExtElement,
	updateDoctorsOfExtelement,
	clearUpdateDoctorsOfExtelement
};
