import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";
import { apiHeadersMultipart } from "../../utils";

const getAll = (offset, type, numResults, sort, desc) => ({
	type: events.TEXTELEMENTS_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/text_elements?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}`)

});

const add = (type, id, payload) => ({
	type: events.TEXTELEMENTS_ADD,
	payload: axios.post(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}`, { ...payload })

});

const get = (type, treatmentId, textElementId) => ({
	type: events.TEXTELEMENTS_GET,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping/${textElementId}`)
});

const update = (treatmentId, type, payload) => ({
	type: events.UPDATE_TEXT_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping/${type}`, { ...payload })
});

const clearUpdate = () => ({
	type: events.CLEAR_UPDATE_TEXT_ELEMENT,
	payload: {}
});

const deleteElement = (type, id, payload) => ({
	type: events.TEXTELEMENTS_DELETE,
	payload: axios.delete(`${Config.apiRootSecure}/treatments/${id}/text_elements/${payload.textElementId}?type=${type}`)

});

const getAllImages = (type, id, query) => ({
	type: events.TEXTELEMENTS_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}${query ? `?${query}` : ""}`)
});

const uploadImage = (title, payload, query) => ({
	type: events.UPLOAD_PIC,
	meta: title,
	payload: axios.post(`${Config.apiRootSecure}/images/upload${query ? `?${query}` : ""}`, payload, { ...apiHeadersMultipart() })
});

const clearImageUpload = () => ({
	type: events.CLEAR_UPLOAD_PIC,
	payload: {}
});

const updateImage = (type, id, payload, query) => ({
	type: events.TEXTELEMENTS_IMAGE_ADD,
	payload: axios.post(`${Config.apiRootSecure}/treatments/${id}/${type}${query ? `?${query}` : ""}`, { ...payload })

});

const deleteImage = (id, imageId, query) => ({
	type: events.TEXTELEMENTS_IMAGE_DELETE,
	payload: axios.delete(`${Config.apiRootSecure}/treatments/${id}/images/${imageId}${query ? `?${query}` : ""}`)
});

const clearDeleteImage = () => ({
	type: events.CLEAR_TEXTELEMENTS_IMAGE_DELETE,
	payload: {}
});

const clearImageUpdate = () => ({
	type: events.CLEAR_TEXTELEMENTS_IMAGE_ADD,
	payload: {}
});

const clearDelete = () => ({
	type: events.CLEAR_TEXTELEMENTS_DELETE,
	payload: {}
});

const cleargetAll = () => ({
	type: events.CLEAR_TEXTELEMENTS_GET_ALL,
	payload: {}
});

const clearAdd = () => ({
	type: events.CLEAR_TEXTELEMENTS_ADD,
	payload: {}
});

const clearGet = () => ({
	type: events.CLEAR_TEXTELEMENTS_GET,
	payload: {}
});

const getClinicalPathwayEligibility = (type, treatmentId, textElementId) => ({
	type: events.ELIGIBILITY_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping/${textElementId}`)
});

const clearGetClinicalPathwayEligibility = () => ({
	type: events.CLEAR_ELIGIBILITY_GET_ONE,
	payload: {}
});

const updateClinicalPathwayEligibility = (treatmentId, type, payload) => ({
	type: events.UPDATE_ELIGIBILITY,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping/${type}`, payload)
});

const clearUpdateClinicalPathwayEligibility = () => ({
	type: events.CLEAR_UPDATE_ELIGIBILITY,
	payload: {}
});

const getHealthPathwayDefinition = (type, treatmentId, textElementId) => ({
	type: events.HEALTH_PATHWAY_DEFINITION_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping/${textElementId}`)
});

const clearGetHealthPathwayDefinition = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_DEFINITION_GET_ONE,
	payload: {}
});

const updateHealthPathwayDefinition = (treatmentId, type, payload) => ({
	type: events.UPDATE_HEALTH_PATHWAY_DEFINITION,
	payload: axios.put(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping/${type}`, payload)
});

const clearUpdateHealthPathwayDefinition = () => ({
	type: events.CLEAR_UPDATE_HEALTH_PATHWAY_DEFINITION,
	payload: {}
});

const getHealthPathwayRemarks = (type, treatmentId, textElementId) => ({
	type: events.HEALTH_PATHWAY_REMARKS_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping/${textElementId}`)
});

const clearGetHealthPathwayRemarks = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_REMARKS_GET_ONE,
	payload: {}
});

const updateHealthPathwayRemarks = (treatmentId, type, payload) => ({
	type: events.UPDATE_HEALTH_PATHWAY_REMARKS,
	payload: axios.put(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping/${type}`, payload)
});

const clearUpdateHealthPathwayRemarks = () => ({
	type: events.CLEAR_UPDATE_HEALTH_PATHWAY_REMARKS,
	payload: {}
});

export default {
	getAll,
	cleargetAll,
	add,
	get,
	clearAdd,
	deleteElement,
	clearDelete,
	clearGet,
	uploadImage,
	clearImageUpload,
	updateImage,
	clearImageUpdate,
	getAllImages,
	deleteImage,
	clearDeleteImage,
	update,
	clearUpdate,
	getClinicalPathwayEligibility,
	clearGetClinicalPathwayEligibility,
	updateClinicalPathwayEligibility,
	clearUpdateClinicalPathwayEligibility,
	getHealthPathwayDefinition,
	clearGetHealthPathwayDefinition,
	updateHealthPathwayDefinition,
	clearUpdateHealthPathwayDefinition,
	getHealthPathwayRemarks,
	clearGetHealthPathwayRemarks,
	updateHealthPathwayRemarks,
	clearUpdateHealthPathwayRemarks
};
