import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import BreadCrumb from "../../../breadcrumb";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { DebounceInput } from "react-debounce-input";
import { Select } from "antd";
import SpecialityCombo from "./specialityCombo";
import FamilyCombo from "./familyCombo";
import screenId from "../../../../../../../router/screenIdList";
import ClassList from "./classlist";
import CreatClass from "./create_class";
import EditClass from "./editClass";
import HospitalSelect from "../../activities/specialities/selectHospital";
import { FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

class Sterilization extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sterilization: {},
			filter: false,
			filterName: "all",
			totalPages: 0,
			sort: utils.checkForParameter("sort", this.props.location) ? utils.checkForParameter("sort", this.props.location) : "",
			desc: utils.checkForParameter("desc", this.props.location) ? utils.checkForParameter("desc", this.props.location) : 0,
			search: utils.checkForParameter("search", this.props.location) ? utils.checkForParameter("search", this.props.location) : "",
			costCenterId: utils.checkForParameter("costCenterId", this.props.location) ? utils.checkForParameter("costCenterId", this.props.location) : "",
			classId: utils.checkForParameter("classId", this.props.location) ? utils.checkForParameter("classId", this.props.location) : "",
			consumableSpecialityId: utils.checkForParameter("consumableSpecialityId", this.props.location) ? utils.checkForParameter("consumableSpecialityId", this.props.location) : "",
			consumableFamilyId: utils.checkForParameter("consumableFamilyId", this.props.location) ? utils.checkForParameter("consumableFamilyId", this.props.location) : "",
			selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			selectedStatus: utils.checkForParameter("selectedStatus", this.props.location) ? utils.checkForParameter("selectedStatus", this.props.location) : "ALL"
		};
	}

	generateItems() {
		const items = [];
		if (this.props.location.pathname.startsWith(`${locations.consumable_sterilization_root}/${locations.reusableMedicalDevices_class_tab}`)) {
			items.push({ name: i18Get("Reusable medical devices class", utils.getLanguage()), link: locations.consumable_sterilization_root + "/" + this.props.match.params.type + this.props.location.search });
		}
		if (this.props.location.pathname === locations.consumable_sterilization_root + `/${this.props.match.params.type}/create`) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.consumable_sterilization_root + `/${this.props.match.params.type}/create${this.props.location.search}` });
		}
		if (this.state.sterilization.id) {
			items.push({ name: this.state.sterilization.name, link: locations.consumable_sterilization_root + `/${this.props.match.params.type}/${this.state.sterilization.id}${this.props.location.search}` });
		}
		return items;
	}

	inputChanged = (e) => {
		const prevState = this.state;
		prevState.search = e.target.value;
		prevState.loading = true;
		this.setState(prevState);
		if (this.list && this.list.setPageNumber) {
			this.list.setPageNumber(1);
		}
		if (this.list && this.list.generateQueryParams) {
			//this.list.generateQueryParams();
		}

		//this\.list\.generateQueryParams\(\);
	};

	onStatusChange = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};

	onHospitalChanged(e) {
		this.setState({ selectedHospital: e ? e : "" });
	}

	clearFilter() {
		this.setState({ selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) }, selectedStatus: "ALL", family: "", speciality: "", search: "" });
	}

	addClicked() {
		this.props.history.push(locations.consumable_sterilization_root + `/${this.props.match.params.type}/create`);
	}

	filterClicked() {
		this.setState({ filter: !this.state.filter });
	}

	filterSelected(e) {
		this.setState({ filterName: e });
	}



	showTop() {

		if (this.props.location.pathname === `${locations.consumable_sterilization_root}/${locations.reusableMedicalDevices_class_tab}`) {
			return true;
		}
		return false;
	}

	getSortDetails(e) {
		this.setState({ sort: e.sort, desc: e.desc, pageSize: e.numResults, offset: e.offset });
		if (this.list && this.list.generateQueryParams) {
			this.list.generateQueryParams();
		}

	}

	specialityChange(e) {
		const prevState = this.state;
		prevState.speciality = e;
		prevState.consumableSpecialityId = null;
		prevState.family = "";
		this.setState(prevState);
		if (this.list && this.list.setPageNumber) {
			this.list.setPageNumber(1);
		}


		//this\.list\.generateQueryParams\(\);
	}
	getSpecialityOptions = (options) => {
		// console.log('options', options);
		if (Array.isArray(options)) {
			const selData = options.find(row => row.id == this.state.consumableSpecialityId);
			if (selData) {
				this.setState({ speciality: selData });
			}
		}
	};

	familyChange(e) {
		const prevState = this.state;
		prevState.family = e;
		prevState.consumableFamilyId = null;
		this.setState(prevState);
		if (this.list && this.list.setPageNumber) {
			this.list.setPageNumber(1);
		}


		//this\.list\.generateQueryParams\(\);
	}

	getFamilyOptions = (options) => {
		if (Array.isArray(options)) {
			const selData = options.find(row => row.id == this.state.consumableFamilyId);
			if (selData) {
				this.setState({ family: selData });
			}
		}
	};

	totalCount(count) {
		this.setState({ totalPages: (count ? count : 0) });
	}

	getFilter() {
		return (
			<div className="row">
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Status" />:</label>
						<Select
							className="w_100"
							value={this.state.selectedStatus ? this.state.selectedStatus : <I18 tkey="All" />}
							onChange={this.onStatusChange}
						>
							<Option key='All' value="ALL"><I18 tkey="All" /></Option>
							<Option key='Approved' value="APPROVED"><I18 tkey="Approved" /></Option>
							<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval " /></Option>
						</Select>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Hospital" />:</label>
						<HospitalSelect
							data={this.state.selectedHospital ? this.state.selectedHospital : ""}
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}

	render() {
		this.state.sterilization = this.props.breadcrumb.componentData;
		return (
			<div className="consumable_definition_table_scroll">
				<div className="main_overview_section">
					<div className="pole_container">
						<BreadCrumb items={this.generateItems()} showRecord={this.showTop()} totalRecords={this.state.totalPages} />
						{!((this.props.location.pathname).includes("create") || (this.props.location.pathname).includes("reusableMedicalDevices-class/")) &&
							<div className="padding-top-15">
								<button className="common_button  margin-right-20 mt_15" onClick={this.addClicked.bind(this)}><i className="fa fa-plus margin-right-5" aria-hidden="true"></i>&nbsp;<I18 tkey="CREATE" /></button>
							</div>
						}
						{this.showTop() &&
							<div className="row padding-top-15 padding-bottom-15">
								<div className="col-md-offset-7 col-md-4">
									<div className="">
										<DebounceInput
											className={"form-control w_100"}
											value={this.state.search}
											debounceTimeout={750}
											placeholder={i18Get("Search...", utils.getLanguage())}
											onChange={this.inputChanged}
										/>
									</div>
								</div>
								<div className="col-md-1 text_align_right">
									{
										this.showTop() &&
										<div className="filter  margin-top-2  margin-right-0" onClick={this.filterClicked.bind(this)}>
											<FilterOutlined className={this.state.filter ? "filter_icon active" : "filter_icon"} type="filter" />
										</div>
									}
								</div>

							</div>
						}
						{
							this.showTop() && this.state.filter &&
							<div>
								{this.getFilter()}
								<div className="row">

									<div className="col-md-4 col-sm-4 margin-bottom-15">
										<label className="common_label"><I18 tkey="Sterilization Speciality" /></label>
										<SpecialityCombo
											getSpecialityOptions={this.getSpecialityOptions}
											data={this.state.speciality ? this.state.speciality : ""}
											specialityChange={this.specialityChange.bind(this)} />
									</div>
									<div className="col-md-4 col-sm-4 margin-bottom-15">
										<label className="common_label"><I18 tkey="Sterilization Family" /></label>
										<FamilyCombo
											data={this.state.family ? this.state.family : ""} familyChange={this.familyChange.bind(this)}
											consumableSpecialityId={this.state.speciality ? this.state.speciality.id : null}
											getFamilyOptions={this.getFamilyOptions}
										/>
									</div>

								</div>
							</div>
						}

						<Switch>
							<Route exact path={locations.create_consumable_sterilization} render={() =>
								this.props.match.params.type === locations.reusableMedicalDevices_class_tab ?
									<CreatClass screenId={screenId.sterilizationCreate} /> : ""} />
							<Route exact path={locations.consumable_sterilization_edit} render={() =>
								this.props.match.params.type === locations.reusableMedicalDevices_class_tab ?
									<EditClass screenId={screenId.sterilizationEdit} />
									: ""} />
							<Route path={locations.consumable_sterilization_root + "/" + locations.reusableMedicalDevices_class_tab}
								render={() => <ClassList search={this.state.search}
									onRef={ref => (this.listClass = ref)}
									status={this.state.selectedStatus}
									hospitalId={this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : ""}
									sort={this.state.sort}
									desc={this.state.desc}
									consumableSpecialityId={this.state.speciality ? this.state.speciality.id : this.state.consumableSpecialityId ? this.state.consumableSpecialityId : null}
									consumableFamilyId={this.state.family ? this.state.family.id : this.state.consumableFamilyId ? this.state.consumableFamilyId : null}
									costCenterId={this.state.costCenter ? this.state.costCenter.value : this.state.costCenterId ? this.state.costCenterId : this.state.costCenter} screenId={screenId.sterilizationList} getSortDetails={this.getSortDetails.bind(this)} totalCount={this.totalCount.bind(this)} />} />
						</Switch>
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate() {
		const filterValues = { search: utils.checkForParameter("search", this.props.location) ? utils.checkForParameter("search", this.props.location) : "" };
		if ((filterValues.search && this.state.search !== filterValues.search)) {
			this.setState({ ...filterValues });
		}


	}
}

Sterilization.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(Sterilization)); 