import React, { Component } from "react";
import Select from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { CaretLeftOutlined, CaretRightOutlined } from "@ant-design/icons";
class ProblemListDropDown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: [],
			offset: 0,
			numResults: 1000,
			isLoading: true,
			search: null,
			problemTypeList: [],
			stepSectionType: "ALL_ITEMS",
			quickReorderChanges: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.renderOptions();
	}

	componentDidUpdate() {
		if (this.state.quickReorderChanges !== this.props.quickReorderChanges) {
			this.renderOptions();
			this.setState({ quickReorderChanges: this.props.quickReorderChanges });
		}
	}
	onListScroll() {
		this.state.offset += this.state.numResults;
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.props.list.map((data) => {
			prevState.options.push({ value: data.problemId, label: data.problem ? data.problem : "", additionalInfoList: data.additionalInfo, modelTreatmentId: data.modelTreatmentId, data: data });
		});
		!this.props.data ? utils.setSingleDataDefault(prevState.options, this.props.onChange, false, false, true) : "";
		prevState.isLoading = false;
		this.setState(prevState);
	}

	onNaveOption(index, nav) {
		const legthOfOption = this.state.options.length;
		const upDownLength = nav === "right" ? index + 1 : index - 1;
		
		if (upDownLength >= 0 && upDownLength < legthOfOption )
		{
			this.props.onChange( this.state.options[upDownLength]);

		} 
	}


	render() {
		let dataIndex  = 0;
		const dataValue = this.props.data;
		if (this.props.data && this.props.data.value && this.state.options) {
			//this.state.options.forEach((item, i) => { if (item.value === this.props.data.value) { dataIndex = i;} });
			dataIndex  = this.state.options.findIndex( (item) =>  item.value === dataValue.value );
		}

		const customStyles = {
			option: (defaultStyles, state) => { 
				return ({
			  ...defaultStyles,
			  color: state.data.modelTreatmentId ? "#0477BD" : ""
				});}, singleValue: (defaultStyles, state) => ({ ...defaultStyles, color: state.data.modelTreatmentId ? "#0477BD" : "" })
		  };
		  
		return (
			<ul className="drop-list-nav">
				<li><CaretLeftOutlined onClick={() => { this.onNaveOption(dataIndex, "left");}} className={dataIndex === 0 ? "disabled" : ""} /></li>
				<li >
					<Select
						className={`w_100 di select_prbl_section ${this.props.className ? this.props.className : ""}`}
						// value={this.props.data ? this.props.data : null}
						value={this.props.data && this.state.options.find((item) => item.value === dataValue.value )}
						onChange={this.props.onChange}
						menuShouldScrollIntoView
						options={this.state.options}
						maxMenuHeight={220}
						isClearable
						onInputChange={this.onSearch.bind(this)}
						noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
						loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
						placeholder={i18Get("Select Problems", utils.getLanguage())}
						styles={customStyles}
					/>
				</li>
				<li><CaretRightOutlined onClick={() => { this.onNaveOption(dataIndex, "right");}} className={(dataIndex + 1) === this.state.options.length ? "disabled" : ""} /></li>
			</ul>
		);
	}
}

export default ProblemListDropDown;
