import React, { useEffect } from "react";
import { Modal } from "antd";
import I18 from "../../../../../../i18";
import PropTypes from "prop-types";
import { withRouter } from "react-router";

import locations from "../../../../../../router/locations";
function NotDeleteInfo(props) {
	useEffect(() => {}, []);
	const propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};
	const getBody = () => {
		const attachedData = props.data;

		const getSections = Object.keys(attachedData);
		let initialHeader = "";
		const row = [];

		if (getSections.length > 0) {
			getSections.map(e => { 

				if (e !== initialHeader) {
					initialHeader = e;
					row.push(<tr><th className="w_100">{e.replace("_", " ")}</th></tr>);
				}
				if (attachedData[e].length > 0) {
					attachedData[e].map(value => {
						if (e === "COMMUNITY_SHARE") {
							row.push(<tr key={value.communityname}><td >{value.communityname}</td></tr>);
						} else {
							const name = `${value.siteId && value.hospitalId ? value.siteShortName : value.hospitalName ? value.hospitalName : "MOM"} ${value.treatmentname}`;
							row.push(<tr key={value.treatmentname}><td ><a onClick={() => { moveToDetails(e, value.treatmentId); } }>{name}</a></td></tr>);
						}
					});
				}
			});
		}
		return <tbody>{row}</tbody>;
	};

	const moveToDetails = (section, id) => {

		if (section === "HEALTH_PATHWAY") {
			//props.history.push(locations.health_pathway_plain + "/local" + "/" + id);
			window.open("#" + locations.health_pathway_plain + "/mom" + "/" + id, "_blank");
		} else {
			//props.history.push(locations.clinical_pathway_plain + "/local" + "/" + id);
			window.open("#" + locations.clinical_pathway_plain + "/mom" + "/" + id, "_blank");
		}

	};
	return (
		<Modal
			open={true}
			className="cost_modal re_assign_modal overflow-x-hidden-model"
			title={<I18 tkey={"Clinical Pathway Linked Section Details"} />}
			onCancel={props.onClose}
			wrapClassName="drag_modal"
			footer={null}>
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				{/* <thead><tr><th><I18 tkey="NAME" /></th></tr></thead> */}
				{getBody()}
			</table>
		</Modal> 
	);

}

export default NotDeleteInfo;