import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import { Specialities } from "../../../../uiComponents";

class Section extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="bloc_create_section padding-0">
				<div className="bloc_create_box">
					<div className="row">
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<label className="code_label"><I18 tkey="* Code must be 2-digit uppercase characters" /></label>
								<NameInput
									value={this.props.code}
									inputChanged={this.props.codeChanged}
									maxLength={2}
								/>
								{this.props.invalidCode && <span className="invalid_right fixed_label"><I18 tkey="Enter valid code" /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Community Name" /></label>
								<NameInput
									value={this.props.name}
									inputChanged={this.props.nameChanged}
									maxLength={60}
								/>
								{this.props.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Specialities" /></label>
								<Specialities
									value={this.props.specialities}
									onChange={this.props.onChangeSpecialities}
									filter={{ typeActivity: "CLINICAL_PATHWAY" }}
									defaultData={ ["CHECK-LIST", "MODELE" ] }
								/>
								{this.props.invalidSpecialities && <span className="invalid_right fixed_label"><I18 tkey="Field should not be empty " /></span>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Section.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Section));
