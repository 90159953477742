import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Input } from "antd";

const { Search } = Input;

class SearchField extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	onSearch(value) {
		const e = { target: { value } };
		this.props.searchChanged(e);
	}

	onSearchClear(value) {
		if (value == "") {
			const e = { target: { value } };
			this.props.searchChanged(e);
		}
	}

	render() {
		return (
			<Search
				className={this.props.className}
				placeholder={this.props.placeholder}
				allowClear
				onSearch={this.onSearch.bind(this)}
				maxLength={this.props.maxLength}
				onChange={this.props.onSearchClear}
				defaultValue={this.props.value}
			/>

		);
	}
}

SearchField.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(SearchField));
