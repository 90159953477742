import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const generateClpBackup = () => ({
	type: events.GENERATE_CLP_BACKUP,
	payload: axios.post(`${Config.apiRootSecure}/clinicalpathway/backup`)
});

const generateClpBackupCompleted = () => ({
	type: events.GENERATE_CLP_BACKUP_COMPLETED,
	payload: {}
});


export default {
	generateClpBackup,
	generateClpBackupCompleted
};
