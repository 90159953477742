import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../router/locations";
import "./side-bar.css";
import Administration from "./administration";
import Elements from "./elements";
import Benchmark from "./benchmark";
import Anaesthesia from "./anaesthesia";
import SheetsSideBar from "./sheets-sidebar";
import ExtClinicalPathway from "./ext-clinical-pathway";
import ExtHealthPathway from "./ext-health-pathway";

class SideBar extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	UNSAFE_componentWillMount() {
		this.unlisten = this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
                            || location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	componentWillUnmount() {
		if (this.unlisten) {
			this.unlisten();
		}
	}

	render() {
		return (
			<div className={this.props.actions.sideBarHideShow ? "hidden-xs hidden-sm side_bar_section hide_close" : "side_bar_section animated slideInLeft"}>
				<Switch>
					<Route path={locations.administration} component={Administration} />
					<Route path={locations.elements} component={Elements} />
					<Route path={locations.extElements} component={Elements} />
					<Route path={locations.sub_clinical_pathways} component={ExtClinicalPathway} />
					<Route path={locations.sub_health_pathways} component={ExtHealthPathway} />
					<Route path={locations.anaesthesia_edit + locations.staffEdit} component={Anaesthesia} />
					<Route path={locations.anaesthesia_edit + locations.staff} component={Anaesthesia} />
					<Route path={locations.anaesthesia_edit + locations.medicaldevice} component={Anaesthesia} />
					<Route path={locations.anaesthesia_edit + locations.pharmacy} component={Anaesthesia} />
					<Route path={locations.health_pathway} component={SheetsSideBar} />
					<Route path={locations.clinical_pathway} component={SheetsSideBar} />
					<Route path={locations.anaesthesia} component={SheetsSideBar} />
					<Route path={locations.interventions} component={SheetsSideBar} />
					<Route path={locations.benchmark} component={Benchmark} />
				</Switch>
			</div>
		);
	}
}

SideBar.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations
}))(SideBar));
