import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import familyActions from "../../../../../../../../actions/family";
import FamilySelect from "../selectFamily";
import familyModelActions from "../../../../../../../../actions/family-model";
class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			familyName: "",
			familyCode: "",
			invalidName: false,
			invalidCode: false,
			invalidFamily: false,
			selectedFamily: "",
			familyDetails: {},
			familyId: "",
			specialityApproved: false,
			isloading: false,
			familyFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.setState({ ...this.state });
		this.props.dispatch(familyActions.fetchOneFamily(this.props.familyId));
	}

	radioButtonChange = e => {
		this.setState({
			...this.state,
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ ...this.state, modelVisible: false, familyFound: false, value: 1, invalidName: false, invalidCode: false, invalidFamily: false });
		this.props.refreshCompleted();
	}

	onFamilyChange(e) {
		this.setState({ ...this.state, selectedFamily: e ? e : "", invalidFamily: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.familyName) || !(this.state.familyName.trim())) {
			prevState.invalidName = true;
		}
		if (!utils.regexFamilyCode.test(this.state.familyCode)) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateFamily = () => {
		if (this.state.selectedFamily && this.state.familyFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.familyName,
					code: this.state.familyCode
				};
				this.props.dispatch(familyModelActions.saveAndApproveFamily(this.state.familyDetails.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateFamily()) {
				payload = {
					familyId: this.state.selectedFamily && this.state.selectedFamily.value ? this.state.selectedFamily.value : ""
				};
				this.props.dispatch(familyModelActions.assignAndApproveFamily(this.state.familyDetails.id, payload));
			}
			else {
				this.setState({ invalidFamily: true });
			}

		}

	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.familyFound = true;
			this.state.value = 2;
			this.setState({ ...this.state });
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.familyName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidCode = false;
		prevState.familyCode = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.familyId !== this.state.familyId) {
			this.state.familyId = this.props.familyId;
			this.props.dispatch(familyActions.fetchOneFamily(this.props.familyId));
		}
		if (this.state.modelVisible !== this.props.visible) {
			this.state.modelVisible = this.props.visible;
			this.setState({ ...this.state });
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.familyModel.isAssignAndApproveFamilyCompleted === "FULFILLED") {
			this.state.loading = true;
			this.setState({ ...this.state, modelVisible: false, familyName: "", familyCode: "", familyFound: false, value: 1 });
			this.props.dispatch(familyModelActions.clearAssignAndApproveFamily());
			this.props.refreshCompleted();
		}
		if (this.props.familyModel.isSaveAndApproveFamilyCompleted === "FULFILLED") {
			this.state.loading = true;
			this.setState({ ...this.state, modelVisible: false, familyName: "", familyCode: "", familyFound: false, value: 1 });
			this.props.dispatch(familyModelActions.clearSaveAndApproveFamily());
			this.props.refreshCompleted();
		}
		if (this.props.family.isFetchOneFamilyCompleted === "FULFILLED") {
			this.state.familyDetails = this.props.family.selectedFamily && this.props.family.selectedFamily.length ? this.props.family.selectedFamily[0] : "";
			this.state.familyName = this.state.familyDetails.name ? this.state.familyDetails.name : "";
			this.state.familyCode = this.state.familyDetails.code ? this.state.familyDetails.code : "";
			this.state.isloading = true;
			this.state.selectedFamily = { label: this.state.familyDetails.name ? this.state.familyDetails.name : "", value: this.state.familyDetails.id };
			this.state.specialityApproved = this.state.familyDetails.specialityApproved ? this.state.familyDetails.specialityApproved : false;
			this.setState({ ...this.state });
			this.props.dispatch(familyActions.clearFetchOneFamily());
		}
		if (this.props.family.isFetchOneFamilyCompleted === "REJECTED") {
			this.props.dispatch(familyActions.clearFetchOneFamily());
		}
		return (
			<div>
				{this.state.modelVisible &&
					<Modal
						className="re_assign_modal"
						title={i18Get("Edit & Approve - Family", utils.getLanguage())}
						visible={this.state.modelVisible}
						onCancel={this.cancelClicked.bind(this)}
						footer={[
							<Button key="back" onClick={this.cancelClicked.bind(this)}>
								<I18 tkey='Cancel' />
							</Button>,
							this.state.specialityApproved ?
								<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
									<I18 tkey='Save & Approve' />
								</Button> : ""
						]}
					>
						<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
							<div className="re_assign_modal_head">
								<div className="row position_relative">
									<div className="col-md-3">
										<div className="form-group margin-bottom-0 pl-15">
											<label className="bold width-100"><I18 tkey="CODE" />:</label>
											<label className="width-100">{this.state.familyDetails.code}</label>
										</div>
									</div>
									<div className="col-md-9">
										<label className="bold width-100"><I18 tkey="NAME" />:</label>
										<label className="width-100">{this.state.familyDetails.name}</label>
									</div>
									<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.familyDetails.hospitalName ? this.state.familyDetails.hospitalName : ""}</p>
								</div>
								<p className="padding-left-15 padding-right-15"><span className="bold"><I18 tkey="SPECIALITY NAME" />:</span> {this.state.familyDetails.specialityName ? this.state.familyDetails.specialityName : ""}</p>
							</div>
							{this.state.specialityApproved && this.state.isloading ?
								<div>
									<div className="row">
										<div className="col-md-12 pt-10">
											<Radio value={1}>
												<I18 tkey="Use new code or name" />
											</Radio>
										</div>
										<div className="col-md-4 pt-10 pr-0">
											<div className="form-group pl-20">
												<label className="bold" ><I18 tkey="CODE" />:</label>
												<NameInput
													value={this.state.familyCode}
													inputChanged={this.codeChanged.bind(this)}
													disabled={this.state.value != 1}
													maxLength={10}
												/>
												{this.state.invalidCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Code must be 2-digit uppercase characters" /></span>}
											</div>
										</div>
										<div className="col-md-8 pt-10">
											<div className="form-group ">
												<label className="bold" ><I18 tkey="NAME" />:</label>
												<NameInput
													value={this.state.familyName}
													inputChanged={this.nameChanged.bind(this)}
													maxLength={240}
													disabled={this.state.value != 1}
												/>
												{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid family name" /></span>}
											</div>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 pt-10">
											<Radio value={2}> <I18 tkey="Assign To Family" /></Radio>
										</div>
										<div className="col-sm-12 pt-10">
											<div className="form-group pl-20">
												<FamilySelect defaultValue="Select Family"
													disabled={this.state.value != 2}
													style={{ width: "100%" }}
													data={this.state.selectedFamily}
													typeActivity={this.props.typeActivity}
													statusCheck={this.statusCheck.bind(this)}
													onFamilyChanged={this.onFamilyChange.bind(this)} />
												{this.state.invalidFamily && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select family" /></span>}
											</div>
										</div>
									</div>
								</div>
								:
								<div className="invalid_label padding-top-7">
									{this.state.isloading ?
										<I18 tkey="The speciality for this family is waiting for approval. Please approve the speciality first" />
										: ""}
								</div>
							}
						</Radio.Group>
					</Modal>
				}
			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	family: PropTypes.object.isRequired,
	familyModel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	family: store.family,
	familyModel: store.familyModel
}))(AssignAndApproveModel));    