import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllSterilizationCompleted: false,
	sterilizationList: [],
	isFetchOneSterilizationCompleted: false,
	selectedSterilization: {},
	isUpdateSterilizationCompleted: false,
	updatedSterilization: {},
	isCreateSterilizationCompleted: false,
	createdSterilization: {},
	isDeleteSterilizationCompleted: false,
	isDeleteSuccess: {},
	family: [],
	fetchFamilyForSterilizationComplete: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_STERILIZATION}_PENDING`:
			return {
				...state,
				isFetchAllSterilizationCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllSterilizationCompleted: "FULFILLED",
					sterilizationList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllSterilizationCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_STERILIZATION}_REJECTED`:
			return {
				...state,
				isFetchAllSterilizationCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_STERILIZATION:
			return {
				...state,
				isFetchAllSterilizationCompleted: false
			};

		case `${eventNames.FETCH_ONE_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneSterilizationCompleted: "FULFILLED",
					selectedSterilization: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneSterilizationCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_STERILIZATION}_REJECTED`:
			return {
				...state,
				isFetchOneSterilizationCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_STERILIZATION:
			return {
				...state,
				isFetchOneSterilizationCompleted: false
			};
		case `${eventNames.UPDATE_STERILIZATION}_PENDING`:
			return {
				...state,
				isUpdateSterilizationCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateSterilizationCompleted: "FULFILLED",
					updatedSterilization: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateSterilizationCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_STERILIZATION}_REJECTED`:
			return {
				...state,
				isUpdateSterilizationCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_STERILIZATION:
			return {
				...state,
				isUpdateSterilizationCompleted: false
			};
		case `${eventNames.CREATE_STERILIZATION}_PENDING`:
			return {
				...state,
				isCreateSterilizationCompleted: "PENDING"
			};
		case `${eventNames.CREATE_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateSterilizationCompleted: "FULFILLED",
					createdSterilization: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateSterilizationCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_STERILIZATION}_REJECTED`:
			return {
				...state,
				isCreateSterilizationCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_STERILIZATION:
			return {
				...state,
				isCreateSterilizationCompleted: false
			};
		case `${eventNames.DELETE_STERILIZATION}_PENDING`:
			return {
				...state,
				isDeleteSterilizationCompleted: "PENDING"
			};
		case `${eventNames.DELETE_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteSterilizationCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteSterilizationCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_STERILIZATION}_REJECTED`:
			return {
				...state,
				isDeleteSterilizationCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_STERILIZATION:
			return {
				...state,
				isDeleteSterilizationCompleted: false
			};
		case `${eventNames.FETCH_FAMILY_FOR_STERILIZATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchFamilyForSterilizationComplete: "FULFILLED",
					family: action.payload.data.data
				};
			}
			return {
				...state,
				fetchFamilyForSterilizationComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_FAMILY_FOR_STERILIZATION}_REJECTED`:
			return {
				...state,
				fetchFamilyForSterilizationComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_FAMILY_FOR_STERILIZATION:
			return {
				...state,
				fetchFamilyForSterilizationComplete: false
			};
		case `${eventNames.FETCH_ALL_CLASS_STERILIZATION}_FULFILLED`:
			return {
				...state,
				fetchAllClassSterilization: action.payload.data.data,
				fetchAllClassSterilizationStatus: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CLASS_STERILIZATION}_REJECTED`:
			return {
				...state, fetchAllClassSterilizationStatus: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CLASS_STERILIZATION:
			return {
				...state, fetchAllClassSterilizationStatus: false
			};

		case `${eventNames.CREATE_CLASS_STERILIZATION}_FULFILLED`:
			return { ...state, createdSterilizationClassStatus: "FULFILLED" };
		case `${eventNames.CREATE_CLASS_STERILIZATION}_REJECTED`:
			return { ...state, createdSterilizationClassStatus: "REJECTED" };
		case eventNames.CLEAR_CREATE_CLASS_STERILIZATION:
			return { ...state, createdSterilizationClassStatus: false };

		case `${eventNames.FETCH_ONE_CLASS_STERILIZATION}_FULFILLED`:
			return { ...state, getSelectedSterilizationClassStatus: "FULFILLED", selectedSterilizationClass: action.payload.data.data };
		case `${eventNames.FETCH_ONE_CLASS_STERILIZATION}_REJECTED`:
			return { ...state, getSelectedSterilizationClassStatus: "REJECTED" };
		case eventNames.CLEAR_FETCH_ONE_CLASS_STERILIZATION:
			return { ...state, getSelectedSterilizationClassStatus: false };

		case `${eventNames.UPDATE_CLASS_STERILIZATION}_FULFILLED`:
			return { ...state, updateSterilizationClassStatus: "FULFILLED" };
		case `${eventNames.UPDATE_CLASS_STERILIZATION}_REJECTED`:
			return { ...state, updateSterilizationClassStatus: "REJECTED" };
		case eventNames.CLEAR_UPDATE_CLASS_STERILIZATION:
			return { ...state, updateSterilizationClassStatus: false };

		case `${eventNames.DELETE_CLASS_STERILIZATION}_FULFILLED`:
			return { ...state, deleteSterilizationClassStatus: "FULFILLED" };
		case `${eventNames.DELETE_CLASS_STERILIZATION}_REJECTED`:
			return { ...state, deleteSterilizationClassStatus: "REJECTED" };
		case eventNames.CLEAR_DELETE_CLASS_STERILIZATION:
			return { ...state, deleteSterilizationClassStatus: false };

		case `${eventNames.GET_CLASS_LIST}_FULFILLED`:
			return { ...state, getClassListStatus: "FULFILLED", classList: action.payload.data.data };
		case `${eventNames.GET_CLASS_LIST}_REJECTED`:
			return { ...state, getClassListStatus: "REJECTED" };
		case eventNames.CLEAR_CLASS_LIST:
			return { ...state, getClassListStatus: false };

		case `${eventNames.GET_CONDITIONMENT_LIST}_FULFILLED`:
			return { ...state, getConditionmentListStatus: "FULFILLED", conditionmentList: action.payload.data.data };
		case `${eventNames.GET_CONDITIONMENT_LIST}_REJECTED`:
			return { ...state, getConditionmentListStatus: "REJECTED" };
		case eventNames.CLEAR_CONDITIONMENT_LIST:
			return { ...state, getConditionmentListStatus: false };

		case `${eventNames.COPY_PAST_STERILIZATION}_FULFILLED`:
			return { ...state, copyPastSterilizationStatus: "FULFILLED" };
		case `${eventNames.COPY_PAST_STERILIZATION}_REJECTED`:
			return { ...state, copyPastSterilizationStatus: "REJECTED" };
		case eventNames.COPY_PAST_STERILIZATION_COMPLETED:
			return { ...state, copyPastSterilizationStatus: false };

		case `${eventNames.ASSIGN_STERILIZATION_CLASS_LIST}_FULFILLED`:
			return {
				...state,
				assignSterilizationListStatus: "FULFILLED",
				assignSterilizationList: action.payload.data.data
			};
		case `${eventNames.ASSIGN_STERILIZATION_CLASS_LIST}_REJECTED`:
			return { ...state, assignSterilizationListStatus: "REJECTED" };
		case eventNames.ASSIGN_STERILIZATION_CLASS_LIST_COMLETED:
			return { ...state, assignSterilizationListStatus: false };

		case `${eventNames.ASSIGN_STERILIZATION_CLASS}_FULFILLED`:
			return { ...state, assignSterilizationStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_STERILIZATION_CLASS}_REJECTED`:
			return { ...state, assignSterilizationStatus: "REJECTED" };
		case eventNames.ASSIGN_STERILIZATION_CLASS_COMPLETED:
			return { ...state, assignSterilizationStatus: false };

		case `${eventNames.GET_CONSUMABLE_SPECIALITY}_FULFILLED`:
			return { ...state, getConsomableSpecialityStatus: "FULFILLED", consomableSpeciality: action.payload.data.data };
		case `${eventNames.GET_CONSUMABLE_SPECIALITY}_REJECTED`:
			return { ...state, getConsomableSpecialityStatus: "REJECTED" };
		case eventNames.CLEAR_CONSUMABLE_SPECIALITY:
			return { ...state, getConsomableSpecialityStatus: false };

		case `${eventNames.CREATE_CONSUMABLE_SPECIALITY}_FULFILLED`:
			return { ...state, createConsomableSpecialityStatus: "FULFILLED" };
		case `${eventNames.CREATE_CONSUMABLE_SPECIALITY}_REJECTED`:
			return { ...state, createConsomableSpecialityStatus: "REJECTED" };
		case eventNames.CREATE_CONSUMABLE_SPECIALITY_COMPLETED:
			return { ...state, createConsomableSpecialityStatus: false };

		case `${eventNames.GET_ONE_CONSOMABLE_SPECIALITY}_FULFILLED`:
			return { ...state, getConsomableSpecialityOneStatus: "FULFILLED", selectedConsomableSpeciality: action.payload.data.data };
		case `${eventNames.GET_ONE_CONSOMABLE_SPECIALITY}_REJECTED`:
			return { ...state, getConsomableSpecialityOneStatus: "REJECTED" };
		case eventNames.GET_ONE_CONSOMABLE_SPECIALITY_COMPLETED:
			return { ...state, getConsomableSpecialityOneStatus: false };

		case `${eventNames.FETCH_ONE_CONSOMABLE_SPECIALITY}_FULFILLED`:
			return { ...state, getOneConsomableSpeciality: "FULFILLED", consomableSpeciality: action.payload.data.data };
		case `${eventNames.FETCH_ONE_CONSOMABLE_SPECIALITY}_REJECTED`:
			return { ...state, getOneConsomableSpeciality: "REJECTED" };
		case eventNames.FETCH_ONE_CONSOMABLE_SPECIALITY_COMPLETED:
			return { ...state, getOneConsomableSpeciality: false };

		case `${eventNames.UPDATE_CONSOMABLE_SPECIALTY}_FULFILLED`:
			return { ...state, updateConsomableSpecialtyStatus: "FULFILLED" };
		case `${eventNames.UPDATE_CONSOMABLE_SPECIALTY}_REJECTED`:
			return { ...state, updateConsomableSpecialtyStatus: "REJECTED" };
		case eventNames.CLEAR_UPDATE_CONSOMABLE_SPECIALTY:
			return { ...state, updateConsomableSpecialtyStatus: false };

		case `${eventNames.DELETE_CONSOMABLE_SPECIALTY}_FULFILLED`:
			return { ...state, isDeleteSpecialtyCompleted: "FULFILLED" };
		case `${eventNames.DELETE_CONSOMABLE_SPECIALTY}_REJECTED`:
			return { ...state, isDeleteSpecialtyCompleted: "REJECTED" };
		case eventNames.DELETE_CONSOMABLE_SPECIALTY_COMPLETED:
			return { ...state, isDeleteSpecialtyCompleted: false };
		case `${eventNames.GET_ALL_STERILIZATION_SPECIALITY}_FULFILLED`:
			return { ...state, getAllSterilizationSpecialityStatus: "FULFILLED", getAllSterilizationSpeciality: action.payload.data.data };
		case `${eventNames.GET_ALL_STERILIZATION_SPECIALITY}_REJECTED`:
			return { ...state, getAllSterilizationSpecialityStatus: "REJECTED" };
		case eventNames.GET_ALL_STERILIZATION_SPECIALITY_COMPLETED:
			return { ...state, getAllSterilizationSpecialityStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_STERILIZATION_SPECIALITY}_FULFILLED`:
			return { ...state, saveAndApproveSterilizationSpecialityStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_STERILIZATION_SPECIALITY}_REJECTED`:
			return { ...state, saveAndApproveSterilizationSpecialityStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED:
			return { ...state, saveAndApproveSterilizationSpecialityStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY}_FULFILLED`:
			return { ...state, asssignAndApproveSterilizationSpecialityStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY}_REJECTED`:
			return { ...state, asssignAndApproveSterilizationSpecialityStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED:
			return { ...state, asssignAndApproveSterilizationSpecialityStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_STERILIZATION_CLASS}_FULFILLED`:
			return { ...state, saveAndApproveSterilizationClassStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_STERILIZATION_CLASS}_REJECTED`:
			return { ...state, saveAndApproveSterilizationClassStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_STERILIZATION_CLASS_COMPLETED:
			return { ...state, saveAndApproveSterilizationClassStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_STERILIZATION_CLASS}_FULFILLED`:
			return { ...state, asssignAndApproveSterilizationClassStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_STERILIZATION_CLASS}_REJECTED`:
			return { ...state, asssignAndApproveSterilizationClassStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_STERILIZATION_CLASS_COMPLETED:
			return { ...state, asssignAndApproveSterilizationClassStatus: false };

		case `${eventNames.GET_ONE_CLASS_STERILIZATION}_FULFILLED`:
			return { ...state, getSterilizationClassStatus: "FULFILLED", sterilizationClass: action.payload.data.data };
		case `${eventNames.GET_ONE_CLASS_STERILIZATION}_REJECTED`:
			return { ...state, getSterilizationClassStatus: "REJECTED" };
		case eventNames.CLEAR_GET_ONE_CLASS_STERILIZATION:
			return { ...state, getSterilizationClassStatus: false };

		default:
			return state;
	}
}
