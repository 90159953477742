import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import locations from "router/locations";
import actions from "actions/sync-fusion";
import typeActivity from "constants/typeActivity.json";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import utilsFunction from "../plugin/utils";
import BreadCrumb from "components/main-container/overview/main-overview/breadcrumb";
import "./diagram.css";
import SidebarSettings from "./SidebarSettings";
import { NodeConstraints, DiagramConstraints, PortVisibility, PortConstraints, AnnotationConstraints } from "@syncfusion/ej2-react-diagrams";
import "./styles.css";
import Diagram from "./diagram";
import eventNames from "actions/events";
import AutoSave from "./autoSave";
import diagramActions from "actions/sync-fusion";
import { Button, Modal } from "antd";
import healthPathwayAction from "actions/health-pathway";
import { v4 as uuid } from "uuid";
import activities from "mom-ui-commons/build/components/diagram/activities";
import action from "./redux/action";
const INTERVENTION_URL = "#/intervention/mom/timeSheets";
const CLINICAL_PATHWAY_URL = "#";
const NODE_ALIGNMENT = { left: "Left", center: "Center", right: "Right" };
const NODE_ALIGNMENT_REVERSE = { Left: "left", Center: "center", Right: "right" };
const NODE_ALIGNMENT_X_VALUE = { left: .01, center: .5, right: 1 };
let diagramInstance;

let isMobile;
function addEvents() {
	isMobile = window.matchMedia("(max-width:550px)").matches;
	if (isMobile) {
		const paletteIcon = document.getElementById("palette-icon");
		if (paletteIcon) {
			// paletteIcon.addEventListener('click', openPalette, false);
		}
	}
}

const notificationIconPort = {
	id: "NOTIFICATION_ICON",
	// Sets the position for the port
	offset: {
		x: 0.1,
		y: 0.2
	},
	style: {
		fill: "red",
		strokeWidth: 2,
		strokeColor: "white"
	},
	width: 12,
	height: 12,
	shape: "Circle",
	visibility: PortVisibility.Visible,
	constraints: PortConstraints.None
};

let diagramWidth = "100%";
let initialCRCValue = { node: "", connector: "" };

class Diagramm extends Component {

	constructor(props) {
		super(props);
		this.colorPickerRef = React.createRef();
		this.defaultColorPickerState = {
			showShapeFillColorPicker: false,
			showShapeBorderColorPicker: false,
			showShapeTextColorPicker: false
		};
		this.defaultSelectedShapeState = {
			fill: "",
			color: "",
			strokeColor: "",
			strokeWidth: "1",
			fontSize: 12,
			fontFamily: "",
			bold: false,
			italic: false,
			underline: false,
			isColorChanged: false,
			linkType: "",
			linkTo: "",
			selectedIntervention: "",
			selectedClp: "",
			selectedHealthPathway: null,
			textAlign: "left",
			customNodeData: {},
			annotationFill: "transparent",
			addInfo: {}
		};
		this.defaultSelectedAnnotationState = {
			title: "",
			duration: "",
			durationInMinutes: "",
			durationMinimumType: "Minutes",
			durationMinimum: "",
			durationMaximum: "",
			durationMaximumType: "Minutes",
			durationMinimumInMinutes: "",
			durationMaximumInMinutes: "",
			durationType: "Minutes",
			HorizontalAlignment: "",
			VerticalAlignment: "",
			fill: "#ffffff",
			color: "#000000",
			strokeColor: "",
			strokeWidth: "",
			fontSize: 12,
			fontFamily: "",
			bold: false,
			italic: false,
			underline: false,
			linkType: "",
			linkTo: "",
			textAlign: "center"
		};

		this.customNodeDataTemplate = {
			id: Math.floor(Math.random() * 1000),
			intervention: "",
			interventionDetails: "",
			intervenants: [],
			intervenantsIds: [],
			intervenantsCode: [],
			location: "",
			durationOfTheTask: "",
			durationMinimum: "", 
			durationMinimumInMinutes: "",
			durationMaximumInMinutes: "",
			durationMinimumType: "Minutes",
			durationMaximum: "", 
			durationMaximumType: "Minutes",
			duration: "",
			durationInMinutes: "",
			unitCost: "",
			durationType: "Minutes",
			expectedResults: "",
			argumentation: "",
			risks: "",
			reference: "",
			linkTo: "",
			linkType: "",
			phase: "",
			type: null,
			typeId: null,
			typeCode: null,
			problems: "",
			data: "",
			code: "",
			steps: null,
			stepsId: "",
			stepsCode: "",
			subSteps: null,
			subStepsId: "",
			subStepsCode: "",
			linkedItem: null,
			linkedItemId: null,
			linkedItemCode: null
		};
		this.state = {
			data: "",
			showNodeInfo: false,
			showNodeDetails: false,
			currentNode: {},
			currentItem: {},
			nodeName: "",
			nodeNameHyperLinkRelation: {},
			nodeHyperlink: "",
			nodes: [],
			connectors: [],
			flowshapes: [],
			settings: {},
			showSettingsModal: false,
			contextItemId: "",
			contextItemName: "",
			nodeColour: "",
			showContextModal: false,
			selectedShape: { ...this.defaultSelectedShapeState },
			currentAnnotationDetails: { ...this.defaultSelectedAnnotationState },
			nodeDescription: "",
			files: [],
			colorPicker: { ...this.defaultColorPickerState },
			diagramHeight: "",
			symbolPaletteHeight: "",
			unmountFromPage: {
				goBack: true,
				path: null
			},
			networkIssue: false,
			sideBarSettings: null,
			customNodeData: JSON.parse(JSON.stringify(this.customNodeDataTemplate)),
			miansideBarHideShow: this.props.actions.sideBarHideShow,
			sideBarStatus: false,
			currentNodeId: null,
			reorderData: false,
			isThereAnyChanges: false,
			isSaving: false,
			diagramInfo: null,
			isInEditPage: false,
			isSaveBlocked: false,
			isSameUser: false
		};
		this.inputReference = React.createRef();
		this.nodeTitleInputRef = React.createRef();
		this.nodeDescriptionInputRef = React.createRef();
		this.diagramContainer = React.createRef();
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(actions.getDiagramData(this.props.match.params.id));
	}


	componentDidMount() {
		const sessionUuid = sessionStorage.getItem("MOM-HealthPathway-Edit-uuid");
		const newUuid = sessionUuid ? sessionUuid : uuid() ;
		sessionStorage.setItem("MOM-HealthPathway-Edit-uuid", newUuid);
		const type = "HEALTH_PATHWAY";
		this.props.dispatch(diagramActions.checkDiagramLockValidation(type, (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": newUuid }));
		addEvents();
		const height = document.getElementsByClassName("main_overview_section")[0].clientHeight - document.getElementsByClassName("pole_breadcrumb")[0].offsetHeight;
		const zoomSliderHeight = document.getElementsByClassName("diagram_zoom_slider_container")[0].offsetHeight;
		this.state.diagramHeight = `${height - 10}px`;
		this.state.symbolPaletteHeight = `${height - 60 - zoomSliderHeight}px`;
		diagramInstance.fitToPage();
		const zoomFactor = utils.getFilterInLocalStorage("DIAGRAM_SETTINGS");
		if (zoomFactor) {
			if (zoomFactor.zoom) { diagramInstance.zoom(zoomFactor.zoom); }
		}
		diagramInstance.constraints = DiagramConstraints.Default & ~DiagramConstraints.Zoom;
		//diagramInstance.doubleClick = this.nodeDoubleClicked;
		diagramInstance.click = this.onClicked;
		diagramInstance.sizeChange = this.dragSize;
		diagramInstance.historyChange = this.historyChange;
		diagramInstance.collectionChange = this.diagramChangeObserver;
		document.addEventListener("mousedown", this.handleClickOutside);
		document.addEventListener("contextmenu", this.stopPropagation, false);
		window.addEventListener("resize", this.updateDiagramHeight);
		this.diagramContainer.current.addEventListener("wheel", this.onDiagramScroll);
		this.props.history.block(history => {
			if (!this.state.unmountFromPage.goBack || !this.state.isThereAnyChanges) {
				return true;
			}

			const conf = window.confirm("Save diagram before leaving?");
			if (conf) {
				this.setState({
					unmountFromPage: {
						goBack: true,
						path: history.pathname
					}
				});
				this.saveDiagram("goBack");
				return false;
			}
			return true;
		});

		setInterval(() => { this.updateSelectionHandler(diagramInstance); }, 10);
	}

	componentWillUnmount() {
		diagramInstance.destroy();
		clearInterval(this.timer);
		document.removeEventListener("mousedown", this.handleClickOutside);
		document.removeEventListener("contextmenu", this.stopPropagation, false);
		window.removeEventListener("resize", this.updateDiagramHeight);
		this.diagramContainer.current.removeEventListener("wheel", this.onDiagramScroll);
		if (this.state.isInEditPage) {
			this.props.dispatch(diagramActions.removeDiagramLock("HEALTH_PATHWAY", (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": sessionStorage.getItem("MOM-HealthPathway-Edit-uuid") }));
		}
		
		this.props.history.block(() => true);
		this.props.dispatch(action.getMissConnectores([]));

	}

	componentDidUpdate() {

		if (this.props.actions.lostRequest) {
			this.setState({ networkIssue: true });
			this.props.dispatch({ type: eventNames.HTTP_REQUEST_LOST_CLEAR });

		}
	}

	setDiagramInstance = instance => diagramInstance = instance;

	updateDiagramHeight = () => {
		const prevState = this.state;
		const height = document.getElementsByClassName("main_overview_section")[0].clientHeight - document.getElementsByClassName("pole_breadcrumb")[0].offsetHeight;
		const zoomSliderHeight = document.getElementsByClassName("diagram_zoom_slider_container")[0].offsetHeight;
		prevState.diagramHeight = `${height - 10}px`;
		this.state.symbolPaletteHeight = `${height - 60 - zoomSliderHeight}px`;
		this.setState(prevState);
	};

	updateDiagramWidth = () => {
		if (document.getElementById("diagram-space") && document.getElementById("sideBar-wrapper")) {
			const widthDiagram = document.getElementById("diagram-space").clientWidth;
			const widthSideBar = this.state.sideBarSettings ? document.getElementById("sideBar-wrapper").clientWidth : 0;
			diagramWidth = widthDiagram - widthSideBar;
		} else {
			diagramWidth = "100%";
		}
	};
	onZoomSliderChange(value) {
		diagramInstance.constraints = DiagramConstraints.Default;
		const zoomFactor = value / 50;
		diagramInstance.reset();
		diagramInstance.zoom(zoomFactor);
		diagramInstance.constraints = DiagramConstraints.Default & ~DiagramConstraints.Zoom;
		utils.setFilterInLocalStorage("DIAGRAM_SETTINGS", { "zoom": zoomFactor, "zoomSlider": value });
	}

	onDiagramScroll = e => {
		if (e.deltaX !== 0) {
			e.preventDefault();
			diagramInstance.scrollSettings.horizontalOffset -= e.deltaX;
		}
	};

	stopPropagation = (e) => {
		e.preventDefault();
		e.stopPropagation();
	};

	fileUploadAction = () => {
		this.inputReference.current.click();
	};


	backToDetails = () => {
		this.props.history.push(`${locations.health_pathway_plain}/${this.props.match.params.organization}/${this.props.match.params.id}`);
	};

	generateDiagramImage = () => {

		const fileName = `diagram_${new Date()}`;
		if (diagramInstance.nodes.length > 0 || diagramInstance.connectors.length > 0 ) {
			const exportOptions = {};
			exportOptions.format = "PNG";
			exportOptions.mode = "Data";
			exportOptions.region = "PageSettings";
			exportOptions.fileName = fileName;
			exportOptions.margin = { left: 10, top: 10, bottom: 10, right: 10 };
			const data =  diagramInstance.exportDiagram(exportOptions);
			return data;
		}
	};

	saveDiagram = (goBack) => {
		let data = diagramInstance.saveDiagram();
		const image = this.generateDiagramImage();

		data = JSON.parse(data);

		data.nodes = data.nodes.map(node => {

			node.annotations.map(annotation => {
				if (!annotation.hyperlink || !annotation.addInfo) {
					return;
				}
				if (annotation.addInfo.selectedShape && annotation.addInfo.selectedShape.linkTo) {
					annotation.hyperlink.link = annotation.addInfo.selectedShape.linkTo;
				}
			});

			return node;
		});

		data = JSON.stringify(data);

		clearInterval(this.timer);

		if (!goBack) {
			this.setState({ isSaving: true });
		}
		const uuid = sessionStorage.getItem("MOM-HealthPathway-Edit-uuid");
		this.props.dispatch(actions.saveDiagramData(this.props.match.params.id, { data, image, uuid }));
	};

	saveNode = () => {
		const currentNode = this.state.currentNode;
		const nodes = diagramInstance.nodes;

		for (const nodeVal of nodes) {
			if (nodeVal.id !== currentNode.id) {
				continue;
			}
			const annotation = nodeVal.annotations[0];
			const shapeStyle = this.state.selectedShape;
			annotation.content = this.getHyperLinkContentName();
			annotation.addInfo = { nodeName: this.state.nodeName, selectedShape: shapeStyle, customNodeData: this.state.customNodeData };
			nodeVal.style.fill = shapeStyle.fill;
			nodeVal.style.strokeColor = shapeStyle.strokeColor;
			annotation.style.color = shapeStyle.linkTo ? (shapeStyle.isColorChanged ? shapeStyle.color : "blue") : shapeStyle.isColorChanged ? shapeStyle.color : "black";
			annotation.style.bold = shapeStyle.bold;
			annotation.style.italic = shapeStyle.italic;
			annotation.style.textDecoration = shapeStyle.underline ? "Underline" : "None";
			annotation.style.fontSize = parseFloat(shapeStyle.fontSize);
			annotation.style.textWrapping = "WrapWithOverflow";
			annotation.style.textOverflow = "Wrap";
			// Alignment-------------------------------------------------------
			annotation.horizontalAlignment = NODE_ALIGNMENT[shapeStyle.textAlign];
			annotation.style.textAlign = NODE_ALIGNMENT[shapeStyle.textAlign];
			annotation.offset = { x: NODE_ALIGNMENT_X_VALUE[shapeStyle.textAlign], y: .5 };
			annotation.style.fill = shapeStyle.annotationFill ? shapeStyle.annotationFill : "transparent";
			// Hyperlink
			annotation.hyperlink = {
				// Disabled shape hyperLink on edit
				link: shapeStyle.linkTo,
				content: this.getHyperLinkContentName(),
				color: annotation.style.color,
				textDecoration: shapeStyle.underline ? "Underline" : "None"
			};
			
			if (this.props.user.loginData.roleType === "PATHWAY_USER" &&
				shapeStyle.linkType === "INTERVENTION"
			) {
				annotation.hyperlink.link = "";
			}

			//Custom details under node
			nodeVal.addInfo = { customNodeData: this.state.customNodeData };
			//-------------------------------------------------------------------
			nodeVal.constraints = NodeConstraints.Default;
			nodeVal.tooltip = { content: "" };
			this.removeNotificationIcon(nodeVal);
			//--------------------------------------------------
			diagramInstance.addLabels(nodeVal, annotation);
			diagramInstance.dataBind();
			utils.openMessage();
		}
	};

	addNotificationIcon = (nodeVal) => {
		if (!nodeVal.ports && !nodeVal.ports.length) {
			// nodeVal.ports = [notificationIconPort];
			diagramInstance.addPorts(nodeVal, notificationIconPort);
			return;
		}

		for (const port of nodeVal.ports) {
			if (port.id === "NOTIFICATION_ICON") {
				return;
			}
		}

		// nodeVal.ports.push(notificationIconPort);
		diagramInstance.addPorts(nodeVal, [...nodeVal.ports, notificationIconPort]);
	};

	removeNotificationIcon = (nodeVal) => {
		if (!nodeVal.ports && !nodeVal.ports.length) {
			return;
		}

		for (let index = 0; index < nodeVal.ports.length; index++) {
			if (nodeVal.ports[index].id === "NOTIFICATION_ICON") {
				// nodeVal.ports.splice(index, 1);
				diagramInstance.removePorts(nodeVal, [notificationIconPort]);
				return;
			}
		}
	};

	getHyperLinkContentName = () => {
		if (this.state.nodeName) {
			return this.state.nodeName;
		}
		if (!this.state.selectedShape) {
			return " ";
		}
		// this.state.selectedShape.linkType === 'INTERVENTION' && 
		if (this.state.selectedShape.selectedIntervention) {
			return this.state.selectedShape.selectedIntervention.label;
		}
		if (this.state.selectedShape.selectedClp) {
			return this.state.selectedShape.selectedClp.label;
		}
		if (this.state.selectedShape.linkTo) {
			return this.state.selectedShape.linkTo;
		}
		return " ";
	};

	hideShowNodeDetails = () => {
		const prevState = {
			...this.state,
			// showNodeDetails: false,
			sideBarSettings: false,
			nodeName: "",
			nodeNameHyperLinkRelation: {},
			colorPicker: { ...this.defaultColorPickerState },
			selectedShape: { ...this.defaultSelectedShapeState }
		};
		this.setState(prevState);
	};

	hideShowNodeSideBar = () => {
		this.setState({ sideBarStatus: !this.state.sideBarStatus });
	};

	saveSettings = () => {
		const settings = {};
		settings.shapeColour = this.state.settings.shapeColour ? this.state.settings.shapeColour : "red";
		settings.shapeBorderColour = this.state.settings.shapeBorderColour ? this.state.settings.shapeBorderColour : "BLUE";
		settings.shapeBorderRadius = this.state.settings.shapeBorderRadius && parseInt(this.state.settings.shapeBorderRadius) ? parseInt(this.state.settings.shapeBorderRadius) : 1;
		this.props.dispatch(actions.saveSettings(this.props.match.params.id, settings));
		this.setState({ settings, showSettingsModal: false, colorPicker: { ...this.defaultColorPickerState } });
	};

	onInputChange = (label, event) => {
		const prevState = this.state;
		switch (label) {
			case "nodename": prevState.nodeName = event.target.value;
				break;
			case "hyperlink": prevState.nodeHyperlink = event.target.value;
				break;
			case "nodedescription": prevState.nodeDescription = event.target.value;
				break;
			case "shapecolour": prevState.settings.shapeColour = event.target.value;
				break;
			case "shapebordercolour": prevState.settings.shapeBorderColour = event.target.value;
				break;
			case "shapeborderradius": prevState.settings.shapeBorderRadius = event.target.value;
				break;
			case "nodeColour": prevState.nodeColour = event.target.value;
				break;
			default: break;
		}
		this.setState(prevState);
	};

	handleClickOutside = e => {
		if (this.colorPickerRef && this.colorPickerRef.current && !this.colorPickerRef.current.contains(e.target)) {
			this.setState({
				colorPicker: { ...this.defaultColorPickerState }
			});
		}
	};

	colorPickerHandler = type => {
		this.setState({
			colorPicker: {
				...this.defaultColorPickerState,
				[type]: !this.state.colorPicker[type]
			}
		});
	};

	onColorPickerChange = (color, item) => {
		if (this.state.showNodeDetails) {
			this.setState({
				selectedShape: {
					...this.state.selectedShape,
					[item]: color,
					isColorChanged: item === "color" ? true : this.state.selectedShape.isColorChanged
				}
			});
		} else {
			this.setState({
				settings: {
					...this.state.settings,
					[item]: color
				}
			});
		}
	};

	styleHandler = (type, value) => {
		const bool = type === "fontSize" || type === "fontFamily" || type === "linkType" || type === "linkTo" || type === "textAlign" ||  type === "color";
		const isNotLink = type === "linkType"; // && value !== 'EXTERNAL_URL';
		const isLink = type === "linkTo" && value && value.length > 0;

		this.resetSelectedShape(value);

		this.setState({
			selectedShape: {
				...this.state.selectedShape,
				linkTo: isNotLink ? "" : this.state.selectedShape.linkTo,
				underline: isNotLink ? false : (isLink ? true : (type === "linkTo" ? false : this.state.selectedShape.underline)),
				[type]: bool ? value : !this.state.selectedShape[type]
			}
		}, () => { this.onChangecustomNodeData(value, type);this.saveNode(); });

		
	};

	resetSelectedShape = (value) => {
		switch (value) {
			case utils.sameDropper():
			case "EXTERNAL_URL":
				this.state.selectedShape.selectedClp = "";
				this.state.selectedShape.selectedIntervention = "";
				this.state.selectedShape.selectedHealthPathway = "";
				break;
			case "INTERVENTION":
				this.state.selectedShape.selectedClp = "";
				this.state.selectedShape.selectedHealthPathway = "";
				break;
			case "CLINICAL_PATHWAY":
				this.state.selectedShape.selectedIntervention = "";
				this.state.selectedShape.selectedHealthPathway = "";
				break;
			case "HEALTH_PATHWAY":
				this.state.selectedShape.selectedIntervention = "";
				this.state.selectedShape.selectedClp = "";
				break;
			default: break;
		}
	};

	//onChangeFiledData
	onChangeFiledData = (value) => {
		const customNodeData = this.state.customNodeData;
		switch (customNodeData.linkType) {
			case "INTERVENTION":
				this.onInterventionChange(value);
				break;
			case "CLINICAL_PATHWAY":
				this.onClinicalPathwayChange(value);
				break;
			case "HEALTH_PATHWAY":
				this.onHealthPathwayChange(value);
				break;
			default: break;
		}
	};

	onHealthPathwayChange = (data) => {
		const selectedShape = this.state.selectedShape;
		let link = "";
		if (data) {
			link = `${CLINICAL_PATHWAY_URL}/loadLink?treatmentCode=${data.code}&hospitalCode=${data.hospitalCode}&typeActivity=HEALTH_PATHWAY`;
			selectedShape.selectedHealthPathway = data;
			selectedShape.selectedHealthPathway.linkTo = link;
		} else {
			selectedShape.selectedHealthPathway = "";
		}

		this.styleHandler("linkTo", link);
		this.setState({ selectedShape });
		this.onChangecustomNodeData(data, "linkedItem");
	};

	onInterventionChange = (intervention) => {
		const selectedShape = this.state.selectedShape;
		let link = "";

		if (intervention) {
			selectedShape.selectedIntervention = intervention;
			link = `${INTERVENTION_URL}/${intervention.value}`;
			selectedShape.selectedIntervention.linkTo = link;
		} else {
			selectedShape.selectedIntervention = "";
		}

		this.setState({ selectedShape });
		this.styleHandler("linkTo", link);
		this.onChangecustomNodeData(intervention, "linkedItem");
	};

	onClinicalPathwayChange = (clinicalPathway) => {
		const selectedShape = this.state.selectedShape;
		let link = "";
		if (clinicalPathway) {
			link = `${CLINICAL_PATHWAY_URL}/loadLink?treatmentCode=${clinicalPathway.code}&hospitalCode=${clinicalPathway.hospitalCode}&typeActivity=CLINICAL_PATHWAY`;
			selectedShape.selectedClp = clinicalPathway;
			selectedShape.selectedClp.linkTo = link;
		} else {
			selectedShape.selectedClp = "";
		}

		this.setState({ selectedShape });
		this.styleHandler("linkTo", link);
		this.onChangecustomNodeData(clinicalPathway, "linkedItem");
	};

	getName() {
		return this.props.type.replace(/_/g, " ");
	}

	getLink(type) {
		return `/health_pathway/${this.props.match.params.organization}/${this.props.match.params.id}` + `${type ? `/sub_health_pathway/${type}` : ""}`;
	}

	generateItems() {
		const items = [];
		if (this.props.typeActivity === typeActivity.HEALTH_PATHWAY.toUpperCase()) {
			items.push({
				name: i18Get("Health Pathway", utils.getLanguage()),
				link: `/health_pathway/${this.props.match.params.organization}`
			});
			items.push({ name: this.props.healthPathwayData && this.props.healthPathwayData.name ?  ` ${this.props.healthPathwayData.hospitalCode ? this.props.healthPathwayData.hospitalCode : "MOM"} ${this.props.healthPathwayData.name}` : i18Get("Health Pathway Details", utils.getLanguage()), link: this.getLink() });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: this.getLink("diagram") });
		}
		return items;
	}

	//====================================GET SELECTED NODE DETAILS============================

	historyChange = (e) => {
		const prevState = this.state;
		if (e && e.change &&  e.change.Remove && e.change.Remove.length > 0) {
			prevState.sideBarSettings = "";
		}
		this.setState(prevState, this.diagramChangeObserver());
	};

	onClicked = (args) => {
		if (args.button !== "Left") { return false; }

		const prevState = this.state;

		if (args && args.button === "Left" && args.actualObject === undefined) {
			//prevState.sideBarSettings = "OPEN_TEMPLATE_TAB";//This is for template listing
			prevState.sideBarSettings = null;
		} else if (args.element && args.element.propName === "nodes") {
			this.getNodeDetails(args);
			prevState.sideBarSettings = "OPEN_NODE_SETTINGS_TAB";
		} else if (args.element && args.element.propName === "connectors") {
			this.getConnectorDetails(args);
			prevState.sideBarSettings = "OPEN_CONNECTOR_SETTINGS_TAB";
		} else  {
			
			prevState.sideBarSettings = null;
		}

		this.setState(prevState, this.updateDiagramWidth());
	};

	getNodeDetails = (args) => {
		this.setState({ customNodeData: {} });
		const prevState = this.state;
		if (args.element && args.element.propName === "nodes" && args.element.properties.children === undefined) {
			
			prevState.currentNode = args.element;
			prevState.selectedShape =  JSON.parse(JSON.stringify(this.defaultSelectedShapeState));
			if (args.element.annotations[0].addInfo) {
				prevState.nodeName = args.element.annotations[0].addInfo.nodeName ? args.element.annotations[0].addInfo.nodeName : "";

				if (args.element.annotations[0].addInfo.selectedShape) {
					prevState.selectedShape = JSON.parse(JSON.stringify(args.element.annotations[0].addInfo.selectedShape));
				}
			} else {
				prevState.nodeName = "";
				prevState.selectedShape.linkTo = "";
				prevState.selectedShape.linkType = "";
				prevState.selectedShape.selectedIntervention = "";
				prevState.selectedShape.selectedClp = "";
				prevState.nodeName = args.element.annotations[0].properties.content;
			}

			const selectedNode = diagramInstance.selectedItems.nodes[0];
			if (selectedNode === undefined) { return false;}
			
			const annotation = selectedNode.annotations[0];
			prevState.selectedShape.fill = selectedNode.style.fill;
			prevState.selectedShape.strokeWidth = selectedNode.style.strokeWidth;
			prevState.selectedShape.color = annotation.hyperlink.link ? annotation.hyperlink.color : annotation.style.color;
			prevState.selectedShape.strokeColor = selectedNode.style.strokeColor;
			prevState.selectedShape.fontSize = annotation.style.fontSize ? annotation.style.fontSize : "12px";
			prevState.selectedShape.fontFamily = annotation.style.fontFamily ? annotation.style.fontFamily : "Arial";
			prevState.selectedShape.bold = annotation.style.bold ? true : false;
			prevState.selectedShape.italic = annotation.style.italic ? true : false;
			prevState.selectedShape.underline = annotation.style.textDecoration ? (annotation.style.textDecoration === "Underline" ? true : false) : false;

			// New node's default alignment would be center.
			prevState.selectedShape.textAlign = annotation.content && annotation.horizontalAlignment ? NODE_ALIGNMENT_REVERSE[annotation.horizontalAlignment] : "center";

			if (annotation.addInfo && annotation.addInfo.selectedShape) {
				prevState.selectedShape.isColorChanged = annotation.addInfo.selectedShape.isColorChanged;
			}
			
			if (selectedNode.addInfo && selectedNode.addInfo.customNodeData) {
				prevState.customNodeData = { ...selectedNode.addInfo.customNodeData, id: selectedNode.id };
			} else {
				prevState.customNodeData = { ...JSON.parse(JSON.stringify(this.customNodeDataTemplate)), id: selectedNode.id };
			}

			//FOR OLD DATA-----Wll remove in future-----------------
			if (args.element.tooltip.content && !args.element.tooltip.content.includes("<li>" ) && !args.element.tooltip.content.includes("<ul>" ))
			{
				prevState.customNodeData.interventionDetails = args.element.tooltip.content.replaceAll("<div>", "\n").replaceAll("</div>", "") ;
			}
			
			if (prevState.customNodeData.duration && !prevState.customNodeData.durationInMinutes) {
				prevState.customNodeData.durationInMinutes = utilsFunction.convertMaxAndMinDurationToMinutes(prevState.customNodeData.duration, prevState.customNodeData.durationType);
			}
			//---------------------------------------------
			prevState.currentNodeId = args.element.id;
			this.setState(prevState);
			return;
		} else {
			prevState.currentNodeId = args.element.id;
			this.setState(prevState);
		}
	};

	 activityHandler = (activity, data) => {
		switch (activity) {
			case "fill":
				this.nodeChangeStyles(data, activity);
				break;
			case "strokeColor":
				this.nodeChangeStyles(data, activity);
				break;
			case "strokeWidth":
				this.nodeChangeStyles(data, activity);
				break;
			case "color":
				this.nodeChangeStyles(data, activity);
				break;

			default:
				console.log("Activity not matching");
				break;
		}

	};

	nodeChangeStyles = (data, item) => {

		this.setState({
			selectedShape: {
				...this.state.selectedShape,
				[item]: data,
				isColorChanged: true
			}
		}, () => {
			this.applyNodeChanges(item);
		});
	};

	applyNodeChanges = (activity, data) => {
		const currentNode = this.state.currentNode;
		const nodes = diagramInstance.nodes;
		
		for (const nodeVal of nodes) {
			if (nodeVal.id !== currentNode.id) {
				continue;
			}
			const shapeStyle = this.state.selectedShape;
			const annotation = nodeVal.annotations[0];
			
			switch (activity) {
				case "fill":
					nodeVal.style.fill = shapeStyle.fill;
					break;
				case "strokeColor":
					nodeVal.style.strokeColor = shapeStyle.strokeColor;
					break;
				case "strokeWidth":
					nodeVal.style.strokeWidth = shapeStyle.strokeWidth;
					break;
				case "color":
					annotation.style.color = shapeStyle.color;
					break;
				case "widthHeight":
					nodeVal.height = data;
					break;
				case "removeAnnotation":
					delete nodeVal.annotations[data ? data : 0];
					break;
				case "readOnly":
					annotation.constraints = AnnotationConstraints.ReadOnly;
					break;
				default:
					break;
			}
			diagramInstance.addLabels(nodeVal, annotation);
			diagramInstance.dataBind();
		}
	};


	// IF DRAG A NODE 
	dragSize = (args) => {
		if ( args.state === "Completed") {
			this.rearrangeAnnotations();
		}
	};


	 onChangecustomNodeData (data, field ) {
		const convertMaxAndMinDurationToMinutes = (value, valueType) => {
			if (!value) { 
				return 0;
			}
			switch (valueType) {
				case "Minutes":
					return value;
				case "Hours":
					return parseFloat(value) * 60;
				case "Days":
					return parseFloat(value) * 24 * 60;
				default: 0;
					break;
			}
		};
		const dataDetails = this.state.customNodeData;
		const prevState = this.state;
		switch (field) {
			case "intervention":
				dataDetails.intervention = data.target.value;
				break;
			case "interventionDetails":
				dataDetails.interventionDetails = data.target.value;
				break;
			case "intervenants":
				dataDetails.intervenants = data;
				dataDetails.intervenantsIds = this.fetchIdsa(data);
				dataDetails.intervenantsCode = this.fetchCode(data);
				break;
			case "unitCost":
				dataDetails.unitCost = data;
				break;
			case "location":
				dataDetails.location = data.target.value;
				break;
			case "durationOfTheTask":
				dataDetails.durationOfTheTask = data;
				break;
			case "duration":
				dataDetails.duration = data;
				dataDetails.durationType = dataDetails.durationType || "Minutes"; // For old Data
				dataDetails.durationInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.duration, dataDetails.durationType);
				break;
			case "durationType":
				dataDetails.durationType = data;
				dataDetails.durationInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.duration, dataDetails.durationType);
				break;
			case "durationMinimum":

				dataDetails.durationMinimumType = dataDetails.durationMinimumType || "Minutes";
				dataDetails.durationMinimum = data;
				dataDetails.durationMinimumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMinimum, dataDetails.durationMinimumType);
				break;
			case "durationMaximum":
				dataDetails.durationMaximumType = dataDetails.durationMaximumType || "Minutes";
				dataDetails.durationMaximum = data;
				dataDetails.durationMaximumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMaximum, dataDetails.durationMaximumType);
				break;
			case "durationMaximumType":
				dataDetails.durationMaximumType = data;
				dataDetails.durationMaximumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMaximum, dataDetails.durationMaximumType);
				break;
			case "durationMinimumType":
				dataDetails.durationMinimumType = data;
				dataDetails.durationMinimumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMinimum, dataDetails.durationMinimumType);
				break;
			case "expectedResults":
				dataDetails.expectedResults = data.target.value;
				break;
			case "argumentation":
				dataDetails.argumentation = data.target.value;
				break;
			case "risks":
				dataDetails.risks = data.target.value;
				break;
			case "reference":
				dataDetails.reference = data.target.value;
				break;
			case "linkTo":
				dataDetails.linkTo = data;
				prevState.selectedShape.linkTo = data;
				break;
			case "linkType":
				//---------CLEAR PREVIOUS DATA---------------------------
				this.resetSelectedShape(data);
				dataDetails.linkTo = "";
				prevState.selectedShape.linkTo = "";
				dataDetails.linkedItem = null;
				dataDetails.linkedItemId = null;
				dataDetails.linkedItemCode = null;
				//------------------------------------
				dataDetails.linkType = data;
				prevState.selectedShape.linkType = data;
				break;
			case "phase":
				//---------CLEAR PREVIOUS DATA---------------------------
				dataDetails.type = [];
				dataDetails.typeId = null;
				dataDetails.typeCode = null;
				//-----REMOVE STEP AND SUB STEPS---------------------------------
				dataDetails.steps = null;
				dataDetails.stepsId = "";
				dataDetails.stepsCode = "";
				dataDetails.subSteps = null;
				dataDetails.subStepsId = null;
				dataDetails.subStepsCode = null;
				//--------------------------------------
				dataDetails.phase = data;
				break;
			case "type":
				dataDetails.type = data;
				dataDetails.typeId =  this.fetchIdsa(data);
				dataDetails.typeCode = this.fetchCode(data);
				break;
			case "problems":
				dataDetails.problems = data.target.value;
				break;
			case "data":
				dataDetails.data = data.target.value;
				break;
			case "code":
				dataDetails.code = data;
				break;
			case "steps":
				dataDetails.steps = data;
				dataDetails.stepsId = data ? data.value : "";
				dataDetails.stepsCode = data ? data.code : "";
				dataDetails.subSteps = null;
				dataDetails.subStepsId = null;
				dataDetails.subStepsCode = null;
				break;
			case "subSteps":
				dataDetails.subSteps = data;
				dataDetails.subStepsId = data ? data.value : "";
				dataDetails.subStepsCode = data ? data.code : "";
				break;
			case "linkedItem":
				dataDetails.linkedItem = data;
				dataDetails.linkedItemId = data ? data.value : null;
				dataDetails.linkedItemCode = data ? data.code : null;
				break;
			case "annotationFill":
				prevState.selectedShape.annotationFill = data;
				break;
			default:
				break;
		}
		
		this.setState({ ...prevState, customNodeData: dataDetails }, () => {this.saveNode();});
		
	}

	fetchIdsa = (data) => { const list = data.map(e => e.value); return list; };
	fetchCode = (data) => { const list = data.map(e => e.code); return list; };

	rearrangeAnnotations = () => {
		const currentNode = this.state.currentNode;
		const nodes = diagramInstance.nodes;
		for (const nodeVal of nodes) {
			
			if (nodeVal.id !== currentNode.id) {
				continue;
			}
			const numberOfAnnotations = nodeVal.annotations.length;
			const nodeHeight = nodeVal.height;
			const nodePartition = Math.floor(numberOfAnnotations / 2);
			const unitHeight = nodeHeight / numberOfAnnotations;

			nodeVal.annotations.forEach( (annotation, possition) => {
				annotation.margin.properties.top = unitHeight * (possition - nodePartition);
				diagramInstance.addLabels(nodeVal, annotation);
				diagramInstance.dataBind(nodeVal);
			});

		}
	};


	getContent = (jsonData) => {
		const tooltipContent = document.createElement("div");
		tooltipContent.innerHTML = "<div > <span > Tooltip !!! </span> </div>";
		return tooltipContent;
	};


	//AUTO SELECT SHAP WHEN USER TOGGLE INTERFACE
	autoSelectNode = (id) => {
		const nodes = diagramInstance.nodes;
		for (const nodeVal of nodes) {
			if (nodeVal.id !== id) {
				continue;
			}
			diagramInstance.select([nodeVal]);
			this.getNodeDetails({ element: nodeVal });
		}
	};

	//=================CONNECTOR==================================================
	getConnectorDetails = (args) => {
		
		//selectedConnector
		const prevState = this.state;
		//CLREAR PREVIOUS DATA BY DEFAULT SETTINGS
		prevState.selectedShape =  { ...this.defaultSelectedShapeState };
		prevState.currentAnnotationDetails =  JSON.parse(JSON.stringify(this.defaultSelectedAnnotationState));
		//ASSIGN CURRENT ELEMENT DETAILS
		prevState.currentItem = args.element;
		//IF DATA EXIST
		if (prevState.currentItem.addInfo && prevState.currentItem.addInfo.customData) {
			prevState.selectedShape = JSON.parse(JSON.stringify(prevState.currentItem.addInfo.customData));
		}
		
		prevState.selectedShape.strokeWidth = prevState.currentItem.style.strokeWidth ? prevState.currentItem.style.strokeWidth : 1;

		if (prevState.currentItem.annotations && prevState.currentItem.annotations[0] && prevState.currentItem.annotations[0].addInfo && prevState.currentItem.annotations[0].addInfo.customData) {
			
			//prevState.currentAnnotationDetails = JSON.parse(JSON.stringify(prevState.currentItem.annotations[0].addInfo.customData));
			prevState.currentAnnotationDetails = { ...JSON.parse(JSON.stringify(this.customNodeDataTemplate)), ...JSON.parse(JSON.stringify(prevState.currentItem.annotations[0].addInfo.customData)), id: prevState.currentItem.id  };
		} else {
			prevState.currentAnnotationDetails = { ...JSON.parse(JSON.stringify(this.customNodeDataTemplate)), id: prevState.currentItem.id };
		}
		// OLD DATA
		if (prevState.currentAnnotationDetails.duration && !prevState.currentAnnotationDetails.durationInMinutes) {
			prevState.currentAnnotationDetails.durationInMinutes = utilsFunction.convertMaxAndMinDurationToMinutes(prevState.currentAnnotationDetails.duration, prevState.currentAnnotationDetails.durationType);
		}
		this.setState(prevState);
	};

	onChangeConnectorData (data, field ) { 
		const convertMaxAndMinDurationToMinutes = (value, valueType) => {
			if (!value) { 
				return 0;
			}
			switch (valueType) {
				case "Minutes":
					return parseFloat(value);
				case "Hours":
					return parseFloat(value) * 60;
				case "Days":
					return parseFloat(value) * 24 * 60;
				default: 0;
					break;
			}
		};
		const currentItem = this.state.currentItem;
		const connectors = diagramInstance.connectors;
		const prevState = this.state;
		const dataDetails = prevState.currentAnnotationDetails;
		for (const connectorVal of connectors) {

			if (connectorVal.id !== currentItem.id) { continue; }
			const annotation = connectorVal.annotations[0];

			switch (field) {
				case "title":
					dataDetails.title = data.target.value;
					break;
				case "duration":
					dataDetails.duration = data;
					dataDetails.durationType = dataDetails.durationType || "Minutes"; // For old Data
					dataDetails.durationInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.duration, dataDetails.durationType);
					break;
				case "durationType":
					dataDetails.durationType = data;
					dataDetails.durationInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.duration, dataDetails.durationType);
					break;
				case "durationMinimum":
					dataDetails.durationMinimum = data;
					dataDetails.durationMinimumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMinimum, dataDetails.durationMinimumType);
					break;
				case "durationMinimumType":
					dataDetails.durationMinimumType = data;
					dataDetails.durationMinimumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMinimum, dataDetails.durationMinimumType);
					break;
				case "durationMaximum":
					dataDetails.durationMaximum = data;
					dataDetails.durationMaximumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMaximum, dataDetails.durationMaximumType);
					break;
				case "durationMaximumType":
					dataDetails.durationMaximumType = data;
					dataDetails.durationMaximumInMinutes = convertMaxAndMinDurationToMinutes(dataDetails.durationMaximum, dataDetails.durationMaximumType);
					break;
				case "horizontalAlignment":
					dataDetails.horizontalAlignment = data;
					annotation.horizontalAlignment = data;
					break;
				case "verticalAlignment":
					dataDetails.verticalAlignment = data;
					annotation.verticalAlignment = data;
					break;
				case "fill":
					dataDetails.fill = data;
					annotation.style.fill = data;
					break;
				case "color":
					dataDetails.color = data;
					annotation.style.color = data;
					break;
				case "strokeColor":
					dataDetails.strokeColor = data;
					annotation.style.strokeColor = data;
					break;
				case "strokeWidth":
					dataDetails.strokeWidth = data;
					annotation.style.strokeWidth = data;
					break;
				case "fontSize":
					dataDetails.fontSize = data;
					annotation.style.fontSize = data;
					break;
				case "fontFamily":
					dataDetails.fontFamily = data;
					annotation.style.fontFamily = data;
					break;
				case "bold":
					dataDetails.bold = data;
					annotation.style.bold = data;
					break;
				case "italic":
					dataDetails.italic = data;
					annotation.style.italic = data;
					break;
				case "underline":
					dataDetails.underline = data;
					annotation.style.textDecoration = data;
					break;
				case "textAlign":
					dataDetails.textAlign = data;
					annotation.style.textAlign = data;
					break;
				case "arrow_strokeColor":
					prevState.selectedShape.strokeColor = data;
					connectorVal.style.strokeColor = data;
					connectorVal.targetDecorator.style = { strokeColor: data, fill: data };
					break;
				case "arrow_strokeWidth":
					prevState.selectedShape.strokeWidth = data;
					connectorVal.style.strokeWidth = data;
					break;
				default:
					break;
			}

			diagramInstance.addLabels(connectorVal, annotation);
			diagramInstance.dataBind();
		}
		this.setState({ ...prevState, currentAnnotationDetails: dataDetails }, () => { this.saveConnector();});
	}
	saveConnector = () => {

		const currentItem = this.state.currentItem;
		const connectors = diagramInstance.connectors;
		for (const connectorVal of connectors) {
			if (connectorVal.id !== currentItem.id) { continue; }
			connectorVal.addInfo = { customData: this.state.selectedShape };
			const annotation = connectorVal.annotations[0];
			annotation.style.textWrapping = "WrapWithOverflow";
			annotation.style.textOverflow = "Wrap";
			annotation.content = this.state.currentAnnotationDetails.title ? 
				`${this.state.currentAnnotationDetails.title.replace(" ", "")}${this.state.currentAnnotationDetails.duration ? 
					` (${this.state.currentAnnotationDetails.duration} ${i18Get(this.state.currentAnnotationDetails.durationType, utils.getLanguage())})` : "" }` : "";
			
			if (annotation.content !== "") {
				annotation.style.fill = this.state.currentAnnotationDetails ? this.state.currentAnnotationDetails.fill : "#ffffff";
			} else {	
				annotation.style.fill = "transparent";
			}

			annotation.addInfo = { customData: this.state.currentAnnotationDetails };
			//--------------------------------------------------
			diagramInstance.addLabels(connectorVal, annotation);
			diagramInstance.dataBind();
			utils.openMessage();
		}
	};

			
		updateSelectionHandler = () => { 


			if(diagramInstance) {
				const nodes = diagramInstance.nodes;
				//Clear All
				// diagramInstance.nodes.forEach(element => { const elementToRemove = document.getElementById(`selectionNode_${element.id}`); if(elementToRemove) {
				// 	elementToRemove.remove();
				// }});
				document.querySelectorAll('[id^="selectionNode_"]').forEach(element  => {
					const elementToRemove = document.getElementById(element.id); if(elementToRemove) {elementToRemove.remove();}
				});
				if (nodes.length > 0) {
					const selectedNodes = diagramInstance.selectedItems.nodes;
					// Create transparent selection nodes on top of each selected node
					if(selectedNodes.length > 0) {
						// Get the SVG container
						const svgContainer = document.getElementById('diagram_diagramLayer');
						selectedNodes.forEach((selectedNode) => {
							const isExistElement = document.getElementById(`selectionNode_${selectedNode.id}`);
							if(!isExistElement) {
								//=====================================
								// Get the element by its ID
										const element = document.getElementById(`${selectedNode.id}`);
										if (element) {
										// Get the bounding box of the element
										const x = element.getAttribute("x");
										const y = element.getAttribute("y");
										// Create a new rectangle element
										const newRect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
										// Set attributes for the rectangle (e.g., x, y, width, height, fill, etc.)
										newRect.setAttribute('x', x);
										newRect.setAttribute('y', y);
										newRect.setAttribute('width', selectedNode.width);
										newRect.setAttribute('height', selectedNode.height);
										newRect.setAttribute('fill', 'transparent');
										newRect.setAttribute('stroke-width', '2');
										newRect.setAttribute('stroke', 'red');
										newRect.setAttribute('id', `selectionNode_${selectedNode.id}`);
										// Append the rectangle to the SVG container
										svgContainer.appendChild(newRect);

										} else {
										console.log('Element with the specified ID not found.');
										}
							}
						});
					}
				}
			}
		};


	//==============DIAGRAM CHANGES DETECTOR===============================================

	diagramChangeObserver = () => {
		if (diagramInstance) {
			const prevState = this.state;
			const getData = JSON.parse(diagramInstance.saveDiagram());
			//const prevState = this.state;
			if (JSON.stringify(getData.nodes) !== initialCRCValue.node ||  JSON.stringify(getData.connectors) !== initialCRCValue.connector) {
				prevState.isThereAnyChanges = true;
				this.props.dispatch(action.getMissConnectores(getData.connectors));
			} else {
				prevState.isThereAnyChanges = false;
			}

			this.setState(prevState);
		}
	};

	okClicked = () => {
		this.props.history.push(`/health_pathway/${this.props.match.params.organization}/${this.props.match.params.id}`); 
	};

	yesClicked = () => {
		this.setState({ isSameUser: false });
		this.props.dispatch(diagramActions.removeDiagramLock("HEALTH_PATHWAY", (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": null }));
		setTimeout(() => {
			this.props.dispatch(diagramActions.checkDiagramLockValidation("HEALTH_PATHWAY", (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": sessionStorage.getItem("MOM-HealthPathway-Edit-uuid") }));
		}, 1000);
		
	};
	
	autoSelectConnector = (id) => {
		if (diagramInstance) {
			const connectors = diagramInstance.connectors;
			const prevState = this.state;

			for (const connectVal of connectors) {
				if (connectVal.id !== id) {
					continue;
				}
				diagramInstance.select([connectVal]);
				prevState.currentNodeId = id;
				this.getConnectorDetails({element: connectVal})
			}
			this.setState(prevState);
			this.props.dispatch(action.selectMissConnector(null));
			
		}
	};


	render() {

		if (this.props.syncfusion.diagramCompleted === "FULFILLED") {
			const prevState = this.state;
			const { data } = this.props.syncfusion.diagram;

			if (data) {
				const diagramJson = JSON.parse(data);
				prevState.data = data;
				prevState.nodes = diagramJson.nodes.map(node => {
					if (this.props.user.loginData.roleType === "PATHWAY_USER" && node.annotations[0].addInfo &&
						node.annotations[0].addInfo.selectedShape && node.annotations[0].addInfo.selectedShape.linkType === "INTERVENTION"
					) {
						node.annotations[0].hyperlink.link = "";
					}
					// Disabled shape hyperLink on edit
					node.annotations.map(annotation => {
						annotation.hyperlink.link = "";
						annotation.constraints = AnnotationConstraints.ReadOnly;
						annotation.content = annotation.content ? annotation.content : "";

					});
					return node;
				});

				//annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly }]
				//OLD CONNECTOR WILL BE READONLY
				prevState.connectors = diagramJson.connectors.map(connector => {

					//IF OLD CONNECTOR MISSING ANOTATION
					if (connector.annotations.length === 0) {
						connector.annotations =  [{ content: "", style: {} }];
					} 

					connector.annotations.map(annotation => {
						annotation.constraints = AnnotationConstraints.ReadOnly | AnnotationConstraints.Interaction | AnnotationConstraints.Drag;
					});
					return { ...connector };
				});
				
				/* No need for load whole diagram data, it also overrides diagram settings given in DiagramComponent */
				// diagramInstance.loadDiagram(data);

				/* INITIAL CRC VALUE OF THE DIAGRAM  */
				initialCRCValue = { node: JSON.stringify(diagramJson.nodes), connector: JSON.stringify(diagramJson.connectors) };

				const queryString = `diagramData=${JSON.stringify(diagramJson.nodes.map(e => { return { id: e.id, intervenants: [], step: "", substep: "", types: [] }; }))}&blockSuccess`;
				this.props.dispatch(healthPathwayAction.getDiagramInfo(this.props.match.params.id, queryString));
			}

			this.setState(prevState);

			this.props.dispatch(action.getMissConnectores(prevState.connectors));//Missed Link connector
			this.props.dispatch(actions.clearGetDiagramData());
		}
		if (this.props.syncfusion.diagramCompleted === "REJECTED") {
			this.props.dispatch(actions.clearGetDiagramData());
		}
		if (this.props.syncfusion.saveDiagramCompleted === "FULFILLED") {

			this.setState({ isSaving: false });


			if (this.state.unmountFromPage.goBack && this.state.unmountFromPage.path) {
				const path = this.state.unmountFromPage.path;
				this.setState({ unmountFromPage: { goBack: false, path: null }, networkIssue: false }, () => {
					this.props.history.push(path);
				});
			} else {
				this.setState({ unmountFromPage: { goBack: true } });
			}
			
			this.setState({ isThereAnyChanges: false });//Set this for no change avaialble after save diagram
			this.props.dispatch(actions.clearSaveDiagramData());
		}
		if (this.props.syncfusion.saveDiagramCompleted === "REJECTED") {
			if (this.props.syncfusion.saveDiagramCode.message_code === 4) {
				this.setState({ isSaveBlocked: true });
			}
			this.setState({ isSaving: false, networkIssue: false });
			if (this.state.unmountFromPage.goBack) {
				this.setState({ unmountFromPage: { goBack: false, path: "" } });
			}
			this.props.dispatch(actions.clearSaveDiagramData());
		}

		if (this.props.syncfusion.saveDiagramSettingsCompleted === "FULFILLED") {
			this.props.dispatch(actions.getDiagramData(this.props.match.params.id));
			this.props.dispatch(actions.clearSaveSettings());
		}
		if (this.props.syncfusion.saveDiagramSettingsCompleted === "REJECTED") {
			this.props.dispatch(actions.clearSaveSettings());
		}
		//if condition rejected -> navigate back
		if (this.props.syncfusion.diagramLockValidation === "REJECTED") {
			if (this.props.syncfusion.diagramValidationRejectInfo.message_code === 4) {
				this.setState({ isSameUser: true });
				this.props.dispatch(diagramActions.clearCheckDiagramLockValidation());
			} else {
				this.props.history.push(`/health_pathway/${this.props.match.params.organization}/${this.props.match.params.id}`); 
			}
		   }

		   if (this.props.syncfusion.diagramLockValidation === "FULFILLED") {
			this.setState({ isInEditPage: true });
			this.props.dispatch(diagramActions.clearCheckDiagramLockValidation());
		   }

		if (this.state.diagramInfo !==  this.props.healthPathway?.diagramInfo)
		{
			this.setState({ diagramInfo: this.props.healthPathway?.diagramInfo }, () => {
				//This will be update all node details if this is/was in group
				activities.updateDiagramDetails(diagramInstance, this.props.healthPathway?.diagramInfo);
			});

		}

		if(this.props.DiagramReducer?.missedConnector) {
			this.autoSelectConnector(this.props.DiagramReducer.missedConnector);
		}
		
		diagramWidth = "100%";
		
		return (
			<div>
				<div className="d-flex">
					<div style={{ flex: 1 }}>
						<BreadCrumb items={this.generateItems()} />
					</div>
					<AutoSave 
						networkIssue={this.state.networkIssue}
						isSaving={this.state.isSaving}
						onSave={this.saveDiagram.bind(this)}
					 />

				</div>

				<Diagram
					containerRef={this.diagramContainer}
					setDiagramInstance={this.setDiagramInstance}
					nodes={this.state.nodes}
					connectors={this.state.connectors}
					backToDetails={this.backToDetails}
					onSave={() => { this.setState({ unmountFromPage: { goBack: false } }); this.saveDiagram(); }}
					onZoomSliderChange={this.onZoomSliderChange}
					height={{
						symbolPaletteHeight: this.state.symbolPaletteHeight,
						diagramHeight: this.state.diagramHeight
					}}
					width={diagramWidth}
					// actionDisabled={this.props.healthPathwayData.hospitalId}
					hideShowNodeSideBar={this.hideShowNodeSideBar}
					sideBarSettings={this.state.sideBarSettings} 
					sideBarStatus={this.state.sideBarStatus}
				/>

				<SidebarSettings 
					openTab={this.state.sideBarSettings} 
					activityHandler={this.activityHandler} 
					nodeName={this.state.nodeName}
					selectedShape={this.state.selectedShape}
					inputChangeHandler={this.onInputChange}
					styleHandler={this.styleHandler}
					onChangeFiledData={this.onChangeFiledData}
					colorPicker={this.state.colorPicker}
					colorPickerHandler={this.colorPickerHandler}
					updateDiagramWidth= { this.updateDiagramWidth}
					treatmentId={this.props.match.params.id}
					customNodeData= {this.state.customNodeData}
					onChangecustomNodeData={this.onChangecustomNodeData.bind(this)}
					healthPathwayData={this.props.healthPathwayData}
					hideSideBar={this.state.sideBarStatus}
					diagramNodesData = {diagramInstance && diagramInstance.saveDiagram()}
					autoSelectNode = {this.autoSelectNode}
					currentNode={this.state.currentNode}
					currentNodeId={this.state.currentNodeId}
					//---------CONNECTOR PROPS------------------------------------
					selectedAnnotation={this.state.currentAnnotationDetails}
					onChangeConnectorData = {this.onChangeConnectorData.bind(this)}
				/>
				{/* { this.state.reorderData && <ReorderData open={this.state.reorderData} onClose={this.closeReorder} treatmentId={this.props.match.params.id} /> } */}

				<Modal
					open={this.state.isSaveBlocked}
					onOk={this.okClicked.bind(this)}
					closable={false}
					footer = {
						<Button onClick={this.okClicked.bind(this)} type="primary"> OK </Button>
					}
					className="margin-top-100"
				>
					<div><I18 tkey="You cannot save changes as this health Pathway is being modified by another user. Please try after some time" /> </div>
				</Modal>
				<Modal
					open={this.state.isSameUser}
					closable={false}
					footer = {
						<>
						    <Button onClick={this.yesClicked.bind(this)} type="primary"> YES </Button>
							<Button onClick={this.okClicked.bind(this)} type="primary"> NO </Button>
						</>
					}
					className="margin-top-100"
				>
					<div><I18 tkey={`This Pathway is currently modified by ${this.props.syncfusion.diagramValidationRejectInfo && this.props.syncfusion.diagramValidationRejectInfo.data ? this.props.syncfusion.diagramValidationRejectInfo.data.user.name  : ""}
					                 ${this.props.syncfusion.diagramValidationRejectInfo && this.props.syncfusion.diagramValidationRejectInfo.data ? this.props.syncfusion.diagramValidationRejectInfo.data.user.ipAddress : ""}
									 ${this.props.syncfusion.diagramValidationRejectInfo && this.props.syncfusion.diagramValidationRejectInfo.data ? this.props.syncfusion.diagramValidationRejectInfo.data.user.country : ""}. 
                                   Do you want to access to the latest version of this pathway and modify it ?`} /> </div>
				</Modal>
			</div>
		);
	}
}

Diagramm.propTypes = {
	DiagramReducer: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	syncfusion: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	DiagramReducer: store.DiagramReducer,
	healthPathway: store.healthPathway,
	actions: store.actions,
	syncfusion: store.syncfusion,
	user: store.user
}))(Diagramm));