import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import BreadCrumb from "../breadcrumb";
import eventNames from "../../../../../actions/events";
import locations from "../../../../../router/locations";
import EditSubClinincPathway from "./edit-sub-clinical-pathway";

class MainSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			extElementsList: [],
			subTreatment: {},
			type: "",
			selectedHospital: ""
		};
	}

	getLink(type) {
		return `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}` + `${type ? `/sub_clinical_pathway/${type}` : ""}`;
	}

	getName(name) {
		return name || "";
	}

	getQueryParams() {
		return `${this.state.position ? `?position=${this.state.position}` : ""}`;
	}

	generateItems() {
		const items = [];
		items.push({
			name: i18Get("Clinical Pathway", utils.getLanguage()),
			link: `/clinical_pathway/${this.props.match.params.organization}`
		});
		items.push({ name: this.props.clinicalPathway.name ? `${this.props.clinicalPathway.hospitalName ? this.props.clinicalPathway.hospitalName : ""} ${this.props.clinicalPathway.name}` : "", link: this.getLink() + this.getQueryParams() });
		items.push({ name: i18Get(this.getName(this.props.type && this.props.type.toUpperCase()), utils.getLanguage()), link: this.getLink(this.props.type) });
		if (this.state.subTreatment.subTreatmentId) {
			items.push({ name: this.state.subTreatment.subTreatmentName, link: `${this.getLink(this.props.type)}/${this.state.subTreatment.subTreatmentId}` });
		}
		return items;
	}

	render() {
		if (this.props.type !== this.state.type) {
			this.state.type = this.props.type;
			this.state.extElementsList = [];
			this.state.typeList = [];
		}
		if (this.props.intervention.getOneSubTreatmentStatus === "FULFILLED") {
			this.state.subTreatment = this.props.intervention.subTreatment;
		}

		if (this.props.extClinicalPathway.position) {
			this.state.position = this.props.extClinicalPathway.position;
			this.props.dispatch({ type: eventNames.CLEAR_SIDE_BAR_POSITION, payload: {} });
		}

		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				<Switch>
					<Route
						path={locations.editSubClinicPathWay}
						render={() => <EditSubClinincPathway {...this.props} />}
					/>
				</Switch>

			</div>
		);
	}
}

MainSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	intervention: store.intervention,
	extClinicalPathway: store.extClinicalPathway,
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(MainSection));
