import axios from "axios";
import Config from "MOM-config";
import events from "./event";

const getGroupDetails = (treatMentId, nodeId) => ({
  type: events.GET_REGROUP_DETAILS,
  meta: nodeId,
  payload: axios.get(
    `${Config.apiRootSecure}/healthPathway/hyperLink/${treatMentId}/${nodeId}`
  ),
});

const clearGetGroupDetails = () => ({
  type: events.GET_CLEAR_REGROUP_DETAILS,
  payload: {},
});


export default {
  getGroupDetails,
  clearGetGroupDetails
};
