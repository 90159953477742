import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import clpDailySubSteps from "../../../../../../../../constants/clpDailySubSteps.json";
import events from "../../../../../../../../actions/events";
import SubStepDropdown from "../../sub-step-dropdown";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 1,
			name: "",
			code: "",
			invalidName: false,
			invalidCode: false,
			invalidSubStep: false,
			selectedSubStep: "",
			subStep: {},
			subStepId: "",
			isloading: false,
			subStepFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwaySubStep(this.props.subStepId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false, invalidCode: false, subStepFound: false });
		this.props.refreshCompleted();
	}

	subStepChanged(e) {
		this.setState({ selectedSubStep: e ? e : "", invalidSubStep: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.name.trim())) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateStep = () => {
		if (this.state.selectedSubStep && this.state.subStepFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.name,
					clinicalPathwayType: this.props.clinicalPathwayType ? this.props.clinicalPathwayType : ""
				};
				this.props.dispatch(adminClinicalPathwayActions.saveAndApproveSubStep(this.state.subStep.clpSubStepId, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateStep()) {
				payload = {
					clpSubStepId: this.state.selectedSubStep && this.state.selectedSubStep.value ? this.state.selectedSubStep.value : "",
					clinicalPathwayType: this.props.clinicalPathwayType ? this.props.clinicalPathwayType : ""
				};
				this.props.dispatch(adminClinicalPathwayActions.assignAndApproveSubStep(this.state.subStep.clpSubStepId, payload));
			}
			else {
				this.setState({ invalidSubStep: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidCode = false;
		prevState.code = value;
		this.setState(prevState);
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.subStepFound = true;
			this.state.value = 2;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.adminClinicalPathway.asssignAndApproveSubStepStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", code: "", subStepFound: false });
			this.props.dispatch(adminClinicalPathwayActions.assignAndApproveSubStepCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.adminClinicalPathway.saveAndApproveSubStepStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", code: "", subStepFound: false });
			this.props.dispatch(adminClinicalPathwayActions.saveAndApproveSubStepCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.adminClinicalPathway.fetchOneClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.state.subStep = this.props.adminClinicalPathway.subStepDetails;
			this.state.name = this.state.subStep.name ? this.state.subStep.name : "";
			this.state.code = this.state.subStep.code ? this.state.subStep.code : "";
			this.state.isloading = true;
			this.state.selectedSubStep = { label: this.state.name ? this.state.name : "", value: this.state.subStep.clpSubStepId };
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwaySubStep());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.subStep.name, id: this.state.subStep.clpSubStepId } });
		}

		if (this.props.adminClinicalPathway.fetchOneClinicalPathwaySubStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwaySubStep());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Sub Step", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel' />
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
							<I18 tkey='Save & Approve' />
						</Button>
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative margin-left-0 margin-right-0">
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.subStep.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.subStep.hospitalName ? this.state.subStep.hospitalName : ""}</p>
							</div>
						</div>
						{this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use New Name" />
										</Radio>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.name}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1 || this.state.subStep.name === clpDailySubSteps.type[this.state.subStep.name]}
											/>
											{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid sub step name" /></span>}
										</div>
									</div>
								</div>
								{this.state.subStep.name !== clpDailySubSteps.type[this.state.subStep.name] &&
									<div className="row">
										<div className="col-md-12 pt-10">
											<Radio value={2}> <I18 tkey="Assign To Sub Step" /></Radio>
										</div>
										<div className="col-sm-12 pt-10">
											<div className="form-group pl-20">
												<SubStepDropdown
													className="padding-4"
													data={this.state.selectedSubStep}
													disabled={this.state.value != 2} style={{ width: "100%" }}
													statusCheck={this.statusCheck.bind(this)}
													isApproved={true}
													isRank={true}
													step={this.props.stepId}
													clinicalPathwayType={this.props.clinicalPathwayType && (this.props.clinicalPathwayType !== "ALL") ? this.props.clinicalPathwayType : ""}
													subStepChanged={this.subStepChanged.bind(this)} />
												{this.state.invalidSubStep && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select sub step" /></span>}
											</div>
										</div>
									</div>
								}
							</div>
							: ""
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	adminClinicalPathway: store.adminClinicalPathway
}))(AssignAndApproveModel));    