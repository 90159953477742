import React from "react";
import { ReactTableDefaults } from "./react-table";
import { List, AutoSizer } from "react-virtualized";
import _ from "lodash";

const wrapperTbodyComponent = (wrapProps, firstDivRef, handleScrollFirst, secondDivRef, handleScrollSecond, handleHeight) => {
	const TbodyComponent = props => {
		const { rows, children: makePageRow, ...restProps } = props;
		const rowRenderer = ({ key, index, style }) => {
			const rowInfo = rows ? rows[index] : "";

			return (
				<div key={key} style={style}>
					{rowInfo && makePageRow(rowInfo.row, rowInfo.index, rowInfo.path)}
				</div>
			);
		};

		const contentHeight = (rows ? rows.length : 0) * (wrapProps.rowHieghtCustom ? wrapProps.rowHieghtCustom : 30);

		return (
			<div>
				<div className="virtualScrollBar" onScroll={handleScrollSecond} ref={secondDivRef} >
					<div style={{ height: contentHeight }}></div>
				</div>
				<ReactTableDefaults.TbodyComponent {...restProps}>

					<AutoSizer>
						{({ height, width }) => {
							handleHeight(height);
							return (
								<div className="virtualScrollWrapper" onScroll={handleScrollFirst} ref={firstDivRef} style={{ width: width }}>
									<List
										width={width}
										height={height}
										rowCount={rows ? rows.length : 0}
										rowHeight={wrapProps.rowHieghtCustom ? wrapProps.rowHieghtCustom : 30}
										scrollToIndex={wrapProps.scrollTo}
										onRowsRendered={wrapProps.rowScroll ? wrapProps.rowScroll : () => { }}
										rowRenderer={rowRenderer}
									/>
								</div>
							);
						}}
					</AutoSizer>

				</ReactTableDefaults.TbodyComponent>
			</div>
		);
	};
	return TbodyComponent;
};

export default Component => {
	const firstDivRef = React.createRef(null);
	const secondDivRef = React.createRef(null);
	const handleScrollFirst = (scroll) => {
		secondDivRef.current.scrollTop = scroll.target.scrollTop;
	};

	const handleScrollSecond = (scroll) => {
		firstDivRef.current.scrollTop = scroll.target.scrollTop;
		document.getElementsByClassName("ReactVirtualized__Grid")[0].scrollTop = scroll.target.scrollTop;

	};
	const handleHeight = (height) => {
		if (secondDivRef.current) {
			secondDivRef.current.style.height = height + "px";
		}
	};
	return class RTVirtualizedTable extends React.Component {
		getTbodyProps = state => {
			const { expanded, pageRows } = state;
			const flattenPageRows = (rows, path = []) =>
				rows
					.map((row, i) => {
						const subRows = row._subRows ? row._subRows : [];
						const nestingPath = [...path, i];
						const isExpanded = _.get(expanded, nestingPath);
						const nestedRows = isExpanded
							? flattenPageRows(subRows, [...path, i])
							: [];
						return [
							{
								row,
								index: i,
								path
							},
							...nestedRows
						];
					})
					.reduce((result, chunk) => result.concat(chunk), []);

			return {
				rows: flattenPageRows(pageRows)
			};
		};

		render() {
			return (
				<Component
					getTbodyProps={this.getTbodyProps}
					TbodyComponent={wrapperTbodyComponent(this.props, firstDivRef, handleScrollFirst, secondDivRef, handleScrollSecond, handleHeight)}
					functionalRowRendering={true}
					{...this.props}
				/>
			);
		}
	};
};
