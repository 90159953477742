import eventNames from "../../actions/events";

const initialTranslationsState = {
	isThemeFetchComplete: false,
	theme: {},
	isThemeUpdateComplete: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_SETTINGS}_FULFILLED`:
			return {
				...state,
				theme: action.payload.data.data,
				isThemeFetchComplete: "FULFILLED"
			};
		case `${eventNames.GET_ALL_SETTINGS}_REJECTED`:
			return {
				...state,
				isThemeFetchComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ALL_SETTINGS:
			return {
				...state,
				isThemeFetchComplete: false
			};
		case `${eventNames.UPDATE_SETTINGS}_REJECTED`:
			return {
				...state,
				isThemeUpdateComplete: "REJECTED"
			};

		case `${eventNames.UPDATE_SETTINGS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isThemeUpdateComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isThemeUpdateComplete: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_SETTINGS:
			return {
				...state,
				isThemeUpdateComplete: false
			};

		default:
			return state;
	}
}
