import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import CardLink from "../../../dashboard/cards/card-link";
import clinicalPathwayAction from "../../../../../actions/clinicalPathway-details";

class ExtClinicalPathway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1,
			clinicalPathway: [],
			location: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
            || location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
		this.fetchSidebar();
	}

	fetchSidebar() {
		this.props.dispatch(clinicalPathwayAction.fetchClinicalPathwaySidebar(this.props.match.params.id));
	}

	componentDidMount() {
		if (this.state.clinicalPathway.length) {
			this.getConfig(this.props.match.params.organization, this.props.match.params.id, this.state.clinicalPathway);
		}
	}

	getConfig(organization, id, clinicalPathway) {
		const configData = clinicalPathway;
		const clinicalPathwayConfig = configData.map((data) => {
			data.link = data.link ? data.link.replace("organization", organization) : "";
			data.link = data.link ? data.link.replace("id", id) : "";
			return data;
		});
		this.setState({ clinicalPathway: clinicalPathwayConfig });
	}

	render() {
		if (this.props.clinicalPathwayDetails.fetchClinicalPathwaySidebar === "FULFILLED") {
			this.state.loading = false;
			this.state.clinicalPathway = this.props.clinicalPathwayDetails.clinicalPathwaySidebar;
			this.props.dispatch(clinicalPathwayAction.clearClinicalPathwaySidebar());
		}

		return (
			<div>
				{ this.getClinicalPathwaySideBar()}
			</div>
		);
	}

	getClinicalPathwaySideBar() {
		return this.state.clinicalPathway.map((cardLink, index) => {
			cardLink.position = index + 1;
			return (
				<div className="mom-sidebar" key={index}>
					<div id={`sidebar_${index + 1}`}>
						{cardLink ? this.renderContents(cardLink) : ""}
					</div>
				</div>
			);
		});
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {
		if (cardLink.expandable) {
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}
		return <CardLink data={cardLink} position={cardLink.position} />;
	}
}

ExtClinicalPathway.propTypes = {
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	clinicalPathwayDetails: store.clinicalPathwayDetails
}))(ExtClinicalPathway));
