import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import ConsumableDefinition from "./consumable-definition";
import ConsumableSterilization from "./sterilization";

class Consumable extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<Switch>
				<Route path={locations.editConsumable_definition} component={ConsumableDefinition} />
				<Route path={locations.consumable_definition} component={ConsumableDefinition} />
				<Route path={locations.consumable_sterilization} component={ConsumableSterilization} />
				<Redirect to={`${locations.consumable_definition_plain}/${locations.tabConsumableClass}`} />
			</Switch>
		);
	}
}

Consumable.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Consumable));
