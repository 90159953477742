import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button, Spin } from "antd";
import clinicalPathwayAction from "../../../../../../../../actions/clinical-pathway";
import DragWindow from "../../drag-window";
import "antd/dist/antd.min.css";
import ReorderByModel from "./reorder-problem-by-attached-model";
import { LoadingOutlined  } from "@ant-design/icons";
class QuickReorderByModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			isLoading: false,
			list: [],
			isLocalInterventionWithMultipleSource: false
		};
	}

	UNSAFE_componentWillMount() {this.fetchData(); }

	confirmation = () => {
		this.props.disableQuickReorder();
	};

	quickReorderTitle = () => {
		return (<div className="position_relative"><DragWindow title={[i18Get("Linked Model List", utils.getLanguage()),
			<span key="number_of_items" className='float_right font_size_11px margin-right-30 '><I18 tkey='Number Of items' />: <strong>{(this.state.list) ? this.state.list.length : 0}</strong> </span>]} /></div>);
	};

	fetchData() {
		this.setState({ isLoading: true });
		const queryString = "offset=0&numResults=1000&type=ADDED&isModel=1&sort=rank&desc=0";
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(clinicalPathwayAction.getAllItems(this.props.match.params.id, queryString, this.state.fetchAllMeta));
		});
	}



	checkAnyLocalInterventionFromMultipleSource() {

		const getProblemList = this.props.clinicalPathwayProblems.problemList.mapping;
		const problemList = getProblemList.filter(each => !each.modelTreatmentId);
		problemList.forEach((problem) => {
			problem.additionalInfo.forEach(additionalInfo => {
				const linkedModels = this.state.list.filter(model => model.id === additionalInfo.modelTreatmentId);
				console.log("linkedModels", linkedModels);
				if (linkedModels.length > 0) {
					this.setState({ isLocalInterventionWithMultipleSource: true });
					return true;
				}
			});
		});
	}

	render() {
		// //--------------------------------FETCH DATA------------------------------------------------------------
		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "FULFILLED" && this.props.clinicalPathway.clinicalPathwayModelMeta === this.state.fetchAllMeta) {
			this.setState({ list: this.props.clinicalPathway.clinicalPathwayModel.data, isLoading: false }, 
				() => { 
					this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
					this.checkAnyLocalInterventionFromMultipleSource();
				 } );
		}

		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal create_problem create_additional quick_reorder_table_modal"
					title={this.quickReorderTitle()}
					visible={this.props.enableQuickReorder}
					onCancel={this.confirmation}
					wrapClassName="drag_modal"
					footer={[
						<Button
							className={this.state.isLocalInterventionWithMultipleSource ? "margin-top-45" : "" }
						 key="back" onClick={this.confirmation}>
							<I18 tkey="Cancel" />
						</Button>
					]}
				>
					<div className="">
						{ this.state.list.length > 0 ?
							<ReorderByModel
								list={this.state.list}
								quickReorderChanges={this.state.quickReorderProblemListChanges}
								disableReorder = { true }

							/>
							: <div className="quick-order-empty-list">{this.state.isLoading ? <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 28 }}/>} /> : <I18 tkey="NO DATA FOUND" /> }</div>
						}
					</div>
				</Modal>
			</div>
		);
	}
}

QuickReorderByModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		clinicalPathway: store.clinicalPathway,
		actions: store.actions
	}))(QuickReorderByModel)
);
