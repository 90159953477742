import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import List from "./list";
class Roles  extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	UNSAFE_componentWillMount() {
		this.props.showRecord(true);
	}
    
	render() {
		return (
			<div className="activities_table_scroll">          
				<List totalCount={this.props.totalCount} showCreate = {this.props.showCreate} showHideCreate = {this.props.showHideCreate} search ={this.props.search} />
			</div>  
		);
	}
}

Roles.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	translations: store.translations
}))(Roles));                    