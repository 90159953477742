import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import elementClassActions from "../../../../../../../actions/element-class";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

class SelectConsumableClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			elementClassData: {},
			consumableClass: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true
		};
	}

	UNSAFE_componentWillMount() {
		this.state.consumableClass = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchElementClassList(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	renderelementClassOptions() { 
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.consumableClass.length) {    
			this.state.consumableClass.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState);
	}

 
	fetchElementClassList(search) {
		this.setState({ isListLoading: true });
		const queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}&status=APPROVED${this.props.consumableType && (this.props.consumableType !== "ALL") ? `&type=${this.props.consumableType}` : ""}${ search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : ""}`;
		this.props.dispatch(elementClassActions.getCosumableClass(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchElementClassList(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchElementClassList(this.state.search);
	}

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	render() {
		if (this.props.elementClass.isFetchAllConsumableClassCompleted === "FULFILLED") {
			this.props.dispatch(elementClassActions.clearCosumableClassFetch());
			this.state.consumableClass = unionWith(this.state.consumableClass,  this.props.elementClass.consumableClass, isEqual);
			this.renderelementClassOptions();
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
				this.fetchElementClassList(false);
				this.state.isDataFound = false;
			}
		}
		return (
			<Select 
				className="w_100 di"
				isDisabled={this.props.disabled}
				defaultActiveFirstOption={false}
				onChange={this.props.onConsumableClassChange}
				value={this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : ""}
				isSearchable
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select", utils.getLanguage())}
			/>
		);
	}
}

SelectConsumableClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elementClass: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	elementClass: store.elementClass,
	translations: store.translations
}))(SelectConsumableClass));                    