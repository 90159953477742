import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import communitySectionAction from "../../../../../../../actions/community-section";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class selectCommunitySection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// communityData: {},
			communityData: [],
			offset: 0,
			numResults: 100,
			search: "",
			fetchAllMeta: null
		};
	}

	UNSAFE_componentWillMount() {
		this.state.communityData = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchHospitalList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderHospitalOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.communityData) {
			this.state.communityData.map((data) => {
				prevState.options.push({ value: data.id, label: data.name });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchHospitalList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += "&status=APPROVED";
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(communitySectionAction.getAll(queryString, this.state.fetchAllMeta));
		});
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchHospitalList(search);
			}, 800);
		}
	}

	onListScroll() {
		const offset = this.state.offset + this.state.numResults;
		this.setState({ ...this.state, offset }, () => { this.fetchHospitalList(); });
	}

	render() {
		if (this.props.communitySection.isFetchAllCommunitySectionCompleted === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.communitySection.fetchAllElementClassMeta) {
				this.state.communityData = this.props.communitySection.communitySection ? this.props.communitySection.communitySection : [];
				this.renderHospitalOptions();
				this.props.dispatch(communitySectionAction.clearGetAll());
			}
		}

		return (
			<Select
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.communityChanged}
				value={this.props.data}
				isSearchable
				isClearable
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Community", utils.getLanguage())}
			/>
		);
	}
}

selectCommunitySection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	specialty: store.specialty,
	translations: store.translations,
	communitySection: store.communitySection
}))(selectCommunitySection));
