import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import CreateActivityFamilies from "./create";
import EditActivityFamilies from "./edit";
import ListActivityFamilies from "./list";
import BreadCrumb from "../../../breadcrumb";
import "./activity_families.css";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import TypeActivityDropdown from "./type-activity-dropdown";
import { DebounceInput } from "react-debounce-input";
import { Select } from "antd";
import HospitalSelect from "../specialities/selectHospital";
import { typeActivity } from "../../../../../../../constants";
import { FilterOutlined } from "@ant-design/icons";
const { Option } = Select;

class ActivityFamilies  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activityFamily: {},
			totalRecords: 0,
			showFilter: false,
			selectedStatus: "All",
			selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			typeActivity: "HEALTH_PATHWAY",
			search: "",
			copyKey: 1
		};
	}

	UNSAFE_componentWillMount() {
		if (utils.checkForParameter("typeActivity", this.props.location)) {
			this.state.typeActivity = utils.checkForParameter("typeActivity", this.props.location);
			this.setState({ ...this.state });
		}
	}

	getSortAndDesc(sort, desc) {
		this.state.sort = sort;
		this.state.desc = desc;
		this.setState({ ...this.state });
	}

	onStatusChange = (e) => {
		this.setState({ ...this.state, selectedStatus: e ? e : "" });
	};

	onHospitalChanged(e) {
		this.setState({ ...this.state, selectedHospital: e ? e : "" });
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Activity Families", utils.getLanguage()), link: locations.activity_families + `?typeActivity=${this.state.typeActivity}` });
		if (this.props.location.pathname === locations.activity_families + "/create") {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.activity_families + `/create?typeActivity=${this.state.typeActivity}` });
		}
		if (this.state.activityFamily.id) {
			items.push({ name: this.state.activityFamily.name, link: locations.activity_families + `/${this.state.activityFamily.id}?typeActivity=${this.state.typeActivity}` });
		}
		return items;
	}

	addClicked() {
		this.props.history.push(locations.activity_families + `/create?typeActivity=${this.state.typeActivity}`);
	}

	typeActivitySelected(e) {
		this.setState({ ...this.state, typeActivity: e }, () => { this.props.history.push(this.props.location.pathname + `?typeActivity=${this.state.typeActivity}`); });
	}

	showContent() {
		if (this.props.location.pathname === locations.activity_families) {
			return true;    
		}
		return false;
	}

	filterClicked() {
		this.setState({ ...this.state, showFilter: !this.state.showFilter });
	}

	getFilter() {
		return (
			<div className="row ">
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Status" />:</label>
						<Select 
							className="w_100"
							value={this.state.selectedStatus ? this.state.selectedStatus : <I18 tkey="All"/> }
							onChange={this.onStatusChange}
						>
							<Option key='All' value="ALL"><I18 tkey="All"/></Option>
							<Option key='Approved' value="APPROVED"><I18 tkey="Approved"/></Option>
							<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval "/></Option>
						</Select>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Hospital" />:</label>
						<HospitalSelect 
							data={this.state.selectedHospital ? this.state.selectedHospital : "" } 
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}

	filterChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState);
	}

	clearFilter() {
		this.setState({ ...this.state, selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) }, selectedStatus: "All", search: "" });
	}

	render() {
		this.state.activityFamily = this.props.breadcrumb.componentData;
		this.state.totalRecords = this.props.family.familyList.count ? this.props.family.familyList.count : 0;
		return (   
			<div className="main_overview_section">
				<div className="pole_container">
					<BreadCrumb items={this.generateItems()} showRecord={this.showContent()} totalRecords={this.state.totalRecords}/>
					{this.showContent() && <div className="main_overview_top_section di w_100 padding-top-15">
						<div className="row">
							<div className="col-md-3 col-sm-8 ">
								{(this.state.typeActivity !== typeActivity.ACCOMMODATION.toUpperCase())
                                 &&
                                <div className="create_interventions margin-top-2"><button className="common_button" type="button" onClick={this.addClicked.bind(this)}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE" /></button></div>}
							</div>
						</div>
					</div>}
					<div className="w_100 di">
						<div className="tab_section tab_section_speciality  padding-top-15">
							{
								this.props.location.pathname === locations. activity_families ?
									<div>
										<div className="row margin-bottom-15">
											<div className="col-md-4">
												<TypeActivityDropdown typeActivitySelected={this.typeActivitySelected.bind(this)} typeActivity={this.state.typeActivity}/>
											</div>
											<div className="col-md-3"></div>
											<div className="col-md-4">
												<DebounceInput
													className="filter_input_design form-control"
													debounceTimeout={750}
													placeholder={i18Get("Search...", utils.getLanguage())}
													value={this.state.search}
													onChange={this.filterChanged.bind(this)}/>
											</div>
											<div className="col-md-1 text_align_right">
												<div className="filter  margin-right-0" onClick={this.filterClicked.bind(this)}>
												 <FilterOutlined  className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
												</div>
											</div>
										</div>
										{this.state.showFilter && this.getFilter()} 
									</div>
									: ""
							}
							<div className="tab-content">
								<div id="home" className="tab-pane fade in active">
									{this.loadContent()}
								</div>
							</div>
						</div>
                    
					</div>
				</div>
			</div>
		);
	}

	loadContent() {
		return (
			<Switch>
				<Route exact path={locations.activity_families}
					render={() =>
						<ListActivityFamilies typeActivity={this.state.typeActivity} search={this.state.search} status = {this.state.selectedStatus} hospitalId = {this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "" } getSortAndDesc={this.getSortAndDesc.bind(this)}/> } />
				<Route exact path={locations.activity_families + "/create"} 
					render={() =>
						<CreateActivityFamilies  typeActivity={this.state.typeActivity}/> } />
				<Route path={locations.edit_activity_families}
					render={() =>                    
						<EditActivityFamilies typeActivity={this.state.typeActivity}/> } />
				<Redirect to={locations.activity_families } /> 
			</Switch>
		);
	}
}

ActivityFamilies.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	family: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	breadcrumb: store.breadcrumb,
	family: store.family,
	translations: store.translations
}))(ActivityFamilies));                    