import React, { Component } from "react";
import I18 from "../../../../../../../i18";
import { Popover, Checkbox } from "antd";
import selectColumn from "../../../../../../../constants/clinicalPathwayColumnSelect.json";
import { EyeOutlined } from "@ant-design/icons";
const CHECK_ALL = "CHECK_ALL";

export default class ShowHideColumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			check: {}
		};
	}

	onBlur() {
		this.setState({ open: false });
	}

	onFocus() {
		this.setState({ open: true });
	}

	handleChange(e) {
		let prevState = this.state;
		if (e !== CHECK_ALL) {
			if (!this.state.check[e]) {
				prevState.check[e] = true;
			} else {
				delete prevState.check[e];
			}
		} else {
			prevState = this.toggleCheckAllOptions(prevState);
		}
		prevState.checkAll = (Object.keys(this.state.check).length === selectColumn["COLUMN"].labels.length);
		prevState.intermediate = !!Object.keys(this.state.check).length && (Object.keys(this.state.check).length < selectColumn["COLUMN"].labels.length);
		this.setState(prevState);
		this.props.onChangeColSelect({ check: this.state.check, checkAll: this.state.checkAll, intermediate: this.state.intermediate, defaultColumn: true });
	}

	getContent() {
		return (
			<div className="">
				{this.renderOptions()}
			</div>
		);
	}

	toggleCheckAllOptions(prevState) {
		if (!this.state.checkAll) {
			selectColumn["COLUMN"].labels.map(function (item) {
				prevState.check[item] = true;
			}.bind(this));
		} else {
			prevState.check = {};
		}
		return prevState;
	}

	renderOptions() {
		const options = [];
		options.push(<div className="padding-top-10"><Checkbox indeterminate={this.state.intermediate} checked={this.state.checkAll} onChange={this.handleChange.bind(this, CHECK_ALL)} />&nbsp;&nbsp;<I18 tkey="Check All" /></div>);
		selectColumn["COLUMN"].labels.map(function (item) {
			options.push(<div className="padding-top-10"><Checkbox checked={this.state.check[item]} onChange={this.handleChange.bind(this, item)} />&nbsp;&nbsp;<I18 tkey={item.replace(/_/g, " ")} /></div>);
		}.bind(this));
		return options;
	}

	handleVisibleChange = visible => {
		this.setState({ open: visible });
		this.props.dropDownVisibleChange(visible);
	};


	render() {
		return (
			<Popover
				overlayClassName="show_hide_new_popup"
				title={null}
				trigger="click"
				placement="leftBottom"
				onVisibleChange={this.handleVisibleChange}
				content={this.getContent()}
				visible={this.props.visible}

			>   
				{
					<span className="margin-right-10">
						<EyeOutlined />
						<I18 tkey='Show/Hide Columns'/>
					</span>
				}
			</Popover> 

		);
	}

	componentDidMount() {
		{
			this.setState({
				open: true,
				check: this.props.selectedColumns && this.props.selectedColumns.check ? this.props.selectedColumns.check : {},
				checkAll: this.props.selectedColumns && this.props.selectedColumns.checkAll ? this.props.selectedColumns.checkAll : "",
				intermediate: this.props.selectedColumns && this.props.selectedColumns.intermediate ? this.props.selectedColumns.intermediate : ""
			});
		}
        
	}
}
