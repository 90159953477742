import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get }  from "../../../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../../utils/utils";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import isPermissionGranted from "../../../../../../../../utils/permission";
import { statusList } from "../../../../../../../../constants";

class Definition  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			definition: {},
			treatmentId: this.props.treatmentId
		};
	} 

	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	UNSAFE_componentWillUpdate () {
		if (this.state.treatmentId !== this.props.treatmentId)
		{ 
			this.setState({ treatmentId: this.props.treatmentId }, this.onFetchData());
		}
	}

	onFetchData () {
		this.props.dispatch(clinicalPathwayDetailsAction.getDefinition((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), this.props.type));
	}
	
	init() {
		const contentBlock = htmlToDraft(this.state.definition.description ? this.state.definition.description : `<p></p><p style="text-align:center;">${i18Get("NO PATHWAY DEFINITION FOUND", utils.getLanguage())}</p>`);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);        
		}
	}

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.clinicalPathway.isReadOnly, method);
	};
	render() { 
		if (this.props.clinicalPathwayDetails.isDefinitionGetCompleted === "FULFILLED") {
			this.state.definition = this.props.clinicalPathwayDetails.definition[0] ? this.props.clinicalPathwayDetails.definition[0] : {};
			this.init();
			this.props.dispatch(clinicalPathwayDetailsAction.clearGetDefinition());
		}
        
		return (            
			<div className="editor_no_edit">
				<div className="table_heading">{this.props.isCheckList ? <I18 tkey='DEFINITION OF THE CHECKLIST'/> : <I18 tkey='PATHWAY DEFINITION'/>}
				 {this.isPermissionGranted("EDIT") ?
						<button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "Pathway_Definition")}><I18 tkey="EDIT"/></button>
						: <span className="text_align_right edit_button_clp"></span>}   
				</div>                               
				<Editor
					readonly={true}
					toolbarHidden={true}
					wrapperClassName="wrapper-class"
					editorClassName="editor-class no_scroll_pathway width_100per_imp"
					toolbarClassName="toolbar-class"
					editorState={this.state.editorState}
				/>
				<div className="di w_100">
					<div className="text_align_right di w_100 margin-bottom-10">
					</div>
				</div>
			</div>
		);
	}
}

Definition.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations,
	permissions: store.permissions,
	clinicalPathway: store.clinicalPathway
}))(Definition));