import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import BreadCrumb from "../../../breadcrumb";
import TypeActivityDropdown from "../activity-families/type-activity-dropdown";
import { DebounceInput } from "react-debounce-input";
import SpecialityList from "./list-speciality";
import CreateSpecialty from "./create-specialty";
import EditSpecialty from "./edit-specialty";
import "./specialties.css";
import { Select } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import specialityActions from "../../../../../../../actions/specialty";
import { typeActivity } from "../../../../../../../constants";
import HospitalSelect from "./selectHospital";

const { Option } = Select;

class Specialities  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			specialtyData: {},
			typeActivity: "HEALTH_PATHWAY",
			showFilter: false,
			copyKey: 1,
			selectedStatus: "ALL",
			selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			filter: ""
		};
	}

	UNSAFE_componentWillMount() {
		if (utils.checkForParameter("typeActivity", this.props.location)) {
			this.state.typeActivity = utils.checkForParameter("typeActivity", this.props.location);
		}
	}
	onStatusChange = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};
	generateItems() {
		const items = [];
		items.push({ name: i18Get("Speciality", utils.getLanguage()), link: locations.specialities + `?typeActivity=${this.state.typeActivity}` });
		if (this.props.location.pathname === locations.specialities + "/create") {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.specialities + "/create?typeActivity=" + this.state.typeActivity  });
		}
		if (this.state.specialtyData && this.state.specialtyData.id) {
			items.push({ name: this.state.specialtyData.name, link: locations.specialities + `/${this.state.specialtyData.id}?typeActivity=${this.state.typeActivity}` });
		}
		return items;
	}

	addClicked() {
		this.props.history.push(locations.specialities + "/create?typeActivity=" + this.state.typeActivity );
	}

	copyClicked() {
		this.setState({ showCopyModal: !this.state.showCopyModal }, () => { 
			if (this.state.showCopyModal) { 
				this.setState({ copyKey: this.state.copyKey + 1 });
				const queryParams = `typeActivity=${this.state.typeActivity}`;
				this.props.dispatch(specialityActions.fetchAllUnassignedLocalMOM(queryParams)); 
			}
		});
	}

	getSortAndDesc(sort, desc) {
		this.state.sort = sort;
		this.state.desc = desc;
		this.setState({ ...this.state });
	}

	clearFilter() {
		this.setState({ ...this.state, selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) }, selectedStatus: "ALL", filter: "" });
	}

	getFilter() {
		return (
			<div className="row ">
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Status" />:</label>
						<Select 
							className="w_100"
							value={this.state.selectedStatus ? this.state.selectedStatus : <I18 tkey="All"/>}
							onChange={this.onStatusChange}
						>
							<Option key='All' value="ALL"><I18 tkey="All"/></Option>
							<Option key='Approved' value="APPROVED"><I18 tkey="Approved"/></Option>
							<Option key='Waiting For Approval' value="WAITING_FOR_APPROVAL"><I18 tkey="Waiting For Approval "/></Option>
						</Select>
					</div>
				</div>
				<div className="col-sm-4">
					<div className="form-group">
						<label><I18 tkey="Hospital" />:</label>
						<HospitalSelect 
							data={this.state.selectedHospital} 
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter });
	}

	onHospitalChanged(e) {
		this.setState({ selectedHospital: e ? e : "" });
	}

	showContent() {
		if (this.props.location.pathname === locations.specialities) {
			return true;    
		}
		return false;
	}
    
	render() {
		this.state.specialtyData = this.props.specialty.specialtyData;
		this.state.totalRecords = this.props.specialty.totalPages ? this.props.specialty.totalPages : 0;
		return (    
			<div className="main_overview_section">
				<div className="bloc_container">
					<BreadCrumb items={this.generateItems()} showRecord={this.showContent()} totalRecords={this.state.totalRecords}/>
					{this.showContent() && <div className="main_overview_top_section di w_100 padding-top-15">
						<div className="row">
							<div className="col-md-9 col-sm-8">
								{(this.state.typeActivity !== typeActivity.ACCOMMODATION.toUpperCase()) ?
									<div className="create_interventions margin-top-2">
										<button className="common_button" type="button" onClick={this.addClicked.bind(this)}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE" /></button>
									</div> : ""}
							</div>
						</div>
					</div>}
					<div className="di w_100">
                   
						<div className="tab_section tab_section_speciality padding-top-15">
							{
								this.props.location.pathname === locations.specialities ?
									<div>
										<div className="row margin-bottom-15">
											<div className="col-md-4">
												<TypeActivityDropdown typeActivitySelected={this.typeActivityChanged.bind(this)} typeActivity={this.state.typeActivity}/>
											</div>
											<div className="col-md-3"></div>
											<div className="col-md-4">
												<DebounceInput
													className="filter_input_design form-control"
													debounceTimeout={750}
													placeholder={i18Get("Search...", utils.getLanguage())}
													value={this.state.filter}
													onChange={this.filterChanged.bind(this)}/>
											</div>
											<div className="col-md-1 text_align_right">
												<div className="filter  margin-right-0" onClick={this.filterClicked.bind(this)}>
													<FilterOutlined  className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
												</div>
											</div>
										</div>
										{this.state.showFilter && this.getFilter()} 
									</div>
									: ""
							}
							<div className="tab-content">
								<div id="home" className="tab-pane fade in active">
									<Switch>
										<Route exact path={locations.specialities}>
											<SpecialityList typeActivity={this.state.typeActivity} filter={this.state.filter} status = {this.state.selectedStatus}  hospitalId = {this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "" } getSortAndDesc={this.getSortAndDesc.bind(this)}/>
										</Route>
										<Route exact path={locations.specialities + "/create"}>
											<CreateSpecialty typeActivity={this.state.typeActivity}/>
										</Route>
										<Route exact path={locations.editSpecialty}>
											<EditSpecialty typeActivity={this.state.typeActivity}/>
										</Route>
										<Redirect to={locations.specialities} /> 
									</Switch>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* <CopyModal key={this.state.copyKey} copyClicked={this.copyClicked.bind(this)} showCopyModal={this.state.showCopyModal} type="SPECIALITY"/> */}
			</div>   
		);
	}

	filterChanged(e) {
		const prevState = this.state;
		prevState.filter = e.target.value;
		this.setState(prevState);
	}

	typeActivityChanged(e) {
		const prevState = this.state;
		prevState.typeActivity = e;
		this.setState(prevState);
		this.props.history.push(this.props.location.pathname + `?typeActivity=${this.state.typeActivity}`);
	}
}

Specialities.propTypes = {
	specialty: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	specialty: store.specialty,
	translations: store.translations
}))(Specialities));                    