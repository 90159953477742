import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ExpandableLink from "../expandable-link";
import CardLink from "../card-link";
import "./index.css";

class ExpandedCard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1
		};
	}

	render() {
		const cardOptions = this.props.options;
		const data = cardOptions.contents;
		return (
			<div className={`color_box ${cardOptions.index !== 3 ? "color_box_open" : "color_box_open_right"}`}>
				<div className="box-items-flip h_100 w_100 display_inline_flex">
					<div className={`small_section color_boxbg${cardOptions.index + 1}`} onClick={this.props.onClick}>
						<img className="margin_auto" src={`images/${cardOptions.icon}`} />
					</div>
					<div className={`large_section dash_card w_100 color_bg_large ${cardOptions.index !== 3 ? "mr10p" : ""}`}>
						{
							data.map((cardLink) => this.renderContents(cardLink))
						}
					</div>
				</div>
			</div>
		);
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {

		if (cardLink.permission && this.props.permissions.userPermission && !this.props.permissions.userPermission[cardLink.permission]["READ"]) {

			return false;
		}
		if (this.props.user.loginData && this.props.user.loginData.scope == 64 && this.props.user.loginData.roleType === "PATHWAY_USER") {
			if (cardLink.name === "Clinical Pathways" || cardLink.name === "Health Pathways") {
				return <CardLink data={cardLink} />;
			}
			return false;
		}
		if (cardLink.expandable) {
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}
		return <CardLink data={cardLink} />;
	}
}

ExpandedCard.propTypes = {
	user: PropTypes.func.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	translations: store.translations,
	permissions: store.permissions
}))(ExpandedCard));
