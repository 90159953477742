import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
	Modal, Button
} from "antd";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import "../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import interventionAction from "../../../../../../../actions/intervention";

class InterventionEditModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			interventionName: "",
			invalidName: false,
			interventionDetails: {},
			treatmentId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(interventionAction.getOneIntervention(this.props.treatmentId, new Date().getTimezoneOffset()));
	}

	cancelClicked() {
		this.setState({
			modelVisible: false, invalidName: false, invalidCode: false, invalidFamily: false
		});
		this.props.refreshCompleted();
	}

	validateFields() {
		const prevState = this.state;
		if (!(this.state.interventionName.trim())) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		let payload = {};
		if (this.validateFields()) {
			payload = {
				name: this.state.interventionName
			};
			this.props.dispatch(interventionAction.editIntervention(this.state.interventionDetails.id, payload));
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.interventionName = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.treatmentId !== this.state.treatmentId) {
			this.state.treatmentId = this.props.treatmentId;
			this.props.dispatch(interventionAction.getOneIntervention(this.props.treatmentId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		if (this.props.intervention.editInterventionStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, interventionName: "" });
			this.props.dispatch(interventionAction.clearEditIntervention());
			this.props.refreshCompleted();
		}
		if (this.props.intervention.getOneIntervention === "FULFILLED") {
			this.state.interventionDetails = this.props.intervention.treatment ? this.props.intervention.treatment : "";
			this.state.interventionName = this.state.interventionDetails.name ? this.state.interventionDetails.name : "";
			this.props.dispatch(interventionAction.clearGetOneIntervention());
		}
		if (this.props.intervention.getOneIntervention === "REJECTED") {
			this.props.dispatch(interventionAction.clearGetOneIntervention());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Intervention", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
              Cancel
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
              Save
						</Button>
					]}
				>
					<div className="position_relative">
						<div className="form-group ">
							<label className="bold">
								<I18 tkey="Rename Intervention" />
                :
							</label>
							<NameInput
								value={this.state.interventionName}
								inputChanged={this.nameChanged.bind(this)}
								maxLength={540}
							/>
							{this.state.invalidName && <span className="invalid text-danger"><I18 tkey="Enter valid intervention name" /></span>}
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

InterventionEditModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	intervention: store.intervention
}))(InterventionEditModel));
