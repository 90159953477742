import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	communitySectionCreate: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.CREATE_COMMUNITY_SECTION}_FULFILLED`:
			return {
				...state,
				communitySection: action.payload.data.data,
				communitySectionCreate: "FULFILLED"
			};
		case `${eventNames.CREATE_COMMUNITY_SECTION}_REJECTED`:
			return {
				...state,
				communitySectionCreate: "REJECTED"
			};
		case `${eventNames.CLEAR_CREATE_COMMUNITY_SECTION}`:
			return {
				...state,
				communitySectionCreate: false
			};

		case `${eventNames.COMMUNITY_SECTION_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllCommunitySectionCompleted: "REJECTED",
				fetchAllElementClassMeta: null
			};

		case `${eventNames.COMMUNITY_SECTION_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					count: action.payload.data.data.count,
					communitySection: action.payload.data.data.data,
					isFetchAllCommunitySectionCompleted: "FULFILLED",
					fetchAllElementClassMeta: action.meta
				};
			}
			return {
				...state,
				communitySection: [],
				isFetchAllCommunitySectionCompleted: "FULFILLED",
				fetchAllElementClassMeta: null
			};
		case eventNames.CLEAR_COMMUNITY_SECTION_FETCH:
			return {
				...state,
				isFetchAllCommunitySectionCompleted: false,
				fetchAllElementClassMeta: null
			};
		case `${eventNames.DELETE_COMMUNITY_SECTION}_REJECTED`:
			return {
				...state,
				deleteCommunitySectionComplete: "REJECTED"
			};

		case `${eventNames.DELETE_COMMUNITY_SECTION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteCommunitySectionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteCommunitySectionComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_COMMUNITY_SECTION:
			return {
				...state,
				deleteCommunitySectionComplete: false
			};

		case `${eventNames.COMMUNITY_SECTION_GET_ONE}_PENDING`:
			return {
				...state,
				fetchOneCommunitySectionComplete: "PENDING"
			};
		case `${eventNames.COMMUNITY_SECTION_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchOneCommunitySectionComplete: "FULFILLED",
					communitySection: action.payload.data.data
				};
			}
			return {
				...state,
				fetchOneCommunitySectionComplete: "FULFILLED"
			};
		case `${eventNames.COMMUNITY_SECTION_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneCommunitySectionComplete: "REJECTED"
			};
		case eventNames.CLEAR_COMMUNITY_SECTION_GET_ONE:
			return {
				...state,
				fetchOneCommunitySectionComplete: false
			};

		case `${eventNames.UPDATE_COMMUNITY_SECTION}_PENDING`:
			return {
				...state,
				updateCommunitySectionComplete: "PENDING"
			};
		case `${eventNames.UPDATE_COMMUNITY_SECTION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateCommunitySectionComplete: "FULFILLED",
					updatedCommunitySection: action.payload.data.data
				};
			}
			return {
				...state,
				updateCommunitySectionComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_COMMUNITY_SECTION}_REJECTED`:
			return {
				...state,
				updateCommunitySectionComplete: "REJECTED"
			};
		case eventNames.UPDATE_COMMUNITY_SECTION_COMPLETED:
			return {
				...state,
				updateCommunitySectionComplete: false
			};
		case eventNames.FILTER_CHANGE_COMMUNITY_SECTION:
			return {
				...state,
				filterChangedCommunitySection: action.payload.filter,
				ignorePageChangeCommunitySection: action.payload.ignorePageChange
			};
		default:
			return state;
	}
}
