import React from "react";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../../constants";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
function getText(value) {
	return <div>{value}</div>;
}

export default function column(editClicked, deleteClicked) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto">
					<div className="min_width_12_px">
						{row.value.status === statusList.STATUS.values.APPROVED
							? <EditOutlined className="edit_color margin-right-10" type="edit" onClick={editClicked(row.value)} />
							: ""}
					</div>
					{row.value.status === statusList.STATUS.values.APPROVED
						? <DeleteOutlined className="delete_color margin-right-10" type="delete" onClick={deleteClicked(row.value)} />
						: <div className="min_width_12_px"> </div>}
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			maxWidth: 130,
			resizable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("SHORT NAME", utils.getLanguage()),
			id: "shortName",
			accessor: (d) => d.shortName,
			maxWidth: 130,
			resizable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("NAME OF THE HOSPITAL", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		}
	]);
	return tableArray;
}
