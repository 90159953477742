import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	clinicalPathway: [],
	fetchAllClinicalPathway: false,
	updateClinicalPathway: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_CLP_PROBLEM}_FULFILLED`:
			return {
				...state,
				problemList: action.payload.data.data,
				fetchAllClinicalPathwayProblemList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CLP_PROBLEM}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayProblemList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CLP_PROBLEM:
			return {
				...state,
				fetchAllClinicalPathwayProblemList: false
			};

		case `${eventNames.GET_ONE_CLP_PROBLEM}_FULFILLED`:
			return {
				...state,
				problemDetails: action.payload.data.data,
				getOneClpProblem: "FULFILLED"
			};
		case `${eventNames.GET_ONE_CLP_PROBLEM}_REJECTED`:
			return {
				...state,
				getOneClpProblem: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_CLP_PROBLEM:
			return {
				...state,
				getOneClpProblem: false
			};

		case `${eventNames.CLP_PROBLEM_UPDATE}_FULFILLED`:
			return { ...state, editClpProblemStatus: "FULFILLED" };
		case `${eventNames.CLP_PROBLEM_UPDATE}_REJECTED`:
			return { ...state, editClpProblemStatus: "REJECTED" };
		case eventNames.CLEAR_CLP_PROBLEM_UPDATE:
			return { ...state, editClpProblemStatus: false };

		case `${eventNames.CLP_PROBLEM_CREATE}_FULFILLED`:
			return {
				...state,
				createdClpProblem: action.payload.data.data,
				createClpProblemStatus: "FULFILLED"
			};
		case `${eventNames.CLP_PROBLEM_CREATE}_REJECTED`:
			return {
				...state,
				createClpProblemStatus: "REJECTED"
			};
		case eventNames.CLEAR_CLP_PROBLEM_CREATE:
			return {
				...state,
				createdClpProblem: [],
				createClpProblemStatus: false
			};

		case `${eventNames.DELETE_CLP_PROBLEM}_FULFILLED`:
			return { ...state, deleteClpProblemStatus: "FULFILLED", attachedModelProblem: action.payload.data.data };
		case `${eventNames.DELETE_CLP_PROBLEM}_REJECTED`:
			return { ...state, deleteClpProblemStatus: "REJECTED" };
		case eventNames.DELETE_CLP_PROBLEM_COMPLETED:
			return { ...state, deleteClpProblemStatus: false };

		case `${eventNames.GET_ALL_CLP_SYNTHESIS}_FULFILLED`:
			return {
				...state,
				synthesisList: action.payload.data.data,
				fetchAllClinicalPathwaySynthesisList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CLP_SYNTHESIS}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwaySynthesisList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CLP_SYNTHESIS:
			return {
				...state,
				synthesisList: [],
				fetchAllClinicalPathwaySynthesisList: false
			};

		case `${eventNames.GET_ALL_MINIMAL_CLP_PROBLEM}_FULFILLED`:
			return {
				...state,
				problemList: action.payload.data.data,
				fetchAllMinimalClinicalPathwayProblemList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_MINIMAL_CLP_PROBLEM}_REJECTED`:
			return {
				...state,
				fetchAllMinimalClinicalPathwayProblemList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_MINIMAL_CLP_PROBLEM:
			return {
				...state,
				problemList: [],
				fetchAllMinimalClinicalPathwayProblemList: false
			};

		case `${eventNames.ADDITIONAL_INFO_CREATE}_FULFILLED`:
			return {
				...state,
				createdAdditionalProblem: action.payload.data.data,
				createAdditionalProblemStatus: "FULFILLED"
			};
		case `${eventNames.ADDITIONAL_INFO_CREATE}_REJECTED`:
			return {
				...state,
				createAdditionalProblemStatus: "REJECTED"
			};
		case eventNames.CLEAR_ADDITIONAL_INFO_CREATE:
			return {
				...state,
				createdAdditionalProblem: [],
				createAdditionalProblemStatus: false
			};

		case `${eventNames.GET_ONE_ADDITIONAL_INFO}_FULFILLED`:
			return {
				...state,
				additionalInfoDetails: action.payload.data.data,
				getOneAdditionalInfo: "FULFILLED"
			};
		case `${eventNames.GET_ONE_ADDITIONAL_INFO}_REJECTED`:
			return {
				...state,
				getOneAdditionalInfo: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_ADDITIONAL_INFO:
			return {
				...state,
				getOneAdditionalInfo: false
			};

		case `${eventNames.UPDATE_ADDITIONAL_INFO}_FULFILLED`:
			return { ...state, editAdditionalProblemStatus: "FULFILLED" };
		case `${eventNames.UPDATE_ADDITIONAL_INFO}_REJECTED`:
			return { ...state, editAdditionalProblemStatus: "REJECTED" };
		case eventNames.CLEAR_UPDATE_ADDITIONAL_INFO:
			return { ...state, editAdditionalProblemStatus: false };

		case `${eventNames.DELETE_ADDITIONAL_INFO}_FULFILLED`:
			return { ...state, deleteAdditionalInfoStatus: "FULFILLED", modelProblemTaskStatus: action.payload.data.data, meta: action.meta };
		case `${eventNames.DELETE_ADDITIONAL_INFO}_REJECTED`:
			return { ...state, deleteAdditionalInfoStatus: "REJECTED" };
		case eventNames.DELETE_ADDITIONAL_INFO_COMPLETED:
			return { ...state, deleteAdditionalInfoStatus: false };

		case `${eventNames.UPDATE_CLP_PROBLEMS_RANK}_FULFILLED`:
			return { ...state, updateClpProblemsRankStatus: "FULFILLED" };
		case `${eventNames.UPDATE_CLP_PROBLEMS_RANK}_REJECTED`:
			return { ...state, updateClpProblemsRankStatus: "REJECTED" };
		case eventNames.CLEAR_UPDATE_CLP_PROBLEMS_RANK:
			return { ...state, updateClpProblemsRankStatus: false };

		case `${eventNames.GET_ALL_STEPS_AND_SUB_STEPS}_FULFILLED`:
			return {
				...state,
				getAllStepsAndSubStepsStatus: "FULFILLED",
				stepsAndSubSteps: action.payload.data.data
			};
		case `${eventNames.GET_ALL_STEPS_AND_SUB_STEPS}_REJECTED`:
			return { ...state, getAllStepsAndSubStepsStatus: "REJECTED" };
		case eventNames.CLEAR_GET_ALL_STEPS_AND_SUB_STEPS:
			return { ...state, stepsAndSubSteps: [], getAllStepsAndSubStepsStatus: false };

		case `${eventNames.GET_ALL_PROBLEM_TYPS}_FULFILLED`:
			return {
				...state,
				getAllProblemTypeStatus: "FULFILLED",
				meta: action.meta,
				problemTypeList: action.payload.data.data
			};
		case `${eventNames.GET_ALL_PROBLEM_TYPS}_REJECTED`:
			return { ...state, getAllProblemTypeStatus: "REJECTED" };
		case eventNames.CLEAR_GET_ALL_PROBLEM_TYPS:
			return { ...state, problemTypeList: [], getAllProblemTypeStatus: false };
		case `${eventNames.GET_ALL_CLP_PROBLEM_COPY}_FULFILLED`:
			return {
				...state,
				problemList: action.payload.data.data,
				fetchAllClinicalPathwayProblemListCopy: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CLP_PROBLEM_COPY}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayProblemListCopy: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CLP_PROBLEM_COPY:
			return {
				...state,
				fetchAllClinicalPathwayProblemListCopy: false
			};
		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS}_FULFILLED`:
			return {
				...state,
				problemCopy: action.payload.data.data,
				copyFromClinicalPathwayProblems: "FULFILLED"
			};
		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS}_REJECTED`:
			return {
				...state,
				copyFromClinicalPathwayProblems: "REJECTED"
			};

		case eventNames.CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS:
			return {
				...state,
				copyFromClinicalPathwayProblems: false
			};

		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS}_FULFILLED`:
			return {
				...state,
				problemCopyStatus: action.payload.data.data,
				copyFromClinicalPathwayProblemsStatus: "FULFILLED"
			};
		case `${eventNames.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS}_REJECTED`:
			return {
				...state,
				copyFromClinicalPathwayProblemsStatus: "REJECTED"
			};

		case eventNames.CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS:
			return {
				...state,
				copyFromClinicalPathwayProblemsStatus: false
			};

		case `${eventNames.GET_ALL_CLP_PROBLEM_REORDER}_FULFILLED`:
			return {
				...state,
				problemList: action.payload.data.data,
				getAllClpProblemListForReorder: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CLP_PROBLEM_REORDER}_REJECTED`:
			return {
				...state,
				getAllClpProblemListForReorder: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CLP_PROBLEM_REORDER:
			return {
				...state,
				getAllClpProblemListForReorder: false
			};

		case `${eventNames.LINK_CHECK_LIST}_FULFILLED`:
			return {
				...state,
				linkCheckListProblems: "FULFILLED"
			};
		case `${eventNames.LINK_CHECK_LIST}_REJECTED`:
			return {
				...state,
				linkCheckListProblems: "REJECTED"
			};

		case eventNames.CLEAR_LINK_CHECK_LIST:
			return {
				...state,
				linkCheckListProblems: false
			};

		case `${eventNames.GET_ALL_CHECK_LIST}_FULFILLED`:
			return {
				...state,
				checkList: action.payload.data.data,
				fetchAllCheckList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CHECK_LIST}_REJECTED`:
			return {
				...state,
				fetchAllCheckList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CHECK_LIST:
			return {
				...state,
				fetchAllCheckList: false
			};

		case `${eventNames.UNLINK_CHECK_LIST}_FULFILLED`:
			return { ...state, unlinkCheckListStatus: "FULFILLED" };
		case `${eventNames.UNLINK_CHECK_LIST}_REJECTED`:
			return { ...state, unlinkCheckListStatus: "REJECTED" };
		case eventNames.UNLINK_CHECK_LIST_COMPLETED:
			return { ...state, unlinkCheckListStatus: false };

		case `${eventNames.REPLACED_BY_CHECK_LIST}_FULFILLED`:
			return { ...state, replaceByCheckListStatus: "FULFILLED" };
		case `${eventNames.REPLACED_BY_CHECK_LIST}_REJECTED`:
			return { ...state, replaceByCheckListStatus: "REJECTED" };
		case eventNames.CLEAR_REPLACED_BY_CHECK_LIST:
			return { ...state, replaceByCheckListStatus: false };

		case `${eventNames.DELETE_MODEL_PROBLEM_OBSERVER}_FULFILLED`:
			return { ...state, modelProblemDeleteStatus: "FULFILLED", modelProblemStatus: action.payload.data.data, meta: action.meta };
		case `${eventNames.DELETE_MODEL_PROBLEM_OBSERVER}_REJECTED`:
			return { ...state, modelProblemDeleteStatus: "REJECTED" };
		case eventNames.CLEAR_DELETE_MODEL_PROBLEM_OBSERVER:
			return { ...state, modelProblemDeleteStatus: false };

		case `${eventNames.GET_CLP_PROBLEM_LIST_FOR_COMPARISON}_FULFILLED`:
			return { ...state, getProblemListForComparisonStatus: "FULFILLED", problemList: action.payload.data.data };
		case `${eventNames.GET_CLP_PROBLEM_LIST_FOR_COMPARISON}_REJECTED`:
			return { ...state, getProblemListForComparisonStatus: "REJECTED" };
		case eventNames.CLEAR_GET_CLP_PROBLEM_LIST_FOR_COMPARISON:
			return { ...state, getProblemListForComparisonStatus: false };

		case `${eventNames.UPDATE_LINKED_MODEL_RANK}_FULFILLED`:
			return { ...state, updateLinkedModelRankStatus: "FULFILLED", updateLinkedModelRankCode: action.payload.data.data };
		case `${eventNames.UPDATE_LINKED_MODEL_RANK}_REJECTED`:
			return { ...state, updateLinkedModelRankStatus: "REJECTED" };
		case eventNames.UPDATE_LINKED_MODEL_RANK_COMPLETED:
			return { ...state, updateLinkedModelRankStatus: false };

		case `${eventNames.UPDATE_LINKED_MODEL_RANK_STATUS}_FULFILLED`:
			return { ...state, linkedModelRankStatus: "FULFILLED",  clpReoderStatus: action.payload.data.data };
		case `${eventNames.UPDATE_LINKED_MODEL_RANK_STATUS}_REJECTED`:
			return { ...state, linkedModelRankStatus: "REJECTED", clpReoderStatus: action.payload.data.data };
		case eventNames.UPDATE_LINKED_MODEL_RANK_STATUS_COMPLETED:
			return { ...state, linkedModelRankStatus: false };

		default:
			return state;
	}
}
