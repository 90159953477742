import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import ActionButton from "../../../../../../../../../plug/action-button";
import clinicalPathwayDetailsAction from "../../../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../../../utils/utils";
import eventNames from "../../../../../../../../../actions/events";
import { Steps, Modal, Checkbox } from "antd";
import Loader from "../../../../../../../../../plug/loader";
import ShowOrHideSteps from "../../../copyfromclinicalpathway/show-or-hide-steps";
import ActiveComponents from "../../../copyfromclinicalpathway/active-components";
import DragWindow from "../../../drag-window";

const Step = Steps.Step;
class CopySubStepModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			selectedSubStepForCopy: {},
			activeComponents: [],
			checkList: [],
			stepLoader: true,
			checkAll: false,
			copyLoading: false,
			replaceNew: false,
			closable: true
		};
	}

	UNSAFE_componentWillMount() {
		this.state.selectedSubStepForCopy = JSON.parse(JSON.stringify(this.props.selectedSubStepForCopy));
		// this.state.selectedSubStepForCopy.data = this.state.selectedSubStepForCopy && this.state.selectedSubStepForCopy.data.filter(e => !e.isCheckList);
		this.fetchList();
	}

	fetchList() {
		this.setState({ isListLoading: true });
		const queryString = this.state.selectedSubStepForCopy && this.state.selectedSubStepForCopy.day ? `day=${this.state.selectedSubStepForCopy.day}` : "";
		this.props.dispatch(clinicalPathwayDetailsAction.fetchCopyToSubStepList(this.props.match.params.id, this.state.selectedSubStepForCopy.stepId, this.state.selectedSubStepForCopy.id, queryString));
	}

	prevClicked() {
		this.setState({ activeStep: this.state.activeStep - 1 });
	}

	nextClicked() {
		if (this.checkActiveComponents()) {
			this.setState({ activeStep: this.state.activeStep + 1 });
		}
	}

	checkActiveComponents() {
		const checkedDataList = this.state.checkList.filter(value => value.checked === true);
		if (checkedDataList && checkedDataList.length) {
			this.state.invalidComponents = false;
		} else {
			this.state.invalidComponents = true;
		}
		if (this.state.invalidComponents) {
			this.setState(this.state);
			return false;
		}
		return true;
	}

	getSelectedComponents(checkList) {
		this.setState({ checkList: checkList, invalidComponents: false, checkAll: false });
	}

	getSubStepDetails() {
		if (this.state.steps && this.state.steps.length) {
			return (<ShowOrHideSteps getSelectedComponents={this.getSelectedComponents.bind(this)} steps={this.state.steps} checkList={this.state.checkList} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	getActiveComponents() {
		const checkActiveComponents = this.state.checkList.filter(value => value.checked === true);
		if (this.state.checkList && this.state.checkList && checkActiveComponents && checkActiveComponents.length) {
			return (<ActiveComponents checkList={this.state.checkList} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	onCheckBoxChange(e) {
		this.setState({ replaceNew: e.target.checked ? e.target.checked : false });
	}

	OkClicked() {
		this.toggleModal();
		this.props.refreshClinicalPathwayDetail(true);
	}

	renderTitle = () => {
		return (
			<div className="ant-sub-steps">
				<div className="margin-right-5 sub-step-bold-text"><I18 tkey="Source Sub Step: " /> </div>
				<div className="margin-right-5"><I18 tkey={this.state.selectedSubStepForCopy.stepName} /> </div>
				<div className="margin-right-5">
					{this.state.selectedSubStepForCopy && this.state.selectedSubStepForCopy.startPrefix !== "" && this.state.selectedSubStepForCopy.startPrefix !== this.state.selectedSubStepForCopy.endPrefix ?
						<span>(<I18 tkey="DAY" /> <I18 tkey={this.state.selectedSubStepForCopy.startPrefix} /> {"->"} <I18 tkey="DAY" /> <I18 tkey={this.state.selectedSubStepForCopy.endPrefix} />): <I18 tkey={this.state.selectedSubStepForCopy.totalPrefixCount} /> <I18 tkey="DAYS" /></span>
						:
						<span>(<I18 tkey="DAY" /> <I18 tkey={this.state.selectedSubStepForCopy.startPrefix} />)</span>}
					{" -> "}
				</div>
				<div className="margin-right-5">
					{this.state.selectedSubStepForCopy && this.state.selectedSubStepForCopy.day ?
						<span><I18 tkey="DAY" /> <I18 tkey={this.state.selectedSubStepForCopy.name} /></span>
						: <I18 tkey={this.state.selectedSubStepForCopy.name} />}
				</div>
			</div>
		);
	};
	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Copy Tasks to Sub Steps", utils.getLanguage())} /> </div>); };
	render() {
		const directionProp = this.state.isMobile ? { direction: "vertical" } : "";
		return (
			<div>
				<Modal
					closable={this.state.closable}
					visible={this.props.visible}
					className="cost_modal re_assign_modal"
					title={this.title()}
					wrapClassName="drag_modal modal_mini_height_auto"
					onCancel={this.state.closable ? this.props.cancelClicked : ""}
					footer={null}>
					{!this.state.summary ?
						<div>
							<Steps className="" {...directionProp} progressDot current={this.state.activeStep}>
								<Step key="source" title={this.renderTitle()} />
								<Step key="summary" title={i18Get("Summary", utils.getLanguage())} />
							</Steps>
							{this.state.activeStep === 0 && (
								<div className="steps-content padding-15">
									<div className="component_heading  di w_100">
										<I18 tkey="Destination Sub Steps" />
									</div>
									<div className="position_relative text-align-right">
										{this.state.invalidComponents && (
											<span className=" fixed_label">
												<I18 tkey="Select Destination Sub Step" />
											</span>
										)}
									</div>
									<div className="copy_scroll position_relative">
										<div className="margin-top-15 position_relative ">
											{this.getSubStepDetails()}
										</div>
									</div>
								</div>
							)}
							{this.state.activeStep === 1 && <div>{this.getActiveData()}</div>}
							<div className="d-flex justify-content-between">
								{this.state.steps && this.state.steps.length && this.state.activeStep === 0 ?
									<div className="margin-top-10">
										<Checkbox
											checked={this.state.replaceNew}
											onChange={(e) => this.onCheckBoxChange(e)}
										/>
										<span className="margin-left-10">
											<I18 tkey="Replace with new if already exist" />
										</span>
									</div>
									: ""}
								<div></div>
								<div className="d-flex  text_align_right margin-top-7">
									<div>
										{this.state.copyLoading &&
											<div className="padding-bottom-10 copy_label">
												<I18 tkey="Copy sub steps will take more time. You will not be able to close this popup" />
											</div>}

										{this.state.activeStep === 0 &&
											<ActionButton
												type="primary"
												className=""
												onClick={this.nextClicked.bind(this)}
												value={i18Get("Next")}
											/>
										}
										<div className="copy_btn_modal">
											{this.state.activeStep > 0 &&
												<ActionButton
													type="primary"
													className="margin-right-10"
													onClick={this.prevClicked.bind(this)}
													value={i18Get("Prev")}
												/>
											}
											{this.state.activeStep === 1 &&
												<ActionButton
													type="primary"
													className=""
													onClick={this.doneClicked.bind(this)}
													value={i18Get("Copy", utils.getLanguage())}
												/>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
						:
						<div className="d-flex justify-content-between margin-bottom-15 margin-top-15">
							<div>
								{this.state.summaryDetails && this.state.summaryDetails.insertCount > 0 && this.state.summaryDetails.replaceCount === 0 && this.state.summaryDetails.ignoreCount === 0 &&
									<label className="bold" ><I18 tkey="Everything has been copied" /></label>
								}
								{this.state.summaryDetails && this.state.summaryDetails.replaceCount > 0 && this.state.summaryDetails.insertCount === 0 && this.state.summaryDetails.ignoreCount === 0 &&
									<label className="bold" ><I18 tkey="Everything has been copied" /></label>
								}
								{this.state.summaryDetails && this.state.summaryDetails.ignoreCount > 0 && this.state.summaryDetails.insertCount > 0 && this.state.summaryDetails.replaceCount === 0 &&
									<label className="bold" >{this.state.summaryDetails.ignoreCount} <I18 tkey='Items has not been copied because they were already present' /></label>
								}
								{this.state.summaryDetails && this.state.summaryDetails.replaceCount > 0 && this.state.summaryDetails.insertCount > 0 && this.state.summaryDetails.ignoreCount === 0 &&
									<label className="bold" >{this.state.summaryDetails.replaceCount} <I18 tkey='Items has not been copied because they were already present' /></label>
								}
								{this.state.summaryDetails && this.state.summaryDetails.ignoreCount > 0 && this.state.summaryDetails.insertCount === 0 && this.state.summaryDetails.replaceCount === 0 &&
									<label className="bold" ><I18 tkey="Nothing has been copied" /></label>
								}
							</div>
							<ActionButton
								type="primary"
								className="margin-right-10"
								onClick={this.OkClicked.bind(this)}
								value={i18Get("Ok", utils.getLanguage())}
							/>
						</div>
					}
				</Modal>
			</div>
		);
	}

	componentDidUpdate() {
		if (this.props.clinicalPathwayDetails.fetchCopyToSubStepList === "FULFILLED") {
			this.state.steps = [];
			const prevState = this.state.selectedSubStepForCopy;
			this.state.steps = this.props.clinicalPathwayDetails.copyToSubStepList ? this.props.clinicalPathwayDetails.copyToSubStepList : [];
			this.state.steps && this.state.steps.map(function (data) {
				const index = data.subSteps.findIndex(value => value.id === prevState.id && value.day === prevState.day);
				if (index !== -1) {
					data.stepItemsCount = parseInt(data.stepItemsCount) - parseInt(data.subSteps[index].itemCount);
					data.subSteps.splice(index, 1);
				}
			});
			const index = this.state.steps && this.state.steps.findIndex(data => !data.subSteps.length);
			if (index !== -1) {
				this.state.steps.splice(index, 1);
			}
			this.state.checkList = this.state.steps ? this.state.steps : [];
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyToSubStepList());
			this.setState({ stepLoader: false, invalidComponents: false, checkAll: false });
		}
		if (this.props.clinicalPathwayDetails.copySubStep == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.subStepCopyCompleted());
			if (this.props.clinicalPathwayDetails.subStepCopy && this.props.clinicalPathwayDetails.subStepCopy.code) {
				this.setState({ code: this.props.clinicalPathwayDetails.subStepCopy.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayDetails.copySubStep == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.subStepCopyCompleted());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayDetails.subStepCopyStatus == "FULFILLED") {
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayDetails.subStepCopyStage && this.props.clinicalPathwayDetails.subStepCopyStage.status) {
				if (this.props.clinicalPathwayDetails.subStepCopyStage.status == "PROCESSED") {
					this.setState({ summary: true, closable: false, summaryDetails: this.props.clinicalPathwayDetails.subStepCopyStage });
					utils.showSuccess(i18Get("Sub step copy completed successfully", utils.getLanguage()));
				} else if (!this.isSubStepCopyCompleted(this.props.clinicalPathwayDetails.subStepCopyStage.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}
			this.props.dispatch(clinicalPathwayDetailsAction.checkSubStepCopyStatusCompleted());
		}

		if (this.props.clinicalPathwayDetails.subStepCopyStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.checkSubStepCopyStatusCompleted());
			this.setState({ loading: false });
		}

	}

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayDetailsAction.checkSubStepCopyStatus(this.state.code));
			}.bind(this), 3000);
		}
	}

	isSubStepCopyCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while copying sub step", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	toggleModal = () => {
		this.setState({ copyLoading: false, activeStep: 0, replaceNew: false });
		if (this.props.actions.actionInProgress) {
			this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		}
		this.props.cancelClicked();
	};

	getActiveData() {
		return (
			<div>
				<div className="steps-content padding-15">
					<div className="row">
						<div className="col-md-3 margin-bottom-10"><div className="component_heading  di w_100 margin-bottom-10"><I18 tkey='Destination Sub Steps' /></div></div>
						<div className="col-md-12 copy_scroll">
							<div className="col-md-12">
								<div className="">{this.getActiveComponents()}</div>
							</div>
						</div>
					</div>
				</div>
			</div>);
	}

	doneClicked() {
		const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
		const list = checkedDataList.filter(value => value.checked === true);
		list.map(function (e) {
			if (e.checked) {
				delete e["checked"];
			}
			if (e.stepName) {
				delete e["stepName"];
			}
			e.subSteps = e.subSteps.filter(value => value.checked === true);
			e.subSteps.map(function (data) {
				if (data.checked) {
					delete data["checked"];
				}
				if (data.name) {
					delete data["name"];
				}
			});
		});
		this.setState({ copyLoading: true, loading: true });
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			components: list,
			isReplaceAll: this.state.replaceNew,
			day: this.state.selectedSubStepForCopy.day ? this.state.selectedSubStepForCopy.day : ""
		};
		this.props.dispatch(clinicalPathwayDetailsAction.subStepCopy(this.props.match.params.id, this.state.selectedSubStepForCopy.stepId, this.state.selectedSubStepForCopy.id, payload));
	}
}

CopySubStepModel.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(CopySubStepModel));