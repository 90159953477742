import { Input, Select, Tooltip } from "antd";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import React, { useState, useEffect, memo } from "react";
import utilslFuntion from "../../../plugin/utils";

const options = [
	{ label: i18Get("Minutes", utils.getLanguage()), value: "Minutes" },
	{ label: i18Get("Hours", utils.getLanguage()), value: "Hours" },
	{ label: i18Get("Days", utils.getLanguage()), value: "Days" }
];
let timer;
const durationkeys = ["duration", "durationType", "durationMinimum", "durationMaximum", "durationMaximumType", "durationMinimumType"];
const Duration = ({ data, onChange, disabled, onValid }) => {
	const [customData, setCustomData] = useState({});
	const [error, setError] = useState(false);

	useEffect(() => {
		if (!Object.keys(customData).length && !isCustomDatavalid(customData, getDurationData(data))) {
		    setCustomData(getDurationData(data));
		}
	}, [data]);
	useEffect(() => {
		if (Object.keys(customData).length && !isCustomDatavalid(customData, getDurationData(data))) {
			timer = setTimeout(() => changeData(customData), 500);
		}
	}, [customData]);
	const getDurationData = (values) => {
		const val = {};
		durationkeys.forEach(d => val[d] = values[d]);
		return val;
	};
	useEffect(() =>{
		if(onValid) {
			onValid(error);
		}
	}, [error]);
	const changeData = (values) => {
		let valid = true;
		const defaultType = "Minutes";
		values.durationMaximumType = values.durationMaximumType || defaultType;
		values.durationMinimumType = values.durationMinimumType || defaultType;
		values.durationType = values.durationType || defaultType;
		values.duration = parseFloat(values.duration) || 0
		data.durationMaximumInMinutes = parseFloat(data.durationMaximumInMinutes);
		data.durationMinimumInMinutes = parseFloat(data.durationMinimumInMinutes);

		Object.keys(values).forEach(p => {
			if (values[p] != data[p]) {
				
				switch (p) {
					case "duration" :
					case "durationType": {
						const value = parseFloat(values.duration || 0);
						const dataInMinutes = utilslFuntion.convertMaxAndMinDurationToMinutes(value, values.durationType || defaultType);

						if ((!data.durationMinimumInMinutes && !data.durationMaximumInMinutes) || (!dataInMinutes && data.durationMaximumInMinutes > data.durationMinimumInMinutes)) {
							onChange(p === "durationType" ? values[p] : value, p);
            			} else if (
							data.durationMinimumInMinutes && data.durationMaximumInMinutes && 
							(dataInMinutes != data.durationMaximumInMinutes ||
							 dataInMinutes != data.durationMinimumInMinutes) &&
							data.durationMinimumInMinutes <= dataInMinutes && 
							data.durationMaximumInMinutes >= dataInMinutes) {
							onChange(p === "durationType" ? values[p] : value, p);
						} else if (!data.durationMaximumInMinutes && data.durationMinimumInMinutes && data.durationMinimumInMinutes <= dataInMinutes) {
							onChange(p === "durationType" ? values[p] : value, p);
						} else if (!data.durationMinimumInMinutes && data.durationMaximumInMinutes && data.durationMaximumInMinutes >= dataInMinutes) {
							onChange(p === "durationType" ? values[p] : value, p);
						} else {
							onChange(p === "durationType" ? values[p] : value, p);
							valid = false;
						}
						break;
					}
					case "durationMinimum": 
					case "durationMinimumType": {
						const value = parseFloat(values.durationMinimum || 0);
						const dataInMinutes = utilslFuntion.convertMaxAndMinDurationToMinutes(value, values.durationMinimumType || defaultType);
						if ((!data.durationMaximumInMinutes && !data.durationInMinutes) || (!values[p] && data.durationMaximumInMinutes >= data.durationInMinutes)) {
							onChange(p === "durationMinimumType" ? values[p] : value, p);
						} else if (data.durationMaximumInMinutes && data.durationInMinutes && data.durationMaximumInMinutes > dataInMinutes && data.durationInMinutes >= dataInMinutes && data.durationMaximumInMinutes >= data.durationInMinutes) {
							onChange(p === "durationMinimumType" ? values[p] : value, p);
						} else if (!data.durationInMinutes && data.durationMaximumInMinutes && data.durationMaximumInMinutes > dataInMinutes) {
							onChange(p === "durationMinimumType" ? values[p] : value, p);
						} else if (!data.durationMaximumInMinutes && data.durationInMinutes && data.durationInMinutes >= dataInMinutes) {
							onChange(p === "durationMinimumType" ? values[p] : value, p);
						} else {
							onChange(p === "durationMinimumType" ? values[p] : value, p);
							valid = false;
						}
						break;
					}
					case "durationMaximum":
					case "durationMaximumType": {

						const value = parseFloat(values.durationMaximum || 0);
						const dataInMinutes = utilslFuntion.convertMaxAndMinDurationToMinutes(value, values.durationMaximumType || defaultType);
						if ((!data.durationMinimumInMinutes && !data.durationInMinutes) || (!dataInMinutes && data.durationMinimumInMinutes <= data.durationInMinutes)) {
							onChange(p === "durationMaximumType" ? values[p] : value, p);
						} else if (data.durationMinimumInMinutes && data.durationInMinutes && data.durationMinimumInMinutes < dataInMinutes && data.durationInMinutes <= dataInMinutes && data.durationMinimumInMinutes <= data.durationInMinutes) {
							console.log(data.durationMinimumInMinutes, "&&", data.durationInMinutes, "&&", data.durationMinimumInMinutes, "< ", dataInMinutes, "&&", data.durationInMinutes, "<=", dataInMinutes);
							onChange(p === "durationMaximumType" ? values[p] : value, p);
						} else if (!data.durationInMinutes && data.durationMinimumInMinutes && data.durationMinimumInMinutes < dataInMinutes) {
							onChange(p === "durationMaximumType" ? values[p] : value, p);
						} else if (!data.durationMinimumInMinutes && data.durationInMinutes && data.durationInMinutes <= dataInMinutes) {
							onChange(p === "durationMaximumType" ? values[p] : value, p);
						} else {
							onChange(p === "durationMaximumType" ? values[p] : value, p);
							valid = false;
						}
						break;
					}
					default: {
						break;
					}
				}
				setError(!valid);
			}
		});
		
	};
	const isCustomDatavalid = (val, propdData) => {
		return JSON.stringify(val) == JSON.stringify(propdData);
	};
	const onDurationChange = (val, field) => {
		setCustomData((d) => ({ ...d, [field]: val }));
	};

	const replaceZero = (val) => {
		return val ? val.toString().replace(".00", "") : "";
	};
	return (
		<div className="d-flex margin-top-15 duration_wrapper">
			<div className="duration-container w_90 position-relative">
				{error ? <span className="invalid-duration"><I18 tkey="Invalid duration data" /></span> : ""}
				<div className="duration-content-container popup_modal_content_input margin-right-10">
					<div className="">
						<div className="text-center"><I18 tkey="Min"/></div>
						<div className="padding-left-10 padding-right-10 duration_value">
							<Tooltip placement="top" title={i18Get("Minimum Duration", utils.getLanguage())} >
								<Input className="edit-task-input-field h_36 "
									onChange={(e) => onDurationChange(utilslFuntion.floatingPointData(e.target.value, customData.durationMinimum), "durationMinimum")} 
									value={utilslFuntion.replaceComa(customData.durationMinimum ? replaceZero(customData.durationMinimum) : "")}
									maxLength="6"
									disabled={ !!disabled }
								/>
							</Tooltip>
						</div>
						<div className="margin-top-10 duration_type">
							<Select
								className="w-100"
								onChange={(e) => onDurationChange(e, "durationMinimumType")}
								value={customData.durationMinimumType ? customData.durationMinimumType : "Minutes"} 
								options={options}
								disabled={ !!disabled }
							/>
						</div>
					</div>
				</div> 
				<div className="duration-content-container popup_modal_content_input margin-right-10">
					<div className="">
						<div className="text-center"><I18 tkey="Max"/></div>
						<div className="padding-left-10 padding-right-10 duration_value">
							<Tooltip placement="top" title={i18Get("Maximum Duration", utils.getLanguage())} >
								<Input className="edit-task-input-field h_36"
									onChange={(e) => onDurationChange(utilslFuntion.floatingPointData(e.target.value, customData.durationMaximum), "durationMaximum")} 
									value={utilslFuntion.replaceComa(customData.durationMaximum ? replaceZero(customData.durationMaximum) : "")}
									maxLength="6"
									disabled={ !!disabled }
								/>
							</Tooltip>
						</div>
						<div className="margin-top-10  duration_type">
							<Select
								className="w-100"
								onChange={(e) => onDurationChange(e, "durationMaximumType")}
								value={customData.durationMaximumType ? customData.durationMaximumType : "Minutes"} 
								options={options}
								disabled={ !!disabled }
							/>
						</div>
					</div>
				</div>
				<div className="duration-content-container popup_modal_content_input margin-right-10">
					<div className="">
						<div className="text-center"><I18 tkey="Frequent"/></div>
						<div className="padding-left-10 padding-right-10 duration_value">
							<Tooltip placement="top" title={i18Get("Frequent", utils.getLanguage())} >
								<Input className="edit-task-input-field h_36" 
									onChange={(e) => onDurationChange(utilslFuntion.floatingPointData(e.target.value, customData.duration), "duration")} 
									value={utilslFuntion.replaceComa(customData.duration ? replaceZero(customData.duration) : "")}
									maxLength="6"
									defaultValue={0}
									disabled={ !!disabled }
								/>
							</Tooltip>
						</div>
						<div className="margin-top-10 duration_type">
							<Select
								className="w-100"
								onChange={(e) => onDurationChange(e, "durationType")}
								value={customData.durationType ? customData.durationType : "Minutes"} 
								options={options}
								disabled={ !!disabled }
							/>
						</div>
					</div>
				</div>
			</div>
		</div>

		
	);
};

export default memo(Duration);