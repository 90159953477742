import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import "./profile.css";
import { Modal } from "antd";
import I18, { i18Get } from "../../../../i18";
import utils from "../../../../utils/utils";
import Config from "../../../../MOM-config";
import eventNames from "../../../../actions/events";
import ActionButton from "../../../../plug/action-button";
import userActions from "../../../../actions/user/profile";
import NameInput from "../../../../plug/regex-name-search-input";
import SVG from "../../../../plug/svg";
import events from "../../../../actions/events";
import screenId from "../../../../router/screenIdList";
const PasswordValidation = React.lazy(() => import("mom-ui-commons/build/components/user/passwordValidation"));

class Profile extends Component {
	constructor(props) {
		super(props);
		this.state = {
			profileData: {},
			file: "",
			changePasswordVisible: false,
			invalidName: false,
			invalidEmail: false,
			fileImage: false,
			showPassword: {
				OLD: false,
				NEW: false,
				R_NEW: false
			}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.profile });
		this.props.dispatch(userActions.getProfile());
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			if (this.state.changePasswordVisible) {
				this.changePassword();
			}
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.profileData.name = value;
		this.setState(prevState);
	}

	onFormSubmit(e) {
		e.preventDefault();
		const formData = new FormData();
		if (this.state.file) {
			formData.append("file", this.state.file);
			this.props.dispatch(userActions.changeProfilePic(formData, this.props.user.userDetails.id));
		} else {
			utils.showError(i18Get("Please select an image", utils.getLanguage()));
		}
	}

	imageValidate(event) {
		const prevState = this.state;
		prevState.invalidCard = false;
		if (event.target.files[0].size > utils.imageSize) {
			utils.showError(i18Get("File size must be below 5 MB", utils.getLanguage()));
			prevState.invalidCard = true;
		}
		const type = event.target.files[0].type.split("/");
		if (utils.imageSupportedFormats.indexOf(type[1]) === -1) {
			utils.showError(i18Get("Unsupported format", utils.getLanguage()));
			prevState.invalidCard = true;
		}
		this.setState(prevState);
		if (this.state.invalidCard) {
			return false;
		}
		return true;
	}

	onChange(event) {
		if (event.target.files && event.target.files[0] && this.imageValidate(event)) {
			const reader = new FileReader();
			const prevState = this.state;
			prevState.file = event.target.files[0];
			reader.onload = (e) => {
				prevState.fileImage = e.target.result;
				this.setState(prevState);
			};
			reader.readAsDataURL(event.target.files[0]);
			// allow to select same file again
			event.target.value = null;
		}
	}

	changeImage() {
		document.getElementsByClassName("changeImage")[0].click();
	}

	uploadImage() {
		document.getElementsByClassName("uploadImage")[0].click();
	}

	toggleShowPassword(label) {
		return () => {
			const prevState = this.state;
			prevState.showPassword[label] = !this.state.showPassword[label];
			this.setState(prevState);
		};
	}

	render() {
		if (this.props.user.isProfileFetchComplete === "FULFILLED") {
			this.state.profileData = this.props.user.userDetails;
			this.props.dispatch(userActions.clearProfile());
		}
		if (this.props.user.isProfileUpdateComplete === "FULFILLED") {
			this.state.profileData = this.props.user.userUpdatedDetails;
			this.props.dispatch(userActions.clearUpdateProfile());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.user.isProfilePicUpdateComplete === "FULFILLED") {
			this.state.imgUrl = this.props.user.userProfilePic;
			this.state.userImgUrl = this.props.user.userProfilePic;
			this.state.profileData.profileUrl = this.props.user.userProfilePic.fileName;
			this.state.file = false;
			this.props.dispatch(userActions.clearUpdateProfile());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.user.isChangePasswordUserCompleted === "FULFILLED") {
			this.state.changePasswordVisible = false;
			this.props.dispatch(userActions.clearChangePassword());
		}

		return (
			<div className="overview_container">
				<div className="edit-form w_100 h_100">
					<div className="thin container">
						<div className="row profile_outer_container">
							<div className="col-md-8 col-md-offset-2 col-sm-8 col-sm-offset-2 col-xs-10 col-xs-offset-1  box-shadow ">
								<div className="row">
									<div className="col-md-5 padding-0 bg_tablets">
										<form onSubmit={this.onFormSubmit.bind(this)}>
											<input type="file" accept="image/x-png,image/jpg,image/jpeg" className="changeImage" onChange={this.onChange.bind(this)} hidden />
											<button type="submit" className="border_radius20 uploadImage" hidden />
										</form>
										<div className="di w_100 profile_img text-center">
											<div className="profile_image_div" onClick={this.changeImage.bind(this)}><img src={(this.state.file && this.state.fileImage) ? this.state.fileImage : ((this.state.profileData && this.state.profileData.profileUrl) ? Config.profileUrl + this.state.profileData.profileUrl : "/images/default_id.png")} alt="" /></div>
											<div className="w_100 di text-center apply_div">
												<button type="button" className=" margin-auto margin-top-20 margin-right-0 " onClick={this.uploadImage.bind(this)}><I18 tkey="Apply" /></button>
											</div>
										</div>
									</div>
									<div className="col-md-7 bg-fff">
										<div className="form_container">
											<div className="row ellipsis_input">
												<div className="col-md-12">
													<div className="group select_mr_input margin-top-0">
														<label className="profile_label"><I18 tkey="NAME" /></label>
														{this.state.invalidName && <span className="invalid"><I18 tkey="Enter name" /></span>}
														<br />
														<span className="highlight" />
														<span className="bar" />
														<NameInput
															className="profile_name"
															value={this.state.profileData.name}
															inputChanged={this.nameChanged.bind(this)}
															maxLength={60}
														/>
													</div>
												</div>
												<div className="col-md-12">
													<div className="group">
														<label className="profile_label"><I18 tkey="EMAIL" /></label>
														<br />
														<input disabled className="value" value={this.state.profileData.email} maxLength="120" />
													</div>
												</div>
											</div>
											<div className="w_100 di margin-bottom-20 text_align_right">
												<button onClick={this.onChangePasswordClick.bind(this)} type="button" className="change_password_button float_left border_radius20"><I18 tkey="Change Password" /></button>
											</div>
											<div className="w_100 di margin-bottom-20 text_align_right">
												<button className="edit_delete_button cancel_button margin-right-15 border_radius20 reset_profile" onClick={this.onResetClicked.bind(this)}><I18 tkey="RESET" /></button>
&nbsp;&nbsp;
												<div className="di profile_save"><ActionButton onClick={this.saveData.bind(this)} value={i18Get("SAVE", utils.getLanguage())} /></div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					title={i18Get("Change Password", utils.getLanguage())}
					className="change_pass_popup re_assign_modal"
					visible={this.state.changePasswordVisible}
					onCancel={this.hideModalChangePassword.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.changePassword.bind(this)}
				>
					<div className="mom_form_popup padding-0">
						<div className="row">
							<div className="col-xs-12 col-sm-offset-1 col-sm-10 ">
								<div className="group">
									<label className="top_10px" htmlFor="oldPassword"><I18 tkey="Old Password" /></label>
									<div className="profile_input_eye">
										<input type={this.state.showPassword.OLD ? "text" : "password"} id="oldPassword" placeholder="" name="oldPassword" value={this.state.oldPassword} onChange={this.passwordChange("oldPassword").bind(this)} maxLength="30" />
										<a onClick={this.toggleShowPassword("OLD").bind(this)}>
											{this.state.showPassword.OLD
												? <SVG src="/images/eye-slash.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
												: <SVG src="/images/eye.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
										</a>
									</div>
									{this.state.invalidOldPass && <span className="invalid"><I18 tkey="Enter old password" /></span>}
								</div>
							</div>
							<div className="col-xs-12 col-sm-offset-1 col-sm-10">
								<div className="group">
									<label className="top_10px" htmlFor="newPassword"><I18 tkey="New Password" /></label>
									<div className="profile_input_eye">
										<input type={this.state.showPassword.NEW ? "text" : "password"} id="newPassword" placeholder="" name="newPassword" value={this.state.newPassword} onChange={this.passwordChange("newPassword").bind(this)} maxLength="30" />
										<a onClick={this.toggleShowPassword("NEW").bind(this)}>
											{this.state.showPassword.NEW
												? <SVG src="/images/eye-slash.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
												: <SVG src="/images/eye.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
										</a>
									</div>
									{this.state.invalidNewPass && <span className="invalid"><I18 tkey="Enter new password" /></span>}
									{this.state.invalidPassOldEqual && <span className="invalid"><I18 tkey="New password cannot be same as old password" /></span>}
									<Suspense fallback={<div></div>}><PasswordValidation value={this.state.newPassword} /></Suspense>
									{/* <Tooltip placement="topRight" 
										title={ () => <span>
											<div>{i18Get("Password should contain atleast 8 characters, 1 numerc, 1 Alphabetic, 1 Upper Case, 1 lower Case, 1 special character (!@#$%^&*)", utils.getLanguage())}</div>
										</span>}><InfoCircleOutlined  className="change-password-info" type="info-circle" theme="outlined" /></Tooltip> */}
								</div>
							</div>
							<div className="col-xs-12 col-sm-offset-1 col-sm-10">
								<div className="group">
									<label className="top_10px" htmlFor="confirmNewPassword"><I18 tkey="Confirm New Password" /></label>
									<div className="profile_input_eye">
										<input type={this.state.showPassword.R_NEW ? "text" : "password"} id="confirmNewPassword" placeholder="" name="confirmNewPassword" value={this.state.confirmNewPassword} onChange={this.passwordChange("confirmNewPassword").bind(this)} maxLength="30" />
										<a onClick={this.toggleShowPassword("R_NEW").bind(this)}>
											{this.state.showPassword.R_NEW
												? <SVG src="/images/eye-slash.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />
												: <SVG src="/images/eye.svg" fillColor={Object.keys(this.props.settings.theme).length ? this.props.settings.theme.primaryColor.value : ""} />}
										</a>
									</div>
									{this.state.invalidConfirmPass && <span className="invalid"><I18 tkey="Enter confirm new password" /></span>}
									{this.state.invalidPassNotEqual && <span className="invalid"><I18 tkey="New passwords do not match" /></span>}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}

	onResetClicked() {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.profileData = [];
		prevState.file = false;
		this.setState(prevState);
		this.props.dispatch(userActions.getProfile());
	}

	saveData() {
		this.state.invalidPhoneNumber = false;
		this.state.invalidName = false;
		this.state.file = false;
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				name: this.state.profileData.name.trim(),
				hospitalId: this.props.user.userDetails.hospitalId ? this.props.user.userDetails.hospitalId : null,
				siteId: this.props.user.userDetails.siteId ? this.props.user.userDetails.siteId : null,
				role: this.props.user.userDetails.role ? this.props.user.userDetails.role : null,
				email: this.props.user.userDetails.email
			};
			this.props.dispatch(userActions.updateProfile(payload, this.props.user.userDetails.id));
		}
	}

	validateFields() {
		let valid = true;
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.profileData.name) || !this.state.profileData.name || !this.state.profileData.name.trim()) {
			prevState.invalidName = true;
			valid = false;
		}

		this.setState(prevState);
		return valid;
	}

	onChangePasswordClick() {
		this.resetInvalidField();
		const { state } = this;
		state.changePasswordVisible = true;
		state.oldPassword = "";
		state.newPassword = "";
		state.confirmNewPassword = "";
		this.setState(state);
	}

	hideModalChangePassword() {
		const { state } = this;
		state.changePasswordVisible = false;
		this.setState(state);
	}

	passwordChange(field) {
		return (e) => {
			const prevState = this.state;
			const inputString = e.target.value;
			prevState[field] = inputString.replace(utils.regexPassValEcp, "");
			this.setState(prevState, this.resetInvalidField);
		};
	}

	resetInvalidField() {
		const prevState = this.state;
		prevState.invalidOldPass = false;
		prevState.invalidNewPass = false;
		prevState.invalidNewPassValid = false;
		prevState.invalidConfirmPass = false;
		prevState.invalidPassOldEqual = false;
		prevState.invalidPassNotEqual = false;
		this.setState(prevState);
	}

	changePassword() {
		this.resetInvalidField();
		if (this.validatePassword()) {
			const payload = {
				newPassword: this.state.newPassword,
				oldPassword: this.state.oldPassword
			};
			this.props.dispatch(userActions.changePasswordUser(payload));
		}
	}

	validatePassword() {
		let valid = true;
		const prevState = this.state;
		if (!prevState.oldPassword) {
			prevState.invalidOldPass = true;
			valid = false;
		}
		if (!prevState.newPassword || !utils.regexPassVal.test(prevState.newPassword)) {
			prevState.invalidNewPass = true;
			valid = false;
		} else if (prevState.oldPassword === prevState.newPassword) {
			prevState.invalidPassOldEqual = true;
			valid = false;
		}
		if (!prevState.confirmNewPassword || !utils.regexPassVal.test(prevState.confirmNewPassword)) {
			prevState.invalidConfirmPass = true;
			valid = false;
		} else if (prevState.newPassword !== prevState.confirmNewPassword) {
			prevState.invalidPassNotEqual = true;
			valid = false;
		}
		this.setState(prevState);

		return valid;
	}
}

Profile.propTypes = {
	history: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	actions: store.actions,
	settings: store.settings,
	translations: store.translations
}))(Profile));