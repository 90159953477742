import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import locations from "../../../../../router/locations";
import { DebounceInput } from "react-debounce-input";
import Family from "../administration/activities/activity-families/selectFamilyForSpeciality";
import Speciality from "../administration/activities/specialities/selectSpecialities";
import StatusDropDown from "../intervention/statusDropDown";
import HospitalSelect from "../administration/activities/specialities/selectHospital";
import { statusList } from "../../../../../constants";
import { Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined, EditOutlined, FilterOutlined } from "@ant-design/icons";
import clinicalPathwayAction from "../../../../../actions/clinical-pathway";
import Loader from "../../../../../plug/loader";
import ReactTable from "../../../../../plug/reactTable";
import column from "./column";
import ClinicalPathwayEditModel from "./clinical-pathway-edit-model";
import AssignAndApproveModel from "./assign-and-approve-model";
import ClpTypeDropDown from "./clp-type-dropdown";
import ExportClpExcel from "./export_clp_list/index.js";
import AttachedClinicalPathways from "./attachedClinicalPathways";
import CopyFromClinicalPathWay from "./details/copyfromclinicalpathway/index";
import CopyClinicalPathwayHeader from "./copyClinicalPathwayHeader";
import isPermissionGranted from "../../../../../utils/permission";
import ApplyModelChanges from "./components/modelChangeNotification";
import "./dome";
import SharedCommunityList from "./SharedCommunityList";
import NotDeleteInfo from "./not-delete-info";

class ClinicalPathWayList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilter: false,
			filter: "",
			organization: "mom",
			count: 0,
			desc: 0,
			status: "ALL",
			waitingForApprovalStatus: "ALL",
			hospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			clinicalPathwayType: "ALL",
			editModel: false,
			modelVisible: false,
			copyFromClinicalPathWay: false,
			fetchAllMeta: null,
			clinicalPathwayData: {},
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			clinicalPathway: [],
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			ignore: false,
			localStorageKey: "CLP_FILTER",
			category: "clinicalPathway",
			linkListView: false,
			linkList: [],
			isLoadingApplyModelChanges: false,
			modelChangesCLP: [],
			isModelAttached: false,
			isNotDeletable: false,
			isNotDeletableInfo: {}
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillUpdate() {
		if (this.state.organization !== this.props.match.params.organization) {
			document.getElementsByClassName("main_container_parent")[0].scrollTo(0, 0);
			this.state.organization = this.props.match.params.organization;
			this.state.speciality = false;
			this.state.family = false;
			this.state.clinicalPathwayCount = false;
			this.state.clinicalPathway = [];
		}

	}

	UNSAFE_componentWillMount() {
		const storedValues = utils.getFilterInLocalStorage(this.state.localStorageKey);
		if (storedValues && (typeof storedValues === "object") && Object.keys(storedValues).length) {
			this.setState({
				organization: this.props.match.params.organization,
				showFilter: storedValues.showFilter,
				clinicalPathwayType: storedValues.clinicalPathwayType,
				category: storedValues.category,
				filterValue: storedValues.filterValue
			});
		}
		else {
			this.setLocalStorageForFilter();
		}
	}

	filterChanged(e) {
		this.setState({ filterValue: e.target.value, page: 1 }, () => {
			this.fetchDataFromServer();
			this.setLocalStorageForFilter();
		});
	}

	createClicked() {
		this.state.ignore = true;
		this.props.history.push({ pathname: `${locations.clinical_pathway_plain}/${this.state.organization}${locations.create}`, state: { category: this.state.category } });
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter, speciality: "", family: "" }, () => { this.setLocalStorageForFilter(); });
	}

	clearFilter() {
		const prevState = this.state;
		prevState.family = false;
		prevState.speciality = false;
		prevState.status = "ALL";
		prevState.waitingForApprovalStatus = "ALL";
		prevState.hospital = { value: null, label: i18Get("All", utils.getLanguage()) };
		prevState.filterValue = "";
		prevState.clinicalPathwayType = "ALL";
		this.setState(prevState);
		this.fetchDataFromServer();
		this.setLocalStorageForFilter();
	}

	setLocalStorageForFilter() {
		const filters = {
			clinicalPathwayType: this.state.clinicalPathwayType,
			showFilter: this.state.showFilter,
			category: this.state.category,
			filterValue: this.state.filterValue
		};
		utils.setFilterInLocalStorage(this.state.localStorageKey, filters);
	}

	getFilter() {
		return (
			<div>
				<div className="row  margin-top-25">
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_spl margin-top-7 label_name"><I18 tkey="Speciality" /></span>
							<Speciality data={this.state.speciality ? this.state.speciality : ""} specialityChanged={this.specialityChanged.bind(this)} organization={this.state.organization} typeActivity='CLINICAL_PATHWAY' hideLabel={true} />
						</div>
					</div>
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name"><I18 tkey="Family" /></span>
							<Family data={this.state.family ? this.state.family : ""} onFamilyChanged={this.familyChanged.bind(this)} organization={this.state.organization} typeActivity='CLINICAL_PATHWAY' specialityId={this.state.speciality ? this.state.speciality.value : ""} hideLabel={true} />
						</div>
					</div>
					{/* <div className="col-md-4 col-sm-4 ">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name">
								<I18 tkey="Status" />
							</span>
							<StatusDropDown
								data={this.state.status}
								onStatusChange={this.statusChanged.bind(this)} />
						</div>
					</div> */}
				</div>
				<div className="row margin-top-10">
				</div>
			</div>
		);
	}

	getHospital() {
		return (
			<div className=" w_100">
				<span className="min_width_status  margin-top-7 label_name">
					<I18 tkey="Hospital" />
				</span>
				<HospitalSelect
					isClpList = {true}
					data={this.state.hospital}
					hospitalChanged={this.hospitalChanged.bind(this)}
				/>
			</div>
		);
	}

	getClearFilter() {
		return (
			<div className="col-md-12 col-sm-4">
				<div className="clear_fliter text_align_right">
					<a className="clear_filter_a" onClick={this.clearFilter.bind(this)}><I18 tkey="Clear Filter" /></a>
				</div>
			</div>
		);
	}

	specialityChanged(e) {
		this.state.speciality = e;
		this.state.family = "";
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	familyChanged(e) {
		this.state.family = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	hospitalChanged(e) {
		this.state.hospital = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	statusChanged(e) {
		this.state.status = e;
		this.setState(this.state);
		this.fetchDataFromServer();
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	tabChanged(category) {
		if (this.state.category !== category) {
			this.setState({ category: category, loading: true, page: 1 }, () => {
				this.fetchDataFromServer();
				this.setLocalStorageForFilter();
			});
		}
	}

	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathway === "FULFILLED" && this.props.clinicalPathway.clinicalPathwayListMeta === this.state.fetchAllMeta) {
			this.state.loading = false;
			this.state.clinicalPathway = this.props.clinicalPathway.clinicalPathway.data;

			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
				
			this.state.clinicalPathwayCount = this.props.clinicalPathway.clinicalPathway.count;
			this.state.totalPages = parseInt(Math.ceil(this.props.clinicalPathway.clinicalPathway.count / this.state.pageSize));
		
			this.props.dispatch(clinicalPathwayAction.clearGetAll());
		}
		if (this.props.clinicalPathway.deleteClinicalPathwayStatus === "FULFILLED") {

			if (this.props.clinicalPathway.notClpDeleteInfo && !this.props.clinicalPathway.notClpDeleteInfo.isDeletable) {
				this.state.isNotDeletable = true;
				this.state.isNotDeletableInfo = this.props.clinicalPathway.notClpDeleteInfo.attachedData;
				utils.showError(this.props.clinicalPathway.notClpDeleteInfo.msg);
				this.setState(this.state);

			} else {

				this.fetchDataFromServer();
			}
			this.props.dispatch(clinicalPathwayAction.removeClinicalPathwayCompleted());
			
		}
		if (this.props.clinicalPathway.deleteClinicalPathwayStatus === "REJECTED") {

			this.props.dispatch(clinicalPathwayAction.removeClinicalPathwayCompleted());
		}

		return (
			<div className="main_overview_section">
				<div className="main_overview_top_section di w_100 padding-top-15">
					<div className="row">
						<div className="col-md-4">
							<div className="record_container w_100 margin-top-6">
								<div className="di number_of_records">
									<span><I18 tkey="Number of Records" />:<b>{this.state.clinicalPathwayCount}</b></span>
								</div>
							</div>
						</div>
						<div className="col-md-8 d-flex align-item-center justify-content-end ">
							<div>
								<ExportClpExcel
									speciality={this.state.speciality}
									family={this.state.family}
									status={this.state.status}
									waitingForApprovalStatus={this.state.waitingForApprovalStatus}
									filterValue={this.state.filterValue}
									hospital={this.state.hospital}
									clinicalPathwayType={this.state.clinicalPathwayType}
									category={this.state.category}
									sort={this.state.sort}
									sortType={this.state.desc}
								/>
							</div>
							<div className="create_interventions text_align_right margin-right-10">
								<button className="common_button" type="button"
									onClick={this.createClicked.bind(this)}
									disabled={!isPermissionGranted(false, "CREATE")}>
									<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
									<I18 tkey={`CREATE ${this.state.category.replace(/([A-Z])/g, " $1").toUpperCase()}`} />
								</button>
							</div>
							<div className="create_interventions text_align_right mt10_mobile">
								<div className="filter margin-right-0" onClick={this.filterClicked.bind(this)}>
									<FilterOutlined className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
								</div>
							</div>
						</div>
					</div>
					{this.state.showFilter && this.getFilter()}
				</div>
				<div className="row d-flex align-items-center ">
					<div className="col-md-4 col-sm-4 ">
						{ this.getHospital()}
					</div>
					<div className="col-md-4 col-sm-4 ">
						<span className="min_width_fmly  margin-top-7 label_name">
							<I18 tkey="Clinical Pathway Type" />
						</span>
						<ClpTypeDropDown
							data={this.state.clinicalPathwayType}
							isClinicalPathway={true}
							clpTypeChanged={this.clpTypeChanged.bind(this)} />
					</div>
					<div className="col-md-4 margin-top-17">
						<a className="padding-0 pathway_searchbox">
							<DebounceInput
								className="filter_input_design form-control"
								debounceTimeout={750}
								placeholder={i18Get("Search...", utils.getLanguage())}
								value={this.state.filterValue}
								onChange={this.filterChanged.bind(this)}
							/>
						</a>
					</div>
				</div>
				<div className="row d-flex align-items-center ">
					{this.state.showFilter && this.getClearFilter()}
				</div>
				<div className="tab_section padding-top-15 di w_100">
					<div className="tab-content table_scroll_container">
						<ul className="nav nav-tabs">
							<li className={this.state.category === "clinicalPathway" ? "active" : ""}>
								<a className="tab_section  di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "clinicalPathway")}>
									<I18 tkey="Clinical Pathways" />
								</a>
							</li>
							<li className={this.state.category === "checkList" ? "active" : ""}>
								<a className="tab_section di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "checkList")}>
									<I18 tkey="Check List" />
								</a>
							</li>
							<li className={this.state.category === "model" ? "active" : ""}>
								<a className="tab_section di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "model")}>
									<I18 tkey="Model" />
								</a>
							</li>
						</ul>
						{this.getList()}
						{this.state.editModel ?
							<div>
								<ClinicalPathwayEditModel
									refreshCompleted={this.refreshCompleted}
									cancelClicked={this.cancelClicked}
									clinicalPathwayId={this.state.clinicalPathwayId}
									isCheckList={this.state.isCheckList}
									visible={this.state.editModel} />
							</div>
							: ""}
						{this.state.modelVisible ?
							<div>
								<AssignAndApproveModel
									refreshCompleted={this.refreshCompleted}
									clinicalPathwayId={this.state.clinicalPathwayId}
									clinicalPathwayType={this.state.clinicalPathwayType}
									visible={this.state.modelVisible} />
							</div>
							: ""}
						{this.state.copyFromClinicalPathWay ?
							<Modal
								visible={this.state.copyFromClinicalPathWay}
								className="cost_modal re_assign_modal overflow-x-hidden-model"
								title={<CopyClinicalPathwayHeader title={"Copy From Clinical Pathway"} clinicalPathwayData={this.state.clinicalPathwayData} />}
								onCancel={this.cancelClicked}
								wrapClassName="drag_modal"
								footer={null}
							>
								<CopyFromClinicalPathWay key='1' clinicalPathwayData={this.state.clinicalPathwayData} closeCopy={this.cancelClicked} />
							</Modal> : ""}
					</div>
				</div>

											
				{this.state.linkListView &&
					<AttachedClinicalPathways
						visible={this.state.linkListView}
						data={this.state.linkList}
						category={this.state.category}
						onClose={this.Closeview.bind(this)}
						location={this.props.location}
						history={this.props.history}
						isModelAttached={this.state.isModelAttached}
						organization={this.props.match.params.organization }
					/>
				}

				{this.state.linkListView && this.state.isCommunityAttached && 
				<SharedCommunityList 
					visible={this.state.linkListView}
					data={this.state.linkList}
					category={this.state.category}
					onClose={this.Closeview.bind(this)}
					location={this.props.location}
					history={this.props.history}
					isCommunityAttached= {this.state.isCommunityAttached}
				/>}
	
				{ isPermissionGranted(this.state.clinicalPathwayData.isReadOnly, "EDIT") &&
					this.state.isLoadingApplyModelChanges && <ApplyModelChanges data={this.state.modelChangesCLP} onClose={this.modelChangesApplyClose.bind(this)} visible={this.state.isLoadingApplyModelChanges} reloadData={this.refreshCompleted} mode={"ApplyChanges"} />
				}
				{this.state.isNotDeletable ? <NotDeleteInfo onClose={this.Closeview.bind(this)}  data={this.state.isNotDeletableInfo } /> : ""}
			</div>
		);
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	moveToDetails = (value) => {
		//if (value.status === statusList.STATUS.values.APPROVED) {//
		this.state.value = value;
		this.props.history.push(this.props.location.pathname + "/" + value.id + this.props.location.search);
		//}
	};

	viewLinkedList(list, modelAttached) {
		return () => {
			this.setState({ linkListView: true, linkList: list, isModelAttached: (modelAttached === true ? modelAttached : false) });

		};
	}

	viewCommunityList(list, communityAttached) {
		return () => {
			this.setState({ linkListView: true, linkList: list, isCommunityAttached: (communityAttached === true ? communityAttached : false) });

		};
	}

	Closeview() {
		this.setState({ linkListView: false, linkList: [], isModelAttached: false, isCommunityAttached: false, isNotDeletable: false  });
	}
	//------------------APPLY MODEL CHANGES--------------------------------------------
	modelChangesApply = (row) => {
		this.setState({ ...this.state, isLoadingApplyModelChanges: true, modelChangesCLP: row, isLoadingApplayModelChanges: true  });
	};
	modelChangesApplyClose = () => {
		this.setState({ ...this.state, isLoadingApplyModelChanges: false, modelChangesCLP: [], isLoadingApplayModelChanges: false });
	};
	//------------------------------------------------------------------------------------------
	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.clinicalPathway, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this), column(this.getActionButton, this.moveToDetails, this.viewLinkedList.bind(this), this.viewCommunityList.bind(this), this.state.category), true, false,
					this.onPageChange.bind(this), "CLINICAL PATHWAY",
					`-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll ${(this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "scanned_treatments_table" : this.props.organization === locations.tabMom ? "intervention_table" : "table_head_element_hide"}`,
					this.onPageSizeChange.bind(this))}
				defaultSorted={[
					{
						id: this.state.sort ? this.state.sort : "name",
						desc: this.state.desc ? true : false
					}
				]}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex} />
		);
	}

	rowScroll(row) {
		this.state.rowScrollIndex = (row.startIndex + (row.stopIndex - row.startIndex) - 1);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = false;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		if (this.state.ignore) {
			return;
		}
		let queryString = "";
		queryString += `offset=${this.state.pageSize * (this.state.page - 1)}&`;
		const organization = `${(this.state.organization ? this.state.organization.toUpperCase() : "MOM")}`;
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${`desc=${this.state.desc}&`}`;
		queryString += this.state.filterValue ? `code=${encodeURIComponent(this.state.filterValue)}&name=${encodeURIComponent(this.state.filterValue)}&momCode=${encodeURIComponent(this.state.filterValue)}&momName=${encodeURIComponent(this.state.filterValue)}&` : "";
		queryString += this.state.speciality ? `specialityId=${this.state.speciality.value}&` : "";
		queryString += this.state.family ? `familyId=${this.state.family.value}&` : "";
		queryString += this.state.status ? `status=${this.state.status}&` : "";
		queryString += this.state.waitingForApprovalStatus ? `wfaStatus=${this.state.waitingForApprovalStatus}&` : "";
		queryString += this.state.hospital && this.state.hospital.value ? `hospitalId=${this.state.hospital.value}&` : "";
		queryString += this.state.clinicalPathwayType && this.state.clinicalPathwayType !== "ALL" ? `clinicalPathwayType=${this.state.clinicalPathwayType}` : "";
		queryString += this.state.category === "checkList" ? "&isCheckList=1" : "&isCheckList=0";
		queryString += this.state.category === "model" ? "&isModel=1" : "";
		queryString += `&timezone=${new Date().getTimezoneOffset()}`;
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(clinicalPathwayAction.getAll(organization, queryString, this.state.fetchAllMeta));
		});
	}

	deleteClicked = (row) => {
		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to delete ?"),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Delete"),
			cancelText: i18Get("Cancel"),
			onOk: () => {
				if (row && row.id) {
					this.props.dispatch(clinicalPathwayAction.removeClinicalPathway(row.id));
				}
			},
			onCancel: () => {
			}
		});
	};


	approveClicked(clinicalPathway) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, clinicalPathwayId: clinicalPathway.id });
		};
	}

	editClicked(clinicalPathway) {
		return (e) => {
			e.preventDefault();
			this.setState({ editModel: true, clinicalPathwayId: clinicalPathway.id, isCheckList: clinicalPathway.isCheckList });
		};
	}

	refreshCompleted = () => {
		this.setState({ ...this.state, modelVisible: false, editModel: false }, () => { this.fetchDataFromServer(); });

	};

	cancelClicked = () => {
		this.setState({ ...this.state, editModel: false, copyFromClinicalPathWay: false });
	};

	clpTypeChanged(e) {
		this.state.clinicalPathwayType = e;
		this.setState(this.state);
		this.fetchDataFromServer();
		this.setLocalStorageForFilter();
	}

	copyFromClpClicked = (data) => {
		const prevState = this.state;
		prevState.clinicalPathwayData = data;
		prevState.copyFromClinicalPathWay = true;
		this.setState(prevState);
	};

	handleCancel() {
		this.setState({ copyFromClinicalPathWay: false });
	}


	getActionButton = (row) => {
		return (
			<div className="">
				<div className="min_width_12_px">
					{
						isPermissionGranted(row.value.isReadOnly, "EDIT") ?
							<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
							: ""
						//<a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
					}
				</div>
				<div className="min_width_12_px">
					{
						isPermissionGranted(row.value.isReadOnly, "DELETE") ?
							<DeleteOutlined className="delete_color margin-right-10" type="delete" onClick={() => this.deleteClicked(row.value)} />
							: ""
					}
				</div>

				{/* {
					isPermissionGranted(row.value.isReadOnly, 'EDIT') && !row.value.isCheckList && row.value.isModel != 1 ?
						<div className="min_width_12_px">
							<Tooltip title={i18Get('Copy From Clinical Pathway', utils.getLanguage())} placement="top">
								<a className="" onClick={() => this.copyFromClpClicked(row.value)}><img src="/images/copy.png" /> </a>
							</Tooltip>
						</div> : ''
				} */}


				{
					isPermissionGranted(row.value.isReadOnly, "EDIT") && row.value.isModel === 1 && row.value.modelLinkedClpList && row.value.modelLinkedClpList.length > 0 ?
						<div className="min_width_12_px">
							<span onClick={() => { this.modelChangesApply(row.value); }}>
								<Tooltip placement="top" title={i18Get("There are some changes in this Model. Click to apply the changes to all the linked Clinical pathways", utils.getLanguage())}>
									<a className="red" >
										<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
											<rect x="0.75" y="0.663086" width="14" height="14" fill="#e31818 " fillOpacity="0.01" />
											<path d="M2.23065 14.6628C2.17951 14.6452 2.12875 14.6273 2.07761 14.6098C1.72516 14.4875 1.48228 14.1719 1.45745 13.8019C1.45416 13.7519 1.45306 13.7018 1.45306 13.6518C1.4527 9.6598 1.45379 5.66743 1.45124 1.67542C1.45087 1.32808 1.55935 1.0421 1.84606 0.835017C2.00603 0.719603 2.18718 0.663357 2.38441 0.663357C4.63133 0.662992 6.87825 0.662262 9.12517 0.66701C9.20187 0.66701 9.29719 0.709012 9.35198 0.763066C10.3955 1.79704 11.4349 2.83504 12.4714 3.87595C12.524 3.92891 12.5701 4.0162 12.5715 4.08816C12.5799 4.60314 12.5781 5.11812 12.5748 5.6331C12.5737 5.79964 12.466 5.89278 12.3246 5.861C12.1986 5.83251 12.1654 5.73865 12.1654 5.6236C12.1657 5.22695 12.1654 4.83068 12.1654 4.43403C12.1654 4.38473 12.1654 4.33542 12.1654 4.26749C12.0993 4.26749 12.0463 4.26749 11.9937 4.26749C11.2629 4.26749 10.5324 4.26785 9.80158 4.26749C9.27345 4.26712 8.96812 3.96325 8.96775 3.43694C8.96702 2.70319 8.96775 1.96943 8.96775 1.23568C8.96775 1.18674 8.96775 1.1378 8.96775 1.07315C8.90566 1.07315 8.85709 1.07315 8.80814 1.07315C6.69381 1.07315 4.5791 1.07315 2.46476 1.07315C2.06373 1.07315 1.86395 1.26855 1.86395 1.66592C1.86359 5.66268 1.86359 9.65943 1.86395 13.6562C1.86395 14.0554 2.06264 14.253 2.46074 14.253C4.2829 14.2533 6.10505 14.253 7.9272 14.253C7.95934 14.253 7.99112 14.2541 8.02326 14.2526C8.13904 14.2475 8.24459 14.2617 8.29353 14.3881C8.34357 14.5174 8.26322 14.5916 8.17665 14.6631C6.19453 14.6628 4.21277 14.6628 2.23065 14.6628ZM9.38339 1.40223C9.38193 1.43948 9.379 1.47491 9.37864 1.51033C9.37827 2.15753 9.37791 2.80436 9.37864 3.45155C9.379 3.73023 9.50757 3.85623 9.79245 3.8566C10.4364 3.85733 11.0803 3.85696 11.7242 3.8566C11.7622 3.8566 11.8005 3.85112 11.8352 3.84856C11.0167 3.0319 10.2041 2.22108 9.38339 1.40223Z" fill="#e31818 " />
											<path d="M5.05464 2.43915C4.40635 2.43915 3.75769 2.43915 3.1094 2.43878C3.05937 2.43878 3.0086 2.44024 2.95893 2.4333C2.8479 2.41796 2.7869 2.34711 2.78288 2.23973C2.77923 2.13783 2.83402 2.06588 2.93555 2.04068C2.97902 2.02972 3.02577 2.02899 3.07105 2.02899C4.39539 2.02826 5.71973 2.02826 7.04407 2.02899C7.08936 2.02899 7.13647 2.02972 7.17957 2.04141C7.28074 2.06844 7.33224 2.13893 7.32786 2.24302C7.32347 2.34711 7.26613 2.41285 7.1635 2.43148C7.11456 2.44061 7.06343 2.43842 7.01339 2.43842C6.36072 2.43951 5.70768 2.43915 5.05464 2.43915Z" fill="#e31818 " />
											<path d="M5.06668 3.38219C5.71899 3.38219 6.37094 3.38219 7.02324 3.38219C7.0689 3.38219 7.1266 3.36722 7.15728 3.38913C7.22157 3.43515 7.29753 3.49176 7.32164 3.56043C7.35488 3.65429 7.29644 3.73829 7.19746 3.77226C7.15144 3.78797 7.09921 3.79125 7.04991 3.79125C5.72265 3.79235 4.39575 3.79198 3.06849 3.79198C3.05023 3.79198 3.03197 3.79271 3.0137 3.79162C2.86907 3.78431 2.77667 3.69921 2.78069 3.57796C2.7847 3.46181 2.87382 3.38402 3.01407 3.38256C3.28763 3.38 3.56119 3.38183 3.83512 3.38146C4.246 3.38219 4.65616 3.38219 5.06668 3.38219Z" fill="#e31818 " />
											<path d="M6.06633 9.02164C4.9111 8.61696 4.39137 7.49532 4.54769 6.52161C4.70145 5.56433 5.48305 4.7915 6.42974 4.65015C7.43267 4.50077 8.52837 5.04059 8.92356 6.16441C10.4488 5.79552 11.8144 6.0983 12.9634 7.18341C13.8067 7.97962 14.2275 8.98292 14.2362 10.1411C14.2545 12.5023 12.3279 14.3877 9.9495 14.3186C8.60398 14.2795 7.50973 13.6999 6.71462 12.6148C5.91951 11.5297 5.73251 10.3168 6.06633 9.02164ZM6.77196 9.1334C6.30337 10.5563 6.84428 12.2583 8.28001 13.136C9.63905 13.9669 11.4386 13.7383 12.5777 12.5754C13.7034 11.426 13.8739 9.61405 12.978 8.26231C12.0525 6.86639 10.3644 6.42811 9.03787 6.87443C9.02472 7.48875 8.81362 8.0271 8.37497 8.46648C7.9356 8.90622 7.39724 9.11879 6.77196 9.1334ZM8.33991 6.88611C8.34174 6.02234 7.64597 5.32474 6.78 5.32145C5.91951 5.31816 5.21862 6.01905 5.21753 6.88356C5.21643 7.73163 5.91731 8.43507 6.76685 8.43763C7.63282 8.44055 8.33808 7.74514 8.33991 6.88611Z" fill="#e31818 " />
											<path d="M10.4225 9.82345C10.4714 9.82345 10.5101 9.82345 10.5489 9.82345C11.0836 9.82345 11.6183 9.82236 12.1533 9.82418C12.3191 9.82491 12.4455 9.92389 12.4908 10.078C12.5324 10.219 12.4787 10.3771 12.348 10.4542C12.2888 10.4889 12.2132 10.513 12.1449 10.5134C11.4583 10.5178 10.772 10.517 10.0854 10.5152C9.87682 10.5145 9.73 10.3677 9.72963 10.1598C9.72781 9.47465 9.72781 8.78948 9.72963 8.1043C9.73 7.89977 9.87792 7.74819 10.0711 7.74564C10.2684 7.74308 10.4214 7.89721 10.4221 8.10612C10.4239 8.63608 10.4228 9.1664 10.4228 9.69635C10.4225 9.73543 10.4225 9.77415 10.4225 9.82345Z" fill="#e31818 " />
											<path d="M6.43194 6.35631C6.43194 6.2409 6.42938 6.12548 6.43267 6.01007C6.43815 5.81504 6.58972 5.66712 6.77927 5.66748C6.9681 5.66785 7.1215 5.8165 7.12479 6.01153C7.1288 6.24236 7.12844 6.47319 7.12479 6.70365C7.12186 6.90416 6.96591 7.055 6.7727 7.05172C6.58461 7.04843 6.43669 6.89795 6.43267 6.70182C6.43011 6.58714 6.43194 6.47173 6.43194 6.35631Z" fill="#e31818 " />
											<path d="M7.12551 7.74115C7.1277 7.92925 6.97394 8.08776 6.78584 8.09141C6.59775 8.09506 6.43704 7.94203 6.4323 7.75467C6.42755 7.56109 6.5835 7.40003 6.77708 7.39929C6.9659 7.39856 7.12332 7.55306 7.12551 7.74115Z" fill="#e31818 " />
										</svg> </a>
								</Tooltip>
							</span>
						</div> : ""
				}

			</div>
		);
	};
}

ClinicalPathWayList.propTypes = {
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathway: store.clinicalPathway,
	translations: store.translations,
	permissions: store.permissions
}))(ClinicalPathWayList));