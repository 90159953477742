import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import locations from "../../../../../../router/locations";
import BreadCrumb from "../../breadcrumb";
import ListTranslations from "./list";
import CreateTranslations from "./create";

class Translations extends Component {
	constructor(props) {
		super(props);
		this.state = {
			search: ""
		};
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Translations", utils.getLanguage()), link: locations.translations });
		if (this.props.location.pathname === locations.createTranslations) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.createTranslations });
		}
		return items;
	}

	addClicked() {
		this.props.history.push(locations.createTranslations);
	}

	filterChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState);
	}

	render() {
		if (this.props.translations.isTranslationCreateCompleted === "FULFILLED") {
			this.state.search = "";
		}

		if (this.props.translations.isTranslationFetchForLanguageCompleted === "FULFILLED") {
			this.state.totalRecords = this.props.translations.translations.count;
		}

		return (
			<div className="personal_doctor_table_scroll">
				<div className="main_overview_section">
					<div className="pole_container">
						<BreadCrumb items={this.generateItems()} showRecord={(this.props.location.pathname === locations.translations)} totalRecords={this.state.totalRecords} />
						<div className="">
							{this.props.location.pathname === locations.translations
								&& (
									<div className="di w_100 float_left">
										<div className=" w_100 float_left padding-top-15 padding-bottom-15">
											<div className="row">
												<div className="col-md-6 col-sm-6">
													<button className="common_button margin-right-15" onClick={this.addClicked.bind(this)}>
														<i className="fa fa-plus margin-right-5" aria-hidden="true" />
														&nbsp;
														<I18 tkey="CREATE" />
													</button>
												</div>
												<div className="col-md-offset-3 col-md-3 col-sm-3 col-sm-offset-3 ">
													<DebounceInput
														className="form-control w_100"
														debounceTimeout={750}
														value={this.state.search}
														onChange={this.filterChanged.bind(this)}
														maxLength={200}
														placeholder={i18Get("Search ...", utils.getLanguage())}
													/>
												</div>
											</div>
										</div>
									</div>
								)}
							<Switch>
								<Route
									exact
									path={locations.createTranslations}
									render={() => <CreateTranslations />}
								/>
								<Route
									path={locations.translations}
									render={() => <ListTranslations search={this.state.search} />}
								/>
							</Switch>

						</div>

					</div>
				</div>
			</div>
		);
	}
}

Translations.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Translations));
