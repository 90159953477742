import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import consumableClassActions from "../../../../../../../../../actions/consumable";
import ActionButton from "../../../../../../../../../plug/action-button";
import locations from "../../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import events from "../../../../../../../../../actions/events";
import Loader from "../../../../../../../../../plug/loader";
import screenId from "../../../../../../../../../router/screenIdList";

class EditConsumableClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumableClass: {}

		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.consumableDefinitionEdit });
		this.props.dispatch(consumableClassActions.fetchOneConsumableClass(this.props.consumableClassId));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	nameChanged(e) {
		this.setState({ consumableClass: { ...this.state.consumableClass, name: e.target.value }, invalidName: false });
	}

	toggle(state, e) {
		this.state.consumableClass[state] = e.target.checked;
		this.setState(this.state);
	}

	validate() {
		const prevState = this.state;
		if (!prevState.consumableClass.name || !prevState.consumableClass.name.trim()) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		if (this.validate()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.consumableClass.name
			};
			this.props.dispatch(consumableClassActions.updateConsumableClass(this.state.consumableClass.id, payload));
		}
	}

	renderEdit() {
		return (
			<div className="pole_create_section di w_100">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="NAME" /></label>
								<input
									className="form-control w_100"
									value={this.state.consumableClass.name}
									onChange={this.nameChanged.bind(this)}
								/>
								{this.state.invalidName ? <span className="invalid_right fixed_label"><I18 tkey="Enter valid name" /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 margin-top-25-check">
							<div className="di w_100 text_align_right">
								{/* <Checkbox
                                    className="float_left margin-top-6"
                                    onChange={this.toggle.bind(this,'isSubsidised')}
                                    checked={this.state.consumableClass.isSubsidised}>
                                        <I18 tkey="Dm not included" />
                                </Checkbox> */}
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.consumable.fetchOneConsumableClassComplete === "FULFILLED") {
			this.state.consumableClass = this.props.consumable.consumableClass;
			this.props.dispatch(consumableClassActions.clearFetchOneConsumableClass());
			if (this.state.consumableClass) {
				this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.consumableClass.name, id: this.state.consumableClass.id } });
			}
		}
		if (this.props.consumable.fetchOneConsumableClassComplete === "REJECTED") {
			this.props.dispatch(consumableClassActions.clearFetchOneConsumableClass());
			this.props.history.push(`${locations.consumable_definition_plain}/${locations.tabConsumableClass}${this.props.location.search}`);
		}
		if (this.props.consumable.updateConsumableClassComplete === "FULFILLED") {
			this.props.dispatch(consumableClassActions.clearUpdateConsumableClass());
			this.props.history.push(`${locations.consumable_definition_plain}/${locations.tabConsumableClass}${this.props.location.search}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.consumable.updateConsumableClassComplete === "REJECTED") {
			this.props.dispatch(consumableClassActions.clearUpdateConsumableClass());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.state.consumableClass.id) {
			return this.renderEdit();
		}
		return (<Loader loading isTable={false} />);
	}
}

EditConsumableClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	consumable: store.consumable
}))(EditConsumableClass));
