import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button } from "antd";
import communitySectionAction from "../../../../../../../../actions/community-section";
import Section from "./createSection"; 

class CreateSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSectionCreate: false,
			name: "",
			code: "",
			invalidCode: false,
			invalidName: false,
			loading: false,
			saveAndNext: false,
			loadingNext: false,
			specialities: [],
			invalidSpecialities: false

		};
	}

	createNewClass() {
		this.setState({ showSectionCreate: true });
	}
    
	nameChanged(value) {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.code = text;
		} else {
			prevState.code = this.state.code ? this.state.code : "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	validate() {
		const prevState = this.state;
		if (!prevState.name || !prevState.name.trim() || !utils.checkNameRegex(prevState.name) ) {
			prevState.invalidName = true;
		}
		if (!prevState.code || !prevState.code.trim()) {
			prevState.invalidCode = true;
		}

		if (!prevState.specialities || this.createPayloadData(prevState.specialities) == 0) {
			prevState.invalidSpecialities = true;
		}

		if (prevState.invalidName || prevState.invalidCode || prevState.invalidSpecialities) {
			this.setState(prevState);
			return false;
		}
        
		return true;
	}
    
	handleOk(saveAndNext) {   
		if (this.validate()) {
			if (saveAndNext === true) {this.setState({ saveAndNext: true, loadingNext: true });}
			else {this.setState({ loading: true, saveAndNext: false });}

			const payload = { 
				name: this.state.name,
				code: this.state.code,
				specialityIds: this.createPayloadData(this.state.specialities)
			};
			this.props.dispatch(communitySectionAction.createCommunitySection(payload));
		}
	}

	handleCancel() {

		this.setState({ 
			showSectionCreate: false, 
			name: "", 
			invalidName: false, 
			code: "", 
			invalidCode: false,
			specialities: [],
			invalidSpecialities: false });
	}
	handleNext() {
		this.setState({ showSectionCreate: true, name: "", invalidName: false, code: "", invalidCode: false, invalidSpecialities: false });
	}

	//Fetch IDs from multi select checkbox
	createPayloadData(data) {
		const dataList = [];
		data.map(data => {dataList.push(data.value);});
		return dataList;
	}
	onChangeSpecialities = e => {
		const prevState = this.state;
		prevState.specialities = e;
		prevState.invalidSpecialities = false;
		this.setState(prevState);
	};

	render() {
		if (this.props.communitySection.communitySectionCreate === "FULFILLED") {
			if (this.state.saveAndNext === true) {
				this.handleNext();}
			else { this.handleCancel();}
			this.props.createClassCompleted(true);
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communitySectionAction.clearCreateCommunitySection());

		}
		if (this.props.communitySection.communitySectionCreate === "REJECTED") {
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communitySectionAction.clearCreateCommunitySection());
		}
		return ( 
			<Fragment>
				<div className="pole_create_section di w_100 padding-top-15 padding-bottom-0 ">
					<button 
						className="common_button margin_top_15_mob  margin-right-0 " 
						type="button" 
						onClick={this.createNewClass.bind(this)}>
						<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
						<I18 tkey="CREATE" />
					</button>
				</div> 
				<Modal
					className="re_assign_modal"
					title={i18Get("Create Community Section", utils.getLanguage())}
					visible={this.state.showSectionCreate}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}
					footer={[
						<Button key="submitNext" type="primary" loading={this.state.loadingNext} onClick={this.handleOk.bind(this, true)}>
							<span className="margin-left-10" >
								<I18 tkey="Save and Next" />
							</span>
						</Button>,
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk.bind(this, false)}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}>
					<Section
						name={this.state.name}
						nameChanged={this.nameChanged.bind(this)}
						invalidName={this.state.invalidName}
						code={this.state.code}
						codeChanged={this.codeChanged.bind(this)}
						invalidCode={this.state.invalidCode}
						onChangeSpecialities={this.onChangeSpecialities.bind(this)}
						specialities={this.state.specialities}
						invalidSpecialities={this.state.invalidSpecialities}
					/>
				</Modal>
			</Fragment>
		);
	}
}

CreateSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	communitySection: store.communitySection,
	translations: store.translations
}))(CreateSection));                    