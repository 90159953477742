import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../../../../i18";
import ClinicalPathwayProblemAction from "../../../../../../../../../actions/clinical-pathway-problems";
import utils from "../../../../../../../../../utils/utils";
import ReactMultiSelectCheckboxes from "../../../../../../../../../plug/react-multiselect-checkboxes";


class CheckList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathways: [],
			loadingSave: false,
			listOffset: 0,
			problemCount: 0,
			listNumResults: 1000,
			clinicalPathway: {},
			checkList: []
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.fetchDataFromServer();
	}
    
	fetchDataFromServer(search) {
		this.setState({ isListLoading: true });
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&`;
		queryParams += `sort=name&desc=0&${search ? `name=${search}&` : ""}`;
		queryParams += `clinicalPathwayType=${this.props.clinicalPathwayData.clinicalPathwayType}`;
		this.props.dispatch(ClinicalPathwayProblemAction.getAllCheckList(this.props.match.params.id, this.props.problemId, queryParams));
	}

	reloadCheckList = () => {
		this.fetchDataFromServer();
	};

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.clinicalPathways.map((data) => {
			prevState.options.push({ value: data.checkListTreatmentId, label: `${data.hospitalName ? data.hospitalName : ""} ${data.checkListTreatmentName}`, checkListProblemId: data.checkListProblemId });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchDataFromServer(search);
			}, 800);
		}
	}

	render() {
		if (this.props.clinicalPathwayProblems.fetchAllCheckList === "FULFILLED") {
			this.state.clinicalPathways = this.props.clinicalPathwayProblems.checkList;
			this.renderOptions();
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllCheckList());
		}

		return (
			<div>
				<div className="w_100 di react_multi_select_checkbox drop_max_height_150">
					<ReactMultiSelectCheckboxes
						value={this.props.data ? this.props.data : null}
						onChange={this.props.onChange.bind(this)}
						options={this.state.options}
						maxMenuHeight={250}
						isMulti={true}
						isClearable={true}
						onInputChange={this.onSearch.bind(this)}
						placeholderButtonLabel="Select Check List"
						noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
						loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }} 
					/>
				</div> 
			</div>
		);
	}

}

CheckList.propTypes = {
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations
}))(CheckList));