import React from "react";
import ReactTable from "./react-table";

import virtualizedTableHOC from "./virtualizedTableHOC";
const VirtualizedTable = virtualizedTableHOC(ReactTable);

export default class App extends React.Component {
	state = {
		scrollTo: -1
	};

	onPageSizeChanged(e) {
		this.state.scrollTo = 0;
		this.props.onPageSizeChange(e);
	}

	render() {
		const obj = { ...this.props, onPageSizeChange: this.onPageSizeChanged.bind(this) };
		return (
			<div>
				<VirtualizedTable {...obj} scrollTo={this.props.rowScrollIndex}
					style={{ height: this.props.height ? this.props.height : "400px" }}
				/>
			</div>
		);
	}
}
