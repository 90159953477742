import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { i18Get } from "../../../../../../../../i18";
import ReactMultiSelectCheckboxes from "../../../../../../../../plug/react-multiselect-checkboxes";
import utils from "../../../../../../../../utils/utils";
import ClinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import { withRouter } from "react-router";
import Select from "react-select";

class InterventionLocationDropDown extends Component {

	constructor(props) {
		super(props);

		this.state = {
			options: [],
			startDayOptions: [],
			offset: 0,
			numResults: 1000,
			isLoading: true,
			search: null,
			stepsAndSubsteps: [],
			additionalInfoId: ""
		};
	}

	UNSAFE_componentWillMount() {
		if (!this.props.isEdit) {
			this.fetchStepsAndSubStepsList();
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchStepsAndSubStepsList();
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchStepsAndSubStepsList(search);
			}, 800);
		}
	}

	fetchStepsAndSubStepsList() {
		this.setState({ isLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${this.state.search ? `&name=${this.state.search}` : ""}`;
		queryString += `${this.props.stepSectionType ? `&stepSectionType=${this.props.stepSectionType}` : ""}`;
		queryString += `${this.props.problemId ? `&healthMappingId=${this.props.problemId}` : ""}`;

		this.props.dispatch(ClinicalPathwayProblemAction.getAllStepsAndSubSteps(this.props.match.params.id, queryString));
	}

	getOptionLabel = data => {
		return `${i18Get(data.stepName, utils.getLanguage())} -> ${data.day ? i18Get("DAY", utils.getLanguage()) : ""} ${i18Get(data.subStepName, utils.getLanguage())}`;
	};


	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		prevState.startDayOptions = [];
		this.state.stepsAndSubsteps.map((data) => {
			prevState.options.push({ value: data.id + data.day, clpSubStepId: data.id, label: this.getOptionLabel(data), day: data.day, stepCode: data.stepCode, subStepCode: data.subStepCode });
			if (data.stepName === "AT INPATIENT UNIT" && data.day && this.props.isCreate) {
				prevState.startDayOptions.push({ ...data, value: data.id + data.day, clpSubStepId: data.id, label: `${data.day ? i18Get("DAY", utils.getLanguage()) : ""} ${i18Get(data.subStepName, utils.getLanguage())}`, day: data.day });
			}
		});
		prevState.isLoading = false;
		if (!this.props.data && this.props.autoSetSingleOne === true)
		{utils.setSingleOneAsDefaultOption(prevState.startDayOptions, this.props.onChange, true);}

		this.setState(prevState);
		if (this.props.isCreate) {
			this.props.renderDayOptions(prevState.startDayOptions);
		}
	}

	render() {
		if (this.props.clinicalPathwayProblems.getAllStepsAndSubStepsStatus === "FULFILLED") {
			this.state.stepsAndSubsteps = [];
			this.state.stepsAndSubsteps = this.props.clinicalPathwayProblems.stepsAndSubSteps.mapping;
			this.renderOptions();
			this.setState({ isLoading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllStepsAndSubSteps());
		}
		if (this.props.clinicalPathwayProblems.getAllStepsAndSubStepsStatus === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllStepsAndSubSteps());
		}
		if (this.props.isEdit && this.props.additionalInfoId !== this.state.additionalInfoId) {
			this.fetchStepsAndSubStepsList();
			this.setState({ additionalInfoId: this.props.additionalInfoId });
		}

		return (
			<div className={!this.props.disabled ? "w_100 di react_multi_select_checkbox drop_max_height_150" : "w_100 di"}>
				{
					!this.props.disabled ?
						<ReactMultiSelectCheckboxes
							value={this.props.data ? this.props.data : null}
							onChange={this.props.onChange}
							options={this.state.options}
							maxMenuHeight={220}
							isMulti={true}
							isClearable={true}
							onInputChange={this.onSearch.bind(this)}
							placeholderButtonLabel={i18Get("Select location of the interventions", utils.getLanguage())}
							noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
							loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
						/>
						:
						<Select
							isDisabled={true}
							placeholder={i18Get("Select location of the interventions", utils.getLanguage())}
						/>
				}
			</div>
		);
	}
}

InterventionLocationDropDown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(InterventionLocationDropDown));
