import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox } from "antd";
import eventNames from "../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../i18";
import elementsAction from "../../../../../../actions/elements";
import ActionButton from "../../../../../../plug/action-button";
import utils from "../../../../../../utils/utils";
import QuantityList from "../../ext-elements/top-section/quantity-list";
import elementsConstants from "../../../../../../constants/elements.json";

class UpdateElement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			invalidQuantity: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.props.dispatch(elementsAction.getOne(this.props.match.params.id, this.props.match.params.mappingId, this.props.type));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
	}

	validate() {
		const prevState = this.state;
		if (!this.state.data.quantity) {
			prevState.invalidQuantity = true;
		}
		if (this.state.invalidQuantity) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		if (this.validate()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				quantity: this.state.data.quantity,
				isOptional: !!this.state.data.isOptional,
				costOne: !!this.state.data.costOne
			};
			if (this.props.type !== elementsConstants.elements.STERILIZATION) {
				payload.isSubsidised = !!this.state.data.isSubsidised;
			}
			this.props.dispatch(elementsAction.updateElement(this.props.match.params.id, this.props.match.params.mappingId, this.props.type, payload));
		}
	}

	cancelClicked() {
		const doctorId = utils.checkForParameter("doctorId", this.props.location);
		const urlToPush = (this.props.location.pathname.substr(0, this.props.location.pathname.lastIndexOf("/")) + (doctorId ? (`?doctorId=${doctorId}`) : ""));
		this.props.history.push(urlToPush);
	}

	optionChanged(value, e) {
		const prevState = this.state;
		prevState.data[value] = e.target.checked;
		this.setState(prevState);
	}

	onQuantityChange(e) {
		const prevState = this.state;
		prevState.data.quantity = e;
		prevState.invalidQuantity = false;
		this.setState(prevState);
	}

	render() {
		if (this.props.elements.isElementGetOneCompleted === "FULFILLED") {
			this.state.data = this.props.elements.elementsDataOne;
			this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.data.id, name: this.state.data.name } });
			this.props.dispatch(elementsAction.clearGet());
		}
		if (this.props.elements.isElementGetOneCompleted === "REJECTED") {
			this.cancelClicked();
			this.props.dispatch(elementsAction.clearGet());
		}
		if (this.props.elements.isElementUpdateCompleted === "FULFILLED") {
			this.cancelClicked();
			this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.data.id, name: this.state.data.name } });
			this.props.dispatch(elementsAction.clearUpdate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<h5 className="medical_device_header"><I18 tkey={this.props.type.replace(/_/g, " ")} /></h5>
					<div className="row">
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-18">
								<input className="w_100 di form-control" value={this.state.data.name} readOnly />
							</div>
						</div>
						<div className="di w_100">
							{ !this.props.hideColumn && (
								<div className="col-md-3 col-sm-3">
									<div className="w_100 di margin-bottom-18">
										<Checkbox checked={this.state.data.isOptional} onChange={this.optionChanged.bind(this, "isOptional")}><I18 tkey="Is Optional" /></Checkbox>
									</div>
								</div>
							)}
							{ !this.props.hideColumn && (
								<div className="col-md-3  col-sm-3">
									<div className="w_100 di margin-bottom-18">
										<Checkbox checked={this.state.data.costOne} onChange={this.optionChanged.bind(this, "costOne")}><I18 tkey="Cost One" /></Checkbox>
									</div>
								</div>
							)}
							{(this.props.type !== elementsConstants.elements.STERILIZATION)
								? (
									<div className="col-md-3  col-sm-3">
										<div className="w_100 di margin-bottom-18">
											<Checkbox
												disabled
												checked={this.state.data.isSubsidised}
												onChange={this.optionChanged.bind(this, "isSubsidised")}
											>
												<I18 tkey="Is Subsidised" />
											</Checkbox>
										</div>
									</div>
								) : ""}
						</div>
						<div className="col-md-4 col-sm-4">
							<QuantityList quantity={this.state.data.quantity} quantityChanged={this.onQuantityChange.bind(this)} invalidQuantity={this.state.invalidQuantity} />
						</div>
						{/* <div className="col-md-4 col-sm-4">
                        <div className="w_100 di margin-bottom-18">
                            <label><I18 tkey="PRICE" /></label>
                        <input className="w_100 di form-control" value={this.state.data.price} readOnly />
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-4">
                        <div className="w_100 di margin-bottom-18">
                            <label><I18 tkey="TOTAL" /></label>
                        <input className="w_100 di form-control" value={this.state.data.total} readOnly />
                        </div>
                    </div> */}
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<button className="edit_delete_button cancel_button margin-right-15" onClick={this.cancelClicked.bind(this)}><I18 tkey="Cancel" /></button>
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

UpdateElement.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	elements: store.elements,
	translations: store.translations
}))(UpdateElement));
