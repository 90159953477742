import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Intervention from "../../v4/intervention";
import Definition from "../../v5/definition";
import Eligibility from "../../v5/eligibility";
import AuthorsAndActivities from "../../v5/authors-activities";
import Staff from "../../v5/staff";
import "../../v5/v5-details.css";
import Anaesthesia from "../../v5/anaesthesia";

class HeaderSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervention: {},
			treatmentId: null,
			showIntervention: false,
			selElementId: null,
			type: null
		};
	}

	editClicked(link) {
		this.props.history.push(`${this.props.location.pathname}/sub_clinical_pathway/${link}`);
	}

	render() {
		this.state.showIntervention = this.props.showIntervention;
		return (
			<div className="pathway_scrollable_content_1">

				<div id="details_1" className="table_section di w_100">
					<AuthorsAndActivities type="AUTHORS_AND_ACTIVITIES" editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData={this.props.clinicalPathwayData} />
				</div>
				<div className="">
					<div className="text_align_right di w_100   margin-bottom-10" />
				</div>
				<div id="details_2" className="table_section di w_100">
					<Definition type="PATHWAY_DEFINITION" editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} isCheckList={this.props.clinicalPathwayData.isCheckList} clinicalPathwayData={this.props.clinicalPathwayData}/>
				</div>
				{!this.props.clinicalPathwayData.isCheckList &&
					<div id="details_3" className="table_section di w_100">
						<Eligibility type="PATHWAY_ELIGIBILITY" editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData={this.props.clinicalPathwayData} />
					</div>
				}
				<div id="details_5" className="table_section di w_100">
					<Staff type="PATHWAY_STAFF" editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData={this.props.clinicalPathwayData} />
				</div>
				{this.state.showIntervention && !this.props.clinicalPathwayData.isCheckList
					&& <div>
						<div id="details_4" className="table_section di w_100">
							<Intervention type="Intervention" editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData={this.props.clinicalPathwayData} />
						</div>
						<div id="details_4" className="table_section di w_100">
							<Anaesthesia type='Anaesthesia' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} clinicalPathwayData={this.props.clinicalPathwayData} />
						</div>
					</div>
				}
				<div className="">
					<div className="text_align_right di w_100   margin-bottom-10" />
				</div>
			</div>
		);
	}
}

HeaderSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(HeaderSection));
