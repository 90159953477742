import eventNames from "../../actions/events";

const initialState = {
	activityStepsData: {},
	isFetchAllActivityStepsTypeCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.ACTIVITY_STEPS_GET_ALL_NAME}_REJECTED`:
			return {
				...state,
				isFetchAllActivityStepsNameCompleted: "REJECTED"
			};

		case `${eventNames.ACTIVITY_STEPS_GET_ALL_NAME}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPagesName: action.payload.data.data.count,
					activityStepsName: action.payload.data.data.data,
					isFetchAllActivityStepsNameCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				activityStepsName: [],
				isFetchAllActivityStepsNameCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ACTIVITY_STEPS_FETCH_NAME:
			return {
				...state,
				isFetchAllActivityStepsNameCompleted: false
			};
		case `${eventNames.ACTIVITY_STEPS_GET_ALL_TYPE}_REJECTED`:
			return {
				...state,
				isFetchAllActivityStepsTypeCompleted: "REJECTED"
			};

		case `${eventNames.ACTIVITY_STEPS_GET_ALL_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					totalPagesType: action.payload.data.data.count,
					activityStepsType: action.payload.data.data.data,
					isFetchAllActivityStepsTypeCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				activityStepsType: [],
				isFetchAllActivityStepsTypeCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_ACTIVITY_STEPS_FETCH_TYPE:
			return {
				...state,
				isFetchAllActivityStepsTypeCompleted: false
			};

		case `${eventNames.FETCH_ONE_ACTIVITY_STEPS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneActivityStepsCompleted: "FULFILLED",
					selectedActivitySteps: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneActivityStepsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_ACTIVITY_STEPS}_REJECTED`:
			return {
				...state,
				isFetchOneActivityStepsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_ACTIVITY_STEPS:
			return {
				...state,
				isFetchOneActivityStepsCompleted: false
			};
		case `${eventNames.UPDATE_ACTIVITY_STEPS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateActivityStepsCompleted: "FULFILLED",
					updatedActivitySteps: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateActivityStepsCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_ACTIVITY_STEPS}_REJECTED`:
			return {
				...state,
				isUpdateActivityStepsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_ACTIVITY_STEPS:
			return {
				...state,
				isUpdateActivityStepsCompleted: false
			};

		case `${eventNames.CREATE_ACTIVITY_STEPS_NAME}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateActivityStepsNameCompleted: "FULFILLED",
					createdActivityStepsName: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateActivityStepsNameCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_ACTIVITY_STEPS_NAME}_REJECTED`:
			return {
				...state,
				isCreateActivityStepsNameCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_ACTIVITY_STEPS_NAME:
			return {
				...state,
				isCreateActivityStepsNameCompleted: false
			};

		case `${eventNames.CREATE_ACTIVITY_STEPS_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateActivityStepsTypeCompleted: "FULFILLED",
					createdActivityStepsType: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateActivityStepsTypeCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_ACTIVITY_STEPS_TYPE}_REJECTED`:
			return {
				...state,
				isCreateActivityStepsTypeCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_ACTIVITY_STEPS_TYPE:
			return {
				...state,
				isCreateActivityStepsTypeCompleted: false
			};

		case `${eventNames.DELETE_ACTIVITY_STEPS_NAME}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteActivityStepsNameCompleted: "FULFILLED",
					isDeleteNameSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteActivityStepsNameCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_ACTIVITY_STEPS_NAME}_REJECTED`:
			return {
				...state,
				isDeleteActivityStepsNameCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_ACTIVITY_STEPS_NAME:
			return {
				...state,
				isDeleteActivityStepsNameCompleted: false
			};

		case `${eventNames.DELETE_ACTIVITY_STEPS_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteActivityStepsTypeCompleted: "FULFILLED",
					isDeleteTypeSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteActivityStepsTypeCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_ACTIVITY_STEPS_TYPE}_REJECTED`:
			return {
				...state,
				isDeleteActivityStepsTypeCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_ACTIVITY_STEPS_TYPE:
			return {
				...state,
				isDeleteActivityStepsTypeCompleted: false
			};
		default:
			return state;
	}
}
