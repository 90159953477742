import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox } from "antd";
import Select from "react-select";
import isEqual from "lodash.isequal";
import unionWith from "lodash.unionwith";
import I18, { i18Get } from "../../../../../../i18";
import eventNames from "../../../../../../actions/events";
import utils from "../../../../../../utils/utils";
import QuantityList from "../../ext-elements/top-section/quantity-list";
import ActionButton from "../../../../../../plug/action-button";
import elementsAction from "../../../../../../actions/elements";
import elementsConstants from "../../../../../../constants/elements.json";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import StatusDropdown from "../../intervention/statusDropDown";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class ElementsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidQuantity: false,
			listOffset: 0,
			listNumresults: 100,
			isListLoading: true,
			isSubsidised: false,
			quantity: 1,
			category: "STANDARD",
			search: ""
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.addClicked();
		}
	}

	checkBoxChanged(e) {
		this.setState({ isSubsidised: e.target.checked });
	}

	addClicked() {
		if (this.validate()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				elementId: this.state.selectedData.value,
				type: this.props.type,
				quantity: this.state.quantity,
				isSubsidised: this.state.isSubsidised,
				isOptional: false,
				costOne: false
			};
			if (this.props.doctorId) {
				payload.doctorId = this.props.doctorId;
			}
			this.props.dispatch(elementsAction.add(this.props.type.toUpperCase(), this.props.match.params.id, payload));
		}
	}

	validate() {
		const prevState = this.state;
		if (!this.state.selectedData) {
			prevState.invalidDataselected = true;
		}
		if (!this.state.quantity) {
			prevState.invalidQuantity = true;
		}
		if (prevState.invalidDataselected || prevState.invalidQuantity) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	onQuantityChange(e) {
		this.setState({ quantity: e, invalidQuantity: false });
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.data) {
			this.state.data.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, isSubsidised: data.isSubsidised });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onFetchListData(search) {
		this.setState({ isListLoading: true });
		const category = (this.props.type !== elementsConstants.elements.STERILIZATION) ? `category=${this.state.category}` : "";
		this.props.dispatch(elementsAction.getAll(this.state.listOffset, this.props.type, this.state.listNumresults, "name", 0, category, search, this.props.doctorId, this.props.match.params.id));
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumresults;
		this.onFetchListData();
	}

	onOptionsChange(e) {
		this.setState({ selectedData: e, invalidDataselected: false, isSubsidised: !!((e && e.isSubsidised)) });
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.onFetchListData(value);
			}, 700);
		}
	}

	clearState() {
		this.state.data = [];
		this.state.options = [];
		this.state.selectedData = "";
		this.state.quantity = 1;
		this.state.isSubsidised = false;
		this.state.listOffset = 0;
		this.state.invalidDataselected = false;
		this.state.invalidQuantity = false;
		this.setState(this.state);
	}

	renderFilter() {
		return (
			<div className="d-flex align-items-end justify-content-between">
				<div className="w_50 d-flex align-items-end padding-bottom-5">
					<div className="col-md-6 padding-left-0">
						<label>
							<I18 tkey="Status" />
						</label>
						<StatusDropdown
							data={this.props.status}
							onStatusChange={this.props.onStatusChange.bind(this)}
						/>
					</div>
					<div className="col-md-6">
						<label>
							<I18 tkey="Hospital" />
						</label>
						<HospitalSelect
							data={this.props.hospital}
							hospitalChanged={this.props.hospitalChanged.bind(this)}
						/>
					</div>
				</div>
			</div>

		);
	}

	render() {
		if (this.state.type !== this.props.type) {
			this.state.listOffset = 0;
			this.state.type = this.props.type;
			this.clearState();
			this.onFetchListData();
		}

		if (this.props.elements.iselementAddCompleted === "FULFILLED") {
			this.clearState();
			this.onFetchListData();
			this.props.dispatch(elementsAction.clearAdd());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.props.onAdd();
		}

		if (this.props.elements.isFetchAllDropDownDataCompleted === "FULFILLED") {
			if (this.props.elements.dropDownData.length === 0 || (this.props.elements.dropDownData[0] && (this.props.elements.dropDownData[0].type === this.state.type))) {
				this.state.data = unionWith(this.state.data, this.props.elements.dropDownData, isEqual);
			} else {
				this.state.data = [];
			}
			this.renderOptions();
			this.props.dispatch(elementsAction.cleargetAll());
		}

		if (this.props.elements.iselementAddCompleted === "REJECTED" || this.props.elements.isElementDeleteCompleted === "REJECTED") {
			this.state.listOffset = 0;
			this.state.options = [];
			this.onFetchListData();
			this.props.dispatch(elementsAction.clearAdd());
			this.props.dispatch(elementsAction.clearDelete());
		}

		if (this.props.elements.isElementDeleteCompleted === "FULFILLED") {
			this.state.listOffset = 0;
			this.state.data = [];
			this.state.options = [];
			this.props.onAdd();
			this.onFetchListData();
		}

		return (
			<div className="">
				{this.renderFilter()}
				<div className="medical_device_box">
					<div className="row">
						<div className="col-md-4 col-sm-4 margin-bottom-20">
							<div className="di w_100 position_relative">
								<label className=""><I18 tkey={`Select ${utils.toPascalCase(this.props.type.replace(/_/g, " "))}`} /></label>
								<Select
									styles={customStyles}
									isSearchable
									className="w100"
									onChange={this.onOptionsChange.bind(this)}
									value={this.state.selectedData}
									options={this.state.options}
									isClearable
									onMenuScrollToBottom={this.onListScroll.bind(this)}
									onInputChange={this.onSearch.bind(this)}
									isLoading={this.state.isListLoading}
									noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
									loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
									placeholder={i18Get("Select", utils.getLanguage())}
								/>
								{this.state.invalidDataselected
									? (
										<span className="invalid_right fixed_label special_case">
											<I18 tkey={`Select valid ${this.props.type.toLowerCase().replace(/_/g, " ")}`} />
										</span>
									) : ""}
							</div>
						</div>
						<div className="col-md-4 col-sm-4 margin-bottom-20">
							<QuantityList
								quantity={this.state.quantity}
								quantityChanged={this.onQuantityChange.bind(this)}
								invalidQuantity={this.state.invalidQuantity}
							/>
						</div>
						<div className="col-md-4 col-sm-4 margin-bottom-20 margin_top_26mob">
							{(this.props.type !== elementsConstants.elements.STERILIZATION)
								? (
									<div className="di">
										<div className="check_box_container margin-top-5 float_right">
											<Checkbox
												disabled
												onChange={this.checkBoxChanged.bind(this)}
												checked={this.state.isSubsidised}
											/>
											<span className="margin-left-5">
												<I18 tkey="Is Subsidised" />
											</span>
										</div>
									</div>
								) : ""}
							<div className="float_right di">
								<ActionButton
									showAdd
									onClick={this.addClicked.bind(this)}
									value={i18Get("ADD", utils.getLanguage())}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ElementsList.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	elements: store.elements,
	translations: store.translations
}))(ElementsList));
