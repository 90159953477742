import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getElementMapping = (id, queryString) => ({
	type: events.FETCH_ALL_ELEMENT_MAPPING,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/element_mappings${queryString ? `?${queryString}` : ""}`)
});

const clearGetElementMapping = () => ({
	type: events.CLEAR_FETCH_ALL_ELEMENT_MAPPING,
	payload: {}
});

const submitElementMapping = (id, payload, queryString) => ({
	type: events.SUBMIT_ELEMENT_MAPPING,
	payload: axios.post(`${Config.apiRootSecure}/treatments/${id}/element_mappings${queryString ? `?${queryString}` : ""}`, { ...payload })
});

const clearSubmitElementMapping = () => ({
	type: events.CLEAR_SUBMIT_ELEMENT_MAPPING,
	payload: {}
});

export default {
	getElementMapping,
	clearGetElementMapping,
	submitElementMapping,
	clearSubmitElementMapping
};
