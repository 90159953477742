import I18 from "i18";
import React, { useEffect, useState } from "react";
import EditData from "./edit";
import Summary from "./summary";

function ConnectorSettings(props) {
	const [tabSwitching, setTabSwitching] =  useState("Data");
	const tabSwitcher = (tab) => { setTabSwitching(tab);};

	// useEffect( () => {
	// 	if (props.diagramData) {
	// 		!props.diagramData.title &&  tabSwitching === "Summary" ? setTabSwitching("Data") : "";
	// 	} else {
	// 		setTabSwitching("Data");
	// 	}
	// }, [props.diagramData]);
	return <div>
		<ul className="sideBar-tab">
			{/* <li data-active={tabSwitching === "Summary"} onClick={ () => tabSwitcher("Summary")}><I18 tkey="Summary" /> </li> */}
			<li data-active={tabSwitching === "Data"} onClick={ () => tabSwitcher("Data")}><I18 tkey="Edit" /> </li>
			{/* <li data-active={tabSwitching === "Attributes"} onClick={() => tabSwitcher("Attributes")}><I18 tkey="Attributes" /> </li> */}
			
		</ul>
		<div className="sideBar-body">
			{tabSwitching === "Data" ? <EditData  onChange={ props.onChange } diagramData={props.diagramData} selectedShape={props.selectedShape}  /> : ""}
			{tabSwitching === "Summary" ? <Summary diagramData={props.diagramData}/> : ""}
		</div>
	</div>;
}
export default ConnectorSettings;