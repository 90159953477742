import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	communitySitesCreate: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.CREATE_COMMUNITY_SITES}_FULFILLED`:
			return {
				...state,
				communitySites: action.payload.data.data,
				communitySitesCreate: "FULFILLED"
			};
		case `${eventNames.CREATE_COMMUNITY_SITES}_REJECTED`:
			return {
				...state,
				communitySitesCreate: "REJECTED"
			};
		case `${eventNames.CLEAR_CREATE_COMMUNITY_SITES}`:
			return {
				...state,
				communitySitesCreate: false
			};

		case `${eventNames.COMMUNITY_SITES_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllcommunitySitesCompleted: "REJECTED"
			};

		case `${eventNames.COMMUNITY_SITES_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					count: action.payload.data.data.count,
					communitySites: action.payload.data.data.sites,
					isFetchAllcommunitySitesCompleted: "FULFILLED"
				};
			}
			return {
				...state,
				communitySites: [],
				isFetchAllcommunitySitesCompleted: "FULFILLED",
				fetchAllElementClassMeta: null
			};
		case eventNames.CLEAR_COMMUNITY_SITES_FETCH:
			return {
				...state,
				isFetchAllcommunitySitesCompleted: false,
				fetchAllElementClassMeta: null
			};
		case `${eventNames.DELETE_COMMUNITY_SITES}_REJECTED`:
			return {
				...state,
				deleteCommunitySitesComplete: "REJECTED"
			};

		case `${eventNames.DELETE_COMMUNITY_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteCommunitySitesComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteCommunitySitesComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_COMMUNITY_SITES:
			return {
				...state,
				deleteCommunitySitesComplete: false
			};

		case `${eventNames.COMMUNITY_SITES_GET_ONE}_PENDING`:
			return {
				...state,
				fetchOneCommunitySitesComplete: "PENDING"
			};
		case `${eventNames.COMMUNITY_SITES_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchOneCommunitySitesComplete: "FULFILLED",
					communitySites: action.payload.data.data
				};
			}
			return {
				...state,
				fetchOneCommunitySitesComplete: "FULFILLED"
			};
		case `${eventNames.COMMUNITY_SITES_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneCommunitySitesComplete: "REJECTED"
			};
		case eventNames.CLEAR_COMMUNITY_SITES_GET_ONE:
			return {
				...state,
				fetchOneCommunitySitesComplete: false
			};

		case `${eventNames.UPDATE_COMMUNITY_SITES}_PENDING`:
			return {
				...state,
				updateCommunitySitesComplete: "PENDING"
			};
		case `${eventNames.UPDATE_COMMUNITY_SITES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateCommunitySitesComplete: "FULFILLED",
					updatedcommunitySites: action.payload.data.data
				};
			}
			return {
				...state,
				updateCommunitySitesComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_COMMUNITY_SITES}_REJECTED`:
			return {
				...state,
				updateCommunitySitesComplete: "REJECTED"
			};
		case eventNames.UPDATE_COMMUNITY_SITES_COMPLETED:
			return {
				...state,
				updateCommunitySitesComplete: false
			};
		case eventNames.FILTER_CHANGE_COMMUNITY_SITES:
			return {
				...state,
				filterChangedcommunitySites: action.payload.filter,
				ignorePageChangecommunitySites: action.payload.ignorePageChange
			};
		default:
			return state;
	}
}
