import React, { useState, useEffect } from "react";
import ShowHide from "./showhide";
import "./style.css";
import events from "../../actions/events";
import selectedTableColumnsList from "./columns.json";
import { useDispatch } from "react-redux";
function showHideColumns (props) {

	const dispatch = useDispatch();
	const [selectedTableColumns, setSelectedTableColumns] = useState({
		check: {},
		checkAll: true,
		intermediate: false
	});

	useEffect(() => {
		setColumanSetings();
			
	}, [props.columnKey, props.storageKey]);

	const setColumanSetings = () => {

		if (localStorage.getItem(props.storageKey)) {
			const localStoreTableColumns = JSON.parse(
				localStorage.getItem(props.storageKey)
			);
			onChangeColSelect(localStoreTableColumns);
		} else {
			checkAll();
		}
	};

	const onChangeColSelect = (value) => {

		setSelectedTableColumns(value);
		localStorage.setItem(props.storageKey, JSON.stringify(value));
		dispatch({
			type: events[props.eventKey],
			payload: { value }
		});
	};

	const checkAll = () => {
		const prevstate = { check: {}, checkAll: true, intermediate: false };
		selectedTableColumnsList[props.columnKey].labels.map(item => {
			if (!selectedTableColumnsList[props.columnKey].nonActive.includes(item)) {
				prevstate.check[item] = true;
				prevstate.intermediate = false;
				prevstate.checkAll = true;
			}
		});

		onChangeColSelect(prevstate);
	};
	return (<ShowHide  selectedColumns={selectedTableColumns} onChangeColSelect={onChangeColSelect} columnKey={props.columnKey} />);
	
}
export default showHideColumns;
