import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

// var fetchAllScope = (pageNumber, numResults, component, companyLocation, companyName, sort, desc) => ({
//     type: events.FETCH_ALL_SCOPE,
//     payload: axios.get(`${Config.apiRootSecure}/scope?pageNumber=${pageNumber}&numResults=${numResults}&component=${component}&company_location=${companyLocation}&company_name=${companyName}${sort ? '&sort=' + sort : ''}&desc=${desc}`),
// });

// var clearFetchAllScope = () => ({
//     type: events.CLEAR_FETCH_ALL_SCOPE,
//     payload: {},
// });

const updateScope = (payload) => ({
	type: events.UPDATE_SCOPE,
	payload: axios.put(`${Config.apiRootSecure}/scope`, { ...payload })
});

const userScope = (queryParams) => ({
	type: events.USER_SCOPE_FETCH,
	payload: axios.get(`${Config.apiRootSecure}/scope?${queryParams}`)
});

const clearUpdateScope = () => ({
	type: events.CLEAR_UPDATE_SCOPE,
	payload: {}
});

const deleteScope = (scopeId) => ({
	type: events.DELETE_SCOPE,
	payload: axios.delete(`${Config.apiRootSecure}/scope/${scopeId}`)
});

const clearDeleteScope = () => ({
	type: events.CLEAR_DELETE_SCOPE,
	payload: {}
});

export default {
	// fetchAllScope,
	// clearFetchAllScope,
	updateScope,
	clearUpdateScope,
	deleteScope,
	clearDeleteScope,
	userScope
};
