import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Steps, Button, Checkbox, Modal, Select } from "antd";
import isEqual from "lodash.isequal";
import unionWith from "lodash.unionwith";
import I18, { i18Get } from "../../../../../../../i18";
import healthPathwayAction from "../../../../../../../actions/health-pathway";
import healthPathwayDetailsAction from "../../../../../../../actions/healthPathway-details";
import utils from "../../../../../../../utils/utils";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import diagramActions from "../../../../../../../actions/sync-fusion";
import { NodeConstraints, ConnectorConstraints } from "@syncfusion/ej2-react-diagrams";

const { Step } = Steps;
class CopyFromHealthPathway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			healthPathways: [],
			selectedData: [],
			genericComponents: {},
			healthPathway: {},
			activeComponents: [],
			selectAll: false,
			copyLoading: false,
			listOffset: 0,
			listNumResults: 100,
			renderList: [],
			componentsForSelection: {},
			stepLoader: false,
			steps: [],
			checkAll: false,
			sourceDiagram: {},
			destinationDiagram: {},
			healthPathwayFilterCategory: this.props.healthPathwayData.isALL ? "ALL" : this.props.healthPathwayData.isMom ? "MOM" : "LOCAL",
			diagramValidation: "",
			isLoading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.healthPathway = this.props.healthPathwayData;
		this.fetchList();
		const metaData = "destination";
		this.props.dispatch(diagramActions.getMetaDiagramData(this.state.healthPathway.id, metaData));
	}

	fetchList(search) {
		this.setState({ isListLoading: true });
		const searchType = this.state.healthPathwayFilterCategory ? this.state.healthPathwayFilterCategory : "";
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&`;
		queryParams += `sort=name&desc=0&${search ? `name=${search}&` : ""}`;
		//queryParams += "ignoreExisting=true&hospitalId=null&";
		queryParams += "ignoreExisting=true&"; //This chnages for Copy Health pathway
		queryParams += `treatmentId=${this.props.match.params.id}&`;
		// queryParams += this.state.healthPathway.clinicalPathway ? `clinicalPathwayType=${this.state.healthPathway.clinicalPathway}` : "";
		queryParams += this.state.healthPathwayFilterCategory ? `searchType=${searchType}` : "";
		this.props.dispatch(healthPathwayAction.getAll(this.props.match.params.organization, queryParams));
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= 767 });
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumResults;
		this.fetchList();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchList(value);
			}, 700);
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.healthPathways.map((data) => {
			prevState.options.push({ value: data.id, label: `${data.hospitalId ? data.hospitalCode : data.hospitalName ? data.hospitalName : "MOM"} ${data.name}`, code: data.code, data: data });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onChange(component, e) {
		this.setState({ [component]: e.target.checked, invalidComponents: false });
	}

	onOptionsChange(e, options) {
		const selectedData = options;
		this.setState({ selectedData, invalidHealthPathway: false, isLoading: true });
		if (selectedData && selectedData.value > 0)
		{
			const metaData = "source";
			this.props.dispatch(diagramActions.getMetaDiagramData(selectedData.value, metaData));
			this.props.dispatch(diagramActions.getDiagramCopyValidation(selectedData.value, this.props.match.params.id));
		} else {
			this.setState({ sourceDiagram: {}, diagramValidation: "", isLoading: false });
		}
	}

	prevClicked() {
		this.setState({ activeStep: this.state.activeStep - 1 });
	}

	nextClicked() {
		if (this.checkActiveComponents()) {
			this.setState({ activeStep: this.state.activeStep + 1 });
		}
	}

	checkActiveComponents() {
		if (!this.state.selectedData || !this.state.selectedData.value) {
			this.state.invalidHealthPathway = true;
		}
		if (Object.values(this.state.genericComponents).includes(!"")) {
			this.state.invalidComponents = false;
		} else {
			this.state.invalidComponents = true;
		}
		if (this.state.invalidComponents || this.state.invalidHealthPathway) {
			this.setState(this.state);
			return false;
		}
		return true;
	}

	onCheckBoxChange(e, value) {
		if (e.target.checked) {
			this.state.genericComponents[value] = true;
			this.state.invalidComponents = false;
			this.state.checkAll = false;
		} else {
			this.state.genericComponents[value] = "";
			this.state.checkAll = false;
		}
		this.setState(this.state);
	}

	onChangeCheckAll(e) {
		if (e.target.checked) {
			this.state.checkAll = true;
			this.state.invalidComponents = false;
			this.state.genericComponents.HEALTH_DEFINITION = true;
			this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM = true;
			this.state.genericComponents.HEALTH_REMARKS = true;
		} else {
			this.state.checkAll = false;
			this.state.genericComponents.HEALTH_DEFINITION = false;
			this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM = false;
			this.state.genericComponents.HEALTH_REMARKS = false;
		}
		this.setState(this.state);
	}

	toggleHealthPathwayCategory = (filterType) => {
		const prevState = this.state;
		prevState.healthPathwayFilterCategory = filterType;
		prevState.selectedData = null;
		prevState.healthPathways = [];
		prevState.listOffset = 0;
		prevState.diagramValidation = "";
		this.setState(prevState, () => {
			this.fetchList();
		});
	};

	//Set State with delay
	delaySetState = () => { setTimeout(() => { this.setState({ isLoading: false });}, 5000); };

	render() {

		if (this.props.syncfusion.diagramValidationCompleted === "FULFILLED") {
			const prevState = this.state;

			prevState.diagramValidation = this.props.syncfusion.diagramValidationInfo;
			this.props.dispatch(diagramActions.clearGetDiagramCopyValidation());

			if (!prevState.diagramValidation) {
				prevState.isLoading = false;
			}
			this.setState(prevState);
			this.delaySetState();
			
			  
		}

		if (this.props.syncfusion.diagramValidationCompleted === "REJECTED") { 
			this.props.dispatch(diagramActions.clearGetDiagramCopyValidation());
			const prevState = this.state;
			prevState.isLoading = false;
			this.setState(prevState);
		}

		if (this.props.syncfusion.metaDiagramCompleted === "FULFILLED") {
			const prevState = this.state;

			const { data } = this.props.syncfusion.metaDiagram;

			if (data) {
				if (this.props.syncfusion.meta === "destination") {

					prevState.destinationDiagram = data;
					//console.log(">>>>destinationDiagram>>>>>>>>>>>>>>>>>>", prevState.destinationDiagram);
				} else {

					prevState.sourceDiagram = data;
					//console.log(">>>>sourceDiagram>>>>>>>>>>>>>>>>>>", prevState.sourceDiagram);
				}
			}
			this.props.dispatch(diagramActions.clearMetaGetDiagramData());
		}

		if (this.props.syncfusion.metaDiagramCompleted === "FULFILLED") { 
			this.props.dispatch(diagramActions.clearMetaGetDiagramData());
		}
		if (this.props.healthPathway.fetchAllHealthPathway === "FULFILLED") {
			this.state.healthPathways = this.props.healthPathway.healthPathway.data;        
			this.renderOptions();
			this.props.dispatch(healthPathwayAction.clearGetAll());
		}

		if (this.props.healthPathwayDetails.copyFromHealthPathway === "FULFILLED") {
			this.state.selectedData = {};
			this.state.activeStep = 0;
			this.state.copyLoading = false;
			this.props.closeCopy();
			this.props.dispatch(healthPathwayDetailsAction.clearCopyFromHealthPathway());
		}

		const directionProp = this.state.isMobile ? { direction: "vertical" } : "";
		return (
			<div>
				<Steps className="margin-top-20" {...directionProp} progressDot current={this.state.activeStep}>
					<Step key="source" title={i18Get("Select source", utils.getLanguage())} />
					<Step key="summary" title={i18Get("Summary", utils.getLanguage())} />
				</Steps>
				{this.state.activeStep === 0 && (
					<div className="steps-content padding-15">
						<div className="row margin-bottom-20">
							<div className="col-md-3"><span className="steps_label_special_copy_from_source font_size_13px_label"><I18 tkey="Select source" /></span></div>
							<div className="col-md-9">
								<div className="btn-group spacer-top-0">
									<button type="button" onClick={() => this.toggleHealthPathwayCategory("ALL")} className={`width_50 uppercase left-button ${this.state.healthPathwayFilterCategory === "ALL" ? "with_border active-btton" : "with_border"}`}>
										<I18 tkey='ALL' />
									</button>
									<span>
										<button type="button" data-toggle="dropdown" onClick={() => this.toggleHealthPathwayCategory("MOM")} className={`width_100 uppercase center_btn ${this.state.healthPathwayFilterCategory === "MOM" ? "with_border active-btton" : "with_border"}`}>
											<I18 tkey='MOM' />
										</button>
										<button type="button" data-toggle="dropdown" onClick={() => this.toggleHealthPathwayCategory("LOCAL")} className={`width_100 uppercase right-button  ${this.state.healthPathwayFilterCategory === "LOCAL" ? "with_border active-btton" : "with_border"}`}>
											<I18 tkey='HOSPITAL' />
										</button>
									</span>
								</div>
								<div className="w_100 di position_relative padding-top-25-steps">
									<Select
										showSearch
										className="w100"
										onChange={this.onOptionsChange.bind(this)}
										value={this.state.selectedData}
										options={this.state.options}
										onMenuScrollToBottom={this.onListScroll.bind(this)}
										onSearch={this.onSearch.bind(this)}
										isLoading={this.state.isListLoading}
										loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
										placeholder={i18Get("Select", utils.getLanguage())}
										optionLabelProp="label"
										defaultActiveFirstOption={false}
										filterOption={false}
									/>
									{this.state.invalidHealthPathway && <span className="invalid fixed_label invalid_select_pathway"><I18 tkey="Select health Pathway" /></span>}
								</div>
							</div>
						</div>
						<div className="component_heading  di w_100"><I18 tkey="Components" /></div>
						<div className="copy_scroll position_relative">
							<div className="margin-top-15 position_relative">
								{this.state.invalidComponents && <span className="invalid fixed_label invalid_select_component"><I18 tkey="Select Components" /></span>}
								{this.state.selectedData  && this.state.selectedData.value
									? (
										<div className="">
											<div>
												<span className="margin-left-10">
													<Checkbox
														checked={this.state.checkAll}
														onChange={(e) => this.onChangeCheckAll(e, "ALL")}
													/>
													<span className="margin-left-10"><I18 tkey="ALL" /></span>
												</span>
											</div>
											<span className="margin-left-10">
												<Checkbox
													checked={this.state.genericComponents.HEALTH_DEFINITION}
													onChange={(e) => this.onCheckBoxChange(e, "HEALTH_DEFINITION")}
												/>
												<span className="margin-left-10"><I18 tkey="DEFINITION" /></span>
											</span>
											<span className="margin-left-10">
												<Checkbox
													checked={this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM}
													onChange={(e) => this.onCheckBoxChange(e, "HEALTH_PATHWAY_DIAGRAM")}
												/>
												<span className="margin-left-10"><I18 tkey="PATHWAY" /></span>
											</span>
											<span className="margin-left-10">
												<Checkbox
													checked={this.state.genericComponents.HEALTH_REMARKS}
													onChange={(e) => this.onCheckBoxChange(e, "HEALTH_REMARKS")}
												/>
												<span className="margin-left-10"><I18 tkey="REMARKS" /></span>
											</span>
										</div>
									) : ""}
							</div>
						</div>
					</div>
				)}
				{this.state.activeStep === 1 && (
					<div>
						{this.getActiveData()}
					</div>
				)}
				{this.state.diagramValidation && <div className="color_red"><I18 tkey={this.state.diagramValidation} /></div>}
				{this.props.isCopyBlocked && <div className="color_red"><I18 tkey="You cannot copy another healthPathway to this one as it is editing by some other user" /></div>}
				<div className="steps-action di w_100 text_align_right">
					{this.state.activeStep === 1 && <Button loading={this.state.copyLoading} type="primary" className="margin-right-10" onClick={this.confirmationAboutCopyDiagramDataReplacement.bind(this)}><I18 tkey="Copy" /></Button>}
					{ this.state.selectedData && this.state.selectedData.value && this.state.healthPathway && this.state.healthPathway.hospitalId !== this.state.selectedData.data.hospitalId ? <div className="information"><I18 tkey="Hyperlinks will be ignored while copying" /></div> : "" }
					{this.state.activeStep > 0 && <Button type="primary" className="margin-right-10" onClick={this.prevClicked.bind(this)} ><I18 tkey="Prev" /></Button>}
					{this.state.activeStep < 1 && <Button type="primary" className="margin-right-10" onClick={this.nextClicked.bind(this)}  disabled={ this.state.isLoading || this.props.isCopyBlocked}><I18 tkey="Next" /></Button>}
				</div>
			</div>
		);
	}

	getActiveData() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-10 padding-bottom-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey="Source" /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey="Code" /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.selectedData ? this.state.selectedData.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey="Name" /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">
											{" "}
											{this.state.selectedData.label}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-10 padding-bottom-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey="Destination" /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey="Code" /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.healthPathway ? this.state.healthPathway.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey="Name" /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">
											{" "}
											{this.state.healthPathway ? this.state.healthPathway.name : ""}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="steps-content padding-15">
					<div className="row">
						<div className="col-md-3 margin-bottom-10"><div className="component_heading  di w_100 margin-bottom-10"><I18 tkey="Components" /></div></div>
						<div className="col-md-12 copy_scroll">
							<div className="col-md-12 margin-bottom-10">
								{" "}
								{this.state.genericComponents.HEALTH_DEFINITION ? <I18 tkey="DEFINITION" /> : ""}
							</div>
							<div className="col-md-12 margin-bottom-10">
								{" "}
								{this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM ? <I18 tkey="PATHWAY" /> : ""}
							</div>
							<div className="col-md-12 margin-bottom-10">
								{" "}
								{this.state.genericComponents.HEALTH_REMARKS ? <I18 tkey="REMARKS" /> : ""}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	isDiagramDataEmptyOrNot(data) {
		let isDiagramEmpty = true;
		if (data && JSON.stringify(data) !== "{}") {
			const diagramJson = JSON.parse(data);
			const diagramData = {
				...diagramJson,
				nodes: diagramJson.nodes.map(node => {
					// node.constraints = NodeConstraints.PointerEvents | NodeConstraints.ReadOnly;
					node.constraints = NodeConstraints.ReadOnly
						| NodeConstraints.Default
						& ~NodeConstraints.Rotate
						& ~NodeConstraints.Select
						& ~NodeConstraints.Resize;
					return node;
				}),
				connectors: diagramJson.connectors.map(connector => {return { ...connector, constraints: ConnectorConstraints.None };})
			};
			const diagramJsonData = diagramData;

			if ((diagramJsonData.nodes && diagramJsonData.nodes.length) ||
				(diagramJsonData.connectors && diagramJsonData.connectors.length)) {
				isDiagramEmpty = false;
			}
		}
		return isDiagramEmpty;
	}

	confirmationModel(message) {

		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to continue?", utils.getLanguage()),
			icon: <ExclamationCircleOutlined />,
			content: i18Get(message, utils.getLanguage()),
			okText: i18Get("Confirm", utils.getLanguage()),
			cancelText: i18Get("Cancel", utils.getLanguage()),
			onOk: () => {
				this.doneClicked();
			},
			onCancel: () => {
			}
		});
	}

	confirmationAboutCopyDiagramDataReplacement() {
		const prevState =  this.state;
		 const isDestinationEmpty = this.isDiagramDataEmptyOrNot(prevState.destinationDiagram );
		 const isSourceDiagramEmpty = this.isDiagramDataEmptyOrNot(prevState.sourceDiagram );
		
		 if (!this.state.genericComponents.HEALTH_PATHWAY_DIAGRAM) {
			this.doneClicked();
		} else  if (isDestinationEmpty)
		{
			this.doneClicked();
		} else if (!isDestinationEmpty) {

			if (isSourceDiagramEmpty)
			{
				  this.confirmationModel("The source Diagram data is empty. Destination data will be empty when the process is complete");
			} else if (!isSourceDiagramEmpty ) {
				  this.confirmationModel("The Destination Diagram data is not empty. Source data will be replaced by the same.");
			}
		} else { this.doneClicked(); }
			
	}

	doneClicked() {
		this.setState({ copyLoading: true });
		Object.keys(this.state.genericComponents).forEach((key) => (this.state.genericComponents[key] === "" ? delete this.state.genericComponents[key] : {}));
		const payload = {
			genericComponents: this.state.genericComponents
		};
		this.props.dispatch(healthPathwayDetailsAction.copyFromHealthPathway(this.props.match.params.id, this.state.selectedData.value, payload));
		if (!this.props.isCopyBlocked) {
			this.props.dispatch(diagramActions.removeDiagramLock("HEALTH_PATHWAY", (this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), { "uuid": sessionStorage.getItem("uuid") }));
		}
	}
}

CopyFromHealthPathway.propTypes = {
	actions: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	syncfusion: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	healthPathwayDetails: store.healthPathwayDetails,
	healthPathway: store.healthPathway,
	translations: store.translations,
	syncfusion: store.syncfusion
}))(CopyFromHealthPathway));
