import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import userActions from "../../../../../../../actions/user";
import locations from "../../../../../../../router/locations";
import Loader from "../../../../../../../plug/loader";
import { Modal } from "antd";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import SimplePopupTable from "../../../../../../../plug/simple-mode-table";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

class userList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			users: [],
			offset: 0,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			filterValue: "",
			viewCommunity: false,
			communityList: []
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.userList });
	}

	viewCommunityList(list) {
		return () => {
			this.setState({ viewCommunity: true, communityList: list });
		};
	}

	closeView() {
		this.setState({ viewCommunity: false, communityList: [] });
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: (d) => d,
				width: 130,
				resizable: false,
				sortable: false,
				Cell: (row) => (
					this.getActionButton(row.value)
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "name",
				accessor: (d) => d.name,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("ROLE", utils.getLanguage()),
				id: "role",
				accessor: (d) => d.userRole,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("ACTIVE", utils.getLanguage()),
				id: "active",
				accessor: (d) => d.active,
				resizable: true,
				width: 100,
				sortable: false,
				Cell: (row) => (<span className="enableDisable">{row.value ? <CheckCircleOutlined className="enable" /> : <CloseCircleOutlined className="disable" />}</span>)
			},
			{
				Header: i18Get("CONTACT BY", utils.getLanguage()),
				id: "contactBy",
				accessor: (d) => d.contactBy,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			}, {
				Header: i18Get("EMAIL", utils.getLanguage()),
				id: "email",
				accessor: (d) => d.email,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("PHONE", utils.getLanguage()),
				id: "phone",
				accessor: (d) => d.phone,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("HOSPITAL", utils.getLanguage()),
				id: "hospitalName",
				accessor: (d) => d,
				resizable: true,
				Cell: (row) => { 
					const momName = row.value.roleType === "PATHWAY_USER" &&  !row.value.hospitalId && !row.value.siteId ? "MOM" :  "";
					return (
						row.value.hospitalName ? this.getText(row.value.hospitalName) : momName ? momName : "-"
					);
				}
			},
			{
				Header: i18Get("SITE", utils.getLanguage()),
				id: "siteName",
				accessor: (d) => d,
				resizable: true,
				Cell: (row) => { 
					const momName = row.value.roleType === "PATHWAY_USER" &&  !row.value.hospitalId && !row.value.siteId ? "MOM" :  "";
					return (row.value.siteName ? this.getText(row.value.siteName) : momName ? momName : "-");
				}
			},
			{
				Header: i18Get("COMMUNITIES", utils.getLanguage()),
				id: "communities",
				accessor: (d) => d,
				sortable: false,
				resizable: true,
				Cell: (row) => (
					<div className="linked_data_count">
						{row.value.communities && row.value.communities.length > 0
							? <a onClick={this.viewCommunityList(row.value.communities)}>{row.value.communities.length}</a>
							: (
								<a className="color_black">
									{row.value.roleType == "PATHWAY_USER" ? 0 : "-"}
								</a>
							)}
					</div>
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	getActionButton(user) {
		return (
			<div className="margin_left_auto">
				<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(user).bind(this)} />
				&nbsp;&nbsp;
				<DeleteOutlined className="delete_color" type="delete" onClick={this.deleteClicked(user).bind(this)} />
			</div>
		);
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList(users) {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(
					users.users, (
						this.state.page - 1),
					users.totalPages,
					this.state.loading,
					() => (<Loader loading={this.state.loading} isTable />),
					false,
					this.state.pageSize,
					true,
					this.onFetchData.bind(this),
					this.getCols(),
					true,
					false,
					this.onPageChange.bind(this),
					"USER",
					"ReactTableUserHeight",
					this.onPageSizeChange.bind(this)
				)}
				getTrProps={function (state, rowInfo) {
					return {
						style: {
							background: rowInfo && rowInfo.index === this.state.activeCompany ? "#e8eaea7a" : ""
						}
					};
				}.bind(this)}
			/>
		);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? "1" : "0";
		} else {
			prevState.sort = "";
			prevState.desc = false;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		queryString += this.state.filterValue ? `&search=${this.state.filterValue}` : "";
		this.props.dispatch(userActions.fetchusers(queryString));
	}

	editClicked(user) {
		return () => {
			this.setState({ user });
			this.props.history.push(`${locations.userManagement_users}/${user.id}`);
		};
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	render() {
		if (this.props.user.isFetchAllUsersCompleted === "FULFILLED") {
			this.state.users = this.props.user.userList;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.users.totalPages = parseInt(Math.ceil(this.state.users.count / this.state.pageSize));
			this.state.loading = false;
			this.props.totalCount(this.state.users.count);
			this.props.dispatch(userActions.clearUserFetch());
		}
		if (this.props.user.isUpdateusersCompleted === "FULFILLED") {
			this.props.dispatch(userActions.clearUpdateuser());
			this.state.loading = true;
			this.fetchDataFromServer();
		}
		if (this.state.filterValue !== this.props.filterValue) {
			this.state.filterValue = this.props.filterValue;
			this.state.page = 1;
			this.fetchDataFromServer();
		}
		return (
			<div className="pole_top_section">
				{this.state.users && this.getList(this.state.users)}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete user", utils.getLanguage())}
					visible={this.state.deleteUserVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteuser.bind(this)}
				>
					<div>
						<I18 tkey="Are you sure you want to delete" />
						{" "}
						{ }
						{" "}
						?
					</div>
				</Modal>
				{this.state.viewCommunity
					&& (
						<SimplePopupTable
							title="COMMUNITY LIST"
							visible={this.state.viewCommunity}
							onCancel={this.closeView.bind(this)}
							footer={false}
							data={this.state.communityList}
						/>
					)}

			</div>
		);
	}

	deleteClicked(user) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deleteUserVisible: true, user });
		};
	}

	hideModalDelete() {
		this.setState({ deleteUserVisible: false });
	}

	deleteuser() {
		this.setState({ deleteUserVisible: false });
		const payload = {
			isDeleted: true
		};
		this.props.dispatch(userActions.updateUser(this.state.user.id, payload));
	}
}

userList.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.administartionUser,
	translations: store.translations
}))(userList));
