import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (queryString) => ({
	type: events.STERILIZATION_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality${queryString}`)
});

const clearSpecialtyFetch = () => ({
	type: events.CLEAR_STERILIZATION_FETCH,
	payload: ""
});

const getOne = (id, queryString) => ({
	type: events.STERILIZATION_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality/${id}${queryString}`)
});

const getOneCompleted = () => ({
	type: events.CLEAR_STERILIZATION_GET_ONE,
	payload: ""
});

const createSpeciality = (payload, queryString) => ({
	type: events.CREATE_STERILIZATION_CONSO,
	payload: axios.post(`${Config.apiRootSecure}/consumableSpeciality${queryString}`, payload)
});

const createSpecialityCompleted = () => ({
	type: events.COMPLETE_CREATE_STERILIZATION_CONSO,
	payload: ""
});

const editSpeciality = (id, payload, queryString) => ({
	type: events.EDIT_STERILIZATION_CONSO,
	payload: axios.put(`${Config.apiRootSecure}/consumableSpeciality/${id}${queryString}`, payload)
});

const editSpecialityCompleted = () => ({
	type: events.COMPLETE_EDIT_STERILIZATION_CONSO,
	payload: ""
});

const deleteSpeciality = (id, queryString) => ({
	type: events.DELETE_STERILIZATION_CONSO,
	payload: axios.delete(`${Config.apiRootSecure}/consumableSpeciality/${id}${queryString}`)
});

const deleteSpecialityCompleted = () => ({
	type: events.COMPLETED_DELETE_STERILIZATION_CONSO,
	payload: ""
});

const getAllFamily = (queryString) => ({
	type: events.STERILIZATION_FAMILY_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/consumableFamily${queryString}`)
});

const clearGetAllFamily = () => ({
	type: events.CLEAR_STERILIZATION_FAMILY_FETCH,
	payload: ""
});

const getOneFamily = (id) => ({
	type: events.STERILIZATION_FAMILY_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/consumableFamily/${id}`)
});

const clearGetOneFamily = () => ({
	type: events.CLEAR_STERILIZATION_FAMILY_ONE,
	payload: ""
});

const createFamily = (payload) => ({
	type: events.CREATE_STERILIZATION_FAMILY,
	payload: axios.post(`${Config.apiRootSecure}/consumableFamily`, payload)
});

const createFamilyCompleted = () => ({
	type: events.CREATE_STERILIZATION_FAMILY_COMPLETED,
	payload: ""
});

const updateFamily = (id, payload) => ({
	type: events.UPDATE_STERILIZATION_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/consumableFamily/${id}`, payload)
});

const updateFamilyCompleted = () => ({
	type: events.UPDATE_STERILIZATION_FAMILY_COMPLETED,
	payload: ""
});

const deleteFamily = (id) => ({
	type: events.DELETE_STERILIZATION_FAMILY,
	payload: axios.delete(`${Config.apiRootSecure}/consumableFamily/${id}`)
});

const deleteFamilyCompleted = () => ({
	type: events.DELETE_STERILIZATION_FAMILY_COMPLETED,
	payload: ""
});

const getAllSterilizationFamily = (queryString) => ({
	type: events.GET_ALL_STERILIZATION_FAMILY,
	payload: axios.get(`${Config.apiRootSecure}/consumableFamilySpeciality?${queryString}`)
});

const getAllSterilizationFamilyCompleted = () => ({
	type: events.GET_ALL_STERILIZATION_FAMILY_COMPLETED,
	payload: ""
});

const saveAndApproveConsumableFamily = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/consumableFamily/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveConsumableFamilyCompleted = () => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED,
	payload: {}
});

const assignAndApproveConsumableFamily = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/consumableFamily/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveConsumableFamilyCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED,
	payload: {}
});

export default {
	getAll,
	clearSpecialtyFetch,
	createSpeciality,
	createSpecialityCompleted,
	editSpeciality,
	editSpecialityCompleted,
	deleteSpeciality,
	deleteSpecialityCompleted,
	getOne,
	getOneCompleted,
	getAllFamily,
	clearGetAllFamily,
	createFamily,
	createFamilyCompleted,
	updateFamily,
	updateFamilyCompleted,
	getOneFamily,
	clearGetOneFamily,
	deleteFamily,
	deleteFamilyCompleted,
	getAllSterilizationFamily,
	getAllSterilizationFamilyCompleted,
	saveAndApproveConsumableFamily,
	saveAndApproveConsumableFamilyCompleted,
	assignAndApproveConsumableFamily,
	assignAndApproveConsumableFamilyCompleted
};
