import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../../constants";

function getText(value) {
	return <div>{value}</div>;
}

export default function column(editClicked, deleteClicked, viewSpecialityList) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto">
					<div className="min_width_12_px">
						{row.value.status === statusList.STATUS.values.APPROVED
							? <EditOutlined className="edit_color margin-right-10" onClick={editClicked(row.value)} /> : ""}
					</div>
					{row.value.status === statusList.STATUS.values.APPROVED
						? <DeleteOutlined className=" delete_color margin-right-10" onClick={deleteClicked(row.value)} /> : <div className="min_width_12_px"> </div>}
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			maxWidth: 130,
			resizable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("NAME OF THE COMMUNITY", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		},
		{
			Header: i18Get("SPECIALITIES", utils.getLanguage()),
			id: "specialities",
			accessor: (d) => d.specialities,
			sortable: false,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				<div className="linked_data_count">
					{row.value && row.value.length > 0
						? <a onClick={viewSpecialityList(row.value)}>{row.value.length}</a> : <a className="color_black">0</a>}
				</div>
			)
		}
	]);
	return tableArray;
}
