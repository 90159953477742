import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (offset, type, numResults, organization, sort, desc, name) => ({
	type: events.ANESTHESIA_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia?offset=${offset}&numResults=${numResults}${organization ? (`&organization=${organization}`) : ""}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}&name=${name || ""}&status=APPROVED`)
});

const getAllDropDownSelectAnaesthesia = (queryParam) => ({
	type: events.ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia/intervention/list?${queryParam}`)
});


const getOneAnaesthesia = (id, timeZone) => ({
	type: events.FETCH_ONE_ANAESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia/${id}${timeZone ? `?timezone=${timeZone}` : ""}`)
});

const clearGetOneAnaesthesia = () => ({
	type: events.CLEAR_FETCH_ONE_ANAESTHESIA,
	payload: {}
});

const add = (type, id, payload) => ({
	type: events.ANESTHESIA_ADD,
	payload: axios.post(`${Config.apiRootSecure}/treatment/${id}/subTreatment?typeActivity=${type.toUpperCase()}`, { ...payload })

});

const get = (type, id, sort, desc, status, hospitalId) => ({
	type: events.ANESTHESIA_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/subTreatment?typeActivity=${type.toUpperCase()}${sort ? (`?sort=${sort}&desc=${desc || 0}`) : ""}${status ? `&status=${status}` : ""}${hospitalId ? `&hospitalId=${hospitalId}` : ""}`)

});

const deleteElement = (id, subtreatmentId, payload) => ({
	type: events.ANESTHESIA_DELETE,
	payload: axios.delete(`${Config.apiRootSecure}/treatment/${id}/subTreatment/${subtreatmentId}`, { ...payload })

});

const clearDelete = () => ({
	type: events.CLEAR_ANESTHESIA_DELETE,
	payload: {}
});

const cleargetAll = () => ({
	type: events.CLEAR_ANESTHESIA_GET_ALL,
	payload: {}
});

const clearGetAllDropDownSelectAnaesthesia = () => ({
	type: events.CLEAR_ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL,
	payload: {}
});


const clearAdd = () => ({
	type: events.CLEAR_ANESTHESIA_ADD,
	payload: {}
});

const clearGet = () => ({
	type: events.CLEAR_ANESTHESIA_GET,
	payload: {}
});

const fetchAllAnaesthesia = (organization, queryParam) => ({
	type: events.GET_ALL_ANAESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesiaList?${queryParam}`)
});

const clearFetchAllAnaesthesia = () => ({
	type: events.CLEAR_GET_ALL_ANAESTHESIA,
	payload: {}
});

const createAnaesthesia = (payload) => ({
	type: events.CREATE_ANAESTHESIA,
	payload: axios.post(`${Config.apiRootSecure}/anaesthesia`, { ...payload })
});
const copyFromanaesthesia = (payload, id) => ({
	type: events.COPY_ANAESTHESIA,
	payload: axios.post(`${Config.apiRootSecure}/anaesthesia/${id}/copyFrom/${payload.anaesthesiaId}`, { ...payload })
});
const clearCopyanaesthesia = () => ({
	type: events.CLEAR_COPY_ANAESTHESIA,
	payload: {}
});
const clearCreateAnaesthesia = () => ({
	type: events.CLEAR_CREATE_ANAESTHESIA,
	payload: {}
});

const updateAnaesthesia = (payload, props) => ({
	type: events.UPDATE_ANAESTHESIA,
	payload: axios.put(`${Config.apiRootSecure}/anaesthesia/${props.id}`, { ...payload })
});

const clearUpdateAnaesthesia = () => ({
	type: events.CLEAR_UPDATE_ANAESTHESIA,
	payload: {}
});

const removeAnaesthesia = (id) => ({
	type: events.ANESTHESIA_REMOVE,
	payload: axios.delete(`${Config.apiRootSecure}/anaesthesia/${id}`)

});

const removeAnaesthesiaCompleted = () => ({
	type: events.ANESTHESIA_REMOVE_COMPLETED,
	payload: {}
});

const saveAndApproveAnaesthesia = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_ANESTHESIA,
	payload: axios.put(`${Config.apiRootSecure}/anaesthesia/${id}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveAnaesthesia = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_ANESTHESIA,
	payload: {}
});

const assignAndApproveAnaesthesia = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_ANESTHESIA,
	payload: axios.put(`${Config.apiRootSecure}/anaesthesia/${id}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveAnaesthesia = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_ANESTHESIA,
	payload: {}
});

const getAnaesthesia = (queryParam) => ({
	type: events.GET_APPROVED_ANESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia?${queryParam}`)
});

const getAnaesthesiaCompleted = () => ({
	type: events.GET_APPROVED_ANESTHESIA_COMPLETED,
	payload: {}
});

const getAnaesthesiaLink = (id) => ({
	type: events.GET_LINKED_ITEMS_FOR_ANESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia/links/${id}`)
});

const getAnaesthesiaLinkCompleted = () => ({
	type: events.GET_LINKED_ITEMS_FOR_ANESTHESIA_COMPLETED,
	payload: {}
});

const saveAndApproveConsumable = (id, type, payload, elementId) => ({
	type: events.SAVE_AND_APPROVE_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/${type}/element/${elementId}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveConsumable = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_ELEMENT,
	payload: {}
});

const assignAndApproveConsumable = (id, type, payload, elementId) => ({
	type: events.ASSIGN_AND_APPROVE_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/${type}/element/${elementId}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveConsumable = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_ELEMENT,
	payload: {}
});

const getAllExistingAnaesthesia = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_EXISTING_ANESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia/minimal/list?offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&treatmentId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const getAllNewAnaesthesia = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_NEW_ANESTHESIA,
	payload: axios.get(`${Config.apiRootSecure}/anaesthesia/minimal/list?offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&treatmentId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const clearGetAllExistingAnaesthesia = () => ({
	type: events.CLEAR_GET_ALL_EXISTING_ANESTHESIA,
	payload: {}
});

const clearGetAllNewAnaesthesia = () => ({
	type: events.CLEAR_GET_ALL_NEW_ANESTHESIA,
	payload: {}
});

const saveAndApprove = (id, type, payload, subtreatmentId) => ({
	type: events.SAVE_AND_APPROVE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/subTreatment/${subtreatmentId}/saveAndApprove`, { ...payload })
});

const clearSaveAndApprove = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE,
	payload: {}
});

const assignAndApprove = (id, type, payload, subtreatmentId) => ({
	type: events.ASSIGN_AND_APPROVE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/subTreatment/${subtreatmentId}/assignAndApprove`, { ...payload })
});

const clearAssignAndApprove = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE,
	payload: {}
});

const getOneSubtreatment = (id, subtreatmentId) => ({
	type: events.SUBTREATMENT_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/subTreatment/${subtreatmentId}`)

});

const clearGetOneSubtreatment = () => ({
	type: events.CLEAR_SUBTREATMENT_GET_ONE,
	payload: {}
});

export default {
	getAll,
	cleargetAll,
	add,
	get,
	deleteElement,
	clearAdd,
	clearDelete,
	clearGet,
	fetchAllAnaesthesia,
	clearFetchAllAnaesthesia,
	createAnaesthesia,
	clearCreateAnaesthesia,
	getOneAnaesthesia,
	clearGetOneAnaesthesia,
	copyFromanaesthesia,
	clearCopyanaesthesia,
	updateAnaesthesia,
	clearUpdateAnaesthesia,
	removeAnaesthesia,
	removeAnaesthesiaCompleted,
	saveAndApproveAnaesthesia,
	clearSaveAndApproveAnaesthesia,
	assignAndApproveAnaesthesia,
	clearAssignAndApproveAnaesthesia,
	getAnaesthesia,
	getAnaesthesiaCompleted,
	saveAndApproveConsumable,
	clearSaveAndApproveConsumable,
	assignAndApproveConsumable,
	clearAssignAndApproveConsumable,
	getAllExistingAnaesthesia,
	clearGetAllExistingAnaesthesia,
	getAllNewAnaesthesia,
	clearGetAllNewAnaesthesia,
	saveAndApprove,
	clearSaveAndApprove,
	assignAndApprove,
	clearAssignAndApprove,
	getOneSubtreatment,
	clearGetOneSubtreatment,
	getAllDropDownSelectAnaesthesia,
	clearGetAllDropDownSelectAnaesthesia,
	getAnaesthesiaLink,
	getAnaesthesiaLinkCompleted
};
