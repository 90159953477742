import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { Button, Modal, Dropdown, Menu, Tooltip } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, ExpandOutlined, FullscreenExitOutlined, CaretDownOutlined } from "@ant-design/icons";
import ProblemCreate from "../problem-section/create";
import ClpStepFilter from "../clp-step-filter";
import isPermissionGranted from "../../../../../../../utils/permission";
import AttachModels from "../../components/attachModels";
import QuickOrder from "../problem-section/quick-reorder";
import { ReorderIcon } from "../../../../../../../utils/icons";

class ProblemTopSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			loading: false,
			problemId: null,
			type: "",
			canEnableProblemListReorder: false,
			enableProblemListReorder: false,
			isProblemOrderUpdating: false,
			isProblemListFullScreen: false,
			quickReorderProblemListChanges: 0,
			saveAndNextQuickOrder: false
		};
	}

	createClicked() {

		if (this.state.clinicalPathway.isCheckList && this.state.totalProblemsUnderClp > 0) {
			utils.showInfo("Checklist can have only one problem!");
			return false;
		}

		this.setState({ createModal: true });
	}

	onClickCancel() {
		this.setState({ editModal: false, problemId: null, createModal: false, type: "" });
	}

	reloadData() {
		this.props.reloadDetails();
		this.props.handleCancel();
	}

	toggleViewListModal = (value) => {
		this.setState({ viewList: value });
	};

	// updateData() {
	// 	if (this.child) {
	// 		this.child.updateProblemsRank();
	// 	}
	// }

	typeChanged = e => {
		this.props.typeChanged(e);
		this.state.type = e;
		this.setState(this.state);
	};

	createMenu = (isModel) => (
		<Menu>
			<Menu.Item onClick={this.createClicked.bind(this)} key="1">
				<span className="margin-right-10">
					<I18 tkey='Cible' />
				</span>
			</Menu.Item>
			{isModel &&
				<Menu.Item onClick={() => this.toggleViewListModal(true)} key="2">
					<span className="margin-right-10">
						<I18 tkey='Model' />
					</span>
				</Menu.Item>
			}
		</Menu>
	);

	render() {
		
		if (Object.keys(this.props.clinicalPathwayData) && !this.state.isLoaded) {
			this.state.clinicalPathway = this.props.clinicalPathwayData;
			this.state.isLoaded = true;
		}
		return (
			<div className={`problem_pathway_section padding-top-10 my3 ${this.state.isProblemListFullScreen ? "full_screen_with_filter padding-15 padding-top-10 h_100" : ""}`}>
				<div className="d-flex ">
					<div className="margin-right-5">
						{!this.props.isHospitalisation ? <ClpStepFilter filter={this.props.clpStepFilter} changeFilter={this.props.changeClpStepFilter} /> : ""}
					</div>
					<div className="margin-right-15">
						{!this.props.enableProblemListReorder ?
						<div className="clinical-pathway-reorder-icon-container">
							<span className="icon-holder" loading={this.state.loading} onClick={() => this.props.onQuickReorderData()}>
								<span className="custom-svg">
									<Tooltip title={i18Get("Re-Order", utils.getLanguage())} placement="top">
										<ReorderIcon />
									</Tooltip>
								</span>
							</span>
							{/* <Button disabled={this.props.clinicalPathwayProblems.problemList && this.props.clinicalPathwayProblems.problemList.mapping && isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, "EDIT") ? false : true} type="button" className="margin-right-10"
								loading={this.state.loading} onClick={() => this.props.onQuickReorderData()}>
								<I18 tkey="Reorder Data" />&nbsp;
							</Button> */}
						</div>
								: ""
						}
					</div>
					<div className="margin-right-10">
						<Dropdown overlay={this.createMenu(!this.props.clinicalPathwayData.isModel && !this.props.clinicalPathwayData.isCheckList)} trigger={["click"]} >
							<div className="mb-10 di float_left">
								<Button type="button" className="single-button with_border uppercase min_width_90"
									disabled={ !isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, "CREATE")}>
									<I18 tkey="Add" />
									<CaretDownOutlined />
								</Button>
							</div>
						</Dropdown>
					</div>
					<div>
						<Button style={{ padding: "0 10px", background: "#323ada" }} onClick={this.props.toggleHideHeaders}>
							{
									this.props.showHeaders
										? <ArrowUpOutlined style={{ color: "#ffffff" }} />
										: <ArrowDownOutlined style={{ color: "#ffffff" }} />
							}
						</Button>
					</div>
					<div onClick={this.props.toggleProblemListFullScreen} className="margin-left-10 d-flex align-items-center">
						{!this.props.isProblemListFullScreen ?
								<ExpandOutlined className="edit_color" style={{ fontSize: 20 }} /> :
								<FullscreenExitOutlined className="edit_color" style={{ fontSize: 18 }} />
						}
					</div>	
					{this.state.createModal ?
					<ProblemCreate
						clinicalPathwayData={this.props.clinicalPathwayData}
						createModal={this.state.createModal}
						type={this.state.type}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						filter={this.props.clpStepFilter}
						changeFilter={this.props.changeClpStepFilter}
					/>
					: ""}
					{this.props.enableQuickProblemListReorder &&
						<QuickOrder
							clinicalPathwayData={this.props.clinicalPathwayData}
							enableQuickProblemListReorder={this.props.enableQuickProblemListReorder}
							disableProblemListReorder={this.props.disableProblemListReorder}
							type={this.state.type ? this.state.type : this.props.type}
							typeChanged={this.props.typeChanged}
							clpStepFilter={this.props.clpStepFilter}
							quickReorderChanges={this.props.quickReorderProblemListChanges}
							saveAndNextQuickOrder={this.props.onSaveAndNextQuickOrder}
							isProblemOrderUpdating={this.props.isProblemOrderUpdating}
						/>
					}
					{
						<AttachModels
							clinicalPathwayData={this.state.clinicalPathway}
							toggleViewListModal={this.toggleViewListModal}
							viewList={this.state.viewList}
							reloadDetails={this.props.reloadDetails}
						/>
					}
				</div>
			</div>
		);
	}
}

ProblemTopSection.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequire
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations,
	permissions: store.permissions
}))(ProblemTopSection));