import events from "../../actions/events";

const initialState = {
	diagram: {},
	diagramCompleted: false,
	saveDiagram: {},
	saveDiagramCompleted: false,
	saveDiagramAsImage: {},
	saveDiagramAsImageCompleted: false,
	saveDiagramSettingsCompleted: false,
	diagramLockValidation: false,
	diagramValidationInfo: {},
	diagramValidationRejectInfo: {},
	lockMeta: ""

};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${events.GET_DIAGRAM_DATA}_FULFILLED`:
			return {
				...state,
				diagramCompleted: "FULFILLED",
				diagram: action.payload.data.data
			};
		case `${events.GET_DIAGRAM_DATA}_REJECTED`:
			return {
				...state,
				diagramCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_GET_DIAGRAM_DATA:
			return {
				...state,
				diagramCompleted: false
			};
		case `${events.SAVE_DIAGRAM_DATA}_FULFILLED`:
			return {
				...state,
				saveDiagramCompleted: "FULFILLED",
				saveDiagram: action.payload.data.data
			};
		case `${events.SAVE_DIAGRAM_DATA}_REJECTED`:
			return {
				...state,
				saveDiagramCompleted: "REJECTED",
				error: action.payload,
				saveDiagramCode: action.payload
			};
		case events.CLEAR_SAVE_DIAGRAM_DATA:
			return {
				...state,
				saveDiagramCompleted: false
			};
		case `${events.SAVE_DIAGRAM_AS_IMAGE}_FULFILLED`:
			return {
				...state,
				saveDiagramAsImageCompleted: "FULFILLED",
				saveDiagramAsImage: action.payload.data.data
			};
		case `${events.SAVE_DIAGRAM_AS_IMAGE}_REJECTED`:
			return {
				...state,
				saveDiagramAsImageCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_SAVE_DIAGRAM_AS_IMAGE:
			return {
				...state,
				saveDiagramAsImageCompleted: false
			};
		case `${events.SAVE_DIAGRAM_SETTINGS}_FULFILLED`:
			return {
				...state,
				saveDiagramSettingsCompleted: "FULFILLED"
			};
		case `${events.SAVE_DIAGRAM_SETTINGS}_REJECTED`:
			return {
				...state,
				saveDiagramSettingsCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_SAVE_DIAGRAM_SETTINGS:
			return {
				...state,
				saveDiagramSettingsCompleted: false
			};
		case `${events.GET_META_DIAGRAM_DATA}_FULFILLED`:
			return {
				...state,
				metaDiagramCompleted: "FULFILLED",
				meta: action.meta,
				metaDiagram: action.payload.data.data
			};
		case `${events.GET_META_DIAGRAM_DATA}_REJECTED`:
			return {
				...state,
				metaDiagramCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_GET_META_DIAGRAM_DATA:
			return {
				...state,
				metaDiagramCompleted: false
			};

		case `${events.GET_DIAGRAM_COPY_VALIDATION}_FULFILLED`:
			return {
				...state,
				diagramValidationCompleted: "FULFILLED",
				diagramValidationInfo: action.payload.data.data
			};
		case `${events.GET_DIAGRAM_COPY_VALIDATION}_REJECTED`:
			return {
				...state,
				diagramValidationCompleted: "REJECTED",
				error: action.payload
			};
		case events.CLEAR_GET_DIAGRAM_COPY_VALIDATION:
			return {
				...state,
				diagramValidationCompleted: false
			};
		case `${events.GET_DIAGRAM_LOCK_VALIDATION}_FULFILLED`:
			return {
				...state,
				diagramLockValidation: "FULFILLED",
				diagramValidationInfo: action.payload.data,
				lockMeta: action.meta
			};
		case `${events.GET_DIAGRAM_LOCK_VALIDATION}_REJECTED`:
			return {
				...state,
				diagramLockValidation: "REJECTED",
				diagramValidationRejectInfo: action.payload,
				lockMeta: action.meta
			};
		case `${events.GET_DIAGRAM_LOCK_REMOVE}_FULFILLED`:
			return {
				...state
			};
		case `${events.CLEAR_GET_DIAGRAM_LOCK_VALIDATION}`:
			return {
				...state,
				diagramLockValidation: false
			};
		default: return state;
	}
}
