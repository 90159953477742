import React, { useEffect, useState } from "react";
import I18, { i18Get } from "i18";
import adminClinicalPathwayActions from "actions/admin-clinical-pathway";
import { useSelector, useDispatch } from "react-redux";
import { Select, Button, Tooltip } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import utils from "utils/utils";

const Steps = (props) => {

	// const onSlide = (e) => { props.onChange("strokeWidth", e);};
	const dispatch = useDispatch();
	const adminClinicalPathway = useSelector((state) => { return state.adminClinicalPathway;});
	const [optionList, setOptionList] = useState([]);
	const [stepValue, setStepValue] = useState([]);
	const [substepValue, setSubstepValue] = useState([]);
	const [optionListSubStep, setOptionListSubStep] = useState([]);
	const [stepAction, setStepAction] = useState(false);
	const [subStepAction, setSubStepActionn] = useState(false);

	useEffect(() => {
		fetchSubStepsList();
	}, [stepValue, props.customData]);

	useEffect(() => {
		fetchStepsList();
	}, [substepValue, props.selectedShape, props.customData.phase]);

	const setStepDefault = (options) => {
		if (props.inputStepName) {
			const findOption = options.find( e => e.label === props.inputStepName);
			setStepValue(findOption);
			props.onChangeData(findOption, "steps");
			props.clearCreatedStepName();
		}
	};
	
	const setSubStepDefault = (options) => {
		if (props.createdSubStepName) {
			const findOption = options.find( e => e.label === props.createdSubStepName);
			setSubstepValue(findOption);
			props.onChangeData(findOption, "subSteps");
			props.clearCreatedSubStepName();
		}
	};

	const fetchStepsList = () => {
		let queryString = `offset=0&numResults=${1000}`;
		queryString += `&isHcp=${true}`;
		queryString += props.customData.phase ? `&type=${props.customData.phase}` : "";
		dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayStep(queryString));
	};

	const fetchSubStepsList = () => {
		if(!props.customData.stepsId || !props.customData.stepsCode) { return false;}
		let queryString = `offset=0&numResults=${1000}`;
		queryString += props.customData.stepsId && props.customData.stepsId ? `&clpStepId=${props.customData.stepsId}` : "";
		queryString += props.customData.stepsCode && props.customData.stepsCode ? `&clpStepCode=${props.customData.stepsCode}` : "";
		dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwaySubStep(queryString));
	};
	
	const stepRenderOptions = () => {
		const options = [];
		if (adminClinicalPathway && adminClinicalPathway.stepList && adminClinicalPathway.stepList.data) {
			const findOption = adminClinicalPathway.stepList.data.filter( e => e.clinicalPathwayType && e.clinicalPathwayType.includes(props.healthPathwayData.clinicalPathway));
			if (findOption.length) {
				findOption.forEach(element => {
					options.push({ label: element.name, value: element.id, code: element.code, title: "" });
				});
			} else {
				adminClinicalPathway.stepList.data.forEach(element => {
					options.push({ label: element.name, value: element.id, code: element.code });
				});
			}
		}
		setOptionList(options);
		setStepDefault(options);
	};

	const subStepRenderOptions = () => {
		const options = [];
		if (adminClinicalPathway && adminClinicalPathway.subStepList && adminClinicalPathway.subStepList.data) {
			adminClinicalPathway.subStepList.data.forEach(element => {
				options.push({ label: element.name, value: element.clpSubStepId, code: element.code, title: "" });
			});
		}
		setOptionListSubStep(options);
		setSubStepDefault(options);
	};

	const stepDataChanged = (e, opt) => {
		props.clearCreatedStepName();
		props.onChangeData(opt, "steps");
		props.onChangeData(null, "subSteps");
		setStepValue(opt);
		setSubstepValue([]);
	};

	const substepDataChanged = (e, opt) => {
		props.clearCreatedSubStepName();
		props.onChangeData(opt, "subSteps");
		setSubstepValue(opt);
	};

	useEffect(() => {
		if (adminClinicalPathway.fetchAllClinicalPathwayStepCompleted === "FULFILLED") {
			dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwayStep());
			stepRenderOptions();
		}
	});

	useEffect(() => {
		if (adminClinicalPathway.fetchAllClinicalPathwaySubStepCompleted === "FULFILLED") {
			dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwaySubStep());
			subStepRenderOptions();
		}
	});
	return (
		<div>
			<div className="d-flex align-items-center margin-top-10">
				<Tooltip placement="topRight" title={i18Get("Steps", utils.getLanguage())} >
					<Select 
						className="w_90" 
						// TitleAttribute= "false"
						value={optionList ? optionList.find(e => (e.code === props.customData.stepsCode)) : null}
						allowClear={true} 
						showSearch
						optionFilterProp="children"
						disabled={!props.customData.phase ? true : false}
						filterOption={(iv, op) => op.label.toLocaleLowerCase().includes((iv || "").toLocaleLowerCase()) }
						filterSort={(optionA, optionB) =>
					  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
						}
						placeholder={i18Get("Steps", utils.getLanguage())}
						options={ optionList } 
						onChange={(e, opt ) => stepDataChanged(e, opt)}
						onSearch={(e, opt) => {  e ? props.onStepSearchData(e) : ""; }}
						onClear={ () => setStepAction(false) }
						notFoundContent={ <div className="steps_substeps_create_icon text_align_center padding-10">
							<Button onClick={ () => {props.createStepClicked(); }} type="primary" ><I18 tkey="Add New" /></Button>
						</div>}
					/>
				</Tooltip>
			</div>
			<div className="d-flex align-items-center margin-top-10">
				<Tooltip placement="topRight" title={i18Get("Sub Steps", utils.getLanguage())} >
					<Select 
						className="w_90" 
						value={optionList ? optionListSubStep.find(e => (e.code === props.customData.subStepsCode)) : null}
						allowClear={true}
						showSearch 
						disabled={!props.customData.steps ? true : false}
						optionFilterProp="children"
						filterOption={(iv, op) => op.label.toLocaleLowerCase().includes((iv || "").toLocaleLowerCase()) }
						filterSort={(optionA, optionB) =>
					  (optionA?.label ?? "").toLowerCase().localeCompare((optionB?.label ?? "").toLowerCase())
						}
						placeholder={i18Get("Sub Steps", utils.getLanguage())}
						options={optionListSubStep} 
						onChange={(e, opt ) => substepDataChanged(e, opt)}
						onSearch={(e, opt) => { e ? props.onSubStepSearchData(e) : ""; }}
						onClear={ () => setSubStepActionn(false) }
						notFoundContent={ <div className="steps_substeps_create_icon text_align_center padding-10">
							<Button onClick={ () => {props.createSubStepClicked(props.customData.steps); }} type="primary" ><I18 tkey="Add New" /></Button>
						</div>}
					/>
				</Tooltip>
			</div>
		</div>
	);
};

export default Steps;
