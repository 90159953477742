import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const createCommunitySection = (payload) => ({
	type: events.CREATE_COMMUNITY_SECTION,
	payload: axios.post(`${Config.apiRootSecure}/community`, { ...payload })
});

const clearCreateCommunitySection = () => ({
	type: events.CLEAR_CREATE_COMMUNITY_SECTION,
	payload: {}
});

const getAll = (queryString, meta) => ({
	type: events.COMMUNITY_SECTION_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/community${queryString}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_COMMUNITY_SECTION_FETCH,
	payload: {}
});

const deleteCommunitySection = (id) => ({
	type: events.DELETE_COMMUNITY_SECTION,
	payload: axios.delete(`${Config.apiRootSecure}/community/${id}`)
});

const clearDeleteCommunitySection = () => ({
	type: events.CLEAR_DELETE_COMMUNITY_SECTION,
	payload: {}
});

const getOne = (id) => ({
	type: events.COMMUNITY_SECTION_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/community/${id}`)
});

const clearGetOne = () => ({
	type: events.CLEAR_COMMUNITY_SECTION_GET_ONE,
	payload: ""
});

const updateCommunitySection = (id, payload) => ({
	type: events.UPDATE_COMMUNITY_SECTION,
	payload: axios.put(`${Config.apiRootSecure}/community/${id}`, payload)
});

const updateCommunitySectionCompleted = () => ({
	type: events.UPDATE_COMMUNITY_SECTION_COMPLETED,
	payload: ""
});

const filterChangeCommunitySection = (filter, ignorePageChange) => ({
	type: events.FILTER_CHANGE_COMMUNITY_SECTION,
	payload: { filter, ignorePageChange }
});

export default {
	createCommunitySection,
	clearCreateCommunitySection,
	getAll,
	clearGetAll,
	deleteCommunitySection,
	clearDeleteCommunitySection,
	getOne,
	clearGetOne,
	updateCommunitySection,
	updateCommunitySectionCompleted,
	filterChangeCommunitySection
};
