import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../i18";
import locations from "../../../../../router/locations";
import Intervention from "../elements/intervention";
import Definition from "./definition";
import MainSection from "./main-section";
import clinicalPathwayActions from "../../../../../actions/clinical-pathway";
import "./ext-elements.css";
import utils from "../../../../../utils/utils";
import typeActivity from "../../../../../constants/typeActivity.json";
import screenId from "../../../../../router/screenIdList";
import Eligibility from "./eligibility";
import AuthorsAndActivities from "./authors-activities";
import EditAuthorsAndActivities from "./edit-author-activity";
import Staff from "../elements/staff";
import Anaesthesia from "./anaesthesia";

class SubClinicalPathWay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathway: i18Get("Clinical Pathway Details", utils.getLanguage())
		};
	}

	UNSAFE_componentWillMount() {
		const payload = {
			organization: this.props.match.params.organization,
			id: this.props.match.params.id

		};
		this.props.dispatch(clinicalPathwayActions.getOne(payload.id, new Date().getTimezoneOffset()));
	}

	renderAnaesthesiaRoutes() {
		return <Route path={`${locations.sub_clinical_pathways + locations.anaesthesia_plain}`} render={() => <Anaesthesia type="Anaesthesia" selectedData={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	renderEditAnaesthesiaRoutes() {
		return <Route path={`${locations.sub_clinical_pathways + locations.anaesthesia_plain}/:subTreatmentId`} render={() => <MainSection type="Anaesthesia" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}
	
	renderEditRoutes() {
		return <Route path={`${locations.sub_clinical_pathways + locations.interventions_plain}/:subTreatmentId`} render={() => <MainSection type="Intervention" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	renderEditAuthorActivityRoutes() {
		return <Route path={`${locations.sub_clinical_pathways + locations.authors_and_actions}/:serviceId`} render={() => <EditAuthorsAndActivities type="AUTHORS_AND_ACTIVITIES" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	renderDefinition() {
		return <Route path={locations.sub_clinical_pathways + locations.pathway_definition} render={() => <Definition type="PATHWAY_DEFINITION" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	renderEligibility() {
		return <Route path={locations.sub_clinical_pathways + locations.eligibility} render={() => <Eligibility type="PATHWAY_ELIGIBILITY" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	renderAuthorAndActivity() {
		return <Route path={locations.sub_clinical_pathways + locations.authors_and_actions} render={() => <AuthorsAndActivities type="AUTHORS_AND_ACTIVITIES" clinicalPathway={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} />} />;
	}

	render() {
		if (this.props.clinicalPathway.fetchOneClinicalPathway === "FULFILLED") {
			this.state.clinicalPathway = this.props.clinicalPathway.clinicalPathway;
			this.props.dispatch(clinicalPathwayActions.cleargetOne());
		}
		return (
			<div className="main_overview_section">
				<div className="">
					<Switch>
						{this.renderEditAnaesthesiaRoutes()}
						{this.renderAnaesthesiaRoutes()}
						{this.renderEditRoutes()}
						{this.renderEditAuthorActivityRoutes()}
						{this.renderAuthorAndActivity()}
						{this.renderDefinition()}
						{this.renderEligibility()}
						<Route path={locations.sub_clinical_pathways + locations.interventions_plain} render={() => <Intervention type="Intervention" selectedData={this.state.clinicalPathway} typeActivity={typeActivity.CLINICAL_PATHWAY.toUpperCase()} screenId={screenId.timeEditAnaesthesia} />} />
						<Route path={locations.sub_clinical_pathways + locations.staff} render={() => <Staff typeActivity="CLINICAL_PATHWAY" details={this.state.clinicalPathway} screenId={screenId.timeEditStaffList} />} />
					</Switch>
				</div>
			</div>
		);
	}
}

SubClinicalPathWay.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(SubClinicalPathWay));
