import React, { Component } from "react";
import { convertToRaw, getDefaultKeyBinding } from "draft-js";
import draftToHtml from "draftjs-to-html";
import I18 from "../../../../../../../../i18";
import TextEditor from "../text-editor/index";
import utils from "../../../../../../../../utils/utils";

export default class TextEditorSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	getLength(editorState) {
		if (editorState) {return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);}
		return 0;
	}

	onEditorStateChange(value, editorState) {
		this.props.onEditorStateChange(value, editorState);
	}

	handleBeforeInput(input) {
		if (input === "interventionDetails") {
			if (this.getLength(this.props.problemDetails.interventionDetails) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "expectedResults") {
			if (this.getLength(this.props.problemDetails.expectedResults) >= utils.editorMaxLength) {
				return "handled";
			}
		}
		if (input === "argumentationAndFoundations") {
			if (this.getLength(this.props.problemDetails.argumentationAndFoundations) >= utils.editorMaxLength) {
				return "handled";
			}
		}
	}

	handlePastedText(text, type) {
		if (type === "interventionDetails") {
			if (text && ((this.getLength(this.props.problemDetails.interventionDetails) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "expectedResults") {
			if (text && ((this.getLength(this.props.problemDetails.expectedResults) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
		if (type === "argumentationAndFoundations") {
			if (text && ((this.getLength(this.props.problemDetails.argumentationAndFoundations) + text.length) <= utils.editorMaxLength)) {
				return true;
			}
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command, type) {
		if (type === "interventionDetails") {
			if (this.getLength(this.props.problemDetails.interventionDetails) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "expectedResults") {
			if (this.getLength(this.props.problemDetails.expectedResults) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		if (type === "argumentationAndFoundations") {
			if (this.getLength(this.props.problemDetails.argumentationAndFoundations) <= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
				return "handled";
			}
		}
		return "not-handled";
	}

	render() {
		return (
			<div>
				<div className="padding-left-5 col-md-6">
					<div className="w_100 di  d-flex align-items-center">
						<label className="margin-bottom-0">
							<I18 tkey="Detailed interventions" />
							:
						</label>
					</div>
				</div>
				<div className="padding-left-5 padding-right-12 w_100 di position_relative">
					{this.props.invalidMedicalPrescription && <span className="invalid_clinical_pathway invalid_color"><I18 tkey="Select Interventions Prescription" /></span>}
					<div className="">
						<TextEditor
							className="text_area_treatment form-control"
							editorState={this.props.problemDetails.interventionDetails}
							onEditorStateChange={this.onEditorStateChange.bind(this, "interventionDetails")}
							handleBeforeInput={this.handleBeforeInput.bind(this, "interventionDetails")}
							handlePastedText={this.handlePastedText.bind(this, "interventionDetails")}
							keyBindingFn={this.keyBindingFn.bind(this)}
							handleKeyCommand={this.handleKeyCommand.bind(this, "interventionDetails")}
						/>
					</div>
				</div>
				<div className="col-md-6 padding-left-5">
					<div className="w_100 di ">
						<div className="d-flex align-items-center justify-content-between ">
							<div className="">
								<label className='margin-bottom-0'><I18 tkey="Expected Result" /></label>
							</div>
						</div>
						<TextEditor
							className="text_area_treatment form-control"
							editorState={this.props.problemDetails.expectedResults}
							onEditorStateChange={this.onEditorStateChange.bind(this, "expectedResults")}
							handleBeforeInput={this.handleBeforeInput.bind(this, "expectedResults")}
							handlePastedText={this.handlePastedText.bind(this, "expectedResults")}
							keyBindingFn={this.keyBindingFn.bind(this)}
							handleKeyCommand={this.handleKeyCommand.bind(this, "expectedResults")}
						/>
					</div>
				</div>
				<div className="col-md-6 padding-left-5">
					<div className="w_100 di ">
						<div className="d-flex align-items-center justify-content-between ">
							<div className="">
								<label className='margin-bottom-0'><I18 tkey="Argumentation And Foundations" /></label>
							</div>
						</div>
						<TextEditor
							className="text_area_treatment form-control"
							editorState={this.props.problemDetails.argumentationAndFoundations}
							onEditorStateChange={this.onEditorStateChange.bind(this, "argumentationAndFoundations")}
							handleBeforeInput={this.handleBeforeInput.bind(this, "argumentationAndFoundations")}
							handlePastedText={this.handlePastedText.bind(this, "argumentationAndFoundations")}
							keyBindingFn={this.keyBindingFn.bind(this)}
							handleKeyCommand={this.handleKeyCommand.bind(this, "argumentationAndFoundations")}
						/>
					</div>
				</div>
			</div>
		);
	}
}
