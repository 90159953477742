import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import sterilizationActions from "../../../../../../../../actions/sterilization";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import SpecialityCombo from "../specialityCombo";
import FamilyCombo from "../familyCombo";

export class EditClass extends Component {

	constructor(props) {
		super(props);
    
		this.state = {
			specialityId: null,
			invalidName: false,
			invalidSpeciality: false,
			invalidFamily: false,
			id: null,
			classData: { isSubsidised: 0, consumableSpecialityId: null }
		};
	}
    
	clearValidationMessage()
	{
		this.setState({
			invalidName: false,
			invalidSpeciality: false,
			invalidFamily: false
		});
	}

	specialityChange = (e) => {
		if (e) {
			this.state.classData.consumableSpecialityId = e.id;
			this.state.classData.consumableFamily = null;
			this.state.classData.consumableFamilyId = null;
			this.setState({ ...this.state, consumableFamily: null, consumableSpeciality: e });
		} else {
			this.state.classData.consumableSpecialityId = null;
			this.state.classData.consumableFamily = null;
			this.state.classData.consumableFamilyId = null;
			this.setState({ ...this.state, consumableFamily: null, consumableSpeciality: null });
		}
		this.clearValidationMessage();
	};

	familyChange = (e) => {
		if (e) {
			this.state.classData.consumableFamilyId = e.id;
			this.setState({ ...this.state, consumableFamily: e });
		} else {
			this.state.classData.consumableFamilyId = null;
			this.setState({ ...this.state, consumableFamily: null });
		}
		this.clearValidationMessage();
	};

	onNameChange = (value) => {
		this.state.classData.name = value;
		this.setState({ ...this.state });
		this.clearValidationMessage();
	};

	toggleisSubsidised = (e) => {
		this.state.classData.isSubsidised = e.target.checked;
		this.setState({ ...this.state });
		this.clearValidationMessage();
	};

	validate = () => {
		let flag = true;
		if (!this.state.classData.name) {
			this.state.invalidName = true;
			flag = false;
		} else {
			this.state.invalidName = false;
		}
		if (!this.state.classData.consumableSpecialityId || this.state.classData.consumableSpecialityId == null) {
			this.state.invalidSpeciality = true;
			flag = false;
		} else {
			this.state.invalidSpeciality = false;
		}

		if (!this.state.classData.consumableFamilyId) {
			this.state.invalidFamily = true;
			flag = false;
		} else {
			this.state.invalidFamily = false;
		}
		this.setState({ ...this.state });
		return flag;    
	};

	createClicked = () => {
       
		if (this.validate()) {
			const { name, consumableSpecialityId, consumableFamilyId } = this.state.classData;
			const payload = { name, consumableSpecialityId, consumableFamilyId };
			this.props.dispatch(sterilizationActions.updateClassSterilization(this.state.id, payload));
		}
	};

	render() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput 
									value={this.state.classData.name}
									inputChanged={this.onNameChange}
									maxLength={60}/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Please enter name" /></span>}
							</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Sterilization Speciality" /></label>
								<SpecialityCombo autoSetSingleOne = {true} specialityChange={this.specialityChange} 
									data={this.state.consumableSpeciality} />
								{this.state.invalidSpeciality && <span className='invalid_right fixed_label'><I18 tkey="Please select speciality" /></span>}
							</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Sterilization Family" /></label>
								<FamilyCombo autoSetSingleOne = {true} familyChange={this.familyChange} data={this.state.consumableFamily}
									consumableSpecialityId={this.state.classData.consumableSpecialityId} />
								{this.state.invalidFamily && <span className='invalid_right fixed_label'><I18 tkey="Please select family" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 margin-top-25-check">
							<div className="di w_100 text_align_right">
								{/* <Checkbox className="margin-top-7 float_left" onChange={this.toggleisSubsidised} checked={this.state.classData.isSubsidised}><I18 tkey="Subsidised" /></Checkbox> */}
								<ActionButton onClick={this.createClicked} value={i18Get("SAVE", utils.getLanguage())}/>
							</div>
						</div>

					</div>
				</div>
			</div>
		);
	}

    

	componentDidUpdate() {
		if (this.props.match.params.id != this.state.id) {
			this.state.id = this.props.match.params.id;
			this.setState({ ...this.state }, () => {
				this.props.dispatch(sterilizationActions.getOneClassSterilization(this.state.id));
			});
		}

		if (this.props.sterilization.getSelectedSterilizationClassStatus == "FULFILLED") {
			if (this.props.sterilization.selectedSterilizationClass) {
				const consumableSpeciality = { value: this.props.sterilization.selectedSterilizationClass.consumableSpecialityId, 
					label: this.props.sterilization.selectedSterilizationClass.consumableSpecialityName };
				const  consumableFamily =  { value: this.props.sterilization.selectedSterilizationClass.consumableFamilyId, 
					label: this.props.sterilization.selectedSterilizationClass.consumableFamilyName };                       
				this.setState({ classData: this.props.sterilization.selectedSterilizationClass,
					consumableSpeciality, consumableFamily }, () => {
					this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { id: this.state.classData.id, name: this.state.classData.name } });
				});
			}
			this.props.dispatch(sterilizationActions.clearOneClassSterilization());
		}

		if (this.props.sterilization.updateSterilizationClassStatus == "FULFILLED") {
			this.setState({ classData: { isSubsidised: 0 } },
				() => {
					this.props.dispatch(sterilizationActions.updateClassSterilizationCompleted());
					this.props.history.push(`${locations.consumable_sterilization_root}/${locations.reusableMedicalDevices_class_tab}${this.props.location.search}`);
				});
		}

		if (this.props.sterilization.updateSterilizationClassStatus == "REJECTED") {
			this.props.dispatch(sterilizationActions.updateClassSterilizationCompleted());
		}

	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: {} });
	}
}

EditClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	actions: store.actions,
	sterilization: store.sterilization,
	translations: store.translations
}))(EditClass)); 