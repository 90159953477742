import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import categoryPersonnelActions from "../../../../../../../../actions/category-personnel";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import CategoryDropdown from "../category-dropdown";
import NumericInput from "../../../../../../../../plug/numeric-input";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import screenId from "../../../../../../../../router/screenIdList";

class CreateCategoryPersonnel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			category: utils.sameDropper(),
			avgMonthlyCost: utils.sameDropper(),
			hoursPerMonth: utils.sameDropper(),
			hoursPerYear: utils.sameDropper(),
			costCategory: null,
			typeValues: {
				isCareTaker: 0,
				isPersonalDoctor: 0,
				isPersonalSupport: 0,
				isPersonalAdmin: 0
			},
			isPermanent: 0,
			invalidName: false,
			invalidCategory: false,
			invalidAverage: false,
			invalidHMonth: false,
			invalidHYear: false,
			invalidType: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.categoryPersonnelCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	onNameChange(value) {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	categoryChanged(e) {
		const prevState = this.state;
		prevState.category = e;
		prevState.invalidCategory = false;
		this.setState(prevState);
	}

	typeChanged(e) {
		const prevState = this.state;
		for (const key in prevState.typeValues) {
			if (key === e) {
				prevState.typeValues[key] = 1;
			} else {
				prevState.typeValues[key] = 0;
			}
		}
		prevState.invalidType = false;
		this.setState(prevState);
	}

	togglePermanent(e) {
		this.setState({ isPermanent: e.target.checked ? 1 : 0 });
	}

	costCategoryChanged(e) {
		const prevState = this.state;
		prevState.costCategory = e;
		this.setState(prevState);
	}

	selectedType() {
		for (const key in this.state.typeValues) {
			if (this.state.typeValues[key]) {
				return key;
			}
		}
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim()) {
			prevState.invalidName = true;
		}
		if (!this.state.category) {
			prevState.invalidCategory = true;
		}
		if (!this.state.avgMonthlyCost || this.state.avgMonthlyCost == 0) {
			prevState.invalidAverage = true;
		}
		if (!this.state.hoursPerMonth || this.state.hoursPerMonth == 0) {
			prevState.invalidHMonth = true;
		}
		if (!this.state.hoursPerYear || this.state.hoursPerYear == 0) {
			prevState.invalidHYear = true;
		}
		this.setState(prevState);
		if (!this.state.invalidName
            && !this.state.invalidAverage
            && !this.state.invalidCategory
            && !this.state.invalidHMonth
            && !this.state.invalidHYear
            && !this.state.invalidType) {
			return true;
		}
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name.trim(),
				category: this.state.category,
				avgMonthlyCost: utils.calculateFloatValue(this.state.avgMonthlyCost),
				hoursPerMonth: utils.calculateFloatValue(this.state.hoursPerMonth),
				hoursPerYear: utils.calculateFloatValue(this.state.hoursPerYear)
			};
			if (this.state.costCategory) {
				payload.costCategory = this.state.costCategory;
			}
			this.props.dispatch(categoryPersonnelActions.createCategoryPersonnel(payload));
		}
	}

	getNumericValue(label, value) {
		const prevState = this.state;
		prevState[label] = value;
		switch (label) {
			case "avgMonthlyCost": prevState.invalidAverage = false;
				break;
			case "hoursPerMonth": prevState.invalidHMonth = false;
				break;
			case "hoursPerYear": prevState.invalidHYear = false;
				break;
			default: break;
		}
		this.setState(prevState);
	}

	render() {
		if (this.props.categoryPersonnel.isCreateCategoryPersonnelCompleted === "FULFILLED") {
			this.props.history.push(locations.categoryPersonnel);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(categoryPersonnelActions.clearCreateCategoryPersonnel());
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.onNameChange.bind(this)}
									maxLength={60}
								/>
								{this.state.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 margin-bottom-5">
							<CategoryDropdown category={this.state.category} invalidCategory={this.state.invalidCategory} categoryChanged={this.categoryChanged.bind(this)} />
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Average Monthly Cost" /></label>
								<NumericInput value={this.state.avgMonthlyCost} maxLength={10} label="avgMonthlyCost" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidAverage && <span className="invalid_right fixed_label"><I18 tkey="Enter valid average cost" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Hours Per Month" /></label>
								<NumericInput value={this.state.hoursPerMonth} maxLength={10} label="hoursPerMonth" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidHMonth && <span className="invalid_right fixed_label"><I18 tkey="Enter valid hours per month" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Hours Per Year" /></label>
								<NumericInput value={this.state.hoursPerYear} maxLength={10} label="hoursPerYear" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidHYear && <span className="invalid_right fixed_label"><I18 tkey="Enter valid hours per year" /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12 margin-top-25-check">
							<div className="di w_100 text_align_right">
								{/* <Checkbox className="margin-top-7 float_left" onChange={this.togglePermanent.bind(this)} checked={this.state.isPermanent}><I18 tkey="Permanent" /></Checkbox> */}
								<ActionButton showAdd onClick={this.createClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateCategoryPersonnel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	categoryPersonnel: store.categoryPersonnel,
	translations: store.translations
}))(CreateCategoryPersonnel));
