export default {
	GET_NODE_GROUP_LIST: "GET_NODE_GROUP_LIST",
	GET_NODE_LIST: "GET_NODE_LIST",
	CREATE_UPDATE_NODE_GROUP: "CREATE_UPDATE_NODE_GROUP",
	DELETE_NODE_GROUP: "DELETE_NODE_GROUP",
	GET_NODE_GROUP: "GET_NODE_GROUP",

	CLEAR_GET_NODE_GROUP_LIST: "CLEAR_GET_NODE_GROUP_LIST",
	CLEAR_GET_NODE_LIST: "CLEAR_GET_NODE_LIST",
	CLEAR_CREATE_UPDATE_NODE_GROUP: "CLEAR_CREATE_UPDATE_NODE_GROUP",
	CLEAR_DELETE_NODE_GROUP: "CLEAR_DELETE_NODE_GROUP",
	CLEAR_GET_NODE_GROUP: "CLEAR_GET_NODE_GROUP",
	GET_ALL_GROUP_NAME: "GET_ALL_GROUP_NAME",
	CLEAR_GET_ALL_GROUP_NAME: "CLEAR_GET_ALL_GROUP_NAME"
};
