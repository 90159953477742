import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../../plug/regex-name-search-input";
import consumableClassActions from "../../../../../../../../../actions/consumable";
import SelectConsumableClass from "../../selectConsumableClass";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			consumableClassName: "",
			consumableClassCode: "",
			invalidConsumableClassName: false,
			invalidConsumableClassCode: false,
			invalidConsumableClass: false,
			selectedConsumableClass: "",
			consumableClass: {},
			consumableClassId: "",
			consumableSpecialityApproved: false,
			consumableFamilyApproved: false,
			consumableClassFound: false,
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(consumableClassActions.fetchOneConsumableClass(this.props.consumableClassId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidConsumableClassName: false, invalidConsumableClassCode: false, invalidConsumableClass: false, consumableClassFound: false });
		this.props.refreshCompleted();
	}

	onConsumableClassChange(e) {
		this.setState({ selectedConsumableClass: e ? e : "", invalidConsumableClass: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!utils.checkConsomableName(this.state.consumableClassName) || !(this.state.consumableClassName.trim())) {
			prevState.invalidConsumableClassName = true;
		}
		if (!(this.state.consumableClassCode)) {
			prevState.invalidConsumableClassCode = true;
		}
		if (prevState.invalidConsumableClassName || prevState.invalidConsumableClassCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateConsumableClass = () => {
		if (this.state.selectedConsumableClass && this.state.consumableClassFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.consumableClassName,
					code: this.state.consumableClassCode
				};
				this.props.dispatch(consumableClassActions.saveAndApproveConsumableClass(this.state.consumableClass.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateConsumableClass()) {
				payload = {
					consumableId: this.state.selectedConsumableClass && this.state.selectedConsumableClass.value ? this.state.selectedConsumableClass.value : ""
				};
				this.props.dispatch(consumableClassActions.assignAndApproveConsumableClass(this.state.consumableClass.id, payload));
			}
			else {
				this.setState({ invalidConsumableClass: true });
			}

		}

	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.consumableClassFound = true;
			this.state.value = 2;
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidConsumableClassName = false;
		prevState.consumableClassName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidConsumableClassCode = false;
		prevState.consumableClassCode = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.consumableClassId !== this.state.consumableClassId) {
			this.state.consumableClassId = this.props.consumableClassId;
			this.props.dispatch(consumableClassActions.fetchOneConsumableClass(this.props.consumableClassId));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.consumable.asssignAndApproveConsumableClassStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, consumableClassName: "", consumableClassCode: "", consumableClassFound: false });
			this.props.dispatch(consumableClassActions.assignAndApproveConsumableClassCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.consumable.saveAndApproveConsumableClassStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, consumableClassName: "", consumableClassCode: "", consumableClassFound: false });
			this.props.dispatch(consumableClassActions.saveAndApproveConsumableClassCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.consumable.fetchOneConsumableClassComplete === "FULFILLED") {
			this.state.consumableClass = this.props.consumable.consumableClass;
			this.state.consumableClassName = this.state.consumableClass.name ? this.state.consumableClass.name : "";
			this.state.consumableClassCode = this.state.consumableClass.code ? this.state.consumableClass.code : "";
			this.state.selectedConsumableClass = { label: this.state.consumableClass.name ? this.state.consumableClass.name : "", value: this.state.consumableClass.id }; 
			this.state.isloading = true;
			this.state.consumableSpecialityApproved = this.state.consumableClass.specialityApproved ? this.state.consumableClass.specialityApproved : false;
			this.state.consumableFamilyApproved = this.state.consumableClass.familyApproved ? this.state.consumableClass.familyApproved : false;
			this.props.dispatch(consumableClassActions.clearFetchOneConsumableClass());
		}
		if (this.props.consumable.fetchOneConsumableClassComplete === "REJECTED") {
			this.props.dispatch(consumableClassActions.clearFetchOneConsumableClass());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Consumable class", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.consumableSpecialityApproved && this.state.consumableFamilyApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.consumableClass.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.consumableClass.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.consumableClass.hospitalName ? this.state.consumableClass.hospitalName : ""}</p>
							</div>
							{this.state.consumableClass.specialityName &&
                            <p className="padding-left-15 padding-right-15"><span className="bold"><I18 tkey="SPECIALITY NAME"/>:</span> {this.state.consumableClass.specialityName ? this.state.consumableClass.specialityName : ""}</p>
							}
							{this.state.consumableClass.familyName &&
                            <p className="padding-left-15 padding-right-15"><span className="bold"><I18 tkey="FAMILY NAME"/>:</span> {this.state.consumableClass.familyName ? this.state.consumableClass.familyName : ""}</p>
							}
						</div> 
						{this.state.consumableSpecialityApproved && this.state.consumableFamilyApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											<NameInput
												value={this.state.consumableClassCode}
												inputChanged={this.codeChanged.bind(this)}
												disabled= {true}
												maxLength={10}
											/>
											{this.state.invalidConsumableClassCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid consumable code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group ">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.consumableClassName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidConsumableClassName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid consumable class name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Consumable Class" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<SelectConsumableClass defaultValue="Select Consumable Class" disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedConsumableClass}
												consumableType={this.props.consumableType}
												statusCheck={this.statusCheck.bind(this)}
												onConsumableClassChange={this.onConsumableClassChange.bind(this)}/>
											{this.state.invalidConsumableClass && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select consumable class" /></span>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.consumableSpecialityApproved ?
									<I18 tkey="The speciality for this consumable class is waiting for approval. Please approve the speciality first" />
									: this.state.isloading && !this.state.consumableFamilyApproved ?  <I18 tkey="The family for this consumable class is waiting for approval. Please approve the family first" /> : ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	consumable: store.consumable,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApproveModel));    