import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import interventionDetailsAction from "../../../../../../../../actions/intervention-details";

class Staff extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			hospitalId: "",
			status: "ALL"
		};
	}

	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	getTable(list) {
		return (
			<table className="w_100">
				<thead>
					<tr>
						<th>
							<I18 tkey="NAME" />
						</th>

						<th>
							<I18 tkey="NUMBER" />
						</th>
						{ !this.props.isAnaesthesia && (
							<th>
								<I18 tkey="DURATION" />
							</th>
						)}
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map((data, index) => (
				<tr key={index}>
					<td>{data.personnelName}</td>
					<td>
						{" "}
						{data.numberOfPersonnels}
					</td>
					{ !this.props.isAnaesthesia
                            && <td>{data.duration ? data.duration : 0}</td>}
				</tr>
			));
		}

		return (
			<tr>
				<td colSpan={this.getColSpan()}><div className="text_align_center w_100"><I18 tkey={`NO ${this.props.type.replace(/_/g, " ").toUpperCase()} FOUND`} /></div></td>
				{" "}
			</tr>
		);
	}

	getColSpan() {
		const val = 5;
		return val;
	}

	onFetchData() {
		this.props.dispatch(interventionDetailsAction.getStaff(this.props.type, this.props.match.params.id, this.state.sort, this.state.desc));
	}

	render() {
		if (this.props.interventionDetails.reloadStaff) {
			this.props.dispatch(interventionDetailsAction.clearReloadStaff());
			this.onFetchData();
		}
		if (this.props.interventionDetails.fetchAllStaff === "FULFILLED") {
			this.state.list = this.props.interventionDetails.staff.personnels;
			this.state.subTotal = this.props.interventionDetails.staff.sumTotal;
			this.state.template = this.props.interventionDetails.staff.template ? this.props.interventionDetails.staff.template.templateName : "";
			this.state.loading = false;
			this.props.dispatch(interventionDetailsAction.clearGetStaff());
		}

		return (
			<div className="risk_preparation_container">
				<div className="table_heading">
					<I18 tkey={this.props.type.toUpperCase()} />
&nbsp;
					{this.state.template ? (`(${this.state.template})`) : ""}
				</div>
				<div className="table_div risk_table">{this.getTable(this.state.list)}</div>
			</div>
		);
	}
}

Staff.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	interventionDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	interventionDetails: store.interventionDetails,
	translations: store.translations
}))(Staff));
