import React, { Component } from "react";
import PropTypes from "prop-types";
import utils from "../../../../../../../../utils/utils";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Table, Tooltip } from "antd";
import events from "../../../../../../../../actions/events";
import { statusList } from "../../../../../../../../constants";
import { MenuOutlined, DeleteOutlined, EditOutlined  } from "@ant-design/icons";
import "antd/dist/antd.min.css";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Resizable } from "react-resizable";
import Pagination from "../../../../../../../../plug/pagination";

const DragHandle = sortableHandle(() => (<MenuOutlined style={{ cursor: "grab", color: "#999" }} />));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

//max-height: calc(100vh - 250px);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class Problem extends Component {

	constructor(props) {
		super(props);

		this.state = {
			problemList: [],
			loading: false,
			totalPages: 1,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			sort: "problemType",
			desc: 0,
			deleteStepVisible: false,
			deletedProblem: {},
			search: "",
			hospitalId: "",
			status: "ALL",
			modelVisible: false,
			enableReorder: this.props.enableReorder,
			problemType: 400,
			stepSectionType: 400,
			code: 75,
			tableBlankSpace: 0
		};
	}
	fetchDataFromServer() {
		let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort ? this.state.sort : "problemType"}&desc=${this.state.desc}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.sectionType ? `&stepSectionType=${this.state.sectionType}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.search ? `&search=${this.state.search}` : "";
		this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayProblems(queryString));
	}

	componentDidUpdate() {
		if (this.state.enableReorder != this.props.enableReorder) {
			this.setState({ enableReorder: this.props.enableReorder });
			this.fetchDataFromServer();
		}
	}

	onFetchData(pagination, filters, sorter) {
		const prevState = this.state;
		prevState.loading = true;
		if (sorter.field && sorter.field.length > 0) {
			prevState.sort = sorter.field;
			prevState.desc = sorter.order == "ascend" ? 0 : 1;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = pagination.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	editClicked(problem) {
		return () => {
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}/${problem.id}`);
		};
	}
	deleteClicked(step) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedProblem: step, deleteStepVisible: true });
		};
	}
	approveClicked() { }

	hideModalDelete() {
		this.setState({ deleteStepVisible: false });
	}

	deleteProblem() {
		this.props.dispatch(adminClinicalPathwayActions.deleteClinicalPathwayProblem(this.state.deletedProblem.id));
		this.setState({ deleteStepVisible: false, deletedProblem: {} });
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex;
		this.setState({ ...this.state }, () => {
			this.onFetchData({ pageSize: this.state.pageSize }, null, { order: (this.state.desc == "ascend" ? 0 : 1), field: this.state.sort });
		});
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange(value) {
		this.setState({ ...this.state, pageSize: value, page: 1 }, () => { this.onPageChange(this.state.page); });
	}
	getText(value) {
		return <div>{value}</div>;
	}

	getActions(row, statusList, isPermissionGranted) {
		return (

			<div className="margin_left_auto min_w_34 text_align_center">
				{!this.props.enableReorder ?
					<span>
						{row.status === statusList.STATUS.values.APPROVED && isPermissionGranted("EDIT") ?
							<EditOutlined
								className="edit_color margin-right-10"
								type="edit"
								onClick={this.editClicked(row)} />
							: isPermissionGranted("EDIT") ? <a className="waiting-for-approve" onClick={this.approveClicked(row)}><img src="/images/waitingforapprovel.png" /> </a> : ""
						}
						{row.status === statusList.STATUS.values.APPROVED && !row.isMandatory && isPermissionGranted("DELETE") ?
							<DeleteOutlined
								className="delete_color"
								type="delete"
								onClick={this.deleteClicked(row)} />
							: ""}
					</span>
					: <DragHandle />
				}
			</div>


		);
	}

	getTaskList(list) {
		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns()}
				bordered
				rowKey="id"
				key={0}
				components={{
					header: {
						cell: ResizableTitle
					},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	}

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	DraggableBodyRow = ({ ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex(
			(x) => x.id === restProps["data-row-key"]
		);
		return <SortableItem index={index} {...restProps} />;
	};

	onSortEnd = ({ oldIndex, newIndex }, list) => {
		if (oldIndex !== newIndex) {
			const prevState = this.state;
			prevState.problemList = arrayMove(
				[].concat(list),
				oldIndex,
				newIndex
			).filter((el) => !!el);

			Array.prototype.splice.apply(
				list,
				[0, prevState.problemList.length].concat(prevState.problemList)
			);
			this.setState({ list: prevState.problemList, orderChanged: true });
			this.props.reorderList(prevState.problemList, "problemList");
		}
	};

	handleResize = label => (e, { size }) => {
		switch (label) {
			case "problemType":
				this.setState({ problemType: size.width });
				break;
			case "code":
				this.setState({ code: size.width });
				break;
			case "stepSectionType":
				this.setState({ stepSectionType: size.width });
				break;
			default:
				break;
		}
	};

	getColumns = () => [
		{
			title: i18Get("ACTIONS", utils.getLanguage()),
			key: "action",
			render: row => { return row.id ? (this.getActions(row, statusList, this.props.isPermissionGranted)) : <span>&#10240;</span>; },
			width: "70px"
		},
		{
			title: i18Get("CODE", utils.getLanguage()),
			dataIndex: "code",
			key: "code",
			render: text => (
				this.getText(text ? text : "")
			),
			width: this.state.code,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("code")
			})
		},
		{
			title: i18Get("PROBLEM TYPE", utils.getLanguage()),
			dataIndex: "problemType",
			key: "problemType",
			render: text => (
				this.getText(text ? text : "")

			),
			width: this.state.problemType,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("problemType")
			})
		},
		{
			title: i18Get("CLINICAL PATHWAY STAGES", utils.getLanguage()),
			dataIndex: "stepSectionType",
			key: "stepSectionType",
			render: text => (
				this.getText(text ? text === "CLINICAL_PATHWAY" ? i18Get("HOSPITALISATION", utils.getLanguage()) : i18Get(text.replace(/_/g, " "), utils.getLanguage()) : "")
			),
			width: this.state.stepSectionType,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("stepSectionType")
			})
		},
		{
			title: i18Get("STATUS", utils.getLanguage()),
			//dataIndex: "status",
			key: "status",
			render: text => {
				return text ?
					<Tooltip placement="top" title={i18Get((text.status ? text.status === "APPROVED" ? "Approved" : "Waiting For Approval" : "") + " - From " + (text.hospitalName ? text.hospitalName : "Master"), utils.getLanguage())}>
						<div className="d-flex align-items-center">
							{text.hospitalName ? text.hospitalName : "Master"}
							<div className={`hospitalname_tag margin-left-10 ${text.hospitalName ? "hospitalnameactive" : ""}`}></div>
						</div>

					</Tooltip> : "";
			}
		}
	];

	calcTableBlankSpace = () => {
		const prevState = this.state;
		const itemCount = prevState.problemList.length;
		const difference = 15 - itemCount;
		if (difference > 0) {
			prevState.tableBlankSpace = 31 * difference;
		} else {
			prevState.tableBlankSpace = 0;
		}
		this.setState(prevState);
	};

	render() {
		if ((this.state.search !== this.props.search) || (this.state.hospitalId !== this.props.hospitalId || this.state.sectionType !== this.props.sectionType)
			|| (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.sectionType = this.props.sectionType;
			this.state.problemList = [];
			this.state.page = 1;
			this.state.loading = true;
			this.fetchDataFromServer();
		}
		if (this.props.adminClinicalPathway.fetchAllClinicalPathwayProblemCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminClinicalPathway.problemList.count / this.state.pageSize);
			this.state.problemList = this.props.adminClinicalPathway.problemList.data;
			this.props.reorderList(this.state.problemList, "problemList");
			this.props.getCount(this.props.adminClinicalPathway.problemList.count);
			this.props.dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwayProblems());
			this.calcTableBlankSpace();
		}
		if (this.props.adminClinicalPathway.deleteClinicalPathwayProblemCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch({ type: events.REFRESH_FILTERS, payload: {} });
			this.props.dispatch(adminClinicalPathwayActions.clearDeleteClinicalPathwayProblem());
		}
		return (
			<div className="font-size-12-table padding-bottom-45">
				{this.getTaskList(this.state.problemList)}
				<Pagination
					page={this.state.page}
					totalPage={this.state.totalPages}
					pageSize={this.state.pageSize}
					onPageSizeChange={this.onPageSizeChange.bind(this)}
					onPageChange={this.onPageChange.bind(this)}
					style={{ marginTop: `${this.state.tableBlankSpace}px` }} />
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Problem", utils.getLanguage())}
					visible={this.state.deleteStepVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteProblem.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" />&nbsp;
						<span className="bold">{this.state.deletedProblem.problemType}</span> ?
					</div>
				</Modal>
			</div>
		);
	}
}

Problem.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminClinicalPathway: store.adminClinicalPathway
}))(Problem));
