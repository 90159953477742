import React, { Component, Fragment } from "react";
import I18, { i18Get } from "../../../../../../i18";
import { Modal, Spin } from "antd";
import utils from "../../../../../../utils/utils";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Config from "../../../../../../MOM-config";
import { LoadingOutlined } from "@ant-design/icons";
import healthPathwayAction from "../../../../../../actions/health-pathway";
import DragWindow from "../../../../../../plug/drag-window"; 

class ExportHealthPathayExcel extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showModel: false,
			loading: false,
			code: null,
			fileLocation: null,
			showColumns: []
		};
		this.timer = null;
	}

	okText = () => {
		return !this.state.loading ? <I18 tkey="Export now" /> : <I18 tkey="Exporting" />;
	};

	toggleModal = () => {
		this.setState({ showModel: false, loading: false });
	};

	getFilters = () => {
		const filters = {};
		if (this.props.status ) {
			filters.status = this.props.status;
		}
		if (this.props.hospital && this.props.hospital.value) {
			filters.hospitalId = this.props.hospital.value;
		}

		if (this.props.filterValue) {
			filters.code = this.props.filterValue;
			filters.name = this.props.filterValue;
		}
		if (this.props.speciality) {
			filters.specialityId = this.props.speciality.value;
		}
		if (this.props.family) {
			filters.familyId = this.props.family.value;
		}
		if (this.props.waitingForApprovalStatus) {
			filters.waitingForApprovalStatus = this.props.waitingForApprovalStatus;
		}
		if (this.props.clinicalPathwayType) {
			filters.clinicalPathwayType = this.props.clinicalPathwayType;
		}
		if (this.props.sort) {
			filters.sort = this.props.sort;
			filters.sortType = this.props.sortType ? 1 : 0;
		}

		filters.isCheckList = this.props.category === "checkList" ? true : false;
		filters.isModel = this.props.category === "model" ? true : false;
		filters.locale = `${utils.getLanguage()}`;
		filters.exportType = "EXCEL";
		filters.showColumns = this.state.showColumns;
		filters.timezone = new Date().getTimezoneOffset();
		return filters;
	};

	onOkClick = () => {
		this.setState({ loading: true });
		const filter = this.getFilters();
		let queryString = "";
		queryString += `locale=${filter.locale ? filter.locale : ""} &`;
		queryString += filter.exportType ? `exportType=${filter.exportType}` : "";

		queryString += `${this.props.sort ? `&sort=${this.props.sort}` : ""}${this.props.desc ? `&desc=${this.props.desc}` : ""}`;
		queryString += this.props.filterValue ? `&code=${encodeURIComponent(this.props.filterValue)}&name=${encodeURIComponent(this.props.filterValue)}&momCode=${encodeURIComponent(this.props.filterValue)}&momName=${encodeURIComponent(this.props.filterValue)}` : "";
		queryString += this.props.speciality ? `&specialityId=${this.props.speciality.value}` : "";
		queryString += this.props.family ? `&familyId=${this.props.family.value}` : "";
		queryString += this.props.hospital ? `&hospitalId=${this.props.hospital.value}` : "";
		queryString += this.props.clinicalPathwayType && this.props.clinicalPathwayType !== "ALL" ? `&clinicalPathwayType=${this.props.clinicalPathwayType}` : "";
		queryString +=  `&organization=${(this.props.organization ? this.props.organization.toUpperCase() : "MOM")}`;
		// queryString += this.props.category === "checkList" ? "&isCheckList=1" : "&isCheckList=0";
		// queryString += this.props.category === "model" ? "&isModel=1" : "";
		this.props.dispatch(healthPathwayAction.exportHealthPathwayList(queryString, filter));
	};

	onExportClick = () => {
		this.setState({ showModel: true });
	};

	isReportCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while exporting health pathway list", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	render() {
		return (
			<Fragment>
				<button className="common_button margin-right-15" type="button" onClick={this.onExportClick}>
					<I18 tkey="EXPORT TO EXCEL" />
				</button> 
				<Modal
					visible={this.state.showModel}
					maskClosable={false}
					className="re_assign_modal message_modal modal_mini_height_auto"
					title={<DragWindow title={i18Get("Export Data ", utils.getLanguage())} /> }
					onCancel={this.toggleModal}
					okText={this.okText()}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					wrapClassName="drag_modal"
					onOk={this.onOkClick}
					okButtonProps={{ loading: this.state.loading }}>
					<div className="alert margin-top-15 alert-info">
						<p> <I18 tkey="Exporting the data of health pathway will take some time. Do not close this tab until the process is completed." /></p>
					</div>
				</Modal>
			</Fragment>
		);
	}

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(healthPathwayAction.checkHealthPathwayListStatus(this.state.code));
			}.bind(this), 3000);
		}
	}

	componentDidUpdate() {
		if (this.props.healthPathway.healthPathwayListExportStatus == "FULFILLED") {
			this.props.dispatch(healthPathwayAction.exportHealthPathwayListCompleted());
			if (this.props.healthPathway.healthPathwayReportList && this.props.healthPathway.healthPathwayReportList.code) {
				this.setState({ code: this.props.healthPathway.healthPathwayReportList.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.healthPathway.healthPathwayReportStatus === "REJECTED") {
			this.props.dispatch(healthPathwayAction.exportHealthPathwayListCompleted());
			this.setState({ loading: false });
		}

		if (this.props.healthPathway.healthPathwayReportStatus === "FULFILLED") {
			this.props.dispatch(healthPathwayAction.checkHealthPathwayListStatusCompleted());
			clearTimeout(this.timer);
			// console.log(">>>>>>>>>>>>>>>>>>>>", this.props.healthPathway.healthPathwayReportStage);
			if (this.props.healthPathway.healthPathwayReportStage && this.props.healthPathway.healthPathwayReportStage.status) {
				if (this.props.healthPathway.healthPathwayReportStage.status === "PROCESSED") {
					const url = Config.apiRoot.replace("/api/v1", "");
					this.props.healthPathway.healthPathwayReportStage.path ? document.location.href = `${url}${this.props.healthPathway.healthPathwayReportStage.path}` : "";
					this.toggleModal();
				} else if (!this.isReportCompleted(this.props.healthPathway.healthPathwayReportStage.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}
		}

		if (this.props.healthPathway.healthPathwayReportExportStatus === "REJECTED") {
			this.props.dispatch(healthPathwayAction.checkHealthPathwayListStatusCompleted());
			this.setState({ loading: false });
		}

	}
}

ExportHealthPathayExcel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	parcours: store.parcours,
	healthPathway: store.healthPathway
}))(ExportHealthPathayExcel));
