import { useSelector, useDispatch } from "react-redux";
import React, { useEffect } from "react";
import locations from "../../../../../router/locations";
import healthPathwayAction from "../../../../../actions/health-pathway";
import "./style.css";
import { NotFound } from "../../../../../utils/icons";
import I18 from "../../../../../i18";
let nullData = false;
function LoadLinkData () {

	const dispatch = useDispatch();
	const healthPathway = useSelector(store => store.healthPathway);
	
	useEffect( () => {

		const getLinkData = window.location.href.split("?")[1];
		const queryString = new URLSearchParams(getLinkData);
		let treatmentCode = "";
		let hospitalCode = "";
		for (const pair of queryString.entries()) {
			treatmentCode =  pair[0] === "treatmentCode" ? pair[1] : treatmentCode;
			hospitalCode =  pair[0] === "hospitalCode" ? pair[1] : hospitalCode;
		}

		dispatch(healthPathwayAction.getLinkData(treatmentCode, hospitalCode));

	}, []);

	useEffect( () => {

		if (healthPathway.linkedDataStatus === "FULFILLED") {

			switchToDetailsPage(healthPathway.linkedData);

			dispatch(healthPathwayAction.clearGetLinkData());
	
		}
		if (healthPathway.linkedDataStatus === "REJECTED") {
			window.location.assign(`#${locations.clinical_pathway_plain}`);
			dispatch(healthPathwayAction.clearGetLinkData());
	
		}

	});

	function switchToDetailsPage(data) {
		nullData = data === null ? true : false;
		if (data) {

			if (data.typeActivity === "CLINICAL_PATHWAY") {
				window.location.assign(`#${locations.clinical_pathway_plain}/mom/${data.id}`, "_blank");
			} else if (data.typeActivity === "HEALTH_PATHWAY") {
				window.location.assign(`#${locations.health_pathway_plain}/mom/${data.id}`, "_blank");
			}
		}
	}
	return (<div className="loader_wrap">

		<div className="loader_container">

			{!nullData ? <div className="margin_auto">
				<img className="w_200 log" src="images/logo.png" alt="logo"/>
				<br />
				<img className="loader" src="images/loader.gif" alt="Loading..."/>
			</div> :
				<div className="margin_auto">
					<div className="margin-bottom-50"><NotFound /><center><h4><I18 tkey="No data!" /></h4></center></div>
					<img style={{ width: "139px" } } className="w_100 log" src="images/logo.png" alt="logo"/>
				</div>
			}
		</div>
	</div>);
}
export default LoadLinkData;
