import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import utils from "../../../../../../../utils/utils";
import "../../../../../../../plug/reactTable/react-table.css";
import healthPathwayDetailsAction from "../../../../../../../actions/healthPathway-details";

class ActiveComponents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0
		};
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("HEALTH PATHWAYS", utils.getLanguage()),
				id: "name",
				accessor: d => d.label
			}
		]);
		return tableArray;
	}

	isCopyCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.props.closeCopyToHealthPathways(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while copying health pathway", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.props.closeCopyToHealthPathways(); });
				return true;
			default: return false;
		}
	};

	getStatus() {
		if (this.state.code) {
			this.timer = setTimeout(function () {
				this.props.dispatch(healthPathwayDetailsAction.checkCopyToHealthPathwayStatus(this.state.code));
			}.bind(this), 3000);
		}
	}


	render() {
		return (<div>
			<div className="col-md-6">
				<ReactTable
					data={this.props.activeHealthPathways}
					loading={this.state.loading}
					className="mobile_margin_bottom_20 intervention_component height_150"
					multiSort={false}
					defaultPageSize={4}
					showPagination={false}
					manual
					noDataText={i18Get("NO DATA FOUND", utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
				/>
			</div>
			<div className="col-md-3"><div className="component_heading  di w_100 margin-bottom-10"><I18 tkey='Components' /></div></div>
			<div className="col-md-6">
				<div className="margin-bottom-10"> {this.props.genericComponents.HEALTH_DEFINITION ? <I18 tkey='DEFINITION' /> : ""}</div>
				<div className="margin-bottom-10"> {this.props.genericComponents.HEALTH_PATHWAY_DIAGRAM ? <I18 tkey='PATHWAY' /> : ""}</div>
				<div className="margin-bottom-10"> {this.props.genericComponents.HEALTH_REMARKS ? <I18 tkey='REMARKS' /> : ""}</div>
			</div>
		</div>
		);
	}

	componentDidUpdate() {
		if (this.props.healthPathwayDetails.copyToHealthPathways == "FULFILLED") {
			this.props.dispatch(healthPathwayDetailsAction.clearCopyToHealthPathways());
			if (this.props.healthPathwayDetails.statusCode && this.props.healthPathwayDetails.statusCode.code) {
				this.setState({ code: this.props.healthPathwayDetails.statusCode.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.healthPathwayDetails.copyToHealthPathways == "REJECTED") {
			this.props.dispatch(healthPathwayDetailsAction.clearCopyToHealthPathways());
			this.props.refreshCopyLoading(false);
			this.setState({ loading: false });
		}

		if (this.props.healthPathwayDetails.checkCopyToHealthPathwayStatus == "FULFILLED") {
			this.props.dispatch(healthPathwayDetailsAction.checkCopyToHealthPathwayStatusCompleted());
			clearTimeout(this.timer);
			if (this.props.healthPathwayDetails.copyToHealthPathwayStatus && this.props.healthPathwayDetails.copyToHealthPathwayStatus.status) {
				if (this.props.healthPathwayDetails.copyToHealthPathwayStatus.status == "PROCESSED") {
					utils.showSuccess(i18Get("Copy to health pathways completed successfully", utils.getLanguage()));
					this.props.closeCopyToHealthPathways();
					this.props.refreshCopyLoading(false);
				} else if (!this.isCopyCompleted(this.props.healthPathwayDetails.copyToHealthPathwayStatus.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.props.closeCopyToHealthPathways(); });
				this.props.refreshCopyLoading(false);
			}

		}

		if (this.props.healthPathwayDetails.checkCopyToHealthPathwayStatus == "REJECTED") {
			this.props.dispatch(healthPathwayDetailsAction.checkCopyToHealthPathwayStatusCompleted());
			this.props.refreshCopyLoading(false);
			this.setState({ loading: false });
		}

	}


}

ActiveComponents.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	healthPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	translations: store.translations,
	healthPathwayDetails: store.healthPathwayDetails,
	healthPathway: store.healthPathway
}))(ActiveComponents));