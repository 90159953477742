import React, { useState, useEffect } from "react";
import { Modal, Table } from "antd";
import { withRouter } from "react-router";
import healthPathway from "actions/health-pathway";
import locations from "router/locations";
import { useDispatch, useSelector } from "react-redux";
import I18, {i18Get} from "i18";
import utils from "utils/utils";
import action from "./redux/action";
import activities from "../re-groupment-list/redux/activities";
import "./styles.css";

const ViewLinkedItem = (props) => {
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const [list, setList] = useState({});

	const {reGroupDetails, fetchRegroupDetails , meta } = useSelector( (store) => store.regroupLink );
	const link = "#" + "/" + "health_pathway" ;

	const columns = [
		{
			title: i18Get("Tasks", utils.getLanguage()),
			dataIndex: "title",
			key: "title",
			render: text => <I18 tkey={text} />,
		},
		{
			title: i18Get("Duration", utils.getLanguage()),
			width: 300,
			children: [
			  {
				title: i18Get("Min", utils.getLanguage()),
				dataIndex: 'durationMinimumMinutes',
				key: 'durationMinimumMinutes',
				width: 100,
				render: text => convertDuration(text),
			  },
			  {
				title: i18Get("Max", utils.getLanguage()),
				dataIndex: 'durationMaximumMinutes',
				key: 'durationMaximumMinutes',
				width: 100,
				render: text => convertDuration(text),
			  },
			  {
				title: i18Get("Freq", utils.getLanguage()),
				dataIndex: 'duration',
				key: 'duration',
				width: 100,
				render: text => convertDuration(text)
			  }
			],
		  },
		{
			title: i18Get("Cost", utils.getLanguage()),
			dataIndex: "unitCost",
			key: "unitCost",
			width: 100,
			align: 'right',
			render: text => activities.floorInFraction(text),
		}
	];
	useEffect(() => {

		if(props.data?.groupId) {
			
			dispatch(action.getGroupDetails(props.match.params.id, props.data.id));
		}
		
	}, [props.data]);
	useEffect(() => {
		
		if(fetchRegroupDetails === "FULFILLED" && meta == props.data.id) {
			setList(reGroupDetails);
			dispatch(action.clearGetGroupDetails());
		}
		
	 }, [fetchRegroupDetails]);

	 const convertDuration = (minuts) => {
		return activities.replaceComa(`${activities.floorInFraction(activities.convertMinutes(minuts))}${activities.findTypeShortName(minuts) || ""}`);
	 };
	 
	return <>
		{props.data?.groupId && <a onClick={() => setOpen(true)}>{props.children}</a> }
		{!props.data?.groupId && props.children}
		<Modal 
			title={i18Get("Summary of Duration and Cost", utils.getLanguage())}
			centered
			open={open}
			onOk={() => setOpen(false)}
			onCancel={() => setOpen(false)}
			width={1000}
			footer= {null}
		>
			<Table 
			className="viewLinkedHp"
			dataSource={list?.tasks || []}  
			pagination={false} 
			columns={columns}  
			title={() => <span>{i18Get("Link to", utils.getLanguage())}: <a className="diagram_route_link" target="_blank" href={link +"/" + `${list?.groupDurationAndCost?.organization === "LOCAL" ? "local" : "mom"}` + "/" + `${list?.groupDurationAndCost?.healthPathwayId}`}>{list?.groupDurationAndCost?.healthPathwayName}</a></span>}
			scroll={{ y: (window.innerHeight - 400)}}
			bordered
			footer= {null}
			summary={() =>{
				if(list?.groupDurationAndCost) {
				const data = list.groupDurationAndCost;
				return (
				<Table.Summary fixed>
				  <Table.Summary.Row>
					<Table.Summary.Cell index={0}><b><I18 tkey={data.durationRule} /></b></Table.Summary.Cell>
					<Table.Summary.Cell index={1}><b>{convertDuration(data.durationMinimumMinutes)}</b></Table.Summary.Cell>
					<Table.Summary.Cell index={1}><b>{convertDuration(data.durationMaximumMinutes)}</b></Table.Summary.Cell>
					<Table.Summary.Cell index={1}><b>{ convertDuration(data.duration)}</b></Table.Summary.Cell>
					<Table.Summary.Cell index={1}><b>{activities.floorInFraction(data.unitCost)}</b></Table.Summary.Cell>
				  </Table.Summary.Row>
				</Table.Summary>
			  );}
			  return false;
			}}
			 />
		</Modal>
	</>;
};

export default withRouter(ViewLinkedItem);