import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const createCommunitySites = (payload) => ({
	type: events.CREATE_COMMUNITY_SITES,
	payload: axios.post(`${Config.apiRootSecure}/hospitals/${payload.hospitalId}/sites`, { ...payload })
});

const clearCreateCommunitySites = () => ({
	type: events.CLEAR_CREATE_COMMUNITY_SITES,
	payload: {}
});

const getAll = (queryString, meta) => ({
	type: events.COMMUNITY_SITES_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/sites${queryString}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_COMMUNITY_SITES_FETCH,
	payload: {}
});

const deleteCommunitySites = (id, hospitalId) => ({
	type: events.DELETE_COMMUNITY_SITES,
	payload: axios.delete(`${Config.apiRootSecure}/hospitals/${hospitalId}/sites/${id}`)
});

const clearDeleteCommunitySites = () => ({
	type: events.CLEAR_DELETE_COMMUNITY_SITES,
	payload: {}
});

const getOne = (id, hospitalId) => ({
	type: events.COMMUNITY_SITES_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/hospitals/${hospitalId}/sites/${id}`)
});

const clearGetOne = () => ({
	type: events.CLEAR_COMMUNITY_SITES_GET_ONE,
	payload: ""
});

const updateCommunitySites = (id, payload) => ({
	type: events.UPDATE_COMMUNITY_SITES,
	payload: axios.put(`${Config.apiRootSecure}/hospitals/${payload.hospitalId}/sites/${id}`, payload)
});

const updateCommunitySitesCompleted = () => ({
	type: events.UPDATE_COMMUNITY_SITES_COMPLETED,
	payload: ""
});

const filterChangeCommunitySites = (filter, ignorePageChange) => ({
	type: events.FILTER_CHANGE_COMMUNITY_SITES,
	payload: { filter, ignorePageChange }
});

export default {
	createCommunitySites,
	clearCreateCommunitySites,
	getAll,
	clearGetAll,
	deleteCommunitySites,
	clearDeleteCommunitySites,
	getOne,
	clearGetOne,
	updateCommunitySites,
	updateCommunitySitesCompleted,
	filterChangeCommunitySites
};
