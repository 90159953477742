import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../../plug/reactTable/react-table.css";
import adminPersonnelActions from "../../../../../../../../../actions/admin-personnel";
import events from "../../../../../../../../../actions/events";
import SelectNewTemplate from "../../new-templates-dropdown";
import SelectExistingTemplate from "../../existing-templates-dropdown";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			newTemplateStatus: false,
			existingTemplateStatus: false,
			invalidNewTemplate: false,
			invalidExistingTemplate: false,
			invalidTemplate: false,
			selectedExistingTemplate: "",
			selectedNewTemplate: "",
			templateDetails: {},
			templateId: "",
			isloading: false,
			personnelTemplateApproved: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(adminPersonnelActions.fetchOneTemplateDetails(this.props.personnelId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ selectedNewTemplate: false, selectedExistingTemplate: false, invalidExistingTemplate: false, invalidNewTemplate: false, value: 1, personnelId: "", newTemplateStatus: false, existingTemplateStatus: false });
		this.props.refreshCompleted();
	}

	newTemplateChanged(e) {
		this.setState({ selectedNewTemplate: e ? e : "", invalidNewTemplate: e && e.value ? false : true });
	}

	existingTemplateChanged(e) {
		this.setState({ selectedExistingTemplate: e ? e : "", invalidExistingTemplate: e && e.value ? false : true });
	}

	validateNewTemplate() {
		if (this.state.selectedNewTemplate && this.state.newTemplateStatus) {
			return true;
		}
		return false;
	}

	validateExistingTemplate = () => {
		if (this.state.selectedExistingTemplate && this.state.existingTemplateStatus) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateNewTemplate()) {
				payload = {
					categoryPersonnelId: this.state.selectedNewTemplate && this.state.selectedNewTemplate.value ? this.state.selectedNewTemplate.value : ""
				};
				this.props.dispatch(adminPersonnelActions.saveAndApproveTemplate(this.state.templateDetails.id, payload));
			}
			else {
				this.setState({ invalidNewTemplate: true });
			}
		}
		if (this.state.value === 2) {
			if (this.validateExistingTemplate()) {
				payload = {
					categoryPersonnelId: this.state.selectedExistingTemplate && this.state.selectedExistingTemplate.value ? this.state.selectedExistingTemplate.value : ""
				};
				this.props.dispatch(adminPersonnelActions.assignAndApproveTemplate(this.state.templateDetails.id, payload));
			}
			else {
				this.setState({ invalidExistingTemplate: true });
			}

		}
	}

	statusCheck(status) {
		if (status === "NEW_TEMPLATE") {
			this.state.newTemplateStatus = true;
		}
		if (status === "EXISTING_TEMPLATE") {
			this.state.existingTemplateStatus = true;
			this.state.value = 2;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.adminPersonnel.asssignAndApproveTemplateStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewTemplate: false, selectedExistingTemplate: false, invalidExistingTemplate: false, invalidNewTemplate: false, value: 1, personnelId: "", newTemplateStatus: false, existingTemplateStatus: false });
			this.props.dispatch(adminPersonnelActions.assignAndApproveTemplateCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.adminPersonnel.saveAndApproveTemplateStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewTemplate: false, selectedExistingTemplate: false, invalidExistingTemplate: false, invalidNewTemplate: false, value: 1, personnelId: "", newTemplateStatus: false, existingTemplateStatus: false });
			this.props.dispatch(adminPersonnelActions.saveAndApproveTemplateCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.adminPersonnel.fetchOneTemplateDetailsCompleted === "FULFILLED") {
			this.state.templateDetails = this.props.adminPersonnel.templateDetails;
			this.state.selectedExistingTemplate = { label: this.state.templateDetails.name ? this.state.templateDetails.name : "", value: this.state.templateDetails.categoryPersonnelId }; 
			this.state.selectedNewTemplate = { label: this.state.templateDetails.name ? this.state.templateDetails.name : "", value: this.state.templateDetails.categoryPersonnelId }; 
			this.state.isloading = true;
			this.state.personnelTemplateApproved = this.state.templateDetails.categoryPersonnelApproved ? this.state.templateDetails.categoryPersonnelApproved : false;
			this.props.dispatch(adminPersonnelActions.clearFetchOneTemplateDetails());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.templateDetails.name, id: this.state.templateDetails.id } });
		}

		if (this.props.adminPersonnel.fetchOneTemplateDetailsCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearFetchOneTemplateDetails());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit and Approve - Personnel Template Mapping", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.personnelTemplateApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative margin-left-0 margin-right-0">
								{/* <div className="col-md-3">
                                    <div class="form-group margin-bottom-0 pl-15">
                                        <label className="bold width-100"><I18 tkey="CODE" />:</label>
                                        <label className="width-100">{this.state.templateDetails.code}</label>
                                    </div>
                                </div> */}
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.templateDetails.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.templateDetails.hospitalName ? this.state.templateDetails.hospitalName : ""}</p>
							</div>
						</div> 
						{this.state.isloading && this.state.personnelTemplateApproved ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Edit OR Add New Category Personnel" />
										</Radio>
									</div>
									<div className="col-md-12 pt-10">
										<div className="form-group margin-bottom-0 pl-20">
											<SelectNewTemplate
												defaultValue="Select TemplateCategory Personnel"
												data={this.state.selectedNewTemplate}
												dataChanged={this.newTemplateChanged.bind(this)}
												disabled={this.state.value != 1}
												checked={this.state.value}
												personnelId={this.props.personnelId}
												templateId={this.props.templateId}
												statusCheck={this.statusCheck.bind(this)}
											/>
											{this.state.invalidNewTemplate && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select template" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Existing Approved Category Personnel" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<SelectExistingTemplate
												defaultValue="Select Category Personnel"
												disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedExistingTemplate}
												statusCheck={this.statusCheck.bind(this)}
												checked={this.state.value}
												templateId={this.props.templateId}
												personnelId={this.props.personnelId}
												dataChanged={this.existingTemplateChanged.bind(this)} />
											{this.state.invalidExistingTemplate && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select template" /></span>}
										</div>
									</div>

								</div>
							</div>
							: <div className="invalid_label padding-top-7">
								{this.state.isloading ?
									<I18 tkey="The personnel for this template is waiting for approval. Please approve the personnel first" />
									: ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	adminPersonnel: store.adminPersonnel
}))(AssignAndApproveModel));    