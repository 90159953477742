import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactMultiSelectCheckboxes from "../../../../../../plug/react-multiselect-checkboxes";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import specialityAction from "./redux/action";
/*
Created at : May 18 2022
ATTRIBUT SETTINGS
=================
#   NAME            TYPE            DESCRIPTION
----------------------------------------------------------
1)  value           json-Object     current option
2)  onChange        function        on change action
3)  options         json-Object     Option List
4)  maxMenuHeight   int             default settings
5)  isMulti         Boolean         defaut true, Multiple option
6)  isClearable     Boolean         defaut true
7)  onInputChange   function        Default option
8)  placeholder     text            Placeholder Button Label
9)  noOptionsMessage text           No data mssage
10) loadingMessage  text            Loading message
11) disabled        Boolean         -
12) className       text            -
--------------------Ativity Elements-----------------------------------
.
.
.

============INSTALLATION=====================
use this demo function in the component where ever you use this component
	onChange = e => {
		let prevState = this.state;
		prevState.selectedSpeciality = e;
		//prevState.invalidSpeciality= false;//If needed
		this.setState(prevState);
	}
	Use this function for fetch Ids
	createPayloadData(data) {
		var dataList = []
		data.map(data => {dataList.push(data.value)})
		return dataList
	}
	//This is for converting server data to option list based on the id and name
	selectOptions(arrayData) {
		var prevState = this.state;
		let options = [];
		if (arrayData && arrayData.length > 0) {
			arrayData.map((data) => {
				if(data.id)
				options.push({ value: data.id, label: data.name});
			});
		}
		return options;
	}
*/

class SpecialityList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: [],
			offset: 0,
			numResults: 1000,
			isLoading: true,
			search: null,
			specialities: [],
			filter: [],
			isDefaultDataEnabled: false
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchData();
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchData(search);
			}, 800);
		}
	}

	fetchData(search) {
		this.setState({ isLoading: true });

		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += "&status=APPROVED";
		queryString += this.props.typeActivity ? `&typeActivity=${this.props.typeActivity.toUpperCase()}` : "";
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += `${this.props.isCheckList ? "&extendedType=CHECKLIST" : ""}`;
		if (this.props.filter) {
			Object.keys(this.props.filter).forEach((key) => {
				const data = this.props.filter[key];
				queryString += `${data ? `&${key}=${data}` : ""}`;
			});
		}
		this.props.dispatch(specialityAction.getAll(queryString));
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.specialities) {
			this.state.specialities.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});

			if (!prevState.isDefaultDataEnabled && this.props.defaultData && (!this.props.value || this.props.value.length === 0 )) {
				
				const defaultValue = prevState.options.filter(option => this.props.defaultData.includes(option.label));
				this.props.onChange(defaultValue);
				prevState.isDefaultDataEnabled = true;
			}
		}
		prevState.isLoading = false;
		this.setState(prevState);
	}

	render() {

		if (this.props.specialities.getAllStatus === "FULFILLED") {
			this.state.specialities = [];
			this.state.specialities = this.props.specialities.data.data;
			this.renderOptions();
			this.setState({ isLoading: false });
			this.props.dispatch(specialityAction.clearGetAll());
		}
		if (this.props.specialities.getAllStatus === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(specialityAction.clearGetAll());
		}

		if (this.state.filter !== this.props.filter) {
			this.fetchData();
			this.setState({ filter: this.props.filter });
		}

		return (
			<div className={`w_100 di react_multi_select_checkbox ${(this.props.className ? this.props.className : "")}`}>
				{
					!this.props.disabled
						? (
							<ReactMultiSelectCheckboxes
								value={this.props.value ? this.props.value : null}
								onChange={this.props.onChange}
								options={this.state.options}
								maxMenuHeight={220}
								isMulti={this.props.isMulti !== false}
								isClearable={this.props.isClearable !== false}
								onInputChange={this.onSearch.bind(this)}
								placeholderButtonLabel={i18Get((this.props.placeholder ? this.props.placeholder : "Select speciality"), utils.getLanguage())}
								noOptionsMessage={() => i18Get((this.props.noOptionsMessage ? this.props.noOptionsMessage : "No Data Found"), utils.getLanguage())}
								loadingMessage={() => i18Get((this.props.loadingMessage ? this.props.loadingMessage : "Loading..."), utils.getLanguage())}
							/>
						)
						: (
							<Select
								isDisabled
								placeholder={i18Get((this.props.placeholder ? this.props.placeholder : "Select speciality"), utils.getLanguage())}
							/>
						)
				}
			</div>
		);
	}
}

SpecialityList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	specialities: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	specialities: store.specialities,
	actions: store.actions
}))(SpecialityList));
