import eventNames from "./event";
const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.PERMISSIONS_GET_ALL}_FULFILLED`:
			return {
				...state,
				rolePermisions: action.payload.data,
				getAllStatus: "FULFILLED"
			};
		case `${eventNames.PERMISSIONS_GET_ALL}_REJECTED`:
			return {
				...state,
				getUserPermissions: "REJECTED"
			};
        
		case eventNames.CLEAR_PERMISSIONS_GET_ALL:
			return {
				...state,
				rolePermisions: "",
				getAllStatus: false
			};

		case `${eventNames.CREATE_PERMISION}_FULFILLED`:
			return {
				...state,
				permisionSaveStatus: "FULFILLED"
			};
		case `${eventNames.CREATE_PERMISION}_REJECTED`:
			return {
				...state,
				permisionSaveStatus: "REJECTED"
			};

		case eventNames.CLEAN_CREATE_PERMISION:
			return {
				...state,
				permisionSaveStatus: false
			};

		case `${eventNames.GET_USER_PERMISSIONS}_FULFILLED`:
			return {
				...state,
				userPermission: action.payload.data.data,
				getUserPermissions: "FULFILLED"
			};
		case `${eventNames.GET_USER_PERMISSIONS}_REJECTED`:
			return {
				...state,
				getUserPermissions: "REJECTED"
			};
        
		case eventNames.GET_USER_PERMISSIONS_COMPLETED:
			return {
				...state,
				getUserPermissions: false
			};
		default:
			return state;
	}
}