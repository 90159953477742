import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal } from "antd";
import I18, { i18Get } from "../../../../../../../../../i18";
import extClinicalPathwayActions from "../../../../../../../../../actions/ext-clinical-pathway";
import ActionButton from "../../../../../../../../../plug/action-button";
import utils from "../../../../../../../../../utils/utils";
import events from "../../../../../../../../../actions/events";

class ClinicalPathwayDeleteModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			deletedExtClinicalPathway: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.state.deletedExtClinicalPathway = this.props.selectedClpForDelete;
	}

	deleteExtClinicalPathwayData() {
		this.props.dispatch({ type: events.ACTION_ONE });
		this.props.dispatch(extClinicalPathwayActions.deleteExtClinicalPathway(this.props.match.params.id, this.state.deletedExtClinicalPathway.id));
	}

	render() {
		if (this.props.extClinicalPathway.isExtClinicalPathwayDeleteComplete === "FULFILLED") {
			this.state.loading = true;
			this.props.cancelClicked();
			this.props.getStepItemCount(this.state.deletedExtClinicalPathway.stepId);
			this.props.refreshExtClinicalPathwayList(this.state.deletedExtClinicalPathway.day, this.state.deletedExtClinicalPathway.clpSubStepId);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(extClinicalPathwayActions.clearDeleteExtClinicalPathway());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={`${i18Get("Delete", utils.getLanguage())} ${this.state.deletedExtClinicalPathway.item}`}
					visible={this.props.visible}
					onCancel={this.props.cancelClicked}
					footer={[
						<div key ='1' className='d-flex align-items-center justify-content-end common_cancel_btn_container'>
							<button key="Cancel" type="primary" className="ant-btn float_none_imp height_common_btn margin-right-10" onClick={this.props.cancelClicked}>{i18Get("Cancel", utils.getLanguage())}</button>
							<ActionButton key="Ok" type="primary" onClick={this.deleteExtClinicalPathwayData.bind(this)} value={i18Get("Ok", utils.getLanguage())} />
						</div>
					]}
				>
					<div>
						<I18 tkey="Are you sure you want to delete" />
						{" "}
						<span className="bold">{this.state.deletedExtClinicalPathway.item}</span>
						{" "}
            ?
					</div>
				</Modal>
			</div>
		);
	}
}

ClinicalPathwayDeleteModel.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	extClinicalPathway: store.extClinicalPathway,
	imagery: store.imagery,
	translations: store.translations
}))(ClinicalPathwayDeleteModel));
