import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import "../../../../../../../../plug/reactTable/react-table.css";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import locations from "../../../../../../../../router/locations";
import familyActions from "../../../../../../../../actions/family";
import Loader from "../../../../../../../../plug/loader";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import { typeActivity, codeTypes, statusList } from "../../../../../../../../constants";
import AssignAndApproveModel from "./assign-and-approve-model";
class ListFamily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			familyList: [],
			totalPages: 1,
			loading: false,
			status: "",
			hospitalId: "",
			familyId: "",
			specialityId: "",
			search: "",
			deleteFamilyVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedFamily: {},
			sort: "",
			desc: 0,
			modelVisible: false,
			filter: false,
			typeActivity: "INTERVENTION"
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.activityFamilyList });
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: d => d,
				width: 80,
				resizable: false,
				sortable: false,
				show: (this.state.typeActivity !== typeActivity.ACCOMMODATION.toUpperCase()),
				Cell: row => (
					<div className="margin_left_auto">
						<div className="min_width_12_px">
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<EditOutlined  className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
								: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							}
						</div>
						<div className="min_width_12_px">
							{row.value.code !== codeTypes.TYPES.FAMILY_CODE.AN && row.value.status === statusList.STATUS.values.APPROVED ?   
								<DeleteOutlined className="delete_color" type="delete" onClick={this.deleteClicked(row.value).bind(this)} />
								: "" }
						</div>
                        
                       
					</div>
				)
			},
			{
				Header: i18Get("CODE", utils.getLanguage()),
				id: "code",
				accessor: d => d.code,
				width: 80,
				resizable: false,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "name",
				accessor: d => d.name,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("SPECIALITY", utils.getLanguage()),
				id: "specialityName",
				accessor: d => d.specialityName,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			}, {
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				Cell: row => (
					row.value ? this.getText(row.value) : "Master" 
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				Cell: row => (
					this.getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	editClicked(family) {
		return () => {
			this.props.history.push(locations.activity_families + `/${family.id}?typeActivity=${this.props.typeActivity}`);
		};
	}

	deleteClicked(family) {
		return () => {
			this.setState({ ...this.state, deletedFamily: family, deleteFamilyVisible: true });
		};
	}

	approveClicked(family) {
		return (e) => {
			e.preventDefault();
			const prevState =  this.state;
			prevState.modelVisible = true;
			prevState.familyId = family.id;
			 this.setState(prevState);
		};
	}

	hideModalDelete() {
		this.setState({ ...this.state, deleteFamilyVisible: false });
	}

	deleteFamily() {
		this.props.dispatch(familyActions.deleteFamily(this.state.deletedFamily.id));
		this.setState({ ...this.state, deleteFamilyVisible: false, deletedFamily: {} });
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState, () => { this.props.getSortAndDesc(this.state.sort, this.state.desc); });
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	refreshCompleted = () => {
		this.setState({ ...this.state, modelVisible: false });
		this.fetchDataFromServer();
	};

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		queryString += this.state.search ? `code=${this.state.search}&name=${this.state.search}&specialityName=${this.state.search}&` : "";
		queryString += this.state.typeActivity ? `typeActivity=${this.state.typeActivity}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		this.props.dispatch(familyActions.fetchAllFamily(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
		this.setState({ ...this.state });
	}

	getList() {
		return (<ReactTable
			{...utils.getPropsForReactTable(this.state.familyList, (this.state.page - 1), this.state.totalPages,
				this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
				this.state.pageSize, true, this.onFetchData.bind(this), this.getCols(), true, false,
				this.onPageChange.bind(this), "ACTIVITY FAMILIES", "", this.onPageSizeChange.bind(this))}
		/>);
	}

	render() {
		if (this.props.typeActivity !== this.state.typeActivity) {
			this.state.page = 1;
			this.state.familyList = [];
			this.state.typeActivity = this.props.typeActivity;
			this.state.loading = true;
			this.setState({ ...this.state });
			this.fetchDataFromServer();
		}

		if (this.state.status !== this.props.status) {
			this.state.status = this.props.status;
			this.state.page = 1;
			this.setState({ ...this.state });
			this.fetchDataFromServer();
		}
		if (this.state.hospitalId !== this.props.hospitalId) {
			this.state.hospitalId = this.props.hospitalId;
			this.state.page = 1;
			this.setState({ ...this.state });
			this.fetchDataFromServer();
		}

		if (this.props.search !== this.state.search) {
			this.state.search = this.props.search;
			this.state.loading = true;
			this.state.familyList = [];
			this.state.page = 1;
			this.setState({ ...this.state });
			this.fetchDataFromServer();
		}

		if (this.props.family.isFetchAllFamilyCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.family.familyList.count / this.state.pageSize);
			this.state.familyList = this.props.family.familyList.family;
			this.setState({ ...this.state });
			this.props.dispatch(familyActions.clearFetchAllFamily());
		}

		if (this.props.family.isFetchAllFamilyCompleted === "REJECTED") {
			this.state.loading = false;
			this.state.totalPages = 1;
			this.state.familyList = [];
			this.setState({ ...this.state });
			this.props.dispatch(familyActions.clearFetchAllFamily());
		}

		if (this.props.family.isDeleteFamilyCompleted === "FULFILLED") {
			this.state.loading = true;
			this.setState({ ...this.state });
			this.fetchDataFromServer();
			this.props.dispatch(familyActions.clearDeleteFamily());
		}

		return (
			<div className="pole_top_section">
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete activity family", utils.getLanguage())}
					visible={this.state.deleteFamilyVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteFamily.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedFamily.name}</span> ?
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							refreshCompleted={this.refreshCompleted}
							familyId={this.state.familyId}
							typeActivity={this.state.typeActivity}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

ListFamily.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	family: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	family: store.family,
	translations: store.translations
}))(ListFamily));