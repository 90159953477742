import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import locations from "../../../../../router/locations";
import Activities from "./activities";
import Sterilization from "./sterilization";
import Consumable from "./consumable";
import Personnels from "./personnels";
import Translations from "./translations";
import Community from "./community";
import Structure from "./structure";
import UserManagement from "./userManagement";
import ClpBackup from "./clpBackup";
import "./administration.css";
import "./pole.css";
import "./details.css";
class Adminstration  extends Component {
	constructor(props) {
		super(props);
	}    
    
	render() {
		if (this.props.user.loginData && this.props.user.loginData.scope == 64 &&  this.props.user.loginData.roleType === "PATHWAY_USER") {
			return (
				<Switch>
					<Route path={locations.activities} component={Activities} />
					<Redirect to={locations.administration} />     
				</Switch>
			);
		}
		else {
			return (
				<Switch>
					<Route path={locations.activities} component={Activities} />
					<Route path={locations.translations} component={Translations}/>
					<Route path={locations.personnels} component={Personnels} />
					<Route path={locations.administration_sterilization} component={Sterilization} />
					<Route path={locations.consumable} component={Consumable} />        
					<Route path={locations.clpBackup} component={ClpBackup} />
					<Route path={locations.community} component={Community} /> 
					<Route path={locations.structure} component={Structure} /> 
					<Route path={locations.userManagement} component={UserManagement} /> 
					<Redirect to={locations.administration} />
                    
				</Switch>
			);
		}
	}
}

Adminstration.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations,
	user: store.user
}))(Adminstration));
