/* eslint-disable react/no-direct-mutation-state */
import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Select from "react-select";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ClinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";


class ProblemTypeDropDown extends Component {
	constructor(props) {
		super(props);

		this.state = {
			options: [],
			offset: 0,
			numResults: 1000,
			isLoading: true,
			search: null,
			problemTypeList: [],
			stepSectionType: "ALL_ITEMS"
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchProblemTypeList();
	}

	onListScroll() {
		const prevState = this.state;
		prevState.offset += prevState.numResults;
		this.setState({ prevState }, () => {
			this.fetchProblemTypeList();
		});
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.setState({ search: search });
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchProblemTypeList(search);
			}, 800);
		}
	}

	fetchProblemTypeList() {
		if (!this.props.isEdit) {
			this.setState({ isLoading: true });
			this.state.meta = Date.now();
			let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}&sort=name&desc=0`;
			queryString += `${this.state.search ? `&name=${this.state.search}` : ""}`;
			queryString += `${this.props.stepSectionType ? `&stepSectionType=${this.props.stepSectionType}` : ""}`;
			this.props.dispatch(ClinicalPathwayProblemAction.getAllProblemTypes(this.props.match.params.id, queryString, this.state.meta));
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (!this.props.isCreate) {
			prevState.options.push({ value: null, label: i18Get("All Types", utils.getLanguage()) });
		}
		this.state.problemTypeList.map((data) => {
			prevState.options.push({ value: data.id, label: data.problemType ? data.problemType : "", isWithRisk: data.isWithRisk ? data.isWithRisk : false, code: data.code });
		});
		prevState.isLoading = false;
		this.setState(prevState);
	}

	checkProblemType() {
		if (localStorage.getItem("CLP_PROBLEM_TYPE")) {
			const problemType = JSON.parse(localStorage.getItem("CLP_PROBLEM_TYPE"));
			if (!problemType) {
				return "";
			}
			if (this.state.problemTypeList && problemType) {
				const index = this.state.problemTypeList.findIndex((e) => e.id == problemType.value);
				if (index !== -1 || problemType.label === "All Types") {
					return this.props.onChange(problemType);
				}
				this.props.onChange(false);
			}
		}
	}

	render() {
		if (this.props.clinicalPathwayProblems.getAllProblemTypeStatus === "FULFILLED" && this.state.meta === this.props.clinicalPathwayProblems.meta) {
			this.state.problemTypeList = this.props.clinicalPathwayProblems.problemTypeList;
			this.renderOptions();
			this.checkProblemType();
			this.setState({ isLoading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllProblemTypes());
		}
		if (this.props.clinicalPathwayProblems.getAllProblemTypeStatus === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllProblemTypes());
		}
		if (this.props.stepSectionType !== this.state.stepSectionType) {
			this.fetchProblemTypeList();
			this.setState({ stepSectionType: this.props.stepSectionType });
		}

		return (
			<Select
				className={`w_100 di select_prbl_section ${this.props.className ? this.props.className : ""}`}
				value={this.props.data ? this.props.data : null}
				onChange={this.props.onChange}
				options={this.state.options}
				maxMenuHeight={220}
				isClearable
				onInputChange={this.onSearch.bind(this)}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Type", utils.getLanguage())}
				isMulti={ !!this.props.isMulti }
			/>
		);
	}
}

ProblemTypeDropDown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(ProblemTypeDropDown));
