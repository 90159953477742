import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { history } from "../store";
import locations from "./locations";
import loginActions from "../actions/user/login";
import MainContainer from "../components/main-container";
import settingsActions from "../actions/settings";
import User from "../components/user";
import translationActions from "../actions/translation";
import config from "../MOM-config";

class routes extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			languageSet: false,
			localStorageChecked: false
		};
		document.title = `MOM${config.appTitle ? ` - ${config.appTitle}` : ""}`;
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			this.props.location.pathname = location.pathname;
			this.props.location.search = location.search;
			this.forceUpdate();
		});
		this.props.dispatch(settingsActions.getAllSettings());
		this.props.dispatch(translationActions.getAllTranslations());
	}

	getLanguage() {
		// By default the language should be in france
		const langKey = "SELECTED_LANGUAGE";
		if (this.props.user.loginData) {
			if (!localStorage.getItem(langKey) || localStorage.getItem(langKey) === "null" || localStorage.getItem(langKey) === "undefined") {
				return "fr";
			}
			return localStorage.getItem(langKey);
		}
		return "fr";
	}

	getStyle(primaryColor, secondaryColor, primaryColorDark, primaryColorLight, secondaryColorDark, checkListColorCode, modelColorCode) {

		return (
			<style>
				{
					`
                    .text_button.eye svg path {
                        fill: ${primaryColor};
                        stroke: ${primaryColor};
                    }
                    .profile_input_eye svg path {
                        fill: ${primaryColor};
                        stroke: ${primaryColor};
                    }
                    .eye_icon_container svg path {
                        fill: ${primaryColor};
                        stroke: ${primaryColor};
                    }
                    .reset_password_eye svg path {
                        fill: ${primaryColor};
                        stroke: ${primaryColor};
                    }
                   
                    .copy_button {
                        color: #fff !important;
                        background: linear-gradient(to right, ${primaryColor} 0%, ${primaryColorLight} 100% )!important;
                    }
                    .copy_button:hover {
                        background: ${primaryColor} !important;
                    }   
                    .group input:focus~label,
                    input:valid~label {
                        color: ${primaryColor};
                    } 
                    
                    .group .login_input~.bar1 {
                        background-color: ${primaryColor};
                    } 
                    .profile_label {
                        color: ${primaryColor} !important;
                    } 
                    .change_password_button {
                        color: ${primaryColor};
                        border: 1px solid ${primaryColor};
                    } 
                    .change_password_button:hover {
                        background-color: ${primaryColor};
                    } 
                    .mom_form_popup .group input {
                        border-bottom: 1px solid ${primaryColor};
                    } 
                    
                    .mom_form_popup .group input:focus {
                        border-bottom: 2px solid ${primaryColor};
                    } 
                    
                    .mom_form_popup .group label {
                        color: ${primaryColor};
                    } 
                    .forgot_password a {
                        color:${primaryColor};
                    } 
                    .forgot_password a:hover {
                        color:${primaryColorDark};
                    } 
                    
                    .login_button button {
                        background-color: ${primaryColor};
                    }
                    .login_button button:hover {
                        background-color: ${primaryColorDark};
                    }
                    
                    .bottom_buttons {
                        background-color: ${primaryColor};
                    } 
                    .side_bar_item_inner_section .second_a {
                        color: ${primaryColor};
                    }
                    .side_bar_item_inner_section .second_a:hover {
                        color: ${primaryColorDark};
                    } 
                    
                    .filter_icon:hover,.filter_icon.active {
                        color: ${primaryColor};
                    } 
                    .clear_filter_a:hover {
                        color:${primaryColor};
                    } 
                    .cancel_button {
                        color: ${primaryColor};
                        border:1px solid ${primaryColor};
                    } 
                    
                    .cost_tabs .cost_section_a_Tag{
                        color: ${primaryColor};
                    }
                    .cost_tabs .cost_section_a_Tag:hover{
                        color: ${primaryColorDark};
                    } 
                    .common_button.pumpkin {
                        background-color: ${secondaryColor};
                    } 
                    .common_button.pumpkin:hover {
                        background-color: ${secondaryColorDark};
                     }
                    .patient_info_drop_down .dropdown-menu a{
                        color: ${primaryColor};
                    } 
                    
                    .patient_info_drop_down .dropdown-menu a svg{
                        color:${primaryColor} !important;
                    }
                    .patient_info_drop_down .dropdown-menu a:hover{
                        color: ${primaryColor};
                    }
                    .btn-group button.text_button:hover { 
                        background-color:${primaryColor};
                    } 
                    .btn-group button.text_button.eye:hover { 
                        color: ${primaryColor} !important;
                    }
                    .btn-group button { 
                        border: 1px solid ${primaryColor} !important;
                        color: ${primaryColor} !important;
                    } 
                    .btn-group button.drop_button:hover { 
                        background-color: ${primaryColor} !important;
                    } 
                    .toast_div button:hover {
                        color: ${primaryColor};
                    } 
                    .rank_div a svg{
                        color: ${primaryColor};
                    }
                    
                    .rank_div a:hover {
                        background-color: ${primaryColor};
                    } 
                    .position_download_button {
                        border: 1px solid ${primaryColor};
                        color: ${primaryColor};
                    } 
                
                    .common_button {
                        background-color: ${primaryColor};
                    }
                    .common_button:hover {
                        background-color:${primaryColorDark};
                    } 
                    .with_border {
                        border: 1px solid ${primaryColor};
                        color: ${primaryColor};
                    }
                    .with_border:hover {
                        border: 1px solid ${primaryColor};
                        background-color: ${primaryColor};
                    } 
                    .edit_button {
                        border: 1px solid ${primaryColor};
                        color: ${primaryColor};
                    }
                    .edit_color {
                        color: ${primaryColor};
                    } 
                    
                        .sidebar_close {
                        background: ${primaryColor};
                    }
                    .sidebar_close:hover {
                        background: ${primaryColorDark};
                    } 
                    
                    .navbar-brand svg {
                        color: ${primaryColor};
                    } 
                    
                    .horizontal_menu .display_table_cell.user_profile span{
                        color: ${primaryColor};
                    } 
                    
                    .administrator_a:hover a {
                        color:${primaryColor};
                    }
                    
                    .administrator_a:hover svg {
                        color:${primaryColor};
                    }
                    
                    .cool-link::after {
                        background: ${primaryColor};
                    } 
                    .mom-sidebar .first_a.active, .mom-sidebar .second_a.active {
                        color: ${primaryColor};
                    } 
                    
                    .mom-sidebar .side_bar_items.expandable.selected {
                        color: ${primaryColor};
                    } 
                    .side_bar_section .side_bar_items:hover {
                        color: ${primaryColor};
                    }
                    .mom-sidebar .inner_a .second_a:hover {
                        color: ${primaryColor};
                    }
                    .first_a:hover {
                        color: ${primaryColor};
                    }
                    .four_boxes .side_bar_items:hover {
                        color: ${primaryColor};
                    }
                    .large_section .inner_a .second_a:hover {
                        color: ${primaryColor};
                    }
                    .large_section .expandable.selected {
                        color: ${primaryColor};
                    }
                    .ant-breadcrumb-link a:hover {
                        color: rgba(0, 0, 0, 0.45);
                    }
                    .steps-action button {
                        background-color: ${primaryColor};
                    }
                    .steps-action button:hover {
                        background-color: ${primaryColorDark};
                    }
                    .ant-btn-primary:hover, .ant-btn-primary:focus {
                        color: #fff;
                        background-color: ${primaryColorDark};
                        border-color: ${primaryColorDark};
                    }
                    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
                        background-color:${primaryColor};
                    }
                    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
                        background-color:${primaryColor};
                    }
                    .ant-steps-item-finish > .ant-steps-item-tail:after {
                        background-color:${primaryColor};
                    }
                    .ant-radio-checked .ant-radio-inner {
                        border-color:${primaryColor};
                    }
                    .ant-radio-inner:after {
                        background-color:${primaryColor};
                    }
                    .ant-radio-checked:after {
                        border: 1px solid ${primaryColor};
                    }
                    .ant-radio-wrapper:hover .ant-radio .ant-radio-inner, .ant-radio:hover .ant-radio-inner, .ant-radio-focused .ant-radio-inner {
                        border-color:${primaryColor};
                    }
                    .ant-btn:hover, .ant-btn:focus {
                        color:${primaryColor};
                        border-color:${primaryColor};
                    }
                    .ant-btn-primary {
                        color:#fff !important;
                        background-color:${primaryColor};
                        border-color:${primaryColor};
                    }
                    .ant-btn-primary:hover {
                        background-color:${primaryColorDark};
                        border-color:${primaryColorDark};
                    }
                    .ant-confirm-body-wrapper .ant-btn-danger {
                        color:#f5222d;
                        background-color:#fff;
                        border-color:#f5222d;
                    }
                    .ant-confirm-body-wrapper .ant-btn-danger:hover {
                        color: #fff;
                        background-color: #f5222d;
                        border-color: #f5222d;
                    }
                    .css-2o5izw:hover {
                        box-shadow: 0 0 0 1px ${primaryColor};
                    }
                    .css-2o5izw:hover {
                        border-color:${primaryColor};
                    }
                    .css-2o5izw{
                        box-shadow: 0 0 0 1px ${primaryColor};
                    }
                    .css-2o5izw {
                        border-color:${primaryColor};
                    }
                    .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
                        border-color:${primaryColor};
                    }
                    .ant-checkbox-checked .ant-checkbox-inner {
                        background-color:${primaryColor};
                        border-color:${primaryColor};
                    }
                    .ant-checkbox-checked:after {
                        border: 1px solid ${primaryColor};
                    }
                    .create_row_button {
                        color: ${primaryColor};
                        border: 1px solid ${primaryColor};
                    }
                    .create_row_button:hover {
                        background-color:${primaryColorDark};
                    }
                    .form-control:focus , .form-control:hover , .ant-input:hover {
                        box-shadow:none;
                        border-color:${primaryColorDark};
                    }
                    .table_scroll_container_routes .ReactTable .rt-td a {
                        color:${primaryColor};
                        text-decoration: underline;
                    }
                    .import_file_title {
                        color:${primaryColor};
                    }
                    .ReactTable .rt-td a {
                        color:${primaryColorDark};
                    }
                    .edit-form .group input {
                        border-bottom: 1px solid ${primaryColor};
                    }
                    .edit-form .group input:focus {
                        border-bottom: 1px solid ${primaryColorDark};
                    }
                    .ant-select-selection__placeholder {
                        color:${primaryColor} !important;
                    }
                    .reload_button {
                        background-color:${primaryColor};
                    }
                    .reload_button:hover {
                        background-color:${primaryColorDark};
                    }
                    .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
                        color:${primaryColor};
                    }
                    .toolbar-class .rdw-link-wrapper .rdw-link-modal .rdw-link-modal-label {
                        color:${primaryColor};
                    }
                    .time_picker_container .tick {
                        color: ${primaryColor};
                    }
                    .badge_container {
                        background-color:${primaryColor} !important;
                    }
                    .apply_div button:hover {
                        color:${primaryColor};
                        background-color:#fff;
                    }
                    .min_width_spl_90.fixed_label_french {
                        width: auto;
                        min-width: max-content;
                        margin-right: 10px;
                    }
                    .import_a.active {
                        border: 1px solid ${primaryColor} !important;
                        color: ${primaryColor};
                    }
                    .import_a.active:hover {
                        background-color: ${primaryColor} !important;
                        color: #fff !important;
                    }
                    .success_error_popover .success_error_key {
                        min-width: 160px;
                    }
                    .enabled {
                    color:  ${primaryColor} !important;
                    font-weight: 600;
                    }
                    .enabled:hover {
                       text-decoration:underline;
                    }
                    .success_error_popover .success_error_key {
                        min-width: 164px;
                    }
                    .nav .active a {
                        border-color: ${primaryColor} !important;
                        border-bottom:none !important;
                    }
                    .nav .active a:hover {
                        border-color: ${primaryColor} !important;
                    }
                    .nav .active a:focus {
                        border-color: ${primaryColor} !important;
                        border-bottom:none !important;
                    }
                    .parcours_filter_btn_active {
                        border-color: ${primaryColor};
                        color: ${primaryColor};
                    }
                    .parcours_filter_btn_active_right {
                        border-color: ${primaryColor};
                        color: ${primaryColor};
                    }
                    .parcours_filter_btn_active_left {
                        border-color: ${primaryColor};
                        color: ${primaryColor};
                    }
                    .ReactTable .rt-tbody::-webkit-scrollbar-thumb {
                        background-color: ${primaryColor};
                    }
                    .ReactTable .rt-tbody::-webkit-scrollbar-thumb:hover {
                        background-color: ${primaryColorDark};
                    }
                    .ReactTable .rt-table::-webkit-scrollbar-thumb {
                        background-color: ${primaryColor};
                    }      
                    .import_data_table_body::-webkit-scrollbar-thumb {
                        background-color: ${primaryColor};
                    }
                    .import_data_table_body::-webkit-scrollbar-thumb:hover {
                        background-color: ${primaryColorDark};
                    }   
                    .Table__pageButton--active {
                        background-color: ${primaryColorDark};
                    }    
                    .exit_element_table_active {
                        background-color: ${primaryColor};
                    }
                    .recommend_badge sup {
                        background-color: ${primaryColor};
                    }
                    .recommended_badge_btn sup {
                        color: #ffffff;
                        background-color: ${primaryColor};
                    }
                    .badge_color:hover .recommended_badge_btn sup {
                        color: ${primaryColor};
                        background-color: #ffffff;
                    }
                    .ant-checkbox-indeterminate .ant-checkbox-inner::after {
                        background-color: ${primaryColor};
                    }
                    .warning_content::-webkit-scrollbar-thumb {
                        background-color: ${primaryColor};
                    }
                    .warning_content::-webkit-scrollbar-thumb:hover {
                        background-color: ${primaryColorDark};
                    }
                    .scaned_primary_color_head {
                        color: ${primaryColor};
                    }
                    .step_open_btn {
                        background-color: ${primaryColor};
                    }
                    .step_open_btn:hover {
                        background-color: ${primaryColorDark};
                    }
                    .individual-route-quick-nav-close {
                        background-color: ${primaryColor};
                    }
                    .individual-route-quick-nav-close:hover {
                        background-color: ${primaryColorDark};
                    }
                    .clinical_pathway_modified {
                        color: ${primaryColor};
                    }
                    .clinical_pathway_tabs.nav-tabs > li > a::before {
                        background-color: ${primaryColor};
                    }
                    .clinical_pathway_tabs.nav-tabs > li.active > a {
                        color: ${primaryColor};
                    }
                    .pathway_li_clpProblem_tab .ant-input-suffix i {
                        color: ${primaryColor};
                    }
                    .pathway_li_clpProblem_tab .ant-input-suffix:hover i {
                        color: ${primaryColorDark};
                    }
                    .model_attached_tr {
                        color: ${modelColorCode} !important;

                    }
                    .model_attached_td {
                        color: ${modelColorCode} !important;

                    }
                    .check_list{
                        color: ${checkListColorCode} !important;
                    }
                    `
				}
			</style>
		);
	}

	getDarkColor(hex, lum) {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, "");
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
		}
		lum = lum || 0;

		// convert to decimal and change luminosity
		let rgb = "#";
		let c;
		let i;
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16);
			c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
			rgb += (`00${c}`).substr(c.length);
		}
		return rgb;
	}

	render() {
		let userDetails = this.props.user.loginData;
		if (!this.state.localStorageChecked) {
			this.state.localStorageChecked = true;
			const key = "USER_DETAILS";
			userDetails = localStorage.getItem(key);
			if (userDetails) {
				userDetails = JSON.parse(userDetails);
				if (userDetails.message_code === 1) {
					this.props.dispatch(loginActions.setLoginData(userDetails));
				} else {
					userDetails = localStorage.setItem(key, "");
					this.props.dispatch(loginActions.clearLogin());
				}
			}
		}

		if (!this.state.languageSet) {
			this.state.languageSet = true;
			this.props.dispatch(translationActions.triggerTranslationJsonGet());
			this.props.dispatch(translationActions.resetLanguage(this.getLanguage()));
		}

		let primaryColor = "#0477bd";
		let secondaryColor = "#ea733a";

		let checkListColorCode = "#2016B8";
		let modelColorCode = "#0477BD";

		if (Object.keys(this.props.settings.theme).length && this.props.settings.theme.primaryColor) {
			primaryColor = this.props.settings.theme.primaryColor.value;
			secondaryColor = this.props.settings.theme.secondaryColor.value;

			if (this.props.settings.theme.clpSettings && Object.keys(this.props.settings.theme.clpSettings).length) {
				checkListColorCode = (this.props.settings.theme.clpSettings.checkListColorCode) ? this.props.settings.theme.clpSettings.checkListColorCode : checkListColorCode;
				modelColorCode = (this.props.settings.theme.clpSettings.modelColorCode) ? this.props.settings.theme.clpSettings.modelColorCode : modelColorCode;
			}

		}


		const primaryColorDark = this.getDarkColor(primaryColor, -0.1);
		const primaryColorLight = `#${primaryColor.replace(new RegExp(/#/g), "")}4f`;
		const secondaryColorDark = this.getDarkColor(secondaryColor, -0.1);

		if (!userDetails || userDetails.message_code === 0) {
			this.state.languageSet = false;
			return (
				<div className="h_100">
					{this.getStyle(primaryColor, secondaryColor, primaryColorDark, primaryColorLight, secondaryColorDark, checkListColorCode, modelColorCode)}
					<User />
				</div>
			);
		}

		return (
			<div className="h_100">
				{this.getStyle(primaryColor, secondaryColor, primaryColorDark, primaryColorLight, secondaryColorDark, checkListColorCode, modelColorCode)}
				<HashRouter history={history} basename="/">
					<Switch>
						<Route path={locations.base} component={MainContainer} />
					</Switch>
				</HashRouter>
			</div>
		);
	}
}

routes.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	settings: store.settings
}))(routes));
