import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../../plug/regex-name-search-input";
import adminPersonnelActions from "../../../../../../../../../actions/admin-personnel";
import events from "../../../../../../../../../actions/events";
import PersonnelTemplateSelect from "../../personnel-templates-dropdown";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			personnelTemplateName: "",
			personnelTemplateCode: "",
			invalidPersonnelTemplateName: false,
			invalidPersonnelTemplateCode: false,
			invalidPersonnelTemplate: false,
			selectedPersonnelTemplate: "",
			personnelTemplate: {},
			personnelTemplateId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(adminPersonnelActions.fetchOnePersonnelTemplate(this.props.templateId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidPersonnelTemplateName: false, invalidPersonnelTemplateCode: false, invalidFamily: false });
		this.props.refreshCompleted();
	}

	onPersonnelTemplateChange(e) {
		this.setState({ selectedPersonnelTemplate: e ? e : "", invalidPersonnelTemplate: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.personnelTemplateName.trim())) {
			prevState.invalidPersonnelTemplateName = true;
		}
		if (prevState.invalidPersonnelTemplateName || prevState.invalidPersonnelTemplateCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validatePersonnelTemplate = () => {
		if (this.state.selectedPersonnelTemplate) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.personnelTemplateName,
					typeActivity: this.state.personnelTemplate ? this.state.personnelTemplate.typeActivity : ""
				};
				this.props.dispatch(adminPersonnelActions.saveAndApprovePersonnelTemplate(this.state.personnelTemplate.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validatePersonnelTemplate()) {
				payload = {
					categoryPersonnelTemplateId: this.state.selectedPersonnelTemplate && this.state.selectedPersonnelTemplate.value ? this.state.selectedPersonnelTemplate.value : "" 
				};
				this.props.dispatch(adminPersonnelActions.assignAndApprovePersonnelTemplate(this.state.personnelTemplate.id, payload));
			}
			else {
				this.setState({ invalidPersonnelTemplate: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidPersonnelTemplateName = false;
		prevState.personnelTemplateName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidPersonnelTemplateCode = false;
		prevState.personnelTemplateCode = value;
		this.setState(prevState);
	}

	render() {
		const { value } = this.state;
		if (this.props.adminPersonnel.asssignAndApprovePersonnelTemplateStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, personnelTemplateName: "", personnelTemplateCode: "" });
			this.props.dispatch(adminPersonnelActions.assignAndApprovePersonnelTemplateCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.adminPersonnel.saveAndApprovePersonnelTemplateStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, personnelTemplateName: "", personnelTemplateCode: "" });
			this.props.dispatch(adminPersonnelActions.saveAndApprovePersonnelTemplateCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.adminPersonnel.fetchOnePersonnelTemplateCompleted === "FULFILLED") {
			this.state.personnelTemplate = this.props.adminPersonnel.personnelTemplate;
			this.state.personnelTemplateName = this.state.personnelTemplate.name ? this.state.personnelTemplate.name : "";
			this.state.personnelTemplateCode = this.state.personnelTemplate.code ? this.state.personnelTemplate.code : "";
			this.state.isloading = true;
			this.props.dispatch(adminPersonnelActions.clearFetchOnePersonnelTemplate());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.personnelTemplate.name, id: this.state.personnelTemplate.id } });
		}

		if (this.props.adminPersonnel.fetchOnePersonnelTemplateCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearFetchOnePersonnelTemplate());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Personnel Template", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
							<I18 tkey='Save & Approve'/>
						</Button>
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative margin-left-0 margin-right-0">
								{/* <div className="col-md-3">
                                    <div class="form-group margin-bottom-0 pl-15">
                                        <label className="bold width-100"><I18 tkey="CODE" />:</label>
                                        <label className="width-100">{this.state.personnelTemplate.code}</label>
                                    </div>
                                </div> */}
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.personnelTemplate.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.personnelTemplate.hospitalName ? this.state.personnelTemplate.hospitalName : ""}</p>
							</div>
						</div> 
						{this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use New Name" />
										</Radio>
									</div>
									{/* <div className="col-md-4 pt-10 pr-0">
                                <div class="form-group pl-20">
                                    <label className="bold" ><I18 tkey="CODE" />:</label>
                                    <NameInput
                                        value={this.state.personnelTemplateCode}
                                        inputChanged={this.codeChanged.bind(this)}
                                        disabled={this.state.value != 1}
                                        maxLength={10}
                                    />
                                    {this.state.invalidPersonnelTemplateCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid personnel template code" /></span>}
                                </div>
                            </div> */}
									<div className="col-md-8 pt-10">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.personnelTemplateName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidPersonnelTemplateName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid personnel template name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Personnel Template" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<PersonnelTemplateSelect 
												defaultValue="Select Personnel Template" 
												disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedPersonnelTemplate}
												typeActivity={this.state.personnelTemplate.typeActivity}
												templateChanged={this.onPersonnelTemplateChange.bind(this)}/>
											{this.state.invalidPersonnelTemplate && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select personnel template" /></span>}
										</div>
									</div>
								</div>
							</div>
							: ""
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	adminPersonnel: store.adminPersonnel
}))(AssignAndApproveModel));    