import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";
import communityMembersAction from "../../../../../../../../actions/community-members";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

class EditCommunityMembers extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communityMembers: {},
			loading: false,
			invalidCode: false,
			invalidName: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(communityMembersAction.getOne(this.props.communityMembersData.id));
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.communityMembers.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.checkCodeRegex(text)) {
			prevState.communityMembers.code = text;
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	shortNameChanged(value) {
		const prevState = this.state;
		if (value.length <= 60) {
			prevState.communityMembers.shortName = value;
			this.setState(prevState);
		}
		prevState.invalidShortName = false;
	}

	validate() {
		const prevState = this.state;
		if (!prevState.communityMembers.name || !prevState.communityMembers.name.trim() || !utils.checkNameRegex(prevState.communityMembers.name)) {
			prevState.invalidName = true;
		}
		if (!prevState.communityMembers.code || !prevState.communityMembers.code.trim()) {
			prevState.invalidCode = true;
		}
		if (!prevState.communityMembers.shortName || !prevState.communityMembers.shortName.trim()) {
			prevState.invalidShortName = true;
		}
		if (prevState.invalidName || prevState.invalidCode || prevState.invalidShortName) {
			this.setState(prevState);
			return false;
		}

		return true;
	}

	saveClicked() {
		if (this.validate()) {
			this.setState({ loading: true });
			const payload = {
				name: this.state.communityMembers.name,
				code: this.state.communityMembers.code,
				shortName: this.state.communityMembers.shortName
			};
			this.props.dispatch(communityMembersAction.updateCommunityMembers(this.state.communityMembers.id, payload));
		}
	}

	handleCancel() {
		this.props.visibleEditModal(false);
	}

	render() {
		if (this.props.communityMembers.fetchOneCommunityMembersComplete === "FULFILLED") {
			this.state.communityMembers = this.props.communityMembers.communityMembers;
			this.props.dispatch(communityMembersAction.clearGetOne());
		}
		if (this.props.communityMembers.fetchOneCommunityMembersComplete === "REJECTED") {
			this.props.dispatch(communityMembersAction.clearGetOne());
		}
		if (this.props.communityMembers.updateCommunityMembersComplete === "FULFILLED") {
			this.props.dispatch(communityMembersAction.updateCommunityMembersCompleted());
			this.handleCancel();
			this.props.createClassCompleted(true);
			this.setState({ loading: false });
		}
		if (this.props.communityMembers.updateCommunityMembersComplete === "REJECTED") {
			this.props.dispatch(communityMembersAction.updateCommunityMembersCompleted());
			this.setState({ loading: false });
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Hospital", utils.getLanguage())}
					visible={this.props.editModal}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}
					footer={[
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.saveClicked.bind(this)}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}>
					<div className="pole_create_box create_category_personal_width_100 padding-0">
						<div className="row">
							<div>
								<div className="col-md-6 col-sm-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><I18 tkey="Code" /></label>
										<NameInput
											value={this.state.communityMembers.code}
											inputChanged={this.codeChanged.bind(this)}
											maxLength={2}
											disabled={true}
										/>
										{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Enter valid code" /></span>}
									</div>
								</div>
								<div className="col-md-6 col-sm-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><I18 tkey="Short Name" /></label>
										<NameInput
											value={this.state.communityMembers.shortName}
											maxLength={10}
											inputChanged={this.shortNameChanged.bind(this)}
										/>
										{this.state.invalidShortName && <span className='invalid_right fixed_label'><I18 tkey="(a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
									</div>
								</div>
							</div>
							<div className="col-md-12 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Name of the Hospital" /></label>
									<NameInput
										value={this.state.communityMembers.name}
										inputChanged={this.nameChanged.bind(this)}
										maxLength={60}
									/>
									{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

EditCommunityMembers.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	communityMembers: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	communityMembers: store.communityMembers
}))(EditCommunityMembers));
