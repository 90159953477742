import PropTypes from "prop-types";
import { Dropdown, Menu, Checkbox, Button, Tooltip } from "antd";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import standardCareChartActions from "../../../../../../../actions/standard-care-chart";
import I18, { i18Get } from "../../../../../../../i18";
import Loader from "../../../../../../../plug/loader";
import { ExpandOutlined, FullscreenExitOutlined, RightCircleOutlined, DownCircleOutlined, ArrowDownOutlined } from "@ant-design/icons";
import utils from "../../../../../../../utils/utils";
import ClpStepFilter from "../clp-step-filter";
import QuickReorderData from "../standard-care-chart/quick-reorder-data";
import TopSection from "../top-section";
import isPermissionGranted from "../../../../../../../utils/permission";
import { statusList } from "../../../../../../../constants";
import { DoctorEdit, DocEdit, NurseEdit, NursePenEdit, ViewNotePad, DoctorNurseEdit, DocNurseEdit, Edit } from "../../../../../../../utils/icons";
import permissions from "../../../../../../../utils/permission";
import AdditionalInfoEdit from "../problem-section/additional-Info/edit";
import { ReorderIcon } from "../../../../../../../utils/icons";

class StandardCareChartTopSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			standardCareChartData: {},
			previousData: {},
			draggedOverData: {},
			loading: false,
			treatmentId: null,
			stepSectionType: "",
			hoursTotalList: "",
			expandedProblemAndCheckListIds: [],
			editAdditionalInfo: false,
			selectedAdditionalInfo: {}, 
			problemId: 0, 
			problemDetails: []
		};
	}

	componentDidMount() {
		this.fetchDataFromServer();
	}


	fetchDataFromServer() {
		let queryString = "";
		queryString += this.props.clpStepFilter ? `clpStepFilter=${this.props.clpStepFilter}` : "";
		this.props.dispatch(standardCareChartActions.getAll(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, queryString));
	}

	// toggleFullScreen = () => {
	// 	this.setState({ ...this.state, isFullScreen: !this.state.isFullScreen });
	// };

	// reloadData() {
	// 	this.fetchDataFromServer();
	// }

	// onClickCancel() {
	// 	this.setState({ editAdditionalInfo: false, createAdditionalInfo: false, selectedAdditionalInfo: {}, problemDetails: {}, problemId: null });
	// }

	// onClickCancelReorder() {
	// 	return (e) => {
	// 		e.preventDefault();
	// 		this.fetchDataFromServer();
	// 		this.setState({ enableReorder: false, enableDrag: false });
	// 	};
	// }

	// onClickSaveOrder() {
	// 	return () => {
	// 		const reOrderedProblems = [];
	// 		const newData = JSON.parse(JSON.stringify(this.state.standardCareChartData.problems));
	// 		if (newData && newData.length > 0) {
	// 			newData.map(function (data, index) {
	// 				reOrderedProblems.push({ additionalInfoId: data.additionalInfoId, rank: index + 1, interventionSummaryName: data.interventionSummaryName, problemId: data.problemId });
	// 			});
	// 			const payload = {
	// 				reOrderedProblems: reOrderedProblems,
	// 				isChart: true
	// 			};
	// 			this.setState({ enableDrag: true });
	// 			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdation(this.props.match.params.id, payload));
	// 		} else {
	// 			this.setState({ enableReorder: false });
	// 		}
	// 	};
	// }

	onQuickReorderData = () => {
		this.props.dispatch(standardCareChartActions.toggleStandardCareChartRankUpdationModal(true));
	};

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, method);
	};

	render() {
		if (this.props.standardCareChart.fetchAllStandardCareChartData === "FULFILLED") {
			const standardCareChartData = this.props.standardCareChart.standardCareChartData;
			this.setState({
				standardCareChartData: standardCareChartData
			});
			// this.props.dispatch(standardCareChartActions.clearGetAll());
		}
		// const standardCareChart = this.props.standardCareChart;
		// this.setState({ standardCareChartData: standardCareChart });
		return (
			// <div className={`padding-top-7 position-relative clinical_pathway_table ${this.props.isFullScreen ? "full_screen padding-15" : ""}`}>
			<div className={"padding-top-7 position-relative clinical_pathway_table"}>
				<div className="d-flex justify-content-end align-items-center padding-left-23">
					{!this.props.isHospitalisation ? <ClpStepFilter filter={this.props.clpStepFilter} changeFilter={this.props.changeClpStepFilter} /> : ""}
					<div className="margin-left-10 margin-right-20">
						{!this.state.enableReorder ?
						<div className="clinical-pathway-reorder-icon-container">
							<span className="icon-holder" loading={this.state.loading} onClick={() => this.onQuickReorderData()}>
								<span className="custom-svg">
									<Tooltip title={i18Get("Re-Order", utils.getLanguage())} placement="top">
										<ReorderIcon />
									</Tooltip>
								</span>
							</span>
							{/* <Button disabled={this.props.clinicalPathwayProblems.problemList && this.props.clinicalPathwayProblems.problemList.mapping && isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, "EDIT") ? false : true} type="button" className="margin-right-10"
							loading={this.state.loading} onClick={() => this.props.onQuickReorderData()}>
							<I18 tkey="Reorder Data" />&nbsp;
						</Button> */}
						</div>
							// <Button disabled={!this.isPermissionGranted("EDIT")} type="button" 
							// 	onClick={() => this.onQuickReorderData()}>
							// 	<I18 tkey="Reorder Data" />&nbsp;
							// </Button>
							: ""
						}
					</div>
					<div className="margin-left-10" onClick={this.props.toggleFullScreen}>
						{this.props.isFullScreen
							? <FullscreenExitOutlined className="edit_color padding-right-15" style={{ fontSize: 18 }} />
							: <ExpandOutlined className="edit_color" style={{ fontSize: 16 }} />
						}
					</div>	
				</div>
				{this.props.standardCareChart.stdCareChartModal &&
					<QuickReorderData
						quickReorderData={this.state.standardCareChartData}
						clpStepFilter={this.props.clpStepFilter}
						treatmentId={this.props.treatmentId}
					/>
				}

				{/* 
				{this.state.editAdditionalInfo ?
					<AdditionalInfoEdit
						selectedAdditionalInfo={this.state.selectedAdditionalInfo}
						editAdditionalInfo={this.state.editAdditionalInfo}
						problemId={this.state.problemId}
						problemDetails={this.state.problemDetails}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						clinicalPathwayData={this.props.clinicalPathwayData}
					/>
					: ""} */}
			</div>
		);
	}
}

StandardCareChartTopSection.propTypes = {
	standardCareChart: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	standardCareChart: store.standardCareChart,
	permissions: store.permissions
}))(StandardCareChartTopSection));