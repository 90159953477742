import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import I18 from "i18";
import ColorPicker from "./color-picker";

const ColorPickerFiled = (props) => {

	const [pickActivity, setPickActivity] = useState(null);
	const wrapperPickerRef = useRef(null);
	useOutsideAlerter(wrapperPickerRef);

	function useOutsideAlerter(ref) {
		useEffect(() => {

		  function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) { setPickActivity(null);}
		  }
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => { document.removeEventListener("mousedown", handleClickOutside);};
		}, [ref]);
	}

	return (
		<>
			<div className="w_100 margin-top-10">
				<strong><I18 tkey={props.title} /></strong>
				<div  style={{ width: "50px", height: "20px", background: props.color }}
					className={"custom_bordered_div cursor_pointer  margin-left-5 float_right"}
					onClick={() => setPickActivity(props.activity)}>
				</div>
			</div>
			{ pickActivity ? <div className="mom-date-picker" ref={wrapperPickerRef}><ColorPicker color={props.color} onchange={(color, activity) => { props.onClicked(activity, color);}} item={pickActivity} /></div> : ""}
		</>

	);
};

export default ColorPickerFiled;
