import React, { Component } from "react";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import { Modal } from "antd";

class SharedCommunityList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}


	moveToDetails = (value) => {
		this.props.history.push(this.props.location.pathname + "/" + value.id);
	};

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th>
							<I18 tkey='NAME' />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map(data => {
				return (
					<tr key={`_${data.id}`}>
						<td key={data.id}><div className='linked_data_count'><a className="w_100" onClick={() => { this.moveToDetails(data); }}>{data.name}</a></div></td>
					</tr>
				);
			});
		}
		else {
			return <tr><td ><div className="text_align_center w_100"><I18 tkey={"NO DATA FOUND"} /></div></td> </tr>;
		}
	}
	render() {
		let title = "";

		title = "Shered Community List";

		return (

			<Modal
				title={[
					i18Get(title, utils.getLanguage()),					
					<span key="number_of_items" className='float_right font_size_11px margin-right-30 '><I18 tkey='Number Of items' />: <strong>{(this.props.data) ? this.props.data.length : 0}</strong> </span>
				]}
				visible={this.props.visible}
				onCancel={this.props.onClose}
				footer={null}
				width="600px"
			>
				<div >
					<div className='margin-top-o-15p margin-bottom-10 scroll_window'>
						{this.getTable(this.props.data)}
					</div>
				</div>
			</Modal>

		);
	}
}
export default SharedCommunityList;
