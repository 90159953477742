import React, { Component } from "react";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";

class CopyClinicalPathwayHeader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathwayData: this.props.clinicalPathwayData,
			title: this.props.title
		};
	}
	render() {
		const preFix = this.state.clinicalPathwayData.siteId && this.state.clinicalPathwayData.hospitalId ? this.state.clinicalPathwayData.siteShortName : this.state.clinicalPathwayData.hospitalName ? this.state.clinicalPathwayData.hospitalName : "MOM"; 
		const pathwayName = this.state.clinicalPathwayData && this.state.clinicalPathwayData.name ? `${preFix} ${this.state.clinicalPathwayData.name}` : "";
		return (
			<div>{`${i18Get(this.props.title, utils.getLanguage())} ${i18Get("To", utils.getLanguage())} ${pathwayName} `}

				<div className="d-flex align-items-center font-size-10 margin-top--7">
					<div className="">
						<I18 tkey='Code' />:&nbsp;
						{this.state.clinicalPathwayData && this.state.clinicalPathwayData.code ? this.state.clinicalPathwayData.code : ""}&nbsp;
					</div>
					<span className='intervention_speciality_name font-size-10'>
						{this.state.clinicalPathwayData && this.state.clinicalPathwayData.specialityName && this.state.clinicalPathwayData.familyName ?
							`( ${this.state.clinicalPathwayData.specialityName}, ${this.state.clinicalPathwayData.familyName} )${this.props.doctorName ? `( ${this.props.doctorName} )` : ""}`
							: ""}
					</span>
				</div>

			</div>
		);
	}
}

export default CopyClinicalPathwayHeader;
