import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../router/locations";
import EditProblem from "./problem";
import EditStepDetails from "./step-details";
import EditSubStepDetails from "./sub-step-details";
import EditItemsDetails from "./items-details";

export default class AdminPersonnelCreateRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={locations.editAdminClinicalPathwayProblem}
					render={() => <EditProblem {...this.props} />}
				/>
				<Route
					exact
					path={locations.editAdminClinicalPathwayStep}
					render={() => <EditStepDetails {...this.props} />}
				/>
				<Route
					exact
					path={locations.editAdminClinicalPathwaySubStep}
					render={() => <EditSubStepDetails {...this.props} />}
				/>
				<Route
					exact
					path={locations.editAdminClinicalPathwayItems}
					render={() => <EditItemsDetails {...this.props} />}
				/>
				<Redirect to={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}`} />
			</Switch>
		);
	}
}
