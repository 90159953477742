import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import "../../../../../../../../plug/reactTable/react-table.css";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal } from "antd";
import locations from "../../../../../../../../router/locations";
import categoryPersonnelActions from "../../../../../../../../actions/category-personnel";
import Loader from "../../../../../../../../plug/loader";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import { statusList } from "../../../../../../../../constants";
import AssignAndApproveModel from "./assign-and-approve-model";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

class ListCategoryPersonnel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryPersonnelList: [],
			totalPages: 1,
			loading: false,
			search: "",
			deleteCPVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedCategoryPersonnel: {},
			sort: "",
			desc: 0,
			filterName: "all",
			status: "ALL",
			hospitalId: "",
			categoryPersonnelId: "",
			modelVisible: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.categoryPersonnelList });
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: d => d,
				width: 80,
				resizable: false,
				sortable: false,
				Cell: row => (
					<div className="margin_left_auto">
						<div className="min_width_12_px">
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
								: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							}
						</div>
						<div className="min_width_12_px">
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<DeleteOutlined className=" delete_color" type="delete" onClick={this.deleteClicked(row.value).bind(this)} />
								: ""}
						</div>
					</div>
				)
			},
			{
				Header: i18Get("CODE", utils.getLanguage()),
				id: "code",
				accessor: d => d.code,
				width: 130,
				resizable: false,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "name",
				accessor: d => d.name,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("AVERAGE MONTHLY COST", utils.getLanguage()),
				id: "avgMonthlyCost",
				accessor: d => d.avgMonthlyCost,
				width: 100,
				resizable: true,
				Cell: row => (
					this.getText(utils.setLocaleForCurrency(row.value))
				)
			},
			{
				Header: i18Get("HOURS PER MONTH", utils.getLanguage()),
				id: "hoursPerMonth",
				accessor: d => d.hoursPerMonth,
				width: 100,
				resizable: true,
				Cell: row => (
					this.getText(utils.setLocaleForCurrency(row.value))
				)
			},
			{
				Header: i18Get("HOURS PER YEAR", utils.getLanguage()),
				id: "hoursPerYear",
				accessor: d => d.hoursPerYear,
				width: 100,
				resizable: true,
				Cell: row => (
					this.getText(utils.setLocaleForCurrency(row.value))
				)
			},
			{
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				width: 200,
				Cell: row => (
					this.getText(row.value ? row.value : "Master")
				)
			}, {
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				width: 150,
				Cell: row => (
					this.getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	showYesOrNo(value) {
		return <div>{value ? <p><I18 tkey="Yes" /></p> : <p><I18 tkey="No" /></p>}</div>;
	}

	editClicked(categoryPersonnel) {
		return () => {
			this.props.history.push(locations.categoryPersonnel + `/${categoryPersonnel.id}`);
		};
	}

	deleteClicked(categoryPersonnel) {
		return () => {
			this.setState({ deletedCategoryPersonnel: categoryPersonnel, deleteCPVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deleteCPVisible: false });
	}

	deleteCategoryPersonnel() {
		this.props.dispatch(categoryPersonnelActions.deleteCategoryPersonnel(this.state.deletedCategoryPersonnel.id));
		this.setState({ deleteCPVisible: false, deletedCategoryPersonnel: {} });
	}

	approveClicked(categoryPersonnel) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, categoryPersonnelId: categoryPersonnel.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.search ? `&name=${this.state.search}&code=${this.state.search}` : "";
		queryString += (this.state.filterName && (this.state.filterName !== "all")) ? `&filter=${this.state.filterName}` : "";
		this.props.dispatch(categoryPersonnelActions.fetchAllCategoryPersonnel(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList() {
		return (<ReactTable
			{...utils.getPropsForReactTable(this.state.categoryPersonnelList, (this.state.page - 1), this.state.totalPages,
				this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
				this.state.pageSize, true, this.onFetchData.bind(this), this.getCols(), true, false,
				this.onPageChange.bind(this), "CATEGORY PERSONNEL", "rt-body-x_scroll-hidden", this.onPageSizeChange.bind(this))}
		/>);
	}

	render() {
		if (this.props.categoryPersonnel.isFetchAllCategoryPersonnelCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.categoryPersonnel.categoryPersonnelList.count / this.state.pageSize);
			this.state.categoryPersonnelList = this.props.categoryPersonnel.categoryPersonnelList.data;
			this.props.totalCount(this.props.categoryPersonnel.categoryPersonnelList.count);
			this.props.dispatch(categoryPersonnelActions.clearFetchAllCategoryPersonnel());
		}
		if (this.props.categoryPersonnel.isFetchAllCategoryPersonnelCompleted === "REJECTED") {
			this.state.loading = false;
			this.state.totalPages = 1;
			this.state.categoryPersonnelList = [];
			this.props.dispatch(categoryPersonnelActions.clearFetchAllCategoryPersonnel());
		}
		if (this.props.categoryPersonnel.isDeleteCategoryPersonnelCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(categoryPersonnelActions.clearDeleteCategoryPersonnel());
		}
		if ((this.state.filterName !== this.props.filterName) || (this.state.search !== this.props.search
            || this.state.hospitalId !== this.props.hospitalId || this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.filterName = this.props.filterName;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.page = 1;
			this.fetchDataFromServer();
		}

		if (this.state.status !== this.props.status) {
			this.setState({ status: this.props.status, page: 1, loading: true }, () => {
				this.fetchDataFromServer();
			});
		}
		if (this.state.hospitalId !== this.props.hospitalId) {
			this.setState({ hospitalId: this.props.hospitalId, page: 1, loading: true }, () => {
				this.fetchDataFromServer();
			});
		}

		return (
			<div className="pole_top_section">
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete category personnel", utils.getLanguage())}
					visible={this.state.deleteCPVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteCategoryPersonnel.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedCategoryPersonnel.name}</span> ?
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							refreshCompleted={this.refreshCompleted}
							categoryPersonnelId={this.state.categoryPersonnelId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

ListCategoryPersonnel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	categoryPersonnel: store.categoryPersonnel,
	translations: store.translations
}))(ListCategoryPersonnel));