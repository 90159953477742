import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CREATE_CLINICAL_PATHWAY from "../../create-intervention/mom";
import screenId from "../../../../../../router/screenIdList";

class CreateClinicalPathwayMom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			costCenter: "",
			name: ""
		};
	}

	render() {
		return (
			<div>
				<CREATE_CLINICAL_PATHWAY
					typeActivity="CLINICAL_PATHWAY"
					screenId={screenId.anaesthesiaCreateMOM}
					category={this.props.location.state ? this.props.location.state.category : ""}
				/>
			</div>
		);
	}
}

CreateClinicalPathwayMom.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateClinicalPathwayMom));
