import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import { Select, Modal } from "antd";
import differenceWith from "lodash.differencewith";
import I18, { i18Get } from "../../../../../../i18";
import elementsAction from "../../../../../../actions/text-element";
import eventNames from "../../../../../../actions/events";
import utils from "../../../../../../utils/utils";
import ActionButton from "../../../../../../plug/action-button";
import "../../../../../../plug/reactTable/react-table.css";
import "./text-element.css";
import Loader from "../../../../../../plug/loader";
import BreadCrumb from "../../breadcrumb";
import { DeleteOutlined } from "@ant-design/icons";

const { Option } = Select;

class TextElement extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			type: "",
			data: [],
			deleteVisible: false,
			elementToDelete: false,
			sorted: [],
			listLength: 50,
			selectedData: []
		};
	}

	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	componentWillUnmount() {
		this.state.list = [];
		this.state.data = [];
		this.state.selectedData = [];
		this.state.type = "";
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTION", utils.getLanguage()),
				id: "action",
				sortable: false,
				accessor: (d) => d.id,
				width: 140,
				resizable: false,
				Cell: (row) => (
					<span className="risk_action_button text_align_center"><DeleteOutlined  className="delete_color" onClick={this.deleteElementClicked.bind(this, row.value)} /></span>
				)
			},
			{
				Header: i18Get(this.props.type.toUpperCase(), utils.getLanguage()),
				id: "name",
				accessor: (d) => d.name,
				resizable: false,
				Cell: (row) => (
					row.value
				)
			}]);
		return tableArray;
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, elementToDelete: false });
	}

	deleteElementClicked(id) {
		const prevState = this.state;
		prevState.elementToDelete = id;
		prevState.deleteVisible = true;
		this.setState(prevState);
	}

	deleteElement() {
		const payload = {
			textElementId: this.state.elementToDelete
		};
		if (this.props.details) {
			this.props.dispatch(elementsAction.deleteElement(this.props.type, this.props.match.params.treatmentId, payload));
		} else {
			this.props.dispatch(elementsAction.deleteElement(this.props.type, this.props.match.params.id, payload));
		}
	}

	addClicked() {
		if ((this.state.selectedData && this.state.selectedData.length)) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				textElementIds: this.state.selectedData,
				type: this.props.type
			};
			if (this.props.details) {
				this.props.dispatch(elementsAction.add(this.props.type, this.props.match.params.treatmentId, payload));
			} else {
				this.props.dispatch(elementsAction.add(this.props.type, this.props.match.params.id, payload));
			}
		} else {
			this.setState({ invalidDataselected: true });
		}
	}

	renderOptions() {
		if (this.state.data && this.state.list) {
			this.state.data = differenceWith(this.state.data, this.state.list, (arrVal, othVal) => arrVal.id === othVal.textElementId);
			return this.state.data.map((data) => <Option key={data.id} value={data.id}>{data.name}</Option>);
		}
	}

	onOptionsChange(value) {
		this.setState({ selectedData: value, invalidDataselected: false });
	}

	onFetchData() {
		if (this.props.details) {
			this.props.dispatch(elementsAction.get(this.props.type, this.props.match.params.treatmentId));
		} else {
			this.props.dispatch(elementsAction.get(this.props.type, this.props.match.params.id));
		}
	}

	onFetchListData() {
		this.props.dispatch(elementsAction.getAll(0, this.props.type, 1000, "name", 0));
	}

	generateItems() {
		const items = [];
		if (this.props.details) {
			items.push({ name: (this.props.details && this.props.details.data) ? this.props.details.data.name : i18Get("Chemotherapy Details", utils.getLanguage()), link: `/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}` });
			items.push({ name: i18Get(utils.showTitleElement(this.props.type), utils.getLanguage()), link: this.props.location.pathname });
		} else {
			items.push({ name: i18Get("Intervention", utils.getLanguage()), link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}` });
			items.push({ name: this.props.intervention ? this.props.intervention : i18Get("Intervention Details", utils.getLanguage()), link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}` });
			items.push({ name: i18Get(utils.showTitleElement(this.props.type), utils.getLanguage()), link: this.props.location.pathname });
		}
		return items;
	}

	render() {
		if (this.state.type !== this.props.type) {
			this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
			this.onFetchData();
			this.onFetchListData();
			this.state.list = [];
			this.state.data = [];
			this.state.selectedData = [];
			this.state.invalidDataselected = false;
			this.state.type = this.props.type;
			this.props.dispatch(elementsAction.cleargetAll());
		}

		if (this.props.textElements.isFetchAllDropDownDataCompleted === "FULFILLED") {
			this.state.data = this.props.textElements.dropDownData;
		}

		if (this.props.textElements.iselementAddCompleted === "FULFILLED") {
			this.state.selectedData = [];
			this.onFetchData();
			this.props.dispatch(elementsAction.clearAdd());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.textElements.isElementGetCompleted === "FULFILLED") {
			this.state.list = this.props.textElements.elementsData;
			this.state.loading = false;
			this.props.dispatch(elementsAction.clearGet());
		}
		if (this.props.textElements.isElementDeleteCompleted === "FULFILLED") {
			this.state.deleteVisible = false;
			this.onFetchData();
			this.props.dispatch(elementsAction.clearDelete());
		}

		return (
			<div className="risk_preparation_container">
				<BreadCrumb useSearch items={this.generateItems()} />
				<div className="risk_preparation_header float_left di w_100 padding-0 margin-top-15 margin-bottom-15">
					<div className="row">
						<div className="col-md-10 col-sm-10">
							<div className="w_100 di position_relative">
								<label className="common_label"><I18 tkey={`Select ${this.props.type.toLowerCase()}`} /></label>
								<Select
									mode="multiple"
									labelInValue
									allowClear
									showSearch
									className="w100"
									defaultActiveFirstOption={false}
									notFoundContent={i18Get("Not Found", utils.getLanguage())}
									optionFilterProp="children"
									onChange={this.onOptionsChange.bind(this)}
									value={this.state.selectedData}
									filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
								>
									{this.renderOptions()}
								</Select>
								{this.state.invalidDataselected && <span className="invalid_right fixed_label"><I18 tkey={`Select valid ${this.props.type.toLowerCase()}`} /></span>}
							</div>
						</div>
						<div className="col-md-2 text_align_right col-sm-2">
							<div className="text_align_right margin_top_18_action_button"><ActionButton showAdd className="common_button margin_top_15px_mob" onClick={this.addClicked.bind(this)} value={i18Get("ADD", utils.getLanguage())} /></div>
						</div>
					</div>
				</div>
				<ReactTable
					data={this.state.list}
					loading={this.state.loading}
					LoadingComponent={() => (<Loader loading={this.state.loading} isTable />)}
					multiSort={false}
					pageSize={(this.state.list && this.state.list.length) ? this.state.list.length : 3}
					showPagination={false}
					noDataText={this.state.loading ? "" : i18Get(`NO ${this.props.type.toUpperCase()} FOUND`, utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange={(newSorted) => {
						this.setState({ sorted: newSorted });
					}}
					sorted={this.state.sorted}
				/>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteElement.bind(this)}
				>
					<div>
						<I18 tkey="Are you sure you want to delete" />
						{" "}
						{}
						{" "}
            ?
					</div>
				</Modal>
			</div>
		);
	}
}

TextElement.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	textElements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	textElements: store.textElements,
	translations: store.translations
}))(TextElement));
