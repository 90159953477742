import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import axios from "axios";
import utils from "../../../../../../../utils/utils";
import Config from "../../../../../../../MOM-config";
import { apiHeaders } from "../../../../../../../utils";

const moment = require("moment");

const PDF = "PDF";
const EXCEL = "EXCEL";

class ExportStandardCareChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			synthesisList: [],
			loading: false,
			showColumns: []
		};
	}

	UNSAFE_componentWillMount() {
		const { selectedPblmColumns } = this.props;
		if (selectedPblmColumns && selectedPblmColumns.check) {
			const columns = [];
			Object.keys(selectedPblmColumns.check).forEach((key) => {
				if (selectedPblmColumns.check[key] === true) {
					columns.push(key);
				}
			});
			this.state.showColumns = columns;
		}
		this.exportPathwayProblem();
	}

	pathwayProblemTemplate(type, win) {
		let params = "?";
		params += `locale=${utils.getLanguage()}&`;
		params += `clpStepFilter=${this.props.clpStepFilter ? this.props.clpStepFilter : ""}&`;
		params += `showColumns=${this.state.showColumns ? this.state.showColumns : ""}&`;
		params += "exportType=EXCEL";
		// let params = "exportType=EXCEL&clpStepFilter=ALL_ITEMS&locale=fr&showColumns=a,b";
		axios({
			url: `${Config.apiRootSecure}/reports/clinicalPathway/${this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id}/standardCareChart/export${params}`,
			...apiHeaders(),
			method: "GET",
			responseType: "blob"
		})
			.then((response) => {
				this.state.isExportLoading = false;
				const blob = new Blob([response.data], (type !== "csv" ? { type: `application/${type}` } : { type: `text/${type}` }));
				const windowUrl = (window.URL || window.webkitURL);
				const downloadUrl = windowUrl.createObjectURL(blob);
				const dateTime = moment().format("DDMMYYYY_HHmmss");
				if (type !== PDF) {
					const link = document.createElement("a");
					link.href = downloadUrl;
					link.download = `${this.props.filePrefix}_master_${dateTime}` + `${type === EXCEL ? ".xlsx" : ""}`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					win.location = downloadUrl;
					win.focus();
				}
				this.setState(this.state);
			}).catch(() => {
				this.state.isExportLoading = false;
				//win.close();
				this.setState(this.state);
			});
		this.props.closeExport();
	}

	exportPathwayProblem() {
		const win = window;
		this.pathwayProblemTemplate(EXCEL, win);
	}

	render() {
		if (Object.keys(this.props.standardCareChart.standardCareChartData) && !this.state.isLoaded) {
			this.state.standardCareChart = this.props.standardCareChart.standardCareChartData;
			this.state.isLoaded = true;
		}

		return (
			<div />
		);
	}
}

ExportStandardCareChart.propTypes = {
	actions: PropTypes.object.isRequired,
	standardCareChart: PropTypes.object.isRequired,
	treatmentId: PropTypes.string.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	standardCareChart: store.standardCareChart
}))(ExportStandardCareChart));
