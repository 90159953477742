import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import anaesthesiaAction from "../../../../../actions/anaesthesia";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class AnaesthesiaSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anaesthesia: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true
		};
	}
   
	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchAnaesthesiaList(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchAnaesthesiaList(this.state.search);
	}

     
	fetchAnaesthesiaList(search) {
		if (!this.state.fetchingData) {   
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			const queryString = "offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + "&status=APPROVED" + (search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "");
			//const queryString = "status=APPROVED" + (search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "");
			this.props.dispatch(anaesthesiaAction.getAnaesthesia(queryString)); 
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			this.state.anaesthesia = [];
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchAnaesthesiaList(search);
				}, 800);
			}
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.anaesthesia) {    
			this.state.anaesthesia.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}
	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	render() {
		if (this.props.anaesthesia.getAnaesthesiaStatus === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.anaesthesia = unionWith(this.state.anaesthesia, this.props.anaesthesia.anaesthesiaList, isEqual);
			this.renderOptions();
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
				this.fetchAnaesthesiaList(false);
				this.state.isDataFound = false;
			} 
			this.props.dispatch(anaesthesiaAction.getAnaesthesiaCompleted());    
		}

		return (   
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.onAnaesthesiaChange}
				isClearable={true}
				value={this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Anaesthesia", utils.getLanguage())}
			/>
		);
	}
}

AnaesthesiaSelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	anaesthesia: store.anaesthesia,
	translations: store.translations
}))(AnaesthesiaSelect));                    