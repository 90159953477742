import React, { useEffect, useState, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./profile.css";
import I18, { i18Get } from "../../../../i18";
import utils from "../../../../utils/utils";
import { Modal, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import userActions from "../../../../actions/user/profile";
import SVG from "../../../../plug/svg";
import events from "../../../../actions/events";
import screenId from "../../../../router/screenIdList";
import loginActions from "../../../../actions/user/login";
const PasswordValidation = React.lazy(() => import("mom-ui-commons/build/components/user/passwordValidation"));

const ChangePassword = () =>  {

	const dispatch = useDispatch();
	const user =  useSelector(store => store.user);
	const settings =  useSelector(store => store.settings);
	//const [profileData, setProfileData] = useState({});
	const [changePasswordVisible, setChangePasswordVisible] = useState(false);
	const [showPasswordOld, setShowPasswordOld] = useState(false);
	const [showPasswordNew, setShowPasswordNew] = useState(false);
	const [showPasswordRnew, setShowPasswordRnew] = useState(false);
	const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmNewPassword, setConfirmNewPassword] = useState("");
	const [invalidOldPass, setInvalidOldPass] = useState(false);
	const [invalidNewPass, setInvalidNewPass] = useState(false);
	const [invalidPassOldEqual, setInvalidPassOldEqual] = useState(false);
	const [invalidConfirmPass, setinvalidConfirmPass] = useState(false);
	const [invalidPassNotEqual, setInvalidPassNotEqual] = useState(false);

	useEffect(() => {
		onRestChangePassword();
		dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.profile });
		dispatch(loginActions.getLoginInfo(user.loginData.email));
	}, []);

	useEffect(() => {
		if (user.isChangePasswordUserCompleted === "FULFILLED") {
			setChangePasswordVisible(false);
			dispatch(userActions.clearChangePassword());
			dispatch(loginActions.getLoginInfo(user.loginData.email));
		}
		if (user.fetchLoginInfo === "FULFILLED") { 
			if (user.loginInfo && user.loginInfo.isResetPassword) {
				setChangePasswordVisible(true);
			} else {
				setChangePasswordVisible(false);
			}
			dispatch(loginActions.clearLoginInfo());
		}
		
		document.removeEventListener("keydown", handleKeyDown, false);

	});

	useEffect(() => {
		resetInvalidField();
	}, [oldPassword, newPassword, confirmNewPassword]);

	const handleKeyDown = (e) => {
		if (e.keyCode === 13) {
			if (changePasswordVisible) {
				changePassword();
			}
		}
	};
	


	
	const onRestChangePassword = () => {
		resetInvalidField(); 
		setOldPassword("");
		setNewPassword("");
		setConfirmNewPassword("");
	};

	const hideModalChangePassword = () => {
		setChangePasswordVisible(false);
	};

	const resetInvalidField = () => {
		setInvalidOldPass(false);
		setInvalidNewPass(false);
		setInvalidPassOldEqual(false);
		setinvalidConfirmPass(false);
		setInvalidPassNotEqual(false);
	};
    
	const changePassword = () => {
		resetInvalidField();
		if (validatePassword()) {
			const payload = {
				newPassword: newPassword,
				oldPassword: oldPassword
			};
			dispatch(userActions.changePasswordUser(payload));
		}
	};

	const validatePassword = () => {
		
		let valid = true;
		if (!oldPassword) {
			setInvalidOldPass(true);
			valid = false;           
		}
		if (!newPassword || !utils.regexPassVal.test(newPassword)) {
			setInvalidNewPass(true);
			valid = false;            
		} else if (oldPassword === newPassword) {
			setInvalidPassOldEqual(true);
			valid = false;           
		}
		if (!confirmNewPassword || !utils.regexPassVal.test(confirmNewPassword)) {
			setinvalidConfirmPass(true);
			valid = false;           
		} else if (newPassword !== confirmNewPassword) {
			setInvalidPassNotEqual(true);
			valid = false;           
		}

		return valid;        
	};
	
	const passwordChange = (e) => {
		const inputString = e.target.value;
		const value = inputString.replace(utils.regexPassValEcp, "");
		setNewPassword(value);

	};
	return (
		<Modal
			title={i18Get("Change Password", utils.getLanguage())}
			className="change_pass_popup re_assign_modal"
			open={changePasswordVisible}
			onCancel={hideModalChangePassword.bind(this)}
			okText={i18Get("OK", utils.getLanguage())}
			cancelText={i18Get("Cancel", utils.getLanguage())}
			onOk={changePassword.bind(this)}>
			<div className="mom_form_popup padding-0">
				<div className="row">
					<div className="col-xs-12 col-sm-offset-1 col-sm-10 ">
						<div className="group">
							<label className="top_10px" htmlFor='oldPassword'><I18 tkey="Old Password" /></label>
							<div className="profile_input_eye">
								<input type={showPasswordOld ? "text" : "password"}  id="oldPassword" placeholder="" name="oldPassword" value={oldPassword} onChange={(e) =>  setOldPassword(e.target.value)} maxLength="30"/> 
								<a onClick={() => { setShowPasswordOld(!showPasswordOld);}}>
									{showPasswordOld ? 
										<SVG src='/images/eye-slash.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""} /> 
										: <SVG src='/images/eye.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""}/>}
								</a>
							</div>
							{invalidOldPass && <span className="invalid"><I18 tkey="Enter old password" /></span>}
						</div>            
					</div>                       
					<div className="col-xs-12 col-sm-offset-1 col-sm-10">
						<div className="group">
							<label className="top_10px" htmlFor='newPassword'><I18 tkey="New Password" /></label>
							<div className="profile_input_eye">
								<input type={showPasswordNew ? "text" : "password"}  id="newPassword" placeholder="" name="newPassword" value={newPassword} onChange={passwordChange} maxLength="30"/>    
								<a onClick={() => { setShowPasswordNew(!showPasswordNew);}}>
									{showPasswordNew ? 
										<SVG src='/images/eye-slash.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""} /> 
										: <SVG src='/images/eye.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""}/>}
								</a>    
							</div>     
							{invalidNewPass && <span className="invalid"><I18 tkey="Enter new password" /></span>}
							{invalidPassOldEqual && <span className="invalid"><I18 tkey="New password cannot be same as old password" /></span>}
							<Suspense fallback={<div></div>}><PasswordValidation value={newPassword} /></Suspense>
						</div>
					</div>
					<div className="col-xs-12 col-sm-offset-1 col-sm-10">
						<div className="group">
							<label className="top_10px" htmlFor='confirmNewPassword'><I18 tkey="Confirm New Password" /></label>
							<div className="profile_input_eye">
								<input type={ showPasswordRnew ? "text" : "password"}  id="confirmNewPassword" placeholder="" name="confirmNewPassword" value={confirmNewPassword} onChange={(e) =>  setConfirmNewPassword(e.target.value)} maxLength="30"/> 
								<a onClick={() => { setShowPasswordRnew(!showPasswordRnew);}}>
									{showPasswordRnew ? 
										<SVG src='/images/eye-slash.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""} /> 
										: <SVG src='/images/eye.svg' fillColor={Object.keys(settings.theme).length ? settings.theme.primaryColor.value : ""}/>}
								</a>  
							</div>          
							{invalidConfirmPass && <span className="invalid"><I18 tkey="Enter confirm new password" /></span>}
							{invalidPassNotEqual && <span className="invalid"><I18 tkey="New passwords do not match" /></span>}
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export default ChangePassword;