import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import utils from "../../../../../../../../utils/utils";
import "./text-element.css";

class TextEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			textElement: {},
			textElementId: null,
			type: "",
			activeCycle: {},
			hideToolbar: false,
			invalidDescription: false
		};
	}

	render() {
		return (
			<div>
				<div className="risk_preparation_header float_left di w_100 padding-0 ">

					<Editor
						wrapperClassName="wrapper-class"
						editorClassName="editor-class"
						toolbarClassName="toolbar-class"
						readonly={!!this.props.readonly}
						editorState={this.props.editorState}
						onEditorStateChange={this.props.onEditorStateChange}
						handleBeforeInput={this.props.handleBeforeInput}
						handlePastedText={this.props.handlePastedText}
						keyBindingFn={this.props.keyBindingFn}
						handleKeyCommand={this.props.handleKeyCommand}
						localization={{
							locale: utils.getLanguage()
						}}
						toolbar={{
							options: ["inline", "fontSize", "list", "textAlign", "link"],
							inline: { inDropdown: true },
							list: { inDropdown: true },
							textAlign: { inDropdown: true },
							link: { inDropdown: true }
						}}
					/>

				</div>
			</div>
		);
	}
}

TextEditor.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	textElements: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	textElements: store.textElements,
	actions: store.actions,
	extClinicalPathway: store.extClinicalPathway,
	translations: store.translations,
	clinicalPathwayDetails: store.clinicalPathwayDetails
}))(TextEditor));
