import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import List from "./list";
import "./style.css";
class Permissions  extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	UNSAFE_componentWillMount() {
		this.props.showRecord(false);
	}
	render() {
		return (
			<div className="">          
				<List totalCount={this.props.totalCount} />
			</div>  
		);
	}
}

Permissions.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	translations: store.translations
}))(Permissions));                    