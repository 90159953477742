import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import ClinicalPathWayList from "./clinical-pathway-list";
import ClinicalPathWayCreate from "./createClinicalPathWay";
// import EditAnaesthesia from './edit-anaesthesia';
import ClinicalPathWayDetails from "./details";
import isPermissionGranted from "../../../../../utils/permission";

class ClinicalPathWay extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<Switch>
				{isPermissionGranted(false, "CREATE") && <Route exact path={locations.clinical_pathway_create} component={ClinicalPathWayCreate} />}
				<Route exact path={locations.clinicalPathwayDetails} component={ClinicalPathWayDetails} />
				<Route path={locations.clinical_pathway} component={ClinicalPathWayList} />
			</Switch>
		);
	}
}

ClinicalPathWay.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(ClinicalPathWay));
