import eventNames from "../../actions/events";

const initialTranslationsState = {
	fetchAllPersonnelTemplateCompleted: false,
	personnelTemplateList: {},
	fetchAllMinimalTemplateCompleted: false,
	minimalTemplateList: {},
	fetchOnePersonnelTemplateCompleted: false,
	personnelTemplate: {},
	updatePersonnelTemplateCompleted: false,
	createPersonnelTemplateCompleted: false,
	deletePersonnelTemplateCompleted: false,
	fetchAllTemplateDetailsCompleted: false,
	templateDetailsList: {},
	fetchOneTemplateDetailsCompleted: false,
	templateDetails: {},
	createTemplateDetailsCompleted: false,
	updateTemplateDetailsCompleted: false,
	deleteTemplateDetailsCompleted: false,
	updateStaffRankCompleted: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_PERSONNEL_TEMPLATES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllPersonnelTemplateCompleted: "FULFILLED",
					personnelTemplateList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllPersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_PERSONNEL_TEMPLATES}_REJECTED`:
			return {
				...state,
				fetchAllPersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES:
			return {
				...state,
				fetchAllPersonnelTemplateCompleted: false
			};
		case `${eventNames.FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllMinimalTemplateCompleted: "FULFILLED",
					minimalTemplateList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllMinimalTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL}_REJECTED`:
			return {
				...state,
				fetchAllMinimalTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL:
			return {
				...state,
				fetchAllMinimalTemplateCompleted: false
			};
		case `${eventNames.FETCH_ONE_PERSONNEL_TEMPLATE}_FULFILLED`:
			return {
				...state,
				personnelTemplate: action.payload.data.data,
				fetchOnePersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_PERSONNEL_TEMPLATE}_REJECTED`:
			return {
				...state,
				fetchOnePersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_PERSONNEL_TEMPLATE:
			return {
				...state,
				fetchOnePersonnelTemplateCompleted: false
			};
		case `${eventNames.UPDATE_PERSONNEL_TEMPLATE}_FULFILLED`:
			return {
				...state,
				updatePersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_PERSONNEL_TEMPLATE}_REJECTED`:
			return {
				...state,
				updatePersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_PERSONNEL_TEMPLATE:
			return {
				...state,
				updatePersonnelTemplateCompleted: false
			};
		case `${eventNames.CREATE_PERSONNEL_TEMPLATE}_FULFILLED`:
			return {
				...state,
				createPersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_PERSONNEL_TEMPLATE}_REJECTED`:
			return {
				...state,
				createPersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_PERSONNEL_TEMPLATE:
			return {
				...state,
				createPersonnelTemplateCompleted: false
			};
		case `${eventNames.DELETE_PERSONNEL_TEMPLATE}_FULFILLED`:
			return {
				...state,
				deletePersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_PERSONNEL_TEMPLATE}_REJECTED`:
			return {
				...state,
				deletePersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_PERSONNEL_TEMPLATE:
			return {
				...state,
				deletePersonnelTemplateCompleted: false
			};
		case `${eventNames.FETCH_ALL_TEMPLATE_DETAILS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllTemplateDetailsCompleted: "FULFILLED",
					templateDetailsList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllTemplateDetailsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_TEMPLATE_DETAILS}_REJECTED`:
			return {
				...state,
				fetchAllTemplateDetailsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_TEMPLATE_DETAILS:
			return {
				...state,
				fetchAllTemplateDetailsCompleted: false
			};
		case `${eventNames.FETCH_ONE_TEMPLATE_DETAILS}_FULFILLED`:
			return {
				...state,
				templateDetails: action.payload.data.data,
				fetchOneTemplateDetailsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_TEMPLATE_DETAILS}_REJECTED`:
			return {
				...state,
				fetchOneTemplateDetailsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_TEMPLATE_DETAILS:
			return {
				...state,
				fetchOneTemplateDetailsCompleted: false
			};
		case `${eventNames.CREATE_TEMPLATE_DETAILS}_FULFILLED`:
			return {
				...state,
				createTemplateDetailsCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_TEMPLATE_DETAILS}_REJECTED`:
			return {
				...state,
				createTemplateDetailsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_TEMPLATE_DETAILS:
			return {
				...state,
				createTemplateDetailsCompleted: false
			};
		case `${eventNames.UPDATE_TEMPLATE_DETAILS}_FULFILLED`:
			return {
				...state,
				updateTemplateDetailsCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_TEMPLATE_DETAILS}_REJECTED`:
			return {
				...state,
				updateTemplateDetailsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_TEMPLATE_DETAILS:
			return {
				...state,
				updateTemplateDetailsCompleted: false
			};
		case `${eventNames.DELETE_TEMPLATE_DETAILS}_FULFILLED`:
			return {
				...state,
				deleteTemplateDetailsCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_TEMPLATE_DETAILS}_REJECTED`:
			return {
				...state,
				deleteTemplateDetailsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_TEMPLATE_DETAILS:
			return {
				...state,
				deleteTemplateDetailsCompleted: false
			};
		case `${eventNames.UPDATE_STAFF_RANK}_FULFILLED`:
			return {
				...state,
				updateStaffRankCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_STAFF_RANK}_REJECTED`:
			return {
				...state,
				updateStaffRankCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_STAFF_RANK:
			return {
				...state,
				updateStaffRankCompleted: false
			};

		case `${eventNames.FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllApprovedPersonnelTemplateCompleted: "FULFILLED",
					personnelTemplateList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllApprovedPersonnelTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES}_REJECTED`:
			return {
				...state,
				fetchAllApprovedPersonnelTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES:
			return {
				...state,
				fetchAllApprovedPersonnelTemplateCompleted: false
			};

		case `${eventNames.SAVE_AND_APPROVE_PERSONNEL_TEMPLATES}_FULFILLED`:
			return { ...state, saveAndApprovePersonnelTemplateStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_PERSONNEL_TEMPLATES}_REJECTED`:
			return { ...state, saveAndApprovePersonnelTemplateStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED:
			return { ...state, saveAndApprovePersonnelTemplateStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES}_FULFILLED`:
			return { ...state, asssignAndApprovePersonnelTemplateStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES}_REJECTED`:
			return { ...state, asssignAndApprovePersonnelTemplateStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED:
			return { ...state, asssignAndApprovePersonnelTemplateStatus: false };

		case `${eventNames.FETCH_ALL_NEW_TEMPLATES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllNewTemplateCompleted: "FULFILLED",
					newTemplateList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllNewTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_NEW_TEMPLATES}_REJECTED`:
			return {
				...state,
				fetchAllNewTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_NEW_TEMPLATES:
			return {
				...state,
				fetchAllNewTemplateCompleted: false
			};

		case `${eventNames.FETCH_ALL_EXISTING_TEMPLATES}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllExistingTemplateCompleted: "FULFILLED",
					existingTemplateList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllExistingTemplateCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXISTING_TEMPLATES}_REJECTED`:
			return {
				...state,
				fetchAllExistingTemplateCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXISTING_TEMPLATES:
			return {
				...state,
				fetchAllExistingTemplateCompleted: false
			};

		case `${eventNames.SAVE_AND_APPROVE_TEMPLATES}_FULFILLED`:
			return { ...state, saveAndApproveTemplateStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_TEMPLATES}_REJECTED`:
			return { ...state, saveAndApproveTemplateStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_TEMPLATES_COMPLETED:
			return { ...state, saveAndApproveTemplateStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_TEMPLATES}_FULFILLED`:
			return { ...state, asssignAndApproveTemplateStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_TEMPLATES}_REJECTED`:
			return { ...state, asssignAndApproveTemplateStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_TEMPLATES_COMPLETED:
			return { ...state, asssignAndApproveTemplateStatus: false };

		default:
			return state;
	}
}
