import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import locations from "../../router/locations";
import Landing from "./landing-page";

export default class User extends Component {
	render() {
		return (
			<Switch>
				<Route path={locations.base} component={Landing} />
			</Switch>
		);
	}
}
