import React, { Component } from "react";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import { ClpStepFilters } from "components/main-container/overview/main-overview/clinical-pathway/details";
import { Select, Tooltip } from "antd";
const { Option } = Select;

function SectionTypeDropDown (props) {

	const renderOptions = () => [
		{ key: ClpStepFilters.BEFORE_ADMISSION, value: ClpStepFilters.BEFORE_ADMISSION, label: i18Get( "BEFORE ADMISSION", utils.getLanguage()) },
		{ key: ClpStepFilters.CLINICAL_PATHWAY, value: ClpStepFilters.CLINICAL_PATHWAY, label: i18Get("HOSPITALISATION", utils.getLanguage()) },
		{ key: ClpStepFilters.AFTER_CHECKOUT, value: ClpStepFilters.AFTER_CHECKOUT, label: i18Get("AFTER CHECKOUT", utils.getLanguage()) }
	];
	
	return (
		<div className={ props.className ? props.className : "w_200p di mb_15_mob"}>
			<Tooltip placement="top" title={i18Get("Phase", utils.getLanguage())} >
				<Select 
					value={props.value}
					className="w_100 di"
					allowClear={ props.allowClear}
					placeholder="Phase"
					disabled={ props.disabled}
					onChange={ props.onChange}
					options={renderOptions()}
					notFoundContent= {i18Get("Not Found", utils.getLanguage())}
				>
				</Select>
			</Tooltip>
		</div>
	);
	
}

export default SectionTypeDropDown;
