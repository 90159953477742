import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import interventionAction from "../../../../../../../actions/intervention";
import InterventionSelect from "./select-interventions";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			interventionName: "",
			interventionCode: "",
			invalidName: false,
			invalidCode: false,
			invalidIntervention: false,
			selectedIntervention: null,
			interventionDetails: {},
			treatmentId: "",
			specialityApproved: false,
			familyApproved: false,
			isloading: false,
			interventionFound: false,
			saveAndApproveLoading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(interventionAction.getOneIntervention(this.props.treatmentId, new Date().getTimezoneOffset()));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false, invalidCode: false, invalidFamily: false, value: 1, interventionFound: false });
		this.props.refreshCompleted();
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.interventionFound = true;
			this.state.value = 2;
		}
	}

	onInterventionChange(e) {
		this.setState({ selectedIntervention: e ? e : "", invalidIntervention: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.interventionName.trim())) {
			prevState.invalidName = true;
		}
		if (!this.state.interventionCode) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateIntervention = () => {
		if (this.state.selectedIntervention && this.state.interventionFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		this.setState({ saveAndApproveLoading: true });
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.interventionName,
					code: this.state.interventionCode
				};
				this.props.dispatch(interventionAction.saveAndApproveIntervention(this.state.interventionDetails.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateIntervention()) {
				payload = {
					interventionId: this.state.selectedIntervention && this.state.selectedIntervention.value ? this.state.selectedIntervention.value : ""
				};
				this.props.dispatch(interventionAction.assignAndApproveIntervention(this.state.interventionDetails.id, payload));
			}
			else {
				this.setState({ invalidIntervention: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.interventionName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.interventionCode = value;
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.treatmentId !== this.state.treatmentId) {
			this.state.treatmentId = this.props.treatmentId;
			this.props.dispatch(interventionAction.getOneIntervention(this.props.treatmentId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.intervention.asssignAndApproveInterventionStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, interventionName: "", interventionCode: "", value: 1, interventionFound: false });
			this.props.dispatch(interventionAction.clearAssignAndApproveIntervention());
			this.props.refreshCompleted();
		}
		if (this.props.intervention.saveAndApproveInterventionStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, interventionName: "", interventionCode: "", value: 1, interventionFound: false, saveAndApproveLoading: false });
			this.props.dispatch(interventionAction.clearSaveAndApproveIntervention());
			this.props.refreshCompleted();
		}
		if (this.props.intervention.getOneIntervention === "FULFILLED") {
			this.state.interventionDetails = this.props.intervention.treatment ? this.props.intervention.treatment : "";
			this.state.interventionName = this.state.interventionDetails.name ? this.state.interventionDetails.name : "";
			this.state.interventionCode = this.state.interventionDetails.code ? this.state.interventionDetails.code : "";
			this.state.isloading = true;
			// this.state.selectedIntervention = { label: this.state.interventionDetails.name ? this.state.interventionDetails.name : "", value: this.state.interventionDetails.id }; 
			this.state.specialityApproved = this.state.interventionDetails.specialityApproved ? this.state.interventionDetails.specialityApproved : false;
			this.state.familyApproved = this.state.interventionDetails.familyApproved ? this.state.interventionDetails.familyApproved : false;
			this.props.dispatch(interventionAction.clearGetOneIntervention());
		}
		if (this.props.intervention.getOneIntervention === "REJECTED") {
			this.props.dispatch(interventionAction.clearGetOneIntervention());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Intervention", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.specialityApproved && this.state.familyApproved ?
							<Button key="save" type="primary" loading={this.state.saveAndApproveLoading} onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.interventionDetails.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.interventionDetails.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.interventionDetails.hospitalName ? this.state.interventionDetails.hospitalName : "Master"}</p>
							</div>
							{this.state.interventionDetails.specialityName &&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="SPECIALITY NAME"/>:</span> {this.state.interventionDetails.specialityName ? this.state.interventionDetails.specialityName : ""}</p>
							}
							{this.state.interventionDetails.familyName &&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="FAMILY NAME"/>:</span> {this.state.interventionDetails.familyName ? this.state.interventionDetails.familyName : ""}</p>
							}
						</div> 
						{this.state.specialityApproved && this.state.familyApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											{/* <label className="code_label"><I18 tkey="* Code must be 2-digit uppercase characters" /></label> */}
											<NameInput
												value={this.state.interventionCode}
												inputChanged={this.codeChanged.bind(this)}
												disabled={true}
												maxLength={20}
											/>
											{this.state.invalidCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid intervention code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group ">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.interventionName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={540}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid intervention name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Intervention" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<InterventionSelect defaultValue="Select Intervention" style={{ width: "100%" }}
												data={this.state.selectedIntervention}
												statusCheck={this.statusCheck.bind(this)}
												disabled={this.state.value != 2}
												onInterventionChange={this.onInterventionChange.bind(this)}/>
											{this.state.invalidIntervention && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select intervention" /></span>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.specialityApproved ?
									<I18 tkey="The speciality for this intervention is waiting for approval. Please approve the speciality first" />
									: this.state.isloading && !this.state.familyApproved ?  <I18 tkey="The family for this intervention is waiting for approval. Please approve the family first" /> : "" }
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	intervention: store.intervention,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApproveModel));    