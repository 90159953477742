import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import Families from "./families";
import Specialities from "./specialities";

export class Sterilization extends Component {
	render() {
		return (
			<div className="activities_table_scroll">
				<Switch>
					<Route path={locations.sterilization_speciality} component={Specialities} />
					<Route path={locations.sterilization_families} component={Families} />
					<Redirect to={locations.specialities} />
				</Switch>
			</div>
		);
	}
}

Sterilization.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Sterilization));
