import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../../../../i18";
import utils from "../../../../../../../../../../utils/utils";
import clinicalPathwayProblemAction from "../../../../../../../../../../actions/clinical-pathway-problems";
import events from "../../../../../../../../../../actions/events";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class ProblemList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			extType: "",
			type: "",
			isLoading: false,
			problems: [],
			offset: 0,
			numResults: 100,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.problems = [];
		this.state.offset = 0;
		this.state.numResults = 100;
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderProblemOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.problems) {
			this.state.problems.map((data) => {
				prevState.options.push({
					value: data.problemId, label: `${i18Get(data.type, utils.getLanguage())} - ${data.problem}`, explanation: data.data, problem: data.problem, problemType: data.type, results: data.results
				});
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchProblemList() {
		this.setState({ isListLoading: true });
		let queryString = `offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += this.state.search ? `&name=${this.state.search}` : "";
		queryString += this.props.sectionType ? `&clpStepFilter=${this.props.sectionType}` : "";
		queryString += this.props.withoutModel ? "&problemlistwithoutModel=true" : "";
		this.props.dispatch(clinicalPathwayProblemAction.getClpProblemList(this.props.match.params.id, this.props.match.params.organization, queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchProblemList(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchProblemList();
	}

	render() {
		if ((this.state.extType !== this.props.extType) || (this.props.type !== this.state.type) || (this.props.days !== this.state.days)) {
			this.state.problems = [];
			this.state.options = [];
			this.state.extType = this.props.extType;
			this.state.type = this.props.type;
			this.state.days = this.props.days;
			this.fetchProblemList();
		}
		if (this.props.extClinicalPathway.listUpdate) {
			this.state.listOffset = 0;
			this.state.problems = [];
			this.state.options = [];
			this.state.search = "";
			this.fetchProblemList();
			this.props.dispatch({ type: events.CLEAR_TRIGGER_LIST_UPDATE, payload: {} });
		}
		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwayProblemList === "FULFILLED") {
			this.props.dispatch(clinicalPathwayProblemAction.clearGetClpProblemList());
			this.state.problems = unionWith(this.state.problems, this.props.clinicalPathwayProblems.problemList.mapping, isEqual);
			this.renderProblemOptions();
		}

		return (
			<Select
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.onProblemChanged}
				value={this.props.data}
				isSearchable
				isClearable
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Problem", utils.getLanguage())}
			/>
		);
	}
}

ProblemList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	extClinicalPathway: store.extClinicalPathway,
	translations: store.translations
}))(ProblemList));
