import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import CardLink from "../card-link";
import I18 from "../../../../../i18";
import isPermissionGranted from "../../../../../utils/permission";
class ExpandableLink extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expanded: false,
			buildVersion: "",
			buildDate: ""
		};
	}

	onLinkClick(index) {
		if (this.props.onClick) {
			this.props.onClick(index);
		}
		this.setState({ expanded: !this.state.expanded });
	}

	onClick() {
		let urlToPush = "";
		if (this.props.data.name === "Interventions") {
			urlToPush = "/intervention/mom";
		}
		if (this.props.data.name === "Anaesthesia") {
			urlToPush = "/anaesthesia/mom";
		}
		if (this.props.data.name === "Clinical Pathways") {
			urlToPush = "/clinical_pathway/mom";
		}
		if (this.props.data.name === "Health Pathways") {
			urlToPush = "/health_pathway/mom";
		}
		this.props.history.push(urlToPush);
	}

	checkIsExpanded(data, type) {
		data.options.forEach((option) => {
			if (option.link && this.props.location.pathname.startsWith(option.link)) {
				this.props.onClick(data.index);
				this.state.expanded = true;
			}
		});
		if (data.link) {
			const links = data.link.split("/");
			if (!this.state.expanded && type && (links[links.length - 1] === type) && this.props.match.isExact) {
				this.props.onClick(data.index);
				this.state.expanded = true;
				// this.props.history.push(data.options[0].link);
			}
		} else if (!this.state.expanded && type && data.name.toLowerCase().indexOf(type) > -1 && this.props.match.isExact) {
			this.props.onClick(data.index);
			this.state.expanded = true;
			// this.props.history.push(data.options[0].link);
		}
	}

	componentDidMount() {
		this.checkIsExpanded(this.props.data, this.props.match.params.type);
	}

	checkPermission(link) {

		if (link.permission == "CLINICAL_PATHWAY")
		{
			return isPermissionGranted(false, "READ");
		} else {

			return true;
		}
	}
	render() {
		const { data } = this.props;

		if (this.props.onClick) {
			this.state.expanded = this.state.expanded && (this.props.openCard === data.index);
		}
		return (
			<div className='w-100'>
				{data.static && <a className="administration_headings font-size-14"><I18 tkey={data.static} /></a>}
				{this.props.data.name !== "Interventions" && this.props.data.name !== "Anaesthesia" && this.props.data.name !== "Clinical Pathways" && this.props.data.name !== "Health Pathways" ?
					<a className={`first_a expandable side_bar_items ${this.state.expanded ? "selected" : ""}`} onClick={this.onLinkClick.bind(this, data.index)}>
						{!this.state.expanded ? <PlusOutlined /> : <MinusOutlined />}
						<I18 tkey={data.name} />
					</a>
					: ""
				}
				{!(this.props.location.pathname.startsWith("/intervention/mom")) ? ""
					:
					this.checkPermission(this.props.data) && <a className={`first_a expandable side_bar_items ${this.props.data.name === "Interventions" ? "selected" : ""}`} onClick={this.onClick.bind(this)}>
						<I18 tkey={data.name} />
					</a>
				}
				{!(this.props.location.pathname.startsWith("/anaesthesia/mom")) ? ""
					:
					this.checkPermission(this.props.data) && <a className={`first_a expandable side_bar_items ${this.props.data.name === "Anaesthesia" ? "selected" : ""}`} onClick={this.onClick.bind(this)}>
						<I18 tkey={data.name} />
					</a>
				}
				{!(this.props.location.pathname.startsWith("/clinical_pathway/mom") && !data.options.length) ? ""
					:
					this.checkPermission(this.props.data) && <a className={`first_a expandable side_bar_items ${this.props.data.name === "Clinical Pathways" ? "selected" : ""}`} onClick={this.onClick.bind(this)}>
						<I18 tkey={data.name} />
					</a>
				}
				{!(this.props.location.pathname.startsWith("/health_pathway/mom") && !data.options.length) ? ""
					:
					this.checkPermission(this.props.data) && <a className={`first_a expandable side_bar_items ${this.props.data.name === "Health Pathways" ? "selected" : ""}`} onClick={this.onClick.bind(this)}>
						<I18 tkey={data.name} />
					</a>
				}
				{
					this.state.expanded && <CardLink data={data.options} position={data.position} />
				}
			</div>);
	}

	componentDidUpdate() {
		if (document.getElementsByClassName("side_bar_section") && document.getElementsByClassName("side_bar_section")[0] && this.state.expanded) {
			const pos = (document.getElementsByClassName("selected") && document.getElementsByClassName("selected")[0]) ? document.getElementsByClassName("selected")[0].offsetTop : 0;
			document.getElementsByClassName("side_bar_section")[0].scrollTop = pos;
		}
	}
}

ExpandableLink.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	settings: store.settings,
	permissions: store.permissions
}))(ExpandableLink));
