import eventNames from "../../actions/events";

const initialState = {
	userDetails: {},
	loginInfo: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_USER}_REJECTED`:
			return {
				...state,
				isFetchAllUsersCompleted: "REJECTED"
			};

		case `${eventNames.FETCH_ALL_USER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					userList: action.payload.data.data,
					isFetchAllUsersCompleted: "FULFILLED"
				};
			}
			break;
		case `${eventNames.CLEAR_USER_FETCH}`:
			return {
				...state,
				isFetchAllUsersCompleted: false
			};

		case `${eventNames.CREATE_USER}_REJECTED`:
			return {
				...state,
				isCreateUsersCompleted: "REJECTED"
			};

		case `${eventNames.CREATE_USER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					userCreate: action.payload.data.data,
					isCreateUsersCompleted: "FULFILLED"
				};
			}
			break;
		case `${eventNames.CLEAR_CREATE_USER}`:
			return {
				...state,
				isCreateUsersCompleted: false
			};

		case `${eventNames.DELETE_USER}_REJECTED`:
			return {
				...state,
				isDeleteUsersCompleted: "REJECTED"
			};

		case `${eventNames.DELETE_USER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					userDelete: action.payload.data.data,
					isDeleteUsersCompleted: "FULFILLED"
				};
			}
			break;
		case `${eventNames.CLEAR_DELETE_USER}`:
			return {
				...state,
				isDeleteUsersCompleted: false
			};

		case `${eventNames.FETCH_ONE_USER}_REJECTED`:
			return {
				...state,
				isFetchOneusersCompleted: "REJECTED"
			};

		case `${eventNames.FETCH_ONE_USER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					selecteduser: action.payload.data.data,
					isFetchOneusersCompleted: "FULFILLED"
				};
			}
			break;
		case `${eventNames.CLEAR_FETCH_ONE_USER}`:
			return {
				...state,
				isFetchOneusersCompleted: false
			};

		case `${eventNames.UPDATE_USER}_REJECTED`:
			return {
				...state,
				isUpdateusersCompleted: "REJECTED"
			};

		case `${eventNames.UPDATE_USER}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatedUser: action.payload.data.data,
					isUpdateusersCompleted: "FULFILLED"
				};
			}
			break;
		case `${eventNames.CLEAR_UPDATE_USER}`:
			return {
				...state,
				isUpdateusersCompleted: false
			};
		case `${eventNames.UPDATE_USER_PASSWORD}_PENDING`:
			return {
				...state,
				isAdminChangePasswordUserCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_USER_PASSWORD}_REJECTED`:
			return {
				...state,
				isAdminChangePasswordUserCompleted: "REJECTED"
			};

		case `${eventNames.UPDATE_USER_PASSWORD}_FULFILLED`:
			return {
				...state,
				isAdminChangePasswordUserCompleted: "FULFILLED"
			};
		case eventNames.CLEAR_UPDATE_USER_PASSWORD:
			return {
				...state,
				isAdminChangePasswordUserCompleted: false
			};
		case `${eventNames.LOGIN_LIMIT_INFO}_FULFILLED`:
			return {
				...state,
				loginInfo: action.payload.data.data,
				fetchLoginInfo: "FULFILLED"
			};
		case `${eventNames.LOGIN_LIMIT_INFO}_REJECTED`:
			return {
				...state,
				loginInfo: null,
				fetchLoginInfo: "REJECTED"
			};
		case `${eventNames.LOGIN_LIMIT_INFO_CLEAR}`:
			return {
				...state,
				loginInfo: null,
				fetchLoginInfo: false
			};
		default:
			return state;
	}
}
