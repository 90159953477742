import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import Loader from "../../../../../../plug/loader";
import { Modal, Checkbox, Tooltip } from "antd";
import extElementActions from "../../../../../../actions/ext-elements";
import consumableActions from "../../../../../../actions/consumable";
import events from "../../../../../../actions/events";
import typeActivity from "../../../../../../constants/typeActivity.json";
import { statusList } from "../../../../../../constants";
import locations from "../../../../../../router/locations";
import AssignAndApprovePopup from "../../intervention/details/v5/extelements/assign-approve-popup";
import { EditOutlined, ShareAltOutlined, DeleteOutlined } from "@ant-design/icons";

class ExtElementsList  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			extElementsList: [],
			deletedExtElement: {},
			deleteVisible: false,
			extElementAssignDoctor: false,
			page: 1,
			sort: "rank",
			desc: 0,
			subTotal: 0,
			totalCount: 3,
			type: "",
			rank: {
				min: 1,
				max: 1
			},
			disableRankUpdate: false,
			doctorList: [],
			checkedList: [],
			modelVisible: false,
			elementId: null,
			elementType: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.type = this.props.type;
	}

	UNSAFE_componentWillUpdate() {
		if (this.props.screenId !== this.state.screenId) {
			this.state.screenId = this.props.screenId;
			this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: this.props.screenId });
		}
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: d => d,
				width: 130,
				resizable: false,
				sortable: false,
				Cell: function(row) {
					return (<div className="margin_left_auto">
						{row.value.createdFromMom ? 
							<Tooltip title={i18Get("Manually Added", utils.getLanguage())} placement="right">
								<div className="exit_element_table_active"></div> 
							</Tooltip>
							: ""}
						<div>
							{row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
								<a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
								: <EditOutlined className={(this.props.doctorId && row.value.isDefault) ? "margin-right-10 disabled_a_tag disabled_bg" : "edit_color margin-right-10"} type="edit"  onClick={(this.props.doctorId && row.value.isDefault) ? (e) => { e.preventDefault();} : this.editClicked(row.value).bind(this)}/>
							}
							{this.props.doctorId && <ShareAltOutlined  className={(this.props.doctorId && row.value.isDefault) ? "margin-right-10 disabled_a_tag disabled_bg" : "edit_color margin-right-10"} type="share-alt"  onClick={(this.props.doctorId && row.value.isDefault) ? (e) => { e.preventDefault();} : this.assignDoctorClicked(row.value).bind(this)}/>}
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<DeleteOutlined  className={(this.props.doctorId && row.value.isDefault) ? "disabled_a_tag disabled_bg" : "delete_color"} type="delete"  onClick={(this.props.doctorId && row.value.isDefault) ? (e) => { e.preventDefault();} : this.deleteClicked(row.value).bind(this)}/>
								: ""
							}
						</div>
					</div>);
				}.bind(this)
			},
			{
				Header: i18Get(this.props.type.replace(/_/g, " ").toUpperCase(), utils.getLanguage()),
				id: "elementName",
				accessor: d => d.elementName,
				resizable: true,
				minWidth: 180,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("IS OPTIONAL", utils.getLanguage()),
				id: "isOptional",
				accessor: d => d.isOptional,
				show: this.props.isScannedTreatments ? false : true,
				resizable: true,
				Cell: row => (
					this.showYesOrNo(row.value)
				)
			},
			{
				Header: i18Get("COST ONE", utils.getLanguage()),
				id: "costOne",
				accessor: d => d.costOne,
				show: this.props.isScannedTreatments ? false : true,
				resizable: true,
				maxWidth: 70,
				Cell: row => (
					this.showYesOrNo(row.value)
				)
			},
			{
				Header: i18Get("DCI", utils.getLanguage()),
				id: "isDci",
				accessor: d => d.isDci,
				resizable: true,
				show: this.props.isDci ? true : false,
				Cell: row => (
					this.showYesNo(row.value)
				)
			},
			{
				Header: i18Get("ANNEX", utils.getLanguage()),
				id: "isAnnex",
				accessor: d => d.isAnnex,
				resizable: true,
				show: this.props.isDci ? true : false,
				Cell: row => (
					this.showYesNo(row.value)
				)
			},
			{
				Header: i18Get("BOTTLE", utils.getLanguage()),
				id: "isBottle",
				accessor: d => d.isBottle,
				resizable: true,
				show: this.props.isDci ? true : false,
				Cell: row => (
					this.showYesNo(row.value)
				)
			},
			{
				Header: i18Get("UNIT QUANTITY", utils.getLanguage()),
				id: "unitQuantity",
				accessor: d => d.unitQuantity,
				resizable: true,
				show: this.props.isDci ? true : false,
				Cell: row => (
					utils.setLocaleForCurrency(row.value ? row.value : 0.00)
				)
			},
			{
				Header: i18Get("TOTAL QUANTITY", utils.getLanguage()),
				id: "totalQuantity",
				accessor: d => d.totalQuantity,
				resizable: true,
				show: this.props.isDci ? true : false,
				Cell: row => (
					utils.setLocaleForCurrency(row.value ? row.value : 0.00)
				)
			},
			{
				Header: i18Get("QUANTITY", utils.getLanguage()),
				id: "quantity",
				accessor: d => d.quantity,
				className: "text_align_right",
				maxWidth: 90,
				resizable: true,
				show: this.props.isDci ? false : true,
				Cell: row => (
					utils.setLocaleForCurrency(row.value ? row.value : 0.00)
				)
			},
			{
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				Cell: row => (
					row.value ? this.getText(row.value) : "Master"
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				Cell: row => (
					this.getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
				)
			}]);
		return tableArray;
	}

	assignDoctorClicked(data) {        
		return () => {
			if (this.props.doctorId) {
				const queryString = `doctorId=${this.props.doctorId}&type=${this.props.type}`;
				this.props.dispatch(extElementActions.fetchAllDoctorsOfExtElement(this.props.match.params.id, data.elementId, queryString));
			}
			this.setState({ extElementSelected: data, extElementAssignDoctor: true });
		};
	}

	onAssignAndApproveClick (data) {
		return () => {
			this.setState({ modelVisible: true, elementId: data.id, elementType: data.type });
		};
	}

	hideModalAssignDoctor() {
		this.setState({ extElementAssignDoctor: false, checkedList: [] });
	}

	assignExtElementToDoctor() {                     
		this.props.dispatch({ type: events.ACTION_ONE });
		const payload = {
			doctorIds: this.state.checkedList,
			type: this.props.type
		};
		this.props.dispatch(extElementActions.updateDoctorsOfExtelement(this.props.match.params.id, this.state.extElementSelected.elementId, this.props.doctorId, payload));
		this.setState({ extElementAssignDoctor: false, extElementSelected: {}, checkedList: [] });       
	}

	getText(value) {
		return <div> {value}</div>;
	}

	showYesOrNo(value) {
		return <div>{value ? <div className = "subsidy_bold"><I18 tkey="Yes"/></div> : <I18 tkey="No"/>}</div>;
	}

	showYesNo(value) {
		return <div>{value ? <I18 tkey="Yes"/> : <I18 tkey="No"/>}</div>;
	}

	showRank(value) {
		return (
			<div className="rank_div">
				{/* <a className={disabledUp ? "disabled_a_tag float_left" : "float_left"} onClick={disabledUp ? (e) => { e.preventDefault(); } : this.rankIncreased(value).bind(this)}><i className="fa fa-angle-up" aria-hidden="true"></i></a> */}
				<span className="rank_value">{utils.setLocaleForCurrency(value.rank, utils.sameDropper(), true)}</span>
				{/* <a className={disabledDown ? "disabled_a_tag float_right" : "float_right"} onClick={disabledDown ? (e) => { e.preventDefault(); } : this.rankDecreased(value).bind(this)}><i className="fa fa-angle-down" aria-hidden="true"></i></a> */}
			</div>
		);
	}

	rankIncreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) - 1,
				increment: 0
			};
			if (this.props.isDci) {
				this.props.dispatch(consumableActions.preparationPerfusionRankUpdate(this.props.match.params.consumableId, value.elementId, payload));
			} else {
				this.props.dispatch(extElementActions.rankUpdate(this.props.match.params.id, this.props.type, value.elementId, payload));
			}
		};
	}

	rankDecreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) + 1,
				increment: 1
			};
			if (this.props.isDci) {
				this.props.dispatch(consumableActions.preparationPerfusionRankUpdate(this.props.match.params.consumableId, value.elementId, payload));
			} else {
				this.props.dispatch(extElementActions.rankUpdate(this.props.match.params.id, this.props.type, value.elementId, payload));
			}        
		};
	}

	editClicked(data) {
		return () => {
			if (this.props.isDci) {
				this.props.history.push(locations.consumable_dci + `/dci/${this.props.match.params.consumableId}/${this.props.type.toLowerCase()}/${data.elementId}`);
			} else if (this.props.typeActivity === typeActivity.RADIOTHERAPY.toUpperCase()) {
				this.props.history.push(`/radiotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}/${data.elementId}` + (utils.checkForParameter("sessionTypeId", this.props.location) ? `?sessionTypeId=${utils.checkForParameter("sessionTypeId", this.props.location)}` : ""));
			} else if (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase()) {
				this.props.history.push(`/imagery/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}/${data.elementId}`);
			} else {
				this.props.history.push(`/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}/extElements/${this.props.type.toLowerCase()}/${data.elementId}${this.props.location.search}`);
			}
		};
	}

	deleteClicked(data) {
		return () => {
			this.setState({ deletedExtElement: data, deleteVisible: true });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	hideModalDelete() {
		this.setState({ deleteVisible: false });
	}

	deleteExtElementData() {
		if (this.props.isDci) {
			this.props.dispatch(consumableActions.deleteDciPerfusionPreparation(this.props.match.params.consumableId, this.props.type, this.state.deletedExtElement.elementId));
		} else {
			const queryString = this.props.doctorId ? `doctorId=${this.props.doctorId}` : "";
			this.props.dispatch(extElementActions.deleteExtElement(this.props.match.params.id, this.props.type, this.state.deletedExtElement.elementId, queryString));
		}
		this.setState({ deleteVisible: false, deletedExtElement: {} });
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "offset=0&numResults=1000&";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		if (this.props.isDci) {
			this.props.dispatch(consumableActions.fetchAllPreparationPerfusion(this.props.match.params.consumableId, this.props.type, queryString));
		} else {
			queryString += this.props.doctorId ? `doctorId=${this.props.doctorId}` : "";
			this.props.dispatch(extElementActions.fetchAllExtElements(this.props.match.params.id, this.state.type, queryString));
		}
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	doctorChecked(id) {
		return () => {
			const prevState = this.state;
			if (prevState.checkedList.indexOf(id) !== -1) {
				prevState.checkedList.splice(prevState.checkedList.indexOf(id), 1);
			} else {
				prevState.checkedList.push(id);
			}
			this.setState(prevState);
		};
	}

	getSubTotal(totalList) {
		if (totalList && totalList.length) {
			const index = totalList.findIndex(total => { return total.type === this.props.type; });
			return (index !== -1) ? totalList[index].total : 0;
		}
		return 0;
	}

	renderDoctorList() {
		if (this.state.doctorList.length > 0) {
			return this.state.doctorList.map(doctor => {                
				return (
					<div key="doctor_list_checkbox" className="margin-right-25 doctor_list_checkbox">
						<Checkbox checked={(this.state.checkedList.indexOf(doctor) !== -1)} onChange={this.doctorChecked(doctor).bind(this)}>{doctor.fullName}</Checkbox>
					</div>
				);    
			});
		}
	}

	buildCheckList() {        
		if (this.state.doctorList.length > 0) {
			return this.state.doctorList.map(doctor => {   
				this.state.alreadyAssignedDoctorList.map(assignDoc => {
					if (doctor.id === assignDoc.id) {
						if (this.state.checkedList.indexOf(doctor) === -1) {
							this.state.checkedList.push(doctor);
						}
					}  
				});                
			});
		}
	}

	getList() {
		return (
			<ReactTable
				data={this.state.extElementsList}
				loading={this.state.loading}
				LoadingComponent={() => {return (<Loader loading={this.state.loading} isTable={true}/>);}}
				multiSort={false}
				defaultPageSize={this.state.totalCount ? this.state.totalCount : 3}
				manual
				onFetchData={this.onFetchData.bind(this)}
				columns={this.getCols()}
				className="-highlight report_react_table rt-body-x_scroll-hidden"
				showPagination={false}
				showPageSizeOptions ={false}
				onPageChange={this.onPageChange.bind(this)}
				noDataText={this.state.loading ? "" : i18Get(`NO ${this.props.type.replace(/_/g, " ")} FOUND`, utils.getLanguage())}
				getTrProps={this.props.doctorId ? this.getTrProps : () => ({})}/>
		);
	}

	getTrProps = (state, rowInfo) => {
		if (rowInfo) {
			return {
				style: {
					background: (rowInfo.original.isDefault) ? "rgb(246, 246, 247)" : "white",
					color: (rowInfo.original.isDefault) ? "rgb(169, 165, 165, 1)" : "#000000"
				}
			};
		}
		return {};
	};

	render() {
		if (this.state.type !== this.props.type) {
			this.state.type = this.props.type;
			this.state.extElementsList = [];
			this.state.totalCount = 0;
			this.state.subTotal = 0;
			this.state.loading = true;
			this.fetchDataFromServer();
		}
		if (this.props.extElements.isFetchAllExtElementDataCompleted === "FULFILLED") {
			this.state.loading = false;
			this.state.extElementsList = this.props.extElements.extElementList.extentedElement;
			this.state.totalCount = this.props.extElements.extElementList.count;
			this.state.subTotal = this.props.extElements.extElementList.subTotal;
			this.props.dispatch(extElementActions.clearFetchAllExtElements());
			this.state.rank = utils.calculateMinMaxRank(this.state.extElementsList);
		}
		if (this.props.consumable.fetchAllPreparationPerfusionComplete === "FULFILLED") {
			this.state.loading = false;
			this.state.extElementsList = this.props.consumable.elements[this.props.consumable.type].mappingData;
			this.state.totalCount = this.props.consumable.elements[this.props.consumable.type].mappingCount;
			this.state.subTotal = this.props.consumable.elements[this.props.consumable.type].total;
			this.props.dispatch(consumableActions.clearFetchAllPreparationPerfusion());
			this.state.rank = utils.calculateMinMaxRank(this.state.extElementsList);   
		}
		if (this.props.extElements.isExtElementDeleteComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch(extElementActions.clearDeleteExtElement());
		}
		if (this.props.consumable.deleteDciPerfusionPreparationComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch(consumableActions.clearDeleteDciPerfusionPreparation());
		}
		if (this.props.extElements.isExtElementUpdateComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(extElementActions.clearUpdateExtElement());
		}
		if (this.props.extElements.isRankUpdateComplete === "FULFILLED") {
			this.state.loading = true;
			this.state.disableRankUpdate = false;
			this.fetchDataFromServer();
			this.props.dispatch(extElementActions.clearRankUpdate());
		}
		if (this.props.extElements.isRankUpdateComplete === "REJECTED") {
			this.state.loading = true;
			this.state.disableRankUpdate = false;
			this.props.dispatch(extElementActions.clearRankUpdate());
		}
		if (this.props.consumable.updateRankComplete === "FULFILLED") {
			this.state.loading = true;
			this.state.disableRankUpdate = false;
			this.fetchDataFromServer();
			this.props.dispatch(consumableActions.clearPreparationPerfusionRankUpdate());
		}
		if (this.props.consumable.updateRankComplete === "REJECTED") {
			this.state.loading = true;
			this.state.disableRankUpdate = false;
			this.props.dispatch(consumableActions.clearPreparationPerfusionRankUpdate());
		}
		if (this.props.extElements.isFetchAllDoctorsOfExtElementComplete === "FULFILLED") {
			this.state.doctorList = this.props.extElements.doctorList.data;
			this.state.alreadyAssignedDoctorList = this.props.extElements.doctorList.alreadyAssigned;
			this.buildCheckList();
			this.props.dispatch(extElementActions.clearFetchAllDoctorsOfExtElement());
		}
		if (this.props.extElements.isUpdateDoctorsOfExtElementComplete === "FULFILLED") {
			this.state.extElementAssignDoctor = false;
			this.fetchDataFromServer();
			this.props.dispatch(extElementActions.clearUpdateDoctorsOfExtelement());
		}

		return (            
			<div> 
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get(`Delete ${this.props.type.toLowerCase().replace(/_/g, " ")}`, utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteExtElementData.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedExtElement.elementName}</span> ?
					</div>
				</Modal>
				<Modal
					className="re_assign_modal"
					title={i18Get("Assign other doctors too", utils.getLanguage())}
					visible={this.state.extElementAssignDoctor}
					onCancel={this.hideModalAssignDoctor.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.assignExtElementToDoctor.bind(this)}>
					<div>
						{this.renderDoctorList()}
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApprovePopup
							refreshCompleted={this.refreshCompleted}
							elementId={this.state.elementId}
							exType={this.state.type}
							type = {this.state.elementType}
							showAssignAndApprove={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

ExtElementsList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extElements: PropTypes.object.isRequired,
	radiotherapy: PropTypes.object.isRequired,
	consumable: PropTypes.object.isRequired,
	imagery: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	extElements: store.extElements,
	radiotherapy: store.radiotherapy,
	consumable: store.consumable,
	imagery: store.imagery,
	translations: store.translations
}))(ExtElementsList));