import React from "react";
import "antd/dist/antd.min.css";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import { Collapse, Button, Checkbox } from "antd";
import utils from "../../../../../../../../utils/utils";
import "./ext-elements.css";
import ClinicalPathwayEditModel from "./edit-ext-clinical-pathway/index";
import ClinicalPathwayCreateModel from "./create-ext-clinical-pathway/index";
import ClinicalPathwayDeleteModel from "./delete-ext-clinical-pathway/index";
import DownloadFile from "./downloadfile";
import events from "../../../../../../../../actions/events";
import extClinicalPathwayActions from "../../../../../../../../actions/ext-clinical-pathway";
import SubStepEdit from "./sub-step-edit";
import CopySubStepModel from "./copy-substep";
import locations from "../../../../../../../../router/locations";
import QuickReorder from "./quick-reorder";
import ItemsList from "./ItemsList";
import isPermissionGranted from "../../../../../../../../utils/permission";
import { EditOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
class ExtClinicalPathway extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			subSteps: [],
			steps: "",
			editId: "",
			loading: false,
			disableSave: false,
			editModel: false,
			createModel: false,
			selectedClpForEdit: "",
			selectedColumn: "",
			selectedFields: {},
			reOrderId: "",
			reOrderList: [],
			isSaveNextOptionClicked: false,
			title: "<div style=\"width:200px; height:100px;\"></div>",
			content: "",
			popoverHeight: 220,
			enableQuickProblemListReorder: false,
			filteredCheckList: [],
			expandList: [],
			expandListitem: []
		};
		this.itemRef = React.createRef();
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.MAPPING_DATA, payload: { value: this.props.steps } });
		if (localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST")) {
			const selectedColumn = JSON.parse(localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST"));
			if (selectedColumn && selectedColumn.check) {
				this.setState({ selectedColumn: selectedColumn.check });
			}
		}
	}

	getPannelHeader = (step) => {
		return (
			this.props.search && this.props.searchType ?
				<span><I18 tkey={step.name} /> {step.startPrefix !== "" ? this.getSuffix(step) : ""}{" (" + step.stepItemsCount} <I18 tkey={"Items"} /> <I18 tkey={"Total"} /> {step.stepItemTotalCount} {")"}</span>
				: <span><I18 tkey={step.name} /> {step.startPrefix !== "" ? this.getSuffix(step) : ""}{" (" + step.stepItemsCount} <I18 tkey={"Items"} />{")"}</span>
		);
	};

	expandClicked = (subStep, item) => {
		const prevState = this.state;
		const index = subStep.data.findIndex(e => e.id === item.id);
		if (index >= 0) {
			prevState.steps && prevState.steps.map((data) => {
				data.subSteps.map((e) => {
					if (e.id === subStep.id && e.day === subStep.day) {
						let checklist = [];
						checklist = this.removeDuplicates(prevState.filteredCheckList.filter(value => value.clpSubStepId === subStep.id && value.day === item.day && value.checkListTreatmentId === item.checkListTreatmentId && value.isExpand !== true));
						checklist.map((e) => { e["isExpand"] = true; });
						prevState.filteredCheckList = prevState.filteredCheckList.filter(value => (checklist.indexOf(value) === -1));
						e.data.splice((index + 1), 0, ...checklist);
					}
				});
			});
			prevState.expandList.push(item.id);
			this.setState(prevState);
		}
	};

	removeDuplicates = (objArray) => {
		const uniqueArray = objArray.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
		return uniqueArray;
	};

	collapseClicked = (subStep, item) => {
		const prevState = this.state;
		const index = subStep.data.findIndex(e => e.id === item.id);
		if (index >= 0) {
			prevState.steps && prevState.steps.map((data) => {
				data.subSteps.map((e) => {
					if (e.id === subStep.id && e.day === subStep.day) {
						e.data.map((data) => {
							if (data.clpSubStepId === subStep.id && data.day === item.day && data.checkListTreatmentId === item.checkListTreatmentId && !data.isHeading) {
								data["isExpand"] = false;
							}
						});
					}
				});
			});
			const index = prevState.expandList.indexOf(item.id);
			prevState.expandList.splice(index, 1);
			this.setState(prevState);
		}
	};

	getSubStepPannelHeader = (subStep) => {
		this.state.filteredCheckList = this.state.filteredCheckList.concat(subStep.data.filter(value => value.isCheckList && !value.isHeading && !value.isExpand));
		subStep.data = subStep.data.filter(value => !value.isCheckList || value.isHeading || value.isExpand);
		return (
			<div className="d-flex justify-content-between collapse_head">
				<div className="d-flex">
					<div className="d-flex">
						{this.state.bulkDeleteStepId && this.state.bulkDeleteStepId === subStep.id && subStep.day === this.state.day && subStep.data.find(item => !item.isCheckList) &&
							<div>
								<Checkbox
									indeterminate={!this.props.checkedAll && (this.props.checkList.length > 0 && this.props.checkList.length < subStep.data.length)}
									checked={this.props.checkedAll || (this.props.checkList.length == subStep.data.length && this.props.checkList.length > 0)}
									onClick={event => event.stopPropagation()}
									className="margin-right-5"
									onChange={(e) => this.props.onSubStepCheckBoxChange(e, subStep)} />
							</div>
						}
						{subStep && subStep.day ?
							this.props.search && this.props.searchType ?
								<div className={` ${subStep.itemCount && subStep.itemCount > 0 ? "subStep_heading" : ""}`}>
									<span className="margin-left-10"><I18 tkey="DAY" /> <I18 tkey={subStep.name} />{" (" + subStep.itemCount} <I18 tkey={"Items"} /> <I18 tkey={"Total"} /> {subStep.itemTotalCount} {")"}</span>
								</div>
								:
								<span className="margin-left-10"><I18 tkey="DAY" /> <I18 tkey={subStep.name} />{" (" + subStep.itemCount} <I18 tkey={"Items"} />{")"}</span>
							:
							this.props.search && this.props.searchType ?
								<div className={` ${subStep.itemCount && subStep.itemCount > 0 ? "subStep_heading" : ""}`}>
									<span><I18 tkey={subStep.name} />{" (" + subStep.itemCount} <I18 tkey={"Items"} /> <I18 tkey={"Total"} /> {subStep.itemTotalCount} {")"}</span>
								</div>
								:
								<span><I18 tkey={subStep.name} />{" (" + subStep.itemCount} <I18 tkey={"Items"} />{")"}</span>
						}
					</div>
					{subStep && subStep.subStepNameId && !this.props.treatmentId && this.isPermissionGranted("EDIT") ?
						<div className="margin-left-10">
							<EditOutlined  className="edit_color " type="edit" onClick={this.subStepEditClicked(subStep).bind(this)} />
						</div>
						: ""}
				</div>
			</div>
		);
	};

	getSuffix(step) {
		if (step.startPrefix !== step.endPrefix) {
			return <span>(<I18 tkey="DAY" /> <I18 tkey={step.startPrefix} /> {"->"} <I18 tkey="DAY" /> <I18 tkey={step.endPrefix} />): <I18 tkey={step.totalPrefixCount} /> <I18 tkey="DAYS" /> </span>;
		} else {
			return <span>(<I18 tkey="DAY" /> <I18 tkey={step.startPrefix} />)</span>;
		}
	}

	renderItemInput(value) {
		return (
			<div className="w_100 di textarea_quick_edit">
				<textarea
					className="form-control w_100"
					defaultValue={value}
					maxLength={255}
					ref={this.itemRef} />
			</div>
		);
	}

	subStepDetails(subStep, step, index) {
		return (
			<div className="">
				<div className=""><div></div>
					<div>
						<Collapse defaultActiveKey={["0"]} ghost={true} >
							<Panel header={this.getSubStepPannelHeader(subStep)} key={index} className="collapse_sub">
								{this.isPermissionGranted("READ") &&
									<div className="btn_container_absolute">
										{!this.state.bulkDeleteStepId && subStep.itemCount !== 0 ?
											<div className="margin-right-10">
												<Button
													onClick={this.enableBulkDelete(subStep, step.id).bind(this)}
													disabled={!this.isPermissionGranted("DELETE")}>
													<span className="margin-left-10" >
														<I18 tkey="Enable Bulk Delete" />
													</span>
												</Button>
											</div> : ""}
										{!this.props.clinicalPathwayType.isCheckList && !this.state.bulkDeleteStepId && !this.props.treatmentId && subStep.itemCount !== 0 &&
											<div className="margin-right-10">
												<Button
													onClick={this.onClickSubStepCopy(step, subStep).bind(this)}
													disabled={!this.isPermissionGranted("EDIT")}>
													<span className="margin-left-10" >
														<I18 tkey="Sub Step Copy" />
													</span>
												</Button>
											</div>}
										{!this.state.bulkDeleteStepId && subStep.itemCount !== 0 ?
											<div className="margin-right-10">
												<Button type="button" className="margin-right-10"
													loading={this.state.loading}
													onClick={() => this.onQuickReorderData(subStep, step)}
													disabled={!this.isPermissionGranted("EDIT")} >
													<I18 tkey="Reorder Data" />&nbsp;
												</Button>
											</div> : ""}
										{!this.state.bulkDeleteStepId &&
											<div className="">
												<Button
													onClick={this.createClicked(subStep, step.id).bind(this)}
													disabled={!this.isPermissionGranted("CREATE")}><I18 tkey="Add" />
												</Button>
											</div>}
										{this.state.bulkDeleteStepId === subStep.id && this.state.day === subStep.day ?
											<div className="">
												<Button className="margin-right-10"
													onClick={this.cancelClicked.bind(this)}><I18 tkey="Cancel" />
												</Button>
												<Button
													loading={this.state.loading}
													onClick={this.onClickBulkDelete(step, subStep).bind(this)}><I18 tkey="Delete Selected" />
												</Button>
											</div> : ""}
									</div>}
								<div className={this.props.fullScreen ? "fullscreen_sub_step_container" : "sub_step_container"}>
									<ItemsList
										subStep={subStep}
										step={step}
										index={index}
										disableSave={this.state.disableSave}
										editId={this.state.editId}
										bulkDeleteStepId={this.state.bulkDeleteStepId}
										expandList={this.state.expandList}
										checkList={this.props.checkList}
										selectedColumn={this.state.selectedColumn}
										selectedFields={this.state.selectedFields}
										day={this.state.day}
										renderItemInput={this.renderItemInput.bind(this)}
										onAttachmentClick={this.onAttachmentClick}
										editClicked={this.editClicked.bind(this)}
										deleteClicked={this.deleteClicked.bind(this)}
										quickEditClicked={this.quickEditClicked.bind(this)}
										saveChanges={this.saveChanges.bind(this)}
										revertEdit={this.revertEdit.bind(this)}
										collapseClicked={this.collapseClicked}
										expandClicked={this.expandClicked}
										clinicalPathway={this.props.clinicalPathway}
										onCellCheckBoxChange={this.props.onCellCheckBoxChange} />
								</div>
							</Panel>
						</Collapse>
					</div>
				</div>
			</div>);
	}

	cancelClicked(isButtonClick) {
		if (this.state.isSaveNextOptionClicked && !isButtonClick) { return; }
		this.setState({
			editModel: false, createModel: false, deleteModel: false, attachmentModel: false, subStepEditModel: false,
			selectedClpForEdit: "", selectedClpForCreate: "", selectedClpForDelete: "", fileData: "", fileType: "", selectedSubStepForEdit: "", editId: utils.sameDropper(),
			reOrderId: null, day: null, enableDrag: false, reOrderList: [], selectedClpSubStep: null, selectedSubStepForCopy: null, subStepCopyModel: false,
			bulkDeleteStepId: null, selectedSubStepForBulkDelete: null
		});
		this.props.clearCheckList();
	}

	revertEdit() {
		const prevState = this.state;
		prevState.editId = utils.sameDropper();
		prevState.selectedFields = {};
		this.setState(prevState);
	}

	quickEditClicked(value) {
		return () => {
			const prevState = this.state;
			prevState.editId = value.id;
			prevState.selectedFields = value;
			this.setState(prevState);
		};
	}

	validate() {
		if (this.state.selectedFields && this.state.selectedFields.isItemOnly) {
			return !(!this.itemRef.current.value || !this.itemRef.current.value.trim() || (this.itemRef.current.value.match(/\\$/)) || this.itemRef.current.value.indexOf("\"") >= 0);
		}
		return !(!this.itemRef.current.value || !this.itemRef.current.value.trim() || (this.itemRef.current.value.match(/\\$/)) || this.itemRef.current.value.indexOf("\"") >= 0);
	}

	saveChanges() {
		return () => {
			if (this.validate()) {
				if (!utils.checkNameRegex(this.itemRef.current.value.trim())) {
					utils.showError(i18Get("Special characters not allowed except ( ) + _ - ' ,.", utils.getLanguage()));
					return false;
				}
				const payload = {
					item: this.itemRef.current.value.trim().charAt(0).toUpperCase() + this.itemRef.current.value.trim().slice(1)
				};
				if (!this.state.selectedFields.isItemOnly) {
					this.props.dispatch(extClinicalPathwayActions.extClinicalPathwayQuickEdit(this.props.match.params.id, this.state.selectedFields.id, payload));
				} else {
					this.props.dispatch(extClinicalPathwayActions.extClpItemOnlyQuickEdit(this.props.match.params.id, this.state.selectedFields.id, payload));
				}
			} else {
				utils.showError(i18Get("Enter valid Data", utils.getLanguage()));
			}
		};
	}

	editClicked(data) {
		return (e) => {
			e.preventDefault();
			this.setState({ editModel: true, selectedClpForEdit: data });
		};
	}

	deleteClicked(data, stepId) {
		return (e) => {
			e.preventDefault();
			data.stepId = stepId;
			this.setState({ deleteModel: true, selectedClpForDelete: data });
		};
	}

	createClicked(data, stepId) {
		return (e) => {
			e.preventDefault();
			data.stepId = stepId;
			this.setState({ createModel: true, selectedClpForCreate: data });
		};
	}

	onClickReorder(data) {
		return () => {
			this.cancelClicked();
			this.setState({ reOrderId: data.id, day: data.day, enableDrag: true, selectedClpSubStep: data, reOrderList: data.data });
		};
	}

	enableBulkDelete(data, stepId) {
		return (e) => {
			e.preventDefault();
			this.cancelClicked();
			data.stepId = stepId;
			this.setState({ bulkDeleteStepId: data.id, day: data.day, selectedSubStepForBulkDelete: data });
		};
	}

	onClickCancelReorder(data) {
		return (e) => {
			e.preventDefault();
			this.refreshExtClinicalPathwayList(data.day, data.id);
			this.setState({ reOrderId: null, day: null, enableDrag: false, reOrderList: [], selectedClpSubStep: "" });
		};
	}

	onClickSaveOrder(step, subStep) {
		return () => {
			const newData = JSON.parse(JSON.stringify(this.state.reOrderList));
			const selectedData = [];
			newData.map(function (data, index) {
				selectedData.push({ id: data.id, rank: index + 1 });
			});
			if (selectedData && selectedData.length > 0) {
				this.setState({ loading: true });
				this.props.dispatch({ type: events.ACTION_ONE });
				this.props.dispatch(extClinicalPathwayActions.extClpRankUpdation(this.props.match.params.id, step.id, subStep.id, subStep.day, selectedData));
			} else {
				this.cancelClicked();
			}
		};
	}

	onClickBulkDelete() {
		return () => {
			const checkList = JSON.parse(JSON.stringify(this.props.checkList));
			if (checkList && checkList.length > 0) {
				this.setState({ loading: true });
				this.props.dispatch({ type: events.ACTION_ONE });
				const payload = {
					mappingIds: checkList
				};
				this.props.dispatch(extClinicalPathwayActions.bulkDeleteOfClpItems(this.props.match.params.id, payload));
			} else {
				utils.showError(i18Get("Select Items", utils.getLanguage()));
			}
		};
	}

	subStepEditClicked(data) {
		return (e) => {
			e.preventDefault();
			e.stopPropagation();
			this.setState({ subStepEditModel: true, selectedSubStepForEdit: data });
		};
	}

	onAttachmentClick = (data, type) => {
		this.setState({ attachmentModel: true, fileData: data, fileType: type });
	};

	onClickItem = (data) => {
		this.props.history.push(`${locations.clinical_pathway_plain}/${this.props.match.params.organization}/${this.props.match.params.id}${data.clpItemId ? `?position=${190297}` : ""}`);
		this.props.tabChanged("clpProblem");
	};

	duplicateClicked(data, stepId) {
		return () => {
			const prevState = this.state;
			data.stepId = stepId;
			prevState.selectedFields = data;
			if (data.isItemOnly) {
				this.props.dispatch(extClinicalPathwayActions.duplicateItemMappingOnly(this.props.match.params.id, data.id));
			} else {
				this.props.dispatch(extClinicalPathwayActions.duplicateMapping(this.props.match.params.id, data.id));
			}
			this.setState(prevState);
		};
	}

	onClickSubStepCopy(step, subStep) {
		return (e) => {
			e.preventDefault();
			e.stopPropagation();
			subStep.stepId = step.id;
			subStep.stepName = step.name;
			subStep.startPrefix = step.startPrefix !== "" ? step.startPrefix : "";
			subStep.endPrefix = step.endPrefix ? step.endPrefix : "";
			subStep.totalPrefixCount = step.totalPrefixCount;
			this.setState({ subStepCopyModel: true, selectedSubStepForCopy: subStep });
		};
	}

	refreshExtClinicalPathwayList(day, clpSubStepId, saveNext) {
		this.state.isSaveNextOptionClicked = saveNext;
		let queryString = "offset=0&numResults=1000";
		queryString += this.props.search ? `&value=${this.props.search}` : "";
		queryString += this.props.searchField && this.props.searchField !== "SEARCH_ALL_FIELDS" ? `&field=${this.props.searchField}` : "";
		queryString += this.props.searchType ? `&mode=${this.props.searchType}` : "";
		queryString += day ? `&days=${day}` : "";
		this.props.dispatch(extClinicalPathwayActions.fetchAllExtClinicalPathway(this.props.match.params.id, clpSubStepId, day ? day : null, queryString));
	}

	refreshDetails(subStepId, day, subStepName) {
		const prevState = this.state;
		prevState.steps && prevState.steps.map(function (data) {
			data.subSteps.map(function (e) {
				if (e.id === subStepId && e.day === day) {
					e.name = subStepName;
				}
			});
		});
		this.props.dispatch({ type: events.MAPPING_DATA, payload: { value: prevState.steps } });
		this.cancelClicked();
	}

	getStepItemCount(stepId) {
		let queryString = "";
		queryString += this.props.search ? `value=${this.props.search}` : "";
		queryString += this.props.searchField && this.props.searchField !== "SEARCH_ALL_FIELDS" ? `&field=${this.props.searchField}` : "";
		queryString += this.props.searchType ? `&mode=${this.props.searchType}` : "";
		this.props.dispatch(extClinicalPathwayActions.getStepItemCount(this.props.match.params.id, stepId, queryString));
	}

	hideEmptySteps() {
		const prevState = this.state;
		const steps = prevState.steps.filter(step => step.stepItemsCount > 0);
		return (this.getStepDetails(steps, this.props.hideSteps));
	}

	getStepDetails(steps, hideSteps) {
		return (
			<Collapse ghost={true} >
				{steps && steps.map((step, index) => <Panel header={this.getPannelHeader(step)} key={index} className={this.props.search && this.props.searchType && step.stepItemsCount && step.stepItemsCount > 0 ? "collaps_data" : ""}>
					<div>
						<div className="ant-collapse-container">
							{step.subSteps && step.subSteps.length ?
								step.subSteps.map((subStep, index) => {
									if (!hideSteps) {
										return (this.subStepDetails(subStep, step, index));
									} else if (subStep && subStep.itemCount && subStep.itemCount > 0) {
										return (this.subStepDetails(subStep, step, index));
									}
								}) : ""}
						</div>
					</div>
				</Panel>)}
			</Collapse>);
	}

	disableProblemListReorder = () => {
		this.setState({ enableQuickProblemListReorder: false });
	};

	onQuickReorderData = (subStep, steps) => {
		this.setState({
			enableQuickProblemListReorder: true,
			quickReorderDataSteps: steps,
			selectedClpSubStep: subStep
		});
	};

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.isReadOnly, method);
	};

	render() {
		if (this.props.clinicalPathwayDetails.mappingData) {
			this.state.steps = this.props.clinicalPathwayDetails.mappingData;
			this.props.dispatch({ type: events.CLEAR_MAPPING_DATA, payload: {} });
		}

		if (this.props.clinicalPathwayDetails.selectedColumn) {
			this.state.selectedColumn = this.props.clinicalPathwayDetails.selectedColumn ? this.props.clinicalPathwayDetails.selectedColumn.check : "";
			this.props.dispatch({ type: events.CLEAR_SELECTED_COLUMN, payload: {} });
		}

		if (this.props.extClinicalPathway.isFetchAllExtClinicalPathwayDataCompleted === "FULFILLED") {
			if (this.props.extClinicalPathway.fetchAllExtClinicalPathwayMeta) {
				this.cancelClicked();
				const prevState = this.state;
				const newData = this.props.extClinicalPathway.extClinicalPathwayList.mapping;
				const count = this.props.extClinicalPathway.extClinicalPathwayList.count ? this.props.extClinicalPathway.extClinicalPathwayList.count : 0;
				const itemTotalCount = this.props.extClinicalPathway.extClinicalPathwayList.itemTotalCount ? this.props.extClinicalPathway.extClinicalPathwayList.itemTotalCount : 0;
				const subStepId = this.props.extClinicalPathway.fetchAllExtClinicalPathwayMeta.subStepId;
				const day = this.props.extClinicalPathway.fetchAllExtClinicalPathwayMeta.day;
				prevState.steps && prevState.steps.map(function (data) {
					data.subSteps.map(function (e) {
						if (e.id === subStepId && e.day === day) {
							e.itemCount = count;
							e.itemTotalCount = itemTotalCount;
							e.data.length = 0;
							e.data.push.apply(e.data, newData);
						}
					});
				});
				this.props.dispatch({ type: events.MAPPING_DATA, payload: { value: prevState.steps } });
			}
			this.props.dispatch(extClinicalPathwayActions.clearFetchAllExtClinicalPathway());
		}

		if (this.props.extClinicalPathway.extClinicalPathwayQuickEditComplete === "FULFILLED") {
			this.state.disableSave = false;
			this.props.dispatch(extClinicalPathwayActions.clearExtClinicalPathwayQuickEdit());
			this.refreshExtClinicalPathwayList(this.state.selectedFields.day, this.state.selectedFields.clpSubStepId);
		}

		if (this.props.extClinicalPathway.extClpItemOnlyQuickEditComplete === "FULFILLED") {
			this.state.disableSave = false;
			this.props.dispatch(extClinicalPathwayActions.clearExtClpItemOnlyQuickEdit());
			this.refreshExtClinicalPathwayList(this.state.selectedFields.day, this.state.selectedFields.clpSubStepId);
		}

		if (this.props.extClinicalPathway.extClpRankUpdateComplete === "FULFILLED") {
			this.disableProblemListReorder();
			this.setState({ loading: false });
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(extClinicalPathwayActions.extClpRankUpdationCompleted());
			this.refreshExtClinicalPathwayList(this.state.selectedClpSubStep.day, this.state.selectedClpSubStep.id);
		}

		if (this.props.extClinicalPathway.extClpRankUpdateComplete === "REJECTED") {
			this.setState({ loading: false });
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(extClinicalPathwayActions.extClpRankUpdationCompleted());
			this.refreshExtClinicalPathwayList(this.state.selectedClpSubStep.day, this.state.selectedClpSubStep.id);
		}

		if (this.props.extClinicalPathway.isDuplicateItemMappingStatus === "FULFILLED") {
			this.refreshExtClinicalPathwayList(this.state.selectedFields.day, this.state.selectedFields.clpSubStepId);
			this.getStepItemCount(this.state.selectedFields.stepId);
			this.props.dispatch(extClinicalPathwayActions.clearDuplicateItemMappingOnly());
		}

		if (this.props.extClinicalPathway.isDuplicateMappingStatus === "FULFILLED") {
			this.refreshExtClinicalPathwayList(this.state.selectedFields.day, this.state.selectedFields.clpSubStepId);
			this.getStepItemCount(this.state.selectedFields.stepId);
			this.props.dispatch(extClinicalPathwayActions.clearDuplicateMapping());
		}

		if (this.props.extClinicalPathway.getStepItemCount === "FULFILLED") {
			const stepDetails = this.props.extClinicalPathway.stepDetails ? this.props.extClinicalPathway.stepDetails : "";
			const index = this.state.steps && this.state.steps.findIndex(value => value.id === stepDetails.stepId);
			if (index !== -1) {
				this.state.steps[index].stepItemsCount = stepDetails.count;
				this.state.steps[index].stepItemTotalCount = stepDetails.stepItemTotalCount;
			}
			this.props.dispatch(extClinicalPathwayActions.getStepItemCountCompleted());
		}

		if (this.props.extClinicalPathway.bulkDeleteOfClpItems === "FULFILLED") {
			this.setState({ loading: false });
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.refreshExtClinicalPathwayList(this.state.selectedSubStepForBulkDelete.day, this.state.selectedSubStepForBulkDelete.id);
			this.getStepItemCount(this.state.selectedSubStepForBulkDelete.stepId);
			this.props.dispatch(extClinicalPathwayActions.bulkDeleteOfClpItemsCompleted());
		}

		if (this.props.extClinicalPathway.bulkDeleteOfClpItems === "REJECTED") {
			this.setState({ loading: false });
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.cancelClicked();
			this.props.dispatch(extClinicalPathwayActions.bulkDeleteOfClpItemsCompleted());
		}

		return (
			<div className='position-relative'>
				{this.props.hideSteps && this.state.steps ?
					this.hideEmptySteps() : this.state.steps ? this.getStepDetails(this.state.steps) : ""}
				{this.state.editModel &&
					<div className="position_relative">
						<ClinicalPathwayEditModel
							cancelClicked={this.cancelClicked.bind(this)}
							refreshExtClinicalPathwayList={this.refreshExtClinicalPathwayList.bind(this)}
							selectedClpForEdit={this.state.selectedClpForEdit}
							visible={this.state.editModel} />
					</div>}
				{this.state.attachmentModel &&
					<DownloadFile
						data={this.state.fileData}
						fileType={this.state.fileType}
						cancelClicked={this.cancelClicked.bind(this)}
						visible={this.state.attachmentModel}
					/>}
				{this.state.createModel &&
					<div className="position_relative">
						<ClinicalPathwayCreateModel
							cancelClicked={this.cancelClicked.bind(this)}
							refreshExtClinicalPathwayList={this.refreshExtClinicalPathwayList.bind(this)}
							getStepItemCount={this.getStepItemCount.bind(this)}
							selectedClpForCreate={this.state.selectedClpForCreate}
							visible={this.state.createModel}
						/>
					</div>}
				{this.state.subStepCopyModel ?
					<div className="position_relative">
						<CopySubStepModel
							refreshClinicalPathwayDetail={this.props.refreshClinicalPathwayDetail}
							selectedSubStepForCopy={this.state.selectedSubStepForCopy}
							cancelClicked={this.cancelClicked.bind(this)}
							visible={this.state.subStepCopyModel}
						/></div> : ""}
				{this.state.deleteModel &&
					<div className="position_relative">
						<ClinicalPathwayDeleteModel
							cancelClicked={this.cancelClicked.bind(this)}
							refreshExtClinicalPathwayList={this.refreshExtClinicalPathwayList.bind(this)}
							getStepItemCount={this.getStepItemCount.bind(this)}
							selectedClpForDelete={this.state.selectedClpForDelete}
							visible={this.state.deleteModel}
						/>
					</div>}
				{this.state.subStepEditModel &&
					<div className="position_relative">
						<SubStepEdit
							cancelClicked={this.cancelClicked.bind(this)}
							refreshDetails={this.refreshDetails.bind(this)}
							selectedSubStepForEdit={this.state.selectedSubStepForEdit}
							visible={this.state.subStepEditModel}
						/>
					</div>}
				{this.state.enableQuickProblemListReorder &&
					<QuickReorder
						enableQuickProblemListReorder={this.state.enableQuickProblemListReorder}
						disableProblemListReorder={this.disableProblemListReorder}
						quickReorderData={this.state.selectedClpSubStep}
						quickReorderDataSteps={this.state.quickReorderDataSteps}
					/>
				}
			</div>
		);
	}
}

ExtClinicalPathway.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	extClinicalPathway: store.extClinicalPathway,
	translations: store.translations,
	permissions: store.permissions
}))(ExtClinicalPathway));