export default {
	GET_ALL_ROLES_LIST: "GET_ALL_ROLES_LIST",
	CLEAR_GET_ALL_ROLES_LIST: "CLEAR_GET_ALL_ROLES_LIST",
	CREATE_ROLES: "CREATE_ROLES",
	CLEAR_CREATE_ROLES: "CLEAR_CREATE_ROLES",
	GET_ONE_ROLE: "GET_ONE_ROLE",
	CLEAR_GET_ONE_ROLE: "CLEAR_GET_ONE_ROLE",
	UPDATE_USER_ROLES: "UPDATE_USER_ROLES",
	CLEAR_UPDATE_USER_ROLES: "CLEAR_UPDATE_USER_ROLES",
	DELETE_ROLES: "DELETE_ROLES",
	CLEAR_DELETE_ROLES: "CLEAR_DELETE_ROLES"
};