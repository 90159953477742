import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllConsumableDefinitionCompleted: false,
	fetchAllConsumableDefinitionMeta: null,
	consumableDefinitionList: [],
	isFetchOneConsumableDefinitionCompleted: false,
	selectedConsumableDefinition: {},
	isUpdateConsumableDefinitionCompleted: false,
	updatedConsumableDefinition: {},
	isDeleteConsumableDefinitionCompleted: false,
	isDeleteSuccess: {},
	isFetchAllConsumableDciCompleted: false,
	consumableDciList: [],
	isFetchOneConsumableDciCompleted: false,
	selectedConsumableDci: {},
	isUpdateConsumableDciCompleted: false,
	updatedConsumableDci: {},
	isDeleteConsumableDciCompleted: false,
	dciList: [],
	fetchDciListComplete: false,
	updateDciElementComplete: false,
	fetchAllPreparationPerfusionComplete: false,
	elements: {},
	dciDetails: {},
	getOneDciComplete: false,
	createDciPerfusionPreparationComplete: false,
	deleteDciPerfusionPreparationComplete: false,
	dciElementList: {},
	fetchAllElementsForDciComplete: false,
	preparationPerfusionDetails: {},
	getOnePreparationPerfusionComplete: false,
	updatePreparationPerfusionComplete: false,
	updateRankComplete: false,
	fetchOneConsumableClassComplete: false,
	consumableClass: {},
	updateConsumableClassComplete: false,
	filterChangedConsumableClass: null,
	ignorePageChangeConsumableClass: false,
	updateConsummableDefinitionMinimalComplete: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_CONSUMABLE_DEFINITION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllConsumableDefinitionCompleted: "FULFILLED",
					fetchAllConsumableDefinitionMeta: action.meta,
					consumableDefinitionList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllConsumableDefinitionCompleted: "FULFILLED",
				fetchAllConsumableDefinitionMeta: null
			};
		case `${eventNames.FETCH_ALL_CONSUMABLE_DEFINITION}_REJECTED`:
			return {
				...state,
				isFetchAllConsumableDefinitionCompleted: "REJECTED",
				fetchAllConsumableDefinitionMeta: null
			};
		case eventNames.CLEAR_FETCH_ALL_CONSUMABLE_DEFINITION:
			return {
				...state,
				isFetchAllConsumableDefinitionCompleted: false,
				fetchAllConsumableDefinitionMeta: null
			};

		case `${eventNames.FETCH_ONE_CONSUMABLE_DEFINITION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneConsumableDefinitionCompleted: "FULFILLED",
					selectedConsumableDefinition: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneConsumableDefinitionCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CONSUMABLE_DEFINITION}_REJECTED`:
			return {
				...state,
				isFetchOneConsumableDefinitionCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CONSUMABLE_DEFINITION:
			return {
				...state,
				isFetchOneConsumableDefinitionCompleted: false
			};

		case `${eventNames.UPDATE_CONSUMABLE_DEFINITION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateConsumableDefinitionCompleted: "FULFILLED",
					updatedConsumableDefinition: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateConsumableDefinitionCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CONSUMABLE_DEFINITION}_REJECTED`:
			return {
				...state,
				isUpdateConsumableDefinitionCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CONSUMABLE_DEFINITION:
			return {
				...state,
				isUpdateConsumableDefinitionCompleted: false
			};
		case `${eventNames.UPDATE_CONSUMABLE_DEFINITION_MINIMAL}_FULFILLED`:
			return {
				...state,
				updateConsummableDefinitionMinimalComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_CONSUMABLE_DEFINITION_MINIMAL}_REJECTED`:
			return {
				...state,
				updateConsummableDefinitionMinimalComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CONSUMABLE_DEFINITION_MINIMAL:
			return {
				...state,
				updateConsummableDefinitionMinimalComplete: false
			};
		case `${eventNames.DELETE_CONSUMABLE_DEFINITION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteConsumableDefinitionCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteConsumableDefinitionCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CONSUMABLE_DEFINITION}_REJECTED`:
			return {
				...state,
				isDeleteConsumableDefinitionCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CONSUMABLE_DEFINITION:
			return {
				...state,
				isDeleteConsumableDefinitionCompleted: false
			};

		case `${eventNames.FETCH_ALL_CONSUMABLE_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllConsumableDciCompleted: "FULFILLED",
					consumableDciList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllConsumableDciCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CONSUMABLE_DCI}_REJECTED`:
			return {
				...state,
				isFetchAllConsumableDciCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CONSUMABLE_DCI:
			return {
				...state,
				isFetchAllConsumableDciCompleted: false
			};
		case `${eventNames.FETCH_ONE_CONSUMABLE_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneConsumableDciCompleted: "FULFILLED",
					selectedConsumableDci: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneConsumableDciCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CONSUMABLE_DCI}_REJECTED`:
			return {
				...state,
				isFetchOneConsumableDciCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CONSUMABLE_DCI:
			return {
				...state,
				isFetchOneConsumableDciCompleted: false
			};

		case `${eventNames.UPDATE_CONSUMABLE_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateConsumableDciCompleted: "FULFILLED",
					updatedConsumableDci: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateConsumableDciCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CONSUMABLE_DCI}_REJECTED`:
			return {
				...state,
				isUpdateConsumableDciCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CONSUMABLE_DCI:
			return {
				...state,
				isUpdateConsumableDciCompleted: false
			};

		case `${eventNames.DELETE_CONSUMABLE_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteConsumableDciCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteConsumableDciCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CONSUMABLE_DCI}_REJECTED`:
			return {
				...state,
				isDeleteConsumableDciCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CONSUMABLE_DCI:
			return {
				...state,
				isDeleteConsumableDciCompleted: false
			};
		case `${eventNames.FETCH_ALL_DCI_LIST}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchDciListComplete: "FULFILLED",
					dciList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchDciListComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_DCI_LIST}_REJECTED`:
			return {
				...state,
				fetchDciListComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_DCI_LIST:
			return {
				...state,
				fetchDciListComplete: false
			};
		case `${eventNames.UPDATE_DCI_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateDciElementComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updateDciElementComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_DCI_ELEMENT}_REJECTED`:
			return {
				...state,
				updateDciElementComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_DCI_ELEMENT:
			return {
				...state,
				updateDciElementComplete: false
			};
		case `${eventNames.FETCH_ALL_DCI_PREPARATION_PERFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const elements = {};
				elements[action.meta] = action.payload.data.data;
				return {
					...state,
					elements,
					type: action.meta,
					fetchAllPreparationPerfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllPreparationPerfusionComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_DCI_PREPARATION_PERFUSION}_REJECTED`:
			return {
				...state,
				fetchAllPreparationPerfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_DCI_PREPARATION_PERFUSION:
			return {
				...state,
				fetchAllPreparationPerfusionComplete: false
			};
		case `${eventNames.GET_ONE_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dciDetails: action.payload.data.data,
					getOneDciComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getOneDciComplete: "FULFILLED"
			};
		case `${eventNames.GET_ONE_DCI}_REJECTED`:
			return {
				...state,
				getOneDciComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_DCI:
			return {
				...state,
				getOneDciComplete: false
			};
		case `${eventNames.CREATE_DCI_PERFUSION_PREPARATION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					createDciPerfusionPreparationComplete: "FULFILLED"
				};
			}
			return {
				...state,
				createDciPerfusionPreparationComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_DCI_PERFUSION_PREPARATION}_REJECTED`:
			return {
				...state,
				createDciPerfusionPreparationComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_DCI_PERFUSION_PREPARATION:
			return {
				...state,
				createDciPerfusionPreparationComplete: false
			};
		case `${eventNames.DELETE_DCI_PREPARATION_PERFUSION}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteDciPerfusionPreparationComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteDciPerfusionPreparationComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_DCI_PREPARATION_PERFUSION}_REJECTED`:
			return {
				...state,
				deleteDciPerfusionPreparationComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_DCI_PREPARATION_PERFUSION:
			return {
				...state,
				deleteDciPerfusionPreparationComplete: false
			};
		case `${eventNames.FETCH_ALL_ELEMENTS_FOR_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dciElementList: action.payload.data.data,
					fetchAllElementsForDciComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllElementsForDciComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_ELEMENTS_FOR_DCI}_REJECTED`:
			return {
				...state,
				fetchAllElementsForDciComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_ELEMENTS_FOR_DCI:
			return {
				...state,
				fetchAllElementsForDciComplete: false
			};
		case `${eventNames.GET_ONE_PREPARATION_PERFUSION_FOR_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					preparationPerfusionDetails: action.payload.data.data,
					getOnePreparationPerfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				getOnePreparationPerfusionComplete: "FULFILLED"
			};
		case `${eventNames.GET_ONE_PREPARATION_PERFUSION_FOR_DCI}_REJECTED`:
			return {
				...state,
				getOnePreparationPerfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_PREPARATION_PERFUSION_FOR_DCI:
			return {
				...state,
				getOnePreparationPerfusionComplete: false
			};
		case `${eventNames.UPDATE_PREPARATION_PERFUSION_FOR_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updatePreparationPerfusionComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updatePreparationPerfusionComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_PREPARATION_PERFUSION_FOR_DCI}_REJECTED`:
			return {
				...state,
				updatePreparationPerfusionComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_PREPARATION_PERFUSION_FOR_DCI:
			return {
				...state,
				updatePreparationPerfusionComplete: false
			};
		case `${eventNames.PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateRankComplete: "FULFILLED"
				};
			}
			return {
				...state,
				updateRankComplete: "FULFILLED"
			};
		case `${eventNames.PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI}_REJECTED`:
			return {
				...state,
				updateRankComplete: "REJECTED"
			};
		case eventNames.CLEAR_PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI:
			return {
				...state,
				updateRankComplete: false
			};
		case `${eventNames.TREATMENT_LIST_FOR_DCI}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					treatmentList: action.payload.data.data,
					treatmentListForDCIComplete: "FULFILLED"
				};
			}
			return {
				...state,
				treatmentListForDCIComplete: "FULFILLED"
			};
		case `${eventNames.TREATMENT_LIST_FOR_DCI}_REJECTED`:
			return {
				...state,
				treatmentListForDCIComplete: "REJECTED"
			};
		case eventNames.CLEAR_TREATMENT_LIST_FOR_DCI:
			return {
				...state,
				treatmentListForDCIComplete: false
			};
		case `${eventNames.FETCH_ALL_DCI_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					dciElementList: action.payload.data.data,
					fetchDciElementListComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchDciElementListComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_DCI_ELEMENT}_REJECTED`:
			return {
				...state,
				fetchDciElementListComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_DCI_ELEMENT:
			return {
				...state,
				fetchDciElementListComplete: false
			};
		case `${eventNames.FETCH_ONE_CONSUMABLE_CLASS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					consumableClass: action.payload.data.data,
					fetchOneConsumableClassComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchOneConsumableClassComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CONSUMABLE_CLASS}_REJECTED`:
			return {
				...state,
				fetchOneConsumableClassComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CONSUMABLE_CLASS:
			return {
				...state,
				fetchOneConsumableClassComplete: false
			};
		case `${eventNames.UPDATE_CONSUMABLE_CLASS}_FULFILLED`:
			return {
				...state,
				updateConsumableClassComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_CONSUMABLE_CLASS}_REJECTED`:
			return {
				...state,
				updateConsumableClassComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CONSUMABLE_CLASS:
			return {
				...state,
				updateConsumableClassComplete: false
			};
		case eventNames.FILTER_CHANGE_CONSUMABLE:
			return {
				...state,
				filterChangedConsumableClass: action.payload.filter,
				ignorePageChangeConsumableClass: action.payload.ignorePageChange
			};

		case `${eventNames.SAVE_AND_APPROVE_CONSUMABLE_CLASS}_FULFILLED`:
			return { ...state, saveAndApproveConsumableClassStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_CONSUMABLE_CLASS}_REJECTED`:
			return { ...state, saveAndApproveConsumableClassStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED:
			return { ...state, saveAndApproveConsumableClassStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_CONSUMABLE_CLASS}_FULFILLED`:
			return { ...state, asssignAndApproveConsumableClassStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_CONSUMABLE_CLASS}_REJECTED`:
			return { ...state, asssignAndApproveConsumableClassStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED:
			return { ...state, asssignAndApproveConsumableClassStatus: false };

		default:
			return state;
	}
}
