import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import specialityActions from "../../../../../../../actions/specialty";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class HospitalSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// hospitalData: {},
			hospitalData: [],
			offset: 0,
			numResults: 100,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.hospitalData = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchHospitalList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderHospitalOptions() {
		const prevState = this.state;
		prevState.options = [];
		!this.props.isCreateClp ? prevState.options.push({ value: null, label: i18Get("All", utils.getLanguage()) }) : "";
		if (this.state.hospitalData) {
			if ((this.props.isCreateClp || this.props.isClpList) && !this.props.hospitalId) {
				prevState.options.push({ value: "MASTER", label: "MOM" });
			}
		
			this.state.hospitalData.map((data) => {
				prevState.options.push({ value: data.id, label: data.name });
				if (this.props.hospitalId) {
					this.props.hospitalChanged({ value: data.id, label: data.name });
				}
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchHospitalList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += `${this.props.hospitalId ? `&hospitalId=${this.props.hospitalId}` : ""}`;
		this.props.dispatch(specialityActions.getAllHospital(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ ...this.state, isListLoading: true }, () => {
				this.fetchHospitalList(search);
			});
		}
	}

	onListScroll() {
		const offset = this.state.offset + this.state.numResults;
		this.setState({ ...this.state, offset }, () => { this.fetchHospitalList(); });
	}

	render() {
		if (this.props.specialty.isFetchAllHospitalCompleted === "FULFILLED") {
			this.props.dispatch(specialityActions.clearHospitalFetch());
			if (!this.props.isCreateClp) {
				this.state.hospitalData = unionWith(this.state.hospitalData, this.props.specialty.hospitalData, isEqual);
			}
			this.renderHospitalOptions();
		}
		return (
			<Select
				styles={customStyles}
				className={`w_100 di ${this.props.isAdminClp ? "z_index_99" : ""}`}
				defaultActiveFirstOption={false}
				onChange={this.props.hospitalChanged}
				value={this.props.data}
				isSearchable
				isClearable={!this.props.isCreateClp}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Hospital", utils.getLanguage())}
				maxMenuHeight={150}
			/>
		);
	}
}

HospitalSelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	specialty: store.specialty,
	translations: store.translations
}))(HospitalSelect));
