import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ClinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";
import { Button } from "antd";
import axios from "axios";
import Config from "../../../../../../../../src/MOM-config";
import { apiHeaders } from "../../../../../../../../src/utils";
import Loader from "../../../../../../../plug/loader";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import column from "./column";
import locations from "../../../../../../../router/locations";

const PDF = "PDF";
const EXCEL = "EXCEL";

class ClpSynthesis extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoaded: false,
			synthesisList: [],
			loading: false,
			totalPages: 1,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			sort: "rank",
			desc: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.state.clinicalPathway = this.props.clinicalPathwayData;
		this.fetchList();
	}

	fetchList() {
		this.setState({ isListLoading: true });
		let query = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		query += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
		query += this.props.clpStepFilter ? `&clpStepFilter=${this.props.clpStepFilter}` : "";
		this.props.dispatch(ClinicalPathwayProblemAction.getAllClpSynthesis(this.props.match.params.id, this.props.match.params.organization, query));
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchList();
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.synthesisList, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this),
					column(this.onClickProblem),
					true, false,
					this.onPageChange.bind(this), "SYNTHESIS", "")} />
		);
	}

	clpSynthesisTemplate(type, win) {
		let queryParams = `locale=${utils.getLanguage()}`;
		queryParams += this.state.page ? `&offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryParams += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryParams += `&treatmentId=${this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id}`;
		queryParams += `&organization=${this.props.match.params.organization}`;
		queryParams += this.props.clpStepFilter ? `&clpStepFilter=${this.props.clpStepFilter}` : "";
		queryParams += "&type=EXCEL";
		axios({
			url: `${Config.apiRootSecure}/reports/clinicalPathway/sythesis/healthMapping?${queryParams}`,
			...apiHeaders(), method: "GET", responseType: "blob"
		})
			.then(response => {
				this.state.isExportLoading = false;
				const blob = new Blob([response.data], (type !== "csv" ? { type: "application/" + type } : { type: "text/" + type }));
				const windowUrl = (window.URL || window.webkitURL);
				const downloadUrl = windowUrl.createObjectURL(blob);
				if (type !== PDF) {
					const link = document.createElement("a");
					link.href = downloadUrl;
					link.download = `${this.state.clinicalPathway.name}` + "_master" + `${type === EXCEL ? ".xlsx" : ""}`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
				} else {
					win.location = downloadUrl;
					win.focus();
				}
				this.setState(this.state);
			}).catch(() => {
				this.state.isExportLoading = false;
				//win.close();
				this.setState(this.state);
			});
	}

	exportClpSynthesis = () => {
		const win = window;
		this.clpSynthesisTemplate(EXCEL, win);
	};

	onClickProblem = (value) => {
		this.props.history.push(`${locations.clinical_pathway_plain}/${this.props.match.params.organization}/${this.props.match.params.id}${value.problemId ? `?position=${value.problemId}` : ""}`);
		this.props.reloadData();
	};


	render() {
		if (Object.keys(this.props.clinicalPathwayData) && !this.state.isLoaded) {
			this.state.clinicalPathway = this.props.clinicalPathwayData;
			this.state.isLoaded = true;
		}

		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwaySynthesisList === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.synthesisList = this.props.clinicalPathwayProblems.synthesisList;
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllClpSynthesis());
		}

		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwaySynthesisList === "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetAllClpSynthesis());
		}

		return (
			<div>
				<div className="d-flex justify-content-between padding-left-0 ">
					<div className="mb-10  text_align_left">
						<span className='intervention_name'>
							{this.props.clinicalPathwayData && this.props.clinicalPathwayData.name && this.props.clinicalPathwayData.hospitalName ?
								`${this.props.clinicalPathwayData.hospitalName} ${this.props.clinicalPathwayData.name}` : this.props.clinicalPathwayData.name ? this.props.clinicalPathwayData.name : ""}
						</span>
						<span className='intervention_speciality_name'>
							{this.props.clinicalPathwayData && this.props.clinicalPathwayData.specialityName && this.props.clinicalPathwayData.familyName ?
								`(${this.props.clinicalPathwayData.specialityName}, ${this.props.clinicalPathwayData.familyName})` : ""}
						</span>
					</div>
					<div className="margin-bottom-10 btn-group text_align_right">
						<div className="float_left">
							<Button type="button" className=" with_border uppercase single-button min_width_90 margin-left-0"
								onClick={() => this.exportClpSynthesis()}>
								<I18 tkey="EXPORT" />
							</Button>
						</div>
					</div>
				</div>
				<div className=" position_relative ">
					<div className="w_100 risk_preparation_container">
						<div className="table_div risk_table synthesis_modal_table">{this.getList()}</div>
					</div>
				</div>
			</div>
		);
	}
}

ClpSynthesis.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations
}))(ClpSynthesis));