import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import adminClinicalPathwayActions from "../../../../../../actions/admin-clinical-pathway";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class ItemDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			offset: 0,
			numResults: 100,
			search: null,
			clinicalPathwayType: "",
			isListLoading: true,
			isDataFound: true
		};
	}

	UNSAFE_componentWillMount() {
		this.state.list = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.clinicalPathwayType = this.props.clinicalPathwayType;
		this.setState({ ...this.state }, () => {this.fetchList(true);});
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	renderOptions() { 
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.list) {    
			this.state.list.map((data) => {   
				prevState.options.push({ value: data.id, label: data.item });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}
 
	fetchList(search) {
		let queryString = `sort=${this.props.isRank ? "rank" : "name"}&desc=0&offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += this.state.clinicalPathwayType ? `&clinicalPathwayType=${this.state.clinicalPathwayType}` : "";
		queryString += this.props.subStepId ? `&clpSubStepId=${this.props.subStepId}` : "";
		queryString += search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "";
		queryString += this.props.isApproved ? "&status=APPROVED" : "";
		this.props.dispatch(adminClinicalPathwayActions.fetchAllMinimalItem(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true }, () => {
				this.timer = setTimeout(() => {
					this.fetchList(search);
				}, 800);
			});
		}
	}

	onListScroll() {
		this.state.offset = this.state.offset + this.state.numResults;
		this.setState({ ...this.state }, () => {this.fetchList();});
	}


	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	render() {
		if (this.props.adminClinicalPathway.fetchAllMinimalItemCompleted === "FULFILLED") {
			this.state.isListLoading = false;
			this.props.dispatch(adminClinicalPathwayActions.clearFetchAllMinimalItem());
			this.state.list = unionWith(this.state.list, this.props.adminClinicalPathway.minimalItemList.data, isEqual);
			this.renderOptions();
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
				this.fetchList(false);
				this.state.isDataFound = false;
			}
		}

		return (         
			<Select 
				styles={customStyles}
				className={this.props.className ? this.props.className : "w_100 di"}
				onChange={this.props.itemChanged}
				value={this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : ""}
				isSearchable
				isClearable
				isDisabled={this.props.disabled}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Item", utils.getLanguage())} />
		);
	}
}

ItemDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	adminClinicalPathway: store.adminClinicalPathway
}))(ItemDropdown));                    