import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Language from "./language-dropdown";
import Profile from "./profile-dropdown";
import locations from "../../../router/locations";
import "./header.css";
import eventNames from "../../../actions/events";
import I18 from "../../../i18";
import userActions from "../../../actions/user/profile";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	logoClicked() {
		this.props.history.push(locations.base);
	}

	onMenuClicked() {
		if (this.props.actions.sideBarHideShow) {
			this.props.actions.sideBarHideShow = false;
		} else {
			this.props.actions.sideBarHideShow = true;
		}
		this.props.dispatch({ type: eventNames.HIDE_SHOW_SIDE_BAR, payload: { sideBar: this.props.actions.sideBarHideShow } });
	}

	onAdminstrationClicked() {
		this.props.history.push(locations.administration);
	}

	onSupportClicked() {
		this.props.history.push(locations.issue);
	}

	render() {
		this.state.hasAccess = this.props.scope.userScope && (this.props.scope.userScope.SUPPORT & this.props.user.loginData.scope);
		this.state.hasScannerAccess = this.props.scope.userScope && (this.props.scope.userScope.SCANNER & this.props.user.loginData.scope);
		if (this.props.user.supportPortalLinkFetchComplete === "FULFILLED") {
			this.state.supportPortalLink = this.props.user.supportLink;
			window.open(this.state.supportPortalLink);
			window.oldOpen = window.open;
			this.props.dispatch(userActions.clearGetSupportPortalLink());
		}
		return (
			<nav className="navbar navbar_header h_50p">
				<div className="container h_100 padding-top-0">
					<div className="h_100">
						<div className="navbar-header float_left">
							<a className="navbar-brand pointer">
								{((this.props.location.pathname !== locations.base) && (this.props.location.pathname !== locations.profile)) && <span className="cursor_pointer" onClick={this.onMenuClicked.bind(this)}><i className="fa fa-bars hidden-md hidden-lg float_left" aria-hidden="true" /></span>}
								<img className="logo-nav malayalam_logo float_left" src="/images/logo.png" onClick={this.logoClicked.bind(this)} />
							</a>
						</div>
						<div className="float_right display_table horizontal_menu mobile_icons">
							{(!this.props.user.loginData.hospitalId)
								&& (
									<div className="display_table_cell cursor_pointer administrator_a hidden-xs" onClick={this.onAdminstrationClicked.bind(this)}>
										<i className="fa fa-cog margin-right-5" aria-hidden="true" />
										{" "}
										<a className="cool-link">
											<I18 tkey="Administration" />
											{" "}
										</a>
									</div>
								)}

							<Language />
							<Profile />
						</div>
					</div>
				</div>
				{this.props.screen.screenId ? <span className="badge badge-info badge_container">{this.props.screen.screenId}</span> : ""}
			</nav>
		);
	}
}

Header.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	screen: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	screen: store.screen,
	user: store.user,
	scope: store.scope,
	translations: store.translations
}))(Header));
