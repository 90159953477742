import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import "../../../../../../plug/reactTable/react-table.css";
import { Modal } from "antd";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import translationActions from "../../../../../../actions/translation";
import Loader from "../../../../../../plug/loader";
import events from "../../../../../../actions/events";
import screenId from "../../../../../../router/screenIdList";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

class ListTranslations extends Component {
	constructor(props) {
		super(props);
		this.state = { ...this.getInitialState() };
		this.translationRef = React.createRef();
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.translationList });
	}

	getInitialState() {
		return {
			translationList: [],
			totalPages: 1,
			loading: false,
			deleteVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedTranslation: {},
			sort: "",
			desc: 0,
			search: "",
			language: utils.getLanguage(),
			editId: utils.sameDropper(),
			translationText: "",
			disableSave: false
		};
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: (d) => d,
				width: 130,
				resizable: false,
				sortable: false,
				Cell: function (row) {
					return (
						(this.state.editId !== row.value.id)
							? (
								<div className="margin_left_auto">
									<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
&nbsp;&nbsp;
									<DeleteOutlined className=" delete_color margin-right-10" type="delete" onClick={this.deleteClicked(row.value).bind(this)} />
								</div>
							)
							: (
								<div className="margin_left_auto">
									<a onClick={this.saveChanges(row.value).bind(this)} disabled={this.state.disableSave}><SaveOutlined className="edit_color margin-right-10" type="save" /></a>
&nbsp;&nbsp;
									<DeleteOutlined className=" delete_color margin-right-10" type="close" onClick={this.revertEdit.bind(this)} />
								</div>
							));
				}.bind(this)
			},
			{
				Header: i18Get("TRANSLATION KEY", utils.getLanguage()),
				id: "field",
				accessor: (d) => d.field,
				resizable: true,
				Cell: (row) => (
					this.getText(row.value)
				)
			}, {
				Header: i18Get("LANGUAGE TRANSLATION", utils.getLanguage()),
				id: "translation",
				accessor: (d) => d,
				resizable: true,
				Cell: (row) => (
					(this.state.editId === row.value.id) ? this.renderInput(this.state.translationText) : this.getText(row.value.translation)
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	renderInput(value) {
		return (
			<div className="w_100 di">
				<input
					className="form-control w_100"
					defaultValue={value}
					ref={this.translationRef}
					maxLength={120}
				/>
			</div>
		);
	}

	validate() {
		return !(!this.translationRef.current.value || !this.translationRef.current.value.trim());
	}

	saveChanges(value) {
		return () => {
			if (this.validate()) {
				const prevState = this.state;
				prevState.disableSave = true;
				this.setState(prevState);
				const payload = {};
				payload[value.id] = this.translationRef.current.value.trim();
				this.props.dispatch(translationActions.updateTranslation(utils.getLanguage(), payload));
			} else {
				utils.showError(i18Get("Enter valid translation", utils.getLanguage()));
			}
		};
	}

	revertEdit() {
		const prevState = this.state;
		prevState.editId = utils.sameDropper();
		prevState.translationText = "";
		this.setState(prevState);
	}

	editClicked(value) {
		return () => {
			const prevState = this.state;
			prevState.editId = value.id;
			prevState.translationText = value.translation;
			this.setState(prevState);
		};
	}

	deleteClicked(translation) {
		return () => {
			const prevState = this.state;
			prevState.deletedTranslation = translation;
			prevState.deleteVisible = true;
			this.setState(prevState);
		};
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, deletedTranslation: {} });
	}

	deleteTranslation() {
		this.props.dispatch(translationActions.deleteTranslation(this.state.deletedTranslation.id, utils.getLanguage()));
		const prevState = this.state;
		prevState.deleteVisible = false;
		prevState.deletedTranslation = {};
		this.setState(prevState);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		queryString += this.props.search ? `search=${this.props.search}` : "";
		this.props.dispatch(translationActions.getAllTranslationForLanguage(utils.getLanguage(), queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	getList() {
		return (
			<ReactTable
				data={this.state.translationList}
				pages={this.state.totalPages}
				loading={this.state.loading}
				LoadingComponent={() => (<Loader loading={this.state.loading} isTable />)}
				multiSort={false}
				defaultPageSize={this.state.pageSize}
				manual
				onFetchData={this.onFetchData.bind(this)}
				columns={this.getCols()}
				className="-highlight report_react_table rt-body-x_scroll-hidden"
				showPagination
				showPageSizeOptions={false}
				onPageChange={this.onPageChange.bind(this)}
				noDataText={this.state.loading ? "" : i18Get("NO TRANSLATION FOUND", utils.getLanguage())}
				previousText={i18Get("Previous", utils.getLanguage())}
				nextText={i18Get("Next", utils.getLanguage())}
				pageText={i18Get("Page", utils.getLanguage())}
				ofText={i18Get("of", utils.getLanguage())}
				rowsText={i18Get("rows", utils.getLanguage())}
				pageSizeOptions={[10, 15, 20, 25, 50, 100]}
				onPageSizeChange={this.onPageSizeChange.bind(this)}
			/>
		);
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	render() {
		if (this.state.language !== utils.getLanguage()) {
			this.state.translationList = [];
			this.state.totalPages = 1;
			this.state.editId = utils.sameDropper();
			this.state.translationText = "";
			this.state.disableSave = false;
			this.state.language = utils.getLanguage();
			this.state.loading = true;
			this.fetchDataFromServer();
		}

		if (this.props.search !== this.state.search) {
			this.state.search = this.props.search;
			this.state.translationList = [];
			this.state.totalPages = 1;
			this.state.editId = utils.sameDropper();
			this.state.translationText = "";
			this.state.disableSave = false;
			this.state.loading = true;
			this.state.page = 1;
			this.fetchDataFromServer();
		}

		if (this.props.translations.isTranslationFetchForLanguageCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.translations.translations.count / this.state.pageSize);
			this.state.translationList = this.props.translations.translations.translations;
			this.props.dispatch(translationActions.clearGetAllTranslationForLanguage());
		}

		if (this.props.translations.isTranslationUpdateCompleted === "FULFILLED") {
			this.state.loading = true;
			this.state.translationText = "";
			this.state.editId = utils.sameDropper();
			this.state.disableSave = false;
			this.fetchDataFromServer();
			this.props.dispatch(translationActions.getAllTranslations());
			this.props.dispatch(translationActions.clearUpdateTranslation());
		}

		if (this.props.translations.isTranslationUpdateCompleted === "REJECTED") {
			this.state.disableSave = false;
			this.props.dispatch(translationActions.clearUpdateTranslation());
		}

		if (this.props.translations.isTranslationDeleteCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(translationActions.getAllTranslations());
			this.props.dispatch(translationActions.clearDeleteTranslation());
		}

		return (
			<div className="pole_top_section">
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete translation", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteTranslation.bind(this)}
				>
					<div>
						<I18 tkey="Are you sure you want to delete" />
						{" "}
						<span className="bold">{this.state.deletedTranslation.field ? this.state.deletedTranslation.field : ""}</span>
						{" "}
            ?
					</div>
				</Modal>
			</div>
		);
	}
}

ListTranslations.propTypes = {
	translations: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(ListTranslations));
