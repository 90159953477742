import eventNames from "../../actions/events";

const initialState = {
	familyData: {},
	isAssignAndApproveFamilyCompleted: false,
	isSaveAndApproveFamilyCompleted: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_FAMILY_MODEL}_PENDING`:
			return {
				...state,
				isFetchAllFamilyCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_FAMILY_MODEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllFamilyCompleted: "FULFILLED",
					familyList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_FAMILY_MODEL}_REJECTED`:
			return {
				...state,
				isFetchAllFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_FAMILY_MODEL:
			return {
				...state,
				isFetchAllFamilyCompleted: false
			};
		case `${eventNames.ASSIGN_AND_APPROVE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isAssignAndApproveFamilyCompleted: "FULFILLED",
					assignAndApproveFamily: action.payload.data.data
				};
			}
			return {
				...state,
				isAssignAndApproveFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.ASSIGN_AND_APPROVE_FAMILY}_REJECTED`:
			return {
				...state,
				isAssignAndApproveFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_ASSIGN_AND_APPROVE_FAMILY:
			return {
				...state,
				isAssignAndApproveFamilyCompleted: false
			};
		case `${eventNames.SAVE_AND_APPROVE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isSaveAndApproveFamilyCompleted: "FULFILLED",
					assignAndApproveFamily: action.payload.data.data
				};
			}
			return {
				...state,
				isSaveAndApproveFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.SAVE_AND_APPROVE_FAMILY}_REJECTED`:
			return {
				...state,
				isSaveAndApproveFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_SAVE_AND_APPROVE_FAMILY:
			return {
				...state,
				isSaveAndApproveFamilyCompleted: false
			};
		default:
			return state;
	}
}
