import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminPersonnelActions from "../../../../../../../../actions/admin-personnel";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import TypeActivityDropdown from "../type-activity-dropdown";

class CreatePersonnelTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			typeActivity: utils.sameDropper()
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.createClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	typeActivitySelected(e) {
		const prevState = this.state;
		prevState.invalidTypeActivity = false;
		prevState.typeActivity = e;
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim()) {
			prevState.invalidName = true;
		}
		if (!this.state.typeActivity) {
			prevState.invalidTypeActivity = true;
		}
		if (!this.state.invalidName && !this.state.invalidTypeActivity) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name.trim(),
				typeActivity: this.state.typeActivity
			};
			this.props.dispatch(adminPersonnelActions.createPersonnelTemplate(payload));
		}
	}

	render() {
		if (this.props.adminPersonnel.createPersonnelTemplateCompleted === "FULFILLED") {
			this.props.dispatch(adminPersonnelActions.clearCreatePersonnelTemplate());
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminPersonnel.createPersonnelTemplateCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearCreatePersonnelTemplate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={60}
								/>
								{this.state.invalidName ? <span className="invalid_right fixed_label"><I18 tkey="Enter valid name" /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="TypeActivity" /></label>
								<TypeActivityDropdown
									className=" "
									ignoreAll
									typeActivity={this.state.typeActivity}
									typeActivitySelected={this.typeActivitySelected.bind(this)}
								/>
								{this.state.invalidTypeActivity ? <span className="invalid_right fixed_label"><I18 tkey="Select valid typeactivity" /></span> : ""}
							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									showAdd
									onClick={this.createClicked.bind(this)}
									value={i18Get("CREATE", utils.getLanguage())}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreatePersonnelTemplate.propTypes = {
	adminPersonnel: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminPersonnel: store.adminPersonnel,
	actions: store.actions
}))(CreatePersonnelTemplate));
