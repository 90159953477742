import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getClpProblemList = (id, organization, query) => ({
	type: events.GET_ALL_CLP_PROBLEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping${query ? `?${query}` : ""}`)
});

const clearGetClpProblemList = () => ({
	type: events.CLEAR_GET_ALL_CLP_PROBLEM,
	payload: {}

});

const getOneClpProblem = (params) => ({
	type: events.GET_ONE_CLP_PROBLEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${params.id}/healthMapping/${params.problemId}`)
});

const clearGetOneClpProblem = () => ({
	type: events.CLEAR_GET_ONE_CLP_PROBLEM,
	payload: {}
});

const createClpProblem = (payload, props) => ({
	type: events.CLP_PROBLEM_CREATE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${props.id}/healthMapping`, { ...payload })
});

const clearCreateClpProblem = () => ({
	type: events.CLEAR_CLP_PROBLEM_CREATE,
	payload: {}
});

const updateClpProblem = (payload, props, id) => ({
	type: events.CLP_PROBLEM_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${props.id}/healthMapping/${id}`, { ...payload })
});

const clearUpdateClpProblem = () => ({
	type: events.CLEAR_CLP_PROBLEM_UPDATE,
	payload: {}
});

const removeClpProblem = (id, organization, problemId) => ({
	type: events.DELETE_CLP_PROBLEM,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${problemId}`)

});

const removeClpProblemCompleted = () => ({
	type: events.DELETE_CLP_PROBLEM_COMPLETED,
	payload: ""
});

const getAllClpSynthesis = (id, organization, query) => ({
	type: events.GET_ALL_CLP_SYNTHESIS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/sythesis/healthMapping${query ? `?${query}` : ""}`)
});

const clearGetAllClpSynthesis = () => ({
	type: events.CLEAR_GET_ALL_CLP_SYNTHESIS,
	payload: {}

});

const getMinimalClpProblemList = (id, organization, query) => ({
	type: events.GET_ALL_MINIMAL_CLP_PROBLEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping${query ? `?${query}` : ""}`)
});

const clearGetMinimalClpProblemList = () => ({
	type: events.CLEAR_GET_ALL_MINIMAL_CLP_PROBLEM,
	payload: {}

});

const createAdditionalInfo = (payload, props, problemId) => ({
	type: events.ADDITIONAL_INFO_CREATE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${props.id}/healthMapping/${problemId}/additionalInfo`, { ...payload })
});

const clearCreateAdditionalInfo = () => ({
	type: events.CLEAR_ADDITIONAL_INFO_CREATE,
	payload: {}
});

const getOneAdditionalInfo = (params) => ({
	type: events.GET_ONE_ADDITIONAL_INFO,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${params.id}/healthMapping/${params.problemId}/additionalInfo/${params.problemMappingId}`)
});

const clearGetOneAdditionalInfo = () => ({
	type: events.CLEAR_GET_ONE_ADDITIONAL_INFO,
	payload: {}
});

const removeAdditionalInfo = (id, problemId, problemMappingId) => ({
	type: events.DELETE_ADDITIONAL_INFO,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${problemId}/additionalInfo/${problemMappingId}`)

});

const removeAdditionalInfoCompleted = () => ({
	type: events.DELETE_ADDITIONAL_INFO_COMPLETED,
	payload: ""
});

const updateAdditionalInfo = (payload, props) => ({
	type: events.UPDATE_ADDITIONAL_INFO,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${props.id}/healthMapping/${props.problemId}/additionalInfo/${props.problemMappingId}`, { ...payload })
});

const clearUpdateAdditionalInfo = () => ({
	type: events.CLEAR_UPDATE_ADDITIONAL_INFO,
	payload: {}
});

const updateClpProblemsRank = (id, payload) => ({
	type: events.UPDATE_CLP_PROBLEMS_RANK,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/updateRank`, payload)
});

const clearUpdateClpProblemsRank = () => ({
	type: events.CLEAR_UPDATE_CLP_PROBLEMS_RANK,
	payload: {}
});

const getAllStepsAndSubSteps = (id, query) => ({
	type: events.GET_ALL_STEPS_AND_SUB_STEPS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/additionalInfo/stepsAndSubsteps${query ? `${query}` : ""}`)
});

const clearGetAllStepsAndSubSteps = () => ({
	type: events.CLEAR_GET_ALL_STEPS_AND_SUB_STEPS,
	payload: {}
});

const getAllProblemTypes = (id, query, meta) => ({
	meta,
	type: events.GET_ALL_PROBLEM_TYPS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/problemTypes${query ? `${query}` : ""}`)
});

const clearGetAllProblemTypes = () => ({
	type: events.CLEAR_GET_ALL_PROBLEM_TYPS,
	payload: {}
});

const getClpProblemListCopyData = (id, organization, query) => ({
	type: events.GET_ALL_CLP_PROBLEM_COPY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping${query ? `?${query}` : ""}`)
});

const clearGetClpProblemListCopyData = () => ({
	type: events.CLEAR_GET_ALL_CLP_PROBLEM_COPY,
	payload: {}

});

const copyFromClinicalPathwayProblems = (id, sourceId, payload) => ({
	type: events.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/healthMapping/${id}/copyFromHealthProblem/${sourceId}`, { ...payload })
});

const clearCopyFromClinicalPathwayProblems = () => ({
	type: events.CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS,
	payload: {}
});

const copyFromClinicalPathwayProblemsStatus = (code, payload) => ({
	type: events.COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/healthMapping/copyFromHealthProblem/status/${code}`, { ...payload })
});

const clearCopyFromClinicalPathwayProblemsStatus = () => ({
	type: events.CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS,
	payload: {}
});

const getProblemListForReorder = (id, organization, query) => ({
	type: events.GET_ALL_CLP_PROBLEM_REORDER,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/standardCareChart/quickReoder${query ? `?${query}` : ""}`)
});

const clearGetProblemListForReorder = () => ({
	type: events.CLEAR_GET_ALL_CLP_PROBLEM_REORDER,
	payload: {}
});

const linkCheckList = (payload, id, problemId) => ({
	type: events.LINK_CHECK_LIST,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${problemId}/linkCheckList`, { ...payload })
});

const clearLinkCheckList = () => ({
	type: events.CLEAR_LINK_CHECK_LIST,
	payload: {}
});

const getAllCheckList = (id, problemId, query) => ({
	type: events.GET_ALL_CHECK_LIST,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${problemId}/checkList${query ? `?${query}` : ""}`)
});

const clearGetAllCheckList = () => ({
	type: events.CLEAR_GET_ALL_CHECK_LIST,
	payload: {}

});

const unlinkCheckList = (id, problemId, payload) => ({
	type: events.UNLINK_CHECK_LIST,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${problemId}/unlinkCheckList`, { ...payload })

});

const unlinkCheckListCompleted = () => ({
	type: events.UNLINK_CHECK_LIST_COMPLETED,
	payload: ""
});

const replaceByCheckList = (payload, props) => ({
	type: events.REPLACED_BY_CHECK_LIST,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${props.id}/healthMapping/${props.problemId}/additionalInfo/${props.problemMappingId}/replaceByCheckList`, { ...payload })
});

const clearReplaceByCheckList = () => ({
	type: events.CLEAR_REPLACED_BY_CHECK_LIST,
	payload: {}
});

const getDeleteModelProblemObserver = (code, meta, query) => ({
	type: events.DELETE_MODEL_PROBLEM_OBSERVER,
	meta: meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/models/status/${code}${query ? query : ""}`)
});

const clearDeleteModelProblemObserver = () => ({
	type: events.CLEAR_DELETE_MODEL_PROBLEM_OBSERVER,
	payload: {}

});

const getClpProblemListForComparison = (destId, sourceId, query) => ({
	type: events.GET_CLP_PROBLEM_LIST_FOR_COMPARISON,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${destId}/compare/${sourceId}/healthMapping${query ? query : ""}`)
});

const clearGetClpProblemListForComparison = () => ({
	type: events.CLEAR_GET_CLP_PROBLEM_LIST_FOR_COMPARISON,
	payload: {}

});

const updateByLinkedModelRank = (id, payload) => ({
	type: events.UPDATE_LINKED_MODEL_RANK,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/models/reorder`, { ...payload })
});

const updateLinkedModelRankCompleted = () => ({
	type: events.UPDATE_LINKED_MODEL_RANK_COMPLETED,
	payload: {}
});

const updateLinkedModelRankStatus = (code) => ({
	type: events.UPDATE_LINKED_MODEL_RANK_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/healthMapping/models/reorder/status/${code}`)
});

const clearUpdateLinkedModelRankStatus = () => ({
	type: events.UPDATE_LINKED_MODEL_RANK_STATUS_COMPLETED,
	payload: ""
});

export default {
	getClpProblemList,
	clearGetClpProblemList,
	removeClpProblem,
	removeClpProblemCompleted,
	getOneClpProblem,
	clearGetOneClpProblem,
	updateClpProblem,
	clearUpdateClpProblem,
	createClpProblem,
	clearCreateClpProblem,
	getAllClpSynthesis,
	clearGetAllClpSynthesis,
	getMinimalClpProblemList,
	clearGetMinimalClpProblemList,
	createAdditionalInfo,
	clearCreateAdditionalInfo,
	getOneAdditionalInfo,
	clearGetOneAdditionalInfo,
	removeAdditionalInfo,
	removeAdditionalInfoCompleted,
	updateAdditionalInfo,
	clearUpdateAdditionalInfo,
	updateClpProblemsRank,
	clearUpdateClpProblemsRank,
	getAllStepsAndSubSteps,
	clearGetAllStepsAndSubSteps,
	getAllProblemTypes,
	clearGetAllProblemTypes,
	getClpProblemListCopyData,
	clearGetClpProblemListCopyData,
	copyFromClinicalPathwayProblems,
	clearCopyFromClinicalPathwayProblems,
	copyFromClinicalPathwayProblemsStatus,
	clearCopyFromClinicalPathwayProblemsStatus,
	getProblemListForReorder,
	clearGetProblemListForReorder,
	linkCheckList,
	clearLinkCheckList,
	getAllCheckList,
	clearGetAllCheckList,
	unlinkCheckList,
	unlinkCheckListCompleted,
	replaceByCheckList,
	clearReplaceByCheckList,
	getDeleteModelProblemObserver,
	clearDeleteModelProblemObserver,
	getClpProblemListForComparison,
	clearGetClpProblemListForComparison,
	updateByLinkedModelRank,
	updateLinkedModelRankCompleted,
	updateLinkedModelRankStatus,
	clearUpdateLinkedModelRankStatus
};
