import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../../router/locations";
import CreatePersonnelTemplate from "./personnel-template";
import CreateTemplateDetails from "./template-details";

export default class AdminPersonnelCreateRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}/create`}
					render={() => <CreatePersonnelTemplate {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.adminPersonnel}/${locations.tabTemplateDetails}/create`}
					render={() => <CreateTemplateDetails {...this.props} />}
				/>
				<Redirect to={`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`} />
			</Switch>
		);
	}
}
