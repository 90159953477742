import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const filterAction = (filter, ignorePageChange) => ({
	type: events.INTERVENTION_FILTER,
	payload: { filter, ignorePageChange }
});

const getAll = (organization, query, meta) => ({
	type: events.CLINICAL_PATHWAY_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway${query ? `?${query}` : ""}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_GET_ALL,
	payload: {}

});

const getOne = (clinicalPathwayId, timeZone) => ({
	type: events.CLINICAL_PATHWAY_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${clinicalPathwayId}${timeZone ? `?timezone=${timeZone}` : ""}`)
});

const updateClinicalPathway = (payload, id) => ({
	type: events.CLINICAL_PATHWAY_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/name`, { ...payload })
});

const createClinicalPathway = (payload) => ({
	type: events.CLINICAL_PATHWAY_CREATE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway`, { ...payload })
});
const clearCreate = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_CREATE,
	payload: {}
});

const clearUpdateClinicalPathway = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_UPDATE,
	payload: {}
});

const clearFilter = () => ({
	type: events.CLEAR_FILTER,
	payload: {}
});

const cleargetOne = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_GET_ONE,
	payload: {}
});

const editIntervention = (id, payload) => ({
	type: events.EDIT_INTERVENTION,
	payload: axios.put(`${Config.apiRootSecure}/intervention/${id}`, { ...payload })
});

const clearEditIntervention = () => ({
	type: events.CLEAR_EDIT_INTERVENTION,
	payload: {}
});

const removeClinicalPathway = (id) => ({
	type: events.DELETE_CLINICAL_PATHWAY,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}`)

});

const removeClinicalPathwayCompleted = () => ({
	type: events.DELETE_CLINICAL_PATHWAY_COMPLETED,
	payload: ""
});

const getClpSubTreatment = (type, id, sort, desc, treatmentId) => ({
	type: events.CLINICAL_PATHWAY_SUB_TREATMENT_GET,
	meta: type,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/subTreatment?typeActivity=${type.toUpperCase()}${sort ? (`?sort=${sort}&desc=${desc || 0}`) : ""}${treatmentId ? `&treatmentId=${treatmentId}` : ""}`)

});

const clearGetClpSubTreatment = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_SUB_TREATMENT_GET,
	payload: {}

});

const getInterventions = (query) => ({
	type: events.GET_INTERVENTION,
	payload: axios.get(`${Config.apiRootSecure}/intervention/minimal/clinicalPathway${query ? `?${query}` : ""}`)

});

const clearGetInterventions = () => ({
	type: events.CLEAR_GET_INTERVENTION,
	payload: {}

});

const getOneClpUsingHospitalId = (clinicalPathwayId, hospitalId, query) => ({
	type: events.CLP_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${clinicalPathwayId}/hospitals/${hospitalId}${query || ""}`)
});

const clearGetOneClpUsingHospitalId = () => ({
	type: events.CLEAR_CLP_GET_ONE,
	payload: {}

});

const getClpSubTreatmentForSelectedCopy = (type, id, sort, desc, treatmentId) => ({
	type: events.GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY,
	meta: type,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/copy/selected/subTreatment?typeActivity=${type.toUpperCase()}${sort ? (`?sort=${sort}&desc=${desc || 0}`) : ""}${treatmentId ? `&treatmentId=${treatmentId}` : ""}`)

});

const clearGetClpSubTreatmentForSelectedCopy = () => ({
	type: events.CLEAR_GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY,
	payload: {}

});

const getClinicalPathway = (query) => ({
	type: events.GET_CLINICAL_PATHWAY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway${query ? `?${query}` : ""}`)
});

const getClinicalPathwayCompleted = () => ({
	type: events.GET_CLINICAL_PATHWAY_COMPLETED,
	payload: {}

});

const saveAndApproveClinicalPathway = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_CLINICAL_PATHWAY,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveClinicalPathwayCompleted = () => ({
	type: events.SAVE_AND_APPROVE_CLINICAL_PATHWAY_COMPLETED,
	payload: {}
});

const deleteExistingClp = (id) => ({
	type: events.DELETE_CLP,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/deleteExisting`)
});

const deleteExistingClpCompleted = () => ({
	type: events.DELETE_CLP_COMPLETED,
	payload: {}
});

const getDayInformation = (id, query) => ({
	type: events.GET_DAY_INFORMATION,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/treatmentSubStepName${query ? `?${query}` : ""}`)
});

const getDayInformationCompleted = () => ({
	type: events.GET_DAY_INFORMATION_COMPLETED,
	payload: {}
});

const exportClinicalPathwayList = (query, bodyParam) => ({
	type: events.CLINICAL_PATHWAY_LIST_EXPORT,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/exportAll${query ? `?${query}` : ""}`, bodyParam)
});

const exportClinicalPathwayListCompleted = () => ({
	type: events.CLINICAL_PATHWAY_EXPORT_LIST_COMPLETED,
	payload: ""
});

const checkClinicalPathwayListStatus = (code) => ({
	type: events.CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/exportAll/status/${code}`)
});

const checkClinicalPathwayListStatusCompleted = () => ({
	type: events.CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS_COMPLETED,
	payload: ""
});

const attachSelectedModelsWithClinicalPathWay = (id, payload) => ({
	type: events.ATTACH_SELECTED_MODEL_WITH_CLP,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/models/link`, { ...payload })
});

const clearAttachSelectedModelsWithClinicalPathWay = () => ({
	type: events.CLEAR_ATTACH_SELECTED_MODEL_WITH_CLP,
	payload: {}

});

const getAllItems = (id, query, meta) => ({
	type: events.CLINICAL_PATHWAY_GET_ALL_MODEL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/models?${query}`)
});

const clearGetAllItems = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_GET_ALL_MODEL,
	payload: {}

});

const applyModelChangesToClinicalPathWay = (id, payload) => ({
	type: events.APPLY_MODEL_CHANGES_WITH_CLP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/models/applyChanges`, { ...payload })
});

const clearApplyModelChangesToClinicalPathWay = () => ({
	type: events.CLEAR_APPLY_MODEL_CHANGES_WITH_CLP,
	payload: {}

});

const getApplyModelObserver = (code, meta, query) => ({
	type: events.MODEL_OBSERVER_CLP,
	meta: meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/models/status/${code}${query ? query : ""}`)
});

const clearGetApplyModelObserver = () => ({
	type: events.CLEAR_MODEL_OBSERVER_CLP,
	payload: {}

});

const getAllApplayModelChangDetails = (id, query) => ({
	type: events.APPLY_MODEL_CHANGES_DETAILS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/models/applyChanges/notification${query ? `?${query}` : ""}`)
});

const clearGetAllApplayModelChangDetails = () => ({
	type: events.CLEAR_APPLY_MODEL_CHANGES_DETAILS,
	payload: {}

});

const getAllClpsForCopy = (id, query, meta) => ({
	type: events.GET_ALL_CLPS_FOR_COPY,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/copyFrom${query ? `?${query}` : ""}`)
});

const clearGetAllClpsForCopy = () => ({
	type: events.CLEAR_GET_ALL_CLPS_FOR_COPY,
	payload: {}

});


export default {
	clearUpdateClinicalPathway,
	filterAction,
	getAll,
	clearGetAll,
	getOne,
	cleargetOne,
	updateClinicalPathway,
	clearCreate,
	clearFilter,
	editIntervention,
	clearEditIntervention,
	removeClinicalPathway,
	removeClinicalPathwayCompleted,
	createClinicalPathway,
	getClpSubTreatment,
	clearGetClpSubTreatment,
	getInterventions,
	clearGetInterventions,
	getOneClpUsingHospitalId,
	clearGetOneClpUsingHospitalId,
	getClpSubTreatmentForSelectedCopy,
	clearGetClpSubTreatmentForSelectedCopy,
	getClinicalPathway,
	getClinicalPathwayCompleted,
	saveAndApproveClinicalPathway,
	saveAndApproveClinicalPathwayCompleted,
	deleteExistingClp,
	deleteExistingClpCompleted,
	getDayInformation,
	getDayInformationCompleted,
	exportClinicalPathwayList,
	exportClinicalPathwayListCompleted,
	checkClinicalPathwayListStatus,
	checkClinicalPathwayListStatusCompleted,
	attachSelectedModelsWithClinicalPathWay,
	clearAttachSelectedModelsWithClinicalPathWay,
	getAllItems,
	clearGetAllItems,
	applyModelChangesToClinicalPathWay,
	clearApplyModelChangesToClinicalPathWay,
	getApplyModelObserver,
	clearGetApplyModelObserver,
	getAllApplayModelChangDetails,
	clearGetAllApplayModelChangDetails,
	getAllClpsForCopy,
	clearGetAllClpsForCopy
};
