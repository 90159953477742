import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchusers = (query) => ({
	type: events.FETCH_ALL_USER,
	payload: axios.get(`${Config.apiRootSecure}/users?${query}`)
});

const clearUserFetch = () => ({
	type: events.CLEAR_USER_FETCH,
	payload: {}
});

const createUser = (payload) => ({
	type: events.CREATE_USER,
	payload: axios.post(`${Config.apiRootSecure}/user`, { ...payload })
});

const clearCreateuser = () => ({
	type: events.CLEAR_CREATE_USER,
	payload: {}
});

const deleteUser = (id) => ({
	type: events.DELETE_USER,
	payload: axios.delete(`${Config.apiRootSecure}/user/${id}`)
});

const clearuserDelete = () => ({
	type: events.CLEAR_DELETE_USER,
	payload: {}
});

const fetchOneuser = (id) => ({
	type: events.FETCH_ONE_USER,
	payload: axios.get(`${Config.apiRootSecure}/user/admin/${id}`)
});

const clearFetchOneuser = () => ({
	type: events.CLEAR_FETCH_ONE_USER,
	payload: {}
});

const updateUser = (id, payload) => ({
	type: events.UPDATE_USER,
	payload: axios.put(`${Config.apiRootSecure}/user/admin/${id}`, { ...payload })
});

const clearUpdateuser = () => ({
	type: events.CLEAR_UPDATE_USER,
	payload: {}
});

const userPasswordUpdate = (payload) => ({
	type: events.UPDATE_USER_PASSWORD,
	payload: axios.post(`${Config.apiRootSecure}/user/admin/changePassword`, { ...payload })
});

const clearUserPasswordUpdate = () => ({
	type: events.CLEAR_UPDATE_USER_PASSWORD,
	payload: {}
});

export default {
	fetchusers,
	clearUserFetch,
	createUser,
	clearCreateuser,
	deleteUser,
	clearuserDelete,
	fetchOneuser,
	clearFetchOneuser,
	updateUser,
	clearUpdateuser,
	userPasswordUpdate,
	clearUserPasswordUpdate
};
