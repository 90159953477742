import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting) => ({
	type: events.ELEMENTS_GET_ALL,
	payload: axios.get(`${Config.apiRootSecure}/treatment/consumableClass/minimal?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&interventionId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${ignoreExisting ? `+${ignoreExisting}` : ""}`)
});

const add = (type, id, payload) => ({
	type: events.ELEMENTS_ADD,
	payload: axios.post(`${Config.apiRootSecure}/treatment/${id}/element?type=${type}`, { ...payload })
});

const get = (type, id, status, hospitalId) => ({
	type: events.ELEMENTS_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/element?type=${type}${status ? `&status=${status}` : ""}${hospitalId ? `&hospitalId=${hospitalId}` : ""}`)
});

const getOne = (treatmentId, mappingId, type) => ({
	type: events.ELEMENTS_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${treatmentId}/${type}/element/${mappingId}`)
});

const deleteElement = (id, type, treatmentId) => ({
	type: events.ELEMENTS_DELETE,
	payload: axios.delete(`${Config.apiRootSecure}/treatment/${treatmentId}/${type}/element/${id}`)

});

const updateElement = (id, mappingId, type, payload) => ({
	type: events.ELEMENTS_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/${type}/element/${mappingId}`, { ...payload })

});

const clearUpdate = () => ({
	type: events.CLEAR_ELEMENTS_UPDATE,
	payload: {}
});
const clearDelete = () => ({
	type: events.CLEAR_ELEMENTS_DELETE,
	payload: {}
});

const cleargetAll = () => ({
	type: events.CLEAR_ELEMENTS_GET_ALL,
	payload: {}
});

const clearAdd = () => ({
	type: events.CLEAR_ELEMENTS_ADD,
	payload: {}
});

const clearGet = () => ({
	type: events.CLEAR_ELEMENTS_GET,
	payload: {}
});

const getAllExistingConsumable = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_EXISTING_CONSUMABLE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/consumableClass/list/minimal?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&interventionId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const getAllNewConsumable = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_NEW_CONSUMABLE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/consumableClass/list/minimal?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&interventionId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const clearGetAllExistingConsumable = () => ({
	type: events.CLEAR_GET_ALL_EXISTING_CONSUMABLE,
	payload: {}
});

const clearGetAllNewConsumable = () => ({
	type: events.CLEAR_GET_ALL_NEW_CONSUMABLE,
	payload: {}
});

const getAllExistingSterlizationConsumable = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_EXISTING_STERLIZATION_CONSUMABLE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/sterilizationClass/list/minimal?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&interventionId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const clearGetAllExistingSterlizationConsumable = () => ({
	type: events.CLEAR_GET_ALL_EXISTING_STERLIZATION_CONSUMABLE,
	payload: {}
});

const getAllNewSterlizationConsumable = (offset, type, numResults, sort, desc, category, search, doctorId, treatmentId, chemo, cycleId, extendedType, ignoreExisting, elementId) => ({
	type: events.GET_ALL_NEW_STERLIZATION_CONSUMABLE,
	payload: axios.get(`${Config.apiRootSecure}/treatment/sterilizationClass/list/minimal?type=${type}&offset=${offset}&numResults=${numResults}${sort ? `&sort=${sort}` : ""}&desc=${desc || 0}${category ? `&${category}` : ""}${doctorId ? `&doctorId=${doctorId}` : ""}${search ? `&name=${search}` : ""}${treatmentId ? (`&interventionId=${treatmentId}`) : ""}${chemo ? `&chemo=${chemo}` : ""}${cycleId ? `&cycleId=${cycleId}` : ""}${extendedType ? `&extendedType=${extendedType}` : ""}${`&${ignoreExisting}`}${elementId ? (`&elementId=${elementId}`) : ""}`)
});

const clearGetAllNewSterlizationConsumable = () => ({
	type: events.CLEAR_GET_ALL_NEW_STERLIZATION_CONSUMABLE,
	payload: {}
});
export default {
	getAll,
	cleargetAll,
	add,
	get,
	clearAdd,
	deleteElement,
	clearDelete,
	clearGet,
	updateElement,
	clearUpdate,
	getOne,
	getAllExistingConsumable,
	clearGetAllExistingConsumable,
	getAllNewConsumable,
	clearGetAllNewConsumable,
	getAllExistingSterlizationConsumable,
	clearGetAllExistingSterlizationConsumable,
	getAllNewSterlizationConsumable,
	clearGetAllNewSterlizationConsumable

};
