import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import config from "./config.json";
import "./administration_sidebar.css";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import CardLink from "../../../dashboard/cards/card-link";

class Administration extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1
		};
	}

	render() {
		return (
			<div className="admin_panel mom-sidebar">
				{
					config.map((cardLink, index) => this.renderContents(cardLink, index))
				}
				{this.scrollTo()}
			</div>
		);
	}

	scrollTo() {
		if (document.getElementsByClassName("side_bar_section") && document.getElementsByClassName("side_bar_section")[0]) {
			const pos = (document.getElementsByClassName("selected") && document.getElementsByClassName("selected")[0]) ? document.getElementsByClassName("selected")[0].offsetTop : 0;
			document.getElementsByClassName("side_bar_section")[0].scrollTop = pos;
		}
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink, index) {

		if (this.props.user.loginData && this.props.user.loginData.scope == 64 && this.props.user.loginData.roleType === "PATHWAY_USER") {
			if (cardLink.index < 2) {

				if (!cardLink.show) { return false;}

				return <ExpandableLink key={index} data={cardLink} openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} />;
			}
			return false;
		}

		if (cardLink.expandable) {

			if (!cardLink.show) {return false;}

			return <ExpandableLink key={index} data={cardLink} openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} />;
		}
		return <CardLink key={index + 100} data={cardLink} />;
	}
}

Administration.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	user: store.user
}))(Administration));
