import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import Category from "../../../../../../../../constants/category.json";

const { Option } = Select;

class CategoryDropdown extends Component {
	constructor(props) {
		super(props);
	}

	renderCategoryOptions() {
		const options = [];
		for (const key in Category) {
			options.push(<Option key={key} value={key}>{i18Get(Category[key], utils.getLanguage())}</Option>);
		}
		return options;
	}

	render() {
		return (
			<div className="w_100 di position_relative">
				<label><I18 tkey="Category" /></label>
				<Select
					allowClear
					showSearch
					value={this.props.category}
					className="w_100 di"
					defaultActiveFirstOption={false}
					onChange={this.props.categoryChanged}
					placeholder={i18Get("Select category", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					optionFilterProp="children"
					filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				>
					{this.renderCategoryOptions()}
				</Select>
				{this.props.invalidCategory && <span className="invalid_right fixed_label"><I18 tkey="Select a category" /></span>}
			</div>
		);
	}
}

CategoryDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CategoryDropdown));
