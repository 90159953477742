import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAllTranslations = () => ({
	type: events.TRANSLATION_FETCH,
	payload: axios.get(`${Config.apiRoot}/translation`)
});

const resetLanguage = (language) => ({
	type: events.RESET_TRANSLATION_LANGUAGE,
	language
});

const clearResetFlag = () => ({
	type: events.CLEAR_RESET_FLAG,
	payload: {}
});

const triggerTranslationJsonGet = () => ({
	type: events.TRIGGER_TRANSLATION_JSON_GET,
	payload: {}
});

const getAllTranslationForLanguage = (language, queryString) => ({
	type: events.GET_ALL_TRANSLATION_FOR_LANGUAGE,
	payload: axios.get(`${Config.apiRootSecure}/translation/${language}?${queryString}`)
});

const clearGetAllTranslationForLanguage = () => ({
	type: events.CLEAR_GET_ALL_TRANSLATION_FOR_LANGUAGE,
	payload: {}
});

const deleteTranslation = (id, language) => ({
	type: events.DELETE_TRANSLATION,
	payload: axios.delete(`${Config.apiRootSecure}/translation/${language}/${id}`)
});

const clearDeleteTranslation = () => ({
	type: events.CLEAR_DELETE_TRANSLATION,
	payload: {}
});

const createTranslation = (language, payload) => ({
	type: events.CREATE_TRANSLATION,
	payload: axios.post(`${Config.apiRootSecure}/translation/${language}`, { ...payload })
});

const clearCreateTranslation = () => ({
	type: events.CLEAR_CREATE_TRANSLATION,
	payload: {}
});

const updateTranslation = (language, payload) => ({
	type: events.UPDATE_TRANSLATION,
	payload: axios.put(`${Config.apiRootSecure}/translation/${language}`, { ...payload })
});

const clearUpdateTranslation = () => ({
	type: events.CLEAR_UPDATE_TRANSLATION,
	payload: {}
});

const syncTranslation = (payload) => ({
	type: events.SYNC_TRANSLATION,
	payload: axios.put(`${Config.apiRootSecure}/sync/translation`, { ...payload })
});

const clearSyncTranslation = () => ({
	type: events.CLEAR_SYNC_TRANSLATION,
	payload: {}
});

export default {
	getAllTranslations,
	resetLanguage,
	clearResetFlag,
	triggerTranslationJsonGet,
	getAllTranslationForLanguage,
	clearGetAllTranslationForLanguage,
	deleteTranslation,
	clearDeleteTranslation,
	createTranslation,
	clearCreateTranslation,
	updateTranslation,
	clearUpdateTranslation,
	syncTranslation,
	clearSyncTranslation
};
