import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import anaesthesiaAction from "../../../../../../../../actions/anaesthesia";
import SelectNewAnesthesia from "./select-new-anaesthesia";
import SelectExistingAnesthesia from "./select-existing-anaesthesia";

class AssignAndApprovePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			elementName: "",
			elementCode: "",
			invalidName: false,
			invalidNewAnaesthesia: false,
			invalidExistingAnaesthesia: false,
			selectedNewAnaesthesia: "",
			selectedExistingAnaesthesia: "",
			elementsDetails: {},
			elementId: "",
			subTreatmentApproved: false,
			isloading: false,
			newAnaesthesiaStatus: false,
			existingAnaesthesiaStatus: false
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.elementId) {
			this.props.dispatch(anaesthesiaAction.getOneSubtreatment(this.props.match.params.id, this.props.elementId));  
		}
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ selectedNewAnaesthesia: false, selectedExistingAnaesthesia: false, invalidExistingAnaesthesia: false, invalidNewAnaesthesia: false, value: 1, elementId: "", newAnaesthesiaStatus: false, existingAnaesthesiaStatus: false });
		this.props.refreshCompleted();
	}

	newAnaesthesiaChanged(e) {
		this.setState({ selectedNewAnaesthesia: e ? e : "", invalidNewAnaesthesia: e && e.value ? false : true });
	}

	existingAnaesthesiaChanged(e) {
		this.setState({ selectedExistingAnaesthesia: e ? e : "", invalidExistingAnaesthesia: e && e.value ? false : true });
	}

	validateNewAnaesthesia() {
		if (this.state.selectedNewAnaesthesia && this.state.newAnaesthesiaStatus) {
			return true;
		}
		return false;
	}

	validateExistingAnaesthesia = () => {
		if (this.state.selectedExistingAnaesthesia && this.state.existingAnaesthesiaStatus) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateNewAnaesthesia()) {
				payload = {
					subTreatmentId: this.state.selectedNewAnaesthesia && this.state.selectedNewAnaesthesia.value ? this.state.selectedNewAnaesthesia.value : ""
				};
				this.props.dispatch(anaesthesiaAction.saveAndApprove(this.props.match.params.id, this.props.exType, payload, this.props.elementId));
			}
			else {
				this.setState({ invalidNewAnaesthesia: true });
			}
		}
		if (this.state.value === 2) {
			if (this.validateExistingAnaesthesia()) {
				payload = {
					subTreatmentId: this.state.selectedExistingAnaesthesia && this.state.selectedExistingAnaesthesia.value ? this.state.selectedExistingAnaesthesia.value : ""
				};
				this.props.dispatch(anaesthesiaAction.assignAndApprove(this.props.match.params.id, this.props.exType, payload, this.props.elementId));
			}
			else {
				this.setState({ invalidExistingAnaesthesia: true });
			}
		}
	}

	statusCheck(status) {
		if (status === "NEW_CONSUMABLE") {
			this.state.newAnaesthesiaStatus = true;
		}
		if (status === "EXISTING_CONSUMABLE") {
			this.state.existingAnaesthesiaStatus = true;
			this.state.value = 2;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.anaesthesia.asssignAndApproveStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewAnaesthesia: false, selectedExistingAnaesthesia: false, invalidExistingAnaesthesia: false, invalidNewAnaesthesia: false, value: 1, elementId: "", newAnaesthesiaStatus: false, existingAnaesthesiaStatus: false });
			this.props.refreshCompleted();
			this.props.dispatch(anaesthesiaAction.clearAssignAndApprove());
		}
		if (this.props.anaesthesia.saveAndApproveStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewAnaesthesia: false, selectedExistingAnaesthesia: false, invalidExistingAnaesthesia: false, invalidNewAnaesthesia: false, value: 1, elementId: "", newAnaesthesiaStatus: false, existingAnaesthesiaStatus: false });
			this.props.refreshCompleted();
			this.props.dispatch(anaesthesiaAction.clearSaveAndApprove());
		}
		if (this.props.anaesthesia.fetchOneSubTreatmentComplete === "FULFILLED") {
			if (this.props.anaesthesia.selectedSubTreatment) {
				this.state.elementsDetails = this.props.anaesthesia.selectedSubTreatment;
				this.state.selectedExistingAnaesthesia = { label: this.state.elementsDetails.subTreatmentName ? this.state.elementsDetails.subTreatmentName : "", value: this.state.elementsDetails.subTreatmentId }; 
				this.state.selectedNewAnaesthesia = { label: this.state.elementsDetails.subTreatmentName ? this.state.elementsDetails.subTreatmentName : "", value: this.state.elementsDetails.subTreatmentId }; 
				this.state.isloading = true;
				this.state.subTreatmentApproved = this.state.elementsDetails.subTreatmentApproved ? this.state.elementsDetails.subTreatmentApproved : false;
			}
			this.props.dispatch(anaesthesiaAction.clearGetOneSubtreatment());
		}
		if (this.props.anaesthesia.fetchOneSubTreatmentComplete === "REJECTED") {
			this.props.dispatch(anaesthesiaAction.clearGetOneSubtreatment());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Anesthesia", utils.getLanguage())}
					visible={this.props.showAssignAndApprove}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.subTreatmentApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.elementsDetails.subTreatmentCode}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.elementsDetails.subTreatmentName}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.elementsDetails.hospitalName ? this.state.elementsDetails.hospitalName : ""}</p>
							</div>
						</div>
						{this.state.subTreatmentApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Edit or Add new Anesthesia" />
										</Radio>
									</div>
									<div className="col-md-12 pt-10">
										<div className="form-group margin-bottom-0 pl-20">
											<SelectNewAnesthesia
												defaultValue="Select Anesthesia"
												data={this.state.selectedNewAnaesthesia}
												dataChanged={this.newAnaesthesiaChanged.bind(this)}
												disabled={this.state.value != 1}
												checked={this.state.value}
												elementId={this.props.elementId}
												statusCheck={this.statusCheck.bind(this)}
												type={this.props.type}
											/>
											{this.state.invalidNewAnaesthesia && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select anesthesia" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Existing Approved Anesthesia" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<SelectExistingAnesthesia
												defaultValue="Select Anesthesia"
												disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedExistingAnaesthesia}
												checked={this.state.value}
												type={this.props.type}
												elementId={this.props.elementId}
												statusCheck={this.statusCheck.bind(this)}
												dataChanged={this.existingAnaesthesiaChanged.bind(this)} />
											{this.state.invalidExistingAnaesthesia && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select anesthesia" /></span>}
										</div>
									</div>

								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.subTreatmentApproved ?
									<I18 tkey="The anaesthiesia for this intervention is waiting for approval. Please approve the anaesthiesia first" />
									: ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApprovePopup.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	anaesthesia: store.anaesthesia,
	elements: store.elements,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApprovePopup));    