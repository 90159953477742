import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import Section from "./section";

class Community extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="activities_table_scroll">
				<Switch>
					<Route path={locations.community_section} component={Section} />
					<Redirect to={locations.community} />
				</Switch>
			</div>
		);
	}
}

Community.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Community));
