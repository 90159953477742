import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import communitySectionAction from "../../../../../../../actions/community-section";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import Loader from "../../../../../../../plug/loader";
import ReactTable from "../../../../../../../plug/reactTable";
import I18, { i18Get } from "../../../../../../../i18";
import eventNames from "../../../../../../../actions/events";
import column from "./column";
import { Checkbox, Button } from "antd";
import ActionButton from "../../../../../../../plug/action-button";

class CommunityShare extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySectionList: [],
			totalPages: 1,
			loading: false,
			search: "",
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			sort: "",
			desc: 0,
			filter: {},
			checkList: [],
			checkedAll: false
		};
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.setState({ loading: true });
		let queryString = "?";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.props.clinicalPathwayData && this.props.clinicalPathwayData.id ? `&treatmentId=${this.props.clinicalPathwayData.id}` : "";
		queryString += this.state.search ? `&code=${encodeURIComponent(this.state.search)}&name=${encodeURIComponent(this.state.search)}&` : "";
		this.state.fetchAllMeta = Date.now();
		this.props.dispatch(communitySectionAction.getAll(queryString, this.state.fetchAllMeta));

	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.treatments = [];
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	onCellCheckBoxChange = (e, community) => {
		if (!e.target.checked) {
			this.setState({ ...this.state, checkedAll: e.target.checked });
		}
		this.checkListChanged(community.id);
	};

	renderCheckbox(community) {
		return (
			<Checkbox
				checked={this.state.checkList.indexOf(community.id) !== -1}
				onChange={(e) => this.onCellCheckBoxChange(e, community) }/>
		);
	}

	checkListChanged(id) {
		const index = this.state.checkList.indexOf(id);
		const prevState = this.state;
		if (index === -1) {
			prevState.checkList.push(id);
		} else {
			prevState.checkList.splice(index, 1);
		}
		this.setState(prevState);
	}

	clearCheckList = () => {
		this.setState({ ...this.state, checkList: [] });
	};

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.communitySectionList, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => {return (<Loader loading={this.state.loading} isTable/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), 
					column((e) => this.renderCheckbox(e)), 
					true, false, 
					this.onPageChange.bind(this), "COMMUNITY SECTION", "Assign_template_treatment_table_fcell", this.onPageSizeChange.bind(this))}/>
		);
	}

	shareClicked() {
		this.setState({ loading: true });
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			communityIds: this.state.checkList
		};
		this.props.dispatch(clinicalPathwayDetailsAction.communityShare(this.props.clinicalPathwayData.id, payload));
	}

	render() {
		if (this.props.communitySection.isFetchAllCommunitySectionCompleted === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.communitySection.fetchAllElementClassMeta) {
				this.state.loading = false;
				this.state.communitySectionList = this.props.communitySection.communitySection ? this.props.communitySection.communitySection : [];
				this.state.checkList = this.state.communitySectionList.filter(({ isChecked }) => isChecked).map(({ id }) => id);
				this.state.totalPages = parseInt(Math.ceil(this.props.communitySection.count / this.state.pageSize));
			}
			this.props.dispatch(communitySectionAction.clearGetAll());
		}
		if (this.props.clinicalPathwayDetails.communityShareCompleted === "FULFILLED") {
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.state.checkList = [];
			this.fetchDataFromServer();
			this.props.reloadDetails();
			this.props.closeCommunityShare();
			this.props.dispatch(clinicalPathwayDetailsAction.communityShareCompleted());
		}
		if (this.props.clinicalPathwayDetails.communityShareCompleted === "REJECTED") {
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(clinicalPathwayDetailsAction.communityShareCompleted());
		}

		return (
			<div>
				{this.getList()}
				<div className="copy_btn_modal margin-bottom-10 margin-top-20">
					<Button type="primary" className="margin-right-10 " onClick={this.props.closeCommunityShare}><I18 tkey='Cancel' /></Button>
					<ActionButton type="primary" className="" onClick={this.shareClicked.bind(this)} value={i18Get("Share", utils.getLanguage())} />
				</div>
			</div>
		);
	}
}

CommunityShare.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	communitySection: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	actions: store.actions,
	communitySection: store.communitySection,
	clinicalPathwayDetails: store.clinicalPathwayDetails
}))(CommunityShare));    