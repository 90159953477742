import React, { Component } from "react";
import { withRouter } from "react-router";
import { Badge } from "antd";
import I18 from "../../../../../../../i18";
import locations from "../../../../../../../router/locations";
import "./tab.css";

class Tab extends Component {
	constructor(props) {
		super(props);
	}

	checkForActiveTab(link) {
		return (this.props.location.pathname.indexOf(link) !== -1);
	}

	tabChanged(link) {
		if (link) {
			const toUrl = `${locations.interventions_plain}/${this.props.match.params.organization}${link}`;
			if (this.props.match.params.organization.toLowerCase() === locations.tabLocal) {
				localStorage.setItem(`INTERVENTION_${this.props.match.params.organization.toUpperCase()}_TAB`, toUrl);
			}
			this.props.history.push(toUrl);
		}
	}

	render() {
		return (
			<li className={this.checkForActiveTab(this.props.tabLocation) ? "active" : ""}>

				<a
					className="tabsmobile"
					data-toggle="tab"
					disabled={this.checkForActiveTab(this.props.tabLocation)}
					onClick={this.tabChanged.bind(this, this.props.tabLocation)}
				>
					{this.props.label == "Recommendation for Standard"
                                && <Badge count={this.props.count} className="mr-5" />}
					<I18 tkey={this.props.label} />
				</a>

			</li>
		);
	}
}

export default withRouter(Tab);
