import { NodeConstraints } from "@syncfusion/ej2-react-diagrams";
const updateDiagramDetails = (diagramInstance, nodeDetails) => {

    //UpdateDuration
    if (diagramInstance && nodeDetails) {

        const nodes = diagramInstance.nodes;

        nodeDetails.forEach(element => {
            const node = nodes.find(e => e.id == element.id);
            if (node?.addInfo?.customNodeData) {
                const customNodeData = node.addInfo.customNodeData;
                const annotation = node.annotations[0];

                let durationInMinutes = parseFloat(element.durationInMinutes) || null;
                let durationMaximumMinutes = parseFloat(element.durationMaximumMinutes) || null;
                let durationMinimumMinutes =  parseFloat(element.durationMinimumMinutes) || null;
                let unitCost = parseFloat(element.unitCost) || null;
                //==================================
                let c_durationInMinutes = parseFloat(customNodeData.durationInMinutes) || null;
                let c_durationMaximumInMinutes = parseFloat(customNodeData.durationMaximumInMinutes) || null;
                let c_durationMinimumInMinutes = parseFloat(customNodeData.durationMinimumInMinutes) || null;
                let c_unitCost = parseFloat(customNodeData.unitCost) || null;

                if (
                    c_durationInMinutes != durationInMinutes || 
                    c_durationMaximumInMinutes != durationMaximumMinutes ||
                    c_durationMinimumInMinutes != durationMinimumMinutes 
                ) 
                {
                        customNodeData.durationMinimumInMinutes = parseFloat(element.durationMinimumMinutes) || 0;
                        customNodeData.durationMaximumInMinutes = parseFloat(element.durationMaximumMinutes) || 0;
                        customNodeData.durationInMinutes = parseFloat(element.durationInMinutes) || 0;

                        customNodeData.durationMinimum = parseFloat(toMatchDayOrHourse(element.durationMinimumMinutes)) || null;
                        customNodeData.durationMaximum = parseFloat(toMatchDayOrHourse(element.durationMaximumMinutes)) || null;
                        customNodeData.duration = parseFloat(toMatchDayOrHourse(element.durationInMinutes)) || null;

                        const defaultType = "Minutes";
                        customNodeData.durationMinimumType = toMatchDayOrHourseCode(element.durationMinimumMinutes) || defaultType;
                        customNodeData.durationMaximumType = toMatchDayOrHourseCode(element.durationMaximumMinutes) || defaultType;
                        customNodeData.durationType = toMatchDayOrHourseCode(element.durationInMinutes) || defaultType;
  
                }

                if( c_unitCost != unitCost) {
                    customNodeData.unitCost = element.unitCost || null; 
                }

                diagramInstance.addLabels(node, annotation);
                diagramInstance.dataBind();
            }

        });
    }
};

const toMatchDayOrHourse = (minutes) => {
    const day = minutes / 1440;
    const hour =  minutes / 60;
    if (day >= 1) {
        return day.toFixed(2);
    } else if (hour >= 1) {
        return hour.toFixed(2);
    } else {
        return parseFloat(minutes).toFixed(2);
    }
  };

  const toMatchDayOrHourseCode = (minutes) => {
    const day = minutes / 1440;
    const hour =  minutes / 60;
    if (day >= 1) {
        return "Days";
    } else if (hour >= 1) {
        return "Hours";
    } else if (minutes > 0) {
        return "Minutes";
    }
  };

//   const nameSwitch = (valueType) => {
//     switch (valueType) {
//         case "MINUTES":
//             return "Minutes";
//         case "HOURS":
//             return "Hours";
//         case "DAYS":
//             return "Days";
//         default:  
//         return "Minutes";
//             break;
//     }
// };

export default {
    updateDiagramDetails
}