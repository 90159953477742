import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import LOCALANAESTHESIACREATE from "../../create-intervention/mom";
import screenId from "../../../../../../router/screenIdList";

class CreateAnaesthesiaMom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {},
			costCenter: "",
			name: ""
		};
	}

	render() {
		return (
			<div>
				<LOCALANAESTHESIACREATE typeActivity="ANAESTHESIA" screenId={screenId.anaesthesiaCreateMOM} />
			</div>
		);
	}
}

CreateAnaesthesiaMom.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateAnaesthesiaMom));
