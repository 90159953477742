import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import config from "./config.json";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import CardLink from "../../../dashboard/cards/card-link";

class Anaesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1,
			config: [],
			location: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
				|| location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	componentDidMount() {
		this.getConfig(this.props.match.params.organization, this.props.match.params.id);
	}

	getConfig(organization, id) {
		const configData = JSON.parse(JSON.stringify(config));
		const anaesthesiaConfig = configData.map((data) => {
			data.link = data.link ? data.link.replace("organization", organization) : "";
			data.link = data.link ? data.link.replace("id", id) : "";
			return data;
		});
		this.setState({ config: anaesthesiaConfig });
	}

	render() {
		return (
			<div className="mom-sidebar">
				{
					this.state.config.map((cardLink) => {
						if (cardLink) {
							return this.renderContents(cardLink);
						}
					})
				}
			</div>
		);
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {
		if (cardLink.expandable) {
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}
		return <CardLink data={cardLink} />;
	}
}

Anaesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Anaesthesia));
