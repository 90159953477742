import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import familyActions from "../../../../../../../../actions/consomable-sterilization";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import Speciality from "../../specialities/selectSpecialities";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import screenId from "../../../../../../../../router/screenIdList";

class CreateFamily extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			speciality: false,
			invalidName: false,
			invalidCode: false,
			invalidSpeciality: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.activityFamilyCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	nameChanged(value) {
		this.setState({ name: value, invalidName: false });
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.code = text;
		} else {
			prevState.code = this.state.code ? this.state.code : "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	specialityChanged(e) {
		const prevState = this.state;
		prevState.speciality = e;
		prevState.invalidSpeciality = false;
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !(this.state.name.trim())) {
			prevState.invalidName = true;
		}
		if (!this.state.speciality || !this.state.speciality.value) {
			prevState.invalidSpeciality = true;
		}
		if (!utils.regexFamilyCode.test(this.state.code)) {
			prevState.invalidCode = true;
		}
		this.setState(prevState);
		if (!this.state.invalidName && !this.state.invalidSpeciality && !this.state.invalidCode) {
			return true;
		}
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name,
				code: this.state.code,
				consumableSpecialityId: this.state.speciality.value,
				consumableType: "STERILIZATION"
			};
			this.props.dispatch(familyActions.createFamily(payload));
		}
	}

	render() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="margin-bottom-10 subheader">
						<span>
							<I18 tkey="Type" />
              :
							{" "}
						</span>
						<span><I18 tkey="Sterilization" /></span>
&nbsp;&nbsp;

					</div>
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<label className="code_label"><I18 tkey="* Code must be 2-digit uppercase characters" /></label>
								<NameInput
									value={this.state.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={2}
								/>
								{this.state.invalidCode && <span className="invalid_right fixed_label"><I18 tkey="Enter valid code" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={120}
								/>
								{this.state.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Select Speciality" /></label>
								<Speciality
									data={this.state.speciality}
									specialityChanged={this.specialityChanged.bind(this)}
									organization={utils.checkForParameter("organization", this.props.location)}
									typeActivity={utils.checkForParameter("typeActivity", this.props.location)}
								/>
								{this.state.invalidSpeciality && <span className="invalid_right fixed_label"><I18 tkey="Select speciality" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="di w_100 text_align_right margin-top-25-check">
								<ActionButton showAdd onClick={this.createClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate() {
		if (this.props.consomableSterilization.createFamilyStatus === "FULFILLED") {
			this.props.history.push(locations.sterilization_families);
			this.props.dispatch(familyActions.createFamilyCompleted());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.consomableSterilization.createFamilyStatus === "REJECTED") {
			this.props.dispatch(familyActions.createFamilyCompleted());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
	}
}

CreateFamily.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	consomableSterilization: store.consomableSterilization,
	translations: store.translations
}))(CreateFamily));
