import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import anaesthesiaActions from "../../../../../../../../actions/anaesthesia";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SelectExistingAnesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anesthesiaList: [],
			offset: 0,
			numResults: 100,
			search: null,
			checked: 1
		};
	}

	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchAnesthesiaList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchAnesthesiaList();
	}


	fetchAnesthesiaList() {
		if (this.props.checked === 2) {
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			if (this.props.checked === 2 && this.props.type !== "STERILIZATION") {
				this.props.dispatch(anaesthesiaActions.getAllExistingAnaesthesia(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), this.state.search, "", this.props.match.params.id, "", "", this.props.exType, ("mode=EXISTING"), this.props.elementId));
			}
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchAnesthesiaList(search);
				}, 800);
			}
		}
	}

	onStatusChange = () => {
		this.props.statusCheck("EXISTING_CONSUMABLE");
		return (this.props.data);
	};

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.anesthesiaList) {
			this.state.anesthesiaList.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	render() {
		if (this.props.anaesthesia.getExistingAnesthesiaStatus === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.anesthesiaList = unionWith(this.state.anesthesiaList, this.props.anaesthesia.existingAnesthesia, isEqual);
			this.renderOptions();
			this.props.dispatch(anaesthesiaActions.clearGetAllExistingAnaesthesia());
		}
		if ((this.props.checked !== this.state.checked)) {
			this.state.checked = this.props.checked;
			this.state.anesthesiaList = [];
			this.state.offset = 0;
			this.state.options = [];
			this.fetchAnesthesiaList();
		}
		if (!this.props.anaesthesia.getExistingAnesthesiaStatus && this.state.fetchDataFromserver) {
			this.fetchAnesthesiaList();
		}
		return (
			<Select
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.dataChanged}
				isClearable={true}
				value={this.state.options ? this.state.options.filter(options => options.value === this.props.data.value ? this.onStatusChange() : "") : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Anesthesia", utils.getLanguage())}
			/>
		);
	}

	componentDidUpdate() {
		// if( this.props.elementId && this.props.elementId !== this.state.elementId){
		//     this.state.elementId = this.props.elementId;
		//     this.fetchAnesthesiaList();
		// }
	}
}

SelectExistingAnesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	anaesthesia: store.anaesthesia,
	translations: store.translations
}))(SelectExistingAnesthesia));                    