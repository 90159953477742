import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { DebounceInput } from "react-debounce-input";
import { FilterOutlined } from "@ant-design/icons";
import locations from "../../../../../../../router/locations";
import CreateCategoryPersonnel from "./create";
import EditCategoryPersonnel from "./edit";
import ListCategoryPersonnel from "./list";
import BreadCrumb from "../../../breadcrumb";
import "./category_personnel.css";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import StatusDropDown from "../../../intervention/statusDropDown";
import HospitalSelect from "../../activities/specialities/selectHospital";

class CategoryPersonnel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryPersonnel: {},
			filter: false,
			filterName: "all",
			search: "",
			totalPages: 0,
			status: "ALL",
			hospital: { value: null, label: i18Get("All", utils.getLanguage()) }

		};
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Categories of Personnel", utils.getLanguage()), link: locations.categoryPersonnel });
		if (this.props.location.pathname === `${locations.categoryPersonnel}/create`) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: `${locations.categoryPersonnel}/create` });
		}
		if (this.props.location.pathname === `${locations.categoryPersonnel}/${this.state.categoryPersonnel.id}`) {
			items.push({ name: this.state.categoryPersonnel.name, link: `${locations.categoryPersonnel}/${this.state.categoryPersonnel.id}` });
		}
		return items;
	}

	searchChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		prevState.loading = true;
		this.setState(prevState);
	}

	addClicked() {
		this.props.history.push(`${locations.categoryPersonnel}/create`);
	}

	statusChanged(e) {
		this.state.status = e;
		this.setState(this.state);
	}

	hospitalChanged(e) {
		this.state.hospital = e;
		this.setState(this.state);
	}

	filterClicked() {
		this.setState({ filter: !this.state.filter });
	}

	filterSelected(e) {
		this.setState({ filterName: e });
	}

	showTop() {
		if (this.props.location.pathname === locations.categoryPersonnel) {
			return true;
		}
		return false;
	}

	totalCount(count) {
		this.setState({ totalPages: (count || 0) });
	}

	clearFilter() {
		this.setState({ hospital: { value: null, label: i18Get("All", utils.getLanguage()) }, status: "ALL", search: "" });
	}

	render() {
		this.state.categoryPersonnel = this.props.breadcrumb.componentData;
		return (
			<div className="structure_table_scroll">
				<div className="main_overview_section">
					<div className="pole_container">
						<BreadCrumb items={this.generateItems()} showRecord totalRecords={this.state.totalPages} />
						{this.showTop() && (
							<div className="pole_create_section w_100 padding-top-15 padding-bottom-15 d-flex align-items-center justify-content-between">
								<button className="common_button float_left margin-right-20 mt_15" onClick={this.addClicked.bind(this)}>
									<i className="fa fa-plus margin-right-5" aria-hidden="true" />
&nbsp;
									<I18 tkey="CREATE" />
								</button>
								<div className="d-flex align-items-center">
									<div className="min_width_300">
										<DebounceInput
											className="filter_input_design form-control"
											debounceTimeout={750}
											placeholder={i18Get("Search...", utils.getLanguage())}
											value={this.state.search}
											onChange={this.searchChanged.bind(this)}
										/>
									</div>
									<div className="filter  margin-left-15" onClick={this.filterClicked.bind(this)}>
										<FilterOutlined  className={` ${this.state.filter ? "filter_icon active" : "filter_icon"} margin-top-neg-3`} type="filter" />
									</div>
								</div>
							</div>
						)}
						{this.showTop() && this.state.filter
							&& (
								<div className="row">
									<div className="col-md-4 col-sm-4 ">
										<div className=" w_100 margin-bottom-5">
											<span className="min_width_fmly  margin-top-7 label_name">
												<I18 tkey="Status" />
											</span>
											<StatusDropDown
												data={this.state.status}
												onStatusChange={this.statusChanged.bind(this)}
											/>
										</div>
									</div>
									<div className="col-md-4 col-sm-4 ">
										<div className=" w_100 margin-bottom-5">
											<span className="min_width_status  margin-top-7 label_name">
												<I18 tkey="Hospital" />
											</span>
											<HospitalSelect
												data={this.state.hospital}
												hospitalChanged={this.hospitalChanged.bind(this)}
											/>
										</div>
									</div>
									<div className="col-sm-4">
										<div className="clear_fliter text_align_right">
											<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
												<I18 tkey="Clear Filter" />
											</a>
										</div>
									</div>
								</div>
							)}
						<Switch>
							<Route exact path={`${locations.categoryPersonnel}/create`} component={CreateCategoryPersonnel} />
							<Route exact path={locations.editCategoryPersonnel} component={EditCategoryPersonnel} />
							<Route path={locations.categoryPersonnel} render={() => <ListCategoryPersonnel search={this.state.search} status={this.state.status} hospitalId={this.state.hospital && this.state.hospital.value ? this.state.hospital.value : ""} filterName={this.state.filterName} totalCount={this.totalCount.bind(this)} />} />
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

CategoryPersonnel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(CategoryPersonnel));
