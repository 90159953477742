import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import familyModelActions from "../../../../../../../actions/family-model";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class FamilySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			family: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true
		};
	}
   
	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.setState({ ...this.state });
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.setState({ ...this.state }, () => {this.fetchFamilyList(this.state.search);});
        
	}

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	fetchFamilyList(search) {
		if (!this.state.fetchingData) {    
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			this.setState({ ...this.state });
			let queryString;
			queryString = "offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + "&typeActivity=" + this.props.typeActivity + (this.state.specialityId ? ("&specialityId=" + this.state.specialityId) : "") + "&status=APPROVED" + (search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "");
			this.props.dispatch(familyModelActions.fetchAllFamilyList(queryString));
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.setState({ ...this.state }, () => {this.fetchFamilyList(search);});
				}, 800);
			}
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.family) {    
			this.state.family.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}

	render() {
		if (this.props.familyModel.isFetchAllFamilyCompleted === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.family = unionWith(this.state.family, this.props.familyModel.familyList.family, isEqual);
			this.renderOptions();  
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound ) {
				this.fetchFamilyList(false);
				this.state.isDataFound = false;
			} 
			this.setState({ ...this.state });
			this.props.dispatch(familyModelActions.clearFetchAllFamilyList());
		}

		if ((this.props.typeActivity !== this.state.typeActivity) || (this.props.specialityId !== this.state.specialityId)) {
			this.state.typeActivity = this.props.typeActivity;
			this.state.specialityId = this.props.specialityId;
			this.state.family = [];
			this.state.offset = 0;
			this.state.options = [];
			this.state.fetchDataFromserver = true;
			this.setState({ ...this.state });
		}
		if (!this.props.familyModel.isFetchAllFamilyCompleted && this.state.fetchDataFromserver) {       
			this.fetchFamilyList(true);
		}
		return (   
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.onFamilyChanged}
				value={this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : ""}
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Family", utils.getLanguage())}
			/>
		);
	}
}

FamilySelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	familyModel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	familyModel: store.familyModel,
	translations: store.translations
}))(FamilySelect));                    