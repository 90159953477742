import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import adminPersonnelActions from "../../../../../../../actions/admin-personnel";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SelectExistingTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			offset: 0,
			numResults: 100,
			search: null,
			checked: 1
		};
	}

	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.typeActivity = this.props.typeActivity;
		this.fetchList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchList();
	}


	fetchList() {
		if (this.props.checked === 2) {
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			if (this.props.checked === 2 && this.props.type !== "STERILIZATION") {
				let queryString = `sort=name&desc=0&offset=${this.state.offset}&numResults=${this.state.numResults}`;
				queryString += this.state.typeActivity ? `&typeActivity=${this.state.typeActivity}` : "";
				queryString += "&mode=EXISTING";
				queryString += `&categoryPersonnelTemplateId=${this.props.templateId}`;
				queryString += this.state.search ? `&search=${this.state.search}` : "";
				this.props.dispatch(adminPersonnelActions.fetchAllExistingTemplates(queryString));
			}
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchList(search);
				}, 800);
			}
		}
	}

	onStatusChange = () => {
		this.props.statusCheck("EXISTING_TEMPLATE");
		return (this.props.data);
	};

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.list) {
			this.state.list.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	render() {
		if (this.props.adminPersonnel.fetchAllExistingTemplateCompleted === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.list = unionWith(this.state.list, this.props.adminPersonnel.existingTemplateList.data, isEqual);
			this.renderOptions();
			this.props.dispatch(adminPersonnelActions.clearFetchAllExistingTemplates());
		}
		if ((this.props.checked !== this.state.checked)) {
			this.state.checked = this.props.checked;
			this.state.list = [];
			this.state.offset = 0;
			this.state.options = [];
			this.fetchList();
		}
		if (!this.props.adminPersonnel.getExistingTemplateStatus && this.state.fetchDataFromserver) {
			this.fetchList();
		}
		return (
			<Select
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.dataChanged}
				isClearable={true}
				value={this.state.options ? this.state.options.filter(options => options.value === this.props.data.value ? this.onStatusChange() : "") : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Category Personnel", utils.getLanguage())}
			/>
		);
	}

	componentDidUpdate() {
		// if( this.props.elementId && this.props.elementId !== this.state.elementId){
		//     this.state.elementId = this.props.elementId;
		//     this.fetchAnesthesiaList();
		// }
	}
}

SelectExistingTemplate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminPersonnel: store.adminPersonnel,
	translations: store.translations
}))(SelectExistingTemplate));                    