import eventNames from "./event";

const initialState = {
	fetchAllHealthPathWayDiagramRules: false,
	healthPathWayDiagramRules: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_DIAGRAM_RULE}_FULFILLED`:
			return {
				...state,
				healthPathWayDiagramRules: action.payload.data.data,
				fetchAllHealthPathWayDiagramRules: "FULFILLED"
			};
		case `${eventNames.GET_DIAGRAM_RULE}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayDiagramRules: "REJECTED"
			};
	
		case eventNames.CLEAR_GET_DIAGRAM_RULE:
			return {
				...state,
				healthPathWayDiagramRules: [],
				fetchAllHealthPathWayDiagramRules: false
			};
		default:
			return state;
	}
}
