import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import specialityModelActions from "../../../../../../../../actions/speciality-model";
import SimplePopupTable from "../../../../../../../../plug/simple-mode-table";
const CommunityList = (props) => {
	const [list, setList] = useState([]);
	const [loading, setLoading] = useState(false);
	const specialty = useSelector(store => store.specialty);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(specialityModelActions.getSpecialityCommunity(props.specialityId));
		setLoading(true);
	}, []);
	useEffect(() => {
		if (specialty.getSpecialityCommunityDataStatus === "FULFILLED") {
			setList(specialty.specialityCommunityList);
			setLoading(false);
		} else if (specialty.getSpecialityCommunityDataStatus === "REJECTED") {
			setLoading(false);
		}
		dispatch(specialityModelActions.clearSpecialityCommunity());
		
	}, [specialty.getSpecialityCommunityDataStatus]);

	return <SimplePopupTable
		title={"COMMUNITY LIST"}
		visible={props.visible}
		onCancel={props.onCancel}
		footer={false}
		data={list}
		isLoading={loading}
	/>;
};
export default CommunityList;