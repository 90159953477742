import Axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getDiagramData = (healthPathwayId) => ({
	type: events.GET_DIAGRAM_DATA,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}/diagram`)
});

const clearGetDiagramData = () => ({
	type: events.CLEAR_GET_DIAGRAM_DATA,
	payload: {}
});

const saveDiagramData = (healthPathwayId, payload) => ({
	type: events.SAVE_DIAGRAM_DATA,
	payload: Axios.put(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}/diagram`, payload)
});

const clearSaveDiagramData = () => ({
	type: events.CLEAR_SAVE_DIAGRAM_DATA,
	payload: {}
});

const saveSettings = (healthPathwayId, payload) => ({
	type: events.SAVE_DIAGRAM_SETTINGS,
	payload: Axios.put(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}/diagram`, payload)
});

const clearSaveSettings = () => ({
	type: events.CLEAR_SAVE_DIAGRAM_SETTINGS
});

const saveDiagramAsImage = (formData) => ({
	type: events.SAVE_DIAGRAM_AS_IMAGE,
	payload: Axios.post(`${Config.apiRoot}/syncfusion/upload/diagram`, formData)
});

const clearSaveDiagramAsImage = () => ({
	type: events.CLEAR_SAVE_DIAGRAM_AS_IMAGE,
	payload: {}
});

const getMetaDiagramData = (healthPathwayId, meta) => ({
	type: events.GET_META_DIAGRAM_DATA,
	meta: meta,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}/diagram`)
});

const clearMetaGetDiagramData = () => ({
	type: events.CLEAR_META_GET_DIAGRAM_DATA,
	payload: {}
});

const getDiagramCopyValidation = (sourceId, destinationId) => ({
	type: events.GET_DIAGRAM_COPY_VALIDATION,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/${destinationId}/copyFrom/${sourceId}/validation`)
});

const clearGetDiagramCopyValidation = () => ({
	type: events.CLEAR_GET_DIAGRAM_COPY_VALIDATION,
	payload: {}
});

const checkDiagramLockValidation = (type, healthPathwayId, payload, meta ) => ({
	type: events.GET_DIAGRAM_LOCK_VALIDATION,
	payload: Axios.put(`${Config.apiRootSecure}/lock/check/${type}/${healthPathwayId}`, payload),
	meta: meta
});

const removeDiagramLock = (type, healthPathwayId, payload) => ({
	type: events.GET_DIAGRAM_LOCK_REMOVE,
	payload: Axios.put(`${Config.apiRootSecure}/lock/remove/${type}/${healthPathwayId}`, payload)
});
const clearCheckDiagramLockValidation = () => ({
	type: events.CLEAR_GET_DIAGRAM_LOCK_VALIDATION,
	payload: {}
});

export default {
	getDiagramData,
	clearGetDiagramData,
	saveDiagramData,
	clearSaveDiagramData,
	saveDiagramAsImage,
	clearSaveDiagramAsImage,
	saveSettings,
	clearSaveSettings,
	getMetaDiagramData,
	clearMetaGetDiagramData,
	getDiagramCopyValidation,
	clearGetDiagramCopyValidation,
	checkDiagramLockValidation,
	removeDiagramLock,
	clearCheckDiagramLockValidation
};
