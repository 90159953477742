import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../../router/locations";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import { Modal, Table, Tooltip } from "antd";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import AssignAndApproveModel from "./assign-and-approve-model";
import { MenuOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import clpDailySubSteps from "../../../../../../../../constants/clpDailySubSteps.json";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
import { Resizable } from "react-resizable";
import Pagination from "../../../../../../../../plug/pagination";
import { statusList } from "../../../../../../../../constants";

const DragHandle = sortableHandle(() => (<MenuOutlined style={{ cursor: "grab", color: "#999" }} />));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

//max-height: calc(100vh - 250px);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class SubSteps extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subStepList: [],
			totalPages: 1,
			loading: false,
			search: "",
			deleteSubStepVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedSubStep: {},
			sort: "rank",
			desc: 0,
			rank: {
				min: 1,
				max: 1
			},
			disableRankUpdate: false,
			status: "ALL",
			hospitalId: "",
			modelVisible: false,
			subStepId: "",
			enableReorder: this.props.enableReorder,
			name: 450,
			code: 120,
			tableBlankSpace: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelList });
	}

	editClicked(subStep) {
		return () => {
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}/${subStep.clpSubStepId}${this.props.location.search}`);
		};
	}

	deleteClicked(subStep) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedSubStep: subStep, deleteSubStepVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deleteSubStepVisible: false });
	}

	deleteClinicalPathwaySubStep() {
		this.props.dispatch(adminClinicalPathwayActions.deleteClinicalPathwaySubStep(this.state.deletedSubStep.clpSubStepId));
		this.setState({ deleteSubStepVisible: false, deletedSubStep: {} });
	}

	getTableOptions() {
		return { page: this.state.page, pageSize: this.state.pageSize, sort: this.state.sort, desc: this.state.sort };
	}

	approveClicked(subStep) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, subStepId: subStep.clpSubStepId });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		if (this.state.stepId) {
			// let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : '';
			// queryString += this.state.pageSize ? `numResults=${this.state.pageSize}` : '';
			let queryString = !this.props.enableReorder && this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "offset=0&";
			queryString += !this.props.enableReorder && this.state.pageSize ? `numResults=${this.state.pageSize}` : "numResults=100";
			queryString += this.state.sort ? `&sort=${this.state.sort ? this.state.sort : "rank"}&desc=${this.state.desc}` : "";
			queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
			queryString += this.state.status ? `&status=${this.state.status}` : "";
			queryString += this.state.stepId ? `&clpStepId=${this.state.stepId}` : "";
			queryString += this.props.step.code ? `&clpStepCode=${this.props.step.code}` : "";
			queryString += this.state.search ? `&search=${this.state.search}` : "";
			if (this.props.enableReorder) {
				this.setState({ page: 1, pageSize: 100 }, () => {
					this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwaySubStep(queryString));
				});
			} else {
				this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwaySubStep(queryString));
			}

		}
		else {
			this.state.loading = false;
			this.state.subStepList = [];
		}
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex;
		this.setState({ ...this.state }, () => {
			this.onFetchData({ pageSize: this.state.pageSize }, null, { order: (this.state.desc == "ascend" ? 0 : 1), field: this.state.sort });
		});
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange(value) {
		this.setState({ ...this.state, pageSize: value, page: 1 }, () => { this.onPageChange(this.state.page); });
	}

	getActions(row, statusList, isPermissionGranted) {
		return (
			<div className="margin_left_auto min_w_34 text_align_left">
				{!this.props.enableReorder ?
					<span>
						{row.status === statusList.STATUS.values.APPROVED && row.name !== clpDailySubSteps.type[row.name] && isPermissionGranted("EDIT") ?
							<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row)} />
							: ""
						}
						{row.status === statusList.STATUS.values.WAITING_FOR_APPROVAL && isPermissionGranted("EDIT") ?
							<a className="waiting-for-approve" onClick={this.approveClicked(row)}><img src="/images/waitingforapprovel.png" /> </a> : ""}
						{row.status === statusList.STATUS.values.APPROVED && !row.isMandatory && isPermissionGranted("DELETE") ?
							<DeleteOutlined className="delete_color" type="delete" onClick={this.deleteClicked(row)} /> : ""}
					</span>
					: <DragHandle />
				}
			</div>

		);
	}

	getText(value) {
		return <div>{value}</div>;
	}

	getColumns = () => [
		{
			title: i18Get("ACTIONS", utils.getLanguage()),
			key: "action",
			render: row => { return row.clpSubStepId ? (this.getActions(row, statusList, this.props.isPermissionGranted)) : <span>&#10240;</span>; },
			width: "70px"
		},
		{
			title: i18Get("CODE", utils.getLanguage()),
			dataIndex: "code",
			key: "code",
			render: text => (
				this.getText(text ? text : "")
			),
			width: this.state.code,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("code")
			})
		},
		{
			title: i18Get("NAME", utils.getLanguage()),
			dataIndex: "name",
			key: "name",
			render: text => (
				this.getText(text ? text : "")
			),
			width: this.state.name,
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("name")
			})
		},
		{
			title: i18Get("STATUS", utils.getLanguage()),
			//dataIndex: "status",
			key: "status",
			render: text => {
				return text.status ?
					<Tooltip placement="top" title={i18Get((text.status ? text.status === "APPROVED" ? "Approved" : "Waiting For Approval" : "") + " - From " + (text.hospitalName ? text.hospitalName : "Master"), utils.getLanguage())}>
						<div className="d-flex align-items-center">
							{text.hospitalName ? text.hospitalName : "Master"}
							<div className={`hospitalname_tag margin-left-10 ${text.hospitalName ? "hospitalnameactive" : ""}`}></div>
						</div>
					</Tooltip> : "";
			},
			width: "100px"
		}
	];


	getList(list) {
		utils.fakeRowsGenerator(this.getColumns(), list, 15);
		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns()}
				bordered
				rowKey="clpSubStepId"
				key={0}
				components={{
					header: {
						cell: ResizableTitle
					},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	}

	DraggableBodyRow = ({ ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex(
			(x) => x.clpSubStepId === restProps["data-row-key"]
		);
		return <SortableItem index={index} {...restProps} />;
	};

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	onSortEnd = ({ oldIndex, newIndex }, list) => {
		if (oldIndex !== newIndex) {
			const prevState = this.state;
			prevState.subStepList = arrayMove(
				[].concat(list),
				oldIndex,
				newIndex
			).filter((el) => !!el);

			Array.prototype.splice.apply(
				list,
				[0, prevState.subStepList.length].concat(prevState.subStepList)
			);
			this.setState({ list: prevState.subStepList, orderChanged: true });
			this.props.reorderList(prevState.subStepList, "subStepList");
		}
	};

	handleResize = label => (e, { size }) => {
		switch (label) {
			case "name":
				this.setState({ name: size.width });
				break;
			case "code":
				this.setState({ code: size.width });
				break;
			default:
				break;
		}
	};

	calcTableBlankSpace = () => {
		const prevState = this.state;
		const itemCount = prevState.subStepList.length;
		const difference = 15 - itemCount;
		if (difference > 0) {
			prevState.tableBlankSpace = 31 * difference;
		} else {
			prevState.tableBlankSpace = 0;
		}
		this.setState(prevState);
	};

	render() {
		if ((this.state.search !== this.props.search) || (this.state.stepId !== (this.props.step && this.props.step.value ? this.props.step.value : null))
			|| (this.state.hospitalId !== this.props.hospitalId) || (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.stepId = this.props.step && this.props.step.value ? this.props.step.value : null;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.subStepList = [];
			this.state.loading = true;
			this.state.page = 1;
			this.fetchDataFromServer();
		}
		if (this.props.adminClinicalPathway.fetchAllClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminClinicalPathway.subStepList.count / this.state.pageSize);
			this.state.subStepList = this.props.adminClinicalPathway.subStepList.data;
			this.props.reorderList(this.state.subStepList, "subStepList");
			this.props.getCount(this.props.adminClinicalPathway.subStepList.count);
			this.props.dispatch(adminClinicalPathwayActions.clearFetchAllClinicalPathwaySubStep());
			this.calcTableBlankSpace();
		}
		if (this.props.adminClinicalPathway.deleteClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch({ type: events.REFRESH_SUB_STEP, payload: {} });
			this.props.dispatch(adminClinicalPathwayActions.clearDeleteClinicalPathwaySubStep());
		}
		return (
			<div>
				{this.getList(this.state.subStepList)}
				<Pagination
					page={this.state.page}
					totalPage={this.state.totalPages}
					pageSize={this.state.pageSize}
					onPageSizeChange={this.onPageSizeChange.bind(this)}
					onPageChange={this.onPageChange.bind(this)}
					style={{ marginTop: `${this.state.tableBlankSpace}px` }} />
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete sub type", utils.getLanguage())}
					visible={this.state.deleteSubStepVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteClinicalPathwaySubStep.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedSubStep.name}</span> ?
					</div>
				</Modal>
				{this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							clinicalPathwayType={this.props.clinicalPathwayType && (this.props.clinicalPathwayType !== "ALL") ? this.props.clinicalPathwayType : ""}
							refreshCompleted={this.refreshCompleted}
							subStepId={this.state.subStepId}
							stepId={this.state.stepId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
	componentDidUpdate() {
		if (this.state.enableReorder != this.props.enableReorder) {
			this.setState({ enableReorder: this.props.enableReorder });
			this.fetchDataFromServer();
		}
	}
}

SubSteps.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminClinicalPathway: store.adminClinicalPathway
}))(SubSteps));    