import React, { Component } from "react";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

const { Option } = Select;
const searchFields = ["SEARCH_ALL_FIELDS", "CODE", "ITEM", "INTERVENANT", "DELIVERABLE", "REFERENCE", "RISK", "ASSESSMENT"];

export default class FieldDropdown extends Component {
	constructor(props) {
		super(props);
	}

	renderFields() {
		return searchFields.map((field) => <Option key={field} value={field === "DELIVERABLE" ? "DESCRIPTION" : field}><I18 tkey={field.replace(/_/g, " ")} /></Option>);
	}

	render() {
		return (
			<div className="select_task_pathway">
				<div className="w_100 di  position_relative">
					{/* <label><I18 tkey="Search Field" /></label> */}
					<Select
						key="SEARCH_FIELD"
						allowClear
						value={this.props.searchField ? this.props.searchField : utils.sameDropper()}
						className="w_100 di"
						defaultActiveFirstOption={false}
						onChange={this.props.searchFieldChanged}
						placeholder={i18Get("Select Search Field", utils.getLanguage())}
						notFoundContent={i18Get("Not Found", utils.getLanguage())}
						optionFilterProp="children"
					>
						{this.renderFields()}
					</Select>
				</div>
			</div>
		);
	}
}
