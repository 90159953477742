import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { Modal } from "antd";
import elementClassAction from "../../../../../../../../../actions/element-class";
import ReactTable from "../../../../../../../../../plug/reactTable";
// import '../../../../../../../../../plug/reactTable/react-table.css';
import Loader from "../../../../../../../../../plug/loader";
import column from "./column";
import locations from "../../../../../../../../../router/locations";
import AssignAndApproveModel from "./assign-and-approve-model";
const ALL = "ALL";

class ConsumableClassesList  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumableClassesList: [],
			page: utils.checkForParameter("page", this.props.location) ? parseInt(utils.checkForParameter("page", this.props.location)) : 1,
			pageSize: utils.checkForParameter("pageSize", this.props.location) ? parseInt(utils.checkForParameter("pageSize", this.props.location)) : localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : 10,
			sort: "",
			desc: 1,
			refresh: false,
			search: "",
			totalPages: 1,
			loading: true,
			deleteVisible: false,
			modelVisible: false,
			consumable: true,
			modalData: {},
			deletedConsumableClass: {},
			type: "",
			fetchAllMeta: null,
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			filter: {
				search: "",
				consumableType: "ALL"
			},
			showAssingPopup: false,
			assingRow: {}
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillMount() {
		this.props.totalCount(0);
	}

	editClicked(consumableClass) {
		return () => {
			this.generateQueryParams();
			this.props.history.push(locations.consumable_definition_plain + `/${locations.tabConsumableClass}/${consumableClass.id}${this.props.location.search}`);
		};
	}

	deleteClicked(consumableClass) {
		return () => {
			this.setState({ deletedConsumableClass: consumableClass, deleteVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	deleteConsumableClass() {
		this.props.dispatch(elementClassAction.deleteConsumableClass(this.state.deletedConsumableClass.id));
		this.setState({ deletedConsumableClass: {}, deleteVisible: false });
	}

	filterChanged(filter) {
		const prevState = this.state;
		prevState.search = filter;
		prevState.page = 1;
		this.setState(prevState, this.fetchDataFromServer());
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = this.props.sort ?  this.props.sort : "";
			prevState.desc = this.props.desc ? this.props.desc : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.props.setSort(prevState.sort, prevState.desc);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.setState({ loading: true });
		let queryString = "?";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.filter.selectedStatus ? `&status=${this.state.filter.selectedStatus}` : "";
		queryString += this.state.filter.selectedHospital && this.state.filter.selectedHospital.value ? `&hospitalId=${this.state.filter.selectedHospital.value}` : "";
		queryString += this.state.filter.consumableType && (this.state.filter.consumableType !== ALL)  ? `&type=${this.state.filter.consumableType}` : "";
		queryString += this.state.filter.search ? `&code=${encodeURIComponent(this.state.filter.search)}&name=${encodeURIComponent(this.state.filter.search)}&` : "";
		this.state.fetchAllMeta = Date.now();
		this.props.dispatch(elementClassAction.getAll(queryString, this.state.fetchAllMeta));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.rowScrollIndex = 0;
		this.generateQueryParams();
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	fetchOnFilterChange(filter, ignorePageChange) {
		if (!ignorePageChange) {
			this.state.page = 1;
		}
		this.state.loading = true;
		this.state.filter = filter;
		this.generateQueryParams();
		this.fetchDataFromServer();
	}

	generateQueryParams() {
		let queryParams = `page=${this.state.page}&pageSize=${this.state.pageSize}&rowScrollIndex=${this.state.rowScrollIndex}&`;
		queryParams += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		Object.keys(this.state.filter).forEach((key) => {
			if (typeof(this.state.filter[key]) !== "undefined" && this.state.filter[key] !== null && this.state.filter[key] !== "") {
				// if(key === 'selectedSupplier') {
				//     queryParams += `${key}=${JSON.stringify(this.state.filter[key])}&`;
				// } else {
				queryParams += `${key}=${this.state.filter[key]}&`;
				// }                
			}
		});

		this.props.history.push(this.props.location.pathname  + "?" + queryParams);
	}

	onPageSizeChange(pageSize) {
		this.state.page = 1;
		this.state.pageSize = pageSize;
		this.state.rowScrollIndex = 0;
		this.generateQueryParams();
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	approveClicked(consumableClass) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, consumableClassId: consumableClass.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	renderList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.consumableClassesList, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true}/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), 
					column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.approveClicked.bind(this)), true, false, 
					this.onPageChange.bind(this), "CONSUMABLE CLASSES", "Virtualized_react_table_scroll", this.onPageSizeChange.bind(this))}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex}
			/>);
	}

	render() {
		if (this.props.elementClass.isFetchAllElementClassCompleted === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.elementClass.fetchAllElementClassMeta) {
				this.state.loading = false;
				this.props.createClassCompleted(false);
				this.state.consumableClassesList = this.props.elementClass.elementClass ? this.props.elementClass.elementClass : [];
				this.state.totalPages = parseInt(Math.ceil(this.props.elementClass.count / this.state.pageSize));
				this.props.totalCount(this.props.elementClass.count);
			}
			this.props.dispatch(elementClassAction.clearElementClassFetch());
		}
		if (this.props.elementClass.deleteElementClassComplete === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(elementClassAction.clearDeleteConsumableClass());
		}
		if (this.props.elementClass.filterChangedElementClass) {
			this.fetchOnFilterChange(this.props.elementClass.filterChangedElementClass, this.props.elementClass.ignorePageChangeElementClass);
			this.props.dispatch(elementClassAction.filterChangeElementClass(null, false));
		}   
		if (this.props.refresh !== this.state.refresh) {
			this.state.refresh = this.props.refresh;
			this.fetchDataFromServer();
		}
		return ( 
			<Fragment>
				<div className=" di w_100">
					{this.renderList()}
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete consumable class", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteConsumableClass.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedConsumableClass.class}</span> ?
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							refreshCompleted={this.refreshCompleted}
							consumableClassId={this.state.consumableClassId}
							consumableType={this.state.filter.consumableType}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</Fragment>
		);
	}
}

ConsumableClassesList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elementClass: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	elementClass: store.elementClass,
	translations: store.translations
}))(ConsumableClassesList));                    