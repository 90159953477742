import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import "antd/dist/antd.min.css";
import "./index.css";
import "./plug/reactTable/react-table.css";
import store from "./store";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import setupInterceptors  from "./middlewares/network";
import MyRouter from "./router";
import "antd/dist/antd.min.css";
import "./index.scss";
setupInterceptors(store);

const root = ReactDOM.createRoot(document.getElementById("root"));
const broadcast = new BroadcastChannel("mom-digram-editor");
broadcast.postMessage("I am First");
broadcast.onmessage = (event) => {
	const uuid = sessionStorage.getItem("MOM-HealthPathway-Edit-uuid");
	if (event.data === "I am First") {
		if (uuid) {
			broadcast.postMessage(uuid);
		}
	}
	if (event.data === uuid) {
		if (uuid) {
			sessionStorage.removeItem("MOM-HealthPathway-Edit-uuid");
			window.location.reload();
		}	
	}
};
root.render(
	<Provider store={store}>
		<HashRouter>

			<MyRouter />
		</HashRouter>
	</Provider>
); 	

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();