import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllCategoryPersonnelCompleted: false,
	categoryPersonnelList: [],
	isFetchOneCategoryPersonnelCompleted: false,
	selectedCategoryPersonnel: {},
	isUpdateCategoryPersonnelCompleted: false,
	updatedCategoryPersonnel: {},
	isCreateCategoryPersonnelCompleted: false,
	createdCategoryPersonnel: {},
	isDeleteCategoryPersonnelCompleted: false,
	isDeleteSuccess: {}
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isFetchAllCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllCategoryPersonnelCompleted: "FULFILLED",
					categoryPersonnelList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isFetchAllCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CATEGORY_PERSONNEL:
			return {
				...state,
				isFetchAllCategoryPersonnelCompleted: false
			};
		case `${eventNames.FETCH_ONE_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isFetchOneCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneCategoryPersonnelCompleted: "FULFILLED",
					selectedCategoryPersonnel: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isFetchOneCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CATEGORY_PERSONNEL:
			return {
				...state,
				isFetchOneCategoryPersonnelCompleted: false
			};
		case `${eventNames.UPDATE_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isUpdateCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateCategoryPersonnelCompleted: "FULFILLED",
					updatedCategoryPersonnel: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isUpdateCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CATEGORY_PERSONNEL:
			return {
				...state,
				isUpdateCategoryPersonnelCompleted: false
			};
		case `${eventNames.CREATE_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isCreateCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.CREATE_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateCategoryPersonnelCompleted: "FULFILLED",
					createdCategoryPersonnel: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isCreateCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CATEGORY_PERSONNEL:
			return {
				...state,
				isCreateCategoryPersonnelCompleted: false
			};
		case `${eventNames.DELETE_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isDeleteCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.DELETE_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteCategoryPersonnelCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isDeleteCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CATEGORY_PERSONNEL:
			return {
				...state,
				isDeleteCategoryPersonnelCompleted: false
			};

		case `${eventNames.FETCH_ALL_APPROVED_CATEGORY_PERSONNEL}_PENDING`:
			return {
				...state,
				isFetchAllApprovedCategoryPersonnelCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_APPROVED_CATEGORY_PERSONNEL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllApprovedCategoryPersonnelCompleted: "FULFILLED",
					categoryPersonnelList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllApprovedCategoryPersonnelCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_APPROVED_CATEGORY_PERSONNEL}_REJECTED`:
			return {
				...state,
				isFetchAllApprovedCategoryPersonnelCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_APPROVED_CATEGORY_PERSONNEL:
			return {
				...state,
				isFetchAllApprovedCategoryPersonnelCompleted: false
			};

		case `${eventNames.SAVE_AND_APPROVE_CATEGORY_PERSONNEL}_FULFILLED`:
			return { ...state, saveAndApproveCategoryPersonnelStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_CATEGORY_PERSONNEL}_REJECTED`:
			return { ...state, saveAndApproveCategoryPersonnelStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED:
			return { ...state, saveAndApproveCategoryPersonnelStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL}_FULFILLED`:
			return { ...state, asssignAndApproveCategoryPersonnelStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL}_REJECTED`:
			return { ...state, asssignAndApproveCategoryPersonnelStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED:
			return { ...state, asssignAndApproveCategoryPersonnelStatus: false };
		default:
			return state;
	}
}
