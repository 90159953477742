import { i18Get } from "i18";
import utils from "utils/utils";
let languageSettings = utils.getLanguage();
setInterval(() => {
	const flowshapesClass =  document.getElementsByClassName("e-acrdn-content");
	if (flowshapesClass.length > 0 && languageSettings !== utils.getLanguage())
	{
		const flow = document.getElementById("flow");
		const basic = document.getElementById("basic");
		const connectors = document.getElementById("connectors");
		const flowDiv = flow.getElementsByTagName("div");
		for (let i = 0; i < flowDiv.length; i++) {
			const text = flowDiv[i].getAttribute("title");
			flowDiv[i].setAttribute("title", i18Get(text, utils.getLanguage()));
		}

		for (let i = 0; i < basic.length; i++) {
			const text = basic[i].getAttribute("title");
			basic[i].setAttribute("title", i18Get(text, utils.getLanguage()));
		}

		for (let i = 0; i < connectors.length; i++) {
			const text = connectors[i].getAttribute("title");
			connectors[i].setAttribute("title", i18Get(text, utils.getLanguage()));
		}
		languageSettings = utils.getLanguage();
	}
}, 1000);