import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { AutoComplete } from "antd";
import I18 from "../../../../../../../../../../i18";
import extClinicalPathwayAction from "../../../../../../../../../../actions/ext-clinical-pathway";
import events from "../../../../../../../../../../actions/events";

const { Option } = AutoComplete;

class SelectItemList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalCount: 0,
			extType: "",
			type: "",
			listNumber: 100,
			listOffset: 0,
			isLoading: false,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	fetchData() {
		this.setState({ isLoading: true });
		let queryString = "offset=0&numResults=100";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.props.match.params.id ? `&clinicalPathwayId=${this.props.match.params.id}` : "";
		queryString += this.props.extType ? `&clpSubStepId=${this.props.extType}` : "";
		queryString += this.state.search ? `&search=${this.state.search}` : "";
		queryString += this.props.isEdit ? "" : `&ignoreExisting=${true}`;
		queryString += this.props.days ? `&days=${this.props.days}` : "";
		this.props.dispatch(extClinicalPathwayAction.fetchAllExtClinicalPathwayForType(queryString));
	}

	scroll() {
		this.state.listOffset = this.state.listOffset + this.state.listNumber;
		this.fetchData();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.state.listOffset = 0;
			this.timer = setTimeout(() => {
				this.fetchData();
			}, 800);
		}
	}

	onSelect(value) {
		if (this.state.search !== value) {
			this.state.search = value;
			this.state.listOffset = 0;
			this.fetchData(value);
		}
		//let selectedData = {};
		this.state.options.map((option) => {
			if (option.value === value) {
				//selectedData = option;
			}
		});
		// this.props.responsibleChanged(selectedData);
	}

	generateItemList() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.itemList) {
			this.state.itemList.map((data) => {
				prevState.options.push({ value: data.item, item: data.item, data: data.item });
			});
		}
		this.state.isLoading = false;
		this.setState(prevState);
	}

	getLoading() {
		return this.state.isLoading;
	}

	renderAutoCompleteOptions() {
		return (this.state.options && this.state.options.map((option) => (
			<Option key={option.item} value={option.item} data={option.item}>
				{option.data}
			</Option>
		)));
	}

	render() {
		if ((this.state.extType !== this.props.extType) || (this.props.type !== this.state.type) || (this.props.days !== this.state.days)) {
			this.state.itemList = [];
			this.state.options = [];
			this.state.extType = this.props.extType;
			this.state.type = this.props.type;
			this.state.days = this.props.days;
			this.fetchData();
		}
		if (this.props.extClinicalPathway.responsibleListUpdate) {
			this.state.listOffset = 0;
			this.state.itemList = [];
			this.state.options = [];
			this.state.search = "";
			this.fetchData();
			this.props.dispatch({ type: events.CLEAR_TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
		}
		if (this.props.extClinicalPathway.isFetchAllExtClinicalPathwayForTypeCompleted === "FULFILLED") {
			this.props.dispatch(extClinicalPathwayAction.clearFetchAllExtClinicalPathwayForType());
			this.state.itemList = this.props.extClinicalPathway.extClinicalPathwayTypeList.data;
			this.generateItemList();
			this.state.isLoading = false;
		}

		return (
			<div className="w_100 di  position_relative margin-bottom-10">
				{this.props.isEdit ? "" : <label className=""><I18 tkey="Select Items" /></label>}
				<AutoComplete
					className="w_100"
					options={this.state.options}
					onSearch={this.onSearch.bind(this)}
					onSelect={this.onSelect.bind(this)}
					onChange={this.props.onItemChanged}
					value={this.props.data ? this.props.data : null}
				>
					{this.renderAutoCompleteOptions()}
				</AutoComplete>
				{this.props.invalidItem && (
					<span className={`${this.props.isEdit ? "invalid_right_edit fixed_label" : "invalid_right fixed_label top_section_invalid"}`}>

						{this.props.invalidDataRgEx
							? <I18 tkey={"Special characters not allowed except ( ) + _ - ' ,."} />
							: <I18 tkey="Please select item" />}
					</span>
				)}
			</div>
		);
	}
}

SelectItemList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	extClinicalPathway: store.extClinicalPathway,
	elements: store.elements,
	translations: store.translations
}))(SelectItemList));
