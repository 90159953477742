import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllConsumableDefinition = (queryString, meta) => ({
	type: events.FETCH_ALL_CONSUMABLE_DEFINITION,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/elementDetails?${queryString}`)
});

const clearFetchAllConsumableDefinition = () => ({
	type: events.CLEAR_FETCH_ALL_CONSUMABLE_DEFINITION,
	payload: {}
});

const fetchOneConsumableDefinition = (id) => ({
	type: events.FETCH_ONE_CONSUMABLE_DEFINITION,
	payload: axios.get(`${Config.apiRootSecure}/elementDetails/${id}`)
});

const clearFetchOneConsumableDefinition = () => ({
	type: events.CLEAR_FETCH_ONE_CONSUMABLE_DEFINITION,
	payload: {}
});

const updateConsumableDefinition = (id, payload) => ({
	type: events.UPDATE_CONSUMABLE_DEFINITION,
	payload: axios.put(`${Config.apiRootSecure}/elementDetails/${id}`, { ...payload })
});

const clearUpdateConsumableDefinition = () => ({
	type: events.CLEAR_UPDATE_CONSUMABLE_DEFINITION,
	payload: {}
});

const updateConsumableDefinitionMinimal = (id, payload) => ({
	type: events.UPDATE_CONSUMABLE_DEFINITION_MINIMAL,
	payload: axios.put(`${Config.apiRootSecure}/elementDetails/${id}/minimal`, { ...payload })
});

const clearUpdateConsumableDefinitionMinimal = () => ({
	type: events.CLEAR_UPDATE_CONSUMABLE_DEFINITION_MINIMAL,
	payload: {}
});

const deleteConsumableDefinition = (id) => ({
	type: events.DELETE_CONSUMABLE_DEFINITION,
	payload: axios.delete(`${Config.apiRootSecure}/elementDetails/${id}`)
});

const clearDeleteConsumableDefinition = () => ({
	type: events.CLEAR_DELETE_CONSUMABLE_DEFINITION,
	payload: {}
});

const fetchAllConsumableDci = (queryString) => ({
	type: events.FETCH_ALL_CONSUMABLE_DCI,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCIMapping?${queryString}`)
});

const clearFetchAllConsumableDci = () => ({
	type: events.CLEAR_FETCH_ALL_CONSUMABLE_DCI,
	payload: {}
});

const fetchOneConsumableDci = (id) => ({
	type: events.FETCH_ONE_CONSUMABLE_DCI,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCIMapping/${id}`)
});

const clearFetchOneConsumableDci = () => ({
	type: events.CLEAR_FETCH_ONE_CONSUMABLE_DCI,
	payload: {}
});

const updateConsumableDci = (id, payload, query) => ({
	type: events.UPDATE_CONSUMABLE_DCI,
	payload: axios.put(`${Config.apiRootSecure}/chronicDCIMapping/${id}?${query}`, { ...payload })
});

const clearUpdateConsumableDci = () => ({
	type: events.CLEAR_UPDATE_CONSUMABLE_DCI,
	payload: {}
});

const deleteConsumableDci = (id) => ({
	type: events.DELETE_CONSUMABLE_DCI,
	payload: axios.delete(`${Config.apiRootSecure}/chronicDCIMapping/${id}`)
});

const clearDeleteConsumableDci = () => ({
	type: events.CLEAR_DELETE_CONSUMABLE_DCI,
	payload: {}
});

const fetchAllDciList = (queryString) => ({
	type: events.FETCH_ALL_DCI_LIST,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCI?${queryString}`)
});

const clearFetchAllDciList = () => ({
	type: events.CLEAR_FETCH_ALL_DCI_LIST,
	payload: {}
});

const updateDciElement = (dciId, payload) => ({
	type: events.UPDATE_DCI_ELEMENT,
	payload: axios.put(`${Config.apiRootSecure}/chronicDCI/${dciId}`, { ...payload })
});

const clearUpdateDciElement = () => ({
	type: events.CLEAR_UPDATE_DCI_ELEMENT,
	payload: {}
});

const fetchAllPreparationPerfusion = (dciId, type, query) => ({
	type: events.FETCH_ALL_DCI_PREPARATION_PERFUSION,
	meta: type,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCI/${dciId}/element/type/${type}?${query}`)
});

const clearFetchAllPreparationPerfusion = () => ({
	type: events.CLEAR_FETCH_ALL_DCI_PREPARATION_PERFUSION,
	payload: {}
});

const getOneDci = (dciId) => ({
	type: events.GET_ONE_DCI,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCI/${dciId}`)
});

const clearGetOneDci = () => ({
	type: events.CLEAR_GET_ONE_DCI,
	payload: {}
});

const createDciPerfusionPreparation = (dciId, payload) => ({
	type: events.CREATE_DCI_PERFUSION_PREPARATION,
	payload: axios.post(`${Config.apiRootSecure}/chronicDCI/${dciId}/element`, { ...payload })
});

const clearCreateDciPerfusionPreparation = () => ({
	type: events.CLEAR_CREATE_DCI_PERFUSION_PREPARATION,
	payload: {}
});

const deleteDciPerfusionPreparation = (dciId, type, id) => ({
	type: events.DELETE_DCI_PREPARATION_PERFUSION,
	payload: axios.delete(`${Config.apiRootSecure}/chronicDCI/${dciId}/element/${id}?type=${type}`)
});

const clearDeleteDciPerfusionPreparation = () => ({
	type: events.CLEAR_DELETE_DCI_PREPARATION_PERFUSION,
	payload: {}
});

const fetchAllElementsForDci = (query) => ({
	type: events.FETCH_ALL_ELEMENTS_FOR_DCI,
	payload: axios.get(`${Config.apiRootSecure}/elements/dci?${query}`)
});

const clearFetchAllElementsForDci = () => ({
	type: events.CLEAR_FETCH_ALL_ELEMENTS_FOR_DCI,
	payload: {}
});

const getOnePreparationPerfusion = (dciId, elementId, query) => ({
	type: events.GET_ONE_PREPARATION_PERFUSION_FOR_DCI,
	payload: axios.get(`${Config.apiRootSecure}/chronicDCI/${dciId}/element/${elementId}${query ? `?${query}` : ""}`)
});

const clearGetOnePreparationPerfusion = () => ({
	type: events.CLEAR_GET_ONE_PREPARATION_PERFUSION_FOR_DCI,
	payload: {}
});

const updatePreparationPerfusion = (dciId, id, payload) => ({
	type: events.UPDATE_PREPARATION_PERFUSION_FOR_DCI,
	payload: axios.put(`${Config.apiRootSecure}/chronicDCI/${dciId}/element/${id}`, { ...payload })
});

const clearUpdatePreparationPerfusion = () => ({
	type: events.CLEAR_UPDATE_PREPARATION_PERFUSION_FOR_DCI,
	payload: {}
});

const preparationPerfusionRankUpdate = (dciId, elementId, payload) => ({
	type: events.PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI,
	payload: axios.put(`${Config.apiRootSecure}/chronicDCI/${dciId}/element/${elementId}/rank`, { ...payload })
});

const clearPreparationPerfusionRankUpdate = () => ({
	type: events.CLEAR_PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI,
	payload: {}
});

const treatmentListForDci = (consumableId, organization) => ({
	type: events.TREATMENT_LIST_FOR_DCI,
	payload: axios.get(`${Config.apiRootSecure}/dciTreatmentMapping/${consumableId}/treatments/${organization}`)
});

const clearTreatmentListForDci = () => ({
	type: events.CLEAR_TREATMENT_LIST_FOR_DCI,
	payload: {}
});

const fetchAllDciElement = (query) => ({
	type: events.FETCH_ALL_DCI_ELEMENT,
	payload: axios.get(`${Config.apiRootSecure}/element/chronicDCI?${query}`)
});

const clearFetchAllDciElement = () => ({
	type: events.CLEAR_FETCH_ALL_DCI_ELEMENT,
	payload: {}
});

const fetchOneConsumableClass = (id) => ({
	type: events.FETCH_ONE_CONSUMABLE_CLASS,
	payload: axios.get(`${Config.apiRootSecure}/consumableClass/${id}`)
});

const clearFetchOneConsumableClass = () => ({
	type: events.CLEAR_FETCH_ONE_CONSUMABLE_CLASS,
	payload: {}
});

const updateConsumableClass = (id, payload) => ({
	type: events.UPDATE_CONSUMABLE_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/consumableClass/${id}`, { ...payload })
});

const clearUpdateConsumableClass = () => ({
	type: events.CLEAR_UPDATE_CONSUMABLE_CLASS,
	payload: {}
});

const filterChangeConsumableClass = (filter, ignorePageChange) => ({
	type: events.FILTER_CHANGE_CONSUMABLE,
	payload: { filter, ignorePageChange }
});

const saveAndApproveConsumableClass = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_CONSUMABLE_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/consumableClass/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveConsumableClassCompleted = () => ({
	type: events.SAVE_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED,
	payload: {}
});

const assignAndApproveConsumableClass = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_CONSUMABLE_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/consumableClass/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveConsumableClassCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED,
	payload: {}
});

const fetchMinimalConsumableSpeciality = (query) => ({
	type: events.GET_MINIMAL_CONSO_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality${query}`)
});

const clearfetchMinimalConsumableSpeciality = () => ({
	type: events.CLEAR_GET_MINIMAL_CONSO_SPECIALITY,
	payload: ""
});

const fetchMinimalConsumableFamily = (query) => ({
	type: events.GET_MINIMAL_CONSO_FAMILY,
	payload: axios.get(`${Config.apiRootSecure}/consumableFamily${query}`)
});

const clearFetchMinimalConsumableFamily = () => ({
	type: events.CLEAR_GET_MINIMAL_CONSO_FAMILY,
	payload: ""
});

export default {
	fetchAllConsumableDefinition,
	clearFetchAllConsumableDefinition,
	fetchOneConsumableDefinition,
	clearFetchOneConsumableDefinition,
	updateConsumableDefinition,
	clearUpdateConsumableDefinition,
	deleteConsumableDefinition,
	clearDeleteConsumableDefinition,
	fetchAllConsumableDci,
	clearFetchAllConsumableDci,
	fetchOneConsumableDci,
	clearFetchOneConsumableDci,
	updateConsumableDci,
	clearUpdateConsumableDci,
	deleteConsumableDci,
	clearDeleteConsumableDci,
	fetchAllDciList,
	clearFetchAllDciList,
	updateDciElement,
	clearUpdateDciElement,
	fetchAllPreparationPerfusion,
	clearFetchAllPreparationPerfusion,
	getOneDci,
	clearGetOneDci,
	createDciPerfusionPreparation,
	clearCreateDciPerfusionPreparation,
	deleteDciPerfusionPreparation,
	clearDeleteDciPerfusionPreparation,
	fetchAllElementsForDci,
	clearFetchAllElementsForDci,
	getOnePreparationPerfusion,
	clearGetOnePreparationPerfusion,
	updatePreparationPerfusion,
	clearUpdatePreparationPerfusion,
	preparationPerfusionRankUpdate,
	clearPreparationPerfusionRankUpdate,
	treatmentListForDci,
	clearTreatmentListForDci,
	fetchAllDciElement,
	clearFetchAllDciElement,
	fetchOneConsumableClass,
	clearFetchOneConsumableClass,
	updateConsumableClass,
	clearUpdateConsumableClass,
	filterChangeConsumableClass,
	updateConsumableDefinitionMinimal,
	clearUpdateConsumableDefinitionMinimal,
	saveAndApproveConsumableClass,
	saveAndApproveConsumableClassCompleted,
	assignAndApproveConsumableClass,
	assignAndApproveConsumableClassCompleted,
	fetchMinimalConsumableSpeciality,
	clearfetchMinimalConsumableSpeciality,
	fetchMinimalConsumableFamily,
	clearFetchMinimalConsumableFamily
};
