import React, { Component } from "react";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ReactMultiSelectCheckboxes from "../../../../../../../plug/react-multiselect-checkboxes";
class InterventionLocationDropDown extends Component {

	constructor(props) {
		super(props);
		this.state = {
			options: [],
			startDayOptions: [],
			additionalInfoId: "",
			getDestSubSteps: [],
			checked: false
		};
	}

	getOptionLabel = data => {
		return `${i18Get(data.stepName, utils.getLanguage())} -> ${data.day ? i18Get("DAY", utils.getLanguage()) : ""} ${i18Get(data.subStepName, utils.getLanguage())}`;
	};

	filterOptionList() {
		const prevState = this.state;
		prevState.getDestSubSteps = this.renderOptions(this.props.stepsAndSubSteps.filter((data) => data.sectionType == this.props.sectionType));
		const getSourceSubSteps = this.renderOptions(this.props.subStepMapping);
		const data = this.getDifference(prevState.getDestSubSteps, getSourceSubSteps);
		if (data.length > 0) {
			this.props.onChange(data);
		} else {
			this.props.onChange([]);
		}
		this.setState(prevState);
	}

	getDifference(destSubSteps, sourceSubSteps) {
		return destSubSteps.filter(object1 => {
			return sourceSubSteps.some(object2 => {
				if (object1.value === object2.value && object1.day == object2.day) {
					return { object1 };
				}
			});
		});
	}

	renderOptions(data) {
		const options = [];
		data.map((data) => {
			options.push({ value: data.clpSubStepId ? data.clpSubStepId + data.day : data.id + data.day, clpSubStepId: data.clpSubStepId ? data.clpSubStepId : data.id, label: this.getOptionLabel(data), day: data.day, stepCode: data.stepCode, subStepCode: data.subStepCode });
		});
		return options;
	}

	render() {

		if (this.props.checked !== this.state.checked) {
			this.state.checked = this.props.checked;
			this.filterOptionList();
		}
		return (
			<div className={!this.props.disabled ? "w_100 di react_multi_select_checkbox drop_max_height_150" : "w_100 di"}>
				{
					<ReactMultiSelectCheckboxes 
						value={this.props.subStepMapping && this.props.subStepMapping.length > 0 ? this.props.subStepMapping : null}
						onChange={this.props.onChange}
						options={this.state.getDestSubSteps}
						isMulti={true}
						placeholderButtonLabel={i18Get("Select location of the interventions", utils.getLanguage())}
						MultiValue={false}
						noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
						loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
					/>
					// <ReactMultiSelectCheckboxes
					// 	value={this.props.subStepMapping ? this.props.subStepMapping : null}
					// 	onChange={this.props.onChange}
					// 	options={this.state.getDestSubSteps}
					// 	maxMenuHeight={220}
					// 	isMulti={true}
					// 	isClearable={true}
					// 	placeholderButtonLabel={i18Get('Select location of the interventions', utils.getLanguage())}
					// 	noOptionsMessage={() => { return i18Get('No Data Found', utils.getLanguage()); }}
					// 	loadingMessage={() => { return i18Get('Loading...', utils.getLanguage()); }}
					// />
				}
			</div>
		);
	}
}

export default InterventionLocationDropDown;
