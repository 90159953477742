import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { MenuOutlined } from "@ant-design/icons";
import { Modal, Button, Table, Checkbox } from "antd";
import standardCareChartActions from "../../../../../../../../actions/standard-care-chart";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Resizable } from "react-resizable";
import clinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc";
import arrayMove from "array-move";
import DragWindow from "../../drag-window";

const DragHandle = sortableHandle(({ active }) => (
	<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class QuickReorderData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			totalPages: 1,
			list: [],
			sorted: [],
			orderChanged: false,
			problemWidth: 300,
			codeWidth: 70,
			selectedItems: [],
			unSelect: false,
			pathwayPblm: false
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchReorderData();
	}

	fetchReorderData() {
		const key = this.props.clpStepFilter;
		if (this.props.clpStepFilter === "ALL_ITEMS") {
			const arrayList = [];
			if (Object.keys(this.props.quickReorderData.BEFORE_ADMISSION).length) {
				arrayList.push(...this.props.quickReorderData.BEFORE_ADMISSION.problems);
			}
			if (Object.keys(this.props.quickReorderData.CLINICAL_PATHWAY).length) {
				arrayList.push(...this.props.quickReorderData.CLINICAL_PATHWAY.problems);
			}
			if (Object.keys(this.props.quickReorderData.AFTER_CHECKOUT).length) {
				arrayList.push(...this.props.quickReorderData.AFTER_CHECKOUT.problems);
			}
			this.state.list = arrayList;
		}
		else if (this.props.quickReorderData[key] && this.props.quickReorderData[key].problems) {
			this.state.list = JSON.parse(JSON.stringify(this.props.quickReorderData[key].problems));
		}
		this.state.list = this.state.list.filter(e => !e.isCheckList || e.isHeading);
	}

	fetchDataFromServer() {
		this.setState({ scrollStatus: true });
		let queryString = "";
		queryString += "offset=0&";
		queryString += "numResults=10000&";
		queryString += this.props.clpStepFilter ? `stepSectionType=${this.props.clpStepFilter}&` : "";
		this.props.dispatch(clinicalPathwayProblemAction.getProblemListForReorder(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, this.props.match.params.organization, queryString));
	}

	onClickSaveOrder() {
		const reOrderedProblems = [];
		const newData = JSON.parse(JSON.stringify(this.state.list));
		if (newData && newData.length > 0) {
			newData.map(function (data, index) {
				reOrderedProblems.push({ additionalInfoId: data.additionalInfoId, rank: index + 1, interventionSummaryName: data.interventionSummaryName, problemId: data.problemId, isHeading: data.isHeading, clpItemId: data.itemId });
			});
			const payload = {
				reOrderedProblems: reOrderedProblems,
				isChart: true
			};
			this.setState({ enableDrag: true });
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdation(this.props.match.params.id, payload));
		} else {
			this.setState({ enableReorder: false }); 
		}
	}

	onClickUnselect = (e) => {
		this.setState({ unSelect: e.target.checked, selectedItems: [] });
	};

	onClickPathwayPblm = () => {
		this.state.list = [];
		this.fetchDataFromServer();
		this.setState({ unSelect: false, selectedItems: [], pathwayPblmLoading: true });
	};

	cancelPathwayPblm = () => {
		this.fetchReorderData();
		this.setState({ unSelect: false, selectedItems: [], pathwayPblm: false });
	};

	renderStyledCell = (text, isCheckList) => ({
		props: {
			style: { background: "" }
		},
		children: (
			<div className="d-flex">
				<div className="">{text ? this.getText(text, isCheckList) : ""}</div>
			</div>
		)
	});

	getText = (value, isCheckList) => {
		return (
			<div>
				<div>
					<span className={isCheckList ? "check_list check_list_bold" : ""}> {value} </span>
				</div>
			</div>
		);
	};

	handleResize = label => (e, { size }) => {
		switch (label) {
			case "problemWidth":
				this.setState({ problemWidth: size.width });
				break;
			case "codeWidth":
				this.setState({ codeWidth: size.width });
				break;
			default:
				break;
		}
	};

	onCheckBoxChange = (e, i, uniqueId) => {
		const prevState = this.state;
		const index = this.state.selectedItems.findIndex(value => value.uniqueId === uniqueId);
		if (index === -1) {
			prevState.selectedItems.push({ uniqueId: uniqueId, index: i });
		} else {
			prevState.selectedItems.splice(index, 1);
		}
		this.setState(prevState);
	};

	getColumns = (selectedItems) => [
		{
			title: "",
			dataIndex: "uniqueId",
			className: "",
			width: 60,
			render: (text, record, index) => (
				<div className="">
					<DragHandle active={selectedItems.length > 0 && selectedItems.findIndex((x) => x.index === index) !== -1} />
					<span className="margin-left-10" >
						<Checkbox
							checked={selectedItems.length > 0 && selectedItems.findIndex(value => value.uniqueId === text) !== -1}
							onChange={!this.state.unSelect ? (e) => this.onCheckBoxChange(e, index, text) : ""}
						/>
					</span>
				</div>
			)
		},
		{
			title: i18Get("PROBLEM", utils.getLanguage()),
			dataIndex: "problem",
			className: "drag-visible",
			width: this.state.problemWidth,
			render: (text, record) => this.renderStyledCell(record.problem, record.isCheckList),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("problemWidth")
			})
		},
		{
			title: i18Get("CODE", utils.getLanguage()),
			dataIndex: "itemId",
			className: "drag-visible",
			width: this.state.codeWidth,
			render: (text, record) => this.renderStyledCell(record.itemId, record.isCheckList),
			onHeaderCell: column => ({
				width: column.width,
				onResize: this.handleResize("codeWidth")
			})
		},
		{
			title: i18Get("INTERVENTIONS SUMMARY NAME", utils.getLanguage()),
			dataIndex: "interventionSummaryName",
			className: "drag-visible",
			render: (text, record) =>
				this.renderStyledCell(record.interventionSummaryName, record.isCheckList)
		}
	];

	merge(a, b, i = 0) {
		const aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	}

	updateIndex(tempDataSource) {
		const prevState = this.state;
		prevState.selectedItems.map(function (data, i) {
			const indexValue = tempDataSource.findIndex(value => value.uniqueId === data.uniqueId);
			if (indexValue !== -1) {
				prevState.selectedItems[i].index = indexValue;
			}
		});
		this.setState({ selectedItems: prevState.selectedItems });
	}

	getTaskList(list) {
		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns(this.state.selectedItems)}
				bordered
				rowKey="uniqueId"
				key={0}
				components={{
					header: {
						cell: ResizableTitle
					},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	}

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	DraggableBodyRow = ({ className, style, ...restProps }, subStep) => {
		const index = subStep.findIndex((x) => x.uniqueId === restProps["data-row-key"]);
		return <SortableItem className={className} style={style} index={index} {...restProps} />;
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { selectedItems, list } = this.state;
		let tempDataSource = JSON.parse(JSON.stringify(list));

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				tempDataSource = arrayMove([].concat(tempDataSource), oldIndex, newIndex).filter((el) => !!el);
			}
			else {
				const filteredItems = [];
				selectedItems.forEach((d) => {
					filteredItems.push(tempDataSource[d.index]);
				});
				const newData = [];
				tempDataSource.forEach((d, i) => {
					if (selectedItems.findIndex((x) => x.index === i) === -1) {
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = this.merge(tempDataSource, filteredItems, newIndex);
				this.updateIndex(tempDataSource, selectedItems);
			}
			this.setState({ list: tempDataSource, orderChanged: true });
		}
	};

	confirmation = () => {

		const { confirm } = Modal;
		if (this.state.orderChanged) {
			confirm({
				title: i18Get("Do you want to leave without saving?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "",
				okText: i18Get("Ok", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					this.props.dispatch(standardCareChartActions.toggleStandardCareChartRankUpdationModal(false));
				},
				onCancel: () => {
				}
			});
		} else {
			this.props.dispatch(standardCareChartActions.toggleStandardCareChartRankUpdationModal(false));
		}
	};

	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Quick Reorder", utils.getLanguage())} /></div>); };

	render() {

		if (this.props.clinicalPathwayProblems.getAllClpProblemListForReorder === "FULFILLED") {
			this.state.list = this.props.clinicalPathwayProblems.problemList && this.props.clinicalPathwayProblems.problemList.length > 0 ? this.props.clinicalPathwayProblems.problemList.filter(e => !e.isCheckList || e.isHeading) : [];

			this.setState({ pathwayPblmLoading: false, pathwayPblm: true });
			this.props.dispatch(clinicalPathwayProblemAction.clearGetProblemListForReorder());
		}
		if (this.props.clinicalPathwayProblems.getAllClpProblemListForReorder === "REJECTED") {
			this.setState({ pathwayPblmLoading: false });
			this.props.dispatch(clinicalPathwayProblemAction.clearGetProblemListForReorder());
		}
		{console.log(">>>>>>>>>>>>> this.props.standardCareChart.standardCareChartRankUpdation", this.props.standardCareChart.standardCareChartRankUpdation);}
		// if (this.props.standardCareChart.standardCareChartRankUpdation === "FULFILLED") {
		// 	console.log(">>>>>>>>>>>> INSIDE REORDER FULFILL");
		// 	this.props.disableProblemListReorder();
		// 	this.setState({ loading: false });
		// 	this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
		// }

		// if (this.props.standardCareChart.standardCareChartRankUpdation === "REJECTED") {
		// 	this.setState({ loading: false });
		// 	this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
		// }

		return (
			<div>
				{console.log(">>>>>>>>>>> INSIDE QUICK REORDER")}
				<Modal
					className="re_assign_modal create_problem create_additional quick_reorder_table_modal"
					title={this.title()}
					open
					onCancel={this.confirmation}
					wrapClassName="drag_modal"
					footer={[
						<Button key="back" onClick={this.confirmation}>
							<I18 tkey="Cancel" />
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={this.onClickSaveOrder.bind(this)}
							loading={this.props.standardCareChart.standardCareChartRankUpdationLoading}
						>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left margin-left-10" >
								{this.state.selectedItems.length} <I18 tkey="items selected" />
							</span>
							: "",
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left" >
								(<a className="text_underline" onClick={(e) => this.onClickUnselect(e)}>
									<I18 tkey="Deselect All" />
								</a>)
							</span> : "",
						(!this.state.pathwayPblm ?
							<Button
								key="submit"
								type="primary"
								onClick={this.onClickPathwayPblm.bind(this)}
								loading={this.state.pathwayPblmLoading}>
								<span className="margin-left-10" >
									<I18 tkey="Use order of the Plan de Soins Type" />
								</span>
							</Button> :
							<Button
								type="primary" key="back" onClick={this.cancelPathwayPblm.bind(this)}>
								<I18 tkey="Undo" />
							</Button>)
					]}
				>
					<div className="">{this.getTaskList(this.state.list)}</div>

				</Modal>
			</div>
		);
	}
}

QuickReorderData.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	standardCareChart: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		actions: store.actions,
		translations: store.translations,
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		standardCareChart: store.standardCareChart
	}))(QuickReorderData)
);