import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import NameInput from "../../../../../../../../plug/regex-name-search-input";

class CreateSubStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			invalidSubStepName: null,
			invalidStep: false,
			subStepName: ""
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.state.template = this.props.template ? this.props.template : utils.checkForParameter("templateId", this.props.location);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelCreate });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	subStepNameChanged(e) {
		const prevState = this.state;
		prevState.subStepName = e;
		prevState.invalidSubStepName = false;
		this.setState(prevState);
	}

	codeChanged(e) {
		const prevState = this.state;
		if (utils.checkCodeRegex(e)) {
			prevState.code = e;
			prevState.invalidCode = false;
		} else {
			prevState.code = "";
		}
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!prevState.subStepName || !prevState.subStepName.trim() || (prevState.subStepName.match(/\\$/)) || (prevState.subStepName.indexOf("\"") >= 0)) {
			prevState.invalidSubStepName = true;
		}
		// if (!prevState.code) {
		// 	prevState.invalidCode = true;
		// }
		if (!prevState.invalidSubStepName && !prevState.invalidCode) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.subStepName.trim(),
				// code: this.state.code.trim(),
				stepCode: this.props.step.code
			};
			this.props.dispatch(adminClinicalPathwayActions.createClinicalPathwaySubStep(this.props.step.value, payload));
		}
	}

	stepChanged(e) {
		const prevState = this.state;
		prevState.invalidStep = false;
		prevState.step = e;
		this.setState(prevState);
	}

	render() {
		if (this.props.adminClinicalPathway.createClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwaySubStep());
			this.props.history.push(locations.administration_clinical_pathway + "/" + locations.tabClinicalPathwaySubStep + this.props.location.search);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.createClinicalPathwaySubStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwaySubStep());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="bloc_create_section">
				<div className="bloc_create_box">
					<div className="row">
						{/* <div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<label className="code_label"> * <I18 tkey="Code must be 5-digit" /></label>
								<NameInput
									value={this.state.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={5} />
								{this.state.invalidCode ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid code' /></span> : ""}
							</div>
						</div> */}
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.subStepName}
									inputChanged={this.subStepNameChanged.bind(this)}
									maxLength={128} />
								{this.state.invalidSubStepName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid name (Special characters " \ not allowed)' /></span> : ""}
							</div>
						</div>
						{/* <div className="col-md-6 col-sm-6">
                            <div className="w_100 di margin-bottom-20 position_relative">
                                <label><I18 tkey="Step" /></label>
                                <StepDropdown
                                    data={this.state.step}
                                    isCreate={true}
                                    clinicalPathwayType={this.props.clinicalPathwayType}
                                    stepChanged={this.stepChanged.bind(this)} />
                                {this.state.invalidStep ? <span className='invalid_right fixed_label'><I18 tkey="Select Step" /></span> : ''}
                            </div>
                        </div> */}
					</div>

					<div className="">
						<div className="di w_100 text_align_right">
							<ActionButton
								showAdd
								onClick={this.createClicked.bind(this)}
								value={i18Get("CREATE", utils.getLanguage())}
							/>
						</div>
					</div>
				</div>
			</div>

		);
	}
}

CreateSubStep.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(CreateSubStep));
