import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal } from "antd";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";

class CommunityView extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		};
	}

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th>
							<I18 tkey="NAME" />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map((data) => (
				<tr key={data}>
					<td>{data}</td>
				</tr>));
		}

		return (
			<tr>
				<td><div className="text_align_center w_100"><I18 tkey="NO DATA FOUND" /></div></td>
				{" "}
			</tr>
		);
	}

	render() {
		return (

			<Modal
				title={i18Get("Community List", utils.getLanguage())}
				visible={this.props.view}
				onCancel={this.props.onClose}
				footer={null}
			>
				<div>
					<div className="margin-top-o-15p margin-bottom-10 scroll_window">
						{this.getTable(this.props.data)}
					</div>
				</div>
			</Modal>

		);
	}
}

CommunityView.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	communitySites: store.communitySites,
	translations: store.translations
}))(CommunityView));
