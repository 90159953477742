import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../constants";

class Extelements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			doctorId: ""
		};
	}

	getTable(list) {
		return (
			<table className="w_100">
				<thead>
					<tr>
						<th>

						</th>
						<th>
							<I18 tkey='CODE' />
						</th>
						<th>
							<I18 tkey='NAME' />
						</th>
						<th>
							<I18 tkey='IS OPTIONAL' />
						</th>
						<th>
							<I18 tkey='COST ONE' />
						</th>
						<th>
							<I18 tkey='QUANTITY' />
						</th>
						<th>
							<I18 tkey='HOSPITAL NAME' />
						</th>
						<th>
							<I18 tkey='STATUS' />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	isCodeEndWithLO = (code) => {
		if (code) {
			const res = code.split("-");
			if (res[0] && res[0].trim().length > 1) {
				const s = res[0].trim();
				if (s.substr(s.length - 2, s.length - 1) == "LO") {
					return true;
				}
			}
		}
		return false;
	};


	getTableContent(list) {
		if (list && list.length > 0) {
			return list.map((data, index) => {
				return (
					<tr key={index}>
						<td className="text_align_center">{
							data.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
								<a className="waiting-for-approve " onClick={() => this.props.onAssignAndApproveClick(data.elementId, this.props.type, data.elementName, data.code, data.type)}><img src="/images/waitingforapprovel.png" /> </a>
								: ""
						}
						</td>
						<td>{data.code ? data.code : " -- "}</td>
						<td>
							{
								data.elementName ? data.elementName : ""
							}

						</td>
						<td> {data.isOptional ? <span className="subsidy_bold"><I18 tkey='Yes' /></span> : <I18 tkey='No' />}</td>
						<td>{data.costOne ? <span className="subsidy_bold"><I18 tkey='Yes' /></span> : <I18 tkey='No' />}</td>
						{
							<td>{utils.setLocaleForCurrency(data.quantity ? data.quantity : 0.00)}</td>}
						<td>{data.hospitalName ? data.hospitalName : "Master"}</td>
						<td className="ex_width_160">{data.status ? data.status === "APPROVED" ? "Approved" : "Waiting For Approval" : ""}</td>
					</tr>

				);
			});
		}
		else {
			return (
				<tr>
					<td>
						<div className="text_align_center w_100">
							<I18 tkey={`NO ${this.props.type.replace(/_/g, " ").toUpperCase()} FOUND`} />
						</div>
					</td>
				</tr>
			);
		}
	}

	render() {
		if (this.props.interventionDetails.fetchAllExtendedElements === "FULFILLED") {
			this.state.list = this.props.interventionDetails.interventionData[this.props.type] ? this.props.interventionDetails.interventionData[this.props.type].data : [];
			this.state.subTotal = this.props.interventionDetails.interventionData[this.props.type] ? this.props.interventionDetails.interventionData[this.props.type].subTotal : 0;
			this.state.loading = false;
		}

		return (
			<div className="risk_preparation_container">
				<div className="table_heading"><I18 tkey={this.props.type.replace(/_/g, " ").toUpperCase()} /></div>
				<div className="table_div risk_table">{this.getTable(this.state.list)}</div>
			</div>
		);
	}
}

Extelements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	interventionDetails: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	interventionDetails: store.interventionDetails,
	translations: store.translations
}))(Extelements));