import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../plug/reactTable/react-table.css";
import anaesthesiaAction from "../../../../../../actions/anaesthesia";
import elementsAction from "../../../../../../actions/elements";
import SelectNewConsumbales from "./select-new-consumable";
import SelectExistingConsumbales from "./select-existing-consumable";

class AssignAndApprovePopup extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			elementName: "",
			elementCode: "",
			invalidName: false,
			invalidNewConsumable: false,
			invalidExistingConsumable: false,
			selectedNewConsumable: "",
			selectedExistingConsumable: "",
			elementsDetails: {},
			elementId: "",
			elementApproved: false,
			isloading: false,
			existingConsumableStatus: false,
			newConsumableStatus: false
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.elementId) {
			this.props.dispatch(elementsAction.getOne(this.props.match.params.id, this.props.elementId, this.props.type));  
		}
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ selectedNewConsumable: false, selectedExistingConsumable: false, invalidExistingConsumable: false, invalidNewConsumable: false, value: 1, elementId: "", newConsumableStatus: false, existingConsumableStatus: false });
		if (this.props.isDetailsPage) {
			this.props.closeAssignAndApprovePopup();
			this.props.refreshAnaesthesiaDetails(true, this.props.type);
		}
		else {
			this.props.refreshCompleted();
		}
	}

	newConsumbaleChanged(e) {
		this.setState({ selectedNewConsumable: e ? e : "", invalidNewConsumable: e && e.value ? false : true });
	}

	existingConsumbaleChanged(e) {
		this.setState({ selectedExistingConsumable: e ? e : "", invalidExistingConsumable: e && e.value ? false : true });
	}

	validateNewConsumable() {
		if (this.state.selectedNewConsumable && this.state.newConsumableStatus) {
			return true;
		}
		return false;
	}

	validateExistingConsumable = () => {
		if (this.state.selectedExistingConsumable && this.state.existingConsumableStatus) {
			return true;
		}
		return false;
	};

	statusCheck(status) {
		if (status === "NEW_CONSUMABLE") {
			this.state.newConsumableStatus = true;
		}
		if (status === "EXISTING_CONSUMABLE") {
			this.state.existingConsumableStatus = true;
			this.state.value = 2;
		}
	}

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateNewConsumable()) {
				payload = {
					elementId: this.state.selectedNewConsumable && this.state.selectedNewConsumable.value ? this.state.selectedNewConsumable.value : ""
				};
				this.props.dispatch(anaesthesiaAction.saveAndApproveConsumable(this.props.match.params.id, this.props.type, payload, this.props.elementId));
			}
			else {
				this.setState({ invalidNewConsumable: true });
			}
		}
		if (this.state.value === 2) {
			if (this.validateExistingConsumable()) {
				payload = {
					elementId: this.state.selectedExistingConsumable && this.state.selectedExistingConsumable.value ? this.state.selectedExistingConsumable.value : ""
				};
				this.props.dispatch(anaesthesiaAction.assignAndApproveConsumable(this.props.match.params.id, this.props.type, payload, this.props.elementId));
			}
			else {
				this.setState({ invalidExistingConsumable: true });
			}

		}

	}

	render() {
		const { value } = this.state;
		if (this.props.anaesthesia.asssignAndApproveElementStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewConsumable: false, selectedExistingConsumable: false, invalidExistingConsumable: false, elementId: "", invalidNewConsumable: false, value: 1, newConsumableStatus: false, existingConsumableStatus: false });
			if (this.props.isDetailsPage) {
				this.props.closeAssignAndApprovePopup();
				this.props.refreshAnaesthesiaDetails(true, this.props.type);
			}
			else {
				this.props.refreshCompleted();
			}
			this.props.dispatch(anaesthesiaAction.clearAssignAndApproveConsumable());
		}
		if (this.props.anaesthesia.saveAndApproveElementStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ selectedNewConsumable: false, selectedExistingConsumable: false, invalidExistingConsumable: false, elementId: "", invalidNewConsumable: false, value: 1, newConsumableStatus: false, existingConsumableStatus: false });
			if (this.props.isDetailsPage) {
				this.props.closeAssignAndApprovePopup();
				this.props.refreshAnaesthesiaDetails(true, this.props.type);
			}
			else {
				this.props.refreshCompleted();
			}
			this.props.dispatch(anaesthesiaAction.clearSaveAndApproveConsumable());
		}
		if (this.props.elements.isElementGetOneCompleted === "FULFILLED") {
			this.state.elementsDetails = this.props.elements.elementsDataOne;
			this.state.selectedExistingConsumable = { label: this.state.elementsDetails.name ? this.state.elementsDetails.name : "", value: this.state.elementsDetails.id }; 
			this.state.selectedNewConsumable = { label: this.state.elementsDetails.name ? this.state.elementsDetails.name : "", value: this.state.elementsDetails.id }; 
			this.state.isloading = true;
			this.state.elementApproved = this.state.elementsDetails.elementApproved ? this.state.elementsDetails.elementApproved : false;
			this.props.dispatch(elementsAction.clearGet());
		}
		if (this.props.elements.isElementGetOneCompleted === "REJECTED") {
			this.props.dispatch(elementsAction.clearGet());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Consumable", utils.getLanguage())}
					visible={this.props.showAssignAndApprove}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.elementApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.elementsDetails.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.elementsDetails.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.elementsDetails.hospitalName ? this.state.elementsDetails.hospitalName : ""}</p>
							</div>
						</div>
						{this.state.elementApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Add a Consumable to this Anesthesia" />
										</Radio>
									</div>
									<div className="col-md-12 pt-10">
										<div className="form-group margin-bottom-0 pl-20">
											<SelectNewConsumbales
												defaultValue="Select Consumable"
												data={this.state.selectedNewConsumable}
												dataChanged={this.newConsumbaleChanged.bind(this)}
												disabled={this.state.value != 1}
												checked={this.state.value}
												elementId={this.props.elementId}
												statusCheck={this.statusCheck.bind(this)}
												type={this.props.type}
											/>
											{this.state.invalidNewConsumable && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select consumable" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Use existing Consumable in this Anesthesia"/></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<SelectExistingConsumbales
												defaultValue="Select Consumable"
												disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedExistingConsumable}
												checked={this.state.value}
												type={this.props.type}
												elementId={this.props.elementId}
												statusCheck={this.statusCheck.bind(this)}
												dataChanged={this.existingConsumbaleChanged.bind(this)} />
											{this.state.invalidExistingConsumable && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select consumable" /></span>}
										</div>
									</div>

								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.elementApproved ?
									<I18 tkey="The consumable for this anaesthiesia is waiting for approval. Please approve the consumable first" />
									: ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApprovePopup.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	anaesthesia: store.anaesthesia,
	elements: store.elements,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApprovePopup));    