import React, { Component } from "react";
import { ClpStepFilters } from "..";
import I18 from "../../../../../../../i18";
import "./style.css";

class ClpStepFilter extends Component {
	render() {
		const { filter, changeFilter } = this.props;
		return (
			<div className="clp_step_filter parcours_filter parcours_filter_clinical_pathway margin-bottom-0 margin-right-0">
				{/* <div onClick={() => changeFilter(ClpStepFilters.ALL_ITEMS)} className="h-100">
					<button type="button" className={`h-100 btn ${filter === ClpStepFilters.ALL_ITEMS ? "parcours_filter_btn_active_left" : "parcours_filter_btn_left"}`} data-toggle="dropdown">
						<I18 tkey="All the items" />
					</button>
				</div> */}
				<div onClick={() => changeFilter(ClpStepFilters.ALL_ITEMS)} className="h-100">
					<button type="button" className={`h-100 btn ${filter === ClpStepFilters.ALL_ITEMS ? "parcours_filter_btn_active_left" : "parcours_filter_btn_left"}`} data-toggle="dropdown">
						<I18 tkey="Parcours" />
					</button>
				</div>
				<div onClick={() => changeFilter(ClpStepFilters.BEFORE_ADMISSION)} className="h-100">
					<button type="button" className={`h-100 btn ${filter === ClpStepFilters.BEFORE_ADMISSION ? "parcours_filter_btn_active" : "parcours_filter_btn_right"}`} data-toggle="dropdown">
						<I18 tkey="Before Admission" />
					</button>
				</div>
				<div onClick={() => changeFilter(ClpStepFilters.CLINICAL_PATHWAY)} className="h-100">
					<button type="button" className={`h-100 btn ${filter === ClpStepFilters.CLINICAL_PATHWAY ? "parcours_filter_btn_active" : "parcours_filter_btn_left"}`} data-toggle="dropdown">
						<I18 tkey="Hospitalisation" />
					</button>
				</div>
				<div onClick={() => changeFilter(ClpStepFilters.AFTER_CHECKOUT)} className="h-100">
					<button type="button" className={`h-100 btn ${filter === ClpStepFilters.AFTER_CHECKOUT ? "parcours_filter_btn_active_right" : "parcours_filter_btn_right"}`} data-toggle="dropdown">
						<I18 tkey="After Checkout" />
					</button>
				</div>
			</div>
		);
	}
}

export default ClpStepFilter;
