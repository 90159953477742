import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import { Collapse, Checkbox } from "antd";

const { Panel } = Collapse;

class AddOrRemoveStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkList: [],
			list: []
		};
	}

	UNSAFE_componentWillMount() {
	}

	onCheckBoxChange = (e, value, type, subStep) => {
		let index;
		if (type === "step") {
			index = this.state.checkList.indexOf(value.stepId);
		}
		if (type === "subStep") {
			index = this.state.checkList.indexOf(value.id);
		}
		const prevState = this.state;
		if (e.target.checked) {
			if (index === -1) {
				if (type === "step") {
					prevState.checkList.map(function (e) {
						if (e.stepName === value.stepName) {
							e.checked = true;
							e.subSteps.map(function (data) {
								data.checked = true;
							});
						}
					});

				}
				if (type === "subStep") {
					prevState.checkList.map(function (e) {
						if (e.stepName === value.stepName && e.stepId === value.stepId) {
							e.checked = true;
							e.subSteps.map(function (data) {
								if (data.name === subStep.name && data.id === subStep.id) {
									data.checked = true;
								}
							});
						}
					});
				}

			}
		}
		else {
			if (type === "step") {
				prevState.checkList.map(function (e) {
					if (e.stepName === value.stepName && e.stepId === value.stepId) {
						e.checked = false;
						e.subSteps.map(function (data) {
							data.checked = false;
						});
					}
				});

			}
			if (type === "subStep") {
				prevState.checkList.map(function (e) {
					if (e.stepName === value.stepName && e.stepId === value.stepId) {
						e.checked = true;
						e.subSteps.map(function (data) {
							if (data.name === subStep.name && data.id === subStep.id) {
								data.checked = false;
							}
						});
					}
				});
				// prevState.checkList.push({ stepId: value[1].stepId, checked: true, day: value.day, subStep: this.state.list });
			}
		}
		this.setState(prevState);
		this.props.getSelectedComponents(this.state.checkList);
	};

	getSuffix(step) {
		if (step.startPrefix !== step.endPrefix) {
			return <span>(<I18 tkey="DAY"/> <I18 tkey={step.startPrefix} /> {"->"} <I18 tkey="DAY" /> <I18 tkey={step.endPrefix} />): <I18 tkey={step.totalPrefixCount} /> <I18 tkey="DAYS" /> </span>;
		}
		else {
			return <span>(<I18 tkey="DAY"/> <I18 tkey={step.startPrefix} />)</span>;
		}
	}

	getSubStepName(subStep) {
		if (subStep) {
			if (subStep && subStep.day) {
				return <span><I18 tkey="DAY" /> <I18 tkey={subStep.name ? subStep.name : ""} />{" (" + subStep.itemCount} <I18 tkey={"Items"} />{")"}</span>;
			} else {
				return <span><I18 tkey={subStep.name ? subStep.name : ""} />{" (" + subStep.itemCount} <I18 tkey={"Items"} />{")"} </span>;
			}
		}
	}

	subStepDetails(subSteps, parentStepId, step) {
		return (
			<div className="pl-25">
				{subSteps && subSteps.length ?
					subSteps.map((subStep, index) => {
						return (<div className="" key={index}>
							{subStep && subStep.name !== "stepId" && subStep.name !== "defaultSubStepId" && subStep.name !== "nameSuffix" ?
								<div className=""><div></div>
									<div className="">
										<Checkbox
											checked={this.checkSubStep(this.state.checkList, subStep, parentStepId)}
											onChange={(e) => this.onCheckBoxChange(e, step, "subStep", subStep)} />&nbsp;&nbsp;{this.getSubStepName(subStep)}
									</div>

								</div>
								: ""}
						</div>);
					})
					: ""}

			</div>
		);
	}

	checkStepData(stepId) {
		if (this.state.checkList && this.state.checkList.length) {
			const index = this.state.checkList.findIndex(value => value.stepId === stepId && value.checked === true);
			if (index !== -1) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	checkSubStep(checkList, subStep, parentStepId) {
		if (checkList && checkList.length) {
			const checkListIndex = checkList.findIndex(row => row.stepId === parentStepId);
			if (checkListIndex < 0) {return false;}
			const index = checkList[checkListIndex].subSteps.findIndex(value => value.id === subStep.id && value.checked === true && value.name === subStep.name);
			if (index !== -1) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	callback() {}

	getPannelHeader = (step) => {
		return (
			<React.Fragment>
				<Checkbox
					checked={this.checkStepData(step.stepId)}
					onClick={event => event.stopPropagation()}
					onChange={(e) => this.onCheckBoxChange(e, step, "step")} />&nbsp;&nbsp; <span><I18 tkey={step.stepName} /> {step.startPrefix !== "" ? this.getSuffix(step) : ""} {" (" + step.stepItemsCount} <I18 tkey={"Items"} />{")"}</span>
			</React.Fragment>
		);
	};

	render() {

		this.state.checkList = this.props.checkList ? this.props.checkList : [];
		return (
			<Collapse ghost={true} >
				{
					this.props.steps.map((step, index) => <Panel header={this.getPannelHeader(step)} key={index}>
						<div>
							{this.subStepDetails(step.subSteps, step.stepId, step)}
						</div>
					</Panel>
					)
				}
			</Collapse>
		);
	}

}

AddOrRemoveStep.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(AddOrRemoveStep));