import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "actions/admin-clinical-pathway";
import NameInput from "plug/regex-name-search-input";
import { Checkbox, Modal } from "antd";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import locations from "router/locations";
import ActionButton from "plug/action-button";
import events from "actions/events";
import ClinicalPathwayTypeDropdown from "components/main-container/overview/main-overview/administration/clinical-pathway/clinical-pathway-type-dropdown";
import StepTypeDropdown from "components/main-container/overview/main-overview/administration/clinical-pathway/step-type-dropdown";
import SectionTypeDropDown from "components/main-container/overview/main-overview/administration/clinical-pathway/section-type-dropdown";
import { ClpStepFilters } from "components/main-container/overview/main-overview/clinical-pathway/details";
import screenIdList from "router/screenIdList";

class CreateStep extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			clinicalPathwayType: this.props.clinicalPathwayType ? this.props.clinicalPathwayType : utils.sameDropper(),
			isChecked1: false,
			isChecked2: true
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		if (this.props.inputStepName || this.props.healthPathwayData.clinicalPathway) {
			const prevState = this.state;
			prevState.name = this.props.inputStepName;
			prevState.clinicalPathwayType = [this.props.healthPathwayData.clinicalPathway];
			this.setState(prevState);
		}
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenIdList.siteCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.createClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		if (utils.checkCodeRegex(value)) {
			prevState.invalidCode = false;
			prevState.code = value;
		} else {
			prevState.code = "";
		}
		this.setState(prevState);
	}

	selectedClinicalPathwayType(e) {
		const prevState = this.state;
		prevState.invalidClinicalPathwayType = false;
		prevState.clinicalPathwayType = e;
		this.setState(prevState);
	}
	selectedStepType = (e, value) => {
		const prevState = this.state;
		prevState.invalidStepType = false;
		prevState.stepType = e;
		prevState.stepTypeDescription = value.props && value.props.description;
		this.setState(prevState);
	};

	selectedSectionType(e) {
		const prevState = this.state;
		prevState.invalidSectionType = false;
		prevState.sectionType = e;
		this.props.onChangecustomNodeData(e, "phase");
		this.setState(prevState);
	}

	handleCheckboxclp = (e) => {
		const prevState = this.state;
		prevState.isChecked1 = e.target.checked;
		prevState.invalidStepfield = false;
		this.setState(prevState);
	};
	
	handleCheckboxhealthPathway = (e) => {
		const prevState = this.state;
		prevState.isChecked2 = e.target.checked;
		prevState.invalidStepfield = false;
		this.setState(prevState);
	};
	
	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim() || (this.state.name.match(/\\$/)) || (this.state.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		// if (!this.state.code) {
		// 	prevState.invalidCode = true;
		// }
		if (!this.state.clinicalPathwayType || !this.state.clinicalPathwayType.length) {
			prevState.invalidClinicalPathwayType = true;
		}
		if (!this.state.sectionType && !this.props.customNodeData.phase) {
			prevState.invalidSectionType = true;
		}

		if (this.state.sectionType === ClpStepFilters.CLINICAL_PATHWAY && !this.state.stepType) {
			prevState.invalidStepType = true;
		}
		if (this.state.isChecked1 && this.state.isChecked2) {
			prevState.invalidStepfield = true;
		}

		if ( !this.state.invalidName && !this.state.invalidCode && !this.state.invalidClinicalPathwayType && !this.state.invalidSectionType && !prevState.invalidStepType && !prevState.invalidStepfield) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name.trim(),
				clinicalPathwayType: this.state.clinicalPathwayType,
				type: this.state.stepType ? this.state.stepType : "",
				sectionType: this.state.sectionType ? this.state.sectionType : this.props.customNodeData.phase,
				isClp: false,
				isHcp: true
			};
			this.props.setInputStepNAme(payload.name);
			this.props.dispatch(adminClinicalPathwayActions.createClinicalPathwayStep(payload));
		}
	}

	render() {
		if (this.props.adminClinicalPathway.createClinicalPathwayStepCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayStep());
			this.props.createStepClosed();
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			let queryString = `offset=0&numResults=${1000}`;
			queryString += `&isHcp=${true}`;
			this.props.dispatch(adminClinicalPathwayActions.fetchAllClinicalPathwayStep(queryString));
		}
		if (this.props.adminClinicalPathway.createClinicalPathwayStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayStep());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div className="pole_create_section">
                	<Modal
					className="re_assign_modal create_problem create_additional quick_reorder_table_modal"
					title={"Create Step"}
					wrapClassName=""
					onCancel={this.props.createStepClosed}
					open={ true }
					footer={[
						<div className="di w_100 text_align_right">
							<ActionButton
								showAdd
								onClick={this.createClicked.bind(this)}
								value={i18Get("CREATE", utils.getLanguage())} />
						</div>
					]}
				>
					<div className="pole_create_box create_category_personal_width_100">
						<div className="row">
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Name" /></label>
									<NameInput
										value={this.state.name}
										inputChanged={this.nameChanged.bind(this)}
										maxLength={128} />
									{this.state.invalidName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid name (Special characters not allowed)' /></span> : ""}
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-20 position_relative">
									<label><I18 tkey="Type" /></label>
									<SectionTypeDropDown
										disabled
										className=" "
										sectionType={this.state.sectionType ? this.state.sectionType : this.props.customNodeData.phase}
										selectedSectionType={this.selectedSectionType.bind(this)}
									/>
									{this.state.invalidSectionType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid type" /></span> : ""}
								</div>
							</div>
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-20 position_relative">
									<label><I18 tkey="Health Pathway Type" /></label>
									<ClinicalPathwayTypeDropdown
										className=" "
										ignoreAll={true}
										clinicalPathwayType={this.state.clinicalPathwayType}
										selectedClinicalPathwayType={this.selectedClinicalPathwayType.bind(this)} />
									{this.state.invalidClinicalPathwayType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid health pathway type" /></span> : ""}
								</div>
							</div>
							{this.state.sectionType === ClpStepFilters.CLINICAL_PATHWAY &&
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-10 position_relative">
									<label><I18 tkey="Step Type" /></label>
									<StepTypeDropdown
										className=" "
										ignoreAll={true}
										stepType={this.state.stepType}
										clinicalPathwayType={this.state.clinicalPathwayType}
										selectedStepType={this.selectedStepType} />
									{this.state.invalidStepType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid step type" /></span> : ""}
								</div>
								<span>
									{this.state.stepType && this.state.stepTypeDescription &&
										<div className='dynamic_description'><em>{this.state.stepTypeDescription.title}</em><br />
											<strong><I18 tkey="Example" />:</strong>
											<ul>
												{this.state.stepTypeDescription.example.map((data, index) => {
													return (<li key={index}>{data}</li>);
												})}
											</ul>
										</div>
									}


								</span>
							</div>}
						</div>
					</div>
				</Modal> 
			
			</div>
		);
	}
}

CreateStep.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(CreateStep));
