import React, { Component, Suspense } from "react";
import Remarks from "./remarks";
import Definition from "./definition";
import "./home.css";
import searchAction from "mom-ui-commons/build/components/health-pathway/redux/action";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
const Diagram = React.lazy(() => import("mom-ui-commons/build/components/health-pathway/diagram"));
const HealthPathwaysDetails = React.lazy(() => import("mom-ui-commons/build/components/health-pathway/healthPathwaysDetails"));

class HealthPathwayDetailV5 extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tab: "DEFINITION"
		};
	}
	
	tabSwitch = (tab) => {
		this.setState({ tab });
	};
	//----------------EDIT DIAGRAM-----------------------------------
	editClicked(link) {
		this.props.history.push(`${this.props.location.pathname}/sub_health_pathway/${link}`);
	}

	componentWillUnmount() {
		if (this.props.healthPathwayLinkedItems?.commonSearch) {
			this.props.dispatch(searchAction.clearCommonSearch());       
		}
	}

	render() {
		return (
			<div className="h-100 px-3 HP_DETAILS_PAGE">
				<Suspense fallback={<div></div>}>
					<HealthPathwaysDetails onChange={this.tabSwitch} tag={this.state.tab} ></HealthPathwaysDetails>
				</Suspense>
				
				{this.state.tab === "DEFINITION" && <div className="description_container pb-2">
					<div className="">
						<Definition exportPdf={this.props.exportPdf} type='DEFINITION' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} healthPathway={this.props.healthPathway} />
					</div>
				</div>}
				{this.state.tab === "DECISION TREE" && <Suspense fallback={<div></div>}><Diagram {...this.props} /></Suspense> }
				
				{this.state.tab === "REMARQUES" && <div className="description_container pb-2" id="remard-section">
					<div className="">
						<Remarks exportPdf={this.props.exportPdf} type='REMARKS' editClicked={this.editClicked.bind(this)} treatmentId={this.props.treatmentId} healthPathway={this.props.healthPathway} />
					</div>
				</div>}
				
			</div>
		);
	}
}

HealthPathwayDetailV5.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	syncfusion: PropTypes.object.isRequired,
	healthPathwayData: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	healthPathwayDetails: store.healthPathwayDetails,
	healthPathwayLinkedItems: store.healthPathwayLinkedItems,
	syncfusion: store.syncfusion,
	translations: store.translations,
	healthPathwayData: store.healthPathway.getOneDetails,
	user: store.user
}))(HealthPathwayDetailV5));

//export default HealthPathwayDetailV5;