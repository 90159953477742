import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox } from "antd";
import I18 from "../../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import Loader from "../../../../../../../plug/loader";
import ShowOrHideSteps from "../copyfromclinicalpathway/show-or-hide-steps";

class ExportSelectedComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			genericComponents: {},
			clinicalPathway: {},
			activeComponents: [],
			selectAll: true,
			checkList: [],
			componentsForSelection: {},
			stepLoader: true,
			checkAll: false,
			invalidComponents: false
		};
	}

	getSelectedComponents(checkList) {
		this.setState({ checkList, invalidComponents: false, checkAll: false }, () => {
			this.state.invalidComponents = false;
			this.props.getSelectedComponents(this.state);
		});
	}

	getSubStepDetails() {
		if (this.state.steps && this.state.steps.length) {
			return (<ShowOrHideSteps getSelectedComponents={this.getSelectedComponents.bind(this)} steps={this.state.steps} checkList={this.state.checkList} />);
		}

		return (<Loader loading={this.state.stepLoader} />);
	}

	onCheckBoxChange(e, value) {
		if (e.target.checked) {
			this.state.genericComponents[value] = true;
			this.state.invalidComponents = false;
			this.state.checkAll = false;
		} else {
			this.state.genericComponents[value] = "";
			this.state.checkAll = false;
		}
		this.setState(this.state);
		this.props.onCheckBoxChange(this.state);
	}

	onChangeCheckAll(e) {
		if (e.target.checked) {
			this.state.checkAll = true;
			this.state.invalidComponents = false;
			this.state.genericComponents.PATHWAY_DEFINITION = true;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = true;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = true;
			if (this.state.clinicalPathwayType && this.state.clinicalPathwayType === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = true;
				this.state.genericComponents.ANAESTHESIA = true;
			}
			this.state.checkList.map((e) => {
				e.checked = true;
				e.subSteps.map((data) => {
					data.checked = true;
				});
			});
		} else {
			this.state.checkAll = false;
			this.state.genericComponents.PATHWAY_DEFINITION = false;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = false;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = false;
			if (this.state.clinicalPathwayType && this.state.clinicalPathwayType === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = false;
				this.state.genericComponents.ANAESTHESIA = false;
			}
			this.state.checkList.map((e) => {
				e.checked = false;
				e.subSteps.map((data) => {
					data.checked = false;
				});
			});
		}
		this.setState(this.state);
		this.props.onChangeCheckAll(this.state);
	}

	render() {
		this.state.invalidComponents = this.props.invalidComponents;
		this.state.clinicalPathwayType = this.props.clinicalPathwayType;
		if (this.props.clinicalPathwayDetails.fetchCopyFromClinicalPathwayList === "FULFILLED") {
			this.state.steps = [];
			this.state.steps = this.props.clinicalPathwayDetails.copyFromClinicalPathwayList ? this.props.clinicalPathwayDetails.copyFromClinicalPathwayList : [];
			this.state.checkList = this.props.clinicalPathwayDetails.copyFromClinicalPathwayList ? this.props.clinicalPathwayDetails.copyFromClinicalPathwayList : [];
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathwayList());
			this.setState({
				stepLoader: false, invalidComponents: false, checkAll: false, genericComponents: {}
			});
		}

		return (
			<div>
				<div className="steps-content padding-15">
					<div className="component_heading  di w_100"><I18 tkey="Components" /></div>
					<div className="copy_scroll position_relative">
						<div className="margin-top-15 position_relative">
							{this.state.invalidComponents && <span className="invalid fixed_label"><I18 tkey="Select Components" /></span>}
							<div className="">
								<div>
									<span className="margin-left-10">
										<Checkbox
											checked={this.state.checkAll}
											onChange={(e) => this.onChangeCheckAll(e, "ALL")}
										/>
										<span className="margin-left-10"><I18 tkey="ALL" /></span>
									</span>
								</div>
								<span className="margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.AUTHORS_AND_ACTIONS}
										onChange={(e) => this.onCheckBoxChange(e, "AUTHORS_AND_ACTIONS")}
									/>
									<span className="margin-left-10"><I18 tkey="AUTHORS AND ACTIONS" /></span>
								</span>
								<span className="margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.PATHWAY_DEFINITION}
										onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_DEFINITION")}
									/>
									<span className="margin-left-10"><I18 tkey="PATHWAY DEFINITION" /></span>
								</span>
								<span className="margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.PATHWAY_ELIGIBILITY}
										onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_ELIGIBILITY")}
									/>
									<span className="margin-left-10"><I18 tkey="PATHWAY ELIGIBILITY" /></span>
								</span>
								{this.state.clinicalPathwayType && this.state.clinicalPathwayType === "SURGERY"
									? <span className="margin-left-10">
										<Checkbox
											checked={this.state.genericComponents.INTERVENTIONS}
											onChange={(e) => this.onCheckBoxChange(e, "INTERVENTIONS")}
										/>
										<span className="margin-left-10"><I18 tkey="INTERVENTIONS" /></span>
									</span>
									: ""}
								{this.state.clinicalPathwayType && this.state.clinicalPathwayType === "SURGERY" ?
									<span className="margin-left-10">
										<Checkbox
											checked={this.state.genericComponents.ANAESTHESIA}
											onChange={(e) => this.onCheckBoxChange(e, "ANAESTHESIA")}
										/>
										<span className="margin-left-10"><I18 tkey="ANAESTHESIA" /></span>
									</span> : ""}
							</div>
						</div>
						<div className="margin-top-15 position_relative">
							{this.getSubStepDetails()}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ExportSelectedComponent.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(ExportSelectedComponent));
