import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import { i18Get } from "../../../../../../../i18";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import ApplyChanges from "./applyChanges";
import Notification from "./notification";
import "./style.css";

class ModelChanges extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			viewModelList: true,
			code: null,
			metaData: new Date(),
			linkedModels: (this.props.clinicalPathwayData && this.props.clinicalPathwayData.linkedModels) ? this.props.clinicalPathwayData.linkedModels : []
		};
	}

	modelChangesApply = (applyRankOrder) => {
		if (this.state.isLoading) {return false;}
		const payload = {
			isModelTreatment: (this.props.clinicalPathwayData && this.props.clinicalPathwayData.modelChanges) || this.props.mode != "Notification" ? true : "",
			applyModelChanges: this.props.clinicalPathwayData && this.props.clinicalPathwayData.applyModelChanges ? true : "",
			isApplyReordering: applyRankOrder ? true : ""
		};
		const id = (this.props.clinicalPathwayData && this.props.clinicalPathwayData.id) ? this.props.clinicalPathwayData.id : (this.props.data && this.props.data.id) ? this.props.data.id : "";
		this.props.dispatch(clinicalPathwayAction.applyModelChangesToClinicalPathWay(id, payload));
		this.setState({ isLoading: true });
	};

	getModelStatus = () => {
		if (this.state.code) {
			this.timer = setTimeout(() =>
				this.props.dispatch(clinicalPathwayAction.getApplyModelObserver(this.state.code, this.state.metaData, "?apply=1")), 3000);
		}
	};

	isRefreshed = (status) => {
		switch (status) {
			case "PROCESSED":
				return true;
			case "ERRORED":
				return true;
			default: return false;
		}
	};

	viewModelList() {
		this.setState({ viewModelList: !this.state.viewModelList });
	}

	fetchAllModelIds() {
		const ids = [];
		if (this.props.clinicalPathwayData.linkedModels)
		{this.props.clinicalPathwayData.linkedModels.map(data => {
			ids.push(data.value);
		});}
		return ids;
	}

	reloadData(activity) {
		if (this.child) {
			this.child.reloadData(activity);
		}
	}

	render() {

		if (this.props.clinicalPathway.clinicalPathwayModelChangesApply === "FULFILLED") {

			if (this.props.clinicalPathway.modelChangesData && this.props.clinicalPathway.modelChangesData.code) {
				this.setState({ code: this.props.clinicalPathway.modelChangesData.code }, () => { this.getModelStatus(); });
			}

			this.props.dispatch(clinicalPathwayAction.clearApplyModelChangesToClinicalPathWay());
		}

		if (this.props.clinicalPathway.clinicalPathwayModelChangesApply == "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(clinicalPathwayAction.clearApplyModelChangesToClinicalPathWay());
		}

		if (this.props.clinicalPathway.modelChangesApplyStatus == "FULFILLED" && this.props.clinicalPathway.meta == this.state.metaData) {
			this.props.dispatch(clinicalPathwayAction.clearGetApplyModelObserver());
			if (this.props.clinicalPathway.modelStatus && this.props.clinicalPathway.modelStatus.status && this.isRefreshed(this.props.clinicalPathway.modelStatus.status)) {
				if (this.props.clinicalPathway.modelStatus.status === "PROCESSED") {
					utils.showSuccess(i18Get("Model Changes applied successfully", utils.getLanguage()));

					if (this.props.mode != "Notification") {
						this.props.reloadData();
						this.props.onClose(false);
					} else {
						this.props.reloadDetails();
						this.reloadData();
					}


				}
				if (this.props.clinicalPathway.modelStatus.status === "ERRORED") {
					utils.showError(i18Get("Failed to apply model changes", utils.getLanguage()));
				}
				this.setState({ isLoading: false });
			} else {
				this.getModelStatus();
			}
		}

		if (this.props.clinicalPathway.modelChangesApplyStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.clearGetApplyModelObserver());
		}
		return (
			<div className={`${(this.props.className ? this.props.className : "")}`}>

				{this.props.mode == "Notification" &&
					<Notification
						isLoading={this.state.isLoading}
						visible={this.props.visible}
						modelChangesApply={this.modelChangesApply.bind(this)}
						data={this.props.data}
						linkedModels={this.state.linkedModels}
						clinicalPathwayData={this.props.clinicalPathwayData} />
				}
				{this.props.mode == "ApplyChanges" &&
					<ApplyChanges
						isLoading={this.state.isLoading}
						onClose={this.props.onClose}
						visible={this.props.visible}
						modelChangesApply={this.modelChangesApply.bind(this)}
						data={this.props.data}
					/>}
			</div>
		);
	}
}

ModelChanges.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	actions: store.actions
}))(ModelChanges));
