import React from "react";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";

export default function getCols(actions, renderTotalDefaultCost, pathname, moveToDetails, selectedColumn) {
	const tableArray = ([
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d,
			resizable: true,
			width: 120,
			Cell: (row) => (
				row.value && row.value.code ? row.value.code : ""
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d,
			fixed: "left",
			resizable: true,
			Cell: (row) => (
				row.value.treatmentApproved
					? (
						<a onClick={() => { moveToDetails(row.value.treatmentId); }}>
							{
								row.value && row.value.name ? row.value.name : ""
							}
						</a>
					)
					:					row.value && row.value.name ? row.value.name : ""
			)
		}, {
			Header: i18Get("OPERATIVE TIME", utils.getLanguage()),
			id: "operativeTime",
			accessor: (d) => d,
			resizable: true,
			width: 80,
			show: (utils.showColumn(selectedColumn, "OPERATIVE TIME") === true),
			Cell: (row) => (
				<div className="text_align_right w_100">{row.value && row.value.operativeTime ? utils.setLocaleForCurrency(row.value.operativeTime, utils.getLanguage(), false) : 0}</div>
			)
		}, {
			Header: i18Get("PERI OPERATIVE TIME", utils.getLanguage()),
			id: "preOperativeTime",
			accessor: (d) => d,
			resizable: true,
			width: 80,
			show: (utils.showColumn(selectedColumn, "PERI OPERATIVE TIME") === true),
			Cell: (row) => (
				<div className="text_align_right w_100">{row.value && row.value.preOperativeTime ? utils.setLocaleForCurrency(row.value.preOperativeTime, utils.getLanguage(), false) : 0}</div>
			)
		},
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d.hospitalName,
			resizable: true,
			width: 120,
			show: (utils.showColumn(selectedColumn, "HOSPITAL NAME") === true),
			Cell: (row) => (
				row.value ? row.value : "Master"
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			resizable: true,
			width: 150,
			show: (utils.showColumn(selectedColumn, "STATUS") === true),
			Cell: (row) => (
				row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : ""
			)
		},
		{
			Header: i18Get("TOTAL DEFAULT COST", utils.getLanguage()),
			id: "defaultValue",
			accessor: (d) => d,
			width: 100,
			resizable: true,
			Cell: (row) => (
				renderTotalDefaultCost(row.value)
			)
		}
		// {
		//     Header:'',
		//     id: 'space',
		//     accessor: d => d,
		//     width:30,
		//     resizable: false,
		//     sortable:false,
		//     Cell: row => (
		//         <div className="text_align_right w_100"></div>
		//     ),

		// }

	]);
	return tableArray;
}
