import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import TabContent from "./tab-content";
import screenId from "../../../../../../router/screenIdList";

class InterventionTabs extends Component {
	constructor(props) {
		super(props);
		this.state = {
			key: 1
		};
	}

	render() {
		if ((this.state.organization !== this.props.organization) && this.props.courseReference) {
			this.state.key = this.state.key + 1;
			this.state.organization = this.props.organization;
		}
		return (
			<div className={(this.props.durationSelected) ? "" : ""}>
				<div id="home" className="tab-pane fade in active">
					<Switch>
						<Route exact path={locations.interventions + locations.timeSheets} render={() => <TabContent key={this.state.key} organization={this.props.organization} typeActivity={this.props.typeActivity} organizationMode="EXTENDED" screenId={screenId.interventionTimeSheetList} totalCount={this.props.totalCount} />} />
						{ this.props.scope.userScope && this.props.scope.userScope.INTERVENTION_V4 && <Route exact path={locations.interventions + locations.analyticalSheets} render={() => <TabContent organization={this.props.organization} typeActivity={this.props.typeActivity} organizationMode="BASIC" screenId={screenId.interventionAnalyticalSheetList} totalCount={this.props.totalCount} />} />}
						<Route exact path={locations.interventions + locations.defaultValues} render={() => <TabContent key={this.state.key} costRefreshTime={this.props.costRefreshTime} isRefreshing={this.props.isRefreshing} organization={this.props.organization} typeActivity={this.props.typeActivity} organizationMode="DEFAULT_VALUE" durationSelected={this.props.durationSelected} durationCourse={this.props.durationCourse} totalCount={this.props.totalCount} />} />
						<Redirect to={`${locations.interventions_plain}/${this.props.organization}${locations.timeSheets}`} />
					</Switch>
				</div>
			</div>
		);
	}
}

InterventionTabs.propTypes = {
	scope: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	scope: store.scope,
	translations: store.translations
}))(InterventionTabs));
