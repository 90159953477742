import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import { i18Get } from "../../../../../../../../i18";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import roleActions from "./redux/action";
import Loader from "../../../../../../../../plug/loader";
import column from "./column";
import SimplePopupTable from "../../../../../../../../plug/simple-mode-table";
import Create from "./create";
import Edit from "./edit";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal } from "antd";

class List extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			users: [],
			offset: 0,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			filterValue: "",
			roleList: [],
			memberListView: false,
			memberList: [],
			roleId: 0,
			editWind: false
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchDataFromServer();
	}

	onPageSizeChange() {
		this.state.page = 1;
		this.setState({ ...this.state });
	}
	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.setState({ ...this.state });
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}
	editClicked(data) {

		return () => {
			const prevState = this.state;
			prevState.editWind = true;
			prevState.roleId = data;
			this.setState({ prevState });
		};

	}
	deleteClicked(id) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			const { confirm } = Modal;
			confirm({
				title: i18Get("Are you sure you want to continue?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "",
				okText: i18Get("Delete", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					this.props.dispatch(roleActions.deleteIt(id));
				},
				onCancel: () => {
					//console.log('Cancel');
				}
			});
		};
	}

	roleNavigation(link, id) {
		if (link) {
			const prevState = this.state;
			prevState.tab = link;
			this.setState(prevState, () => {
				this.props.history.push({
					pathname: link,
					search: `?roleIds=${ id }`
				  });
			});
		}
	}
	
	addClicked() {
		const prevState = this.state;
		this.props.showHideCreate(true);
		this.setState(prevState);
	}

	handleOk() {

	}

	refreshList() {
		this.fetchDataFromServer();
	}
	handleCancel() {
		const prevState = this.state;
		this.props.showHideCreate(false);
		prevState.editWind = false;
		prevState.roleId = 0;
		this.setState(prevState);
	}

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.roleList, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this), column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.roleNavigation.bind(this), this.viewList.bind(this)), true, false,
					this.onPageChange.bind(this), "ROLES",
					"-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll",
					this.onPageSizeChange.bind(this))} />
		);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? "1" : "0";
		} else {
			prevState.sort = "";
			prevState.desc = false;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
		queryString += this.state.search ? `search=${this.state.search}&` : "";
		queryString += "status=APPROVED";
		this.props.dispatch(roleActions.getAll(queryString));
	}

	viewList(list) {
		return () => {
			this.setState({ memberListView: true, memberList: list });

		};
	}
	closeView() {
		this.setState({ memberListView: false, memberList: [] });
	}

	filterChanged(value) {
		const prevState = this.state;
		prevState.search = value;
		prevState.page = 1;
		this.setState(prevState, this.fetchDataFromServer());
	}

	render() {

		if (this.props.roles.getAllStatus == "FULFILLED") {
			this.state.roleList = this.props.roles.data.data;
			this.state.loading = false;
			this.state.totalPages = parseInt(Math.ceil(this.props.roles.data.count / this.state.pageSize));
			this.props.totalCount(this.props.roles.data.count);
			this.props.dispatch(roleActions.clearGetAll());
		}

		if (this.props.roles.getAllStatus == "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(roleActions.clearGetAll());
		}

		if (this.props.roles.isDeleteStatus == "FULFILLED") {
			this.fetchDataFromServer();
			this.props.dispatch(roleActions.CleanDeleteIt());
		}

		if (this.props.search != this.state.search) {
			this.filterChanged(this.props.search);
		}
		return (
			<div className="row">
				<div className="w100 di">
					{this.getList()}
				</div>
				{this.state.memberListView &&
					<SimplePopupTable
						title={"MEMBERS LIST"}
						visible={this.state.memberListView}
						onCancel={this.closeView.bind(this)}
						footer={false}
						data={this.state.communityList}
					/>
				}

				{this.props.showCreate &&
					<Create visible={this.props.showCreate} onCancel={this.handleCancel.bind(this)} refreshList={this.refreshList.bind(this)} ></Create>
				}
				{this.state.editWind &&
					<Edit visible={this.state.editWind} refreshList={this.refreshList.bind(this)} id={this.state.roleId} onCancel={this.handleCancel.bind(this)} />
				}
			</div>
		);
	}
}

List.propTypes = {
	roles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	roles: store.roles,
	translations: store.translations
}))(List));