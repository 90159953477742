import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import Users from "./users";
import RolePermissions from "./rolePermissions";
import { Redirect } from "react-router-dom";

class UserManagement  extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<div className="activities_table_scroll">          
				<Switch>
					<Route path={locations.userManagement_users} component={Users} />
					<Route path={locations.userManagement_role_permissions} component={RolePermissions} />
					<Redirect to={locations.userManagement_users} component={Users}  />     
				</Switch>
			</div>  
		);
	}
}

UserManagement.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	translations: store.translations
}))(UserManagement));                    