import axios from "axios";
import Config from "MOM-config";
import events from "./event";

const getMissConnectores = (data) => ({
  type: events.MISSED_CONNECTORES,
  payload: {data},
});

const selectMissConnector = (data) => ({
  type: events.SELECTED_MISSED_CONNECTORE,
  payload: {data},
});
const diagramUpdated = () => ({
  type: events.DIAGRAM_IS_UPDATED,
  payload: {}
});

export default {
  getMissConnectores,
  selectMissConnector,
  diagramUpdated
};
