import eventNames from "./event";
import activities from "./activities";
const initialState = {
	fetchAllRegroupmentList: false,
	healthPathWayDetailsRegroupment: [],
	linkedGroup: null,
	healthPathWayDetailsRegroupmentCopy: []
};


function enhanceData (act, ogData) {
	const dataList = [ ...ogData ];
	const prevCurrent = JSON.parse(JSON.stringify(dataList[act.index]));
	dataList[act.index][act.dataType] = act.value;
	dataList[act.index]["isUpdated"] = true;
	return setOtherFiledBasedOnTheGroup(act, dataList, prevCurrent);
}


function setOtherFiledBasedOnTheGroup(act, ogData, prevCurrent) {//index, value, dataType, list )
	let dataList = [ ...ogData ];
	const currentRow = dataList[act.index];
	const getGroupRow = dataList.find((e, i) => e[act.dataType] === currentRow[act.dataType] && i !== act.index);

	if(!act.value && act.dataType == "groupCode") {

		dataList && dataList[act.index].groupDuration && (
			dataList[act.index].groupDuration.durationMinimumMinute = null,
			dataList[act.index].groupDuration.durationMaximumMinute = null,
			dataList[act.index].groupDuration.duration = null
		  );

		dataList[act.index].groupCost = null;
		dataList[act.index].durationRule = undefined;
		dataList[act.index].costRule = undefined;
		dataList = activities.findGroupDuration(prevCurrent, dataList);
		dataList = activities.findGroupCost(prevCurrent, dataList);

	}else if (act.dataType === "groupCode") {
		const durationRule = "durationRule";
		const costRule = "costRule";
		
		if (getGroupRow) {
			currentRow[durationRule] = getGroupRow[durationRule] ;
			currentRow[costRule] = getGroupRow[costRule];
		} else if(act.healthPathWayDiagramRules && act.healthPathWayDiagramRules.length && currentRow["groupCode"]) {
			currentRow[durationRule] = act.healthPathWayDiagramRules[0].id;
			currentRow[costRule] = act.healthPathWayDiagramRules[0].id;
		}

		if(act.value) {
			dataList = activities.findGroupDuration(currentRow, dataList);
			dataList = activities.findGroupCost(currentRow, dataList);
			dataList = activities.findGroupDuration(prevCurrent, dataList);
		}

	} else if (act.dataType == "durationRule") {

		dataList = activities.setGroupRuleasDefalut(currentRow, dataList);

	}else if (act.dataType == "costRule") {

		dataList = activities.setGroupCostDefalut(currentRow, dataList);

	}


	return dataList;
}

const clearMissmatches = (list) => {
	let data = []
	if(list && list.length > 0) {
	 data = list.map(e => { if(!e.groupId || e.groupId == "0") { e.costRule = null; e.durationRule = null;}; return e;});
	}
	return data;
}
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT}_FULFILLED`:
			return {
				...state,
				healthPathWayDetailsRegroupment: clearMissmatches(action.payload.data.data),
				healthPathWayDetailsRegroupmentCopy: action.payload.data.data && action.payload.data.data.length && action.payload.data.data[0].groupId ? JSON.parse(JSON.stringify(clearMissmatches(action.payload.data.data))) : [],
				linkedGroup: action.payload.data.data && action.payload.data.data.length ? action.payload.data.data[0].groupId : null,
				fetchAllRegroupmentList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT}_REJECTED`:
			return {
				...state,
				healthPathWayDetailsRegroupment: [],
				healthPathWayDetailsRegroupmentCopy: [],
				linkedGroup: null,
				fetchAllRegroupmentList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT:
			return {
				...state,
				healthPathWayDetailsRegroupment: [],
				healthPathWayDetailsRegroupmentCopy: [],
				linkedGroup: null,
				fetchAllRegroupmentList: false
			};
		
		case eventNames.CLEAR_REGROUPLIST_STATUS:
			return {
				...state,
				fetchAllRegroupmentList: false
			};
		case eventNames.UPDATE_RE_GROUPMENT:
			return {
				...state,
				healthPathWayDetailsRegroupment: enhanceData(action.payload, JSON.parse(JSON.stringify(state.healthPathWayDetailsRegroupment.filter(e => e.name !== "")))),
			};
		case eventNames.SET_RE_GROUPMENT:
			return {
				...state,
				healthPathWayDetailsRegroupment: action.payload
			};
		default:
			return state;
	}
}
