import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (queryString, type) => ({
	type: events.SPECIALTY_MODEL_GET_ALL,
	meta: type,
	payload: axios.get(`${Config.apiRootSecure}/speciality${queryString}`)
});

const clearSpecialtyFetch = () => ({
	type: events.CLEAR_SPECIALTY_MODEL_GET_ALL,
	payload: {}
});

const saveAndApproveSpeciality = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_SPECIALTY,
	payload: axios.put(`${Config.apiRootSecure}/speciality/${id}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveSpeciality = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_SPECIALTY,
	payload: {}
});

const fetchOneSpecialty = (id) => ({
	type: events.FETCH_ONE_SPECIALTY,
	payload: axios.get(`${Config.apiRootSecure}/speciality/${id}`)
});

const clearFetchOneSpecialty = () => ({
	type: events.CLEAR_FETCH_ONE_SPECIALTY,
	payload: {}
});

const assignAndApproveSpeciality = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_SPECIALTY,
	payload: axios.put(`${Config.apiRootSecure}/speciality/${id}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveSpeciality = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_SPECIALTY,
	payload: {}
});

const getSpecialityCommunity = (id) => ({
	type: events.GET_SPECIALITY_COMMUNITY,
	payload: axios.get(`${Config.apiRootSecure}/speciality/${id}/community`)
});

const clearSpecialityCommunity = () => ({
	type: events.CLEAR_SPECIALITY_COMMUNITY,
	payload: {}
});

export default {
	getAll,
	clearSpecialtyFetch,
	assignAndApproveSpeciality,
	clearAssignAndApproveSpeciality,
	fetchOneSpecialty,
	clearFetchOneSpecialty,
	saveAndApproveSpeciality,
	clearSaveAndApproveSpeciality,
	getSpecialityCommunity,
	clearSpecialityCommunity
};
