import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { PlusOutlined } from "@ant-design/icons";
import { i18Get } from "../../i18";
import utils from "../../utils/utils";
import "./action-button.css";

class ActionButton extends Component {
	/**
     * @param actionName name of action
     * @param onButtonClick callback for click
     * @param loadingText text shown for loading
     */

	constructor(props) {
		super(props);
		this.state = {
			isLoading: false
		};
	}

	UNSAFE_componentWillMount() {
	}


	onButtonClick(e) {
		e.preventDefault();
		
		if (!this.props.actions.actionInProgress && this.state.isLoading) {
			this.state.isLoading = false;
		}
		if (this.props.onClick && !this.state.isLoading) {
			this.state.isLoading = true;
			this.props.onClick();
			// this.setState(this.state);
		}
	}

	render() {
		let disabled = "";

		if (!this.props.actions.actionInProgress && this.state.isLoading) {
			this.state.isLoading = false;
		}
		if (this.state.isLoading) {
			disabled = "disabled";
		}
		const className = `common_button ${this.props.className ? this.props.className : ""}`;
		const text = this.state.isLoading ? (this.props.loadingText ? this.props.loadingText : i18Get("Loading...", utils.getLanguage())) : this.props.value;
		return (
			<button disabled={this.props.disabled} className={className} {...disabled} onClick={this.onButtonClick.bind(this)}>
				{(this.props.showAdd && !this.state.isLoading) ? <PlusOutlined  className="margin-right-8 action_icon font_weight_600 bold" theme="outlined" type="plus" /> : ""}
				{text}
			</button>
		);
	}
}

ActionButton.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations
}))(ActionButton));
