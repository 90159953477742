import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import communitySitesAction from "../../../../../../../../actions/community-sites";
import Sites from "./createSites";

class CreateSites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showSitesCreate: false,
			name: "",
			code: "",
			shortName: "",
			invalidCode: false,
			invalidName: false,
			loading: false,
			selectHospital: "",
			saveAndNext: false,
			loadingNext: false,
			invalidHospital: false,
			selectCommunity: null,
			invalidCommunity: false,
			invalidShortName: false
		};
	}

	createNewClass() {
		this.setState({ showSitesCreate: true });
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}
	shortNameChanged(value) {
		const prevState = this.state;
		if (value.length <= 60)
		{
			prevState.shortName = value;
			this.setState(prevState);
		} 
		prevState.invalidShortName = false;
	}
	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.checkCodeRegex(text)) {
			prevState.code = text;
		} else {
			prevState.code = "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}
	onHospitalChanged(e) {
		const prevState = this.state;
		prevState.selectHospital = e ? e : "";
		prevState.invalidHospital = false;
		this.setState(prevState);
	}

	onCommunityChanged(e) {
		const prevState = this.state;
		prevState.invalidCommunity = false;
		const options = [];
		if (e) {
			e.map((data) => {
				options.push(data.value);
			});
		}
		prevState.selectCommunity = options;
		this.setState(prevState);
	}

	validate() {
		const prevState = this.state;
		if (!prevState.name || !prevState.name.trim() || !utils.checkNameRegex(prevState.name)) {
			prevState.invalidName = true;
		}
		if (!prevState.code || !prevState.code.trim()) {
			prevState.invalidCode = true;
		}

		if (!prevState.selectHospital || prevState.selectHospital.id > 0) {
			prevState.invalidHospital = true;
		}

		if (!prevState.selectCommunity || prevState.selectCommunity.length == 0) {
			prevState.invalidCommunity = true;
		}

		if (!prevState.shortName || !prevState.shortName.trim()) {
			prevState.invalidShortName = true;
		}
		if (prevState.invalidName || prevState.invalidCode || prevState.invalidHospital || prevState.invalidShortName || prevState.invalidCommunity) {
			this.setState(prevState);
			return false;
		}

		return true;
	}

	handleOk(saveAndNext) {
		if (this.validate()) {
			if (saveAndNext === true) {this.setState({ saveAndNext: true, loadingNext: true });}
			else {this.setState({ loading: true, saveAndNext: false });}
			const payload = {
				name: this.state.name,
				code: this.state.code,
				shortName: this.state.shortName,
				hospitalId: this.state.selectHospital && this.state.selectHospital.value ? this.state.selectHospital.value : "",
				communityIds: this.state.selectCommunity
			};
			this.props.dispatch(communitySitesAction.createCommunitySites(payload));
		}
	}

	handleCancel() {
		this.setState({
			showSitesCreate: false,
			invalidHospital: false,
			name: "",
			invalidName: false,
			code: "",
			invalidCode: false,
			shortName: "",
			selectCommunity: null,
			invalidCommunity: false,
			selectHospital: null,
			invalidShortName: false

		});
	}
	handleNext() {
		this.setState({ showSitesCreate: true, name: "", invalidName: false, code: "", invalidCode: false, invalidShortName: false, invalidHospital: false, shortName: "" });
	}
	render() {
		if (this.props.communitySites.communitySitesCreate === "FULFILLED") {
			if (this.state.saveAndNext === true) {
				this.handleNext();
			}
			else { this.handleCancel(); }
			this.props.createClassCompleted(true);
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communitySitesAction.clearCreateCommunitySites());
		}
		if (this.props.communitySites.communitySitesCreate === "REJECTED") {
			this.setState({ loading: false, loadingNext: false });
			this.props.dispatch(communitySitesAction.clearCreateCommunitySites());
		}
		return (
			<Fragment>
				<div className="pole_create_section di w_100 padding-top-15 padding-bottom-0 ">
					<button
						className="common_button margin_top_15_mob  margin-right-0 "
						type="button"
						onClick={this.createNewClass.bind(this)}>
						<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
						<I18 tkey="CREATE" />
					</button>
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Create Sites", utils.getLanguage())}
					visible={this.state.showSitesCreate}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}
					footer={[
						<Button key="submitNext" type="primary" loading={this.state.loadingNext} onClick={this.handleOk.bind(this, true)}>
							<span className="margin-left-10" >
								<I18 tkey="Save and Next" />
							</span>
						</Button>,
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.handleOk.bind(this, false)}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}>
					<Sites
						name={this.state.name}
						shortName={this.state.shortName}
						nameChanged={this.nameChanged.bind(this)}
						shortNameChanged={this.shortNameChanged.bind(this)}
						invalidShortName = {this.state.invalidShortName}
						invalidName={this.state.invalidName}
						invalidHospital={this.state.invalidHospital}
						code={this.state.code}
						codeChanged={this.codeChanged.bind(this)}
						invalidCode={this.state.invalidCode}
						selectHospital={this.state.selectHospital}
						onHospitalChanged={this.onHospitalChanged.bind(this)}
						onCommunityChanged={this.onCommunityChanged.bind(this)}
						selectedCommunity={this.state.selectCommunity}
						invalidCommunity= {this.state.invalidCommunity}
					/>
				</Modal>
			</Fragment>
		);
	}
}

CreateSites.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	communitySites: store.communitySites,
	translations: store.translations
}))(CreateSites));
