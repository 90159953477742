import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import "./top-section.css";
import ProcedureDropdown from "./procedure-dropdown";
import NumericInput from "../../../../../../../plug/numeric-input";
import ActionButton from "../../../../../../../plug/action-button";
import ClinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import eventNames from "../../../../../../../actions/events";
import { Modal, Radio, Checkbox } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import isPermissionGranted from "../../../../../../../utils/permission";
import StdCareChartSettings from "../standard-care-chart/settings-modal";
import { statusList } from "../../../../../../../constants";

class TopSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathway: {},
			invalidDay: false,
			noOfDays: "",
			noOfHours: "",
			invalidHours: false,
			invalidProcedureDay: false,
			isLoaded: false,
			selectedHospital: "",
			modelVisible: false,
			loading: false,
			services: "",
			type: "DAY",
			beforeType: "DAY",
			afterType: "DAY",
			lengthOfStayModal: false,
			beforeNoOfDays: "",
			beforeNoOfHours: "",
			invalidBeforeAdmissionDay: false,
			invalidBeforeHours: false,
			invalidBeforeStatus: false,
			invalidBeforeDay: false,
			afterNoOfDays: "",
			afterNoOfHours: "",
			invalidAfterCheckOutNoOfDays: false,
			invalidAfterHours: false,
			invalidAfterStatus: false,
			invalidAfterDay: false,
			clpMinuteChart: false,
			invalidMinute: false,
			confirmationMsg: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
	}

	toggleIsLoaded() {
		const prevState = this.state;
		prevState.isLoaded = false;
		this.setState(prevState);
	}

	onClicklengthOfStay = () => {
		this.setState({ lengthOfStayModal: true, isLoaded: false });
		this.props.onFetchData();
	};

	lengthOfStayChanged(label, value) {
		const prevState = this.state;
		if (prevState.type === "DAY") {
			prevState.invalidDay = false;
			prevState.noOfDays = value;
			prevState.noOfHours = 0;
			if (prevState.noOfDays === "" || parseInt(prevState.noOfDays) < prevState.procedureDay) {
				if (parseInt(prevState.noOfDays) === 1) {
					prevState.procedureDay = 1;
					prevState.invalidProcedureDay = false;
				} else if (parseInt(prevState.noOfDays) > 1) {
					prevState.procedureDay = 2;
					prevState.invalidProcedureDay = false;
				} else {
					prevState.procedureDay = false;
				}
			}
			if (!prevState.procedureDay) {
				if (parseInt(prevState.noOfDays) === 1) {
					prevState.procedureDay = 1;
					prevState.invalidProcedureDay = false;
				} else if (parseInt(prevState.noOfDays) > 1) {
					prevState.procedureDay = 2;
					prevState.invalidProcedureDay = false;
				} else {
					prevState.procedureDay = false;
				}
			}
		}
		if (prevState.type === "HOURS") {
			const limit = (prevState.clpMinuteChart) ? 9 : 24;
			if (value < limit) {
				prevState.invalidHours = false;
				prevState.noOfHours = value;
				prevState.noOfDays = 0;
				prevState.procedureDay = 0;
				prevState.invalidProcedureDay = false;
				prevState.invalidDay = false;
			}
		}
		this.setState(prevState);
	}

	lengthOfStayBeforeAdmissionChanged(label, value) {
		const prevState = this.state;
		prevState.invalidBeforeDay = false;
		prevState.beforeNoOfDays = (value > 0) ? value : "";
		prevState.beforeNoOfHours = 23;
		this.setState(prevState);
	}
	lengthOfStayAfterCheckoutChanged(label, value) {
		const prevState = this.state;
		prevState.invalidAfterDay = false;
		prevState.afterNoOfDays = (value > 0) ? value : "";
		prevState.afterNoOfHours = 23;
		this.setState(prevState);
	}
	typeChanged(e) {
		const prevState = this.state;
		prevState.type = e.target.value;
		prevState.invalidDay = false;
		prevState.invalidHours = false;
		prevState.invalidProcedureDay = false;
		if (prevState.type != "HOURS") {
			prevState.clpMinuteChart = false;
		}
		prevState.invalidMinute = false;
		this.setState(prevState);
	}
	typeBeforeAdmissionChanged(e) {
		const prevState = this.state;
		prevState.afterType = e.target.value;
		this.setState(prevState);
	}
	typeAfterCheckoutChanged(e) {
		const prevState = this.state;
		prevState.beforeType = e.target.value;
		this.setState(prevState);
	}

	procedureDayChanged(e) {
		const prevState = this.state;
		prevState.procedureDay = e;
		prevState.invalidProcedureDay = false;
		this.setState(prevState);
	}

	handleCheckboxChange() {
		return (e) => {
			const prevState = this.state;
			prevState.clpMinuteChart = e.target.checked;
			if (prevState.clpMinuteChart) {
				prevState.noOfHours = (prevState.type != "HOURS") ? 8 : (prevState.noOfHours > 8) ? 8 : prevState.noOfHours;
				prevState.type = "HOURS";
			}
			prevState.noOfDays = 0;
			prevState.procedureDay = 0;
			prevState.invalidDay = false;
			prevState.invalidHours = false;
			prevState.invalidMinute = false;
			this.setState(prevState);
		};
	}
	validate() {
		const prevState = this.state;
		if (this.state.type !== "HOURS" && (!utils.NumericValidation.test(this.state.noOfDays) || !this.state.noOfDays || parseInt(this.state.noOfDays) === 0)) {
			prevState.invalidDay = true;
		}
		if (!this.state.procedureDay && parseInt(this.state.procedureDay) !== 0) {
			prevState.invalidProcedureDay = true;
		}
		if (this.state.clpMinuteChart && this.state.noOfHours > 8 && this.state.type == "HOURS") {
			prevState.invalidMinute = true;
		}
		this.setState(prevState);
		if (this.state.invalidDay || this.state.invalidProcedureDay || this.state.invalidMinute) {
			return false;
		}
		return true;
	}

	onClickSave() {
		if (this.state.clinicalPathway && this.validate()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const props = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization
			};
			const payload = {
				noOfDays: this.state.type === "HOURS" ? 1 : this.state.noOfDays,
				noOfHours: this.state.type === "DAY" ? 0 : this.state.clpMinuteChart ? (this.state.noOfHours > 8 ? 8 : this.state.noOfHours) : this.state.noOfHours,
				beforeAdmissionNoOfDays: this.state.beforeNoOfDays > 0 ? this.state.beforeNoOfDays : 1,
				beforeAdmissionNoOfHours: this.state.beforeNoOfHours,
				afterCheckOutNoOfDays: this.state.afterNoOfDays > 0 ? this.state.afterNoOfDays : 1,
				afterCheckOutNoOfHours: this.state.afterNoOfHours,
				procedureDay: this.state.type === "HOURS" ? 1 : this.state.procedureDay,
				services: this.state.service ? this.state.service.trim() : "",
				clpMinuteChart: this.state.clpMinuteChart,
				isConfirm: this.state.confirmationMsg,
				startsAt: this.state.startsAt && this.state.type === "HOURS" ? this.state.startsAt : null,
				endsAt: this.state.endsAt && this.state.type === "HOURS" ? this.state.endsAt : null
			};
			this.props.dispatch(ClinicalPathwayDetailsAction.updateClinicalPathwayDetails(payload, props));
		}
	}

	cancelClicked() {
		this.setState({
			modelVisible: false, lengthOfStayModal: false, 
			noOfDays: parseInt(this.state.noOfHours) > 0 ? 0 : this.props.clinicalPathway.clinicalPathway.noOfDays, 
			noOfHours: this.props.clinicalPathway.clinicalPathway.noOfHours ? this.props.clinicalPathway.clinicalPathway.noOfHours : 0, 
			procedureDay: parseInt(this.state.noOfHours) > 0 ? 0 : this.props.clinicalPathway.clinicalPathway.procedureDay ? parseInt(this.props.clinicalPathway.clinicalPathway.procedureDay) : 0,
			beforeNoOfDays: this.props.clinicalPathway.clinicalPathway.beforeAdmissionNoOfDays,
			afterNoOfDays: this.props.clinicalPathway.clinicalPathway.afterCheckOutNoOfDays,
			type: parseInt(this.props.clinicalPathway.clinicalPathway.noOfDays) === 1 && parseInt(this.props.clinicalPathway.clinicalPathway.noOfHours) > 0 ? "HOURS" : "DAY",
			clpMinuteChart: this.props.clinicalPathway.clinicalPathway.clpMinuteChart,
			invalidProcedureDay: false
		});
	}

	confirmation = (merge) => {
		const { confirm } = Modal;
		if (merge.warningMessage) {
			confirm({
				title: i18Get("There is a chance of removing the data from the Chemin Clinique, Do you want to continue?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "",
				okText: i18Get("Yes", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					this.state.confirmationMsg = true;
					this.onClickSave();
					return false;
				},
				onCancel: () => {
					this.setState({ lengthOfStayModal: false, confirmationMsg: false });
				}
			});
		} else {
			this.state.confirmationMsg = false;
		}
	};

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, method);
	};

	onChangeChartSettings = (startsAt, endsAt) => {
		this.setState({ ...this.state, startsAt: startsAt, endsAt: endsAt });
	};

	render() {
		if (this.props.clinicalPathway.clinicalPathway && this.props.clinicalPathway.clinicalPathway.id && !this.state.isLoaded) {
			this.state.clinicalPathway = this.props.clinicalPathway.clinicalPathway;
			this.state.noOfDays = this.props.clinicalPathway.clinicalPathway.noOfDays;
			this.state.noOfHours = this.props.clinicalPathway.clinicalPathway.noOfHours ? this.props.clinicalPathway.clinicalPathway.noOfHours : 0;
			this.state.service = this.props.clinicalPathway.clinicalPathway.services;
			this.state.procedureDay = this.props.clinicalPathway.clinicalPathway.procedureDay ? parseInt(this.props.clinicalPathway.clinicalPathway.procedureDay) : 1;
			this.state.status = utils.toPascalCase(this.props.clinicalPathway.status);
			this.state.type = parseInt(this.state.noOfDays) === 1 && parseInt(this.state.noOfHours) > 0 ? "HOURS" : "DAY";
			if (this.state.noOfHours > 0) {
				this.state.noOfDays = 0;
				this.state.procedureDay = 0;
			}
			this.state.beforeNoOfDays = (this.props.clinicalPathway.clinicalPathway.beforeAdmissionNoOfDays) ? this.props.clinicalPathway.clinicalPathway.beforeAdmissionNoOfDays : 1;
			this.state.beforeNoOfHours = this.props.clinicalPathway.clinicalPathway.beforeAdmissionNoOfHours ? this.props.clinicalPathway.clinicalPathway.beforeAdmissionNoOfHours : 0;
			this.state.beforeType = parseInt(this.state.beforeNoOfDays) === 1 && parseInt(this.state.beforeNoOfHours) > 0 ? "HOURS" : "DAY";

			this.state.afterNoOfDays = (this.props.clinicalPathway.clinicalPathway.afterCheckOutNoOfDays) ? this.props.clinicalPathway.clinicalPathway.afterCheckOutNoOfDays : 1;
			this.state.afterNoOfHours = this.props.clinicalPathway.clinicalPathway.afterCheckOutNoOfHours ? this.props.clinicalPathway.clinicalPathway.afterCheckOutNoOfHours : 0;
			this.state.afterType = parseInt(this.state.afterNoOfDays) === 1 && parseInt(this.state.afterNoOfHours) > 0 ? "HOURS" : "DAY";
			this.state.clpMinuteChart = this.props.clinicalPathway.clinicalPathway.clpMinuteChart;
			this.state.isLoaded = true;
		}

		if (this.props.clinicalPathwayDetails.editClinicalPathwayDetailsStatus === "FULFILLED") {
			this.state.validationMsg = this.props.clinicalPathwayDetails.validationMsg;
			this.confirmation(this.state.validationMsg);
			if ((this.state.validationMsg && !this.state.validationMsg.warningMessage) || this.state.validationMsg.warningMessage == utils.sameDropper()) {
				this.state.lengthOfStayModal = false;
			}
			const { errorMessageForRemovedItems } = this.state.validationMsg;
			if (this.state.validationMsg && (errorMessageForRemovedItems && errorMessageForRemovedItems.length > 0)) {
				this.state.modelVisible = true;
			} else {
				this.props.reloadDetails();
			}
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(ClinicalPathwayDetailsAction.clearUpdateClinicalPathwayDetails());
		}

		return (
			<div className="treatment_details margin-right-10  ">
				<div className="">
					{!this.state.clinicalPathway.isCheckList ?
						this.state.clinicalPathway.clinicalPathway !== "MEDICINE_WITHOUT_ACTE" ?
							<a className={this.isPermissionGranted("EDIT") == true ? "text_decoration_underline" : "color_black cursor_default"} onClick={this.onClicklengthOfStay} >
								{this.state.noOfHours == 0 ? `${this.state.noOfDays ? this.state.noOfDays : ""} ${i18Get("Days", utils.getLanguage())}  
							(${i18Get("Procedure on Day", utils.getLanguage())} 
							${this.state.procedureDay ? this.state.procedureDay : ""})`
									:
									`${this.state.noOfHours ? this.state.noOfHours : ""} ${i18Get("Hours", utils.getLanguage())} 
							(${i18Get("Procedure on Day", utils.getLanguage())}
							${this.state.procedureDay ? this.state.procedureDay : this.state.procedureDay == 0 ? this.state.procedureDay : ""})`}
							</a>
							:
							<a className={this.isPermissionGranted("EDIT") == true ? "text_decoration_underline" : "color_black cursor_default"} onClick={this.onClicklengthOfStay} >
								{this.state.noOfHours == 0 ? `${this.state.noOfDays ? this.state.noOfDays : ""} ${i18Get("Days", utils.getLanguage())}`
									:
									`${this.state.noOfHours ? this.state.noOfHours : ""} ${i18Get("Hours", utils.getLanguage())}`}
							</a>
						: ""}
					{this.state.lengthOfStayModal && this.isPermissionGranted("EDIT") &&
						<Modal
							className="re_assign_modal"
							title={i18Get("Length of Stay / Day of Procedure", utils.getLanguage())}
							visible={this.state.lengthOfStayModal}
							onCancel={this.cancelClicked.bind(this)}
							wrapClassName="drag_modal"
							footer={[
								<div key="re_assign_modal_footer" className="d-flex align-items-center justify-content-end common_cancel_btn_container">
									<button type="primary" className="ant-btn float_none_imp height_common_btn margin-right-10" onClick={this.cancelClicked.bind(this)}>{i18Get("Cancel", utils.getLanguage())}</button>
									<ActionButton disabled={this.props.selectedHospital} className="common_button" onClick={this.onClickSave.bind(this)} value={i18Get("Save", utils.getLanguage())} />
								</div>
							]}>
							<div>
								<div className="row">
									<div className="w_100 col-sm-4 col-xs-6">
										<h4 className="code_header w_30 float_left"><I18 tkey="Before Admission" /></h4>
										<li className="title_line w_70 float_right"></li>
									</div>
									<div className="col-md-3 padding-left-0 w_100">
										<div className="w_50 di position_relative ml-15">
											<label className="common_label position_relative"><I18 tkey="Duration of tasks" /></label>
											<NumericInput
												value={this.state.beforeNoOfDays}
												getNumericValue={this.lengthOfStayBeforeAdmissionChanged.bind(this)}
												maxLength={2}
												integerOnly={true}
												disabled={this.props.selectedHospital}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="w_100 col-sm-4 col-xs-6 margin-top-20">
										<h4 className="code_header w_auto float_left"><I18 tkey="Hospitalisation" /></h4>
										<li className="title_line w_75 float_right"></li>
									</div>
									<div className="col-md-4 margin-top-19 padding-right-0">
										<label className="stay"><I18 tkey="Length of Stay" /></label>
										<Radio.Group value={this.state.type} onChange={this.typeChanged.bind(this)} disabled={this.props.selectedHospital}>
											<Radio className="" value="DAY">
												<I18 tkey="Jours" />
											</Radio>
											<Radio className="" value="HOURS">
												<I18 tkey="Hours" />
											</Radio>
										</Radio.Group>
										<Checkbox
											checked={this.state.clpMinuteChart}
											onChange={this.handleCheckboxChange().bind(this)}>
											<I18 tkey="Minutes" />
										</Checkbox>
									</div>
									<div className="col-md-3 margin-top-28 padding-left-0">
										<div className="w_100 di position_relative">
											<NumericInput
												value={this.state.type === "DAY" ? this.state.noOfDays : this.state.noOfHours}
												getNumericValue={this.lengthOfStayChanged.bind(this)}
												maxLength={2}
												integerOnly={true}
												disabled={this.props.selectedHospital}
											/>
										</div>
									</div>
									{this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway !== "MEDICINE_WITHOUT_ACTE" ?
										<div className="col-md-5">
											<div className="w_100 di position_relative">
												<label className="common_label"><I18 tkey="Day of Procedure" /></label>
												<ProcedureDropdown
													days={this.state.noOfDays}
													procedureDay={this.state.procedureDay}
													type={this.state.type}
													procedureDayChanged={this.procedureDayChanged.bind(this)}
													disabled={this.props.selectedHospital} />
												{this.state.invalidProcedureDay ?
													utils.showError(i18Get("Select Day of Procedure", utils.getLanguage())) : ""}
											</div>
										</div> : ""}
									<div className="col-md-3 padding-left-0 w_100">
										<div className="w_100 di position_relative ml-15">
											{this.props.clinicalPathway && parseInt(this.state.noOfDays) === 0 && this.state.type === "HOURS" && this.props.category === "standardCareChart" &&
												<StdCareChartSettings
													onChangeChartSettings={this.onChangeChartSettings}
												/>
											}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="w_100 col-sm-4 col-xs-6 margin-top-20">
										<h4 className="code_header w_auto float_left"><I18 tkey="After Exit" /></h4>
										<li className="title_line w_85 float_right"></li>
									</div>
									<div className="col-md-3 padding-left-0 w_100">
										<div className="w_50 di position_relative ml-15">
											<label className="common_label position_relative"><I18 tkey="Duration of tasks" /></label>
											<NumericInput
												value={this.state.afterNoOfDays}
												getNumericValue={this.lengthOfStayAfterCheckoutChanged.bind(this)}
												maxLength={2}
												integerOnly={true}
												disabled={this.props.selectedHospital}
											/>
										</div>
									</div>
								</div>
							</div>
						</Modal>}
					{this.state.modelVisible &&
						<Modal
							className="re_assign_modal"
							title={i18Get("Unable to change Length of Stay / Day of Procedure", utils.getLanguage())}
							visible={this.state.modelVisible}
							onCancel={this.cancelClicked.bind(this)}
							bodyStyle={{ maxHeight: "400px", overflowY: "scroll" }}
							wrapClassName="drag_modal"
							footer={[]}>
							{this.state.validationMsg && this.state.validationMsg.errorMessageForRemovedItems.length > 0 &&
								<div>
									<label className="bold" ><I18 tkey="Please remove the tasks under the following steps and continue to change the Length of Stay / Day of Procedure" /></label>
									{this.state.validationMsg && this.state.validationMsg.errorMessageForRemovedItems.map((msg) => {
										return (<div key="re_assign_modal_key_" className="bold"><I18 tkey={msg.stepName} /> {">>"} <I18 tkey="DAY" /> <I18 tkey={msg.subStepName} /></div>);
									})}
								</div>}
						</Modal>}
				</div>
			</div>
		);
	}
}

TopSection.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	doctors: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations,
	doctors: store.doctors,
	permissions: store.permissions
}))(TopSection));