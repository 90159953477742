import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../../../router/locations";
import utils from "../../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../../i18";
import adminPersonnelActions from "../../../../../../../../../actions/admin-personnel";
import Loader from "../../../../../../../../../plug/loader";
import ReactTable from "../../../../../../../../../plug/reactTable/react-table";
import { Modal } from "antd";
import events from "../../../../../../../../../actions/events";
import screenId from "../../../../../../../../../router/screenIdList";
import column from "./column";
import AssignToTreatments from "./assign-to-treatments";
import AssignAndApproveModel from "./assign-and-approve-model";

class StandardTab extends Component {
	constructor(props) {
		super(props);
		this.state = {
			personnelTemplate: [],
			totalPages: 1,
			loading: false,
			search: "",
			deleteTemplateVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedTemplate: {},
			sort: "",
			desc: 0,
			assignTreatmentVisible: false,
			selectedTemplate: null,
			modelVisible: false,
			templateId: "",
			hospitalId: "",
			status: "ALL"
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelList });
	}

	editClicked(template) {
		return () => {
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}/${template.id}`);
		};
	}

	approveClicked(template) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, templateId: template.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	deleteClicked(template) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedTemplate: template, deleteTemplateVisible: true });
		};
	}

	toggleVisibleAssignTreatments(template) {
		return () => {
			this.setState({ assignTreatmentVisible: !this.state.assignTreatmentVisible, selectedTemplate: template });
		};
	}

	hideModalDelete() {
		this.setState({ deleteTemplateVisible: false });
	}

	deleteTemplate() {
		this.props.dispatch(adminPersonnelActions.deletePersonnelTemplate(this.state.deletedTemplate.id));
		this.setState({ deleteTemplateVisible: false, deletedTemplate: {} });
	}

	getTableOptions() {
		return { page: this.state.page, pageSize: this.state.pageSize, sort: this.state.sort, desc: this.state.sort };
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.typeActivity && (this.state.typeActivity !== "ALL") ? `&typeActivity=${this.state.typeActivity}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		queryString += this.state.status  ? `&status=${this.state.status}` : "";
		queryString += this.state.search ? `&search=${this.state.search}` : "";
		this.props.dispatch(adminPersonnelActions.fetchAllPersonnelTemplates(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.personnelTemplate, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => {return (<Loader loading={this.state.loading} isTable/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), 
					column(this.editClicked.bind(this), this.deleteClicked.bind(this), this.toggleVisibleAssignTreatments.bind(this), this.approveClicked.bind(this)), 
					true, false, 
					this.onPageChange.bind(this), "PERSONNEL TEMPLATES", "", this.onPageSizeChange.bind(this))}/>
		);
	}

	render() {
		if ((this.state.search !== this.props.search) || (this.state.typeActivity !== this.props.typeActivity)
        || (this.state.hospitalId !== this.props.hospitalId) || (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.typeActivity = this.props.typeActivity;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.personnelTemplate = [];
			this.state.page = 1;
			this.state.loading = true;
			this.fetchDataFromServer();
		}
		if (this.props.adminPersonnel.fetchAllPersonnelTemplateCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminPersonnel.personnelTemplateList.count / this.state.pageSize);
			this.state.personnelTemplate = this.props.adminPersonnel.personnelTemplateList.data;
			this.props.getCount(this.props.adminPersonnel.personnelTemplateList.count);
			this.props.dispatch(adminPersonnelActions.clearFetchAllPersonnelTemplates());
		}
		if (this.props.adminPersonnel.deletePersonnelTemplateCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(adminPersonnelActions.clearDeletePersonnelTemplate());
		}
		return (
			<div>
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Template", utils.getLanguage())}
					visible={this.state.deleteTemplateVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteTemplate.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" />&nbsp; 
						<span className="bold">{this.state.deletedTemplate.name}</span> ?
					</div>
				</Modal>
				{this.state.assignTreatmentVisible ?
					<AssignToTreatments
						template={this.state.selectedTemplate}
						assignTreatmentVisible={this.state.assignTreatmentVisible}
						toggleVisibleAssignTreatments={this.toggleVisibleAssignTreatments.bind(this)}/> : ""}
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							typeActivity={this.state.typeActivity && (this.state.typeActivity !== "ALL") ? this.state.typeActivity : ""}
							refreshCompleted={this.refreshCompleted}
							templateId={this.state.templateId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

StandardTab.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	adminPersonnel: store.adminPersonnel
}))(StandardTab));    