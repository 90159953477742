import eventNames from "../../actions/events";

const initialTranslationsState = {
	screenId: null
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case eventNames.BADGE_SCREEN_ID:

			return {
				...state,
				screenId: action.payload
			};
		default:
			return state;
	}
}
