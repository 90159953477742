import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import extClinicalPathwayAction from "../../../../../../../../../actions/ext-clinical-pathway";
import { Modal, Dropdown, Menu, Button } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import "./upload-files.css";

class UploadFiles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			totalCount: 0,
			file: "",
			loading: false
		};
	}
	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	onFetchData() {
		this.props.dispatch(extClinicalPathwayAction.fetchReferenceFile(this.props.match.params.id, this.props.mappingId));
	}

	onChangeFile = (e) => {
		if (e.target.files[0]) {
			const type = e.target.files[0].type.split("/");
			if (type[1] !== "pdf") {
				utils.showError(i18Get("File not supported", utils.getLanguage()));
			}
			else {
				this.state.file = e.target.files[0];
				this.uploadFile(e.target.files[0]);
				this.setState({ visible: false });
			}
		}
	};

	onInputClick = (event) => {
		event.target.value = "";
	};

	deleteClicked(data) {
		return () => {
			this.setState({ deletedReferenceFile: data, deleteVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false });
	}

	onButtonClick() {
		this.setState({ visible: true });
	}

	onFieldChanged(value, e) {
		const prevState = this.state;
		prevState.selectField = value;
		this.setState(prevState);
		if (e) {
			this.refs.fileInput.click();
		}
	}

	deleteReferenceFile() {
		this.props.dispatch(extClinicalPathwayAction.deleteReferenceFile(this.props.match.params.id, this.state.deletedReferenceFile.id));
		this.setState({ deleteVisible: false, deletedReferenceFile: {} });
	}

	uploadFile = () => {
		const formData = new FormData();
		if (this.state.file) {
			formData.append("file", this.state.file);
			this.props.dispatch(extClinicalPathwayAction.uploadReferenceFile(this.props.match.params.id, this.props.mappingId, this.state.selectField ? this.state.selectField : "", formData));
			this.setState({ loading: true });
		}
	};

	menu = (
		<Menu>
			<Menu.Item key="0">
				<a onClick={this.onFieldChanged.bind(this, "DELIVERABLE")}><I18 tkey="DELIVERABLE" /> </a>
			</Menu.Item>
			<Menu.Item key="1">
				<a onClick={this.onFieldChanged.bind(this, "REFERENCE")}><I18 tkey="REFERENCE" /> </a>
			</Menu.Item>
			<Menu.Item key="2">
				<a onClick={this.onFieldChanged.bind(this, "RISK")}><I18 tkey="RISK" /> </a>
			</Menu.Item>
			<Menu.Item key="3">
				<a onClick={this.onFieldChanged.bind(this, "ASSESSMENT")}><I18 tkey="ASSESSMENT" /> </a>
			</Menu.Item>
		</Menu>
	);

	render() {
		if (this.props.extClinicalPathway.isReferenceFileUploadComplete === "FULFILLED") {
			this.state.filePath = this.props.extClinicalPathway.data && this.props.extClinicalPathway.data.filePath ? this.props.extClinicalPathway.data.filePath : "";
			this.state.fileName = this.props.extClinicalPathway.data && this.props.extClinicalPathway.data.fileName ? this.props.extClinicalPathway.data.fileName : "";
			this.onFetchData();
			this.props.dispatch(extClinicalPathwayAction.clearUploadReferenceFile());
			this.setState({ loading: false });
		}

		if (this.props.extClinicalPathway.isReferenceFileUploadComplete === "REJECTED") {
			this.props.dispatch(extClinicalPathwayAction.clearUploadReferenceFile());
			this.setState({ loading: false });
		}

		if (this.props.extClinicalPathway.isFetchReferenceFileComplete === "FULFILLED") {
			this.state.referenceAttachments = this.props.extClinicalPathway.attachments ? this.props.extClinicalPathway.attachments : [];
			this.props.dispatch(extClinicalPathwayAction.clearFetchReferenceFile());
		}

		if (this.props.extClinicalPathway.isReferenceFileDeleteComplete === "FULFILLED") {
			this.onFetchData();
			this.props.dispatch(extClinicalPathwayAction.clearDeleteReferenceFile());
		}

		return (
			<div>
				<div className="d-flex align-items-center justify-content-between margin-bottom-8">
					<label className="common_label margin-bottom-0"><I18 tkey='Files' /></label>
					<input
						ref="fileInput"
						onChange={this.onChangeFile}
						onClick={this.onInputClick}
						type="file"
						style={{ display: "none" }}
					/>
					<div className="text_align_right">
						<Dropdown overlay={this.menu} trigger={["click"]}>
							<Button
								loading={this.state.loading} onClick={() => this.onButtonClick()}><PlusOutlined />
							</Button>
						</Dropdown>
					</div>
				</div>
				<div className="upload_content_clinic">
					{this.state.referenceAttachments && this.state.referenceAttachments.length && this.state.referenceAttachments.length > 0 ? this.state.referenceAttachments.map(data => {
						return (
							<div className="" key="upload_content_clinic_">
								<div className="d-flex">
									<DeleteOutlined  className="delete_color margin-right-10" type="delete" onClick={this.deleteClicked(data).bind(this)} />
									<label className="" htmlFor="customFile">{data.fileName ? data.fileName : ""}</label>
									<label className="file-type"><b>{data.fileType ? " (" + i18Get(data.fileType.toUpperCase(), utils.getLanguage()) + ")" : ""}</b></label>
								</div>
							</div>
						);
					}) : ""}
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete File", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteReferenceFile.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedReferenceFile && this.state.deletedReferenceFile.fileName ? this.state.deletedReferenceFile.fileName : ""}</span> ?
					</div>
				</Modal>
			</div>
		);
	}
}

UploadFiles.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	extClinicalPathway: store.extClinicalPathway,
	elements: store.elements,
	translations: store.translations,
	scannedTreatments: store.scannedTreatments
}))(UploadFiles));