export default {
	dashboard: "D1",
	profile: "U1",
	siteList: "A1",
	siteCreate: "A2",
	siteEdit: "A3",
	poleList: "A4",
	poleCreate: "A5",
	poleEdit: "A6",
	serviceList: "A7",
	serviceCreate: "A8",
	serviceEdit: "A9",
	medicalUnitsList: "A10",
	medicalUnitsCreate: "A11",
	medicalUnitsEdit: "A12",
	costCenterList: "A13",
	costCenterCreate: "A14",
	costCenterEdit: "A15",
	sectorList: "A16",
	sectorCreate: "A17",
	sectorEdit: "A18",
	blocList: "A19",
	blocCreate: "A20",
	blocEdit: "A21",
	blocTypeList: "A22",
	blocTypeCreate: "A23",
	blocTypeEdit: "A24",
	roomList: "A25",
	roomCreate: "A26",
	roomEdit: "A27",
	specialityList: "A28",
	specialityCreate: "A29",
	specialityEdit: "A30",
	activityFamilyList: "A31",
	activityFamilyCreate: "A32",
	activityFamilyEdit: "A33",
	activityStepList: "A34",
	activityStepCreate: "A35",
	activityStepEdit: "A36",
	typeComplexityList: "A37",
	typeComplexityCreate: "A38",
	typeComplexityEdit: "A39",
	sessionTypeList: "A40",
	sessionTypeCreate: "A41",
	sessionTypeEdit: "A42",
	adminPersonnelList: "A43",
	adminPersonnelCreate: "A44",
	adminPersonnelEdit: "A45",
	categoryPersonnelList: "A46",
	categoryPersonnelCreate: "A47",
	categoryPersonnelEdit: "A48",
	doctorList: "A49",
	doctorCreate: "A50",
	doctorEdit: "A51",
	definitionOfBlocTeamsList: "A52",
	definitionOfBlocTeamsCreate: "A53",
	definitionOfBlocTeamsEdit: "A54",
	sessionTypeTeamsList: "A55",
	sessionTypeTeamsCreate: "A56",
	sessionTypeTeamsEdit: "A57",
	minuteCostEquipmentList: "A58",
	minuteCostEquipmentCreate: "A59",
	minuteCostEquipmentEdit: "A60",
	equipmentList: "A61",
	equipmentCreate: "A62",
	equipmentEdit: "A63",
	impressionPreferences: "A64",
	importList: "A65",
	importStep1: "A66",
	importStep2: "A67",
	assignImportValues: "A68",
	consumableUsage: "A69",
	consumableDefinitionList: "A68",
	consumableDefinitionEdit: "A69",
	anticancerConsumableDciList: "A70",
	anticancerConsumableDciEdit: "A71",
	equipmentConsumableDciList: "A72",
	equipmentConsumableDciEdit: "A73",
	dciList: "A74",
	dciDetails: "A75",
	dciPreparationMaterialsList: "A76",
	dciPreparationMaterialsEdit: "A77",
	dciPerfusionMaterialsList: "A78",
	dciPerfusionMaterialsEdit: "A79",
	costCalculationParameters: "A80",
	costCalculationParametersEdit: "A81",
	userList: "A82",
	userCreate: "A83",
	userEdit: "A84",
	translationList: "A85",
	translationCreate: "A86",
	sterilizationList: "A87",
	sterilizationCreate: "A88",
	sterilizationEdit: "A89",
	accommodationTypeList: "A90",
	accommodationTypeCreate: "A91",
	accommodationTypeEdit: "A92",
	acteList: "A93",
	acteCreate: "A94",
	treatmentStatusList: "A95",
	gtinList: "A96",
	groupCreate: "A97",
	supplierEdit: "A98",
	groupEdit: "A99",
	tarifList: "A100",
	gtinEdit: "A101",
	importMapList: "A102",
	interventionAnalyticalSheetList: "F1",
	interventionTimeSheetList: "F2",
	interventionMomCreate: "F3",
	analyticalSheetDetails: "F4",
	analyticalEditAnaesthesia: "F5",
	analyticalEditRisk: "F6",
	analyticalEditPreparation: "F7",
	analyticalEditPosition: "F8",
	analyticalEditInstallation: "F9",
	analyticalEditStaffList: "F10",
	analyticalEditStaffSpecialEdit: "F11",
	analyticalEditSterilization: "F12",
	analyticalEditSterilizationEdit: "F13",
	analyticalEditMedicalDevice: "F14",
	analyticalEditMedicalDeviceEdit: "F15",
	analyticalEditPharmacy: "F16",
	analyticalEditPharmacyEdit: "F17",
	analyticalEditProsthesis: "F18",
	analyticalEditProsthesisEdit: "F19",
	timeSheetDetails: "F20",
	timeEditDefinition: "F21",
	timeEditAnaesthesia: "F22",
	timeEditRisk: "F23",
	timeEditRoomInstalltion: "F24",
	timeEditPatientInstallation: "F25",
	timeEditStaffList: "F26",
	timeEditStaffSpecialEdit: "F27",
	timeEditPatientPreparation: "F28",
	timeEditPatientPreparationEdit: "F29",
	timeEditSurgicalDressing: "F30",
	timeEditSurgicalDressingEdit: "F31",
	timeEditDrapingFieldage: "F32",
	timeEditDrapingFieldageEdit: "F33",
	timeEditInstrumentation: "F34",
	timeEditInstrumentationEdit: "F35",
	timeEditSmallEquipment: "F36",
	timeEditSmallEquipmentEdit: "F37",
	timeEditProsthesisDMI: "F38",
	timeEditProsthesisDMIEdit: "F39",
	timeEditStitches: "F40",
	timeEditStitchesEdit: "F41",
	timeEditClosingDrainages: "F42",
	timeEditClosingDrainagesEdit: "F43",
	timeEditSamples: "F44",
	timeEditSamplesEdit: "F45",
	timeEditDressings: "F46",
	timeEditDressingsEdit: "F47",
	timeEditDescription: "F48",
	timeEditRemarks: "F49",
	interventionLocalCreateStep1: "F50",
	interventionLocalCreateStep2: "F51",
	interventionLocalCreateStep3ExistingMOM: "F52",
	interventionLocalCreateStep3NewMOM: "F53",
	interventionLocalCreateStep4: "F54",
	durationInterventionList: "F55",
	durationInterventionDetailedList: "F56",
	durationInterventionDetailedByDoctorList: "F57",
	durationInterventionByDoctorList: "F58",
	durationCourseOutPatientList: "F59",
	durationCourseConventionalList: "F60",
	durationCourseLevelsList: "F61",
	scannedTreatmentDetails: "F62",
	anaesthesiaList: "T1",
	anaesthesiaCreateMOM: "T2",
	anaesthesiaDetails: "T3",
	anaesthesiaEditStaffList: "T4",
	anaesthesiaEditStaffSpecialEdit: "T5",
	anaesthesiaEditMedicalDeviceList: "T6",
	anaesthesiaEditMedicalDeviceEdit: "T7",
	anaesthesiaEditPharmacyList: "T8",
	anaesthesiaEditPharmacyEdit: "T9",
	anaesthesiaCreateLocalStep1: "T10",
	anaesthesiaCreateLocalStep2: "T11",
	anaesthesiaCreateLocalStep3ExistingMOM: "T12",
	anaesthesiaCreateLocalStep3NewMOM: "T13",
	anaesthesiaCreateLocalStep4: "T14",
	chemotherapyList: "C1",
	chemotherapyCreateMOM: "C2",
	chemotherapyDetails: "C3",
	chemotherapyEditDefinition: "C4",
	chemotherapyEditRisk: "C5",
	chemotherapyEditStaffList: "C6",
	chemotherapyEditStaffCreate: "C7",
	chemotherapyEditStaffEdit: "C8",
	chemotherapyEditAgentList: "C9",
	chemotherapyEditAgentEdit: "C10",
	chemotherapyEditPreparationMaterialsList: "C11",
	chemotherapyEditPreparationMaterialsEdit: "C12",
	chemotherapyEditPerfusionMaterialsList: "C13",
	chemotherapyEditPerfusionMaterialsEdit: "C14",
	chemotherapyEditRemarks: "C15",
	chemotherapyCreateLocalStep1: "C16",
	chemotherapyCreateLocalStep2: "C17",
	chemotherapyCreateLocalStep3ExistingMOM: "C18",
	chemotherapyCreateLocalStep3NewMOM: "C19",
	chemotherapyCreateLocalStep4: "C20",
	imageryList: "S1",
	imageryCreateMOM: "S2",
	imageryDetails: "S3",
	imageryEditDefinition: "S4",
	imageryEditAnaesthesia: "S5",
	imageryEditEquipmentList: "S6",
	imageryEditEquipmentCreate: "S7",
	imageryEditEquipmentEdit: "S8",
	imageryEditStaffList: "S9",
	imageryEditStaffCreate: "S10",
	imageryEditStaffEdit: "S11",
	imageryEditComplexityFactorsList: "S12",
	imageryEditComplexityFactorsCreate: "S13",
	imageryEditComplexityFactorsEdit: "S14",
	imageryEditPreparationMaterialsList: "S15",
	imageryEditPreparationMaterialsEdit: "S16",
	imageryEditPerfusionMaterialsList: "S17",
	imageryEditPerfusionMaterialsEdit: "S18",
	imageryEditRemarks: "S19",
	imageryCreateLocalStep1: "S20",
	imageryCreateLocalStep2: "S21",
	imageryCreateLocalStep3ExistingMOM: "S22",
	imageryCreateLocalStep3NewMOM: "S23",
	imageryCreateLocalStep4: "S24",
	radiotherapyList: "R1",
	radiotherapyCreateMOM: "R2",
	radiotherapyDetails: "R3",
	radiotherapyEditDefinition: "R4",
	radiotherapyEditAnaesthesia: "R5",
	radiotherapyEditEquipmentList: "R6",
	radiotherapyEditEquipmentCreate: "R7",
	radiotherapyEditEquipmentEdit: "R8",
	radiotherapyEditStaffList: "R9",
	radiotherapyEditStaffCreate: "R10",
	radiotherapyEditStaffEdit: "R11",
	radiotherapyEditPreparationMaterialsList: "R12",
	radiotherapyEditPreparationMaterialsEdit: "R13",
	radiotherapyEditPerfusionMaterialsList: "R14",
	radiotherapyEditPerfusionMaterialsEdit: "R15",
	radiotherapyEditRemarks: "R16",
	radiotherapyCreateLocalStep1: "R17",
	radiotherapyCreateLocalStep2: "R18",
	radiotherapyCreateLocalStep3ExistingMOM: "R19",
	radiotherapyCreateLocalStep3NewMOM: "R20",
	radiotherapyCreateLocalStep4: "R21",
	accommodationList: "H1",
	accommodationCreateMOM: "H2",
	accommodationDetails: "H3",
	accommodationEditSynthesis: "H4",
	accommodationEditSynthesisEdit: "H5",
	accommodationEditDirectCharges: "H6",
	accommodationEditDirectChargesEdit: "H7",
	accommodationEditInducedLoads: "H8",
	accommodationEditInducedLoadsEdit: "H9",
	accommodationEditHotelAndGeneralExpences: "H10",
	accommodationEditHotelAndGeneralExpencesEdit: "H11",
	accommodationEditOtherLogisticsAndStructure: "H12",
	accommodationEditOtherLogisticsAndStructureEdit: "H13",
	accommodationCreateLocalStep1: "H14",
	accommodationCreateLocalStep2: "H15",
	accommodationCreateLocalStep3ExistingMOM: "H16",
	accommodationCreateLocalStep3NewMOM: "H17",
	accommodationCreateLocalStep4: "H18",
	accommodationCreateMOMSelectvalues: "H19",
	accommodationAdministrationList: "H20",
	accommodationAdministrationEdit: "H21",
	courseReferenceList: "P1",
	courseReferenceDetails: "P2",
	courseReferenceEdit: "P3",
	individualRotes_parcours: "P4",
	performanceActivityInterventionList: "E1",
	performanceActivityPharmacyList: "E2",
	performanceConsumablesConsumptionAnalysisList: "E3",
	performanceConsumablesEffectsAnalysisList: "E4",
	performanceCostsOfInterventionsList: "E5",
	performanceConsumablesCapturedAnalysisList: "E6",
	scannedTreatments: "SC1"
};
