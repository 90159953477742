import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../i18";
import locations from "../../../../../../router/locations";
import ListAllElements from "./listall";
import UpdateElement from "./updateone";
import BreadCrumb from "../../breadcrumb";
import utils from "../../../../../../utils/utils";

class MedicalDevices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			medicalDevices: {}
		};
	}

	getLink() {
		return (this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? `/anaesthesia/${this.props.match.params.organization}/${this.props.match.params.id}` : `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}`;
	}

	generateItems() {
		const items = [];
		items.push({
			name: (this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? i18Get("Anaesthesia", utils.getLanguage()) : i18Get("Intervention", utils.getLanguage()),
			link: (this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? `/anaesthesia/${this.props.match.params.organization}` : `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}`
		});
		items.push({ name: this.props.details, link: this.getLink() });
		items.push({ name: i18Get(utils.showTitleElement(this.props.type), utils.getLanguage()), link: this.getLink() + ((this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? `/${this.props.type.toLowerCase()}` : `/elements/${this.props.type.toLowerCase()}`) });
		if (this.state.medicalDevices.id) {
			items.push({ name: this.state.medicalDevices.name, link: `${this.getLink() + ((this.props.location.pathname.indexOf(/anaesthesia/) !== -1) ? `/${this.props.type.toLowerCase()}` : `/elements/${this.props.type.toLowerCase()}`)}/${this.state.medicalDevices.id}` });
		}
		return items;
	}

	render() {
		this.state.medicalDevices = this.props.breadcrumb.componentData;
		return (
			<div className="">
				<BreadCrumb items={this.generateItems()} />
				<Switch>
					<Route exact path={locations.elementdetails} render={() => <UpdateElement hideColumn={this.props.hideColumn} type={this.props.type} screenId={this.props.EditScreenId} />} />
					<Route path={`${locations.anaesthesia_edit + locations.medicaldevice}/:mappingId`} render={() => <UpdateElement hideColumn={this.props.hideColumn} type={this.props.type} screenId={this.props.EditScreenId} />} />
					<Route path={`${locations.anaesthesia_edit + locations.pharmacy}/:mappingId`} render={() => <UpdateElement hideColumn={this.props.hideColumn} type={this.props.type} screenId={this.props.EditScreenId} />} />
					<ListAllElements type={this.props.type} hideColumn={this.props.hideColumn} screenId={this.props.screenId} />
				</Switch>
			</div>
		);
	}
}

MedicalDevices.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(MedicalDevices));
