import React, { Component } from "react";
import { Select } from "antd";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";

const { Option } = Select;

class Level extends Component {
	constructor(props) {
		super(props);
	}

	options() {
		const options = [];
		options.push(<Option key="1" value="1"><I18 tkey="Level 1" /></Option>);
		options.push(<Option key="2" value="2"><I18 tkey="Level 2" /></Option>);
		options.push(<Option key="3" value="3"><I18 tkey="Level 3" /></Option>);
		return options;
	}

	render() {
		return (
			<div className="w_100 di position_relative">
				<label className=""><I18 tkey="Level" /></label>
				<Select
					allowClear
					className="w_100 create_level"
					placeholder={i18Get("Select level", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					optionFilterProp="children"
					onChange={this.props.onLevelChanged}
					value={this.props.data}
				>
					{this.options()}
				</Select>
				{this.props.invalidLevel && <span className="invalid_right fixed_label"><I18 tkey="Select level" /></span>}
			</div>
		);
	}
}

export default withRouter(Level);
