import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../../router/locations";
import EditPersonnelTemplate from "./personnel-template";
import EditTemplateDetails from "./template-details";

export default class AdminPersonnelCreateRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={locations.editAdminPersonnelTemplate}
					render={() => <EditPersonnelTemplate {...this.props} />}
				/>
				<Route
					exact
					path={locations.editAdminPersonnelTemplateDetails}
					render={() => <EditTemplateDetails {...this.props} />}
				/>
				<Redirect to={`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`} />
			</Switch>
		);
	}
}
