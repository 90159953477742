import eventNames from "../../actions/events";

const initialState = {
	fetchAllStaffForAnaesthesia: false,
	fetchAllMedicalDeviceForAnaesthesia: false,
	fetchAllPharmacyForAnaesthesia: false,
	staff: {},
	medicalDevice: {},
	pharmacy: {},
	anaesthesia: {},
	fetchAllStaffComplete: false,
	staffList: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.ANAESTHESIA_STAFF_GET}_REJECTED`:
			return {
				...state,
				fetchAllStaffForAnaesthesia: "REJECTED"
			};
		case `${eventNames.ANAESTHESIA_STAFF_GET}_PENDING`:
			return {
				...state,
				fetchAllStaffForAnaesthesia: "PENDING"
			};
		case `${eventNames.ANAESTHESIA_STAFF_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const anaesthesia = {};
				anaesthesia[action.meta] = {};
				anaesthesia[action.meta].staff = action.payload.data.data;
				return {
					...state,
					anaesthesia,
					fetchAllStaffForAnaesthesia: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllStaffForAnaesthesia: "FULFILLED"
			};
		case eventNames.CLEAR_ANAESTHESIA_STAFF_GET:
			return {
				...state,
				fetchAllStaffForAnaesthesia: false
			};
		case `${eventNames.ANAESTHESIA_MEDICAL_DEVICES_GET}_REJECTED`:
			return {
				...state,
				fetchAllMedicalDeviceForAnaesthesia: "REJECTED"
			};
		case `${eventNames.ANAESTHESIA_MEDICAL_DEVICES_GET}_PENDING`:
			return {
				...state,
				fetchAllMedicalDeviceForAnaesthesia: "PENDING"
			};
		case `${eventNames.ANAESTHESIA_MEDICAL_DEVICES_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const anaesthesia = {};
				anaesthesia[action.meta] = {};
				anaesthesia[action.meta].medicalDevice = action.payload.data.data;
				return {
					...state,
					anaesthesia,
					fetchAllMedicalDeviceForAnaesthesia: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllMedicalDeviceForAnaesthesia: "FULFILLED"
			};
		case eventNames.CLEAR_ANAESTHESIA_MEDICAL_DEVICES_GET:
			return {
				...state,
				fetchAllMedicalDeviceForAnaesthesia: false
			};
		case `${eventNames.ANAESTHESIA_PHARMACY_GET}_REJECTED`:
			return {
				...state,
				fetchAllPharmacyForAnaesthesia: "REJECTED"
			};
		case `${eventNames.ANAESTHESIA_PHARMACY_GET}_PENDING`:
			return {
				...state,
				fetchAllPharmacyForAnaesthesia: "PENDING"
			};
		case `${eventNames.ANAESTHESIA_PHARMACY_GET}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const anaesthesia = {};
				anaesthesia[action.meta] = {};
				anaesthesia[action.meta].pharmacy = action.payload.data.data;
				return {
					...state,
					anaesthesia,
					fetchAllPharmacyForAnaesthesia: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllPharmacyForAnaesthesia: "FULFILLED"
			};
		case eventNames.CLEAR_ANAESTHESIA_PHARMACY_GET:
			return {
				...state,
				fetchAllPharmacyForAnaesthesia: false
			};
		case `${eventNames.FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS}_REJECTED`:
			return {
				...state,
				fetchAllStaffComplete: "REJECTED"
			};
		case `${eventNames.FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const anaesthesia = {};
				anaesthesia[action.meta] = {};
				anaesthesia[action.meta].staffList = action.payload.data.data;
				return {
					...state,
					anaesthesia,
					fetchAllStaffComplete: "FULFILLED"
				};
			}
			return {
				...state,
				fetchAllStaffComplete: "FULFILLED"
			};
		case eventNames.CLEAR_FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS:
			return {
				...state,
				fetchAllStaffComplete: false
			};
		case `${eventNames.FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				const Anaesthesia = {};
				Anaesthesia[action.meta] = {};
				Anaesthesia[action.meta].staff = action.payload.data.data;
				return {
					...state,
					anaesthesia: Anaesthesia,
					fetchScannedStaffForAnaesthesia: "FULFILLED"
				};
			}
			return {
				...state,
				fetchScannedStaffForAnaesthesia: "FULFILLED"
			};
		case `${eventNames.FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS}_REJECTED`:
			return {
				...state,
				fetchScannedStaffForAnaesthesia: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS:
			return {
				...state,
				fetchScannedStaffForAnaesthesia: false
			};
		default:
			return state;
	}
}
