import Axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getRemarks = (treatmentId, type) => ({
	type: events.HEALTH_PATHWAY_REMARKS_GET,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping?type=${type}&offset=0&numResults=1000`)
});

const clearGetRemarks = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_REMARKS_GET,
	payload: {}
});

const getDefinition = (treatmentId, type) => ({
	type: events.HEALTH_PATHWAY_DEFINITION_GET,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/textElementMapping?type=${type}&offset=0&numResults=1000`)
});

const clearGetDefinition = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_DEFINITION_GET,
	payload: {}
});

const fileUpload = (formData, billType, timestamp) => ({
	type: events.FILE_UPLOAD,
	payload: Axios.post(`${Config.apiRoot}/extract?billType=${billType}&timestamp=${timestamp}`, formData)
});

const clearFileUpload = () => ({
	type: events.CLEAR_FILE_UPLOAD,
	payload: {}
});

const copyToHealthPathways = (id, payload) => ({
	type: events.COPY_TO_HEALTH_PATHWAYS,
	payload: Axios.post(`${Config.apiRootSecure}/healthPathway/${id}/copyToManyHealthPathways`, { ...payload })
});

const clearCopyToHealthPathways = () => ({
	type: events.CLEAR_COPY_TO_HEALTH_PATHWAYS,
	payload: {}
});

const checkCopyToHealthPathwayStatus = (code) => ({
	type: events.CHECK_COPY_TO_HEALTH_PATHWAY_STATUS,
	payload: Axios.get(`${Config.apiRootSecure}/healthPathway/copyToManyHealthPathways/status/${code}`)
});

const checkCopyToHealthPathwayStatusCompleted = () => ({
	type: events.CHECK_COPY_TO_HEALTH_PATHWAY_STATUS_COMPLETED,
	payload: ""
});

const copyFromHealthPathway = (destinationId, sourceId, payload) => ({
	type: events.COPY_FROM_HEALTH_PATHWAY,
	payload: Axios.post(`${Config.apiRootSecure}/healthPathway/${destinationId}/copyFrom/${sourceId}`, { ...payload })
});

const clearCopyFromHealthPathway = () => ({
	type: events.CLEAR_COPY_FROM_HEALTH_PATHWAY,
	payload: ""
});

const communityShare = (treatmentId, payload) => ({
	type: events.COMMUNITY_SHARE_HEALTH_PATHWAY,
	payload: Axios.post(`${Config.apiRootSecure}/healthPathway/${treatmentId}/communityShare`, { ...payload })
});

const communityShareCompleted = () => ({
	type: events.COMMUNITY_SHARE_HEALTH_PATHWAY_COMPLETED,
	payload: ""
});
export default {
	getRemarks,
	clearGetRemarks,
	getDefinition,
	clearGetDefinition,
	fileUpload,
	clearFileUpload,
	copyToHealthPathways,
	clearCopyToHealthPathways,
	checkCopyToHealthPathwayStatus,
	checkCopyToHealthPathwayStatusCompleted,
	copyFromHealthPathway,
	clearCopyFromHealthPathway,
	communityShare,
	communityShareCompleted
};
