import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import categoryPersonnelActions from "../../../../../../../../actions/category-personnel";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import CategoryDropdown from "../category-dropdown";
import Loader from "../../../../../../../../plug/loader";
import NumericInput from "../../../../../../../../plug/numeric-input";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import screenId from "../../../../../../../../router/screenIdList";

class CreateCategoryPersonnel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryPersonnel: {},
			typeValues: {
				isCareTaker: 0,
				isPersonalDoctor: 0,
				isPersonalSupport: 0,
				isPersonalAdmin: 0
			},
			invalidName: false,
			invalidCategory: false,
			invalidAverage: false,
			invalidHMonth: false,
			invalidHYear: false,
			invalidType: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.categoryPersonnelEdit });
		this.props.dispatch(categoryPersonnelActions.fetchOneCategoryPersonnel(this.props.match.params.id));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	onNameChange(value) {
		const prevState = this.state;
		prevState.categoryPersonnel.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	categoryChanged(e) {
		const prevState = this.state;
		prevState.categoryPersonnel.category = e;
		prevState.invalidCategory = false;
		this.setState(prevState);
	}

	typeChanged(e) {
		const prevState = this.state;
		for (const key in prevState.typeValues) {
			if (key === e) {
				prevState.typeValues[key] = 1;
			} else {
				prevState.typeValues[key] = 0;
			}
		}
		prevState.invalidType = false;
		this.setState(prevState);
	}

	togglePermanent(e) {
		const prevState = this.state;
		prevState.categoryPersonnel.isPermanent = e.target.checked ? 1 : 0;
		this.setState(prevState);
	}

	costCategoryChanged(e) {
		const prevState = this.state;
		prevState.categoryPersonnel.costCategory = e;
		this.setState(prevState);
	}

	selectedType() {
		for (const key in this.state.typeValues) {
			if (this.state.typeValues[key]) {
				return key;
			}
		}
	}

	init() {
		this.state.typeValues.isCareTaker = this.state.categoryPersonnel.isCareTaker ? 1 : 0;
		this.state.typeValues.isPersonalAdmin = this.state.categoryPersonnel.isPersonalAdmin ? 1 : 0;
		this.state.typeValues.isPersonalDoctor = this.state.categoryPersonnel.isPersonalDoctor ? 1 : 0;
		this.state.typeValues.isPersonalSupport = this.state.categoryPersonnel.isPersonalSupport ? 1 : 0;
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.categoryPersonnel.name) || !this.state.categoryPersonnel.name || !this.state.categoryPersonnel.name.trim()) {
			prevState.invalidName = true;
		}
		if (!this.state.categoryPersonnel.category) {
			prevState.invalidCategory = true;
		}
		if (!this.state.categoryPersonnel.avgMonthlyCost || this.state.categoryPersonnel.avgMonthlyCost == 0) {
			prevState.invalidAverage = true;
		}
		if (!this.state.categoryPersonnel.hoursPerMonth || this.state.categoryPersonnel.hoursPerMonth == 0) {
			prevState.invalidHMonth = true;
		}
		if (!this.state.categoryPersonnel.hoursPerYear || this.state.categoryPersonnel.hoursPerYear == 0) {
			prevState.invalidHYear = true;
		}
		this.setState(prevState);
		if (!this.state.invalidName
            && !this.state.invalidAverage
            && !this.state.invalidCategory
            && !this.state.invalidHMonth
            && !this.state.invalidHYear
            && !this.state.invalidType) {
			return true;
		}
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.categoryPersonnel.name.trim(),
				category: this.state.categoryPersonnel.category,
				avgMonthlyCost: utils.calculateFloatValue(this.state.categoryPersonnel.avgMonthlyCost),
				hoursPerMonth: utils.calculateFloatValue(this.state.categoryPersonnel.hoursPerMonth),
				hoursPerYear: utils.calculateFloatValue(this.state.categoryPersonnel.hoursPerYear)
			};
			if (this.state.categoryPersonnel.costCategory) {
				payload.costCategory = this.state.categoryPersonnel.costCategory;
			}
			this.props.dispatch(categoryPersonnelActions.updateCategoryPersonnel(this.state.categoryPersonnel.id, payload));
		}
	}

	getNumericValue(label, value) {
		const prevState = this.state;
		prevState.categoryPersonnel[label] = value;
		switch (label) {
			case "avgMonthlyCost": prevState.invalidAverage = false;
				break;
			case "hoursPerMonth": prevState.invalidHMonth = false;
				break;
			case "hoursPerYear": prevState.invalidHYear = false;
				break;
			default: break;
		}
		this.setState(prevState);
	}

	renderEdit() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Code" /></label>
								<input className="form-control w_100" value={this.state.categoryPersonnel.code} readOnly />
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.categoryPersonnel.name}
									inputChanged={this.onNameChange.bind(this)}
									maxLength={60}
								/>
								{this.state.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-6 margin-bottom-5 col-sm-6">
							<CategoryDropdown category={this.state.categoryPersonnel.category} invalidCategory={this.state.invalidCategory} categoryChanged={this.categoryChanged.bind(this)} />
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Average Monthly Cost" /></label>
								<NumericInput value={this.state.categoryPersonnel.avgMonthlyCost} maxLength={10} label="avgMonthlyCost" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidAverage && <span className="invalid_right fixed_label"><I18 tkey="Enter valid average cost" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 float_right">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Hours Per Month" /></label>
								<NumericInput value={this.state.categoryPersonnel.hoursPerMonth} maxLength={10} label="hoursPerMonth" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidHMonth && <span className="invalid_right fixed_label"><I18 tkey="Enter valid hours per month" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-5 position_relative">
								<label><I18 tkey="Hours Per Year" /></label>
								<NumericInput value={this.state.categoryPersonnel.hoursPerYear} maxLength={10} label="hoursPerYear" getNumericValue={this.getNumericValue.bind(this)} />
								{this.state.invalidHYear && <span className="invalid_right fixed_label"><I18 tkey="Enter valid hours per year" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 col-sm-offset-6 col-md-offset-6">
							<div className="di w_100 text_align_right">
								{/* <Checkbox className="margin-top-7 float_left" onChange={this.togglePermanent.bind(this)} checked={this.state.categoryPersonnel.isPermanent}><I18 tkey="Permanent" /></Checkbox> */}
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.categoryPersonnel.isUpdateCategoryPersonnelCompleted === "FULFILLED") {
			this.state.categoryPersonnel = this.props.categoryPersonnel.updatedCategoryPersonnel;
			this.props.dispatch(categoryPersonnelActions.clearUpdateCategoryPersonnel());
			this.props.history.push(locations.categoryPersonnel);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.categoryPersonnel.isFetchOneCategoryPersonnelCompleted === "FULFILLED") {
			this.state.categoryPersonnel = this.props.categoryPersonnel.selectedCategoryPersonnel;
			this.init();
			this.state.categoryPersonnel.isPermanent = this.props.categoryPersonnel.selectedCategoryPersonnel.isPermanent ? 1 : 0;
			this.props.dispatch(categoryPersonnelActions.clearFetchOneCategoryPersonnel());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.categoryPersonnel.name, id: this.state.categoryPersonnel.id } });
		}

		if (this.props.categoryPersonnel.isFetchOneCategoryPersonnelCompleted === "REJECTED") {
			this.props.dispatch(categoryPersonnelActions.clearFetchOneCategoryPersonnel());
			this.props.history.push(locations.categoryPersonnel);
		}
		if (this.state.categoryPersonnel.id) {
			return this.renderEdit();
		}
		return (<Loader loading isTable={false} />);
	}
}

CreateCategoryPersonnel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	categoryPersonnel: store.categoryPersonnel,
	translations: store.translations
}))(CreateCategoryPersonnel));
