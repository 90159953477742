import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import familyActions from "../../../../../../../../actions/consomable-sterilization";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import Speciality from "../../specialities/selectSpecialities";
import Loader from "../../../../../../../../plug/loader";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import screenId from "../../../../../../../../router/screenIdList";

class EditActivityFamilies extends Component {
	constructor(props) {
		super(props);
		this.state = {
			family: {},
			invalidName: false,
			invalidSpeciality: false,
			speciality: {}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.activityFamilyEdit });
		const queryString = "?consumableType=STERILIZATION";
		this.props.dispatch(familyActions.getOneFamily(this.props.match.params.id, queryString));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.family.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	specialityChanged(e) {
		const prevState = this.state;
		prevState.speciality = e;
		prevState.invalidSpeciality = false;
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.family.name) || !(this.state.family.name.trim())) {
			prevState.invalidName = true;
		}
		if (!this.state.speciality || !this.state.speciality.value) {
			prevState.invalidSpeciality = true;
		}
		this.setState(prevState);
		if (!this.state.invalidName && !this.state.invalidSpeciality) {
			return true;
		}
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.family.name,
				consumableSpecialityId: this.state.speciality.value,
				consumableType: "STERILIZATION"
			};
			this.props.dispatch(familyActions.updateFamily(this.state.family.id, payload));
		}
	}

	renderEdit() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="margin-bottom-10 subheader">
						<span>
							<I18 tkey="Type" />
              :
							{" "}
						</span>
						<span><I18 tkey="Sterilization" /></span>
&nbsp;&nbsp;

					</div>
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<input className="form-control w_100" value={this.state.family.code} readOnly />
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.family.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={120}
								/>
								{this.state.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Select Speciality" /></label>
								<Speciality
									data={this.state.speciality ? this.state.speciality : ""}
									specialityChanged={this.specialityChanged.bind(this)}
								/>
								{this.state.invalidSpeciality && <span className="invalid_right fixed_label"><I18 tkey="Select a speciality" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6 margin-top-25-check">
							<div className="di w_100 text_align_right">
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.state.family.id) {
			return this.renderEdit();
		}
		return (<Loader loading isTable={false} />);
	}

	componentDidUpdate() {
		if (this.props.consomableSterilization.updateFamilyStatus === "FULFILLED") {
			this.state.family = {};
			this.props.dispatch(familyActions.updateFamilyCompleted());
			this.props.history.push(locations.sterilization_families);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.setState({ ...this.state });
		}
		if (this.props.consomableSterilization.selectedFamilyStatus === "FULFILLED") {
			this.state.family = this.props.consomableSterilization.selectedFamily[0];
			this.state.speciality.value = this.state.family.consumableSpecialityId;
			this.state.speciality.label = this.state.family.consumableSpecialityName;
			this.props.dispatch(familyActions.clearGetOneFamily());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.family.name, id: this.state.family.id } });
			this.setState({ ...this.state });
		}
		if (this.props.consomableSterilization.selectedFamilyStatus === "REJECTED") {
			this.props.dispatch(familyActions.clearGetOneFamily());
			this.props.history.push(`${locations.sterilization_families}/${utils.checkForParameter("organization", this.props.location)}?typeActivity=${this.props.typeActivity}`);
		}
	}
}

EditActivityFamilies.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	consomableSterilization: store.consomableSterilization,
	translations: store.translations
}))(EditActivityFamilies));
