import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal } from "antd";
import elmentClassAction from "../../../../../../../../actions/element-class";
import Create from "./createClass"; 

class CreateClass extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showClassCreate: false,
			newClassName: "",
			speciality: null,
			family: null,
			invalidFamily: false,
			type: null,
			invalidType: false
		};
	}

	createNewClass() {
		this.setState({ showClassCreate: true });
	}
    
	onClassNameChange(e) {
		this.setState({ newClassName: e.target.value, invalidClassName: false });
	}

	onTypeChange = (e) => {
		if (e) {
			this.setState({ type: e, invalidType: false });
		} else {
			this.setState({ type: null, invalidType: true });
		}
        
	};

	specialityChanged(e) {
		const prevState = this.state;
		prevState.speciality = e ? e : null;
		prevState.family = null;
		prevState.invalidFamily = false;
		this.setState(prevState);
	}

	onFamilyChanged(e) {
		const prevState = this.state;
		prevState.family = e ? e : null;
		this.setState(prevState);
	}

	validate() {
		const prevState = this.state;
		if (!prevState.newClassName || !utils.checkConsomableName(prevState.newClassName) || !prevState.newClassName.trim()) {
			prevState.invalidClassName = true;
		}
		if (!prevState.family || !prevState.family.value) {
			prevState.invalidFamily = true;
		}
		if (prevState.type && prevState.type.value) {
			prevState.invalidType = false;
			this.setState({ invalidType: false });
		} else {
			prevState.invalidType = true;
			this.setState({ invalidType: true });
		}
		if (prevState.invalidClassName || prevState.invalidFamily || prevState.invalidType) {
			this.setState(prevState);
			return false;
		}
        
		return true;
	}
    
	handleOk() {    
		if (this.validate()) {
			const payload = { 
				name: this.state.newClassName,
				elementId: this.props.match.params.id, 
				specialityId: this.state.speciality.value,
				familyId: this.state.family.value,
				type: this.state.type.value
			};
			this.props.dispatch(elmentClassAction.createClass(payload));
		}
	}

	handleCancel() {
		this.setState({ 
			showClassCreate: false, 
			newClassName: "", 
			invalidClassName: false, 
			speciality: null, 
			family: null, 
			invalidFamily: false });
	}

	render() {
		if (this.props.elementClass.elementClassCreate === "FULFILLED") {
			this.handleCancel();
			this.props.createClassCompleted(true);
			this.props.dispatch(elmentClassAction.clearCreateClass());
		}

		return ( 
			<Fragment>
				<div className="pole_create_section di w_100 padding-top-15 padding-bottom-0 ">
					<button 
						className="common_button margin_top_15_mob  margin-right-0 " 
						type="button" 
						onClick={this.createNewClass.bind(this)}>
						<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
						<I18 tkey="CREATE" />
					</button>
				</div> 
				<Modal
					className="re_assign_modal"
					title={i18Get("Create class", utils.getLanguage())}
					visible={this.state.showClassCreate}
					onOk={this.handleOk.bind(this)}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}>
					<Create
						newClassName={this.state.newClassName}
						onClassNameChange={this.onClassNameChange.bind(this)}
						invalidClassName={this.state.invalidClassName}
						speciality={this.state.speciality}
						specialityChanged={this.specialityChanged.bind(this)}
						family={this.state.family}
						onFamilyChanged={this.onFamilyChanged.bind(this)}
						invalidFamily={this.state.invalidFamily}
						typeChange={this.onTypeChange}
						invalidType = {this.state.invalidType}
					/>
				</Modal>
			</Fragment>
		);
	}
}

CreateClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elementClass: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	elementClass: store.elementClass,
	translations: store.translations
}))(CreateClass));                    