import React, { Component } from "react";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ColumnResizer from "../../../../../../../plug/react-table-column-resizer";
import { RightCircleOutlined, DownCircleOutlined } from "@ant-design/icons";
import { Tooltip, Checkbox } from "antd";
import permissions from "../../../../../../../utils/permission";
import InterventionLocationDropDown from "./interventionLocationDropDown";

class TableData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			colWidths: {},
			expandedProblemAndCheckListIds: [],
			sectionTypeHeaderTopPadding: 0,
			checkList: [],
			stepSectionType: null
		};
	}

	expandClicked = (data, item) => {
		const prevState = this.state;
		prevState.expandedProblemAndCheckListIds.push({ problemId: data.problemId, checkListTreatmentId: item.checkListTreatmentId });
		this.setState(prevState);
	};

	collapseClicked = (data, item) => {
		const prevState = this.state;
		const index = prevState.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === item.checkListTreatmentId);
		if (index >= 0) {
			prevState.expandedProblemAndCheckListIds.splice(index, 1);
		}
		this.setState(prevState);
	};

	filterCheckList = (data, item) => {
		const index = this.state.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === item.checkListTreatmentId);
		if (index < 0) {
			return false;
		}
		return true;
	};


	rowHider = (data, item, index) => {
		if (data.problem && item && !item.isCheckList) {
			return true;
		} else if (item && item.isCheckList && item.isHeading) {
			return true;
		} else if (item && item.checkListTreatmentId && this.filterCheckList(data, item)) {
			return true;
		}
		else {
			return false;
		}
	};

	checkAdditionalInfo(checkList, additionalInfo, parentProblemId) {
		if (checkList && checkList.length) {
			const checkListIndex = checkList.findIndex(row => row.problemId === parentProblemId);
			if (checkListIndex < 0) { return false; }
			const index = checkList[checkListIndex].additionalInfo.findIndex(value => value.id === additionalInfo.id && (value.checked ? value.checked === true : null) && value.name === additionalInfo.name);
			if (index !== -1) {
				return true;
			}
			else {
				return false;
			}
		}
	}

	checkAllCheckListItem(checked, additionalInfo, problem) {
		if (additionalInfo && additionalInfo.isCheckList && additionalInfo.isHeading) {
			problem.additionalInfo.map(function (data) {
				if (additionalInfo.id == data.checkListTreatmentId) {
					data["checked"] = checked;
				}
			});
		}
	}

	onCheckBoxChange = (e, value, type, additionalInfo) => {
		let index;
		if (type === "additionalInfo") {
			index = this.state.checkList.indexOf(value.problemId);
		}
		const prevState = this.state;
		if (e.target.checked) {
			if (index === -1) {
				if (type === "additionalInfo") {
					prevState.checkList.map(function (e) {
						if (e.problem === value.problem && e.problemId === value.problemId) {
							e["checked"] = true;
							e.additionalInfo.map(function (data) {
								if (data.name === additionalInfo.name && data.id === additionalInfo.id) {
									data["checked"] = true;
								}
							});
						}
					});
				}
			}
		}
		else if (type === "additionalInfo") {
			prevState.checkList.map(function (e) {
				if (e.problem === value.problem && e.problemId === value.problemId) {
					e["checked"] = true;
					e.additionalInfo.map(function (data) {
						if (data.name === additionalInfo.name && data.id === additionalInfo.id) {
							delete data["checked"];
						}
					});
				}
			});
		}
		this.setState(prevState);
		this.props.getSelectedComponents(this.state.checkList);
		this.checkAllCheckListItem(e.target.checked, additionalInfo, value);
	};

	onChangeInterventionLocation = (e, problemIndex, additionalIndex) => {
		const prevState = this.state;
		prevState.checkList[problemIndex].additionalInfo[additionalIndex].subStepMapping = e;
		this.setState(prevState);
	};

	formatSteps = data => {
		const steps = [];
		data.map((step) => {
			if (step.clpSubStepId) {
				steps.push(`${i18Get(step.stepName, utils.getLanguage())} -> 
                ${step.day ? `${i18Get("DAY", utils.getLanguage())} ${i18Get(step.startPrefix, utils.getLanguage())} 
                ${step.endPrefix ? `${i18Get("To", utils.getLanguage())} ${i18Get("DAY", utils.getLanguage())} ${i18Get(step.endPrefix, utils.getLanguage())}`
		: ""}` : i18Get(step.subStepName.trim(), utils.getLanguage())}`);
			}
		});
		return steps.join(", ");
	};

	getAdditionalInfo(data, problemIndex) {
		const tempList = [];
		if (data.stepSectionType !== this.state.stepSectionType) {
			this.state.stepSectionType = data.stepSectionType;
			tempList.push(this.sectionTypeHeader(data.stepSectionType));
		}
		for (let index = 0; index <= data.additionalInfo.length; index++) {
			const item = data.additionalInfo[index];
			if (item != utils.sameDropper() && this.rowHider(data, item, index)) {
				tempList.push(
					<tr>
						<td className={data.modelTreatmentId ? "model_attached_td" : ""}>
							{data.modelTreatmentName ? <Tooltip placement="top" title={"Model Name: " + (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName}>
								{data.problem && item && !item.isCheckList ? data.problem : data.problem && item && item.checkListTreatmentId && this.filterCheckList(data, item) ? data.problem : ""}</Tooltip> : (data.problem && item && !item.isCheckList ? data.problem : data.problem && item && item.checkListTreatmentId && this.filterCheckList(data, item) ? data.problem : "")}
						</td>
						<ColumnResizer className="columnResizer" minWidth={0} />
						{!this.props.treatmentId ?
							<td className={`text_align_center ${item && item.modelTreatmentId ? "model_attached_td" : ""} `}>
								<div className='drop-round-arrow'>
									<div className="text_align_center margin-bottom-0 margin-top-0 margin-left-0 margin-right-0 d-flex align-items-center">
										{permissions(this.props.clinicalPathwayData.isReadOnly, "EDIT") && this.props.comparisonMethod === "3" ?
											<div className="margin-right-5">
												{item.isCheckList && !item.isHeading ?
													<div className={item.isCheckList ? "check_list check_list_bold check_list padding-left-20" : ""}>
														<Checkbox disabled checked={this.checkAdditionalInfo(this.state.checkList, item, data.problemId)} />
													</div>
													:
													<div className={item.isCheckList ? "check_list check_list_bold " : ""}>
														<Checkbox
															checked={this.checkAdditionalInfo(this.state.checkList, item, data.problemId)}
															onChange={(e) => this.onCheckBoxChange(e, data, "additionalInfo", item)} />
													</div>
												}
											</div> : ""}

										{item && item.isCheckList && item.isHeading ? this.filterCheckList(data, item) ?
											<DownCircleOutlined style={{ color: "#2016b8" }} className="check_list" onClick={() => this.collapseClicked(data, item)} />
											:
											<RightCircleOutlined style={{ color: "#2016b8" }} className="align-items-center check_list" onClick={() => this.expandClicked(data, item)} />
											: ""
										}
									</div>
								</div>
							</td> : ""
						}
						<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
							{index === data.additionalInfo.length ?
								""
								:
								item.modelTreatmentName ?
									<Tooltip placement="top" title={"Model Name: " + (item.modelHospitalName ? item.modelHospitalName + " " : "") + item.modelTreatmentName}>
										{!item.isCheckList ? item.interventionSummaryName : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.interventionSummaryName}</span> : ""}
									</Tooltip> :
									!item.isCheckList ? item.interventionSummaryName : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.interventionSummaryName}</span> : ""
							}
						</td>
						<ColumnResizer className="columnResizer" minWidth={0} />
						<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
							{
								this.props.comparisonMethod === "3" ?
									this.props.srcAndDestClpSame ?
										item.subStepMapping ? this.formatSteps(item.subStepMapping) : item.subStepMapping && this.filterCheckList(data, item) ? <span className="check_list">{this.formatSteps(item.subStepMapping)}</span> : ""
										:
										item.checked && !item.isHeading ?
											<InterventionLocationDropDown
												stepsAndSubSteps={this.props.stepsAndSubSteps}
												onChange={(e) => { this.onChangeInterventionLocation(e, problemIndex, index); }}
												sectionType={data.stepSectionType}
												subStepMapping={item.subStepMapping}
												checked={item.checked}
											/>
											: ""
									:
									item.subStepMapping ? this.formatSteps(item.subStepMapping) : item.subStepMapping && this.filterCheckList(data, item) ? <span className="check_list">{this.formatSteps(item.subStepMapping)}</span> : ""
							}
						</td>
						<ColumnResizer className="columnResizer" minWidth={0} />
						<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
							{index === data.additionalInfo.length ?
								"" : !item.isCheckList && item.categoryPersonnelName ? item.categoryPersonnelName : this.filterCheckList(data, item) && item.categoryPersonnelName ? <span className="check_list">{item.categoryPersonnelName}</span> : ""
							}
						</td>
					</tr>
				);
			}
		}
		return tempList;
	}

	getTableContent(list) {
		if (list && list.length > 0) {
			const data = list.map((data, dataIndex) => {
				return (
					<tbody key={dataIndex} id={data.problemId}>
						{this.getAdditionalInfo(data, dataIndex)}
					</tbody>);
			});
			return data;
		}
		else {
			return (
				<tr>
					<td colSpan={18}>
						<div className="text_align_center w_100">
							<I18 tkey={"NO HEALTH PROBLEM FOUND"} />
						</div>
					</td>
				</tr>
			);
		}
	}

	widthSetter(columnName) {
		const name = i18Get(columnName ? columnName : "", utils.getLanguage());
		if (!this.state.colWidths[name]) {
			return {
				minWidth: 150
			};
		}
	}

	sectionTypeHeader(stepSectionType) {
		return (
			<tr>
				<td className="subHead" colSpan={8}>
					<I18 tkey={stepSectionType === "CLINICAL_PATHWAY" ? "HOSPITALISATION" : stepSectionType.replace(/_/g, " ").toUpperCase()} />
				</td>
			</tr>
		);
	}

	render() {

		this.state.checkList = this.props.checkList ? this.props.checkList : [];
		this.state.stepSectionType = null;
		return (
			<div className="fullbody_table">
				<table id="table" data-show-columns="true">
					<thead id='pathway_problem_table_header'>
						<tr>
							<th style={this.widthSetter("PROBLEM")}>
								<I18 tkey='PROBLEM' />
							</th>
							<ColumnResizer className="columnResizer" minWidth={0} />
							<th></th>
							<th style={this.widthSetter("INTERVENTIONS SUMMARY NAME")}>
								<I18 tkey='INTERVENTIONS SUMMARY NAME' />
							</th>
							<ColumnResizer className="columnResizer" minWidth={0} />
							<th style={this.widthSetter("STEPS")}>
								<I18 tkey='STEPS' />
							</th>
							<ColumnResizer className="columnResizer" minWidth={0} />
							<th style={this.widthSetter("INTERVENANTS")}>
								<I18 tkey='INTERVENANTS' />
							</th>
						</tr>
					</thead>
					{this.getTableContent(this.props.list)}
				</table>
			</div>);
	}
}

export default TableData;