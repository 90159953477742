import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import "./scanned-treatment.css";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import locations from "../../../../../../../../router/locations";

class Search extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		const isScannedTreatment = (this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1);
		return (
			<li className={!isScannedTreatment ? "float_right_li_special" : "responsive_input_bars_position_two fr"}>
				<a className="padding-0 scanned_search">
					<DebounceInput
						debounceTimeout={750}
						placeholder={i18Get("Search...", utils.getLanguage())}
						value={this.props.search}
						onChange={this.props.searchChanged}
					/>
				</a>
			</li>
		);
	}
}

export default withRouter(Search);
