import React from "react";
import { connect } from "react-redux";
import isObject from "lodash.isobject";
import Store from "../store";

function evalTemplate(strans, params) {
	let tString = strans;
	if (isObject(params) && isObject(params.S)) {
		Object.keys(params.S).forEach((k) => {
			tString = tString.replace(`\${S.${k}}`, params.S[k]);
		});
	}
	return tString;
}

export function i18Get(key, lang, S) {
	const state = Store.getState();
	const trans = state.translations.languages.translations ? state.translations.languages.translations[lang] : "";
	try {
		let transText = key;
		if (trans && typeof trans === "object" && key in trans) {
			transText = trans[key];
		}
		// transText = '' + transText + '';
		return evalTemplate(transText, { S });
	} catch (err) {
		// console.log(err)
		// nothing to catch
	}
	return key;
}

class I18 extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const key = this.props.tkey;
		const { language } = this.props.translations;
		const S = this.props.params;
		return i18Get(key, language, S);
	}
}

export default connect((state) => ({
	translations: state.translations
}))(I18);
