import React, { Component } from "react";
import { Select } from "antd";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";

const { Option } = Select;

class ClpTypeDropDown extends Component {
	constructor(props) {
		super(props);
	}

	options() {
		const options = [];
		if (this.props.isClinicalPathway) {
			options.push(<Option key="ALL" value="ALL"><I18 tkey="ALL" /></Option>);
		}
		options.push(<Option key="SURGERY" value="SURGERY"><I18 tkey="SURGERY" /></Option>);
		options.push(<Option key="MEDICINE WITH ACTE" value="MEDICINE"><I18 tkey="MEDICINE WITH ACTE" /></Option>);
		options.push(<Option key="MEDICINE WITHOUT ACTE" value="MEDICINE_WITHOUT_ACTE"><I18 tkey="MEDICINE WITHOUT ACTE" /></Option>);
		return options;
	}

	render() {
		return (
			<div className="w_100 di position_relative">
				<Select
					className="w_100 create_level"
					placeholder={i18Get("Select Clinical Pathway Type", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					optionFilterProp="children"
					onChange={this.props.clpTypeChanged}
					value={this.props.data}
				>
					{this.options()}
				</Select>
			</div>
		);
	}
}

export default withRouter(ClpTypeDropDown);
