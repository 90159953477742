import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import translationActions from "../../../../../../actions/translation";
import ActionButton from "../../../../../../plug/action-button";
import locations from "../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import events from "../../../../../../actions/events";
import screenId from "../../../../../../router/screenIdList";

class CreateTranslation extends Component {
	constructor(props) {
		super(props);
		this.state = {
			text: "",
			translation: "",
			invalidText: false,
			invalidTranslation: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.translationCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	nameChanged(label) {
		return (e) => {
			const prevState = this.state;
			prevState[label] = e.target.value;
			if (label === "text") {
				prevState.invalidText = false;
			} else if (label === "translation") {
				prevState.invalidTranslation = false;
			}
			this.setState(prevState);
		};
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.text.trim()) {
			prevState.invalidText = true;
		}
		if (!this.state.translation.trim()) {
			prevState.invalidTranslation = true;
		}
		this.setState(prevState);
		if (!this.state.invalidText
            && !this.state.invalidTranslation) {
			return true;
		}
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				field: this.state.text,
				translation: this.state.translation
			};
			this.props.dispatch(translationActions.createTranslation(utils.getLanguage(), payload));
		}
	}

	render() {
		if (this.props.translations.isTranslationCreateCompleted === "FULFILLED") {
			this.props.dispatch(translationActions.clearCreateTranslation());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(translationActions.getAllTranslations());
			this.props.history.push(locations.translations);
		}
		if (this.props.translations.isTranslationCreateCompleted === "REJECTED") {
			this.props.dispatch(translationActions.clearCreateTranslation());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Text" /></label>
								<input
									className="form-control w_100"
									key={1}
									value={this.state.text}
									onChange={this.nameChanged("text").bind(this)}
									maxLength={200}
								/>
								{this.state.invalidText && <span className="invalid_right fixed_label"><I18 tkey="Enter valid text" /></span>}
							</div>
						</div>
						<div className="col-md-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Translation" /></label>
								<input
									className="form-control w_100"
									key={2}
									value={this.state.translation}
									onChange={this.nameChanged("translation").bind(this)}
									maxLength={200}
								/>
								{this.state.invalidTranslation && <span className="invalid_right fixed_label"><I18 tkey="Enter valid translation" /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="di w_100 text_align_right">
								<ActionButton showAdd onClick={this.createClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateTranslation.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	translations: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations
}))(CreateTranslation));
