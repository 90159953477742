import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import ReactMultiSelectCheckboxes from "../../../../../../../plug/react-multiselect-checkboxes";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import categoryPersonnelActions from "../../../../../../../actions/category-personnel";
let timer = null;
const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};
const CategoryPersonnelDropdown = (props) => {

	const [list, setList] = useState([]);
	const [options, setOption] = useState([]);
	const [offset, setOffset] = useState(0);
	const [numResults, setNumResults] = useState(100);
	const [search, setSearch] = useState("");
	const  [isListLoading, setIsListLoading] = useState(false);
	const dispatch = useDispatch();
	const categoryPersonnel = useSelector(store => store.categoryPersonnel);
	useEffect(() => {
		fetchList();
	}, []);

	useEffect(() => {
		if ((props.clinicalPathway && props.data && props.data.length > 0 && props.filterData ) ||
        (props.listActiveFirst && props.data && props.data.length > 0)) {
			const filteredData = options.filter(e => !props.data.find(rm => (rm.value === e.value)));
			setOption(props.data.concat(filteredData));
		}
	}, [props]);
	useEffect(() => {
		if (categoryPersonnel.isFetchAllApprovedCategoryPersonnelCompleted === "FULFILLED") {
			const nList = unionWith(list, categoryPersonnel.categoryPersonnelList.data, isEqual);
			setList(nList);
			renderOptions(nList); 
			dispatch(categoryPersonnelActions.clearFetchAllApprovedCategoryPersonnel());
		}
	
	}, [categoryPersonnel]);


	function renderOptions(nList) {

		if (nList) {
			nList.map((data) => {
				options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		
		if (props.data && props.data.length === 0 && props.autoSetSingleOne === true) {
			const multiSelect = !props.clinicalPathway ? false : true;
			utils.setSingleOneAsDefaultOption(options, props.categoryPersonnelChanged, multiSelect);
		}
		setIsListLoading(false);
		setOption(options);
	}

	function fetchList() {
		setIsListLoading(true);
		let queryString = `offset=${offset}&numResults=${numResults}&sort=name&desc=0`;
		queryString += "&status=APPROVED";
		queryString += search ? `&name=${search}` : "";
		dispatch(categoryPersonnelActions.fetchAllApprovedCategoryPersonnel(queryString));
	}

	function onSearch(searchTxt) {

		setOffset(0);
		if (search !== searchTxt) {
			clearTimeout(timer);
			setSearch(searchTxt);
			setIsListLoading(true);
			timer = setTimeout(() => {
				fetchList(search);
			}, 800);
		}
	}

	function onListScroll() {
		setOffset(offset + numResults);
		fetchList();
	}

	return (
		<div className={props.className}>
			{!props.clinicalPathway ?
				<div>
					<Select
						styles={customStyles}
						className="w_100 di Select_Category_Personnel"
						onChange={props.categoryPersonnelChanged}
						value={props.data}
						isSearchable
						isClearable
						options={options}
						onMenuScrollToBottom={onListScroll}
						onInputChange={onSearch}
						isLoading={isListLoading}
						noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
						loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
						placeholder={i18Get("Select Category Personnel", utils.getLanguage())}
					/>
				</div> :
				<div className={!props.disabled ? `react_multi_select_checkbox${!props.isAdminPage ? " drop_max_height_150" : ""}` : ""}>
					{!props.disabled ?
						<ReactMultiSelectCheckboxes
							className="w_100 di Select_Category_Personnel"
							value={props.data ? props.data : null}
							onChange={props.categoryPersonnelChanged}
							options={options}
							isMulti={true}
							isClearable={true}
							isDisabled={props.disabled}
							onMenuScrollToBottom={onListScroll}
							onInputChange={onSearch}
							placeholderButtonLabel={i18Get("Select Intervenant", utils.getLanguage())}
							isLoading={isListLoading}
							noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
							loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
						/>
						:
						<Select
							className="w_100 di  Select_Category_Personnel"
							isDisabled={true}
							placeholder={i18Get("Select Intervenant", utils.getLanguage())}
						/>
					}
				</div>}
		</div>
	);
};
export default CategoryPersonnelDropdown;