import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
	EditorState, ContentState, convertToRaw, getDefaultKeyBinding
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import textElementActions from "../../../../../../actions/text-element";
import ActionButton from "../../../../../../plug/action-button";
import eventNames from "../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import BreadCrumb from "../../breadcrumb";
import "./text-element.css";
import typeActivity from "../../../../../../constants/typeActivity.json";
import locations from "../../../../../../router/locations";

class Definition extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			textElement: {},
			textElementId: null,
			type: "",
			activeCycle: {},
			invalidDescription: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.activeCycle = this.props.cycle ? this.props.cycle : {};
		this.state.textElementId = this.props.healthPathwayDetails.definition && this.props.healthPathwayDetails.definition.length ? this.props.healthPathwayDetails.definition[0].textElementId : null;
	}

	getName() {
		return this.props.type.replace(/_/g, " ");
	}

	getLink(type) {
		return `/health_pathway/${this.props.match.params.organization}/${this.props.match.params.id}` + `${type ? `/sub_health_pathway/${type}` : ""}`;
	}

	fetchData() {
		if (this.state.textElementId) {
			this.props.dispatch(textElementActions.getHealthPathwayDefinition(this.props.type, this.props.match.params.id, this.state.textElementId));
		} else {
			this.props.history.push(`${locations.health_pathway_plain}/${this.props.match.params.organization}/${this.props.match.params.id}`);
		}
	}

	onEditorStateChange(editorState) {
		const prevState = this.state;
		prevState.invalidDescription = false;

		if (this.getLength(editorState) >= utils.editorMaxLength && this.checkLastChangeType(editorState)) {
			this.setState({ editorState: this.state.editorState });
			return;
		}
		prevState.editorState = editorState;

		this.setState(prevState);
	}

	checkLastChangeType(editorState) {
		return ((editorState.getLastChangeType() === "insert-characters") || (editorState.getLastChangeType() === "split-block"));
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	handleBeforeInput() {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength) {
			return "handled";
		}
	}

	handlePastedText(text) {
		if (text && ((this.getLength(this.state.editorState) + text.length) >= utils.editorMaxLength)) {
			return true;
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command) {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
			return "handled";
		}
		return "not-handled";
	}

	init() {
		const contentBlock = htmlToDraft(this.state.textElement.description ? this.state.textElement.description : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);
		}
	}

	generateItems() {
		const items = [];
		if (this.props.typeActivity === typeActivity.HEALTH_PATHWAY.toUpperCase()) {
			items.push({
				name: i18Get("Health Pathway", utils.getLanguage()),
				link: `/health_pathway/${this.props.match.params.organization}`
			});
			items.push({ name: this.props.healthPathway ? this.props.healthPathway : i18Get("Health Pathway Details", utils.getLanguage()), link: this.getLink() });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: this.getLink("definition") });
		}
		return items;
	}

	saveClicked() {
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			id: this.state.textElement.id,
			textElementId: this.state.textElementId,
			type: this.props.type,
			description: this.state.editorState.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) : ""
		};
		this.props.dispatch(textElementActions.updateHealthPathwayDefinition(this.props.match.params.id, this.props.type, payload));
	}

	validateFields() {
		let valid = true;
		const prevState = this.state;
		const description = prevState.editorState.getCurrentContent().hasText() ? draftToHtml(convertToRaw(prevState.editorState.getCurrentContent())) : "";

		if (description ? !description.trim() || (description.lastIndexOf("\\") >= 0) || (description.indexOf("\"") >= 0) : false) {
			valid = false;
			prevState.invalidDescription = true;
		}

		this.setState(prevState);
		return valid;
	}

	render() {
		if (this.props.type !== this.state.type) {
			this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
			this.state.type = this.props.type;
			this.fetchData();
		}
		if (this.props.textElements.isHealthPathwayDefinitionCompleted === "FULFILLED") {
			this.state.textElement = this.props.textElements.elementsData ? this.props.textElements.elementsData : {};
			this.state.textElementId = this.state.textElement.textElementId;
			this.init();
			this.props.dispatch(textElementActions.clearGetHealthPathwayDefinition());
		}
		if (this.props.textElements.isHealthPathwayDefinitionUpdateComplete === "FULFILLED") {
			this.fetchData();
			this.props.dispatch(textElementActions.clearUpdateHealthPathwayDefinition());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				<div className="risk_preparation_header float_left di w_100 padding-0 margin-top-30 margin-bottom-15">
					<div className="row">
						<div className="col-md-10 col-sm-10">
							<div className="w_100 di margin-bottom-18">
								<Editor
									wrapperClassName="wrapper-class"
									editorClassName="editor-class"
									toolbarClassName="toolbar-class"
									editorState={this.state.editorState}
									onEditorStateChange={this.onEditorStateChange.bind(this)}
									handleBeforeInput={this.handleBeforeInput.bind(this)}
									handlePastedText={this.handlePastedText.bind(this)}
									keyBindingFn={this.keyBindingFn.bind(this)}
									handleKeyCommand={this.handleKeyCommand.bind(this)}
									localization={{
										locale: utils.getLanguage()
									}}
									toolbar={{
										options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"]
									}}
								/>
								{this.state.invalidDescription && <span className="invalid_eligiblity_edit fixed_label"><I18 tkey='Enter Valid Description (Special characters " \ not allowed)' /></span>}
							</div>
						</div>
					</div>
					<div className="di col-sm-10 text_align_right margin-top-15">
						<ActionButton  onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
					</div>
				</div>
			</div>
		);
	}
}

Definition.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	textElements: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	textElements: store.textElements,
	actions: store.actions,
	translations: store.translations,
	healthPathwayDetails: store.healthPathwayDetails
}))(Definition));
