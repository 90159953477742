import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import actions from "../../../../../../../actions/sterilization";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import events from "../../../../../../../actions/events";
import NameInput from "../../../../../../../plug/regex-name-search-input";

export class Create extends Component {

	constructor(props) {
		super(props);
		this.state = {
            
		};
	}

	codeChanged = (value) => {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.code = text;
		} else {
			prevState.code = this.state.code ? this.state.code : "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	};

	nameChanged = (value) => {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	};

	costConstantChange = (label, value) => {
		if (value > 100) {
			value = 100;
		}
		this.setState({ ...this.state, costConstant: value });
	};
       
	validateFields = () => {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !(this.state.name.trim())) {
			prevState.invalidName = true;
		}
		if (!utils.regexSpecialityCode.test(this.state.code)) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	};

	saveClicked = () => {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name,
				code: this.state.code,
				consumableType: "STERILIZATION"
			};
			this.props.dispatch(actions.createConsomableSpeciality(payload));
		}
	};

	render() {
		return (
			<div className="">
				<div className="">
                   
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<label className="code_label"><I18 tkey="* Code must be 3-digit uppercase characters" /></label>
								<NameInput
									value={this.state.code}
									inputChanged={this.codeChanged}
									maxLength={3}
								/>
								{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Enter valid code" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.nameChanged}
									maxLength={60}
								/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
                       
                          
                       
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton showAdd={true} onClick={this.saveClicked} value={i18Get("CREATE", utils.getLanguage())}/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	componentDidUpdate() {
		if (this.props.sterilization.createConsomableSpecialityStatus === "FULFILLED") {
			this.props.dispatch(actions.createConsomableSpecialityCompleted());
			this.props.history.push(locations.sterilization_speciality);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
	}
}



Create.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	specialty: store.specialty,
	actions: store.actions,
	translations: store.translations,
	sterilization: store.sterilization
}))(Create)); 
