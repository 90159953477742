import axios from "axios";
import Config from "../../../../../../../MOM-config";
import events from "./event";

const getAll = (query) => ({
	type: events.GET_ALL_SPECITALITIES_LIST,
	payload: axios.get(`${Config.apiRootSecure}/speciality${query}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_GET_ALL_SPECITALITIES_LIST,
	payload: {}

});

export default {
	getAll,
	clearGetAll
};
