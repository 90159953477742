import React from "react";
import { Tooltip } from "antd";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../constants";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
function getText(value) {
	return <div>{value}</div>;
}

export default function getCols(editClicked, deleteClicked, approveClicked, isPermissionGranted) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto min_w_34 text_align_left">
					{row.value.status === statusList.STATUS.values.APPROVED && isPermissionGranted("EDIT")
						? <EditOutlined className="edit_color margin-right-10" type="edit" onClick={editClicked(row.value)} /> : 
						isPermissionGranted("EDIT") ? (
							<a className="waiting-for-approve" onClick={approveClicked(row.value)}>
								<img src="/images/waitingforapprovel.png" />
							</a>
						) : ""
					}
					{row.value.status === statusList.STATUS.values.APPROVED && !row.value.isMandatory && isPermissionGranted("EDIT")
						? <DeleteOutlined className="delete_color" type="delete" onClick={deleteClicked(row.value)} /> : ""}
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			width: 100,
			resizable: true,
			sortable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("ITEM", utils.getLanguage()),
			id: "item",
			accessor: (d) => d.item,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("INTERVENANT", utils.getLanguage()),
			id: "categoryPersonnelName",
			accessor: (d) => d.categoryPersonnelName,
			resizable: true,
			sortable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d,
			width: 150,
			resizable: true,
			Cell: (row) => (
				<Tooltip placement="top" title={i18Get(`${row.value.status ? row.value.status === "APPROVED" ? "Approved" : "Waiting For Approval" : ""} - From ${row.value.hospitalName ? row.value.hospitalName : "Master"}`, utils.getLanguage())}>
					<div className="d-flex align-items-center">
						{row.value.hospitalName ? row.value.hospitalName : "Master"}
						<div className={`hospitalname_tag margin-left-10 ${row.value.hospitalName ? "hospitalnameactive" : ""}`} />
					</div>
				</Tooltip>

			)
		}
	]);
	return tableArray;
}
