import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../../router/locations";
import PersonnelTemplates from "./personnel-templates";
import TemplateDetails from "./template-details";

export default class AdminPersonnelListRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`}
					render={() => <PersonnelTemplates {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.adminPersonnel}/${locations.tabTemplateDetails}`}
					render={() => <TemplateDetails {...this.props} />}
				/>
				<Redirect to={`${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`} />
			</Switch>
		);
	}
}
