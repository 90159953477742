import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button, Checkbox } from "antd";
import clinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import standardCareChartActions from "../../../../../../../../actions/standard-care-chart";
import ProblemListeDropDown from "../problem-list-dropdown";
import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import DragWindow from "../../drag-window";
import "antd/dist/antd.min.css";
import ReorderAdditionalInfo from "./reorder-additionalInfo";
import ReorderProblem from "./reorderProblem";
import ReorderByModel from "./reorderModel";

class QuickReorder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			quickReorderChanges: 0,
			orderChanged: false,
			problemWidth: 300,
			typeWidth: 300,
			intervenantWidth: 300,
			selectedItems: [],
			unSelect: false,
			tab: (this.props.clinicalPathwayData.isCheckList || this.props.clinicalPathwayData.isModel) ? "problem" : "model",
			problemList: [],
			additionalInfoList: [],
			saveAndNext: false,
			loadingSaveAndNext: false,
			rankingOfModelLoading: false,
			code: null,
			modelProblemRank: false,
			modelAdditionalInfoRank: false,
			visibleApplyCibleAndTaskRanking: false,
			saveStatus: 0,
			hideModel: (this.props.clinicalPathwayData.isCheckList || this.props.clinicalPathwayData.isModel),
			totalItems: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.setState({ quickReorderChanges: this.props.quickReorderChanges, list: JSON.parse(JSON.stringify(this.props.clinicalPathwayProblems.problemList.mapping)) });
	}

	componentDidUpdate(prevProps) {
		const prevState = this.state;
		if (prevProps.quickReorderChanges !== this.props.quickReorderChanges) {
			prevState.list = JSON.parse(JSON.stringify(this.props.clinicalPathwayProblems.problemList.mapping));
			prevState.quickReorderChanges = this.props.quickReorderChanges;
			prevState.selectedItems = [];
			if (this.state.problemDetails) {
				const updatedProblem = prevState.list.filter(each => each.problemId === this.state.problemDetails.value);
				this.updateProblemAdditionalInfoList(updatedProblem);
			}
			this.setState(prevState);
		}

		if (this.props.isProblemOrderUpdating === false && this.state.loadingSaveAndNext) {
			prevState.loadingSaveAndNext = false;
			prevState.orderChanged = false;
			prevState.unSelect = true;
			this.setState(prevState);
		}
	}

	updateProblemAdditionalInfoList(data)
	{
		if (data.length > 0)
		{
			const prevState = this.state;
			prevState.additionalInfoList = data[0].additionalInfo.filter(e => !e.isCheckList || e.isHeading);
			prevState.problemDetails = { value: data[0].problemId, label: data[0].problem ? data[0].problem : "", additionalInfoList: data[0].additionalInfo, data: data[0] };
			this.setState(prevState);
		}
	}

	filterModelData(data, modelDetails)
	{
	    const fetchData = [];
		data.forEach((eachProblem) => {

			if (eachProblem.modelTreatmentId && !modelDetails.includes(eachProblem.modelTreatmentId)) {
				fetchData.push(eachProblem.modelTreatmentId);
			}

			if (eachProblem.additionalInfo && eachProblem.additionalInfo !== undefined) {
				const getData = this.filterModelData(eachProblem.additionalInfo, fetchData);
				fetchData.push(...modelDetails);
				fetchData.push(...getData);
			}

		});

		return [...new Set(fetchData)];
	}

	onSaveRankingOfModel = () => {
		const prevState = this.state;
		prevState.rankingOfModelLoading = true;
		let payload = [];
		if (this.state.tab === "additionalInfo") {
			payload = this.filterModelData(this.state.additionalInfoList, []);

		} else {
			payload = this.filterModelData(this.state.list, []);
		}
		this.props.saveAndNextQuickOrder(true);
		this.setState(prevState, () => {
			this.props.dispatch(clinicalPathwayProblemAction.updateByLinkedModelRank(this.props.match.params.id, { reOrderedLinkedModels: payload, clpUpdatingFrom: this.state.tab.toUpperCase(), modelProblemRank: this.state.modelProblemRank, modelAdditionalInfoRank: this.state.modelAdditionalInfoRank }));
		});
	};

	onClickSaveAndNExt = () => {
		this.setState({ saveAndNext: true });
		this.onClickSave();
	};

	onClickSave = () => {
		let payload = [];
		this.props.saveAndNextQuickOrder(this.state.saveAndNext);
		if (this.state.tab === "model") {
			const prevState = this.state;
			prevState.saveStatus = this.state.saveStatus + 1;
			prevState.loadingSaveAndNext = this.state.saveAndNext;
			prevState.loading = !this.state.saveAndNext;
			this.setState(prevState);
		} else if (this.state.tab === "additionalInfo") {
			if (this.state.additionalInfoList && this.state.additionalInfoList.length < 1) { return; }
			this.setState({ loading: (!this.state.saveAndNext), loadingSaveAndNext: this.state.saveAndNext });
			payload = this.state.additionalInfoList.map((problem, index) => {
				return { additionalInfoId: problem.id, rank: index + 1, interventionSummaryName: problem.interventionSummaryName, isHeading: problem.isHeading };
			});
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdation(this.props.match.params.id, { problemId: this.state.problemDetails.value, reOrderedProblems: payload }));
		} else {
			if (this.state.list && this.state.list.length < 1) { return; }
			this.setState({ loading: (!this.state.saveAndNext), loadingSaveAndNext: this.state.saveAndNext });
			payload = this.state.list.map((problem, index) => {
				return { problemId: problem.problemId, rank: problem.rank || index + 1, name: problem.problem };
			});
			this.props.dispatch(clinicalPathwayProblemAction.updateClpProblemsRank(this.props.match.params.id, { reOrderedProblems: payload }));
		}
		
		this.state.saveAndNext = false;
	};

	setListItems(data, orderChanged) {this.setState({ list: data, orderChanged: orderChanged });}
	isModelOrderChanged(orderChanged) {this.setState({ orderChanged: orderChanged });}

	onClickUnselect = () => {
		this.setState({ unSelect: true });
	};


	confirmation = () => {

		const { confirm } = Modal;
		this.props.saveAndNextQuickOrder(false);                     
		if (this.state.orderChanged) {
			confirm({
				title: i18Get("Do you want to leave without saving?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "",
				okText: i18Get("Ok", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					setTimeout(function () {
						this.props.disableProblemListReorder();
					}.bind(this), 100);

				},
				onCancel: () => {
					//console.log('Cancel');
				}
			});
		} else {
			setTimeout(function () {
				this.props.disableProblemListReorder();
			}.bind(this), 100);
		}
	};

	quickReorderTitle = () => {
		return (<div className=""><DragWindow title={i18Get("Quick Reorder", utils.getLanguage())} />
			<div className="reorder_modal_select">
				{this.state.tab === "additionalInfo" &&
					<ProblemListeDropDown
						data={this.state.problemDetails ? this.state.problemDetails : null}
						onChange={this.onChangeProblemList}
						stepSectionType={this.props.clpStepFilter}
						className="margin-bottom--10 font-12"
						list={this.state.list}
						quickReorderChanges={this.state.quickReorderChanges}
					/>
				}
			</div>
		</div>);
	};

	tabChanged(tab) {
		this.setState({ tab: tab, selectedItems: [] });
	}

	onChangeProblemList = e => {
		const prevState = this.state;
		prevState.problemDetails = e;
		if (e && e.additionalInfoList.length > 0) {
			prevState.additionalInfoList = e.additionalInfoList.filter(e => !e.isCheckList || e.isHeading);
		} else {
			prevState.additionalInfoList = [];
		}
		this.setState(prevState);
	};

	selectedItems(data) {this.setState({ selectedItems: data });}

	onChangeAdditionalInfoList = e => {
		this.state.additionalInfoList = e;
		this.setState({ orderChanged: true });
	};

	deSelect() { this.setState({ unSelect: false, selectedItems: [] }); }

	onClickProblem = (value) => {
		this.setState({ tab: "additionalInfo", selectedItems: [] }, () => {
			this.onChangeProblemList({ value: value.problemId, label: value.problem ? value.problem : "", additionalInfoList: value.additionalInfo, data: value });
		});
	};

	getStatus() {
		if (this.state.code) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayProblemAction.updateLinkedModelRankStatus(this.state.code, {}));
			}.bind(this), 3000);
		}
	}

	isCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while reorder the clinical pathway problem", utils.getLanguage()));
				this.setState({ loading: false });
				return true;
			default: return false;
		}
	};

	toggleApplyCibleAndTaskRanking = () => { this.setState({ visibleApplyCibleAndTaskRanking: !this.state.visibleApplyCibleAndTaskRanking, modelProblemRank: this.state.tab === "problem", modelAdditionalInfoRank: this.state.tab === "problem"  }); };
	applayTheProblemRankingInTheModel = (e) => { this.setState({ modelProblemRank: e.target.checked }); };
	applayTheInterventionsRankingInEachProblemInTheModel = (e) => { this.setState({ modelAdditionalInfoRank: e.target.checked }); };
	applyTheOrderinTheModel = () => {
		const { confirm } = Modal;
		confirm({
			title: i18Get("Do you want to order the Interventions according to the order in the Model?", utils.getLanguage()),
			content: "",
			icon: <QuestionCircleOutlined />,
			okText: i18Get("Apply", utils.getLanguage()),
			cancelText: i18Get("Cancel", utils.getLanguage()),
			onOk: () => {
				setTimeout(function () {
					this.setState({ modelAdditionalInfoRank: true }, this.onSaveRankingOfModel());

				}.bind(this), 100);

			},
			onCancel: () => {
				//console.log('Cancel');
			}
		});

	};

	setItemCount(count) {
		const prevState = this.state;
		prevState.totalItems = count;
		this.setState(prevState);
	}

	render() {

		if (this.props.standardCareChart.standardCareChartRankUpdation === "FULFILLED") {
			this.props.disableProblemListReorder();
			this.setState({ loading: false, loadingSaveAndNext: false, orderChanged: false });
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
			this.state.unSelect = true;
		}

		if (this.props.standardCareChart.standardCareChartRankUpdation === "REJECTED") {
			this.setState({ loading: false, loadingSaveAndNext: false });
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
		}

		if (this.props.clinicalPathwayProblems.updateClpProblemsRankStatus === "FULFILLED") {
			this.props.disableProblemListReorder();
			this.setState({ loading: false, loadingSaveAndNext: false, orderChanged: false });
			this.props.dispatch(clinicalPathwayProblemAction.clearUpdateClpProblemsRank());
			this.state.unSelect = true;
		}

		if (this.props.clinicalPathwayProblems.updateClpProblemsRankStatus === "REJECTED") {
			this.setState({ loading: false, loadingSaveAndNext: false });
			this.props.dispatch(clinicalPathwayProblemAction.clearUpdateClpProblemsRank());
		}

		//-------------------------------------------------------------------------------
		if (this.props.clinicalPathwayProblems.updateLinkedModelRankStatus === "FULFILLED") {
			
			this.setState({ code: this.props.clinicalPathwayProblems.updateLinkedModelRankCode.code }, () => { this.getStatus(); });
			this.props.dispatch(clinicalPathwayProblemAction.updateLinkedModelRankCompleted());
		}

		if (this.props.clinicalPathwayProblems.updateLinkedModelRankStatus === "REJECTED") {
			this.setState({ rankingOfModelLoading: false });
			this.props.dispatch(clinicalPathwayProblemAction.updateLinkedModelRankCompleted());
		}

		//------------------------APPLIED ORDER STATUS---------------------------------------------
		if (this.props.clinicalPathwayProblems.linkedModelRankStatus === "FULFILLED") {
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayProblems.clpReoderStatus && this.props.clinicalPathwayProblems.clpReoderStatus.status) {
				if (this.props.clinicalPathwayProblems.clpReoderStatus.status === "PROCESSED") {
					utils.showSuccess(i18Get("Reorder applied successfully", utils.getLanguage()));
					this.props.disableProblemListReorder();
					this.setState({ rankingOfModelLoading: false, loadingSaveAndNext: false });
					this.props.dispatch(clinicalPathwayProblemAction.updateLinkedModelRankCompleted());
					this.setState({ visibleApplyCibleAndTaskRanking: false, modelProblemRank: false, modelAdditionalInfoRank: false });
				} else if (!this.isCompleted(this.props.clinicalPathwayProblems.clpReoderStatus.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ rankingOfModelLoading: false, loadingSaveAndNext: false });
			}
			this.props.dispatch(clinicalPathwayProblemAction.clearUpdateLinkedModelRankStatus());
		}

		if (this.props.clinicalPathwayProblems.linkedModelRankStatus === "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.clearUpdateLinkedModelRankStatus());
			this.setState({ rankingOfModelLoading: false, loadingSaveAndNext: false });
		}

		return (
			<div>
				<Modal
					className="re_assign_modal wide_reorder create_additional quick_reorder_table_modal"
					title={this.quickReorderTitle()}//{i18Get("Quick Reorder", utils.getLanguage())}
					visible={this.props.enableQuickProblemListReorder}
					onCancel={this.confirmation}
					wrapClassName="drag_modal"
					footer={[
						<Button
							key="submitandnext"
							type="primary"
							onClick={this.onClickSaveAndNExt}
							loading={this.state.loadingSaveAndNext}
						>
							<span className="margin-left-10" >
								<I18 tkey="Save And Next" />
							</span>
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={this.onClickSave}
							loading={this.state.loading}
						>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						this.state.tab !== "model" ?
							!(this.props.clinicalPathwayData.isCheckList || this.props.clinicalPathwayData.isModel) &&
								this.state.list && this.state.list.filter(e => e.modelTreatmentId).length > 0 ?
								this.state.tab === "problem" ?
									<Button
										key="UseTheRankingOfModel"
										type="primary"
										onClick={this.toggleApplyCibleAndTaskRanking}
										loading={this.state.rankingOfModelLoading}>
										<span className="margin-left-10" >
											<I18 tkey="Use the Ranking of Model" />
										</span>
									</Button>
									:
									<Button
										key="UseTheRankingOfModel"
										type="primary"
										onClick={this.applyTheOrderinTheModel}
										loading={this.state.rankingOfModelLoading}>
										<span className="margin-left-10" >
											<I18 tkey="Apply the Order in the Model" />
										</span>
									</Button> : "" 
							: "",
						<Button key="back" onClick={this.confirmation}>
							<I18 tkey="Cancel" />
						</Button>,
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left margin-left-10" >
								{this.state.selectedItems.length} <I18 tkey="items selected" />
							</span>
							: "",
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left" >
								(<a className="text_underline" onClick={(e) => this.onClickUnselect(e)}>
									<I18 tkey="Deselect All" />
								</a>)
							</span> : ""
					]}
				>
					<ul className="nav nav-tabs margin-bottom-8">
						{ !this.state.hideModel ? <li className={(this.state.tab === "model") ? "active" : ""}>
							<a data-toggle="tab" href="#" onClick={this.tabChanged.bind(this, "model")}>
								<I18 tkey="MODEL" />
							</a>
						</li> : ""}
						<li className={(this.state.tab === "problem") ? "active" : ""}>
							<a data-toggle="tab" href="#" onClick={this.tabChanged.bind(this, "problem")}>
								<I18 tkey="PROBLEM" />
							</a>
						</li>
						<li className={(this.state.tab === "additionalInfo") ? "active" : ""}>
							<a data-toggle="tab" href="#" onClick={this.tabChanged.bind(this, "additionalInfo")}>
								<I18 tkey="INTERVENTIONS SUMMARY NAME" />
							</a>
						</li>
						{this.state.problemDetails && this.state.tab === "additionalInfo" ?
							<li className="float_left margin-left-30">
								<div className="font_size_14px"><I18 tkey={`${this.state.problemDetails && this.state.problemDetails.data ? this.state.problemDetails.data.problem : ""}`} /></div>
								<div className="font_size_11px color_gray"><strong><I18 tkey="Type" />: </strong><I18 tkey={`${this.state.problemDetails && this.state.problemDetails.data ? this.state.problemDetails.data.type : ""}`} /></div>
							</li>
						 : ""}
						<li className="float_right font_size_11px padding-top-25"><I18 tkey="Number of items" />: {this.state.totalItems}</li>
					</ul>
					{this.state.tab === "model" && 
					<>
						<ReorderByModel
							clinicalPathwayData = { this.props.clinicalPathwayData }
							saveStatus={this.state.saveStatus} 
							quickReorderChanges={this.state.quickReorderChanges}
							onOrderChange = {this.isModelOrderChanged.bind(this)}
							setItemCount={this.setItemCount.bind(this)}
						/>
					</>}
					{this.state.tab === "problem" &&
						<div className="">
							<ReorderProblem
								clinicalPathwayData={this.props.clinicalPathwayData}
								listProblem={this.state.list}
								selectedItems={this.selectedItems.bind(this)}
								deSelect={this.deSelect.bind(this)}
								unSelect={this.state.unSelect}
								setListItems={this.setListItems.bind(this)}
								onClickProblem={this.onClickProblem}
								quickReorderChanges={this.state.quickReorderChanges}
								setItemCount={this.setItemCount.bind(this)}
							/>
							
						</div>
					}
					{this.state.tab === "additionalInfo" &&
						<div className="">
							<ReorderAdditionalInfo
								clinicalPathwayData={this.props.clinicalPathwayData}
								problemList={this.state.additionalInfoList}
								selectedItems={this.selectedItems.bind(this)}
								deSelect={this.deSelect.bind(this)}
								unSelect={this.state.unSelect}
								onChangeAdditionalInfoList={this.onChangeAdditionalInfoList}
								setListItems={this.setListItems.bind(this)}
								problemId={this.state.problemDetails && this.state.problemDetails.value}
								quickReorderChanges={this.state.quickReorderChanges}
								setItemCount={this.setItemCount.bind(this)}
							/>
						</div>
					}
				</Modal>


				<Modal
					className="re_assign_modal message_box"
					title={i18Get("Apply Problem and Task Ranking", utils.getLanguage())}
					visible={this.state.visibleApplyCibleAndTaskRanking}
					onCancel={this.confirmation}
					wrapClassName="drag_modal"
					footer={[
						<Button
							key="submit"
							type="primary"
							onClick={this.onSaveRankingOfModel}
							loading={this.state.rankingOfModelLoading}
						>
							<span className="margin-left-10" >
								<I18 tkey="Apply Now" />
							</span>
						</Button>,
						<Button key="back" onClick={this.toggleApplyCibleAndTaskRanking}>
							<I18 tkey="Cancel" />
						</Button>

					]}
				>
					<div>
						<div className="margin-bottom-10"><b>{i18Get("Do you want to Apply the PROBLEM ranking according to Model ranking?", utils.getLanguage())}</b></div>
						<p><Checkbox onChange={this.applayTheProblemRankingInTheModel} checked={this.state.modelProblemRank}>{i18Get("Apply the PROBLEM Ranking in the Model", utils.getLanguage())}</Checkbox></p>
						<p><Checkbox onChange={this.applayTheInterventionsRankingInEachProblemInTheModel} checked={this.state.modelAdditionalInfoRank}>{i18Get("Apply the Intervention Ranking in each PROBLEM and Model", utils.getLanguage())}</Checkbox></p>
					</div>
				</Modal>
			</div>
		);
	}
}

QuickReorder.propTypes = {
	standardCareChart: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		standardCareChart: store.standardCareChart,		
		actions: store.actions

	}))(QuickReorder)
);