import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import interventionDetailsAction from "../../../../../../../actions/intervention-details";
import utils from "../../../../../../../utils/utils";
import { statusList } from "../../../../../../../constants";

class MedicalDevices extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			hospitalId: "",
			status: "ALL"

		};
	}

	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	onFetchData() {
		this.props.dispatch(interventionDetailsAction.getMedicalDevices(this.props.type, this.props.match.params.id, this.state.sort, this.state.desc, this.state.status, this.state.hospitalId));
	}

	getTable(list) {
		return (
			<table className="w_100">
				<thead>
					<tr>
						<th />

						<th>
							<I18 tkey="CODE" />
						</th>

						<th>
							<I18 tkey="NAME" />
						</th>

						<th>
							<I18 tkey="QUANTITY" />
						</th>
						<th>
							<I18 tkey="HOSPITAL" />
						</th>
						<th>
							<I18 tkey="STATUS" />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	getTableContent(list) {
		if (list.length > 0) {
			return list.map((data) => (
				<tr key={data.elementId}>
					<td className="text_align_center">
						{
							data.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ? 
								(
									<a className="waiting-for-approve " onClick={() => this.props.onAssignAndApproveClick(data.elementId, this.props.type, data.elementName, data.code, data.type)}>
										<img src="/images/waitingforapprovel.png" />
										{" "}
									</a>
								)
								: ""
						}
					</td>
					<td>{data.code ? data.code : " -- "}</td>
					<td>{data.elementName}</td>
					<td>{ utils.setLocaleForCurrency(data.quantity ? data.quantity : 0.00)}</td>
					<td>{data.hospitalName ? data.hospitalName : "Master"}</td>
					<td>{data.status ? data.status === "APPROVED" ? "Approved" : "Waiting For Approval" : ""}</td>
				</tr>

			));
		}

		return (
			<tr>
				<td colSpan={this.getColSpan()}><div className="text_align_center w_100"><I18 tkey={`NO ${this.props.type.replace(/_/g, " ").toUpperCase()} FOUND`} /></div></td>
				{" "}
			</tr>
		);
	}

	getColSpan() {
		let val = 6;
		if (!this.props.showTotal) {
			val -= 2;
		}
		return val;
	}

	render() {
		if (this.props.interventionDetails.fetchAllMedicalDevices === "FULFILLED") {
			this.state.list = this.props.interventionDetails.medicalDevices.data;
			this.state.subTotal = this.props.interventionDetails.medicalDevices.subTotal;
			this.state.loading = false;
		}

		if (this.props.status !== this.state.status) {
			this.state.status = this.props.status;
			this.onFetchData();
		}
		if (this.props.hospitalId !== this.state.hospitalId) {
			this.state.hospitalId = this.props.hospitalId;
			this.onFetchData();
		}

		return (
			<div className="risk_preparation_container">
				<div className="table_heading"><I18 tkey="MEDICAL DEVICE" /></div>
				<div className="table_div risk_table">{this.getTable(this.state.list)}</div>
			</div>
		);
	}
}

MedicalDevices.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	interventionDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	interventionDetails: store.interventionDetails,
	translations: store.translations
}))(MedicalDevices));
