import React from "react";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { Radio } from "antd";
export default function getCols(onClick, isCheckedRows) {
	const tableArray = ([
		{
			Header: "",
			id: "actions",
			accessor: (d) => d,
			resizable: false,
			width: 50,
			sortable: false,
			Cell: (row) => {
				return (
					<div>
						{/* <Checkbox checked={isCheckedRows(row.value) ? true : false} onChange={(e) => { onClick(e, row.index) }} /> */}
						<Radio value={row.value.id} checked={isCheckedRows(row.value) ? true : false} onChange={(e) => { onClick(e); }} ></Radio>
					</div>

				);
			}
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (<span >{(row.value.hospitalName ? row.value.hospitalName + " " : "") + row.value.name}</span>)
		}
	]);
	return tableArray;
}
