import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { useParams, withRouter } from "react-router";
import I18, { i18Get }  from "../../../../../../i18";
import anesthesiaAction from "../../../../../../actions/anaesthesia";
import utils from "../../../../../../utils/utils";
import eventNames from "../../../../../../actions/events";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import "../../../../../../plug/reactTable/react-table.css";
import { Modal } from "antd";
import Select from "react-select";
import ActionButton from "../../../../../../plug/action-button";
import isEqual from "lodash.isequal";
import differenceBy from "lodash.differenceby";
import unionWith from "lodash.unionwith";
import Loader from "../../../../../../plug/loader";
import BreadCrumb from "../../breadcrumb";
import typeActivity from "../../../../../../constants/typeActivity.json";
import { statusList } from "../../../../../../constants";
import AssignAndApprovePopup from "../../intervention/details/v4/anaesthesia/assign-approve-popup";
import { DeleteOutlined } from "@ant-design/icons";

class Anaesthesia  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			maxlen: [],
			type: "",
			data: [],
			deleteVisible: false,
			elementToDelete: false,
			modelVisible: false,
			name: "",
			sorted: [],
			listOffset: 0,
			listNumResults: 100
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	} 

	componentDidMount () {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13 && this.state.list.length < 2) {
			this.addClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.state.listOffset = 0;
		this.state.listNumResults = 100;
		this.fetchDropdownData();
		this.onFetchData();
	}

	componentWillUnmount () {
		this.state.list = [];
		this.state.data = [];
		this.state.selectedData = "";
		this.state.type = "";
		clearTimeout(this.timer);
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	getCols() {
		const tableArray = ([
			{
				Header: "",
				id: "action",
				accessor: d => d,
				sortable: false,
				resizable: true,
				maxWidth: 60,
				Cell: row => (
					<div className="margin_left_auto"> 
						{
							row.value.status === statusList.STATUS.values.APPROVED ?
								<span className="risk_action_button text_align_center"><DeleteOutlined  className="delete_color" onClick={this.deleteElementClicked.bind(this, row.value)} /></span>
								: ""
						}

						{
							row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
								<a className="waiting-for-approve " onClick={this.onAssignAndApproveClick(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
								: ""
						}
					</div>
                
				)
			},
			{
				Header: i18Get(this.props.type.toUpperCase(), utils.getLanguage()),
				id: "subTreatmentName",
				accessor: d => d.subTreatmentName,
				sortable: false,
				resizable: true,
				Cell: row => (
					row.value
				)
			},
			{
				Header: i18Get("HOSPITAL", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				sortable: false,
				Cell: row => (
					row.value ? row.value : "Master"
				)
			},
			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				sortable: false,
				Cell: row => (
					row.value && row.value === "APPROVED" ? "Approved" : "Waiting For Approval"
				)
			}]);
		return tableArray;
	}

	deleteElementClicked(anaesthesia) {
		const prevState = this.state;
		prevState.elementToDelete = anaesthesia;
		prevState.deleteVisible = true;
		this.setState(prevState);
	}

	onAssignAndApproveClick (data) {
		return () => {
			this.setState({ modelVisible: true, subTreatmentId: data.subTreatmentId, elementType: data.type });
		};
	}

	refreshCompleted = () => {
		const prevState = this.state;
		prevState.modelVisible = false;
		this.fetchDropdownData();
		this.onFetchData();
	};

	deleteElement() {
		this.props.dispatch(anesthesiaAction.deleteElement(this.props.match.params.id, this.state.elementToDelete.subTreatmentId));      
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, elementToDelete: false });
	}

	addClicked() {
		if (this.state.selectedData && this.state.selectedData.value) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				subTreatmentId: this.state.selectedData.value
			};
			this.props.dispatch(anesthesiaAction.add(this.props.type, (this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) ? this.props.match.params.id : this.props.match.params.treatmentId, payload));      
		}
		else {
			this.setState({ invalidDataselected: true });
		}
	}


	renderOptions() {      
		const prevState = this.state;  
		prevState.options = []; 
		if (this.state.data && this.state.list) {    
			this.state.data = differenceBy(this.state.data, this.state.list, "id");
			this.state.data.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onOptionsChange(e) {
		this.setState({ selectedData: e, invalidDataselected: false });
	}

	fetchDropdownData() {
		this.setState({ isLoading: true });
		let queryString = "";
		queryString += `offset=${this.state.listOffset ? this.state.listOffset : ""}&`;
		queryString +=  `numResults=${this.state.listNumResults}&`;
		queryString +=  `organization=${this.props.match.params.organization}&`;
		queryString += "sort=name&";
		queryString += "desc=0&";
		queryString += "status=APPROVED&";
		queryString += `treatmentId=${ this.props.match.params.id}`;
		this.props.dispatch(anesthesiaAction.getAllDropDownSelectAnaesthesia(queryString)); 
	}

	onFetchData() {
		this.props.dispatch(anesthesiaAction.get(this.props.type, (this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) ? this.props.match.params.id : this.props.match.params.treatmentId, this.state.sort, this.state.desc, this.state.data.subTreatmentId));
	}

	generateItems() {
		const items = [];
		if (this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) {
			items.push({ name: i18Get("Intervention", utils.getLanguage()), link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}` });
			items.push({ name: this.props.intervention ? this.props.intervention : i18Get("Intervention Details", utils.getLanguage()), link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}` });
			items.push({ name: i18Get("Anaesthesia", utils.getLanguage()), link: this.props.location.pathname });
		} else if (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase()) {
			items.push({ name: this.props.details ? this.props.details.data.name : i18Get(`${utils.toPascalCase(this.props.typeActivity)} Details`, utils.getLanguage()), link: `/imagery/${this.props.match.params.organization}/${this.props.match.params.treatmentId}` });
			items.push({ name: i18Get(utils.toPascalCase(this.props.type), utils.getLanguage()), link: `${this.props.location.pathname}` });
		} else {
			items.push({ name: this.props.details ? this.props.details.data.name : i18Get(`${utils.toPascalCase(this.props.typeActivity)} Details`, utils.getLanguage()), link: `/radiotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}` });
			items.push({ name: i18Get(utils.toPascalCase(this.props.type), utils.getLanguage()), link: this.props.location.pathname });
		}
		return items;
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumResults;
		this.fetchDropdownData();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchDropdownData(value);
			}, 700);
		}
	}

	render() {
		if (this.props.anaesthesia.isFetchAllDropDownDataCompleted === "FULFILLED") {
			this.state.data = unionWith(this.state.data, this.props.anaesthesia.dropDownData, isEqual);
			this.renderOptions();
			this.props.dispatch(anesthesiaAction.clearGetAllDropDownSelectAnaesthesia());
		}
		if (this.props.anaesthesia.iselementAddCompleted === "FULFILLED") {
			this.state.selectedData = "";
			this.state.data = [];
			this.state.options = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.onFetchData();
			this.fetchDropdownData(this.state.search);
			this.props.dispatch(anesthesiaAction.clearAdd());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.anaesthesia.isElementGetCompleted === "FULFILLED") {
			this.state.list = this.props.anaesthesia.elementsData.subTreatments;
			this.state.maxlen = this.state.list.filter(data => data.status === "APPROVED");
			this.state.loading = false;
			this.renderOptions();
			this.props.dispatch(anesthesiaAction.clearGet());
		}
		if (this.props.anaesthesia.isElementDeleteCompleted === "FULFILLED") {
			this.state.deleteVisible = false;
			this.state.data = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.fetchDropdownData();
			this.onFetchData();
			this.props.dispatch(anesthesiaAction.clearDelete());
		}

		return (            
			<div>
				<BreadCrumb useSearch items={this.generateItems()}/>    
				<div  className="risk_preparation_header float_left di w_100 padding-0 margin-top-15 margin-bottom-15">
					{this.state.maxlen.length < 2 && <div className="row"><div className="col-md-10 col-sm-10">
						<div className="w_100 di position_relative">
							<label className="common_label"><I18 tkey={"Select " + this.props.type.toLowerCase()}/></label>
							<Select 
								isSearchable
								className="w100"
								onChange={this.onOptionsChange.bind(this)}
								value={this.state.selectedData}
								options={this.state.options}
								isClearable={true}
								onMenuScrollToBottom={this.onListScroll.bind(this)}
								onInputChange={this.onSearch.bind(this)}
								isLoading={this.state.isListLoading}
								noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
								loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
								placeholder={i18Get("Select", utils.getLanguage())}
							/>
							{this.state.invalidDataselected && <span className='invalid_right fixed_label'><I18 tkey="Select valid anaesthesia" /></span>}
						</div>              
					</div>
					<div className="col-md-2 col-sm-2">
						<div className="text_align_right margin_top_18_action_button"><ActionButton showAdd={true} className="common_button margin_top_15px_mob" onClick={this.addClicked.bind(this)} value={i18Get("ADD", utils.getLanguage())} /></div>
					</div>  
					</div>}
				</div>
				<ReactTable
					data={this.state.list}
					loading={this.state.loading}
					LoadingComponent={() => {return (<Loader loading={this.state.loading} isTable={true}/>);}}
					multiSort={false}
					pageSize={this.state.list.length ? this.state.list.length : 3}
					showPagination={false}
					noDataText={this.state.loading ? "" : i18Get("NO ANAESTHESIA FOUND", utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange = { (newSorted) => {
						this.setState({ sorted: newSorted });
					}}                                       
					sorted={this.state.sorted}
				/>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteElement.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.elementToDelete.name}</span> ?
					</div>
				</Modal>

				{  this.state.modelVisible ?
					<div>
						<AssignAndApprovePopup
							refreshCompleted={this.refreshCompleted}
							elementId={this.state.subTreatmentId}
							exType={this.state.type}
							type = {this.state.elementType}
							showAssignAndApprove={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

Anaesthesia.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	actions: store.actions,
	anaesthesia: store.anaesthesia,
	translations: store.translations
}))(Anaesthesia));