import eventNames from "./event";
const initialState = {
	fetchRegroupDetails: false,
	reGroupDetails: [],

};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_REGROUP_DETAILS}_FULFILLED`:
			return {
				...state,
				fetchRegroupDetails: "FULFILLED",
				reGroupDetails: action.payload.data.data,
				meta: action.meta
			};
		case `${eventNames.GET_REGROUP_DETAILS}_REJECTED`:
			return {
				...state,
				fetchRegroupDetails: "REJECTED"
			};

		case eventNames.GET_CLEAR_REGROUP_DETAILS:
			return {
				...state,
				fetchRegroupDetails: false
			};
		default:
			return state;
	}
}
