/* eslint-disable no-nested-ternary */
import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import locations from "../../../../../../router/locations";
import ClinicalPathwayDetailV5 from "./v5";
import clinicalPathwayAction from "../../../../../../actions/clinical-pathway";
import clinicalPathwayDetailsAction from "../../../../../../actions/clinicalPathway-details";
import events from "../../../../../../actions/events";
import TopSection from "./top-section";
import { Modal, Button } from "antd";
import TopDetailsSection from "./top-details-section";
import Loader from "../../../../../../plug/loader";
import "./details.css";
import BreadCrumb from "../../breadcrumb";
import selectColumn from "../../../../../../constants/clinicalPathwayColumnSelect.json";
import selectClpPblmColumn from "../../../../../../constants/clpProblemColumnSelect.json";
import selectedPathwayColumns from "../../../../../../constants//clpClinicalPathwayColumnSelect.json";
import AddOrRemoveStep from "./add-or-remove-step";
import CopyFromClinicalPathWay from "./copyfromclinicalpathway";
import Export from "./export";
import ExportSelectedComponent from "./export-selected-component";
import ProblemSection from "./problem-section";
import Search from "./top-section/search";
import ProblemSearch from "./problem-section/search";
import DayInformation from "./day-information";
import ExportPathwayProblem from "./export-pathway-problem";
import StandardCareChart from "./standard-care-chart";
import { ArrowDownOutlined, ArrowUpOutlined, FullscreenExitOutlined, ExpandOutlined } from "@ant-design/icons";
import ClpStepFilter from "./clp-step-filter";
import ActionButton from "../../../../../../plug/action-button";
import DragWindow from "./drag-window";
import ExportStandardCareChart from "./export-standard-care-chart";
import CopyClinicalPathwayHeader from "../copyClinicalPathwayHeader";
import isPermissionGranted from "../../../../../../utils/permission";
import QuickOrder from "./problem-section/quick-reorder";
import ProblemTopSection from "../details/problem-top-section";
import StandardCareChartTopSection from "../details/standard-care-chart-top-section";

let prefs = {
	CLP_DEFAULT_TAB: null,
	CLP_SHOW_STEPS: null,
	CLP_HIDE_HEADERS: null
};
export const ClpStepFilters = {
	ALL_ITEMS: "ALL_ITEMS",
	BEFORE_ADMISSION: "BEFORE_ADMISSION",
	CLINICAL_PATHWAY: "CLINICAL_PATHWAY",
	AFTER_CHECKOUT: "AFTER_CHECKOUT"
};
class ClinicalPathWayDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			clinicalPathway: {},
			key: 1,
			isLoadingFirstTime: true,
			isLoaded: true,
			searchType: "EQUALS",
			search: "",
			showIntervention: true,
			hideSteps: false,
			addOrRemoveStep: false,
			copyFromClinicalPathWay: false,
			dayInformation: false,
			selectedHospital: "",
			selectedColumns: {
				check: {},
				checkAll: true
			},
			selectedPblmColumns: {
				check: {},
				checkAll: true
			},
			selectedPathwayColumns: {
				check: {},
				checkAll: true
			},
			steps: [],
			loadingSave: false,
			stepLoader: false,
			authorCheckList: [],
			interventionCheckList: [],
			anaesthesiaCheckList: [],
			loadingCopySelect: false,
			category: "",
			hideHeaders: false,
			clpStepFilter: ClpStepFilters.ALL_ITEMS,
			extClpFullscreen: false,
			copyFromPathWayProblems: false,
			isClpSynthesis: false,
			comparisonOfInterventions: false,
			taskCount: 0,
			totalTaskCount: 0,
			enableProblemListReorder: false,
			saveAndNextQuickOrder: false,
			quickReorderProblemListChanges: 0,
			isProblemOrderUpdating: false,
			isFullScreen: false,
			isHospitalisation: false,
			pathwayTab: "",
			intervenants: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.onFetchData();
		this.props.dispatch({ type: events.SELECTED_COLUMN, payload: {} });
		if (localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST")) {
			const selectedColumns = JSON.parse(localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST"));
			if (selectedColumns.check && (selectedColumns.check.RESPONSIBLE || !selectedColumns.deafultColumn)) {
				delete selectedColumns.check["RESPONSIBLE"];
				selectedColumns.check.REFERENCE = false;
				selectedColumns.check.ASSESSMENT = false;
				selectedColumns.check.INTERVENANT = true;
				selectedColumns.checkAll = false;
				selectedColumns.intermediate = true;
				selectedColumns["defaultColumn"] = true;
			}
			if (selectedColumns) {
				this.setState({ selectedColumns: selectedColumns }, () => {
					localStorage.setItem("CLINICAL_PATHWAY_COLUMN_LIST", JSON.stringify(selectedColumns));
				});
			}
		} else {
			selectColumn["COLUMN"].labels.map(function (item) {
				this.state.selectedColumns.check[item] = true;
			}.bind(this));
			this.state.selectedColumns["defaultColumn"] = true;
			this.setState({ selectedColumns: this.state.selectedColumns }, () => {
				localStorage.setItem("CLINICAL_PATHWAY_COLUMN_LIST", JSON.stringify(this.state.selectedColumns));
			});
			this.props.dispatch({ type: events.SELECTED_COLUMN, payload: { value: this.state.selectedColumns } });
		}

		if (localStorage.getItem("CLP_PBLM_COLUMN_LIST")) {
			const selectedPblmColumns = JSON.parse(localStorage.getItem("CLP_PBLM_COLUMN_LIST"));
			if (selectedPblmColumns.check && selectedPblmColumns.check["DOCTOR/NURSE_PRESCRIPTION"]) {
				delete selectedPblmColumns.check["DOCTOR/NURSE_PRESCRIPTION"];
				selectedPblmColumns.check.INTERVENTION_DETAILS = true;
			}
			if (selectedPblmColumns) {
				this.setState({ selectedPblmColumns: selectedPblmColumns });
			}
		} else {
			selectClpPblmColumn["COLUMN"].labels.map(function (item) {
				this.state.selectedPblmColumns.check[item] = true;
			}.bind(this));
			this.setState({ selectedPblmColumns: this.state.selectedPblmColumns }, () => {
				localStorage.setItem("CLP_PBLM_COLUMN_LIST", JSON.stringify(this.state.selectedPblmColumns));
			});
		}

		if (localStorage.getItem("CLP_PTWAY_COLUMN_LIST")) {
			const selectedPathwayColumns = JSON.parse(localStorage.getItem("CLP_PTWAY_COLUMN_LIST"));
			if (selectedPathwayColumns.check && selectedPathwayColumns.check.INTERVENANT) {
				delete selectedPathwayColumns.check["INTERVENANT"];
				selectedPathwayColumns.check.INTERVENANTS = true;
				selectedPathwayColumns.check.CODE = true;
				selectedPathwayColumns.checkAll = true;
				selectedPathwayColumns.intermediate = false;
			}
			if (selectedPathwayColumns) {
				this.setState({ selectedPathwayColumns: selectedPathwayColumns });
			}
		}
		else {
			selectedPathwayColumns["COLUMN"].labels.map(function (item) {
				this.state.selectedPathwayColumns.check[item] = true;
			}.bind(this));
			this.setState({ selectedPathwayColumns: this.state.selectedPathwayColumns }, () => {
				localStorage.setItem("CLP_PTWAY_COLUMN_LIST", JSON.stringify(this.state.selectedPathwayColumns));
			});
		}

		const clpPrefs = JSON.parse(localStorage.getItem("CLP_PREFS"));
		//const clpPathwyaPrefs = JSON.parse(localStorage.getItem("CLP_INTERVENANTS_PATHWAY_PREFS"));

		this.setState({
			hideSteps: clpPrefs && clpPrefs.CLP_SHOW_STEPS,
			hideHeaders: clpPrefs && clpPrefs.CLP_HIDE_HEADERS,
			category: clpPrefs ? clpPrefs.CLP_DEFAULT_TAB : "clpProblem",
			clpStepFilter: clpPrefs ? clpPrefs.CLP_DEFAULT_TAB === "clpProblem" ? ClpStepFilters.ALL_ITEMS : ClpStepFilters.ALL_ITEMS : ClpStepFilters.ALL_ITEMS,
			pathwayTab: "healthPathway"
			//intervenants: clpPathwyaPrefs ?  clpPathwyaPrefs.CLP_DEFAULT_PATHWAY_TAB : "healthPathway"
		});
	}

	componentDidUpdate () {
		if (this.state.clinicalPathway.isCheckList && this.state.category === "clpTasks") {
			this.setState({ category: "clpProblem" });
			this.setState({ pathwayTab: "healthPathway" });
		}
	}

	setPrefs = () => {
		prefs = {
			CLP_DEFAULT_TAB: this.state.category,
			CLP_HIDE_HEADERS: this.state.hideHeaders,
			CLP_SHOW_STEPS: this.state.hideSteps,
			CLP_DEFAULT_HOSPITAL: this.state.selectedHospital
		};
		if (this.state.type !== false) {
			localStorage.setItem("CLP_PROBLEM_TYPE", JSON.stringify(this.state.type ? this.state.type : ""));
		}
		localStorage.setItem("CLP_PREFS", JSON.stringify(prefs));
	};


	setInterventionPrefs = () => {
		prefs = {
			CLP_DEFAULT_PATHWAY_TAB: this.state.intervenants
		};
		if (this.state.intervenants !== false) {
			localStorage.setItem("CLP_PROBLEM_INTERVENANTS", JSON.stringify(this.state.intervenants ? this.state.intervenants : ""));
		}
		//localStorage.setItem("CLP_INTERVENANTS_PATHWAY_PREFS", JSON.stringify(prefs));
	};


	onFetchData() {
		if (this.state.selectedHospital && this.state.selectedHospital.value) {
		    this.fetchDataWithHospitalId();
		}
		else {
			const payload = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization,
				organizationMode: this.state.organizationMode
			};
			this.props.dispatch(clinicalPathwayAction.getOne(payload.id, new Date().getTimezoneOffset()));
		 }
	}
	fetchDataWithHospitalId() {
		let queryString = "";
		queryString += `?timeZone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(clinicalPathwayAction.getOneClpUsingHospitalId(this.props.match.params.id, this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : "", queryString));
	}

	generateItems() {
		const items = [];
		const basePage = utils.getFilterInLocalStorage("CLP_FILTER")["category"];
		items.push({
			name: i18Get(`${basePage === "checkList" ? "Check List" : basePage === "model" ? "Model" : "Clinical Pathway" }`, utils.getLanguage()),
			link: `${locations.clinical_pathway_plain}/${this.props.match.params.organization}`
		});
		if (this.state.clinicalPathway && this.state.clinicalPathway.name) {
			const preFix = this.state.clinicalPathway.siteId && this.state.clinicalPathway.hospitalId ? this.state.clinicalPathway.siteShortName : this.state.clinicalPathway.hospitalName ? this.state.clinicalPathway.hospitalName : "MOM"; 
			items.push({ name: `${preFix} ${this.state.clinicalPathway.name}`, link: this.props.location.pathname });
		}
		return items;
	}

	reloadDetails() {
		const prevState = this.state;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState, () => { this.onFetchData(); });

	}

	hospitalChanged(e) {
		const prevState = this.state;
		prevState.selectedHospital = e;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.setPrefs();
		if (prevState.selectedHospital && prevState.selectedHospital.value) {
		    this.fetchDataWithHospitalId(e);
		} else {
		    this.onFetchData();
		}
	}
	onChangeColSelect = (value) => {
		if (value.isProblem) {
			this.setState({ selectedPblmColumns: value }, () => {
				localStorage.setItem("CLP_PBLM_COLUMN_LIST", JSON.stringify(value));
			});
		}
		else if (value.isChart) {
			this.setState({ selectedPathwayColumns: value }, () => {
				localStorage.setItem("CLP_PTWAY_COLUMN_LIST", JSON.stringify(value));
			});
		}
		else {
			this.setState({ selectedColumns: value }, () => {
				localStorage.setItem("CLINICAL_PATHWAY_COLUMN_LIST", JSON.stringify(value));
			});
			this.props.dispatch({ type: events.SELECTED_COLUMN, payload: { value } });
		}
	};

	searchChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState);
	}

	searchFieldChanged(e) {
		const prevState = this.state;
		prevState.searchField = e;
		this.setState(prevState);
	}

	searchTypeChanged(e) {
		const prevState = this.state;
		prevState.searchType = e.target.value;
		this.setState(prevState);
	}

	typeChanged = e => {
		console.log(">>>>>>>>>>>type  changed", e);
		this.state.type = e;
		this.setState(this.state);
		console.log(">>>>>>>>>>>> this.state.type", this.state.type);
		if (e !== false) {
			this.setPrefs();
		}
	};

	intervenantsChanged = e => {
		// this.state.intervenants = e;
		// this.setState(this.state);
		this.setState({ intervenants: e });
		if (e !== false) {
			this.setInterventionPrefs();
		}
	};

	onClickAddOrRemove = () => {
		this.setState({ addOrRemoveStep: true, steps: [], loadingSave: false, stepLoader: true });
		this.props.dispatch(clinicalPathwayDetailsAction.fetchAllMinimalSubStep(this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : this.props.match.params.id));
		this.props.dispatch(clinicalPathwayDetailsAction.clearFetchAllMinimalSubStep());
	};

	handleCancel() {
		const prevState = this.state;
		prevState.addOrRemoveStep = false;
		prevState.copyFromClinicalPathWay = false;
		prevState.dayInformation = false;
		prevState.exportClinicalPathWay = false;
		prevState.exportSelectedComponent = false;
		prevState.checkList = [];
		prevState.exportPathwayPblm = false;
		prevState.isClpSynthesis = false,
		prevState.exportStandardCareChart = false;
		this.setState(prevState);
	}

	dayInformation = () => {
		this.setState({ dayInformation: true });
	};

	copyFromClinicalPathWay = () => {
		this.setState({ copyFromClinicalPathWay: true });
	};
	copyFromPathWayProblems = () => {
		this.setState({ copyFromPathWayProblems: true });
	};

	closeCopyFromPathWayProblems = () => {
		this.state.copyFromPathWayProblems = false;
		this.setState({ copyFromPathWayProblems: false });
	};

	onComparisonOfClps = () => {
		this.setState({ clpComparison: !this.state.clpComparison });
	};

	exportExcel = () => {
		this.setState({ exportClinicalPathWay: true });
	};

	exportPathwayPblm = () => {
		this.setState({ exportPathwayPblm: true });
	};
	exportStandardCareChart = () => {
		this.setState({ exportStandardCareChart: true });
	};
	closeCopy() {
		this.onFetchData();
		this.state.increasekeyOnFetchSuccess = true;
		this.handleCancel();
	}

	closeExport() {
		this.handleCancel();
	}

	exportSelectedComponent = () => {
		this.setState({ exportSelectedComponent: true });
	};

	closeSelectedComponentExport() {
		this.onFetchData();
		this.state.increasekeyOnFetchSuccess = true;
		this.handleCancel();
	}

	onChangeShowHideSteps = (e) => {
		this.setState({ hideSteps: e.target.checked }, () => this.setPrefs());
		this.state.increasekeyOnFetchSuccess = true;
	};

	getSubStepDetails() {
		if (this.state.steps && this.state.steps.length) {
			return (<AddOrRemoveStep getSelectedComponents={this.getSelectedComponents.bind(this)} steps={this.state.steps} checkList={this.state.checkList} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	onSaveClicked() {
		const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
		checkedDataList.map(function (e) {
			if (e.stepName) {
				delete e["stepName"];
			}
			e.subSteps.map(function (data) {
				if (data.name) {
					delete data["name"];
				}
			});
		});
		const payload = {
			checkList: checkedDataList
		};
		this.props.dispatch(clinicalPathwayDetailsAction.saveSubStep(this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : this.props.match.params.id, payload));
		this.setState({ loadingSave: true });
	}

	getSelectedComponents(checkList, type) {
		if (type === "Authors_And_Actions") {
			this.state.authorCheckList = checkList;
		}
		if (type === "Intervention") {
			this.state.interventionCheckList = checkList;
		}
		if (type === "Anaesthesia") {
			this.state.anaesthesiaCheckList = checkList;
		}
	}

	tabChanged(category) {
		if (this.state.category !== category) {
			const prevState = this.state;
			prevState.category = category;
			prevState.searchField = utils.sameDropper();
			this.setState(
				prevState,
				() => this.setPrefs()
			);
			this.onFetchData();
		}
	}

	PathwaytabChanged(value) {
		if (value === "healthPathway") {
			this.setState({
				isHospitalisation: false,
				pathwayTab: value,
				clpStepFilter: "ALL_ITEMS"
			   });
		} else {
			this.setState({
				isHospitalisation: true,
				pathwayTab: value,
				clpStepFilter: "CLINICAL_PATHWAY"
			   });
			 this.fetchTotalItemCount();
		}
	}

	fetchTotalItemCount() {
		let queryString = "";
		queryString += this.state.search ? `value=${encodeURIComponent(this.state.search.trim())}` : "";
		queryString += this.state.searchField && this.state.searchField !== "SEARCH_ALL_FIELDS" ? `&field=${this.state.searchField}` : "";
		queryString += this.state.searchType ? `&mode=${this.state.searchType}` : "";
		queryString += this.state.clpStepFilter ? `&clpStepFilter=${this.state.clpStepFilter}` : "";
		this.props.dispatch(clinicalPathwayDetailsAction.fetchTotalItemCount(this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : this.props.match.params.id, queryString));
	}

	toggleHideHeaders = () => this.setState(
		{ hideHeaders: !this.state.hideHeaders },
		() => this.setPrefs()
	);

	changeClpStepFilter = filter => this.setState({ clpStepFilter: filter }, () => { this.fetchTotalItemCount(); });

	// toggleFullScreen = () => {
	// 	this.setState({ extClpFullscreen: !this.state.extClpFullscreen });
	// };

	onClickSynthesis() {
		this.setState({ isClpSynthesis: true });
	}

	addRemoveStepTitle = () => {
		return (<div className="position_relative"><DragWindow title={i18Get("Add/Remove Step", utils.getLanguage())} /> </div>);
	};

	copyFromClinicalPathwayTitle = () => { return (<div className="position_relative w_100"><DragWindow title={<CopyClinicalPathwayHeader title={"Copy From Clinical Pathway"} clinicalPathwayData={this.state.clinicalPathway} />} /> </div>); };

	allocationofDaysinClinicalPathwayTitle = () => { return (<div className="position_relative"><DragWindow title={i18Get("Allocation of Days in Clinical Pathway", utils.getLanguage())} /> </div>); };

	setTaskCount = (totalCount, count) => { this.setState({ taskCount: count, totalTaskCount: totalCount });};

	onQuickReorderData = () => {
		this.setState({ enableQuickProblemListReorder: true });
	};

	disableProblemListReorder = () => {
		this.setState({ enableProblemListReorder: false });
		this.reloadData();

		if (!this.state.saveAndNextQuickOrder) {
			this.setState({ enableQuickProblemListReorder: false });
		}

	};

	onSaveAndNextQuickOrder(flag) {
		this.setState({ saveAndNextQuickOrder: flag, isProblemOrderUpdating: true });
	}

	onEnableReorder = () => {
		this.setState({ enableProblemListReorder: true });
	};
	
	reloadData() {
		if (this.child) {
			this.child.reloadData();
		}
		this.handleCancel();
	}

	onProblemListReorderCompleted = () => {
		this.setState({ enableProblemListReorder: false, isProblemOrderUpdating: false });
		this.disableProblemListReorder();
	};


	toggleFullScreen = () => {
		this.setState({ ...this.state, isFullScreen: !this.state.isFullScreen });
	};

	render() {
		if (this.props.clinicalPathway.fetchOneClpUsingHospitalId === "FULFILLED") {
	
			this.state.clinicalPathway = this.props.clinicalPathway.clinicalPathway;
			this.props.dispatch(clinicalPathwayAction.clearGetOneClpUsingHospitalId());
		}
		
		if (this.props.clinicalPathway.fetchOneClinicalPathway === "REJECTED") { 
			this.props.dispatch(clinicalPathwayAction.clearGetOneClpUsingHospitalId());
		}

		if (this.props.clinicalPathway.fetchOneClinicalPathway === "FULFILLED") {
			this.state.isLoadingFirstTime = false;
			this.state.clinicalPathway = this.props.clinicalPathway.clinicalPathway;
			if (this.child && this.state.category === "standardCareChart") {
				this.child.toggleIsLoaded();
			}
			if (this.state.increasekeyOnFetchSuccess) {
				this.state.key = this.state.key + 1;
				this.state.increasekeyOnFetchSuccess = false;
			}
			if (this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway !== "SURGERY") {
				this.state.showIntervention = false;
			}
			this.state.isLoaded = false;
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
		}

		if (this.props.clinicalPathway.fetchOneClinicalPathway === "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
			this.props.history.push(`${locations.clinical_pathway_plain}/${this.props.match.params.organization}`);
		}

		if (this.props.clinicalPathwayDetails.fetchAllMinimalSubStep === "FULFILLED") {
			this.state.steps = [];
			this.state.steps = this.props.clinicalPathwayDetails.minimalSubStepList ? this.props.clinicalPathwayDetails.minimalSubStepList : [];
			this.state.checkList = this.props.clinicalPathwayDetails.minimalSubStepList ? this.props.clinicalPathwayDetails.minimalSubStepList : [];
			this.props.dispatch(clinicalPathwayDetailsAction.clearFetchAllMinimalSubStep());
			this.setState({ stepLoader: false });
		}

		if (this.props.clinicalPathwayDetails.saveMinimalSubStep === "FULFILLED") {
			this.state.addOrRemoveStep = false;
			this.setState({ checkList: [], loadingSave: false, steps: [] });
			this.props.dispatch(clinicalPathwayDetailsAction.clearSaveSubStep());
			this.reloadDetails();
		}

		if (this.props.clinicalPathwayDetails.mappingData) {
			this.fetchTotalItemCount();
		}

		if (this.props.clinicalPathwayDetails.fetchTotalNumberOfItems === "FULFILLED") {
			this.state.totalItemCount = this.props.clinicalPathwayDetails.totalItemCount;
			this.props.dispatch(clinicalPathwayDetailsAction.clearFetchTotalItemCount());
		}

		if (!this.props.clinicalPathway.fetchOneClinicalPathway && this.state.isLoadingFirstTime) {
			return (<Loader loading />);
		}
		
		return (
			<div className="main_overview_section intervention_details clinical_pathway_container clinical_pathway_main clinical_pathway_container_h_100">
				<div className="clinical_pathway_breadcrumb_container">
					<div className="clinical_pathway_breadcrumb">
						<BreadCrumb items={this.generateItems()} />
					</div>
					<div className="d-flex align-items-center ">
						<div className="">
							<label className="common_label margin-right-10 margin-bottom-0"><I18 tkey='Modified on' /></label>
							<div className="clinical_pathway_modified">
								{this.state.clinicalPathway.updatedAt}
							</div>
						</div>
					</div>
				</div>
				<div className="position_relative w_100 di clinical_pathway_table_container">
					<div className="eye_button_section w_100 padding-top-5">
						<div className="row">
							<TopDetailsSection
								clinicalPathway={this.state.clinicalPathway}
								selectedColumns={this.state.selectedColumns}
								selectedPblmColumns={this.state.selectedPblmColumns}
								hideSteps={this.state.hideSteps}
								onClickAddOrRemove={this.onClickAddOrRemove}
								onChangeColSelect={this.onChangeColSelect}
								copyFromClinicalPathWay={this.copyFromClinicalPathWay}
								exportExcel={this.exportExcel}
								exportSelectedComponent={this.exportSelectedComponent}
								loadingCopySelect={this.state.loadingCopySelect}
								category={this.state.category}
								onChangeShowHideSteps={this.onChangeShowHideSteps}
								exportPathwayPblm={this.exportPathwayPblm}
								onEnableReorder={this.onEnableReorder}
								onEnableQuickReorder={this.onEnableQuickReorder}
								copyFromPathWayProblems={this.copyFromPathWayProblems.bind(this)}
								selectedPathwayColumns={this.state.selectedPathwayColumns}
								onClickSynthesis={this.onClickSynthesis.bind(this)}
								exportStandardCareChart={this.exportStandardCareChart}
								clpStepFilter={this.state.clpStepFilter}
								hospitalChanged={this.hospitalChanged.bind(this)}
								selectedHospital={this.state.selectedHospital}
								reloadDetails={this.reloadDetails.bind(this)}
								onComparisonOfClps={this.onComparisonOfClps.bind(this)}
								taskCount={this.state.taskCount}
								totalTaskCount={this.state.totalTaskCount}
								isSearchEnabled={ this.state.search || this.state.searchField || this.state.type || this.state.intervenants}
								dayInformation={this.dayInformation}
								search={this.state.search}
								searchField={this.state.searchField}
								searchType={this.state.searchType}
								clinicalPathwayData={this.state.clinicalPathway}
								onFetchData={this.onFetchData.bind(this)}
								onRef={ref => (this.child = ref)}
							/>
						</div>
					</div>
					<div className="pathway_tab padding-top-5">
						<ul className="nav nav-tabs clinical_pathway_tabs">
							<li className={this.state.pathwayTab === "healthPathway" ? "active" : ""}>
								<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.PathwaytabChanged.bind(this, "healthPathway")}>
									<I18 tkey="HealthPathway" />
								</a>
							</li>
							<li className={this.state.pathwayTab === "clinicalPathway" ? "active" : ""}>
								<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.PathwaytabChanged.bind(this, "clinicalPathway")}>
									<I18 tkey="ClinicalPathway" />
								</a>
							</li>
							<li className={`float_right_li d-flex pathway_li ${this.state.category === "clpProblem" ? "pathway_li_clpProblem" : ""}`}>
								{/* <div className="padding-0 tab_search d-flex"> */}
								{this.state.category === "clpTasks" ?
										<Search
											key={this.state.key}
											search={this.state.search}
											searchField={this.state.searchField}
											searchType={this.state.searchType}
											searchFieldChanged={this.searchFieldChanged.bind(this)}
											searchTypeChanged={this.searchTypeChanged.bind(this)}
											searchChanged={this.searchChanged.bind(this)}
										/> : ""}
								{this.state.category === "clpProblem" ?
										<ProblemSearch
											search={this.state.search}
											field={this.state.searchField}
											type={this.state.type}
											intervenants={this.state.intervenants}
											searchFieldChanged={this.searchFieldChanged.bind(this)}
											typeChanged={this.typeChanged}
											intervenantsChanged = {this.intervenantsChanged}
											searchChanged={this.searchChanged.bind(this)}
											clpStepFilter={this.state.clpStepFilter}
											clinicalPathwayData={this.state.clinicalPathway}
										/> : ""
								}
								{/* </div> */}
							</li>
						</ul>
					</div>
					<div className={`problem_pathway_section padding-top-10 my1  ${this.state.isFullScreen ? "full_screen padding-15 padding-top-10 h_100" : ""}`}>
						<div className="pathway_tab d-flex justify-content-between align-items-end">
							 <div className="clinical_pathway_tabs_container">
								<ul className="nav nav-tabs clinical_pathway_tabs">
									<li className={this.state.category === "clpProblem" ? "active" : ""}>
										<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "clpProblem")}>
											<I18 tkey="Pathway Problems" />
										</a>
									</li>
									<li className={this.state.category === "standardCareChart" ? "active" : ""}>
										<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "standardCareChart")}>
											<I18 tkey="Pathway Chart" />
										</a>
									</li>
								</ul>
							 </div>
							{this.state.category === "clpProblem" ?											
								<ProblemTopSection
									clinicalPathwayData={this.state.clinicalPathway}
									handleCancel={this.handleCancel.bind(this)}
									toggleHideHeaders={this.toggleHideHeaders}
									showHeaders={!this.state.hideHeaders}
									clpStepFilter={this.state.clpStepFilter}
									changeClpStepFilter={this.changeClpStepFilter}
									reloadDetails={this.reloadDetails.bind(this)}
									onQuickReorderData = {this.onQuickReorderData}
									enableProblemListReorder = {this.state.enableProblemListReorder}
									enableQuickProblemListReorder = {this.state.enableQuickProblemListReorder}
									disableProblemListReorder = {this.disableProblemListReorder}
									type={this.state.type}
									typeChanged={this.typeChanged}
									quickReorderProblemListChanges = {this.state.quickReorderProblemListChanges}
									onSaveAndNextQuickOrder = {this.onSaveAndNextQuickOrder.bind(this)}
									isProblemOrderUpdating = {this.state.isProblemOrderUpdating}
									toggleProblemListFullScreen = {this.toggleFullScreen}
									isProblemListFullScreen = {this.state.isFullScreen}
									isHospitalisation = {this.state.isHospitalisation}
								/> : ""}
							{/* {this.state.category === "standardCareChart"  ? 
									<ClpStepFilter filter={this.state.clpStepFilter} changeFilter={this.changeClpStepFilter} />
								: ""} */}
							 {this.state.category === "standardCareChart"  ?											
								<StandardCareChartTopSection 
									treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : null}
									clpStepFilter={this.state.clpStepFilter}
									changeClpStepFilter={this.changeClpStepFilter}
									selectedColumns={this.state.selectedPathwayColumns}
									onRef={ref => (this.child = ref)}
									category={this.state.category}
									reloadDetails={this.reloadDetails.bind(this)}
									dayInformation={this.dayInformation}
									onFetchData={this.onFetchData.bind(this)}
									clinicalPathwayData={this.state.clinicalPathway}
									setTaskCount={this.setTaskCount.bind(this)}
									isFullScreen = {this.state.isFullScreen}
									toggleFullScreen = {this.toggleFullScreen}
									isHospitalisation = {this.state.isHospitalisation}
								/> : ""}
						</div>


						{this.state.category === "clpProblem" ?
						<ProblemSection
							key={this.state.key}
							reloadDetails={this.reloadDetails.bind(this)}
							clinicalPathwayData={this.state.clinicalPathway}
							search={this.state.search}
							type={this.state.type}
							showIntervention={this.state.showIntervention}
							treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""}
							field={this.state.searchField}
							showHeaders={!this.state.hideHeaders}
							toggleHideHeaders={this.toggleHideHeaders}
							clpStepFilter={this.state.clpStepFilter}
							changeClpStepFilter={this.changeClpStepFilter}
							selectedPblmColumns={this.state.selectedPblmColumns}
							typeChanged={this.typeChanged}
							copyFromPathWayProblems={this.state.copyFromPathWayProblems}
							closeCopyFromPathWayProblems={this.closeCopyFromPathWayProblems.bind(this)}
							dayInformation={this.dayInformation}
							onFetchData={this.onFetchData.bind(this)}
							category={this.state.category}
							handleCancel={this.handleCancel.bind(this)}
							isClpSynthesis={this.state.isClpSynthesis}
							clpComparison={this.state.clpComparison}
							closeComparisonOfClps={this.onComparisonOfClps.bind(this)}
							setTaskCount={this.setTaskCount.bind(this)}
							onRef={ref => (this.child = ref)}
							enableProblemListReorder = {this.state.enableProblemListReorder}
							toggleProblemListFullScreen = {this.toggleFullScreen}
							isProblemListFullScreen = {this.state.isFullScreen}
							intervenants = {this.state.intervenants}
						/> : ""
						}
						{this.state.category === "standardCareChart" ?
						<StandardCareChart
							treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : null}
							clpStepFilter={this.state.clpStepFilter}
							changeClpStepFilter={this.changeClpStepFilter}
							selectedColumns={this.state.selectedPathwayColumns}
							onRef={ref => (this.child = ref)}
							category={this.state.category}
							reloadDetails={this.reloadDetails.bind(this)}
							dayInformation={this.dayInformation}
							onFetchData={this.onFetchData.bind(this)}
							clinicalPathwayData={this.state.clinicalPathway}
							setTaskCount={this.setTaskCount.bind(this)}
							isFullScreen = {this.state.isFullScreen}
						/>
						: ""
						}
					</div>
					{this.state.addOrRemoveStep ?
						<Modal
							visible={this.state.addOrRemoveStep}
							className="cost_modal"
							title={this.addRemoveStepTitle()}
							onCancel={this.handleCancel.bind(this)}
							destroyOnClose={true}
							bodyStyle={{ maxHeight: "400px", overflowY: "scroll" }}
							wrapClassName="drag_modal"
							footer={[
								<Button key="back" onClick={this.handleCancel.bind(this)}>
									<I18 tkey="Cancel" />
								</Button>,
								<Button key="save" type="primary" loading={this.state.loadingSave} onClick={this.onSaveClicked.bind(this)}>
									<span className="margin-left-10" >
										<I18 tkey="Save" />
									</span>
								</Button>
							]}
						>
							{this.getSubStepDetails()}
						</Modal> : ""
					}
					{this.state.copyFromClinicalPathWay ?
						<Modal
							visible={this.state.copyFromClinicalPathWay}
							className="cost_modal re_assign_modal overflow-x-hidden-model"
							title={this.copyFromClinicalPathwayTitle()}
							onCancel={this.handleCancel.bind(this)}
							wrapClassName="drag_modal"
							footer={null}
						>
							<CopyFromClinicalPathWay key={this.state.key} clinicalPathwayData={this.state.clinicalPathway} closeCopy={this.closeCopy.bind(this)} />
						</Modal> : ""}
					{this.state.dayInformation ?
						<Modal
							visible={this.state.dayInformation}
							className="cost_modal re_assign_modal"
							title={i18Get("Allocation of Days in Clinical Pathway", utils.getLanguage())}
							onCancel={this.handleCancel.bind(this)}
							footer={null}
						>
							<DayInformation treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""} />
						</Modal> : ""}
					{this.state.exportClinicalPathWay ?
						<Export treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""} key={this.state.key} clinicalPathwayData={this.state.clinicalPathway} exportClinicalPathWay={this.state.exportClinicalPathWay} closeExport={this.closeExport.bind(this)} selectedColumns={this.state.selectedColumns} typeActivity={this.props.typeActivity} showIntervention={this.state.showIntervention} hospitalId={this.state.selectedHospital ? this.state.selectedHospital.value : ""} clinicalPathwayType={this.state.clinicalPathway.clinicalPathwayType}
							search={this.state.search ? this.state.search : ""} searchField={this.state.searchField ? this.state.searchField : ""} searchType={this.state.searchType ? this.state.searchType : ""} clpStepFilter={this.state.clpStepFilter} />
						: ""}
					{this.state.exportSelectedComponent ?
						<ExportSelectedComponent treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""} key={this.state.key} clinicalPathwayData={this.state.clinicalPathway} exportSelectedComponent={this.state.exportSelectedComponent} closeSelectedComponentExport={this.closeSelectedComponentExport.bind(this)} selectedColumns={this.state.selectedColumns} typeActivity={this.props.typeActivity} showIntervention={this.state.showIntervention} clinicalPathwayType={this.state.clinicalPathway.clinicalPathwayType}
							search={this.state.search ? this.state.search : ""} searchField={this.state.searchField ? this.state.searchField : ""} searchType={this.state.searchType ? this.state.searchType : ""} clpStepFilter={this.state.clpStepFilter} />
						: ""}
					{this.state.exportPathwayPblm ?
						<ExportPathwayProblem
							closeExport={this.closeExport.bind(this)}
							clinicalPathwayData={this.state.clinicalPathway}
							search={this.state.search}
							treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""}
							type={this.state.type}
							field={this.state.searchField}
							clpStepFilter={this.state.clpStepFilter}
							selectedPblmColumns={this.state.selectedPblmColumns}
						/> : ""}
					{this.state.exportStandardCareChart ?
						<ExportStandardCareChart
							closeExport={this.closeExport.bind(this)}
							filePrefix={this.state.clinicalPathway.name}
							treatmentId={this.state.selectedHospital && this.state.selectedHospital.treatmentId ? this.state.selectedHospital.treatmentId : ""}
							type={this.state.type}
							clpStepFilter={this.state.clpStepFilter}
							selectedPblmColumns={this.state.selectedPathwayColumns}
						/> : ""}
				</div>
			</div>
		);
	}
}

ClinicalPathWayDetails.propTypes = {
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathway: store.clinicalPathway,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations
}))(ClinicalPathWayDetails));