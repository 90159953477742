import React, { Component } from "react";
import { ChromePicker } from "react-color";

class ColorPicker extends Component {
	render() {
		return (
			<div>
				<ChromePicker disableAlpha color={this.props.color} onChange={(color) => this.props.onchange(color.hex, this.props.item ? this.props.item : null)} />
			</div>
		);
	}
}

export default ColorPicker;
