import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import { i18Get } from "../../../../../../../i18";
import staffAction from "../../../../../../../actions/staff-elements";
import utils from "../../../../../../../utils/utils";
import Loader from "../../../../../../../plug/loader";
import events from "../../../../../../../actions/events";
import column from "./column";

class Team extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			sorted: [],
			template: null
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.onFetchData();
	}

	onFetchData() {
		this.state.loading = true;
		this.props.dispatch(staffAction.getAll(this.props.match.params.id, this.state.sort, this.state.desc, this.props.status, this.props.hospitalId));
	}

	render() {
		this.state.typeActivity = this.props.typeActivity;
		if (this.props.staffElements.isFetchAllDataCompleted === "FULFILLED") {
			this.state.loading = false;
			this.state.list = this.props.staffElements.data.personnels;
			this.props.dispatch(staffAction.transferTemplateInfo({ label: this.props.staffElements.data.template ? this.props.staffElements.data.template.templateName : "", value: this.props.staffElements.data.template ? this.props.staffElements.data.template.templateId : "" }));
			this.props.dispatch(staffAction.cleargetAll());
		}

		return (
			<div className="tab_section padding-top-15">
				<ReactTable
					className="margin-bottom-42"
					data={this.state.list}
					loading={this.state.loading}
					LoadingComponent={() => (<Loader loading={this.state.loading} isTable />)}
					multiSort={false}
					pageSize={(this.state.list && this.state.list.length) ? this.state.list.length : 3}
					showPagination={false}
					noDataText={this.state.loading ? "" : i18Get("NO STAFF FOUND", utils.getLanguage())}
					columns={column(this.props.typeActivity)}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange={(newSorted) => {
						this.setState({ sorted: newSorted });
					}}
					sorted={this.state.sorted}
				/>
			</div>
		);
	}
}

Team.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	staffElements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	staffElements: store.staffElements,
	translations: store.translations
}))(Team));
