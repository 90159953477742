import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import elementActions from "../../../../../../../../actions/elements";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SelectNewConsumbales extends Component {
	constructor(props) {
		super(props);
		this.state = {
			consumables: [],
			offset: 0,
			numResults: 100,
			search: null,
			checked: 1,
			isDataFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchConsumableList(true);
		if (this.props.type !== "STERILIZATION") {
			this.props.dispatch(elementActions.getAllExistingConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), this.state.search, "", this.props.match.params.id, "", "", this.props.exType, ("mode=EXISTING"), this.props.elementId));
		}
		if (this.props.type === "STERILIZATION") {
			this.props.dispatch(elementActions.getAllExistingSterlizationConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), this.state.search, "", this.props.match.params.id, "", "", this.props.exType, ("mode=EXISTING"), this.props.elementId));
		}
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchConsumableList(this.state.search);
	}


	fetchConsumableList(search) {
		if (this.props.checked === 1) {
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			if (this.props.checked === 1 && this.props.type !== "STERILIZATION") {
				this.props.dispatch(elementActions.getAllNewConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), search ? this.state.search ? this.state.search : this.props.data ? this.props.data.label : "" : "", "", this.props.match.params.id, "", "", this.props.exType, ("mode=NOT_EXISTING"), this.props.elementId));
			}
			if (this.props.type === "STERILIZATION" && this.props.checked === 1) {
				this.props.dispatch(elementActions.getAllNewSterlizationConsumable(this.state.offset, this.props.type, this.state.numResults, "name", 0, ("category=STANDARD"), search ? this.state.search ? this.state.search : this.props.data ? this.props.data.label : "" : "", "", this.props.match.params.id, "", "", this.props.exType, ("mode=NOT_EXISTING"), this.props.elementId));
			}
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchConsumableList(search);
				}, 800);
			}
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.consumables) {
			this.state.consumables.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onStatusChange = () => {
		this.props.statusCheck("NEW_CONSUMABLE");
		return (this.props.data);
	};

	render() {
		if (this.props.elements.getNewConsumableStatus === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.consumables = unionWith(this.state.consumables, this.props.elements.getNewConsumables, isEqual);
			this.renderOptions();
			this.props.dispatch(elementActions.clearGetAllNewConsumable());
			if (!(this.state.options && this.state.options.length) && !this.state.isDataFound) {
				this.fetchConsumableList(false);
				this.state.isDataFound = true;
			}
		}
		if (this.props.elements.getNewSterlizationConsumableStatus === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.consumables = unionWith(this.state.consumables, this.props.elements.getNewSterlizationConsumables, isEqual);
			this.props.dispatch(elementActions.clearGetAllNewSterlizationConsumable());
			if (!(this.state.options && this.state.options.length) && !this.state.isDataFound) {
				this.fetchConsumableList(false);
				this.state.isDataFound = true;
			}
		}
		if ((this.props.checked !== this.state.checked)) {
			this.state.checked = this.props.checked;
			this.state.consumables = [];
			this.state.offset = 0;
			this.state.options = [];
			this.fetchConsumableList(this.state.search);
		}
		if (!this.props.elements.getNewSterlizationConsumableStatus && this.state.fetchDataFromserver) {
			this.fetchConsumableList(this.state.search);
		}
		return (
			<Select
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.dataChanged}
				isClearable={true}
				value={this.state.options ? this.state.options.filter(options => options.value === this.props.data.value ? this.onStatusChange() : "") : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Consumbale", utils.getLanguage())}
			/>
		);
	}
}

SelectNewConsumbales.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	elements: store.elements,
	translations: store.translations
}))(SelectNewConsumbales));                    