import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import ActionButton from "../../../../../plug/action-button";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import resetPasswordActions from "../../../../../actions/user/resetPassword";
import SVG from "../../../../../plug/svg";

class ResetPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			newPassword: "",
			confirmPassword: "",
			invalidPassword: false,
			passwordsNoMatch: false,
			showPassword: {
				NEW: false,
				R_NEW: false
			}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (
				location.pathname !== this.props.location.pathname ||
				location.search !== this.props.location.search
			) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.onSubmitClicked();
		}
	}

	handleInputChange(field) {
		return (e) => {
			const prevState = this.state;
			prevState[field] = e.target.value;
			prevState.invalidPassword = false;
			prevState.passwordsNoMatch = false;
			this.setState(prevState);
		};
	}

	onLoginClicked() {
		this.props.history.push(locations.dashBoard);
	}

	toggleShowPassword(label) {
		return () => {
			const prevState = this.state;
			prevState.showPassword[label] = !this.state.showPassword[label];
			this.setState(prevState);
		};
	}

	validateFields() {
		let invalid = false;
		const prevState = this.state;
		if (this.state.newPassword.length < 8) {
			prevState.invalidPassword = true;
			invalid = true;
		} else if (this.state.newPassword !== this.state.confirmPassword) {
			prevState.passwordsNoMatch = true;
			invalid = true;
		}
		if (invalid) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	onSubmitClicked() {
		if (this.validateFields()) {
			const payload = {
				newPassword: this.state.newPassword,
				repeatPassword: this.state.confirmPassword,
				token: this.state.token
			};
			this.props.dispatch(resetPasswordActions.resetPassword(payload));
		}
	}

	render() {
		if (this.state.token !== this.props.match.params.token) {
			this.state.token = this.props.match.params.token;
			this.props.dispatch(
				resetPasswordActions.validateResetToken({
					token: this.state.token
				})
			);
		}
		if (this.props.user.isValidateResetTokenComplete === "REJECTED") {
			this.props.dispatch(resetPasswordActions.clearValidateResetToken());
			this.props.history.push(locations.base);
		}
		if (this.props.user.isResetPasswordComplete === "FULFILLED") {
			this.props.dispatch(resetPasswordActions.clearResetPassword());
			this.props.history.push(locations.base);
		}
		return (
			<div className="login_input_container margin-top-35">
				<div className="input_group di w_100">
					<img className="width_15 input_icon" src="images/password_icon.png" />
					<div className="group w_100 reset_password_eye">
						<input
							maxLength="30"
							className="input_icon login_input"
							type={this.state.showPassword.NEW ? "text" : "password"}
							value={this.state.newPassword}
							onChange={this.handleInputChange("newPassword").bind(this)}
							required
						/>
						<a onClick={this.toggleShowPassword("NEW").bind(this)}>
							{this.state.showPassword.NEW ? (
								<SVG
									src="/images/eye-slash.svg"
									fillColor={
										Object.keys(this.props.settings.theme).length
											? this.props.settings.theme.primaryColor.value
											: ""
									}
								/>
							) : (
								<SVG
									src="/images/eye.svg"
									fillColor={
										Object.keys(this.props.settings.theme).length
											? this.props.settings.theme.primaryColor.value
											: ""
									}
								/>
							)}
						</a>
						<span className="highlight" />
						<span className="bar1" />
						<label>{i18Get("New Password", utils.getLanguage())}</label>
						{this.state.invalidPassword && (
							<span className="invalid fixed_label">
								<I18 tkey="Password must be minimum 8 characters" />
							</span>
						)}
					</div>
				</div>
				<div className="input_group di margin-top-20 w_100">
					<img className="width_15 input_icon" src="images/password_icon.png" />
					<div className="group w_100 reset_password_eye">
						<input
							maxLength="30"
							className="input_icon login_input"
							type={this.state.showPassword.R_NEW ? "text" : "password"}
							value={this.state.confirmPassword}
							onChange={this.handleInputChange("confirmPassword").bind(this)}
							required
						/>
						<a onClick={this.toggleShowPassword("R_NEW").bind(this)}>
							{this.state.showPassword.R_NEW ? (
								<SVG
									src="/images/eye-slash.svg"
									fillColor={
										Object.keys(this.props.settings.theme).length
											? this.props.settings.theme.primaryColor.value
											: ""
									}
								/>
							) : (
								<SVG
									src="/images/eye.svg"
									fillColor={
										Object.keys(this.props.settings.theme).length
											? this.props.settings.theme.primaryColor.value
											: ""
									}
								/>
							)}
						</a>
						<span className="highlight" />
						<span className="bar1" />
						<label>{i18Get("Confirm New Password", utils.getLanguage())}</label>
						{this.state.passwordsNoMatch && (
							<span className="invalid fixed_label">
								<I18 tkey="Passwords do not match" />
							</span>
						)}
					</div>
				</div>
				<div
					className="forgot_password float_right"
					onClick={this.onLoginClicked.bind(this)}
				>
					<a>{i18Get("Login/Register", utils.getLanguage())}</a>
				</div>
				<div className="login_button di w_100 text_align_center margin-top-35">
					<ActionButton
						type="button"
						onClick={this.onSubmitClicked.bind(this)}
						actionName={this.props.actions.actionOneInProgress}
						value={i18Get("SUBMIT", utils.getLanguage())}
					/>
				</div>
			</div>
		);
	}
}

ResetPassword.propTypes = {
	user: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		user: store.user,
		actions: store.actions,
		settings: store.settings,
		translations: store.translations
	}))(ResetPassword)
);
