import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import communitySitesAction from "../../../../../../../actions/community-sites";
import I18, { i18Get } from "../../../../../../../i18";

class CommunityInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySiteList: [],
			selectCommunity: [],
			loading: false,
			search: ""

		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(communitySitesAction.getOne(this.props.healthPathwayData.siteId, this.props.healthPathwayData.hospitalId));
	}

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th>
							<I18 tkey="NAME" />
						</th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}

	getTableContent(list) {
		if (list && list.length > 0) {
			return list.map((data) => (
				<tr key={data.label}>
					<td>{data.label}</td>
				</tr>
			));
		}

		return (
			<tr>
				<td><div className="text_align_center w_100"><I18 tkey="NO DATA FOUND" /></div></td>
				{" "}
			</tr>
		);
	}

	render() {
		console.log("site", this.props.healthPathwayData);
		if (this.props.communitySites.fetchOneCommunitySitesComplete === "FULFILLED") {
			this.state.communitySiteList = this.props.communitySites.communitySites;
			this.state.selectCommunity = this.state.communitySiteList.communityList;
			this.props.dispatch(communitySitesAction.clearGetOne());
		}
		return (
			<div>
				<div className="margin-top-o-15p margin-bottom-10">
					<h5>
						{ i18Get(("Owner of this Healthpathway:"), utils.getLanguage())}
						{" "}
						<b>{`${this.props.hospitalName}`}</b>
					</h5>
					<h5 className="padding-top-5">{ i18Get(("Accessible Communities:"), utils.getLanguage())}</h5>
				</div>
				{this.getTable(this.state.selectCommunity)}
			</div>
		);
	}
}

CommunityInfo.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	communitySites: store.communitySites,
	healthPathwayDetails: store.healthPathwayDetails
}))(CommunityInfo));
