import React, { Component } from "react";
// import DebounceInput from '../../../../../../../../../plug/name-search-input-state';
import { DebounceInput } from "react-debounce-input";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";

export default class Search extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="">
				<div className="cosumable_search">
					<DebounceInput
						className={this.props.isConsumableClass ? "form-control w_100" : ""}
						debounceTimeout={750}
						placeholder={i18Get("Search...", utils.getLanguage())}
						value={this.props.filter.search}
						onChange={this.props.searchChanged}
					/>
				</div>
			</div>
		);
	}
}
