import React, { useState, useRef, useEffect } from "react";
import ColorPicker from "../../SidebarSettings/settings/color-picker";

const EditorColorPicker = (props) => {

	const [pickActivity, setPickActivity] = useState(null);
	const wrapperPickerRef = useRef(null);
	useOutsideAlerter(wrapperPickerRef);

	function useOutsideAlerter(ref) {
		useEffect(() => {

		  function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) { setPickActivity(null);}
		  }
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => { document.removeEventListener("mousedown", handleClickOutside);};
		}, [ref]);
	}

	return (
		<>
			<span onClick={() => setPickActivity(props.activity)}>{props.children}</span>
			{ pickActivity ? <div className="mom-date-picker" ref={wrapperPickerRef} style={{ right: "100px" }}><ColorPicker color={props.color} onchange={(color, activity) => props.onchange(color, activity) } item={pickActivity} /></div> : ""}
		</>

	);
};

export default EditorColorPicker;
