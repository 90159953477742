/* eslint-disable indent */
import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../actions/admin-clinical-pathway";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import ClinicalPathwayTypeDropdown from "../clinical-pathway-type-dropdown";
import StepTypeDropdown from "../step-type-dropdown";
import SectionTypeDropDown from "../section-type-dropdown";
import { ClpStepFilters } from "../../../clinical-pathway/details";
import StepTypeDescription from "../../../../../../../constants/stepTypeDescription.json";
import { Checkbox, Modal } from "antd";

class EditStepDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			stepDetails: {},
			warningMsg: false,
			clpType: {},
			clpTypeTemp: [],
			clinicalPathwayTypeTemp: [],
			stepStatusCheck: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwayStep(this.props.match.params.id));
	}

	componentDidMount () {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.saveClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.stepDetails.name = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		if (utils.checkCodeRegex(value)) {
			prevState.invalidCode = false;
			prevState.stepDetails.code = value;
		} else {
			prevState.stepDetails.code = "";
		}
		this.setState(prevState);
	}

	selectedClinicalPathwayType(e) {
		const prevState = this.state;
		prevState.invalidClinicalPathwayType = false;
		prevState.clinicalPathwayTypeTemp = this.state.stepDetails.clinicalPathwayType ? [...this.state.stepDetails.clinicalPathwayType] : null;
		prevState.stepDetails.clinicalPathwayType = e;
		prevState.clpTypeTemp = this.state.clpType ? { ...this.state.clpType } : {};
		let ItemRemoved = false;
		for (const index in this.state.clpType) {
			if (this.state.clpType[index] == true) {

				if (e && !e.includes(index)) {
					prevState.clpType[index] = false;
					if (!ItemRemoved) { ItemRemoved = true; }
				}
			}
			else if (e && e.includes(index)) {
				prevState.clpType[index] = true;
			}
		}
		if (ItemRemoved) {
			prevState.warningMsg = true;
		}
		this.setState(prevState);
	}

	selectedStepType(e, value) {
		const prevState = this.state;
		prevState.invalidStepType = false;
		prevState.stepDetails.type = e;
		prevState.stepTypeDescription = value.props && value.props.description;
		this.setState(prevState);
	}

	selectedSectionType(e) {
		const prevState = this.state;
		prevState.invalidSectionType = false;
		prevState.stepDetails.sectionType = e;
		this.setState(prevState);
	}

	handleCheckboxclp = (e) => {
		const prevState = this.state;
		prevState.stepDetails.isClp = e.target.checked;
		prevState.invalidStepfield = false;
		this.setState(prevState);
	};

	handleCheckboxhealthPathway = (e) => {
		const prevState = this.state;
		prevState.stepDetails.isHcp = e.target.checked;
		prevState.invalidStepfield = false;
		this.setState(prevState);
	};
	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.stepDetails.name) || !this.state.stepDetails.name || !this.state.stepDetails.name.trim() || (this.state.stepDetails.name.match(/\\$/)) || (this.state.stepDetails.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (!this.state.stepDetails.code) {
			prevState.invalidCode = true;
		}
		if (!this.state.stepDetails.clinicalPathwayType) {
			prevState.invalidClinicalPathwayType = true;
		}
		if (!this.state.stepDetails.sectionType) {
			prevState.invalidSectionType = true;
		}

		if (this.state.sectionType === ClpStepFilters.CLINICAL_PATHWAY && !this.state.stepType) {

			prevState.invalidStepType = true;
		}
		if (!this.state.stepDetails.isClp && !this.state.stepDetails.isHcp) {
			prevState.invalidStepfield = true;
		}
		if (!prevState.invalidName && !prevState.invalidCode && !prevState.invalidClinicalPathwayType && !prevState.invalidSectionType && !prevState.invalidStepType && !prevState.invalidStepfield) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.stepDetails.name.trim(),
				code: this.state.stepDetails.code.trim(),
				clinicalPathwayType: this.state.stepDetails.clinicalPathwayType,
				type: this.state.stepDetails.type ? this.state.stepDetails.type : "",
				sectionType: this.state.stepDetails.sectionType ? this.state.stepDetails.sectionType : "",
				isClp: this.state.stepDetails.isClp ? this.state.stepDetails.isClp : false,
				isHcp: this.state.stepDetails.isHcp ? this.state.stepDetails.isHcp : false
			};
			this.props.dispatch(adminClinicalPathwayActions.updateClinicalPathwayStep(this.state.stepDetails.id, payload));
		}
	}

	findStepTypeDescription(stepType) {
		if (StepTypeDescription[stepType]) {
			this.setState({ stepTypeDescription: StepTypeDescription[stepType] });
		}
	}

	hideWarningMsg() {
		const prevState = this.state;
		prevState.warningMsg = false;
		prevState.stepDetails["clinicalPathwayType"] = this.state.clinicalPathwayTypeTemp;
		prevState.clpType = this.state.clpTypeTemp;
		this.setState(prevState);
	}

	render() {

		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayStepCompleted === "FULFILLED") {
			this.state.stepDetails = this.props.adminClinicalPathway.step;
			const optData = {};
			this.props.adminClinicalPathway.step.clinicalPathwayType.forEach(e => { optData[e] = true; });
			this.state.clpType = optData;
			this.state.type = this.props.adminClinicalPathway.step.type;
			this.findStepTypeDescription(this.state.type);
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.stepDetails.name, id: this.state.stepDetails.id } });
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayStep());
		}
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayStep());
			this.props.history.push(locations.administration_clinical_pathway + "/" + locations.tabClinicalPathwayStep);
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayStepCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayStep());
			this.props.history.push(locations.administration_clinical_pathway + "/" + locations.tabClinicalPathwayStep);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayStep());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								{/* <label className="code_label"> * <I18 tkey="Code must be 5-digit" /></label> */}
								<NameInput
									value={this.state.stepDetails.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={5}
									disabled={true} />
								{this.state.invalidCode ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid code' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.stepDetails.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={128} />
								{this.state.invalidName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid name (Special characters not allowed)' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Type" /></label>
								<SectionTypeDropDown
									className=" "
									sectionType={this.state.stepDetails.sectionType ? this.state.stepDetails.sectionType : utils.sameDropper()}
									selectedSectionType={this.selectedSectionType.bind(this)}
									disabled={true}
								/>
								{this.state.invalidSectionType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid type" /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Clinical Pathway Type" /></label>
								<ClinicalPathwayTypeDropdown
									className=" "
									ignoreAll={true}
									clinicalPathwayType={this.state.stepDetails.clinicalPathwayType}
									selectedClinicalPathwayType={this.selectedClinicalPathwayType.bind(this)} />
								{this.state.invalidClinicalPathwayType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid clinical pathway type" /></span> : ""}
							</div>
						</div>
						{this.state.stepDetails.sectionType && this.state.stepDetails.sectionType === ClpStepFilters.CLINICAL_PATHWAY &&
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-20 position_relative">
									<label><I18 tkey="Step Type" /></label>
									<StepTypeDropdown
										className=" "
										disabled={this.state.type}
										clinicalPathwayType={this.state.stepDetails.clinicalPathwayType}
										stepType={this.state.stepDetails.type ? this.state.stepDetails.type : utils.sameDropper()}
										selectedStepType={this.selectedStepType.bind(this)} />
									{this.state.invalidStepType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid step type" /></span> : ""}
								</div>
								<span>
									{this.state.stepDetails.type && this.state.stepTypeDescription &&
										<div className='dynamic_description'><em>{this.state.stepTypeDescription.title}</em><br />
											<strong><I18 tkey="Example" />:</strong>
											<ul>
												{this.state.stepTypeDescription.example.map((data, index) => {
													return (<li key={index}>{data}</li>);
												})}
											</ul>
										</div>
									}
								</span>
							</div>}
							<div className="col-md-8">
							<label><I18 tkey="Available in:" /></label>
							<div className="w_100 di margin-bottom-10 position_relative">
								<Checkbox 
									onChange={this.handleCheckboxclp} 
									value={1}
									checked={this.state.stepDetails.isClp}
								>
									Clinical pathway
								</Checkbox>
								<Checkbox 
									onChange={this.handleCheckboxhealthPathway} 
									value={2}
									checked={this.state.stepDetails.isHcp}
								>
									Health pathway
								</Checkbox>
								{this.state.invalidStepfield ? <span className='col-md-8 invalid_right fixed_label'><I18 tkey="Select valid step field" /></span> : ""}
							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									onClick={this.saveClicked.bind(this)}
									value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Step", utils.getLanguage())}
					visible={this.state.warningMsg}
					onCancel={this.hideWarningMsg.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={() => { this.setState({ warningMsg: false }); }}>
					<div>
						<I18 tkey="Editing this will remove all the corresponding sub steps and items belonging to the step. It will also remove the items added for clinical pathways under this step. Are you sure you want to edit" />&nbsp; ?
					</div>
				</Modal>
			</div>
		);
	}
}

EditStepDetails.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(EditStepDetails));
