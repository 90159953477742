import React, { useState, useRef, useEffect } from "react";
import "./styles.css";
import I18 from "i18";
import ColorPicker from "./color-picker";

const BgColorPicker = (props) => {

	const [pickActivity, setPickActivity] = useState(null);
	const wrapperPickerRef = useRef(null);
	useOutsideAlerter(wrapperPickerRef);

	function useOutsideAlerter(ref) {
		useEffect(() => {

		  function handleClickOutside(event) {
				if (ref.current && !ref.current.contains(event.target)) { setPickActivity(null);}
		  }
		  document.addEventListener("mousedown", handleClickOutside);
		  return () => { document.removeEventListener("mousedown", handleClickOutside);};
		}, [ref]);
	}

	return (
		<>
        	<div className="d-flex margin-top-20">
				<div className="w_100 ">
					<strong><I18 tkey='Background' /></strong>
					<div  style={{ width: "50px", height: "20px", background: props.fill }}
						className={"custom_bordered_div cursor_pointer  margin-left-5 float_right"}
						onClick={() => setPickActivity("fill")}>
					</div>
				</div>
			</div>

			{ props.strokeColorDisabled !== true ? <div className="d-flex margin-top-10">
				<div className="w_100 ">
					<strong><I18 tkey='Border' /></strong>
					<div style={{ width: "50px", height: "20px", background: props.strokeColor }}
						className={"custom_bordered_div cursor_pointer margin-left-5 float_right"}
						onClick={() => setPickActivity("strokeColor")}>
					</div>
				</div>
			</div> : ""}
			<div className="d-flex margin-top-10">
				<div className="w_100 ">
					<strong><I18 tkey='Font Color' /></strong>
					<div style={{ width: "50px", height: "20px", background: props.color }}
						className={"custom_bordered_div cursor_pointer margin-left-5 float_right"}
						onClick={() => setPickActivity("color")}>
					</div>
				</div>
			</div>
			{ pickActivity ? <div className="mom-date-picker" ref={wrapperPickerRef}><ColorPicker color={props[pickActivity]} onchange={(color, activity) => { props.onClicked(activity, color);}} item={pickActivity} /></div> : ""}
		</>

	);
};

export default BgColorPicker;
