import eventNames from "./event";

const initialState = {
	updateReGroup: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.UPDATE_GROUPMENT}_FULFILLED`:
			return {
				...state,
				updateReGroup: "FULFILLED"
			};
		case `${eventNames.UPDATE_GROUPMENT}_REJECTED`:
			return {
				...state,
				updateReGroup: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_GROUPMENT:
			return {
				...state,
				updateReGroup: false
			};
		default:
			return state;
	}
}
