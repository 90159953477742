// import i18 from "i18";
import { AnnotationConstraints } from "@syncfusion/ej2-react-diagrams";
export default [
	{ id: "Rectangle", shape: { type: "Basic", shape: "Rectangle" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Ellipse", shape: { type: "Basic", shape: "Ellipse" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Parallelogram", shape: { type: "Basic", shape: "Parallelogram" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Triangle", shape: { type: "Basic", shape: "Triangle" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Hexagon", shape: { type: "Basic", shape: "Hexagon" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Pentagon", shape: { type: "Basic", shape: "Pentagon" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Cylinder", shape: { type: "Basic", shape: "Cylinder" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Plus", shape: { type: "Basic", shape: "Plus" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Heptagon", shape: { type: "Basic", shape: "Heptagon" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Octagon", shape: { type: "Basic", shape: "Octagon" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Trapezoid", shape: { type: "Basic", shape: "Trapezoid" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Decagon", shape: { type: "Basic", shape: "Decagon" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "RightTriangle", shape: { type: "Basic", shape: "RightTriangle" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Diamond", shape: { type: "Basic", shape: "Diamond" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Star", shape: { type: "Basic", shape: "Star" }, annotations: [{ constraints: AnnotationConstraints.ReadOnly }]  },
	{ id: "Text", style: { fill: "transparent", strokeColor: "transparent" }, 
		annotations: [{ content: "Annotation Text", constraints: AnnotationConstraints.ReadOnly }],
		shape: { symbolInfo: { description: { text: "Text" } } }
	 }
];