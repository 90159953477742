//LIVE ACTIVITIES
// For fix element alignment
setInterval(function () {
	if (document.getElementsByClassName("anticon-filter").length > 0 && document.getElementsByClassName("ReactTable").length > 0) {
		const div = document.getElementsByClassName("anticon-filter")[0];
		const table = document.getElementsByClassName("ReactTable")[0];
		if (div.classList.contains("active")) {
			table.classList.add("clp_table");
			table.classList.remove("clp_table_cover");
		} else {
			table.classList.remove("clp_table");
			table.classList.add("clp_table_cover");
		}
	}
}, 10);