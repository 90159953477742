import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router";
import locations from "../../../../../../../router/locations";
import CreateProblem from "./problem";
import CreateStep from "./step";
import CreateSubStep from "./sub-step";
import CreateItems from "./items";

export default class AdminPersonnelCreateRoute extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}/create`}
					render={() => <CreateProblem {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}/create`}
					render={() => <CreateStep {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}/create`}
					render={() => <CreateSubStep {...this.props} />}
				/>
				<Route
					exact
					path={`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}/create`}
					render={() => <CreateItems {...this.props} />}
				/>
				<Redirect to={`${locations.adminPersonnel}/${locations.tabClinicalPathwayStep}`} />
			</Switch>
		);
	}
}
