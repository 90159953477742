import React, { Component } from "react";
import { i18Get } from "../../i18";
import utils from "../../utils/utils";
import "./pagination_style.css";

class Pagination extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: "",
			pageSizeOptions: [10, 15, 20, 25, 50, 100],
			pageSize: 10,
			className: "",
			style: {}
		};
	}
	UNSAFE_componentWillMount() {
		if (this.props.pageSizeOptions) {
			this.state.pageSizeOptions = this.props.pageSizeOptions;
		}
		this.state.pageSize = this.props.pageSize ? this.props.pageSize : this.state.pageSize;
		this.state.className = this.props.className ? this.props.className : "";
		this.state.style = this.props.style ? this.props.style : this.state.style;
		this.setState({ ...this.state });
	}
	prev = () => {
		if (this.props.page > 1) {
			this.props.onPageChange(parseInt(this.props.page) - 1);
		}
	};
	next = () => {
		if (this.props.page < this.props.totalPage) {
			this.props.onPageChange(parseInt(this.props.page) + 1);
		}
	};
	inputchange = (e) => {
		if (e.target.value > 0 && e.target.value <= this.props.totalPage) {
			this.props.onPageChange(e.target.value);
		}
	};

	onPageSizeChange = (e) => {
		if (e.target.value > 0) {
			this.props.onPageSizeChange(e.target.value);
		}
	};
	componentDidUpdate() {
		if (this.props.style != this.state.style)
		{this.state.style = this.props.style ? this.props.style : this.state.style;}
	}
	render() {
		return (
			<div className={`custom-pagination ${this.state.className}`} style={this.state.style}>
				<div className='pagination-bottom col-sm-12'>
					<div className={`col-sm-4 nav ${(this.props.page > 1) ? "active" : ""}`}>
						<div className='-button' onClick={this.prev}>{i18Get("Previous", utils.getLanguage())}</div>
					</div>

					<div className='col-sm-4 -pageJump'>
						<span>{i18Get("Page", utils.getLanguage())}
							<input type="number" onChange={this.inputchange} value={this.props.page} />
							of {this.props.totalPage ? this.props.totalPage : 1}
						</span>
						<span className='-pageSizeOptions'>
							<select onChange={this.onPageSizeChange}>
								{this.state.pageSizeOptions.map(d => {
									return <option key={d} selected={this.props.pageSize == d ? true : false} value={d}>{d} {i18Get("rows", utils.getLanguage())}</option>;
								})}
							</select>
						</span>
					</div>
					<div className={`col-sm-4 nav ${(this.props.page < this.props.totalPage) ? "active" : ""}`}>
						<div className='-button' onClick={this.next}>{i18Get("Next", utils.getLanguage())}</div>
					</div>

				</div>
			</div>
		);
	}
}

export default Pagination;