import eventNames from "./event";

const initialState = {
	sort: "numberOfTask",
	order: 1
};


export default function reducer(state = initialState, action) {
	switch (action.type) {

		case eventNames.SET_HEALPATHWAYS_SORT:
			return {
				...state,
				sort: action.payload.sort,
				order: action.payload.order
			};

		default:
			return state;
	}
}
