import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import CREATE_HEALTH_PATHWAY from "../../create-intervention/mom";
import screenId from "../../../../../../router/screenIdList";

class CreateHealthPathwayMom extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: {}
		};
	}

	render() {
		return (
			<div>
				<CREATE_HEALTH_PATHWAY typeActivity="HEALTH_PATHWAY" screenId={screenId.anaesthesiaCreateMOM} />
			</div>
		);
	}
}

CreateHealthPathwayMom.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateHealthPathwayMom));
