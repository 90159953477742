import React, { useEffect, useState, useRef  } from "react";
import { useSelector } from "react-redux";
import utils from "utils/utils";
import "./style.css";
import "./onserver.js";

const  HorizontalVirtualScroll = ({ refItem, onZoomSliderChange }) => {
    const healthPathway = useSelector((state) => { return state.healthPathway?.fetchAllDiagramInfo;});
    const targetRef = useRef(null);
    useEffect(() => {// FOR REFRESh COMPONENT IN INITIAL STAGE
      const zoomFactor = utils.getFilterInLocalStorage("DIAGRAM_SETTINGS");
      const delayTimeout = setTimeout( () => {
        onZoomSliderChange(10);
        onZoomSliderChange(zoomFactor.zoomSlider);
      }, 3000);
        return () => clearTimeout(delayTimeout);

    }, [healthPathway]);
    const handleScroll = () => {
        if (targetRef.current) {
          refItem.current.scrollLeft = targetRef.current.scrollLeft

          const scrollPosition = targetRef.current.scrollLeft;
          document.getElementById('diagramcontent').scrollLeft = scrollPosition;
          console.log('Scroll Position:', scrollPosition);
        }
      };

    return <>
        <div ref={targetRef} onScroll={handleScroll} className="virtualScroll-diagram-H" style={{width: refItem?.current?.clientWidth || '100%'}} id="virtualDiagramScrollWrap">
            <div  id="virtual_scroll_content"></div>
        </div>
    </>
};

export default HorizontalVirtualScroll;

