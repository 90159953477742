import { Modal } from "antd";
import Util from "./utils";
import { i18Get } from "../i18";

const { confirm } = Modal;

function showDeleteConfirm(title, content, onSuccess, cancelCall) {
	confirm({
		title: title === "" ? `${i18Get("Delete this item", Util.getLanguage())} ?` : title,
		content,
		okText: i18Get("Yes", Util.getLanguage()),
		okType: "danger",
		cancelText: i18Get("No", Util.getLanguage()),
		onOk() {
			if (onSuccess) {
				onSuccess();
			}
		},
		onCancel() {
			if (cancelCall) {
				cancelCall();
			}
		}
	});
}

function showLogout(content, onSuccess, cancelCall) {
	confirm({
		title: `${i18Get("Logout", Util.getLanguage())} ?`,
		content,
		okText: i18Get("Logout", Util.getLanguage()),
		okType: "danger",
		cancelText: i18Get("No", Util.getLanguage()),
		onOk() {
			if (onSuccess) {
				onSuccess();
			}
		},
		onCancel() {
			if (cancelCall) {
				cancelCall();
			}
		}
	});
}

function showConfirm(title, content, onSuccess, cancelCall) {
	confirm({
		title,
		content,
		okText: i18Get("Yes", Util.getLanguage()),
		cancelText: i18Get("No", Util.getLanguage()),
		onOk() {
			if (onSuccess) {
				onSuccess();
			}
		},
		onCancel() {
			if (cancelCall) {
				cancelCall();
			}
		}
	});
}

function showConfirmCustomButton(title, content, onSuccess, cancelCall, okText, cancelText) {
	confirm({
		title,
		content,
		okText,
		cancelText,
		onOk() {
			if (onSuccess) {
				onSuccess();
			}
		},
		onCancel() {
			if (cancelCall) {
				cancelCall();
			}
		}
	});
}

export default {
	showDeleteConfirm,
	showLogout,
	showConfirm,
	showConfirmCustomButton
};
