import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../utils/utils";
import elementsAction from "../../../../../../actions/elements";
import "./medicaldevice.css";
import ElementsTable from "./elements-table";
import ElementsList from "./elements-list";
import events from "../../../../../../actions/events";

class ListAllElements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			category: "STANDARD",
			doctorId: null,
			status: "ALL",
			hospital: ""
		};
	}

	componentWillUnmount() {
		this.state.list = [];
		this.state.category = "STANDARD",
		clearTimeout(this.timer);
	}

	UNSAFE_componentWillMount() {
		this.state.doctorId = utils.checkForParameter("doctorId", this.props.location);
	}

	onFetchData() {
		this.props.dispatch(elementsAction.get(
			this.props.type,
			this.props.match.params.id,
			this.state.status,
			this.state.hospital ? this.state.hospital.value : ""
		));
	}

	refreshCompleted() {
		this.onFetchData();
	}

	clearState() {
		const prevState = this.state;
		prevState.list = [];
		prevState.type = this.props.type;
		prevState.category = "STANDARD",
		prevState.status = "ALL",
		prevState.hospital = "",
		this.setState(prevState);
		clearTimeout(this.timer);
	}

	onAddElements() {
		this.onFetchData();
	}

	onStatusChange(e) {
		this.state.status = e;
		this.setState(this.state);
		this.onFetchData();
	}

	hospitalChanged(e) {
		this.state.hospital = e;
		this.setState(this.state);
		this.onFetchData();
	}

	render() {
		if (this.state.type !== this.props.type) {
			this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: this.props.screenId });
			this.state.type = this.props.type;
			this.onFetchData();
			this.clearState();
		}

		return (
			<div className="">
				<div className="padding-top-15  di w_100 float_left">
					<ElementsList
						type={this.state.type}
						status={this.state.status}
						hospital={this.state.hospital}
						onAdd={this.onAddElements.bind(this)}
						onStatusChange={this.onStatusChange.bind(this)}
						hospitalChanged={this.hospitalChanged.bind(this)}
						doctorId={this.state.doctorId}
					/>
				</div>
				<ElementsTable
					doctorId={this.state.doctorId}
					refreshCompleted={this.refreshCompleted.bind(this)}
					type={this.state.type}
					hideColumn={this.props.hideColumn}
				/>
			</div>
		);
	}
}

ListAllElements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(ListAllElements));
