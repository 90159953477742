
export default function apiHeaders() {
	let headers = { headers: {} };
	const key = "USER_DETAILS";
	let userDetails = localStorage.getItem(key);
	if (userDetails) {
		userDetails = JSON.parse(userDetails);
		headers = { headers: { Authorization: `bearer ${userDetails.data.jwtToken}` } };
	}
	return headers;
}
