import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../actions/admin-clinical-pathway";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import CategoryPersonnelDropdown from "../../personnels/category-personnels/categoryPersonnelDropdown";

class EditItemDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			itemDetails: {}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwayItems(this.props.match.params.id));
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.saveClicked();
		}
	}

	itemChanged(value) {
		const prevState = this.state;
		prevState.invalidItem = false;
		prevState.itemDetails.item = value;
		this.setState(prevState);
	}

	categoryPersonnelNameChanged(e) {
		const prevState = this.state;
		if (e && e.length < 4) {
			prevState.itemDetails.categoryPersonnelName = e;
			prevState.invalidCategoryPersonnel = false;
			this.setState(prevState);
		}
	}

	selectedCategoryPersonnelIds() {
		const categoryPersonnelIds = [];
		this.state.itemDetails.categoryPersonnelName.map((data) => {
			categoryPersonnelIds.push(data.value);
		});
		return categoryPersonnelIds;
	}

	subStepChanged(e) {
		const prevState = this.state;
		prevState.invalidSubStep = false;
		prevState.itemDetails.subStep = e;
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.itemDetails.item || !this.state.itemDetails.item.trim() || (this.state.itemDetails.item.match(/\\$/)) || (this.state.itemDetails.item.indexOf("\"") >= 0)) {
			prevState.invalidItem = true;
		}
		if (this.state.itemDetails.categoryPersonnelName < 1) {
			prevState.invalidCategoryPersonnel = true;
		}
		if (!this.state.invalidItem && !this.state.invalidCategoryPersonnel) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				item: this.state.itemDetails.item.trim(),
				categoryPersonnelIds: this.selectedCategoryPersonnelIds()
			};
			this.props.dispatch(adminClinicalPathwayActions.updateClinicalPathwayItems(this.state.itemDetails.id, payload));
		}
	}

	render() {
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayItemsCompleted === "FULFILLED") {
			this.state.itemDetails = this.props.adminClinicalPathway.itemDetails;
			let data = {};
			this.state.itemDetails.categoryPersonnelName = [];
			this.state.itemDetails.categoryPersonnels && this.state.itemDetails.categoryPersonnels.map((value) => {
				data = {
					value: value.categoryPersonnelId,
					label: value.categoryPersonnelName
				};
				this.state.itemDetails.categoryPersonnelName.push(data);
			});
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.itemDetails.item, id: this.state.itemDetails.id } });
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayItems());
		}
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayItemsCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayItems());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}`);
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayItemsCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayItems());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwayItemsCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwayItems());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Item" /></label>
								<NameInput
									value={this.state.itemDetails.item}
									inputChanged={this.itemChanged.bind(this)}
									maxLength={255}
								/>
								{this.state.invalidItem ? <span className="invalid_right fixed_label"><I18 tkey='Enter valid item (Special characters " \ not allowed)' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Intervenant" /></label>
								<CategoryPersonnelDropdown
									data={this.state.itemDetails.categoryPersonnelName}
									categoryPersonnelChanged={this.categoryPersonnelNameChanged.bind(this)}
									clinicalPathway
									isAdminPage
								/>
								{this.state.invalidCategoryPersonnel ? <span className="invalid_right fixed_label"><I18 tkey="Select Intervenant" /></span> : ""}
							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									onClick={this.saveClicked.bind(this)}
									value={i18Get("SAVE", utils.getLanguage())}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

EditItemDetails.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(EditItemDetails));
