import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import AnaesthesiaList from "./anaesthesia";
import AnaesthesiaCreate from "./createAnaesthesia/create-anaesthesia";
import EditAnaesthesia from "./edit-anaesthesia";
import AnaesthesiaDetail from "./details";

class Anaesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {

		};
	}

	render() {
		return (
			<Switch>
				<Route exact path={locations.anaesthesia_local_create} component={AnaesthesiaCreate} />
				<Route exact path={locations.anaesthesia_mom_create} component={AnaesthesiaCreate} />
				<Route exact path={locations.anaesthesia_detail} component={AnaesthesiaDetail} />
				<Route path={locations.anaesthesia_edit} component={EditAnaesthesia} />
				<Route path={locations.anaesthesia} component={AnaesthesiaList} />
			</Switch>
		);
	}
}

Anaesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Anaesthesia));
