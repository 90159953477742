import React, { useEffect } from "react";

import DragM from "dragm";
import "antd/dist/antd.min.css";

function DragWindow(props) {
	let modalDom = null;
	useEffect(() => {
		modalDom = document.getElementsByClassName("ant-modal-wrap")[0];
	});

	const updateTransform = transformStr => { 
		modalDom.style.transform = transformStr;
	};

	return <DragM updateTransform={updateTransform}>
		<div>{props.title}</div>
	</DragM>;
}
export default DragWindow;