import eventNames from "./event";

const initialState = {
	healthPathWayTaskDetails: [],
    fetchAllHealthPathWayTasks: false,
	updateHealthPathwayTasksStatus:false
};

const filterData = (list) => {
	return list.filter(e => {if(e.title && e.title.trim() !== "") return e;}); 
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_HEALTH_PATHWAY_TASK_DETAILS}_FULFILLED`:
			return {
				...state,
				healthPathWayTaskDetails: filterData(action.payload.data.data),
				fetchAllHealthPathWayTasks: "FULFILLED"
			};
        case `${eventNames.GET_ALL_HEALTH_PATHWAY_TASK_DETAILS}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayTasks: "REJECTED"
			};
        case `${eventNames.CLEAR_GET_ALL_HEALTH_PATHWAY_TASK_DETAILS}`:
                return {
                    ...state,
                    healthPathWayTaskDetails: [],
                    fetchAllHealthPathWayTasks: false
                };
        case `${eventNames.UPDATE_HEALTH_PATHWAY_TASK_DETAILS}_FULFILLED`:
			    return { ...state, 
					updateHealthPathwayTasksStatus: "FULFILLED" };

		case `${eventNames.CLEAR_UPDATE_HEALTH_PATHWAY_TASK_DETAILS}`:
					return { ...state, updateHealthPathwayTasksStatus: false };
		default:
			return state;
	}
}