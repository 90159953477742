import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import { Modal, Button } from "antd";
import "../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import interventionAction from "../../../../../../../actions/intervention";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 1,
			defaultValue: "",
			invalidDefaultValue: false,
			details: {},
			defaultId: "",
			treatmentApproved: false,
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(interventionAction.getOneDefaultValue(this.props.defaultId));
	}

	cancelClicked() {
		this.setState({ modelVisible: false, invalidDefaultValue: false });
		this.props.refreshCompleted();
	}


	validateFields = () => {
		if (this.state.defaultValue) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.validateFields()) {
			payload = {
				defaultValue: this.state.defaultValue
			};
			this.props.dispatch(interventionAction.approveDefaultValue(this.state.details.id, payload));
		}
		else {
			this.setState({ invalidDefaultValue: true });
		}

	}

	defaultValueChanged(value) {
		const prevState = this.state;
		prevState.defaultValue = value;
		prevState.invalidDefaultValue = false;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.defaultId !== this.state.defaultId) {
			this.state.defaultId = this.props.defaultId;
			this.props.dispatch(interventionAction.getOneDefaultValue(this.props.defaultId));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		if (this.props.intervention.approveDefaultValueStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, defaultValue: "" });
			this.props.dispatch(interventionAction.clearApproveDefaultValue());
			this.props.refreshCompleted();
		}
		if (this.props.intervention.getOneDefaultValue === "FULFILLED") {
			this.state.details = this.props.intervention.defaultValue ? this.props.intervention.defaultValue : "";
			this.state.defaultValue = this.state.details.defaultValue ? this.state.details.defaultValue : "";
			this.state.isloading = true;
			this.state.treatmentApproved = this.state.details.treatmentApproved ? this.state.details.treatmentApproved : false;
			// this.state.familyApproved = this.state.interventionDetails.familyApproved ? this.state.interventionDetails.familyApproved : false;
			this.props.dispatch(interventionAction.clearGetOneDefaultValue());
		}
		if (this.props.intervention.getOneDefaultValue === "REJECTED") {
			this.props.dispatch(interventionAction.clearGetOneDefaultValue());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Approve Default Value", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
                            Cancel
						</Button>,
						this.state.treatmentApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
                                Approve
							</Button> : ""
					]}
				>
					<div className="re_assign_modal_head">
						<div className="row position_relative">
							<div className="col-md-3">
								<div className="form-group margin-bottom-0 pl-15">
									<label className="bold width-100"><I18 tkey="CODE" />:</label>
									<label className="width-100">{this.state.details.code}</label>
								</div>
							</div>
							<div className="col-md-9">
								<label className="bold width-100"><I18 tkey="NAME" />:</label>
								<label className="width-100">{this.state.details.name}</label>
							</div>
							<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.details.hospitalName ? this.state.details.hospitalName : ""}</p>
						</div>
						{/* {this.state.details.specialityName&&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="SPECIALITY NAME"/>:</span> {this.state.interventionDetails.specialityName ? this.state.interventionDetails.specialityName : ''}</p>
                           }
                            {this.state.details.familyName&&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="FAMILY NAME"/>:</span> {this.state.interventionDetails.familyName ? this.state.interventionDetails.familyName : ''}</p>
                            } */}
					</div>
					{this.state.treatmentApproved && this.state.isloading ?
						<div>
							<div className="row">
								<div className="col-md-4 pt-10 pr-0">
									<div className="form-group pl-20">
										<label className="bold" ><I18 tkey="DEFAULT VALUE" />:</label>
										<NameInput
											value={this.state.defaultValue}
											inputChanged={this.defaultValueChanged.bind(this)}
											maxLength={20}
										/>
										{this.state.invalidDefaultValue && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid default value" /></span>}
									</div>
								</div>
							</div>
						</div>
						:
						<div className="invalid_label padding-top-7">
							{this.state.isloading && !this.state.treatmentApproved ?
								<I18 tkey="The treatment for this default value is waiting for approval. Please approve the treatment first" />
								: ""
							}
						</div>
					}
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	intervention: store.intervention,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApproveModel));    