import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import familyActions from "../../../../../../../actions/family";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class FamilyForSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			family: [],
			offset: 0,
			numResults: 100,
			searchVal: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.setState({ ...this.state }, () => { this.fetchFamilyList(); });
	}

	fetchFamilyList(search) {
		if (this.state.specialityId && !this.state.fetchingData) {
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			this.setState({ ...this.state });
			const queryString = `offset=${this.state.offset}&numResults=${this.state.numResults}&typeActivity=${this.props.typeActivity}${this.state.specialityId ? (`&specialityId=${this.state.specialityId}`) : ""}&status=APPROVED${this.props.isCheckList ? "&extendedType=CHECKLIST" : ""}${search ? `&name=${search}` : ""}`;
			this.state.specialityId && this.props.dispatch(familyActions.fetchAllFamilyForSpeciality(queryString));
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.setState({ ...this.state }, () => { this.fetchFamilyList(search); });
				}, 800);
			}
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.family) {    
			this.state.family.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState) ;

		if (!this.props.data) {
			utils.setSingleDataDefault(prevState.options, this.props.onFamilyChanged, this.props.multiselect, this.props.setSingleDataDefault, this.props.autoSetSingleOne);
		}

	}

	render() {
		if (this.props.family.isFamilyForSpecialityFetchCompleted === "FULFILLED") {
			if (this.props.family.familySpeciality && this.props.family.familySpeciality[0] && (this.state.specialityId !== this.props.family.familySpeciality[0].specialityId)) {
				this.state.family = [];
				this.state.offset = 0;
				this.state.options = [];
			}
			this.state.fetchingData = false;
			this.state.family = unionWith(this.state.family, this.props.family.familySpeciality, isEqual);
			this.setState({ ...this.state }, () => {
				this.renderOptions();
				this.props.dispatch(familyActions.clearFetchAllFamilyForSpeciality());   
			});
		}

		if ((this.props.specialityId !== this.state.specialityId) || (this.props.isCheckList !== this.state.isCheckList)) {
			this.state.specialityId = this.props.specialityId;
			this.state.isCheckList = this.props.isCheckList;
			this.state.family = [];
			this.state.offset = 0;
			this.state.options = [];
			this.state.fetchDataFromserver = true;
			this.setState({ ...this.state });
		}
		if (!this.props.family.isFamilyForSpecialityFetchCompleted && this.state.fetchDataFromserver) {
			this.fetchFamilyList();
		}
		return (
			<Select
				styles={customStyles}
				isSearchable
				className="w100"
				isDisabled={this.props.assignTeam && this.props.disableFamily}
				onChange={this.props.onFamilyChanged}
				isClearable
				value={this.props.data}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Family", utils.getLanguage())}
			/>
		);
	}
}

FamilyForSelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	family: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	family: store.family,
	translations: store.translations
}))(FamilyForSelect));
