import axios from "axios";
import Config from "../../../../../../../MOM-config";
import events from "./event";

const getAll = (query, meta) => ({
	type: events.GET_ALL_COMMUNITY_LIST,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/community${query}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_GET_ALL_COMMUNITY_LIST,
	payload: {}

});

export default {
	getAll,
	clearGetAll
};
