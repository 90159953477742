import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../utils/utils";
import I18, { i18Get } from "../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../plug/regex-name-search-input";
import clinicalPathwayAction from "../../../../../actions/clinical-pathway";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			name: "",
			invalidName: false,
			invalidClinicalPathway: false,
			selectedClp: "",
			clinicalPathwayDetails: {},
			clinicalPathwayId: "",
			specialityApproved: false,
			familyApproved: false,
			isloading: false,
			clinicalPathwayFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(clinicalPathwayAction.getOne(this.props.clinicalPathwayId, new Date().getTimezoneOffset()));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false, invalidFamily: false, clinicalPathwayFound: false, value: 1 });
		this.props.refreshCompleted();
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.clinicalPathwayFound = true;
			this.state.value = 2;
		}
	}

	onClinicalPathwayChange(e) {
		this.setState({ selectedClp: e ? e : "", invalidClinicalPathway: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.name.trim()) || (this.state.name.match(/\\$/)) || (this.state.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateClp = () => {
		if (this.state.selectedClp && this.state.clinicalPathwayFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.name.trim(),
					code: this.state.code
				};
				this.props.dispatch(clinicalPathwayAction.saveAndApproveClinicalPathway(this.state.clinicalPathwayDetails.id, payload));
			}
		}
		if (this.state.value === 2) {
			this.props.dispatch(clinicalPathwayAction.deleteExistingClp(this.state.clinicalPathwayDetails.id));
		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.clinicalPathwayId !== this.state.clinicalPathwayId) {
			this.state.clinicalPathwayId = this.props.clinicalPathwayId;
			this.props.dispatch(clinicalPathwayAction.getOne(this.props.clinicalPathwayId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.clinicalPathway.deleteExistingClpStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", clinicalPathwayFound: false, value: 1 });
			this.props.dispatch(clinicalPathwayAction.deleteExistingClpCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.clinicalPathway.saveAndApproveClinicalPathwayStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", clinicalPathwayFound: false, value: 1 });
			this.props.dispatch(clinicalPathwayAction.saveAndApproveClinicalPathwayCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.clinicalPathway.fetchOneClinicalPathway === "FULFILLED") {
			this.state.clinicalPathwayDetails = this.props.clinicalPathway.clinicalPathway ? this.props.clinicalPathway.clinicalPathway : "";
			this.state.name = this.state.clinicalPathwayDetails.name ? this.state.clinicalPathwayDetails.name : "";
			this.state.code = this.state.clinicalPathwayDetails.code ? this.state.clinicalPathwayDetails.code : "";
			this.state.isloading = true;
			// this.state.selectedClp = { label: this.state.clinicalPathwayDetails.name ? this.state.clinicalPathwayDetails.name : '', value: this.state.clinicalPathwayDetails.id }; 
			this.state.specialityApproved = this.state.clinicalPathwayDetails.specialityApproved ? this.state.clinicalPathwayDetails.specialityApproved : false;
			this.state.familyApproved = this.state.clinicalPathwayDetails.familyApproved ? this.state.clinicalPathwayDetails.familyApproved : false;
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
		}
		if (this.props.clinicalPathway.fetchOneClinicalPathway === "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.cleargetOne());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Clinical Pathway", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>,
						this.state.specialityApproved && this.state.familyApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve' />
							</Button> : ""
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.clinicalPathwayDetails.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.clinicalPathwayDetails.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.clinicalPathwayDetails.momHospitalName ? this.state.clinicalPathwayDetails.momHospitalName : ""}</p>
							</div>
							{this.state.clinicalPathwayDetails.specialityName &&
                                <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="SPECIALITY NAME" />:</span> {this.state.clinicalPathwayDetails.specialityName ? this.state.clinicalPathwayDetails.specialityName : ""}</p>
							}
							{this.state.clinicalPathwayDetails.familyName &&
                                <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="FAMILY NAME" />:</span> {this.state.clinicalPathwayDetails.familyName ? this.state.clinicalPathwayDetails.familyName : ""}</p>
							}
						</div>
						{this.state.specialityApproved && this.state.familyApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											<NameInput
												value={this.state.code}
												disabled={true}
												maxLength={20}
											/>
											{this.state.invalidCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid clinical pathway code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.name}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={128}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid clinical pathway name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}></Radio><span className='clp_delete_label'><I18 tkey="Delete Clinical Pathway" /></span>
									</div>
									{/* <div className="col-sm-12 pt-10">
                                        <div className="form-group pl-20">
                                    <ClinicalPathwaySelect defaultValue="Select Clinical Pathway" disabled={this.state.value != 2} style={{ width: '100%' }}
                                        data={this.state.selectedClp}
                                        statusCheck={this.statusCheck.bind(this)}
                                        disabled={this.state.value!=2}
                                        clinicalPathwayType={this.props.clinicalPathwayType}
                                        onClinicalPathwayChange={this.onClinicalPathwayChange.bind(this)}/>
                                    {this.state.invalidClinicalPathway && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select clinical pathway" /></span>}
                                </div>
                                    </div> */}
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.specialityApproved ?
									<I18 tkey="The speciality for this clinical pathway is waiting for approval. Please approve the speciality first" />
									: this.state.isloading && !this.state.familyApproved ? <I18 tkey="The family for this clinical pathway is waiting for approval. Please approve the family first" /> : ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	clinicalPathway: store.clinicalPathway,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApproveModel));    