import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import interventionActions from "../../../../../actions/intervention";
import TextElement from "./text-element";
import Anaesthesia from "./anaesthesia";
import Staff from "./staff";
import MedicalDevices from "./elements";
import "./elements.css";
import typeActivity from "../../../../../constants/typeActivity.json";
import screenId from "../../../../../router/screenIdList";

class Elements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			intervention: "Intervention Details"
		};
	}

	UNSAFE_componentWillMount() {
		const payload = {
			organization: this.props.match.params.organization,
			id: this.props.match.params.id,
			organizationMode: "BASIC",
			showAnaesthesia: false,
			showStaff: false,
			cost: false

		};
		this.props.dispatch(interventionActions.getOneIntervention(payload.id, new Date().getTimezoneOffset()));
	}

	render() {
		if (this.props.intervention.getOneIntervention === "FULFILLED") {
			this.state.intervention = this.props.intervention.treatment.name;
			this.props.dispatch(interventionActions.clearGetOneIntervention());
		}

		return (
			<div className="main_overview_section">
				<Switch>
					{/* <Route path={locations.elements+locations.risks}  render={()=><TextElement type='risk' intervention={this.state.intervention} screenId={screenId.analyticalEditRisk}/>} /> */}
					<Route path={locations.elements + locations.staff} render={() => <Staff typeActivity={typeActivity.INTERVENTION.toUpperCase()} details={this.state.intervention} />} />
					<Route path={locations.elements + locations.anaesthesia_plain} render={() => <Anaesthesia type="anaesthesia" intervention={this.state.intervention} typeActivity={typeActivity.INTERVENTION.toUpperCase()} screenId={screenId.analyticalEditAnaesthesia} />} />
					<Route path={locations.elements + locations.medicaldevice} render={() => <MedicalDevices type="MEDICAL_DEVICE" details={this.state.intervention} screenId={screenId.analyticalEditMedicalDevice} EditScreenId={screenId.analyticalEditMedicalDeviceEdit} />} />
					<Route path={locations.elements + locations.pharmacy} render={() => <MedicalDevices type="PHARMACY" details={this.state.intervention} screenId={screenId.analyticalEditPharmacy} EditScreenId={screenId.analyticalEditPharmacyEdit} />} />
					<Route path={locations.elements + locations.prosthesis} render={() => <MedicalDevices type="PROSTHESIS" details={this.state.intervention} screenId={screenId.analyticalEditProsthesis} EditScreenId={screenId.analyticalEditProsthesisEdit} />} />
					<Route path={locations.elements + locations.sterilization} render={() => <MedicalDevices type="STERILIZATION" details={this.state.intervention} screenId={screenId.analyticalEditSterilization} EditScreenId={screenId.analyticalEditSterilizationEdit} />} />
					<Route path={locations.elements + locations.preparation} render={() => <TextElement type="preparation" intervention={this.state.intervention} screenId={screenId.analyticalEditPreparation} />} />
					<Redirect to={locations.elements + locations.risks} />
				</Switch>
			</div>
		);
	}
}

Elements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	intervention: store.intervention,
	translations: store.translations
}))(Elements));
