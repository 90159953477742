import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router";
import { Breadcrumb } from "antd";
import PropTypes from "prop-types";
import I18 from "../../../../../i18";

class BreadCrumb extends Component {
	constructor(props) {
		super(props);
	}

	reRoute(link) {
		return () => {
			this.props.history.push(this.props.useSearch ? link + this.props.location.search : link);
		};
	}

	renderItems(length) {
		return this.props.items.map((item, index) => {
			if (item.name) {
				return <Breadcrumb.Item key={index}><a className={(length - 1 !== index) ? "enabled" : ""} onClick={this.reRoute(item.link).bind(this)}>{item.name}</a></Breadcrumb.Item>;
			}
		});
	}

	render() {
		const { length } = this.props.items;
		if (this.props.items.length) {
			return (
				<div className={this.props.className ? `pole_breadcrumb with_records ${this.props.className}` : "pole_breadcrumb with_records"}>
					<Breadcrumb>
						{this.renderItems(length)}
					</Breadcrumb>
					{this.props.showRecord
						&& (
							<div className="record_container w_100 text_align_right">
								<div className="di number_of_records">
									<span>
										<I18 tkey="Number of Records" />
										:
										<b>{this.props.totalRecords ? this.props.totalRecords : 0}</b>
									</span>
								</div>
							</div>
						)}
				</div>
			);
		}
		return null;
	}
}

BreadCrumb.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(BreadCrumb));
