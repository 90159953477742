function contextMenuSettings () {
	return  [
		{
			id: "duplicate",
			text: "Duplicate"
		  },
		{
			text: "Delete",
			id: "delete"
		},
		{
			text: "Align",
			id: "Align",
			items: [
				{
					text: "Left",
					id: "left"
				},
				{
					text: "Center",
					id: "Center"
				},
				{
					text: "Right",
					id: "right"
				},
				{
					text: "Top",
					id: "Top"
				},
				{
					text: "Bottom",
					id: "Bottom"
				},
				{
					text: "Middle",
					id: "Middle"
				},
				{
					text: "Width",
					id: "Width"
				},
				{
					text: "Height",
					id: "Height"
				}
			]
		}
	];
}

export default contextMenuSettings();