import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Button } from "antd";
import utils from "../../../../../utils/utils";
import I18, { i18Get } from "../../../../../i18";
import "../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../plug/regex-name-search-input";
import anaesthesiaAction from "../../../../../actions/anaesthesia";

class AnaesthesiaEditModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anaesthesiaName: "",
			invalidName: false,
			anaesthesiaDetails: {},
			treatmentId: "",
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(anaesthesiaAction.getOneAnaesthesia(this.props.treatmentId, new Date().getTimezoneOffset()));
	}

	cancelClicked() {
		this.setState({
			modelVisible: false, invalidName: false, invalidCode: false, invalidFamily: false
		});
		this.props.refreshCompleted();
	}

	validateFields() {
		const prevState = this.state;
		if (!(this.state.anaesthesiaName.trim())) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		let payload = {};
		if (this.validateFields()) {
			payload = {
				name: this.state.anaesthesiaName
			};
			this.props.dispatch(anaesthesiaAction.updateAnaesthesia(payload, this.state.anaesthesiaDetails));
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.anaesthesiaName = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.treatmentId !== this.state.treatmentId) {
			this.state.treatmentId = this.props.treatmentId;
			this.props.dispatch(anaesthesiaAction.getOneAnaesthesia(this.props.treatmentId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		
		if (this.props.anaesthesia.isUpdateAnaesthesiaComplete === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, anaesthesiaName: "" });
			this.props.dispatch(anaesthesiaAction.clearUpdateAnaesthesia());
			this.props.refreshCompleted();
		}
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "FULFILLED") {
			this.state.anaesthesiaDetails = this.props.anaesthesia.selectedAnaesthesia ? this.props.anaesthesia.selectedAnaesthesia : "";
			this.state.anaesthesiaName = this.state.anaesthesiaDetails.name ? this.state.anaesthesiaDetails.name : "";
			this.props.dispatch(anaesthesiaAction.clearGetOneAnaesthesia());
		}
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "REJECTED") {
			this.props.dispatch(anaesthesiaAction.clearGetOneAnaesthesia());
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Anaesthesia", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
              Cancel
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
              Save
						</Button>
					]}
				>
					<div className="position_relative">
						<div className="form-group ">
							<label className="bold">
								<I18 tkey="Rename Anaesthesia" />
                :
							</label>
							<NameInput
								value={this.state.anaesthesiaName}
								inputChanged={this.nameChanged.bind(this)}
								maxLength={540}
							/>
							{this.state.invalidName && <span className="invalid text-danger"><I18 tkey="Enter valid anaesthesia name" /></span>}
						</div>
					</div>
				</Modal>

			</div>
		);
	}
}

AnaesthesiaEditModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations,
	anaesthesia: store.anaesthesia
}))(AnaesthesiaEditModel));
