// export default {
//     apiRoot: 'http://localhost:3002/api/v1',
//     apiRootSecure: 'http://localhost:3002/api/v1/secure',
//     env: 'dev',
//     imageUrl: 'http://localhost:3002/uploads/',
//     currency: '€',
//     defaultLocaleCurrencyCode: 'en-IN',
//     frenchLocaleCurrencyCode: 'fr-FR',
//     profileUrl: 'http://localhost:3002/uploads/dp/',
//     logUrl: 'http://localhost:3002/importationLogs/',
//     scannerUrl: '/scanner/#/',
//     siteUrl: 'http://localhost:3002/#',
//     isMasterServer: true,
//     hospitalName: 'Hopital Name'
// };


// export default {
//     apiRoot: 'http://192.168.2.75:3002/api/v1',
//     apiRootSecure: 'http://192.168.2.75:3002/api/v1/secure',
//     env: 'dev',
//     imageUrl: 'http://192.168.2.75:3002/uploads/',
//     currency: '€',
//     defaultLocaleCurrencyCode: 'en-IN',
//     frenchLocaleCurrencyCode: 'fr-FR',
//     profileUrl: 'http://192.168.2.75:3002/uploads/dp/',
//     logUrl: 'http://192.168.2.75:3002/importationLogs/',
//     scannerUrl: '/scanner/#/',
//     siteUrl: 'http://localhost:3002/#',
//     isMasterServer: false,
//     hospitalName: 'Hopital Name'
// };

export default {
	apiRoot: "https://v2labmaster.mompartners.fr/api/v1",
	apiRootSecure: "https://v2labmaster.mompartners.fr/api/v1/secure",
	env: "",
	imageUrl: "/uploads/",
	currency: "€",
	defaultLocaleCurrencyCode: "en-IN",
	frenchLocaleCurrencyCode: "fr-FR",
	profileUrl: "/uploads/dp/",
	logUrl: "/importationLogs/",
	scannerUrl: "https://v2labmaster.mompartners.fr/scanner/#/",
	hospital_ext: "v2lab",
	appTitle: "Master-Staging",
	siteUrl: "https://v2labmaster.mompartners.fr/#",
	isMasterServer: true
};

/* Staging V2 */
// export default {
// 	apiRoot: "/api/v1",
// 	apiRootSecure: "/api/v1/secure",
// 	env: "",
// 	imageUrl: "/uploads/",
// 	currency: "€",
// 	defaultLocaleCurrencyCode: "en-IN",
// 	frenchLocaleCurrencyCode: "fr-FR",
// 	profileUrl: "/uploads/dp/",
// 	logUrl: "/importationLogs/",
// 	scannerUrl: "https://v2labmaster.mompartners.fr/scanner/#/",
// 	hospital_ext: "v2lab",
// 	appTitle: "Master-Staging",
// 	siteUrl: "https://v2labmaster.mompartners.fr/#",
// 	isMasterServer: true
// };

/* Production V3 */
// export default {
// 	apiRoot: "/api/v1",
// 	apiRootSecure: "/api/v1/secure",
// 	env: "",
// 	imageUrl: "/uploads/",
// 	currency: "€",
// 	defaultLocaleCurrencyCode: "en-IN",
// 	frenchLocaleCurrencyCode: "fr-FR",
// 	profileUrl: "/uploads/dp/",
// 	logUrl: "/importationLogs/",
// 	scannerUrl: "https://masterv3.mompartners.fr/scanner/#/",
// 	hospital_ext: "MasterV3",
// 	appTitle: "MasterV3",
// 	siteUrl: "https://masterv3.mompartners.fr/#",
// 	isMasterServer: true,
// 	hospitalName: "MasterV3",
// 	issueLoggerCreateApi: "/api/v1/secure/issues/create"
// };

/* Production */
// export default {
//     apiRoot: '/api/v1',
//     apiRootSecure: '/api/v1/secure',
//     env: '',
//     imageUrl: '/uploads/',
//     currency: '€',
//     defaultLocaleCurrencyCode: 'en-IN',
//     frenchLocaleCurrencyCode: 'fr-FR',
//     profileUrl: '/uploads/dp/',
//     logUrl: '/importationLogs/',
//     scannerUrl: 'https://master.mompartners.fr/scanner/#/',
//     hospital_ext: 'Master',
//     appTitle: "Master",
//     siteUrl: 'https://master.mompartners.fr/#',
//     isMasterServer: true,
//     hospitalName: 'Master',
//     issueLoggerCreateApi: '/api/v1/secure/issues/create'
// };