import React, { Component } from "react";
import { Select } from "antd";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import locations from "../../../../../../../router/locations";

const { Option } = Select;

class StatusDropdown extends Component {
	constructor(props) {
		super(props);
	}

	optionsStatus() {
		const newOptions = ["All", "Approved", "Waiting For Approval"];
		// let statusIndex = this.props.intervention.status ? newOptions.indexOf(this.props.intervention.status) : -1;
		return newOptions.map((option, index) => <Option key={index} value={option.toUpperCase().replace(/ /g, "_")}><I18 tkey={option} /></Option>);
	}

	render() {
		return (
			(locations.scannedTreatments !== `/${this.props.match.params.sheets}`)
				? (
					<div className="">
						<div className="w_100 di position_relative">
							<label className="common_label"><I18 tkey="Status" /></label>
							<Select
								allowClear
								className="w100"
								placeholder={i18Get("Select status", utils.getLanguage())}
								notFoundContent={i18Get("Not Found", utils.getLanguage())}
								optionFilterProp="children"
								onChange={this.props.onStatusChange}
								value={this.props.status ? i18Get(this.props.status, utils.getLanguage()) : utils.sameDropper()}
							>
								{this.optionsStatus()}
							</Select>
							{this.props.invalidStatus && <span className="invalid fixed_label"><I18 tkey="Select a status" /></span>}
						</div>
					</div>
				)
				: ""
		);
	}
}

export default withRouter(StatusDropdown);
