import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../../../../utils/utils";
import assignTreatmentActions from "../../../../../../../../../../../actions/assign-to-treatments";
import Loader from "../../../../../../../../../../../plug/loader";
import ReactTable from "../../../../../../../../../../../plug/reactTable/react-table";
import column from "./column";
import { Checkbox } from "antd";

class AssignToTreatmentsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			treatments: [],
			totalPages: 1,
			loading: false,
			search: "",
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			sort: "",
			desc: 0,
			filter: {},
			checkedAll: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}` : "";
		queryString += this.state.pageSize ? `&numResults=${this.state.pageSize}` : "";
		queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += this.state.filter.specialityId && this.state.filter.specialityId.length ? `&specialityIds=${this.getSpecialityParams()}` : "";
		queryString += this.state.filter.familyId ? `&familyId=${this.state.filter.familyId}` : "";
		queryString += this.state.filter.unassigned ? `&unAssigned=${this.state.filter.unassigned}` : "";
		queryString += this.state.organization ? `&organization=${this.state.organization}` : "";
		queryString += this.state.filter.search ? `&search=${this.state.filter.search}` : "";
		if (this.state.organization) {
			this.props.dispatch(assignTreatmentActions.fetchAllTreatmentsForTypeActivity(this.props.typeActivity, this.props.categoryPersonnelTemplateId, queryString));
		}
	}

	getSpecialityParams() {
		let params = "";
		this.state.filter.specialityId.map(speciality => {
			params += params ? "," + speciality.value : speciality.value;
		});
		return params;
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.treatments = [];
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	renderOnFilterChange(filter) {
		const prevState = this.state;
		prevState.filter = filter;
		prevState.loading = true;
		prevState.page = 1;
		prevState.treatments = [];
		this.setState(prevState, this.fetchDataFromServer);
	}

	onCellCheckBoxChange = (e, treatment) => {
		if (!e.target.checked) {
			this.setState({ ...this.state, checkedAll: e.target.checked });
		}
		this.props.checkListChanged(treatment.id);
	};

	renderCheckbox(treatment) {
		return (
			<Checkbox
				checked={this.props.checkList.indexOf(treatment.id) !== -1}
				onChange={(e) => this.onCellCheckBoxChange(e, treatment) }/>
		);
	}

	onHeaderCheckBoxChange = ({ target: { checked } }) => {
		if (checked) {
			const itemNotInCheckList = this.state.treatments.filter(row => !this.props.checkList.includes(row.id));
			itemNotInCheckList.forEach(row => this.props.checkListChanged(row.id));
		} else {
			this.props.clearCheckList();
		}
		this.setState({ ...this.state, checkedAll: checked });
	};

	clearCheckAll() {
		this.setState({ checkedAll: false });
	}

	renderHeaderCheckBox = () => { 
		return <Checkbox indeterminate={!this.state.checkedAll && (this.props.checkList.length > 0 && this.props.checkList.length < this.state.treatments.length)} 
			checked={this.state.checkedAll || (this.props.checkList.length === this.state.treatments.length && this.props.checkList.length > 0)} onChange={this.onHeaderCheckBoxChange.bind(this)}></Checkbox>;
	}; 

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.treatments, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => {return (<Loader loading={this.state.loading} isTable/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), 
					column((e) => this.renderCheckbox(e), this.renderHeaderCheckBox.bind(this) ), 
					true, false, 
					this.onPageChange.bind(this), "TREATMENTS", "Assign_template_treatment_table_fcell", this.onPageSizeChange.bind(this))}/>
		);
	}

	render() {
		if (this.props.assignToTreatments.fetchAllTreatmentsCompleted === "FULFILLED") {
			this.state.loading = false;
			this.state.treatments = this.props.assignToTreatments.treatmentList.data;
			this.state.totalPages = Math.ceil(this.props.assignToTreatments.treatmentList.count / this.state.pageSize);
			this.props.dispatch(assignTreatmentActions.clearFetchAllTreatmentsForTypeActivity());
		}
		if (this.props.assignToTreatments.fetchAllTreatmentsCompleted === "REJECTED") {
			this.state.loading = false;
			this.props.dispatch(assignTreatmentActions.clearFetchAllTreatmentsForTypeActivity());
		}

		if (this.props.organization !== this.state.organization) {
			this.state.organization = this.props.organization;
			this.state.treatments = [];
			this.state.loading = true;
			this.state.page = 1;
			this.state.totalPages = 1;
			this.fetchDataFromServer();
		}

		return (
			<div>
				{this.getList()}
			</div>
		);
	}
}

AssignToTreatmentsList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	assignToTreatments: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	assignToTreatments: store.assignToTreatments
}))(AssignToTreatmentsList));    