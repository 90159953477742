import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox } from "antd";
import I18 from "../../../../../../../../../i18";

class BgColor extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			totalCount: 0,
			file: "",
			loading: false
		};
	}

	getStyle(color, index) {
		if (index === 0) {
			var colorOne = color;
		}
		if (index === 1) {
			var colorTwo = color;
		}
		if (index === 2) {
			var colorThree = color;
		}

		return (
			<style>
				{ `
                        .text_button.eye svg path {
                            fill: ${color};
                            stroke: ${color};
                        }
                        .profile_input_eye svg path {
                            fill: ${color};
                            stroke: ${color};
                        }       
                        .color_check {
                            color:${color}
                        } 
                        
                        .color_check1 .ant-checkbox-checked .ant-checkbox-inner {
                            border-color:${colorOne};
                            background-color:${colorOne};
                        }  
                        .color_check1 .ant-checkbox-checked:after {
                            border-color:${colorOne};
                        }
                        .color_check1 .ant-checkbox-checked::after {
                            border-color:${colorOne};
                        }
                        .color_check1.ant-checkbox-wrapper:hover .ant-checkbox-inner, .color_check1 .ant-checkbox:hover .ant-checkbox-inner, .color_check1 .ant-checkbox-input:focus + .color_check1 .ant-checkbox-inner {
                            border-color:${colorOne};
                        }

                        .color_check2 .ant-checkbox-checked .ant-checkbox-inner {
                            border-color:${colorTwo};
                            background-color:${colorTwo};
                        }  
                        .color_check2 .ant-checkbox-checked:after {
                            border-color:${colorTwo};
                        }
                        .color_check2 .ant-checkbox-checked::after {
                            border-color:${colorTwo};
                        }
                        .color_check2.ant-checkbox-wrapper:hover .ant-checkbox-inner, .color_check2 .ant-checkbox:hover .ant-checkbox-inner, .color_check2 .ant-checkbox-input:focus + .color_check2 .ant-checkbox-inner {
                            border-color:${colorTwo};
                        }

                        .color_check3 .ant-checkbox-checked .ant-checkbox-inner {
                            border-color:${colorThree};
                            background-color:${colorThree};
                        }  
                        .color_check3 .ant-checkbox-checked:after {
                            border-color:${colorThree};
                        }
                        .color_check3 .ant-checkbox-checked::after {
                            border-color:${colorThree};
                        }
                        .color_check3.ant-checkbox-wrapper:hover .ant-checkbox-inner, .color_check3 .ant-checkbox:hover .ant-checkbox-inner, .color_check3 .ant-checkbox-input:focus + .color_check3 .ant-checkbox-inner {
                            border-color:${colorThree};
                        }
                    `}
			</style>
		);
	}

	render() {
		return (
			<div key="items_center" className="d-flex align-items-center">
				{this.props.bgColor && this.props.bgColor.length && this.props.bgColor.length > 0 ? this.props.bgColor.map((data, index) => (
					<div key="item_wrapper" className="margin-left-4 margin-right-4">
						{this.getStyle(data.color, index)}
						<Checkbox
							key="items_center_check"
							className={`color_check${index + 1}`}
							checked={this.props.value === data.id}
							onChange={(e) => this.props.onColorChange(e, data)}
						/>
						<span className="margin-left-5">
							<I18 tkey={data.name} />
						</span>

					</div>
				)) : ""}

			</div>
		);
	}
}

BgColor.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	elements: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	extClinicalPathway: store.extClinicalPathway,
	translations: store.translations
}))(BgColor));
