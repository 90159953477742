import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const userLogin = (payload) => ({
	type: events.USER_LOGIN,
	payload: axios.put(`${Config.apiRoot}/login/`, { ...payload })
});

const userLogout = () => ({
	type: events.USER_LOGOUT,
	payload: {} /* axios.delete(`${Config.apiRootSecure}/logout`), */
});

const setLoginData = (data) => ({
	type: events.SET_LOGIN_DATA,
	payload: data
});

const clearLoginMinimal = () => ({
	type: events.CLEAR_USER_LOGIN_MINIMAL,
	payload: {}
});

const clearLogin = () => ({
	type: events.CLEAR_USER_LOGIN,
	payload: {}
});

const getLoginInfo = (email) => ({
	type: events.LOGIN_LIMIT_INFO,
	payload: axios.get(`${Config.apiRoot}/loginInfo/${email}`)
});

const clearLoginInfo = () => ({
	type: events.LOGIN_LIMIT_INFO_CLEAR
});

export default {
	userLogin,
	setLoginData,
	clearLogin,
	userLogout,
	clearLoginMinimal,
	getLoginInfo,
	clearLoginInfo
};
