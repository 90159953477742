import { connect } from "react-redux";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../utils/utils";
import eventNames from "../../../../../../../actions/events";
import { Modal, Spin } from "antd";
import Config from "../../../../../../../MOM-config";
import SelectComponets from "./selection-modal";
import DragWindow from "../drag-window";
import { LoadingOutlined } from "@ant-design/icons";

class ExportSelectedComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			genericComponents: {},
			clinicalPathway: {},
			activeComponents: [],
			selectAll: true,
			checkList: [],
			componentsForSelection: {},
			stepLoader: false,
			checkAll: false,
			invalidComponents: false,
			loading: false,
			code: null
		};
		this.timer = null;
	}

	UNSAFE_componentWillMount() {
		this.state.clinicalPathway = this.props.clinicalPathwayData;
		this.state.showModel = this.props.exportSelectedComponent;
		if (localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST")) {
			const selectedColumns = JSON.parse(localStorage.getItem("CLINICAL_PATHWAY_COLUMN_LIST"));
			if (selectedColumns && selectedColumns.check) {
				const columns = [];
				Object.keys(selectedColumns.check).forEach(function (key) {
					if (selectedColumns.check[key] === true) {
						columns.push(key);
					}
				});
				this.state.showColumns = columns;
			}
		}
		this.fetchList();
	}

	okText = () => {
		return !this.state.loading ? <I18 tkey="Export now" /> : <Fragment><I18 tkey="Exporting" /><Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 24 }} spin />} /></Fragment>;
	};

	fetchList() {
		this.setState({ stepLoader: true });
		this.props.dispatch(clinicalPathwayDetailsAction.fetchCopyFromClinicalPathwayList(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id));
	}

	toggleModal = () => {
		this.setState({ showModel: false, loading: false });
		this.props.closeSelectedComponentExport();
	};

	onCheckBoxChange(state) {
		this.setState(state);
	}

	onChangeCheckAll(state) {
		this.setState(state);
	}

	getSelectedComponents(state) {
		this.setState(state);
	}
	title = () => {return (<div className="position_relative"><DragWindow title={i18Get("Select components to export", utils.getLanguage())} /></div>);};
	render() {
		return (<div>
			<Fragment>
				<Modal
					visible={this.state.showModel}
					maskClosable={false}
					className="cost_modal re_assign_modal"
					title={this.title()}
					onCancel={this.toggleModal}
					okText={this.okText()}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					wrapClassName="drag_modal"
					onOk={this.doneClicked.bind(this)}>
					<SelectComponets clinicalPathwayType = {this.props.clinicalPathwayData.clinicalPathway} invalidComponents={this.state.invalidComponents} onCheckBoxChange={this.onCheckBoxChange.bind(this)} onChangeCheckAll={this.onChangeCheckAll.bind(this)} getSelectedComponents={this.getSelectedComponents.bind(this)}/>
				</Modal>
			</Fragment>
		</div>);
	}

	getFilters = () => {
		const filters = {};
		if (this.props.search) {
			filters.search = this.props.search;
		}
		if (this.props.searchField) {
			filters.searchField = this.props.searchField;
		}
		if (this.props.searchType) {
			filters.searchType = this.props.searchType;
		}
		if (this.props.clpStepFilter) {
			filters.clpStepFilter = this.props.clpStepFilter;
		}
		filters.locale = `${utils.getLanguage()}`;
		filters.exportType = "EXCEL";
		filters.showColumns = this.state.showColumns;
		filters.timezone = new Date().getTimezoneOffset();
		filters.organization = this.props.match.params.organization;
		return filters;
	};


	checkActiveComponents() {
		const checkedDataList = this.state.checkList.filter(value => value.checked === true);
		if ((checkedDataList && checkedDataList.length) || (Object.values(this.state.genericComponents).includes(!""))) {
			this.state.invalidComponents = false;
		} else {
			this.state.invalidComponents = true;
		}
		if (this.state.invalidComponents) {
			this.setState(this.state);
			return false;
		}
		return true;
	}

	doneClicked() {
		if (this.checkActiveComponents()) {
			this.setState({ loading: true });
			const filter = this.getFilters();
			const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
			const list = checkedDataList.filter(value => value.checked === true);
			list.map(function (e) {
				if (e.checked) {
					delete e["checked"];
				}
				e.subSteps = e.subSteps.filter(value => value.checked === true);
				e.subSteps.map(function (data) {
					if (data.checked) {
						delete data["checked"];
					}
				});
			});
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				components: list,
				genericComponents: this.state.genericComponents,
				filter,
				isSelected: true

			};
			this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReport(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, payload));
		}
	}

	isReportCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while exporting clinical pathway", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatus(this.state.code));
			}.bind(this), 3000);
		}
	}

	componentDidUpdate() {
		if (this.props.clinicalPathwayDetails.clinicalPathwayReportExportStatus == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReportCompleted());
			if (this.props.clinicalPathwayDetails.clinicalPathwayReport && this.props.clinicalPathwayDetails.clinicalPathwayReport.code) {
				this.setState({ code: this.props.clinicalPathwayDetails.clinicalPathwayReport.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportExportStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.exportClinicalPathwayReportCompleted());
			this.setState({ loading: false });
		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportStatus == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatusCompleted());
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayDetails.clinicalPathwayReportStage && this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status) {
				if (this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status == "PROCESSED") {
					const url = Config.apiRoot.replace("/api/v1", "");
					// window.open(`${url}${this.props.intervention.clinicalPathwayReportStage.destination}`);
					this.props.clinicalPathwayDetails.clinicalPathwayReportStage.path ? document.location.href = `${url}${this.props.clinicalPathwayDetails.clinicalPathwayReportStage.path}` : "";
					this.toggleModal();
				} else if (!this.isReportCompleted(this.props.clinicalPathwayDetails.clinicalPathwayReportStage.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}

		}

		if (this.props.clinicalPathwayDetails.clinicalPathwayReportStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.checkClinicalPathwayReportStatusCompleted());
			this.setState({ loading: false });
		}

	}
}

ExportSelectedComponent.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(ExportSelectedComponent));