import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAllSettings = () => ({
	type: events.GET_ALL_SETTINGS,
	payload: axios.get(`${Config.apiRoot}/appSettings`)
});

const clearGetAllSettings = () => ({
	type: events.CLEAR_GET_ALL_SETTINGS,
	payload: {}
});

const updateSettings = (payload) => ({
	type: events.UPDATE_SETTINGS,
	payload: axios.put(`${Config.apiRootSecure}/appSettings`, [...payload])
});

const clearUpdateSettings = () => ({
	type: events.CLEAR_UPDATE_SETTINGS,
	payload: {}
});

export default {
	getAllSettings,
	clearGetAllSettings,
	updateSettings,
	clearUpdateSettings
};
