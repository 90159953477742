import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import anaesthesiaAction from "../../../../../../actions/anaesthesia";
import locations from "../../../../../../router/locations";
import Staff from "../../intervention/details/v4/staff";
import MedicalDevices from "./medical-devices";
import Pharmacy from "./Pharmacy";
import utils from "../../../../../../utils/utils";
import componentsForSelection from "../../../../../../constants/componentsForSelection.json";
import interventionDetailsAction from "../../../../../../actions/intervention-details";
import eventNames from "../../../../../../actions/events";
import screenId from "../../../../../../router/screenIdList";
import StatusDropDown from "../../intervention/statusDropDown";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import AssignAndApprovePopup from "./assign-approve-popup";
import NumericInput from "../../../../../../plug/numeric-input";
import BreadCrumb from "../../breadcrumb";

class AnaesthesiaDetail  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPrice: true,
			totalPages: 1,  
			sorted: [],
			copyFromAnaesthesia: false,
			anaesthesia: {},
			key: 0,
			showSelectionModal: false,
			selectionModalExportType: "",
			componentsForSelection: {},
			invalidReAssignMOM: {},
			newMomdata: "",
			assignComplete: false,
			status: "ALL",
			hospital: "",
			showAssignAndApprove: false,
			selElementId: null,
			type: null,
			name: null,
			code: null,
			exType: null
		};
	}
    
	generateItems() {
		const items = [];       
		items.push({ 
			name: i18Get("Anaesthesia", utils.getLanguage()), 
			link: `${locations.anaesthesia_plain}/${this.props.match.params.organization}` });
		if (this.state.anaesthesia && this.state.anaesthesia.name) {
			items.push({ name: this.state.anaesthesia.name, link: this.props.location.pathname });
		}
		return items;
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: screenId.anaesthesiaDetails });
		this.state.componentsForSelection = JSON.parse(JSON.stringify(componentsForSelection[componentsForSelection.contents.ANAESTHESIA]));
		this.fetchOneAnaesthesia();
	}

	fetchOneAnaesthesia() {
		this.props.dispatch(anaesthesiaAction.getOneAnaesthesia(this.props.match.params.id, new Date().getTimezoneOffset()));
	}

	getFilters() {
		return (    
			<div className="row margin-top-10 margin-bottom-10">
				<div className="col-md-4">
					<span className="min_width_status label_name">
						<I18 tkey="Hospital" />
					</span>
					<HospitalSelect
						data={this.state.hospital}
						hospitalChanged={this.hospitalChanged.bind(this)} 
					/>
				</div>
				<div className="col-md-4 col-sm-4 ">
					<div className=" w_100 ">
						<span className="min_width_fmly   label_name">
							<I18 tkey="Status" />
						</span>
						<StatusDropDown 
							data={this.state.status} 
							onStatusChange={this.statusChanged.bind(this)}
						/> 
					</div> 
				</div>
			</div>      
		);
	}
	statusChanged(e) {
		this.state.status = e;
		this.setState(this.state);
	}

	hospitalChanged(e) {
		this.state.hospital = e;
		this.setState(this.state);
	}
	editClicked(link) {
		this.props.history.push(this.props.location.pathname + "/" + link );
	}
    
	onAssignAndApproveClick = (elementId, exType, name, code, type) => {
		this.setState({ showAssignAndApprove: true, selElementId: elementId, exType: exType, name: name, code: code, type: type });
	};

	closeAssignAndApprovePopup = () => {
		this.setState({ showAssignAndApprove: false });
	};

	refreshAnaesthesiaDetails = (refresh, type) => {
		if (refresh && type === "MEDICAL_DEVICE") {
			this.props.dispatch(interventionDetailsAction.getMedicalDevices(type, this.props.match.params.id, this.state.sort, this.state.desc, this.state.status, this.state.hospital ? this.state.hospital.value : ""));
			this.fetchOneAnaesthesia();
		}
		if (refresh && type === "PHARMACY") {
			this.props.dispatch(interventionDetailsAction.getPharmacy(type, this.props.match.params.id, this.state.sort, this.state.desc, this.state.status,  this.state.hospital ? this.state.hospital.value : ""));
			this.fetchOneAnaesthesia();
		}
	};

	render() {
		//let costDetails = {};
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "FULFILLED") {
			this.state.anaesthesia = this.props.anaesthesia.selectedAnaesthesia;
			// costDetails = this.state.anaesthesia.costDetails;
		}
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "REJECTED") {            
			this.props.dispatch(anaesthesiaAction.clearGetOneAnaesthesia());
			this.props.history.push(locations.anaesthesia_plain + "/" + this.props.match.params.organization);                   
		}
		this.state.hasPrintAccess = this.props.scope.userScope && ((this.props.scope.userScope["PRINT"] & this.props.user.loginData.scope) === this.props.user.loginData.scope);
		return (
			<div className="main_overview_section intervention_details"> 
				<div className="position_relative w_100 di">    
					<div className="eye_button_section w_100 padding-top-15">
						<div className="clinical_pathway_breadcrumb">
							<BreadCrumb items={this.generateItems()} useSearch/>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className='margin-bottom-10 w_100 ellipsis'> 
									<span className='intervention_name'>{this.state.anaesthesia && this.state.anaesthesia.name ? this.state.anaesthesia.name : ""}</span>
									<span className='intervention_speciality_name'>{this.state.anaesthesia && this.state.anaesthesia.specialityName && this.state.anaesthesia.familyName ? ("(" + this.state.anaesthesia.specialityName + ", " + this.state.anaesthesia.familyName + ")") : ""}</span>
									{((this.state.anaesthesia) && (this.state.anaesthesia.userName || this.state.anaesthesia.updatedUser)) && <div><I18 tkey='Modified By' />: {this.state.anaesthesia.updatedUser ? this.state.anaesthesia.updatedUser : (this.state.anaesthesia.userName ? this.state.anaesthesia.userName : "")} </div>}
									<div className="row  align-items-center d-flex margin-bottom-5">
										<div className="col-md-6 padding-left-0">
											<I18 tkey='Anaesthesia Code' />: {this.state.anaesthesia && this.state.anaesthesia.code} 
										</div>
										<div className="col-md-6 form_control_update justify-content-end align-items-center d-flex common_input">
											<label className="common_label width_85 margin-bottom-0"><I18 tkey='Updated At:' /></label>
											<NumericInput className=""  value={this.state.anaesthesia && this.state.anaesthesia.updatedAt ? this.state.anaesthesia.updatedAt : ""} disabled={true} />
										</div>
									</div>            
								</div>    
							</div>     
						</div>
					</div>
					<div className='w_100'>
						{this.getFilters()}
					</div>
					<div className="">
						<div className="table_section di w_100">
							<Staff type='STAFF' key={this.state.key} status={this.state.status} hospitalId={this.state.hospital ? this.state.hospital.value : "" } isAnaesthesia={true}/> 
						</div>
					</div> 
					<div className="">
						<div className="text_align_right di w_100   margin-bottom-40">
							<button className="common_button pumpkin" onClick={this.editClicked.bind(this, "staff")}><I18 tkey='ADD / EDIT' /></button>
						</div>
					</div> 
					<div className="">
						<div className="table_section di w_100">
							<MedicalDevices type='MEDICAL_DEVICE' onAssignAndApproveClick={this.onAssignAndApproveClick} status={this.state.status} hospitalId={this.state.hospital ? this.state.hospital.value : "" } key={this.state.key}/> 
						</div>
					</div>
					<div className="">
						<div className="text_align_right di w_100   margin-bottom-40">
							<button className="common_button pumpkin" onClick={this.editClicked.bind(this, "medical_device")}><I18 tkey='ADD / EDIT' /></button>
						</div>
					</div>
					<div className="">
						<div className="table_section di w_100">
							<Pharmacy type='Pharmacy' onAssignAndApproveClick={this.onAssignAndApproveClick} status={this.state.status} hospitalId={this.state.hospital ? this.state.hospital.value : ""} key={this.state.key}/> 
						</div>
					</div>
					<div className="">
						<div className="text_align_right di w_100   margin-bottom-40">
							<button className="common_button pumpkin" onClick={this.editClicked.bind(this, "pharmacy")}><I18 tkey='ADD / EDIT' /></button>
						</div>
					</div>
				</div>
				{this.state.showAssignAndApprove &&
                <AssignAndApprovePopup elementId={this.state.selElementId} type={this.state.type} exType={this.state.exType} name={this.state.name} code={this.state.code}  showAssignAndApprove={this.state.showAssignAndApprove} closeAssignAndApprovePopup={this.closeAssignAndApprovePopup} refreshAnaesthesiaDetails={this.refreshAnaesthesiaDetails} isDetailsPage ={true}/> 
				}
			</div>
		);
	}
	formatCount(value) {
		return utils.setLocaleForCurrency(value ? value : 0.00);
	}
}

AnaesthesiaDetail.propTypes = {
	user: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	scope: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	user: store.user,
	intervention: store.intervention,
	anaesthesia: store.anaesthesia,
	settings: store.settings,
	scope: store.scope,
	translations: store.translations
}))(AnaesthesiaDetail));