import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";

export default function getCols(typeActivity) {
	const tableArray = ([
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "personnelName",
			accessor: (d) => d.personnelName,
			resizable: false,
			Cell: (row) => (
				row.value
			)
		},
		{
			Header: i18Get("NUMBER", utils.getLanguage()),
			id: "numberOfPersonnels",
			accessor: (d) => d.numberOfPersonnels,
			resizable: false,
			Cell: (row) => (
				utils.setLocaleForCurrency(row.value ? row.value : 0.00)
			)
		},
		{
			Header: i18Get("DURATION", utils.getLanguage()),
			id: "duration",
			accessor: (d) => d.duration,
			resizable: true,
			show: typeActivity !== "ANAESTHESIA",
			Cell: (row) => (
				row.value ? row.value : 0
			)
		}
	]);
	return tableArray;
}
