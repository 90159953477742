import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllFamilyCompleted: false,
	familyList: [],
	isFetchOneFamilyCompleted: false,
	selectedFamily: {},
	isUpdateFamilyCompleted: false,
	updatedFamily: {},
	isCreateFamilyCompleted: false,
	createdFamily: {},
	isDeleteFamilyCompleted: false,
	isDeleteSuccess: {},
	typeActivity: "",
	isCopyFamilyComplete: false,
	isFetchAllCopyListComplete: false,
	copyList: []
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_FAMILY}_PENDING`:
			return {
				...state,
				isFetchAllFamilyCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllFamilyCompleted: "FULFILLED",
					familyList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_FAMILY}_REJECTED`:
			return {
				...state,
				isFetchAllFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_FAMILY:
			return {
				...state,
				isFetchAllFamilyCompleted: false
			};
		case `${eventNames.FETCH_ONE_FAMILY}_PENDING`:
			return {
				...state,
				isFetchOneFamilyCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ONE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneFamilyCompleted: "FULFILLED",
					selectedFamily: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_FAMILY}_REJECTED`:
			return {
				...state,
				isFetchOneFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_FAMILY:
			return {
				...state,
				isFetchOneFamilyCompleted: false
			};
		case `${eventNames.UPDATE_FAMILY}_PENDING`:
			return {
				...state,
				isUpdateFamilyCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateFamilyCompleted: "FULFILLED",
					updatedFamily: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_FAMILY}_REJECTED`:
			return {
				...state,
				isUpdateFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_FAMILY:
			return {
				...state,
				isUpdateFamilyCompleted: false
			};
		case `${eventNames.CREATE_FAMILY}_PENDING`:
			return {
				...state,
				isCreateFamilyCompleted: "PENDING"
			};
		case `${eventNames.CREATE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateFamilyCompleted: "FULFILLED",
					createdFamily: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_FAMILY}_REJECTED`:
			return {
				...state,
				isCreateFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_FAMILY:
			return {
				...state,
				isCreateFamilyCompleted: false
			};

		case `${eventNames.DELETE_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteFamilyCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteFamilyCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_FAMILY}_REJECTED`:
			return {
				...state,
				isDeleteFamilyCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_FAMILY:
			return {
				...state,
				isDeleteFamilyCompleted: false
			};

		case `${eventNames.FETCH_ALL_FAMILY_FOR_SPECIALITY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFamilyForSpecialityFetchCompleted: "FULFILLED",
					familySpeciality: action.payload.data.data.family
				};
			}
			return {
				...state,
				isFamilyForSpecialityFetchCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_FAMILY_FOR_SPECIALITY}_REJECTED`:
			return {
				...state,
				isFamilyForSpecialityFetchCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_FAMILY_FOR_SPECIALITY:
			return {
				...state,
				isFamilyForSpecialityFetchCompleted: false,
				familySpeciality: []

			};
		case `${eventNames.COPY_MOM_FAMILY_TO_LOCAL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCopyFamilyComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isCopyFamilyComplete: "FULFILLED"
			};
		case `${eventNames.COPY_MOM_FAMILY_TO_LOCAL}_REJECTED`:
			return {
				...state,
				isCopyFamilyComplete: "REJECTED"
			};
		case eventNames.CLEAR_COPY_MOM_FAMILY_TO_LOCAL:
			return {
				...state,
				isCopyFamilyComplete: false
			};
		case `${eventNames.FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					copyList: action.payload.data.data,
					isFetchAllCopyListComplete: "FULFILLED"
				};
			}
			return {
				...state,
				isFetchAllCopyListComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY}_REJECTED`:
			return {
				...state,
				isFetchAllCopyListComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY:
			return {
				...state,
				isFetchAllCopyListComplete: false
			};
		case `${eventNames.RE_ASSIGN_FAMILY}_FULFILLED`:
			return { ...state, reAssignStatus: "FULFILLED" };
		case `${eventNames.RE_ASSIGN_FAMILY}_REJECTED`:
			return { ...state, reAssignStatus: "REJECTED" };
		case eventNames.RE_ASSIGN_FAMILY_COMPLETED:
			return { ...state, reAssignStatus: false };
		default:
			return state;
	}
}
