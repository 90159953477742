import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import extClinicalPathwayAction from "../../../../../../../../../actions/ext-clinical-pathway";
import events from "../../../../../../../../../actions/events";
import { Button, Modal } from "antd";
import NameInput from "../../../../../../../../../plug/regex-name-search-input";
import ActionButton from "../../../../../../../../../plug/action-button";

class SubStepEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subStepDetails: {},
			totalCount: 0,
			extType: "",
			type: "",
			listNumber: 100,
			listOffset: 0,
			isLoading: false,
			search: "",
			invalidSubStep: false
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchSubStepData();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	fetchSubStepData() {
		this.setState({ isLoading: true });
		const queryString = this.props.selectedSubStepForEdit.day ? `days=${this.props.selectedSubStepForEdit.day}` : "";
		this.props.dispatch(extClinicalPathwayAction.fetchSubStepDetails(this.props.selectedSubStepForEdit.subStepNameId, queryString));
	}


	SubStepChanged(e) {
		const prevState = this.state;
		prevState.subStepDetails.name = e;
		prevState.invalidSubStep = false;
		this.setState(prevState);
	}


	validateSubStepName = () => {
		const prevState = this.state;
		if (this.state.subStepDetails.name ? !this.state.subStepDetails.name.trim() || (this.state.subStepDetails.name.match(/\\$/)) || (this.state.subStepDetails.name.indexOf("\"") >= 0) : true) {
			prevState.invalidSubStep = true;
		}
		this.setState(prevState);
		if (!this.state.invalidSubStep) {
			return true;
		}
		return false;
	};

	saveClicked = () => {
		if (this.validateSubStepName()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.subStepDetails.name ? this.state.subStepDetails.name.trim() : ""
			};
			this.props.dispatch(extClinicalPathwayAction.updateSubStepName(this.state.subStepDetails.id, payload));
		}
	};

	render() {
		if (this.props.extClinicalPathway.fetchSubStepDetails === "FULFILLED") {
			this.props.dispatch(extClinicalPathwayAction.clearFetchSubStepDetails());
			this.state.subStepDetails = this.props.extClinicalPathway.subStepDetails;
			this.state.isLoading = false;
		}

		if (this.props.extClinicalPathway.isSubStepNameUpdateComplete === "FULFILLED") {
			const updatedSubStepName = this.props.extClinicalPathway.updatedSubStepName ? this.props.extClinicalPathway.updatedSubStepName : "";
			this.props.dispatch(extClinicalPathwayAction.clearUpdateSubStepName());
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.refreshDetails(this.props.selectedSubStepForEdit.id, this.props.selectedSubStepForEdit.day, updatedSubStepName);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit SubStep Name", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.props.cancelClicked}
					footer={[
						<div key ='1' className='d-flex align-items-center justify-content-end common_cancel_btn_container'>
							<button key="Cancel" type="primary" className="ant-btn float_none_imp height_common_btn margin-right-10" onClick={this.props.cancelClicked}>{i18Get("Cancel", utils.getLanguage())}</button>
							<ActionButton key="Save" type="primary" onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
						</div>
					]}
				>
					<div className="position_relative">
						<div className="form-group ">
							<label className="bold" ><I18 tkey="SubStep Name" />:</label>
							<NameInput
								value={this.state.subStepDetails && this.state.subStepDetails.name ? this.state.subStepDetails.name : ""}
								inputChanged={this.SubStepChanged.bind(this)}
								maxLength={128}
							/>
							{this.state.invalidSubStep && <span className='invalid_day fixed_label'><I18 tkey='Enter Valid Name (Special characters " \ not allowed)' /></span>}
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

SubStepEdit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	extClinicalPathway: store.extClinicalPathway,
	actions: store.actions,
	translations: store.translations
}))(SubStepEdit));