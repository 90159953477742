import eventNames from "./event";

const initialState = {
	healthPathWayLinkedItems: [],
    fetchAllHealthPathWayLinkedItems: false,
	commonSearch: "",
	hpTab: "",
	takeDuplicateStatus: false,
	duplicate: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_LINKED_ITEMS_LIST}_FULFILLED`:
			return {
				...state,
				healthPathWayLinkedItems: action.payload.data.data,
				fetchAllHealthPathWayLinkedItems: "FULFILLED"
			};
        case `${eventNames.GET_LINKED_ITEMS_LIST}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayLinkedItems: "REJECTED"
			};
        case `${eventNames.CLEAR_GET_LINKED_ITEMS_LIST}`:
                return {
                    ...state,
                    healthPathWayLinkedItems: [],
                    fetchAllHealthPathWayLinkedItems: false
                };
		case `${eventNames.SET_COMMON_SEARCH}`:
			return {
				...state,
				commonSearch: action.payload
			};
		case `${eventNames.CLEAR_COMMON_SEARCH}`:
			return {
				...state,
				commonSearch: ""
			};
		case `${eventNames.HEALTHPATHWAYS_TAB}`:
			return {
				...state,
				hpTab: action.payload.tab
			};
		
		case `${eventNames.TAKE_HEALTH_PATHWAYS_DUPLICATE}_FULFILLED`:
			return {
				...state,
				duplicate: action.payload.data.data,
				takeDuplicateStatus: "FULFILLED"
			};
		case `${eventNames.TAKE_HEALTH_PATHWAYS_DUPLICATE}_REJECTED`:
			return {
				...state,
				takeDuplicateStatus: "REJECTED"
			};
		case `${eventNames.CLEAR_TAKE_HEALTH_PATHWAYS_DUPLICATE}`:
				return {
					...state,
					duplicate: [],
					takeDuplicateStatus: false
				};
		default:
			return state;
	}
}