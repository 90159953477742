import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	clinicalPathway: [],
	fetchAllClinicalPathway: false,
	updateClinicalPathway: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.INTERVENTION_FILTER}`:
			return {
				...state,
				filterData: action.payload.filter,
				ignorePageChange: action.payload.ignorePageChange
			};
		case `${eventNames.CLEAR_FILTER}`:
			return {
				...state,
				filterData: {}
			};

		case `${eventNames.CLINICAL_PATHWAY_GET_ALL}_FULFILLED`:
			return {
				...state,
				clinicalPathway: action.payload.data.data,
				clinicalPathwayListMeta: action.meta,
				fetchAllClinicalPathway: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_GET_ALL}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathway: "REJECTED"
			};

		case eventNames.CLEAR_CLINICAL_PATHWAY_GET_ALL:
			return {
				...state,
				clinicalPathway: [],
				fetchAllClinicalPathway: false
			};

		case `${eventNames.CLINICAL_PATHWAY_GET_ONE}_FULFILLED`:
			return {
				...state,
				clinicalPathway: action.payload.data.data,
				fetchOneClinicalPathway: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneClinicalPathway: "REJECTED"
			};
		case eventNames.CLEAR_CLINICAL_PATHWAY_GET_ONE:
			return {
				...state,
				fetchOneClinicalPathway: false
			};

		case `${eventNames.CLINICAL_PATHWAY_UPDATE}_FULFILLED`:
			return { ...state, editClinicalPathwayStatus: "FULFILLED" };
		case `${eventNames.CLINICAL_PATHWAY_UPDATE}_REJECTED`:
			return { ...state, editClinicalPathwayStatus: "REJECTED" };
		case eventNames.CLEAR_CLINICAL_PATHWAY_UPDATE:
			return { ...state, editClinicalPathwayStatus: false };

		case `${eventNames.CLINICAL_PATHWAY_CREATE}_FULFILLED`:
			return {
				...state,
				createdClinicalPathwayData: action.payload.data.data,
				createClinicalPathway: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_CREATE}_REJECTED`:
			return {
				...state,
				createClinicalPathway: "REJECTED"
			};
		case eventNames.CLEAR_CLINICAL_PATHWAY_CREATE:
			return {
				...state,
				createdClinicalPathwayData: [],
				createClinicalPathway: false
			};

		case `${eventNames.CLINICAL_PATHWAY_SUB_TREATMENT_GET}_FULFILLED`:
			return {
				...state,
				meta: action.meta,
				clinicalPathwaySubTreatment: action.payload.data.data,
				fetchAllClinicalPathwaySubTreatment: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_SUB_TREATMENT_GET}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwaySubTreatment: "REJECTED"
			};

		case eventNames.CLEAR_CLINICAL_PATHWAY_SUB_TREATMENT_GET:
			return {
				...state,
				clinicalPathwaySubTreatment: [],
				fetchAllClinicalPathwaySubTreatment: false
			};

		case `${eventNames.EDIT_INTERVENTION}_FULFILLED`:
			return { ...state, editInterventionStatus: "FULFILLED" };
		case `${eventNames.EDIT_INTERVENTION}_REJECTED`:
			return { ...state, editInterventionStatus: "REJECTED" };
		case eventNames.CLEAR_EDIT_INTERVENTION:
			return { ...state, editInterventionStatus: false };

		case `${eventNames.DELETE_CLINICAL_PATHWAY}_FULFILLED`:
			return { ...state, deleteClinicalPathwayStatus: "FULFILLED", notClpDeleteInfo: action.payload.data.data };
		case `${eventNames.DELETE_CLINICAL_PATHWAY}_REJECTED`:
			return { ...state, deleteClinicalPathwayStatus: "REJECTED", clinicalPathwayError: action.payload };
		case eventNames.DELETE_CLINICAL_PATHWAY_COMPLETED:
			return { ...state, deleteClinicalPathwayStatus: false };

		case `${eventNames.GET_INTERVENTION}_FULFILLED`:
			return {
				...state,
				intervention: action.payload.data.data,
				fetchAllIntervention: "FULFILLED"
			};
		case `${eventNames.GET_INTERVENTION}_REJECTED`:
			return {
				...state,
				fetchAllIntervention: "REJECTED"
			};

		case eventNames.CLEAR_GET_INTERVENTION:
			return {
				...state,
				intervention: [],
				fetchAllIntervention: false
			};

		case `${eventNames.CLP_GET_ONE}_FULFILLED`:
			return {
				...state,
				clinicalPathway: action.payload.data.data,
				fetchOneClpUsingHospitalId: "FULFILLED"
			};
		case `${eventNames.CLP_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneClpUsingHospitalId: "REJECTED"
			};
		case eventNames.CLEAR_CLP_GET_ONE:
			return {
				...state,
				fetchOneClpUsingHospitalId: false
			};

		case `${eventNames.GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY}_FULFILLED`:
			return {
				...state,
				meta: action.meta,
				clinicalPathwaySubTreatment: action.payload.data.data,
				fetchAllClpSubTreatmentForSelectedCopy: "FULFILLED"
			};
		case `${eventNames.GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY}_REJECTED`:
			return {
				...state,
				fetchAllClpSubTreatmentForSelectedCopy: "REJECTED"
			};

		case eventNames.CLEAR_GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY:
			return {
				...state,
				clinicalPathwaySubTreatment: [],
				fetchAllClpSubTreatmentForSelectedCopy: false
			};

		case `${eventNames.GET_CLINICAL_PATHWAY}_FULFILLED`:
			return {
				...state,
				clinicalPathwayList: action.payload.data.data,
				getClinicalPathwayStatus: "FULFILLED"
			};
		case `${eventNames.GET_CLINICAL_PATHWAY}_REJECTED`:
			return {
				...state,
				getClinicalPathwayStatus: "REJECTED"
			};

		case eventNames.GET_CLINICAL_PATHWAY_COMPLETED:
			return {
				...state,
				clinicalPathwayList: [],
				getClinicalPathwayStatus: false
			};

		case `${eventNames.SAVE_AND_APPROVE_CLINICAL_PATHWAY}_FULFILLED`:
			return { ...state, saveAndApproveClinicalPathwayStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_CLINICAL_PATHWAY}_REJECTED`:
			return { ...state, saveAndApproveClinicalPathwayStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_CLINICAL_PATHWAY_COMPLETED:
			return { ...state, saveAndApproveClinicalPathwayStatus: false };

		case `${eventNames.DELETE_CLP}_FULFILLED`:
			return { ...state, deleteExistingClpStatus: "FULFILLED" };
		case `${eventNames.DELETE_CLP}_REJECTED`:
			return { ...state, deleteExistingClpStatus: "REJECTED" };
		case eventNames.DELETE_CLP_COMPLETED:
			return { ...state, deleteExistingClpStatus: false };

		case `${eventNames.CLINICAL_PATHWAY_LIST_EXPORT}_FULFILLED`:
			return { ...state, clinicalPathwayListExportStatus: "FULFILLED", clinicalPathwayReportList: action.payload.data.data };
		case `${eventNames.CLINICAL_PATHWAY_LIST_EXPORT}_REJECTED`:
			return { ...state, clinicalPathwayListExportStatus: "REJECTED" };
		case eventNames.CLINICAL_PATHWAY_EXPORT_LIST_COMPLETED:
			return { ...state, clinicalPathwayListExportStatus: false };

		case `${eventNames.CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS}_FULFILLED`:
			return { ...state, clinicalPathwayReportExportStatus: "FULFILLED", clinicalPathwayReportStage: action.payload.data.data };
		case `${eventNames.CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS}_REJECTED`:
			return { ...state, clinicalPathwayReportExportStatus: "REJECTED" };
		case eventNames.CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS_COMPLETED:
			return { ...state, clinicalPathwayReportExportStatus: false };

		case `${eventNames.ATTACH_SELECTED_MODEL_WITH_CLP}_FULFILLED`:
			return { ...state, attachModelWithclinicalPathwayStatus: "FULFILLED", attachedModel: action.payload.data.data };
		case `${eventNames.ATTACH_SELECTED_MODEL_WITH_CLP}_REJECTED`:
			return { ...state, attachModelWithclinicalPathwayStatus: "REJECTED" };
		case eventNames.CLEAR_ATTACH_SELECTED_MODEL_WITH_CLP:
			return { ...state, attachModelWithclinicalPathwayStatus: false };

		case `${eventNames.CLINICAL_PATHWAY_GET_ALL_MODEL}_FULFILLED`:
			return {
				...state,
				clinicalPathwayModel: action.payload.data.data,
				clinicalPathwayModelMeta: action.meta,
				fetchAllClinicalPathwayModel: "FULFILLED"
			};
		case `${eventNames.CLINICAL_PATHWAY_GET_ALL_MODEL}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayModel: "REJECTED"
			};

		case eventNames.CLEAR_CLINICAL_PATHWAY_GET_ALL_MODEL:
			return {
				...state,
				clinicalPathwayModel: [],
				fetchAllClinicalPathwayModel: false
			};

		case `${eventNames.APPLY_MODEL_CHANGES_WITH_CLP}_FULFILLED`:
			return { ...state, clinicalPathwayModelChangesApply: "FULFILLED", modelChangesData: action.payload.data.data };
		case `${eventNames.APPLY_MODEL_CHANGES_WITH_CLP}_REJECTED`:
			return { ...state, clinicalPathwayModelChangesApply: "REJECTED", modelChangesData: "" };
		case eventNames.CLEAR_APPLY_MODEL_CHANGES_WITH_CLP:
			return { ...state, clinicalPathwayModelChangesApply: false, modelChangesData: "" };


		case `${eventNames.MODEL_OBSERVER_CLP}_FULFILLED`:
			return { ...state, modelChangesApplyStatus: "FULFILLED", modelStatus: action.payload.data.data, meta: action.meta };
		case `${eventNames.MODEL_OBSERVER_CLP}_REJECTED`:
			return { ...state, modelChangesApplyStatus: "REJECTED" };
		case eventNames.CLEAR_MODEL_OBSERVER_CLP:
			return { ...state, modelChangesApplyStatus: false };

		case `${eventNames.APPLY_MODEL_CHANGES_DETAILS}_FULFILLED`:
			return { ...state, modelChangeDtails: action.payload.data.data, applayModelChangesDetails: "FULFILLED" };
		case `${eventNames.APPLY_MODEL_CHANGES_DETAILS}_REJECTED`:
			return { ...state, modelChangeDtails: null, applayModelChangesDetails: "REJECTED" };
		case eventNames.CLEAR_APPLY_MODEL_CHANGES_DETAILS:
			return { ...state, modelChangeDtails: null, applayModelChangesDetails: false };

		case `${eventNames.GET_ALL_CLPS_FOR_COPY}_FULFILLED`:
			return {
				...state,
				clinicalPathway: action.payload.data.data,
				clinicalPathwayListMeta: action.meta,
				fetchAllClinicalPathwaysForCopy: "FULFILLED"
			};
		case `${eventNames.GET_ALL_CLPS_FOR_COPY}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwaysForCopy: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_CLPS_FOR_COPY:
			return {
				...state,
				clinicalPathway: [],
				fetchAllClinicalPathwaysForCopy: false
			};

		default:
			return state;
	}
}
