import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import ReactTable from "../../../../../../../plug/reactTable";
import interventionAction from "../../../../../../../actions/intervention";
import Loader from "../../../../../../../plug/loader";
import locations from "../../../../../../../router/locations";
import column from "./column";
import Config from "../../../../../../../MOM-config";
import { i18Get } from "../../../../../../../i18";
import NumericInput from "./numeric-input";
import { statusList } from "../../../../../../../constants";
import AssignAndApproveModel from "./assign-and-approve-model";

class DefaultValueList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			sort: "name",
			desc: 0,
			interventions: [],
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			firstLoad: true,
			filter: {},
			isRefreshing: false,
			refreshCode: null,
			costRefreshTime: null,
			editId: null,
			disableClick: false,
			modelVisible: false,
			defaultId: "",
			editData: {},
			invalidDefaultValue: false,
			selectedColumn: {}
		};
       
	}

	getRowScrollIndexFromParams = () => {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	};

	UNSAFE_componentWillMount() {
		this.state.organizationMode = this.props.organizationMode;
		const filter = utils.getFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}`);
		if (filter && (typeof filter === "object") && Object.keys(filter).length) {
			this.state.filter = filter;
			this.state.filter.selectedStatus = filter.selectedStatus ? filter.selectedStatus.toUpperCase() : "";
			this.state.filter.selectedHospital = filter.selectedHospital ? filter.selectedHospital.value : "";
			this.state.filter.selectedSpecialty = filter.selectedSpecialty ? filter.selectedSpecialty.value : "";
			this.state.filter.selectedFamily = filter.selectedFamily ? filter.selectedFamily.value : "";
		}
		this.state.typeActivity = this.props.typeActivity;
	}

	setLocalStorageForList = () => {
		const params = {
			page: this.state.page,
			pageSize: this.state.pageSize,
			sort: this.state.sort,
			desc: this.state.desc ? 1 : 0,
			rowScrollIndex: this.state.rowScrollIndex
		};
		utils.setFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}_LIST`, params);
	};

	onFetchData = (state) => {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	};

	fetchDataFromServer = () => {
		this.state.fetchAllMeta = Date.now();
		let queryParams = "?typeActivity=INTERVENTION";
		queryParams += `&offset=${((this.state.page - 1) * this.state.pageSize)}&numResults=${this.state.pageSize}&`;
		queryParams += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${this.state.desc ? `desc=${this.state.desc}&` : ""}`;
		queryParams += `${this.state.filter.selectedSpecialty ? `specialityId=${this.state.filter.selectedSpecialty}&` : ""}`;
		queryParams += `${this.state.filter.selectedFamily ? `familyId=${this.state.filter.selectedFamily}&` : ""}`;
		queryParams += `${this.state.filter.selectedStatus && (this.state.filter.selectedStatus !== "ALL") ? `status=${this.state.filter.selectedStatus}&` : ""}`;
		queryParams += `${this.state.filter.selectedHospital ? `hospitalId=${this.state.filter.selectedHospital}&` : ""}`;
		queryParams += `${this.state.filter && this.state.filter.search ? `name=${this.state.filter.search}&code=${this.state.filter.search}` : ""}`;
		this.setLocalStorageForList();
		this.props.dispatch(interventionAction.getTreatmentCostList(queryParams));
	};

	actions(row) {
		return (
			<div className="margin_left_auto">
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.WAITING_FOR_APPROVAL ?
							<a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							: ""
					}
				</div>
				{/* <div className="min_width_12_px">
            {
            row.value.status === statusList.STATUS.values.APPROVED ?
            <Icon className="delete_color" type="delete" onClick={()=>this.deleteClicked(row.value)}/>
            : ''
            }
            </div> */}
			</div>
		);
	}


	validateField() {
		return !(!this.defaultValue.getValue());
	}

	renderTotalDefaultCost(value) {
		return (
			(value.status === statusList.STATUS.values.APPROVED) ? 
				(value.id !== this.state.editId) ?
					<div className="text_align_right w_100">
						<a onClick={() => {this.quickEditClicked(value);}}>
							{value.defaultValue ? utils.setLocaleForCurrency(value.defaultValue, utils.getLanguage(), false) : 0}
						</a>
					</div>
					:
					<NumericInput 
						key="defaultValue"
						maxLength={9} 
						label="defaultValue" 
						className="defatul-cost-tbox"
						onBlur={this.saveClicked}
						value={this.state.editData && this.state.editData.defaultValue ? this.state.editData.defaultValue : "" } 
						onRef={ref => this.defaultValue = ref}
					/> 
            
				:  <div className="text_align_right w_100">{value.defaultValue ? utils.setLocaleForCurrency(value.defaultValue, utils.getLanguage(), false) : 0}</div>
		);
	}

	quickEditClicked = (value) => {
		if (value.treatmentApproved) {
			const prevState = this.state;
			prevState.editId = value.id;
			prevState.editData = value;
			this.setState(prevState);
		}
		else {
			utils.showError(i18Get("The treatment for this default value is waiting for approval. Please approve the treatment first", utils.getLanguage()));
		}
	};

	saveClicked = () => {
		if (this.validateField()) {
			this.setState({ disableClick: true, editData: { ...this.state.editData, defaultValue: this.defaultValue.getValue() ? this.defaultValue.getValue() : 0 } }, () => {
				const payload = {
					defaultValue: this.defaultValue.getValue() ? String(this.defaultValue.getValue()).replace(",", ".") : 0
				};
				this.props.dispatch(interventionAction.updateTotalDefaultCost(this.state.editData.treatmentId, payload));
    
			});
		} else {
			utils.showError(i18Get("Enter valid default cost", utils.getLanguage()));
		}
	};

	closeClicked() {
		const prevState = this.state;
		prevState.editId = null;
		prevState.editData = {};
		this.setState(prevState);
	}

	onPageChange = (pageIndex) => {
		this.state.page = pageIndex + 1;
		this.state.rowScrollIndex = 0;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	};

	onPageSizeChange = (pageSize) => {
		this.state.page = 1;
		this.state.pageSize = pageSize;
	};

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	approveClicked(treatment) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, defaultId: treatment.id });
		};
	}

	rowScroll = (row) => {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	};

	moveToDetails = (treatmentId) => {
		window.open(`${Config.siteUrl}/intervention/mom/timeSheets/${treatmentId}`, "_blank");
	};

	getList = () => {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.interventions, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData, column(this.actions.bind(this), this.renderTotalDefaultCost.bind(this), this.props.location.pathname, this.moveToDetails, this.state.selectedColumn), true, false,
					this.onPageChange, ((this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "SCANNED TREATMENTS" : "INTERVENTION"),
					`-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll ${(this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "scanned_treatments_table" : this.props.organization === locations.tabMom ? "intervention_table" : "table_head_element_hide"}`,
					this.onPageSizeChange)}
				getTrProps={(state, rowInfo) => {
					return {
						style: {
							background: rowInfo && rowInfo.index === this.state.activeCompany ? "#e8eaea7a" : ""
						}
					};
				}
				}
				defaultSorted={[
					{
						id: this.state.sort ? this.state.sort : "name",
						desc: this.state.desc ? true : false
					}
				]}
				rowScroll={this.rowScroll}
				rowScrollIndex={this.state.rowScrollIndex} />
		);
	};

	render() {

		if (this.props.intervention.selectedColumnDefaultValues) {
			this.state.selectedColumn = this.props.intervention.selectedColumnDefaultValues;
		}

		return (
			<div>
				{this.getList()}
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							refreshCompleted={this.refreshCompleted}
							defaultId={this.state.defaultId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>);
	}

	componentDidUpdate() {
		if (this.props.intervention.filterData && Object.keys(this.props.intervention.filterData).length) {
			if (!this.props.intervention.ignorePageChange) {
				this.state.page = 1;
			}
			this.state.filter = JSON.parse(JSON.stringify(this.props.intervention.filterData));
			this.props.dispatch(interventionAction.clearFilter());
			this.setState({ ...this.state }, () => {
				this.fetchDataFromServer();
			});

		}
		if (this.state.organization !== this.props.organization) {
			this.state.interventions = [];
			this.state.totalPages = 1;
			this.state.organization = this.props.organization;
			const filterData = utils.getFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}_LIST`);
			if (filterData && (typeof filterData === "object") && Object.keys(filterData).length) {
				this.state.page = filterData.page ? filterData.page : 1;
				this.state.pageSize = filterData.pageSize ? filterData.pageSize : utils.getDefaultPageSize();
				this.state.sort = filterData.sort ? filterData.sort : "name";
				this.state.desc = filterData.desc ? filterData.desc : 0;
				this.state.rowScrollIndex = filterData.rowScrollIndex ? filterData.rowScrollIndex : 0;
			}
			this.setState({ ...this.state });
		}
		if ((this.state.organizationMode !== this.props.organizationMode) || (this.state.typeActivity !== this.props.typeActivity)) {
			this.state.page = 1;
			this.state.interventions = [];
			this.state.typeActivity = this.props.typeActivity;
			this.state.loading = true;
			this.state.organizationMode = this.props.organizationMode;
			this.setState({ ...this.state }, () => {
				this.fetchDataFromServer();
			});

		}
		if (this.props.intervention.getTreatmentCostListStatus === "FULFILLED") {
			this.state.interventions = this.props.intervention.treatmentCostList.data;
			this.state.totalPages = parseInt(Math.ceil(this.props.intervention.treatmentCostList.count / this.state.pageSize));
			this.state.loading = false;
			this.props.totalCount(this.props.intervention.treatmentCostList.count);
			this.props.dispatch(interventionAction.getTreatmentCostListComplete());
		}
		if (this.props.intervention.updateTotalDefaultCostComplete === "FULFILLED") {
			this.state.disableClick = false;
			this.state.editId = null;
			this.state.editData = {};
			this.fetchDataFromServer();
			this.props.dispatch(interventionAction.clearUpdateTotalDefaultCost());
		}
		if (this.props.intervention.updateTotalDefaultCostComplete === "REJECTED") {
			this.state.disableClick = false;
			this.props.dispatch(interventionAction.clearUpdateTotalDefaultCost());
		}
		if (this.state.costRefreshTime !== this.props.costRefreshTime) {
			this.setState({ costRefreshTime: this.props.costRefreshTime }, () => {
				this.fetchDataFromServer();
			});
		}

	}



}

DefaultValueList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	intervention: store.intervention,
	translations: store.translations
}))(DefaultValueList)); 