import React, { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import I18 from "i18";
import { User, LocationDot, Clock, EditSummary } from "utils/icons";
import { Collapse, Spin } from "antd";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import healthPathwayAction from "actions/health-pathway";
import CollapsePannel from "./CollapsePannel";
import { withRouter } from "react-router";
const { Panel } = Collapse;
const sortOrder = ["BEFORE_ADMISSION", "CLINICAL_PATHWAY", "AFTER_CHECKOUT", "OTHERS" ];
const Summary = (props) => {
	const dispatch = useDispatch();
	const healthPathway = useSelector((state) => { return state.healthPathway;});
	const isDiagramUpdated = useSelector((state) => { return state.DiagramReducer.isDiagramUpdated;});
	const [nodesDetails, setNodeDetails] = useState([]);
	const [nodePayload, setNodePayload] = useState([]);
	const [activeKey, setActiveKey] = useState("");
	const [diagramData, setDiagramData] = useState([]);
	const [staticDiagram, setStaticDiagram] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect( () => {

			let data = props.diagramNodesData;
			const nodeData = [];
			const nodePayloaData = [];
			setNodeDetails([]);
			data = JSON.parse(data);
			data.nodes.forEach((element) => {
				if (element.addInfo) {
					const customNodeData = element.addInfo.customNodeData;
					if (customNodeData.intervention !== ""  && 
					((customNodeData.intervenantsIds && customNodeData.intervenantsIds.length > 0) || 
					customNodeData.interventionDetails || customNodeData.location || 
					customNodeData.durationOfTheTask || customNodeData.expectedResults || 
					customNodeData.argumentation || customNodeData.risks || 
					customNodeData.reference || customNodeData.linkType || customNodeData.phase || 
					customNodeData.problems || customNodeData.data)) {

						nodeData.push({ ...customNodeData, bgColor: element.style.fill, id: element.id, phase: customNodeData.phase || "OTHERS"});
						nodePayloaData.push({
							id: element.id,
							intervenants: customNodeData.intervenantsCode ? customNodeData.intervenantsCode : [], 
							step: customNodeData.stepsCode ? customNodeData.stepsCode : null,
							substep: customNodeData.subStepsCode ? customNodeData.subStepsCode : null,
							types: customNodeData.typeCode ? customNodeData.typeCode : [] 
						}) ;
					}
				}
			});
			setNodeDetails(reorderNodeData(nodeData));
			if (nodePayloaData !== nodePayload) {setNodePayload([...nodePayloaData]);}
		
	}, [props.diagramNodesData, isDiagramUpdated]);


	useEffect(() => {

		if (nodePayload && nodePayload.length > 0 || healthPathway.diagramDataSortUpdatedcount ) {

			if (props.staticDiagram) { setStaticDiagram(true);}
			getAdditionalInformation();
			
		}

	 }, [nodePayload, healthPathway.diagramDataSortUpdatedcount]);



	useEffect(() => {
		
		if (healthPathway.fetchAllDiagramAdditionalInfo === "FULFILLED") {
			setDiagramData(healthPathway.diagramAdditionalInfo);
			reOrderByServerData(healthPathway.diagramAdditionalInfo);
			setLoading(false);
			dispatch(healthPathwayAction.clearGetDiagramAdditionalInfo());
		}

		if (healthPathway.fetchAllDiagramAdditionalInfo === "REJECTED") {
			setLoading(false);
			dispatch(healthPathwayAction.clearGetDiagramAdditionalInfo());
		}
	});

	useEffect( () => { 
		if (props.currentNode) {
			setActiveKey(props.currentNode.id);
		}
	}, [props.currentNode] );

	useEffect( () => { 
		if (props.currentNodeId) {
			getNodePhase(props.currentNodeId);
		}
	}, [props.currentNodeId, nodesDetails] );


	const getAdditionalInformation = () => {
		if (nodePayload && nodePayload.length > 0) {
			setLoading(true);
			if (props.staticDiagram) { setStaticDiagram(true);}
			const queryString = `diagramData=${JSON.stringify(nodePayload)}&blockSuccess`;	//blockSuccess for remove unwanted success message
			dispatch(healthPathwayAction.getDiagramAdditionalInfo(props.match.params.id, queryString));
		}
	};

	const reOrderByServerData = (diagramData) => { 

		const staticData = [];
		if ( diagramData && diagramData.length > 0) {

			diagramData.map(function (data, i) {
				const value = nodesDetails.find(value => value?.id === data?.id);
				staticData[i] = value;
			});
			
		}
		setNodeDetails(staticData);

	};

	const reorderNodeData = (data) => {
		let sortData = [];
		sortOrder.forEach((a) => {
			sortData = [...sortData, ...(data.filter(e => e.phase == a ))];
		});
		return sortData;
	};
	
	const getNodePhase = (colapsId) => {
		if (colapsId) {

			props.autoSelectNode(colapsId);	
		}
		setActiveKey(colapsId);
	};

	const headers = (phase) => {
		phase = phase ? phase  : "OTHER" ;
		phase = phase === "CLINICAL_PATHWAY" ? "HOSPITALISATION" : phase.replace("_", " ");
		return phase;
	};

	const genExtra = () => (
		 props.tabSwitching ? <li data-active={props.tabSwitching === "Data"} onClick={ () => props.tabSwitcher("Data")}>
			<EditSummary width={20} height={15} />
		</li> : ""
	  );

	return (
		<div className="summary-block" id="summary-block-0">
			{ sortOrder.map( eachPhase => {
				const phaseData = nodesDetails.filter(e => e?.phase === eachPhase);
				if (phaseData.length === 0) { return "";}
				return <Collapse key={eachPhase} activeKey={[activeKey]} onChange={ (e) => { getNodePhase(e);}} accordion>
					<h5 className="header"><I18 tkey={headers(eachPhase)}/></h5>
					{phaseData.map((data, i) => {

						const getDiagramData = diagramData.find(e => e.id === data.id);

						return <Panel 
							header={<I18 tkey={data.intervention} />} 
							key={data.id} style={{ backgroundColor: data.bgColor }}
							extra={genExtra()} 
							id={`panel-${data.id}`}
						>
							{data ? <CollapsePannel
								getDiagramData={getDiagramData}
								data={data}
								index={i}
								currentNodeId={props.currentNodeId}
								loading={loading}
								staticDiagram={props.staticDiagram}
							/> : ""}
						</Panel>;
					})}
				</Collapse>;
			})}
		</div>
	);
};
export default withRouter(Summary);