import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import typeActivity from "../../../../../../../../constants/typeActivity.json";

const { Option } = Select;

class TypeActivityDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	renderTypeActivityOptions() {
		const options = [];
		for (const key in typeActivity) {
			if (this.props.exceptions && this.props.exceptions.length) {
				if (this.props.exceptions.indexOf(key) === -1) {
					options.push(<Option key={key} title={typeActivity[key]} value={key}>{i18Get(typeActivity[key], utils.getLanguage())}</Option>);
				}
			} else if (this.props.courseReference) {
				if ((key !== "ANAESTHESIA") && (key !== "BIOLOGY")) {
					options.push(<Option key={key} title={typeActivity[key]} value={key}>{i18Get(typeActivity[key], utils.getLanguage())}</Option>);
				}
			} else if (this.props.doctors) {
				options.push(<Option key={key} title={typeActivity[key]} value={key}>{i18Get(typeActivity[key], utils.getLanguage())}</Option>);
			} else {
				options.push(<Option key={key} title={typeActivity[key]} value={key}>{i18Get(typeActivity[key], utils.getLanguage())}</Option>);
			}
		}
		return options;
	}

	render() {
		return (
			<div className={this.props.className ? this.props.className : "mb_15_mob"}>
				<Select
					value={this.props.typeActivity}
					className="w_100 di"
					defaultActiveFirstOption
					onChange={this.props.typeActivitySelected}
					placeholder={i18Get("Select type activity", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
				>
					{this.renderTypeActivityOptions()}
				</Select>
			</div>
		);
	}
}

TypeActivityDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(TypeActivityDropdown));
