import axios from "axios";
import utils from "../utils/utils";
import { i18Get } from "../i18";
import eventNames from "../actions/events";

const setupInterceptors = (store) => {
	axios.interceptors.response.use((response) => handleResponse(response, store), (error) => handleResponse(error.response, store));
	axios.interceptors.request.use((config) => {
		const key = "USER_DETAILS";
		let userDetails = localStorage.getItem(key);
		if (userDetails) {
			userDetails = JSON.parse(userDetails);
			config.headers.common.Authorization = `bearer ${userDetails.data.jwtToken}`;
		}
		return config;
	}, (error) => Promise.reject(error));
};

export default setupInterceptors;

const handleResponse = (response, store) => {

	if ((response && response.status) && (response.status === 403 || response.status === 401)) {
		const userDetails = localStorage.getItem("USER_DETAILS");
		if (userDetails) {
			utils.showError(response.status === 401 ? i18Get("Session expired", utils.getLanguage()) : i18Get("Unauthorized access", utils.getLanguage()));
			localStorage.setItem("USER_DETAILS", "");
			localStorage.setItem("LOGOUT_LOCATION", window.location);
			window.location = "/";
		} else {
			window.location = "/";
		}
		return response;
	}
	
	if (!response || (response && response.status === 0 )) {
		store.dispatch({ type: eventNames.HTTP_REQUEST_LOST });
	} else {
		store.dispatch({ type: eventNames.HTTP_REQUEST_LOST_CLEAR });
	}

	if (!response || !response.data) {
		utils.showError(i18Get("Oops, Something went wrong.", utils.getLanguage()));
		store.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		return Promise.reject(response);
	}

	if ((!response.data.status) && (response.config.responseType === "blob") && (response.status === 400) && (response.data.type === "application/vnd.ms-excel")) {
		utils.showError(i18Get("No File Found", utils.getLanguage()));
		return Promise.reject(response.data);
	}

	if (response.data.type === "application/pdf" || response.data.type === "text/csv"
        || response.data.type === "application/vnd.ms-excel") {
		return response;
	}

	if ((!response.data.status) && (response.config.responseType === "blob") && (response.status === 400) && (response.data.type === "application/json")) {
		utils.showError(i18Get("No Data Found", utils.getLanguage()));
		return Promise.reject(response.data);
	}

	if (!response.data.status) {
		if (response.data.data && response.data.data.message) {
			utils.showError(i18Get(response.data.data.message, utils.getLanguage()));
		} else {
			utils.showError(i18Get(response.data.message, utils.getLanguage()));
			const key = "USER_LOGIN_ATTEMPT";
			const attempt = localStorage.getItem(key);
			localStorage.setItem(key, ( (attempt * 1) + 1));

			if (response.data.message === "Maximum login attempts exceeded" ) {
				store.dispatch({ type: eventNames.LOGIN_BLOCKED });
			} else {
				store.dispatch({ type: eventNames.LOGIN_BLOCKE_CLEAR });
			}
		}
		store.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		return Promise.reject(response.data);
	} if (response.data.status && (response.data.message_code === 1 || response.data.message_code === 6)) {
		if (response.config.method !== "get" && response.data && response.data.message && !response?.config?.data?.includes("blockSuccess")) {
			utils.showSuccess(i18Get(response.data.message, utils.getLanguage()));
		}
		return response;
	} if (response.data.message_code && response.data.message_code === 3) {
		response.data.data.forEach((validationFailure) => {
			const message = validationFailure.msg;
			utils.showError(i18Get(message, utils.getLanguage()));
		});
		return Promise.reject(response.data);
	} if (response.data.message_code && response.data.message_code === 5) {
		// Ignore silent response
		return response;
	} if (response.data.message_code && response.data.message_code !== 1) {
		const message = (response.data ? response.data.message : response.message);
		store.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		utils.showError(i18Get(message, utils.getLanguage()));
		return Promise.reject(response.data);
	}

	return response;
};
