import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import locations from "../../../../../router/locations";
import { DebounceInput } from "react-debounce-input";
import Family from "../administration/activities/activity-families/selectFamilyForSpeciality";
import Speciality from "../administration/activities/specialities/selectSpecialities";
import StatusDropDown from "../intervention/statusDropDown";
import SiteDropDown from "../clinical-pathway/createClinicalPathWay/site_dropdown";
import HospitalSelect from "../administration/activities/specialities/selectHospital";
import { statusList } from "../../../../../constants";
import { Modal, Icon } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import healthPathwayAction from "../../../../../actions/health-pathway";
import Loader from "../../../../../plug/loader";
import ReactTable from "../../../../../plug/reactTable";
import events from "../../../../../actions/events";
import screenId from "../../../../../router/screenIdList";
import column from "./column";
import HealthPathwayEditModel from "./health-pathway-edit-model";
import ClpTypeDropDown from "../clinical-pathway/clp-type-dropdown";
import { FilterOutlined, EditOutlined, DeleteOutlined, CopyOutlined } from "@ant-design/icons";
import ExportHealthpathwayList from "./export-healthpathway-list/index.js";
import NotDeleteInfo from "./not-delete-info";
import HealthPathwayDuplicateModel from "mom-ui-commons/build/components/health-pathway/healthPathwayDuplicate/healthPathwayDuplicateModel";

const HP_CURRENT_PAGE = "HP_CURRENT_PAGE";
class HealthPathWayList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showFilter: false,
			filter: "",
			organization: "mom",
			count: 0,
			desc: 1,
			sort: "updatedAt",
			status: "ALL",
			hospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			site: null,
			clinicalPathwayType: "ALL",
			editModel: false,
			modelVisible: false,
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			healthPathway: [],
			page: localStorage.getItem(HP_CURRENT_PAGE) || 1,
			pageSize: utils.getDefaultPageSize(),
			ignore: false,
			localStorageKey: "HEALTH_FILTER",
			isNotDeletable: false,
			isNotDeletableInfo: {},
			isModalOpen: false,
			isduplicate: false
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillUpdate() {
		if (this.state.organization !== this.props.match.params.organization) {
			document.getElementsByClassName("main_container_parent")[0].scrollTo(0, 0);
			this.state.organization = this.props.match.params.organization;
			this.state.speciality = false;
			this.state.family = false;
			this.state.healthPathwayCount = false;
			this.state.healthPathway = [];
		}
	}

	UNSAFE_componentWillMount() {
		const prevState = this.state;
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.anaesthesiaList });
		prevState.organization = this.props.match.params.organization;
		const storedValues = utils.getFilterInLocalStorage(this.state.localStorageKey);
		if (storedValues && (typeof storedValues === "object") && Object.keys(storedValues).length) {
			prevState.showFilter = storedValues.showFilter;
			prevState.clinicalPathwayType = storedValues.clinicalPathwayType;
			prevState.filterValue = storedValues.filterValue;
			prevState.hospital = storedValues.hospital;
			prevState.speciality = storedValues.speciality;
			prevState.family = storedValues.family;

		}
		else {
			this.setLocalStorageForFilter();
		}
		this.setState(prevState);
	}

	createClicked() {
		this.state.ignore = true;
		this.props.history.push(`${locations.health_pathway_plain}/${this.state.organization}${locations.create}`);
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter, speciality: "", family: "" }, () => {
			this.setLocalStorageForFilter();
		});
	}

	clearFilter() {
		const prevState = this.state;
		prevState.family = false;
		prevState.speciality = false;
		prevState.status = "ALL";
		prevState.hospital = { value: null, label: i18Get("All", utils.getLanguage()) };
		prevState.filterValue = "";
		prevState.clinicalPathwayType = "ALL";
		this.setState(prevState);
		this.fetchDataFromServer();
		this.setLocalStorageForFilter();
	}

	setLocalStorageForFilter() {
		const filters = {
			clinicalPathwayType: this.state.clinicalPathwayType,
			showFilter: this.state.showFilter,
			hospital: this.state.hospital,
			filterValue: this.state.filterValue,
			speciality: this.state.speciality,
			family: this.state.family
		};
		utils.setFilterInLocalStorage(this.state.localStorageKey, filters);
	}

	getFilter() {
		return (
			<div>
				<div className="row  margin-top-25">
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_spl margin-top-7 label_name"><I18 tkey="Speciality" /></span>
							<Speciality data={this.state.speciality ? this.state.speciality : ""} specialityChanged={this.onFilter.bind(this, "speciality")} organization={this.state.organization} typeActivity='HEALTH_PATHWAY' hideLabel={true} />
						</div>
					</div>
					<div className="col-md-4 col-sm-4">
						<div className=" w_100 margin-bottom-5">
							<span className="min_width_fmly  margin-top-7 label_name"><I18 tkey="Family" /></span>
							<Family data={this.state.family ? this.state.family : ""} onFamilyChanged={this.onFilter.bind(this, "family")} organization={this.state.organization} typeActivity='HEALTH_PATHWAY' specialityId={this.state.speciality ? this.state.speciality.value : ""} hideLabel={true} />
						</div>
					</div>
				</div>
				<div className="row margin-top-10">
				</div>
			</div>
		);
	}

	getHospital() {
		return (
			<div className=" w_100">
				<span className="min_width_status  margin-top-7 label_name">
					<I18 tkey="Hospital" />
				</span>
				<HospitalSelect
					isClpList={true}
					data={this.state.hospital}
					hospitalChanged={this.onFilter.bind(this, "hospital")}
				/>
			</div>
		);
	}

	getClearFilter() {
		return (
			<div className="col-md-12 col-sm-4">
				<div className="clear_fliter text_align_right">
					<a className="clear_filter_a" onClick={this.clearFilter.bind(this)}><I18 tkey="Clear Filter" /></a>
				</div>
			</div>
		);
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	render() {

		if (this.props.healthPathway.fetchAllHealthPathway === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.healthPathway = this.props.healthPathway.healthPathway.data;
			this.state.healthPathwayCount = this.props.healthPathway.healthPathway.count;
			this.state.totalPages = parseInt(Math.ceil(this.props.healthPathway.healthPathway.count / this.state.pageSize));
			this.props.dispatch(healthPathwayAction.clearGetAll());
		}
		if (this.props.healthPathway.deleteHealthPathwayStatus === "FULFILLED") {
			if (this.props.healthPathway.notClpDeleteInfo && !this.props.healthPathway.notClpDeleteInfo.isDeletable) {
				this.state.isNotDeletable = true;
				this.state.isNotDeletableInfo = this.props.healthPathway.notClpDeleteInfo.attachedData;
				utils.showError(this.props.healthPathway.notClpDeleteInfo.msg);
				this.setState(this.state);

			} else {
				this.fetchDataFromServer();
			}
			this.props.dispatch(healthPathwayAction.removeHealthPathwayCompleted());

		}
		if (this.props.healthPathway.deleteHealthPathwayStatus === "REJECTED") {
			this.props.dispatch(healthPathwayAction.removeHealthPathwayCompleted());
		}

		return (
			<div className="main_overview_section">
				<div className="main_overview_top_section di w_100 padding-top-15">
					<div className="row">
						<div className="col-md-4">
							<div className="record_container w_100 margin-top-6">
								<div className="di number_of_records">
									<span><I18 tkey="Quantity of Pathways" />:<b>{this.state.healthPathwayCount}</b></span>
								</div>
							</div>
						</div>
						<div className="col-md-8 d-flex align-item-center justify-content-end ">

							<div>
								<ExportHealthpathwayList
									speciality={this.state.speciality}
									family={this.state.family}
									filterValue={this.state.filterValue}
									hospital={this.state.hospital}
									clinicalPathwayType={this.state.clinicalPathwayType}
									category={this.state.category}
									sort={this.state.sort}
									sortType={this.state.desc}
									organization={this.props.match.params.organization}
								/>
							</div>

							<div className="create_interventions text_align_right margin-right-10">
								<button className="common_button" type="button" onClick={this.createClicked.bind(this)}><i className="fa fa-plus margin-right-8" aria-hidden="true"></i><I18 tkey="CREATE HEALTH PATHWAY" /></button>
							</div>
							<div className="create_interventions text_align_right mt10_mobile">
								<div className="filter margin-right-0" onClick={this.filterClicked.bind(this)}>
									<FilterOutlined className={this.state.showFilter ? "filter_icon active" : "filter_icon"} type="filter" />
								</div>
							</div>
						</div>
					</div>
					{this.state.showFilter && this.getFilter()}
				</div>
				<div className="row d-flex align-items-center ">
					<div className="col-md-4 col-sm-4 ">
						{this.getHospital()}
					</div>
					<div className="col-md-4 col-sm-4 ">
						<span className="min_width_fmly  margin-top-7 label_name">
							<I18 tkey="Health Pathway Type" />
						</span>
						<ClpTypeDropDown 
							data={this.state.clinicalPathwayType} 
							clpTypeChanged={this.onFilter.bind(this, "clinicalPathwayType")}
							isClinicalPathway={true}
						/>
					</div>

					<div className="col-md-4 margin-top-17">
						<a className="padding-0 pathway_searchbox">
							<DebounceInput
								className="filter_input_design form-control"
								debounceTimeout={750}
								placeholder={i18Get("Search...", utils.getLanguage())}
								value={this.state.filterValue}
								onChange={(e) => { this.onFilter("filterValue", e.target.value); }}
								maxlength={120}
							/>
						</a>
					</div>

				</div>
				<div className="row d-flex align-items-center ">
					{this.state.showFilter && this.getClearFilter()}
				</div>
				<div className="tab_section padding-top-15 di w_100">
					<div className="tab-content table_scroll_container">
						<div id="home" className="tab-pane fade in active">
							{this.getList()}
						</div>
						{this.state.editModel ?
							<div>
								<HealthPathwayEditModel
									refreshCompleted={this.refreshCompleted}
									healthPathwayId={this.state.healthPathwayId}
									visible={this.state.editModel}
									allowClear={true}
									isMulti={false}
								/>
							</div>
							: ""}
					</div>
				</div>
				{this.state.isNotDeletable ? <NotDeleteInfo onClose={this.Closeview.bind(this)} data={this.state.isNotDeletableInfo} /> : ""}
				{this.state.isduplicate ? 
					<HealthPathwayDuplicateModel 
						title={i18Get("Create a Duplicate Pathway", utils.getLanguage())} 
						onVisible={this.duplicateHandle.bind(this)} 
						healthPathwayId={this.state.healthPathwayId}
					/> 
					: ""}
			</div>
		);
	}

	duplicateHandle = (e, refresh) => {
		this.setState({ isduplicate: e });
		if (refresh) {
			this.fetchDataFromServer();
		}
		
		
	};

	Closeview() {
		this.setState({ isNotDeletable: false });
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	moveToDetails = (value) => {
		if (value.status === statusList.STATUS.values.APPROVED) {
			this.state.value = value;
			this.props.history.push(this.props.location.pathname + "/" + value.id + this.props.location.search);
		}
	};

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.healthPathway, (this.state.page - 1), this.state.totalPages,
					this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this), column(this.getActionButton, this.moveToDetails), true, false,
					this.onPageChange.bind(this), "HEALTH PATHWAY",
					`-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll ${(this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "scanned_treatments_table" : this.props.organization === locations.tabMom ? "intervention_table" : "table_head_element_hide"}`,
					this.onPageSizeChange.bind(this))}
				getTrProps={function (state, rowInfo) {
					return {
						style: {
							background: rowInfo && rowInfo.index === this.state.activeCompany ? "#e8eaea7a" : ""
						}
					};
				}.bind(this)}
				defaultSorted={[
					{
						id: this.state.sort ? this.state.sort : "name",
						desc: this.state.desc ? true : false
					}
				]}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex} />
		);
	}

	rowScroll(row) {
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	onFetchData(state) {
		const prevState = this.state;
		// prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = false;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		if (this.state.ignore) {
			return;
		}
		let queryString = "";
		const organization = `${(this.state.organization ? this.state.organization.toUpperCase() : "MOM")}`;
		queryString += `offset=${this.state.page ? (this.state.page - 1) * this.state.pageSize : 0}&`;
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${this.state.desc ? `desc=${this.state.desc}&` : ""}`;
		queryString += this.state.filterValue ? `code=${encodeURIComponent(this.state.filterValue)}&name=${encodeURIComponent(this.state.filterValue)}&momCode=${encodeURIComponent(this.state.filterValue)}&momName=${encodeURIComponent(this.state.filterValue)}&` : "";
		queryString += this.state.speciality ? `specialityId=${this.state.speciality.value}&` : "";
		queryString += this.state.family ? `familyId=${this.state.family.value}&` : "";
		queryString += this.state.status ? `status=${this.state.status}&` : "";
		queryString += this.state.hospital && this.state.hospital.value ? `hospitalId=${this.state.hospital.value}&` : "";
		queryString += this.state.site ? `siteId=${this.state.site.value}&` : "";
		queryString += this.state.clinicalPathwayType ? `clinicalPathwayType=${this.state.clinicalPathwayType}` : "";
		queryString += `&timeZone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(healthPathwayAction.getAll(organization, queryString));
		localStorage.setItem(HP_CURRENT_PAGE, this.state.page);
	}

	deleteClicked = (row) => {
		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to delete ?"),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Delete"),
			cancelText: i18Get("Cancel"),
			onOk: () => {
				if (row && row.id) {
					this.props.dispatch(healthPathwayAction.removeHealthPathway(row.id));
				}

			},
			onCancel: () => {
			}
		});
	};


	approveClicked(healthPathway) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, healthPathwayId: healthPathway.id });
		};
	}

	editClicked(healthPathway) {
		return (e) => {
			e.preventDefault();
			this.setState({ editModel: true, healthPathwayId: healthPathway.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false, editModel: false });
		this.fetchDataFromServer();
	};



	onFilter(e, v) {
		const prevState = this.state;
		prevState[e] = v;
		prevState.page = 1;
		prevState.site = e === "hospital" ? null : prevState.site;
		prevState.family = e === "speciality" ? null : prevState.family;

		this.setState({ ...prevState }, () => {
			localStorage.setItem(HP_CURRENT_PAGE, 1);
			this.fetchDataFromServer();
			this.setLocalStorageForFilter();
		});
	}

	handleReplace = (healthPathway) => {
		this.setState({
			isduplicate: true,
			healthPathwayId: healthPathway.id
		});
	};

	getActionButton = (row) => {
		return (

			<div className="">
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
							: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
					}
				</div>
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<DeleteOutlined className="delete_color" type="delete" onClick={() => this.deleteClicked(row.value)} />
							: ""
					}
				</div>
				<div className="min_width_12_px margin-left-10">
					<CopyOutlined onClick={() => this.handleReplace(row.value)} />
				</div>
			</div>

		);
	};

}

HealthPathWayList.propTypes = {
	healthPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	healthPathway: store.healthPathway,
	translations: store.translations
}))(HealthPathWayList));