import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import { DebounceInput } from "react-debounce-input";
import BreadCrumb from "../../../breadcrumb";
import AdminPersonnelRoute from "./route";
import { FilterOutlined } from "@ant-design/icons";
import PersonnelTemplateDropdown from "./personnel-templates-dropdown";
import TypeActivityDropdown from "./type-activity-dropdown";
import "./Administer_Personnel.css";
import StatusDropDown from "../../../intervention/statusDropDown";
import HospitalSelect from "../../../administration/activities/specialities/selectHospital";

class AdminPersonnels extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminPersonnelData: {},
			tab: locations.tabPersonnelTemplates,
			search: "",
			count: 0,
			template: null,
			typeActivity: "ALL",
			showFilter: false,
			selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) },
			selectedStatus: "ALL"
		};
	}

	UNSAFE_componentWillMount() {
		this.state.tab = this.props.location.pathname.startsWith(`${locations.adminPersonnel}/${locations.tabTemplateDetails}`) ? locations.tabTemplateDetails : locations.tabPersonnelTemplates;
		this.state.typeActivity = utils.checkForParameter("typeActivity", this.props.location) ? utils.checkForParameter("typeActivity", this.props.location) : this.props.location.pathname.startsWith(`${locations.adminPersonnel}/${locations.tabTemplateDetails}`) ? "Intervention" : "ALL";
		const templateId = utils.checkForParameter("templateId", this.props.location);
		const templateName = utils.checkForParameter("template", this.props.location);
		if ((this.state.tab === locations.tabTemplateDetails) && templateId && templateName) {
			this.state.template = { label: templateName, value: templateId };
		}
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Administer Personnel", utils.getLanguage()), link: `${locations.adminPersonnel}/${this.state.tab}` });
		if (this.props.location.pathname === `${locations.adminPersonnel}/${this.state.tab}/create`) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: `${locations.adminPersonnel}/${this.state.tab}/create` });
		}
		if (this.props.location.pathname === `${locations.adminPersonnel}/${this.state.tab}/${this.state.adminPersonnelData.id}`) {
			items.push({ name: this.state.adminPersonnelData.name, link: `${locations.adminPersonnel}/${this.state.tab}/${this.state.adminPersonnelData.id}` });
		}
		return items;
	}

	searchChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		this.setState(prevState);
	}

	tabChanged(link) {
		if (link) {
			const prevState = this.state;
			prevState.tab = link;
			if (link === locations.tabTemplateDetails && prevState.typeActivity === "ALL") {
				prevState.typeActivity = "Intervention";
			}
			prevState.search = "";
			prevState.template = null;
			prevState.count = 0;
			this.setState(prevState);
			this.props.history.push(locations.adminPersonnel + "/" + link);
		}
	}

	addClicked() {
		this.props.history.push(`${this.props.location.pathname}/create${this.props.location.search}`);
	}

	setFilter() {
		let query = `${(this.state.template && this.state.template.value) || this.state.typeActivity ? "?" : ""}`;
		query += `${this.state.template && this.state.template.value ? `templateId=${this.state.template.value}&template=${this.state.template.label}&` : ""}`;
		query += `${this.state.typeActivity ? `typeActivity=${this.state.typeActivity}` : ""}`;
		this.props.history.push(this.props.location.pathname + query);
	}

	templateChanged(e) {
		this.setState({ template: e }, this.setFilter);
	}

	typeActivitySelected(e) {
		this.setState({ typeActivity: e, template: null }, this.setFilter);
	}

	getCount(count) {
		this.setState({ count: count });
	}

	showContent() {
		if (this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabPersonnelTemplates}` ||
            this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabTemplateDetails}`) {
			return true;
		}
		return false;
	}

	disableCreate() {
		return (this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabTemplateDetails}`) && (!this.state.template || !this.state.template.value);
	}

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter });
	}

	onHospitalChanged(e) {
		this.setState({ selectedHospital: e ? e : "" });
	}

	statusChanged = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};

	clearFilter() {
		this.setState({ selectedHospital: { value: null, label: i18Get("All", utils.getLanguage()) }, selectedStatus: "ALL", filter: "", search: "" });
	}

	getFilter() {
		return (
			<div className="row margin-top-15">
				<div className="col-sm-4">
					<div className="form-group">
						<span><I18 tkey="Status" />:</span>
						<StatusDropDown
							data={this.state.selectedStatus}
							onStatusChange={this.statusChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="form-group">
						<span><I18 tkey="Hospital" />:</span>
						<HospitalSelect
							data={this.state.selectedHospital}
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-4">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}

	render() {
		this.state.adminPersonnelData = this.props.breadcrumb.componentData;
		return (
			<div className="personal_table_scroll">
				<div className="main_overview_section">
					<div className="bloc_container">
						<BreadCrumb
							useSearch
							items={this.generateItems()}
							showRecord={this.showContent()}
							totalRecords={this.state.count} />
						{this.showContent() ?
							<div className="main_overview_top_section di w_100 padding-top-15">
								<div className="d-flex align-items-center justify-content-between">
									<button
										className="common_button"
										type="button"
										disabled={this.disableCreate()}
										onClick={this.addClicked.bind(this)}>
										<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
										<I18 tkey="CREATE" />
									</button>
									<div className="d-flex align-items-center">
										<div className="min_width_300">
											<DebounceInput
												className="filter_input_design form-control"
												debounceTimeout={750}
												placeholder={i18Get("Search...", utils.getLanguage())}
												value={this.state.search}
												onChange={this.searchChanged.bind(this)} />
										</div>
										<div className="filter  margin-left-15" onClick={this.filterClicked.bind(this)}>
											<FilterOutlined  className={`margin-top-neg-3 ${this.state.showFilter ? "filter_icon active" : "filter_icon"}`} type="filter" />
										</div>
									</div>
								</div>
							</div> : ""}
						{this.showContent() ? 
							this.state.showFilter && this.getFilter()
							: ""}
                            
						<div className="di w_100">
							<div className="tab_section padding-top-15">
								{this.showContent() ?
									<ul className="nav nav-tabs">
										<li className={(this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabPersonnelTemplates}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabPersonnelTemplates)}>
												<I18 tkey="Teams" />
											</a>
										</li>
										<li className={(this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabTemplateDetails}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabTemplateDetails)}>
												<I18 tkey="Team Members" />
											</a>
										</li>
										<li className="Administer_Personnel_perent_li_width">
											<li className="Administer_Personnel_li_width padding-left-5">
												<TypeActivityDropdown
													ignoreAll={this.state.tab === locations.tabTemplateDetails}
													className="w_100_imp padding-4"
													typeActivity={this.state.typeActivity}
													typeActivitySelected={this.typeActivitySelected.bind(this)} />
											</li>
											{(this.props.location.pathname === `${locations.adminPersonnel}/${locations.tabTemplateDetails}`) ?
												<li className="Administer_Personnel_li_width padding-left-5">
													<PersonnelTemplateDropdown
														className="padding-4"
														data={this.state.template}
														typeActivity={this.state.typeActivity && (this.state.typeActivity !== "ALL") ? this.state.typeActivity : ""}
														templateChanged={this.templateChanged.bind(this)} />
												</li> : ""}
										</li>
									</ul> : ""}
								<AdminPersonnelRoute
									search={this.state.search}
									status={this.state.selectedStatus}
									hospitalId={this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : null}
									tab={this.state.tab}
									typeActivity={this.state.typeActivity}
									template={this.state.template && this.state.template.value ? this.state.template.value : null}
									getCount={this.getCount.bind(this)}
									onRef={ref => this.list = ref} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

AdminPersonnels.propTypes = {
	adminPersonnel: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminPersonnel: store.adminPersonnel,
	breadcrumb: store.breadcrumb
}))(AdminPersonnels));                    