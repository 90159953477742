import React, { useEffect } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import I18 from "../../../../../i18";
import loginActions from "../../../../../actions/user/login";
import utils from "../../../../../utils/utils";

const  Suspended = (props) => {
	const actions = useSelector(store => store.actions);
	const user = useSelector(store => store.user);
	const dispatch = useDispatch();
	const key = "USER_LOGIN_ATTEMPT";
	const attempt = localStorage.getItem(key);
	useEffect( () => {
		if ((utils.regexEmail.test(props.email))) { 
			dispatch(loginActions.getLoginInfo(props.email));
		}
	}, [props.email, attempt]);
	console.log("actions>>>>>>>>>>>>>>>>>>>>>>>>>>>>", actions);
	console.log("user>>>>>>>>>>>>>>>>>>>>>>>>>>>>", user);
	if (actions && actions.loginBlocked) {
		return (<div className="login_button di w_100 text_align_center margin-top-35 font_size_20px">
			<p><I18 tkey="Sorry, your account is temporarily inactive" />,</p>
			<div><WarningOutlined style={{ fontSize: "44px", color: "#c1711f" }} /></div>
			<p><I18 tkey="Please contact the administrator" />.</p>
		</div>);
	} else if (user && user.loginInfo && user.loginInfo.wrongLoginAttempt ) {
		return <div className="di w_100 text_align_left padding-left-10  color_red"><b>{(5 - user.loginInfo.wrongLoginAttempt) }</b> <I18 tkey={`Attempt${user.loginInfo.wrongLoginAttempt === 4 ? "" : "s"} Remaining`} /></div>;
	}
};

export default Suspended;