import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import specialtyActions from "../../../../../../../actions/specialty";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import events from "../../../../../../../actions/events";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import NumericInput from "../../../../../../../plug/numeric-input";
import screenId from "../../../../../../../router/screenIdList";

class CreateSpecialty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			invalidName: false,
			invalidCode: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.specialityCreate });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	costConstantChange = (label, value) => {
		if (value > 100) {
			value = 100;
		}
		this.setState({ ...this.state, costConstant: value });
	};

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !(this.state.name.trim())) {
			prevState.invalidName = true;
		}
		if (!utils.regexSpecialityCode.test(this.state.code)) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.name,
				code: this.state.code,
				typeActivity: utils.checkForParameter("typeActivity", this.props.location)
			};
			if (this.props.typeActivity && this.props.typeActivity.toUpperCase() == "INTERVENTION") {
				payload["costConstant"] = utils.calculateFloatValue(this.state.costConstant);
			}
			this.props.dispatch(specialtyActions.createSpecialty(payload));
		}
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.code = text;
		} else {
			prevState.code = this.state.code ? this.state.code : "";
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	render() {
		if (this.props.specialty.isCreateSpecialtyCompleted === "FULFILLED") {
			this.props.dispatch(specialtyActions.clearcreateSpecialty());
			this.props.history.push(locations.specialities + `?typeActivity=${this.props.typeActivity}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="">
				<div className="">
					<div className="margin-bottom-10 subheader">
						<span><I18 tkey="TypeActivity" />: </span>
						<span><I18 tkey={this.props.typeActivity.replace(/_/g, " ")} /></span>
					</div>
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<label className="code_label"><I18 tkey="* Code must be 3-digit uppercase characters" /></label>
								<NameInput
									value={this.state.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={3}
								/>
								{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Enter valid code" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={60}
								/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						{
							this.props.typeActivity && this.props.typeActivity.toUpperCase() == "INTERVENTION" &&
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="General Cost" /></label>
									<NumericInput
										value={this.state.costConstant} maxValue={100}
										getNumericValue={this.costConstantChange}
									/>
								</div>
							</div>
						}
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton showAdd={true} onClick={this.saveClicked.bind(this)} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateSpecialty.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	specialty: store.specialty,
	actions: store.actions,
	translations: store.translations
}))(CreateSpecialty)); 