import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Spin } from "antd";
import clinicalPathwayAction from "../../../../../../../../actions/clinical-pathway";
import ClinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import ReorderByModel from "../quick-reorder-by-model/reorder-problem-by-attached-model";
import { LoadingOutlined  } from "@ant-design/icons";
import I18 from "../../../../../../../../i18";
class ReorderModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			list: [],
			quickReorderChanges: 0,
			orderChanged: false,
			selectedItems: [],
			unSelect: false,
			saveAndNext: false,
			code: null,
			isLocalInterventionWithMultipleSource: false,
			saveStatus: 0,
			isLoading: false
		};
	}

	UNSAFE_componentWillMount() {this.fetchData(); }

	componentDidUpdate() {
		const prevState = this.state;
		if (this.state.saveStatus !== this.props.saveStatus)
		{
			this.onClickSave();
			prevState.saveStatus = this.props.saveStatus;
			this.setState(prevState);
		}

		if (this.props.quickReorderChanges !== this.state.quickReorderChanges) {
			this.fetchData();
			this.setState({ quickReorderChanges: this.props.quickReorderChanges });
		}

		
	}

	onClickSaveAndNExt = () => { this.setState({ saveAndNext: true }, this.onClickSave());};

	onClickSave = () => {
		const payload  = [];
		if (this.state.list && this.state.list.length < 1) {return;}
		this.setState({ loading: true });
		this.state.list.forEach((item) => { payload.push(item.id);});
		this.props.dispatch(ClinicalPathwayProblemAction.updateByLinkedModelRank(this.props.match.params.id, { reOrderedLinkedModels: payload, linkedModelList: true }));
	};


	setListItems(data) { this.setState({ ...this.state, list: data, orderChanged: true }); this.props.onOrderChange(true);}

	onClickUnselect = () => { this.setState({ unSelect: true }); };

	selectedItems(data) {
		this.setState({ selectedItems: data });
	}


	deSelect() { this.setState({ unSelect: false, selectedItems: [] }); }

	fetchData() {
		this.setState({ isLoading: true });
		const queryString = "offset=0&numResults=1000&type=ADDED&isModel=1&sort=rank&desc=0";
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(clinicalPathwayAction.getAllItems(this.props.match.params.id, queryString, this.state.fetchAllMeta));
		});
	}
	
	checkAnyLocalInterventionFromMultipleSource() {

		const getProblemList = this.props.clinicalPathwayProblems.problemList.mapping;
		const problemList = getProblemList.filter(each => !each.modelTreatmentId);
		problemList.forEach((problem) => {
			problem.additionalInfo.forEach(additionalInfo => {
				const linkedModels = this.state.list.filter(model => model.id === additionalInfo.modelTreatmentId);
				console.log("linkedModels", linkedModels);
				if (linkedModels.length > 0) {
					this.setState({ isLocalInterventionWithMultipleSource: true });
					return true;
				}
			});
		});
	}

	render() {
		// //--------------------------------FETCH DATA------------------------------------------------------------
		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "FULFILLED" && this.props.clinicalPathway.clinicalPathwayModelMeta === this.state.fetchAllMeta) {
			this.setState({ list: this.props.clinicalPathway.clinicalPathwayModel.data, isLoading: false }, 
				() => { 
					this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
					this.checkAnyLocalInterventionFromMultipleSource();
				 } );
		}

		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
		}

		return (
			<div>
				{ this.state.list.length > 0  ?
					<ReorderByModel
						list={this.state.list}
						selectedItems={this.selectedItems.bind(this)}
						deSelect={this.deSelect.bind(this)}
						unSelect={this.state.unSelect}
						setListItems={this.setListItems.bind(this)}
						quickReorderChanges={this.state.quickReorderProblemListChanges}
						disableReorder = { false }
						disableHiperLink = {true}
						setItemCount={this.props.setItemCount}

					/>
					: <div className="quick-order-empty-list">{ !this.state.isLoading ? <span><I18 tkey="No Data Found" /></span> : <Spin indicator={<LoadingOutlined type="loading" style={{ fontSize: 28 }} Spin/>} /> }  </div>
				}
			</div>
		);
	}
}

ReorderModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		clinicalPathway: store.clinicalPathway,
		actions: store.actions
	}))(ReorderModel)
);
