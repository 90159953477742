import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import sterilizationActions from "../../../../../../../../actions/sterilization";
import SterilizatonSelect from "../sterilizatonSelect";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			sterilizationClassName: "",
			sterilizationClassCode: "",
			invalidSterilizationClassName: false,
			invalidSterilizationClassCode: false,
			invalidSterilization: false,
			selectedSterilization: "",
			sterilizationClass: {},
			sterilizationClassId: "",
			consumableSpecialityApproved: false,
			consumableFamilyApproved: false,
			sterilizationClassFound: false,
			isloading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(sterilizationActions.fetchOneClassSterilization(this.props.sterilizationClassId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidSterilizationClassName: false, invalidSterilizationClassCode: false, invalidFamily: false, sterilizationClassFound: false });
		this.props.refreshCompleted();
	}

	onSterilizationChange(e) {
		this.setState({ selectedSterilization: e ? e : "", invalidSterilization: e && e.value ? false : true });
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.sterilizationClassFound = true;
			this.state.value = 2;
		}
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkConsomableName(this.state.sterilizationClassName) || !(this.state.sterilizationClassName.trim())) {
			prevState.invalidSterilizationClassName = true;
		}
		if (!(this.state.sterilizationClassCode)) {
			prevState.invalidSterilizationClassCode = true;
		}
		if (prevState.invalidSterilizationClassName || prevState.invalidSterilizationClassCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateSterilization = () => {
		if (this.state.selectedSterilization && this.state.sterilizationClassFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.sterilizationClassName,
					code: this.state.sterilizationClassCode
				};
				this.props.dispatch(sterilizationActions.saveAndApproveSterilizationClass(this.state.sterilizationClass.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateSterilization()) {
				payload = {
					consumableId: this.state.selectedSterilization && this.state.selectedSterilization.value ? this.state.selectedSterilization.value : "" 
				};
				this.props.dispatch(sterilizationActions.assignAndApproveSterilizationClass(this.state.sterilizationClass.id, payload));
			}
			else {
				this.setState({ invalidSterilization: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidSterilizationClassName = false;
		prevState.sterilizationClassName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidSterilizationClassCode = false;
		prevState.sterilizationClassCode = value;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.sterilizationClassId !== this.state.sterilizationClassId) {
			this.state.sterilizationClassId = this.props.sterilizationClassId;
			this.props.dispatch(sterilizationActions.fetchOneClassSterilization(this.props.sterilizationClassId));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.sterilization.asssignAndApproveSterilizationClassStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, sterilizationClassName: "", sterilizationClassCode: "", sterilizationClassFound: false });
			this.props.dispatch(sterilizationActions.assignAndApproveSterilizationClassCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.sterilization.saveAndApproveSterilizationClassStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, sterilizationClassName: "", sterilizationClassCode: "", sterilizationClassFound: false });
			this.props.dispatch(sterilizationActions.saveAndApproveSterilizationClassCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.sterilization.getSterilizationClassStatus === "FULFILLED") {
			this.state.sterilizationClass = this.props.sterilization.sterilizationClass;
			this.state.sterilizationClassName = this.state.sterilizationClass.name ? this.state.sterilizationClass.name : "";
			this.state.sterilizationClassCode = this.state.sterilizationClass.code ? this.state.sterilizationClass.code : "";
			this.state.selectedSterilization = { label: this.state.sterilizationClass.name ? this.state.sterilizationClass.name : "", value: this.state.sterilizationClass.id }; 
			this.state.isloading = true;
			this.state.consumableSpecialityApproved = this.state.sterilizationClass.consumableSpecialityApproved ? this.state.sterilizationClass.consumableSpecialityApproved : false;
			this.state.consumableFamilyApproved = this.state.sterilizationClass.consumableFamilyApproved ? this.state.sterilizationClass.consumableFamilyApproved : false;
			this.props.dispatch(sterilizationActions.clearFetchOneClassSterilization());
		}
		if (this.props.sterilization.getSterilizationClassStatus === "REJECTED") {
			this.props.dispatch(sterilizationActions.clearFetchOneClassSterilization());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Sterilization Class", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						this.state.consumableSpecialityApproved && this.state.consumableFamilyApproved ?
							<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve'/>
							</Button> : ""
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.sterilizationClass.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.sterilizationClass.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.sterilizationClass.hospitalName ? this.state.sterilizationClass.hospitalName : ""}</p>
							</div>
							{this.state.sterilizationClass.consumableSpecialityName &&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="STERILIZATION SPECIALITY NAME"/>:</span> {this.state.sterilizationClass.consumableSpecialityName ? this.state.sterilizationClass.consumableSpecialityName : ""}</p>
							}
							{this.state.sterilizationClass.consumableFamilyName &&
                            <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="STERILIZATION FAMILY NAME"/>:</span> {this.state.sterilizationClass.consumableFamilyName ? this.state.sterilizationClass.consumableFamilyName : ""}</p>
							}
						</div> 
						{this.state.consumableSpecialityApproved && this.state.consumableFamilyApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											<NameInput
												value={this.state.sterilizationClassCode}
												inputChanged={this.codeChanged.bind(this)}
												disabled={true}
												maxLength={10}
											/>
											{this.state.invalidSterilizationClassCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid sterilization class code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group ">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.sterilizationClassName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidSterilizationClassName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid sterilization class name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Sterilization Class" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<SterilizatonSelect defaultValue="Select Sterilizaton" disabled={this.state.value != 2} style={{ width: "100%" }}
												data={this.state.selectedSterilization}
												typeActivity={this.props.typeActivity}
												statusCheck={this.statusCheck.bind(this)}
												onSterilizationChange={this.onSterilizationChange.bind(this)}/>
											{this.state.invalidSterilization && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select sterilizaton class" /></span>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.consumableSpecialityApproved ?
									<I18 tkey="The sterilization speciality for this sterilization class is waiting for approval. Please approve the sterilization speciality first" />
									: this.state.isloading && !this.state.consumableFamilyApproved ?  <I18 tkey="The sterilization family for this sterilization class is waiting for approval. Please approve the sterilization family first" /> : "" }
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	sterilization: store.sterilization
}))(AssignAndApproveModel));    