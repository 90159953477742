import { connect } from "react-redux";
import React, { Component } from "react";
// import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import InterventionLocationDropDown from "../problem-section/additional-Info/interventionLocationDropDown";
import StartDayDropdown from "../problem-section/additional-Info/startDay-dropdown";
import EndDayDropdown from "../problem-section/additional-Info/endDay-dropdown";

class StepsComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {

			startDayOptions: [],
			endDayOptions: [],
			startDay: null,
			endDay: null,
			interventionLocation: []
		};
	}
	componentDidUpdate() {
		if (this.props.data != this.state.interventionLocation) {
			this.setState({ interventionLocation: this.props.data });
		}
	}
	startDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		if (e) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			prevState.endDayOptions = list.splice(list.findIndex(x => x.value === e.value));
		}
		else {
			prevState.endDayOptions = [];
			filteredData = prevState.interventionLocation.filter(item1 => item1.clpSubStepId !== prevState.startDay.clpSubStepId);
			prevState.interventionLocation = [...filteredData];
		}
		this.props.onSubStepMapping(prevState.interventionLocation);
		prevState.startDay = e;
		prevState.endDay = null;
		this.setState(prevState);
	}

	endDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		prevState.endDay = e;
		if (prevState.startDay && prevState.endDay) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			const startDayIndex = list.findIndex(x => x.value === prevState.startDay.value);
			const endDayIndex = list.findIndex(x => x.value === prevState.endDay.value);
			const data = list.slice(startDayIndex, endDayIndex + 1);
			const interventionLocationList = JSON.parse(JSON.stringify(prevState.interventionLocation));
			filteredData = interventionLocationList.filter(item1 => item1.clpSubStepId !== prevState.endDay.clpSubStepId);
			prevState.interventionLocation = [...filteredData, ...data];
			this.props.onSubStepMapping(prevState.interventionLocation);
		}
		this.setState(prevState);
	}

	onLocationChange(value) {
		const prevState = this.state;
		prevState.interventionLocation = value;
		if (!value.length) {
			prevState.startDay = null;
			prevState.endDayOptions = [];
			prevState.endDay = null;
		}
		this.props.onSubStepMapping(prevState.interventionLocation);
		this.setState(prevState);
	}

	renderDayOptions = e => {
		this.state.startDayOptions = e;
		this.setState(this.state);
	};
	render() {

		return (
			<div className={`row ${this.props.className}`}>
				{this.props.rangeOption == true && 
				<div className={`col-md-2 padding-0 ${(this.props.clinicalPathwayType !==  "BEFORE_ADMISSION" && this.props.clinicalPathwayType !== "AFTER_CHECKOUT" && this.props.clinicalPathwayType !== "DEFINITION") ? "margin-top-38" : "margin-top-8"}`}>
					<span className="steps_label_special font_size_13px_label padding-top-0">
						<I18 tkey='Location of the intervention' />
					</span>
				</div>}
				<div className="col-md-10 padding-0">
					{this.props.invalidLocation && <span className='invalid_clinical_pathway invalid_color margin-top--7' >
						<I18 tkey={"Select a valid location of the intervention"} />
					</span>}
					{this.props.rangeOption == true &&
						<div className=''>
							{(this.props.clinicalPathwayType !==  "BEFORE_ADMISSION" && this.props.clinicalPathwayType !== "AFTER_CHECKOUT" && this.props.clinicalPathwayType !== "DEFINITION") && 
							<table className='float_right_i margin-top--5'>
								<tr>
									<td className='padding-right-5 w_45_px'><label>&nbsp;<I18 tkey='From' />:</label></td>
									<td className='padding-right-5'>
										<div className="w_145_px di position_relative">
											<StartDayDropdown
												startDayOptions={this.state.startDayOptions}
												startDay={this.state.startDay}
												startDayChanged={this.startDayChanged.bind(this)}
											/>
										</div>
									</td>
									<td className='padding-left-5 padding-right-5 w_30_px'><label><I18 tkey='To' />:</label></td>
									<td className="w_145_px">
										<div className="w_145_px di position_relative float_right">
											<EndDayDropdown
												endDayOptions={this.state.endDayOptions}
												endDay={this.state.endDay}
												endDayChan38ged={this.endDayChanged.bind(this)}
											/>
										</div>
									</td>
								</tr>
							</table>}
						</div>
					}
					<div className="w_100 di position_relative react_dropdown_scroll margin-top-5">
						<InterventionLocationDropDown
							data={this.state.interventionLocation ? this.state.interventionLocation : null}
							onChange={this.onLocationChange.bind(this)}
							stepSectionType={this.props.stepSectionType}
							problemId={null}
							renderDayOptions={this.renderDayOptions}
							isCreate={true}
							isEdit={true}
							additionalInfoId={this.props.stepSectionType}
						/>
					</div>
				</div>
			</div>
		);
	}

}

StepsComponent.propTypes = {

};

export default withRouter(connect(() => ({

}))(StepsComponent));