import React from "react";
import { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";

export default function getCols(getActionButton, moveToDetails) {
	const tableArray = ([
		{
			Header: "",
			id: "actions",
			accessor: (d) => d,
			resizable: false,
			width: 80,
			sortable: false,
			Cell: (row) => (
				getActionButton(row)
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d,
			width: 120,
			resizable: false,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.code : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d,
			width: 350,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.name : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("SPECIALITY", utils.getLanguage()),
			id: "specialityName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.specialityName : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("FAMILY", utils.getLanguage()),
			id: "familyName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.familyName : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value.hospitalName ? row.value.hospitalName : "Master"
					}
				</span>
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value.status === "APPROVED" ? "Approved" : "Waiting For Approval"
					}
				</span>
			)
		}
	]);
	return tableArray;
}
