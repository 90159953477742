
import utils from "utils/utils";
const findGroupDuration = (currentRow, dataList) => {

	const dRule = parseInt(currentRow["durationRule"]) || 0;
	const getAllGroupRows = dataList.filter(e => e.groupCode === currentRow.groupCode );
	let durationMinimumMinute = 0,
	 durationMaximumMinute = 0,
	 duration = 0;
	switch (dRule) {
		case 1://MINIMUM CASE
			
		durationMinimumMinute = getAllGroupRows.reduce((min, current) => Math.min(min, current.durationMinimum), getAllGroupRows[0]?.durationMinimum);
		durationMaximumMinute = getAllGroupRows.reduce((min, current) => Math.min(min, current.durationMaximum), getAllGroupRows[0]?.durationMaximum);
		duration = getAllGroupRows.reduce((min, current) => Math.min(min, current.durationFrequent), getAllGroupRows[0]?.durationFrequent);
		  break;
		case 2://MAXIMUM CASE
			durationMinimumMinute = getAllGroupRows.reduce((min, current) => Math.max(min, current.durationMinimum), getAllGroupRows[0]?.durationMinimum);
			durationMaximumMinute = getAllGroupRows.reduce((min, current) => Math.max(min, current.durationMaximum), getAllGroupRows[0]?.durationMaximum);
			duration = getAllGroupRows.reduce((min, current) => Math.max(min, current.durationFrequent), getAllGroupRows[0]?.durationFrequent);
		  break;
		case 3://SUM CASE
			getAllGroupRows.forEach(element => {
				durationMinimumMinute+= (parseInt(element.durationMinimum) || 0);
				durationMaximumMinute+= (parseInt(element.durationMaximum) || 0);
				duration+= (parseInt(element.durationFrequent) || 0);
			});
		  break;
		case 4://MEANS CASE
			const count = getAllGroupRows.length;
			getAllGroupRows.forEach(element => {
				durationMinimumMinute+= (parseInt(element.durationMinimum) || 0);
				durationMaximumMinute+= (parseInt(element.durationMaximum) || 0);
				duration+= (parseInt(element.durationFrequent) || 0);
			});

			durationMinimumMinute/=count;
			durationMaximumMinute/=count;
			duration/=count;

		  break;
		default:
		
	  }

	  dataList.map( e => {
		if( e.groupCode === currentRow.groupCode) {
			e.groupDuration.durationMinimumMinute  = floorInFraction(durationMinimumMinute || 0);
			e.groupDuration.durationMaximumMinute  = floorInFraction(durationMaximumMinute || 0);
			e.groupDuration.duration  = floorInFraction(duration);
			return e;
		}
	  });
	
	return dataList;
};

const groupValidation = (dM, dMx, frq) => {
	
	dM = parseFloat(dM);
	dMx = parseFloat(dMx);
	frq = parseFloat(frq);

	if (
		(dMx > dM || !dMx) &&
		(dMx >= frq || !frq || !dMx) && 
		(frq >= dM || !frq)
		) {
			return true;
		}else {
			return  {
				error: true,
				message:`Min: ${dM}, Max: ${dMx}, Freq: ${frq}`,
				data: {
					dM, dMx, frq
				}
			};
		}

};

const findGroupCost = (currentRow, dataList) => {

	const dRule = parseInt(currentRow["costRule"]) || 0;
	const getAllGroupRows = dataList.filter(e => e.groupCode === currentRow.groupCode);
	let groupCost = 0;

	switch (dRule) {
		case 1://MINIMUM CASE
			
			groupCost = getAllGroupRows.reduce((min, current) => Math.min(min, current.unitCost), getAllGroupRows[0]?.unitCost);

		  break;
		case 2://MAXIMUM CASE
			groupCost = getAllGroupRows.reduce((min, current) => Math.max(min, current.unitCost), getAllGroupRows[0]?.unitCost);
		  break;
		case 3://SUM CASE
			getAllGroupRows.forEach(element => {
				groupCost+= (parseInt(element.unitCost) || 0);
			});
		  break;
		case 4://MEANS CASE
			const count = getAllGroupRows.length;
			getAllGroupRows.forEach(element => { groupCost+= (parseInt(element.unitCost) || 0);});
			groupCost/=count;

		  break;
		default:
		
	  }

	  dataList.map( e => {
		if( e.groupCode === currentRow.groupCode) {
			e.groupCost  = floorInFraction(groupCost);
			return e;
		}
	  });
	
	return dataList;
};

const setGroupRuleasDefalut = (currentRow, dataList) => {

	dataList.map( e => {
		if(e.groupCode === currentRow.groupCode) {
			e.durationRule = currentRow.durationRule
		}
		return e;
	})

	return findGroupDuration(currentRow, dataList);
};

const setGroupCostDefalut = (currentRow, dataList) => {

	dataList.map( e => {
		if(e.groupCode === currentRow.groupCode) {
			e.costRule = currentRow.costRule
		}
		return e;
	})

	return findGroupCost(currentRow, dataList);
};

const floorInFraction = (value) => {

	value = value*1;
	if(value) {
		value = Math.floor(value) != value ? value.toFixed(2) : value;
	}
	 return value;
};


function convertMinutesToDaysHoursMinutes(minutes) {
	if (minutes < 0) {
		return { days: 0, hours: 0, minutes: 0};
	}
  
	const minutesInDay = 60 * 24;
	const minutesInHour = 60;
  
	const days = Math.floor(minutes / minutesInDay);
	const hours = Math.floor((minutes % minutesInDay) / minutesInHour);
	const remainingMinutes = minutes % minutesInHour;
  
	return {
	  days: days,
	  hours: hours,
	  minutes: remainingMinutes,
	};
  }

  function convertMinutes(minutes) {
    let day = minutes/1440;
    let hour =  minutes/60;
    if (day >= 1) {
        return day;
    }else if(hour >= 1) {
        return hour;
    }else {
        return minutes;
    }
  }
  function findTypeShortName(minutes) {
	
	minutes = parseInt(minutes);
    let day = minutes/1440;
    let hour =  minutes/60;
    if (day >= 1) {
        return "J";
    }else if(hour >= 1) {
        return "H";
    }else if (minutes > 0) {
        return "M";
    }
  }

  const replaceComa = (value) => {

	if(utils.getLanguage() == "fr" && value) {
		value = value.toString().replace(".", ",")
	}

	return value
  };
export default {
	findGroupDuration, 
	findGroupCost, 
	setGroupRuleasDefalut, 
	setGroupCostDefalut, 
	floorInFraction, 
	convertMinutesToDaysHoursMinutes, 
	convertMinutes, 
	findTypeShortName,
	replaceComa,
	groupValidation};