import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import Search from "./search";
import Tab from "./tab";
import ShowHideColumns from "../../../../../../../plug/show-hide-columns";

class TopSection extends Component {
	constructor(props) {
		super(props);
	}

	createClicked() {
		this.props.history.push(`${locations.interventions_plain}/${this.props.match.params.organization}${locations.create}`);
	}

	render() {
		return (
			<div>
				<ul className="nav nav-tabs position_relative margin-bottom-10">
					<Search
						field={this.props.field}
						search={this.props.search}
						fieldChanged={this.props.fieldChanged}
						searchChanged={this.props.searchChanged}
					/>
				</ul>

				<ul className="nav nav-tabs position_relative">
					{ !this.props.course
						? <Tab label="Time Sheets Interventions" tabLocation={locations.timeSheets} /> : ""}
					{!this.props.course
						? <Tab label="Default Values" tabLocation={locations.defaultValues} /> : ""}
					{ this.props.location.pathname.indexOf(locations.timeSheets) !== -1 ? 
						 <ShowHideColumns storageKey="INTERVENSTION_TIMESHEETS_COLUMN_SETTINGS" eventKey="SELECTED_COLUMN_INTERVENTION_TIMESHEETS"  columnKey="INTERVENTION_TIME_SHEET" tab="TIME" /> 
						:  <ShowHideColumns storageKey="INTERVENSTION_DEFAULT_VALUES_COLUMN_SETTINGS" eventKey="SELECTED_COLUMN_INTERVENTION_DEFAULT_VALUES"  columnKey="INTERVENTION_DEFAULT_VALUES" tab="DEFAULT" /> 
					}
				</ul>
			</div>
		);
	}
}

TopSection.propTypes = {
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	scope: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	user: store.user,
	scope: store.scope
}))(TopSection));
