import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";

function getText(value) {
	return <div>{value}</div>;
}

export default function column(editClicked, deleteClicked, viewCommunityList) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto">
					<div className="min_width_12_px">
						<EditOutlined className="edit_color margin-right-10" type="edit" onClick={editClicked(row.value)} />
					</div>
					<DeleteOutlined className="delete_color margin-right-10" type="delete" onClick={deleteClicked(row.value)} />

				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d.code,
			maxWidth: 130,
			resizable: false,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("SHORT NAME", utils.getLanguage()),
			id: "shortName",
			accessor: (d) => d.shortName,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("NAME OF THE SITE", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d.hospitalName,
			resizable: true,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("COMMUNITY", utils.getLanguage()),
			id: "communityName",
			accessor: (d) => d.communityName,
			resizable: true,
			sortable: false,
			maxWidth: 150,
			Cell: (row) => (
				<div className="linked_data_count margin_left_auto">
					{row.value && row.value.length > 0
						? <a onClick={viewCommunityList(row.value.split(","))}>{row.value.split(",").length}</a> : <a className="color_black">0</a>}
				</div>
			)
		},
		{
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			resizable: true,
			maxWidth: 150,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		}
	]);
	return tableArray;
}
