import eventNames from "../../actions/events";

const initialTranslationsState = {
	fetchAllTreatmentsCompleted: false,
	treatmentList: {},
	assignToTreatmentsCompleted: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY}_FULFILLED`:
			return {
				...state,
				treatmentList: action.payload.data.data,
				fetchAllTreatmentsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY}_REJECTED`:
			return {
				...state,
				fetchAllTreatmentsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY:
			return {
				...state,
				fetchAllTreatmentsCompleted: false
			};
		case `${eventNames.ASSIGN_TO_TREATMENTS}_FULFILLED`:
			return {
				...state,
				assignToTreatmentsCompleted: "FULFILLED"
			};
		case `${eventNames.ASSIGN_TO_TREATMENTS}_REJECTED`:
			return {
				...state,
				assignToTreatmentsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_ASSIGN_TO_TREATMENTS:
			return {
				...state,
				assignToTreatmentsCompleted: false
			};
		default:
			return state;
	}
}
