import eventNames from "./event";

const initialState = {
	healthPathWayDropDownList: [],
	fetchAllHealthPathWayDropDownList: false,
	fetchAllHealthPathWayDetailsRegroupment: false,
	healthPathWayDetailsRegroupment: [],
	fetchAllHealthPathWayLinkedTaskDropDownList: false,
	HealthPathWayLinkedTaskDropDownList: []
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_HEALTH_PATHWAY_DROP_DOWN}_FULFILLED`:
			return {
				...state,
				healthPathWayDropDownList: action.payload.data.data,
				fetchAllHealthPathWayDropDownList: "FULFILLED"
			};
		case `${eventNames.GET_HEALTH_PATHWAY_DROP_DOWN}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayDropDownList: "REJECTED"
			};

		case eventNames.CLEAR_GET_HEALTH_PATHWAY_DROP_DOWN:
			return {
				...state,
				healthPathWayDropDownList: [],
				fetchAllHealthPathWayDropDownList: false
			};
		case `${eventNames.GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT}_FULFILLED`:
			return {
				...state,
				healthPathWayDetailsRegroupment: action.payload.data,
				fetchAllHealthPathWayDropDownList: "FULFILLED"
			};
		case `${eventNames.GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayDropDownList: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT:
			return {
				...state,
				healthPathWayDetailsRegroupment: [],
				fetchAllHealthPathWayDropDownList: false
			};
		case `${eventNames.GET_LINKED_GROUP_TASK_LIST_DROP_DOWN}_FULFILLED`:
			return {
				...state,
				HealthPathWayLinkedTaskDropDownList: action.payload.data,
				fetchAllHealthPathWayLinkedTaskDropDownList: "FULFILLED"
			};
		case `${eventNames.GET_LINKED_GROUP_TASK_LIST_DROP_DOWN}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathWayLinkedTaskDropDownList: "REJECTED"
			};
	
		case eventNames.CLEAR_GET_LINKED_GROUP_TASK_LIST_DROP_DOWN:
			return {
				...state,
				HealthPathWayLinkedTaskDropDownList: [],
				fetchAllHealthPathWayLinkedTaskDropDownList: false
			};
		default:
			return state;
	}
}
