import { connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import  { Select } from "antd";
import { i18Get } from "i18";
import utils from "utils/utils";

import healthPathwayAction from "actions/health-pathway";

function SelectHealthPathWays(props) {

	const dispatch = useDispatch();
	const healthPathway = useSelector(store => store.healthPathway);
	const [healthPathwayList, setHealthPathwayList] = useState([]);
	const optionsData = [];

	useEffect( () => {

		fetchDataFromServer();
	}, [props.clinicalPathwayType, props.hospitalId]);

	const fetchDataFromServer = () => {

		const hospitalId = props.healthPathwayData.hospitalId;
		let queryString = "";
		queryString += "offset=0&numResults=1000&sort=name&desc=0";
		queryString += "&clinicalPathwayType=ALL";//${props.clinicalPathwayType}
		queryString += `&hospitalId=${hospitalId ? hospitalId : "MASTER"}`;
		queryString += `&timeZone=${new Date().getTimezoneOffset()}`;
		dispatch(healthPathwayAction.getAll("", queryString));
	};

	useEffect( () => {

		if (healthPathway.fetchAllHealthPathway === "FULFILLED") {
			setHealthPathwayList([ ...healthPathway.healthPathway.data ]);
			dispatch(healthPathwayAction.clearGetAll());
	
		}
		if (healthPathway.fetchAllHealthPathway === "REJECTED") {
			dispatch(healthPathwayAction.clearGetAll());
	
		}
	});
	const renderOptions = () => {      
		const code = props.healthPathwayData.code;
		if (healthPathwayList.length > 0) {    
			healthPathwayList.map((data) => {   
				optionsData.push({ value: data.id, 
					label: `${data.hospitalId ? data.hospitalCode : data.hospitalName ? data.hospitalName : "MOM"} ${data.name}`, 
					code: data.code, hospitalCode: data.hospitalCode });
			});
		}    
		return optionsData.filter(e => e.code !== code);
	};



	return 	<div className={ props.className ? props.className : ""}>
		<Select 
			value={props.value ? renderOptions().find(e => e.code === props.value) : null}
			className="w_100 di"
			allowClear={true}
			placeholder="HealthPathway"
			showSearch
			disabled={ props.disabled}
			onChange={ (e, opt) => props.onchange(opt) }
			options={renderOptions()}
			isMulti={props.isMulti}
			notFoundContent= {i18Get("Not Found", utils.getLanguage())}
			optionFilterProp="children"
			filterOption={(iv, op) => op.label.toLocaleLowerCase().includes((iv || "").toLocaleLowerCase()) }
		>
		</Select>
	</div>;

}
export default SelectHealthPathWays;