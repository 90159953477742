import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "i18";
import utils from "utils/utils";
import interventionAction from "actions/intervention";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class InterventionSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			interventions: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true,
			options: []
		};
	}
   
	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchInterventionList(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchInterventionList();
	}

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.value);
	};

	fetchInterventionList(search) {
		if (!this.state.fetchingData) {   
			this.state.fetchDataFromserver = false;
			this.state.isListLoading = true;
			this.state.fetchingData = true;
			let queryString = "";
			const organization = `${(this.props.organization ? this.props.organization.toUpperCase() : "MOM")}`;
			if (this.props.diagram) {
				queryString = "sort=name&desc=0&offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + (search ? this.state.search ? `&name=${this.state.search}` : "" : "");
			}
			else {
				queryString = "offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + (search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "");
			}

			queryString += this.props.isApprovedList ? "&status=APPROVED": "";

			this.props.dispatch(interventionAction.getAll( queryString, false, organization));
		}
	}

	onSearch(search) {
		if (this.state.search !== search) {
			this.state.search = search;
			clearTimeout(this.timer);
			this.state.offset = 0;
			this.timer = setTimeout(() => {
				this.state.fetchDataFromserver = true;
				this.fetchInterventionList(search);
			}, 800);
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.interventions) {    
			this.state.interventions.map((data) => {   
				const prefix = `${this.props.match.params.organization !== "mom" ? data.siteShortName ? data.siteShortName : "" : data.siteId && data.hospitalId ? data.siteShortName : "MOM"}`;
				prevState.options.push({ value: data.id, label: (prefix ? prefix + " " : "") + data.name, hospitalCode: data.hospitalCode, code: data.code });
				
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}

	render() {
		let value = this.state.options.find(options =>  options.code === this.props.value);
		if(!value && this.props.customNodeData.linkedItem) {
			value = this.props.customNodeData.linkedItem;
		}
		if (this.props.intervention.fetchAllInterventions === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.interventions = unionWith(this.state.interventions, this.props.intervention.interventions.data, isEqual);
			this.renderOptions();
			this.state.isListLoading = false;
			if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
				this.fetchInterventionList(false);
				this.state.isDataFound = false;
			}
			this.props.dispatch(interventionAction.clearGetAll());    
		}
		return (   
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100 select_intervention"
				onChange={this.props.onInterventionChange}
				isClearable={true}
				value={value}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Intervention", utils.getLanguage())}
			/>
		);
	}
}

InterventionSelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	intervention: store.intervention,
	translations: store.translations
}))(InterventionSelect));