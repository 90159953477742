import eventNames from "./event";

const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_SPECITALITIES_LIST}_FULFILLED`:
			return {
				...state,
				data: action.payload.data.data,
				getAllStatus: "FULFILLED"
			};
		case `${eventNames.GET_ALL_SPECITALITIES_LIST}_REJECTED`:
			return {
				...state,
				getAllStatus: "REJECTED"
			};

		case eventNames.CLEAR_GET_ALL_SPECITALITIES_LIST:
			return {
				...state,
				getAllStatus: false
			};

		default:
			return state;
	}
}
