import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";
import { apiHeadersMultipart } from "../../utils";

const fetchAllExtClinicalPathway = (id, subStepId, day, queryString) => ({
	type: events.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA,
	meta: { subStepId, day },
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/subStep/${subStepId}?${queryString}`)
});

const clearFetchAllExtClinicalPathway = () => ({
	type: events.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA,
	payload: {}
});

const deleteExtClinicalPathway = (treatmentId, id, queryString) => ({
	type: events.DELETE_EXT_CLINICAL_PATHWAY,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/masterMapping/${id}${queryString ? `?${queryString}` : ""}`)
});

const clearDeleteExtClinicalPathway = () => ({
	type: events.CLEAR_DELETE_EXT_CLINICAL_PATHWAY,
	payload: {}
});

const updateExtClinicalPathway = (id, mappingId, payload) => ({
	type: events.UPDATE_EXT_CLINICAL_PATHWAY,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${mappingId}`, { ...payload })
});

const clearUpdateExtClinicalPathway = () => ({
	type: events.CLEAR_UPDATE_EXT_CLINICAL_PATHWAY,
	payload: {}
});

const fetchAllExtClinicalPathwayForType = (queryString) => ({
	type: events.FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE,
	payload: axios.get(`${Config.apiRootSecure}/minimal/clinicalPathway/items?${queryString}`)
});

const clearFetchAllExtClinicalPathwayForType = () => ({
	type: events.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE,
	payload: {}
});

const createExtClinicalPathway = (id, subStepId, payload) => ({
	type: events.CREATE_EXT_CLINICAL_PATHWAY,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/subStep/${subStepId}`, { ...payload })
});

const clearCreateExtClinicalPathway = () => ({
	type: events.CLEAR_CREATE_EXT_CLINICAL_PATHWAY,
	payload: {}
});

const fetchOneExtClinicalPathway = (id, mappingId, queryString) => ({
	type: events.FETCH_ONE_EXT_CLINICAL_PATHWAY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${mappingId}${queryString ? `?${queryString}` : ""}`)
});

const clearFetchOneExtClinicalPathway = () => ({
	type: events.CLEAR_FETCH_ONE_EXT_CLINICAL_PATHWAY,
	payload: {}
});

const getOneExtClp = (id, extTypeId, queryString) => ({
	type: events.GET_ONE_EXT_CLP,
	payload: axios.get(`${Config.apiRootSecure}/minimal/clinicalPathway/${id}/subStep/${extTypeId}${queryString ? `?${queryString}` : ""}`)

});

const clearExtClp = () => ({
	type: events.CLEAR_GET_ONE_EXT_CLP,
	payload: {}

});

const updateSubStepName = (id, payload) => ({
	type: events.UPDATE_SUB_STEP_NAME,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/treatmentSubStepName/${id}`, { ...payload })
});

const clearUpdateSubStepName = () => ({
	type: events.CLEAR_UPDATE_SUB_STEP_NAME,
	payload: {}
});

const fetchSubStepDetails = (id, queryString) => ({
	type: events.GET_ONE_SUB_STEP_DETAILS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/treatmentSubStepName/${id}${queryString ? `?${queryString}` : ""}`)

});

const clearFetchSubStepDetails = () => ({
	type: events.CLEAR_GET_ONE_SUB_STEP_DETAILS,
	payload: {}

});

const updateExtClinicalPathwayRank = (clpId, id, clpItemId, payload) => ({
	type: events.EXT_CLINICAL_PATHWAY_RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${clpId}/masterMapping/${id}/item/${clpItemId}`, { ...payload })
});

const clearUpdateExtClinicalPathwayRank = () => ({
	type: events.CLEAR_EXT_CLINICAL_PATHWAY_RANK_UPDATE,
	payload: {}
});

const fetchAllExtClinicalPathwayResponsible = (id, subStepId, queryString) => ({
	type: events.FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE,
	payload: axios.get(`${Config.apiRootSecure}/minimal/clinicalPathway/${id}/subStep/${subStepId}/responsibles${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllExtClinicalPathwayResponsible = () => ({
	type: events.CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE,
	payload: {}
});

const uploadReferenceFile = (id, clpItemId, fileType, formData) => ({
	type: events.UPLOAD_REFERENCE_FILE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${clpItemId}/upload/${fileType}`, formData, { ...apiHeadersMultipart() })
});

const clearUploadReferenceFile = () => ({
	type: events.CLEAR_UPLOAD_REFERENCE_FILE,
	payload: {}
});

const deleteReferenceFile = (id, referenceFileId) => ({
	type: events.DELETE_REFERENCE_FILE,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/file/${referenceFileId}`)
});

const clearDeleteReferenceFile = () => ({
	type: events.CLEAR_DELETE_REFERENCE_FILE,
	payload: {}
});

const fetchReferenceFile = (id, itemId) => ({
	type: events.FETCH_REFERENCE_ATTACHMENT,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${itemId}/download/file`)
});

const clearFetchReferenceFile = () => ({
	type: events.FETCH_REFERENCE_ATTACHMENT_COMPLETED,
	payload: {}
});

const fetchBgColor = () => ({
	type: events.FETCH_BACK_GROUND_COLOR,
	payload: axios.get(`${Config.apiRootSecure}/bgColor`)
});

const clearFetchBgColor = () => ({
	type: events.FETCH_BACK_GROUND_COLOR_COMPLETED,
	payload: {}
});

const createItem = (id, subStepId, payload) => ({
	type: events.CREATE_ITEM,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/subStep/${subStepId}/createItemMappingOnly`, { ...payload })
});

const clearCreateItem = () => ({
	type: events.CLEAR_CREATE_ITEM,
	payload: {}
});

const updateItem = (id, mappingId, payload) => ({
	type: events.UPDATE_ITEM,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${mappingId}/updateItemMappingOnly`, { ...payload })
});

const clearItemUpdate = () => ({
	type: events.CLEAR_UPDATE_ITEM,
	payload: {}
});

const duplicateItemMappingOnly = (id, mappingId) => ({
	type: events.DUPLICATE_ITEM_MAPPING_ONLY,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${mappingId}/item/duplicate`)
});

const clearDuplicateItemMappingOnly = () => ({
	type: events.CLEAR_DUPLICATE_ITEM_MAPPING_ONLY,
	payload: {}
});

const duplicateMapping = (id, mappingId) => ({
	type: events.DUPLICATE_MAPPING,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${mappingId}/duplicate`)
});

const clearDuplicateMapping = () => ({
	type: events.CLEAR_DUPLICATE_MAPPING,
	payload: {}
});

const extClinicalPathwayQuickEdit = (id, mappingId, payload) => ({
	type: events.EXT_CLINICAL_PATHWAY_QUICK_EDIT,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/quickEdit/${mappingId}`, { ...payload })
});

const clearExtClinicalPathwayQuickEdit = () => ({
	type: events.CLEAR_EXT_CLINICAL_PATHWAY_QUICK_EDIT,
	payload: {}
});

const extClpItemOnlyQuickEdit = (id, mappingId, payload) => ({
	type: events.EXT_CLP_ITEM_ONLY_QUICK_EDIT,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/quickEdit/${mappingId}/updateItemMappingOnly`, { ...payload })
});

const clearExtClpItemOnlyQuickEdit = () => ({
	type: events.CLEAR_EXT_CLP_ITEM_ONLY_QUICK_EDIT,
	payload: {}
});

const extClpRankUpdation = (id, stepId, subStepId, day, payload) => ({
	type: events.EXT_CLP_RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/step/${stepId}/subStep/${subStepId}/rankUpdation${day ? `?days=${day}` : ""}`, payload)
});

const extClpRankUpdationCompleted = () => ({
	type: events.EXT_CLP_RANK_UPDATE_COMPLETED,
	payload: {}
});

const getStepItemCount = (id, stepId, query) => ({
	type: events.GET_STEP_ITEM_COUNT,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/minimal/${stepId}${query ? `?${query}` : ""}`)
});

const getStepItemCountCompleted = () => ({
	type: events.GET_STEP_ITEM_COUNT_COMPLETED,
	payload: {}
});

const bulkDeleteOfClpItems = (treatmentId, payload) => ({
	type: events.BULK_DELETE_OF_CLP_ITEMS,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/masterMapping`, payload)
});

const bulkDeleteOfClpItemsCompleted = () => ({
	type: events.BULK_DELETE_OF_CLP_ITEMS_COMPLETED,
	payload: {}
});

export default {
	fetchAllExtClinicalPathway,
	clearFetchAllExtClinicalPathway,
	deleteExtClinicalPathway,
	clearDeleteExtClinicalPathway,
	updateExtClinicalPathway,
	clearUpdateExtClinicalPathway,
	fetchAllExtClinicalPathwayForType,
	clearFetchAllExtClinicalPathwayForType,
	createExtClinicalPathway,
	clearCreateExtClinicalPathway,
	fetchOneExtClinicalPathway,
	clearFetchOneExtClinicalPathway,
	getOneExtClp,
	clearExtClp,
	fetchSubStepDetails,
	clearFetchSubStepDetails,
	updateSubStepName,
	clearUpdateSubStepName,
	updateExtClinicalPathwayRank,
	clearUpdateExtClinicalPathwayRank,
	fetchAllExtClinicalPathwayResponsible,
	clearFetchAllExtClinicalPathwayResponsible,
	uploadReferenceFile,
	clearUploadReferenceFile,
	deleteReferenceFile,
	clearDeleteReferenceFile,
	fetchReferenceFile,
	clearFetchReferenceFile,
	fetchBgColor,
	clearFetchBgColor,
	createItem,
	clearCreateItem,
	updateItem,
	clearItemUpdate,
	duplicateItemMappingOnly,
	clearDuplicateItemMappingOnly,
	duplicateMapping,
	clearDuplicateMapping,
	extClinicalPathwayQuickEdit,
	clearExtClinicalPathwayQuickEdit,
	extClpItemOnlyQuickEdit,
	clearExtClpItemOnlyQuickEdit,
	extClpRankUpdation,
	extClpRankUpdationCompleted,
	getStepItemCount,
	getStepItemCountCompleted,
	bulkDeleteOfClpItems,
	bulkDeleteOfClpItemsCompleted
};
