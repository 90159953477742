import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllClinicalPathwayProblems = (queryString) => ({
	type: events.FETCH_ALL_CLINICAL_PATHWAY_PROBLEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/healthProblem${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllClinicalPathwayProblems = () => ({
	type: events.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_PROBLEM,
	payload: {}
});

const fetchAllClinicalPathwayStep = (queryString, meta = null) => ({
	type: events.FETCH_ALL_CLINICAL_PATHWAY_STEP,
	meta: meta,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/step${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllClinicalPathwayStep = () => ({
	type: events.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_STEP,
	payload: {}
});

const fetchAllMinimalStep = (queryString) => ({
	type: events.FETCH_ALL_MINIMAL_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/step${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllMinimalStep = () => ({
	type: events.CLEAR_FETCH_ALL_MINIMAL_STEP,
	payload: {}
});

const fetchAllMinimalSubStep = (queryString) => ({
	type: events.FETCH_ALL_MINIMAL_SUB_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/subStep${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllMinimalSubStep = () => ({
	type: events.CLEAR_FETCH_ALL_MINIMAL_SUB_STEP,
	payload: {}
});

const fetchOneClinicalPathwayProblem = (id) => ({
	type: events.FETCH_ONE_CLINICAL_PATHWAY_PROBLEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/healthProblem/${id}`)
});

const clearFetchOneClinicalPathwayProblem = () => ({
	type: events.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_PROBLEM,
	payload: {}
});

const updateClinicalPathwayProblem = (id, payload) => ({
	type: events.UPDATE_CLINICAL_PATHWAY_PROBLEM,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/healthProblem/${id}`, { ...payload })
});

const clearUpdateClinicalPathwayProblem = () => ({
	type: events.CLEAR_UPDATE_CLINICAL_PATHWAY_PROBLEM,
	payload: {}
});

const deleteClinicalPathwayProblem = (id) => ({
	type: events.DELETE_CLINICAL_PATHWAY_PROBLEM,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/healthProblem/${id}`)
});

const clearDeleteClinicalPathwayProblem = () => ({
	type: events.CLEAR_DELETE_CLINICAL_PATHWAY_PROBLEM,
	payload: {}
});

const fetchOneClinicalPathwayStep = (id) => ({
	type: events.FETCH_ONE_CLINICAL_PATHWAY_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/step/${id}`)
});

const clearFetchOneClinicalPathwayStep = () => ({
	type: events.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_STEP,
	payload: {}
});

const createClinicalPathwayProblem = (payload) => ({
	type: events.CREATE_CLINICAL_PATHWAY_PROBLEM,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/healthProblem`, { ...payload })
});

const clearCreateClinicalPathwayProblem = () => ({
	type: events.CLEAR_CREATE_CLINICAL_PATHWAY_PROBLEM,
	payload: {}
});

const createClinicalPathwayStep = (payload) => ({
	type: events.CREATE_CLINICAL_PATHWAY_STEP,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/step`, { ...payload })
});

const clearCreateClinicalPathwayStep = () => ({
	type: events.CLEAR_CREATE_CLINICAL_PATHWAY_STEP,
	payload: {}
});

const updateClinicalPathwayStep = (id, payload) => ({
	type: events.UPDATE_CLINICAL_PATHWAY_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/step/${id}`, { ...payload })
});

const clearUpdateClinicalPathwayStep = () => ({
	type: events.CLEAR_UPDATE_CLINICAL_PATHWAY_STEP,
	payload: {}
});

const deleteClinicalPathwayStep = (id) => ({
	type: events.DELETE_CLINICAL_PATHWAY_STEP,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/step/${id}`)
});

const clearDeleteClinicalPathwayStep = () => ({
	type: events.CLEAR_DELETE_CLINICAL_PATHWAY_STEP,
	payload: {}
});

const fetchAllClinicalPathwaySubStep = (queryString) => ({
	type: events.FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/subStep${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllClinicalPathwaySubStep = () => ({
	type: events.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP,
	payload: {}
});

const fetchOneClinicalPathwaySubStep = (stepId) => ({
	type: events.FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/subStep/${stepId}`)
});

const clearFetchOneClinicalPathwaySubStep = () => ({
	type: events.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP,
	payload: {}
});

const createClinicalPathwaySubStep = (stepId, payload) => ({
	type: events.CREATE_CLINICAL_PATHWAY_SUB_STEP,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/subStep/${stepId}`, { ...payload })
});

const clearCreateClinicalPathwaySubStep = () => ({
	type: events.CLEAR_CREATE_CLINICAL_PATHWAY_SUB_STEP,
	payload: {}
});

const updateClinicalPathwaySubStep = (subStepId, payload) => ({
	type: events.UPDATE_CLINICAL_PATHWAY_SUB_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/subStep/${subStepId}`, { ...payload })
});

const clearUpdateClinicalPathwaySubStep = () => ({
	type: events.CLEAR_UPDATE_CLINICAL_PATHWAY_SUB_STEP,
	payload: {}
});

const deleteClinicalPathwaySubStep = (subStepId) => ({
	type: events.DELETE_CLINICAL_PATHWAY_SUB_STEP,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/subStep/${subStepId}`)
});

const clearDeleteClinicalPathwaySubStep = () => ({
	type: events.CLEAR_DELETE_CLINICAL_PATHWAY_SUB_STEP,
	payload: {}
});

const fetchAllClinicalPathwayItems = (queryString) => ({
	type: events.FETCH_ALL_CLINICAL_PATHWAY_ITEMS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/items${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllClinicalPathwayItems = () => ({
	type: events.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_ITEMS,
	payload: {}
});

const fetchAllMinimalItem = (queryString) => ({
	type: events.FETCH_ALL_MINIMAL_ITEM,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/items${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllMinimalItem = () => ({
	type: events.CLEAR_FETCH_ALL_MINIMAL_ITEM,
	payload: {}
});

const fetchOneClinicalPathwayItems = (id) => ({
	type: events.FETCH_ONE_CLINICAL_PATHWAY_ITEMS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/items/${id}`)
});

const clearFetchOneClinicalPathwayItems = () => ({
	type: events.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_ITEMS,
	payload: {}
});

const createClinicalPathwayItems = (payload) => ({
	type: events.CREATE_CLINICAL_PATHWAY_ITEMS,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/items`, payload)
});

const clearCreateClinicalPathwayItems = () => ({
	type: events.CLEAR_CREATE_CLINICAL_PATHWAY_ITEMS,
	payload: {}
});

const updateClinicalPathwayItems = (id, payload) => ({
	type: events.UPDATE_CLINICAL_PATHWAY_ITEMS,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/items/${id}`, payload)
});

const clearUpdateClinicalPathwayItems = () => ({
	type: events.CLEAR_UPDATE_CLINICAL_PATHWAY_ITEMS,
	payload: {}
});

const deleteClinicalPathwayItems = (id) => ({
	type: events.DELETE_CLINICAL_PATHWAY_ITEMS,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/items/${id}`)
});

const clearDeleteClinicalPathwayItems = () => ({
	type: events.CLEAR_DELETE_CLINICAL_PATHWAY_ITEMS,
	payload: {}
});

const updateStepRank = (payload) => ({
	type: events.STEP_RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/step/updateRank`, { ...payload })
});

const clearUpdateStepRank = () => ({
	type: events.CLEAR_STEP_RANK_UPDATE,
	payload: {}
});

const updateSubStepRank = (payload) => ({
	type: events.SUB_STEP_RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/subStep/updateRank`, { ...payload })
});

const clearUpdateSubStepRank = () => ({
	type: events.CLEAR_SUB_STEP_RANK_UPDATE,
	payload: {}
});

const saveAndApproveStep = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/step/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveStepCompleted = () => ({
	type: events.SAVE_AND_APPROVE_STEP_COMPLETED,
	payload: {}
});

const assignAndApproveStep = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/step/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveStepCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_STEP_COMPLETED,
	payload: {}
});

const saveAndApproveSubStep = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_SUB_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/subStep/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveSubStepCompleted = () => ({
	type: events.SAVE_AND_APPROVE_SUB_STEP_COMPLETED,
	payload: {}
});

const assignAndApproveSubStep = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_SUB_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/subStep/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveSubStepCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_SUB_STEP_COMPLETED,
	payload: {}
});

const saveAndApproveItem = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_ITEM,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/items/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveItemCompleted = () => ({
	type: events.SAVE_AND_APPROVE_ITEM_COMPLETED,
	payload: {}
});

const assignAndApproveItem = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_ITEM,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/items/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveItemCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_ITEM_COMPLETED,
	payload: {}
});
const updateProblemListOrder = (payload) => ({
	type: events.CLNC_PATHWAY_PROBLEM_ORDER,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/healthProblem/updateRank`, { ...payload })
});

const clearUpdateProblemListOrder = () => ({
	type: events.CLEAR_CLNC_PATHWAY_PROBLEM_ORDER,
	payload: {}
});

const isStepIsUSedStatus = (id) => ({
	type: events.IS_STEP_IS_USED_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/step/${id}`)
});

const clearIsStepIsUSedStatus = () => ({
	type: events.CLEAR_IS_STEP_IS_USED_STATUS,
	payload: {}
});

export default {
	fetchAllClinicalPathwayProblems,
	clearFetchAllClinicalPathwayProblems,
	createClinicalPathwayProblem,
	clearCreateClinicalPathwayProblem,
	fetchOneClinicalPathwayProblem,
	clearFetchOneClinicalPathwayProblem,
	updateClinicalPathwayProblem,
	clearUpdateClinicalPathwayProblem,
	deleteClinicalPathwayProblem,
	clearDeleteClinicalPathwayProblem,
	fetchAllClinicalPathwayStep,
	clearFetchAllClinicalPathwayStep,
	fetchAllMinimalStep,
	clearFetchAllMinimalStep,
	fetchAllMinimalSubStep,
	clearFetchAllMinimalSubStep,
	fetchOneClinicalPathwayStep,
	clearFetchOneClinicalPathwayStep,
	createClinicalPathwayStep,
	clearCreateClinicalPathwayStep,
	updateClinicalPathwayStep,
	clearUpdateClinicalPathwayStep,
	deleteClinicalPathwayStep,
	clearDeleteClinicalPathwayStep,
	fetchAllClinicalPathwaySubStep,
	clearFetchAllClinicalPathwaySubStep,
	fetchOneClinicalPathwaySubStep,
	clearFetchOneClinicalPathwaySubStep,
	createClinicalPathwaySubStep,
	clearCreateClinicalPathwaySubStep,
	updateClinicalPathwaySubStep,
	clearUpdateClinicalPathwaySubStep,
	deleteClinicalPathwaySubStep,
	clearDeleteClinicalPathwaySubStep,
	fetchAllClinicalPathwayItems,
	clearFetchAllClinicalPathwayItems,
	fetchAllMinimalItem,
	clearFetchAllMinimalItem,
	fetchOneClinicalPathwayItems,
	clearFetchOneClinicalPathwayItems,
	createClinicalPathwayItems,
	clearCreateClinicalPathwayItems,
	updateClinicalPathwayItems,
	clearUpdateClinicalPathwayItems,
	deleteClinicalPathwayItems,
	clearDeleteClinicalPathwayItems,
	updateStepRank,
	clearUpdateStepRank,
	updateSubStepRank,
	clearUpdateSubStepRank,
	saveAndApproveStep,
	saveAndApproveStepCompleted,
	assignAndApproveStep,
	assignAndApproveStepCompleted,
	saveAndApproveSubStep,
	saveAndApproveSubStepCompleted,
	assignAndApproveSubStep,
	assignAndApproveSubStepCompleted,
	saveAndApproveItem,
	saveAndApproveItemCompleted,
	assignAndApproveItem,
	assignAndApproveItemCompleted,
	updateProblemListOrder,
	clearUpdateProblemListOrder,
	isStepIsUSedStatus,
	clearIsStepIsUSedStatus
};
