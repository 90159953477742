import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllEquipmentsCompleted: false,
	equipmentList: [],
	isFetchOneEquipmentCompleted: false,
	selectedEquipment: {},
	isUpdateEquipmentCompleted: false,
	updatedCategoryPersonnel: {},
	isCreateEquipmentCompleted: false,
	createdEquipment: {},
	isDeleteEquipmentCompleted: false,
	isDeleteSuccess: {}
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_EQUIPMENT}_PENDING`:
			return {
				...state,
				isFetchAllEquipmentsCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllEquipmentsCompleted: "FULFILLED",
					equipmentList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllEquipmentsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EQUIPMENT}_REJECTED`:
			return {
				...state,
				isFetchAllEquipmentsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EQUIPMENT:
			return {
				...state,
				isFetchAllEquipmentsCompleted: false
			};

		case `${eventNames.FETCH_ONE_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneEquipmentCompleted: "FULFILLED",
					selectedEquipment: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneEquipmentCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_EQUIPMENT}_REJECTED`:
			return {
				...state,
				isFetchOneEquipmentCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_EQUIPMENT:
			return {
				...state,
				isFetchOneEquipmentCompleted: false
			};
		case `${eventNames.UPDATE_EQUIPMENT}_PENDING`:
			return {
				...state,
				isUpdateEquipmentCompleted: "PENDING"
			};
		case `${eventNames.UPDATE_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateEquipmentCompleted: "FULFILLED",
					updatedCategoryPersonnel: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateEquipmentCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_EQUIPMENT}_REJECTED`:
			return {
				...state,
				isUpdateEquipmentCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_EQUIPMENT:
			return {
				...state,
				isUpdateEquipmentCompleted: false
			};

		case `${eventNames.CREATE_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isCreateEquipmentCompleted: "FULFILLED",
					createdEquipment: action.payload.data.data
				};
			}
			return {
				...state,
				isCreateEquipmentCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_EQUIPMENT}_REJECTED`:
			return {
				...state,
				isCreateEquipmentCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_EQUIPMENT:
			return {
				...state,
				isCreateEquipmentCompleted: false
			};
		case `${eventNames.DELETE_EQUIPMENT}_PENDING`:
			return {
				...state,
				isDeleteEquipmentCompleted: "PENDING"
			};
		case `${eventNames.DELETE_EQUIPMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isDeleteEquipmentCompleted: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isDeleteEquipmentCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_EQUIPMENT}_REJECTED`:
			return {
				...state,
				isDeleteEquipmentCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_EQUIPMENT:
			return {
				...state,
				isDeleteEquipmentCompleted: false
			};
		case `${eventNames.FETCH_ALL_EQUIPMENT_FOR_TREATMENTS}_PENDING`:
			return {
				...state,
				isFetchAllEquipmentForTreatmentCompleted: "PENDING"
			};
		case `${eventNames.FETCH_ALL_EQUIPMENT_FOR_TREATMENTS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllEquipmentForTreatmentCompleted: "FULFILLED",
					equipmentList: action.payload.data.data
				};
			}
			return {
				...state,
				equipmentList: [],
				isFetchAllEquipmentForTreatmentCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EQUIPMENT_FOR_TREATMENTS}_REJECTED`:
			return {
				...state,
				isFetchAllEquipmentForTreatmentCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EQUIPMENT_FOR_TREATMENTS:
			return {
				...state,
				isFetchAllEquipmentForTreatmentCompleted: false
			};
		default:
			return state;
	}
}
