import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../utils/utils";
import I18, { i18Get } from "../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../plug/regex-name-search-input";
import anaesthesiaAction from "../../../../../actions/anaesthesia";
import AnaesthesiaSelect from "./select-anaesthesia";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeActivity: utils.sameDropper(),
			value: 1,
			anaesthesiaName: "",
			anaesthesiaCode: "",
			invalidName: false,
			invalidCode: false,
			invalidAnaesthesia: false,
			selectedAnaesthesia: "",
			anaesthesiaDetails: {},
			treatmentId: "",
			specialityApproved: false,
			familyApproved: false,
			isloading: false,
			anaesthesiaFound: false,
			saveAndApproveLoading: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(anaesthesiaAction.getOneAnaesthesia(this.props.treatmentId, new Date().getTimezoneOffset()));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false, invalidCode: false, invalidFamily: false, anaesthesiaFound: false, value: 1 });
		this.props.refreshCompleted();
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.anaesthesiaFound = true;
			this.state.value = 2;
		}
	}

	onAnaesthesiaChange(e) {
		this.setState({ selectedAnaesthesia: e ? e : "", invalidAnaesthesia: e && e.value ? false : true });
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.anaesthesiaName.trim())) {
			prevState.invalidName = true;
		}
		if (!this.state.anaesthesiaCode) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateAnaesthesia = () => {
		if (this.state.selectedAnaesthesia && this.state.anaesthesiaFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		this.setState({ saveAndApproveLoading: true });
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.anaesthesiaName,
					code: this.state.anaesthesiaCode
				};
				this.props.dispatch(anaesthesiaAction.saveAndApproveAnaesthesia(this.state.anaesthesiaDetails.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateAnaesthesia()) {
				payload = {
					anaesthesiaId: this.state.selectedAnaesthesia && this.state.selectedAnaesthesia.value ? this.state.selectedAnaesthesia.value : ""
				};
				this.props.dispatch(anaesthesiaAction.assignAndApproveAnaesthesia(this.state.anaesthesiaDetails.id, payload));
			}
			else {
				this.setState({ invalidAnaesthesia: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.anaesthesiaName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.anaesthesiaCode = value;
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	componentDidUpdate() {
		if (this.props.treatmentId !== this.state.treatmentId) {
			this.state.treatmentId = this.props.treatmentId;
			this.props.dispatch(anaesthesiaAction.getOneAnaesthesia(this.props.treatmentId, new Date().getTimezoneOffset()));
		}
		if (this.props.visible) {
			this.state.modelVisible = this.props.visible;
		}
	}

	render() {
		const { value } = this.state;
		if (this.props.anaesthesia.asssignAndApproveAnaesthesiaStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, anaesthesiaName: "", anaesthesiaCode: "", anaesthesiaFound: false, value: 1 });
			this.props.dispatch(anaesthesiaAction.clearAssignAndApproveAnaesthesia());
			this.props.refreshCompleted();
		}
		if (this.props.anaesthesia.saveAndApproveAnaesthesiaStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, anaesthesiaName: "", anaesthesiaCode: "", anaesthesiaFound: false, value: 1, saveAndApproveLoading: true });
			this.props.dispatch(anaesthesiaAction.clearSaveAndApproveAnaesthesia());
			this.props.refreshCompleted();
		}

		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "FULFILLED") {
			this.state.anaesthesiaDetails = this.props.anaesthesia.selectedAnaesthesia ? this.props.anaesthesia.selectedAnaesthesia : "";
			this.state.anaesthesiaName = this.state.anaesthesiaDetails.name ? this.state.anaesthesiaDetails.name : "";
			this.state.anaesthesiaCode = this.state.anaesthesiaDetails.code ? this.state.anaesthesiaDetails.code : "";
			this.state.isloading = true;
			//this.state.selectedAnaesthesia = { label: this.state.anaesthesiaDetails.name ? this.state.anaesthesiaDetails.name : "", value: this.state.anaesthesiaDetails.id };
			this.state.specialityApproved = this.state.anaesthesiaDetails.specialityApproved ? this.state.anaesthesiaDetails.specialityApproved : false;
			this.state.familyApproved = this.state.anaesthesiaDetails.familyApproved ? this.state.anaesthesiaDetails.familyApproved : false;
			this.props.dispatch(anaesthesiaAction.clearGetOneAnaesthesia());
		}
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "REJECTED") {
			this.props.dispatch(anaesthesiaAction.clearGetOneAnaesthesia());
		}
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Anaesthesia", utils.getLanguage())}
					visible={this.state.modelVisible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel' />
						</Button>,
						this.state.specialityApproved && this.state.familyApproved ?
							<Button key="save" type="primary" loading={this.state.saveAndApproveLoading} onClick={this.saveClicked.bind(this)}>
								<I18 tkey='Save & Approve' />
							</Button> : ""
					]}
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative">
								<div className="col-md-3">
									<div className="form-group margin-bottom-0 pl-15">
										<label className="bold width-100"><I18 tkey="CODE" />:</label>
										<label className="width-100">{this.state.anaesthesiaDetails.code}</label>
									</div>
								</div>
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.anaesthesiaDetails.name}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.anaesthesiaDetails.hospitalName ? this.state.anaesthesiaDetails.hospitalName : ""}</p>
							</div>
							{this.state.anaesthesiaDetails.specialityName &&
                                <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="SPECIALITY NAME" />:</span> {this.state.anaesthesiaDetails.specialityName ? this.state.anaesthesiaDetails.specialityName : ""}</p>
							}
							{this.state.anaesthesiaDetails.familyName &&
                                <p className="padding-left-15 padding-right-15 margin-bottom-0 padding-bottom-10"><span className="bold"><I18 tkey="FAMILY NAME" />:</span> {this.state.anaesthesiaDetails.familyName ? this.state.anaesthesiaDetails.familyName : ""}</p>
							}
						</div>
						{this.state.specialityApproved && this.state.familyApproved && this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use new code or name" />
										</Radio>
									</div>
									<div className="col-md-4 pt-10 pr-0">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="CODE" />:</label>
											<NameInput
												value={this.state.anaesthesiaCode}
												inputChanged={this.codeChanged.bind(this)}
												disabled={true}
												maxLength={20}
											/>
											{this.state.invalidCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid anaesthesia code" /></span>}
										</div>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group ">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.anaesthesiaName}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={540}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid anaesthesia name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Anaesthesia" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<AnaesthesiaSelect defaultValue="Select Anaesthesia" style={{ width: "100%" }}
												data={this.state.selectedAnaesthesia}
												statusCheck={this.statusCheck.bind(this)}
												disabled={this.state.value != 2}
												onAnaesthesiaChange={this.onAnaesthesiaChange.bind(this)} />
											{this.state.invalidAnaesthesia && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select anaesthesia" /></span>}
										</div>
									</div>
								</div>
							</div>
							:
							<div className="invalid_label padding-top-7">
								{this.state.isloading && !this.state.specialityApproved ?
									<I18 tkey="The speciality for this anaesthesia is waiting for approval. Please approve the speciality first" />
									: this.state.isloading && !this.state.familyApproved ? <I18 tkey="The family for this anaesthesia is waiting for approval. Please approve the family first" /> : ""}
							</div>
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	anaesthesia: store.anaesthesia,
	consomableSterilization: store.consomableSterilization
}))(AssignAndApproveModel));    