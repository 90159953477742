import React, { Component } from "react";
import I18 from "../../../../../i18";
import Config from "../../../../../MOM-config";

export default class Landing extends Component {
	render() {
		return (
			<div className="login_header_section text_align_center">
				<img className="margin-bottom-10" src="images/logo.png" alt="" />
				<div className="text_bottom">
					<I18 tkey="Welcome to the MOM-Benchmark" />
					{" "}
				</div>
				<div className="text_bottom">
					<I18 tkey="Platform of" />
					{" "}
					<span>{Config.hospitalName}</span>
					{" "}
				</div>
			</div>
		);
	}
}
