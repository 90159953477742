import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllPersonnelTemplates = (queryString) => ({
	type: events.FETCH_ALL_PERSONNEL_TEMPLATES,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplate${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllPersonnelTemplates = () => ({
	type: events.CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES,
	payload: {}
});

const fetchAllMinimalPersonnelTemplates = (typeActivity, queryString) => ({
	type: events.FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL,
	payload: axios.get(`${Config.apiRootSecure}/CategoryPersonnelTemplate/typeActivity/${typeActivity}${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllMinimalPersonnelTemplates = () => ({
	type: events.CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL,
	payload: {}
});

const fetchOnePersonnelTemplate = (id) => ({
	type: events.FETCH_ONE_PERSONNEL_TEMPLATE,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplate/${id}`)
});

const clearFetchOnePersonnelTemplate = () => ({
	type: events.CLEAR_FETCH_ONE_PERSONNEL_TEMPLATE,
	payload: {}
});

const createPersonnelTemplate = (payload) => ({
	type: events.CREATE_PERSONNEL_TEMPLATE,
	payload: axios.post(`${Config.apiRootSecure}/categoryPersonnelTemplate`, { ...payload })
});

const clearCreatePersonnelTemplate = () => ({
	type: events.CLEAR_CREATE_PERSONNEL_TEMPLATE,
	payload: {}
});

const updatePersonnelTemplate = (id, payload) => ({
	type: events.UPDATE_PERSONNEL_TEMPLATE,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplate/${id}`, { ...payload })
});

const clearUpdatePersonnelTemplate = () => ({
	type: events.CLEAR_UPDATE_PERSONNEL_TEMPLATE,
	payload: {}
});

const deletePersonnelTemplate = (id) => ({
	type: events.DELETE_PERSONNEL_TEMPLATE,
	payload: axios.delete(`${Config.apiRootSecure}/categoryPersonnelTemplate/${id}`)
});

const clearDeletePersonnelTemplate = () => ({
	type: events.CLEAR_DELETE_PERSONNEL_TEMPLATE,
	payload: {}
});

const fetchAllTemplateDetails = (queryString) => ({
	type: events.FETCH_ALL_TEMPLATE_DETAILS,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllTemplateDetails = () => ({
	type: events.CLEAR_FETCH_ALL_TEMPLATE_DETAILS,
	payload: {}
});

const fetchOneTemplateDetails = (templateId) => ({
	type: events.FETCH_ONE_TEMPLATE_DETAILS,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${templateId}`)
});

const clearFetchOneTemplateDetails = () => ({
	type: events.CLEAR_FETCH_ONE_TEMPLATE_DETAILS,
	payload: {}
});

const createTemplateDetails = (templateId, payload) => ({
	type: events.CREATE_TEMPLATE_DETAILS,
	payload: axios.post(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${templateId}`, payload)
});

const clearCreateTemplateDetails = () => ({
	type: events.CLEAR_CREATE_TEMPLATE_DETAILS,
	payload: {}
});

const updateTemplateDetails = (templateId, payload) => ({
	type: events.UPDATE_TEMPLATE_DETAILS,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${templateId}`, payload)
});

const clearUpdateTemplateDetails = () => ({
	type: events.CLEAR_UPDATE_TEMPLATE_DETAILS,
	payload: {}
});

const deleteTemplateDetails = (templateId, personnelId) => ({
	type: events.DELETE_TEMPLATE_DETAILS,
	payload: axios.delete(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${personnelId}`)
});

const clearDeleteTemplateDetails = () => ({
	type: events.CLEAR_DELETE_TEMPLATE_DETAILS,
	payload: {}
});

const updateRank = (id, payload) => ({
	type: events.UPDATE_STAFF_RANK,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplate/categoryPersonnelTemplateMapping/${id}/rank`, payload)
});

const clearUpdateRank = () => ({
	type: events.CLEAR_UPDATE_STAFF_RANK,
	payload: {}
});

const fetchAllApprovedPersonnelTemplates = (queryString) => ({
	type: events.FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES,
	payload: axios.get(`${Config.apiRootSecure}/categoryPersonnelTemplate${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllApprovedPersonnelTemplates = () => ({
	type: events.CLEAR_FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES,
	payload: {}
});

const saveAndApprovePersonnelTemplate = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_PERSONNEL_TEMPLATES,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplate/${id}/saveAndApprove`, { ...payload })
});

const saveAndApprovePersonnelTemplateCompleted = () => ({
	type: events.SAVE_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED,
	payload: {}
});

const assignAndApprovePersonnelTemplate = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplate/${id}/assignAndApprove`, { ...payload })
});

const assignAndApprovePersonnelTemplateCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED,
	payload: {}
});

const fetchAllNewTemplates = (queryString) => ({
	type: events.FETCH_ALL_NEW_TEMPLATES,
	payload: axios.get(`${Config.apiRootSecure}/minimal/categoryPersonnel${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllNewTemplates = () => ({
	type: events.CLEAR_FETCH_ALL_NEW_TEMPLATES,
	payload: {}
});

const fetchAllExistingTemplates = (queryString) => ({
	type: events.FETCH_ALL_EXISTING_TEMPLATES,
	payload: axios.get(`${Config.apiRootSecure}/minimal/categoryPersonnel${queryString ? `?${queryString}` : ""}`)
});

const clearFetchAllExistingTemplates = () => ({
	type: events.CLEAR_FETCH_ALL_EXISTING_TEMPLATES,
	payload: {}
});

const saveAndApproveTemplate = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_TEMPLATES,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveTemplateCompleted = () => ({
	type: events.SAVE_AND_APPROVE_TEMPLATES_COMPLETED,
	payload: {}
});

const assignAndApproveTemplate = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_TEMPLATES,
	payload: axios.put(`${Config.apiRootSecure}/categoryPersonnelTemplateMapping/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveTemplateCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_TEMPLATES_COMPLETED,
	payload: {}
});

export default {
	fetchAllPersonnelTemplates,
	clearFetchAllPersonnelTemplates,
	fetchAllMinimalPersonnelTemplates,
	clearFetchAllMinimalPersonnelTemplates,
	fetchOnePersonnelTemplate,
	clearFetchOnePersonnelTemplate,
	createPersonnelTemplate,
	clearCreatePersonnelTemplate,
	updatePersonnelTemplate,
	clearUpdatePersonnelTemplate,
	deletePersonnelTemplate,
	clearDeletePersonnelTemplate,
	fetchAllTemplateDetails,
	clearFetchAllTemplateDetails,
	fetchOneTemplateDetails,
	clearFetchOneTemplateDetails,
	createTemplateDetails,
	clearCreateTemplateDetails,
	updateTemplateDetails,
	clearUpdateTemplateDetails,
	deleteTemplateDetails,
	clearDeleteTemplateDetails,
	updateRank,
	clearUpdateRank,
	fetchAllApprovedPersonnelTemplates,
	clearFetchAllApprovedPersonnelTemplates,
	saveAndApprovePersonnelTemplate,
	saveAndApprovePersonnelTemplateCompleted,
	assignAndApprovePersonnelTemplate,
	assignAndApprovePersonnelTemplateCompleted,
	fetchAllNewTemplates,
	clearFetchAllNewTemplates,
	fetchAllExistingTemplates,
	clearFetchAllExistingTemplates,
	saveAndApproveTemplate,
	saveAndApproveTemplateCompleted,
	assignAndApproveTemplate,
	assignAndApproveTemplateCompleted
};
