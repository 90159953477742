import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, Table, Checkbox } from "antd";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import { MenuOutlined } from "@ant-design/icons";
import ReactTable from "../../../../../../../plug/reactTable/react-table";
import column from "./column";
import DragWindow from "../../../../../../../plug/drag-window";
import Loader from "../../../../../../../plug/loader";
import { DebounceInput } from "react-debounce-input";
import arrayMove from "array-move";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import Pagination from "../../../../../../../plug/pagination";
import "./style.css";

const DragHandle = sortableHandle(() => (<MenuOutlined style={{ cursor: "grab", color: "#999" }} />));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

//max-height: calc(100vh - 250px);

class ModeList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			offset: 0,
			isLoading: true,
			search: null,
			clinicalPathway: [],
			filter: [],
			totalPages: 1,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			sort: "name",
			desc: 0,
			filterTab: "NOT_ADDED",
			totalCount: 0,
			linkedItems: 0,
			unlinkItems: 0,
			linkDataSession: { linked: [], unlink: [] },
			enableReorder: false,
			reorderList: []
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}


	onSortEnd = ({ oldIndex, newIndex }, list) => {
		if (oldIndex !== newIndex) {
			const prevState = this.state;
			prevState.clinicalPathway = arrayMove(
				[].concat(list),
				oldIndex,
				newIndex
			).filter((el) => !!el);

			Array.prototype.splice.apply(
				list,
				[0, prevState.clinicalPathway.length].concat(prevState.clinicalPathway)
			);
			this.setState({ clinicalPathway: prevState.clinicalPathway, orderChanged: true });
			this.reorderList(prevState.clinicalPathway);
		}
	};

	reorderList(list) {
		this.setState({ reorderList: list });
	}

	filterChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value;
		prevState.page = 1;
		this.setState(prevState, () => {
			this.fetchData();
		});
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.isLoading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "name";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchData();
	}

	toggleTab = (tab) => {
		const prevState = this.state;
		if (prevState.filterTab != tab) {
			prevState.linkedItems = 0;
			prevState.unlinkItems = 0;
			prevState.linkDataSession = { linked: [], unlink: [] };
			prevState.filterTab = tab;
			prevState.page = 1;
			prevState.clinicalPathway = [];
			this.setState({ prevState }, () => {
				this.fetchData();
			});
		}
	};

	usedModelSeparator = (data) => {
		if (data) {
			data.map((itam) => {
				if (itam.checked) {
					itam["linked"] = true;
				} else {
					itam["linked"] = false;
				}
				return itam;
			});
		}
		return data;
	};

	linkModelCount = () => {
		const prevState = this.state;
		prevState.unlinkItems = prevState.linkDataSession.unlink.length;
		prevState.linkedItems = prevState.linkDataSession.linked.length;
		this.setState(prevState);
	};

	isCheckedRows = (row) => {
		if (row.linked) {
			return !this.state.linkDataSession.unlink.includes(row.id);
		} else {
			return this.state.linkDataSession.linked.includes(row.id);
		}
	};

	onClickSaveOrder = () => {
		if (this.state.reorderList && this.state.reorderList.length < 1) {return;}
		const payload = this.state.reorderList.map((value, index) => {
			return { id: value.id, rank: index + 1 };
		});
		if (payload && payload.length > 0) {
			this.setState({ isReOrderUpdating: true });
			this.props.dispatch(
				//adminClinicalPathwayActions.updateProblemListOrder({ reOrderedData: payload })
			);
		}
	};

	fetchData() {
		this.setState({ isLoading: true });
		let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += `${this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : ""}`;
		queryString += `${this.state.search ? `search=${this.state.search}&` : ""}`;
		queryString += this.state.filterTab ? `type=${this.state.filterTab}` : "";
		if (this.props.filter) {
			Object.keys(this.props.filter).forEach((key) => {
				const data = this.props.filter[key];
				queryString += `${data ? `&${key}=${data}` : ""}`;
			});
		}
		if (this.props.category !== "model") {
			queryString += this.props.category === "checkList" ? "&isCheckList=1" : "&isCheckList=0";
		} else {
			queryString += "&isModel=1";
		}
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(clinicalPathwayAction.getAllItems(this.props.match.params.id, queryString, this.state.fetchAllMeta));
		});
	}

	oncheckRadio = (e) => {
		const prevState = this.state;
		if (e.target.checked == true) {
			prevState.linkDataSession.linked[0] = e.target.value;
			this.setState(prevState, () => { this.linkModelCount(); });

		}

	};
	onClick = (e, index) => {
		const prevState = this.state;
		const rowDataId = prevState.clinicalPathway[index]["id"];
		if (this.state.linkedItems <= 9 || e.target.checked == false || prevState.clinicalPathway[index]["linked"]) {
			prevState.clinicalPathway[index]["checked"] = e.target.checked;
			if (!prevState.clinicalPathway[index]["linked"]) {
				if (e.target.checked == true) {
					prevState.linkDataSession.linked.push(rowDataId);
				} else {
					const arrayIndex = prevState.linkDataSession.linked.indexOf(rowDataId);
					if (arrayIndex > -1) {
						prevState.linkDataSession.linked.splice(arrayIndex, 1);
					}
				}
			}
			if (prevState.clinicalPathway[index]["linked"]) {
				if (e.target.checked == true) {
					const arrayIndex = prevState.linkDataSession.unlink.indexOf(rowDataId);
					if (arrayIndex > -1) {
						prevState.linkDataSession.unlink.splice(arrayIndex, 1);
					}
				} else {
					prevState.linkDataSession.unlink.push(rowDataId);
				}
			}
			this.setState(prevState, () => { this.linkModelCount(); });
		} else {
			utils.showInfo(i18Get("Limited maximum selection to 10", utils.getLanguage()));
		}
	};

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.clinicalPathway, (this.state.page - 1), this.state.totalPages,
					this.state.isLoading, () => { return (<Loader loading={this.state.isLoading} isTable />); }, false,
					this.state.pageSize, true, this.onFetchData.bind(this),
					column(this.oncheckRadio, this.isCheckedRows.bind(this)),
					true, false,
					this.onReactTablePageChange.bind(this), this.props.category.toUpperCase(), "")}
				getTrProps={(state, rowInfo) => { if (rowInfo) { return { className: rowInfo.row.name.linked ? "blue_text" : "" }; } return {}; }}
			/>
		);
	}

	DraggableBodyRow = ({ ...restProps }, subStep) => {
		const index = subStep.findIndex(
			(x) => x.id === restProps["data-row-key"]
		);
		return <SortableItem index={index} {...restProps} />;
	};

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	getColumns = () => [
		{
			title: i18Get("", utils.getLanguage()),
			key: "action",

			render: (record) => (
				!this.state.enableReorder ?
					<div>
						<Checkbox checked={this.isCheckedRows(record) ? true : false} onChange={(e) => { this.onClick(e, this.state.clinicalPathway.indexOf(record)); }} />
					</div>
					: <DragHandle />
			),
			width: "80px"
		},
		{
			title: i18Get("NAME", utils.getLanguage()),
			dataIndex: "name",
			key: "name",
			render: (text, row) => (
				(row.hospitalName ? row.hospitalName + " " : "") + row.name
			)
		}
	];

	getAddedModelList() {
		return (
			<Table
				pagination={false}
				dataSource={this.state.clinicalPathway ? this.state.clinicalPathway : ""}
				columns={this.getColumns()}
				bordered
				rowKey="id"
				key={0}
				style={{ "min-height": "250px" }}
				components={{
					body: {
						wrapper: (e) => this.DraggableContainer(e, this.state.clinicalPathway),
						row: (e) => this.DraggableBodyRow(e, this.state.clinicalPathway ? this.state.clinicalPathway : null)
					}
				}}
			/>
		);
	}

	disableReorder = () => {
		this.setState({ enableReorder: false, isReOrderUpdating: false });
	};

	onReactTablePageChange(pageIndex) {
		const page = pageIndex + 1;
		this.onPageChange(page);
	}

	onPageChange(pageIndex) {

		this.state.page = pageIndex;
		this.setState({ ...this.state }, () => {
			
			this.onFetchData({ pageSize: this.state.pageSize }, null, { order: (this.state.desc == "ascend" ? 0 : 1), field: this.state.sort });
		});
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange(value) {
		this.setState({ ...this.state, pageSize: value, page: 1 }, () => { this.onPageChange(this.state.page); });
	}
	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "FULFILLED" && this.props.clinicalPathway.clinicalPathwayModelMeta === this.state.fetchAllMeta) {
			this.state.clinicalPathway = [];
			this.state.clinicalPathway = this.usedModelSeparator(this.props.clinicalPathway.clinicalPathwayModel.data);
			this.state.isLoading = false;
			this.state.totalCount = this.props.clinicalPathway.clinicalPathwayModel.count;
			this.state.totalPages = Math.ceil(this.props.clinicalPathway.clinicalPathwayModel.count / this.state.pageSize);
			this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
		}
		if (this.props.clinicalPathway.fetchAllClinicalPathwayModel === "REJECTED") {
			this.state.isLoading = false;
			this.state.totalPages = 1;
			this.props.dispatch(clinicalPathwayAction.clearGetAllItems());
		}

		if (this.state.filter !== this.props.filter) {
			this.setState({ filter: this.props.filter });
		}

		return (
			<div className={`w_100 di ${(this.props.className ? this.props.className : "")}`}>
				{
					<Modal
						visible={this.props.visible}
						className="cost_modal re_assign_modal clp_model_modal z-index-99"
						title={<div className="position_relative"><DragWindow title={i18Get("Select Models To Integrate", utils.getLanguage())} /> </div>}
						onCancel={() => { this.props.onCancel(false); }}
						wrapClassName="drag_modal"
						okText={"Apply"}
						onOk={() => this.props.onApplyChange(this.state.linkDataSession)}
						okButtonProps={{ disabled: (!this.state.linkedItems == 0 || !this.state.unlinkItems == 0 ? false : true) }}
					>

						<div className=" position_relative ">
							<div className='w_100 d-flex '>
								<div className="col-md-8 float_left padding-0">
									<div>
										<div className="">
											<div className="btn-tab-group spacer-top-10 marging-bottom--1">
												<button type="button" data-toggle="dropdown" onClick={() => this.toggleTab("NOT_ADDED")} className={`width_100 ${this.state.filterTab === "NOT_ADDED" ? " uppercase active-tab-btton" : " uppercase right-tab-button"}`}>
													<I18 tkey='NOT ADDED' />
												</button>
												<button type="button" onClick={() => this.toggleTab("ADDED")} className={`width_100 ${this.state.filterTab === "ADDED" ? " uppercase  active-tab-btton" : " uppercase center-tab-btn"}`}  >
													<I18 tkey='ADDED' />
												</button>
												{/* <button type="button" onClick={() => this.toggleTab('')} className={`width_100 ${this.state.filterTab === '' ? " uppercase active-tab-btton" : " uppercase  left-tab-button"}`}>
													<I18 tkey='ALL' />
												</button> */}
											</div>
										</div>
									</div>
								</div>
								{/* {this.state.filterTab === 'ADDED' ?
									!this.state.enableReorder ?
										<Button className="float_right"
											onClick={() => this.setState({ enableReorder: true })}><I18 tkey="Enable Reorder" />
										</Button>
										:
										<React.Fragment>
											<Button className="margin-left-10 float_right"
												disabled={this.state.isReOrderUpdating} onClick={this.disableReorder}><I18 tkey="Cancel Order" />
											</Button>
											<Button className="float_right"
												loading={this.state.isReOrderUpdating} onClick={this.onClickSaveOrder}>
												<span className="margin-left-10" >
													<I18 tkey="Save Order" />
												</span>
											</Button>
										</React.Fragment> : ''
								} */}
								<div className="col-md-4 float_right padding-0">
									<DebounceInput
										className="filter_input_design form-control"
										debounceTimeout={750}
										placeholder={i18Get("Search...", utils.getLanguage())}
										value={this.state.search}
										onChange={this.filterChanged.bind(this)} />
								</div>
							</div>
							<div className="w_100 risk_preparation_container">
								<div className="table_div risk_table model_modal_table">{this.state.filterTab !== "ADDED" ? this.getList() : this.getAddedModelList()}</div>
								{this.state.filterTab === "ADDED" && <Pagination
									page={this.state.page}
									totalPage={this.state.totalPages}
									pageSize={this.state.pageSize}
									onPageSizeChange={this.onPageSizeChange.bind(this)}
									onPageChange={this.onPageChange.bind(this)}
								/>}
								<div><span><I18 tkey='Total Model' />: <b>{this.state.totalCount}</b></span>
									{/* | <span><I18 tkey='Total Model Adding' />: <b>{this.state.linkedItems}</b></span>{this.state.filterTab !== 'NOT_ADDED' ? <span> | <I18 tkey='Total Model Removing' /> : <b>{this.state.unlinkItems}</b></span> : ''} */}
								</div>
							</div>
						</div>
					</Modal>
				}
			</div>
		);
	}
}

ModeList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	actions: store.actions
}))(ModeList));
