import React, { Component } from "react";
import "./left-container.css";

export default class Left extends Component {
	render() {
		return (
			<div className="hidden-xs col-sm-7 col-md-7 col-lg-8 h_100 no-padding di">
				<div className="left_image_container">
					<div className="bottom_text">
						{/* <div className="first_line">Lorem Ipsum is simply</div>
                        <div className="second_line"><span>consectetur.</span><span>adipisci.</span><span>quisquam</span></div> */}
					</div>
				</div>
			</div>
		);
	}
}
