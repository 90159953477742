import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import { CloseOutlined } from "@ant-design/icons";
import SimpleModeTable from "../../../../../../../plug/simple-mode-table";
import I18Plus from "../../../../../../../plug/i18Plus";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import { Checkbox } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = { viewModelList: false, showHideNotificaion: true, modelDetails: [], applyModelRankOrder: true };
	}

	componentDidMount() {
		this.fetchModelChangesDetails();
	}
	showHideNotification = (flag) => {
		this.setState({ showHideNotificaion: flag });
	};
	viewModelList() {
		this.setState({ viewModelList: !this.state.viewModelList });
	}

	fetchModelChangesDetails() {

		const query = `isClp=${this.props.clinicalPathwayData.modelChanges ? 1 : 0}`;
		const id = this.props.clinicalPathwayData.id;
		this.props.dispatch(clinicalPathwayAction.getAllApplayModelChangDetails(id, query));
	}

	render() {

		if (document.getElementsByClassName("problem_content_container").length > 0) {
			if (this.state.showHideNotificaion) {
				document.getElementsByClassName("problem_content_container")[0].setAttribute("class", "problem_content_container mode-notification-enabled");
			} else {
				document.getElementsByClassName("problem_content_container")[0].setAttribute("class", "problem_content_container");
			}
		}
		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwayProblemList === "FULFILLED" && !this.state.showHideNotificaion) {
			this.showHideNotification(true);
		}

		if (this.props.clinicalPathway.applayModelChangesDetails === "FULFILLED") {

			if (this.props.clinicalPathway.modelChangeDtails) {
				this.state.modelDetails = this.props.clinicalPathway.modelChangeDtails;
				this.state.modelDetails.map((data, index) => {
					return this.state.modelDetails[index] = { id: data.treatmentId, name: data.treatmentName };
				});
			}

			this.props.dispatch(clinicalPathwayAction.clearGetAllApplayModelChangDetails());

		}

		if (this.props.clinicalPathway.applayModelChangesDetails === "REJECTED") {
			this.props.dispatch(clinicalPathwayAction.clearGetAllApplayModelChangDetails());
		}

		return (
			this.state.showHideNotificaion &&
			<div className={"w_100 model-notification"}>
				<CloseOutlined title='close' className="close" onClick={() => { this.showHideNotification(false); }} />
				{
					//  <div className='padding-top-5'><I18Plus lword="changes" onClick={''} tkey="There are some changes in this Models. Do you want to apply the changes to all the linked Clinical pathway?" />//This text need to use
					//  <div className='padding-top-5'><I18Plus rindex={1} lword="changes" onClick={this.viewModelList.bind(this)} tkey="There are some changes in added Models. Do you want to apply the changes to the linked Clinical pathway?" />//this component need to use
					<div className='padding-top-5'>
						<div className='float_left'>
							{this.props.clinicalPathwayData.applyModelChanges &&
								<I18Plus rindex={1} lword="changes" onClick={this.viewModelList.bind(this)} tkey="There are some changes in added Models. Do you want to apply the changes to the linked Clinical pathway?" />
							}
							{this.props.clinicalPathwayData.modelChanges &&
								<I18Plus rindex={1} lword="changes" onClick={this.viewModelList.bind(this)} tkey="There are some changes in this Model. Do you want to apply the changes to all the linked Clinical pathways?" />
							}
						</div>< br />
						<div className='float_left margin-top-5 rank_checkbox'><Checkbox onChange={e => { this.setState({ applyModelRankOrder: e.target.checked }); }} checked={this.state.applyModelRankOrder}>&nbsp;&nbsp;<I18 tkey='Apply the task rank as well'/></Checkbox></div>
						<span><button disabled={this.props.isLoading} onClick={() => { this.props.modelChangesApply(this.state.applyModelRankOrder); }} >{ this.props.isLoading ? <LoadingOutlined style={{ fontSize: 20 }}  /> : "" }<I18 tkey={"Apply Changes"} /></button></span>
					</div>
				}
				{this.state.viewModelList && <SimpleModeTable data={this.state.modelDetails} title={`${this.props.clinicalPathwayData.modelChanges ? "Clinical Pathways" : "Models"}`} visible={this.state.viewModelList} onCancel={this.viewModelList.bind(this)} />}
			</div>
		);
	}
}

Notification.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequire,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	actions: store.actions,
	clinicalPathwayProblems: store.clinicalPathwayProblems
}))(Notification));
