import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import locations from "../../../../../../../../router/locations";
import ConsumableClassList from "./consumable-class";

export default class List extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route
					path={`${locations.consumable_definition_plain}/${locations.tabConsumableClass}`}
					render={() => <ConsumableClassList {...this.props} />}
				/>
				<Redirect to={`${locations.consumable_definition_plain}/${locations.tabConsumableClass}`} />
			</Switch>
		);
	}
}
