import React, { Component } from "react";
import Left from "./left-container";
import Right from "./right-container";
import "./landing.css";

export default class Landing extends Component {
	render() {
		return (
			<div className="container-fluid h_100">
				<div className="row h_100">
					<Left />
					<Right />
				</div>
			</div>
		);
	}
}
