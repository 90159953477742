import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import { Modal, Button } from "antd";

class ApplyChanges extends Component {
	constructor(props) {
		super(props);
	}

	getTable(list) {
		return (
			<table className="w_100 Assign_template_treatment_table_fcell simple_table">
				<thead>
					<tr>
						<th><I18 tkey="NAME" /></th>
					</tr>
				</thead>
				<tbody>
					{this.getTableContent(list)}
				</tbody>
			</table>
		);
	}
	getTableContent(list) {
		if (list.length > 0) {
			return list.map((data) => (<tr key={data.name}><td>{data.name}</td></tr>));
		}

		return (
			<tr>
				<td><div className="text_align_center w_100"><I18 tkey="NO DATA FOUND" /></div></td>
				{" "}
			</tr>
		);
	}
	render() {
		return (
			<span className=''>
				<Modal
					title={i18Get(this.props.isLoading ? "Model Linking..." : "Clinical Pathway List", utils.getLanguage())}
					visible={this.props.visible}
					wrapClassName="drag_modal"
					onCancel={this.props.onClose}
					footer={<div><Button onClick={this.props.onClose}><I18 tkey="Cancel" ></I18></Button><Button type="primary" onClick={() => { this.props.modelChangesApply(); }} loading={this.props.isLoading} ><span className="margin-left-10" ><I18 tkey={!this.props.isLoading ? "Apply Changes" : "Applying"} /></span></Button></div>}
				>
					{!this.props.isLoading ? <div>
						<div className="margin-top-o-15p margin-bottom-10 scroll_window">
							{this.getTable(this.props.data.modelLinkedClpList)}
						</div>
					</div>
						:
						<div className="alert margin-top-15 alert-info">
							<p> <I18 tkey="Linking the data of model will take some time. Do not close this tab until the process is completed." /></p>
						</div>}
				</Modal>

			</span>
		);
	}
}

ApplyChanges.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	actions: store.actions
}))(ApplyChanges));
