import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import eventNames from "../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../i18";
import interventionAction from "../../../../../../actions/intervention";
import ActionButton from "../../../../../../plug/action-button";
import utils from "../../../../../../utils/utils";
import Loader from "../../../../../../plug/loader";

class EditSubClinincPathway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subTreatment: {},
			invalidQuantity: false
		};
		// this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	// _handleKeyDown(e) {
	//     if (e.keyCode === 13) {
	//         this.saveClicked();
	//     }
	// }

	UNSAFE_componentWillMount() {
		this.props.dispatch(interventionAction.getOneSubtreatment(this.props.match.params.id, this.props.match.params.subTreatmentId, this.props.type));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	cancelClicked() {
		this.props.history.push(`/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}/sub_clinical_pathway/${this.props.type}${this.props.location.search}`);
	}

	onChange(value, e) {
		if (e.target.value.length <= 255) {
			this.state.subTreatment[value] = e.target.value;
			this.state.invalidDescription = false;
			this.setState(this.state);
		}
	}

	validateFields() {
		const prevState = this.state;
		if ((this.state.subTreatment.description ? !this.state.subTreatment.description.trim() || this.state.subTreatment.description.match(/\\$/) : false) || (this.state.subTreatment.description ? this.state.subTreatment.description.indexOf("\"") >= 0 : false)) {
			prevState.invalidDescription = true;
		}
		this.setState(prevState);
		if (!this.state.invalidDescription) {
			return true;
		}
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				description: this.state.subTreatment.description ? this.state.subTreatment.description.trim() : ""
			};
			this.props.dispatch(interventionAction.updateSubtreatment(this.props.type, this.props.match.params.id, payload, this.state.subTreatment.subTreatmentId));
		}
	}

	renderEdit() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-18">
								<input className="w_100 di form-control" value={this.state.subTreatment.subTreatmentName} readOnly />
							</div>
						</div>
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-18">
								<label className="common_label"><I18 tkey="Description" /></label>
								<textarea className="text_area_treatment form-control" maxLength={255} value={this.state.subTreatment.description} onChange={this.onChange.bind(this, "description")} />
								{this.state.invalidDescription && <span className="invalid invalid_color"><I18 tkey='Enter Valid Description (Special characters " \ not allowed)' /></span>}
							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<button className="edit_delete_button cancel_button margin-right-15" onClick={this.cancelClicked.bind(this)}><I18 tkey="Cancel" /></button>
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.intervention.getOneSubTreatmentStatus === "FULFILLED") {
			this.state.subTreatment = this.props.intervention.subTreatment;
			this.props.dispatch(interventionAction.getOneSubtreatmentCompleted());
		}
		if (this.props.intervention.getOneSubTreatmentStatus === "REJECTED") {
			this.props.dispatch(interventionAction.getOneSubtreatmentCompleted());
			this.cancelClicked();
		}

		if (this.props.intervention.editSubTreatmentStatus === "FULFILLED") {
			this.props.dispatch(interventionAction.updateSubtreatmentCompleted());
			this.cancelClicked();
		}

		if (this.state.subTreatment.treatmentId) {
			return this.renderEdit();
		}
		return (
			<div>
				<Loader loading isTable={false} />
			</div>
		);
	}
}

EditSubClinincPathway.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	intervention: store.intervention,
	chemotherapy: store.chemotherapy,
	radiotherapy: store.radiotherapy,
	consumable: store.consumable,
	imagery: store.imagery,
	translations: store.translations
}))(EditSubClinincPathway));
