 setInterval(() => {
    const virtual = document.getElementById('virtualDiagramScrollWrap');
    const vScrollContent = document.getElementById("virtual_scroll_content");
    const scrollContent = document.getElementById("diagram_backgroundLayer_svg");
    const getXPosition = () => {
      if(vScrollContent && scrollContent) {
        const  sContent = scrollContent.getBoundingClientRect();
        const sVirtual = virtual.getBoundingClientRect();
        vScrollContent.style.width=`${sContent.width}px`;
        if(sContent.width > sVirtual.width) {
          virtual.style.visibility = 'visible';
        }else {
          virtual.style.visibility = 'hidden';
        }

      }
    };

    getXPosition();
  }, 1000);