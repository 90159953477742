import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import sterilizationActions from "../../../../../../../../actions/sterilization";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import SpecialityCombo from "../specialityCombo";
import FamilyCombo from "../familyCombo";

export class CreatClass extends Component {

	constructor(props) {
		super(props);

		this.state = {
			specialityId: null,
			invalidName: false,
			invalidSpeciality: false,
			invalidFamily: false
		};
	}

	specialityChange = (e) => {
		if (e) {
			this.setState({ ...this.state, consumableSpeciality: e, consumableFamily: null, consumableFamilyId: null, consumableSpecialityId: e.id });
		} else {
			this.setState({ ...this.state, consumableSpeciality: null, consumableFamily: null, consumableFamilyId: null, consumableSpecialityId: null });
		}

	};

	familyChange = (e) => {
		if (e) {
			this.setState({ ...this.state, consumableFamily: e, consumableFamilyId: e.id });
		} else {
			this.setState({ ...this.state, consumableFamily: null, consumableFamilyId: null });
		}
		this.clearValidationMessage();
	};

	clearValidationMessage() {
		this.setState({
			invalidName: false,
			invalidSpeciality: false,
			invalidFamily: false
		});
	}

	onNameChange = (value) => {
		this.setState({ ...this.state, name: value });
		this.clearValidationMessage();
	};

	onCodeChange = (value) => {
		this.setState({ ...this.state, code: value });
		this.clearValidationMessage();
	};

	toggleisSubsidised = (e) => {
		this.setState({ ...this.state, isSubsidised: e.target.checked });
		this.clearValidationMessage();
	};

	validate = () => {
		let flag = true;
		if (!(this.state.name ? this.state.name.trim() : null) || !utils.checkConsomableName(this.state.name)) {
			this.state.invalidName = true;
			flag = false;
		} else {
			this.state.invalidName = false;
		}
		if (!this.state.consumableSpecialityId || this.state.consumableSpecialityId == null) {
			this.state.invalidSpeciality = true;
			flag = false;
		} else {
			this.state.invalidSpeciality = false;
		}

		if (!this.state.consumableFamilyId) {
			this.state.invalidFamily = true;
			flag = false;
		} else {
			this.state.invalidFamily = false;
		}
		this.setState({ ...this.state });
		return flag;
	};

	createClicked = () => {

		if (this.validate()) {
			const { name, consumableSpecialityId, consumableFamilyId, code } = this.state;
			const payload = { name, consumableSpecialityId, consumableFamilyId, code };
			payload.type = "STERILIZATION";
			this.props.dispatch(sterilizationActions.createClassSterilization(payload));
		}
	};

	render() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.onNameChange}
									maxLength={120} />
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Sterilization Speciality" /></label>
								<SpecialityCombo autoSetSingleOne={true} specialityChange={this.specialityChange} data={this.state.consumableSpeciality} />
								{this.state.invalidSpeciality && <span className='invalid_right fixed_label'><I18 tkey="Please select speciality" /></span>}
							</div>
						</div>

						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Sterilization Family" /></label>
								<FamilyCombo autoSetSingleOne={true} familyChange={this.familyChange} data={this.state.consumableFamily}
									consumableSpecialityId={this.state.consumableSpecialityId} />
								{this.state.invalidFamily && <span className='invalid_right fixed_label'><I18 tkey="Please select family" /></span>}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<NameInput
									value={this.state.code}
									inputChanged={this.onCodeChange}
									maxLength={14} />
							</div>
						</div>
						<div className="col-md-12 col-sm-12 margin-top-25-check">
							<div className="di w_100 text_align_right">
								{/* <Checkbox className="margin-top-7 float_left" onChange={this.toggleisSubsidised} checked={this.state.isSubsidised}><I18 tkey="Subsidised" /></Checkbox> */}
								<ActionButton showAdd={true} onClick={this.createClicked} value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>

					</div>
				</div>
			</div >
		);
	}

	componentDidUpdate() {
		if (this.props.sterilization.createdSterilizationClassStatus == "FULFILLED") {
			this.setState({ name: "", consumableSpecialityId: "", consumableFamilyId: "" },
				() => {
					this.props.dispatch(sterilizationActions.createClassSterilizationCompleted());
					this.props.history.push(`${locations.consumable_sterilization_root}/${locations.reusableMedicalDevices_class_tab}`);
				});
		}

		if (this.props.sterilization.createdSterilizationClassStatus == "REJECTED") {
			this.props.dispatch(sterilizationActions.createClassSterilizationCompleted());
		}

	}
}

CreatClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	sterilization: store.sterilization,
	translations: store.translations
}))(CreatClass)); 