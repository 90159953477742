import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox, Tooltip } from "antd";
import adminPersonnelActions from "../../../../../../../../../actions/admin-personnel";
import ActionButton from "../../../../../../../../../plug/action-button";
import locations from "../../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import events from "../../../../../../../../../actions/events";
import screenId from "../../../../../../../../../router/screenIdList";
import CategoryPersonnelDropdown from "../../../category-personnels/categoryPersonnelDropdown";
import PersonnelNumberDropdown from "./personnel-number-dropdown";
import NumberInput from "../../../../../../../../../plug/numeric-input";

class CreateTemplateDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			categoryPersonnelName: null,
			categoryPersonnelNumber: 1,
			customTime: 0,
			isBlocOperative: false,
			isBlocPeriOperative: false,
			isBlocSupport: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.state.template = this.props.template ? this.props.template : utils.checkForParameter("templateId", this.props.location);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelCreate });
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.createClicked();
		}
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	categoryPersonnelNameChanged(e) {
		const prevState = this.state;
		prevState.categoryPersonnelName = e;
		prevState.invalidCategoryPersonnelName = false;
		this.setState(prevState);
	}

	activityStepNameChanged(e) {
		const prevState = this.state;
		prevState.activityStepName = e;
		this.setState(prevState);
	}

	categoryPersonnelNumberChanged(e) {
		const prevState = this.state;
		prevState.categoryPersonnelNumber = e;
		prevState.invalidCategoryPersonnelNumber = false;
		this.setState(prevState);
	}

	getNumericValue(label, value) {
		const prevState = this.state;
		prevState[label] = parseInt(value);
		if (label === "customTime" && parseInt(value) > 0) {
			prevState.isBlocOperative = false;
			prevState.isBlocPeriOperative = false;
			prevState.isBlocSupport = false;
		}
		prevState.invalidCheckbox = false;
		this.setState(prevState);
	}

	handleCheckboxChange(field) {
		return (e) => {
			const prevState = this.state;
			prevState[field] = e.target.checked;
			prevState.invalidCheckbox = false;
			this.setState(prevState);
		};
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.categoryPersonnelName || !this.state.categoryPersonnelName.value) {
			prevState.invalidCategoryPersonnelName = true;
		}
		if (!(this.state.customTime > 0) && !this.state.isBlocOperative && !this.state.isBlocPeriOperative && !this.state.isBlocSupport) {
			prevState.invalidCheckbox = true;
		}
		if (this.state.invalidCategoryPersonnelName || this.state.invalidCategoryPersonnelNumber || this.state.invalidCheckbox) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				categoryPersonnelId: this.state.categoryPersonnelName.value,
				numberOfPersonnels: this.state.categoryPersonnelNumber,
				customTime: this.state.customTime,
				isBlocOperative: this.state.isBlocOperative,
				isBlocPeriOperative: this.state.isBlocPeriOperative,
				isBlocSupport: this.state.isBlocSupport,
				typeActivity: this.state.typeActivity
			};
			this.props.dispatch(adminPersonnelActions.createTemplateDetails(this.state.template, payload));
		}
	}

	render() {
		if (this.props.adminPersonnel.createTemplateDetailsCompleted === "FULFILLED") {
			this.props.dispatch(adminPersonnelActions.clearCreateTemplateDetails());
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabTemplateDetails}${this.props.location.search}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminPersonnel.createTemplateDetailsCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearCreateTemplateDetails());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="bloc_create_section">
				<div className="bloc_create_box padding-top-10">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Category Personnel Name2" /></label>
								<CategoryPersonnelDropdown
									data={this.state.categoryPersonnelName}
									categoryPersonnelChanged={this.categoryPersonnelNameChanged.bind(this)}
								/>
								{this.state.invalidCategoryPersonnelName
									? <span className="invalid_right fixed_label"><I18 tkey="Select a category personnel" /></span> : ""}
							</div>
						</div>

						{this.state.typeActivity != "RADIOTHERAPY"
							&& this.state.typeActivity != "CHEMOTHERAPY"
							&& this.state.typeActivity != "IMAGERY"
							? (
								<div className="col-md-6 col-sm-6">
									<div className="w_100 di margin-bottom-20 position_relative">
										<label><I18 tkey="Category Personnel Number" /></label>
										<PersonnelNumberDropdown
											categoryPersonnelNumber={this.state.categoryPersonnelNumber}
											categoryPersonnelNumberChanged={this.categoryPersonnelNumberChanged.bind(this)}
										/>
										{this.state.invalidCategoryPersonnelNumber
											? <span className="invalid_right fixed_label"><I18 tkey="Select quantity" /></span> : ""}
									</div>
								</div>
							)
							: ""
						}
					</div>
					<div className="row">
						<div className="col-md-4 col-lg-6 ">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Fixed Duration" /></label>
								<NumberInput
									integerOnly
									label="customTime"
									value={this.state.customTime}
									getNumericValue={this.getNumericValue.bind(this)}
								/>
							</div>
						</div>
						<div className="col-lg-6 col-md-8 col-sm-8 margin-top-33 position_relative">
							{this.state.invalidCheckbox
								? <span className="invalid_check_box fixed_label"><I18 tkey="Select atleast one duration" /></span>
								: ""}
							{this.state.typeActivity !== "CLINICAL_PATHWAY"
								&& (
									<div className="row">
										<div className="template_details_check_box_width_30">
											<Tooltip title={i18Get("Operative time of the surgery", utils.getLanguage())} placement="top">
												<Checkbox
													checked={this.state.isBlocOperative}
													disabled={this.state.customTime > 0}
													onChange={this.handleCheckboxChange("isBlocOperative").bind(this)}
												>
													<I18 tkey="Bloc Operatoire" />
												</Checkbox>
											</Tooltip>
										</div>
										<div className="template_details_check_box_width_40">
											<Tooltip title={i18Get("Preoperative time of the surgery", utils.getLanguage())} placement="top">
												<Checkbox
													checked={this.state.isBlocPeriOperative}
													disabled={this.state.customTime > 0}
													onChange={this.handleCheckboxChange("isBlocPeriOperative").bind(this)}
												>
													<I18 tkey="Bloc Peri Operatoire" />
												</Checkbox>
											</Tooltip>
										</div>
										<div className="template_details_check_box_width_30">
											<Tooltip title={i18Get("Support time of the surgery", utils.getLanguage())} placement="top">
												<Checkbox
													checked={this.state.isBlocSupport}
													disabled={this.state.customTime > 0}
													onChange={this.handleCheckboxChange("isBlocSupport").bind(this)}
												>
													<I18 tkey="Bloc Support" />
												</Checkbox>
											</Tooltip>
										</div>
									</div>
								)}
						</div>

						<div className="col-lg-12 col-md-12 padding-bottom-10">
							<div className="di w_100 text_align_right">
								<ActionButton
									showAdd
									onClick={this.createClicked.bind(this)}
									value={i18Get("CREATE", utils.getLanguage())}
								/>
							</div>
						</div>

					</div>

				</div>
			</div>

		);
	}
}

CreateTemplateDetails.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminPersonnel: store.adminPersonnel,
	categoryPersonnel: store.categoryPersonnel,
	actions: store.actions
}))(CreateTemplateDetails));
