import React, { useState, useEffect } from "react";
import { Input, Slider, Tooltip, Select } from "antd";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import { VerticalLine } from "utils/icons";
import BgColorPicker from "../settings/bgColorPicker";
import "./style.css";
import {
	BoldOutlined,
	ItalicOutlined,
	UnderlineOutlined,
	AlignLeftOutlined,
	AlignCenterOutlined,
	AlignRightOutlined,
	VerticalAlignTopOutlined,
	VerticalAlignMiddleOutlined,
	VerticalAlignBottomOutlined
} from "@ant-design/icons";
import ColorPickerFiled from "../settings/ColorPickerFiled";
import Duration from "../edit-task/duration";


const EditData = (props) => {
	const { TextArea } = Input;
	const [customData, setCustomData] = useState({});

	useEffect( () => {
		setCustomData(props.diagramData);
	}, [props.diagramData]);

	function onChangeData (data, field ) {
		props.onChange(data, field);
	}

	const colorPicker = (activity, color) => {
		props.onChange(color, activity);
	};
	const onSlide = (e) => {
		props.onChange(e, "arrow_strokeWidth");
	};

	return <div>
		<div className="margin-top-5">
			<div className="card-container">
				<div className="d-flex justify-content-between">
					<div className="d-flex">
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => {onChangeData("Right", "horizontalAlignment"); onChangeData("Left", "textAlign");}}>
							<AlignLeftOutlined style={{
								fontSize: "17px",
								color: customData.textAlign === "left" ? "#3740F2" : "#000000"
							}} />
						</div>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => { onChangeData("Center", "horizontalAlignment"); onChangeData("Center", "textAlign");}}>
							<AlignCenterOutlined style={{
								fontSize: "17px",
								color: customData.textAlign === "center" ? "#3740F2" : "#000000"
							}} />
						</div>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => {onChangeData("Left", "horizontalAlignment"); onChangeData("Right", "textAlign");}}>
							<AlignRightOutlined style={{
								fontSize: "17px",
								color: customData.textAlign === "right" ? "#3740F2" : "#000000"
							}} />
						</div>
						<VerticalLine width="2" height="22" style={{ marginLeft: "10px" }}/>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => {onChangeData("Bottom", "verticalAlignment");}}>
							<VerticalAlignTopOutlined  style={{
								fontSize: "17px",
								color: customData.verticalAlignment === "Bottom" ? "#3740F2" : "#000000"
							}} />
						</div>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => { onChangeData("Center", "verticalAlignment");}}>
							<VerticalAlignMiddleOutlined style={{
								fontSize: "17px",
								color: customData.verticalAlignment === "Center" || !customData.verticalAlignment ? "#3740F2" : "#000000"
							}} />
						</div>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => {onChangeData("Top", "verticalAlignment");}}>
							<VerticalAlignBottomOutlined style={{
								fontSize: "17px",
								color: customData.verticalAlignment === "Top" ? "#3740F2" : "#000000"
							}} />
						</div>
						<div className="margin-left-5 edit_task_font popup_modal_content_input height_24_input">
							<Input type="number" value={!isNaN(customData.fontSize) ? customData.fontSize : 12 } min={4} onChange={e => onChangeData(e.target.value, "fontSize")} />
						</div>
						<div className={`font_type_edit_task  ${customData.bold ? "style_applied" : ""}`} onClick={() => onChangeData(!customData.bold, "bold")}>
							<BoldOutlined />
						</div>
						<div className={`font_type_edit_task  ${customData.italic ? "style_applied" : ""}`} onClick={() => onChangeData(!customData.italic, "italic")}>
							<ItalicOutlined />
						</div>
						<div className={`font_type_edit_task  ${customData.underline ? "style_applied" : ""}`} onClick={() => onChangeData(customData.underline ? "" : "underline", "underline")}>
							<UnderlineOutlined />
						</div>
						{/* <div className={`font_type_edit_task ${customData.underline ? "style_applied" : ""}`} onClick={() => props.colorPickerHandler("showShapeTextColorPicker")}>
							<FontColorsOutlined />
							<div style={{ borderBottom: `3px solid ${customData.color}`, position: "relative", bottom: "3px" }}></div>
						</div> */}
					</div>
				</div>
				<div className="d-flex margin-top-10">
					<Tooltip placement="top" title={i18Get("Title", utils.getLanguage())} >
						<TextArea autoFocus
							placeholder={i18Get("Title", utils.getLanguage())}
							className="w_90 edit-task-input-field"
							onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
							style={{
								height: "60px",
								resize: "none",
								color: customData.color,
								fontWeight: customData.bold ? "bold" : "normal",
								fontStyle: customData.italic ? "italic" : "normal",
								textDecoration: customData.underline ? "underline" : "none",
								fontSize: parseFloat(customData.fontSize ? customData.fontSize : 12),
								fontFamily: customData.fontFamily,
								textAlign: customData.textAlign ? customData.textAlign : "center"
							}}
							maxLength={505}
							type="text" draggable={false} 
							value={customData ? customData.title : ""}
							onChange={(e) => {onChangeData(e, "title");}}
						/>
					</Tooltip>
				</div>
				
				<Duration  key={customData.id} data={customData} onChange={props.onChange}/>
				<div className="w_90">
					<BgColorPicker 
						onClicked={colorPicker} 
						fill={customData.fill} 
						strokeColor={customData.strokeColor} 
						color={customData.color}
						strokeColorDisabled={true} />
					<ColorPickerFiled 
						onClicked={colorPicker} 
						activity={"arrow_strokeColor"}
						title="Connector Width"
						color={props.selectedShape.strokeColor ? props.selectedShape.strokeColor : "#757575"}
					/>
					<div>
						<Slider  min={1} max={5} range defaultValue={1} value={props.selectedShape.strokeWidth} onChange={onSlide} />
					</div>
				</div>
			</div>
		</div>
	</div>;
};
export default EditData;