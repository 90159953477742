import connectorSymbols  from "./connectorSymbols";
import flowshapes  from "./flowshapes";
import basicShapes  from "./basicShapes";
import { i18Get } from "i18";
import utils from "utils/utils";

export  default [
	{
		id: "flow",
		expanded: true,
		symbols: flowshapes,
		iconCss: "e-diagram-icons1 e-diagram-flow",
		title: i18Get("Flow Shapes", utils.getLanguage())
	},
	{
		id: "basic",
		expanded: true,
		symbols: basicShapes,
		iconCss: "e-diagram-icons1 e-diagram-basic",
		title: i18Get("Basic Shapes", utils.getLanguage())
	},
	{
		id: "connectors",
		expanded: true,
		symbols: connectorSymbols,
		iconCss: "e-diagram-icons1 e-diagram-connector",
		title: i18Get("Connectors", utils.getLanguage())
	}
];