import events from "../../actions/events";

const initState = { };

export default (state = initState, action) => {
	switch (action.type) {
		case `${events.STERILIZATION_GET_ALL}_FULFILLED`:
			return { ...state, sterilization: action.payload.data.data, getSterilizationStatus: "FULFILLED" };
		case `${events.STERILIZATION_GET_ALL}_REJECTED`:
			return { ...state, getSterilizationStatus: "REJECTED" };
		case events.CLEAR_STERILIZATION_FETCH:
			return { ...state, getSterilizationStatus: false };

		case `${events.STERILIZATION_GET_ONE}_FULFILLED`:
			return { ...state, sterilizationSelectData: action.payload.data.data, getOneSterilizationStatus: "FULFILLED" };
		case `${events.STERILIZATION_GET_ONE}_REJECTED`:
			return { ...state, getOneSterilizationStatus: "REJECTED" };
		case events.CLEAR_STERILIZATION_GET_ONE:
			return { ...state, getOneSterilizationStatus: false };

		case `${events.CREATE_STERILIZATION_CONSO}_FULFILLED`:
			return { ...state, createSterilatiionSpecialityStatus: "FULFILLED" };
		case `${events.CREATE_STERILIZATION_CONSO}_REJECTED`:
			return { ...state, createSterilatiionSpecialityStatus: "REJECTED" };
		case events.COMPLETE_CREATE_STERILIZATION_CONSO:
			return { ...state, createSterilatiionSpecialityStatus: false };

		case `${events.EDIT_STERILIZATION_CONSO}_FULFILLED`:
			return { ...state, editSterilatiionSpecialityStatus: "FULFILLED" };
		case `${events.EDIT_STERILIZATION_CONSO}_REJECTED`:
			return { ...state, editSterilatiionSpecialityStatus: "REJECTED" };
		case events.COMPLETE_EDIT_STERILIZATION_CONSO:
			return { ...state, editSterilatiionSpecialityStatus: false };

		case `${events.DELETE_STERILIZATION_CONSO}_FULFILLED`:
			return { ...state, deleteSterilatiionSpecialityStatus: "FULFILLED" };
		case `${events.DELETE_STERILIZATION_CONSO}_REJECTED`:
			return { ...state, deleteSterilatiionSpecialityStatus: "REJECTED" };
		case events.COMPLETED_DELETE_STERILIZATION_CONSO:
			return { ...state, deleteSterilatiionSpecialityStatus: false };

		case `${events.STERILIZATION_FAMILY_GET_ALL}_FULFILLED`:
			return { ...state, family: action.payload.data.data, getFamilyStatus: "FULFILLED" };
		case `${events.STERILIZATION_FAMILY_GET_ALL}_REJECTED`:
			return { ...state, getFamilyStatus: "REJECTED" };
		case events.CLEAR_STERILIZATION_FAMILY_FETCH:
			return { ...state, getFamilyStatus: false };

		case `${events.STERILIZATION_FAMILY_GET_ONE}_FULFILLED`:
			return { ...state, selectedFamily: action.payload.data.data, selectedFamilyStatus: "FULFILLED" };
		case `${events.STERILIZATION_FAMILY_GET_ONE}_REJECTED`:
			return { ...state, selectedFamilyStatus: "REJECTED" };
		case events.CLEAR_STERILIZATION_FAMILY_ONE:
			return { ...state, selectedFamilyStatus: false };

		case `${events.CREATE_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, createFamilyStatus: "FULFILLED" };
		case `${events.CREATE_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, createFamilyStatus: "REJECTED" };
		case events.CREATE_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, createFamilyStatus: false };

		case `${events.UPDATE_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, updateFamilyStatus: "FULFILLED" };
		case `${events.UPDATE_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, updateFamilyStatus: "REJECTED" };
		case events.UPDATE_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, updateFamilyStatus: false };

		case `${events.DELETE_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, deleteFamilyStatus: "FULFILLED" };
		case `${events.DELETE_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, deleteFamilyStatus: "REJECTED" };
		case events.DELETE_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, deleteFamilyStatus: false };

		case `${events.GET_ALL_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, getAllSterilizationFamilyStatus: "FULFILLED", getAllSterilizationFamily: action.payload.data.data };
		case `${events.GET_ALL_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, getAllSterilizationFamilyStatus: "REJECTED" };
		case events.GET_ALL_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, getAllSterilizationFamilyStatus: false };

		case `${events.SAVE_AND_APPROVE_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, saveAndApproveSterilizationFamilyStatus: "FULFILLED" };
		case `${events.SAVE_AND_APPROVE_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, saveAndApproveSterilizationFamilyStatus: "REJECTED" };
		case events.SAVE_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, saveAndApproveSterilizationFamilyStatus: false };

		case `${events.ASSIGN_AND_APPROVE_STERILIZATION_FAMILY}_FULFILLED`:
			return { ...state, asssignAndApproveSterilizationFamilyStatus: "FULFILLED" };
		case `${events.ASSIGN_AND_APPROVE_STERILIZATION_FAMILY}_REJECTED`:
			return { ...state, asssignAndApproveSterilizationFamilyStatus: "REJECTED" };
		case events.ASSIGN_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED:
			return { ...state, asssignAndApproveSterilizationFamilyStatus: false };

		case `${events.GET_MINIMAL_CONSO_SPECIALITY}_FULFILLED`:
			return { ...state, consoSpeciality: action.payload.data.data, getconsoSpecialityStatus: "FULFILLED" };
		case `${events.GET_MINIMAL_CONSO_SPECIALITY}_REJECTED`:
			return { ...state, getconsoSpecialityStatus: "REJECTED" };
		case events.CLEAR_GET_MINIMAL_CONSO_SPECIALITY:
			return { ...state, getconsoSpecialityStatus: false };

		case `${events.GET_MINIMAL_CONSO_FAMILY}_FULFILLED`:
			return { ...state, consofamily: action.payload.data.data, getconsofamilyStatus: "FULFILLED" };
		case `${events.GET_MINIMAL_CONSO_FAMILY}_REJECTED`:
			return { ...state, getconsofamilyStatus: "REJECTED" };
		case events.CLEAR_GET_MINIMAL_CONSO_FAMILY:
			return { ...state, getconsofamilyStatus: false };
		default: return state;
	}
};
