import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import locations from "../../../../../../../router/locations";
import Anaesthesia from "../v4/anaesthesia";
import Staff from "../v4/staff";
import Extelements from "./extelements";
import interventionDetailsAction from "../../../../../../../actions/intervention-details";
import interventionAction from "../../../../../../../actions/intervention";
import extelementConstants from "../../../../../../../constants/extElements.json";
import AssignAndApprovePopup from "./extelements/assign-approve-popup";
class InterventionDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnaesthesia: true,
			showPrice: true,
			showStaff: true,
			showCostIntervention: false,
			intervention: {},
			classOrComplete: "",
			showAssignAndApprove: false,
			selElementId: null,
			type: null,
			name: null,
			code: null,
			exType: null
		};
	}

	UNSAFE_componentWillMount() {

		//this.props.dispatch(interventionDetailsAction.fetchAllExtendedElements(this.props.match.params.id, this.props.doctorId, this.props.category))
	}

	getQueryParams(doctorId) {
		return `${this.props.location.search ? `${this.props.location.search}${doctorId && (this.props.location.search.indexOf("doctorId=") === -1) ? `doctorId=${this.props.doctorId}` : ""}` : `${doctorId ? `?doctorId=${this.props.doctorId}` : ""}`}`;
	}

	editClicked(link) {
		this.props.history.push(this.props.location.pathname + locations.extElement + "/" + link + this.getQueryParams(this.props.doctorId));
	}

	getExtElements() {
		return extelementConstants.type.map((type, index) => {
			return (
				<div key={index + "_"}>
					<div className="">
						<div className="table_section di w_100">
							<Extelements key={index} onAssignAndApproveClick={this.onAssignAndApproveClick} type={type} showTotal={this.state.showPrice} classOrComplete={this.state.classOrComplete} />
						</div>
					</div>
					<div className="">
						<div className="text_align_right di w_100   margin-bottom-40">
							<button className="common_button pumpkin" onClick={this.editClicked.bind(this, type.toLowerCase())}><I18 tkey="ADD / EDIT" /></button>
						</div>
					</div>
				</div>
			);
		});
	}

	onAssignAndApproveClick = (elementId, exType, name, code, type) => {
		this.setState({ showAssignAndApprove: true, selElementId: elementId, exType: exType, name: name, code: code, type: type });
	};

	closeAssignAndApprovePopup = () => {
		this.setState({ showAssignAndApprove: false });
	};

	refreshInterventionDetails = (refresh) => {
		if (refresh) {
			this.props.dispatch(interventionDetailsAction.fetchAllExtendedElements(this.props.match.params.id, this.props.doctorId, this.props.category));
			this.props.dispatch(interventionAction.getOneIntervention(this.props.match.params.id, new Date().getTimezoneOffset()));
		}
	};

	render() {
		this.state.showAnaesthesia = this.props.showAnaesthesia;
		this.state.showPrice = this.props.showPrice;
		this.state.showStaff = this.props.showStaff;

		return (
			<div>
				{this.state.showAnaesthesia &&
					<div>
						<div className="">
							<div className="table_section di w_100">

								<Anaesthesia type='Anaesthesia' classOrComplete={this.state.classOrComplete} showPrice={this.state.showPrice} showStaff={this.state.showStaff} anaesthesiaDetail={this.props.anaesthesiaDetail} typeActivity={this.props.typeActivity} status={this.props.status} hospitalId={this.props.hospitalId} />
							</div>
						</div>
						<div className="">
							<div className="text_align_right di w_100   margin-bottom-40">
								<button className="common_button pumpkin" onClick={this.editClicked.bind(this, "anaesthesia")}><I18 tkey="ADD / EDIT" /></button>
							</div>
						</div>
					</div>}
				{this.state.showStaff &&
					<div>
						<div className="">
							<div className="table_section di w_100">
								<Staff type='Staff' showTotal={this.state.showPrice} status={this.props.status} hospitalId={this.props.hospitalId} />
							</div>
						</div>
						<div className="">
							<div className="text_align_right di w_100 margin-bottom-20">
								<button className="common_button pumpkin" onClick={this.editClicked.bind(this, "staff")}><I18 tkey="EDIT" /></button>
							</div>
						</div>
					</div>}
				{ this.getExtElements()}
				{this.state.showAssignAndApprove &&
					<AssignAndApprovePopup elementId={this.state.selElementId} type={this.state.type} exType={this.state.exType} name={this.state.name} code={this.state.code} showAssignAndApprove={this.state.showAssignAndApprove} closeAssignAndApprovePopup={this.closeAssignAndApprovePopup} refreshInterventionDetails={this.refreshInterventionDetails} isDetailsPage={true} />
				}
			</div>
		);
	}

	componentDidUpdate() {
		if (this.state.classOrComplete != this.props.category) {
			this.setState({ classOrComplete: this.props.category }, () => {
				this.props.dispatch(interventionDetailsAction.fetchAllExtendedElements(this.props.match.params.id, this.props.doctorId, this.props.status, this.props.hospitalId));
			});
		}
	}
}

InterventionDetail.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations
}))(InterventionDetail));