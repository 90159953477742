import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import adminPersonnelActions from "../../../../../../../actions/admin-personnel";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SelectNewTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			list: [],
			offset: 0,
			numResults: 100,
			search: null,
			typeActivity: "",
			checked: 1,
			isListLoading: true,
			isDataFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.list = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.state.typeActivity = this.props.typeActivity;
		this.fetchList(true);
		this.fetchExistingTemplates();
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	renderOptions() { 
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.list) {    
			this.state.list.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name });
			});
		}    
		if (this.props.staff) {    
			if (prevState.options.indexOf(this.props.staff.value) === -1) {                            
				prevState.options.push(this.props.staff);
			}
		}
		prevState.isListLoading = false;
		this.setState(prevState); 
	}
 
	fetchList(isSearch) {
		if (this.props.checked === 1) {
			let queryString = `sort=name&desc=0&offset=${this.state.offset}&numResults=${this.state.numResults}`;
			queryString += this.state.typeActivity ? `&typeActivity=${this.state.typeActivity}` : "";
			queryString += "&mode=NOT_EXISTING";
			queryString += `&categoryPersonnelTemplateId=${this.props.templateId}`;
			const search = isSearch ? this.state.search ? this.state.search : this.props.data ? this.props.data.label : "" : "";
			queryString += search ? `&search=${search}` : "";
			this.props.dispatch(adminPersonnelActions.fetchAllNewTemplates(queryString));
		}
	}

	fetchExistingTemplates() {
		let queryString = `sort=name&desc=0&offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += this.state.typeActivity ? `&typeActivity=${this.state.typeActivity}` : "";
		queryString += "&mode=EXISTING";
		queryString += `&categoryPersonnelTemplateId=${this.props.templateId}`;
		const search = this.state.search ? this.state.search : "";
		queryString += search ? `&search=${search}` : "";
		this.props.dispatch(adminPersonnelActions.fetchAllExistingTemplates(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchList(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchList(this.state.search);
	}

	onStatusChange = () => {
		this.props.statusCheck("NEW_TEMPLATE");
		return (this.props.data);
	};

	render() {
		if (this.state.typeActivity !== this.props.typeActivity) {
			this.state.typeActivity = this.props.typeActivity;
			this.state.offset = 0;
			this.state.list = [];
			this.state.isListLoading = true;
			this.fetchList(this.state.search);
		}
		if (this.props.adminPersonnel.fetchAllNewTemplateCompleted === "FULFILLED") {
			this.state.isListLoading = false;
			this.props.dispatch(adminPersonnelActions.clearFetchAllNewTemplates());
			this.state.list = unionWith(this.state.list, this.props.adminPersonnel.newTemplateList.data, isEqual);
			this.renderOptions();
			if (!(this.state.options && this.state.options.length) && !this.state.isDataFound) {
				this.fetchList(false);
				this.state.isDataFound = true;
			}
		}
		if ((this.props.checked !== this.state.checked)) {
			this.state.checked = this.props.checked;
			this.state.list = [];
			this.state.offset = 0;
			this.state.options = [];
			this.fetchList(this.state.search);
		}

		return (         
			<Select
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.dataChanged}
				isClearable={true}
				value={this.state.options ? this.state.options.filter(options => options.value === this.props.data.value ? this.onStatusChange() : "") : ""}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Category Personnel", utils.getLanguage())}
			/>
		);
	}
}

SelectNewTemplate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	adminPersonnel: store.adminPersonnel
}))(SelectNewTemplate));                    