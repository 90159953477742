import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const filterAction = (filter, ignorePageChange) => ({
	type: events.INTERVENTION_FILTER,
	payload: { filter, ignorePageChange }
});

const getAll = (query, isScannedTreatments, organization, meta) => ({
	type: events.INTERVENTION_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/interventionList${query ? `?${query}` : ""}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_INTERVENTION_GET_ALL,
	payload: {}

});

const getOne = (treatmentId) => ({
	type: events.INTERVENTION_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/intervention/${treatmentId}`)
});

const updateIntervention = (payload, props) => ({
	type: events.INTERVENTION_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/${props.organization}/intervention/${props.id}`, { ...payload })
});

const getCost = (id, organizationMode, doctorId, category) => ({
	type: events.COST_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/coutCost?organizationMode=${organizationMode}${doctorId ? `&doctorId=${doctorId}` : ""}${category ? `&category=${category}` : ""}`)
});

const getConsumables = (id, organizationMode, doctorId, category) => ({
	type: events.CONSUMMABLES_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/coutConsummables?organizationMode=${organizationMode}${doctorId ? `&doctorId=${doctorId}` : ""}${category ? `&category=${category}` : ""}`)
});
const createIntervention = (payload) => ({
	type: events.INTERVENTION_CREATE,
	payload: axios.post(`${Config.apiRootSecure}/intervention`, { ...payload })
});
const clearCreate = () => ({
	type: events.CLEAR_INTERVENTION_CREATE,
	payload: {}
});

const clearUpdateIntervention = () => ({
	type: events.CLEAR_INTERVENTION_UPDATE,
	payload: {}
});

const copyFromIntervention = (payload, id, doctorId) => ({
	type: events.COPY_INTERVENTION,
	payload: axios.post(`${Config.apiRootSecure}/interventions/${id}/copyFrom/${payload.interventionId}${doctorId ? `?doctorId=${doctorId}` : ""}`, { ...payload })
});

const copyToIntervention = (payload, id, doctorId) => ({
	type: events.COPY_INTERVENTION,
	payload: axios.post(`${Config.apiRootSecure}/interventions/${id}/copyTo${doctorId ? `?doctorId=${doctorId}` : ""}`, { ...payload })
});

const clearCopyIntervention = () => ({
	type: events.CLEAR_COPY_INTERVENTION,
	payload: {}
});

const clearFilter = () => ({
	type: events.CLEAR_FILTER,
	payload: {}
});

const cleargetOne = () => ({
	type: events.CLEAR_INTERVENTION_GETONE,
	payload: {}
});

const assignNewMOM = (id, payload) => ({
	type: events.ASSIGN_NEW_MOM,
	payload: axios.put(`${Config.apiRootSecure}/treatments/local/relink/${id}`, { ...payload })
});

const clearAssignNewMOM = () => ({
	type: events.CLEAR_ASSIGN_NEW_MOM,
	payload: {}
});

const exportInterventionReport = (query) => ({
	type: events.INTERVENTION_REPORT_EXPRORT,
	payload: axios.post(`${Config.apiRootSecure}/interventions/interventionReport/report`, query)
});

const exportInterventionReportCompleted = () => ({
	type: events.INTERVENTION_REPORT_EXPRORT_COMPLETED,
	payload: ""
});

const checkInterventionReportStatus = (code) => ({
	type: events.CHECK_INTERVENTION_REPORT_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/interventions/interventionReport/report/${code}`)
});
const checkInterventionReportStatusCompleted = () => ({
	type: events.CHECK_INTERVENTION_REPORT_STATUS_COMPLETED,
	payload: ""
});

const addSubtreatment = (type, id, payload) => ({
	type: events.INTERVENTION_SUB_TREATMENT_ADD,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/subTreatment?typeActivity=${type.toUpperCase()}`, { ...payload })

});

const addSubtreatmentCompleted = () => ({
	type: events.INTERVENTION_SUB_TREATMENT_ADD_COMPLETED,
	payload: ""
});

const deleteSubtreatment = (id, subTreatmentId) => ({
	type: events.INTERVENTION_SUB_TREATMENT_DELETE,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/subTreatment/${subTreatmentId}`)

});

const deleteSubtreatmentCompleted = () => ({
	type: events.INTERVENTION_SUB_TREATMENT_DELETE_COMPLETED,
	payload: ""
});

const updateSubtreatment = (type, id, payload, subTreatmentId) => ({
	type: events.INTERVENTION_SUB_TREATMENT_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/subTreatment/${subTreatmentId}?typeActivity=${type.toUpperCase()}`, { ...payload })

});

const updateSubtreatmentCompleted = () => ({
	type: events.INTERVENTION_SUB_TREATMENT_UPDATE_COMPLETED,
	payload: ""
});

const getOneSubtreatment = (id, subTreatmentId, type) => ({
	type: events.INTERVENTION_SUB_TREATMENT_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/subTreatment/${subTreatmentId}?typeActivity=${type.toUpperCase()}`)

});

const getOneSubtreatmentCompleted = () => ({
	type: events.INTERVENTION_SUB_TREATMENT_GET_ONE_COMPLETED,
	payload: ""
});

const getOneIntervention = (treatmentId, timeZone) => ({
	type: events.GET_ONE_INTERVENTION,
	payload: axios.get(`${Config.apiRootSecure}/intervention/${treatmentId}?timezone=${timeZone}`)
});

const clearGetOneIntervention = () => ({
	type: events.CLEAR_GET_ONE_INTERVENTION,
	payload: ""
});

const getInterventions = (query) => ({
	type: events.GET_APPROVED_INTERVENTIONS,
	payload: axios.get(`${Config.apiRootSecure}/intervention${query ? `?${query}` : ""}`)
});

const getInterventionLinks = (id) => ({
	type: events.GET_LINKED_ITEMS_FOR_INTERVENTION,
	payload: axios.get(`${Config.apiRootSecure}/intervention/links/${id}`)
});

const getInterventionLinksCompleted = () => ({
	type: events.GET_LINKED_ITEMS_FOR_INTERVENTION_COMPLETED,
	payload: ""
});

const getInterventionsCompleted = () => ({
	type: events.GET_APPROVED_INTERVENTIONS_COMPLETED,
	payload: ""
});

const assignAndApproveIntervention = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_INTERVENTION,
	payload: axios.put(`${Config.apiRootSecure}/intervention/${id}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveIntervention = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_INTERVENTION,
	payload: {}
});

const saveAndApproveIntervention = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_INTERVENTION,
	payload: axios.put(`${Config.apiRootSecure}/intervention/${id}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveIntervention = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_INTERVENTION,
	payload: {}
});

const editIntervention = (id, payload) => ({
	type: events.EDIT_INTERVENTION,
	payload: axios.put(`${Config.apiRootSecure}/intervention/${id}`, { ...payload })
});

const clearEditIntervention = () => ({
	type: events.CLEAR_EDIT_INTERVENTION,
	payload: {}
});

const removeIntervention = (id) => ({
	type: events.DELETE_INTERVENTION,
	payload: axios.delete(`${Config.apiRootSecure}/intervention/${id}`)

});

const removeInterventionCompleted = () => ({
	type: events.DELETE_INTERVENTION_COMPLETED,
	payload: ""
});

const saveAndApproveConsumable = (id, type, payload, elementId) => ({
	type: events.SAVE_AND_APPROVE_CONSUMABLE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}/saveAndApprove`, { ...payload })
});

const clearSaveAndApproveConsumable = () => ({
	type: events.CLEAR_SAVE_AND_APPROVE_CONSUMABLE,
	payload: {}
});

const assignAndApproveConsumable = (id, type, payload, elementId) => ({
	type: events.ASSIGN_AND_APPROVE_CONSUMABLE,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping/${type}/${elementId}/assignAndApprove`, { ...payload })
});

const clearAssignAndApproveConsumable = () => ({
	type: events.CLEAR_ASSIGN_AND_APPROVE_CONSUMABLE,
	payload: {}
});

const getTreatmentCostList = (query) => ({
	type: events.GET_TREATMENT_COST_LIST,
	payload: axios.get(`${Config.apiRootSecure}/defaultValue${query}`)
});

const getTreatmentCostListComplete = () => ({
	type: events.GET_TREATMENT_COST_LIST_COMPLETED,
	payload: ""
});

const updateTotalDefaultCost = (treatmentId, payload) => ({
	type: events.UPDATE_TOTAL_DEFAULT_COST,
	payload: axios.put(`${Config.apiRootSecure}/treatment/${treatmentId}/defaultValue`, { ...payload })
});

const clearUpdateTotalDefaultCost = () => ({
	type: events.CLEAR_UPDATE_TOTAL_DEFAULT_COST,
	payload: {}
});

const getOneDefaultValue = (id) => ({
	type: events.GET_ONE_DEFAULT_VALUE,
	payload: axios.get(`${Config.apiRootSecure}/defaultValue/${id}`)
});

const clearGetOneDefaultValue = () => ({
	type: events.CLEAR_GET_ONE_DEFAULT_VALUE,
	payload: ""
});

const approveDefaultValue = (id, payload) => ({
	type: events.APPROVE_DEFAULT_VALUE,
	payload: axios.put(`${Config.apiRootSecure}/defaultValue/${id}/approve`, { ...payload })
});

const clearApproveDefaultValue = () => ({
	type: events.CLEAR_APPROVE_DEFAULT_VALUE,
	payload: {}
});

const updateInterventionDescription = (id, payload) => ({
	type: events.UPDATE_INTERVENTION_DESCRIPTION,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}`, { ...payload })
});

const clearUpdateInterventionDescription = () => ({
	type: events.CLEAR_UPDATE_INTERVENTION_DESCRIPTION,
	payload: {}
});

export default {
	clearUpdateIntervention,
	filterAction,
	getAll,
	clearGetAll,
	getOne,
	cleargetOne,
	updateIntervention,
	getCost,
	getConsumables,
	createIntervention,
	clearCreate,
	copyFromIntervention,
	clearCopyIntervention,
	copyToIntervention,
	clearFilter,
	assignNewMOM,
	clearAssignNewMOM,
	exportInterventionReport,
	exportInterventionReportCompleted,
	checkInterventionReportStatus,
	checkInterventionReportStatusCompleted,
	addSubtreatment,
	addSubtreatmentCompleted,
	deleteSubtreatment,
	deleteSubtreatmentCompleted,
	updateSubtreatment,
	updateSubtreatmentCompleted,
	getOneSubtreatment,
	getOneSubtreatmentCompleted,
	getOneIntervention,
	clearGetOneIntervention,
	getInterventions,
	getInterventionsCompleted,
	saveAndApproveIntervention,
	clearSaveAndApproveIntervention,
	assignAndApproveIntervention,
	clearAssignAndApproveIntervention,
	editIntervention,
	clearEditIntervention,
	removeIntervention,
	removeInterventionCompleted,
	saveAndApproveConsumable,
	clearSaveAndApproveConsumable,
	assignAndApproveConsumable,
	clearAssignAndApproveConsumable,
	getTreatmentCostList,
	getTreatmentCostListComplete,
	updateTotalDefaultCost,
	clearUpdateTotalDefaultCost,
	getOneDefaultValue,
	clearGetOneDefaultValue,
	approveDefaultValue,
	clearApproveDefaultValue,
	updateInterventionDescription,
	clearUpdateInterventionDescription,
	getInterventionLinks,
	getInterventionLinksCompleted
};
