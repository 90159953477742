import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../../utils/utils";
import ReactTable from "../../../../../../../../plug/reactTable/react-table";
import "../../../../../../../../plug/reactTable/react-table.css";
import isPermissionGranted from "../../../../../../../../utils/permission";
import { statusList } from "../../../../../../../../constants";
class AuthorsAndActivities extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			modelVisible: false,
			totalPages: 1,
			list: [],
			sorted: [],
			costDetails: {},
			checkList: [],
			treatmentId: this.props.treatmentId
		};
	}

	UNSAFE_componentWillMount() {
		this.state.data = this.props.data;
		this.onFetchData();
	}
	
	UNSAFE_componentWillUpdate () {
		if (this.state.treatmentId !== this.props.treatmentId)
		{ 
			console.log("Auth Activity API Triggered>>>>>>>>>>>>>>>>>>>>>>>");
			this.setState({ treatmentId: this.props.treatmentId }, this.onFetchData());
		}
	}
	getCols() {
		const tableArray = ([
			{
				Header: "",
				id: "linkStatus",
				width: 50,
				accessor: d => d,
				resizable: false,
				sortable: false,
				Cell: () => (
					<div>
					</div>
				)
			},
			{
				Header: i18Get("AUTHOR", utils.getLanguage()),
				id: "authors",
				accessor: d => d,
				resizable: true,
				sortable: false,
				width: 180,
				Cell: row => (
					row.value && row.value ? row.value.authors : ""
				)
			},
			{
				Header: i18Get("ACTION", utils.getLanguage()),
				id: "activity",
				accessor: d => d.activity,
				resizable: true,
				sortable: false,
				width: 110,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			},
			{
				Header: i18Get("DATE", utils.getLanguage()),
				id: "date",
				accessor: d => d.date,
				resizable: true,
				sortable: false,
				width: 140,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			},
			{
				Header: i18Get("DESCRIPTION", utils.getLanguage()),
				id: "description",
				accessor: d => d.description,
				resizable: true,
				sortable: false,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			}
		]);
		return tableArray;
	}

	onCellCheckBoxChange = (e, data) => {
		this.checkListChanged(data.id);
	};

	onFetchData() {
		let queryString = "";
		queryString +=  `timezone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(clinicalPathwayDetailsAction.getAuthorsAndActivities((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), queryString));
	}

	fetchDataForSelectedCopy() {
		let queryString = "";
		queryString +=  `timezone=${new Date().getTimezoneOffset()}`;
		this.props.dispatch(clinicalPathwayDetailsAction.getAuthorsAndActivitiesForSelectedCopy((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), queryString));
	}

	onHeaderCheckBoxChange = ({ target: { checked } }) => {
		if (checked) {
			const itemNotInCheckList = this.state.list.filter(row => !this.state.checkList.includes(row.id));
			itemNotInCheckList.forEach(row => this.checkListChanged(row.id));
		} else {
			this.clearCheckList();
		}
		this.setState({ ...this.state, checkedAll: checked });
	};


	checkListChanged(id) {
		const index = this.state.checkList.indexOf(id);
		const prevState = this.state;
		prevState.checkedAll = true;
		if (index === -1) {
			prevState.checkList.push(id);
		} else {
			prevState.checkList.splice(index, 1);
		}
		this.setState(prevState);
		this.props.getSelectedComponents(prevState.checkList, "Authors_And_Actions");
	}

	clearCheckList = () => {
		const prevState = this.state;
		prevState.checkList = [];
		this.setState(prevState);
	};

	isPermissionGranted = (method) => {

		return isPermissionGranted(this.props.clinicalPathway.clinicalPathway.isReadOnly, method);
	};

	render() {

		console.log("Auther Activity >>>>>>>>>>>>>>>>>", this.props.treatmentId );

		if (this.props.clinicalPathwayDetails.fetchAllAuthorsAndActivities === "FULFILLED") {
			this.state.list = this.props.clinicalPathwayDetails.authorsAndActivities.data;
			this.state.loading = false;
			this.props.dispatch(clinicalPathwayDetailsAction.clearGetAuthorsAndActivities());
		}

		if (this.props.clinicalPathwayDetails.fetchAllAuthorsAndActivitiesForSelectedCopy === "FULFILLED") {
			this.state.list = this.props.clinicalPathwayDetails.authorsAndActivities.data;
			this.state.loading = false;
			this.props.dispatch(clinicalPathwayDetailsAction.clearGetAuthorsAndActivitiesForSelectedCopy());
		}

		return (
			<div className="table_no_data_edit">
				<div className="table_heading d-flex align-items-center justify-content-between">                    
					<div className="check_box_container_table position_absolute"></div>
					<div className='margin_auto'><I18 tkey='AUTHORS AND ACTIONS' /></div>
					{this.isPermissionGranted("EDIT") ?
						<button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "Authors_And_Actions")}><I18 tkey="EDIT" /></button>
						: <span className="text_align_right edit_button_clp"></span> }
				</div>
				<ReactTable
					data={this.state.list}
					loading={this.state.loading}
					multiSort={false}
					pageSize={this.state.list.length ? this.state.list.length : 2}
					showPagination={false}
					noDataText={i18Get("NO AUTHORS AND ACTIONS FOUND", utils.getLanguage())}
					columns={this.getCols()}
					ofText={i18Get("of", utils.getLanguage())}
					rowsText={i18Get("rows", utils.getLanguage())}
					onSortedChange={(newSorted) => {
						this.setState({ sorted: newSorted });
					}}
					sorted={this.state.sorted}
				/>
			</div>
		);
	}
}

AuthorsAndActivities.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations,
	permissions: store.permissions,
	clinicalPathway: store.clinicalPathway
}))(AuthorsAndActivities));