import React, { Component } from "react";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../../../../i18";
import utils from "../../../../../../../../../../../utils/utils";

const { Option } = Select;

export default class StatusDropdown extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="w_100 di">
				<Select
					value={this.props.data}
					className="w_100 di"
					defaultActiveFirstOption={false}
					onChange={this.props.dropdownChanged}
					placeholder={i18Get("Select", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
				>
					<Option value={0}><I18 tkey="All" /></Option>
					<Option value={1}><I18 tkey="Unassigned" /></Option>
					<Option value={2}><I18 tkey="Assigned This Team" /></Option>
					<Option value={3}><I18 tkey="Assigned To Other Teams" /></Option>
				</Select>
			</div>
		);
	}
}
