import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import sterilizationActions from "../../../../../../../actions/consumable";

const customStyles = {
	control: (provided) => ({
		...provided,
		height: "32px",
		minHeight: "32px"
	})
};
class FamilyCombo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			family: [],
			offset: 0,
			numResults: 450,
			search: "",
			consumableSpecialityId: null
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchData();
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	renderOptions() { 
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.family) {    
			this.state.family.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name, ...data });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState, () => {
			this.isSelectedItemExist(this.state.options);
			if (this.props.getFamilyOptions) {
				this.props.getFamilyOptions(this.state.options);
			}
		});

		if (!this.props.data && this.props.autoSetSingleOne === true) {
			utils.setSingleOneAsDefaultOption(prevState.options, this.props.familyChange);
		}
	}

	isSelectedItemExist = (options) => {
		if (Array.isArray(options) &&  this.props.data && this.props.data.value) {
			const selItem = options.filter(row => row.id === this.props.data.value);
            
			if (selItem && selItem.length > 0)
			{return true;}
		}
		if (this.props.data && this.props.data.value) {
			this.props.familyChange(null);
		}
		return false;
	};

	fetchData() {
		this.setState({ isListLoading: true }, () => {
			let queryString = "?consumableType=STERILIZATION&";
			queryString += `offset=${this.state.offset}&`;
			queryString += this.state.numResults ? `numResults=${this.state.numResults}&` : "";
			queryString += this.state.consumableSpecialityId ? `consumableSpecialityId=${this.state.consumableSpecialityId}&` : "";
			queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}&` : "";
			queryString += this.state.search ? `search=${this.state.search}&` : "";
			this.props.dispatch(sterilizationActions.fetchMinimalConsumableFamily(queryString));
		});
       
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchData(search);
			}, 800);
		}
	}

	onListScroll() {
		// this.state.offset += this.state.numResults;
		// this.fetchData()
	}

	render() {
       
		return (
			<Select 
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.familyChange}
				value={this.props.data && this.props.data.label ? this.props.data : null }
				isSearchable
				isClearable={true}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select", utils.getLanguage())}
			/>
		);
	}

	componentDidUpdate() {
		if (this.props.consomableSterilization.getconsofamilyStatus === "FULFILLED") {
			this.props.dispatch(sterilizationActions.clearFetchMinimalConsumableFamily());
			this.state.family = this.props.consomableSterilization.consofamily.consumableFamily; //unionWith(this.state.family,  this.props.consomableSterilization.consofamily.consumableFamily, isEqual);
			this.setState({ ...this.state }, () => {
				this.renderOptions();
			});
		}

		if (this.props.consumableSpecialityId !== this.state.consumableSpecialityId) {
			this.state.consumableSpecialityId = this.props.consumableSpecialityId;
			this.setState({ ...this.state, options: [] }, () => {
				this.fetchData();
			});
		}
	}
}

FamilyCombo.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	sterilization: store.sterilization,
	translations: store.translations,
	consomableSterilization: store.consomableSterilization
}))(FamilyCombo));      