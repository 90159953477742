import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../i18";
import { Modal, Button, Radio } from "antd";
import "../../../../../../../../plug/reactTable/react-table.css";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import adminClinicalPathwayActions from "../../../../../../../../actions/admin-clinical-pathway";
import events from "../../../../../../../../actions/events";
import ItemDropdown from "../../item-dropdown";

class AssignAndApproveModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			value: 1,
			name: "",
			code: "",
			invalidName: false,
			invalidCode: false,
			invalidItem: false,
			selectedItem: "",
			item: {},
			itemId: "",
			isloading: false,
			itemFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.modelVisible = this.props.visible;
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwayItems(this.props.itemId));
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};


	cancelClicked() {
		this.setState({ modelVisible: false, invalidName: false, invalidCode: false, itemFound: false });
		this.props.refreshCompleted();
	}

	itemChanged(e) {
		this.setState({ selectedItem: e ? e : "", invalidItem: e && e.value ? false : true });
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.itemFound = true;
			this.state.value = 2;
		}
	}


	validateFields() {
		const prevState = this.state;
		if (!(this.state.name.trim())) {
			prevState.invalidName = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateStep = () => {
		if (this.state.selectedItem && this.state.itemFound) {
			return true;
		}
		return false;
	};

	saveClicked() {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					item: this.state.name,
					clinicalPathwayType: this.props.clinicalPathwayType ? this.props.clinicalPathwayType : ""
				};
				this.props.dispatch(adminClinicalPathwayActions.saveAndApproveItem(this.state.item.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateStep()) {
				payload = {
					clpItemId: this.state.selectedItem && this.state.selectedItem.value ? this.state.selectedItem.value : "",
					clinicalPathwayType: this.props.clinicalPathwayType ? this.props.clinicalPathwayType : ""
				};
				this.props.dispatch(adminClinicalPathwayActions.assignAndApproveItem(this.state.item.id, payload));
			}
			else {
				this.setState({ invalidItem: true });
			}

		}

	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const prevState = this.state;
		prevState.invalidCode = false;
		prevState.code = value;
		this.setState(prevState);
	}

	render() {
		const { value } = this.state;
		if (this.props.adminClinicalPathway.asssignAndApproveItemStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", code: "", itemFound: false });
			this.props.dispatch(adminClinicalPathwayActions.assignAndApproveItemCompleted());
			this.props.refreshCompleted();
		}
		if (this.props.adminClinicalPathway.saveAndApproveItemStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, name: "", code: "", itemFound: false });
			this.props.dispatch(adminClinicalPathwayActions.saveAndApproveItemCompleted());
			this.props.refreshCompleted();
		}

		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayItemsCompleted === "FULFILLED") {
			this.state.item = this.props.adminClinicalPathway.itemDetails;
			this.state.name = this.state.item.item ? this.state.item.item : "";
			this.state.code = this.state.item.code ? this.state.item.code : "";
			this.state.isloading = true;
			this.state.selectedItem = { label: this.state.name ? this.state.name : "", value: this.state.item.id }; 
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayItems());
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.item.item, id: this.state.item.id } });
		}

		if (this.props.adminClinicalPathway.fetchOneClinicalPathwayItemsCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwayItems());
		}

        
		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit & Approve - Item", utils.getLanguage())}
					visible={this.props.visible}
					onCancel={this.cancelClicked.bind(this)}
					footer={[
						<Button key="back" onClick={this.cancelClicked.bind(this)}>
							<I18 tkey='Cancel'/>
						</Button>,
						<Button key="save" type="primary" onClick={this.saveClicked.bind(this)}>
							<I18 tkey='Save & Approve'/>
						</Button>
					]} 
				>
					<Radio.Group className="w_100" onChange={this.radioButtonChange} value={value}>
						<div className="re_assign_modal_head">
							<div className="row position_relative margin-left-0 margin-right-0">
								<div className="col-md-9">
									<label className="bold width-100"><I18 tkey="NAME" />:</label>
									<label className="width-100">{this.state.item.item}</label>
								</div>
								<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.item.hospitalName ? this.state.item.hospitalName : ""}</p>
							</div>
						</div> 
						{this.state.isloading ?
							<div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={1}>
											<I18 tkey="Use New Name" />
										</Radio>
									</div>
									<div className="col-md-8 pt-10">
										<div className="form-group pl-20">
											<label className="bold" ><I18 tkey="NAME" />:</label>
											<NameInput
												value={this.state.name}
												inputChanged={this.nameChanged.bind(this)}
												maxLength={240}
												disabled={this.state.value != 1}
											/>
											{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid item name" /></span>}
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-md-12 pt-10">
										<Radio value={2}> <I18 tkey="Assign To Item" /></Radio>
									</div>
									<div className="col-sm-12 pt-10">
										<div className="form-group pl-20">
											<ItemDropdown
												className="padding-4"
												data={this.state.selectedItem}
												disabled={this.state.value != 2} style={{ width: "100%" }}
												statusCheck={this.statusCheck.bind(this)}
												isApproved={true}
												isRank={true}
												subStepId={this.props.subStepId}
												clinicalPathwayType={this.props.clinicalPathwayType && (this.props.clinicalPathwayType !== "ALL") ? this.props.clinicalPathwayType : ""}
												itemChanged={this.itemChanged.bind(this)} />
											{this.state.invalidItem && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select item" /></span>}
										</div>
									</div>
								</div>
							</div>
							: ""
						}
					</Radio.Group>
				</Modal>

			</div>
		);
	}
}

AssignAndApproveModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	translations: store.translations,
	adminClinicalPathway: store.adminClinicalPathway
}))(AssignAndApproveModel));    