import eventNames from "../../actions/events";

const initialState = {
	courseone: {}
};
export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.COURSE_GET_ONE}_PENDING`:
			return {
				...state,
				isFetchOneCourse: "PENDING"
			};
		case `${eventNames.COURSE_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneCourse: "FULFILLED",
					courseOne: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_GET_ONE}_REJECTED`:
			return {
				...state,
				isFetchOneCourse: "REJECTED"
			};
		case `${eventNames.COURSE_STEP_GET_ONE}_PENDING`:
			return {
				...state,
				isFetchOneCourseStep: "PENDING"
			};
		case `${eventNames.COURSE_STEP_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneCourseStep: "FULFILLED",
					courseStep: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_STEP_GET_ONE}_REJECTED`:
			return {
				...state,
				isFetchOneCourseStep: "REJECTED"
			};
		case `${eventNames.COURSE_CREATE_UPDATE}_PENDING`:
			return {
				...state,
				iscreateCourseStep: "PENDING"
			};
		case `${eventNames.COURSE_CREATE_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscreateCourseStep: "FULFILLED",
					courseStepCreate: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_CREATE_UPDATE}_REJECTED`:
			return {
				...state,
				iscreateCourseStep: "REJECTED"
			};
		case `${eventNames.CLEAR_CREATE}`:
			return {
				...state,
				iscreateCourseStep: false
			};
		case `${eventNames.CLEAR_GET}`:
			return {
				...state,
				isFetchOneCourse: false
			};
		case `${eventNames.COURSE_ELEMENTS_FETCH}_PENDING`:
			return {
				...state,
				isElementsFetch: "PENDING"
			};
		case `${eventNames.COURSE_ELEMENTS_FETCH}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isElementsFetch: "FULFILLED",
					elementsList: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_ELEMENTS_FETCH}_REJECTED`:
			return {
				...state,
				isElementsFetch: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_EEMENTS_GET}`:
			return {
				...state,
				isElementsFetch: false
			};
		case `${eventNames.COURSE_LIEUX_FETCH}_PENDING`:
			return {
				...state,
				isLieuxFetch: "PENDING"
			};
		case `${eventNames.COURSE_LIEUX_FETCH}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isLieuxFetch: "FULFILLED",
					lieuxList: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_LIEUX_FETCH}_REJECTED`:
			return {
				...state,
				isLieuxFetch: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_LIEUX_GET}`:
			return {
				...state,
				isLieuxFetch: false
			};

		case `${eventNames.COURSE_VALUE_CREATE}_PENDING`:
			return {
				...state,
				iscourseValueCreate: "PENDING"
			};
		case `${eventNames.COURSE_VALUE_CREATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscourseValueCreate: "FULFILLED",
					StepValueCreate: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_VALUE_CREATE}_REJECTED`:
			return {
				...state,
				iscourseValueCreate: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_VALUE_CREATE}`:
			return {
				...state,
				iscourseValueCreate: false
			};

		case `${eventNames.COURSE_VALUE_EDIT}_PENDING`:
			return {
				...state,
				iscourseValueEdit: "PENDING"
			};
		case `${eventNames.COURSE_VALUE_EDIT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscourseValueEdit: "FULFILLED",
					StepValueEdit: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_VALUE_EDIT}_REJECTED`:
			return {
				...state,
				iscourseValueEdit: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_VALUE_EDIT}`:
			return {
				...state,
				iscourseValueEdit: false
			};

		case `${eventNames.COURSE_VALUE_DELETE}_PENDING`:
			return {
				...state,
				iscourseValueDelete: "PENDING"
			};
		case `${eventNames.COURSE_VALUE_DELETE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscourseValueDelete: "FULFILLED",
					StepValueDelete: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_VALUE_DELETE}_REJECTED`:
			return {
				...state,
				iscourseValueDelete: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_VALUE_DELETE}`:
			return {
				...state,
				iscourseValueDelete: false
			};

		case `${eventNames.COURSE_VALUE_RANK_UPDATE}_PENDING`:
			return {
				...state,
				iscourseValueRankUpdate: "PENDING"
			};
		case `${eventNames.COURSE_VALUE_RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscourseValueRankUpdate: "FULFILLED",
					StepValueRank: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_VALUE_RANK_UPDATE}_REJECTED`:
			return {
				...state,
				iscourseValueRankUpdate: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_RANK_UPDATE}`:
			return {
				...state,
				iscourseValueRankUpdate: false
			};

		case `${eventNames.GET_ALL_COURSE_STEPS_MINIMAL}_PENDING`:
			return {
				...state,
				iscourseValueMinimalGet: "PENDING"
			};
		case `${eventNames.GET_ALL_COURSE_STEPS_MINIMAL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					iscourseValueMinimalGet: "FULFILLED",
					courseMinimal: action.payload.data.data
				};
			}
			break;
		case `${eventNames.GET_ALL_COURSE_STEPS_MINIMAL}_REJECTED`:
			return {
				...state,
				iscourseValueMinimalGet: "REJECTED"
			};
		case `${eventNames.CLEAR_GET_ALL_COURSE_STEPS_MINIMAL}`:
			return {
				...state,
				iscourseValueMinimalGet: false
			};

		case `${eventNames.COURSE_STEPS_COPY}_PENDING`:
			return {
				...state,
				courseCopyComplete: "PENDING"
			};
		case `${eventNames.COURSE_STEPS_COPY}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					courseCopyComplete: "FULFILLED",
					courseCopy: action.payload.data.data
				};
			}
			break;
		case `${eventNames.COURSE_STEPS_COPY}_REJECTED`:
			return {
				...state,
				courseCopyComplete: "REJECTED"
			};
		case `${eventNames.CLEAR_COURSE_STEPS_COPY}`:
			return {
				...state,
				courseCopyComplete: false
			};

		case `${eventNames.GET_LOCAL_TREATMENT_LIST}_FULFILLED`:
			return { ...state, getLocalTreatmentStatus: "FULFILLED", getLocalTreatment: action.payload.data.data };
		case `${eventNames.GET_LOCAL_TREATMENT_LIST}_REJECTED`:
			return { ...state, getLocalTreatmentStatus: "REJECTED" };
		case eventNames.CLEAR_LOCAL_TREATMENT_LIST:
			return { ...state, getLocalTreatmentStatus: false };

		case `${eventNames.COPY_TO_LOCAL}_FULFILLED`:
			return { ...state, copyToLocalStatus: "FULFILLED" };
		case `${eventNames.COPY_TO_LOCAL}_REJECTED`:
			return { ...state, copyToLocalStatus: "REJECTED" };
		case eventNames.COPY_TO_LOCAL_CLEAR:
			return { ...state, copyToLocalStatus: false };

		default:
			return state;
	}
}
