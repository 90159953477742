import React, { Component } from "react";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ShowHideColumns from "../top-section/show-hide-col";
import { Checkbox, Dropdown, Menu, Button, Tooltip, Modal } from "antd";
import { InfoCircleTwoTone, MenuOutlined, ShareAltOutlined, DeploymentUnitOutlined, CopyOutlined, DownloadOutlined, CaretDownOutlined } from "@ant-design/icons";
import ShowHideProblemColumns from "./show-hide-problem-col";
import ShowHidePathwayColumns from "../standard-care-chart/show-hide-pathway-columns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ClinicalPathwayHospital from "../top-section/clinicalPathwayHospital";
import CommunityShare from "../community-share";
import CommunityInfo from "../community-info";
import isPermissionGranted from "../../../../../../../utils/permission";
import ModelNotification from "../../components/modelChangeNotification";
import { GitCompare } from "../../../../../../../utils/icons";
import QuickReorderByModel from "../problem-section/quick-reorder-by-model";
import { statusList } from  "../../../../../../../constants";
import TopSection from "../top-section";

//import QuickReorderByModelTest from "../problem-section/quick-reorder-by-model-hooks";

class TopDetailsSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSettings: false,
			communityShare: false,
			communityInfo: false,
			reOrderByAppliedModel: false,
			isItSharedClinicalPathway: false
		};
	}

	dropDownVisibleChange = visible => {
		this.setState({ visible: visible, showColumns: false });
	};

	onClickAddOrRemove = () => {
		this.dropDownVisibleChange(false);
		this.props.onClickAddOrRemove();
	};

	copyFromClinicalPathWay = () => {
		this.dropDownVisibleChange(false);
		this.props.copyFromClinicalPathWay();
	};
	copyFromPathWayProblems = () => {
		this.dropDownVisibleChange(false);
		this.props.copyFromPathWayProblems();
	};

	clpComparison = () => {
		this.dropDownVisibleChange(false);
		this.props.onComparisonOfClps();
	};
	exportExcel = () => {
		this.dropDownVisibleChange(false);
		this.props.exportExcel();
	};

	exportSelectedComponent = () => {
		this.dropDownVisibleChange(false);
		this.props.exportSelectedComponent();
	};

	onClickCommunityShare = () => {
		this.dropDownVisibleChange(false);
		this.setState({ communityShare: true });
	};

	onClickCommunityInfo = () => {
		this.setState({ communityInfo: true });
	};

	onClickShowHideColumns = () => {
		this.setState({ showColumns: true });
	};

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.isReadOnly, method);
	};

	handleCancel() {
		const prevState = this.state;
		prevState.communityShare = false;
		prevState.communityInfo = false;
		this.setState(prevState);
	}
	//--------------QUICK REORDER BY APPLIED MODEL---------------------------
	 quickReorderByAppliedModel = () => { 
		const prevState = this.state;
		prevState.reOrderByAppliedModel = !this.state.reOrderByAppliedModel;
		this.setState(prevState);
	};
	//-----------------------------------------------------------------------

	settingsMenu = () => (
		this.props.category === "clpTasks" ?
			<Menu>
				<Menu.Item onClick={() => this.onClickAddOrRemove()} key="0">
					{
						<span className="margin-right-10">
							< MenuOutlined />
							<I18 tkey='Add/Remove Step' />
						</span>
					}
				</Menu.Item>
				<Menu.Item onClick={() => this.onClickShowHideColumns()} key="1">
					<ShowHideColumns
						selectedColumns={this.props.selectedColumns}
						onChangeColSelect={this.props.onChangeColSelect}
						dropDownVisibleChange={this.dropDownVisibleChange}
						visible={this.state.showColumns}
					/>
				</Menu.Item>
				<Menu.Item key="2">
					{
						<span className="margin-right-10 d-flex switch_container_pathway">
							<div>
								<Checkbox
									checked={this.props.hideSteps}
									onChange={(e) => this.props.onChangeShowHideSteps(e)}>
									<I18 tkey='Hide Empty Steps' />
								</Checkbox>
							</div>
						</span>
					}
				</Menu.Item>
			</Menu> :
			<Menu>
				<Menu.Item onClick={() => this.onClickShowHideColumns()} key="1">
					<ShowHideProblemColumns
						selectedColumns={this.props.selectedPblmColumns}
						onChangeColSelect={this.props.onChangeColSelect}
						dropDownVisibleChange={this.dropDownVisibleChange}
						visible={this.state.showColumns}
					/>
				</Menu.Item>
				{/* <Menu.Item onClick={() => this.copySelectedToMaster()} key="2">
                <span className="margin-right-10">
                    <Icon type="copy" theme="outlined" />
                    <I18 tkey='Copy Selected To Master' />
                </span>
                </Menu.Item> */}

				<Menu.Item onClick={() => this.clpComparison()} key="2">
					<span className="margin-right-10">
						<i aria-label="icon: eye" className="anticon anticon-eye"><GitCompare /></i>
						<I18 tkey='Comparison of Interventions' />
					</span>
				</Menu.Item>
			</Menu>

	);

	copyMenu = () => (
		<Menu>
			<Menu.Item onClick={() => this.copyFromClinicalPathWay()} key="1">
				<span className="margin-right-10">
					<CopyOutlined />
					<I18 tkey='Copy From Clinical Pathway' />
				</span>
			</Menu.Item>
		</Menu>
	);


	communityShareMenu = () => (
		<Menu>
			<Menu.Item onClick={() => this.onClickCommunityShare()} key="2">
				<span className="margin-right-10">
					{/* <Icon type="copy" theme="outlined" /> */}
					<DeploymentUnitOutlined />
					<I18 tkey='Share to community' />
				</span>
			</Menu.Item>
		</Menu>
	);

	settingsPathway = () => (
		<Menu>
			<Menu.Item onClick={() => this.onClickShowHideColumns()} key="2">
				<ShowHidePathwayColumns
					selectedColumns={this.props.selectedPathwayColumns}
					onChangeColSelect={this.props.onChangeColSelect}
					dropDownVisibleChange={this.dropDownVisibleChange}
					visible={this.state.showColumns}
				/>
			</Menu.Item>
		</Menu>
	);

	exportMenu = (category) => (
		category === "clpTasks" ?
			<Menu>
				<Menu.Item onClick={() => this.exportExcel()} key="3">
					<span className="margin-right-10">
						<DownloadOutlined className="margin-right-5" />
						<I18 tkey='Export Excel' />
					</span>
				</Menu.Item>
				<Menu.Item onClick={() => this.exportSelectedComponent()} key="4">
					<span className="margin-right-10">
						<DownloadOutlined className="margin-right-5" />
						<I18 tkey='Export Selected' />
					</span>
				</Menu.Item>
			</Menu> : category === "standardCareChart" ?
				<Menu>
					<Menu.Item onClick={() => this.props.exportStandardCareChart()} key="1">
						<span className="margin-right-10">
							<DownloadOutlined className="margin-right-5" />
							<I18 tkey='Export Excel' />
						</span>
					</Menu.Item>
				</Menu>
				:
				<Menu>
					<Menu.Item onClick={() => this.props.exportPathwayPblm()} key="1">
						<span className="margin-right-10">
							<DownloadOutlined className="margin-right-5"  />
							<I18 tkey='Export Excel' />
						</span>
					</Menu.Item>
				</Menu>
	);


	render() {

		if (this.state.isItSharedClinicalPathway !== this.props.clinicalPathway.sharedCommunityCount)
		{
			this.setState({ isItSharedClinicalPathway: this.props.clinicalPathway.sharedCommunityCount });
		}
		return (
			<div className="col-md-12 padding-right-0">
				<div className="w_100 ellipsis d-flex justify-content-between overflow_unset">
					<div>
						<span className='clinical_pathway_intervention_name'>
							{this.props.clinicalPathway && this.props.clinicalPathway.name ? `${this.props.clinicalPathway.siteId && this.props.clinicalPathway.hospitalId ? this.props.clinicalPathway.siteShortName : this.props.clinicalPathway.hospitalName ? this.props.clinicalPathway.hospitalName : "MOM"} ${this.props.clinicalPathway.name}` : ""}
						</span>
						<div className="d-flex align-items-center">
							<div className="">
								<I18 tkey='Code' />:&nbsp;
								{this.props.clinicalPathway && this.props.clinicalPathway.code ? this.props.clinicalPathway.code : ""}&nbsp;
							</div>
							<span className='intervention_speciality_name font_12'>
								{this.props.clinicalPathway && this.props.clinicalPathway.specialityName && this.props.clinicalPathway.familyName ?
									`(${this.props.clinicalPathway.specialityName}, ${this.props.clinicalPathway.familyName}, ${this.props.clinicalPathway.clinicalPathway === "MEDICINE" ? i18Get("MEDICINE WITH ACTE", utils.getLanguage()) : this.props.clinicalPathway.clinicalPathway.replace(/_/g, " ")})${this.props.doctorName ? `(${this.props.doctorName})` : ""}`
									: ""}
							</span>
						</div>
						{this.props.clinicalPathway.siteId && this.props.clinicalPathway.hospitalId &&
							<div className="mb-10 d-flex pblm_btn_container float_left margin-top-8">
								<div className="tool_tip margin-bottom-10" onClick={this.onClickCommunityInfo.bind(this)}>
									<InfoCircleTwoTone />
								</div>
							</div>
						}

						<div className="d-flex align-items-center ">
							<div className='color_red' >
								{/* <ClinicalPathwayHospital
									className=""
									hospitalChanged={this.props.hospitalChanged}
									data={this.props.selectedHospital}
									siteName={this.props.clinicalPathway.siteName}
								/> */}
								<label >
									{`${i18Get("Server", utils.getLanguage())}: ${this.props.selectedHospital && this.props.selectedHospital.label ? this.props.selectedHospital.label : "Master"}`}
								</label>
							</div>
							{this.props.clinicalPathway.hospitalId &&
								<div className={`mb-10 di float_left margin-top-8  margin-left-10 ${(isPermissionGranted(this.props.clinicalPathway.isReadOnly, "CREATE")) && !this.state.isItSharedClinicalPathway ? "btn-group" : ""}`}>
									<Dropdown overlay={this.communityShareMenu()} trigger={["click"]} >
										<div className="mb-10 di float_left min-height-36">
											<Button type="button" className={`single-button with_border uppercase min_width_90 min-height-36 ${this.state.isItSharedClinicalPathway ? "shared_community" : ""}`} loading={this.state.loading} disabled={!isPermissionGranted(this.props.clinicalPathway.isReadOnly, "CREATE")}>
												<span className='color_blue font_size_12px share_pathway'>
													<ShareAltOutlined />
													<span className='share_to_text'><I18 tkey={this.state.isItSharedClinicalPathway ? "Share Again" : "Share To"} /></span>
												</span>
												<CaretDownOutlined />
											</Button>
										</div>
									</Dropdown>
								</div>
							}
						</div>
					</div>
					<div className="align-items-center d-flex margin-bottom-5">
						<div className="padding-right-0 w_100">
							{this.props.category === "standardCareChart" &&
								<div
									className={`mr-7 mb-10 ${(this.isPermissionGranted("EDIT")) ? "btn-group float_right" : "float_left"} patient_info_drop_down task_btn_container`}>
									<div className="mb-10 di float_left">
										<Dropdown onVisibleChange={this.dropDownVisibleChange} visible={this.state.visible} overlay={this.settingsPathway()} trigger={["click"]} >
											<div className="mb-10 di float_left">
												<Button type="button" className="single-button with_border uppercase min_width_90"
													loading={this.state.loading}
													disabled={!this.isPermissionGranted("EDIT")}>
													<I18 tkey="SETTINGS" />
													<CaretDownOutlined />
												</Button>
											</div>
										</Dropdown>
									</div>
								</div>}
							<div className="d-flex justify-content-end padding-left-10">
								<div className="margin-right-10 d-flex align-items-center">
									{this.props.category === "clpTasks" &&
										<div className="empty_step_hidden_clinical_pathway">
											{this.props.hideSteps ?
												<label className="margin-right-10 margin-bottom-0"><I18 tkey='Empty Step Hidden' /></label>
												: ""}
										</div>}
								</div>
								{this.props.category === "clpProblem" &&
									<div className="mb-10 d-flex pblm_btn_container float_left">
										<div className="tool_tip margin-bottom-10">
											<Tooltip placement="top" overlayClassName="tool_tip_pathway" title={i18Get("Designed according the works of Mrs Thérèse Psiuk.", utils.getLanguage())}>
												<InfoCircleTwoTone />
											</Tooltip>
										</div>
										<div className="mr-7 mb-10 float_right task_btn_container">
											<Button type="primary" className="single-button with_border min_width_90" onClick={this.props.onClickSynthesis}><I18 tkey="Synthesis" /></Button>
										</div>
									</div>}
								<div className="text_align_right">
									{this.props.category !== "standardCareChart" &&
										<div className={`mr-7 mb-10 ${(this.isPermissionGranted("EDIT")) ? "btn-group" : ""} patient_info_drop_down float_right task_btn_container`}>
											{
												<Dropdown onVisibleChange={this.dropDownVisibleChange} visible={this.state.visible} overlay={this.settingsMenu()} trigger={["click"]} disabled={!this.isPermissionGranted("EDIT")}>
													<div className="mb-10 di float_left">
														<Button type="button" className="with_border uppercase single-button min_width_90"
															loading={this.state.loading} disabled={!this.isPermissionGranted("EDIT")}>
															<I18 tkey="SETTINGS" />
															<CaretDownOutlined />
														</Button>
													</div>
												</Dropdown>
											}
										</div>}
								</div>
								<div className="text_align_right">
									{this.props.category === "clpTasks" && !this.props.clinicalPathway.isCheckList && !this.props.clinicalPathway.isModel ?
										<div
											className={`mr-7 mb-10 ${(this.isPermissionGranted("EDIT")) ? "btn-group" : ""} patient_info_drop_down float_right task_btn_container`}>
											{/* {!this.props.copySelectedDataToMaster ?
												<Dropdown overlay={this.copyMenu()} trigger={['click']} >
													<div className="mb-10 di float_left">
														<Button type="button" className="single-button with_border uppercase min_width_90"
															loading={this.state.loading} disabled={!this.isPermissionGranted('EDIT')}>
															<I18 tkey="COPY" />
															<Icon type="caret-down" />
														</Button>
													</div>
												</Dropdown> : ''
											} */}
										</div> : ""
									}
									{this.props.category === "clpProblem" &&
										<div className={`mr-7 mb-10 ${(this.isPermissionGranted("EDIT")) ? "btn-group" : ""}  patient_info_drop_down float_right task_btn_container`}>
											<div className="mb-10  di float_left">
												<Button type="button" className="single-button with_border uppercase min_width_90"
													loading={this.state.loading}
													disabled={!this.isPermissionGranted("EDIT")}
													onClick={() => this.copyFromPathWayProblems()} >
													<I18 tkey="Copy From" />
												</Button>
											</div>

										</div>
									}
								</div>
								{this.props.category === "standardCareChart" ?
									<div className="text_align_right margin-right-7">
										<div className="mb-10 btn-group patient_info_drop_down float_right task_btn_container">
											<Dropdown overlay={this.exportMenu(this.props.category)} trigger={["click"]} placement="bottomLeft">
												<div className="mb-10 di float_left">
													<Button type="button" className=" with_border uppercase single-button min_width_90"
														loading={this.state.loading}>
														<I18 tkey="EXPORT" />
														<CaretDownOutlined />
													</Button>
												</div>
											</Dropdown>
										</div>
									</div> :
									<div className="text_align_right">
										<div className="mb-10 btn-group patient_info_drop_down float_right task_btn_container">
											<Dropdown overlay={this.exportMenu(this.props.category)} trigger={["click"]} placement="bottomLeft">
												<div className="mb-10 di float_left">
													<Button type="button" className=" with_border uppercase single-button min_width_90"
														loading={this.state.loading}>
														<I18 tkey="EXPORT" />
														<CaretDownOutlined />
													</Button>
												</div>
											</Dropdown>
										</div>
									</div>}
							</div>

							<div className={`d-flex align-items-center w_auto float_right font_size_13px padding-left-10 ${this.props.category === "standardCareChart" ? "margin-top-9" : ""}`}>								
								<div className="margin-right-15">
									<TopSection
										dayInformation={this.props.dayInformation}
										selectedHospital={this.props.selectedHospital && this.props.selectedHospital.treatmentId ? this.props.selectedHospital.treatmentId : null}
										reloadDetails={this.props.reloadDetails}
										search={this.props.search}
										searchField={this.props.searchField}
										searchType={this.props.searchType}
										clinicalPathwayData={this.props.clinicalPathway}
										onFetchData={this.props.onFetchData}
										onRef={this.props.onRef}
									/>
								</div>

								{!this.props.clinicalPathway.isCheckList  && !this.props.clinicalPathway.isModel && this.props.category === "clpProblem" &&
								 this.props.clinicalPathway.modelTreatmentCount > 0 ?
								 <span className="margin-right-20"><a className='model_attached_count text_underline' onClick={this.quickReorderByAppliedModel }>{this.props.clinicalPathway.modelTreatmentCount} <I18 tkey="MODEL(S) APPLIED" /></a></span>  : ""
								}
								
								{ this.props.clpStepFilter === "ALL_ITEMS" && !this.props.isSearchEnabled ? (this.props.category !== "clpTasks" ) ? `${i18Get("Total Tasks", utils.getLanguage())}: ${this.props.totalTaskCount}` : "" 
									: 
									(this.props.category !== "clpTasks" ) ? `${i18Get("Tasks", utils.getLanguage())}: ${this.props.taskCount} (Total: ${this.props.totalTaskCount})` : "" }

							</div>
						</div>
					</div>
				</div>
				{!this.props.clinicalPathway.isCheckList && this.props.category === "clpProblem" && this.isPermissionGranted("EDIT") && (this.props.clinicalPathway.applyModelChanges || this.props.clinicalPathway.modelChanges) && <ModelNotification reloadDetails={this.props.reloadDetails} clinicalPathwayData={this.props.clinicalPathway} mode="Notification" />}

				{this.state.communityShare ?
					<Modal
						visible={this.state.communityShare}
						className="cost_modal re_assign_modal"
						title={i18Get("Community Share", utils.getLanguage())}
						onCancel={this.handleCancel.bind(this)}
						wrapClassName="drag_modal"
						footer={null}
					>
						<CommunityShare clinicalPathwayData={this.props.clinicalPathway} closeCommunityShare={this.handleCancel.bind(this)} reloadDetails={this.props.reloadDetails} />
					</Modal> : ""}

				{this.state.communityInfo ?
					<Modal
						centered
						visible={this.state.communityInfo}
						className=" re_assign_modal"
						title={i18Get(("Community Info"), utils.getLanguage())}
						onCancel={this.handleCancel.bind(this)}
						wrapClassName="drag_modal"
						footer={null}
						width={500}
					>
						<CommunityInfo
							clinicalPathwayData={this.props.clinicalPathway}
							closeCommunityShare={this.handleCancel.bind(this)}
							hospitalName={this.props.clinicalPathway.hospitalName}
							siteName={this.props.clinicalPathway.siteName}
						/>
					</Modal> : ""}

				{ this.state.reOrderByAppliedModel ?
					<QuickReorderByModel 
						saveAndNextQuickOrder = { () => { }}
						enableQuickReorder = { this.state.reOrderByAppliedModel }
						disableQuickReorder = { this.quickReorderByAppliedModel.bind(this)}
						clinicalPathwayData = { this.props.clinicalPathway }
						reloadDetails = {this.props.reloadDetails}
					/> : ""

				}
				{/* <QuickReorderByModelTest 
					saveAndNextQuickOrder = {"Vipin" }
					enableQuickReorder = { this.state.reOrderByAppliedModel }
					disableQuickReorder = { this.quickReorderByAppliedModel.bind(this)}
					clinicalPathwayData = { this.props.clinicalPathway }
					reloadDetails = {this.props.reloadDetails}
				/>  */}
			</div>
		);
	}
}

TopDetailsSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		clinicalPathwayProblems: store.clinicalPathwayProblems,
		actions: store.actions,
		permissions: store.permissions
	}))(TopDetailsSection)
);