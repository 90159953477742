import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import ReactTable from "../../../../../../../plug/reactTable";
import interventionAction from "../../../../../../../actions/intervention";
import Loader from "../../../../../../../plug/loader";
import locations from "../../../../../../../router/locations";
import column from "./column";
import { Modal, Popover } from "antd";
import { statusList } from "../../../../../../../constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import I18, { i18Get } from "../../../../../../../i18";
import AssignAndApproveModel from "./assign-and-approve-model";
import InterventionEditModel from "./intervention-edit";
import InterventionDeleteModel from "./intervention-delete-model";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
class InterventionList  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			sort: "name",
			desc: 0,
			interventions: [],
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			rowScrollIndex: this.getRowScrollIndexFromParams(),
			firstLoad: true,
			filter: {},
			treatmentId: "",
			modelVisible: false,
			editModel: false,
			selectedColumn: {},
			interventionLinks: [],
			deleteModalVisible: false,
			isInterventionLinked: false
		};
	}

	getRowScrollIndexFromParams() {
		const rowScrollIndex = parseInt(utils.checkForParameter("rowScrollIndex", this.props.location));
		const pageSize = parseInt(utils.checkForParameter("pageSize", this.props.location));
		return rowScrollIndex < pageSize ? rowScrollIndex : 0;
	}

	UNSAFE_componentWillMount() {
		this.state.organizationMode = this.props.organizationMode;
		const filter = utils.getFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}`);
		if (filter && (typeof filter === "object") && Object.keys(filter).length) {
			this.state.filter = filter;
			this.state.filter.selectedStatus = filter.selectedStatus ? filter.selectedStatus.toUpperCase() : "";
			this.state.filter.selectedHospital = filter.selectedHospital ? filter.selectedHospital.value : "";
			this.state.filter.selectedSpecialty = filter.selectedSpecialty ? filter.selectedSpecialty.value : "";
			this.state.filter.selectedFamily = filter.selectedFamily ? filter.selectedFamily.value : "";
			this.state.filter.selectedIntervention = filter.selectedIntervention ? filter.selectedIntervention.value : "";
		}
		this.state.typeActivity = this.props.typeActivity;
	}

	setLocalStorageForList() {
		const params = {
			page: this.state.page,
			pageSize: this.state.pageSize,
			sort: this.state.sort,
			desc: this.state.desc ? 1 : 0,
			rowScrollIndex: this.state.rowScrollIndex
		};
		utils.setFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}_LIST`, params);
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.state.fetchAllMeta = Date.now();
		const isScannedTreatments = (this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? true : false;
		let queryParams = `typeActivity=${this.state.typeActivity ? this.state.typeActivity : "INTERVENTION"}&`;
		queryParams += `offset=${((this.state.page - 1) * this.state.pageSize)}&numResults=${this.state.pageSize}&`;
		queryParams += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${this.state.desc ? `desc=${this.state.desc}&` : ""}`;
		queryParams += `${this.state.filter.selectedHospital ? `hospitalId=${this.state.filter.selectedHospital}&` : ""}`;
		queryParams += `${this.state.filter.selectedSpecialty ? `specialityId=${this.state.filter.selectedSpecialty}&` : ""}`;
		queryParams += `${this.state.filter.selectedFamily ? `familyId=${this.state.filter.selectedFamily}&` : ""}`;
		queryParams += `${this.state.filter.selectedStatus && (this.state.filter.selectedStatus !== "ALL") ? `status=${this.state.filter.selectedStatus}&` : ""}`;
		queryParams += `${(this.state.filter.selectedInterventionStatus && this.state.filter.selectedInterventionStatus !== "ALL") ? `wfaStatus=${this.state.filter.selectedInterventionStatus}&` : ""}`;
		queryParams += `${this.state.filter && this.state.filter.search ? `name=${this.state.filter.search}&code=${this.state.filter.search}` : ""}`;
		this.setLocalStorageForList();
		this.props.dispatch(interventionAction.getAll(queryParams, isScannedTreatments, this.props.organization, this.state.fetchAllMeta));
	}

    

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		this.state.rowScrollIndex = 0;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange(pageSize) {
		this.state.page = 1;
		this.state.pageSize = pageSize;
	}

	rowScroll(row) {
        
		this.state.rowScrollIndex = row.startIndex + (row.stopIndex - row.startIndex);
	}

	interventionLinkList = (row) => {
		this.setState({ treatmentId: row.id, deleteModalVisible: true });
		if (row.id) {
			this.props.dispatch(interventionAction.getInterventionLinks(row.id));
		}
	};

	// deleteClicked = (row) => {
	// 	const { confirm } = Modal;
	// 	console.log(">>>>>>>>>>>> this.state.interventionLinks", this.state.interventionLinks);
	// 	console.log(">>>>>>>>>>>> this.props.intervention.interventionLinkList", this.props.intervention.interventionLinkList);
	// 	if (this.state.deleteModalVisible) {
	// 		return (
	// 			<div>
	// 				<interventionDeleteModel
	// 					treatmentId={this.state.treatmentId}
	// 					visible={this.state.deleteModalVisible} />
	// 			</div>
	// 		);
	// 	} else {
	// 		confirm({
	// 			title: i18Get("Are you sure you want to delete ?"),
	// 			icon: <ExclamationCircleOutlined />,
	// 			content: "",
	// 			okText: i18Get("Delete"),
	// 			cancelText: i18Get("Cancel"),
	// 			onOk: () => {
	// 				if (row && row.id) {
	// 					this.props.dispatch(interventionAction.removeIntervention(row.id));
	// 				}
	// 			},
	// 			onCancel: () => {
	// 			}
	// 		});
	// 	}
	// };

	approveClicked(treatment) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, treatmentId: treatment.id });
		};
	}

	editClicked(treatment) {
		return (e) => {
			e.preventDefault();
			this.setState({ editModel: true, treatmentId: treatment.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false, editModel: false });
		this.fetchDataFromServer();
	};

	deleteCompleted = () => {
		this.setState({ deleteModalVisible: false });
		this.fetchDataFromServer();
	};

	getContent(data) {
		return (
			<div className="padding-10 success_error_popover font_size_12px">
				<div className="display_inline_flex w_100">
					<span className="success_error_key bold"><I18 tkey="Consumable Count"/></span>
					<span className="">{data.wfaConsumableCount ? data.wfaConsumableCount : 0}</span>
				</div>
				<div className="display_inline_flex w_100">
					<span className="success_error_key bold"><I18 tkey="Anaesthesia Count"/></span>
					<span className="">{data.wfaSubTreatmentCount ? data.wfaSubTreatmentCount : 0}</span>
				</div>
			</div>
		);
	}

	getActionButton = (row) => {
		return (
			<div className="">
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<EditOutlined  className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
							: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
					}
				</div>
				<div className="min_width_12_px">
					{
						row.value.status === statusList.STATUS.values.APPROVED ?
							<DeleteOutlined  className="delete_color" type="delete" onClick={() => this.interventionLinkList(row.value)}/>
							: ""
					}
				</div>
				<div className="min_width_12_px">
					{
						(row.value.wfaSubTreatmentCount || row.value.wfaConsumableCount) ? 
							<Popover 
								content={this.getContent(row.value ? row.value : {})} 
								title={i18Get("Waiting For Approval Count", utils.getLanguage())}
								trigger="click" 
								placement="topRight">
								<a><img className="margin-left-10" src="/images/info.png" /></a>
							</Popover>
							: "" }
				</div>
			</div>
		);
	};

	moveToDetails = (value) => {
		if (value.status === statusList.STATUS.values.APPROVED)
		{this.props.history.push(this.props.location.pathname + "/" + value.id + this.props.location.search);}
	};


	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.interventions, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => {return (<Loader loading={this.state.loading} isTable={true}/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), column(this.getActionButton, this.moveToDetails, this.state.selectedColumn), true, false, 
					this.onPageChange.bind(this), ((this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "SCANNED TREATMENTS" : "INTERVENTION"), 
					`-highlight Virtualized_react_table_scroll editable_table ReactVirtualized_rt_table_height Virtualized_react_table_scroll ${(this.props.location.pathname.indexOf(locations.scannedTreatments) !== -1) ? "scanned_treatments_table" : this.props.organization === locations.tabMom ? "intervention_table" : "table_head_element_hide" }`, 
					this.onPageSizeChange.bind(this))}
				getTrProps={function(state, rowInfo) {
					return {
						style: {
							background: rowInfo && rowInfo.index === this.state.activeCompany ? "#e8eaea7a" : ""
						}
					};
				}.bind(this)} 
				defaultSorted={[
					{
						id: this.state.sort ? this.state.sort : "name",
						desc: this.state.desc ? true : false 
					}
				]}
				rowScroll={this.rowScroll.bind(this)}
				rowScrollIndex={this.state.rowScrollIndex}/>
		);
	}
    
	render() {  

		if (this.props.intervention.selectedColumnTimeSheet) {
			this.state.selectedColumn = this.props.intervention.selectedColumnTimeSheet;
		}
		
		if (this.props.intervention.filterData && Object.keys(this.props.intervention.filterData).length) {
			// this.checkChangeInFilter(this.props.intervention.filterData);
			if (!this.props.intervention.ignorePageChange) {
				this.state.page = 1;
			}
			this.state.filter = JSON.parse(JSON.stringify(this.props.intervention.filterData));
			this.props.dispatch(interventionAction.clearFilter());
			this.fetchDataFromServer();
		}
		if (this.state.organization !== this.props.organization) {
			this.state.interventions = [];
			this.state.totalPages = 1;
			this.state.organization = this.props.organization;
			const filterData = utils.getFilterInLocalStorage(`INTERVENTION_${this.props.organization.toUpperCase()}_LIST`);
			if (filterData && (typeof filterData === "object") && Object.keys(filterData).length) {
				this.state.page = filterData.page ? filterData.page : 1;
				this.state.pageSize = filterData.pageSize ? filterData.pageSize : utils.getDefaultPageSize();
				this.state.sort = filterData.sort ? filterData.sort : "name";
				this.state.desc = filterData.desc ? filterData.desc : 0;
				this.state.rowScrollIndex = filterData.rowScrollIndex ? filterData.rowScrollIndex : 0;
			}
		}
		if ((this.state.organizationMode !== this.props.organizationMode) || (this.state.typeActivity !== this.props.typeActivity)) {
			this.state.page = 1;
			this.state.interventions = [];
			this.state.typeActivity = this.props.typeActivity;
			this.state.loading = true;       
			this.state.organizationMode = this.props.organizationMode;
			this.fetchDataFromServer();
		}
		if (this.props.intervention.fetchAllInterventions === "FULFILLED") {
			if (this.state.fetchAllMeta === this.props.intervention.interventionsListMeta) {
				this.state.interventions = this.props.intervention.interventions.data;
				this.state.totalPages = parseInt(Math.ceil(this.props.intervention.interventions.count / this.state.pageSize));
				this.state.loading = false;
				this.props.totalCount(this.props.intervention.interventions.count);
			}
			this.props.dispatch(interventionAction.clearGetAll());
		}

		if (this.props.intervention.getInterventionLinkStatus === "FULFILLED") {
			this.setState({ interventionLinks: this.props.intervention.interventionLinkList });
			if (this.props.intervention.interventionLinkList.length > 0) {
				this.setState({  isInterventionLinked: true });
			}
			this.props.dispatch(interventionAction.getInterventionLinksCompleted());
		}
		if (this.props.intervention.getInterventionLinkStatus === "REJECTED") {
			this.setState({  isInterventionLinked: false });
			this.props.dispatch(interventionAction.getInterventionLinksCompleted());
		}

		return (<div>
			{this.getList()}
			{  this.state.modelVisible ?
				<div>
					<AssignAndApproveModel
						refreshCompleted={this.refreshCompleted}
						treatmentId={this.state.treatmentId}
						visible={this.state.modelVisible} />
				</div>
				: ""
			}
			{  this.state.editModel ?
				<div>
					<InterventionEditModel
						refreshCompleted={this.refreshCompleted}
						treatmentId={this.state.treatmentId}
						visible={this.state.editModel} />
				</div>
				: ""
			}
			{  this.state.deleteModalVisible ?
				<div>
					<InterventionDeleteModel
						deleteCompleted={this.deleteCompleted}
						treatmentId={this.state.treatmentId}
						visible={this.state.deleteModalVisible} 
						isInterventionLinked={this.state.isInterventionLinked}/>
				</div>
				: ""
			}
		</div>);
	}

	// componentDidUpdate() {
	// 	if (this.props.intervention.deleteInterventionStatus === "FULFILLED") {
	// 		this.props.dispatch(interventionAction.removeInterventionCompleted());
	// 		this.fetchDataFromServer();
	// 	}
	// 	if (this.props.intervention.deleteInterventionStatus === "REJECTED") {
	// 		this.props.dispatch(interventionAction.removeInterventionCompleted());
	// 	}
	// }
}

InterventionList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	intervention: store.intervention,
	translations: store.translations
}))(InterventionList)); 