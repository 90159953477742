import React from "react";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import locations from "../../../../../../../../router/locations";

function getText(value) {
	return <div>{value}</div>;
}

export default function getCols(editClicked, deleteClicked, roleNavigation) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: d => d.id,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: row => (
				<div className="margin_left_auto min_w_34 text_align_left">
					<EditOutlined className="edit_color margin-right-10" type="edit"  onClick={editClicked(row.value)}/>
					<DeleteOutlined className="delete_color" type="delete"  onClick={deleteClicked(row.value)}/>
				</div>
			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: d => d.code,
			resizable: true,
			sortable: false,
			width: 120,
			Cell: row => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("ROLE NAME", utils.getLanguage()),
			id: "name",
			accessor: d => d,
			resizable: true,
			width: 600,
			Cell: row => (
				row.value.roleType === "PATHWAY_USER" ? <a onClick={() => { roleNavigation(locations.userManagement_permissions, row.value.id); }}>{row.value.name}</a> : row.value.name
			)
		},
		{
			Header: i18Get("ASSIGN SITE", utils.getLanguage()),
			id: "roleType",
			accessor: d => d.roleType,
			sortable: false,
			resizable: true,
			Cell: row => (
				row.value === "PATHWAY_USER" ? <I18 tkey="Yes"/> : <I18 tkey="No"/>
			)
		},
		{
			Header: i18Get("NUMBER OF USERS", utils.getLanguage()),
			id: "isApproved",
			accessor: d => d.noOfUsers,
			resizable: true,
			sortable: false,
			width: 150,
			Cell: row => (
				getText(row.value)
			)
		}
        
	]);
	return tableArray;
}