// import i18 from "i18";
import { AnnotationConstraints } from "@syncfusion/ej2-react-diagrams";

export default [
	{
		id: "link1",
		type: "Orthogonal",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		targetDecorator: { shape: "None" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link2",
		type: "Straight",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		targetDecorator: { shape: "None" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link3",
		type: "Bezier",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		targetDecorator: { shape: "None" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link4",
		type: "Straight",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link5", 
		type: "Straight", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } }, 
		style: { strokeWidth: 2, strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link6", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 }, 
		type: "Straight",
		targetDecorator: { style: { strokeColor: "#757575", fill: "#757575" } },
		shape: {
			type: "Bpmn",
			flow: "Association",
			association: "Directional"
		}, style: {
			strokeDashArray: "2,2", strokeColor: "#757575"
		},
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link7", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 }, 
		type: "Straight",
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } }, 
		style: { strokeWidth: 2, strokeDashArray: "4 4", strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link8",
		type: "Orthogonal",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link9", 
		type: "Orthogonal", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } }, 
		style: { strokeWidth: 2, strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link10", 
		type: "Orthogonal", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } }, 
		style: { strokeWidth: 2, strokeDashArray: "4 4", strokeColor: "#757575" },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link11", 
		sourcePoint: { x: 0, y: 0 }, 
		targetPoint: { x: 40, y: 40 }, 
		type: "Orthogonal",
		targetDecorator: { style: { strokeColor: "#757575", fill: "#757575" } },
		shape: {
			type: "Bpmn",
			flow: "Association",
			association: "Directional"
		}, style: {
			strokeDashArray: "2,2", strokeColor: "#757575"
		},
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link12",
		type: "Bezier",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		style: { strokeWidth: 1, strokeColor: "#757575" },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link13", sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 }, type: "Bezier",
		targetDecorator: { style: { strokeColor: "#757575", fill: "#757575" } },
		shape: {
			type: "Orthogonal",
			flow: "Association",
			association: "Directional"
		}, style: {
			strokeDashArray: "2,2", strokeColor: "#757575"
		},
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link14", sourcePoint: { x: 0, y: 0 }, targetPoint: { x: 40, y: 40 }, type: "Bezier",
		targetDecorator: { style: { strokeColor: "#757575", fill: "#757575" } },
		shape: {
			type: "Orthogonal",
			flow: "Association",
			association: "Directional"
		}, style: {
			strokeWidth: 2, strokeDashArray: "4 4", strokeColor: "#757575"
		},
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "link15",
		type: "Bezier",
		sourcePoint: { x: 0, y: 0 },
		targetPoint: { x: 60, y: 60 },
		style: { strokeWidth: 2, strokeColor: "#757575" },
		targetDecorator: { shape: "Arrow", style: { strokeColor: "#757575", fill: "#757575" } },
		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	},
	{
		id: "Link16",
		type: "Orthogonal",
		sourcePoint: {  x: 250, y: 250 },
		targetPoint: { x: 350, y: 350  },
		style: { strokeWidth: 2, strokeColor: "#757575" },
		segments: [
			{
				type: "Orthogonal",
				// Defines the direction for the segment lines
				direction: "Right",
				// Defines the length for the segment lines
				length: 70
			},
			{
				type: "Orthogonal",
				direction: "Bottom",
				length: 20
			}
		],

		annotations: [{ content: " ", constraints: AnnotationConstraints.ReadOnly |  AnnotationConstraints.Interaction | AnnotationConstraints.Drag }]
	}
];