import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import StepTypeDescription from "../../../../../../constants/stepTypeDescription.json";

const { Option } = Select;

class clinicalPathwayTypeDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	renderClinicalPathwayTypeOptions() {
		const options = [];
		if (this.props.clinicalPathwayType === "MEDICINE_WITHOUT_ACTE") {
			options.push(
				<Option
					key="ARRIVAL_DAY"
					value="ARRIVAL_DAY"
					description={StepTypeDescription["ARRIVAL_DAY"]}
				>
					<I18 tkey="ARRIVAL DAY" />
				</Option>);
			options.push(<Option key="ACCOMMODATION_DAY" value="ACCOMMODATION_DAY"><I18 tkey="ACCOMMODATION DAY" /></Option>);
			options.push(
				<Option
					key="EXIT_DAY"
					value="EXIT_DAY"
					description={StepTypeDescription["EXIT_DAY"]}
				>
					<I18 tkey="EXIT DAY" /></Option>);
		} else {
			options.push(
				<Option
					key="ARRIVAL_DAY"
					value="ARRIVAL_DAY"
					description={StepTypeDescription["ARRIVAL_DAY"]}
				>
					<I18 tkey="ARRIVAL DAY" />
				</Option>);
			options.push(
				<Option
					key="BEFORE_SURGERY_DAY"
					value="BEFORE_SURGERY_DAY"
					description={StepTypeDescription["BEFORE_SURGERY_DAY"]}
				>
					<I18 tkey="BEFORE PROCEDURE DAY" />
				</Option>);
			options.push(
				<Option
					key="SURGERY_DAY"
					value="SURGERY_DAY"
					description={StepTypeDescription["SURGERY_DAY"]}
				>
					<I18 tkey="PROCEDURE DAY" /></Option>);
			options.push(
				<Option
					key="AFTER_SURGERY_CUSTOM_DAY"
					value="AFTER_SURGERY_CUSTOM_DAY"
					description={StepTypeDescription["AFTER_SURGERY_CUSTOM_DAY"]}
				>
					<I18 tkey="AFTER PROCEDURE CUSTOM DAY" /></Option>);
			options.push(
				<Option
					key="AFTER_SURGERY_DAY"
					value="AFTER_SURGERY_DAY"
					description={StepTypeDescription["AFTER_SURGERY_DAY"]}
				>
					<I18 tkey="AFTER PROCEDURE DAY" /></Option>);
			options.push(
				<Option
					key="EXIT_DAY"
					value="EXIT_DAY"
					description={StepTypeDescription["EXIT_DAY"]}
				>
					<I18 tkey="EXIT DAY" /></Option>);
		}
		return options;
	}

	render() {
		return (
			<div className={this.props.className ? this.props.className : "w_200p di mb_15_mob"}>
				<Select
					value={this.props.stepType}
					disabled={this.props.disabled}
					className="w_100 di"
					defaultActiveFirstOption
					onSelect={(value, e) => this.props.selectedStepType(value, e)}
					placeholder={i18Get("Select Step Type", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
				>
					{this.renderClinicalPathwayTypeOptions()}
				</Select>
			</div>
		);
	}
}

clinicalPathwayTypeDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(clinicalPathwayTypeDropdown));
