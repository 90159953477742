import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import HospitalList from "../hospitalList";
import Community from "../selectCommunitySection";

class CreateSites extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="bloc_create_section padding-0">
				<div className="bloc_create_box">
					<div className="row">

						<div className="">
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Code" /></label>
									<NameInput
										value={this.props.code}
										inputChanged={this.props.codeChanged}
										maxLength={5}
									/>
									{this.props.invalidCode && <span className="invalid_right fixed_label"><I18 tkey="Enter valid code" /></span>}
									{!this.props.invalidCode && <span className="invalid_right fixed_label color_gray"><I18 tkey="5 Digit" /></span>}
								</div>
							</div>

							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Short Name" /></label>
									<NameInput
										value={this.props.shortName}
										inputChanged={this.props.shortNameChanged}
										maxLength={10}
									/>
									{this.props.invalidShortName && <span className="invalid_right fixed_label"><I18 tkey="(a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								</div>
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name of the Site" /></label>
								<NameInput
									value={this.props.name}
									inputChanged={this.props.nameChanged}
									maxLength={60}
								/>
								{this.props.invalidName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Hospital" /></label>
								<HospitalList
									data={this.props.selectHospital}
									onChange={this.props.onHospitalChanged}
								/>
								{this.props.invalidHospital && <span className="invalid_right fixed_label"><I18 tkey="Select hospital " /></span>}
							</div>
						</div>
						<div className="col-md-12 col-sm-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Community of Practice" /></label>
								<Community
									data={this.props.selectedCommunity}
									communityChanged={this.props.onCommunityChanged}
								/>
								{this.props.invalidCommunity && <span className="invalid_right fixed_label"><I18 tkey="Select community of practice" /></span>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateSites.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateSites));
