import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	clinicalPathway: [],
	fetchAllClinicalPathway: false,
	updateClinicalPathway: false,
	diagramDataSortUpdatedcount: null,
	diagramInfo: [],
	getOneDetails: null
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.CLEAR_FILTER}`:
			return {
				...state,
				filterData: {}
			};

		case `${eventNames.HEALTH_PATHWAY_GET_ALL}_FULFILLED`:
			return {
				...state,
				healthPathway: action.payload.data.data,
				healthPathwayListMeta: action.meta,
				fetchAllHealthPathway: "FULFILLED"
			};
		case `${eventNames.HEALTH_PATHWAY_GET_ALL}_REJECTED`:
			return {
				...state,
				fetchAllHealthPathway: "REJECTED"
			};

		case eventNames.CLEAR_HEALTH_PATHWAY_GET_ALL:
			return {
				...state,
				healthPathway: [],
				fetchAllHealthPathway: false
			};

		case `${eventNames.HEALTH_PATHWAY_GET_ONE}_FULFILLED`:
			return {
				...state,
				healthPathway: action.payload.data.data,
				getOneDetails: action.payload.data.data,
				fetchOneHealthPathway: "FULFILLED"
			};
		case `${eventNames.HEALTH_PATHWAY_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneHealthPathway: "REJECTED"
			};
		case eventNames.CLEAR_HEALTH_PATHWAY_GET_ONE:
			return {
				...state,
				fetchOneHealthPathway: false
			};

		case `${eventNames.HEALTH_PATHWAY_GET_ONE_BY_CODE}_FULFILLED`:
			return {
				...state,
				healthPathwayByCode: action.payload.data.data,
				meta: action.meta,
				fetchOneHealthPathwayByCode: "FULFILLED"
			};
		case `${eventNames.HEALTH_PATHWAY_GET_ONE_BY_CODE}_REJECTED`:
			return {
				...state,
				fetchOneHealthPathwayByCode: "REJECTED"
			};
		case eventNames.CLEAR_HEALTH_PATHWAY_GET_ONE_BY_CODE:
			return {
				...state,
				fetchOneHealthPathwayByCode: false
			};
		case `${eventNames.HEALTH_PATHWAY_UPDATE}_FULFILLED`:
			return { ...state, editHealthPathwayStatus: "FULFILLED" };
		case `${eventNames.HEALTH_PATHWAY_UPDATE}_REJECTED`:
			return { ...state, editHealthPathwayStatus: "REJECTED" };
		case eventNames.CLEAR_HEALTH_PATHWAY_UPDATE:
			return { ...state, editHealthPathwayStatus: false };

		case `${eventNames.HEALTH_PATHWAY_CREATE}_FULFILLED`:
			return {
				...state,
				createdHealthPathwayData: action.payload.data.data,
				createHealthPathway: "FULFILLED"
			};
		case `${eventNames.HEALTH_PATHWAY_CREATE}_REJECTED`:
			return {
				...state,
				createHealthPathway: "REJECTED"
			};
		case eventNames.CLEAR_HEALTH_PATHWAY_CREATE:
			return {
				...state,
				createdHealthPathway: [],
				createHealthPathway: false
			};

		case `${eventNames.SAVE_SORTABLE_TASK_LIST}_FULFILLED`:
			return {
				...state,
				reorderTaskData: action.payload.data.data,
				taskListreorder: "FULFILLED",
				diagramDataSortUpdatedcount: state.diagramDataSortUpdatedcount + 1
			};
		case `${eventNames.SAVE_SORTABLE_TASK_LIST}_REJECTED`:
			return {
				...state,
				taskListreorder: "REJECTED"
			};
		case eventNames.CLEAR_SAVE_SORTABLE_TASK_LIST:
			return {
				...state,
				reorderTaskData: [],
				taskListreorder: false
			};
		case eventNames.CLEAR_DIAGRAM_SOFRT_UPDATED_FLAG:
			return {
				...state,
				diagramDataSortUpdated: false
			};
		case `${eventNames.DELETE_HEALTH_PATHWAY}_FULFILLED`:
			return { ...state, deleteHealthPathwayStatus: "FULFILLED", 
				notClpDeleteInfo: action.payload.data.data };
		case `${eventNames.DELETE_HEALTH_PATHWAY}_REJECTED`:
			return { ...state, deleteHealthPathwayStatus: "REJECTED" };
		case eventNames.DELETE_HEALTH_PATHWAY_COMPLETED:
			return { ...state, deleteHealthPathwayStatus: false };

		case `${eventNames.GET_ONE_HEALTH_PATHWAY_HOSPITAL}_FULFILLED`:
			return {
				...state,
				healthPathway: action.payload.data.data,
				fetchOneHealthPathwayUsingHospitalId: "FULFILLED"
			};
		case `${eventNames.GET_ONE_HEALTH_PATHWAY_HOSPITAL}_REJECTED`:
			return {
				...state,
				fetchOneHealthPathwayUsingHospitalId: "REJECTED"
			};
		case eventNames.CLEAR_GET_ONE_HEALTH_PATHWAY_HOSPITAL:
			return {
				...state,
				fetchOneHealthPathwayUsingHospitalId: false
			};
		case `${eventNames.HEALTH_PATHWAY_EXPORT}_FULFILLED`:
			return { ...state, healthPathwayListExportStatus: "FULFILLED", healthPathwayReportList: action.payload.data.data };
		case `${eventNames.HEALTH_PATHWAY_EXPORT}_REJECTED`:
			return { ...state, healthPathwayListExportStatus: "REJECTED" };
		case eventNames.HEALTH_PATHWAY_EXPORT_COMPLETED:
			return { ...state, healthPathwayListExportStatus: false };

		case `${eventNames.CHECK_HEALTH_PATHWAY_REPORT_STATUS}_FULFILLED`:
			return { ...state, healthPathwayReportStatus: "FULFILLED",  healthPathwayReportStage: action.payload.data.data };
		case `${eventNames.CHECK_HEALTH_PATHWAY_REPORT_STATUS}_REJECTED`:
			return { ...state, healthPathwayReportStatus: "REJECTED", healthPathwayReportStage: action.payload.data.data };
		case eventNames.CHECK_HEALTH_PATHWAY_REPORT_STATUS_COMPLETED:
			return { ...state, healthPathwayReportStatus: false };

		case `${eventNames.GET_LINKED_DATA}_FULFILLED`:
			return { ...state, linkedDataStatus: "FULFILLED", linkedData: action.payload.data.data };
		case `${eventNames.GET_LINKED_DATA}_REJECTED`:
			return { ...state, linkedDataStatus: "REJECTED" };
		case eventNames.CLEAR_GET_LINKED_DATA:
			return { ...state, linkedDataStatus: false };

		case `${eventNames.GENERATE_DIAGRAM_EXPORT_EXCEL}_FULFILLED`:
			return { ...state, diagramExportStatus: "FULFILLED", diagramExportData: action.payload.data.data };
		case `${eventNames.GENERATE_DIAGRAM_EXPORT_EXCEL}_REJECTED`:
			return { ...state, diagramExportStatus: "REJECTED" };
		case eventNames.CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL:
			return { ...state, diagramExportStatus: false, diagramExportData: null };

		case `${eventNames.GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS}_FULFILLED`:
			return { ...state, healthPathwayExcelExportStatus: "FULFILLED",  healthPathwayExcelExportStage: action.payload.data.data };
		case `${eventNames.GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS}_REJECTED`:
			return { ...state, healthPathwayExcelExportStatus: "REJECTED", healthPathwayExcelExportStage: action.payload.data.data };
		case eventNames.CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS:
			return { ...state, healthPathwayExcelExportStatus: false,  healthPathwayExcelExportStage: null };

		case `${eventNames.GET_ALL_DIAGRAM_ADDITIONAL_INFO}_FULFILLED`:
			return { ...state, diagramAdditionalInfo: action.payload.data.data, fetchAllDiagramAdditionalInfo: "FULFILLED" };
		case `${eventNames.GET_ALL_DIAGRAM_ADDITIONAL_INFO}_REJECTED`:
			return { 	...state, fetchAllDiagramAdditionalInfo: "REJECTED" 	};
		case eventNames.CLEAR_GET_ALL_DIAGRAM_ADDITIONAL_INFO:
			return { ...state, fetchAllDiagramAdditionalInfo: false };

		case `${eventNames.GET_DIAGRAM_INFO}_FULFILLED`:
			return { ...state, diagramInfo: action.payload.data.data, fetchAllDiagramInfo: "FULFILLED" };
		case `${eventNames.GET_DIAGRAM_INFO}_REJECTED`:
			return { 	...state, fetchAllDiagramInfo: "REJECTED" 	};

			
		case `${eventNames.GET_ALL_DIAGRAM_SORTABLE_INFO}_FULFILLED`:
			return { ...state, diagramSortableInfo: action.payload.data.data, fetchAllDiagramSortableInfo: "FULFILLED" };
		case `${eventNames.GET_ALL_DIAGRAM_SORTABLE_INFO}_REJECTED`:
			return { 	...state, fetchAllDiagramSortableInfo: "REJECTED" 	};
		case eventNames.CLEAR_GET_ALL_DIAGRAM_SORTABLE_INFO:
			return { ...state, fetchAllDiagramSortableInfo: false };

		case `${eventNames.GET_ALL_DIAGRAM_SORTABLE_TASK_INFO}_FULFILLED`:
			return { ...state, diagramSortableTaskInfo: action.payload.data.data, fetchAllDiagramSortableTaskInfo: "FULFILLED" };
		case `${eventNames.GET_ALL_DIAGRAM_SORTABLE_TASK_INFO}_REJECTED`:
			return { 	...state, fetchAllDiagramSortableTaskInfo: "REJECTED" 	};
		case eventNames.CLEAR_GET_ALL_DIAGRAM_SORTABLE_TASK_INFO:
			return { ...state, fetchAllDiagramSortableTaskInfo: false };

		case `${eventNames.SAVE_ALL_DIAGRAM_SORTABLE_INFO}_FULFILLED`:
			return { ...state, statusOfSavingDiagramSortableInfo: "FULFILLED" };
		case `${eventNames.SAVE_ALL_DIAGRAM_SORTABLE_INFO}_REJECTED`:
			return { 	...state, statusOfSavingDiagramSortableInfo: "REJECTED" 	};
		case eventNames.CLEAR_SAVE_ALL_DIAGRAM_SORTABLE_INFO:
			return { ...state, statusOfSavingDiagramSortableInfo: false };

		case `${eventNames.GET_TRAJECTORY_INFORMATION}_FULFILLED`:
			return { ...state, geTrajectoryData: action.payload.data.data, fetchTrajectoryData: "FULFILLED" };
		case `${eventNames.GET_TRAJECTORY_INFORMATION}_REJECTED`:
			return { 	...state, fetchTrajectoryData: "REJECTED" 	};
		case eventNames.CLEAR_GET_TRAJECTORY_INFORMATION:
			return { ...state, fetchTrajectoryData: false };

		case `${eventNames.GET_DIAGRAM_VERSIONS}_FULFILLED`:
			return { ...state, diagramVersionData: action.payload.data.data, fetchAllDiagramVersion: "FULFILLED" };
		case `${eventNames.GET_DIAGRAM_VERSIONS}_REJECTED`:
			return { 	...state, fetchAllDiagramVersion: "REJECTED" 	};
		case eventNames.CLEAR_GET_DIAGRAM_VERSIONS:
			return { ...state, fetchAllDiagramVersion: false };

		case `${eventNames.GET_DIAGRAM_DETAILS}_FULFILLED`:
			return { ...state, diagramVersionDetails: action.payload.data.data, isCurrentDiagramVersion: action.meta,  fetchDiagramVersion: "FULFILLED" };
		case `${eventNames.GET_DIAGRAM_DETAILS}_REJECTED`:
			return { 	...state, fetchDiagramVersion: "REJECTED" 	};
		case eventNames.CLEAR_GET_DIAGRAM_DETAILS:
			return { ...state, fetchDiagramVersion: false };

		case `${eventNames.DELETE_DIAGRAM_SUB_VERSIONS}_FULFILLED`:
			return { ...state, deleteDiagramVersion: "FULFILLED" };
		case `${eventNames.DELETE_DIAGRAM_SUB_VERSIONS}_REJECTED`:
			return { 	...state, deleteDiagramVersion: "REJECTED" 	};
		case eventNames.CLEAR_DELETE_DIAGRAM_SUB_VERSIONS:
			return { ...state, deleteDiagramVersion: false };	
		case eventNames.SET_SELECTED_TRAJECTORY:
			return {
				...state,
				selectedTrajectory: action.payload
			};	
		default:
			return state;
	}
}
