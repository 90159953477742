import React, { useEffect, useState } from "react";
import { i18Get } from "i18";
import ActionButton from "plug/action-button";
import { Slider } from "antd";
import utils from "utils/utils";
import { PlusOperater, CloseCircle } from "utils/icons";
import { CloseOutlined, ArrowDownOutlined } from "@ant-design/icons";
import MissLink from "../missLink";
import Draggable from 'react-draggable';
const  defaultZoom = 50;

function FloatingAction (props) {

	const [slide, setSlide] = useState(50);
	const [isclosed, setIsClosed] = useState(false);

	useEffect( () => {
		const zoomFactor = utils.getFilterInLocalStorage("DIAGRAM_SETTINGS");
		if (zoomFactor) {
			if (zoomFactor.zoomSlider)  { setSlide(zoomFactor.zoomSlider); }
		}
	}, []);

	const onChange = (e) => {
		setSlide(e);
		props.onZoomSliderChange(e);
	};

	const handleClose = () =>{
		setIsClosed(! isclosed);
	};


	return 	<Draggable><div className="floating-button">
		{!isclosed ? <div>
		<div className="pt-4 pb-4 padding-top-10 padding-bottom-10 settings_container all-scroll">
			<MissLink />
			<ActionButton disabled={props.actionDisabled}  value={i18Get("Back", utils.getLanguage())} onClick={props.backToDetails} />
			<ActionButton disabled={props.actionDisabled}  value={i18Get("Save", utils.getLanguage())} onClick={props.onSave} />
			{props.sideBarSettings ? <span onClick={() => { props.hideShowNodeSideBar(); }} >{ props.sideBarStatus ? <PlusOperater /> : <CloseCircle /> }</span> : "" }
		</div>
		<div className="diagram_zoom_slider_container">
			<Slider
				min={10}
				marks={{
					25: "50%",
					50: "100%",
					75: "150%",
					100: "200%"
				}}
				defaultValue={defaultZoom}
				trackStyle={{ backgroundColor: "transparent" }}
				handleStyle={{ backgroundColor: "#3740f2" }}
				tooltipVisible={false}
				onChange={onChange}
				value={slide}
			/>
		</div>
		</div> : ""}
		<div className="diagram_zoom_slider_closeButton">
		<span onClick={() => { handleClose() }}>{isclosed ? <ArrowDownOutlined/> : <CloseOutlined onClick={() =>{handleClose()}}/> }</span>
		</div>
	</div></Draggable>;

}
export default FloatingAction;                    