import eventNames from "./event";
const initialState = {
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_ROLES_LIST}_FULFILLED`:
			return {
				...state,
				data: action.payload.data.data,
				getMeta: action.meta,
				getAllStatus: "FULFILLED"
			};
		case `${eventNames.GET_ALL_ROLES_LIST}_REJECTED`:
			return {
				...state,
				getAllStatus: "REJECTED"
			};
        
		case eventNames.CLEAR_GET_ALL_ROLES_LIST:
			return {
				...state,
				getAllStatus: false
			};

		//===============GETONE===========================
		case `${eventNames.GET_ONE_ROLE}_FULFILLED`:
			return {
				...state,
				getOneData: action.payload.data.data,
				getMeta: action.meta,
				getOneStatus: "FULFILLED"
			};
		case `${eventNames.GET_ONE_ROLE}_REJECTED`:
			return {
				...state,
				getOneData: "",
				getOneStatus: "REJECTED"
			};
        
		case eventNames.CLEAR_GET_ONE_ROLE:
			return {
				...state,
				getOneData: "",
				getOneStatus: false
			};
		//===============CREATE===========================
		case `${eventNames.CREATE_ROLES}_FULFILLED`:
			return {
				...state,
				data: action.payload.data.data,
				getMeta: action.meta,
				isCreatedStatus: "FULFILLED"
			};
		case `${eventNames.CREATE_ROLES}_REJECTED`:
			return {
				...state,
				isCreatedStatus: "REJECTED"
			};
        
		case eventNames.CLEAR_CREATE_ROLES:
			return {
				...state,
				isCreatedStatus: false
			};
		//===============UPDATE===========================
		case `${eventNames.UPDATE_USER_ROLES}_FULFILLED`:
			return {
				...state,
				data: action.payload.data.data,
				isUpdatedStatus: "FULFILLED"
			};
		case `${eventNames.UPDATE_USER_ROLES}_REJECTED`:
			return {
				...state,
				isUpdatedStatus: "REJECTED"
			};
        
		case `${eventNames.CLEAR_UPDATE_USER_ROLES}`:
			return {
				...state,
				isUpdatedStatus: false
			};
		//===============UPDATE===========================
		case `${eventNames.DELETE_ROLES}_FULFILLED`:
			return {
				...state,
				isDeleteStatus: "FULFILLED"
			};
		case `${eventNames.DELETE_ROLES}_REJECTED`:
			return {
				...state,
				isDeleteStatus: "REJECTED"
			};
        
		case eventNames.CLEAR_DELETE_ROLES:
			return {
				...state,
				isDeleteStatus: false
			};
		default:
			return state;
	}
}