import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get }  from "../../../../../../../../i18";
import clinicalPathwayDetailsAction from "../../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../../utils/utils";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import isPermissionGranted from "../../../../../../../../utils/permission";
import { statusList } from "../../../../../../../../constants";

class Eligibility  extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			eligibility: {}
		};
	} 

	UNSAFE_componentWillMount() {
		this.onFetchData();
	}

	UNSAFE_componentWillUpdate () {
		if (this.state.treatmentId !== this.props.treatmentId)
		{ 
			this.setState({ treatmentId: this.props.treatmentId }, this.onFetchData());
		}
	}

	onFetchData() {
		this.props.dispatch(clinicalPathwayDetailsAction.getEligibility((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), this.props.type));
	}
	init() {
		const contentBlock = htmlToDraft(this.state.eligibility.description ? this.state.eligibility.description : `<p></p><p style="text-align:center;">${i18Get("NO PATHWAY ELIGIBILITY FOUND", utils.getLanguage())}</p>`);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);        
		}
	}

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathway.clinicalPathway.isReadOnly, method);
	};
	render() { 
		if (this.props.clinicalPathwayDetails.isEligibilityGetCompleted === "FULFILLED") {
			this.state.eligibility = this.props.clinicalPathwayDetails.eligibility[0] ? this.props.clinicalPathwayDetails.eligibility[0] : {};
			this.init();
			this.props.dispatch(clinicalPathwayDetailsAction.clearGetEligibility());
		}
        
		return (            
			<div className="editor_no_edit">
				<div className="table_heading"><I18 tkey='PATHWAY ELIGIBILITY'/>
					{this.isPermissionGranted("EDIT") ?
						<button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "Pathway_Eligibility")}><I18 tkey="EDIT"/></button>
						: <span className="text_align_right edit_button_clp"></span> }   
				</div>                               
				<div className="di w_100 float_left">
					<Editor
						readonly={true}
						toolbarHidden={true}
						wrapperClassName="wrapper-class"
						editorClassName="editor-class no_scroll_pathway "
						toolbarClassName="toolbar-class"
						editorState={this.state.editorState}
					/>
				</div>
				<div className="di w_100">
					<div className="text_align_right di w_100 margin-bottom-10">
					</div>
				</div>
			</div>
		);
	}
}

Eligibility.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations,
	permissions: store.permissions,
	clinicalPathway: store.clinicalPathway
}))(Eligibility));