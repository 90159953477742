import React from "react";
import { Tooltip } from "antd";
import I18, { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";

export default function getCols(getActionButton, moveToDetails) {
	const tableArray = ([
		{
			Header: "",
			id: "actions",
			accessor: (d) => d,
			resizable: false,
			width: 70,
			sortable: false,
			Cell: (row) => (
				<div>
					{getActionButton(row)}
				</div>

			)
		},
		{
			Header: i18Get("CODE", utils.getLanguage()),
			id: "code",
			accessor: (d) => d,
			width: 130,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.code : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d,
			width: 350,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						`${row.value.hospitalId ? row.value.hospitalCode : row.value.hospitalName ? row.value.hospitalName : "MOM"} ${row.value.name}`
					}
				</span>
			)
		},
		{
			Header: i18Get("MODIFIED ON", utils.getLanguage()),
			id: "updatedAt",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.updatedAt : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("SPECIALITY", utils.getLanguage()),
			id: "specialityName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.specialityName : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("FAMILY", utils.getLanguage()),
			id: "familyName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value && row.value ? row.value.familyName : ""
					}
				</span>
			)
		},
		{
			Header: i18Get("TYPE", utils.getLanguage()),
			id: "clinicalPathway",
			accessor: (d) => d,
			sortable: false,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.clinicalPathway === "MEDICINE" ? <I18 tkey="MEDICINE WITH ACTE" /> : row.value.clinicalPathway.replace(/_/g, " ")
					}
				</span>
			)
		},
		{
			Header: i18Get("HOSPITAL", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.hospitalName ? row.value.hospitalName : <I18 tkey="MOM" />
					}
				</span>
			)
		},
		{
			Header: i18Get("SITE", utils.getLanguage()),
			id: "siteName",
			accessor: (d) => d,
			resizable: true,
			Cell: (row) => (
				<span onClick={() => { moveToDetails(row.value); }}>
					{
						row.value.hospitalId && row.value ? row.value.siteName : <I18 tkey="MOM" />
					}
				</span>
			)
		}
		// {
		// 	Header: i18Get("STATUS", utils.getLanguage()),
		// 	id: "status",
		// 	accessor: (d) => d,
		// 	width: 150,
		// 	resizable: true,
		// 	Cell: (row) => (
		// 		<span onClick={() => { moveToDetails(row.value); }}>
		// 			<Tooltip placement="top" title={i18Get(`${row.value.status ? row.value.status === "APPROVED" ? "Approved" : "Waiting For Approval" : ""} - From ${row.value.momHospitalName ? row.value.momHospitalName : "Master"}`, utils.getLanguage())}>
		// 				<div className="d-flex align-items-center">
		// 					{row.value.momHospitalName ? row.value.momHospitalName : "Master"}
		// 					<div className={`hospitalname_tag margin-left-10 ${row.value.momHospitalName ? "hospitalnameactive" : ""}`} />
		// 				</div>
		// 			</Tooltip>
		// 		</span>
		// 	)
		// }
	]);
	return tableArray;
}
