import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { Modal } from "antd";
import extClinicalPathwayActions from "../../../../../../../../../actions/ext-clinical-pathway";
import SelectTypeList from "./type-list";
import SelectResponisbleList from "./responsible-list";
import ActionButton from "../../../../../../../../../plug/action-button";
import events from "../../../../../../../../../actions/events";
import SelectItemList from "./item-list/index";
import Level from "./level-dropdown";
import DragWindow from "../../../drag-window";

class ClinicalPathwayCreateModel extends Component {
	constructor(props) {
		super(props);
		this.state = Object.assign({}, this.getInitialState());
	}

	UNSAFE_componentWillMount() {
		this.state.selectedClpForCreate = this.props.selectedClpForCreate;
	}

	getInitialState = () => {
		return {
			data: false,
			invalidData: false,
			invalidResponisble: false,
			type: "",
			extType: "",
			selectedClpForCreate: "",
			tab: "item",
			isSaveNext: false,
			invalidDataRegEx: false,
			invalidItemRegEx: false
		};
	};

	dataChanged(e) {
		const prevState = this.state;
		if (e.length <= 255) {
			prevState.data = e;
			if (!e) {
				prevState.responsible = null;
				this.props.dispatch({ type: events.TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
			}
			prevState.invalidData = false;
			prevState.invalidDataRegEx = false;
			this.setState(prevState);
		}
	}

	itemChanged(e) {
		const prevState = this.state;
		prevState.responsible = e.responsible && e.responsible.every((data) => data.value != null) ? e.responsible : null;
		prevState.invalidResponisble = false;
		this.setState(prevState);
	}

	onItemChanged(e) {
		const prevState = this.state;
		if (e.length <= 255) {
			prevState.item = e;
			prevState.invalidItem = false;
			prevState.invalidItemRegEx = false;
			this.setState(prevState);
		}
	}

	responsibleChanged(e) {
		if (e.length <= 5) {
			const prevState = this.state;
			prevState.responsible = e;
			prevState.invalidResponisble = false;
			this.setState(prevState);
		}
	}

	onLevelChanged(e) {
		this.state.level = e;
		this.state.invalidLevel = false;
		this.setState(this.state);
	}

	validateFields() {
		const prevState = this.state;
		if (!this.state.data || !this.state.data.trim() || !Object.keys(this.state.data).length || (this.state.data.match(/\\$/)) || (this.state.data.indexOf("\"") >= 0)) {
			prevState.invalidData = true;
		} else if (!utils.checkNameRegex(this.state.data)) {
			prevState.invalidData = true;
			prevState.invalidDataRegEx = true;

		}

		if (!this.state.responsible || this.state.responsible.length < 1) {
			prevState.invalidResponisble = true;
		}

		this.setState(prevState);
		if (!this.state.invalidData && !this.state.invalidResponisble) {
			return true;
		}
		return false;
	}

	validateItemOnlyFields() {
		const prevState = this.state;
		if (!this.state.item || !this.state.item.trim() || (this.state.item.match(/\\$/)) || (this.state.item.indexOf("\"") >= 0)) {
			prevState.invalidItem = true;
		} else if (!utils.checkNameRegex(this.state.item)) {
			prevState.invalidItem = true;
			prevState.invalidItemRegEx = true;

		}

		if (!this.state.level) {
			prevState.invalidLevel = true;
		}
		this.setState(prevState);
		if (!this.state.invalidItem && !this.state.invalidLevel) {
			return true;
		}
		return false;
	}

	addClicked(isSaveNext) {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				item: this.state.data.trim().charAt(0).toUpperCase() + this.state.data.trim().slice(1),
				categoryPersonnelIds: this.state.responsible.map(data => data.value),
				days: this.state.selectedClpForCreate.day ? this.state.selectedClpForCreate.day : null
			};
			this.props.dispatch(extClinicalPathwayActions.createExtClinicalPathway(this.props.match.params.id, this.state.selectedClpForCreate.id, payload));
			this.state.isSaveNext = isSaveNext;
		}
	}

	createItemOnly(isSaveNext) {
		if (this.validateItemOnlyFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				item: this.state.item.trim().charAt(0).toUpperCase() + this.state.item.trim().slice(1),
				days: this.state.selectedClpForCreate.day ? this.state.selectedClpForCreate.day : null,
				level: this.state.level
			};
			this.props.dispatch(extClinicalPathwayActions.createItem(this.props.match.params.id, this.state.selectedClpForCreate.id, payload));
			this.state.isSaveNext = isSaveNext;
		}
	}

	getLabel(txt) {
		return txt.replace(/_/g, " ").replace(/\w+/g, function (txt) { return txt.charAt(0) + txt.substr(1).toLowerCase(); });
	}

	tabChanged(tab) {
		if (tab) {
			const prevState = this.state;
			prevState.tab = tab;
			prevState.data = null;
			prevState.item = null;
			prevState.level = null;
			prevState.responsible = null;
			prevState.invalidData = false;
			prevState.invalidResponisble = false;
			prevState.invalidItem = false;
			prevState.invalidLevel = false;
			this.setState(prevState);
			this.props.dispatch({ type: events.TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
		}
	}

	loadContent() {
		return (
			<div className="create_modal_popup">
				<ul className="nav nav-tabs margin-bottom-8">
					<li className={(this.state.tab === "item") ? "active" : ""}>
						<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "item")}>
							<I18 tkey="ITEM" />
						</a>
					</li>
					<li className={(this.state.tab === "subTitle") ? "active" : ""}>
						<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "subTitle")}>
							<I18 tkey="SUB TITLE" />
						</a>
					</li>
				</ul>
				{
					this.state.tab === "item" ?
						<div className="row align_items_base">
							<div className="col-md-12 padding-left-0 padding-right-0">
								<SelectTypeList
									data={this.state.data}
									dataChanged={this.dataChanged.bind(this)}
									itemChanged={this.itemChanged.bind(this)}
									type={this.state.type}
									extType={this.state.selectedClpForCreate.id}
									days={this.state.selectedClpForCreate.day}
									invalidData={this.state.invalidData}
									invalidDataRgEx={this.state.invalidDataRegEx}
									onRef={ref => this.typeList = ref} />
							</div>
							<div className="col-md-12 padding-left-0 padding-right-0">
								<SelectResponisbleList
									data={this.state.responsible}
									responsibleChanged={this.responsibleChanged.bind(this)}
									type={this.state.type}
									extType={this.state.selectedClpForCreate.id}
									days={this.state.selectedClpForCreate.day}
									invalidData={this.state.invalidResponisble}
									onRef={ref => this.responsibleList = ref}
									setSingleDataDefault={ true }
									autoSetSingleOne={ false }
									multiselect={ true } />
							</div>
						</div>
						:
						<div className="row align_items_base ">
							<div className="col-md-12 padding-left-0 padding-right-0 react_multi_select_checkbox">
								<SelectItemList
									data={this.state.item}
									onItemChanged={this.onItemChanged.bind(this)}
									type={this.state.type}
									extType={this.state.selectedClpForCreate.id}
									days={this.state.selectedClpForCreate.day}
									invalidItem={this.state.invalidItem}
									invalidDataRgEx={this.state.invalidItemRegEx}
									onRef={ref => this.itemList = ref} />
							</div>
							<div className="col-md-6 padding-left-0 padding-right-0">
								<Level
									data={this.state.level}
									onLevelChanged={this.onLevelChanged.bind(this)}
									invalidLevel={this.state.invalidLevel}
								/>
							</div>
						</div>}
			</div>
		);
	}

	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Create Clinical Pathway", utils.getLanguage())} /> </div>); };

	render() {
		if (this.props.extClinicalPathway.isExtClinicalPathwayCreateComplete === "FULFILLED") {
			this.state.data = null;
			if (!this.state.isSaveNext) {this.state.responsible = [];}
			this.props.dispatch(extClinicalPathwayActions.clearCreateExtClinicalPathway());
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch({ type: events.TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
			if (!this.state.isSaveNext) {
				this.props.cancelClicked();
			}
			this.props.getStepItemCount(this.state.selectedClpForCreate.stepId);
			this.props.refreshExtClinicalPathwayList(this.state.selectedClpForCreate.day, this.state.selectedClpForCreate.id, this.state.isSaveNext);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.extClinicalPathway.isItemCreateComplete === "FULFILLED") {
			this.setState({ item: null, level: null });
			this.props.dispatch(extClinicalPathwayActions.clearCreateItem());
			this.props.dispatch({ type: events.TRIGGER_LIST_UPDATE, payload: {} });
			this.props.dispatch({ type: events.TRIGGER_RESPONSIBLE_LIST_UPDATE, payload: {} });
			if (!this.state.isSaveNext) {
				this.props.cancelClicked();
			}
			this.props.getStepItemCount(this.state.selectedClpForCreate.stepId);
			this.props.refreshExtClinicalPathwayList(this.state.selectedClpForCreate.day, this.state.selectedClpForCreate.id, this.state.isSaveNext);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<Modal
				className="re_assign_modal"
				title={this.title()}
				visible={this.props.visible}
				onCancel={this.props.cancelClicked.bind(this, true)}
				footer={[
					<div key ='1' className='d-flex align-items-center justify-content-end common_cancel_btn_container'>
						<button key="Cancel" type="primary" className="ant-btn float_none_imp height_common_btn margin-right-10" onClick={this.props.cancelClicked.bind(this, true)}>{i18Get("Cancel", utils.getLanguage())}</button>
						<ActionButton type="primary" className="margin-right-10" onClick={this.state.tab === "item" ? this.addClicked.bind(this, false) : this.createItemOnly.bind(this, false)} value={i18Get("Add", utils.getLanguage())} />
						<ActionButton type="primary" className="margin-right-8" onClick={this.state.tab === "item" ? this.addClicked.bind(this, true) : this.createItemOnly.bind(this, true)} value={i18Get("Add & Next", utils.getLanguage())} />
					</div>
				]}
			>
				{this.loadContent()}
			</Modal>
		);
	}
}

ClinicalPathwayCreateModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	extClinicalPathway: store.extClinicalPathway,
	actions: store.actions,
	translations: store.translations
}))(ClinicalPathwayCreateModel));