import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ClinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};
const masterOption = { value: null, label: "MOM", treatmentId: null };

class ClinicalPathwayHospital extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// hospitalData: {},
			hospitalData: [],
			offset: 0,
			numResults: 100,
			search: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.hospitalData = [];
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchHospitalList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderHospitalOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.hospitalData) {
			this.state.hospitalData.map((data) => {
				if (data.treatmentId && data.id == null)
				{
					prevState.options.push(masterOption);
					
				} else {
					prevState.options.push({ value: data.id, label: data.name + (data.name !== "MOM" && this.props.siteName ? ` -> ${this.props.siteName}` : ""), treatmentId: data.treatmentId });}
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchHospitalList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${search ? `&name=${search}` : ""}`;
		this.props.dispatch(ClinicalPathwayDetailsAction.getHospitalForClp(this.props.match.params.id, queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			clearTimeout(this.timer);
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.fetchHospitalList(search);
			}, 800);
		}
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchHospitalList();
	}

	onSetDefaultHospital() {
		this.props.hospitalChanged(masterOption);
	}

	render() {

		if (this.props.clinicalPathwayDetails.getHospitalForClpCompleted === "FULFILLED") {
			this.props.dispatch(ClinicalPathwayDetailsAction.getHospitalForClpCompleted());
			this.state.hospitalData.push({ id: null, name: "MOM", treatmentId: null });
			this.state.hospitalData = unionWith(this.state.hospitalData, this.props.clinicalPathwayDetails.hospitalData.data, isEqual).filter(e => e.name !== null);
			this.renderHospitalOptions();
			this.onSetDefaultHospital();
		}
		return (
			<Select
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.hospitalChanged}
				value={this.props.data}
				isSearchable
				isClearable={!!(this.state.options && this.state.options.length > 1)}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select Hospital", utils.getLanguage())}
			/>
		);
	}
}

ClinicalPathwayHospital.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(ClinicalPathwayHospital));
