import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import { Button, Modal } from "antd";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import ModeList from "../modeList/list";
import DragWindow from "../../../../../../../plug/drag-window";
import "./style.css";

class AttachModels extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedModels: { linkTreatmentIds: [], unlinkTreatmentIds: [] },
			isLoading: false,
			code: null,
			showModel: false,
			metaData: "link Model",
			processStatus: ""
		};
	}

	onApplyChange(data) {
		const prevState = this.state;
		prevState.selectedModels = { linkTreatmentIds: data.linked, unlinkTreatmentIds: data.unlink };
		if (data.linked && data.linked.length > 0) {
			prevState.processTitle = "link";
		} else if (data.linked && data.linked.length == 0 && data.unlink && data.unlink.length > 0) {
			prevState.processTitle = "unlink";
		}

		if (data.linked && data.linked.length == 0 && data.unlink && data.unlink.length == 0) {
			utils.showInfo(i18Get(`Please ${(this.state.processTitle == "unlink" ? "uncheck" : "select")} atleast one model!`, utils.getLanguage()));
			return false;
		}
		this.setState(prevState, () => {
			this.toggleModal();
			this.toContinue();
		});
	}

	toContinue = () => {
		if (this.state.isLoading) { return false; }

		this.setState({ isLoading: true });
		const payload = {
			linkTreatmentIds: this.state.selectedModels.linkTreatmentIds,
			unlinkTreatmentIds: this.state.selectedModels.unlinkTreatmentIds
		};

		this.props.dispatch(clinicalPathwayAction.attachSelectedModelsWithClinicalPathWay(this.props.match.params.id, payload));

	};

	getModelStatus = () => {
		if (this.state.code) {
			this.timer = setTimeout(() =>
				this.props.dispatch(clinicalPathwayAction.getApplyModelObserver(this.state.code, this.state.metaData)), 3000);

		}
	};

	isRefreshed = (status) => {
		switch (status) {
			case "PROCESSED":
				return true;
			case "ERRORED":
				return true;
			default: return false;
		}
	};

	footer = () => {
		return (<div><Button ><I18 tkey="Cancel" onClick={this.toggleModal}></I18></Button><Button type="primary" onClick={this.toContinue} loading={this.state.isLoading} ><span className="margin-left-10" >
			{
				this.state.processTitle === "unlink" ?
					<I18 tkey={!this.state.isLoading ? "Unlink now" : "Unlinking"} /> :
					<I18 tkey={!this.state.isLoading ? "Link now" : "Linking"} />

			}
		</span></Button></div>);
	};

	toggleModal = () => {
		const caseIs = !this.state.showModel;
		this.setState({ showModel: caseIs, viewList: false });
	};

	render() {

		if (this.props.clinicalPathway.attachModelWithclinicalPathwayStatus === "FULFILLED") {
			if (this.props.clinicalPathway.attachedModel && this.props.clinicalPathway.attachedModel.code) {
				this.setState({ code: this.props.clinicalPathway.attachedModel.code }, () => { this.getModelStatus(); });
			}
			this.props.dispatch(clinicalPathwayAction.clearAttachSelectedModelsWithClinicalPathWay());
		}

		if (this.props.clinicalPathway.attachModelWithclinicalPathwayStatus === "REJECTED") {
			this.setState({ isLoading: false });
			this.props.dispatch(clinicalPathwayAction.clearAttachSelectedModelsWithClinicalPathWay());
		}

		if (this.props.clinicalPathway.modelChangesApplyStatus == "FULFILLED" && this.props.clinicalPathway.meta == this.state.metaData) {
			this.props.dispatch(clinicalPathwayAction.clearGetApplyModelObserver());
			if (this.props.clinicalPathway.modelStatus && this.props.clinicalPathway.modelStatus.status && this.isRefreshed(this.props.clinicalPathway.modelStatus.status)) {
				if (this.props.clinicalPathway.modelStatus.status === "PROCESSED") {
					utils.showSuccess(i18Get("Model Changes applied successfully", utils.getLanguage()));
					this.setState({ isLoading: false, showModel: false,  viewList: false  });
					this.props.reloadDetails();
				}
				if (this.props.clinicalPathway.modelStatus.status === "ERRORED") {
					utils.showError(i18Get("Failed to apply model changes", utils.getLanguage()));
					this.setState({ isLoading: false, showModel: false,  viewList: false  });
				}
				this.setState({ selectedModels: [], showModel: false, viewList: false });
			} else {
				this.getModelStatus();
			}
		}

		return (
			<div className='attach_model_wrapper'>
				
				{this.props.viewList ?
					<ModeList
						visible={this.props.viewList}
						value={this.state.selectedModels}
						onCancel={this.props.toggleViewListModal}
						category='model'
						onApplyChange={this.onApplyChange.bind(this)}
						clinicalPathwayData={this.props.clinicalPathwayData}
					/> : ""
				}
				{this.state.showModel && <Modal
					visible={this.state.showModel}
					maskClosable={false}
					className="re_assign_modal zIndex9X5"
					title={<div className="position_relative"><DragWindow title={i18Get(`${(this.state.processTitle === "unlink" ? "Unlink" : "Link")} Model`, utils.getLanguage())} /> </div>}
					onCancel={this.toggleModal}
					footer={this.footer()}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					wrapClassName="drag_modal"
					onOk={this.toContinue}>
					<div className="alert margin-top-15 alert-info">
						<p> <I18 tkey={`${(this.state.processTitle === "unlink" ? "Unlinking" : "Linking")} the data of model will take some time. Do not close this tab until the process is completed.`} /></p>
					</div>
				</Modal> }
			</div>
		);
	}
}

AttachModels.propTypes = {
	dispatch: PropTypes.func.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	clinicalPathway: store.clinicalPathway,
	actions: store.actions
}))(AttachModels));
