import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../router/locations";
import "./main-overview.css";
import Elements from "./elements";
import ExtElements from "./ext-elements";
import Administration from "./administration";
import Intervention from "./intervention";
import Anaesthesia from "./anaesthesia";
import CreateIntervention from "./create-intervention";
import ClinicalPathWay from "./clinical-pathway";
import HealthPathWay from "./health-pathway";
import SubClinicalPathWay from "./sub-clinical-pathway";
import SubHealthPathWay from "./sub-health-pathway";
import isPermissionGranted from "../../../../utils/permission";
import LoadLinkData from "./loadLink";
const HP_CURRENT_PAGE = "HP_CURRENT_PAGE";
class MainOverview extends Component {
	constructor(props) {
		super(props);
	}
	componentDidUpdate() {
		//HELATH PATHWAY CURRENT PAGE RESET TO DEFAULT
		if (!this.props.match.url.includes("health_pathway")) {
			localStorage.setItem(HP_CURRENT_PAGE, 1);
		}
	
	}
	render() {
		if (this.props.user.loginData && this.props.user.loginData.scope == 64 && this.props.user.loginData.roleType === "PATHWAY_USER") {
			return (
				<div id="scroll_position" className={this.props.actions.sideBarHideShow ? "main_container_parent width_100per_imp scrollbar" : "main_container_parent overflow_hide scrollbar"}>
					<Switch>
						<Route path={locations.administration} component={Administration} />
						<Route path={locations.sub_health_pathways} component={SubHealthPathWay} />
						<Route path={locations.sub_clinical_pathways} component={SubClinicalPathWay} />
						<Route path={locations.health_pathway} component={HealthPathWay} />
						{isPermissionGranted(false, "READ") && <Route path={locations.clinical_pathway} component={ClinicalPathWay}  />}
						<Redirect to={locations.base} />
					</Switch>
				</div>
			);
		}

		return (
			<div id="scroll_position" className={this.props.actions.sideBarHideShow ? "main_container_parent width_100per_imp scrollbar" : "main_container_parent overflow_hide scrollbar"}>
				<Switch>
					<Route path={locations.loadLink} component={LoadLinkData} />  
					<Route exact path={locations.interventions + locations.create} component={CreateIntervention} />
					<Route path={locations.administration} component={Administration} />
					<Route path={locations.sub_health_pathways} component={SubHealthPathWay} />
					<Route path={locations.sub_clinical_pathways} component={SubClinicalPathWay} />
					<Route path={locations.elements} component={Elements} />
					<Route path={locations.extElements} component={ExtElements} />
					<Route path={locations.interventions} component={Intervention} />
					<Route exact path={locations.anaesthesia_mom_create} component={Anaesthesia} />
					<Route exact path={locations.anaesthesia_local_create} component={Anaesthesia} />
					<Route path={locations.anaesthesia_edit} component={Anaesthesia} />
					<Route path={locations.health_pathway} component={HealthPathWay} />
					<Route path={locations.anaesthesia} component={Anaesthesia} />
					<Route path={locations.clinical_pathway} component={ClinicalPathWay} />
					<Redirect to={locations.base} />
				</Switch>
			</div>
		);
	}
}

MainOverview.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	actions: store.actions,
	translations: store.translations,
	user: store.user
}))(MainOverview));
