import React, { Component } from "react";
import Select from "react-select";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

const customStyles = {
	control: (provided) => ({
		...provided,
		height: "32px",
		minHeight: "32px"
	})
};

export default class TypeDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isListLoading: false,
			options: [{ value: "PHARMACY", label: i18Get("PHARMACY", utils.getLanguage()) },
				{ value: "MEDICAL_DEVICE", label: i18Get("MEDICAL DEVICE", utils.getLanguage()) },
				{ value: "PROSTHESIS", label: i18Get("PROSTHESIS", utils.getLanguage()) }
			]
		};
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.fetchData();
	}

	render() {
		return (

			<Select
				styles={customStyles}
				className="w_100 di"
				defaultActiveFirstOption={false}
				onChange={this.props.typeChange}
				value={this.props.data}
				isSearchable
				isClearable
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => i18Get("No Data Found", utils.getLanguage())}
				loadingMessage={() => i18Get("Loading...", utils.getLanguage())}
				placeholder={i18Get("Select", utils.getLanguage())}
			/>
		);
	}
}
