import React, { Component } from "react";
import { DebounceInput } from "react-debounce-input";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import SearchFieldDropdown from "./search-field-dropdown";
import ProblemTypeDropDown from "../problem-type-dropdown";
import SearchField from "./search-field";
import ProblemIntervenantsDropDown  from "../problem-intervenants-dropdown";
import CategoryPersonnelDropdown from "../../../../administration/personnels/category-personnels/categoryPersonnelDropdown";

export default class ProblemSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}
	
	render() {
		return (
			<div className="d-flex w_100 justify-content-end">
				{!this.props.clinicalPathwayData.isCheckList &&
					<div className="padding_left_prblm min_width_problem_react_select pathway_li_clpProblem_tab">
						<ProblemTypeDropDown
							data={this.props.type ? this.props.type : null}
							onChange={this.props.typeChanged}
							stepSectionType={this.props.clpStepFilter}
						/>
					</div>
				}
				<div className="padding_left_prblm min_width_problem_react_select pathway_li_clpProblem_tab">
					<CategoryPersonnelDropdown
						data={this.props.intervenants && Object.keys(this.props.intervenants).length  ? this.props.intervenants : null}
						categoryPersonnelChanged={this.props.intervenantsChanged}
						clinicalPathway={false}
						filterData={this.state.filterData}
						autoSetSingleOne={true}
					/>
					{/* <ProblemIntervenantsDropDown
						data={this.props.intervenants ? this.props.intervenants : null}
						onChange={this.props.intervenantsChanged}
						stepSectionType={this.props.clpStepFilter}
					/> */}
				</div>
				<div className="padding_left_prblm pathway_li_clpProblem_tab select_prbl_section_container margin-top-3">
					<SearchFieldDropdown
						field={this.props.field}
						searchFieldChanged={this.props.searchFieldChanged}
					/>
				</div>
				<div className="padding_left_prblm pathway_li_clpProblem_tab">
					{this.props.field == "SEARCH_ALL_FIELDS" || this.props.field == utils.sameDropper()
						?
						<SearchField
							className="margin-top-4 ant_input_search_field"
							maxLength={750}
							placeholder={i18Get("Search...", utils.getLanguage())}
							value={this.props.search ? this.props.search : ""}
							searchChanged={this.props.searchChanged}
						/>
						:
						<DebounceInput
							className="filter_input_design form-control prblm_search"
							debounceTimeout={750}
							maxLength={750}
							placeholder={i18Get("Search...", utils.getLanguage())}
							value={this.props.search ? this.props.search : ""}
							onChange={this.props.searchChanged}
						/>
					}
				</div>
			</div>
		);
	}
}
