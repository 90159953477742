import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import communitySitesAction from "../../../../../../../../actions/community-sites";
import NameInput from "../../../../../../../../plug/regex-name-search-input";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button } from "antd";
import HospitalList from "../hospitalList";
import Community from "../selectCommunitySection";
import { ExclamationCircleOutlined } from "@ant-design/icons";

class EditCommunitySites extends Component {
	constructor(props) {
		super(props);
		this.state = {
			communitySites: {},
			selectedHopsital: {},
			loading: false,
			invalidHospital: false,
			invalidCode: false,
			invalidName: false,
			invalidCommunity: false,
			invalidShortName: false,
			exstCommunityList: [],
			continueFlag: false

		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(communitySitesAction.getOne(this.props.communitySitesData.id, this.props.communitySitesData.hospitalId));
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.communitySites.name = value;
		prevState.invalidName = false;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.checkCodeRegex(text)) {
			prevState.communitySites.code = text;
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}
	onHospitalChanged(e) {
		const prevState = this.state;
		prevState.communitySites.selectedHopsital = e ? e : "";
		prevState.invalidHospital = false;
		this.setState(prevState);

	}

	onCommunityChanged(e) {
		const prevState = this.state;
		prevState.continueFlag = false;
		const options = [];
		if (e) {
			e.map((data) => {
				options.push(data.value);
			});
		}
		prevState.selectCommunity = options;
		this.setState(prevState);
	}
	shortNameChanged(value) {
		const prevState = this.state;
		if (value.length <= 10)
		{
			prevState.communitySites.shortName = value;
			this.setState(prevState);
		} 
		prevState.invalidShortName = false;
	}

	confirmation = () => {
		const options = [];
		if (this.state.communitySites.communityList) {
			this.state.communitySites.communityList.map((data) => {
				options.push(data.value);
			});
		}

		const difference = options.filter(x => this.state.selectCommunity.includes(x));

		if (options.length > 0 && options.length != difference.length && !this.state.continueFlag)
		{
			const { confirm } = Modal;
			confirm({
				title: i18Get("Are you sure you want to continue?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "You have removed one or more community from the Hospital Site. Assigned User Permission will be revoked from that Site community if you Update.",
				okText: i18Get("Update", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					this.saveClicked();
				},
				onCancel: () => {
				}
			});
		} else {
			this.saveClicked();
		}
	};

      
	validate() {
		const prevState = this.state;
		if (!prevState.communitySites.name || !prevState.communitySites.name.trim() || !utils.checkNameRegex(prevState.communitySites.name)) {
			prevState.invalidName = true;
		}
		if (!prevState.communitySites.code || !prevState.communitySites.code.trim()) {
			prevState.invalidCode = true;
		}

		if (!prevState.communitySites.shortName || !prevState.communitySites.shortName.trim()) {
			prevState.invalidShortName = true;
		}

		if (!prevState.communitySites.selectedHopsital || prevState.communitySites.selectedHopsital.id > 0) {
			prevState.invalidHospital = true;
		}
		if (prevState.invalidName || prevState.invalidCode || prevState.invalidHospital || prevState.invalidShortName) {
			this.setState(prevState);
			return false;
		}

		return true;
	}

	saveClicked() {
		this.setState({ continueFlag: true });

		if (this.validate()) {
			this.setState({ loading: true });
			const payload = {
				name: this.state.communitySites.name,
				code: this.state.communitySites.code,
				hospitalId: this.state.communitySites.selectedHopsital && this.state.communitySites.selectedHopsital.value ? this.state.communitySites.selectedHopsital.value : "",
				communityIds: this.state.selectCommunity,
				shortName: this.state.communitySites.shortName
			};
			this.props.dispatch(communitySitesAction.updateCommunitySites(this.state.communitySites.id, payload));
		}
	}


	handleCancel() {
		this.props.visibleEditModal(false);
	}

	render() {
		if (this.props.communitySites.fetchOneCommunitySitesComplete === "FULFILLED") {
			this.state.communitySites = this.props.communitySites.communitySites;
			this.state.communitySites["selectedHopsital"] = { label: this.state.communitySites.HospitalName, value: this.state.communitySites.hospitalId };
			this.onCommunityChanged(this.state.communitySites.communityList);
			this.props.dispatch(communitySitesAction.clearGetOne());
		}
		if (this.props.communitySites.fetchOneCommunitySitesComplete === "REJECTED") {
			this.props.dispatch(communitySitesAction.clearGetOne());
		}
		if (this.props.communitySites.updateCommunitySitesComplete === "FULFILLED") {
			this.props.dispatch(communitySitesAction.updateCommunitySitesCompleted());
			this.handleCancel();
			this.props.createClassCompleted(true);
			this.setState({ loading: false });
		}
		if (this.props.communitySites.updateCommunitySitesComplete === "REJECTED") {
			this.props.dispatch(communitySitesAction.updateCommunitySitesCompleted());
			this.setState({ loading: false });
		}

		return (
			<div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Edit Site", utils.getLanguage())}
					visible={this.props.editModal}
					onCancel={this.handleCancel.bind(this)}
					destroyOnClose={true}
					footer={[
						// <Button key="submit" type="primary" loading={this.state.loading} onClick={this.saveClicked.bind(this)}>
						<Button key="submit" type="primary" loading={this.state.loading} onClick={this.confirmation.bind(this)}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.handleCancel.bind(this)}>
							<I18 tkey="Cancel" />
						</Button>
					]}>
					<div className="pole_create_box create_category_personal_width_100 padding-0">
						<div className="row">
							<div>
								<div className="col-md-6 col-sm-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><I18 tkey="Code" /></label>
										<NameInput
											value={this.state.communitySites.code}
											inputChanged={this.codeChanged.bind(this)}
											maxLength={2}
											disabled={true}
										/>
										{this.state.invalidCode && <span className='invalid_right fixed_label'><I18 tkey="Enter valid code" /></span>}
									</div>
								</div>

								<div className="col-md-6 col-sm-6">
									<div className="w_100 di margin-bottom-18 position_relative">
										<label><I18 tkey="Short Name" /></label>
										<NameInput
											value={this.state.communitySites.shortName}
											inputChanged={this.shortNameChanged.bind(this)}
											maxLength={10}
										/>
										{this.state.invalidShortName && <span className='invalid_right fixed_label'><I18 tkey="(a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
									</div>
								</div>
							</div>
							<div className="col-md-12 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Site" /></label>
									<NameInput
										value={this.state.communitySites.name}
										inputChanged={this.nameChanged.bind(this)}
										maxLength={60}
									/>
									{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								</div>
							</div>
  
							<div className="col-md-12 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Hospital" /></label>
									<HospitalList
										data={this.state.communitySites.selectedHopsital}
										onChange={this.onHospitalChanged.bind(this)}
									/>
									{this.state.invalidHospital && <span className='invalid_right fixed_label'><I18 tkey="Select hospital" /></span>}
								</div>
							</div>
							<div className="col-md-12 col-sm-12">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="Community of Practice"/></label>
									<Community
										data={this.state.selectCommunity}
										communityChanged={this.onCommunityChanged.bind(this)}
									/>
									{this.props.invalidHospital && <span className='invalid_right fixed_label'><I18 tkey="Select community of practice" /></span>}
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</div>
		);
	}
}

EditCommunitySites.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	communitySites: store.communitySites
}))(EditCommunitySites)); 