import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import TemplateDropdown from "../../administration/personnels/admin-personnels/personnel-templates-dropdown";
import I18, { i18Get } from "../../../../../../i18";
import staffElementsAction from "../../../../../../actions/staff-elements";
import ActionButton from "../../../../../../plug/action-button";
import utils from "../../../../../../utils/utils";
import events from "../../../../../../actions/events";

class SwitchTemplate extends Component {
	constructor(props) {
		super(props);
		this.state = {
			edit: false,
			template: null,
			invalidTemplate: false,
			disableClick: false
		};
	}

	toggleEdit() {
		this.setState({ edit: !this.state.edit });
	}

	templateChanged(e) {
		this.setState({ template: e, invalidTemplate: false });
	}

	validate() {
		const prevState = this.state;
		if (!this.state.template || !this.state.template.value) {
			prevState.invalidTemplate = true;
		}
		if (prevState.invalidTemplate) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	switchTemplate() {
		if (this.validate()) {
			this.setState({ disableClick: true });
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				categoryPersonnelTemplateId: this.state.template.value
			};
			this.props.dispatch(staffElementsAction.switchTemplate(this.props.match.params.id, payload));
		}
	}

	render() {
		if (this.props.staffElements.switchTemplateCompleted === "FULFILLED") {
			this.state.disableClick = false;
			this.state.edit = false;
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.onFetchData();
			this.props.dispatch(staffElementsAction.clearSwitchTemplate());
		}
		if (this.props.staffElements.switchTemplateCompleted === "REJECTED") {
			this.state.disableClick = false;
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.props.dispatch(staffElementsAction.clearSwitchTemplate());
		}
		if (this.props.staffElements.templateInfo && Object.keys(this.props.staffElements.templateInfo).length) {
			if (this.props.staffElements.templateInfo.label && this.props.staffElements.templateInfo.value) {
				this.state.template = this.props.staffElements.templateInfo;
			}
			this.props.dispatch(staffElementsAction.transferTemplateInfo(null));
		}

		return (
			<div className="switch_templater_container">
				<div className="row">
					<label className="switch_templater_label"><I18 tkey="Team" /></label>
					<div className="col-md-3 ">
						{this.state.edit
							? (
								<div>
									<TemplateDropdown
										minimal
										disabled={this.state.disableClick}
										data={this.state.template}
										typeActivity={this.props.typeActivity}
										templateChanged={this.templateChanged.bind(this)}
									/>
									{this.state.invalidTemplate ? <span className="invalid_switch"><I18 tkey="Select valid template" /></span> : ""}
								</div>
							)
							:							<div className="margin-top-6">{this.state.template ? this.state.template.label : ""}</div>}
					</div>
					<div className="col-md-9">
						{this.state.edit
							? (
								<div>
									<ActionButton
										onClick={this.switchTemplate.bind(this)}
										value={i18Get("SAVE", utils.getLanguage())}
									/>
									<button
										className="margin-left-10 switch_template_cancel_btn edit_delete_button cancel_button margin-right-15"
										disabled={this.state.disableClick}
										onClick={this.toggleEdit.bind(this)}
									>
										<I18 tkey="CANCEL" />
									</button>
								</div>
							)
							:							(
								<button
									className="edit_delete_button cancel_button margin-right-15"
									disabled={this.state.disableClick}
									onClick={this.toggleEdit.bind(this)}
								>
									<I18 tkey="EDIT" />
								</button>
							)}
					</div>
				</div>
			</div>
		);
	}
}

SwitchTemplate.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	staffElements: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	staffElements: store.staffElements,
	actions: store.actions
}))(SwitchTemplate));
