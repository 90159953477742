import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllFamily = (queryString) => ({
	type: events.FETCH_ALL_FAMILY,
	payload: axios.get(`${Config.apiRootSecure}/family?${queryString}`)
});

const clearFetchAllFamily = () => ({
	type: events.CLEAR_FETCH_ALL_FAMILY,
	payload: {}
});

const fetchOneFamily = (id) => ({
	type: events.FETCH_ONE_FAMILY,
	payload: axios.get(`${Config.apiRootSecure}/family/${id}`)
});

const clearFetchOneFamily = () => ({
	type: events.CLEAR_FETCH_ONE_FAMILY,
	payload: {}
});

const createFamily = (payload) => ({
	type: events.CREATE_FAMILY,
	payload: axios.post(`${Config.apiRootSecure}/family`, { ...payload })
});

const clearCreateFamily = () => ({
	type: events.CLEAR_CREATE_FAMILY,
	payload: {}
});

const updateFamily = (id, payload) => ({
	type: events.UPDATE_FAMILY,
	payload: axios.put(`${Config.apiRootSecure}/family/${id}`, { ...payload })
});

const clearUpdateFamily = () => ({
	type: events.CLEAR_UPDATE_FAMILY,
	payload: {}
});

const deleteFamily = (id) => ({
	type: events.DELETE_FAMILY,
	payload: axios.delete(`${Config.apiRootSecure}/family/${id}`)
});

const clearDeleteFamily = () => ({
	type: events.CLEAR_DELETE_FAMILY,
	payload: {}
});

const fetchAllFamilyForSpeciality = (queryString) => ({
	type: events.FETCH_ALL_FAMILY_FOR_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/familySpeciality?${queryString}`)
});

const clearFetchAllFamilyForSpeciality = () => ({
	type: events.CLEAR_FETCH_ALL_FAMILY_FOR_SPECIALITY,
	payload: {}
});

export default {
	fetchAllFamily,
	clearFetchAllFamily,
	fetchOneFamily,
	clearFetchOneFamily,
	createFamily,
	clearCreateFamily,
	updateFamily,
	clearUpdateFamily,
	deleteFamily,
	clearDeleteFamily,
	fetchAllFamilyForSpeciality,
	clearFetchAllFamilyForSpeciality
};
