import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import utils from "../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../i18";
import actions from "../../../../../../../actions/sterilization";
import Loader from "../../../../../../../plug/loader";
import ReactTable from "../../../../../../../plug/reactTable";
import { Modal, Radio } from "antd";
import "../../../../../../../plug/reactTable/react-table.css";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import { typeActivity, codeTypes, statusList } from "../../../../../../../constants";
import SpecialitySelect from "./selectSpecialities";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export class List extends Component {
	constructor(props) {
		super(props);
		this.state = {
			specialty: [],
			totalPages: 1,
			loading: false,
			search: "",
			status: "",
			hospitalId: "",
			deleteSpecialtyVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedSpecialty: {},
			sort: "",
			desc: 0,
			modelVisible: false,
			typeActivity: utils.sameDropper(),
			value: 1,
			selectedSpeciality: "",
			specialtyName: "",
			specialtyCode: "",
			invalidName: false,
			invalidCode: false,
			invalidSpeciality: false,
			specialtyId: "",
			specialtyDetails: {},
			specialtyFound: false
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.specialityList });
		this.state.typeActivity = this.props.typeActivity;
	}

	getCols() {
		const tableArray = ([
			{
				Header: i18Get("ACTIONS", utils.getLanguage()),
				id: "actions",
				accessor: d => d,
				width: 80,
				resizable: false,
				sortable: false,
				show: (this.state.typeActivity !== typeActivity.ACCOMMODATION.toUpperCase()),
				Cell: row => (
					<div className="margin_left_auto">
						<div className="min_width_12_px">
							{row.value.status === statusList.STATUS.values.APPROVED ?
								<EditOutlined className="edit_color margin-right-10" type="edit" onClick={this.editClicked(row.value).bind(this)} />
								: <a className="waiting-for-approve" onClick={this.approveClicked(row.value).bind(this)}><img src="/images/waitingforapprovel.png" /> </a>
							}
						</div>
						<div className="min_width_12_px">
							{row.value.code !== codeTypes.TYPES.SPECIALITY_CODE.SAN && row.value.status === statusList.STATUS.values.APPROVED ?
								<DeleteOutlined className="delete_color" type="delete" onClick={this.deleteClicked(row.value).bind(this)} />
								: ""}
						</div>
					</div>
				)
			},
			{
				Header: i18Get("CODE", utils.getLanguage()),
				id: "code",
				accessor: d => d.code,
				maxWidth: 80,
				resizable: false,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("NAME", utils.getLanguage()),
				id: "name",
				accessor: d => d.name,
				resizable: true,
				Cell: row => (
					this.getText(row.value)
				)
			},
			{
				Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
				id: "hospitalName",
				accessor: d => d.hospitalName,
				resizable: true,
				Cell: row => (
					row.value ? this.getText(row.value) : "Master"
				)
			},

			{
				Header: i18Get("STATUS", utils.getLanguage()),
				id: "status",
				accessor: d => d.status,
				resizable: true,
				width: 150,
				Cell: row => (
					this.getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
				)
			}]);
		return tableArray;
	}

	getText(value) {
		return <div>{value}</div>;
	}

	editClicked(specialty) {
		return () => {
			this.props.history.push(locations.sterilization_speciality + "/" + specialty.id);
		};
	}

	radioButtonChange = e => {
		this.setState({
			value: e.target.value
		});
	};

	deleteClicked(specialty) {
		return () => {
			this.setState({ deletedSpecialty: specialty, deleteSpecialtyVisible: true });
		};
	}

	hideModalDelete = () => {
		this.setState({ deleteSpecialtyVisible: false });
	};

	cancelClicked = () => {
		this.setState({ modelVisible: false, invalidName: false, invalidCode: false, invalidSpeciality: false, specialtyFound: false, value: 1, selectedSpeciality: "" });
	};

	deleteSpecialty() {
		this.props.dispatch(actions.deleteConsomableSpecialty(this.state.deletedSpecialty.id));
		this.setState({ deleteSpecialtyVisible: false, deletedSpecialty: {} });
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState, () => { this.props.getSortAndDesc(this.state.sort, this.state.desc); });
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.state.page ? `?offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
		queryString += this.state.pageSize ? `numResults=${this.state.pageSize}&` : "";
		queryString += this.state.sort ? `sort=${this.state.sort}&desc=${this.state.desc}` : "";
		queryString += "&consumableType=STERILIZATION";
		queryString += this.state.search ? `&code=${encodeURIComponent(this.state.search)}&name=${encodeURIComponent(this.state.search)}&` : "";
		queryString += this.state.status ? `&status=${this.state.status}` : "";
		queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
		this.props.dispatch(actions.getConsomableSpeciality(queryString));
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList() {
		return (<ReactTable
			{...utils.getPropsForReactTable(this.state.specialty, (this.state.page - 1), this.state.totalPages,
				this.state.loading, () => { return (<Loader loading={this.state.loading} isTable={true} />); }, false,
				this.state.pageSize, true, this.onFetchData.bind(this), this.getCols(), true, false,
				this.onPageChange.bind(this), "SPECIALITY", "", this.onPageSizeChange.bind(this))}
		/>);
	}

	onSpecialityChanged = (e) => {
		this.setState({ selectedSpeciality: e, invalidSpeciality: e && e.value ? false : true });
	};

	approveClicked(specialty) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, specialityId: specialty.id });
			this.props.dispatch(actions.fetchOneConsomableSpeciality(specialty.id));
		};
	}

	statusCheck(status) {
		if (status === "DATA_FOUND") {
			this.state.specialtyFound = true;
			this.state.value = 2;
		}
	}

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.specialtyName) || !(this.state.specialtyName.trim())) {
			prevState.invalidName = true;
		}
		if (!utils.regexSpecialityCode.test(this.state.specialtyCode)) {
			prevState.invalidCode = true;
		}
		if (prevState.invalidName || prevState.invalidCode) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	validateSpeciality = () => {
		if (this.state.selectedSpeciality && this.state.specialtyFound) {
			return true;
		}
		return false;
	};

	saveClicked = () => {
		let payload = {};
		if (this.state.value === 1) {
			if (this.validateFields()) {
				payload = {
					name: this.state.specialtyName,
					code: this.state.specialtyCode
				};
				this.props.dispatch(actions.saveAndApproveConsumableSpeciality(this.state.specialtyDetails.id, payload));
			}
		}
		if (this.state.value === 2) {
			if (this.validateSpeciality()) {
				payload = {
					consumableSpecialityId: this.state.selectedSpeciality.value
				};
				this.props.dispatch(actions.assignAndApproveConsumableSpeciality(this.state.specialtyDetails.id, payload));
			}
			else {
				this.setState({ invalidSpeciality: true });
			}

		}

	};

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.specialtyName = value;
		this.setState(prevState);
	}

	codeChanged(value) {
		const text = value.toUpperCase();
		const prevState = this.state;
		if (utils.capitalizeValidation.test(text)) {
			prevState.specialtyCode = text;
		} else {
			prevState.specialtyCode = value;
		}
		prevState.invalidCode = false;
		this.setState(prevState);
	}

	render() {
		const { value } = this.state;
		return (
			<div>
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Sterilization Speciality", utils.getLanguage())}
					visible={this.state.deleteSpecialtyVisible}
					onCancel={this.hideModalDelete}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteSpecialty.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedSpecialty.name}</span> ?
					</div>
				</Modal>
				{this.state.modelVisible ?
					<Modal
						className="re_assign_modal"
						title={i18Get("Edit & Approve - Sterilization Speciality", utils.getLanguage())}
						visible={this.state.modelVisible}
						onCancel={this.cancelClicked}
						okText={i18Get("Save & Approve", utils.getLanguage())}
						cancelText={i18Get("Cancel", utils.getLanguage())}
						onOk={this.saveClicked}
					>
						<Radio.Group onChange={this.radioButtonChange} value={value}>
							<div className="re_assign_modal_head">
								<div className="row position_relative">
									<div className="col-md-3">
										<div className="form-group pl-15">
											<label className="bold width-100"><I18 tkey="CODE" />:</label>
											<label className="width-100">{this.state.specialtyDetails.code}</label>
										</div>
									</div>
									<div className="col-md-9">
										<label className="bold width-100"><I18 tkey="NAME" />:</label>
										<label className="width-100">{this.state.specialtyDetails.name}</label>
									</div>

									<p className="hospital_name"><I18 tkey="Hospital" />: {this.state.specialtyDetails.hospitalName}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12 pt-10">
									<Radio value={1}>
										<I18 tkey="Use new code or name" />
									</Radio>
								</div>
								<div className="col-md-3 pt-10 pr-0">
									<div className="form-group pl-20">
										<label className="bold" ><I18 tkey="CODE" />:</label>
										<NameInput
											value={this.state.specialtyCode}
											inputChanged={this.codeChanged.bind(this)}
											disabled={this.state.value != 1}
											maxLength={3}
										/>
										{this.state.invalidCode && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter valid speciality code" /></span>}
									</div>
								</div>
								<div className="col-md-9 pt-10">
									<div className="form-group ">
										<label className="bold" ><I18 tkey="NAME" />:</label>
										<NameInput
											value={this.state.specialtyName}
											inputChanged={this.nameChanged.bind(this)}
											maxLength={240}
											disabled={this.state.value != 1}
										/>
										{this.state.invalidName && <span className='invalid_right_popup fixed_label'><I18 tkey="Enter speciality name" /></span>}
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-12 pt-10">
									<Radio value={2}> <I18 tkey="Assign to Sterilization Speciality" /></Radio>
								</div>
								<div className="col-sm-12 pt-10">
									<div className="form-group pl-20">
										<SpecialitySelect defaultValue="Select Sterilization Speciality" disabled={this.state.value != 2} style={{ width: "100%" }}
											data={this.state.selectedSpeciality ? this.state.selectedSpeciality : "" }
											specialityId={this.state.specialityId}
											typeActivity={this.state.typeActivity}
											assignAndApprove={true}
											statusCheck={this.statusCheck.bind(this)}
											specialityChanged={this.onSpecialityChanged} />

										{this.state.invalidSpeciality && <span className='invalid_right_popup fixed_label'><I18 tkey="Please select sterilization speciality" /></span>}
									</div>
								</div>
							</div>
						</Radio.Group>
					</Modal> : ""}

			</div>
		);
	}

	componentDidUpdate() {

		if (this.state.typeActivity !== this.props.typeActivity) {
			this.setState({ typeActivity: this.props.typeActivity }, () => {
				this.fetchDataFromServer();
			});

		}
		if (this.state.search !== this.props.filter) {
			this.setState({ search: this.props.filter, page: 1, loading: true }, () => {
				this.fetchDataFromServer();
			});
		}
		if (this.state.status !== this.props.status) {
			this.setState({ status: this.props.status, page: 1, loading: true }, () => {
				this.fetchDataFromServer();
			});
		}
		if (this.state.hospitalId !== this.props.hospitalId) {
			this.setState({ hospitalId: this.props.hospitalId, page: 1, loading: true }, () => {
				this.fetchDataFromServer();
			});
		}

		if (this.props.sterilization.getConsomableSpecialityStatus === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = parseInt(Math.ceil(this.props.sterilization.consomableSpeciality.count / this.state.pageSize));
			this.state.specialty = this.props.sterilization.consomableSpeciality.data;
			this.setState({ ...this.state }, () => {
				this.props.dispatch(actions.clearConsomableSpeciality());
				this.props.setCount(this.props.sterilization.consomableSpeciality.count);
			});

		}
		if (this.props.sterilization.isDeleteSpecialtyCompleted === "FULFILLED") {
			this.state.loading = true;
			this.setState({ loading: true }, () => {
				this.fetchDataFromServer();
				this.props.dispatch(actions.deleteConsomableSpecialtyCompleted());
			});

		}
		if (this.props.sterilization.asssignAndApproveSterilizationSpecialityStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, specialtyName: "", specialtyCode: "", specialtyFound: false, value: 1, selectedSpeciality: "" });
			this.fetchDataFromServer();
			this.props.dispatch(actions.assignAndApproveConsumableSpecialityCompleted());
		}
		if (this.props.sterilization.saveAndApproveSterilizationSpecialityStatus === "FULFILLED") {
			this.state.loading = true;
			this.setState({ modelVisible: false, specialtyName: "", specialtyCode: "", specialtyFound: false, value: 1, selectedSpeciality: "" });
			this.fetchDataFromServer();
			this.props.dispatch(actions.saveAndApproveConsumableSpecialityCompleted());
		}
		if (this.props.sterilization.getConsomableSpecialityOneStatus === "FULFILLED") {
			this.state.specialtyDetails = this.props.sterilization.selectedConsomableSpeciality;
			this.state.specialtyName = this.state.specialtyDetails.name ? this.state.specialtyDetails.name : "";
			this.state.specialtyCode = this.state.specialtyDetails.code ? this.state.specialtyDetails.code : "";
			this.state.selectedSpeciality = { label: this.state.specialtyDetails.name ? this.state.specialtyDetails.name : "", value: this.state.specialtyDetails.id };
			this.props.dispatch(actions.fetchOneConsomableSpecialityCompleted());
		}
		if (this.props.sterilization.getConsomableSpecialityOneStatus === "REJECTED") {
			this.props.dispatch(actions.fetchOneConsomableSpecialityCompleted());
		}
	}
}



List.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	specialtyModel: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	specialty: store.specialty,
	translations: store.translations,
	specialtyModel: store.specialtyModel,
	sterilization: store.sterilization
}))(List));  