import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../actions/admin-clinical-pathway";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";
import SectionTypeDropDown from "../section-type-dropdown";
import { Checkbox } from "antd";

class CreateProblem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: "",
			risk: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
		this.setState({ code: utils.randomAlphaNumeric(5) });
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.createClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.name = value;
		this.setState(prevState);
	}

	selectedSectionType(e) {
		const prevState = this.state;
		prevState.invalidSectionType = false;
		prevState.sectionType = e;
		this.setState(prevState);
	}

	onRiskChange = (e) => {
		const prevState = this.state;
		prevState.risk = e.target.checked;
		this.setState(prevState);
	};

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.name) || !this.state.name || !this.state.name.trim() || (this.state.name.match(/\\$/)) || (this.state.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (!this.state.code) {
			prevState.invalidCode = true;
		}
		if (!this.state.sectionType) {
			prevState.invalidSectionType = true;
		}
		if (!prevState.invalidName && !this.state.invalidCode && !prevState.invalidClinicalPathwayType && !prevState.invalidSectionType) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	createClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				problemType: this.state.name.trim(),
				stepSectionType: this.state.sectionType ? this.state.sectionType : "",
				code: this.state.code.trim(),
				isWithRisk: this.state.risk
			};
			this.props.dispatch(adminClinicalPathwayActions.createClinicalPathwayProblem(payload));
		}
	}

	render() {
		if (this.props.adminClinicalPathway.createClinicalPathwayProblemCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayProblem());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.createClinicalPathwayProblemCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearCreateClinicalPathwayProblem());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Problem Type" /></label>
								<NameInput
									value={this.state.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={56} />
								{this.state.invalidName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid problem type (Special characters not allowed)' /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Type" /></label>
								<SectionTypeDropDown
									className=" "
									sectionType={this.state.sectionType}
									selectedSectionType={this.selectedSectionType.bind(this)}
								/>
								{this.state.invalidSectionType ? <span className='invalid_right fixed_label'><I18 tkey="Select valid type" /></span> : ""}
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di position_relative">
								<Checkbox onChange={this.onRiskChange}>
									<strong className='bold' ><I18 tkey="Enable Risk Option" /><br />
										<label className='margin-left-23'>
											<em className='color-6a6666'><I18 tkey="This enables 'With Risk' or 'Without Risk' while creating a cible" /></em>
										</label>
									</strong>
								</Checkbox>

							</div>
						</div>
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									showAdd
									onClick={this.createClicked.bind(this)}
									value={i18Get("CREATE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

CreateProblem.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(CreateProblem));
