import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";

const { Option } = Select;

class ChartControlTypeDropdown extends Component {
	constructor(props) {
		super(props);
		this.state = {
		};
	}

	options() {
		const newOptions = ["NONE", "AS PRESCRIBED", "IF REQUIRED", "PERMANENTLY"];
		// let statusIndex = this.props.intervention.status ? newOptions.indexOf(this.props.intervention.status) : -1;
		return newOptions.map((option, index) => <Option key={index} value={option.toUpperCase().replace(/ /g, "_")}><I18 tkey={option} /></Option>);
	}

	render() {
		return (
			<Select
				value={this.props.data}
				allowClear={false}
				disabled={this.props.disabled}
				className={`w_100 di ${this.props.createClass ? this.props.createClass : "select_prbl_section"}`}
				onChange={this.props.chartControlTypeChanged}
				placeholder={i18Get("Select", utils.getLanguage())}
				notFoundContent={i18Get("Not Found", utils.getLanguage())}
			>
				{this.options()}
			</Select>
		);
	}
}

ChartControlTypeDropdown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(ChartControlTypeDropdown));
