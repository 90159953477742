import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getPreparation = (type, id, sort, desc, filter) => ({
	type: events.PREPARATION_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}${sort ? `&sort=${sort}` : ""}${desc ? `&desc=${desc}` : ""}${filter ? `&filter=${filter}` : ""}`)

});

const getPositions = (type, id, sort, desc, filter) => ({
	type: events.POSITION_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}${sort ? `&sort=${sort}` : ""}${desc ? `&desc=${desc}` : ""}${filter ? `&filter=${filter}` : ""}`)

});
const getInstallation = (type, id, sort, desc, filter) => ({
	type: events.INSTALLATION_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}${sort ? `&sort=${sort}` : ""}${desc ? `&desc=${desc}` : ""}${filter ? `&filter=${filter}` : ""}`)

});

const getStaff = (type, id, sort, desc) => ({
	type: events.STAFF_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/personnel${sort ? (`&sort=${sort}&desc=${desc || 0}`) : ""}`)

});

const clearGetStaff = () => ({
	type: events.CLEAR_STAFF_GET,
	payload: {}
});

const getMedicalDevices = (type, id, sort, desc, status, hospitalId) => ({
	type: events.MEDICAL_DEVICES_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/element?type=${type}${sort ? `&sort=${sort}` : ""}${desc ? `&desc=${desc}` : ""}${status ? `&status=${status}` : ""}${hospitalId ? `&hospitalId=${hospitalId}` : ""}`)

});

const getPharmacy = (type, id, sort, desc, status, hospitalId) => ({
	type: events.PHARMACY_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/element?type=${type}${sort ? `&sort=${sort}` : ""}${desc ? `&desc=${desc}` : ""}${status ? `&status=${status}` : ""}${hospitalId ? `&hospitalId=${hospitalId}` : ""}`)

});
const getAllImagesInstallation = (type, id) => ({
	type: events.INSTALLATION_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}`)
});

const getAllImagesPosition = (type, id) => ({
	type: events.POSITION_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}`)
});

const getDefinition = (id, type) => ({
	type: events.DEFINITION_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}&offset=0&numResults=1000`)
});

const clearGetDefinition = () => ({
	type: events.CLEAR_DEFINITION_GET,
	payload: {}
});

const getAllImagesDefinition = (id, type) => ({
	type: events.DEFINITION_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}`)
});

const clearGetAllImagesDefinition = () => ({
	type: events.CLEAR_DEFINITION_GET_ALL_IMAGES,
	payload: {}
});

const getDescription = (id, type) => ({
	type: events.DESCRIPTION_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}&offset=0&numResults=1000`)
});

const clearGetDescription = () => ({
	type: events.CLEAR_DESCRIPTION_GET,
	payload: {}
});

const getAllImagesDescription = (id, type) => ({
	type: events.DESCRIPTION_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}`)
});

const clearGetAllImagesDescription = () => ({
	type: events.CLEAR_DESCRIPTION_GET_ALL_IMAGES,
	payload: {}
});

const getRemarks = (id, type) => ({
	type: events.REMARKS_GET,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/text_elements?type=${type}&offset=0&numResults=1000`)
});

const clearGetRemarks = () => ({
	type: events.CLEAR_REMARKS_GET,
	payload: {}
});

const getAllImagesRemarks = (id, type, query) => ({
	type: events.REMARKS_GET_ALL_IMAGES,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/${type}${query ? `?${query}` : ""}`)
});

const clearGetAllImagesRemarks = () => ({
	type: events.CLEAR_REMARKS_GET_ALL_IMAGES,
	payload: {}
});

const clearGet = () => ({
	type: events.CLEAR_INTERVENTIONDETAILS_GET,
	payload: []
});

const reloadStaff = () => ({
	type: events.RELOAD_STAFF,
	payload: {}
});

const clearReloadStaff = () => ({
	type: events.CLEAR_RELOAD_STAFF,
	payload: {}
});
const fetchAllElements = (id, doctorId) => ({
	type: events.FETCH_ALL_ELEMENTS,
	payload: axios.get(`${Config.apiRootSecure}/treatments/${id}/all_elements?offset=0&numResults=1000${doctorId ? (`&doctorId=${doctorId}`) : ""}`)
});

const fetchAllExtendedElements = (id, doctorId, status, hospitalId) => ({
	type: events.FETCH_ALL_EXTENDED_ELEMENTS,
	payload: axios.get(`${Config.apiRootSecure}/treatment/${id}/elementsForTreatmentMapping?offset=0&numResults=1000${doctorId ? (`&doctorId=${doctorId}`) : ""}${status ? `&status=${status}` : ""}${hospitalId ? `&hospitalId=${hospitalId}` : ""}`)
});

export default {
	getPreparation,
	clearGet,
	getPositions,
	getInstallation,
	getStaff,
	clearGetStaff,
	// getSterilization,
	getMedicalDevices,
	getPharmacy,
	// getProsthesis,
	getAllImagesInstallation,
	getAllImagesPosition,
	getDefinition,
	clearGetDefinition,
	getAllImagesDefinition,
	clearGetAllImagesDefinition,
	getDescription,
	clearGetDescription,
	getAllImagesDescription,
	clearGetAllImagesDescription,
	getRemarks,
	clearGetRemarks,
	getAllImagesRemarks,
	clearGetAllImagesRemarks,
	reloadStaff,
	clearReloadStaff,
	fetchAllExtendedElements,
	fetchAllElements
};
