import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ExpandedCard from "./expandable-card";
import I18 from "../../../../i18";

let config;

class Card extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1
		};
		config = this.props.config;
	}

	UNSAFE_componentWillReceiveProps(props) {
		config = props.config;
	}

	render() {
		return (
			config ? this.getCard() : <div />
		);
	}

	getCard() {
		return config.map((card, index) => {
			if (this.state.expandedCardPosition === index) {
				return this.renderOpenedCard(index);
			}
			const className = (index !== 3 ? (this.state.expandedCardPosition === 3 ? "mr333" : "mr444") : "");
			return (
				<div key={index} className={`color_box color_boxbg${index + 1} ${className}`} onClick={this.handleCardClick.bind(this, index)}>
					<div className="display_table w_100">
						<div className="display_table_cell">
							<div className="box-items text_align_center">
								<img className="margin-bottom-20 box_item_icon" src={`images/${card.icon}`} />
								<div className="box_item_name"><I18 tkey={card.name} /></div>
							</div>
						</div>
					</div>
				</div>
			);
		});
	}

	handleCardClick(index) {
		this.setState({ expandedCardPosition: (this.state.expandedCardPosition === index ? -1 : index) });
	}

	renderOpenedCard(index) {
		return <ExpandedCard onClick={this.handleCardClick.bind(this, index)} options={config[index]} />;
	}
}

Card.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Card));
