import eventNames from "../../actions/events";

const initialState = {
	fetchAllElementMappingComplete: false,
	elementsForMapping: [],
	elementMappingSubmitComplete: false,
	mappedElement: {}
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_ELEMENT_MAPPING}_PENDING`:
			return {
				...state,
				fetchAllElementMappingComplete: "PENDING"
			};
		case `${eventNames.FETCH_ALL_ELEMENT_MAPPING}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllElementMappingComplete: "FULFILLED",
					elementsForMapping: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllElementMappingComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_ELEMENT_MAPPING}_REJECTED`:
			return {
				...state,
				fetchAllElementMappingComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_ELEMENT_MAPPING:
			return {
				...state,
				fetchAllElementMappingComplete: false
			};
		case `${eventNames.SUBMIT_ELEMENT_MAPPING}_PENDING`:
			return {
				...state,
				elementMappingSubmitComplete: "PENDING"
			};
		case `${eventNames.SUBMIT_ELEMENT_MAPPING}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					elementMappingSubmitComplete: "FULFILLED",
					mappedElement: action.payload.data.data
				};
			}
			return {
				...state,
				elementMappingSubmitComplete: "FULFILLED"
			};
		case `${eventNames.SUBMIT_ELEMENT_MAPPING}_REJECTED`:
			return {
				...state,
				elementMappingSubmitComplete: "REJECTED"
			};
		case eventNames.CLEAR_SUBMIT_ELEMENT_MAPPING:
			return {
				...state,
				elementMappingSubmitComplete: false
			};
		default:
			return state;
	}
}
