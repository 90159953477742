import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import ActionButton from "../../../../../../../plug/action-button";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import clinicalPathwayDetailsAction from "../../../../../../../actions/clinicalPathway-details";
import utils from "../../../../../../../utils/utils";
import eventNames from "../../../../../../../actions/events";
import { Steps, Button, Checkbox } from "antd";
import Select from "react-select";
import isEqual from "lodash.isequal";
import differenceBy from "lodash.differenceby";
import unionWith from "lodash.unionwith";
import Loader from "../../../../../../../plug/loader";
import ShowOrHideSteps from "./show-or-hide-steps";
import ActiveComponents from "./active-components";

const Step = Steps.Step;
class CopyFromClinicalPathWay extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeStep: 0,
			clinicalPathways: [],
			selectedData: {},
			genericComponents: {},
			clinicalPathway: {},
			activeComponents: [],
			selectAll: true,
			checkList: [],
			listOffset: 0,
			listNumResults: 100,
			renderList: [],
			componentsForSelection: {},
			stepLoader: false,
			checkAll: false,
			loading: false,
			code: "",
			copyLoading: false
		};
	}

	UNSAFE_componentWillMount() {
		if (this.props.clinicalPathwayData) {
			this.state.clinicalPathway = this.props.clinicalPathwayData;
			this.fetchList();
		}
	}

	fetchList(search) {
		this.setState({ isListLoading: true });
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&`;
		queryParams += `sort=name&desc=0&${search ? `name=${search}&` : ""}`;
		queryParams += "ignoreExisting=true&";
		queryParams += "status=APPROVED&";
		queryParams += this.state.clinicalPathway.isCheckList ? "isCheckList=1&" : "isCheckList=0&";
		this.props.dispatch(clinicalPathwayAction.getAllClpsForCopy(this.props.match.params.id ? this.props.match.params.id : this.state.clinicalPathway.id, queryParams));
	}

	resize() {
		this.setState({ isMobile: window.innerWidth <= 767 });
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumResults;
		this.fetchList();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchList(value);
			}, 700);
		}
	}

	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.clinicalPathways = differenceBy(this.state.clinicalPathways, [this.state.intervention], "id");
		this.state.clinicalPathways.map((data) => {
			prevState.options.push({ value: data.id, label: `${data.hospitalName ? data.hospitalName : ""} ${data.name}`, code: data.code, clinicalPathwayType: data.clinicalPathway });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onChange(component, e) {
		this.setState({ [component]: e.target.checked, invalidComponents: false });
	}

	onOptionsChange(e) {
		const selectedData = e;
		this.setState({ selectedData: selectedData, invalidClinicalPathway: false });
		if (selectedData && selectedData.value) {
			this.props.dispatch(clinicalPathwayDetailsAction.fetchCopyFromClinicalPathwayList(selectedData.value));
			this.setState({ stepLoader: true });
		}
	}

	prevClicked() {
		this.setState({ activeStep: this.state.activeStep - 1 });
	}

	nextClicked() {
		if (this.checkActiveComponents()) {
			this.setState({ activeStep: this.state.activeStep + 1 });
		}
	}

	checkActiveComponents() {
		const checkedDataList = this.state.checkList.filter(value => value.checked === true);
		if ((checkedDataList && checkedDataList.length) || (Object.values(this.state.genericComponents).includes(!""))) {
			this.state.invalidComponents = false;
		} else {
			this.state.invalidComponents = true;
		}
		if (!this.state.selectedData || !this.state.selectedData.value) {
			this.state.invalidClinicalPathway = true;
		}
		if (this.state.invalidComponents || this.state.invalidClinicalPathway) {
			this.setState(this.state);
			return false;
		}
		return true;
	}

	getSelectedComponents(checkList) {
		this.setState({ checkList: checkList, invalidComponents: false, checkAll: false });
	}

	getSubStepDetails() {
		if (this.state.steps && this.state.steps.length) {
			return (<ShowOrHideSteps getSelectedComponents={this.getSelectedComponents.bind(this)} steps={this.state.steps} checkList={this.state.checkList} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	getActiveComponents() {
		const checkActiveComponents = this.state.checkList.filter(value => value.checked === true);
		if (this.state.checkList && this.state.checkList && checkActiveComponents && checkActiveComponents.length) {
			return (<ActiveComponents checkList={this.state.checkList} />);
		}
		else {
			return (<Loader loading={this.state.stepLoader} />);
		}
	}

	onCheckBoxChange(e, value) {
		if (e.target.checked) {
			this.state.genericComponents[value] = true;
			this.state.invalidComponents = false;
			this.state.checkAll = false;
		}
		else {
			this.state.genericComponents[value] = "";
			this.state.checkAll = false;
		}
		this.setState(this.state);
	}

	onChangeCheckAll(e) {
		if (e.target.checked) {
			this.state.checkAll = true;
			this.state.invalidComponents = false;
			this.state.genericComponents.PATHWAY_DEFINITION = true;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = true;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = true;
			if (this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = true;
				this.state.genericComponents.ANAESTHESIA = true;
			}
			this.state.checkList.map(function (e) {
				e["checked"] = true;
				e.subSteps.map(function (data) {
					data["checked"] = true;
				});
			});
		}
		else {
			this.state.checkAll = false;
			this.state.genericComponents.PATHWAY_DEFINITION = false;
			this.state.genericComponents.PATHWAY_ELIGIBILITY = false;
			this.state.genericComponents.AUTHORS_AND_ACTIONS = false;
			if (this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY") {
				this.state.genericComponents.INTERVENTIONS = false;
				this.state.genericComponents.ANAESTHESIA = false;
			}
			this.state.checkList.map(function (e) {
				e["checked"] = false;
				e.subSteps.map(function (data) {
					data["checked"] = false;
				});
			});
		}
		this.setState(this.state);
	}

	getStatus() {
		if (this.state.code && this.state.loading) {
			this.timer = setTimeout(function () {
				this.props.dispatch(clinicalPathwayDetailsAction.copyFromClinicalPathwayStatus(this.state.code, {}));
			}.bind(this), 3000);
		}
	}

	toggleModal = () => {
		this.setState({ copyLoading: false, activeStep: 0 });
		if (this.props.actions.actionInProgress) {
			this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
		}
		this.state.selectedData = {};
		this.props.closeCopy();
	};

	isClpCopyCompleted = (status) => {
		switch (status) {
			case "PROCESSED":
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			case "ERRORED":
				utils.showError(i18Get("Error occured while copying clinical pathway", utils.getLanguage()));
				this.setState({ loading: false }, () => { this.toggleModal(); });
				return true;
			default: return false;
		}
	};

	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathwaysForCopy === "FULFILLED") {
			this.state.clinicalPathways = unionWith(this.state.clinicalPathways, this.props.clinicalPathway.clinicalPathway.data, isEqual);
			this.renderOptions();
			this.props.dispatch(clinicalPathwayAction.clearGetAllClpsForCopy());
		}

		if (this.props.clinicalPathwayDetails.fetchCopyFromClinicalPathwayList === "FULFILLED") {
			this.state.steps = [];
			this.state.steps = this.props.clinicalPathwayDetails.copyFromClinicalPathwayList ? this.props.clinicalPathwayDetails.copyFromClinicalPathwayList : [];
			this.state.checkList = this.props.clinicalPathwayDetails.copyFromClinicalPathwayList ? this.props.clinicalPathwayDetails.copyFromClinicalPathwayList : [];
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathwayList());
			this.setState({ stepLoader: false, invalidComponents: false, checkAll: false, genericComponents: {} });
		}

		if (this.props.clinicalPathwayDetails.copyFromClinicalPathWay == "FULFILLED") {
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathway());
			if (this.props.clinicalPathwayDetails.clpCopy && this.props.clinicalPathwayDetails.clpCopy.code) {
				this.setState({ code: this.props.clinicalPathwayDetails.clpCopy.code }, () => {
					this.getStatus();
				});
			}
		}

		if (this.props.clinicalPathwayDetails.copyFromClinicalPathWay == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathway());
			this.setState({ loading: false, copyLoading: false });
		}

		if (this.props.clinicalPathwayDetails.copyFromClinicalPathwayStatus == "FULFILLED") {
			clearTimeout(this.timer);
			if (this.props.clinicalPathwayDetails.clpCopyStatus && this.props.clinicalPathwayDetails.clpCopyStatus.status) {
				if (this.props.clinicalPathwayDetails.clpCopyStatus.status == "PROCESSED") {
					utils.showSuccess(i18Get("clinical pathway copy completed successfully", utils.getLanguage()));
					this.toggleModal();
				} else if (!this.isClpCopyCompleted(this.props.clinicalPathwayDetails.clpCopyStatus.status)) {
					this.getStatus();
				}
			} else {
				this.setState({ loading: false }, () => { this.toggleModal(); });
			}
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathwayStatus());
		}

		if (this.props.clinicalPathwayDetails.copyFromClinicalPathwayStatus == "REJECTED") {
			this.props.dispatch(clinicalPathwayDetailsAction.clearCopyFromClinicalPathwayStatus());
			this.setState({ loading: false });
		}

		return (<div>
			<Steps className="steps_wrapper" progressDot current={this.state.activeStep}>
				<Step key='source' title={i18Get("Select source & steps", utils.getLanguage())} />
				<Step key='summary' title={i18Get("Summary", utils.getLanguage())} />
			</Steps>
			{this.state.activeStep === 0 && <div className="steps-content padding-15">
				<div className="row margin-bottom-20">
					<div className="col-md-3"><span className="steps_label_special font_size_13px_label padding-top-0"><I18 tkey='Select source' /></span></div>
					<div className="col-md-9 padding-right-0">
						<div className="w_100 di position_relative react_dropdown_scroll">
							<Select
								isSearchable
								className="w100"
								onChange={this.onOptionsChange.bind(this)}
								value={this.state.selectedData}
								options={this.state.options}
								onMenuScrollToBottom={this.onListScroll.bind(this)}
								onInputChange={this.onSearch.bind(this)}
								isLoading={this.state.isListLoading}
								loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
								placeholder={i18Get("Select", utils.getLanguage())} />
							<span className='warning_msg position_unset'>
								*<b><I18 tkey="This List shows the Clinical Pathways with tasks present in steps having the same" /></b>:
								<ul className='list_square margin-left-10'>
									<li ><I18 tkey="Task Length of Before Hospitalisation" /> ({this.state.clinicalPathway.beforeAdmissionNoOfDays ? parseInt(this.state.clinicalPathway.beforeAdmissionNoOfDays) : 2})</li>
									<li ><I18 tkey="Length of Stay" /> ({this.state.clinicalPathway.noOfHours == 0 ? this.state.clinicalPathway.noOfDays : this.state.clinicalPathway.noOfHours})</li>
									<li ><I18 tkey="Day of Procedure" /> ({this.state.clinicalPathway.procedureDay ? parseInt(this.state.clinicalPathway.procedureDay) : 1})</li>
									<li ><I18 tkey="After Checkout" /> ({this.state.clinicalPathway.afterCheckOutNoOfDays ? parseInt(this.state.clinicalPathway.afterCheckOutNoOfDays) : 2})</li>
								</ul>
							</span>
							{this.state.invalidClinicalPathway && <span className='invalid fixed_label margin-top-35'><I18 tkey='Select Clinical Pathway' /></span>}
						</div>
					</div>
				</div>
				<div className="component_heading  di w_100"><I18 tkey='Components' /></div>
				<div className="copy_scroll position_relative">
					<div className="margin-top-15 position_relative">
						{this.state.invalidComponents && <span className='invalid fixed_label'><I18 tkey='Select Components' /></span>}
						{this.state.selectedData && this.state.selectedData.value ?
							<div className="">
								<div>
									<span className="check-box-block  margin-left-10">
										<Checkbox
											checked={this.state.checkAll}
											onChange={(e) => this.onChangeCheckAll(e, "ALL")} />
										<span className="margin-left-10"><I18 tkey='ALL' /></span>
									</span>
								</div>
								<span className="check-box-block  margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.AUTHORS_AND_ACTIONS}
										onChange={(e) => this.onCheckBoxChange(e, "AUTHORS_AND_ACTIONS")} />
									<span className="margin-left-10"><I18 tkey='AUTHORS AND ACTIONS' /></span>
								</span>
								<span className="check-box-block margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.PATHWAY_DEFINITION}
										onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_DEFINITION")} />
									<span className="margin-left-10"><I18 tkey='PATHWAY DEFINITION' /></span>
								</span>
								<span className="check-box-block margin-left-10">
									<Checkbox
										checked={this.state.genericComponents.PATHWAY_ELIGIBILITY}
										onChange={(e) => this.onCheckBoxChange(e, "PATHWAY_ELIGIBILITY")} />
									<span className="margin-left-10"><I18 tkey='PATHWAY ELIGIBILITY' /></span>
								</span>
								{this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY" ?
									<span className="check-box-block margin-left-10">
										<Checkbox
											checked={this.state.genericComponents.INTERVENTIONS}
											onChange={(e) => this.onCheckBoxChange(e, "INTERVENTIONS")} />
										<span className="margin-left-10"><I18 tkey='INTERVENTIONS' /></span>
									</span> : ""
								}
								{this.state.clinicalPathway && this.state.clinicalPathway.clinicalPathway === "SURGERY" ?
									<span className="check-box-block margin-left-10">
										<Checkbox
											checked={this.state.genericComponents.ANAESTHESIA}
											onChange={(e) => this.onCheckBoxChange(e, "ANAESTHESIA")} />
										<span className="margin-left-10"><I18 tkey='ANAESTHESIA' /></span>
									</span> : ""
								}
							</div> : ""
						}
					</div>
					<div className="margin-top-15 position_relative height_max_200 scroll">
						{this.state.selectedData && this.state.selectedData.value ? this.getSubStepDetails() : ""}
					</div>
				</div>
			</div>}
			{this.state.activeStep === 1 && <div >
				{this.getActiveData()}
			</div>}
			<div className="steps-action di w_100 text_align_right">
				{this.state.copyLoading &&
					<div className="padding-bottom-10 copy_label"><I18 tkey="Copy clinical pathway will take more time. You will not be able to close this popup" /></div>}
				{this.state.activeStep === 0 && <Button type="primary" className="" onClick={this.nextClicked.bind(this)}><I18 tkey='Next' /></Button>}
				<div className="copy_btn_modal">
					{this.state.activeStep === 1 && <Button type="primary" className="margin-right-10 " onClick={this.prevClicked.bind(this)}><I18 tkey='Prev' /></Button>}
					{this.state.activeStep === 1 && <ActionButton type="primary" className="" onClick={this.doneClicked.bind(this)} value={i18Get("Copy", utils.getLanguage())} />}
				</div>
			</div>
		</div>);
	}

	getActiveData() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-10 padding-bottom-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey='Source' /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Code' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.selectedData ? this.state.selectedData.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Name' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9"> {this.state.selectedData.label}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="steps-content padding-left-15 padding-right-15 padding-top-10 padding-bottom-10">
							<div className="row">
								<div className="col-md-12 header_copy_paste"><span className="steps_label font_size_14px_label margin-top-0 margin-bottom-0"><I18 tkey='Destination' /></span></div>
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Code' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9">{this.state.clinicalPathway ? this.state.clinicalPathway.code : ""}</div>
									</div>
									<div className="row">
										<div className="col-md-3 col-sm-2 col-xs-3"><span className="font_weight_600 color_5c5c5c"><I18 tkey='Name' /></span></div>
										<div className="col-md-9 col-sm-10 col-xs-9"> {this.state.clinicalPathway ? this.state.clinicalPathway.name : ""}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="steps-content padding-15">
					<div className="row">
						<div className="col-md-3 margin-bottom-10"><div className="component_heading  di w_100 margin-bottom-10"><I18 tkey='Components' /></div></div>
						<div className="col-md-12 margin-top-15 position_relative height_max_200 scroll">
							{this.state.genericComponents.AUTHORS_AND_ACTIONS ? <div className="col-md-12 margin-bottom-10"><I18 tkey='AUTHORS AND ACTIONS' /></div> : ""}
							{this.state.genericComponents.PATHWAY_DEFINITION ? <div className="col-md-12 margin-bottom-10"><I18 tkey='PATHWAY DEFINITION' /></div> : ""}
							{this.state.genericComponents.PATHWAY_ELIGIBILITY ? <div className="col-md-12 margin-bottom-10"><I18 tkey='PATHWAY ELIGIBILITY' /></div> : ""}
							{this.state.genericComponents.INTERVENTIONS ? <div className="col-md-12 margin-bottom-10"><I18 tkey='INTERVENTIONS' /></div> : ""}
							{this.state.genericComponents.ANAESTHESIA ? <div className="col-md-12 margin-bottom-10"><I18 tkey='ANAESTHESIA' /></div> : ""}
							<div className="col-md-12">
								<div className="">{this.getActiveComponents()}</div>
							</div>
						</div>
					</div>
				</div>
			</div>);
	}

	doneClicked() {
		const checkedDataList = JSON.parse(JSON.stringify(this.state.checkList));
		const list = checkedDataList.filter(value => value.checked === true);
		list.map(function (e) {
			if (e.checked) {
				delete e["checked"];
			}
			if (e.stepName) {
				delete e["stepName"];
			}
			e.subSteps = e.subSteps.filter(value => value.checked === true);
			e.subSteps.map(function (data) {
				if (data.checked) {
					delete data["checked"];
				}
				if (data.name) {
					delete data["name"];
				}
			});
		});
		this.setState({ copyLoading: true, loading: true });
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			components: list,
			genericComponents: this.state.genericComponents,
			destClpType: this.state.clinicalPathway.clinicalPathway,
			sourceClpType: this.state.selectedData.clinicalPathwayType
		};
		this.props.dispatch(clinicalPathwayDetailsAction.copyFromClinicalPathway(this.state.clinicalPathway.id, this.state.selectedData.value, payload));
	}
}

CopyFromClinicalPathWay.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(CopyFromClinicalPathWay));