import React, { Component } from "react";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";

const { Option } = Select;

export default class InterventionStatusDropdown extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="w_100 di">
				{this.props.isAnaesthesia
					? (
						<Select
							value={this.props.data ? this.props.data : utils.sameDropper()}
							className="w_100 di "
							defaultActiveFirstOption={false}
							onChange={this.props.dropdownChanged}
							dropdownStyle={{ minWidth: "50%" }}
							placeholder={i18Get("Select", utils.getLanguage())}
							notFoundContent={i18Get("Not Found", utils.getLanguage())}
						>
							<Option value="ALL"><I18 tkey="All" /></Option>
							<Option value="CONSUMABLE_WITH_WFA"><I18 tkey="Consumables With Waiting For Approval" /></Option>
						</Select>
					)
					: (
						<Select
							value={this.props.data ? this.props.data : utils.sameDropper()}
							className="w_100 di "
							defaultActiveFirstOption={false}
							onChange={this.props.dropdownChanged}
							dropdownStyle={{ minWidth: "50%" }}
							placeholder={i18Get("Select", utils.getLanguage())}
							notFoundContent={i18Get("Not Found", utils.getLanguage())}
						>
							<Option value="ALL"><I18 tkey="All" /></Option>
							<Option value="CONSUMABLE_WITH_WFA"><I18 tkey="Consumables With Waiting For Approval" /></Option>
							<Option value="ANAESTHESIA_WITH_WFA"><I18 tkey="Anaesthesia With Waiting For Approval" /></Option>
							<Option value="CONSUMABLE_AND_ANAESTHESIA_WITH_WFA"><I18 tkey="Consumables And Anaesthesia With Waiting For Approval" /></Option>
						</Select>
					)}
			</div>
		);
	}
}
