import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import configOne from "./configOne.json";
import configTwo from "./configTwo.json";
import ExpandableLink from "../../../dashboard/cards/expandable-link";
import locations from "../../../../../router/locations";
import CardLink from "../../../dashboard/cards/card-link";

class Elements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			expandedCardPosition: -1,
			config: [],
			isLoaded: false,
			location: ""
		};
	}

	componentDidMount() {
		const { id } = this.props.match.params;
		const { sheets } = this.props.match.params;
		const { organization } = this.props.match.params;
		this.getConfig(organization, id, sheets);
	}

	UNSAFE_componentWillMount() {
		this.props.history.listen((location) => {
			if (location.pathname !== this.props.location.pathname
				|| location.search !== this.props.location.search) {
				this.props.location.pathname = location.pathname;
				this.props.location.search = location.search;
				this.forceUpdate();
			}
		});
	}

	getConfig(organization, id, sheets) {
		let config = JSON.parse(JSON.stringify((`/${sheets}` === locations.analyticalSheets) ? configTwo : configOne));
		config = config.map((data) => {
			data.link = data.link ? data.link.replace("organization", organization).replace("id", id).replace("sheets", sheets) : "";
			data.link = data.link ? data.link + this.props.location.search : "";
			return data;
		});
		this.setState({ config, isLoaded: true });
	}

	render() {
		return (
			<div className="mom-sidebar ">
				{
					this.state.config.map((cardLink) => {
						if (cardLink) {
							return this.renderContents(cardLink);
						}
					})
				}
			</div>
		);
	}

	onExpanded(index) {
		this.setState({ expandedCardPosition: index });
	}

	renderContents(cardLink) {
		if (cardLink.expandable) {
			return <ExpandableLink openCard={this.state.expandedCardPosition} onClick={this.onExpanded.bind(this)} data={cardLink} />;
		}
		return <CardLink data={cardLink} />;
	}
}

Elements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(Elements));
