import eventNames from "../../actions/events";

const initialState = {
	filterData: {},
	communityMembersCreate: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.CREATE_COMMUNITY_MEMBERS}_FULFILLED`:
			return {
				...state,
				communityMembers: action.payload.data.data,
				communityMembersCreate: "FULFILLED"
			};
		case `${eventNames.CREATE_COMMUNITY_MEMBERS}_REJECTED`:
			return {
				...state,
				communityMembersCreate: "REJECTED"
			};
		case `${eventNames.CLEAR_CREATE_COMMUNITY_MEMBERS}`:
			return {
				...state,
				communityMembersCreate: false
			};

		case `${eventNames.COMMUNITY_MEMBERS_GET_ALL}_REJECTED`:
			return {
				...state,
				isFetchAllCommunityMembersCompleted: "REJECTED",
				fetchAllElementClassMeta: null
			};

		case `${eventNames.COMMUNITY_MEMBERS_GET_ALL}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					count: action.payload.data.data.count,
					communityMembers: action.payload.data.data.data,
					isFetchAllCommunityMembersCompleted: "FULFILLED",
					fetchAllElementClassMeta: action.meta
				};
			}
			return {
				...state,
				communityMembers: [],
				isFetchAllCommunityMembersCompleted: "FULFILLED",
				fetchAllElementClassMeta: null
			};
		case eventNames.CLEAR_COMMUNITY_MEMBERS_FETCH:
			return {
				...state,
				isFetchAllCommunityMembersCompleted: false,
				fetchAllElementClassMeta: null
			};
		case `${eventNames.DELETE_COMMUNITY_MEMBERS}_REJECTED`:
			return {
				...state,
				deleteCommunityMembersComplete: "REJECTED"
			};

		case `${eventNames.DELETE_COMMUNITY_MEMBERS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					deleteCommunityMembersComplete: "FULFILLED"
				};
			}
			return {
				...state,
				deleteCommunityMembersComplete: "FULFILLED"
			};
		case eventNames.CLEAR_DELETE_COMMUNITY_MEMBERS:
			return {
				...state,
				deleteCommunityMembersComplete: false
			};

		case `${eventNames.COMMUNITY_MEMBERS_GET_ONE}_PENDING`:
			return {
				...state,
				fetchOneCommunityMembersComplete: "PENDING"
			};
		case `${eventNames.COMMUNITY_MEMBERS_GET_ONE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchOneCommunityMembersComplete: "FULFILLED",
					communityMembers: action.payload.data.data
				};
			}
			return {
				...state,
				fetchOneCommunityMembersComplete: "FULFILLED"
			};
		case `${eventNames.COMMUNITY_MEMBERS_GET_ONE}_REJECTED`:
			return {
				...state,
				fetchOneCommunityMembersComplete: "REJECTED"
			};
		case eventNames.CLEAR_COMMUNITY_MEMBERS_GET_ONE:
			return {
				...state,
				fetchOneCommunityMembersComplete: false
			};

		case `${eventNames.UPDATE_COMMUNITY_MEMBERS}_PENDING`:
			return {
				...state,
				updateCommunityMembersComplete: "PENDING"
			};
		case `${eventNames.UPDATE_COMMUNITY_MEMBERS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					updateCommunityMembersComplete: "FULFILLED",
					updatedCommunityMembers: action.payload.data.data
				};
			}
			return {
				...state,
				updateCommunityMembersComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_COMMUNITY_MEMBERS}_REJECTED`:
			return {
				...state,
				updateCommunityMembersComplete: "REJECTED"
			};
		case eventNames.UPDATE_COMMUNITY_MEMBERS_COMPLETED:
			return {
				...state,
				updateCommunityMembersComplete: false
			};
		case eventNames.FILTER_CHANGE_COMMUNITY_MEMBERS:
			return {
				...state,
				filterChangedCommunityMembers: action.payload.filter,
				ignorePageChangeCommunityMembers: action.payload.ignorePageChange
			};
		default:
			return state;
	}
}
