import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import specialtyActions from "../../../../../../../actions/specialty";
import events from "../../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import Loader from "../../../../../../../plug/loader";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import screenId from "../../../../../../../router/screenIdList";
import NumericInput from "../../../../../../../plug/numeric-input";

class EditSpecialty extends Component {
	constructor(props) {
		super(props);
		this.state = {
			specialty: {},
			invalidName: false
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.specialityEdit });
		this.props.dispatch(specialtyActions.fetchOneSpecialty(this.props.match.params.id));
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { specialtyData: {} } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.specialty.name = value;
		this.setState(prevState);
	}

	costConstantChange = (label, value) => {
		if (value > 100) {
			value = 100;
		}
		const specialty = this.state.specialty;
		specialty.costConstant = value;
		this.setState({ ...this.state, specialty: specialty });
	};

	validateFields() {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.specialty.name) || !(this.state.specialty.name.trim())) {
			prevState.invalidName = true;
			this.setState(prevState);
			return false;
		}
		return true;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.specialty.name,
				typeActivity: utils.checkForParameter("typeActivity", this.props.location)
			};
			if (this.props.typeActivity && this.props.typeActivity.toUpperCase() == "INTERVENTION") {
				payload["costConstant"] = utils.calculateFloatValue(this.state.specialty.costConstant);
			}
			this.props.dispatch(specialtyActions.updateSpecialty(this.state.specialty.id, payload));
		}
	}

	reload = () => {
		this.props.dispatch(specialtyActions.fetchOneSpecialty(this.props.match.params.id));
	};

	renderEdit() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="margin-bottom-10 subheader">
						<span><I18 tkey="TypeActivity" />: </span>
						<span><I18 tkey={this.props.typeActivity.replace(/_/g, " ")} /></span>
					</div>
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<input className="w_100 di form-control" value={this.state.specialty.code} readOnly />
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.specialty.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={60}
								/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
						{
							this.props.typeActivity && this.props.typeActivity.toUpperCase() == "INTERVENTION" &&
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-18 position_relative">
									<label><I18 tkey="General Cost" /></label>
									<NumericInput
										value={this.state.specialty.costConstant}
										getNumericValue={this.costConstantChange}
									/>
								</div>
							</div>
						}

						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.specialty.isUpdateSpecialtyCompleted === "FULFILLED") {
			this.state.specialty = this.props.specialty.updatedSpecialty;
			this.props.dispatch(specialtyActions.clearUpdateSpecialty());
			this.props.history.push(locations.specialities + `?typeActivity=${this.props.typeActivity}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
			this.setState({ ...this.state });
		}

		if (this.props.specialty.isFetchOneSpecialtyCompleted === "FULFILLED") {
			this.state.specialty = this.props.specialty.selectedSpecialty;
			this.setState({ ...this.state }, () => {
				this.props.dispatch(specialtyActions.clearFetchOneSpecialty());
				this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { specialtyData: { name: this.state.specialty.name, id: this.state.specialty.id } } });
			});

		}
		if (this.props.specialty.isFetchOneSpecialtyCompleted === "REJECTED") {
			this.props.history.push(locations.specialities + `?typeActivity=${this.props.typeActivity}`);
			this.props.dispatch(specialtyActions.clearFetchOneSpecialty());
		}
		if (this.state.specialty.id) {
			return this.renderEdit();
		}
		return (<Loader loading={true} isTable={false} />);
	}
	componentDidUpdate() {

	}
}

EditSpecialty.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	specialty: store.specialty,
	translations: store.translations,
	actions: store.actions
}))(EditSpecialty)); 