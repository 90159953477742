import eventNames from "../../actions/events";

const initialTranslationsState = {
	fetchAllClinicalPathwayProblemCompleted: false,
	problemList: {},
	createClinicalPathwayProblemCompleted: false,
	fetchOneClinicalPathwayProblemCompleted: false,
	problem: {},
	updateClinicalPathwayProblemCompleted: false,
	deleteClinicalPathwayProblemCompleted: false,
	fetchAllClinicalPathwayStepCompleted: false,
	stepList: {},
	fetchAllMinimalStepCompleted: false,
	minimalTemplateList: {},
	fetchOneClinicalPathwayStepCompleted: false,
	step: {},
	updateClinicalPathwayStepCompleted: false,
	createClinicalPathwayStepCompleted: false,
	deleteClinicalPathwayStepCompleted: false,
	fetchAllClinicalPathwaySubStepCompleted: false,
	subStepList: {},
	fetchOneClinicalPathwaySubStepCompleted: false,
	subStepDetails: {},
	createClinicalPathwaySubStepCompleted: false,
	updateClinicalPathwaySubStepCompleted: false,
	deleteClinicalPathwaySubStepCompleted: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_PROBLEM}_FULFILLED`:
			return {
				...state,
				fetchAllClinicalPathwayProblemCompleted: "FULFILLED",
				problemList: action.payload.data.data
			};
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_PROBLEM}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayProblemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_PROBLEM:
			return {
				...state,
				fetchAllClinicalPathwayProblemCompleted: false
			};

		case `${eventNames.CREATE_CLINICAL_PATHWAY_PROBLEM}_FULFILLED`:
			return {
				...state,
				createClinicalPathwayProblemCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_PROBLEM}_REJECTED`:
			return {
				...state,
				createClinicalPathwayProblemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CLINICAL_PATHWAY_PROBLEM:
			return {
				...state,
				createClinicalPathwayProblemCompleted: false
			};

		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_PROBLEM}_FULFILLED`:
			return {
				...state,
				problem: action.payload.data.data,
				fetchOneClinicalPathwayProblemCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_PROBLEM}_REJECTED`:
			return {
				...state,
				fetchOneClinicalPathwayProblemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_PROBLEM:
			return {
				...state,
				fetchOneClinicalPathwayProblemCompleted: false
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_PROBLEM}_FULFILLED`:
			return {
				...state,
				problemDetails: action.payload.data.data,
				updateClinicalPathwayProblemCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_PROBLEM}_REJECTED`:
			return {
				...state,
				updateClinicalPathwayProblemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CLINICAL_PATHWAY_PROBLEM:
			return {
				...state,
				updateClinicalPathwayProblemCompleted: false
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_PROBLEM}_FULFILLED`:
			return {
				...state,
				deleteClinicalPathwayProblemCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_PROBLEM}_REJECTED`:
			return {
				...state,
				deleteClinicalPathwayProblemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CLINICAL_PATHWAY_PROBLEM:
			return {
				...state,
				deleteClinicalPathwayProblemCompleted: false
			};

		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_STEP}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllClinicalPathwayStepCompleted: "FULFILLED",
					stepList: action.payload.data.data,
					meta: action.meta
				};
			}
			return {
				...state,
				fetchAllClinicalPathwayStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_STEP}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_STEP:
			return {
				...state,
				fetchAllClinicalPathwayStepCompleted: false
			};
		case `${eventNames.FETCH_ALL_MINIMAL_STEP}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllMinimalStepCompleted: "FULFILLED",
					minimalStepList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllMinimalStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_MINIMAL_STEP}_REJECTED`:
			return {
				...state,
				fetchAllMinimalStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_MINIMAL_STEP:
			return {
				...state,
				fetchAllMinimalStepCompleted: false
			};

		case `${eventNames.FETCH_ALL_MINIMAL_SUB_STEP}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllMinimalSubStepCompleted: "FULFILLED",
					minimalSubStepList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllMinimalSubStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_MINIMAL_SUB_STEP}_REJECTED`:
			return {
				...state,
				fetchAllMinimalSubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_MINIMAL_SUB_STEP:
			return {
				...state,
				fetchAllMinimalSubStepCompleted: false
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_STEP}_FULFILLED`:
			return {
				...state,
				step: action.payload.data.data,
				fetchOneClinicalPathwayStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_STEP}_REJECTED`:
			return {
				...state,
				fetchOneClinicalPathwayStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_STEP:
			return {
				...state,
				fetchOneClinicalPathwayStepCompleted: false
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_STEP}_FULFILLED`:
			return {
				...state,
				updateClinicalPathwayStepCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_STEP}_REJECTED`:
			return {
				...state,
				updateClinicalPathwayStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CLINICAL_PATHWAY_STEP:
			return {
				...state,
				updateClinicalPathwayStepCompleted: false
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_STEP}_FULFILLED`:
			return {
				...state,
				createClinicalPathwayStepCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_STEP}_REJECTED`:
			return {
				...state,
				createClinicalPathwayStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CLINICAL_PATHWAY_STEP:
			return {
				...state,
				createClinicalPathwayStepCompleted: false
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_STEP}_FULFILLED`:
			return {
				...state,
				deleteClinicalPathwayStepCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_STEP}_REJECTED`:
			return {
				...state,
				deleteClinicalPathwayStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CLINICAL_PATHWAY_STEP:
			return {
				...state,
				deleteClinicalPathwayStepCompleted: false
			};
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllClinicalPathwaySubStepCompleted: "FULFILLED",
					subStepList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllClinicalPathwaySubStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwaySubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP:
			return {
				...state,
				fetchAllClinicalPathwaySubStepCompleted: false
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP}_FULFILLED`:
			return {
				...state,
				subStepDetails: action.payload.data.data,
				fetchOneClinicalPathwaySubStepCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP}_REJECTED`:
			return {
				...state,
				fetchOneClinicalPathwaySubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP:
			return {
				...state,
				fetchOneClinicalPathwaySubStepCompleted: false
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_SUB_STEP}_FULFILLED`:
			return {
				...state,
				createClinicalPathwaySubStepCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_SUB_STEP}_REJECTED`:
			return {
				...state,
				createClinicalPathwaySubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CLINICAL_PATHWAY_SUB_STEP:
			return {
				...state,
				createClinicalPathwaySubStepCompleted: false
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_SUB_STEP}_FULFILLED`:
			return {
				...state,
				updateClinicalPathwaySubStepCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_SUB_STEP}_REJECTED`:
			return {
				...state,
				updateClinicalPathwaySubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CLINICAL_PATHWAY_SUB_STEP:
			return {
				...state,
				updateClinicalPathwaySubStepCompleted: false
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_SUB_STEP}_FULFILLED`:
			return {
				...state,
				deleteClinicalPathwaySubStepCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_SUB_STEP}_REJECTED`:
			return {
				...state,
				deleteClinicalPathwaySubStepCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CLINICAL_PATHWAY_SUB_STEP:
			return {
				...state,
				deleteClinicalPathwaySubStepCompleted: false
			};

		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_ITEMS}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllClinicalPathwayItemsCompleted: "FULFILLED",
					itemList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllClinicalPathwayItemsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_CLINICAL_PATHWAY_ITEMS}_REJECTED`:
			return {
				...state,
				fetchAllClinicalPathwayItemsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_CLINICAL_PATHWAY_ITEMS:
			return {
				...state,
				fetchAllClinicalPathwayItemsCompleted: false
			};

		case `${eventNames.FETCH_ALL_MINIMAL_ITEM}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					fetchAllMinimalItemCompleted: "FULFILLED",
					minimalItemList: action.payload.data.data
				};
			}
			return {
				...state,
				fetchAllMinimalItemCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_MINIMAL_ITEM}_REJECTED`:
			return {
				...state,
				fetchAllMinimalItemCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_MINIMAL_ITEM:
			return {
				...state,
				fetchAllMinimalItemCompleted: false
			};

		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_ITEMS}_FULFILLED`:
			return {
				...state,
				itemDetails: action.payload.data.data,
				fetchOneClinicalPathwayItemsCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_CLINICAL_PATHWAY_ITEMS}_REJECTED`:
			return {
				...state,
				fetchOneClinicalPathwayItemsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_CLINICAL_PATHWAY_ITEMS:
			return {
				...state,
				fetchOneClinicalPathwayItemsCompleted: false
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_ITEMS}_FULFILLED`:
			return {
				...state,
				createClinicalPathwayItemsCompleted: "FULFILLED"
			};
		case `${eventNames.CREATE_CLINICAL_PATHWAY_ITEMS}_REJECTED`:
			return {
				...state,
				createClinicalPathwayItemsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_CLINICAL_PATHWAY_ITEMS:
			return {
				...state,
				createClinicalPathwayItemsCompleted: false
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_ITEMS}_FULFILLED`:
			return {
				...state,
				updateClinicalPathwayItemsCompleted: "FULFILLED"
			};
		case `${eventNames.UPDATE_CLINICAL_PATHWAY_ITEMS}_REJECTED`:
			return {
				...state,
				updateClinicalPathwayItemsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_CLINICAL_PATHWAY_ITEMS:
			return {
				...state,
				updateClinicalPathwayItemsCompleted: false
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_ITEMS}_FULFILLED`:
			return {
				...state,
				deleteClinicalPathwayItemsCompleted: "FULFILLED"
			};
		case `${eventNames.DELETE_CLINICAL_PATHWAY_ITEMS}_REJECTED`:
			return {
				...state,
				deleteClinicalPathwayItemsCompleted: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_CLINICAL_PATHWAY_ITEMS:
			return {
				...state,
				deleteClinicalPathwayItemsCompleted: false
			};

		case `${eventNames.STEP_RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isStepRankUpdateComplete: "FULFILLED",
					rank: action.payload.data.data
				};
			}
			return {
				...state,
				isStepRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.STEP_RANK_UPDATE}_REJECTED`:
			return {
				...state,
				isStepRankUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_STEP_RANK_UPDATE:
			return {
				...state,
				isStepRankUpdateComplete: false
			};

		case `${eventNames.SUB_STEP_RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isSubStepRankUpdateComplete: "FULFILLED",
					rank: action.payload.data.data
				};
			}
			return {
				...state,
				isSubStepRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.SUB_STEP_RANK_UPDATE}_REJECTED`:
			return {
				...state,
				isSubStepRankUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_SUB_STEP_RANK_UPDATE:
			return {
				...state,
				isSubStepRankUpdateComplete: false
			};

		case eventNames.REFRESH_FILTERS:
			return {
				...state,
				filterRefresh: true
			};
		case eventNames.CLEAR_REFRESH_FILTERS:
			return {
				...state,
				filterRefresh: false
			};

		case eventNames.REFRESH_SUB_STEP:
			return {
				...state,
				refreshSubStep: true
			};
		case eventNames.CLEAR_REFRESH_SUB_STEP:
			return {
				...state,
				refreshSubStep: false
			};

		case `${eventNames.SAVE_AND_APPROVE_STEP}_FULFILLED`:
			return { ...state, saveAndApproveStepStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_STEP}_REJECTED`:
			return { ...state, saveAndApproveStepStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_STEP_COMPLETED:
			return { ...state, saveAndApproveStepStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_STEP}_FULFILLED`:
			return { ...state, asssignAndApproveStepStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_STEP}_REJECTED`:
			return { ...state, asssignAndApproveStepStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_STEP_COMPLETED:
			return { ...state, asssignAndApproveStepStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_SUB_STEP}_FULFILLED`:
			return { ...state, saveAndApproveSubStepStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_SUB_STEP}_REJECTED`:
			return { ...state, saveAndApproveSubStepStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_SUB_STEP_COMPLETED:
			return { ...state, saveAndApproveSubStepStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_SUB_STEP}_FULFILLED`:
			return { ...state, asssignAndApproveSubStepStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_SUB_STEP}_REJECTED`:
			return { ...state, asssignAndApproveSubStepStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_SUB_STEP_COMPLETED:
			return { ...state, asssignAndApproveSubStepStatus: false };

		case `${eventNames.SAVE_AND_APPROVE_ITEM}_FULFILLED`:
			return { ...state, saveAndApproveItemStatus: "FULFILLED" };
		case `${eventNames.SAVE_AND_APPROVE_ITEM}_REJECTED`:
			return { ...state, saveAndApproveItemStatus: "REJECTED" };
		case eventNames.SAVE_AND_APPROVE_ITEM_COMPLETED:
			return { ...state, saveAndApproveItemStatus: false };

		case `${eventNames.ASSIGN_AND_APPROVE_ITEM}_FULFILLED`:
			return { ...state, asssignAndApproveItemStatus: "FULFILLED" };
		case `${eventNames.ASSIGN_AND_APPROVE_ITEM}_REJECTED`:
			return { ...state, asssignAndApproveItemStatus: "REJECTED" };
		case eventNames.ASSIGN_AND_APPROVE_ITEM_COMPLETED:
			return { ...state, asssignAndApproveItemStatus: false };
		case `${eventNames.CLNC_PATHWAY_PROBLEM_ORDER}_FULFILLED`:
			return { ...state, isUpdateProblemReorder: "FULFILLED" };
		case `${eventNames.CLNC_PATHWAY_PROBLEM_ORDER}_REJECTED`:
			return { ...state, isUpdateProblemReorder: "REJECTED" };
		case eventNames.CLEAR_CLNC_PATHWAY_PROBLEM_ORDER:
			return { ...state, isUpdateProblemReorder: false };
		default:
			return state;
	}
}
