import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import roleActions from "../redux/action";
import { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";

class Roles extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			roleList: []
		};
	}

	UNSAFE_componentWillMount() {
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.props.dispatch(roleActions.getAll("offset=0&numResults=100&sort=name&desc=0&status=APPROVED"));
	}

	renderOptions() {      
		const options = [];
		if (this.state.roleList) {    
			const roleId = [];
			this.state.roleList.map((data) => {   
				options.push({ value: data.id, label: data.name, code: data.code, roleType: data.roleType });
				if (this.props.value.length === 0 && this.props.defaultRoles === data.id) {
					roleId.push({ value: data.id, label: data.name, code: data.code, roleType: data.roleType });
				   this.props.onChange(roleId);
				   console.log(">>>>>RoleID>>>>", roleId);
				}
			});
		}    
		return options;
	}
    
	render() {

		if (this.props.roles.getAllStatus == "FULFILLED")
		{
			this.state.roleList = this.props.roles.data.data;
			this.state.loading = false;
			this.props.dispatch(roleActions.clearGetAll());
		}

		if (this.props.roles.getAllStatus == "REJECTED")
		{
			this.state.loading = false;
			this.props.dispatch(roleActions.clearGetAll());
		}

		return (
			<div className={this.props.className ? this.props.className : "" }>
				<Select
					placeholder={i18Get("Select Role", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					value={this.props.value}
					onChange={this.props.onChange}
					style={{ width: "100%" }}
					options = {this.renderOptions()}
					isMulti = {this.props.isMulti ? this.props.isMulti : false}
				/>
			</div>
		);
	}
}

Roles.propTypes = {
	roles: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	roles: store.roles,
	translations: store.translations
}))(Roles));