import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import BreadCrumb from "../../../breadcrumb";
import DebounceInput from "../../../../../../../plug/name-search-input";
import UserList from "./listUsers";
import UserCreate from "./createUser";
import UserEdit from "./editUser";

class Users extends Component {
	constructor(props) {
		super(props);
		this.state = {
			users: {},
			filterValue: "",
			totalPages: 0
		};
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Users", utils.getLanguage()), link: locations.userManagement_users });
		if (this.props.location.pathname === locations.userManagement_create_user) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: locations.create_user });
		}
		if (this.state.user.id) {
			items.push({ name: this.state.user.name, link: `${locations.userManagement_users}/${this.state.user.id}` });
		}
		return items;
	}

	onCreateClicked() {
		this.props.history.push(locations.userManagement_create_user);
	}

	filterChanged(filter) {
		const { state } = this;
		state.filterValue = filter;
		state.loading = true;
		this.setState(state);
	}

	isList() {
		if (this.props.location.pathname === locations.userManagement_users) {
			return true;
		}
		return false;
	}

	totalCount(count) {
		this.setState({ totalPages: (count || 0) });
	}

	render() {
		this.state.user = this.props.breadcrumb.componentData;
		return (
			<div className="main_overview_section">
				<div className="pole_container">
					<BreadCrumb items={this.generateItems()} showRecord totalRecords={this.state.totalPages} />
					{this.isList() && (
						<div className="pole_create_section di w_100 padding-top-15 padding-bottom-15 float_left">
							<div className="float_right">
								<DebounceInput
									isDebounce
									inputChanged={this.filterChanged.bind(this)}
								/>
							</div>
							<button className="common_button float_left margin-right-20" onClick={this.onCreateClicked.bind(this)}>
								<i className="fa fa-plus margin-right-5" aria-hidden="true" />
&nbsp;
								<I18 tkey="CREATE" />
							</button>
						</div>
					)}
					<Switch>
						<Route exact path={locations.userManagement_create_user} component={UserCreate} />
						<Route exact path={locations.userManagement_edit_user} component={UserEdit} />
						<Route path={locations.userManagement_users} render={() => <UserList filterValue={this.state.filterValue} totalCount={this.totalCount.bind(this)} />} />
					</Switch>
				</div>
			</div>
		);
	}
}

Users.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(Users));
