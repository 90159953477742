import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import locations from "../../../../../../router/locations";
import InterventionDetailV5 from "./v5";
import interventionAction from "../../../../../../actions/intervention";
import assignStepsActions from "../../../../../../actions/assign-steps";
import TopSection from "./top-section";
import TopDetailsSection from "./top-details-section";
import TotalSection from "./total-section";
import Loader from "../../../../../../plug/loader";
import "./details.css";
import screenId from "../../../../../../router/screenIdList";
import eventNames from "../../../../../../actions/events";
import BreadCrumb from "../../breadcrumb";

class InterventionDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showAnaesthesia: true,
			showPrice: true,
			showStaff: true,
			showCostIntervention: false,
			doctorId: false,
			showAssignSteps: false,
			intervention: {},
			key: 1,
			isMapped: false,
			isLoadingFirstTime: true,
			anaesthesiaDetail: { showDetailedAnesthesia: true, minimal: [] },
			isLoaded: true,
			invalidReAssignMOM: {},
			newMomdata: "",
			assignComplete: false,
			classOrComplete: "STANDARD",
			defaultValueUpdated: false,
			selectedHospital: "",
			status: "ALL"
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: ((locations.timeSheets === `/${this.props.match.params.sheets}`) ? screenId.timeSheetDetails : ((locations.scannedTreatments === `/${this.props.match.params.sheets}`) ? screenId.scannedTreatmentDetails : screenId.analyticalSheetDetails)) });
		this.state.isLoadingFirstTime = true;
		this.state.organizationMode = (locations.analyticalSheets === "/" + this.props.match.params.sheets) ? "BASIC" : "EXTENDED";
		this.state.doctorId = utils.checkForParameter("doctorId", this.props.location);
		this.onFetchData(this.state.doctorId);
	}

	onFetchData() {
		const payload = {
			showAnaesthesia: this.state.showAnaesthesia,
			showPrice: this.state.showPrice,
			showStaff: this.state.showStaff,
			cost: true,
			id: this.props.match.params.id,
			organization: this.props.match.params.organization,
			organizationMode: this.state.organizationMode,
			category: this.state.classOrComplete
		};
		this.props.dispatch(interventionAction.getOneIntervention(payload.id, new Date().getTimezoneOffset()));
	}

	reAssignMomClicked() {
		const prevState = this.state;
		prevState.reAssignMom = true;
		prevState.assignComplete = false;
		this.setState(prevState);
	}

	filterClicked(value) {
		const prevState = this.state;
		prevState[value] = !this.state[value];
		this.onFetchData(this.state.doctorId);
		this.setState(prevState);
	}

	statusChanged(e) {
		const prevState = this.state;
		prevState.status = e;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.onFetchData(e);
	}

	hospitalChanged(e) {
		const prevState = this.state;
		prevState.selectedHospital = e;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.onFetchData(e);
	}

	costIntervention() {
		const prevState = this.state;
		prevState.showCostIntervention = true;
		this.setState(prevState);
	}

	copyFromIntervention() {
		const prevState = this.state;
		prevState.copyFromIntervention = true;
		this.setState(prevState);
	}

	copyToIntervention() {
		const prevState = this.state;
		prevState.copyToIntervention = true;
		this.setState(prevState);
	}

	toggleAssignSteps() {
		const prevState = this.state;
		if (!this.state.showAssignSteps) {
			const queryString = this.state.doctorId ? `doctorId=${this.state.doctorId}` : "";
			this.props.dispatch(assignStepsActions.getElementMapping(this.props.match.params.id, queryString));
		}
		prevState.showAssignSteps = !this.state.showAssignSteps;
		prevState.intervention.hasPendingMappings = false;
		prevState.isMapped = true;
		this.setState(prevState);
	}

	onNewMOMSelectChange(data) {
		const prevState = this.state;
		prevState.invalidReAssignMOM.invalidMOM = false;
		prevState.newMomdata = data;
		this.setState(prevState);
	}

	handleCancel() {
		const prevState = this.state;
		prevState.intervention.hasPendingMappings = false;
		prevState.showCostIntervention = false;
		prevState.copyFromIntervention = false;
		prevState.copyToIntervention = false;
		prevState.newMomdata = "";
		prevState.reAssignMom = false;
		prevState.isMapped = true;
		prevState.invalidReAssignMOM = {};
		this.setState(prevState);
	}

	closeCopy() {
		this.onFetchData(this.state.doctorId);
		this.state.increasekeyOnFetchSuccess = true;
		this.handleCancel();
	}

	changeDoctor(e, doctorName) {
		const prevState = this.state;
		prevState.doctorId = e;
		prevState.doctorName = doctorName;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.onFetchData(e);
	}

	setDoctor(doctorName) {
		const prevState = this.state;
		prevState.doctorName = doctorName;
		this.setState(prevState);
	}

	reloadDetails() {
		const prevState = this.state;
		prevState.increasekeyOnFetchSuccess = true;
		this.setState(prevState);
		this.onFetchData(this.state.doctorId);
	}

	reAssignNewMom() {
		if (this.validate()) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			const payload = {
				momId: this.state.newMomdata.id,
				treatmentId: this.props.match.params.id,
				organizationMode: this.state.organizationMode
			};
			this.props.dispatch(interventionAction.assignNewMOM(this.props.match.params.id, payload));
		}
	}

	afterTreatmentLinkSuccess() {
		this.state.key += 1;
		this.onFetchData();
	}

	validate() {
		const prevState = this.state;
		let valid = true;
		if (!this.state.newMomdata && !this.state.newMomdata.id) {
			prevState.invalidReAssignMOM.invalidMOM = true;
			valid = false;
		}
		if (!valid) {
			this.setState(prevState);
			return false;
		}
		return true;
	}

	generateItems() {
		const items = [];
		items.push({
			name: i18Get("Intervention", utils.getLanguage()),
			link: `${locations.interventions_plain}/${this.props.match.params.organization}/${this.props.match.params.sheets}`
		});
		if (this.state.intervention && this.state.intervention.name) {
			items.push({ name: this.state.intervention.name, link: this.props.location.pathname });
		}
		return items;
	}

	toggleClassOrComplete = (status) => {
		if (this.state.classOrComplete != status) {
			this.setState({ classOrComplete: status }, () => {
				this.onFetchData(this.state.doctorId);
			});
		}
	};

	render() {
		if (this.props.intervention.getOneIntervention === "FULFILLED") {
			this.state.isLoadingFirstTime = false;
			this.state.intervention = this.props.intervention.treatment;
			if (this.child) {
				this.child.toggleIsLoaded();
			}
			if (this.state.increasekeyOnFetchSuccess) {
				this.state.key = this.state.key + 1;
				this.state.increasekeyOnFetchSuccess = false;
			}
			this.state.isLoaded = false;
			this.state.defaultValueUpdated = true;
			this.props.dispatch(interventionAction.clearGetOneIntervention());
		}
		if (this.props.intervention.getOneIntervention === "REJECTED") {
			this.props.dispatch(interventionAction.clearGetOneIntervention());
			this.props.history.push(`${locations.interventions_plain}/${this.props.match.params.organization}/${this.props.match.params.sheets}${this.props.location.search}`);
		}
		if (this.props.intervention.assignNewMOMCompleted === "FULFILLED") {
			this.state.assignComplete = true;
			this.state.key += 1;
			this.onFetchData();
			this.handleCancel();
			this.props.dispatch(interventionAction.clearAssignNewMOM());
		}
		if (this.props.intervention.assignNewMOMCompleted === "REJECTED") {
			this.props.dispatch(interventionAction.clearAssignNewMOM());
		}
		if (!this.props.intervention.getOneIntervention && this.state.isLoadingFirstTime) {
			return (<Loader loading />);
		}

		return (
			<div className="main_overview_section intervention_details">
				<div className="position_relative w_100 di">
					<div className="eye_button_section w_100 padding-top-15">
						<div className="clinical_pathway_breadcrumb">
							<BreadCrumb items={this.generateItems()} useSearch />
						</div>
						<div className="row">
							<TopDetailsSection
								intervention={this.state.intervention}
								doctorName={this.state.doctorName} />
						</div>
					</div>
					{this.state.intervention && (this.state.intervention.hasPendingMappings && !this.state.isMapped) && (locations.scannedTreatments !== `/${this.props.match.params.sheets}`) &&
						<div className="toast_div di w_100"><I18 tkey="There are consumables without the type, hence all items are not listed here. You can assign the item using 'Assign Steps' button." />
							<I18 tkey="You can assign the consumables using a simplified method if you click on the button 'Yes'. You can always use the 'Assign steps' button to refine the assignment anytime." />
							<div className="margin-top-10 text_align_right"><button className="margin-right-10" type="button" onClick={this.handleCancel.bind(this)}><I18 tkey='No' /></button><button type="button" onClick={this.toggleAssignSteps.bind(this)}><I18 tkey='Yes' /></button></div>
						</div>}
					<TopSection
						setDoctor={this.setDoctor.bind(this)}
						changeDoctor={this.changeDoctor.bind(this)}
						statusChanged={this.statusChanged.bind(this)}
						hospitalChanged={this.hospitalChanged.bind(this)}
						selectedHospital={this.state.selectedHospital}
						reloadDetails={this.reloadDetails.bind(this)}
						afterTreatmentLinkSuccess={this.afterTreatmentLinkSuccess.bind(this)}
						interventionData={this.state.intervention}
						onRef={ref => (this.child = ref)} />
					<Switch>
						<Route exact path={locations.interventionDetailsv5} render={() => <InterventionDetailV5 category={this.state.classOrComplete} key={this.state.key} showStaff={this.state.showStaff} showPrice={this.state.showPrice} showAnaesthesia={this.state.showAnaesthesia} doctorId={this.state.doctorId} anaesthesiaDetail={this.state.anaesthesiaDetail} typeActivity={this.props.typeActivity} status={this.state.status} hospitalId={this.state.selectedHospital ? this.state.selectedHospital.value : ""} />} />
					</Switch>
					{<TotalSection
						defaultValueUpdated={this.state.defaultValueUpdated}
						changeupdateState={() => this.setState({ defaultValueUpdated: false })}
						//  key={this.state.key} showAnaesthesia={this.state.showAnaesthesia} 
						interventionName={this.state.intervention && this.state.intervention.name ? this.state.intervention.name : ""}
						isDefault={true}
						defaultValue={this.state.intervention.defaultValue}
					/>}
				</div>
			</div>
		);
	}

	componentDidUpdate() {
		if (this.props.intervention.updateTotalDefaultCostComplete === "FULFILLED") {
			this.props.dispatch(interventionAction.clearUpdateTotalDefaultCost());
			this.onFetchData(this.state.doctorId);
		}
	}
}

InterventionDetail.propTypes = {
	intervention: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	settings: PropTypes.object.isRequired,
	scope: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	parcours: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	intervention: store.intervention,
	settings: store.settings,
	scope: store.scope,
	user: store.user,
	translations: store.translations,
	parcours: store.parcours
}))(InterventionDetail));