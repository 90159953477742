import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const fetchAllSterilization = (queryString) => ({
	type: events.FETCH_ALL_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilizationClass?${queryString}`)
});

const clearFetchAllSterilization = () => ({
	type: events.CLEAR_FETCH_ALL_STERILIZATION,
	payload: {}
});

const fetchOneSterilization = (id) => ({
	type: events.FETCH_ONE_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilization/${id}`)
});

const clearFetchOneSterilization = () => ({
	type: events.CLEAR_FETCH_ONE_STERILIZATION,
	payload: {}
});

const createSterilization = (payload) => ({
	type: events.CREATE_STERILIZATION,
	payload: axios.post(`${Config.apiRootSecure}/sterilization`, { ...payload })
});

const clearCreateSterilization = () => ({
	type: events.CLEAR_CREATE_STERILIZATION,
	payload: {}
});

const updateSterilization = (id, payload) => ({
	type: events.UPDATE_STERILIZATION,
	payload: axios.put(`${Config.apiRootSecure}/sterilization/${id}`, { ...payload })
});

const clearUpdateSterilization = () => ({
	type: events.CLEAR_UPDATE_STERILIZATION,
	payload: {}
});

const deleteSterilization = (id) => ({
	type: events.DELETE_STERILIZATION,
	payload: axios.delete(`${Config.apiRootSecure}/sterilization/${id}`)
});

const clearDeleteSterilization = () => ({
	type: events.CLEAR_DELETE_STERILIZATION,
	payload: {}
});

const fetchFamilyForSterilization = (queryString) => ({
	type: events.FETCH_FAMILY_FOR_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilization/family?${queryString}`)
});

const clearFetchFamilyForSterilization = () => ({
	type: events.CLEAR_FETCH_FAMILY_FOR_STERILIZATION,
	payload: {}
});

const fetchAllClassSterilization = (queryString) => ({
	type: events.FETCH_ALL_CLASS_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilizationClass?${queryString}`)
});

const clearFetchAllClassSterilization = () => ({
	type: events.CLEAR_FETCH_ALL_CLASS_STERILIZATION,
	payload: {}
});

const createClassSterilization = (payload) => ({
	type: events.CREATE_CLASS_STERILIZATION,
	payload: axios.post(`${Config.apiRootSecure}/sterilizationClass`, payload)
});

const createClassSterilizationCompleted = () => ({
	type: events.CLEAR_CREATE_CLASS_STERILIZATION,
	payload: ""
});

const getOneClassSterilization = (id) => ({
	type: events.FETCH_ONE_CLASS_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilizationClass/${id}`)
});

const clearOneClassSterilization = () => ({
	type: events.CLEAR_FETCH_ONE_CLASS_STERILIZATION,
	payload: ""
});

const updateClassSterilization = (id, payload) => ({
	type: events.UPDATE_CLASS_STERILIZATION,
	payload: axios.put(`${Config.apiRootSecure}/sterilizationClass/${id}`, payload)
});

const updateClassSterilizationCompleted = () => ({
	type: events.CLEAR_UPDATE_CLASS_STERILIZATION,
	payload: ""
});

const deleteClassSterilization = (id) => ({
	type: events.DELETE_CLASS_STERILIZATION,
	payload: axios.delete(`${Config.apiRootSecure}/sterilizationClass/${id}`)
});

const deleteClassSterilizationCompleted = () => ({
	type: events.CLEAR_DELETE_CLASS_STERILIZATION,
	payload: ""
});

const getClassList = (query) => ({
	type: events.GET_CLASS_LIST,
	payload: axios.get(`${Config.apiRootSecure}/minimal/sterilizationClass${query}`)
});

const clearClassList = () => ({
	type: events.CLEAR_CLASS_LIST,
	payload: ""
});

const getConditionment = () => ({
	type: events.GET_CONDITIONMENT_LIST,
	payload: axios.get(`${Config.apiRootSecure}/conditionment`)
});

const clearConditionment = () => ({
	type: events.CLEAR_CONDITIONMENT_LIST,
	payload: ""
});

const copySterilzation = (id, data) => ({
	type: events.COPY_PAST_STERILIZATION,
	payload: axios.put(`${Config.apiRootSecure}/sterilization/copy/${id}`, data)
});

const copySterilzationCompleted = () => ({
	type: events.COPY_PAST_STERILIZATION_COMPLETED,
	payload: ""
});

const getAssignSterilizationClassList = (query) => ({
	type: events.ASSIGN_STERILIZATION_CLASS_LIST,
	payload: axios.get(`${Config.apiRootSecure}/sterilizationClass/sterilization/list${query}`)
});

const getAssignSterilizationClassListCompleted = () => ({
	type: events.ASSIGN_STERILIZATION_CLASS_LIST_COMLETED,
	payload: ""
});

const assignSterilizationClass = (id, data) => ({
	type: events.ASSIGN_STERILIZATION_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/sterilizationClass/${id}/sterilization/copy`, data)
});

const assignSterilizationClassCompleted = () => ({
	type: events.ASSIGN_STERILIZATION_CLASS_COMPLETED,
	payload: ""
});

const getConsomableSpeciality = (queryString) => ({
	type: events.GET_CONSUMABLE_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality${queryString}`)
});

const clearConsomableSpeciality = () => ({
	type: events.CLEAR_CONSUMABLE_SPECIALITY,
	payload: ""
});

const createConsomableSpeciality = (data) => ({
	type: events.CREATE_CONSUMABLE_SPECIALITY,
	payload: axios.post(`${Config.apiRootSecure}/consumableSpeciality`, data)
});

const createConsomableSpecialityCompleted = () => ({
	type: events.CREATE_CONSUMABLE_SPECIALITY_COMPLETED,
	payload: ""
});

const fetchOneConsomableSpeciality = (id) => ({
	type: events.GET_ONE_CONSOMABLE_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality/${id}`)
});

const fetchOneConsomableSpecialityCompleted = () => ({
	type: events.GET_ONE_CONSOMABLE_SPECIALITY_COMPLETED,
	payload: ""
});

const updateConsomableSpecialty = (id, payload) => ({
	type: events.UPDATE_CONSOMABLE_SPECIALTY,
	payload: axios.put(`${Config.apiRootSecure}/consumableSpeciality/${id}`, payload)
});

const updateConsomableSpecialtyCompleted = () => ({
	type: events.CLEAR_UPDATE_CONSOMABLE_SPECIALTY,
	payload: {}
});

const deleteConsomableSpecialty = (id) => ({
	type: events.DELETE_CONSOMABLE_SPECIALTY,
	payload: axios.delete(`${Config.apiRootSecure}/consumableSpeciality/${id}`)
});

const deleteConsomableSpecialtyCompleted = () => ({
	type: events.DELETE_CONSOMABLE_SPECIALTY_COMPLETED,
	payload: {}
});

const getAllSterilizationSpeciality = (queryString) => ({
	type: events.GET_ALL_STERILIZATION_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality${queryString}`)
});

const getAllSterilizationSpecialityCompleted = () => ({
	type: events.GET_ALL_STERILIZATION_SPECIALITY_COMPLETED,
	payload: ""
});

const saveAndApproveConsumableSpeciality = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_SPECIALITY,
	payload: axios.put(`${Config.apiRootSecure}/consumableSpeciality/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveConsumableSpecialityCompleted = () => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED,
	payload: {}
});

const assignAndApproveConsumableSpeciality = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY,
	payload: axios.put(`${Config.apiRootSecure}/consumableSpeciality/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveConsumableSpecialityCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED,
	payload: {}
});

const getOneConsomableSpeciality = (id) => ({
	type: events.FETCH_ONE_CONSOMABLE_SPECIALITY,
	payload: axios.get(`${Config.apiRootSecure}/consumableSpeciality/${id}`)
});

const getOneConsomableSpecialityCompleted = () => ({
	type: events.FETCH_ONE_CONSOMABLE_SPECIALITY_COMPLETED,
	payload: ""
});

const saveAndApproveSterilizationClass = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/sterilizationClass/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveSterilizationClassCompleted = () => ({
	type: events.SAVE_AND_APPROVE_STERILIZATION_CLASS_COMPLETED,
	payload: {}
});

const assignAndApproveSterilizationClass = (id, payload) => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_CLASS,
	payload: axios.put(`${Config.apiRootSecure}/sterilizationClass/${id}/assignAndApprove`, { ...payload })
});

const assignAndApproveSterilizationClassCompleted = () => ({
	type: events.ASSIGN_AND_APPROVE_STERILIZATION_CLASS_COMPLETED,
	payload: {}
});

const fetchOneClassSterilization = (id) => ({
	type: events.GET_ONE_CLASS_STERILIZATION,
	payload: axios.get(`${Config.apiRootSecure}/sterilizationClass/${id}`)
});

const clearFetchOneClassSterilization = () => ({
	type: events.CLEAR_GET_ONE_CLASS_STERILIZATION,
	payload: ""
});

export default {
	fetchAllSterilization,
	clearFetchAllSterilization,
	fetchOneSterilization,
	clearFetchOneSterilization,
	createSterilization,
	clearCreateSterilization,
	updateSterilization,
	clearUpdateSterilization,
	deleteSterilization,
	clearDeleteSterilization,
	fetchFamilyForSterilization,
	clearFetchFamilyForSterilization,
	fetchAllClassSterilization,
	clearFetchAllClassSterilization,
	createClassSterilization,
	createClassSterilizationCompleted,
	getOneClassSterilization,
	clearOneClassSterilization,
	updateClassSterilization,
	updateClassSterilizationCompleted,
	deleteClassSterilization,
	deleteClassSterilizationCompleted,
	getClassList,
	clearClassList,
	getConditionment,
	clearConditionment,
	copySterilzation,
	copySterilzationCompleted,
	getAssignSterilizationClassList,
	getAssignSterilizationClassListCompleted,
	assignSterilizationClass,
	assignSterilizationClassCompleted,
	getConsomableSpeciality,
	clearConsomableSpeciality,
	createConsomableSpeciality,
	createConsomableSpecialityCompleted,
	fetchOneConsomableSpeciality,
	fetchOneConsomableSpecialityCompleted,
	updateConsomableSpecialty,
	updateConsomableSpecialtyCompleted,
	getOneConsomableSpeciality,
	getOneConsomableSpecialityCompleted,
	deleteConsomableSpecialty,
	deleteConsomableSpecialtyCompleted,
	getAllSterilizationSpeciality,
	getAllSterilizationSpecialityCompleted,
	saveAndApproveConsumableSpeciality,
	saveAndApproveConsumableSpecialityCompleted,
	assignAndApproveConsumableSpeciality,
	assignAndApproveConsumableSpecialityCompleted,
	saveAndApproveSterilizationClass,
	saveAndApproveSterilizationClassCompleted,
	assignAndApproveSterilizationClass,
	assignAndApproveSterilizationClassCompleted,
	fetchOneClassSterilization,
	clearFetchOneClassSterilization
};
