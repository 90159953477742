import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import { DebounceInput } from "react-debounce-input";
import BreadCrumb from "../../breadcrumb";
import ClinicalPathwayRoute from "./route";
import { Button } from "antd";
import StepDropdown from "./step-dropdown";
import "./Administer_Personnel.css";
import StatusDropDown from "../../intervention/statusDropDown";
import HospitalSelect from "../../administration/activities/specialities/selectHospital";
import events from "../../../../../../actions/events";
import adminClinicalPathwayActions from "../../../../../../actions/admin-clinical-pathway";
import SectionTypeDropDown from "./section-type-dropdown";
import { FilterOutlined } from "@ant-design/icons";

class ClinicalPathway extends Component {
	constructor(props) {
		super(props);
		this.state = {
			adminClinicalPathwayData: {},
			tab: locations.tabClinicalPathwayProblem,
			search: "",
			count: 0,
			step: null,
			subStep: null,
			showFilter: false,
			selectedHospital: "",
			selectedStatus: "ALL",
			enableReorder: false,
			clinicalPathwayType: ["SURGERY"],
			reorderList: [],
			isReOrderUpdating: false,
			reorderFullList: []
		};
	}

	UNSAFE_componentWillMount() {
		this.state.tab = this.props.location.pathname.startsWith(`${locations.administration_clinical_pathway}`) ? locations.tabClinicalPathwayProblem : locations.tabClinicalPathwaySubStep;
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Clinical Pathway", utils.getLanguage()), link: `${locations.administration_clinical_pathway}/${this.state.tab}` });
		items.push({ name: i18Get((this.state.tab.charAt(0).toUpperCase() + this.state.tab.slice(1)), utils.getLanguage()), link: `${locations.administration_clinical_pathway}/${this.state.tab}` });
		if (this.props.location.pathname === `${locations.administration_clinical_pathway}/${this.state.tab}/create`) {
			items.push({ name: i18Get("Create", utils.getLanguage()), link: `${locations.administration_clinical_pathway}/${this.state.tab}/create` });
		}
		if (this.props.location.pathname === `${locations.administration_clinical_pathway}/${this.state.tab}/${this.state.adminClinicalPathwayData.id}`) {
			items.push({ name: this.state.adminClinicalPathwayData.name, link: `${locations.administration_clinical_pathway}/${this.state.tab}/${this.state.adminClinicalPathwayData.id}` });
		}
		return items;
	}

	searchChanged(e) {
		const prevState = this.state;
		prevState.search = e.target.value ? e.target.value.trim() : "";
		this.setState(prevState);
	}

	tabChanged(link) {
		if (link) {
			const prevState = this.state;
			prevState.tab = link;
			prevState.search = "";
			prevState.count = 0;
			prevState.enableReorder = false;
			prevState.isReOrderUpdating = false;
			this.setState(prevState);
			this.props.history.push(locations.administration_clinical_pathway + "/" + link);
		}
	}

	addClicked() {
		this.props.history.push(`${this.props.location.pathname}/create${this.props.location.search}`);
	}

	setFilter() {
		let query = `${(this.state.step && this.state.step.value) || this.state.clinicalPathwayType ? "?" : ""}`;
		query += `${this.state.step && this.state.step.value ? `stepId=${this.state.step.value}&step=${this.state.step.label}&` : ""}`;
		query += `${this.state.clinicalPathwayType ? `clinicalPathwayType=${this.state.clinicalPathwayType}` : ""}`;
		query += `${this.state.subStep && this.state.subStep.value ? `subStepId=${this.state.subStep.value}&subStep=${this.state.subStep.label}&` : ""}`;
		this.props.history.push(this.props.location.pathname + query);
	}

	stepChanged(e) {
		this.setState({ step: e, subStep: null }, this.setFilter);
	}

	subStepChanged(e) {
		this.setState({ subStep: e }, this.setFilter);
	}

	selectedClinicalPathwayType(e) {
		this.setState({ clinicalPathwayType: e, step: null, subStep: null }, this.setFilter);
	}

	getCount(count) {
		this.setState({ count: count });
	}

	showContent() {
		if (this.props.location.pathname === `${locations.administration_clinical_pathway}` ||
			this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}` ||
			this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}` ||
			this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}` ||
			this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}`
		) {
			return true;
		}
		return false;
	}

	disableCreate = () => {
		if (this.props.permissions.userPermission && this.props.permissions.userPermission.CLINICAL_PATHWAY["CREATE"]) {
			return (
				(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`) && (!this.state.step || !this.state.step.value)
			);
		}
		return true;
	};

	filterClicked() {
		this.setState({ showFilter: !this.state.showFilter });
	}

	onHospitalChanged(e) {
		this.setState({ selectedHospital: e ? e : "" });
	}

	statusChanged = (e) => {
		this.setState({ selectedStatus: e ? e : "" });
	};

	clearFilter() {
		this.setState({ selectedHospital: "", selectedStatus: "ALL", filter: "", search: "", sectionType: utils.sameDropper() });
	}

	reorderList(list, type) {
		this.setState({ reorderList: list, type: type });
	}
	reorderFullList(list) {
		this.setState({ reorderFullList: list });
	}
	selectedSectionType(e) {
		const prevState = this.state;
		prevState.sectionType = e;
		this.setState(prevState);
	}

	arrangeReorderData() {
		const prevState = this.state;
		this.state.reorderList.map((e, i) => {
			prevState.reorderFullList[i] = e;
		});
		this.setState({ prevState });
		return prevState.reorderFullList;
	}

	onClickSaveOrder = () => {
		if (this.state.reorderList && this.state.reorderList.length < 1) {return;}
		const reorderData = this.arrangeReorderData();
		const payload = reorderData.map((value, index) => {
			return { id: value.id ? value.id : value.clpSubStepId, code: value.code, rank: index + 1 };
		});
		if (payload && payload.length > 0) {
			this.setState({ isReOrderUpdating: true });
			if (this.state.type === "stepList") {
				this.props.dispatch(
					adminClinicalPathwayActions.updateStepRank({ reOrderedData: payload, clinicalPathwayType: this.state.clinicalPathwayType })
				);
			}
			else if (this.state.type === "subStepList") {
				this.props.dispatch(
					adminClinicalPathwayActions.updateSubStepRank({ reOrderedData: payload, clpStepId: this.state.step.value, clpStepCode: this.state.step.code })
				);
			}
			else {
				this.props.dispatch(
					adminClinicalPathwayActions.updateProblemListOrder({ reOrderedData: payload })
				);
			}
		}
	};
	getFilter() {
		return (
			<div className="row margin-top-15 z-index-11">
				<div className="col-sm-3">
					<div className="form-group">
						<span><I18 tkey="Status" />:</span>
						<StatusDropDown
							data={this.state.selectedStatus}
							onStatusChange={this.statusChanged.bind(this)} />
					</div>
				</div>
				<div className="col-sm-3">
					<div className="form-group">
						<span><I18 tkey="Hospital" />:</span>
						<HospitalSelect
							data={this.state.selectedHospital}
							isAdminClp={true}
							hospitalChanged={this.onHospitalChanged.bind(this)} />
					</div>
				</div>
				{this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}` &&
					<div className="col-sm-3">
						<div className="form-group">
							<span><I18 tkey="Type" />:</span>
							<SectionTypeDropDown
								className="1"
								sectionType={this.state.sectionType}
								selectedSectionType={this.selectedSectionType.bind(this)}
								allowClear={true}
							/>
						</div>
					</div>}
				<div className="col-sm-3">
					<div className="clear_fliter text_align_right">
						<a className="clear_filter_a filter_top_align" onClick={this.clearFilter.bind(this)}>
							<I18 tkey='Clear Filter' />
						</a>
					</div>
				</div>
			</div>
		);
	}
	onEnableReorder = () => {
		this.setState({ enableReorder: true });
	};

	disableReorder = () => {
		this.setState({ enableReorder: false, isReOrderUpdating: false });
	};

	isPermissionGranted = (method) => {
		if (this.props.permissions.userPermission && this.props.permissions.userPermission.CLINICAL_PATHWAY[method]) {
			return true;
		}
		return false;
	};

	render() {
		this.state.adminClinicalPathwayData = this.props.breadcrumb.componentData;
		if (this.props.adminClinicalPathway.filterRefresh) {
			this.setState({ subStep: "", step: "" }, this.setFilter);
			this.props.dispatch({ type: events.CLEAR_REFRESH_FILTERS, payload: {} });
		}
		if (this.props.adminClinicalPathway.refreshSubStep) {
			this.setState({ subStep: "" }, this.setFilter);
			this.props.dispatch({ type: events.CLEAR_REFRESH_SUB_STEP, payload: {} });
		}

		if (this.props.adminClinicalPathway.isUpdateProblemReorder === "FULFILLED") {
			this.disableReorder();
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateProblemListOrder());
		}
		if (this.props.adminClinicalPathway.isUpdateProblemReorder === "REJECTED") {
			this.setState({ isReOrderUpdating: false });
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateProblemListOrder());
		}
		if (this.props.adminClinicalPathway.isStepRankUpdateComplete === "FULFILLED") {
			this.disableReorder();
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateStepRank());
		}
		if (this.props.adminClinicalPathway.isStepRankUpdateComplete === "REJECTED") {
			this.setState({ isReOrderUpdating: false });
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateStepRank());
		}
		if (this.props.adminClinicalPathway.isSubStepRankUpdateComplete === "FULFILLED") {
			this.disableReorder();
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateSubStepRank());
		}
		if (this.props.adminClinicalPathway.isSubStepRankUpdateComplete === "REJECTED") {
			this.setState({ isReOrderUpdating: false });
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateSubStepRank());
		}

		return (
			<div className="personal_table_scroll">
				<div className="main_overview_section">
					<div className="bloc_container clinical_pathway_administration_container">
						<BreadCrumb
							useSearch
							items={this.generateItems()}
							showRecord={this.showContent()}
							totalRecords={this.state.count} />
						{this.showContent() ?
							<div className="main_overview_top_section di w_100 padding-top-15">
								<div className="d-flex align-items-center justify-content-between">
									<button
										className="common_button"
										type="button"
										disabled={this.disableCreate()}
										onClick={this.addClicked.bind(this)}>
										<i className="fa fa-plus margin-right-8" aria-hidden="true"></i>
										<I18 tkey="CREATE" />
									</button>
									<div className="d-flex align-items-center">
										<div className="min_width_300">
											<DebounceInput
												className="filter_input_design form-control"
												debounceTimeout={750}
												placeholder={i18Get("Search...", utils.getLanguage())}
												value={this.state.search}
												onChange={this.searchChanged.bind(this)} />
										</div>
										<div className="filter  margin-left-15" onClick={this.filterClicked.bind(this)}>
											<FilterOutlined className={`margin-top-neg-3 ${this.state.showFilter ? "filter_icon active" : "filter_icon"}`} type="filter" />
										</div>
									</div>
								</div>
							</div> : ""}
						{this.showContent() ?
							this.state.showFilter && this.getFilter()
							: ""}
						<div className="di w_100 clinical_pathway_administration_table_tab_container">
							<div className="tab_section padding-top-15 h-100">
								{this.showContent() ?
									<ul className="nav nav-tabs">
										<li className={(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayProblem}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabClinicalPathwayProblem)}>
												<I18 tkey="Problem" />
											</a>
										</li>
										<li className={(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabClinicalPathwayStep)}>
												<I18 tkey="Step" />
											</a>
										</li>
										<li className={(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabClinicalPathwaySubStep)}>
												<I18 tkey="Sub Step" />
											</a>
										</li>
										<li className={(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}`) ? "active" : ""}>
											<a data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, locations.tabClinicalPathwayItems)}>
												<I18 tkey="Items" />
											</a>
										</li>
										<li className="Administer_Personnel_perent_li_width margin-left-9 float_right_i">
											{this.props.location.pathname !== `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayItems}` &&
												<li className="padding-left-5">
													{!this.state.enableReorder ?
														<Button className="float_right"
															onClick={() => this.setState({ enableReorder: true })}><I18 tkey="Enable Reorder" />
														</Button>
														:
														<React.Fragment>
															<Button className="margin-left-10 float_right"
																disabled={this.state.isReOrderUpdating} onClick={this.disableReorder}><I18 tkey="Cancel Order" />
															</Button>
															<Button className="float_right"
																loading={this.state.isReOrderUpdating} onClick={this.onClickSaveOrder}>
																<span className="margin-left-10" >
																	<I18 tkey="Save Order" />
																</span>
															</Button>
														</React.Fragment>
													}
												</li>
											}
											{/* {this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwayStep}` || this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`
												? <li className="Administer_Personnel_li_width padding-left-5 w-220-px">
													<ClinicalPathwayTypeDropdown
														className="w_100_imp padding-4"
														clinicalPathwayType={this.state.clinicalPathwayType}
														selectedClinicalPathwayType={this.selectedClinicalPathwayType.bind(this)} />
												</li> : ''} */}
											{(this.props.location.pathname === `${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`) ?
												<li className="Administer_Personnel_li_width padding-left-5 w-220-px">
													<StepDropdown
														className="padding-4 z-index-11"
														data={this.state.step}
														isRank={true}
														clinicalPathwayType={this.state.clinicalPathwayType && (this.state.clinicalPathwayType !== "ALL") ? this.state.clinicalPathwayType : ""}
														stepChanged={this.stepChanged.bind(this)} />
												</li> : ""}
										</li>
									</ul> : ""}
								<ClinicalPathwayRoute
									search={this.state.search}
									status={this.state.selectedStatus}
									hospitalId={this.state.selectedHospital && this.state.selectedHospital.value ? this.state.selectedHospital.value : null}
									tab={this.state.tab}
									clinicalPathwayType={this.state.clinicalPathwayType}
									step={this.state.step ? this.state.step : null}
									subStep={this.state.subStep && this.state.subStep.value ? this.state.subStep.value : null}
									getCount={this.getCount.bind(this)}
									onRef={ref => this.list = ref}
									enableReorder={this.state.enableReorder}
									reorderList={this.reorderList.bind(this)}
									reorderFullList={this.reorderFullList.bind(this)}
									sectionType={this.state.sectionType}
									isPermissionGranted={this.isPermissionGranted}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ClinicalPathway.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	adminClinicalPathway: store.adminClinicalPathway,
	breadcrumb: store.breadcrumb,
	permissions: store.permissions
}))(ClinicalPathway));                    