import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import locations from "../../../../../router/locations";
import Staff from "../elements/staff";
import Elements from "../elements/elements";
import anaesthesiaActions from "../../../../../actions/anaesthesia";
import screenId from "../../../../../router/screenIdList";
import typeActivity from "../../../../../constants/typeActivity.json";

class EditAnaesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			anaesthesia: "Anaesthesia Details"
		};
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(anaesthesiaActions.getOneAnaesthesia(this.props.match.params.id, this.props.match.params.organization));
	}

	render() {
		if (this.props.anaesthesia.fetchOneAnaesthesiaComplete === "FULFILLED") {
			this.state.anaesthesia = this.props.anaesthesia.selectedAnaesthesia.name;
			this.props.dispatch(anaesthesiaActions.clearGetOneAnaesthesia());
		}
		return (
			<div className="main_overview_section">
				<Switch>
					<Route path={locations.anaesthesia_edit + locations.staff} render={() => <Staff typeActivity={typeActivity.ANAESTHESIA.toUpperCase()} details={this.state.anaesthesia} hideColumn />} />
					<Route path={locations.anaesthesia_edit + locations.medicaldevice} render={() => <Elements type="MEDICAL_DEVICE" details={this.state.anaesthesia} hideColumn screenId={screenId.anaesthesiaEditMedicalDeviceList} EditScreenId={screenId.anaesthesiaEditMedicalDeviceEdit} />} />
					<Route path={locations.anaesthesia_edit + locations.pharmacy} render={() => <Elements type="PHARMACY" details={this.state.anaesthesia} hideColumn screenId={screenId.anaesthesiaEditPharmacyList} EditScreenId={screenId.anaesthesiaEditPharmacyEdit} />} />
				</Switch>
			</div>
		);
	}
}

EditAnaesthesia.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	anaesthesia: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	anaesthesia: store.anaesthesia,
	translations: store.translations
}))(EditAnaesthesia));
