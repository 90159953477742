import eventNames from "../../actions/events";

const initialTranslationsState = {
	isFetchAllExtElementDataCompleted: false,
	extElementList: [],
	isExtElementDeleteComplete: false,
	isDeleteSuccess: {},
	isExtElementUpdateComplete: false,
	updatedExtElement: {},
	isFetchAllExtElementForTypeCompleted: false,
	extElementTypeList: [],
	isExtElementCreateComplete: false,
	createdExtElement: {},
	isRankUpdateComplete: false,
	rank: {},
	isFetchOneExtElementComplete: false,
	selectedExtElement: {},
	listUpdate: false
};

export default function reducer(state = initialTranslationsState, action) {
	switch (action.type) {
		case `${eventNames.FETCH_ALL_EXT_ELEMENT_DATA}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllExtElementDataCompleted: "FULFILLED",
					extElementList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllExtElementDataCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXT_ELEMENT_DATA}_REJECTED`:
			return {
				...state,
				isFetchAllExtElementDataCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXT_ELEMENT_DATA:
			return {
				...state,
				isFetchAllExtElementDataCompleted: false
			};

		case `${eventNames.DELETE_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtElementDeleteComplete: "FULFILLED",
					isDeleteSuccess: action.payload.data.data
				};
			}
			return {
				...state,
				isExtElementDeleteComplete: "FULFILLED"
			};
		case `${eventNames.DELETE_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isExtElementDeleteComplete: "REJECTED"
			};
		case eventNames.CLEAR_DELETE_EXT_ELEMENT:
			return {
				...state,
				isExtElementDeleteComplete: false
			};

		case `${eventNames.UPDATE_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtElementUpdateComplete: "FULFILLED",
					updatedExtElement: action.payload.data.data
				};
			}
			return {
				...state,
				isExtElementUpdateComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isExtElementUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_EXT_ELEMENT:
			return {
				...state,
				isExtElementUpdateComplete: false
			};

		case `${eventNames.FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllExtElementForTypeCompleted: "FULFILLED",
					extElementTypeList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllExtElementForTypeCompleted: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE}_REJECTED`:
			return {
				...state,
				isFetchAllExtElementForTypeCompleted: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE:
			return {
				...state,
				isFetchAllExtElementForTypeCompleted: false
			};

		case `${eventNames.CREATE_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isExtElementCreateComplete: "FULFILLED",
					createdExtElement: action.payload.data.data
				};
			}
			return {
				...state,
				isExtElementCreateComplete: "FULFILLED"
			};
		case `${eventNames.CREATE_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isExtElementCreateComplete: "REJECTED"
			};
		case eventNames.CLEAR_CREATE_EXT_ELEMENT:
			return {
				...state,
				isExtElementCreateComplete: false
			};

		case `${eventNames.RANK_UPDATE}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isRankUpdateComplete: "FULFILLED",
					rank: action.payload.data.data
				};
			}
			return {
				...state,
				isRankUpdateComplete: "FULFILLED"
			};
		case `${eventNames.RANK_UPDATE}_REJECTED`:
			return {
				...state,
				isRankUpdateComplete: "REJECTED"
			};
		case eventNames.CLEAR_RANK_UPDATE:
			return {
				...state,
				isRankUpdateComplete: false
			};

		case `${eventNames.FETCH_ONE_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchOneExtElementComplete: "FULFILLED",
					selectedExtElement: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchOneExtElementComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ONE_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isFetchOneExtElementComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ONE_EXT_ELEMENT:
			return {
				...state,
				isFetchOneExtElementComplete: false
			};
		case eventNames.TRIGGER_LIST_UPDATE:
			return {
				...state,
				listUpdate: true
			};
		case eventNames.CLEAR_TRIGGER_LIST_UPDATE:
			return {
				...state,
				listUpdate: false
			};
		case `${eventNames.FETCH_ALL_DOCTORS_OF_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isFetchAllDoctorsOfExtElementComplete: "FULFILLED",
					doctorList: action.payload.data.data
				};
			}
			return {
				...state,
				isFetchAllDoctorsOfExtElementComplete: "FULFILLED"
			};
		case `${eventNames.FETCH_ALL_DOCTORS_OF_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isFetchAllDoctorsOfExtElementComplete: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_ALL_DOCTORS_OF_EXT_ELEMENT:
			return {
				...state,
				isFetchAllDoctorsOfExtElementComplete: false
			};
		case `${eventNames.UPDATE_DOCTORS_OF_EXT_ELEMENT}_FULFILLED`:
			if (action.payload.data.message_code === 1) {
				return {
					...state,
					isUpdateDoctorsOfExtElementComplete: "FULFILLED",
					doctorListUpdate: action.payload.data.data
				};
			}
			return {
				...state,
				isUpdateDoctorsOfExtElementComplete: "FULFILLED"
			};
		case `${eventNames.UPDATE_DOCTORS_OF_EXT_ELEMENT}_REJECTED`:
			return {
				...state,
				isUpdateDoctorsOfExtElementComplete: "REJECTED"
			};
		case eventNames.CLEAR_UPDATE_DOCTORS_OF_EXT_ELEMENT:
			return {
				...state,
				isUpdateDoctorsOfExtElementComplete: false
			};
		default:
			return state;
	}
}
