import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../i18";
import interventionAction from "../../../../../../actions/intervention";
import utils from "../../../../../../utils/utils";
import eventNames from "../../../../../../actions/events";
import ReactTable from "../../../../../../plug/reactTable/react-table";
import "../../../../../../plug/reactTable/react-table.css";
import { Modal } from "antd";
import Select from "react-select";
import ActionButton from "../../../../../../plug/action-button";
import clinicalPathwayAction from "../../../../../../actions/clinical-pathway";
import isEqual from "lodash.isequal";
import differenceBy from "lodash.differenceby";
import unionWith from "lodash.unionwith";
import Loader from "../../../../../../plug/loader";
import BreadCrumb from "../../breadcrumb";
import { statusList } from "../../../../../../constants";
import Config from "../../../../../../MOM-config";
import { EditorState, ContentState, convertToRaw, getDefaultKeyBinding } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

class Anaesthesia extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			maxlen: [],
			type: "",
			data: [],
			deleteVisible: false,
			elementToDelete: false,
			modelVisible: false,
			name: "",
			sorted: [],
			listOffset: 0,
			listNumResults: 100,
			invalidDescription: false,
			editorState: EditorState.createEmpty()
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13 && this.state.list.length < 2) {
			this.addClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
		this.state.listOffset = 0;
		this.state.listNumResults = 100;
		this.fetchListData();
		this.onFetchData();
	}

	componentWillUnmount() {
		this.state.list = [];
		this.state.data = [];
		this.state.selectedData = "";
		this.state.type = "";
		clearTimeout(this.timer);
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	getCols() {
		const tableArray = ([
			{
				Header: "",
				id: "action",
				accessor: d => d,
				sortable: false,
				resizable: true,
				maxWidth: 60,
				Cell: row => (
					<div className="margin_left_auto test_align_center margin-bottom-0 margin-top-0 margin-left-0 margin-right-0">
						{
							row.value.status === statusList.STATUS.values.APPROVED ?
								<span className="risk_action_button text_align_center"><EditOutlined type="edit" className="edit_color" onClick={this.editClicked.bind(this, row.value)} /></span>
								: ""
						}
						{
							row.value.status === statusList.STATUS.values.APPROVED ?
								<span className="risk_action_button text_align_center margin-left-10"><DeleteOutlined type="delete" className="delete_color" onClick={this.deleteElementClicked.bind(this, row.value)} /></span>
								: ""
						}

					</div>

				)
			},
			{
				Header: i18Get("ANAESTHESIA", utils.getLanguage()),
				id: "subTreatmentName",
				accessor: d => d,
				sortable: false,
				resizable: true,
				Cell: row => (
					this.props.user.loginData && this.props.user.loginData.scope == 64 &&  this.props.user.loginData.roleType === "PATHWAY_USER" ?
						row.value && row.value ? row.value.subTreatmentName : ""
						:                     
						<a onClick={() => { this.moveToDetails(row.value); }}>
							{
								row.value && row.value ? row.value.subTreatmentName : ""
							}
						</a> 
				)
			},
			{
				Header: i18Get("DESCRIPTION", utils.getLanguage()),
				id: "description",
				accessor: d => d.description,
				sortable: false,
				resizable: true,
				Cell: row => (
					<d className="w_100 word_break">
						{row.value}
					</d>
				)
			}]);
		return tableArray;
	}

	deleteElementClicked(intervention) {
		const prevState = this.state;
		prevState.elementToDelete = intervention;
		prevState.deleteVisible = true;
		this.setState(prevState);
	}

	moveToDetails = (value) => {
		if (value.status === statusList.STATUS.values.APPROVED)
		{window.open(`${Config.siteUrl}/anaesthesia/mom/${value.subTreatmentId}`, "_blank");}
	};

	editClicked(intervention) {
		this.props.history.push(`/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}/sub_clinical_pathway/Anaesthesia/${intervention.subTreatmentId}${this.props.location.search}`);
	}

	onAssignAndApproveClick(data) {
		return () => {
			this.setState({ modelVisible: true, subTreatmentId: data.subTreatmentId, elementType: data.type });
		};
	}

	refreshCompleted = () => {
		const prevState = this.state;
		prevState.modelVisible = false;
		this.fetchListData();
		this.onFetchData();
	};

	deleteElement() {
		this.props.dispatch(interventionAction.deleteSubtreatment(this.props.match.params.id, this.state.elementToDelete.subTreatmentId));
	}

	hideModalDelete() {
		this.setState({ deleteVisible: false, elementToDelete: false });
	}

	addClicked() {
		const subTreatmentIds = [];
		if (this.state.selectedData && this.state.selectedData.value) {
			this.props.dispatch({ type: eventNames.ACTION_ONE });
			subTreatmentIds.push(this.state.selectedData.value);
			const payload = {
				subTreatmentIds: subTreatmentIds,
				typeActivity: this.props.type.toUpperCase()
			};
			this.props.dispatch(interventionAction.addSubtreatment(this.props.type, this.props.match.params.id, payload));
		}
		else {
			this.setState({ invalidDataSelected: true });
		}
	}


	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.state.data && this.state.list) {
			this.state.data = differenceBy(this.state.data, this.state.list, "id");
			this.state.data.map((data) => {
				prevState.options.push({ value: data.id, label: data.name });
			});
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onOptionsChange(e) {
		this.setState({ selectedData: e, invalidDataSelected: false });
	}

	fetchListData() {
		this.setState({ isListLoading: true });
		const queryString = "typeActivity=" + this.props.type.toUpperCase() + "&offset=" + (this.state.listOffset) + "&numResults=" + (this.state.listNumResults) + "&ignoreExisting=true" + (this.state.search ? `&name=${this.state.search}` : "") + (this.props.match.params.id ? `&clinicalPathwayId=${this.props.match.params.id}` : "");
		this.props.dispatch(clinicalPathwayAction.getInterventions(queryString));
	}

	onFetchData() {
		this.props.dispatch(clinicalPathwayAction.getClpSubTreatment(this.props.type, this.props.match.params.id, this.state.sort, this.state.desc));
	}

	generateItems() {
		const items = [];
		items.push({ name: i18Get("Clinical Pathway", utils.getLanguage()), link: `/clinical_pathway/${this.props.match.params.organization}` });
		if (this.props.selectedData) {items.push({ name: this.props.selectedData.name ? `${this.props.selectedData.hospitalName ? this.props.selectedData.hospitalName : ""} ${this.props.selectedData.name}` : i18Get("Clinical Pathway Details", utils.getLanguage()), link: `/clinical_pathway/${this.props.match.params.organization}/${this.props.match.params.id}${this.state.position ? `?position=${this.state.position}` : ""}` });}
		items.push({ name: i18Get("ANAESTHESIA", utils.getLanguage()), link: this.props.location.pathname });
		return items;
	}

	onListScroll() {
		this.state.listOffset += this.state.listNumResults;
		this.fetchListData();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.setState({ isListLoading: true });
			this.timer = setTimeout(() => {
				this.state.listOffset = 0;
				this.fetchListData(value);
			}, 700);
		}
	}

	onChange(e) {
		if (e.target.value.length <= 200) {
			this.state.description = e.target.value;
			this.setState(this.state);
		}
	}

	validateFields() {
		let valid = true;
		const prevState = this.state;
		const description = prevState.editorState.getCurrentContent().hasText() ? draftToHtml(convertToRaw(prevState.editorState.getCurrentContent())) : "";

		if (description ? !description.trim() || (description.lastIndexOf("\\") >= 0) || (description.indexOf("\"") >= 0) : false) {
			valid = false;
			prevState.invalidDescription = true;
		}

		this.setState(prevState);
		return valid;
	}

	saveClicked() {
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			genericDescription: this.state.editorState.getCurrentContent().hasText() ?  draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) : "",
			typeActivity: this.props.type.toUpperCase()
		};
		this.props.dispatch(interventionAction.updateInterventionDescription(this.props.match.params.id, payload));
	}

	onEditorStateChange(editorState) {
		const prevState = this.state;
		prevState.invalidDescription = false;
		if (this.getLength(editorState) >= utils.editorMaxLength && this.checkLastChangeType(editorState)) {
			this.setState({ editorState: this.state.editorState });
			return;
		} else {
			this.setState({ editorState: editorState });       
		}
	}

	checkLastChangeType(editorState) {
		return ((editorState.getLastChangeType() === "insert-characters") || (editorState.getLastChangeType() === "split-block"));
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	handleBeforeInput() {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength) {
			return "handled";
		}
	}

	handlePastedText(text) {
		if (text && ((this.getLength(this.state.editorState) + text.length) >= utils.editorMaxLength)) {
			return true;
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command) {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
			return "handled";
		}
		return "not-handled";
	}

	init() {
		const contentBlock = htmlToDraft(this.state.description ? this.state.description : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);        
		}
	}

	render() {
		if (this.props.clinicalPathway.fetchAllIntervention === "FULFILLED") {
			this.state.data = unionWith(this.state.data, this.props.clinicalPathway.intervention.data, isEqual);
			this.renderOptions();
			this.props.dispatch(clinicalPathwayAction.clearGetInterventions());
		}

		if (this.props.intervention.editInterventionDescriptionStatus === "FULFILLED") {
			this.props.dispatch(interventionAction.clearUpdateInterventionDescription());
			this.onFetchData();
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.intervention.createSubTreatmentStatus === "FULFILLED") {
			this.state.selectedData = "";
			this.state.data = [];
			this.state.options = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.onFetchData();
			this.fetchListData(this.state.search);
			this.props.dispatch(interventionAction.addSubtreatmentCompleted());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}

		if (this.props.clinicalPathway.fetchAllClinicalPathwaySubTreatment === "FULFILLED") {
			this.state.list = this.props.clinicalPathway.clinicalPathwaySubTreatment.subTreatments;
			this.state.description = this.props.clinicalPathway.clinicalPathwaySubTreatment && this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription ? this.props.clinicalPathway.clinicalPathwaySubTreatment.genericDescription : "";
			this.init();
			this.state.loading = false;
			this.renderOptions();
			this.props.dispatch(clinicalPathwayAction.clearGetClpSubTreatment());
		}

		if (this.props.intervention.deleteSubTreatmentStatus === "FULFILLED") {
			this.state.deleteVisible = false;
			this.state.data = [];
			this.state.listOffset = 0;
			this.state.search = "";
			this.fetchListData();
			this.onFetchData();
			this.props.dispatch(interventionAction.deleteSubtreatmentCompleted());
		}

		if (this.props.extClinicalPathway.position) {
			this.state.position = this.props.extClinicalPathway.position;
		}

		return (
			<div>
				<BreadCrumb useSearch items={this.generateItems()} />
				<div className="risk_preparation_header float_left di w_100 padding-0 margin-top-15 margin-bottom-15">
					<div className="row">
						<div className="col-md-10 col-sm-10">
							<div className="w_100 di margin-bottom-18">
								<label className="common_label"><I18 tkey='GENERIC DESCRIPTION' /></label>
								<Editor
									wrapperClassName="wrapper-class height_200_editor"
									editorClassName="editor-class"
									toolbarClassName="toolbar-class"
									editorState={this.state.editorState}
									onEditorStateChange={this.onEditorStateChange.bind(this)}
									handleBeforeInput={this.handleBeforeInput.bind(this)}
									handlePastedText={this.handlePastedText.bind(this)}
									keyBindingFn={this.keyBindingFn.bind(this)}
									handleKeyCommand={this.handleKeyCommand.bind(this)}
									localization={{
										locale: utils.getLanguage()
									}}
									toolbar={ { 
										options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"] }}
								/>
								{this.state.invalidDescription && <span className='invalid_intervention_edit fixed_label'><I18 tkey='Enter Valid Description (Special characters " \ not allowed)' /></span>}
								<div className="text_align_right margin-top-10">
									<ActionButton className="common_button margin_top_15px_mob" onClick={this.saveClicked.bind(this)} value={i18Get("SAVE", utils.getLanguage())} />
								</div>
							</div>  
							<div className="w_100 di position_relative">
								<label className="common_label"><I18 tkey={"Select Anaesthesia"} /></label>
								<div className="d-flex align-items-center">
									<Select
										isSearchable
										className="col-sm-10 padding-0"
										onChange={this.onOptionsChange.bind(this)}
										value={this.state.selectedData}
										options={this.state.options}
										isClearable={true}
										onMenuScrollToBottom={this.onListScroll.bind(this)}
										onInputChange={this.onSearch.bind(this)}
										isLoading={this.state.isListLoading}
										noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
										loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
										placeholder={i18Get("Select", utils.getLanguage())}
									/>
									{this.state.invalidDataSelected && <span className='invalid_right fixed_label'><I18 tkey="Select valid anaesthesia" /></span>}
									<div className="text_align_right margin-left-10 action_button  col-sm-2 padding-0"><ActionButton showAdd={true} className="common_button margin_top_15px_mob  col-sm-12" onClick={this.addClicked.bind(this)} value={i18Get("ADD", utils.getLanguage())} /></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="w_100 padding-bottom-50">
					<ReactTable
						data={this.state.list}
						loading={this.state.loading}
						LoadingComponent={() => { return (<Loader loading={this.state.loading} isTable={true} />); }}
						multiSort={false}
						pageSize={this.state.list.length ? this.state.list.length : 3}
						showPagination={false}
						noDataText={this.state.loading ? "" : i18Get("NO ANAESTHESIA FOUND", utils.getLanguage())}
						columns={this.getCols()}
						ofText={i18Get("of", utils.getLanguage())}
						rowsText={i18Get("rows", utils.getLanguage())}
						onSortedChange={(newSorted) => {
							this.setState({ sorted: newSorted });
						}}
						sorted={this.state.sorted}
					/>
				</div>
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete", utils.getLanguage())}
					visible={this.state.deleteVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deleteElement.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.elementToDelete.name}</span> ?
					</div>
				</Modal>
			</div>
		);
	}
}

Anaesthesia.propTypes = {
	actions: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	intervention: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	extClinicalPathway: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	intervention: store.intervention,
	clinicalPathway: store.clinicalPathway,
	extClinicalPathway: store.extClinicalPathway,
	translations: store.translations,
	user: store.user
}))(Anaesthesia));