import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../../../../../router/locations";
import utils from "../../../../../../../../../utils/utils";
import I18, { i18Get } from "../../../../../../../../../i18";
import adminPersonnelActions from "../../../../../../../../../actions/admin-personnel";
import Loader from "../../../../../../../../../plug/loader";
import ReactTable from "../../../../../../../../../plug/reactTable/react-table";
import { Modal } from "antd";
import events from "../../../../../../../../../actions/events";
import screenId from "../../../../../../../../../router/screenIdList";
import column from "./column";
import AssignAndApproveModel from "./assign-and-approve-model";

class TemplateDetailsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			templateDetails: [],
			totalPages: 1,
			loading: false,
			search: "",
			deletePersonnelVisible: false,
			page: 1,
			pageSize: utils.getDefaultPageSize(),
			deletedPersonnel: {},
			sort: "",
			desc: 0,
			rank: {
				min: 1,
				max: 1
			},
			disableRankUpdate: false,
			status: "ALL",
			hospitalId: "",
			modelVisible: false,
			personnelId: ""
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.adminPersonnelList });
	}

	editClicked(personnel) {
		return () => {
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabTemplateDetails}/${personnel.id}${this.props.location.search}`);
		};
	}

	deleteClicked(personnel) {
		return (e) => {
			e.stopPropagation();
			e.nativeEvent.stopImmediatePropagation();
			this.setState({ deletedPersonnel: personnel, deletePersonnelVisible: true });
		};
	}

	hideModalDelete() {
		this.setState({ deletePersonnelVisible: false });
	}

	deletePersonnel() {
		const queryString = `${this.props.typeActivity ? `typeActivity=${this.props.typeActivity.toUpperCase()}` : ""}`;
		this.props.dispatch(adminPersonnelActions.deleteTemplateDetails(this.state.template, this.state.deletedPersonnel.id, queryString));
		this.setState({ deletePersonnelVisible: false, deletedPersonnel: {} });
	}

	getTableOptions() {
		return { page: this.state.page, pageSize: this.state.pageSize, sort: this.state.sort, desc: this.state.sort };
	}

	approveClicked(personnel) {
		return (e) => {
			e.preventDefault();
			this.setState({ modelVisible: true, personnelId: personnel.id });
		};
	}

	refreshCompleted = () => {
		this.setState({ modelVisible: false });
		this.fetchDataFromServer();
	};

	showRank(value) {
		// let disabledUp = ((parseInt(value.rank) === this.state.rank.min) || this.state.disableRankUpdate);
		// let disabledDown = ((parseInt(value.rank) === this.state.rank.max) || this.state.disableRankUpdate);
		return (
			<div className="rank_div">
				{/* <a className={disabledUp ? "disabled_a_tag float_left" : "float_left"} onClick={disabledUp ? (e) => { e.preventDefault(); } : this.rankIncreased(value).bind(this)}><i className="fa fa-angle-up" aria-hidden="true"></i></a> */}
				<span className="rank_value">{utils.setLocaleForCurrency(value.rank, utils.sameDropper(), true)}</span>
				{/* <a className={disabledDown ? "disabled_a_tag float_right" : "float_right"} onClick={disabledDown ? (e) => { e.preventDefault(); } : this.rankDecreased(value).bind(this)}><i className="fa fa-angle-down" aria-hidden="true"></i></a> */}
			</div>
		);
	}

	rankIncreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) - 1,
				templateId: this.state.template
			};
			this.props.dispatch(adminPersonnelActions.updateRank(value.id, payload));
		};
	}

	rankDecreased(value) {
		return () => {
			this.setState({ disableRankUpdate: true });
			const payload = {
				rank: parseInt(value.rank) + 1,
				templateId: this.state.template
			};
			this.props.dispatch(adminPersonnelActions.updateRank(value.id, payload));
		};
	}

	onFetchData(state) {
		const prevState = this.state;
		prevState.loading = true;
		if (state.sorted && state.sorted.length > 0) {
			prevState.sort = state.sorted[0].id;
			prevState.desc = state.sorted[0].desc ? 1 : 0;
		} else {
			prevState.sort = "";
			prevState.desc = 0;
		}
		if (prevState.page < 1) {
			prevState.page = 1;
		}
		prevState.pageSize = state.pageSize;
		this.setState(prevState);
		localStorage.setItem("PAGE_SIZE", this.state.pageSize);
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		if (this.state.template) {
			let queryString = this.state.page ? `offset=${((this.state.page - 1) * this.state.pageSize)}&` : "";
			queryString += this.state.pageSize ? `numResults=${this.state.pageSize}` : "";
			queryString += this.state.sort ? `&sort=${this.state.sort}&desc=${this.state.desc}` : "";
			queryString += this.state.hospitalId ? `&hospitalId=${this.state.hospitalId}` : "";
			queryString += this.state.status  ? `&status=${this.state.status}` : "";
			queryString += this.state.template ? `&categoryPersonnelTemplateId=${this.state.template}` : "";
			queryString += this.state.search ? `&search=${this.state.search}` : "";
			this.props.dispatch(adminPersonnelActions.fetchAllTemplateDetails(queryString));
		} else {
			this.state.loading = false;
			this.state.templateDetails = [];
		}
	}

	onPageChange(pageIndex) {
		this.state.page = pageIndex + 1;
		if (document.getElementsByClassName("rt-tbody").length) {
			document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
		}
	}

	onPageSizeChange() {
		this.state.page = 1;
	}

	getList() {
		return (
			<ReactTable
				{...utils.getPropsForReactTable(this.state.templateDetails, (this.state.page - 1), this.state.totalPages, 
					this.state.loading, () => {return (<Loader loading={this.state.loading} isTable={true}/>);}, false, 
					this.state.pageSize, true, this.onFetchData.bind(this), 
					column(this.props.typeActivity, this.editClicked.bind(this), this.deleteClicked.bind(this), this.showRank.bind(this), this.approveClicked.bind(this)), true, false, 
					this.onPageChange.bind(this), "ADMINISTER PERSONNEL", "", this.onPageSizeChange.bind(this))}/>
		);
	}

	render() {
		if ((this.state.search !== this.props.search) || (this.state.template !== this.props.template)
        || (this.state.hospitalId !== this.props.hospitalId) || (this.state.status !== this.props.status)) {
			this.state.search = this.props.search;
			this.state.template = this.props.template;
			this.state.hospitalId = this.props.hospitalId;
			this.state.status = this.props.status;
			this.state.templateDetails = [];
			this.state.loading = true;
			this.state.page = 1;
			this.fetchDataFromServer();
		}
		if (this.props.adminPersonnel.fetchAllTemplateDetailsCompleted === "FULFILLED") {
			this.state.loading = false;
			if (document.getElementsByClassName("rt-tbody").length) {
				document.getElementsByClassName("rt-tbody")[0].scrollTop = 0;
			}
			this.state.totalPages = Math.ceil(this.props.adminPersonnel.templateDetailsList.count / this.state.pageSize);
			this.state.templateDetails = this.props.adminPersonnel.templateDetailsList.data;
			this.props.getCount(this.props.adminPersonnel.templateDetailsList.count);
			// if((this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) || (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase())
			//     || (this.props.typeActivity === typeActivity.RADIOTHERAPY.toUpperCase())) {
			//         this.state.rank = utils.calculateMinMaxRank(this.state.templateDetails);
			// }
			this.props.dispatch(adminPersonnelActions.clearFetchAllTemplateDetails());
		}
		if (this.props.adminPersonnel.updateStaffRankCompleted === "FULFILLED") {
			this.state.disableRankUpdate = false;
			this.props.dispatch(adminPersonnelActions.clearUpdateRank());
		}
		if (this.props.adminPersonnel.updateStaffRankCompleted === "REJECTED") {
			this.state.disableRankUpdate = false;
			this.props.dispatch(adminPersonnelActions.clearUpdateRank());
		}
		if (this.props.adminPersonnel.deleteTemplateDetailsCompleted === "FULFILLED") {
			this.state.loading = true;
			this.fetchDataFromServer();
			this.props.dispatch(adminPersonnelActions.clearDeleteTemplateDetails());
		}
		return (
			<div>
				{this.getList()}
				<Modal
					className="re_assign_modal"
					title={i18Get("Delete Personnel", utils.getLanguage())}
					visible={this.state.deletePersonnelVisible}
					onCancel={this.hideModalDelete.bind(this)}
					okText={i18Get("OK", utils.getLanguage())}
					cancelText={i18Get("Cancel", utils.getLanguage())}
					onOk={this.deletePersonnel.bind(this)}>
					<div>
						<I18 tkey="Are you sure you want to delete" /> <span className="bold">{this.state.deletedPersonnel.name}</span> ?
					</div>
				</Modal>
				{  this.state.modelVisible ?
					<div>
						<AssignAndApproveModel
							typeActivity={this.state.typeActivity && (this.state.typeActivity !== "ALL") ? this.state.typeActivity : ""}
							refreshCompleted={this.refreshCompleted}
							templateId = {this.state.template}
							personnelId={this.state.personnelId}
							visible={this.state.modelVisible} />
					</div>
					: ""
				}
			</div>
		);
	}
}

TemplateDetailsList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	adminPersonnel: store.adminPersonnel
}))(TemplateDetailsList));    