import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import  Select  from "react-select";
import { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import familyActions from "../../../../../../../actions/consomable-sterilization";
import unionWith from "lodash.unionwith";
import isEqual from "lodash.isequal";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class FamilySelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			family: [],
			offset: 0,
			numResults: 100,
			search: null,
			isDataFound: true
		};
	}
   
	UNSAFE_componentWillMount() {
		this.state.offset = 0;
		this.state.numResults = 100;
		this.fetchFamilyList(true);
	}

	componentWillUnmount () {
		clearTimeout(this.timer);
	}

	onListScroll() {
		this.state.offset += this.state.numResults;
		this.state.fetchDataFromserver = true;
		this.fetchFamilyList(this.state.search);
	}

     
	fetchFamilyList(search) {
		if (!this.state.fetchingData) {   
			this.state.fetchDataFromserver = false;
			this.state.fetchingData = true;
			let queryString;
			if (this.props.assignAndApprove) {
				queryString = "consumableType=STERILIZATION&" + "offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + "&status=APPROVED" + (search ? this.state.search ? `&name=${this.state.search}` : this.props.data ? `&name=${this.props.data.label}` : "" : "");
			}
			else {
				queryString = "consumableType=STERILIZATION&" + "offset=" + (this.state.offset) + "&numResults=" + (this.state.numResults) + "&status=APPROVED" + (this.state.search ? `&name=${this.state.search}` : "");
			}
			this.props.dispatch(familyActions.getAllSterilizationFamily(queryString)); 
		}
	}

	onStatusChange = () => {
		this.props.statusCheck("DATA_FOUND");
		return (this.props.data);
	};

	onSearch(search) {
		if (this.state.search !== search) {
			if (this.state.search !== search) {
				this.state.search = search;
				clearTimeout(this.timer);
				this.state.offset = 0;
				this.timer = setTimeout(() => {
					this.state.fetchDataFromserver = true;
					this.fetchFamilyList(search);
				}, 800);
			}
		}
	}

	renderOptions() {      
		const prevState = this.state;  
		prevState.options = [];
		if (this.state.family) {    
			this.state.family.map((data) => {   
				prevState.options.push({ value: data.id, label: data.name, code: data.code });
			});
		}    
		prevState.isListLoading = false;
		this.setState(prevState); 
	}

	render() {
		if (this.props.consomableSterilization.getAllSterilizationFamilyStatus === "FULFILLED") {
			this.state.fetchingData = false;
			this.state.family = unionWith(this.state.family, this.props.consomableSterilization.getAllSterilizationFamily.consumableFamily, isEqual);
			this.renderOptions();
			this.props.dispatch(familyActions.getAllSterilizationFamilyCompleted());  
			if (this.props.assignAndApprove) {
				if (!(this.state.options && this.state.options.length) && this.state.isDataFound) {
					this.fetchFamilyList(false);
					this.state.isDataFound = false;
				}  
			}
		}

		if ((this.props.consumableSpecialityId !== this.state.consumableSpecialityId)) {
			this.state.consumableSpecialityId = this.props.consumableSpecialityId;
			this.state.family = [];
			this.state.offset = 0;
			this.state.options = [];
			this.state.fetchDataFromserver = true;
		}
		if (!this.props.consomableSterilization.getAllSterilizationFamilyStatus && this.state.fetchDataFromserver) {       
			this.fetchFamilyList();
		}
		return (   
			<Select 
				styles={customStyles}
				isDisabled={this.props.disabled}
				isSearchable
				className="w100"
				onChange={this.props.onFamilyChange}
				isClearable={true}
				value={this.props.assignAndApprove ? this.state.options ? this.state.options.filter(options => options.label === this.props.data.label ? this.onStatusChange() : "") : "" : this.props.data}
				options={this.state.options}
				onMenuScrollToBottom={this.onListScroll.bind(this)}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Sterilization Family", utils.getLanguage())}
			/>
		);
	}
}

FamilySelect.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	consomableSterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({ 
	consomableSterilization: store.consomableSterilization,
	translations: store.translations
}))(FamilySelect));                    