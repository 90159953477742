import React from "react";
import { Slider } from "antd";
import I18 from "i18";
const NodeStyles = (props) => {

	const onSlide = (e) => {
		props.onChange("strokeWidth", e);
	};
	return (
		<div>
			<strong><I18 tkey='Boarder Width' /></strong>
			<Slider defaultValue={props.strokeWidth} value={props.strokeWidth} onChange={onSlide} />
		</div>
	);
};

export default NodeStyles;
