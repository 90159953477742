import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (organization, query, meta) => ({
	type: events.HEALTH_PATHWAY_GET_ALL,
	meta,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway${query ? `?${query}` : ""}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_GET_ALL,
	payload: {}

});

const getOne = (healthPathwayId, timeZone) => ({
	type: events.HEALTH_PATHWAY_GET_ONE,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}${timeZone ? `?timezone=${timeZone}` : ""}`)
});

const updateHealthPathway = (payload, id) => ({
	type: events.HEALTH_PATHWAY_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/healthPathway/${id}/name`, { ...payload })
});

const createHealthPathway = (payload) => ({
	type: events.HEALTH_PATHWAY_CREATE,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway`, { ...payload })
});
const clearCreate = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_CREATE,
	payload: {}
});

const clearUpdateHealthPathway = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_UPDATE,
	payload: {}
});

const clearFilter = () => ({
	type: events.CLEAR_FILTER,
	payload: {}
});

const cleargetOne = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_GET_ONE,
	payload: {}
});

const getOneByCode = (code, timeZone, metaData) => ({
	type: events.HEALTH_PATHWAY_GET_ONE_BY_CODE,
	meta: metaData,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${code}/diagramdetailsByCode/${timeZone ? `?timezone=${timeZone}` : ""}`)
});

const cleargetOneByCode = () => ({
	type: events.CLEAR_HEALTH_PATHWAY_GET_ONE_BY_CODE,
	payload: {}
});

const removeHealthPathway = (id) => ({
	type: events.DELETE_HEALTH_PATHWAY,
	payload: axios.delete(`${Config.apiRootSecure}/healthPathway/${id}`)

});

const removeHealthPathwayCompleted = () => ({
	type: events.DELETE_HEALTH_PATHWAY_COMPLETED,
	payload: ""
});

const getOneHealthPathwayUsingHospitalId = (healthPathwayId, hospitalId, query) => ({
	type: events.GET_ONE_HEALTH_PATHWAY_HOSPITAL,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${healthPathwayId}/hospitals/${hospitalId}${query || ""}`)
});

const clearGetOneHealthPathwayUsingHospitalId = () => ({
	type: events.CLEAR_GET_ONE_HEALTH_PATHWAY_HOSPITAL,
	payload: {}

});

const saveAndApproveHealthPathway = (id, payload) => ({
	type: events.SAVE_AND_APPROVE_HEALTH_PATHWAY,
	payload: axios.put(`${Config.apiRootSecure}/healthPathway/${id}/saveAndApprove`, { ...payload })
});

const saveAndApproveHealthPathwayCompleted = () => ({
	type: events.SAVE_AND_APPROVE_HEALTH_PATHWAY_COMPLETED,
	payload: {}
});

const checkHealthPathwayListStatus = (code) => ({
	type: events.CHECK_HEALTH_PATHWAY_REPORT_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/exportAll/status/${code}`)
});

const checkHealthPathwayListStatusCompleted = () => ({
	type: events.CHECK_HEALTH_PATHWAY_REPORT_STATUS_COMPLETED,
	payload: ""
});

const exportHealthPathwayList = (query, bodyParam) => ({
	type: events.HEALTH_PATHWAY_EXPORT,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/exportAll${query ? `?${query}` : ""}`, bodyParam)
});

const exportHealthPathwayListCompleted = () => ({
	type: events.HEALTH_PATHWAY_EXPORT_COMPLETED,
	payload: ""
});

const getLinkData = (treatmentCode, hospitalCode) => ({
	type: events.GET_LINKED_DATA,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/linkData/${treatmentCode}/${hospitalCode}`)
});

const clearGetLinkData = () => ({
	type: events.CLEAR_GET_LINKED_DATA,
	payload: ""
});

const generateDiagramExportExcel = (bodyParam) => ({
	type: events.GENERATE_DIAGRAM_EXPORT_EXCEL,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/exportDiagram`, { ...bodyParam, timezone: new Date().getTimezoneOffset() })
});

const CleargenerateDiagramExportExcel = () => ({
	type: events.CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL,
	payload: ""
});

const generateDiagramExportExcelStatus = (code) => ({
	type: events.GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/exportDiagram/status/${code}`)
});

const clearGenerateDiagramExportExcelStatus = () => ({
	type: events.CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS,
	payload: ""
});

const getDiagramAdditionalInfo = (treatMentId, diagramData) => ({
	type: events.GET_ALL_DIAGRAM_ADDITIONAL_INFO,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/diagram/diagramdetails/${treatMentId}`, diagramData )
});
const getDiagramInfo = (treatMentId, diagramData) => ({
	type: events.GET_DIAGRAM_INFO,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/diagram/diagramdetails/${treatMentId}`, diagramData )
});

const clearGetDiagramAdditionalInfo = () => ({
	type: events.CLEAR_GET_ALL_DIAGRAM_ADDITIONAL_INFO,
	payload: ""
});
const getTrajectoryInfo = (id, query) => ({
	type: events.GET_TRAJECTORY_INFORMATION,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${id}/trajectory${query ? `?${query}` : ""}`)
});

const clearGetTrajectoryInfo = () => ({
	type: events.CLEAR_GET_TRAJECTORY_INFORMATION,
	payload: ""
});


const getDiagramSortableData = (id) => ({
	type: events.GET_ALL_DIAGRAM_SORTABLE_INFO,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${id}/diagram/steporder`)
});

const getDiagramSortableReOrderTaskData = (treatmentId) => ({
	type: events.GET_ALL_DIAGRAM_SORTABLE_TASK_INFO,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/diagram/reorderTasks`)
});

const clearGetDiagramSortableReOrderTaskData  = () => ({
	type: events.CLEAR_GET_ALL_DIAGRAM_SORTABLE_TASK_INFO,
	payload: ""
});

const saveSortableReOrderTaskData = (treatmentId, reorderTasks) => ({
	type: events.SAVE_SORTABLE_TASK_LIST,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/${treatmentId}/diagram/reorderTasks`, reorderTasks)
});

const clearSaveSortableReOrderTaskData = () => ({
	type: events.CLEAR_SAVE_SORTABLE_TASK_LIST,
	payload: ""
});


const clearGetDiagramSortableData = () => ({
	type: events.CLEAR_GET_ALL_DIAGRAM_SORTABLE_INFO,
	payload: ""
});

const saveDiagramSortableData = (id, bodyParam) => ({
	type: events.SAVE_ALL_DIAGRAM_SORTABLE_INFO,
	payload: axios.post(`${Config.apiRootSecure}/healthPathway/${id}/diagram/steporder`, bodyParam)
});


const clearSaveDiagramSortableData = () => ({
	type: events.CLEAR_SAVE_ALL_DIAGRAM_SORTABLE_INFO,
	payload: ""
});


const getDiagramVersions = (id) => ({
	type: events.GET_DIAGRAM_VERSIONS,
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${id}/versions`)
});

const clearGetDiagramVersions = () => ({
	type: events.CLEAR_GET_DIAGRAM_VERSIONS,
	payload: ""
});

const getDiagramVersionDetails = (treatmentId, item, isLatest) => ({
	type: events.GET_DIAGRAM_DETAILS,
	meta: { isLatest, item },
	payload: axios.get(`${Config.apiRootSecure}/healthPathway/${treatmentId}/versions/${item.id}`)
});

const clearGetDiagramVersionDetails = () => ({
	type: events.CLEAR_GET_DIAGRAM_DETAILS,
	payload: ""
});

const deleteDiagramSubVersions = (id, bodyParam) => ({
	type: events.DELETE_DIAGRAM_SUB_VERSIONS,
	payload: axios.delete(`${Config.apiRootSecure}/healthPathway/${id}/versions?ids=${bodyParam}`)

});

const clearDeleteDiagramSubVersions = () => ({
	type: events.CLEAR_DELETE_DIAGRAM_SUB_VERSIONS,
	payload: ""
});

const setSelectedTrajectory = (trajectory) => ({
	type: events.SET_SELECTED_TRAJECTORY,
	payload: trajectory
});

export default {
	getAll,
	clearGetAll,
	getOne,
	cleargetOne,
	getOneByCode,
	cleargetOneByCode,
	updateHealthPathway,
	clearUpdateHealthPathway,
	createHealthPathway,
	clearCreate,
	removeHealthPathway,
	removeHealthPathwayCompleted,
	clearFilter,
	getOneHealthPathwayUsingHospitalId,
	clearGetOneHealthPathwayUsingHospitalId,
	saveAndApproveHealthPathway,
	saveAndApproveHealthPathwayCompleted,
	checkHealthPathwayListStatusCompleted,
	exportHealthPathwayList,
	exportHealthPathwayListCompleted,
	checkHealthPathwayListStatus,
	getLinkData,
	clearGetLinkData,
	generateDiagramExportExcel,
	CleargenerateDiagramExportExcel,
	generateDiagramExportExcelStatus,
	clearGenerateDiagramExportExcelStatus,
	getDiagramAdditionalInfo,
	getDiagramInfo,
	clearGetDiagramAdditionalInfo,
	getTrajectoryInfo,
	clearGetTrajectoryInfo,
	getDiagramSortableData,
	clearGetDiagramSortableData,
	saveDiagramSortableData,
	clearSaveDiagramSortableData,
	getDiagramVersions,
	clearGetDiagramVersions,
	getDiagramVersionDetails,
	clearGetDiagramVersionDetails,
	deleteDiagramSubVersions,
	clearDeleteDiagramSubVersions,
	getDiagramSortableReOrderTaskData,
	clearGetDiagramSortableReOrderTaskData,
	saveSortableReOrderTaskData,
	clearSaveSortableReOrderTaskData,
	setSelectedTrajectory
};
