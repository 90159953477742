import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getDefinition = (treatmentId, type) => ({
	type: events.DESCRIPTION_GET,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping?type=${type}&offset=0&numResults=1000`)
});

const clearGetDefinition = () => ({
	type: events.CLEAR_DESCRIPTION_GET,
	payload: {}
});

const clearClinicalPathwaySidebar = () => ({
	type: events.CLEAR_FETCH_SIDE_BAR,
	payload: {}
});
const fetchClinicalPathwaySidebar = (id) => ({
	type: events.FETCH_SIDE_BAR,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/sideBar`)
});

const fetchAllExtendedClinicalPathway = (id, search, searchField, searchType, clpStepFilter) => ({
	type: events.FETCH_ALL_EXTENDED_CLINICAL_PATHWAY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping?offset=0&numResults=1000${search ? `&value=${search}` : ""}${searchField ? `&field=${searchField}` : ""}${searchType ? `&mode=${searchType}` : ""}${clpStepFilter ? `&clpStepFilter=${clpStepFilter}` : ""}`)
});

const fetchAllExtendedClinicalPathwayCompleted = () => ({
	type: events.FETCH_ALL_EXTENDED_CLINICAL_PATHWAY_COMPLETED,
	payload: {}
});

const updateClinicalPathwayDetails = (payload, props) => ({
	type: events.CLINICAL_PATHWAY_DETAILS_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${props.id}/days`, { ...payload })
});

const clearUpdateClinicalPathwayDetails = () => ({
	type: events.CLEAR_CLINICAL_PATHWAY_DETAILS_UPDATE,
	payload: {}
});

const fetchClpDefaultSteps = (type, name) => ({
	type: events.FETCH_DEFAULT_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/step/subStep/default${type ? `?clinicalPathwayType=${type}` : ""}${name ? `&name=${name}` : ""}`)
});

const clearFetchClpDefaultSteps = () => ({
	type: events.CLEAR_FETCH_DEFAULT_STEP,
	payload: {}
});

const fetchReferenceAttachment = (id, itemId, fileType) => ({
	type: events.FETCH_REFERENCE_ATTACHMENT,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/${itemId}/download/file${fileType ? `?fileType=${fileType}` : ""}`)
});

const fetchReferenceAttachmentCompleted = () => ({
	type: events.FETCH_REFERENCE_ATTACHMENT_COMPLETED,
	payload: {}
});

const getEligibility = (treatmentId, type) => ({
	type: events.ELIGIBILITY_GET,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${treatmentId}/textElementMapping?type=${type}&offset=0&numResults=1000`)
});

const clearGetEligibility = () => ({
	type: events.CLEAR_ELIGIBILITY_GET,
	payload: {}
});

const fetchAllMinimalSubStep = (id) => ({
	type: events.FETCH_ALL_MINIMAL_SUB_STEP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/minimalSubsteps`)
});

const clearFetchAllMinimalSubStep = () => ({
	type: events.CLEAR_FETCH_ALL_MINIMAL_SUB_STEP,
	payload: {}
});

const saveSubStep = (id, payload) => ({
	type: events.SAVE_MINIMAL_SUB_STEP,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/minimalSubsteps`, { ...payload })
});

const clearSaveSubStep = () => ({
	type: events.CLEAR_SAVE_MINIMAL_SUB_STEP,
	payload: {}
});

const fetchCopyFromClinicalPathwayList = (id) => ({
	type: events.FETCH_COPY_FROM_CLP_LIST,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/copyFromClinicalPathwayList`)
});

const clearCopyFromClinicalPathwayList = () => ({
	type: events.CLEAR_FETCH_COPY_FROM_CLP_LIST,
	payload: {}
});

const copyFromClinicalPathway = (id, sourceId, payload) => ({
	type: events.COPY_FROM_CLINICAL_PATHWAY,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/copyFrom/${sourceId}`, { ...payload })
});

const clearCopyFromClinicalPathway = () => ({
	type: events.CLEAR_COPY_FROM_CLINICAL_PATHWAY,
	payload: {}
});

const copyFromClinicalPathwayStatus = (code) => ({
	type: events.CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/copyFrom/status/${code}`)
});

const clearCopyFromClinicalPathwayStatus = () => ({
	type: events.CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS_COMPLETED,
	payload: ""
});

const exportClinicalPathwayReport = (id, query) => ({
	type: events.CLINICAL_PATHWAY_EXPORT,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/export`, query)
});

const exportClinicalPathwayReportCompleted = () => ({
	type: events.CLINICAL_PATHWAY_EXPORT_COMPLETED,
	payload: ""
});

const checkClinicalPathwayReportStatus = (code) => ({
	type: events.CHECK_CLINICAL_PATHWAY_REPORT_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/export/status/${code}`)
});

const checkClinicalPathwayReportStatusCompleted = () => ({
	type: events.CHECK_CLINICAL_PATHWAY_REPORT_STATUS_COMPLETED,
	payload: ""
});

const createService = (id, payload) => ({
	type: events.CREATE_CLINICAL_PATHWAY_SERVICE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/authors/activities`, { ...payload })
});

const createServiceCompleted = () => ({
	type: events.CREATE_CLINICAL_PATHWAY_SERVICE_COMPLETED,
	payload: {}
});

const getAuthorsAndActivities = (id, query) => ({
	type: events.GET_AUTHORS_AND_ACTIVITIES,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/authors/activities?${query}`)
});

const clearGetAuthorsAndActivities = () => ({
	type: events.CLEAR_GET_AUTHORS_AND_ACTIVITIES,
	payload: ""
});

const deleteAuthorAndActivity = (id, serviceId) => ({
	type: events.DELETE_AUTHOR_AND_ACTIVITY,
	payload: axios.delete(`${Config.apiRootSecure}/clinicalPathway/${id}/authors/activities/${serviceId}`)

});

const deleteAuthorAndActivityCompleted = () => ({
	type: events.DELETE_AUTHOR_AND_ACTIVITY_COMPLETED,
	payload: ""
});

const updateAuthorAndActivity = (id, serviceId, payload) => ({
	type: events.UPDATE_AUTHOR_AND_ACTIVITY,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/authors/activities/${serviceId}`, { ...payload })
});

const updateAuthorAndActivityCompleted = () => ({
	type: events.UPDATE_AUTHOR_AND_ACTIVITY_COMPLETED,
	payload: {}
});

const getOneAuthorAndActivity = (id, serviceId, timeZone) => ({
	type: events.GET_ONE_AUTHORS_AND_ACTIVITIES,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/authors/activities/${serviceId}${timeZone ? `?timezone=${timeZone}` : ""}`)
});

const getOneAuthorAndActivityCompleted = () => ({
	type: events.CLEAR_GET_ONE_AUTHORS_AND_ACTIVITIES,
	payload: ""
});

const getHospitalForClp = (id, query) => ({
	type: events.GET_HOSPITAL_FOR_CLP,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/hospitals${query || ""}`)
});

const getHospitalForClpCompleted = () => ({
	type: events.GET_HOSPITAL_FOR_CLP_COMPLETED,
	payload: ""
});

const copyAllToMaster = (id, sourceId, payload) => ({
	type: events.COPY_ALL_TO_CLINICAL_PATHWAY_MASTER,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${sourceId}/copyToMaster/${id}`, { ...payload })
});

const clearCopyAllToMaster = () => ({
	type: events.CLEAR_COPY_ALL_TO_CLINICAL_PATHWAY_MASTER,
	payload: {}
});

const getAuthorsAndActivitiesForSelectedCopy = (id, query) => ({
	type: events.GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/copy/selected/authors/activities?${query}`)
});

const clearGetAuthorsAndActivitiesForSelectedCopy = () => ({
	type: events.CLEAR_GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY,
	payload: ""
});

const fetchAllExtendedClpForSelectedCopy = (id) => ({
	type: events.FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/copy/selected/itemMapping?offset=0&numResults=1000`)
});

const clearFetchAllExtendedClpForSelectedCopy = () => ({
	type: events.CLEAR_FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY,
	payload: {}
});

const copySelectedDataToMasterClp = (id, payload) => ({
	type: events.COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/copy/selected/${id}`, { ...payload })
});

const clearCopySelectedDataToMasterClp = () => ({
	type: events.CLEAR_COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP,
	payload: {}
});

const fetchCopyToSubStepList = (id, stepId, subStepId, query) => ({
	type: events.FETCH_COPY_TO_SUB_STEP_LIST,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/step/${stepId}/subStep/${subStepId}/copyToSubSteps${query ? `?${query}` : ""}`)
});

const clearCopyToSubStepList = () => ({
	type: events.CLEAR_FETCH_COPY_TO_SUB_STEP_LIST,
	payload: {}
});

const subStepCopy = (id, stepId, subStepId, payload) => ({
	type: events.SUB_STEP_COPY,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/step/${stepId}/subStep/${subStepId}/copyToSubSteps`, { ...payload })
});

const subStepCopyCompleted = () => ({
	type: events.SUB_STEP_COPY_COMPLETED,
	payload: ""
});

const checkSubStepCopyStatus = (code) => ({
	type: events.CHECK_SUB_STEP_COPY_STATUS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/copyToSubSteps/status/${code}`)
});

const checkSubStepCopyStatusCompleted = () => ({
	type: events.CHECK_SUB_STEP_COPY_STATUS_COMPLETED,
	payload: ""
});

const fetchTotalItemCount = (id, query) => ({
	type: events.FETCH_TOTAL_ITEM_COUNT,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/masterMapping/count${query ? `?${query}` : ""}`)
});

const clearFetchTotalItemCount = () => ({
	type: events.CLEAR_FETCH_TOTAL_ITEM_COUNT,
	payload: {}
});

const communityShare = (id, payload) => ({
	type: events.COMMUNITY_SHARE,
	payload: axios.post(`${Config.apiRootSecure}/clinicalPathway/${id}/communityShare`, { ...payload })
});

const communityShareCompleted = () => ({
	type: events.COMMUNITY_SHARE_COMPLETED,
	payload: ""
});

export default {
	getDefinition,
	clearGetDefinition,
	getEligibility,
	clearGetEligibility,
	fetchAllExtendedClinicalPathway,
	fetchAllExtendedClinicalPathwayCompleted,
	fetchClinicalPathwaySidebar,
	clearClinicalPathwaySidebar,
	updateClinicalPathwayDetails,
	clearUpdateClinicalPathwayDetails,
	fetchClpDefaultSteps,
	clearFetchClpDefaultSteps,
	fetchReferenceAttachment,
	fetchReferenceAttachmentCompleted,
	fetchAllMinimalSubStep,
	clearFetchAllMinimalSubStep,
	saveSubStep,
	clearSaveSubStep,
	fetchCopyFromClinicalPathwayList,
	clearCopyFromClinicalPathwayList,
	copyFromClinicalPathway,
	clearCopyFromClinicalPathway,
	copyFromClinicalPathwayStatus,
	clearCopyFromClinicalPathwayStatus,
	exportClinicalPathwayReport,
	exportClinicalPathwayReportCompleted,
	checkClinicalPathwayReportStatus,
	checkClinicalPathwayReportStatusCompleted,
	createService,
	createServiceCompleted,
	getAuthorsAndActivities,
	clearGetAuthorsAndActivities,
	deleteAuthorAndActivity,
	deleteAuthorAndActivityCompleted,
	getOneAuthorAndActivity,
	getOneAuthorAndActivityCompleted,
	updateAuthorAndActivity,
	updateAuthorAndActivityCompleted,
	getHospitalForClp,
	getHospitalForClpCompleted,
	copyAllToMaster,
	clearCopyAllToMaster,
	getAuthorsAndActivitiesForSelectedCopy,
	clearGetAuthorsAndActivitiesForSelectedCopy,
	fetchAllExtendedClpForSelectedCopy,
	clearFetchAllExtendedClpForSelectedCopy,
	copySelectedDataToMasterClp,
	clearCopySelectedDataToMasterClp,
	fetchCopyToSubStepList,
	clearCopyToSubStepList,
	subStepCopy,
	subStepCopyCompleted,
	checkSubStepCopyStatus,
	checkSubStepCopyStatusCompleted,
	fetchTotalItemCount,
	clearFetchTotalItemCount,
	communityShare,
	communityShareCompleted
};
