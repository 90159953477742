import events from "../../actions/events";

const initState = { };

export default (state = initState, action) => {
	switch (action.type) {
		case `${events.SYNC_DATA}_FULFILLED`:
			return { ...state, syncStatus: "FULFILLED" };
		case `${events.SYNC_DATA}_REJECTED`:
			return { ...state, syncStatus: "REJECTED" };
		case events.SYNC_DATA_COMPLETED:
			return { ...state, syncStatus: false };

		case `${events.GET_LAST_SYNC_DATE}_FULFILLED`:
			return { ...state, syncDate: action.payload.data.data, getSyncDateStatus: "FULFILLED" };
		case `${events.GET_LAST_SYNC_DATE}_REJECTED`:
			return { ...state, getSyncDateStatus: "REJECTED" };
		case events.GET_LAST_SYNC_DATE_COMPLETED:
			return { ...state, getSyncDateStatus: false };
		default: return state;
	}
};
