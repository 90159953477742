import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import Select from "react-select";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import communitySitesAction from "../../../../../../actions/community-sites";

const customStyles = {
	control: (provided) => ({
		...provided,
		minHeight: "32px"
	})
};

class SiteDropDown extends Component {
	constructor(props) {
		super(props);
		this.state = {
			sites: [],
			offset: 0,
			numResults: 500,
			search: "",
			hospital: !this.props.user.loginData.hospitalId ? "MASTER" : ""
		};
	}

	UNSAFE_componentWillMount() {
		this.state.sites = [];
		this.state.offset = 0;
		this.state.numResults = 500;
		this.fetchSiteList();
	}

	componentWillUnmount() {
		clearTimeout(this.timer);
	}

	renderSiteOptions() {
		const prevState = this.state;
		prevState.options = [];
		if (this.props.hospital === "MASTER") {
			const site = { value: "MASTER", label: "MOM" };
			prevState.options.push(site);
			this.props.siteChanged(site);
		} else if (this.state.sites) {
			// if(this.props.isUser){
			//     let site = { value: 'ALL', label: 'All' };
			//     prevState.options.push(site);
			// } 
			this.state.sites.map((data) => {
				prevState.options.push({ value: data.id, label: data.name, hospitalId: data.hospitalId, hospitalName: data.hospitalName });
			});

			if (!this.props.data && this.props.autoSetSingleOne === true)
			{utils.setSingleOneAsDefaultOption(prevState.options, this.props.siteChanged);}
		}
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	fetchSiteList(search) {
		this.setState({ isListLoading: true });
		let queryString = `?offset=${this.state.offset}&numResults=${this.state.numResults}`;
		queryString += `${search ? `&name=${search}` : ""}`;
		queryString += `${this.props.hospital && this.props.hospital !== "MASTER" ? `&hospitalId=${this.props.hospital}` : ""}`;
		queryString += `${this.props.site && this.props.site.value !== "MASTER" ? this.props.site.value : ""}`;
		this.props.dispatch(communitySitesAction.getAll(queryString));
	}

	onSearch(search) {
		this.state.offset = 0;
		if (this.state.search !== search) {
			this.state.search = search;
			this.setState({ isListLoading: true });
			this.fetchSiteList(search);
		}
	}

	render() {
		if (this.props.communitySites.isFetchAllcommunitySitesCompleted === "FULFILLED") {
			this.props.dispatch(communitySitesAction.clearGetAll());
			this.state.sites = this.props.communitySites.communitySites;
			this.renderSiteOptions();
		}
		if (this.props.hospital !== this.state.hospital) {
			this.fetchSiteList();
			this.setState({ hospital: this.props.hospital });
		}
 
		return (
			<Select
				styles={customStyles}
				className={"w_100 di"}
				defaultActiveFirstOption={false}
				onChange={this.props.siteChanged}
				value={this.props.data}
				isSearchable
				options={this.state.options}
				onInputChange={this.onSearch.bind(this)}
				isLoading={this.state.isListLoading}
				noOptionsMessage={() => { return i18Get("No Data Found", utils.getLanguage()); }}
				loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
				placeholder={i18Get("Select Site", utils.getLanguage())}
				maxMenuHeight={150}
			/>
		);
	}
}

SiteDropDown.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	communitySites: PropTypes.object.isRequired,
	user: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	communitySites: store.communitySites,
	translations: store.translations,
	user: store.user
}))(SiteDropDown));
