import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import TopSection from "./top-section";
import List from "./ext-elements-list";
import { i18Get } from "../../../../../i18";
import utils from "../../../../../utils/utils";
import BreadCrumb from "../breadcrumb";
import locations from "../../../../../router/locations";
import EditExtElement from "./edit-ext-elements";
import extElements from "../../../../../constants/extElements.json";

class MainSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			typeList: [],
			extElementsList: [],
			extElement: {},
			type: "",
			selectedHospital: ""
		};
	}

	getLink(type) {
		return `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}` + `${type ? `/extElements/${type.toLowerCase()}` : ""}`;
	}

	getName() {
		return (extElements.extElements.PROSTHESES_DMI === this.props.type) ? "Prostheses DMI" : this.props.type.replace(/_/g, " ").replace(/\w+/g, (txt) => txt.charAt(0) + txt.substr(1).toLowerCase());
	}

	getQueryParams(doctorId) {
		return `${this.props.location.search ? `${this.props.location.search}${doctorId && (this.props.location.search.indexOf("doctorId=") === -1) ? `doctorId=${this.props.doctorId}` : ""}` : `${doctorId ? `?doctorId=${this.props.doctorId}` : ""}`}`;
	}

	generateItems() {
		const items = [];
		items.push({
			name: i18Get("Intervention", utils.getLanguage()),
			link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}${this.props.location.search}`
		});
		items.push({ name: this.props.intervention, link: this.getLink() + this.getQueryParams() });
		items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: this.getLink(this.props.type) + this.getQueryParams() });
		if (this.state.extElement.elementId) {
			items.push({ name: this.state.extElement.elementName, link: `${this.getLink(this.props.type)}/${this.state.extElement.elementId}${this.getQueryParams()}` });
		}
		return items;
	}

	render() {
		this.state.extElement = this.props.breadcrumb.componentData;
		if (this.props.type !== this.state.type) {
			this.state.type = this.props.type;
			this.state.extElementsList = [];
			this.state.typeList = [];
		}
		if (this.props.extElements.isFetchAllExtElementDataCompleted === "FULFILLED") {
			this.state.extElementsList = this.props.extElements.extElementList.extentedElement;
		}

		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				<Switch>
					<Route
						path={locations.ediExtElements}
						render={() => <EditExtElement {...this.props} />}
					/>
					<Route
						path={locations.extElements}
						render={() => (
							<div>
								<TopSection
									{...this.props}
									extElementsList={this.state.extElementsList}
								/>
								<List {...this.props} />
							</div>
						)}
					/>
				</Switch>

			</div>
		);
	}
}

MainSection.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	extElements: PropTypes.object.isRequired,
	breadcrumb: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	extElements: store.extElements,
	breadcrumb: store.breadcrumb,
	translations: store.translations
}))(MainSection));
