import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { MenuOutlined } from "@ant-design/icons";
import { Table, Checkbox } from "antd";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import arrayMove from "array-move";
const DragHandle = sortableHandle(({ active }) => (
	<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);


class reorderProblemByAttachedModel extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: this.props.list,
			sorted: [],
			quickReorderChanges: 0,
			orderChanged: false,
			problemWidth: 300,
			typeWidth: 300,
			selectedItems: [],
			unSelect: false
		};
	}

	UNSAFE_componentWillMount() {
		const prevState = this.state;
		prevState.list = this.props.list;
		if (this.props.setItemCount) {
			this.props.setItemCount(this.props.list ? this.props.list.length : 0);
		}
		this.setState(prevState);
	}

	getText = (value, problem) => {
		return (
			<div>
				<div>
					{value && problem ? <a className="text_underline" onClick={() => { this.props.onClickProblem(value); }}>{value.problem}</a> : <span>{value.type}</span>}
				</div>
			</div>
		);
	};
  
	handleResize = () => (e, { size }) => {
		this.setState({ typeWidth: size.width });
	};

	onCheckBoxChange = (e, i, id) => {
		const prevState = this.state;
		const index = this.state.selectedItems.findIndex(value => value.id === id);
		if (index === -1) {
			prevState.selectedItems.push({ id: id, index: i });
		} else {
			prevState.selectedItems.splice(index, 1);
		}
		this.setState(prevState);
		this.props.selectedItems(prevState.selectedItems);
	};

	goToDetailPage(value) {
		window.open(window.location.origin + "/#/clinical_pathway/mom/" + value.id, "_blank", "noopener noreferrer").focus();
	}

	getColumns = (selectedItems) => [
		{
			title: "",
			dataIndex: "id",
			className: "",
			width: 60,
			hidden: this.props.disableReorder,
			render: (text, record, index) => (
				<div className="">
					<DragHandle active={selectedItems.length > 0 && selectedItems.findIndex((x) => x.index === index) !== -1} />
					<span className="margin-left-10" >
						<Checkbox
							checked={selectedItems.length > 0 && selectedItems.findIndex(value => value.id === text) !== -1}
							onChange={!this.state.unSelect ? (e) => this.onCheckBoxChange(e, index, text) : ""}
						/>
					</span>
				</div>
			)
		},
		{
			title: i18Get("NAME", utils.getLanguage()),
			dataIndex: "name",
			className: "drag-visible",
			render: (text, row) => {
				return this.props.disableHiperLink ? <span >{(row.hospitalName ? row.hospitalName + " " : "") + row.name}</span> : (<a onClick={() => { this.goToDetailPage(row); } }><span >{(row.hospitalName ? row.hospitalName + " " : "") + row.name}</span></a>);}
		}
	].filter(item => !item.hidden);

	merge(a, b, i = 0) {
		const aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	}

	updateIndex(tempDataSource) {
		const prevState = this.state;
		prevState.selectedItems.map(function (data, i) {
			const indexValue = tempDataSource.findIndex(value => value.id === data.id);
			if (indexValue !== -1) {
				prevState.selectedItems[i].index = indexValue;
			}
		});
		this.setState({ selectedItems: prevState.selectedItems });
	}

	getTaskList(list) {
		return (
			<Table
				pagination={false}
				dataSource={list ? list : ""}
				columns={this.getColumns(this.state.selectedItems)}
				bordered
				rowKey="id"
				key={0}
				components={{
					header: {},
					body: {
						wrapper: (e) => this.DraggableContainer(e, list),
						row: (e) => this.DraggableBodyRow(e, list ? list : null)
					}
				}}
			/>
		);
	}

	DraggableContainer = (props, list) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e, list)}
			{...props}
		/>
	);

	DraggableBodyRow = ({ className, style, ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex(
			(x) => x.id === restProps["data-row-key"]
		);
		return <SortableItem className={className} style={style} index={index} {...restProps} />;
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { selectedItems, list } = this.state;
		let tempDataSource = JSON.parse(JSON.stringify(list));

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				tempDataSource = arrayMove([].concat(tempDataSource), oldIndex, newIndex).filter((el) => !!el);
			} 
			else {
				const filteredItems = [];
				selectedItems.forEach((d) => {
					filteredItems.push(tempDataSource[d.index]);
				});
				const newData = [];
				tempDataSource.forEach((d, i) => {
					if (selectedItems.findIndex((x) => x.index === i) === -1) {
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = this.merge(tempDataSource, filteredItems, newIndex);
				this.updateIndex(tempDataSource, selectedItems);
			}
			this.setState({ list: tempDataSource, orderChanged: true });
			this.props.setListItems(tempDataSource);
		}
	};

	render() {
		return (<div className="">{this.getTaskList(this.state.list)}</div>);
	}
}

reorderProblemByAttachedModel.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		actions: store.actions,
		clinicalPathway: store.clinicalPathway
	}))(reorderProblemByAttachedModel)
);
