import { connect } from "react-redux";
import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import locations from "../../../../../router/locations";
import "./create-intervention.css";
import CreateInterventionMom from "./mom";
import typeActivity from "../../../../../constants/typeActivity.json";

class CreateIntervention extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<Switch>
				<Route path={locations.interventions_mom} render={() => <CreateInterventionMom typeActivity={typeActivity.INTERVENTION.toUpperCase()} />} />
			</Switch>
		);
	}
}

CreateIntervention.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(CreateIntervention));
