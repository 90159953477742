import axios from "axios";
import Config from "../../../../../../../../../MOM-config";
import events from "./event";

const getAll = (query, meta) => ({
	type: events.GET_ALL_ROLES_LIST,
	meta: meta,
	payload: axios.get(`${Config.apiRootSecure}/roles?${query}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_GET_ALL_ROLES_LIST,
	payload: {}

});

const getOne = (id) => ({
	type: events.GET_ONE_ROLE,
	payload: axios.get(`${Config.apiRootSecure}/roles/${id}`)
});

const clearGetOne = () => ({
	type: events.CLEAR_GET_ONE_ROLE,
	payload: {}

});

const create = (payload) => ({
	type: events.CREATE_ROLES,
	payload: axios.post(`${Config.apiRootSecure}/roles`, { ...payload })
});

const clearCreate = () => ({
	type: events.CLEAR_CREATE_ROLES,
	payload: {}

});
const updateRole = (payload, id) => ({
	type: events.UPDATE_USER_ROLES,
	payload: axios.put(`${Config.apiRootSecure}/roles/${id}`, { ...payload })
});

const clearUpdate = () => ({
	type: events.CLEAR_UPDATE_USER_ROLES,
	payload: {}

});

const deleteIt = (id) => ({
	type: events.DELETE_ROLES,
	payload: axios.delete(`${Config.apiRootSecure}/roles/${id}`)
});
const CleanDeleteIt = () => ({
	type: events.CLEAR_DELETE_ROLES,
	payload: {}

});

export default {
	getAll,
	clearGetAll,
	getOne,
	clearGetOne,
	create,
	clearCreate,
	updateRole,
	clearUpdate,
	deleteIt,
	CleanDeleteIt
};