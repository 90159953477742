import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import {
	EditorState, ContentState, convertToRaw, getDefaultKeyBinding
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import textElementActions from "../../../../../../actions/text-element";
import ActionButton from "../../../../../../plug/action-button";
import eventNames from "../../../../../../actions/events";
import { i18Get } from "../../../../../../i18";
import utils from "../../../../../../utils/utils";
import BreadCrumb from "../../breadcrumb";
import "./text-element.css";
import typeActivity from "../../../../../../constants/typeActivity.json";
import extElements from "../../../../../../constants/extElements.json";

class TextElements extends Component {
	constructor(props) {
		super(props);
		this.state = {
			editorState: EditorState.createEmpty(),
			textElement: {},
			type: "",
			activeCycle: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.state.activeCycle = this.props.cycle ? this.props.cycle : {};
		if (utils.checkForParameter("cycleId", this.props.location)) {
			this.state.activeCycle.id = utils.checkForParameter("cycleId", this.props.location);
		}
		if (!this.state.activeCycle.id && (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) && (this.props.type === "REMARKS")) {
			this.props.history.push(`/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}`);
		}
	}

	getName() {
		return this.props.type.replace(/_/g, " ").replace(/\w+/g, (txt) => txt.charAt(0) + txt.substr(1).toLowerCase());
	}

	getLink(type) {
		return `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}/${this.props.match.params.id}` + `${type ? `/extElements/${type.toLowerCase()}` : ""}`;
	}

	fetchData() {
		this.props.dispatch(textElementActions.get(this.props.type, (this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) ? this.props.match.params.id : this.props.match.params.treatmentId, "", 0, "", this.state.activeCycle.id));
	}

	onEditorStateChange(editorState) {
		if (this.getLength(editorState) >= utils.editorMaxLength && this.checkLastChangeType(editorState)) {
			this.setState({ editorState: this.state.editorState });
		} else {
			this.setState({ editorState });
		}
	}

	checkLastChangeType(editorState) {
		return ((editorState.getLastChangeType() === "insert-characters") || (editorState.getLastChangeType() === "split-block"));
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	handleBeforeInput() {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength) {
			return "handled";
		}
	}

	handlePastedText(text) {
		if (text && ((this.getLength(this.state.editorState) + text.length) >= utils.editorMaxLength)) {
			return true;
		}
	}

	keyBindingFn(e) {
		return getDefaultKeyBinding(e);
	}

	handleKeyCommand(command) {
		if (this.getLength(this.state.editorState) >= utils.editorMaxLength && (command !== "backspace") && (command !== "delete")) {
			return "handled";
		}
		return "not-handled";
	}

	init() {
		const contentBlock = htmlToDraft(this.state.textElement.description ? this.state.textElement.description : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);
		}
	}

	generateItems() {
		const items = [];
		if (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) {
			items.push({ name: (this.props.details && this.props.details.data) ? this.props.details.data.name : i18Get("Chemotherapy Details", utils.getLanguage()), link: `/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}${this.props.location.search}` });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: `/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}${this.props.location.search}` });
		} else if (this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) {
			items.push({
				name: i18Get("Intervention", utils.getLanguage()),
				link: `/intervention/${this.props.match.params.organization}/${this.props.match.params.sheets}${this.props.location.search}`
			});
			items.push({ name: this.props.intervention ? this.props.intervention : i18Get("Intervention Details", utils.getLanguage()), link: this.getLink() + this.props.location.search });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: this.getLink(this.props.type) + this.props.location.search });
		} else if (this.props.typeActivity === typeActivity.IMAGERY.toUpperCase()) {
			items.push({ name: this.props.details ? this.props.details.data.name : i18Get(`${utils.toPascalCase(this.props.typeActivity)} Details`, utils.getLanguage()), link: `/imagery/${this.props.match.params.organization}/${this.props.match.params.treatmentId}${this.props.location.search}` });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: `/imagery/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}${this.props.location.search}` });
		} else {
			items.push({ name: this.props.details ? this.props.details.data.name : i18Get(`${utils.toPascalCase(this.props.typeActivity)} Details`, utils.getLanguage()), link: `/radiotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}${this.props.location.search}` });
			items.push({ name: i18Get(this.getName(), utils.getLanguage()), link: `/radiotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}/${this.props.type.toLowerCase()}${this.props.location.search}` });
		}
		return items;
	}

	saveClicked() {
		this.props.dispatch({ type: eventNames.ACTION_ONE });
		const payload = {
			type: this.props.type,
			description: this.state.editorState.getCurrentContent().hasText() ? draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) : ""
		};
		this.props.dispatch(textElementActions.update((this.props.typeActivity === typeActivity.INTERVENTION.toUpperCase()) ? this.props.match.params.id : this.props.match.params.treatmentId, this.state.textElement.id, payload));
	}

	render() {
		if (this.props.type !== this.state.type) {
			this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: this.props.screenId });
			this.state.type = this.props.type;
			this.fetchData();
		}
		if ((!utils.checkForParameter("cycleId", this.props.location)) && (this.props.type === "REMARKS") && (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase()) && (this.props.details)) {
			this.props.history.push(`/chemotherapy/${this.props.match.params.organization}/${this.props.match.params.treatmentId}`);
		}
		if (((!this.state.activeCycle) || (!this.state.activeCycle.cycle)) && (this.props.typeActivity === typeActivity.CHEMOTHERAPY.toUpperCase())) {
			if (this.state.activeCycle.id && this.props.details && this.props.details.cycleData.length) {
				this.state.activeCycle = this.props.details.cycleData[this.props.details.cycleData.findIndex((cycle) => parseInt(this.state.activeCycle.id) === parseInt(cycle.id))];
			}
		}
		if (this.props.textElements.isElementGetCompleted === "FULFILLED") {
			this.state.textElement = this.props.textElements.elementsData[0] ? this.props.textElements.elementsData[0] : {};
			this.init();
			this.props.dispatch(textElementActions.clearGet());
		}
		if (this.props.textElements.isTextElementUpdateComplete === "FULFILLED") {
			this.fetchData();
			this.props.dispatch(textElementActions.clearUpdate());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: eventNames.ACTION_ONE_CLEAR });
			}
		}
		return (
			<div>
				<BreadCrumb items={this.generateItems()} />
				{this.state.activeCycle.id && (this.props.type === extElements.textElements.REMARKS) && <div className="col-md-12 padding-top-15">{this.state.activeCycle.cycle ? this.state.activeCycle.cycle : ""}</div>}
				<div className="di w_100 padding-top-15">
					<Editor
						wrapperClassName="wrapper-class"
						editorClassName="editor-class"
						toolbarClassName="toolbar-class"
						editorState={this.state.editorState}
						onEditorStateChange={this.onEditorStateChange.bind(this)}
						handleBeforeInput={this.handleBeforeInput.bind(this)}
						handlePastedText={this.handlePastedText.bind(this)}
						keyBindingFn={this.keyBindingFn.bind(this)}
						handleKeyCommand={this.handleKeyCommand.bind(this)}
						localization={{
							locale: utils.getLanguage()
						}}
						toolbar={{ options: ["inline", "blockType", "fontSize", "fontFamily", "list", "textAlign", "colorPicker", "link", "history"] }}
					/>
					<div className="di w_100 text_align_right margin-top-15">
						<ActionButton onClick={this.saveClicked.bind(this)} value={i18Get("Save", utils.getLanguage())} />
					</div>
				</div>
			</div>
		);
	}
}

TextElements.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	textElements: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	chemotherapy: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	textElements: store.textElements,
	actions: store.actions,
	chemotherapy: store.chemotherapy,
	translations: store.translations
}))(TextElements));
