import healthpathwaylistDropDown from "./healthPathway-list-dropDown/redux/reducer";
import linkedTaskDropDown from "./linked-task-drop-down/redux/reducer";
import reGroupmentList from "./re-groupment-list/redux/reducer";
import digramRules from "./duration-rule/redux/reducer";
import regroupActionButton from "./actionButton/redux/reducer";
import regroupLink from "./viewLinkedHP/redux/reducer";
export default {
	healthpathwaylistDropDown,
	linkedTaskDropDown,
	reGroupmentList,
	digramRules,
	regroupActionButton,
	regroupLink
};