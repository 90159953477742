import React, { Component } from "react";
import { Select } from "antd";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import consumableComponents from "../../../../../../../../constants/consumable.json";

const { Option } = Select;

export default class ConsumableTypeDropdown extends Component {
	constructor(props) {
		super(props);
	}

	renderOptions() {
		return consumableComponents.ELEMENT_CLASS_FILTER.map((type) => <Option key={type} value={type}><I18 tkey={type.replace(/_/g, " ")} /></Option>);
	}

	render() {
		return (
			<div className="">
				<Select
					allowClear
					showSearch
					value={this.props.type ? this.props.type : utils.sameDropper()}
					className="w_100 di"
					defaultActiveFirstOption={false}
					onChange={this.props.typeChanged}
					placeholder={i18Get("Select type", utils.getLanguage())}
					notFoundContent={i18Get("Not Found", utils.getLanguage())}
					optionFilterProp="children"
					filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
				>
					{this.renderOptions()}
				</Select>
			</div>
		);
	}
}
