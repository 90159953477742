import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { EditorState, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from "html-to-draftjs";
import I18, { i18Get } from "../../../../../../../../i18";
import healthPathwayDetailsAction from "../../../../../../../../actions/healthPathway-details";
import utils from "../../../../../../../../utils/utils";

class Remarks extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			images: [],
			remarks: {}
		};
	}

	UNSAFE_componentWillMount() {
		this.state.data = this.props.data;
		this.fetchDataFromServer();
	}

	fetchDataFromServer() {
		this.props.dispatch(healthPathwayDetailsAction.getRemarks((this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id), this.props.type));
	}

	init() {
		const contentBlock = htmlToDraft(this.state.remarks && this.state.remarks.description ? this.state.remarks.description : `<p></p><p style="text-align:center;">${i18Get("NO REMARKS FOUND", utils.getLanguage())}</p>`);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.editorState = EditorState.createWithContent(contentState);
		}
	}

	render() {
		if (this.props.healthPathwayDetails.getHealthPathwayRemarksCompleted === "FULFILLED") {
			this.state.remarks = this.props.healthPathwayDetails.remarks[0] ? this.props.healthPathwayDetails.remarks[0] : {};
			this.init();
			this.props.dispatch(healthPathwayDetailsAction.clearGetRemarks());
		}

		return (
			<div className="editor_no_edit">
				<div className="table_heading">
					{/* <I18 tkey="REMARKS" /> */}
					{!this.props.treatmentId && !this.props.exportPdf
						? <button className="text_align_right edit_button_clp" onClick={this.props.editClicked.bind(this, "remarks")}><I18 tkey="EDIT" /></button>
						: <span className="text_align_right edit_button_clp" />}
				</div>
				<div className="di w_100 float_left">
					<Editor
						readonly
						toolbarHidden
						wrapperClassName="wrapper-class"
						editorClassName="editor-class no_scroll_pathway"
						toolbarClassName="toolbar-class"
						editorState={this.state.editorState}
					/>
				</div>
				<div className="di w_100">
					<div className="text_align_right di w_100 margin-bottom-40" />
				</div>
			</div>
		);
	}
}

Remarks.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	healthPathwayDetails: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	healthPathwayDetails: store.healthPathwayDetails,
	translations: store.translations
}))(Remarks));
