import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import standardCareChartActions from "../../../../../../../../actions/standard-care-chart";
import { Select } from "antd";
import settings from "../../../../../../../../constants/stdCarChartSettings.json";
import I18 from "../../../../../../../../i18";

const { Option } = Select;

class StdCareChartSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			startsAt: settings.startTime[5],
			endsAt: settings.endTime[settings.endTime.length - 1]
		};
	}
	UNSAFE_componentWillMount() {
		this.props.dispatch(standardCareChartActions.fetchStartTimeAndEndTime(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id));
	}

	onSelectChange = (type, value) => {
		this.setState({ ...this.state, [type]: value }, () => {
			this.props.onChangeChartSettings(parseInt(this.state.startsAt), parseInt(this.state.endsAt));
		});
	};

	render() {
		if (this.props.standardCareChart.fetchStartTimeAndEndTime === "FULFILLED") {
			this.state.startsAt = this.props.standardCareChart.stdCareChartSettings.startHour ? this.props.standardCareChart.stdCareChartSettings.startHour : settings.startTime[5];
			this.state.endsAt = this.props.standardCareChart.stdCareChartSettings.endHour ? this.props.standardCareChart.stdCareChartSettings.endHour : settings.endTime[settings.endTime.length - 1];
			this.props.dispatch(standardCareChartActions.clearFetchStartTimeAndEndTime());
		}
		return (
			<div className='w_100  padding-left-0'>
				<div className="col-md-6 di position_relative padding-left-0">
					<span style={{ display: "inline-block", width: "100px" }}><b><I18 tkey="Day starts at" /> </b></span>
					<Select value={this.state.startsAt} className="w_100" onChange={v => this.onSelectChange("startsAt", v)}>
						{settings.startTime.map(time => <Option key={time} value={time}>{time}</Option>)}
					</Select>
				</div>
				<div className="col-md-6 padding-left-0">
					<span style={{ display: "inline-block", width: "100px" }}><b><I18 tkey="Day ends at" /> </b></span>
					<Select value={this.state.endsAt} className="w_100" onChange={v => this.onSelectChange("endsAt", v)}>
						{settings.endTime.map(time => <Option key={time} value={time}>{time}</Option>)}
					</Select>
				</div>
			</div>
		);
	}
}

StdCareChartSettings.propTypes = {
	standardCareChart: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	standardCareChart: store.standardCareChart
}))(StdCareChartSettings));
