import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import clinicalPathwayAction from "../../../../../../../actions/clinical-pathway";
import utils from "../../../../../../../utils/utils";
import Select from "react-select";

class SourceComponents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isListLoading: false,
			renderList: [],
			listOffset: 0,
			listNumResults: 1000,
			clinicalPathwayCategory: this.props.sourceType,
			isCheckList: false


		};
	}
	UNSAFE_componentWillMount() {
		this.fetchList();
	}

	componentDidUpdate() {
		if (this.state.clinicalPathwayCategory != this.props.sourceType) {
			this.setState({
				clinicalPathwayCategory: this.props.sourceType
			}, this.fetchList());
		}
	}

	fetchList(search) {
		this.setState({ isListLoading: true, clinicalPathways: [] });
		let queryParams = `offset=${this.state.listOffset}&numResults=${this.state.listNumResults}&isClpComparison=true&`;
		queryParams += search ? `name=${encodeURIComponent(search)}` : "";
		queryParams += "&sort=name&desc=0";
		queryParams += "&ignoreExisting=true";
		queryParams += this.props.sourceType === "ALL_ITEMS" ? "&sourceType=ALL_ITEMS" : "";
		queryParams += `&treatmentId=${this.props.match.params.id}`;
		queryParams += this.props.sourceType === "CL" ? "&isCheckList=1" : "&isCheckList=0";
		queryParams += this.props.sourceType === "MDL" ? "&isModel=1" : "";
		this.setState({ ...this.state, fetchAllMeta: Date.now() }, () => {
			this.props.dispatch(clinicalPathwayAction.getAll("", queryParams, this.state.fetchAllMeta));
		});
	}

	onListScroll() {
		this.state.stepsOffset += this.state.stepsNumResults;
		this.fetchList();
	}

	onSearch(value) {
		if (this.state.search !== value) {
			clearTimeout(this.timer);
			this.state.search = value;
			this.timer = setTimeout(() => {
				this.state.stepsOffset = 0;
				this.fetchList(value);
			}, 700);
		}
	}




	renderOptions() {
		const prevState = this.state;
		prevState.options = [];
		this.state.clinicalPathways.map((data) => {
			const stepSectionType = data.stepSectionType !== utils.sameDropper() ? data.stepSectionType : null;
			const prefixName = data.siteId && data.hospitalId ? data.siteShortName : data.hospitalName ? data.hospitalName : "MOM";
			prevState.options.push({ value: data.id, label: `${prefixName} ${data.name}`, code: data.code, stepSectionType: stepSectionType, noOfDays: data.noOfDays, procedureDay: data.procedureDay, noOfHours: data.noOfHours, isCheckList: data.isCheckList });
		});
		prevState.isListLoading = false;
		this.setState(prevState);
	}

	onOptionsChange(e) {
		this.props.onChange(e);
	}

	render() {
		if (this.props.clinicalPathway.fetchAllClinicalPathway === "FULFILLED" && this.props.clinicalPathway.clinicalPathwayListMeta === this.state.fetchAllMeta) {
			this.state.clinicalPathways = this.props.clinicalPathway.clinicalPathway.data;
			this.renderOptions();
			this.props.dispatch(clinicalPathwayAction.clearGetAll());
		}

		return (
			<div>
				<Select
					isSearchable
					className="w100"
					isClearable={true} 
					onChange={this.onOptionsChange.bind(this)}
					value={this.props.selectedData}
					options={this.state.options}
					onMenuScrollToBottom={this.onListScroll.bind(this)}
					onInputChange={this.onSearch.bind(this)}
					isLoading={this.state.isListLoading}
					loadingMessage={() => { return i18Get("Loading...", utils.getLanguage()); }}
					placeholder={i18Get("Select", utils.getLanguage())}
				/>
				{this.props.invalidClinicalPathway && <span className='invalid fixed_label margin-top--20'><I18 tkey='Select Clinical Pathway' /></span>}
			</div>

		);
	}
}

SourceComponents.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathway: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathway: store.clinicalPathway,
	translations: store.translations
}))(SourceComponents));