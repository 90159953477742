//LIVE ACTIVITIES
// For fix element alignment
setInterval(function () {
	if (document.getElementsByClassName("header_check_box").length > 0)
	{
		const width = document.getElementsByClassName("header_check_box")[0].offsetWidth;
		for (const name of document.getElementsByClassName("header_section")) {
			name.style["width"] = width + "px";
		}
	}
}, 10);