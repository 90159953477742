import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../i18";
import { Collapse } from "antd";

const { Panel } = Collapse;

class ActiveComponents extends Component {
	constructor(props) {
		super(props);
		this.state = {
			checkList: [],
			list: []
		};
	}

	UNSAFE_componentWillMount() {
	}

	getSuffix(step) {
		if (step.startPrefix !== step.endPrefix) {
			return <span>(<I18 tkey="DAY"/> <I18 tkey={step.startPrefix} /> {"->"} <I18 tkey="DAY" /> <I18 tkey={step.endPrefix} />): <I18 tkey={step.totalPrefixCount} /> <I18 tkey="DAYS" /> </span>;
		}
		else {
			return <span>(<I18 tkey="DAY"/> <I18 tkey={step.startPrefix} />)</span>;
		}
	}

	getSubStepName(subStep) {
		if (subStep && subStep.checked) {
			if (subStep && subStep.day ) {
				return <span><I18 tkey="DAY" /> <I18 tkey={subStep.name ? subStep.name : ""} />{" (" + subStep.itemCount + ")"}</span>;
			} else {
				return <span><I18 tkey={subStep.name ? subStep.name : ""} />{" (" + subStep.itemCount + ")"} </span>;
			}
		}
	}

	subStepDetails(subSteps) {
		return (
			<div className="pl-25">
				{subSteps && subSteps.length ?
					subSteps.map((subStep, index) => {
						return (<div className="" key={index}>
							{subStep && subStep.name !== "stepId" && subStep.name !== "defaultSubStepId" && subStep.name !== "nameSuffix" ?
								<div className=""><div></div>
									<div className="">
										{this.getSubStepName(subStep)}
									</div>

								</div>
								: ""}
						</div>);
					})
					: ""}

			</div>
		);
	}

	callback() {}

	getPannelHeader = (step) => {
		return (
			<React.Fragment>
				<span><I18 tkey={step.checked ? step.stepName : ""} /> {step.startPrefix !== "" && step.checked ? this.getSuffix(step) : ""}</span>
			</React.Fragment>
		);
	};

	render() {

		return (
			<Collapse ghost={true} >
				{
					this.props.checkList.map((step, index) => 
						step.checked ?
							<Panel header={this.getPannelHeader(step)} key={index}>
								<div>
									{this.subStepDetails(step.subSteps, step.stepId, step)}
								</div>
							</Panel>
							: ""
					)
				}
			</Collapse>
		);
	}

}

ActiveComponents.propTypes = {
	actions: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	actions: store.actions,
	clinicalPathwayDetails: store.clinicalPathwayDetails,
	translations: store.translations
}))(ActiveComponents));