import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Select, Input, Tooltip, Popover, Button } from "antd";
import {
	BoldOutlined,
	FontColorsOutlined,
	ItalicOutlined,
	UnderlineOutlined,
	AlignLeftOutlined,
	AlignCenterOutlined,
	AlignRightOutlined,
	BgColorsOutlined
} from "@ant-design/icons";
import InterventionSelect from "../fieldComponets/select-intervention";
import ClinicalPathwaySelect from "../fieldComponets/select-clinical-pathway";
import I18, { i18Get } from "i18";
import utils from "utils/utils";
import { Option } from "antd/lib/mentions";
import Steps from "../fieldComponets/steps-substeps";
import CategoryPersonnelDropdown from "../fieldComponets/category-personnel-dropdown";
import SectionTypeDropDown from "../fieldComponets/section-type-dropdown";
import ProblemTypeDropDown from "../fieldComponets/problem-type-dropdown";
import "./style.css";
import EditorColorPicker from "../../diagram/editor-color-picker";
import SelectHealthPathWays from "../fieldComponets/select-healthpathway";
import Duration from "./duration";
import utilslFuntion from "../../../plugin/utils";

const SideBarEditTask = (props) => {
	const { TextArea } = Input;
	const [customData, setCustomData] = useState({});
	const [linkTo, setLinkTo] = useState("");
	const nodeServerData = useSelector( store => {
		if (store.healthPathway && store.healthPathway.diagramInfo) {

			return store.healthPathway.diagramInfo.find(e => e.id == customData.id);
		}
	});
	
	useEffect( () => {
		setCustomData(props.customNodeData);
	}, [props.customNodeData]);

	const onChangeData = (data, field ) => {
		props.onChangecustomNodeData(data, field);
	};

	useEffect( () => {
		setLinkTo(props.selectedShape.linkTo);
	}, [props.selectedShape.linkTo]);

	const fontBgColor = props.selectedShape.annotationFill ? props.selectedShape.annotationFill : "#000000";
	return (
		<div>
			<div className="">
				<div className="card-container">
					<div className="di w_100 diagram-data-header"></div>
					<div>

						<div className="d-flex justify-content-between">
							<div className="d-flex">
								<div className={`font_type_edit_task  ${props.selectedShape.bold ? "style_applied" : ""}`} onClick={() => props.styleHandler("textAlign", "left")}>
									<AlignLeftOutlined style={{
										fontSize: "17px",
										color: props.selectedShape.textAlign === "left" ? "#3740F2" : "#000000"
									}} />
								</div>
								<div className={`font_type_edit_task  ${props.selectedShape.bold ? "style_applied" : ""}`} onClick={() => props.styleHandler("textAlign", "center")}>
									<AlignCenterOutlined style={{
										fontSize: "17px",
										color: props.selectedShape.textAlign === "center" ? "#3740F2" : "#000000"
									}} />
								</div>
								<div className={`font_type_edit_task  ${props.selectedShape.bold ? "style_applied" : ""}`} onClick={() => props.styleHandler("textAlign", "right")}>
									<AlignRightOutlined style={{
										fontSize: "17px",
										color: props.selectedShape.textAlign === "right" ? "#3740F2" : "#000000"
									}} />
								</div>
								<div className="margin-left-8 edit_task_font popup_modal_content_input height_24_input">
									<Input type="number" value={props.selectedShape.fontSize} min={4} onChange={e => props.styleHandler("fontSize", e.target.value)} />
								</div>
								<div className={`font_type_edit_task  ${props.selectedShape.bold ? "style_applied" : ""}`} onClick={() => props.styleHandler("bold")}>
									<BoldOutlined />
								</div>
								<div className={`font_type_edit_task  ${props.selectedShape.italic ? "style_applied" : ""}`} onClick={() => props.styleHandler("italic")}>
									<ItalicOutlined />
								</div>
								<div className={`font_type_edit_task  ${props.selectedShape.underline ? "style_applied" : ""}`} onClick={() => props.styleHandler("underline")}>
									<UnderlineOutlined />
								</div>
								<div className="font_type_edit_task" >
									<EditorColorPicker onchange={ e => { props.styleHandler("color", e);}} 
										color={ props.selectedShape.color ? props.selectedShape.color : "#000"} activity="color" >
										<>
											<FontColorsOutlined />
											<div style={{ borderBottom: `3px solid ${props.selectedShape.color}`, position: "relative", bottom: "3px" }}></div>
										</>
									</EditorColorPicker>
								</div>
								<div className="font_type_edit_task" >
									<EditorColorPicker onchange={onChangeData} color={
										fontBgColor ? fontBgColor : "transparent"
									} activity="annotationFill" >
										<>
											<BgColorsOutlined  className="bgColorIcon" style={{ fontSize: "14px" }} />
											<div style={{ borderBottom: `3px solid ${fontBgColor}`, position: "relative", bottom: "3px" }}></div>
										</>
									</EditorColorPicker>
									
								</div>
							</div>
						</div>
						<div className="d-flex margin-top-10">
							<Tooltip placement="top" title={i18Get("Title", utils.getLanguage())} >
								<TextArea autoFocus
									placeholder={i18Get("Title", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
									style={{
										height: "60px",
										resize: "none",
										color: props.selectedShape.color,
										fontWeight: props.selectedShape.bold ? "bold" : "normal",
										fontStyle: props.selectedShape.italic ? "italic" : "normal",
										textDecoration: props.selectedShape.underline ? "underline" : "none",
										fontSize: parseFloat(props.selectedShape.fontSize),
										fontFamily: props.selectedShape.fontFamily,
										textAlign: props.selectedShape.textAlign
									}}
									maxLength={510}
									type="text" draggable={false} value={props.nodeName}
									onChange={(e) => { props.inputChangeHandler("nodename", e), onChangeData(e, "intervention");}}
								/>
							</Tooltip>
						</div>

						<div>
							<div className="d-flex align-items-end"></div>
							<div className="d-flex margin-top-10">
								<Tooltip placement="top" title={i18Get("Description", utils.getLanguage())} >
									<TextArea
										placeholder={i18Get("Description", utils.getLanguage())}
										className="w_90 edit-task-input-field"
										onFocus={(e) => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
										style={{
											height: "50px",
											resize: "none"
										}}
										maxLength={500}
										type="text" draggable={false} value={customData.interventionDetails}
										onChange={(e) => { onChangeData(e, "interventionDetails"); }}
									/>
								</Tooltip>
							</div>
						</div>


						<div className="d-flex margin-top-10 diagram-checkBox-list">
							<CategoryPersonnelDropdown
								customData={customData}
								data={customData.intervenants}
								categoryPersonnelChanged={(e) => { e.length < 10 ? onChangeData(e, "intervenants") : ""; }}
								clinicalPathway={true}
								isAdminPage={false}
								className={"w_90"}
								listActiveFirst={true}
							/>
						</div>
						
						<div className="d-flex margin-top-10 popup_modal_content_input">
							<Tooltip placement="top" title={i18Get("Unit Cost", utils.getLanguage())} >
								<Input className="w_90 edit-task-input-field" 
									placeholder={i18Get("Unit Cost", utils.getLanguage())}
									onChange={(e) => onChangeData(utilslFuntion.validCost(e.target.value, utilslFuntion.replaceComa( nodeServerData?.groupId ? nodeServerData.unitCost : customData?.unitCost ?? "")).toString().replace(",", "."),"unitCost")} 
									//(e.target.value.replace(/[^0-9]/g, ""), "unitCost")
									value={ nodeServerData?.groupId ? nodeServerData.unitCost : customData?.unitCost ?? ""}
									maxLength="10"
									defaultValue={0}
									disabled={!!(nodeServerData?.groupId)}
								/>

							</Tooltip>
						</div>

						<div className="d-flex margin-top-10 popup_modal_content_input">
							<Tooltip placement="top" title={i18Get("Location", utils.getLanguage())} >
								<Input className="w_90 edit-task-input-field" 
									placeholder={i18Get("Location", utils.getLanguage())}
									onChange={(e) =>  onChangeData(e, "location")} 
									value={customData.location}
									maxLength={64}
								 />
							</Tooltip>
						</div>
						
						<Duration key={customData.id} data={customData} onChange={props.onChangecustomNodeData} disabled={nodeServerData?.groupId}/>

						<div className="d-flex margin-top-10">
							<Tooltip placement="top" title={i18Get("Expected Results", utils.getLanguage())} >
								<TextArea 
									placeholder={i18Get("Expected Results", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									maxLength={1000}
									type="text" draggable={false}
									onChange={(e) =>  onChangeData(e, "expectedResults")} 
									value={customData.expectedResults}
								/>
							</Tooltip>
						</div>

						<div className="d-flex margin-top-10">
							<Tooltip placement="top" title={i18Get("Argumentation and Foundation", utils.getLanguage())} >
								<TextArea 
									placeholder={i18Get("Argumentation and Foundation", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									maxLength={1000}
									type="text" draggable={false} 
									onChange={(e) =>  onChangeData(e, "argumentation")} 
									value={customData.argumentation}
								/>
							</Tooltip>
						</div>

						<div className="d-flex margin-top-10">
							<Tooltip placement="top" title={i18Get("Risks", utils.getLanguage())} >
								<TextArea 
									placeholder={i18Get("Risks", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									maxLength={1000}
									type="text" draggable={false} 
									onChange={(e) =>  onChangeData(e, "risks")} 
									value={customData.risks}
								/>
							</Tooltip>
						</div>

						<div className="d-flex margin-top-10 margin-bottom-10">
							<Tooltip placement="top" title={i18Get("Reference", utils.getLanguage())} >
								<TextArea 
									placeholder={i18Get("Reference", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									maxLength={1000}
									type="text" draggable={false} 
									onChange={(e) =>  onChangeData(e, "reference")} 
									value={customData.reference}
								/>
							</Tooltip>
						</div>

	
						<div className="d-flex margin-top-10 select_link ">
							<Tooltip placement="top" title={i18Get("Link Type", utils.getLanguage())} >
								<Select 
									className="w_90" 
									allowClear={true} 
									placeholder={i18Get("Link Type", utils.getLanguage())}
									value={customData.linkType ? customData.linkType : undefined }  
									onChange={(e) =>  onChangeData(e, "linkType")} >
									<Option value="CLINICAL_PATHWAY"><I18 tkey='Clinical Pathway' /></Option>
									<Option value="INTERVENTION"><I18 tkey='Intervention' /></Option>
									<Option value="EXTERNAL_URL"><I18 tkey='External URL' /></Option>
									<Option value="HEALTH_PATHWAY"><I18 tkey='Health Pathway' /></Option>
								</Select>
							</Tooltip>
						</div>
						{customData.linkType === "EXTERNAL_URL" &&
									<div className="margin-top-15">
										<div>
											<span><I18 tkey='Link (include http:// or https://)' /></span>
										</div>
										<div className="position_relative externalink-diagram">
											<Input 
												maxLength={510} 
												type="text" 
												value={customData.linkTo}
												onChange={e => {props.inputChangeHandler("hyperlink", e), onChangeData(e.target.value, "linkTo");} } />
										</div>
									</div>
						}
						{customData.linkType === "INTERVENTION" &&
                            <div className="d-flex margin-top-10 min-height-40 select-link">
                            	<div className="w_90">
                            		<InterventionSelect
                            			defaultValue={i18Get("Select Intervention", utils.getLanguage())}
                            			value={customData.linkedItemCode}
                            			diagram={true}
                            			statusCheck={() => { }}
                            			onInterventionChange={props.onChangeFiledData}
										isApprovedList={true}
										customNodeData={props.customNodeData}
                            		/>
                            	</div>
                            </div>
						}
						{customData.linkType === "CLINICAL_PATHWAY" &&
                            <div className="d-flex margin-top-10 select-link min-height-40">
                            	<div className="w_90">
                            		<ClinicalPathwaySelect 
                            			defaultValue={i18Get("Select Clinical Pathway", utils.getLanguage())}
                            			value={customData.linkedItemCode}
                            			organization={"MOM"}
                            			diagram={true}
                            			statusCheck={() => { }}
                            			clinicalPathwayType={null}
                            			onClinicalPathwayChange={props.onChangeFiledData}
										healthPathwayData={props.healthPathwayData}
                            		/>
                            	</div>
								
                            </div>
						}
						{customData.linkType === "HEALTH_PATHWAY" &&
                            <div className="d-flex margin-top-10 select-link min-height-40">
                            	<div className="w_90">
                            		<SelectHealthPathWays 
                            			defaultValue={i18Get("Select Clinical Pathway", utils.getLanguage())}
                            			value={customData.linkedItemCode}
                            			onchange={props.onChangeFiledData}
                            			healthPathwayData={props.healthPathwayData}
                            		/>
                            	</div>
								
                            </div>
						}

						{props.selectedShape.linkTo ?
							<div className="d-flex ">
								<div className="text-right w_90" >
									<a className="diagram_route_link" target="_blank" href={linkTo} rel="noreferrer"><I18 tkey='Test link' /></a>
								</div>
							</div>
							: ""
						} 

						<div className="d-flex margin-top-10">
							<SectionTypeDropDown
								value={customData.phase ? customData.phase : null }
								onChange={(e) => { onChangeData(e, "phase"); }}
								allowClear={false}
								className="w_90"
								placeholder={i18Get("Phase", utils.getLanguage())}
							/>
						</div>

						<div className="d-flex margin-top-10">
							{ customData.phase ? <ProblemTypeDropDown
								className="w_90 select-link"
								data={customData.type ? customData.type : null}
								customData={customData}
								onChange={(e) => { e.length < 11 ? onChangeData(e, "type") : ""; }}
								stepSectionType={customData.phase}
								isCreate={true}
								isMulti={ true }
								
							/> :
								<Tooltip placement="top" title={i18Get("Type", utils.getLanguage())} >
									<Select 
										disabled={true} 
										className="w_90" 
										allowClear={true} 
										placeholder={i18Get("Type", utils.getLanguage())}
									>
									</Select>
								</Tooltip>
							}
						</div>

						<div className="d-flex margin-top-10 popup_modal_content_input">
							<Tooltip placement="top" title={i18Get("Problem", utils.getLanguage())} >
								<Input className="w_90 edit-task-input-field" 
									placeholder={i18Get("Problem", utils.getLanguage())}
									onChange={(e) =>  onChangeData(e, "problems")} 
									value={customData.problems}
									maxLength={ 120 }/>
							</Tooltip>
						</div>

						<div className="d-flex margin-top-10">
							<Tooltip placement="top" title={i18Get("data", utils.getLanguage())} >
								<TextArea 
									placeholder={i18Get("Data", utils.getLanguage())}
									className="w_90 edit-task-input-field"
									maxLength={245}
									type="text" draggable={false} 
									onChange={(e) =>  onChangeData(e, "data")} 
									value={customData.data}
								/>
							</Tooltip>
						</div>
						<Steps 
							customData={customData}
							onChangeData={onChangeData}
							treatmentId={props.treatmentId} 
							createStepClicked={props.createStepClicked}
							createSubStepClicked={props.createSubStepClicked}
							onStepSearchData={props.onStepSearchData}
							onSubStepSearchData={props.onSubStepSearchData}
							inputStepName={props.inputStepName} 
							createdSubStepName={props.createdSubStepName}
							clearCreatedSubStepName={props.clearCreatedSubStepName}
							clearCreatedStepName={props.clearCreatedStepName}
							selectedShape={props.selectedShape}
							healthPathwayData={props.healthPathwayData}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SideBarEditTask;