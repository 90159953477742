import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { MenuOutlined } from "@ant-design/icons";
import { Modal, Button, Table, Checkbox } from "antd";
import extClinicalPathwayActions from "../../../../../../../../../actions/ext-clinical-pathway";
import events from "../../../../../../../../../actions/events";
import { sortableContainer, sortableElement, sortableHandle } from "react-sortable-hoc";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import DragWindow from "../../../drag-window";
import { Resizable } from "react-resizable";
import arrayMove from "array-move";

const DragHandle = sortableHandle(({ active }) => (
	<MenuOutlined style={{ cursor: "grab", color: active ? "blue" : "#999" }} />
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);
const ResizableTitle = props => {
	const { onResize, width, ...restProps } = props;

	if (!width) {
		return <th {...restProps} />;
	}

	return (
		<Resizable
			width={width}
			height={0}
			handle={
				<span
					className="react-resizable-handle"
					onClick={e => {
						e.stopPropagation();
					}}
				/>
			}
			onResize={onResize}
			draggableOpts={{ enableUserSelectHack: false }}
		>
			<th {...restProps} />
		</Resizable>
	);
};

class QuickReorder extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			totalPages: 1,
			list: [],
			sorted: [],
			orderChanged: false,
			itemWidth: 300,
			selectedItems: [],
			unSelect: false
		};
	}

	UNSAFE_componentWillMount() {
		this.state.list = JSON.parse(
			JSON.stringify(this.props.quickReorderData.data)
		).filter(value => value.isExpand != true);
	}

	onClickSaveOrder() {
		const newData = JSON.parse(JSON.stringify(this.state.list));
		const selectedData = [];
		newData.map(function (data, index) {
			selectedData.push({ id: (data.isHeading) ? data.checkListTreatmentId : data.id, rank: index + 1, isHeading: (data.isHeading) ? true : false, problemId: data.problemId });
		});
		if (selectedData && selectedData.length > 0) {
			this.setState({ loading: true });
			this.props.dispatch({ type: events.ACTION_ONE });
			this.props.dispatch(
				extClinicalPathwayActions.extClpRankUpdation(
					this.props.match.params.id,
					this.props.quickReorderDataSteps.id,
					this.props.quickReorderData.id,
					this.props.quickReorderData.day,
					selectedData
				)
			);
		}
	}

	onClickUnselect = (e) => {
		this.setState({ unSelect: e.target.checked, selectedItems: [] });
	};

	renderStyledCell = (text, record) => ({
		props: {
			style: { background: "" }
		},
		children: (
			<div className="d-flex">
				<div className="">{text ? this.getText(text, record) : ""}</div>
			</div>
		)
	});

	getText = (value, data) => {
		return (
			<div>
				<div className={data && data.isCheckList ? data.isHeading ? "check_list check_list_bold font_size_14px" : "check_list" : ""}>
					<span> {value} </span>
				</div>
			</div>
		);
	};

	handleResize = label => (e, { size }) => {
		switch (label) {
			case "itemWidth":
				this.setState({ itemWidth: size.width });
				break;
			default:
				break;
		}
	};

	onCheckBoxChange = (e, i, id) => {
		const prevState = this.state;
		const index = this.state.selectedItems.findIndex(value => value.id === id);
		if (index === -1) {
			prevState.selectedItems.push({ id: id, index: i });
		} else {
			prevState.selectedItems.splice(index, 1);
		}
		this.setState(prevState);
	};

	getColumns(selectedItems) {
		return [
			{
				title: "",
				dataIndex: "id",
				className: "",
				width: 60,
				render: (text, record, index) => (
					<div className="">
						<DragHandle active={selectedItems.length > 0 && selectedItems.findIndex((x) => x.index === index) !== -1} />
						<span className="margin-left-10" >
							<Checkbox
								checked={selectedItems.length > 0 && selectedItems.findIndex(value => value.id === text) !== -1}
								onChange={!this.state.unSelect ? (e) => this.onCheckBoxChange(e, index, text) : ""} />
						</span>
					</div>
				)
			},
			{
				title: i18Get("ITEM", utils.getLanguage()),
				dataIndex: "item",
				className: "drag-visible",
				width: this.state.itemWidth,
				render: (text, record) => this.renderStyledCell(record.item, record),
				onHeaderCell: column => ({
					width: column.width,
					onResize: this.handleResize("itemWidth")
				})
			},
			{
				title: i18Get("INTERVENANT", utils.getLanguage()),
				dataIndex: "categoryPersonnelName",
				className: "drag-visible",
				render: (text, record) =>
					this.renderStyledCell(record.categoryPersonnelName, record)
			}
		];
	}

	merge(a, b, i = 0) {
		const aa = [...a];
		return [...a.slice(0, i), ...b, ...aa.slice(i, aa.length)];
	}

	updateIndex(tempDataSource) {
		const prevState = this.state;
		prevState.selectedItems.map(function (data, i) {
			const indexValue = tempDataSource.findIndex(value => value.id === data.id);
			if (indexValue !== -1) {
				prevState.selectedItems[i].index = indexValue;
			}
		});
		this.setState({ selectedItems: prevState.selectedItems });
	}

	getTaskList(list) {
		return (
			<div>
				<Table
					pagination={false}
					dataSource={list ? list : ""}
					columns={this.getColumns(this.state.selectedItems)}
					bordered
					rowKey="id"
					key={0}
					components={{
						header: {
							cell: ResizableTitle
						},
						body: {
							wrapper: (e) => this.DraggableContainer(e, list),
							row: (e) => this.DraggableBodyRow(e, list ? list : null)
						}
					}}
				/>
			</div>
		);
	}

	DraggableContainer = (props) => (
		<SortableContainer
			useDragHandle
			disableAutoscroll
			helperClass="row-dragging"
			onSortEnd={(e) => this.onSortEnd(e)}
			{...props}
		/>
	);

	DraggableBodyRow = ({ ...restProps }, subStep) => {
		// function findIndex base on Table rowKey props and should always be a right array index
		const index = subStep.findIndex((x) => x.id === restProps["data-row-key"]);
		return (
			<SortableItem
				className = "className" 
				style = "style"
				index={index}
				{...restProps}
				selected={this.state.selectedItems.length}
			/>
		);
	};

	onSortEnd = ({ oldIndex, newIndex }) => {
		const { selectedItems, list } = this.state;
		let tempDataSource = JSON.parse(JSON.stringify(list));

		if (oldIndex !== newIndex) {
			if (!selectedItems.length) {
				tempDataSource = arrayMove([].concat(tempDataSource), oldIndex, newIndex).filter((el) => !!el);
			}
			else {
				const filteredItems = [];
				selectedItems.forEach((d) => {
					filteredItems.push(tempDataSource[d.index]);
				});
				const newData = [];
				tempDataSource.forEach((d, i) => {
					if (selectedItems.findIndex((x) => x.index === i) === -1) {
						newData.push(d);
					}
				});
				tempDataSource = [...newData];
				tempDataSource = this.merge(tempDataSource, filteredItems, newIndex);
				this.updateIndex(tempDataSource, selectedItems);
			}
			this.setState({ list: tempDataSource, orderChanged: true });
		}
	};

	confirmation = () => {
		const { confirm } = Modal;
		if (this.state.orderChanged) {
			confirm({
				title: i18Get("Do you want to leave without saving?", utils.getLanguage()),
				icon: <ExclamationCircleOutlined />,
				content: "",
				okText: i18Get("Ok", utils.getLanguage()),
				cancelText: i18Get("Cancel", utils.getLanguage()),
				onOk: () => {
					this.props.disableProblemListReorder();
				},
				onCancel: () => {
				}
			});
		} else {
			this.props.disableProblemListReorder();
		}
	};
	title = () => { return (<div className="position_relative"><DragWindow title={i18Get("Quick Reorder", utils.getLanguage())} /></div>); };
	render() {
		return (
			<div>
				<Modal
					className="re_assign_modal create_problem create_additional quick_reorder_table_modal"
					title={this.title()}
					visible={this.props.enableQuickProblemListReorder}
					onCancel={this.confirmation}
					wrapClassName="drag_modal"
					footer={[
						<Button key="back" onClick={this.confirmation}>
							<I18 tkey="Cancel" />
						</Button>,
						<Button
							key="submit"
							type="primary"
							onClick={this.onClickSaveOrder.bind(this)}
							loading={this.state.loading}
						>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left margin-left-10" >
								{this.state.selectedItems.length} <I18 tkey="items selected" />
							</span>
							: "",
						this.state.selectedItems.length ?
							<span className="margin-right-10 float_left" >
                (<a className="text_underline" onClick={(e) => this.onClickUnselect(e)}>
									<I18 tkey="Deselect All" />
								</a>)
							</span> : ""
					]}
				>
					<div className="">{this.getTaskList(this.state.list)}</div>
				</Modal>
			</div>
		);
	}
}

QuickReorder.propTypes = {
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayDetails: PropTypes.object.isRequired,
	QuickReorder: PropTypes.object.isRequired
};

export default withRouter(
	connect((store) => ({
		actions: store.actions,
		clinicalPathwayDetails: store.clinicalPathwayDetails,
		QuickReorder: store.extClinicalPathway,
		translations: store.translations
	}))(QuickReorder)
);
