import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18 from "../../../../../../../../i18";
import SpecialityDropdown from "../../../activities/specialities/selectSpecialities";
import FamilyDropdown from "../../../activities/activity-families/selectFamilyForSpeciality";
import typeActivity from "../../../../../../../../constants/typeActivity.json";
import TypeDropdown from "./typedropdown";
import utils from "../../../../../../../../utils/utils";

class ConsumableCreateClass extends Component {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			<div className="bloc_create_section padding-0">
				<div className="bloc_create_box">
					<div className="row">
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Class Name" /></label>
								{this.props.invalidClassName && <span className="invalid_right fixed_label"><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
								<input className="form-control" value={this.props.newClassName} type="text" onChange={this.props.onClassNameChange} />
							</div>
						</div>
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Speciality" /></label>
								<SpecialityDropdown
									autoSetSingleOne = {true}
									data={this.props.speciality}
									specialityChanged={this.props.specialityChanged}
									typeActivity={typeActivity.INTERVENTION.toUpperCase()}
								/>
							</div>
						</div>
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Family" /></label>
								<FamilyDropdown
									autoSetSingleOne = {true}
									data={this.props.family}
									onFamilyChanged={this.props.onFamilyChanged}
									specialityId={this.props.speciality ? this.props.speciality.value : utils.sameDropper()}
									typeActivity={typeActivity.INTERVENTION.toUpperCase()}
								/>
								{this.props.invalidFamily && <span className="invalid fixed_label"><I18 tkey="Enter valid family" /></span>}
							</div>
						</div>
						<div className="col-md-12">
							<div className="w_100 di margin-bottom-20 position_relative">
								<label><I18 tkey="Type" /></label>
								<TypeDropdown typeChange={this.props.typeChange} />
								{this.props.invalidType && <span className="invalid fixed_label"><I18 tkey="Please  select type" /></span>}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

ConsumableCreateClass.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	translations: store.translations
}))(ConsumableCreateClass));
