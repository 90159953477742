import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import actions from "../../../../../../../actions/sterilization";
import events from "../../../../../../../actions/events";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";

export class Edit extends Component {

	constructor(props) {
		super(props);
    
		this.state = {
			specialty: {},
			invalidName: false
		};
	}

	nameChanged = (value) => {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.specialty.name = value;
		this.setState(prevState);
	};

	validateFields = () => {
		const prevState = this.state;
		if (!utils.checkNameRegex(this.state.specialty.name) || !(this.state.specialty.name.trim())) {
			prevState.invalidName = true;
			this.setState(prevState);
			return false;
		}
		return true;
	};
    
	saveClicked = () => {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.specialty.name,
				consumableType: "STERILIZATION"
			};
			this.props.dispatch(actions.updateConsomableSpecialty(this.state.specialty.id, payload));
		}
	};

	render() {
		return (
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
                
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								<input  className="w_100 di form-control" value={this.state.specialty.code} readOnly/> 
							</div>
						</div>
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.specialty.name}
									inputChanged={this.nameChanged}
									maxLength={60}
								/>
								{this.state.invalidName && <span className='invalid_right fixed_label'><I18 tkey="Field should only contain (a-zA-ZÀ-ž0-9 ()+ _-',.)" /></span>}
							</div>
						</div>
                    
						<div className="col-md-12">
							<div className="di w_100 text_align_right">                                
								<ActionButton onClick={this.saveClicked} value={i18Get("SAVE", utils.getLanguage())}/>
							</div>
						</div>
					</div>
				</div>
			</div>

		);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch(actions.getOneConsomableSpeciality(this.props.match.params.id));
	}

	componentDidUpdate() {
		if (this.props.sterilization.getOneConsomableSpeciality === "FULFILLED") {
			this.setState({ specialty: this.props.sterilization.consomableSpeciality }, () => {
				this.props.dispatch(actions.getOneConsomableSpecialityCompleted());
				this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { specialtyData: { name: this.state.specialty.name, id: this.state.specialty.id } } });
			});
           
		}
		if (this.props.sterilization.getOneConsomableSpeciality === "REJECTED") {
			this.props.history.push(locations.sterilization_speciality);
			this.props.dispatch(actions.getOneConsomableSpecialityCompleted());
		}
		if (this.props.sterilization.updateConsomableSpecialtyStatus === "FULFILLED") {
			this.state.specialty = {};
			this.props.dispatch(actions.updateConsomableSpecialtyCompleted());
			this.props.history.push(locations.sterilization_speciality);
			this.setState({ ...this.state });
		}
	}

    
}


Edit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	specialty: PropTypes.object.isRequired,
	specialtyModel: PropTypes.object.isRequired,
	sterilization: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	specialty: store.specialty,
	translations: store.translations,
	specialtyModel: store.specialtyModel,
	sterilization: store.sterilization
}))(Edit));  