import React from "react";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";
import I18, { i18Get } from "../../../../../../../../../i18";
import utils from "../../../../../../../../../utils/utils";
import { statusList } from "../../../../../../../../../constants";

function getText(value) {
	return <div>{value}</div>;
}

export default function getCols(typeActivityProps, editClicked, deleteClicked, showRank, approveClicked) {
	const tableArray = ([
		{
			Header: i18Get("ACTIONS", utils.getLanguage()),
			id: "actions",
			accessor: (d) => d,
			width: 80,
			resizable: false,
			sortable: false,
			Cell: (row) => (
				<div className="margin_left_auto">
					{row.value.status === statusList.STATUS.values.APPROVED
						? <EditOutlined className="edit_color margin-right-10" type="edit" onClick={editClicked(row.value)} />
						: (
							<a className="waiting-for-approve" onClick={approveClicked(row.value)}>
								<img src="/images/waitingforapprovel.png" />
								{" "}
							</a>
						)}
					{row.value.status === statusList.STATUS.values.APPROVED
						? <DeleteOutlined  className="delete_color" type="delete" onClick={deleteClicked(row.value)} /> : ""}
				</div>
			)
		},
		// {
		//     Header: i18Get('RANK', utils.getLanguage()),
		//     id: 'rank',
		//     accessor: d => d,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? showRank(row.value) : ''
		//     )
		// },
		{
			Header: i18Get("CATEGORY PERSONNEL NAME", utils.getLanguage()),
			id: "name",
			accessor: (d) => d.name,
			resizable: true,
			width: 250,
			Cell: (row) => (
				getText(row.value)
			)
		},
		{
			Header: i18Get("CATEGORY PERSONNEL NUMBER", utils.getLanguage()),
			id: "numberOfPersonnels",
			accessor: (d) => d.numberOfPersonnels,
			width: 100,
			resizable: true,
			Cell: (row) => (
				getText(utils.setLocaleForCurrency(row.value))
			)
		},
		{
			Header: i18Get("FIXED DURATION", utils.getLanguage()),
			id: "customTime",
			accessor: (d) => d.customTime,
			resizable: true,
			width: 100,
			Cell: (row) => (
				row.value ? row.value : 0
			)
		},
		{
			Header: i18Get("BLOC OPERATOIRE", utils.getLanguage()),
			id: "isBlocOperative",
			accessor: (d) => d.isBlocOperative,
			width: 100,
			resizable: true,
			Cell: (row) => (
				row.value ? <I18 tkey="YES" /> : <I18 tkey="NO" />
			)
		},
		{
			Header: i18Get("BLOC PERI OPERATOIRE", utils.getLanguage()),
			id: "isBlocPeriOperative",
			accessor: (d) => d.isBlocPeriOperative,
			width: 100,
			resizable: true,
			Cell: (row) => (
				row.value ? <I18 tkey="YES" /> : <I18 tkey="NO" />
			)
		},
		{
			Header: i18Get("BLOC SUPPORT", utils.getLanguage()),
			id: "isBlocSupport",
			accessor: (d) => d.isBlocSupport,
			width: 100,
			resizable: true,
			Cell: (row) => (
				row.value ? <I18 tkey="YES" /> : <I18 tkey="NO" />
			)
		},
		// {
		//     Header: i18Get('ACTIVITY STEP', utils.getLanguage()),
		//     id: 'activityStepName',
		//     accessor: d => d.activityStepName,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         getText(row.value)
		//     )
		// },
		// {
		//     Header: i18Get('POST COUNT', utils.getLanguage()),
		//     id: 'postCount',
		//     accessor: d => d.postCount,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? row.value : 0
		//     )
		// },
		// {
		//     Header: i18Get('PATIENT DURATION', utils.getLanguage()),
		//     id: 'patientDuration',
		//     accessor: d => d.patientDuration,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? row.value : 0
		//     )
		// },
		// {
		//     Header: i18Get('VARIABLE', utils.getLanguage()),
		//     id: 'variable',
		//     accessor: d => d.variable,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? <I18 tkey='YES'/> : <I18 tkey='NO' />
		//     )
		// },
		// {
		//     Header: i18Get('SALARY PER MINUTE', utils.getLanguage()),
		//     id: 'salaryPerMin',
		//     accessor: d => d.salaryPerMin,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? row.value : 0
		//     )
		// },
		// {
		//     Header: i18Get('EQUIPMENT', utils.getLanguage()),
		//     id: 'equipment',
		//     accessor: d => d.equipment,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? <I18 tkey='YES'/> : <I18 tkey='NO' />
		//     )
		// },
		// {
		//     Header: i18Get('TOTAL COST', utils.getLanguage()),
		//     id: 'totalCost',
		//     accessor: d => d.totalCost,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         getText(utils.setLocaleForCurrency(row.value ? row.value : 0.00))
		//     )
		// },

		// {
		//     Header: i18Get('DURATION PER LINE', utils.getLanguage()),
		//     id: 'durationPerLine',
		//     accessor: d => d.durationPerLine,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         getText(row.value ? row.value : 0)
		//     )
		// },
		// {
		//     Header: i18Get('PATIENT', utils.getLanguage()),
		//     id: 'patient',
		//     accessor: d => d.patient,
		//     show: (typeActivityProps === typeActivity.CHEMOTHERAPY.toUpperCase()) || (typeActivityProps === typeActivity.IMAGERY.toUpperCase()) || (typeActivityProps === typeActivity.RADIOTHERAPY.toUpperCase()),
		//     resizable: true,
		//     Cell: row => (
		//         row.value ? <I18 tkey='YES'/> : <I18 tkey='NO' />
		//     )
		// },
		{
			Header: i18Get("HOSPITAL NAME", utils.getLanguage()),
			id: "hospitalName",
			accessor: (d) => d.hospitalName,
			width: 200,
			resizable: true,
			Cell: (row) => (
				getText(row.value ? row.value : "Master")
			)
		}, {
			Header: i18Get("STATUS", utils.getLanguage()),
			id: "status",
			accessor: (d) => d.status,
			width: 150,
			resizable: true,
			Cell: (row) => (
				getText(row.value ? row.value === "APPROVED" ? "Approved" : "Waiting For Approval" : "")
			)
		}
	]);
	return tableArray;
}
