import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Checkbox, Tooltip } from "antd";
import adminPersonnelActions from "../../../../../../../../actions/admin-personnel";
import ActionButton from "../../../../../../../../plug/action-button";
import locations from "../../../../../../../../router/locations";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import events from "../../../../../../../../actions/events";
import eventNames from "../../../../../../../../actions/events";
import screenId from "../../../../../../../../router/screenIdList";
import PersonnelNumberDropdown from "../create/template-details/personnel-number-dropdown";
import NumberInput from "../../../../../../../../plug/numeric-input";

class EditTemplateDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			templateDetails: {}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	_handleKeyDown(e) {
		if (e.keyCode == 13) {
			this.saveClicked();
		}
	}

	UNSAFE_componentWillMount() {
		this.state.template = this.props.template ? this.props.template : utils.checkForParameter("templateId", this.props.location);
		this.state.typeActivity = this.props.typeActivity ? this.props.typeActivity : utils.checkForParameter("typeActivity", this.props.location);
		this.props.dispatch({ type: eventNames.BADGE_SCREEN_ID, payload: screenId.adminPersonnelEdit });
	}

	componentDidMount() {
		this.props.dispatch(adminPersonnelActions.fetchOneTemplateDetails(this.props.match.params.id));
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: {} });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	categoryPersonnelNumberChanged(e) {
		const prevState = this.state;
		prevState.templateDetails.numberOfPersonnels = e;
		prevState.invalidCategoryPersonnelNumber = false;
		this.setState(prevState);
	}

	activityStepNameChanged(e) {
		const prevState = this.state;
		prevState.templateDetails.activityStepName = e;
		this.setState(prevState);
	}

	getNumericValue(label, value) {
		const prevState = this.state;
		prevState.templateDetails[label] = parseInt(value);
		if ((label === "customTime") && parseInt(value) > 0) {
			prevState.templateDetails.isBlocOperative = false;
			prevState.templateDetails.isBlocPeriOperative = false;
			prevState.templateDetails.isBlocSupport = false;
		}
		prevState.invalidCheckbox = false;
		this.setState(prevState);
	}

	handleCheckboxChange(field) {
		return (e) => {
			const prevState = this.state;
			prevState.templateDetails[field] = e.target.checked;
			prevState.invalidCheckbox = false;
			this.setState(prevState);
		};
	}

	validateFields() {
		const prevState = this.state;
		// if(this.state.typeActivity != 'RADIOTHERAPY' &&
		//     this.state.typeActivity != 'CHEMOTHERAPY' &&
		//     this.state.typeActivity != 'IMAGERY' &&
		//     !this.state.templateDetails.numberOfPersonnels && (this.state.templateDetails.numberOfPersonnels !== 0)) {
		//     prevState.invalidCategoryPersonnelNumber = true;
		// }
		if (!(this.state.templateDetails.customTime > 0) && !this.state.templateDetails.isBlocOperative && !this.state.templateDetails.isBlocPeriOperative && !this.state.templateDetails.isBlocSupport) {
			prevState.invalidCheckbox = true;
		}
		this.setState(prevState);
		if (this.state.invalidCategoryPersonnelNumber || this.state.invalidCheckbox) {
			return false;
		}
		return true;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				numberOfPersonnels: this.state.templateDetails.numberOfPersonnels,
				customTime: this.state.templateDetails.customTime,
				isBlocOperative: this.state.templateDetails.isBlocOperative,
				isBlocPeriOperative: this.state.templateDetails.isBlocPeriOperative,
				isBlocSupport: this.state.templateDetails.isBlocSupport
			};
			// if(this.state.typeActivity === 'RADIOTHERAPY' ||
			// this.state.typeActivity === 'CHEMOTHERAPY' ||
			// this.state.typeActivity === 'IMAGERY') {
			//     payload.activityStepId = this.state.templateDetails.activityStepName ? this.state.templateDetails.activityStepName.value : null;
			//     payload.postCount = this.state.templateDetails.postCount;
			//     payload.patientDuration = this.state.templateDetails.patientDuration;
			//     payload.durationPerLine = this.state.templateDetails.durationPerLine;
			//     payload.equipment = this.state.templateDetails.equipment;
			//     payload.patient = this.state.templateDetails.patient;
			// }
			this.props.dispatch(adminPersonnelActions.updateTemplateDetails(this.state.templateDetails.id, payload));
		}
	}

	editAdminPersonnel() {
		return (
			<div>
				<div className="row">
					<div className="col-md-6 col-sm-6">
						<div className="w_100 di margin-bottom-10 position_relative">
							<label><I18 tkey="Category Personnel Name" /></label>
              :
							<input className="form-control w_100" value={this.state.templateDetails.name} readOnly />
						</div>
					</div>
					{this.state.typeActivity != "RADIOTHERAPY"
						&& this.state.typeActivity != "CHEMOTHERAPY"
						&& this.state.typeActivity != "IMAGERY"
						? (
							<div className="col-md-6 col-sm-6">
								<div className="w_100 di margin-bottom-10 position_relative">
									<label><I18 tkey="Category Personnel Number" /></label>
									<PersonnelNumberDropdown
										categoryPersonnelNumber={this.state.templateDetails.numberOfPersonnels}
										categoryPersonnelNumberChanged={this.categoryPersonnelNumberChanged.bind(this)}
									/>
									{this.state.invalidCategoryPersonnelNumber
										? <span className="invalid_right fixed_label"><I18 tkey="Select quantity" /></span> : ""}
								</div>
							</div>
						)
						:
						// <div className="col-md-6 col-sm-6 ">
						//     <div className="w_100 di margin-bottom-10 position_relative">
						//         <label><I18 tkey="Post Count" /></label>
						//         <NumberInput
						//             integerOnly
						//             label="postCount"
						//             value={this.state.templateDetails.postCount}
						//             getNumericValue={this.getNumericValue.bind(this)}/>
						//     </div>
						// </div>
						""
					}
				</div>
				<div className="row">
					<div className="col-md-4 col-lg-6">
						<div className="w_100 di margin-bottom-10 position_relative">
							<label><I18 tkey="Fixed Duration" /></label>
							<NumberInput
								integerOnly
								label="customTime"
								value={this.state.templateDetails.customTime}
								getNumericValue={this.getNumericValue.bind(this)}
							/>
						</div>
					</div>
					<div className="col-lg-6 col-md-8 col-sm-8 margin-top-33 position_relative">
						{this.state.invalidCheckbox
							? <span className="invalid_check_box fixed_label"><I18 tkey="Select atleast one duration" /></span>
							: ""}
						{this.state.typeActivity !== "CLINICAL_PATHWAY"
							&& (
								<div className="row">
									<div className="template_details_check_box_width_30">
										<Tooltip title={i18Get("Operative time of the surgery", utils.getLanguage())} placement="top">
											<Checkbox
												checked={this.state.templateDetails.isBlocOperative}
												disabled={this.state.templateDetails.customTime > 0}
												onChange={this.handleCheckboxChange("isBlocOperative").bind(this)}
											>
												<I18 tkey="Bloc Operatoire" />
											</Checkbox>
										</Tooltip>
									</div>
									<div className="template_details_check_box_width_40">
										<Tooltip title={i18Get("Preoperative time of the surgery", utils.getLanguage())} placement="top">
											<Checkbox
												checked={this.state.templateDetails.isBlocPeriOperative}
												disabled={this.state.templateDetails.customTime > 0}
												onChange={this.handleCheckboxChange("isBlocPeriOperative").bind(this)}
											>
												<I18 tkey="Bloc Peri Operatoire" />
											</Checkbox>
										</Tooltip>
									</div>
									<div className="template_details_check_box_width_30">
										<Tooltip title={i18Get("Support time of the surgery", utils.getLanguage())} placement="top">
											<Checkbox
												checked={this.state.templateDetails.isBlocSupport}
												disabled={this.state.templateDetails.customTime > 0}
												onChange={this.handleCheckboxChange("isBlocSupport").bind(this)}
											>
												<I18 tkey="Bloc Support" />
											</Checkbox>
										</Tooltip>
									</div>
								</div>
							)}
					</div>
				
					{/* {(this.state.typeActivity === 'RADIOTHERAPY' ||
                this.state.typeActivity === 'CHEMOTHERAPY' ||
                this.state.typeActivity === 'IMAGERY') ?
                <div>
                <div className="row">
                <div className="col-md-6 col-sm-6">
                    <div className="w_100 di margin-bottom-10 position_relative">
                        <label><I18 tkey="Activity Step" /></label>
                        <ActivityStepDropdown
                            data={this.state.templateDetails.activityStepName}
                            typeActivity={this.state.typeActivity}
                            activityStepNameChanged={this.activityStepNameChanged.bind(this)}/>
                    </div>
                </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 ">
                        <div className="w_100 di margin-bottom-10 position_relative">
                            <label><I18 tkey="Patient Duration" /></label>
                            <NumberInput
                                integerOnly
                                label="patientDuration"
                                value={this.state.templateDetails.patientDuration}
                                getNumericValue={this.getNumericValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="col-md-6 col-sm-6 ">
                        <div className="w_100 di margin-bottom-10 position_relative">
                            <label><I18 tkey="Salary Per Minute" /></label>
                            <NumberInput
                                disabled
                                integerOnly
                                label="salaryPerMin"
                                value={this.state.templateDetails.salaryPerMin}
                                getNumericValue={this.getNumericValue.bind(this)}/>
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-md-4 col-lg-6 ">
                        <div className="w_100 di margin-bottom-10 position_relative">
                            <label><I18 tkey="Duration Per Line" /></label>
                            <NumberInput
                                integerOnly
                                label="durationPerLine"
                                value={this.state.templateDetails.durationPerLine}
                                getNumericValue={this.getNumericValue.bind(this)}/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-8 col-sm-8 margin-top-33 position_relative">
                        <div className="row">
                            <div className="template_details_check_box_width_30">
                                <Tooltip title={i18Get('Variable', utils.getLanguage())} placement="top">
                                    <Checkbox
                                        disabled
                                        checked={this.state.templateDetails.variable}
                                        onChange={this.handleCheckboxChange('variable').bind(this)}>
                                            <I18 tkey="Variable" />
                                    </Checkbox>
                                </Tooltip>
                            </div>
                            <div className="template_details_check_box_width_30">
                                <Tooltip title={i18Get('Equipment', utils.getLanguage())} placement="top">
                                    <Checkbox
                                        checked={this.state.templateDetails.equipment}
                                        onChange={this.handleCheckboxChange('equipment').bind(this)}>
                                            <I18 tkey="Equipment" />
                                    </Checkbox>
                                </Tooltip>
                            </div>
                            <div className="template_details_check_box_width_40">
                                <Tooltip title={i18Get('Patient', utils.getLanguage())} placement="top">
                                    <Checkbox
                                        checked={this.state.templateDetails.patient}
                                        onChange={this.handleCheckboxChange('patient').bind(this)}>
                                            <I18 tkey="Patient" />
                                    </Checkbox>
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    </div>
                </div> : ''} */}
					<div className="col-sm-12 padding-bottom-10">
						<div className="di w_100 text_align_right">
							<ActionButton
								onClick={this.saveClicked.bind(this)}
								value={i18Get("SAVE", utils.getLanguage())}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		if (this.props.adminPersonnel.fetchOneTemplateDetailsCompleted === "FULFILLED") {
			this.state.templateDetails = this.props.adminPersonnel.templateDetails;
			if (this.state.templateDetails.activityStepId) {
				const step = {
					label: this.state.templateDetails.activityStepName,
					value: this.state.templateDetails.activityStepId
				};
				this.state.templateDetails.activityStepName = step;
			}
			if (this.state.templateDetails && Object.keys(this.state.templateDetails).length) {
				this.props.dispatch({ type: eventNames.BREADCRUMB_DATA, payload: { id: this.state.templateDetails.id, name: this.state.templateDetails.name } });
			}
			this.props.dispatch(adminPersonnelActions.clearFetchOneTemplateDetails());
		}
		if (this.props.adminPersonnel.fetchOneTemplateDetailsCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearFetchOneTemplateDetails());
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabTemplateDetails}${this.props.location.search}`);
		}
		if (this.props.adminPersonnel.updateTemplateDetailsCompleted === "FULFILLED") {
			this.props.dispatch(adminPersonnelActions.clearUpdateTemplateDetails());
			this.props.history.push(`${locations.adminPersonnel}/${locations.tabTemplateDetails}${this.props.location.search}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminPersonnel.updateTemplateDetailsCompleted === "REJECTED") {
			this.props.dispatch(adminPersonnelActions.clearUpdateTemplateDetails());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (
			<div className="bloc_create_section">
				<div className="bloc_create_box">
					{this.state.templateDetails && this.editAdminPersonnel()}
				</div>
			</div>
		);
	}
}

EditTemplateDetails.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	adminPersonnel: PropTypes.object.isRequired,
	categoryPersonnel: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminPersonnel: store.adminPersonnel,
	categoryPersonnel: store.categoryPersonnel,
	actions: store.actions
}))(EditTemplateDetails));
