import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import adminClinicalPathwayActions from "../../../../../../../actions/admin-clinical-pathway";
import I18, { i18Get } from "../../../../../../../i18";
import utils from "../../../../../../../utils/utils";
import ActionButton from "../../../../../../../plug/action-button";
import locations from "../../../../../../../router/locations";
import NameInput from "../../../../../../../plug/regex-name-search-input";
import events from "../../../../../../../actions/events";
import screenId from "../../../../../../../router/screenIdList";

class EditSubStepDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			subStepDetails: {}
		};
		this._handleKeyDown = this._handleKeyDown.bind(this);
	}

	UNSAFE_componentWillMount() {
		this.props.dispatch({ type: events.BADGE_SCREEN_ID, payload: screenId.siteCreate });
		this.props.dispatch(adminClinicalPathwayActions.fetchOneClinicalPathwaySubStep(this.props.match.params.id));
	}

	componentDidMount() {
		document.addEventListener("keydown", this._handleKeyDown, false);
	}

	componentWillUnmount() {
		this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { } });
		document.removeEventListener("keydown", this._handleKeyDown, false);
	}

	_handleKeyDown(e) {
		if (e.keyCode === 13) {
			this.saveClicked();
		}
	}

	nameChanged(value) {
		const prevState = this.state;
		prevState.invalidName = false;
		prevState.subStepDetails.name = value;
		this.setState(prevState);
	}

	codeChanged(e) {
		const prevState = this.state;
		if (utils.checkCodeRegex(e)) {
			prevState.invalidCode = false;
			prevState.subStepDetails.code = e;
		} else {
			prevState.subStepDetails.code = "";
		}
		this.setState(prevState);
	}

	validateFields() {
		const prevState = this.state;
		if (!prevState.subStepDetails.name || !prevState.subStepDetails.name.trim() || (prevState.subStepDetails.name.match(/\\$/)) || (prevState.subStepDetails.name.indexOf("\"") >= 0)) {
			prevState.invalidName = true;
		}
		if (!this.state.subStepDetails.code) {
			prevState.invalidCode = true;
		}
		if (!this.state.invalidName && !this.state.invalidCode) {
			return true;
		}
		this.setState(prevState);
		return false;
	}

	saveClicked() {
		if (this.validateFields()) {
			this.props.dispatch({ type: events.ACTION_ONE });
			const payload = {
				name: this.state.subStepDetails.name.trim(),
				code: this.state.subStepDetails.code.trim()
			};
			this.props.dispatch(adminClinicalPathwayActions.updateClinicalPathwaySubStep(this.state.subStepDetails.clpSubStepId, payload));
		}
	}

	render() {
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.state.subStepDetails = this.props.adminClinicalPathway.subStepDetails;
			this.state.subStepDetails.step = {
				label: this.state.subStepDetails.clpStepName,
				value: this.state.subStepDetails.clpStepId
			};
			this.props.dispatch({ type: events.BREADCRUMB_DATA, payload: { name: this.state.subStepDetails.name, id: this.state.subStepDetails.clpSubStepId } });
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwaySubStep());
		}
		if (this.props.adminClinicalPathway.fetchOneClinicalPathwaySubStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearFetchOneClinicalPathwaySubStep());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`);
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwaySubStepCompleted === "FULFILLED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwaySubStep());
			this.props.history.push(`${locations.administration_clinical_pathway}/${locations.tabClinicalPathwaySubStep}`);
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}
		if (this.props.adminClinicalPathway.updateClinicalPathwaySubStepCompleted === "REJECTED") {
			this.props.dispatch(adminClinicalPathwayActions.clearUpdateClinicalPathwaySubStep());
			if (this.props.actions.actionInProgress) {
				this.props.dispatch({ type: events.ACTION_ONE_CLEAR });
			}
		}

		return (       
			<div className="pole_create_section">
				<div className="pole_create_box create_category_personal_width_100">
					<div className="row">
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Code" /></label>
								{/* <label className="code_label"> * <I18 tkey="Code must be 5-digit" /></label> */}
								<NameInput
									value={this.state.subStepDetails.code}
									inputChanged={this.codeChanged.bind(this)}
									maxLength={5}
									disabled={true}/>
								{this.state.invalidCode ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid code' /></span> : ""}
							</div> 
						</div> 
						<div className="col-md-6 col-sm-6">
							<div className="w_100 di margin-bottom-18 position_relative">
								<label><I18 tkey="Name" /></label>
								<NameInput
									value={this.state.subStepDetails.name}
									inputChanged={this.nameChanged.bind(this)}
									maxLength={128}/>
								{this.state.invalidName ? <span className='invalid_right fixed_label'><I18 tkey='Enter valid name (Special characters " \ not allowed)' /></span> : ""}
							</div> 
						</div> 
						{/* <div className="row">
                        <div className="col-md-6 col-sm-6">
                            <div className="w_100 di margin-bottom-20 position_relative">
                                <label><I18 tkey="Step" /></label>
                                <StepDropdown
                                    data={this.state.subStepDetails.step}
                                    clinicalPathwayType={this.props.clinicalPathwayType}
                                    stepChanged={this.stepChanged.bind(this)} />
                                {this.state.invalidStep ? <span className='invalid_right fixed_label'><I18 tkey="Select Step" /></span> : ''}
                            </div>
                        </div>
                        </div> */}
						<div className="col-md-12">
							<div className="di w_100 text_align_right">
								<ActionButton
									onClick={this.saveClicked.bind(this)}
									value={i18Get("SAVE", utils.getLanguage())}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

EditSubStepDetails.propTypes = {
	adminClinicalPathway: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

export default withRouter(connect((store) => ({
	adminClinicalPathway: store.adminClinicalPathway,
	actions: store.actions
}))(EditSubStepDetails));
